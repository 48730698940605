<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Summary Report of Crew & Officer with Medical Issue</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">

            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.rankId"
                                @change="selectReportType"
                                required>
                          <option :value=null>All</option>
                          <option v-for="(rank, index) in ranks" :value="rank.id">{{ rank.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">Year</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.year"
                                @change="selectReportType"
                                required>
                          <option :value="null">All</option>
                          <option v-for="(year) in years" :value="year">{{ year }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">


                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          :disabled="selectedIssues.length <= 0"
                          @click="getCrewReportWithMedicalIssue">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          :disabled="selectedIssues.length <= 0"
                          @click="printReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group row mt-4">
              <div class="col-12 text-left ml-4">
                <div class="row">
                  <div class="col-2">
                    <label for="list-count" class="col-form-label">Medical Issue</label>
                    <span class="col-form-label" style="margin-left: 29px;">:</span>
                  </div>
                  <div class="col-10">
                    <div class="pr-4 pl-0" style="margin-left: -57px; margin-top: 5px">
                      <div class="form-check form-check-inline mr-4 mb-3">
                        <input class="form-check-input" type="checkbox" id="selectAll" value="option2"
                               v-model="selectAll" @click="selectAllIssues">
                        <label class="form-check-label ml-2" for="selectAll">Select All</label>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap" style="margin-left: -57px; margin-top: 5px">
                      <div v-for="(issue, index) in objMedicalIssues" class="form-check form-check-inline mr-4 mb-3">
                        <input class="form-check-input" type="checkbox" :id="`${issue.name}`" :name="`${issue.name}`"
                               :value="`${issue.id}`" v-model="selectedIssues"
                               @click="toggleCheckbox(issue.id)">
                        <label class="form-check-label ml-2 text-capitalize"
                               :for="`${issue.name}`">{{ toLowerCase(issue.name) }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row ml-2">
          <div class="col-12">
            <hr>
            <div class="ml-1 row text-left ">
              <div class="col-md-12">
                <div class="form-group row">
                  <label for="list-count" class="col-form-label">FILTER BY : <b class="pl-2"
                                                                                style="font-size: 1.1em;">{{
                      filterBy
                    }}</b></label>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;">Seafarer Name</th>
                    <th>Rank</th>
                    <th>Clinic</th>
                    <th style="width: 10%;">Checkup</th>
                    <th>Status</th>
                    <th>Sea Service Status</th>
                    <th>Fit</th>
                    <th>Medical Issue</th>
                    <th>File</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td class="text-center align-middle"> {{ index + 1 }}</td>
                          <td class="text-center align-middle">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>
                          </td>
                          <td class="text-center align-middle"> {{ crew.rank }}</td>
                          <td class="text-center align-middle"> {{ crew.clinic }}</td>
                          <td class="text-center align-middle"> {{ crew.date_checkup }}</td>
                          <td class="text-center align-middle"> {{ crew.status }}</td>
                          <td class="text-center align-middle"> {{ crew.seaServiceStatus }}</td>
                          <td class="text-center align-middle"> {{ crew.isFitToWork }}</td>
                          <td class="text-center align-middle">
                            <li v-for="(issue, index) in crew.medical_issues" :key="index"
                                class="text-left text-capitalize"
                                :style="{ color: isSelected(issue.id) ? 'red' : 'gray'}">
                              {{ issue.name }}
                            </li>
                          </td>
                          <td class="text-center align-middle">
                            <template v-if="crew.hasFile">
                              <img
                                  class="btn-img"
                                  title="Click to view remark attachment file"
                                  type="button" :src="pdfIcon"
                                  @click="viewPdfOnNewTab(crew.file)"
                                  style="height: 25px !important; width: 25px !important;" alt="PDF icon">
                            </template>
                            <template v-else>
                              {{ crew.file }}
                            </template>
                          </td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import PdfIcon from "@/assets/viewpdf.png";

export default {
  name: "MedicalIssueReport",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      isEditable: false,
      isGenerateOnboardButton: false,
      reportParam: {
        rankId: null,
        isPDF: false,
        rankName: null,
        year: null,
        medicalIssueId: [],
        filteredBy: null
      },
      isLoading: false,
      hasData: false,
      ranks: null,
      crewData: null,
      rankSelected: null,
      yearSelected: null,
      startYear: 2009,
      years: [],
      selectedIssues: [],
      selectAll: false,
      pdfIcon: PdfIcon,
      filters: null
    }
  },


  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
      'getCrewMedicalKeys',
    ]),
    selectReportType() {
      this.hasData = false;
      this.rankSelected = this.getRankName(this.reportParam.rankId);
      this.yearSelected = this.reportParam.year
    },

    generateYears() {
      const currentYear = new Date().getFullYear();
      for (let year = this.startYear; year <= currentYear; year++) {
        this.years.push(year);
      }
    },

    isSelected(id) {
      return this.reportParam.medicalIssueId.includes(id);
    },

    selectAllIssues() {
      this.selectedIssues = [];

      if (!this.selectAll) {
        this.selectedIssues = this.objMedicalIssues.map(item => item.id);
      } else {
        this.selectedIssues = [];
      }
      this.reportParam.medicalIssueId = this.selectedIssues;
    },

    toggleCheckbox(issueId) {
      this.selectAll = false;
      this.hasData = false;
       if (this.selectedIssues.includes(issueId)) {
        this.selectedIssues = this.selectedIssues.filter((selectedId) => selectedId !== issueId);
      } else {
        this.selectedIssues.push(issueId);
      }
      this.reportParam.medicalIssueId = this.selectedIssues
    },

    toLowerCase(str) {
      return str.toLowerCase();
    },

    getRankName(rankId) {
      let rankName = '';
      const rankList = this.ranks
      for (let c in rankList) {
        if (parseInt(rankList[c].id) === parseInt(rankId)) {
          rankName = rankList[c].name;
        }
      }
      return rankName;
    },

    async getCrewReportWithMedicalIssue() {
      if (this.reportParam.medicalIssueId.length === 0) {
        this.errorAlert('Please Select Medical Issue', '')
      } else {
        this.reportParam.isPDF = false;
        this.hasData = false;
        let params = this.reportParam;
        this.isLoading = true;
        return await axios.post(PMC.GET_MEDICAL_ISSUE_REPORT, params).then(async r => {
          this.isLoading = false;
          if (Object.keys(r.data).length > 0) {
            this.hasData = true;
            this.crewData = r.data;
          } else {
            this.hasData = false;
            this.crewData = null;
          }
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate summary of crew with medical issue')
          return false
        });
      }
    },

    async getRanks() {
      let params = {};
      return await axios.get(PMC.GET_ACTIVE_RANK_LEVEL, params).then(async r => {
        this.ranks = r.data;
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
        return false
      });
    },

    async printCrewReportWithMedicalIssue() {
      this.reportParam.filteredBy = this.filters;
      this.reportParam.isPDF = true;
      let params = this.reportParam;
      return await axios.post(PMC.GET_MEDICAL_ISSUE_REPORT, params).then(async r => {
        this.isLoading = false;
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Crew Age Range')
        return false
      });
    },

    async printReport() {
      if (this.reportParam.medicalIssueId.length === 0) {
        this.errorAlert('Please Select Medical Issue', '')
      } else {
        AlertService.loadingAlert('Please wait ...', 'Generating PDF')
        let pdfData = await this.printCrewReportWithMedicalIssue();
        if (pdfData) {
          await this.pdfViewer('File', pdfData);
          Swal.close();
        } else {
          Swal.close();
        }
      }
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          'objMedicalIssues',
          'isReloading',
        ]),

    filterBy() {
      const filters = [];

      if (this.rankSelected) {
        filters.push(this.getRankName(this.reportParam.rankId));
      } else
        filters.push("ALL")

      if (this.years) {
        filters.push(this.reportParam.year);
      }

      const selectedMedicalIssues = this.objMedicalIssues
          .filter(issue => this.selectedIssues.includes(issue.id))
          .map(issue => issue.name);

      if (selectedMedicalIssues.length > 0) {
        filters.push(selectedMedicalIssues.join(' / '));
      }

      this.filters = filters.filter(Boolean).join(' / ');

      return this.filters;
    },
  },

  /** mounted **/
  mounted() {
    this.getRanks();
    this.generateYears();
  },

  /** created **/
  async created() {
    this.$MyGlobalFunction = global_variables_config
    await this.getCrewComplimentPerVessel();
    this.getCrewMedicalKeys()
    if (this.$route.query.view != null) {
      this.reportParam.rankId = parseInt(this.$route.query.rankId);
    }
  },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },
}
</script>

<style scoped>

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
  text-transform: uppercase;
}

</style>
