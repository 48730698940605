<template>
  <div>
    <slot name="top-nav-header"></slot>
    <slot name="container"></slot>
    <slot name="modal-crew-searching"></slot>
  </div>
</template>

<script>

import Modal from '../base/Modal'

export default {
  name: 'Index',
  data () {
    return {}
  },

  methods: {},

  computed: {},

  mounted () {
  },

  created () {
  },

  destroyed () {
  },

}
</script>

<style scoped>

</style>
