<template>
  <div class="container-fluid">
    <!-- header row -->
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row">
          <!-- title & add button     -->
          <div class="col-md-5 text-left">

            <div class="form-group row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Document</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newDocumentModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Document
                  </button>
                </div>
              </div>

              <!--              <label class="col-sm-3"><h4>Document</h4></label>-->
              <!--              <div class="col-sm-5">-->
              <!--                <button class="btn btn-xs pmc-btn-tertia in"-->
              <!--                        data-toggle="modal"-->
              <!--                        data-target="#newDocumentModal"-->
              <!--                        data-backdrop="static"-->
              <!--                        data-keyboard="false">-->
              <!--                  <font-awesome-icon icon="plus"/>-->
              <!--                  Add-->
              <!--                </button>-->
              <!--              </div>-->

            </div>
          </div>

        </div>

        <div class="row">
          <!-- searchbar -->
          <div class="col-md-3">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                v-model="documentFormParam.searchValue"
                @keypress.enter="search_document"
                placeholder="Search document name">

              <button class="btn btn-sm pmc-btn-prime" @click="search_document">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- document type -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-type" class="col-sm-3 col-form-label">Type</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-8 text-left">
                <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                        v-model="documentFormParam.type"
                        @change="on_change_type">
                  <option :value="null">--select--</option>
                  <option :value="doctype.id" v-for="(doctype,index) in objDocTypes" :key="doctype.id"
                          style="font-size: 14px;">
                    <span> {{ doctype.name }}</span>
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- document category -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-category" class="col-sm-3 col-form-label">Category</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-8 text-left">
                <select name="listCategory" id="list-category" class="custom-select custom-select-sm"
                        v-model="documentFormParam.category" @change="on_change_category">
                  <option :value="null">--select--</option>
                  <option :value="category.id" v-for="(category,index) in objDocCategory" :key="category.id"
                          style="font-size: 14px;">
                    <span> {{ category.name }}</span>
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- status -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listStat" id="list-stat" class="form-control custom-select-sm"
                        v-model="documentFormParam.status"
                        @change="on_change_status">
                  <option :value="null">--select--</option>
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.display }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-right">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="documentFormParam.count"
                        @change="on_change_count">
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="documentPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table section -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="12">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="7%">CODE</th>
                <th scope="row" class="align-middle" width="20%">NAME</th>
                <th scope="row" class="text-center align-middle" width="5%">HAS <br>EXPIRY</th>
                <th scope="row" class="align-middle" width="7%">TYPE</th>
                <th scope="row" class="align-middle" width="7%">CATEGORY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>AT</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>AT</th>
                <th scope="row" class="align-middle" width="5%">STATUS</th>
                <th scope="row" class="text-center align-middle" width="16%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-for="(document,index) in documents" :key="document.id"
                  data-backdrop="static"
                  data-keyboard="false"
              >
                <!--data-toggle="modal"-->
                <!--data-target="#viewRankMatrix"-->
                <!--@click="documentRankMatrix(document)"-->

                <td>{{ index + documentPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ document.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">
                  {{ document.name }}
                </td>
                <td scope="col" class="p-0 text-center">{{ document.hasExpiry ? 'YES' : 'NO' }}</td>
                <td scope="col" class="p-0 text-center">{{ getDocType(document) }}</td>
                <td scope="col" class="p-0 text-center">{{ getDocCategory(document) }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ document.createdBy }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ document.updatedBy }}</td>
                <td scope="col" class="p-0 text-center">{{ document.createdAt }}</td>
                <td scope="col" class="p-0 text-center">{{ document.updatedAt }}</td>
                <td scope="col" class="p-0 text-center">{{ document.status ? 'ACTIVE' : 'IN-ACTIVE' }}</td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewDocumentModal"
                    data-backdrop="static"
                    @click.stop="viewDocument(document.id)"
                    data-keyboard="false">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>

                  <button
                    class="btn btn-xs btn-primary ml-2"
                    data-toggle="modal"
                    data-target="#viewRankMatrix"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="documentRankMatrix(document)"
                  >
                    <font-awesome-icon icon="check"/>
                    rank matrix
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click.stop="delete_document(document.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new document modal   -->
    <div class="modal fade" id="newDocumentModal" tabindex="-1" role="dialog" aria-labelledby="newDocumentModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newDocumentModalLabel">NEW DOCUMENT</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="newDocument.code"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      v-model="newDocument.name"
                      class="form-control form-control-sm"
                      placeholder="---"
                    />
                  </div>
                </div>

                <!-- new has expiry -->
                <div class="form-group row mt-3">
                  <label class="col-sm-3 col-form-label">HAS EXPIRY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      v-model="newDocument.hasExpiry"
                      type="checkbox"
                      class="form-check form-check-inline ml-2 "
                      placeholder="---"
                    />
                  </div>
                </div>

                <!--  new validity               -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">VALIDITY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-2">
                    <input
                      :disabled="!newDocument.hasExpiry"
                      v-model="newDocument.validity_value"
                      type="number"
                      min="0"
                      class="form-control form-control-sm text-center"
                      placeholder="e.g 10"
                    />
                  </div>

                  <!-- validity key   -->
                  <div class="col-sm-3">
                    <select class="form-control form-control-sm"
                            :disabled="!newDocument.hasExpiry"
                            v-model="newDocument.validity_key">
                      <option :value="null" selected>--select--</option>
                      <option :value="validity.id" v-for="(validity,index) in validities" :key="validity.id">
                        <span> {{ validity.key.toUpperCase() }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- new  document type -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">TYPE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select class="custom-select custom-select-sm" v-model="newDocument.doc_type">
                      <option :value="null">--select--</option>
                      <option :value="doctype.id" v-for="(doctype,index) in objDocTypes" :key="doctype.id"
                              style="font-size: 14px;">
                        <span> {{ doctype.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- new category -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CATEGORY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select class="custom-select custom-select-sm" v-model="newDocument.doc_category">
                      <option :value="null">--select--</option>
                      <option :value="category.id" v-for="(category,index) in objDocCategory" :key="category.id"
                              style="font-size: 14px;">
                        <span> {{ category.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- new is monitored -->
                <div class="form-group row mt-3">
                  <label class="col-sm-3 col-form-label">MONITORED</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      v-model="newDocument.isMonitored"
                      type="checkbox"
                      class="form-check form-check-inline ml-2 "
                      placeholder="---"
                    />
                    <span></span>
                  </div>
                </div>

                <!-- new is need rank -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NEED RANK</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      v-model="newDocument.isNeedRank"
                      type="checkbox"
                      class="form-check form-check-inline ml-2 "
                      placeholder="---"
                    />
                    <span></span>
                  </div>
                </div>

                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      v-model="newDocument.status"
                      type="checkbox"
                      class="form-check form-check-inline ml-2 "
                      placeholder="---"
                    />
                    <span>{{ newDocument.status ? 'ACTIVE' : 'IN-ACTIVE' }}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button @click="save"
                    v-show="!isEditable"
                    type="button"
                    class="btn btn-sm pmc-btn-tertia">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal"
              @click="close_new_modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="viewRankMatrix" tabindex="-1" role="dialog" aria-labelledby="viewPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="form-inline mb-3">
              <div class="form-group mb-2 mr-5">
                <h5 class="modal-title" id="viewPrincipalModalLabel">
                  {{ selectedDocument.name }}
                </h5>
              </div>
              <!--              <div class="form-group ">-->
              <!--                <select id="select-certifier"-->
              <!--                        class="form-control form-control-sm ml-3"-->
              <!--                        @change="on_change_checklist_type"-->
              <!--                >-->
              <!--                  <option :value="checklistType.id" v-for="(checklistType,index) in objChecklistTypes"-->
              <!--                          :key="checklistType.id"-->
              <!--                          style="font-size: 14px;">-->
              <!--                    <span> {{ checklistType.description }}</span>-->
              <!--                  </option>-->
              <!--                </select>-->
              <!--              </div>-->
            </div>

            <!--            <h5 class="modal-title" id="viewPrincipalModalLabel">-->
            <!--              {{ selectedDocument.name }}-->
            <!--            </h5>-->
          </div>

          <div class="modal-body">

            <div class="row">
              <div class="col-8">
                <ul class="nav nav-pills" id="pills-tab" role="tablist">

                  <li class="nav-item">
                    <a class="nav-link active" id="pills-allRanks-tab" data-toggle="pill" href="#pills-allRanks"
                       role="tab"
                       aria-selected="true">ALL RANKS</a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" id="pills-deck-tab" data-toggle="pill" href="#pills-deck" role="tab"
                       aria-selected="false">DECK</a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" id="pills-engine-tab" data-toggle="pill" href="#pills-engine" role="tab"
                       aria-selected="false">ENGINE</a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" id="pills-radio-tab" data-toggle="pill" href="#pills-radio" role="tab"
                       aria-selected="false">RADIO</a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" id="pills-steward-tab" data-toggle="pill" href="#pills-steward" role="tab"
                       aria-selected="false">STEWARD</a>
                  </li>
                </ul>

              </div>
              <!--              <div class="col-4 text-end mt-1">-->
              <!--                <select id="select-certifier" class="form-control form-control-sm"-->
              <!--                        v-model="selectedChecklistCategoryId"-->
              <!--                        :disabled="!isEditable"-->
              <!--                >-->
              <!--                  <option value="null">&#45;&#45;CHECKLIST CATEGORY&#45;&#45;</option>-->
              <!--                  <option :value="checklistCategory.id" v-for="(checklistCategory,index) in objChecklistCategories"-->
              <!--                          :key="checklistCategory.id"-->
              <!--                          style="font-size: 14px;">-->
              <!--                    <span> {{ checklistCategory.description }}</span>-->
              <!--                  </option>-->
              <!--                </select>-->
              <!--              </div>-->
            </div>

            <hr>

            <div class="row mt-2">
              <div class="col-md-12">
                <div class="row ml-1">

                  <div class="col-sm-6">
                    <div class="row text-left">
                      <div class="col-md-12">
                        <div class="form-group row">
                          <label for="list-count" class="col-sm-4 col-form-label">Category</label>
                          <span class="col-form-label">:</span>
                          <div class="col-sm-7 text-left">
                            <select id="select-certifier" class="form-control form-control-sm"
                                    v-model="selectedChecklistCategoryId"
                                    :disabled="!isEditable"
                            >
                              <option :value="null" style="font-size: 12px;">--CHECKLIST CATEGORY--</option>
                              <option :value="checklistCategory.id" v-for="(checklistCategory,index) in objChecklistCategories"
                                      :key="checklistCategory.id"
                                      style="font-size: 12px;">
                                <span> {{ checklistCategory.description }}</span>
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-md-12 text-left">
                        <div class="form-group row">
                          <label for="list-count" class="col-sm-3 col-form-label">Filter</label>
                          <span class="col-form-label">:</span>
                          <div class="col-sm-8 text-left">
                            <select id="select-certifier"
                                    class="form-control form-control-sm"
                                    @change="on_change_checklist_type"
                            >
                              <option :value="checklistType.id" v-for="(checklistType,index) in objChecklistTypes"
                                      :key="checklistType.id"
                                      style="font-size: 12px;">
                                <span> {{ checklistType.description }}</span>
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-12">
                <div class="row ml-1">

                  <div class="col-sm-6">
                    <div class="row text-left">
                      <div class="col-md-12">
                        <div class="form-group row">
                          <label for="list-count" class="col-sm-4 col-form-label">Sequence</label>
                          <span class="col-form-label">:</span>
                          <div class="col-sm-7 text-left">
                            <input type="text" class="form-control form-control-sm"
                                   :disabled="!isEditable" v-model="selectedDocument.cchDocSequence" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-md-12 text-left">
                        <div class="form-group row">
                          <label for="list-count" class="col-sm-3 col-form-label">Parent Document</label>
                          <span class="col-form-label">:</span>
                          <div class="col-sm-8 text-left">
                            <multi-select
                              :disabled="!isEditable"
                              :select-label="'Select'"
                              v-model="parentDocumentId"
                              :options="filteredObjDocs"
                              :custom-label="namesLang"
                              placeholder="Select Parent Document"
                              label="VesselType"
                              style="z-index: 9999;"
                              track-by="id">
                            </multi-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>


            <div class="tab-content" id="pills-tabContent">

              <div class="tab-pane fade show active" id="pills-allRanks" role="tabpanel"
                   aria-labelledby="pills-allRanks-tab">
                <br>

                <div class="row justify-content-center">
                  <div class="col col-md">
                    <div class="table table-responsive pmc-table border-top"
                         style="overflow-x: hidden !important; height: 350px; width: 100%;">
                      <table class="table table-sm text-center small sticky-header">
                        <thead v-if="objAllRanks ? (objAllRanks.length !== 0) : '' ">
                        <tr>
                          <th>(SELECT ALL)</th>
                          <th style="width: 50%;">
                            <div style="margin-right: 10px;" class="mb-1">
                              <input type="checkbox"
                                     v-model="selectAllRanks"
                                     @change="selectAllRanksMatrix"
                                     :disabled="!isEditable"
                              />
                            </div>
                          </th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(allRank,index) in objAllRanks" :key="allRank.id">
                          <td>{{ allRank.name }}</td>

                          <td>
                            <input
                              type="checkbox"
                              class="form-check form-check-inline mb-2 mt-2"
                              placeholder="---"
                              :value="allRank.id"
                              v-model="selectedRanks"
                              :disabled="!isEditable"
                            />
                          </td>
                        </tr>

                        <tr v-if="objAllRanks ? (objAllRanks.length == 0) : '' ">
                          <td colspan="100%" class="bg-light-danger font-weight-bolder">
                            <p class="text-center mt-3">There are no ranks available. </p>
                          </td>
                        </tr>


                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>

              </div>

              <div class="tab-pane fade" id="pills-deck" role="tabpanel"
                   aria-labelledby="pills-deck-tab">
                <br>

                <div class="row justify-content-center">
                  <div class="col col-md">
                    <div class="table table-responsive pmc-table border-top"
                         style="overflow-x: hidden !important; height: 350px; width: 100%;">
                      <table class="table table-sm text-center small sticky-header">

                        <thead v-if="objDeckRanks ? (objDeckRanks.length !== 0) : '' ">
                        <tr>
                          <th>(SELECT ALL)</th>
                          <th style="width: 50%;">
                            <div style="margin-right: 10px;" class="mb-1">
                              <input type="checkbox"
                                     v-model="selectAllDeck"
                                     @change="selectAllDeckRanksMatrix"
                                     :disabled="!isEditable"
                              />
                            </div>
                          </th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(deckRank,index) in objDeckRanks" :key="deckRank.id">
                          <td>{{ deckRank.name }}</td>

                          <td>
                            <input
                              type="checkbox"
                              class="form-check form-check-inline mb-2 mt-2"
                              :value="deckRank.id"
                              v-model="selectedRanks"
                              :disabled="!isEditable"
                            />
                          </td>

                          <!--                          <td>-->
                          <!--                            <input-->
                          <!--                              type="checkbox"-->
                          <!--                              class="form-check form-check-inline mb-2 mt-2"-->
                          <!--                              :checked="sampleDataFromBackend.some(item => item.rank_id === deckRank.id)"-->
                          <!--                              @change="updateSelectedRankMatrix(deckRank.id, selectedDocument.id)"-->
                          <!--                            />-->
                          <!--                          </td>-->


                          <!--                          <td>-->
                          <!--                            <input-->
                          <!--                              type="checkbox"-->
                          <!--                              class="form-check form-check-inline mb-2 mt-2"-->
                          <!--                              :checked="selectedRankMatrix.includes(deckRank.id)"-->
                          <!--                              @change="updateSelectedRankMatrix(deckRank.id, selectedDocument.id)"-->
                          <!--                            />-->
                          <!--                          </td>-->


                        </tr>


                        <tr v-if="objDeckRanks ? (objDeckRanks.length == 0) : '' ">
                          <td colspan="100%" class="bg-light-danger font-weight-bolder">
                            <p class="text-center mt-3">There are no ranks available for deck. </p>
                          </td>
                        </tr>

                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>


              </div>


              <div class="tab-pane fade" id="pills-engine" role="tabpanel"
                   aria-labelledby="pills-engine-tab">
                <br>

                <div class="row justify-content-center">
                  <div class="col col-md">
                    <div class="table table-responsive pmc-table border-top"
                         style="overflow-x: hidden !important; height: 350px; width: 100%;">
                      <table class="table table-sm text-center small sticky-header">
                        <thead v-if="objEngineRanks ? (objEngineRanks.length !== 0) : '' ">
                        <tr>
                          <th>(SELECT ALL)</th>
                          <th style="width: 50%;">
                            <div style="margin-right: 10px;" class="mb-1">
                              <input type="checkbox"
                                     v-model="selectAllEngine"
                                     @change="selectAllEngineRanksMatrix"
                                     :disabled="!isEditable"
                              />
                            </div>
                          </th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(engineRank,index) in objEngineRanks" :key="engineRank.id">
                          <td>{{ engineRank.name }}</td>

                          <td>
                            <input
                              type="checkbox"
                              class="form-check form-check-inline mb-2 mt-2"
                              placeholder="---"
                              :value="engineRank.id"
                              v-model="selectedRanks"
                              :disabled="!isEditable"
                            />
                          </td>
                        </tr>

                        <tr v-if="objEngineRanks ? (objEngineRanks.length == 0) : '' ">
                          <td colspan="100%" class="bg-light-danger font-weight-bolder">
                            <p class="text-center mt-3">There are no ranks available for engine. </p>
                          </td>
                        </tr>


                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>

              </div>


              <div class="tab-pane fade" id="pills-radio" role="tabpanel"
                   aria-labelledby="pills-radio-tab">
                <br>

                <div class="row justify-content-center">
                  <div class="col col-md">
                    <div class="table table-responsive pmc-table border-top"
                         style="overflow-x: hidden !important; height: 350px; width: 100%;"">
                      <table class="table table-sm text-center small sticky-header">
                        <thead v-if="objRadioRanks ? (objRadioRanks.length !== 0) : '' ">
                        <tr>
                          <th>(SELECT ALL)</th>
                          <th style="width: 50%;">
                            <div style="margin-right: 10px;" class="mb-1">
                              <input type="checkbox"
                                     v-model="selectAllRadio"
                                     @change="selectAllRadioRanksMatrix"
                                     :disabled="!isEditable"
                              />
                            </div>
                          </th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(radioRank,index) in objRadioRanks" :key="radioRank.id">
                          <td>{{ radioRank.name }}</td>

                          <td>
                            <input
                              type="checkbox"
                              class="form-check form-check-inline mb-2 mt-2"
                              placeholder="---"
                              :value="radioRank.id"
                              v-model="selectedRanks"
                              :disabled="!isEditable"
                            />
                          </td>
                        </tr>

                        <tr v-if="objRadioRanks ? (objRadioRanks.length == 0) : '' ">
                          <td colspan="100%" class="bg-light-danger font-weight-bolder">
                            <p class="text-center mt-3">There are no ranks available for radio. </p>
                          </td>
                        </tr>


                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>

              </div>


              <div class="tab-pane fade" id="pills-steward" role="tabpanel"
                   aria-labelledby="pills-steward-tab">
                <br>

                <div class="row justify-content-center">
                  <div class="col col-md">
                    <div class="table table-responsive pmc-table border-top"
                         style="overflow-x: hidden !important; height: 350px; width: 100%;">
                      <table class="table table-sm text-center small sticky-header">

                        <thead v-if="objStewardRanks ? (objStewardRanks.length !== 0) : '' ">
                        <tr>
                          <th>(SELECT ALL)</th>
                          <th style="width: 50%;">
                            <div style="margin-right: 10px;" class="mb-1">
                              <input type="checkbox"
                                     v-model="selectAllSteward"
                                     @change="selectAllStewardRanksMatrix"
                                     :disabled="!isEditable"
                              />
                            </div>
                          </th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(stewardRank,index) in objStewardRanks" :key="stewardRank.id">
                          <td>{{ stewardRank.name }}</td>

                          <td>
                            <input
                              type="checkbox"
                              class="form-check form-check-inline mb-2 mt-2"
                              placeholder="---"
                              :value="stewardRank.id"
                              v-model="selectedRanks"
                              :disabled="!isEditable"
                            />
                          </td>
                        </tr>

                        <tr v-if="objStewardRanks ? (objStewardRanks.length == 0) : '' ">
                          <td colspan="100%" class="bg-light-danger font-weight-bolder">
                            <p class="text-center mt-3">There are no ranks available for steward. </p>
                          </td>
                        </tr>


                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>

              </div>


            </div>


          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="setEditRankMatrix"
            >
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable"
            >
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    @click="saveRankMatrix"
                    v-show="isEditable"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="setCancelRankMatrix"
            >
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {AppMixins} from '../../../../mixins/app.mixins';
import {AlertMixins} from '../../../../mixins/alert.mixins';
import {AlertService} from "@/service/alert.service";
import _ from "lodash";

export default {
  name: 'List',

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],
      statuses: [
        {
          id: 1,
          key: '',
          display: 'All',
        },
        {
          id: 2,
          key: 1,
          display: 'Active',
        },
        {
          id: 3,
          key: 0,
          display: 'Inactive',
        },
      ],

      isEditable: false,
      validities: [
        {
          id: 1,
          key: 'days',
        },

        {
          id: 2,
          key: 'months',
        },

        {
          id: 3,
          key: 'years',
        },
      ],

      documentFormParam: {
        searchValue: null,
        type: null,   //default document
        category: null, //default foreign
        count: 10,
        status: 1, //default true
        page: null,
      },

      pageLastLeft: 1,

      rankTypeParam: {
        rankType: null,
      },

      selectedDocument: {},

      selectedRankMatrix: [],

      sampleDataFromBackend: [
        {
          "id": 5,
          "document_id": 216,
          "rank_id": 8
        },
        {
          "id": 6,
          "document_id": 216,
          "rank_id": 1
        }
      ],

      selectedRanks: [],
      selectedChecklistCategoryId: null,

      selectAllRanks: false,
      selectAllDeck: false,
      selectAllEngine: false,
      selectAllRadio: false,
      selectAllSteward: false,

      documentRankMatrixParams: {
        documentId: null,
        checklistType: 1,
      },

      cchDocParent: {
        id: null,
      },
      cchDocSequence: null,

    };
  },

  /** methods**/
  methods: {
    ...mapActions(
      [
        'getDocumentSetupKeys',
        'set_documents',
        'set_document',
        'create_document',
        'delete_document',
        'update_document',
        'null_document',
        'null_documents',
        'reset_document_pagination',
        'reset_document_formparam',
        'null_obj_docCategory',
        'null_obj_doctypes',
        'null_new_document',
        'document_prerequisite',

        'getAllRanks',
        'getDeckRanks',
        'getEngineRanks',
        'getRadioRanks',
        'getStewardRanks',
        'update_document_rank_matrix',
        'set_document_rank_matrix',

        'getChecklistCategoryKeys',
        'getChecklistTypeKeys',

        'getDocumentKeys',

      ],
    ),

    namesLang({name}) {
      return `${name}`;
    },

    search_document() {

      if (this.documentFormParam.searchValue === '') {
        this.documentFormParam.page = this.pageLastLeft
        this.set_documents(this.documentFormParam)
      }

      if (this.documentFormParam.searchValue) {
        this.documentFormParam.page = 1
      }

      this.set_documents(this.documentFormParam)


      // this.documentFormParam.page = 1;  //set always first page when searching
      // this.set_documents(this.documentFormParam);

    },

    on_change_status(e) {
      this.documentFormParam.page = 1
      this.documentFormParam.status = e.target.value;
      this.set_documents(this.documentFormParam);
    },

    on_change_count(e) {

      this.documentFormParam.page = 1
      this.documentFormParam.searchValue
      this.pageLastLeft = 1
      this.documentFormParam.count = e.target.value
      this.set_documents(this.documentFormParam)


      // this.documentFormParam.count = e.target.value;
      // this.set_documents(this.documentFormParam);
    },

    on_change_type(e) {
      this.documentFormParam.type = e.target.value;
      this.set_documents(this.documentFormParam);
    },

    on_change_category(e) {
      this.documentFormParam.category = e.target.value;
      this.set_documents(this.documentFormParam);
    },

    set_this_page(e) {

      this.documentFormParam.searchValue
      this.documentPagination.currentPage = e
      this.documentFormParam.page = e
      this.pageLastLeft = e
      this.set_documents(this.documentFormParam)

      // this.documentFormParam.currentPage = e;
      // this.documentFormParam.page = e;
      // this.set_documents(this.documentFormParam);
    },

    getDocType({docType}) {
      let __docType = null;
      __docType = this.objDocTypes.find((val, index) => val.id == docType);
      return __docType ? __docType.name : null;
    },

    getDocCategory({docCategory}) {
      let __docCategory = null;
      __docCategory = this.objDocCategory.find((val, index) => val.id == docCategory);
      return __docCategory ? __docCategory.name : null;
    },

    close_new_modal() {
      this.null_new_document();
    },

    close_view_modal() {
      this.null_document();
      this.isEditable = false;
    },

    set_edit() {
      this.isEditable = true;
    },

    set_cancel() {
      this.isEditable = !this.isEditable;
      this.set_document(this.document.id);
    },

    save() {
      this.create_document(this.newDocument).then((r) => {
        if (r) {
          this.hideModal('newDocumentModal');
        }
      });
    },

    update() {
      this.update_document(this.document).then((r) => {
        if (r) {
          this.hideModal('viewDocumentModal');
          this.isEditable = false;
        }
      });
    },

    goto_pre_requisite_page(__documentId) {
      this.document_prerequisite(__documentId);
      this.$router.push({
        name: 'DocPreRequisite',
        params: {documentId: __documentId},
      });
    },

    viewDocument(__id) {
      this.$router.push({name: 'DocumentView', params: {documentId: __id}})
    },

    documentRankMatrix(documentData) {
      this.selectedDocument = documentData
      this.selectedChecklistCategoryId = documentData.checklistCategoryId
      this.cchDocParent.id = documentData.cchDocParentId
      this.selectedDocument.cchDocSequence = documentData.cchDocSequence

      this.documentRankMatrixParams.documentId = documentData.id
      this.set_document_rank_matrix(this.documentRankMatrixParams)
    },

    async saveRankMatrix() {
      let __create = AlertService.questionAlertService('Are you sure you want to save your changes?', 'Document Rank Matrix', 'question')
      if (await __create) {
        console.log(this.selectedRanksWithDocumentId)
        let params = {
          documentSequence: this.selectedDocument.cchDocSequence,
          documentParent: this.parentDocumentId ? this.parentDocumentId.id : 0,
          rankMatrix: this.selectedRanksWithDocumentId,
          selectedDocumentId: this.selectedDocument.id,
          checklistTypeId: this.documentRankMatrixParams.checklistType,
          selectedChecklistCategoryId: this.selectedChecklistCategoryId,
        }

        let documentRankMatrix = await this.update_document_rank_matrix(params);
        if (documentRankMatrix) {
          AlertService.successAlertService('Your changes have been saved', 'Document Rank Matrix');
          // this.hideModal('viewRankMatrix')
          this.isEditable = false

          this.set_document_rank_matrix(this.documentRankMatrixParams)
          this.selectedDocument.checklistCategoryId = this.selectedChecklistCategoryId

            //
            this.selectedDocument.cchDocParentId = this.parentDocumentId.id;


          this.selectAllRanks = false
          this.selectAllDeck = false
          this.selectAllEngine = false
          this.selectAllRadio = false
          this.selectAllSteward = false

        }

      }
    },

    setEditRankMatrix() {
      this.isEditable = true
    },

    setCancelRankMatrix() {
      this.isEditable = false
      this.selectedRankMatrix = []

      this.selectedRanks = this.documentRanksMatrix.map((data) => data.rank_id);

      this.selectAllRanks = false;
      this.selectAllDeck = false;
      this.selectAllEngine = false;
      this.selectAllRadio = false;
      this.selectAllSteward = false;

      this.selectedChecklistCategoryId = this.selectedDocument.checklistCategoryId;

    },


    selectAllRanksMatrix() {
      if (this.selectAllRanks) {
        const allRanks = this.objAllRanks.map((deckRank) => deckRank.id);
        this.selectedRanks = this.selectedRanks.concat(allRanks);
      } else {
        this.selectedRanks = this.selectedRanks.filter((rank) => {
          return !this.objAllRanks.some((deckRank) => deckRank.id === rank);
        });
      }
    },

    selectAllDeckRanksMatrix() {
      if (this.selectAllDeck) {
        const deckRanks = this.objDeckRanks.map((deckRank) => deckRank.id);
        this.selectedRanks = this.selectedRanks.concat(deckRanks);
      } else {
        this.selectedRanks = this.selectedRanks.filter((rank) => {
          return !this.objDeckRanks.some((deckRank) => deckRank.id === rank);
        });
      }
    },

    selectAllEngineRanksMatrix() {
      if (this.selectAllEngine) {
        const engineRanks = this.objEngineRanks.map((deckRank) => deckRank.id);
        this.selectedRanks = this.selectedRanks.concat(engineRanks);
      } else {
        this.selectedRanks = this.selectedRanks.filter((rank) => {
          return !this.objEngineRanks.some((deckRank) => deckRank.id === rank);
        });
      }
    },

    selectAllRadioRanksMatrix() {
      if (this.selectAllRadio) {
        const radioRanks = this.objRadioRanks.map((deckRank) => deckRank.id);
        this.selectedRanks = this.selectedRanks.concat(radioRanks);
      } else {
        this.selectedRanks = this.selectedRanks.filter((rank) => {
          return !this.objRadioRanks.some((deckRank) => deckRank.id === rank);
        });
      }
    },

    selectAllStewardRanksMatrix() {
      if (this.selectAllSteward) {
        const stewardRanks = this.objStewardRanks.map((deckRank) => deckRank.id);
        this.selectedRanks = this.selectedRanks.concat(stewardRanks);
      } else {
        this.selectedRanks = this.selectedRanks.filter((rank) => {
          return !this.objStewardRanks.some((deckRank) => deckRank.id === rank);
        });
      }
    },

    on_change_checklist_type(e) {
      this.documentRankMatrixParams.checklistType = e.target.value;
      this.set_document_rank_matrix(this.documentRankMatrixParams)
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objDocTypes',
        'objDocCategory',
        'document',
        'documents',
        'newDocument',
        'documentPagination',
        'isReloading',

        'objAllRanks',
        'objDeckRanks',
        'objEngineRanks',
        'objRadioRanks',
        'objStewardRanks',

        // ranks from backend
        'documentRanksMatrix',

        'objChecklistCategories',
        'objChecklistTypes',
        'objDocs',

      ],
    ),
      filteredObjDocs() {
        return _.filter(this.objDocs, document => document.id !== this.selectedDocument.id);
      },

    documentType: {
      get() {
        let __type = this.objDocTypes.find((val, index) => val.id === this.document.docType);
        return __type ? __type.name : null;
      },
      set(val) {
        this.document.docType = val ? val.id : null;
      },
    },

    documentCategory: {
      get() {
        let __category = this.objDocCategory.find((val, index) => val.id === this.document.docCategory);
        return __category ? __category.name : null;
      },

      set(val) {
        this.document.docCategory = val ? val : null;
      },
    },

    parentDocumentId: {
      get() {
        let obj = this.objDocs.find((doc) => doc.id === this.cchDocParent.id );
        return obj;
      },
      set(val) {
        this.cchDocParent.id  = val ? val.id : null;
      },
    },

    selectedRanksWithDocumentId() {
      return this.selectedRanks.map(id => {
        // const rank = this.objDeckRanks.find(r => r.id === id);
        return {
          rankId: id,
          documentId: this.selectedDocument.id,
          selectedChecklistCategoryId: this.selectedChecklistCategoryId,
          checklistTypeId: this.documentRankMatrixParams.checklistType,
        };
      });
    },


  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.getDocumentSetupKeys();   //load the key first
    await this.set_documents(this.documentFormParam);

    await this.getAllRanks();
    await this.getDeckRanks();
    await this.getEngineRanks();
    await this.getRadioRanks();
    await this.getStewardRanks();

    await this.getChecklistCategoryKeys();
    await this.getChecklistTypeKeys();
    await this.getDocumentKeys();

    this.selectedRanks = this.documentRanksMatrix.map((data) => data.rank_id);
  },

  /** watch **/
  watch: {

    documentRanksMatrix: {
      immediate: true, // trigger the watcher immediately on component creation
      handler(newVal) {
        this.selectedRanks = newVal.map((data) => data.rank_id);
      },
    },


    'selectedChecklistCategoryId'() {
      if (this.selectedChecklistCategoryId == null) {
        this.selectedRanks = []
      }

    },


  },

  /** destroyed **/
  destroyed() {
    this.reset_document_pagination();
    this.reset_document_formparam();
    this.null_document();
  },
};
</script>

<style scoped>
.custom-select-sm {
  font-size: 0.75rem !important;
}

input[type="checkbox"] {
  transform: scale(2) !important;
}
</style>
