import axios from "axios";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";


const initStateCrewChangePlan = () => {
  return {
    onboardDocumentExpiryRemark: {},
    onboardCrewsDocCerts: [],
    onboardCrewsDocCertsCount: null,
    isLoadingOnboardDocRemark: null,
  }
};

const state = {
  ...initStateCrewChangePlan()
};

const mutations = {
  set_onboardCrewsDocCerts(state, params) {
    state.onboardCrewsDocCerts = params
  },

  set_onboardCrewsDocCertsCount(state, params) {
    state.onboardCrewsDocCertsCount = params
  },

  set_onboardDocumentExpiryRemark(state, params) {
    state.onboardDocumentExpiryRemark = params
  },

  set_is_loading_onboard_doc_remark(state, payload) {
    state.isLoadingOnboardDocRemark = payload
  },

  update_onboard_doc_expiry_remark(state, params) {
    let onboardCrewsDocCerts = state.onboardCrewsDocCerts.find((val, index) => val.crewDocumentId === params.crewDocumentId)
    Object.assign(onboardCrewsDocCerts, params)
  },


}


const actions = {
  async set_onboardDocsCertsExpiry({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GET_ONBOARD_DOCS_CERTS_EXPIRY, {params}).then((r) => {
      commit('set_onboardCrewsDocCerts', r.data.crewDocuments)
      commit('set_onboardCrewsDocCertsCount', r.data.count)
      commit('set_is_loading', false)
      return true;

    }).catch((er) => {
      commit('set_is_loading', false)
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get set_onboard crews doc certs')
      }
      return false;
    })
  },


  async generateOnboardDocsCertsExpiryList({commit}, params) {
    return await axios.post(PMC.GENERATE_ONBOARD_DOCS_CERTS_EXPIRY, params).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate onboard crews doc certs')
      return false
    });
  },


  async set_onboard_document_expiry_remark({commit}, param) {
    commit('set_is_loading_onboard_doc_remark', true)
    return await axios.get(`${PMC.FIND_ONBOARD_DOCS_CERTS_EXPIRY}/${param}`).then((r) => {
      commit('set_onboardDocumentExpiryRemark', r.data);
      commit('set_is_loading_onboard_doc_remark', false)
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Find onboard document remark');
      return false;
    });
  },

  async update_onboard_document_expiry_remark({commit}, params) {
    return axios.post(`${PMC.UPDATE_ONBOARD_DOCS_CERTS_EXPIRY}/${params.id}`, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('update_onboard_doc_expiry_remark', r.data.updatedOnboardDocumentExpiryRemark);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Update onboard document expiry remark');
      return false;

    });
  },

};


const getters = {
  onboardCrewsDocCerts: state => UtilityService.capitalizeProperties(state.onboardCrewsDocCerts),
  onboardCrewsDocCertsCount: state => state.onboardCrewsDocCertsCount,
  onboardDocumentExpiryRemark: state => state.onboardDocumentExpiryRemark,
  isLoadingOnboardDocRemark: state => state.isLoadingOnboardDocRemark,
};


export default {state, actions, mutations, getters};

