<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">School</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-sm pmc-btn-prime"
                          data-toggle="modal"
                          data-target="#newSchoolModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add
                  </button>
                </div>
              </div>


<!--              <div class="col-3">-->
<!--                <h4>Schools</h4>-->
<!--              </div>-->
<!--              <div class="col-3">-->
<!--                <button class="btn btn-sm pmc-btn-tertia"-->
<!--                        data-toggle="modal"-->
<!--                        data-target="#newSchoolModal"-->
<!--                        data-backdrop="static"-->
<!--                        data-keyboard="false">-->
<!--                  <span>ADD</span>-->
<!--                </button>-->
<!--              </div>-->

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                  v-model="schoolFormParam.searchValue"
                  type="text"
                  class="form-control form-control-sm"
                  id="formdata-searchvalue"
                  @keypress.enter="search_school"
                  placeholder="Search school name">

              <button class="btn btn-sm pmc-btn-prime" @click="search_school">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- status -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm" v-model="schoolFormParam.status" @change="on_change_status">
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.display }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm" v-model="schoolFormParam.count" @change="on_change_count">
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- pagination   -->
        <div class="row py-1">
          <div class="col-md-8">
            <div class="row">
              <div class="col-6">
                <pmc-pagination :page-object="schoolPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table section -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="10">
            <template slot="table-header">
              <tr>
                <th scope="row" width="2%">#</th>
                <th scope="row" width="5%">CODE</th>
                <th scope="row" width="20%">NAME</th>
                <th scope="row" width="20%">ADDRESS</th>
                <th scope="row" width="9%">CREATED BY</th>
                <th scope="row" width="9%">UDATED BY</th>
                <th scope="row" width="6%">CREATED AT</th>
                <th scope="row" width="6%">UPDATED AT</th>
                <th scope="row" width="5%">STATUS</th>
                <th scope="row" width="7%">ACTION</th>
              </tr>
            </template>

            <template slot="table-body">
              <tr v-if = "schools.length === 0">
                <td colspan="10">No Results</td>
              </tr>
              <tr v-else v-for="(school,index) in schools" :key="school.id">
                <td scope="col">{{ index + schoolPagination.from }}</td>
                <td scope="col">{{ school.code }}</td>
                <td scope="col" class="text-left pl-4">{{ school.name }}</td>
                <td scope="col" class="text-left pl-4">{{ school.address }}</td>
                <td scope="col">{{ school.createdBy }}</td>
                <td scope="col">{{ school.updatedBy }}</td>
                <td scope="col">{{ school.created_at }}</td>
                <td scope="col">{{ school.updated_at }}</td>
                <td scope="col">{{ school.status ? 'ACTIVE' : 'IN-ACTIVE' }}</td>
                <td>
                  <button class="btn btn-xs pmc-btn-prime"
                          data-toggle="modal"
                          data-target="#viewSchoolModal"
                          data-backdrop="static"
                          data-keyboard="false"
                          @click="set_school(school.id)">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>

                  <button class="btn btn-xs pmc-btn-danger ml-2" @click="delete_school(school.id)">
                    <font-awesome-icon icon="trash"/>
                    <span>delete</span>
                  </button>

                </td>
              </tr>
            </template>
          </pmc-table>
        </div>

      </div>
    </div>

    <!-- new school modal -->
    <div class="modal fade" id="newSchoolModal" tabindex="-1" role="dialog" aria-labelledby="newSchoolModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">NEW SCHOOL</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           v-model="newSchool.code"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- code -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           v-model="newSchool.name"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- address -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ADDRESS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                      <textarea type="text"
                                rows="5"
                                style="resize: none;"
                                v-model="newSchool.address"
                                class="form-control form-control-sm"
                                placeholder="---"
                      ></textarea>
                  </div>
                </div>

                <!-- status -->
                <div class="form-group row mt-3">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="checkbox"
                           v-model="newSchool.status"
                           class="form-check form-check-inline ml-2"
                           placeholder="---">
                    <span>{{ newSchool.status ? 'ACTIVE' : 'IN-ACTIVE' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn pmc-btn-tertia"
              @click="create">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>
            <button
              type="button"
              class="btn pmc-btn-second"
              data-dismiss="modal"
              @click="on_close_newschool">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- view school modal -->
    <div class="modal fade" id="viewSchoolModal" tabindex="-1" role="dialog" aria-labelledby="viewSchoolModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewSchoolModalLabel">View School Details</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="school.code"
                           class="form-control form-control-sm"
                           :disabled="!isEditable"
                           :class="{'form-control-plaintext':!isEditable,'':isEditable}"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="school.name"
                           class="form-control form-control-sm"
                           :disabled="!isEditable"
                           :class="{'form-control-plaintext':!isEditable,'':isEditable}"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- address -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ADDRESS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                      <textarea type="text"
                                rows="5"
                                style="resize: none;"
                                v-model="school.address"
                                class="form-control form-control-sm"
                                :disabled="!isEditable"
                                placeholder="---"
                      ></textarea>
                  </div>
                </div>

                <!-- status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="checkbox"
                           v-model="school.status"
                           class="form-check form-check-inline ml-2"
                           :disabled="!isEditable"
                           placeholder="---"/>
                    <span>{{ school.status ? 'ACTIVE' : 'IN-ACTIVE' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button" class="btn btn-sm pmc-bg-warning"
              v-show="!isEditable"
              @click="set_editable">
              <font-awesome-icon icon="edit"/>
              EDIT</button>

            <button
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal"
              v-show="!isEditable"
              @click="on_close_viewschool">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              v-show="isEditable"
              @click="update(school)">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              type="button"
              class="btn btn-sm pmc-btn-danger"
              v-show="isEditable"
              @click="set_cancel">
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AppMixins} from '../../../../mixins/app.mixins';
import {AlertMixins} from '../../../../mixins/alert.mixins';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'List',

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          display: 'All',
        },
        {
          id: 2,
          key: 1,
          display: 'Active',
        },
        {
          id: 3,
          key: 0,
          display: 'Inactive',
        },
      ],

      isEditable: false,

      pageLastLeft: 1,

    };
  },

  /** methods**/
  methods: {
    ...mapActions(
        [
          'set_school_paginated',
          'set_null_schools',
          'set_null_school',
          'set_null_new_school',
          'reset_school_pagination',
          'create_school',
          'update_school',
          'delete_school',
          'set_school',
        ],
    ),

    search_school() {
      if (this.schoolFormParam.searchValue === '') {
        this.schoolFormParam.page = this.pageLastLeft
        this.set_school_paginated(this.schoolFormParam)
      }

      if (this.schoolFormParam.searchValue) {
        this.schoolFormParam.page = 1
      }

      this.set_school_paginated(this.schoolFormParam);
    },

    async create() {
      if (await this.questionAlert('Are you sure you want to add this new school', 'Adding new school')) {
        this.create_school(this.newSchool).then((r) => {
          if (r) {
            this.hideModal('newSchoolModal');
          }
        });
      }
    },

    async update() {
      if (await this.questionAlert('Do you want to save the changes.', 'Update school', 'question')) {
        this.update_school(this.school).then((r) => {
          if (r) {
            this.hideModal('viewSchoolModal');
            this.isEditable = false;
          }
        });
      }
    },

    set_this_page(e) {
      this.schoolFormParam.searchValue = ''
      this.schoolPagination.currentPage = e
      this.schoolFormParam.page = e
      this.pageLastLeft = e
      this.set_school_paginated(this.schoolFormParam)
    },

    set_editable() {
      this.isEditable = true;
    },

    set_cancel() {
      this.set_school(this.school.id);
      this.isEditable = false;
    },

    on_close_viewschool() {
      this.isEditable = false;
      this.set_null_school();
    },

    on_close_newschool() {
      this.set_null_new_school();
    },

    on_change_count(e) {
      this.schoolFormParam.page = 1
      this.schoolFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.schoolFormParam.count = e.target.value
      this.set_school_paginated(this.schoolFormParam)
    },

    on_change_status() {
      this.schoolFormParam.page = 1;
      this.set_school_paginated(this.schoolFormParam);
    },
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          'schoolPagination',
          'schoolFormParam',
          'newSchool',
          'schools',
          'school',
          'isReloading',
        ],
    ),
  },

  /** mounted **/
  mounted() {},

  /** created **/
  created() {
    this.set_school_paginated(this.schoolFormParam);
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.set_null_school();
    this.reset_school_pagination();
  },
};
</script>

<style scoped>

input[type="checkbox"] {
  transform: scale(2) !important;
}

</style>
