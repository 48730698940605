<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Country</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newCountryModal"
                          data-backdrop="static"
                          data-keyboard="false"
                          @click="resetStatus"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Country
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search country name"
                v-model="countryFormParam.searchValue"
                @keypress.enter="searchCountry">

              <button class="btn btn-sm pmc-btn-prime" @click="searchCountry">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- status -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm"
                        v-model="countryFormParam.status"
                        @change="on_change_status">
                  <option :value="null">--select--</option>
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.placeholder }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="countryFormParam.count"
                        @change="on_change_count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>


        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="countryPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="4%">CODE</th>
                <th scope="row" class="align-middle" width="7%">NAME</th>
                <th scope="row" class="align-middle" width="7%">NATIONALITY</th>
                <th scope="row" class="align-middle" width="7%">NATIONALITY CODE</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>AT</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>AT</th>
                <th scope="row" class="align-middle" width="5%">STATUS</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-if = "countries.length === 0">
                <td colspan="10">No Results</td>
              </tr>
              <tr v-else v-for="(country, index) in countries" :key="country.id">
                <td>{{ index + countryPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ country.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ country.name }}</td>
                <td scope="col" class="p-0 text-center">{{ country.natl }}</td>
                <td scope="col" class="p-0 text-center">{{ country.natl_code }}</td>
                <td scope="col" class="p-0 text-center">{{ country.updated_by }}</td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ country.created_at }}
                </td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ country.updated_at }}
                </td>
                <td scope="col" class="p-0 text-center">
                  {{ country.status == 1 ? 'ACTIVE' : 'INACTIVE' }}
                </td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewCountryModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="countryViewtoCountryData = country">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_country(country.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new country modal   -->
    <div class="modal fade" id="newCountryModal" tabindex="-1" role="dialog" aria-labelledby="newCountryModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newCountryModalLabel">NEW COUNTRY</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newCountry.code"
                    />
                  </div>
                </div>

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newCountry.name"
                    />
                  </div>
                </div>

                <!-- new  nationality-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NATIONALITY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newCountry.natl"
                    />
                  </div>
                </div>

                <!-- new  nationality code-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NATIONALITY CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newCountry.natl_code"
                    />
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newCountry.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view country   -->
    <div class="modal fade" id="viewCountryModal" tabindex="-1" role="dialog" aria-labelledby="viewCountryModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewCountryModalLabel">COUNTRY</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="countryData.code"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="countryData.name"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- nationality -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NATIONALITY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="countryData.natl"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- nationality code -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NATIONALITY CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="countryData.natl_code"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           v-model="countryData.status"
                           class="form-check form-check-inline ml-2 mt-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CREATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           v-model="countryData.created_by"
                           class="form-control form-control-plaintext"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">UPDATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           v-model="countryData.updated_by"
                           class="form-control form-control-plaintext"
                           placeholder="---"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit">
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel">
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      countryViewtoCountryData: {},
      countryData: {},
      newCountry: {
        status: 1,
      },
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,

      pageLastLeft: 1,

    };
  },


  methods: {
    ...mapActions([
      'set_countries',
      'create_country',
      'update_country',
      'delete_country',
      'null_countries',
    ]),
    resetStatus(){
      this.newCountry.status = 1;
    },

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Country', 'question')
      if (await __create) {

        let params = {
          code: this.newCountry.code ?? null,
          name: this.newCountry.name, // this is required
          natl: this.newCountry.natl ?? null,
          natl_code: this.newCountry.natl_code ?? null,
          address: this.newCountry.address ?? null,
          status: this.newCountry.status ? 1 : 0,
        };

        let newCountry = await this.create_country(params);
        if (newCountry) {
          AlertService.successAlertService('Create country record successful', 'Create country');
          this.hideModal('newCountryModal')
          this.newCountry = {};
          this.set_countries();
        }
      }
      this.newCountry.status = 1;
    },

    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update country', 'question');
      if (await __update) {
        let params = {
          id: this.countryData.id,
          code: this.countryData.code ?? null,
          name: this.countryData.name,
          natl: this.countryData.natl ?? null,
          natl_code: this.countryData.natl_code ?? null,
          status: this.countryData.status ? 1 : 0,

        };

        let updatedCountry = await this.update_country(params);
        if (updatedCountry) {
          AlertService.successAlertService("Updated successfully", 'Update country');
          this.hideModal('viewCountryModal')
          this.isEditable = false
        }
      }
    },

    set_this_page(e) {
      this.countryFormParam.searchValue = ''
      this.countryPagination.currentPage = e
      this.countryFormParam.page = e
      this.pageLastLeft = e
      this.set_countries(this.countryFormParam)

      // this.countryFormParam.currentPage = e;
      // this.countryFormParam.page = e;
      // this.set_countries(this.countryFormParam);
    },

    searchCountry() {
      if (this.countryFormParam.searchValue === '') {
        this.countryFormParam.page = this.pageLastLeft
        this.set_countries(this.countryFormParam)
      }

      if (this.countryFormParam.searchValue) {
        this.countryFormParam.page = 1
      }

      this.set_countries(this.countryFormParam)

      // this.countryFormParam.page = 1;
      // this.set_countries(this.countryFormParam);
    },

    on_change_status(e) {
      this.countryFormParam.page = 1
      this.countryFormParam.status = e.target.value;
      this.set_countries(this.countryFormParam);
    },

    on_change_count(e) {
      this.countryFormParam.page = 1
      this.countryFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.countryFormParam.count = e.target.value
      this.set_countries(this.countryFormParam)

      // this.countryFormParam.count = e.target.value;
      // this.set_countries(this.countryFormParam);
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel(e) {
      this.isEditable = false
      this.countryData = Object.assign({}, this.countryViewtoCountryData);
    },


  },


  /**  computed  **/
  computed: {
    ...mapGetters([
      'isReloading',
      'countries',
      'countryFormParam',
      'countryPagination',
    ]),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_countries(this.countryFormParam);
  },

  /** watch **/
  watch: {
    'countryViewtoCountryData'() {
      this.countryData = Object.assign({}, this.countryViewtoCountryData);
    },
  },

  /** destroyed **/
  destroyed() {
    this.null_countries()
  },


}
</script>

<style scoped>

</style>
