<template>
  <div>
     <div class="container-fluid">
       <div class="row">
        <div class="col-md-12 py-3">
          <div class="card">
            <div class="card-header text-left">
              <div class="row align-middle">
                <div class="col-md-2">
                  <div class="text-left">
                    <span style="font-size: 20px;" class="font-weight-bold">Employee Details</span>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="text-left">
                    <button v-show="!isEditable" type="button" class="btn btn-sm btn-secondary" @click="goBack">
                      <font-awesome-icon icon="undo"/>
                      <span class="ml-1 small">GO BACK</span>
                    </button>

                    <button v-show="!isEditable" class="ml-2 btn btn-sm pmc-bg-warning" @click="set_update">
                      <font-awesome-icon icon="edit"/>
                      <span class="ml-1 small">EDIT</span>
                    </button>

                    <button v-show="isEditable" class="ml-2 btn btn-sm btn-success" @click="update">
                      <font-awesome-icon icon="save"/>
                      <span class="ml-1 small">SAVE</span>
                    </button>

                    <button v-show="isEditable" class="ml-2 btn btn-sm btn-danger" @click="set_cancel">
                      <font-awesome-icon icon="times"/>
                      <span class="ml-1 small">CANCEL</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <div id="crew-img-container">
                  <img id="crew-img" :src="employeeImage" alt="crewImage" class="img-fluid">
                  <div class="text-center mt-1">
                    <div role="group" aria-label="Basic example" class="btn-group-sm">
                      <button type="button"
                              class="btn btn-lg border-0 mt-1 text-left"
                              data-toggle="modal"
                              data-target="#uploadImageModal"
                              data-backdrop="static"
                              data-keyboard="false">
                        <font-awesome-icon icon="upload"/>
                        {{employee.image ? 'UPDATE' : 'UPLOAD'}}
                      </button>

                      <button type="button"
                              class="btn btn-lg  btn-outline-danger border-0 text-left mt-1 ml-4"
                              @click="delete_employee_image(employee.id)">
                        <font-awesome-icon icon="trash"/>
                        DELETE
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4 text-left">
                <!-- last name               -->
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">LAST NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.last_name"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- first name               -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">FIRST NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.first_name"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- middle name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">MIDDLE NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.middle_name"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- suffix name               -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">SUFFIX NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.suffix_name"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- suffix name               -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">E NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.e_name"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- signature name               -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">SIGNATURE NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.signatory_name"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- birthdate -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">BIRTHDATE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="date"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.birthdate"
                           placeholder="---"
                    />
                  </div>
                </div>
              </div>


              <div class="col-md-3 text-left">
                <!-- gender -->
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">GENDER</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="gender"
                           v-if="!isEditable"
                           placeholder="---"
                    />
                    <select name="empGender"
                            id="employee-gender"
                            class="custom-select custom-select-sm"
                            v-focus="isEditable"
                            v-show="isEditable"
                            v-model="employee.gender_id">
                      <option :value="null">--select--</option>
                      <option :value="val.id" v-for="(val,index) in objGenders" :key="val.id">{{val.name}}</option>
                    </select>
                  </div>
                </div>


                <!-- mobile 1 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">MOBILE #1</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.mobile_no1"
                           placeholder="---"
                           :maxlength="inputMaxLength"
                    />
                  </div>
                </div>


                <!-- mobile 2 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">MOBILE #2</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.mobile_no2"
                           placeholder="---"
                           :maxlength="inputMaxLength"
                    />
                  </div>
                </div>

                <!-- LANDLINE 1 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">LANDLINE #1</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.land_line1"
                           placeholder="---"
                           :maxlength="inputMaxLength"
                    />
                  </div>
                </div>

                <!-- LANDLINE 2 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">LANDLINE #2</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.land_line2"
                           placeholder="---"
                           :maxlength="inputMaxLength"
                    />
                  </div>
                </div>


                <!-- email 1 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">EMAIL #1</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="email"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.email1"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- email 2 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">EMAIL #2</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="email"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.email2"
                           placeholder="---"
                    />
                  </div>
                </div>
              </div>


              <div class="col-md-3 text-left">
              <!-- sss no -->
                <div class="form-group row ">
                  <label class="col-sm-4 col-form-label">SSS #NO.</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.sss_no"
                           placeholder="---"
                    />
                  </div>
                </div>

              <!-- phil health -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">PHIL HEALTH</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.phil_health_no"
                           placeholder="---"
                    />
                  </div>
                </div>

              <!-- pag ibig -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">PAG-IBIG NO#.</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.pag_ibig_no"
                           placeholder="---"
                    />
                  </div>
                </div>

              <!-- civil status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">CIVIL STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input v-if="!isEditable"
                           type="text"
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="civilStatus"
                    />
                    <select name="empCivilStatus"
                            id="employee-civil-status"
                            class="custom-select custom-select"
                            v-focus="isEditable"
                            v-model="employee.civil_status_id"
                            v-show="isEditable"
                    >
                      <option :value="null">--select--</option>
                      <option :value="val.id" v-for="(val,index) in objCivilStatus" :key="val.id">{{val.name}}</option>
                    </select>
                  </div>
                </div>

                <!-- date hired -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">DATE HIRED</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="date"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.date_hired"
                           placeholder="---"
                    />
                  </div>
                </div>

              <!-- date separated -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">SEPARATED</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="date"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.date_separated"
                           placeholder="---"
                    />
                  </div>
                </div>

              <!-- status -->
                <div class="form-group row mt-3">
                  <label class="col-sm-4 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="checkbox"
                           class="ml-2 form-check form-check-inline"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.status"
                    />
                    <span>{{employee.status ? 'ACTIVE' : 'INACTIVE'}}</span>
                  </div>
                </div>

              </div>

            </div>

            <hr>

            <div class="row py-2">
              <div class="col-md-2"></div>

              <div class="col-md-4 text-left">

                <!--Position  -->
                <div class="form-group row ">
                  <label class="col-sm-4 col-form-label">POSITION</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input v-if="!isEditable"
                           type="text"
                           class="form-control form-control-plaintext form-control-sm"
                           disabled
                           v-model="position"
                           placeholder="---"
                    />
                    <select v-show="isEditable"
                            v-model="employee.position_id"
                            class="custom-select custom-select-sm"
                            name="empPosition"
                            id="emp-position">
                      <option :value="null">-- select --</option>
                      <option :value="val.id" v-for="(val,index) in objPositions" :key="index">{{val.name}}</option>
                    </select>
                  </div>
                </div>

                <!-- department  -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">DEPARTMENT</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input v-if="!isEditable"
                           type="text"
                           class="form-control form-control-plaintext form-control-sm"
                           disabled
                           v-model="department"
                           placeholder="---"
                    />
                    <select v-show="isEditable"
                            v-model="employee.department_id"
                            class="custom-select custom-select-sm"
                            name="empDepartment"
                            id="emp-department">
                      <option :value="null">-- select --</option>
                      <option :value="val.id" v-for="(val,index) in objDepartments" :key="index">{{val.name}}</option>
                    </select>
                  </div>
                </div>

                <!-- company  -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">COMPANY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-plaintext form-control-sm"
                           disabled
                           v-model="company"
                           v-if="!isEditable"
                           placeholder="---"
                    />
                    <select class="custom-select custom-select-sm"
                            v-show="isEditable"
                            v-model="employee.company_id"
                            name="empCompany"
                            id="employee-company">
                      <option :value="null">--select--</option>
                      <option class="text-left small" :value="val.id" v-for="(val,index) in objCompanies" :key="val.id">{{val.name}}</option>
                    </select>
                  </div>
                </div>

                <!-- created at -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">CREATED AT</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-plaintext form-control-sm"
                           disabled
                           v-model="employee.created_at"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- updated at -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">UPDATED AT</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-plaintext form-control-sm"
                           disabled
                           v-model="employee.updated_at"
                           placeholder="---"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-3 text-left">
                <!-- username -->
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">USERNAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control"
                           :class="{'form-control-sm' : isEditable,'form-control-plaintext form-control-sm':!isEditable}"
                           :disabled="!isEditable"
                           v-focus="isEditable"
                           v-model="employee.username"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- password remaining days -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">PASSWORD <br><small class="font-weight-bold">REMAINING DAYS</small></label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-plaintext form-control-sm"
                           disabled
                           v-model="employee.pw_remaining_days+' days'"
                           placeholder="---"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label ">
                    <span class="font-weight-bold small">RESET PASSWORD</span>
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
<!--                    <button class="btn btn-sm btn-secondary" @click="reset_employee_password(employee)">-->
<!--                      <font-awesome-icon icon="user-lock"/>-->
<!--                      <small class="ml-1">DEFAULT PASSWORD</small>-->
<!--                    </button>-->
                      <button type="button"
                              id="reset-btn"
                              class="btn btn-primary"
                              data-toggle="modal"
                              data-target="#reset-password-modal"
                              data-backdrop="static"
                              data-keyboard="false">
                          <font-awesome-icon icon="user-lock"/>
                          Reset
                      </button>
                  </div>
                </div>
              </div>

              <div class="col-md-3 text-left">
                  <!-- password updated at -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-4 col-form-label">PASSWORD STATUS<br><small class="font-weight-bold">activation via user</small></label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                        {{employee.pw_updated_at ? 'ACTIVATED' : 'NOT ACTIVATED'}}
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
       </div>

     <!--        Reset Password Modal -->
     <div class="modal fade" id="reset-password-modal" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog" role="document">
             <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title" id="exampleModalLabel">Reset Password</h5>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                     </button>
                 </div>
                 <div class="modal-body">
                     <div class="form-group row">
                         <label for="default-password" class="col-sm-2 col-form-label">Default</label>
                         <div class="col-sm-10">
                             <input type="text" disabled  class="form-control" id="default-password" placeholder="pmc@staff">
                         </div>
                     </div>
                 </div>
                 <div class="modal-footer">
                     <button type="button" class="btn btn-primary" @click="reset_employee_password(employee)">Reset Password</button>
                 </div>
             </div>
         </div>
     </div>


         <!-- Upload Image Modal -->
       <div class="modal fade" id="uploadImageModal" tabindex="-1" role="dialog" aria-labelledby="uploadImageModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
           <div class="modal-content">
             <div class="modal-header">
               <h5 class="modal-title" id="uploadImageModalLabel">Upload Image</h5>
             </div>

             <div class="modal-body">
               <div class="form-group row ">
                 <label class="col-sm-4 col-form-label">Upload Image</label>
                 <span class="col-form-label">:</span>
                 <div class="col-sm-7">
                   <input type="file"
                          id="employee-image"
                          class=" form-control-file"
                          @change="on_change_upload"
                          placeholder="---"
                   />
                 </div>
               </div>
             </div>
             <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close_upload_modal">Close</button>
               <button type="button" class="btn btn-primary" @click="upload_image">Save changes</button>
             </div>
           </div>
         </div>
       </div>
     </div>
  </div>
</template>

<script>
import { AppMixins } from '../../../../mixins/app.mixins'
import { AlertMixins } from '../../../../mixins/alert.mixins'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ViewEmployee',

  /** mixins **/
  mixins: [AppMixins,AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data () {
    return {
      employeeId : parseInt(this.$route.params.employeeId),
      isEditable :false,
      inputMaxLength: 15
    }
  },

  /** methods**/
  methods: {
    ...mapActions([
        'set_employee',
        'null_obj_departments',
        'null_obj_genders',
        'null_obj_companies',
        'null_obj_civilStatus',
        'null_employee',
        'upload_employee_image',
        'delete_employee_image',
        'update_employee',
        'reset_employee_password',
    ]),

  async upload_image () {
      if(await this.questionAlert("Do you want to upload thi image.",'Upload image','question')){
        let imageBlob = document.getElementById('employee-image').files[0];
        let formData = new FormData();
        formData.append('employee_id',this.employeeId)
        formData.append('file',imageBlob)
        const payLoad = {
          data : formData,
          employeeId : this.employeeId
        } ;
        await this.upload_employee_image(payLoad).then((r)=>{
          this.close_upload_modal();
          this.successAlert('Upload Successfully');
        });
      }
    },

    set_update(){
     this.isEditable = true;
    },

    set_cancel(){
      this.isEditable = false;
      this.set_employee(this.employeeId);
    },

    //update / save
   async update(){
      if(await this.questionAlert("Do you want to save changes.",'Update employee','question')){
        this.update_employee(this.employee).then((r)=>{
          if(r){
            this.successAlert('Employee record updated successfully','Update employee');
            this.isEditable = false;
          }
        });
      }
    },

    cancel(){
      this.isEditable = false;
    },

    close_upload_modal(){
      this.hideModal('uploadImageModal');
      $(`#employee-image`).val("");
    },

    on_change_upload(){
    },
  },

  /**  computed  **/
  computed: {
    ...mapGetters([
        'employee',
        'objDepartments',
        'objGenders',
        'objCompanies',
        'objCivilStatus',
        'objPositions',
    ]),

    department : {
      get(){
        let __depId = this.employee.department_id;
        let __department = this.objDepartments.find((val)=> val.id === __depId);
        return __department ? __department.name : null;
      },
      set(val){
        this.employee.department_id = val ? val : null;
      }
    },

    position : {
      get(){
        let __depId = this.employee.position_id;
        let __position = this.objPositions.find((val)=> val.id === __depId);
        return __position ? __position.name : null;
      },
      set(val){
        this.employee.department_id = val ? val : null;
      }
    },

    gender: {
      get(){
        let __genId = this.employee.gender_id;
        let __gender = this.objGenders.find((val)=> val.id === __genId);
        return __gender ? __gender.name : null;
      },
      set(val){
        this.employee.gender_id = val ? val : null;
      }
    },

    company : {
      get(){
        let __comId = this.employee.company_id;
        let __company = this.objCompanies.find((val)=> val.id === __comId);
        return __company ? __company.name : null;
      },
      set(val){
        this.employee.company_id = val ? val : null;
      }
    },

    civilStatus : {
      get() {
        let __civId = this.employee.civil_status_id;
        let __civilStatus = this.objCivilStatus.find((val) => val.id === __civId);
        return __civilStatus ? __civilStatus.name : null;
      },
      set(val){
        this.employee.civil_status_id = val ? val : null;
      }
    },

    employeeImage:{
      get(){
        return this.employee.image ? this.employee.image : this.noPhoto;
      },
    }
  },

  /** mounted **/
  mounted () {
  },

  /** created **/
 async created () {
    await this.set_employee(this.employeeId);
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed () {},
}
</script>

<style scoped>

#crew-img-container {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

#crew-img {
  width: 200px;
  height: 200px;
}

.crew-img {
  width: 200px;
  height: 200px;
}

input[type="checkbox"] {
  transform: scale(2) !important;
}

#reset-btn {
  margin-top: -2px;
}
</style>
