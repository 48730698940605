import {PMC} from '@/config/constants.config';
import axios from 'axios';
import {UtilityService} from '@/service/utility.service';
import {AlertService} from '@/service/alert.service';

const initStateCcManipulation = () => {
  return {
    crewChangeMemoDetails: {},
    objCcMemoBatches: [],
    objSignOffReasonKeys: [],
  };
};

const state = {
  ...initStateCcManipulation(),
};

const mutations = {
  set_crew_change_memo_details(state, payload) {
    console.log('set_crew_change_memo_details', payload);
    state.crewChangeMemoDetails = payload;
    localStorage.setItem('defaultCcMemoDetails', JSON.stringify(payload));
  },

  set_obj_cc_memo_batches(state, payload) {
    state.objCcMemoBatches = payload;
  },

  reset_state_cc_manipulation(state) {
    state.crewChangeMemoDetails = {};
    state.objCcMemoBatches = [];
    state.objSignOffReasonKeys = [];
  },

  //reset_cc_memo_details
  revert_details(state, payload) {
    let defaultCcMemoDetails = JSON.parse(
        localStorage.getItem('defaultCcMemoDetails'));
    let defaultCrew = payload.type === 1 ?
        defaultCcMemoDetails.signOnCrews :
        defaultCcMemoDetails.signOffCrews;
    let currentCrew = payload.type === 1 ?
        state.crewChangeMemoDetails.signOnCrews :
        state.crewChangeMemoDetails.signOffCrews;
    let findDefCrew = defaultCrew.find((val, index) => {
      return val.ccId === payload.ccId;
    });
    let findCurCrew = currentCrew.find((val, index) => {
      return val.ccId === payload.ccId;
    });
    Object.assign(findCurCrew, findDefCrew);
  },

  reset_cc_memo_details(state) {
    state.crewChangeMemoDetails = {}
  }
};

const actions = {
  get_crew_change_memo_details({commit}, params) {
    return axios.get(PMC.GET_CREW_CHANGE_MEMO_DETAILS, {params}).then((r) => {
      // commit('set_crew_change_memo_details')
      commit('set_crew_change_memo_details', r.data.ccMemoDetails);
      commit('set_obj_cc_memo_batches', r.data.objCcMemoBatches);
      return true;

    }).catch((er) => {
      return false;

    });
  },

  get_cc_manipulate_keys({commit}) {
    return axios.get(PMC.GET_CC_MANIPULATE_KEYS).then((r) => {
      commit('set_obj_cc_memo_batches', r.data.objCcMemoBatches);
      commit('set_obj_sign_off_reasons', r.data.objSignOffReasonKeys);
      commit('set_obj_countries', r.data.objCountryKeys);
      commit('set_obj_ports', r.data.objPortKeys);
      return true;

    }).catch((er) => {
      return false;

    });
  },

  apply_changes({commit}, params) {
    return axios.post(PMC.UPDATE_CC_MEMO_BATCH, params).then((r) => {
      commit('set_crew_change_memo_details', r.data); //
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService('Cant establish server connection');
      } else {
        UtilityService.generateResponseMessage(er, 'Applying Changes');
      }
      return false;
    });
  },

  revert_details({commit}, params) {
    commit('revert_details', params);
  },

  reset_state_cc_manipulation({commit}) {
    commit('reset_state_cc_manipulation');
  },

  // Todo call end point for seArching cc memo no .
  search_cc_memo_no({commit}, params) {
    return axios.get(PMC.SEARCH_CC_MEMO_NO, {params}).then((r) => {
      commit('set_crew_change_memo_details', r.data.ccMemoDetails);
      return true;
    }).catch((er) => {
      return false;
    });
  },

  async generate_agent_list({commit}, params) {
    return await axios.get(PMC.GENERATE_AGENT_LIST, {params}).then((r) => {
      console.log('generate_agent_list', r.data);
      return r.data;
    }).finally(() => {
      console.log('get agent finally');
    }).catch((er) => {
      console.log(er);
      return false;
    });
  },

  /**
   * @deprecated
   */
  async generate_crew_check_list({commit}, params) {
    return await axios.get(PMC.GENERATE_CREW_CHECKLIST, {params}).then((r) => {
      return r.data;
    }).catch((er) => {
      return false;
    });
  },

  async actualize_onboarding({commit}, params) {
    return await axios.post(PMC.ACTUALIZE_ONBOARDING, params).then((r) => {
      console.log('actualizeOnboarding', r.data);
      return true;
    }).catch((er) => {
      return false;
    });
  },

  reset_cc_memo_details({commit}){
    commit('reset_cc_memo_details');
  },

  update_cc_memo_details({commit}, payload) {
    console.log('update_cc_memo_details:actions',payload);
    return axios.post(PMC.UPDATE_CC_MEMO_DETAILS, payload).then((r) => {
      //commit('set_crew_change_memo_details', r.data.ccMemoDetails);
      return true;
    }).catch((er) => {
      console.log(er);
      return false;
    });
  },
};

const getters = {
  crewChangeMemoDetails: state => state.crewChangeMemoDetails,
  objCcMemoBatches: state => UtilityService.capitalizeProperties(
      state.objCcMemoBatches),
};

export default {
  state,
  actions,
  mutations,
  getters,
};
