<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Manpower Pool List (INACTIVE)</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.rankId"
                                @change="selectReportType"
                                required>
                          <option :value="null">All</option>
                          <option v-for="(objRank, index) in objRanks" :value="objRank.id">{{ objRank.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">YEAR</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.year"
                                @change="selectReportType"
                                required>
                          <option :value="null">All</option>
                          <option v-for="(year) in years" :value="year">{{ year }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">REASON</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.reasonId"
                                @change="selectReportType"
                                required>
                          <option :value="null">All</option>
                          <option :value="reason.id" v-for="(reason,index) in reasons">
                            {{ reason.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="getManpowerPoolListInactive">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          @click="printReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
            <div class="row ml-2">
              <div class="">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <span class="col-form-label">
                        <label for="list-count" class="col-form-label">FILTER BY : <b class="pl-2"
                                                                                      style="font-size: 1.1em;">{{
                            filterBy
                          }}</b></label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Seafarer Name</th>
                    <th style="width: 5%">Rank</th>
                    <th>Last Vessel</th>
                    <th>EOC</th>
                    <th>Sign Off Date</th>
                    <th>Reason</th>
                    <th>Remarks</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="pl-2">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>
                          </td>
                          <td class="text-center align-middle pl-2"> {{ crew.rank_alias }}</td>
                          <td>{{ crew.vessel }}</td>
                          <td>{{ crew.eoc_date }}</td>
                          <td>{{ crew.signoff_date }}</td>
                          <td>{{ crew.withdrawal_reason }}</td>
                          <td>{{ crew.remarks }}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {CommonMixins} from "@/mixins/common.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import axios from "axios";
import {UtilityService} from "@/service/utility.service";
import {PMC} from "@/config/constants.config";
import Swal from "sweetalert2";
import {AlertService} from "@/service/alert.service";
import {FileService} from "@/service/file.service";

export default {
  name: "InactiveList",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      reportParam: {
        rankId: null,
        isPDF: false,
        rankName: null,
        reasonId: null,
        year: null,
        filteredBy: null
      },
      hasData: false,
      ranks: null,
      rankSelected: null,
      filters: null,
      isLoading: false,
      crewData: null,
      reasons: null,
      startYear: 1997,
      years: [],
    }

  },


  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
      'getVaccineBrandKeys',
    ]),

    selectReportType() {
      this.hasData = false;

    },

    generateYears() {
      const currentYear = new Date().getFullYear();
      for (let year = this.startYear; year <= currentYear; year++) {
        this.years.push(year);
      }
    },

    async getManpowerPoolListInactive() {
      this.reportParam.isPDF = false;
      this.hasData = false;
      let params = this.reportParam;
      this.isLoading = true;
      return await axios.post(PMC.GET_CREW_INACTIVE_LIST, params).then(async r => {
        this.isLoading = false;
        if (Object.keys(r.data).length > 0) {
          this.hasData = true;
          this.crewData = r.data;
        } else {
          this.hasData = false;
          this.crewData = null;
        }
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate inactive crew list')
        return false
      });
    },

    async printManpowerPoolListInactive() {
      this.reportParam.filteredBy = this.filters;
      this.reportParam.isPDF = true;
      let params = this.reportParam;
      return await axios.post(PMC.GET_CREW_INACTIVE_LIST, params).then(async r => {
        this.isLoading = false;
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Covid Vaccine Summary')
        return false
      });
    },

    async printReport() {
      AlertService.loadingAlert('Please wait ...', 'Generating PDF')

      let pdfData = await this.printManpowerPoolListInactive();
      if (pdfData) {
        await this.pdfViewer('File', pdfData);
        Swal.close();
      } else {
        Swal.close();
      }
    },

    async getWithdrawalReason() {
      return await axios.get(PMC.GET_WITHDRAWAL_REASON_KEYS).then(async r => {
        this.reasons = UtilityService.capitalizeProperties(r.data);
        return r.data;
      }).catch((err) => {
        UtilityService.generateResponseMessage(err, 'Getting withdrawal reason keys')
        return false;
      })
    },

    getRankName(rankId) {
      let rankName = "";
      const rankList = this.objRanks
      for (let c in rankList) {
        if (parseInt(rankList[c].id) === parseInt(rankId)) {
          rankName = rankList[c].name;
        }
      }
      return rankName;
    },

    getReasonName(reasonId) {
      let reasonName = "";
      const reasonList = this.reasons
      for (let c in reasonList) {
        if (parseInt(reasonList[c].id) === parseInt(reasonId)) {
          reasonName = reasonList[c].name;
        }
      }
      return reasonName;
    }

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objRanks',
        'objWithdrawalReasonKeys'
      ]
    ),
    filterBy() {
      const filters = [];


      if (this.reportParam.rankId) {
        // filters.push("RANK");
        filters.push(this.getRankName(this.reportParam.rankId));
      } else {
        filters.push("ALL");
      }

      // if (this.reportParam.year) {
      //   filters.push("YEAR");
      // }

      if (this.reportParam.reasonId) {
        filters.push(this.getReasonName(this.reportParam.reasonId))
        // filters.push("REASON");
      }

      this.filters = filters.length > 0 ? filters.join(' / ') : 'All';

      return this.filters;

    },

  },

  /** mounted **/
  mounted() {
    this.getWithdrawalReason();
    this.generateYears();
  },

  /** created **/
  async created() {
    this.getCrewComplimentPerVessel();
    this.$globalVariables = global_variables_config
    this.getWithdrawalReason();
  },


  beforeDestroy() {
  },
}
</script>

<style scoped>

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
  text-transform: uppercase;
}
</style>
