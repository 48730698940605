import axios from "axios";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";

const initState = () => {
  return {
    ccmMasterChecklist: [],
    ccmFlagId: null,
    ccmFlagName: null,
    ccmVesselId: null,
    ccmVesselEcdisId: null,
  }
};

const state = {
  ...initState()
};

const mutations = {
  set_ccmMasterChecklist(state, params) {
    state.ccmMasterChecklist = params
  },

  set_ccmFlagId(state, params) {
    state.ccmFlagId = params
  },

  set_ccmFlagName(state, params) {
    state.ccmFlagName = params
  },

  set_ccmVesselId(state, params) {
    state.ccmVesselId = params
  },

  set_ccmVesselEcdisId(state, params) {
    state.ccmVesselEcdisId = params
  },
};


const actions = {

  async getCcmMasterChecklistDetails({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GET_CCM_MASTER_CHECKLIST_DETAILS, {params}).then((r) => {
      commit('set_ccmMasterChecklist', r.data.ccmDocDetails)
      console.log(r.data.ccmDocDetails, 'r.data.ccmDocDetails')
      commit('set_ccmFlagId', r.data.ccmFlagId)
      commit('set_ccmFlagName', r.data.ccmFlagName)
      commit('set_ccmVesselId', r.data.ccmVesselId)
      commit('set_ccmVesselEcdisId', r.data.ccmVesselEcdisId)
      // console.log(r.data.ccmFlagName, 'docDetails')
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get ccm master checklist')
      return false

    })
  },

  async generateCcmMasterChecklist({commit}, params) {
    return await axios.post(PMC.GENERATE_CCM_MASTER_CHECKLIST, params).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate ccm masterlist')
      return false
    });
  },
};


const getters = {
  ccmMasterChecklist: state => UtilityService.capitalizeProperties(state.ccmMasterChecklist),
  ccmFlagId: state => state.ccmFlagId,
  ccmFlagName: state => state.ccmFlagName,
  ccmVesselId: state => state.ccmVesselId,
  ccmVesselEcdisId: state => state.ccmVesselEcdisId,
};


/** export **/
export default {state, actions, mutations, getters};
