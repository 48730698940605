import utilityService, {UtilityService} from "@/service/utility.service";
import {PMC} from "@/config/constants.config";
import axios from "axios";
import {AlertService} from "@/service/alert.service";
import {commit} from "lodash/seq";
import {FileService} from "@/service/file.service";

const initState = () => {
  return {
    vesselDetails: {},
    crewChangeMemo: {},
    isCcMemo: false,
    ccMemoDetailFile: null,
  }
};

const initBatchListState = () => {
  return {
    isBatchListing: false,

    noBatchSignOnList: [], //list of all crew change that has no batch .
    hasBatchSignOnList: [],

    removeInBatch: [],
    addInBatch: [],

  }
}

const CancelToken = axios.CancelToken;

const state = {
  ...initState(),
  ...initBatchListState()
};

const mutations = {
  set_crew_change_memo(state, payload) {
    state.crewChangeMemo = payload;
  },

  reset_cc_memo_state(state) {
    Object.assign(state, initState())
  },

  set_is_cc_memo(state, payload) {
    state.isCcMemo = payload;
  },

  set_null_cc_memo(state) { //
    state.crewChangeMemo = {}
  },


  set_no_batch_sign_on_list(state, payload) {
    state.noBatchSignOnList = payload;
  },

  set_has_batch_sign_on_list(state, payload) {
    state.hasBatchSignOnList = payload;
  },

  //isBatchListing
  set_is_batch_listing(state, payload = true) {
    state.isBatchListing = payload;
  },


  //addInBatch
  push_add_in_batch(state, payload) {
    state.addInBatch.push(payload)
  },

  pop_add_in_batch(state, payload) {
    let index = state.addInBatch.findIndex((val, index) => {
      return val.ccrId === payload.ccrId;
    });

    state.addInBatch.splice(index, 1);
  },


  //removeInBatch
  push_remove_in_batch(state, payload) {
    state.removeInBatch.push(payload)
  },

  pop_remove_in_batch(state, payload) {
    let index = state.removeInBatch.findIndex((val, index) => {
      return val.ccrId === payload.ccrId;
    })
    state.removeInBatch.splice(index, 1);
  },

  set_null_add_in_batch(state) {
    state.addInbatch = [];
  },

  set_null_remove_in_batch(state) {
    state.removeInBatch = [];
  },

  /*confirm_add_batch_memo(state,payload){
    state.addInBatch.forEach((val,index)=>{
      let ccrId = val.ccrId;
      let index = state.noBatchSignOnList.findIndex((val,index)=>{
        return val.ccrId === ccrId;
      })
      state.noBatchSignOnList.splice(index,1);
    })
  },*/

  // confirm_delete_batch_memo(state, payload) {
  //
  // },

  reset_null_batch_list_state(state) {
    Object.assign(state, initBatchListState())
  },

  set_cc_memo_detail_file(state, payload) {
    state.ccMemoDetailFile = payload;
  },

  //todo mutation for revision up
  set_revision_up(state,payload){
    state.crewChangeMemo.ccMemoRevisionNo = payload.revision
  },

  //todo mutation for revision down
  set_revision_down(state,payload){
    state.crewChangeMemo.ccMemoRevisionNo = payload.revision
  },

  //todo mutation for approve
  set_approved(state,payload){
    state.crewChangeMemo.ccMemoIsApproved = payload.approved
  },

  //todo mutation for disapprove
  set_dis_approve(state,payload){
    state.crewChangeMemo.ccMemoIsApproved = payload.approved; //todo get the is_approved property only
  }
};

const actions = {
  /** start crew change memo details function methods. **/
  generate_cc_memo_details({commit}, params) {
    let cancel;
    return axios.post(PMC.GENERATE_CCM_DETAILS, params, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    }).then((r) => {
      commit('set_crew_change_memo', r.data)
      return true;

    }).catch((er) => {
      cancel();
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Generate CC MEMO Details')
      }
      return false;
    })
  },

  async get_cc_memo_details({commit}, params) {
    commit('set_is_loading', true)
    commit('set_is_cc_memo', true)
    return await axios.get(PMC.GET_CC_MEMO_DETAILS, {params}).then((r) => {
      commit('set_is_cc_memo', false)
      commit('set_crew_change_memo', r.data)
      commit('set_cc_memo_remarks', r.data.batchingRemarks)
      commit('set_is_loading', false)
      return true;

    }).catch((er) => {
      commit('set_is_cc_memo', false)
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get CC Memo Details')
      }
      commit('set_is_loading', false)
      return false;
    })
  },

  create_cc_memo_details({commit}, params) {
    let cancel;
    commit('set_is_cc_memo');
    return axios.post(PMC.CREATE_CC_MEMO_DETAILS, params, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      })
    }).then((r) => {
      commit('set_is_cc_memo', false);
      commit('set_crew_change_memo', r.data)
      return true;

    }).catch((er) => {
      cancel();
      commit('set_is_cc_memo', false);
      UtilityService.generateResponseMessage(er, 'Create Cc Memo Details')
      return false;

    })
  },


  // update_cc_memo_details(){
  //   return axios.post().then((r)=>{
  //     //todo update_cc_memo_details
  //     return true;
  //
  //   }).catch((er)=>{
  //     if(!e.response){
  //       AlertService.errorAlertService("Cant establish server connection");
  //     } else {
  //       UtilityService.generateResponseMessage(er, "Update Cc Memo Details")
  //     }
  //     return false;
  //   })
  // },

  /** reset cc memo details state **/
  reset_cc_memo_state({commit}) {
    commit('reset_cc_memo_state')
  },

  set_null_cc_memo({commit}) {
    commit('set_null_cc_memo')
  },

  async set_update_cc_memo({commit}, params) {
    commit('set_is_batch_listing')
    return await axios.get(PMC.SET_UPDATE_CC_MEMO, {params}).then((r) => {
      commit('set_no_batch_sign_on_list', r.data.noBatchSignOnList)
      commit('set_has_batch_sign_on_list', r.data.hasBatchSignOnList)
      commit('set_is_batch_listing', false)
      return true;
    }).catch((er) => {
      commit('set_is_batch_listing', false)
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, er)
      }
      return false;
    })
  },

  reset_null_batch_list_state({commit}) {
    commit('reset_null_batch_list_state')
  },

  add_in_batch({commit}, params) {
    if (params.type === 1) {
      commit('push_add_in_batch', params.obj)
    }

    if (params.type === 2) {
      commit('pop_add_in_batch', params.obj)
    }
  },

  remove_in_batch({commit}, params) {
    if (params.type === 1) {
      commit('push_remove_in_batch', params.obj)
    }

    if (params.type === 2) {
      commit('pop_remove_in_batch', params.obj)
    }
  },

  confirm_add_batch_memo({commit}, params) {
    return axios.post(PMC.ADD_CREW_FROM_MEMO, params).then((r) => {
      commit('set_no_batch_sign_on_list', r.data.noBatchSignOnList)
      commit('set_has_batch_sign_on_list', r.data.hasBatchSignOnList)
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Bad Request')
      }
      return false;
    })
  },

  confirm_delete_batch_memo({commit}, params) {
    return axios.post(PMC.DELETE_CREW_FROM_MEMO, params).then((r) => {
      commit('set_no_batch_sign_on_list', r.data.noBatchSignOnList)
      commit('set_has_batch_sign_on_list', r.data.hasBatchSignOnList)
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Bad Request')
      }
      return false;
    })
  },

  set_null_add_in_batch({commit}) {
    commit('set_null_add_in_batch')
  },

  set_null_remove_in_batch({commit}) {
    commit('set_null_remove_in_batch')
  },

  //preview_print_cc_memo
  preview_print_cc_memo({commit}, params) {
    return axios.post(PMC.PREVIEW_PRINT_CC_MEMO, params).then((r) => {
      commit('set_obj_employee', r.data.objEmployeeKeys)
      // localStorage.setItem("objEmp",JSON.stringify(r.data.objEmployeeKeys))
      // let objEmp = JSON.parse(localStorage.getItem("objEmp"))
      return r.data.domFile;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Bad Request')
      }
      return false;
    })
  },

  download_cc_memo({commit}, params) {
    return axios.post(PMC.PREVIEW_PRINT_CC_MEMO, params)
      .then((r) => {
        let fileUrl = UtilityService.base64FileToBlobUrl(r.data)
        let fileLink = document.createElement('a')
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", "cc_memo_details.pdf")
        document.body.appendChild(fileLink)
        fileLink.click()
        fileLink.remove();
        return true;
      }).catch((er) => {
        if (!er.response) {
          AlertService.errorAlertService("Cant establish server connection");
        } else {
          UtilityService.generateResponseMessage(er, 'Bad Request')
        }
        return false;
      })
  },

  revision_up_cc_memo({commit}, params) {
    return axios.get(`${PMC.REVISION_UP_CC_MEMO}/${params}`).then((r) => {
      console.log(r.data)
      commit('set_revision_up',r.data)
      return true;
    }).finally((r) => {
      //on finally
    }).catch((er) => {
      console.log(er);
      return false;
    })
  },

  revision_down_cc_memo({commit}, params) {
    return axios.get(`${PMC.REVISION_DOWN_CC_MEMO}/${params}`).then((r) => {
      console.log(r.data)
      commit('set_revision_down',r.data)
      return true;
    }).finally(() => {
      // on finally
    }).catch((er) => {
      console.log(er)
      return false
    })
  },
  approved_cc_memo({commit}, params) {
    return axios.get(`${PMC.APPROVED_CC_MEMO}/${params}`).then((r) => {
      commit('set_approved', r.data)
      console.log("approved_cc_memo:actions",r.data)
      return true;

    }).finally(() => {
      // on finally
    }).catch((er) => {
      console.log(er)
      return false;

    })
  },
  dis_approved_cc_memo({commit}, params) {
    return axios.get(`${PMC.DISAPPROVED_CC_MEMO}/${params}`).then((r) => {
      commit('set_dis_approve', r.data)
      console.log("dis_approved_cc_memo:actions",r.data)
      return true;
    }).finally(() => {
      // on finally
    }).catch((er) => {
      console.log(er);
      return false;
    })
  },


  async generateCcMemoCovidVaccineReport({commit}, params) {
    return await axios.post(PMC.GENERATE_CC_MEMO_COVID_VACCINE_REPORT, params).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate ccm covid vaccine report')
      return false
    });
  },


}

const getters = {
  /**
   * @author remove if the mechanics is stable upon testing,
   *
   */
  /*signOnCrews: state => UtilityService.capitalizeProperties(state.signOnCrews),
  signOffCrews: state => UtilityService.capitalizeProperties(state.signOffCrews),
  vesselDetails: state => UtilityService.capitalizeProperties(state.vesselDetails),*/
  // batchingRemarks: state => state.batchingRemarks,
  crewChangeMemo: state => UtilityService.capitalizeProperties(state.crewChangeMemo),
  isCcMemo: state => state.isCcMemo,
  isBatchListing: state => state.isBatchListing,
  noBatchSignOnList: state => UtilityService.capitalizeProperties(state.noBatchSignOnList), //list of all crew change that has no batch .
  hasBatchSignOnList: state => UtilityService.capitalizeProperties(state.hasBatchSignOnList),
  removeInBatch: state => state.removeInBatch,
  addInBatch: state => state.addInBatch,
  ccMemoDetailFile: state => state.ccMemoDetailFile,
};

export default {
  state,
  actions,
  mutations,
  getters
}
