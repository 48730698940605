/** state **/
import axios from 'axios'
import { UtilityService } from '@/service/utility.service'
import { AlertService } from '@/service/alert.service'
import { PMC } from '@/config/constants.config'

const state = {
  covidVaccines: [],
  covidVaccine: [],
  isExistsVaccine: null,

  newCovidVaccine: {
    vaccineId: null,
    crewId: null,
    inoculateDate: null,
    completionDate: null,
    isBooster: null,

    // expiry: null, optional
    remarks: null,

  },

  covidVaccineBlob: null,
  covidVaccineBlob_gte3mb: null,

  formCovidVaccineBlob: null,
  formCovidVaccineBlob_gte3mb: null,
}

/** mutations **/
const mutations = {

  set_covid_vaccines (state, params) {
    state.covidVaccines = params
  },

  set_isExists_vaccine (state, params) {
    state.isExistsVaccine = params
  },

  //todo
  set_covid_vaccine (state, param) {
    state.covidVaccine = param
  },

  //todo
  create_covid_vaccine (state, params) {
    state.newCovidVaccine = {
      vaccineId: null,
      crewId: null,
      inoculateDate: null,
      completionDate: null,
      isBooster: null,
      // expiry: null, optional
      remarks: null,
    }
    state.covidVaccines.unshift(params)
  },


  create_vaccine_covid (state) {
    state.newCovidVaccine = {};
  },

  update_covid_vaccine (state, params) {
    let covidVaccine = state.covidVaccines.find((val, index) => val.id === params.id)
    Object.assign(covidVaccine, params)
  },

  delete_covid_vaccine (state, param) {

    let index = state.covidVaccines.findIndex((val,index)=> val.id === param)
    state.covidVaccines.splice(index,1);

  },

  null_covid_vaccines (state) {
    state.covidVaccines = null
  },

  null_covid_vaccine (state) {
    state.covidVaccine = null
  },

  set_covid_vaccine_blob (state, param) {
    state.covidVaccineBlob = param
  },

  null_covid_vaccine_blob (state) {
    state.covidVaccineBlob = null
  },

  set_covid_vaccine_blob_gte3mb (state, param) {
    state.covidVaccineBlob_gte3mb = param
  },

  null_covid_vaccine_blob_gte3mb (state) {
    state.covidVaccineBlob_gte3mb = null
  },

  //formCovidVaccineBlob
  set_form_covid_vaccine_blob (state, param) {
    state.formCovidVaccineBlob = param
  },

  null_form_covid_vaccine_blob (state) {
    state.formCovidVaccineBlob = null
  },

  //formCovidVaccineBlob_gte3mb
  set_form_covid_vaccine_blob_get3mb (state, param) {
    state.formCovidVaccineBlob_gte3mb = param
  },

  null_form_covid_vaccine_blob_get3mb (state) {
    state.formCovidVaccineBlob_gte3mb = null
  },
}

/** actions **/
const actions = {

  async set_covid_vaccines ({ commit }, params) {
    return axios.get(PMC.COVID_VACCINE, {params}).then((r) => {
      commit('set_covid_vaccines', r.data.covidVaccines)
      commit('set_isExists_vaccine', r.data.isExistsVaccine)
      return true

    }).catch((er) => {

      UtilityService.generateResponseMessage(er, 'Get covid vaccine')
      return false
    })
  },

  set_covid_vaccine ({ commit }, param) {
    return axios.get(`${PMC.COVID_VACCINE}/${param}`).then((r) => {
      let file = r.data.docBlob
      commit('set_covid_vaccine', r.data.covidVaccine)
      if (file != null || file != undefined) {
        let parseBlob = UtilityService.base64ToBlobParser(r.data.docBlob)

        if (UtilityService.isGte2mb(parseBlob)) {
          commit('set_is_2mbUp', true)
          commit('set_covid_vaccine_blob_gte3mb', r.data.docBlob)

        } else {
          commit('set_is_2mbUp', false)
          commit('set_covid_vaccine_blob', r.data.docBlob)

        }
      }

      return true

    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Get vaccine details')
      return false

    })
  },

  async create_covid_vaccine ({ commit }, params) {
    let __confirmation = AlertService.questionAlertService('Are you sure you want to create new vaccine details', 'New vaccine details', 'question')

    if (await __confirmation) {
      return axios.post(`${PMC.COVID_VACCINE}`, params, {
        'Content-Type': 'multipart/form-data',
        maxContentLength: 10000000,
        maxBodyLength: 10000000,

      }).then((r) => {
        commit('create_covid_vaccine', r.data)
        commit('set_crew_covid_vaccine',r.data);
        commit('set_isExists_vaccine', r.data.isExistsVaccine)
        AlertService.successAlertService('Successfully created.', 'New covid vaccine records')
        return true

      }).catch((r) => {
        UtilityService.generateResponseMessage(r, 'New covid vaccine record.')
        return false

      })
    }
  },

 async update_covid_vaccine ({ commit }, params) {
      return axios.post(`${PMC.UPDATE_COVID_VACCINE}`,params,{
        'Content-Type': 'multipart/form-data',
        maxContentLength: 10000000,
        maxBodyLength: 10000000,
      }).then((r)=>{
        // commit('update_covid_vaccine',r.data); // does not return true for CrewChangeMemoCovid
        commit('set_crew_covid_vaccine',r.data);  //mutation from crew.module
        return true;

      }).catch((error)=>{
        UtilityService.generateResponseMessage(error,'Update vaccine details.');
        return false;

      })

  },

 async delete_covid_vaccine ({ commit }, param) {
    let __confirmation = AlertService.questionAlertService('Are you sure you want to delete this record.','Covid vaccine record','question');
    if(await __confirmation){
      return axios.delete(`${PMC.COVID_VACCINE}/${param}`).then((r)=>{
        commit('delete_covid_vaccine',r.data.id);
        AlertService.successAlertService('Record deleted successfully','Deleted');

        return true;

      }).catch((er)=>{
        UtilityService.generateResponseMessage(er,'Delete covid vaccine record');
        return false;

      })
    }
  },


  async delete_covid_vaccine_file_attachment ({ commit }, params) {
    let __confirmation = AlertService.questionAlertService('Are you sure you want to delete this attachment.','Covid Vaccine','question');
    if(await __confirmation){
      return axios.post(`${PMC.DELETE_COVID_VACCINE_FILE}/${params}`).then((r)=>{
        AlertService.successAlertService('File Attachment deleted successfully','Deleted');
        return true;
      }).catch((er)=>{
        UtilityService.generateResponseMessage(er,'Delete covid vaccine file attachment');
        return false;

      })
    }
  },

  null_covid_vaccines ({ commit }) {
    commit('null_covid_vaccines')
  },

  null_covid_vaccine ({ commit }) {
    commit('null_covid_vaccine')
  },

  null_covid_vaccine_blob ({ commit }) {
    commit('null_covid_vaccine_blob')
  },

  null_covid_vaccine_blob_gte3mb ({ commit }) {
    commit('null_covid_vaccine_blob_gte3mb')
  },

  set_form_covid_vaccine_blob ({ commit }, param) {
    commit('set_form_covid_vaccine_blob', param)
  },

  null_form_covid_vaccine_blob ({ commit }) {
    commit('null_form_covid_vaccine_blob')
  },

  set_form_covid_vaccine_blob_get3mb ({ commit }, param) {
    commit('set_form_covid_vaccine_blob_get3mb', param)
  },

  null_form_covid_vaccine_blob_get3mb ({ commit }) {
    commit('null_form_covid_vaccine_blob_get3mb')
  },
}

/** getters **/
const getters = {
  covidVaccine: state => UtilityService.capitalizeProperties(state.covidVaccine),
  covidVaccines: state => UtilityService.capitalizeProperties(state.covidVaccines),
  newCovidVaccine: state => state.newCovidVaccine,
  covidVaccineBlob: state => state.covidVaccineBlob,
  covidVaccineBlob_gte3mb: state => state.covidVaccineBlob_gte3mb,
  formCovidVaccineBlob: state => state.formCovidVaccineBlob,
  formCovidVaccineBlob_gte3mb: state => state.formCovidVaccineBlob_gte3mb,

  isExistsVaccine: state => state.isExistsVaccine,

}

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
}
