import axios from 'axios';

import {PMC, PMC_APP_KEYS, TOKEN} from '../../config/constants.config';
import {UtilityService} from '../../service/utility.service';
import {AlertService} from "@/service/alert.service";

const initAuthState = () => {
  return {
    status: '',
    token: localStorage.getItem(TOKEN.PMC || ''),
    authUser: {},
    authData: {
      username: null,
      password: null,
    },
    b4rExpired: localStorage.getItem('b4Expired'),
    authProfileDetails: {},
    authImg: null,
  }
}


const state = {
  ...initAuthState()
};

const mutations = {
  AUTH_REQUEST(state, params) {},
  AUTH_SUCCESS(state, token, user) {},

  LOGOUT(state) {
    state.status = null;
    state.token = null;
    state.authUser = {};
    state.authData = {};
    state.b4rExpired = null;
    window.localStorage.removeItem(TOKEN.PMC);
    window.localStorage.removeItem(PMC.USER_DETAILS);
    window.localStorage.removeItem('b4Expired');
    window.localStorage.removeItem(PMC_APP_KEYS.ENCRYPTED_KEY)
    // window.location.clear();
  },

  SET_AUTH_USER(state, params) {
    state.authUser = params;
  },

  SET_AUTH_DATA_NULL(state, params) {
    state.authData = {
      username: null,
      password: null,
    };
  },

  SET_TOKEN(state, params) {
    //todo make it secure,
    localStorage.setItem(TOKEN.PMC, params);
  },

  SET_USER_DETAILS(state, params) {
    localStorage.setItem(PMC.USER_DETAILS, JSON.stringify(params));
  },

  SET_DAYS_BEFORE_EXPIRED(state, params) {
    state.b4rExpired = params;
    //localStorage.setItem('b4Expired', JSON.stringify(params));
  },

  set_auth_profile_details(state, params) {
    state.authProfileDetails = params;
  },

  set_auth_img(state, params) {
    state.authProfileDetails.auth_image = params;
    state.authUser.auth_image = params;
    //localStorage.setItem(PMC.USER_DETAILS, JSON.stringify(state.authUser));
  },

  null_auth_img(state) {
    state.authProfileDetails.auth_image = null;
    state.authUser.auth_image = null;
    //localStorage.setItem(PMC.USER_DETAILS, JSON.stringify(state.authUser));
  },

  null_auth_details(state) {
    state.authProfileDetails = {};
  },

  reset_auth_state(state){
   Object.assign(state,initAuthState())
  }
};

const actions = {
  loginUser({commit}, params) {
    commit('set_is_loading', true);
    return axios.post(PMC.LOGIN, params).then((response) => {
      //commit('SET_AUTH_DATA_NULL');
      //commit('SET_USER_DETAILS', response.data.authUser);

      commit('SET_AUTH_USER', response.data.authUser);
      commit('SET_DAYS_BEFORE_EXPIRED', response.data.beforeExpired);
      commit('SET_TOKEN', response.data.token);
      commit('set_is_loading', false);
      const restrictedDept = [1,2,3,4,5,6];

      if(response.data.authUser.department){
        let userDept = response.data.authUser.department;
        let userId = response.data.authUser.id;
        if(!restrictedDept.includes(userDept)){
          localStorage.setItem('isSetupAccess','Y');
        }
        else localStorage.setItem('isSetupAccess','N');
        //MS. MJ ID
        if(userId === 143){
          localStorage.setItem('isSetupAccess','Y');
        }
      }
      if (response.data.beforeExpired > 0) {
        return true;
      } else {
        return false;
      }
    }).catch((errors) => {
      commit('SET_AUTH_DATA_NULL');
      commit('set_is_loading', false);
      if(!errors.response){
        AlertService.errorAlertService('Cant establish server connection','Login')
      }else {
        UtilityService.generateResponseMessage(errors, 'Login');
      }
      return false;
    });
  },

  logoutUser({commit}) {
    return axios.get(PMC.LOGOUT).then((res) => {
      commit('LOGOUT');
      localStorage.removeItem("PMC_TOKEN");
      localStorage.removeItem("vuex_pmc");
      localStorage.removeItem("isSetupAccess");
      return true;
    }).catch((error) => {
      UtilityService.generateResponseMessage(error);
      return false;

    });
  },

  getAuthUser({commit}) {
    let authUser = JSON.parse(localStorage.getItem(PMC.USER_DETAILS));
    let b4ExpiredDays = JSON.parse(localStorage.getItem('b4Expired'));
    commit('SET_AUTH_USER', authUser);
    commit('SET_DAYS_BEFORE_EXPIRED', b4ExpiredDays);
    return true;
  },

  changePassword({commit}, params) {
    return axios.post(PMC.CHANGE_PASSWORD, params).then((response) => {
      return true;

    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Change Password');
      return false;

    });
  },

  //todo forgotPassword({commit},params){}

  async getAuthProfile({commit}, params) {
    return await axios.get(`${PMC.EMPLOYEE}/${params}/profile`).then((response) => {
      commit('set_auth_profile_details', response.data);
      return true;
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Get profile details');
      return false;
    });
  },

  uploadAuthImage({commit}, params) {
    return axios.post(PMC.UPLOAD_EMPLOYEE_IMAGE, params, {
      onUploadProgress: progressEvent => {
      },
    }).then((response) => {
      commit('set_auth_img', response.data);
      return true;
    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'Upload employee image');
      return false;
    });
  },

  deleteAuthImage({commit}, params) {
    return axios.delete(`${PMC.DELETE_EMPLOYEE_IMAGE}/${params}`).then((response) => {
      commit('null_auth_img');
      return true;
    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'Delete employee image');
      return false;
    });
  },

  null_auth_details({commit}) {
    commit('null_auth_details');
  },

  reset_auth_state({commit}) {
    commit('reset_auth_state');
  }

};

const getters = {
  authUser: state => state.authUser,
  authData: state => state.authData,
  token: state => state.token,
  b4rExpired: state => state.b4rExpired,
  authProfileDetails: state => UtilityService.capitalizeProperties(state.authProfileDetails),
  authImg: state => state.authImg,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
