<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5 mt-2">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Union</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newTradeRouteModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Union
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search union name"
                v-model="unionFormParam.searchValue"
                @keypress.enter="searchUnion"
              >

              <button class="btn btn-sm pmc-btn-prime">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- status -->
          <!--          <div class="col-sm-2">-->
          <!--            <div class="form-group row">-->
          <!--              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>-->
          <!--              <span class="col-form-label">:</span>-->
          <!--              <div class="col-sm-7 text-left">-->
          <!--                <select name="listCount" id="list-stat" class="form-control custom-select-sm"-->
          <!--                        v-model="tradeRouteFormParam.status"-->
          <!--                        @change="on_change_status">-->
          <!--                  <option :value="null">&#45;&#45;select&#45;&#45;</option>-->
          <!--                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">-->
          <!--                    {{ status.placeholder }}-->
          <!--                  </option>-->
          <!--                </select>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="unionFormParam.count"
                        @change="on_change_count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="unionPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="7%">CODE</th>
                <th scope="row" class="align-middle" width="7%">NAME</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>AT</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>AT</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-for="(union, index) in unions" :key="union.id">
                <td>{{ index + unionPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ union.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ union.name }}</td>
                <td scope="col" class="p-0 text-center">{{ union.created_by }}</td>
                <td scope="col" class="p-0 text-center">{{ union.updated_by }}</td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ union.created_at }}
                </td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ union.updated_at }}
                </td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewUnionModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="unionViewtoUnionData = union">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_union(union.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new trade route modal   -->
    <div class="modal fade" id="newTradeRouteModal" tabindex="-1" role="dialog" aria-labelledby="newPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newPrincipalModalLabel">NEW UNION</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newUnion.code"
                    />
                  </div>
                </div>

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newUnion.name"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view trade route   -->
    <div class="modal fade" id="viewUnionModal" tabindex="-1" role="dialog"
         aria-labelledby="viewPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewPrincipalModalLabel">UNION</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="unionData.code"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="unionData.name"
                    />
                  </div>
                </div>


                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CREATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="unionData.created_by"
                    />
                  </div>
                </div>

                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">UPDATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="unionData.updated_by"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit"
            >
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel"
            >
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      unionViewtoUnionData: {},
      unionData: {},
      newUnion: {},
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,

      pageLastLeft: 1,

    };
  },

  /** methods**/
  methods: {
    ...mapActions([
      'null_unions',
      'set_unions',
      'create_union',
      'update_union',
      'delete_union',

    ]),

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Document Category', 'question')
      if (await __create) {

        let params = {
          code: this.newUnion.code ? this.newUnion.code : '',
          name: this.newUnion.name, // this is required
        };

        let newDocCat = await this.create_union(params);
        if (newDocCat) {
          AlertService.successAlertService('Create union record successful', 'Create union');
          this.hideModal('newDocCatModal');
          this.newUnion = {};
          this.set_unions();
        }
      }
    },


    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update union', 'question');
      if (await __update) {
        let params = {
          id: this.unionData.id,
          code: this.unionData.code ? this.unionData.code : '',
          name: this.unionData.name,

        };

        let update_union = await this.update_union(params);
        if (update_union) {
          AlertService.successAlertService("Updated successfully", 'Update union');
          this.hideModal('viewUnionModal')
          this.isEditable = false
        }
      }

    },


    on_change_count(e) {
      this.unionFormParam.page = 1
      this.unionFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.unionFormParam.count = e.target.value
      this.set_unions(this.unionFormParam)

      // this.unionFormParam.count = e.target.value;
      // this.set_unions(this.unionFormParam);
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel() {
      this.isEditable = false
      this.unionData = Object.assign({}, this.unionViewtoUnionData);
    },

    set_this_page(e) {
      this.unionFormParam.searchValue = ''
      this.unionPagination.currentPage = e
      this.unionFormParam.page = e
      this.pageLastLeft = e
      this.set_unions(this.unionFormParam)

      // this.unionFormParam.currentPage = e;
      // this.unionFormParam.page = e;
      // this.set_unions(this.unionFormParam);
    },

    searchUnion() {
      if (this.unionFormParam.searchValue === '') {
        this.unionFormParam.page = this.pageLastLeft
        this.set_unions(this.unionFormParam)
      }

      if (this.unionFormParam.searchValue) {
        this.unionFormParam.page = 1
      }

      this.set_unions(this.unionFormParam)

      // this.unionFormParam.page = 1;
      // this.set_unions(this.unionFormParam);
    },

  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'unions',
        'unionPagination',
        'unionFormParam',
      ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    // await this.set_flags(this.unionFormParam)
    await this.set_unions(this.unionFormParam)
  },


  /** watch **/
  watch: {
    'unionViewtoUnionData'() {
      this.unionData = Object.assign({}, this.unionViewtoUnionData);
    },
  },

  /** destroyed **/
  destroyed() {
    this.null_unions();
  },


}
</script>

<style scoped>

</style>
