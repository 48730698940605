<template>
  <div class="row justify-content-center">
    <div class="col col-md">
      <div class="table table-responsive pmc-table border-top">

        <template>
          <slot name="table-contents"></slot>
        </template>

      </div>
    </div>


    <div class="overlay" v-if="isModalOpenOverlayBackdrop"></div>
    <div class="modal fade" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="documentListModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document" :class="{'xl-width' : isPdfViewerOpen, 'edit-doc-modal-width' : !isPdfViewerOpen }">
        <div class="modal-content">
          <div class="modal-header">
            <h6 v-if="crewDocData.documentName">{{ crewDocData.documentName.toUpperCase() }}</h6>
            <div class="btn-group">
              <button class="btn btn-sm ml-2" @click="doc_editable_close" aria-label="Close">
                <font-awesome-icon icon="times" class="text-danger"/>
                Close
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="text-left" :class="{'col-md-6' : isPdfViewerOpen, 'col-md-11' : !isPdfViewerOpen }">
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">Document No.</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      :disabled="!isCrewDocEdited"
                      v-model="crewDocData.documentNo"
                      class="form-control form-control-sm"
                      name="FamFirst"
                      placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">Document No. 2</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           :disabled="!isCrewDocEdited"
                           v-model="crewDocData.documentNo2"
                           class="form-control form-control-sm"
                           name="FamFirst"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">Ranks</label>
                  <span>:</span>
                  <div class="col-md-8">
                    <multi-select
                      :disabled="!isCrewDocEdited"
                      v-model="thisCrewDocRankId"
                      :options="objRanks"
                      :custom-label="ranks_lang"
                      :select-label="'Select Document Rank'"
                      placeholder="Select Document Rank"
                      label="Document Rank"
                      track-by="id">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">Date Issued</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                      type="date"
                      :disabled="!isCrewDocEdited"
                      v-model="crewDocData.dateIssued"
                      :class="['form-control', 'form-control-sm', isIssuedDateValid ? 'invalid-date' : '']"
                      @change="onChangeIssuedDate"
                      @blur="onBlurIssuedDate"
                      :min="minDate"
                      :max="maxDate">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">Date Expiry</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                      type="date"
                      :disabled="!isCrewDocEdited || !crewDocData.dateIssued"
                      v-model="crewDocData.dateExpiry"
                      :class="['form-control', 'form-control-sm', isExpiryDateValid ? 'invalid-date' : '']"
                      placeholder="--"
                      @change="onChangeExpiryDate"
                      @blur="onBlurExpiryDate"
                      :min="crewDocData.dateIssued"
                      :max="maxExpiryDate">
                  </div>
                </div>


                <div class="form-group row mt-3">
                  <label class="col-sm-3 col-form-label">
                    {{ crewDocData.hasFile ? 'Replace File' : 'Attach File' }}
                  </label>
                  <span class="col-form-label">:</span>
                  <input :disabled="!isCrewDocEdited" type="checkbox" class="form-check form-check-inline ml-sm-4" v-model="isCrewDocReplace" @change="on_attachment_checkbox">
                  <div class="col-sm-8">
                    <input type="file"
                           :disabled="!isCrewDocReplace"
                           style="resize: none !important;"
                           class="form-control-file"
                           @change="on_change_attachFile"
                           :id="fileInputId"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-3">
                  <label class="col-sm-3 col-form-label">Created by</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <span> {{ titleCase(crewDocData.createdBy) }}</span>
                  </div>
                </div>

                <div class="form-group row mt-3 ">
                  <label class="col-sm-3 col-form-label">Uploaded by</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <span class="small"> {{ titleCase(crewDocData.uploadedBy) }}</span>
                  </div>
                </div>

                <div class="form-group row mt-3">
                  <label class="col-sm-3 col-form-label">Last Update by</label>
                  <span>:</span>
                  <div class="col-sm-8 text-left">
                    <span class="small">{{ titleCase(crewDocData.updatedBy) }}</span>
                  </div>
                </div>


              </div>

              <div class="border-left vertical-center-container col-md-1" v-if="crewDocData.hasFile">
                <button class="btn-secondary" @click="openPdfViewer" v-if="isPdfViewerOpen">
                  <font-awesome-icon icon="arrow-left"/>
                </button>

                <button class="btn-secondary" @click="openPdfViewer" v-else>
                  <font-awesome-icon icon="arrow-right"/>
                </button>
              </div>

              <div :class="{'col-md-5' : isPdfViewerOpen, 'col-md-1' : !isPdfViewerOpen }">

                <div class="prev-container" v-if="isPdfViewerOpen">
                  <div v-if="!is2mbUp" style="height: 400px;">
                  <span v-if="!thisDocImageBlob && !formDocImgFile">
                    <font-awesome-icon icon="file" style="height: 80%; "/>
                  </span>
                    <object v-if="formDocImgFile" width="100%" height="100%" :data="formDocImgFile+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=50,10,0'" type="application/pdf"></object>
                    <object v-if="thisDocImageBlob && !formDocImgFile" width="100%" height="100%" :data="thisDocImageBlob+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=50,10,0'" type="application/pdf"></object>
                  </div>

                  <div v-if="is2mbUp">
                    <div class="center-alignment">
                      <button class="btn btn-xs btn-outline-danger" @click="previewFileDocument(this10mbDocImageBlob)">
                        <font-awesome-icon icon="file"/>
                        <small class="ml-2">document</small>
                      </button>
                      <p class="small ml-1">Click to preview file more than 3mb.</p>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </div>

          <div class="modal-footer p-2">
            <button type="button"
                    class="btn btn-primary btn-sm px-3 font-weight-normal"
                    @click="openInNewWindow(thisDocImageBlob)"
                    v-if="crewDocData.hasFile"
            >
              <font-awesome-icon icon="file"/>
              Open File
            </button>

            <button type="button"
                    class="btn btn-danger btn-sm px-3 font-weight-normal"
                    v-if="!crewDocData.hasFile"
                    readonly
            >
              <font-awesome-icon icon="file"/>
              No File
            </button>

            <button type="button"
                    v-if="!isCrewDocEdited"
                    class="btn btn-warning btn-sm px-3 font-weight-normal"
                    @click="doc_editable"
            >
              <font-awesome-icon icon="edit"/>
              Edit
            </button>

            <button type="button"
                    v-if="isCrewDocEdited"
                    class="btn btn-success btn-sm px-3 font-weight-normal"
                    @click="update_document"
            >
              <font-awesome-icon icon="save"/>
              Save
            </button>

            <button type="button"
                    v-if="isCrewDocEdited"
                    class="btn btn-danger btn-sm px-3 font-weight-normal"
                    @click="doc_editable_cancel(crewDocData.id)"
            >
              <font-awesome-icon icon="undo"/>
              Cancel
            </button>

            <button type="button"
                    v-if="!isCrewDocEdited"
                    class="btn btn-danger btn-sm px-3 font-weight-normal"
                    @click="doc_editable_close"
            >
              <font-awesome-icon icon="times"/>
              Close
            </button>

          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Vue from "vue";
import {global_variables_config} from "@/config/global-variable.config";
import {mapActions, mapGetters} from "vuex";
import noDocumentPrev from "@/assets/noDocumentPrev.png";
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import Table from "@/components/base/Table.vue";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";

export default {
  name: "CcmMasterChecklistEditDocument",
  components: {Table},
  mixins: [AppMixins, AlertMixins],
  props: {

    // ccmMasterChecklist: {
    //   type: Array,
    //   default: [],
    // },
    //
    // ccmFlagId: {
    //   type: Number,
    //   default: 0,
    // },
    //
    // ccmFlagName: {
    //   type: String,
    //   default: 'Flag Name',
    // },
    //
    // loading: {
    //   type: Boolean,
    //   default: false,
    // },

    modalId: {
      type: String,
      default: 'example',
    },

    fileInputId: {
      type: String,
      default: 'example',
    },

  },

  data() {
    return {

      editModalId: this.modalId,

      isCrewDocEdited: false,
      isCrewDocReplace: false,

      crewDocViewToCrewDocData: {},
      crewDocData: {},

      isModalOpenOverlayBackdrop: false,
      isPdfViewerOpen: false,

      // 1984 is the earliest year in date_issued table in crew_documents
      minDate: "1980-01-01",
      maxDate: "9999-12-31",
      maxExpiryDate: null,
      advanceDateInYears: 20,
      existingIssuedDate: null,
      existingExpiryDate: null,
      isIssuedDateValid: false,
      isExpiryDateValid: false,
    };
  },

  methods: {
    ...mapActions([
      'viewCrewDoc',
      'updateCrewDocCcmChecklist',
      'updateCrewDoc',
      'set_form_doc_image_file',
      'set_form_10mb_docfile',
      'set_is_2mbUp',
      'getRankKeys',
    ]),

    // async view_document(crewDocId, modalId) {
    //   if(crewDocId){
    //     AlertService.loadingAlert('Please wait ...', 'Loading Data');
    //     let docData = await this.viewCrewDoc(crewDocId);
    //     if(docData){
    //       Swal.close();
    //       this.isModalOpenOverlayBackdrop = true;
    //       this.showModalBackdropStatic(this.editModalId);
    //       this.viewCrewDoc(crewDocId);
    //     }
    //   } else{
    //     AlertService.infoAlertService("This crew has no document of this type",'No Document');
    //     this.isModalOpenOverlayBackdrop = false;
    //   }
    //
    // },

    view_document(crewDocId) {
      if(crewDocId){
        this.isModalOpenOverlayBackdrop = true;
        this.showModalBackdropStatic(this.editModalId);
        this.viewCrewDoc(crewDocId).then(() => {
          if (this.crewDocData.dateIssued !== '0000-00-00' && this.crewDocData.dateIssued){
            this.existingIssuedDate = this.crewDocData.dateIssued;
          }

          if (this.crewDocData.dateExpiry !== '0000-00-00' && this.crewDocData.dateExpiry){
            this.existingExpiryDate = this.crewDocData.dateExpiry;
          }
        });
      }else{
        AlertService.infoAlertService("This crew has no document of this type",'No Document');
        this.isModalOpenOverlayBackdrop = false;
      }
    },

    /** base 64 file string ,  **/
    previewFileDocument(__stringFileParam) {
      this.base64ToBlob(__stringFileParam);
    },

    doc_editable() {
      if (!this.isCrewDocEdited) {
        this.isCrewDocEdited = true;
      }
    },



    doc_editable_cancel(crewDocId) {
      if (this.isCrewDocEdited) {
        this.isCrewDocEdited = false;
      }
      if (this.isCrewDocReplace) {
        this.isCrewDocReplace = false;
      }

      this.viewCrewDoc(crewDocId);
      this.crewDocData = Object.assign({}, this.crewDocument);

    },

    doc_editable_close() {
      this.hideModal(this.editModalId);
      this.isPdfViewerOpen = false;
      this.isModalOpenOverlayBackdrop = false;
      this.resetDate();

      setTimeout(() => {
        this.isCrewDocEdited = false;
        this.isCrewDocReplace = false;
      }, 300)
    },

    ranks_lang({
                 alias,
                 name,
               }) {
      return `[${alias}] - ${name}`;
    },


    async update_document() {
      let file = document.getElementById(this.fileInputId).files[0];

      if (this.isCrewDocReplace && file == (null || undefined)) {
        this.warningAlert('Please input a valid attachment');

      } else {
        const msg1 = 'Are you sure you want to update this document record';
        const msg2 = 'Are you sure you want to update & replace ' + '\n' + 'existing file on this document record.';
        let mes = null;

        if (this.crewDocData.hasFile && file != (null || undefined)) {
          mes = msg2;

        } else {
          mes = msg1;
        }

        if (await this.questionAlert(mes, 'Update Crew Document')) {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('crewDocId', this.crewDocData.id);
          formData.append('documentNo', this.crewDocData.documentNo);
          formData.append('documentNo2', this.crewDocData.documentNo2);
          formData.append('dateIssued', this.crewDocData.dateIssued);
          formData.append('dateExpiry', this.crewDocData.dateExpiry);
          formData.append('rankId', this.crewDocData.rankId);
          this.updateCrewDocCcmChecklist(formData).then((r) => {
            if (r) {
              AlertService.successAlertService("Updated successfully",'Update Document');
              this.emp_null_v_attachdoc();
              this.set_form_doc_image_file(null);
              this.$emit('refresh-ccm-checklist');
              this.hideModal(this.editModalId);
              this.isCrewDocReplace = false;
              this.isCrewDocEdited = false;
              this.isPdfViewerOpen = false;
              this.isModalOpenOverlayBackdrop = false;
              this.resetDate();
            }
          });
        }
      }
    },

    /** on viewing attached crew file  **/
    on_change_attachFile(e) {
      const image = e.target.files[0];
      const reader = new FileReader();

      if (image !== undefined) {
        reader.readAsDataURL(image);
        reader.onload = async e => {
          let param = e.target.result;
          if (this.gte2mb(image)) {
            this.set_is_2mbUp(true);
            this.set_form_10mb_docfile(param);
            let msg = 'It seem\'s like you want to upload a file more than 3mb. \n' +
              'Do you want to preview. ?';
            if (await this.questionAlert(`${msg}`, 'File Preview', 'warning')) {
              this.base64ToBlob(param);   //view file on separate tab.
            }
          } else {
            this.set_form_doc_image_file(param);

          }
        };
      } else {
        this.resetAttachFile();

      }
    },

    emp_null_v_attachdoc() {
      $("#" + this.fileInputId).val(null);
    },

    resetAttachFile() {
      this.set_form_doc_image_file(null);
      this.set_is_2mbUp(false);
      this.set_form_10mb_docfile(null);
    },

    //on check view documents.
    on_attachment_checkbox() {
      if (!this.isCrewDocReplace) {
        this.resetAttachFile();
        this.emp_null_v_attachdoc();
      }
    },


    openInNewWindow(pdfBlob){
      this.viewPdfOnNewWindow(pdfBlob);
    },


    openPdfViewer(){
      this.isPdfViewerOpen = !this.isPdfViewerOpen;
    },

    onChangeIssuedDate(){
        if (!this.crewDocData.dateIssued && !this.existingExpiryDate){
          this.crewDocData.dateExpiry = null
        } else if (!this.crewDocData.dateIssued){
          this.crewDocData.dateExpiry = null
        }
    },

    onChangeExpiryDate() {
      if (!this.crewDocData.dateExpiry){
        this.crewDocData.dateExpiry = null;
      }
    },

    onBlurIssuedDate() {
      const issuedDate = new Date(this.crewDocData.dateIssued);
      const expiryDate = new Date(this.crewDocData.dateExpiry);

      const currentDate = new Date();
      const issueDateMin = new Date(this.minDate)
      const fallbackDate = this.existingIssuedDate || new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()+1).toISOString().split('T')[0];

      const monthIndex = 12;
      const day = 31;

      const setExpiryDateInvalid = () => {
        if (this.crewDocData.dateExpiry){
          this.isExpiryDateValid = true;
          setTimeout(() => {
            this.isExpiryDateValid = false;
          }, 300);
        }
        this.maxExpiryDate = new Date(`${issuedDate.getFullYear() + this.advanceDateInYears}-${monthIndex}-${day}`).toISOString().split('T')[0];
        this.crewDocData.dateExpiry = null;
      };

      if ( issuedDate.getFullYear() < issueDateMin.getFullYear() || issuedDate.getFullYear() > (currentDate.getFullYear() + this.advanceDateInYears)) {
        this.isIssuedDateValid = true;
        setTimeout(() => {
          this.isIssuedDateValid = false;
        }, 300)
        this.crewDocData.dateIssued = fallbackDate;
      } else if (issuedDate > expiryDate){
        setExpiryDateInvalid();
      } else if ((expiryDate.getFullYear() - issuedDate.getFullYear()) === 20){
        if ((issuedDate.getDate() > expiryDate.getDate()) || issuedDate.getMonth() > expiryDate.getMonth()){
          setExpiryDateInvalid();
        }
      } else if (expiryDate.getFullYear() - issuedDate.getFullYear() > 20){
        setExpiryDateInvalid();
      }
    },

    onBlurExpiryDate() {
      let expiryDateData = this.crewDocData.dateExpiry;
      let issuedDateData = this.crewDocData.dateIssued;
      const issueDate = new Date(issuedDateData);
      const currentDate = new Date();

      if (expiryDateData !== '0000-00-00' && expiryDateData) {
        const expiryDate = new Date(expiryDateData);
        if ( expiryDate < issueDate || expiryDate.getFullYear() > (issueDate.getFullYear() + this.advanceDateInYears)){
          this.isExpiryDateValid = true;
          setTimeout(() => {
            this.isExpiryDateValid = false;
          }, 300)
          if (this.existingExpiryDate && ( new Date(this.existingExpiryDate) - issueDate.getFullYear() <= this.advanceDateInYears)){
            this.crewDocData.dateExpiry = this.existingExpiryDate;
          } else if (this.existingExpiryDate && (new Date(this.existingExpiryDate).getFullYear() > issueDate.getFullYear())){
            this.crewDocData.dateExpiry = this.existingExpiryDate;
          } else {
            this.crewDocData.dateExpiry = this.crewDocData.dateIssued;
          }
        }
      }

    },

    resetDate(){
      this.existingExpiryDate = null;
      this.existingIssuedDate = null;
      this.maxExpiryDate = null;
    }

  },

  computed: {
    ...mapGetters([
      'crewDocument',
      'is2mbUp',
      'docImageBlob',
      'formDocImgFile',
      'form10mbUpDocFile',
      'docImageBlob_gte10mb',
      'objRanks',
      'isReloading',
    ]),

    thisDocImageBlob() {
      return this.docImageBlob ? this.docImageBlob : this.formDocImgFile;
    },

    this10mbDocImageBlob() {
      return this.form10mbUpDocFile ? this.form10mbUpDocFile : this.docImageBlob_gte10mb;
    },

    thisCrewDocRankId: {
      get() {
        return this.objRanks.find((doc) => doc.id === this.crewDocData.rankId);
      },
      set(val) {
        this.crewDocData.rankId = val ? val.id : null;
      },
    },


  },

  created() {
    this.$globalVariables = global_variables_config
    this.getRankKeys();
  },

  beforeDestroy() {
    this.$globalVariables = null;
  },


  /** watch **/
  watch: {
    'crewDocument'() {
      this.crewDocData = Object.assign({}, this.crewDocument);
    },

  },



}
</script>

<style scoped>

.edit-doc-modal-width{
  max-width: 700px !important;
}

.xl-width{
  max-width: 1280px !important;
}

.vertical-center-container {
  display: flex;
  align-items: center; /* Center vertically */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invalid-date {
  border: 2px solid #FA5366FF;
  box-shadow: 0 0 10px #719ECE;
}

</style>
