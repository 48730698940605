import axios from 'axios'
import { UtilityService } from '../../../service/utility.service'
import { PMC } from '../../../config/constants.config'
import { AlertService } from '../../../service/alert.service'


const initApplicantState = () => {
  return {
    applicant: {},
    applicants: [],

    objectCrewPagination : {
      from: 1,
      currentPage: 1,
      lastPage: 0,
      perPage: 10,
      to: 9,
      total: 0,
    },
    crewEndorsers : [],
  }
}


/** state **/
const state = {
  ...initApplicantState()
}

/** mutations **/
const mutations = {
  set_applicant (state, params) {
    state.applicant = params
  },

  set_applicants (state, params) {
    state.applicants = params
  },

  update_applicant (state, params) {
    // let applicant = state.applicants.find((applicant, index) => applicant.id === params.id)
    Object.assign(state.applicant, params)
  },

  delete_applicant (state, params) {
    let index = state.applicants.findIndex((applicant, index) => applicant.id === params.id)
    state.applicants.splice(index, 1)
  },

  null_applicant (state) {
    state.applicant = {}
  },

  null_applicants (state) {
    state.applicants = []
  },

  //objectCrewsPagination
  set_object_crew_pagination(state,payload){
    state.objectCrewPagination = {
      from: payload.from,
      currentPage: payload.current_page,
      lastPage: payload.last_page,
      perPage: payload.per_page,
      to: payload.to,
      total: payload.total,
    }
  },

  //objectCrewsPagination
  reset_object_crews_pagination(state){
    Object.assign(state.objectCrewPagination,{
      from: 1,
      currentPage: 1,
      lastPage: 0,
      perPage: 10,
      to: 9,
      total: 0,
    })
  },

  reset_state_applicant_module(state){
    Object.assign(state,initApplicantState())
  }
}

/** actions **/
const actions = {
  async set_applicants ({ commit }, params) {
    commit('set_is_loading',true)
    return await axios.get(PMC.APPLICANT_TRACKING, { params }).then((r) => {
      commit('set_applicants', r.data.data)
      commit('set_applicants_pagination', r.data)
      commit('set_is_loading',false)

      return true

    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Get applicant list')
      return false

    })
  },

  set_applicant ({ commit }, params) {
    return axios.get(`${PMC.APPLICANT_TRACKING}/${params}`,{params}).then((r) => {

      commit('set_applicant', r.data.applicantTracking)
      commit('set_obj_crew_endorsers',r.data.crewEndorsers.data)
      commit('set_object_crew_pagination',r.data.crewEndorsers)
      return true;

    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Get applicant details')
      return false

    })
  },

  //use for applicant tracking
 async update_applicant ({ commit }, params) {
    let __confirmation = AlertService.questionAlertService('Do you want to save the changes you made', 'Update applicant tracking','question')
    if(await __confirmation){
      return axios.patch(`${PMC.APPLICANT_TRACKING}/${params.applicantTrackingId}`, params).then((r) => {
        commit('update_applicant', r.data)
        console.log(params, 'params')
        AlertService.successAlertService('Update applicant tracking successfully','Update applicant tracking')
        return true

      }).catch((er) => {
        UtilityService.generateResponseMessage(er, 'Update applicant')
        return false

      })
    }
  },

  delete_applicant ({ commit }, params) {
    return axios.delete(`${PMC.APPLICANT_TRACKING}/${params}`).then((r) => {
      commit('delete_applicant', r.data)
      return true

    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Delete applicant')
      return false

    })
  },

  null_applicant ({ commit }) {
    commit('null_applicant')
  },

  null_applicants ({ commit }) {
    commit('null_applicants')
  },

  reset_object_crews_pagination({commit}){
    commit('reset_object_crews_pagination');
  },

  reset_state_applicant_module({commit}){
    commit('reset_state_applicant_module')
  },
}

/** getters **/
const getters = {
  applicant: state => UtilityService.capitalizeProperties(state.applicant),
  applicants: state => UtilityService.capitalizeProperties(state.applicants),
  objectCrewPagination: state => UtilityService.capitalizeProperties(state.objectCrewPagination),
}

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
}
