<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Standby and Onboard</span>
                </div>
                <div class="form-group ">

                </div>
              </div>

            </div>
          </div>


        </div>

        <hr/>


        <div class="row mt-5">

          <div class="col-md-12">
            <div class="row">

              <!--              <div class="col-sm-3">-->
              <!--                <div class="form-group row">-->
              <!--                  <span class="ml-4 font-weight-bold lead" style="font-size: 18px;"> dsadsadas</span>-->

              <!--                </div>-->
              <!--              </div>-->

              <div class="col-sm-3">
                <div class="input-group mb-2 mr-sm-2">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="formdata-searchvalue"
                    placeholder="Search"
                    v-model="standbyOnboardFormParam.searchValue"
                    @keypress.enter="searchStandbyOnboard">

                  <button class="btn btn-sm pmc-btn-prime" @click="searchStandbyOnboard">
                    <font-awesome-icon icon="search"/>
                    Search
                  </button>
                </div>
              </div>

              <div class="col-sm-2 ml-4">
                <div class="form-group row">
                  <label for="list-count" class="col-form-label">FILTER</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7 text-left">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="standbyOnboardFormParam.filterType"
                            @change="on_change_filterType"
                            required>
                      <option value="all">All</option>
                      <option value="standby">Standby</option>
                      <option value="onboard">Onboard </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-sm-2 ml-4">
                <div class="form-group row">
                  <label for="list-count" class="col-form-label">RANK</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7 text-left">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="standbyOnboardFormParam.rankId"
                            @change="on_change_rank"
                            required>
                      <option :value="null">--select--</option>
                      <option :value="rank.id" v-for="(rank,index) in objRanks" :key="rank.id">
                        {{ rank.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group row">
                  <label for="list-count" class="col-form-label">PROVINCE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7 text-left">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="standbyOnboardFormParam.provinceId"
                            @change="on_change_province"
                            required>
                      <option :value="null">--SELECT--</option>
                      <option :value="province.id" v-for="(province,index) in objProvince" :key="province.id">
                        {{ province.prov_desc }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>


              <div class="col-sm-1">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="loadStandbyOnboardCrewList"
                  >
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
              </div>


            </div>
          </div>


        </div>

        <!--  pagination      -->
        <div class="row py-1 mt-3">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="standbyOnboardPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">

          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">


                <!--MASTERLIST FORMAT-->
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 13%;">Name</th>
                    <th>Rank</th>
                    <th style="width: 6%;">Age</th>
                    <th style="width: 11%;"><span class="d-block">Sea Service</span>Status</th>
                    <th style="width: 7%;"><span class="d-block">Present/Previous</span>Vessel</th>
                    <th style="width: 8%;">Sign-On/Sign-Off</th>
                    <th>Province</th>
                    <th style="width: 15%;">Address</th>
                    <th>Contact Details</th>
                    <th style="width: 6%;">Civil Status</th>
                    <th style="width: 13%;">Contact Person</th>
                    <th style="width: 2%;">Relation</th>
                    <th style="width: 33%;"><span class="d-block">Contact Person</span>Details</th>

                  </tr>

                  </thead>

                  <template v-if="isReloading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>

                  <template v-else>
                    <template v-if="standbyOnboardCrews ? (standbyOnboardCrews.length != 0) : '' ">
                      <tr v-for="(standbyOnboardCrew, index) in standbyOnboardCrews" :key="standbyOnboardCrew.id">
                        <td class="align-middle">{{ index + 1 }}</td>
                        <td class="align-middle">
                          <a href="#"
                             @click.stop="gotoCrewProfileNewWindow(standbyOnboardCrew.crew_id, 'all-info')"
                             style="color:black;">
                            <small><b>{{ standbyOnboardCrew.fullName }} </b></small>
                          </a>
                        </td>

                        <td class="align-middle">{{ standbyOnboardCrew.rankAlias }}</td>
                        <td class="align-middle">{{ standbyOnboardCrew.age }}</td>

                        <td class="align-middle">
                          {{ standbyOnboardCrew.seaServiceStatus }}
                        </td>

                        <td class="align-middle">
                          {{
                            standbyOnboardCrew.currentVessel ?
                              standbyOnboardCrew.currentVessel :
                              standbyOnboardCrew.previousVessel
                          }}
                        </td>

                        <td class="align-middle">

                          {{ standbyOnboardCrew.isStandby ? standbyOnboardCrew.previousVesselSignOff : '' }}
                          {{ standbyOnboardCrew.isOnboard ? standbyOnboardCrew.currentVesselSignOn : '' }}

                        </td>

                        <td class="align-middle">
                          {{ standbyOnboardCrew.province }}
                        </td>

                        <td class="align-middle">
                          {{ standbyOnboardCrew.address }}
                        </td>

                        <td class="align-middle">
                          {{ standbyOnboardCrew.contact }}
                        </td>

                        <td class="align-middle">
                          {{ standbyOnboardCrew.civilStatus }}
                        </td>

                        <td class="align-middle">
                          {{ standbyOnboardCrew.contactPerson }}
                        </td>

                        <td class="align-middle">
                          {{ standbyOnboardCrew.contactPersonRelation }}
                        </td>

                        <td style="white-space: pre-line;" class="align-middle">

                          {{ standbyOnboardCrew.contactPersonTelNum1 ? standbyOnboardCrew.contactPersonTelNum1 + ' (Mobile 1)\n' : '' }}

                          {{ standbyOnboardCrew.contactPersonTelNum2 ? standbyOnboardCrew.contactPersonTelNum2 + ' (Mobile 2)\n' : '' }}

                          {{ standbyOnboardCrew.contactPersonMobileNum1 ? standbyOnboardCrew.contactPersonMobileNum1 + ' (Tel 1)\n' : ''}}

                          {{ standbyOnboardCrew.contactPersonMobileNum2 ? standbyOnboardCrew.contactPersonMobileNum2 + ' (Tel 2)\n' : ''}}

                        </td>


                      </tr>
                    </template>

                    <template v-else>
                      <tr>
                        <td colspan="100%" class="bg-light-danger font-weight-bolder">
                          <p class="text-center mt-3">No Results</p>
                        </td>
                      </tr>
                    </template>


                  </template>

                </table>


              </div>
            </div>
          </div>


        </div>

      </div>
    </div>

    <!--  Edit Gear Size Modal modal   -->


    <!-- generating pdf loading modal -->
    <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

<!--            <div class="progress">-->
<!--              <div class="progress-bar" role="progressbar" :style="{ width: loadingProgress + '%'}" :aria-valuenow="loadingProgress" aria-valuemin="0" aria-valuemax="100">-->
<!--                {{ loadingProgress }}%-->
<!--              </div>-->
<!--            </div>-->

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";

export default {
  name: "StandbyOnboard",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      isEditable: false,

      isGenerateOnboardButton: false,

      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      pageLastLeft: 1,
      loadingProgress: 0,

    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_standbyOnboardCrews',
      'getRankKeys',
      'generateCrewStandbyOnboardList',
      'getProvinceKeys',
    ]),

    searchStandbyOnboard() {
      if (this.standbyOnboardFormParam.searchValue === '') {
        this.standbyOnboardFormParam.page = this.pageLastLeft
        this.set_standbyOnboardCrews(this.standbyOnboardFormParam)
      }

      if (this.standbyOnboardFormParam.searchValue) {
        this.standbyOnboardFormParam.page = 1
      }

      this.set_standbyOnboardCrews(this.standbyOnboardFormParam)
    },

    on_change_rank(e) {
      this.standbyOnboardFormParam.page = 1
      this.standbyOnboardFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.standbyOnboardFormParam.rankId = e.target.value;
      this.set_standbyOnboardCrews(this.standbyOnboardFormParam);
    },

    on_change_province(e) {
      this.standbyOnboardFormParam.page = 1
      this.standbyOnboardFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.standbyOnboardFormParam.provinceId = e.target.value;
      this.set_standbyOnboardCrews(this.standbyOnboardFormParam);
    },

    on_change_filterType(e) {
      this.standbyOnboardFormParam.page = 1
      this.standbyOnboardFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.standbyOnboardFormParam.filterType = e.target.value;
      this.set_standbyOnboardCrews(this.standbyOnboardFormParam);
    },

    set_this_page(e) {
      this.standbyOnboardFormParam.searchValue = ''
      this.standbyOnboardPagination.currentPage = e
      this.standbyOnboardFormParam.page = e
      this.pageLastLeft = e
      this.set_standbyOnboardCrews(this.standbyOnboardFormParam)
    },

    async loadStandbyOnboardCrewList() {
      const progressModal = $('#generatingAnimationModal')
      progressModal.modal('show');
      this.standbyOnboardFormParam.count = null;

      for (let i = 0; i <= 100; i += 25) {
        this.loadingProgress = i;
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call delay
      }

      let standbyOnboardCrewListFile = await this.generateCrewStandbyOnboardList(this.standbyOnboardFormParam);

      if (standbyOnboardCrewListFile) {
        this.loadingProgress = 100;
        progressModal.modal('hide');
        this.standbyOnboardFormParam.count = 10;
        await this.pdfViewerMasterChecklist('File', standbyOnboardCrewListFile);
      } else {
        progressModal.modal('hide');
      }
    },

    gotoProfile(thisCrewId, crewType) {
        this.$nextTick(() => {
          this.formSelectedCrewId = thisCrewId;
          let routeData = this.$router.resolve({ //
            name: 'CrewProfile',
            params: {
              crewId: thisCrewId,
            },
            query: {
              view: 'ccplan'
            }
          });
          window.open(routeData.href, '_blank');
        })
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'standbyOnboardCrews',
        'standbyOnboardPagination',
        'standbyOnboardFormParam',
        'objRanks',
        'objProvince',
        'isReloading',
      ]),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_standbyOnboardCrews(this.standbyOnboardFormParam);
    await this.getRankKeys();
    await this.getProvinceKeys();
  },

  /** watch **/
  watch: {},


}
</script>

<style scoped>

</style>
