<template>
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-3">
              <h6>Crew Details</h6>
            </div>
            <div class="col-md-9">
              <div class="form-inline" style="float: right;">
                <div class="form-group ml-2">
                  <label class="font-weight-bold">Type :</label>
                </div>
                <div class="form-group">
                  <select class="form-control form-control-sm ml-2" :disabled="true">
                    <option value="pre-departure">Pre-Departure Checklist</option>
                    <option value="pre-departure">Disembarkation Checklist</option>
                  </select>
                </div>
                <div class="form-group">
                  <pmc-btn
                    v-if="!isEditEnable"
                    @buttonEvent="set_editable"
                    :button-class="'btn btn-xs pmc-btn-info ml-1'"
                    :button-label="'Modify'">
                    <template slot="button-icon">
                      <font-awesome-icon icon="edit"/>
                    </template>
                  </pmc-btn>
                  <pmc-btn
                    v-if="isEditEnable"
                    @buttonEvent="save()"
                    :button-class="'btn btn-xs pmc-btn-tertia ml-1'"
                    :button-label="'Save'">
                    <template slot="button-icon">
                      <font-awesome-icon icon="save"/>
                    </template>
                  </pmc-btn>
                  <pmc-btn
                    v-if="isEditEnable"
                    @buttonEvent="reset()"
                    :button-class="'btn btn-xs pmc-btn-warning ml-1'"
                    :button-label="'Reset'">
                    <template slot="button-icon">
                      <font-awesome-icon icon="undo"/>
                    </template>
                  </pmc-btn>
                  <pmc-btn
                    v-if="isEditEnable"
                    @buttonEvent="defaultChecklist()"
                    :button-class="'btn btn-xs pmc-btn-prime ml-1'"
                    :button-label="'Load Default'">
                    <template slot="button-icon">
                      <font-awesome-icon icon="arrow-down"/>
                    </template>
                  </pmc-btn>
                  <pmc-btn
                    v-if="isEditEnable"
                    @buttonEvent="cancel()"
                    :button-class="'btn btn-xs pmc-btn-danger ml-1'"
                    :button-label="'Cancel'">
                    <template slot="button-icon">
                      <font-awesome-icon icon="times"/>
                    </template>
                  </pmc-btn>
                  <pmc-btn
                    v-if="!isEditEnable"
                    :disabled="this.crewFormData.no_checklist"
                    @buttonEvent="loadChecklistPDF()"
                    :button-class="'btn btn-xs pmc-btn-warning ml-1'"
                    :button-label="'Print'">
                    <template slot="button-icon">
                      <font-awesome-icon icon="file"/>
                    </template>
                  </pmc-btn>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <!-- Name of Crew  -->
              <div class="form-group row mt-1 text-left">
                <label for="name-of-crew" class="col-sm-3 font-weight-bold" style="padding: 0 0 0 15px;">Name of
                  Crew</label>
                <span>:</span>
                <div class="col-sm-8" style="padding: 0 0 0 10px;">
                  <input
                    style="padding-left:5px;"
                    v-model="crewFormData.crew_name"
                    :disabled="true"
                    type="text"
                    :class="'form-control form-control-sm'"
                    id="name-of-crew"
                    name="CrewName"
                    placeholder="--">
                </div>
              </div>
              <!-- Departure  -->
              <div class="form-group row mt-1 text-left">
                <label for="departure-date" class="col-sm-3 font-weight-bold" style="padding: 0 0 0 15px;">Departure</label>
                <span>:</span>
                <div class="col-sm-8" style="padding: 0 0 0 10px;">
                  <input
                    style="padding-left:5px;"
                    v-model="crewFormData.departure"
                    :disabled="true"
                    type="text"
                    :class="{'form-control form-control-sm' : isEditEnable, 'form-control form-control-sm form-control-plaintext' : !isEditEnable}"
                    id="departure-date"
                    name="CrewDeparture"
                    placeholder="N/A">
                </div>
              </div>

              <!-- Blood Pressure  -->
              <div class="row text-left">
                <div class="col-6">
                  <div class="form-group row mt-1">
                    <label for="blood-pressure" class="col-sm-6 font-weight-bold"
                           style="padding: 0 0 0 15px;">Age</label>
                    <span>:</span>
                    <div class="col-sm-5" style="padding: 0 0 0 10px;">
                      <input
                        style="padding-left:5px;"
                        v-model="crewFormData.age"
                        :disabled="true"
                        type="text"
                        :class="{'form-control form-control-sm' : isEditEnable, 'form-control form-control-sm form-control-plaintext' : !isEditEnable}"
                        id="blood-pressure"
                        name="CrewBloodPressure"
                        placeholder="N/A">
                    </div>
                  </div>
                </div>
                <div class="col-5">
                  <div class="form-group row mt-1">
                          <span class="col-sm-4">
                            <label for="blood-type" class="font-weight-bold" style="padding: 0px;">BMI </label>
                            <label style="float: right;">:</label>
                          </span>

                    <div class="col-sm-8" style="padding: 0 0 0 10px;">
                      <input
                        style="padding-left:5px;"
                        v-model="crewFormData.bmi"
                        :disabled="true"
                        type="text"
                        :class="{'form-control form-control-sm' : isEditEnable, 'form-control form-control-sm form-control-plaintext' : !isEditEnable}"
                        id="blood-type"
                        name="EndorsementByOthers"
                        placeholder="--">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 border-left">
              <div class="row mt-1 text-left">
                <!-- Rank  -->
                <div class="col-6">
                  <div class="form-group row">
                    <label for="rank" class="col-sm-5 font-weight-bold" style="padding: 0 0 0 10px;">Rank</label>
                    <span>:</span>
                    <div class="col-sm-6" style="padding: 0 0 0 10px;">
                      <input
                        style="padding-left:5px;"
                        v-model="crewFormData.rank_name"
                        :disabled="true"
                        type="text"
                        :class="{'form-control form-control-sm' : isEditEnable, 'form-control form-control-sm form-control-plaintext' : !isEditEnable}"
                        id="rank"
                        name="CrewRank"
                        placeholder="--">
                    </div>
                  </div>
                </div>
                <!-- Vessel  -->
                <div class="col-6">
                  <div class="form-group row">
                    <label for="vessel" class="col-sm-5 font-weight-bold"
                           style="padding: 0 0 0 10px;">Vessel</label>
                    <span>:</span>
                    <div class="col-sm-6" style="padding: 0 0 0 10px;">
                      <input
                        style="padding-left:5px;"
                        v-model="crewFormData.vessel"
                        :disabled="true"
                        type="text"
                        :class="{'form-control form-control-sm' : isEditEnable, 'form-control form-control-sm form-control-plaintext' : !isEditEnable}"
                        id="vessel"
                        name="CrewVessel"
                        placeholder="N/A">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-1 text-left">
                <!-- Previous Vessel  -->
                <div class="col-6">
                  <div class="form-group row">
                    <label for="previous-vessel" class="col-sm-5 font-weight-bold" style="padding: 0 0 0 10px;">Previous
                      Vessel</label>
                    <span>:</span>
                    <div class="col-sm-6" style="padding: 0 0 0 10px;">
                      <input
                        style="padding-left:5px;"
                        v-model="crewFormData.previous_vessel"
                        :disabled="true"
                        type="text"
                        :class="{'form-control form-control-sm' : isEditEnable, 'form-control form-control-sm form-control-plaintext' : !isEditEnable}"
                        id="previous-vessel"
                        name="CrewPreviousVessel"
                        placeholder="N/A">
                    </div>
                  </div>
                </div>
                <!-- Debarkation  -->
                <div class="col-6">
                  <div class="form-group row">
                    <label for="debarkation" class="col-sm-5 font-weight-bold" style="padding: 0 0 0 10px;">Debarkation </label>
                    <span>:</span>
                    <div class="col-sm-6" style="padding: 0 0 0 10px;">
                      <input
                        style="padding-left:5px;"
                        v-model="crewFormData.debarkation"
                        :disabled="true"
                        type="text"
                        :class="{'form-control form-control-sm' : isEditEnable, 'form-control form-control-sm form-control-plaintext' : !isEditEnable}"
                        id="debarkation"
                        name="CrewDebarkation"
                        placeholder="N/A">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-1 text-left">
                <div class="col-6">
                  <div class="form-group row">
                    <label for="height" class="col-sm-5 font-weight-bold"
                           style="padding: 0 0 0 10px;">Height</label>
                    <span>:</span>
                    <div class="col-sm-6" style="padding: 0 0 0 10px;">
                      <input
                        style="padding-left:5px;"
                        v-model="crewFormData.height"
                        :disabled="true"
                        type="text"
                        :class="{'form-control form-control-sm' : isEditEnable, 'form-control form-control-sm form-control-plaintext' : !isEditEnable}"
                        id="height"
                        name="CrewHeight"
                        placeholder="N/A">
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label for="weight" class="col-sm-5 font-weight-bold"
                           style="padding: 0 0 0 10px;">Weight</label>
                    <span>:</span>
                    <div class="col-sm-6" style="padding: 0 0 0 10px;">
                      <input
                        style="padding-left:5px;"
                        v-model="crewFormData.weight"
                        :disabled="true"
                        type="text"
                        :class="{'form-control form-control-sm' : isEditEnable, 'form-control form-control-sm form-control-plaintext' : !isEditEnable}"
                        id="weight"
                        name="CrewWeight"
                        placeholder="N/A">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div class="row text-left my-1 no-gutters" style="border: 1px solid rgba(0,0,0,0.2);">
            <div class="col-lg-12">
              <template>
                <div class="row">
                  <div class="col col-md-12">
                    <div v-if="Object.keys(cchCategories).length > 0" class="table">
                      <table class="table table-bordered table-sm text-center" v-model="isListChanged"
                             style="overflow-x: hidden !important;">
                        <tbody>
                        <tr>
                          <div style="margin-bottom: -12px;overflow-x: hidden !important;">
                            <table class="table table-bordered table-sm sticky-header">
                              <thead>
                              <tr style="padding:0px;margin: 0px;">
                                <th style="width: 42%;">Particulars</th>
                                <th style="width: 8%;">Date Issued</th>
                                <th style="width: 8%;font-size: 8pt;">Date Expire</th>
                                <th style="width: 15%;">Reference Number</th>
                                <th style="width: 8%;">Possession</th>
                                <th style="width: 19%;">Remarks</th>
                              </tr>
                              </thead>
                            </table>
                          </div>
                        </tr>
                        <tr v-for="(checklist,index) in cchCategories" :key="checklist.id">
                          <div>
                            <table class="table table-bordered table-sm">
                              <tbody>
                              <tr class="form-inline th-no-color text-left ml-2"
                                  style="line-height: 30px;font-weight: bolder;">
                                {{
                                  setChecklistHeader(checklist.description, checklist.sequence, checklist.is_display_sequence)
                                }}
                                <button class="btn btn-xs pmc-bg-info ml-2"
                                        v-if="isEditEnable"
                                        @click="setChecklistLevel(checklist.sequence)"
                                        data-toggle="modal"
                                        data-target="#add-new-doc-modal"
                                        data-backdrop="static"
                                        data-keyboard="false"
                                        style="padding:10px;">
                                  <font-awesome-icon class="pmc-bg-info" icon="plus-circle"/>
                                  Add Document
                                </button>
                              </tr>
                              </tbody>
                            </table>
                            <div style="margin-top: -12px;margin-bottom: -12px;">
                              <table class="table table-bordered table-sm table-hover">
                                <tbody>
                                <template v-for="(document,index1) in checklist.documents">
                                  <tr>
                                    <td class="text-left" style="width: 42%;padding-left: 10px;">
                                      <span>{{checklist.sequence}}.{{document.index}}. {{ document.document_name ? document.document_name : "" }}</span>
                                      <pmc-btn
                                        v-if="isEditEnable"
                                        style="float: right;"
                                        @buttonEvent="removeFromChecklistSelected(checklist.id, document.document_id)"
                                        :button-class="'btn btn-xs pmc-btn-danger ml-1'"
                                        :button-label="''">
                                        <template slot="button-icon">
                                          <font-awesome-icon icon="trash"/>
                                        </template>
                                      </pmc-btn>
                                    </td>
                                    <td class=" text-center bg-default" style="width: 8%;font-size: 8pt;"
                                        :class="{'remarks-no-data': !document.date_issued}">
                                      {{ document.date_issued ? document.date_issued : '?' }}
                                    </td>
                                    <td style="width: 8%;font-size: 8pt;"
                                        :class="{'remarks-no-data': !document.date_expiry, 'noExpiry' : document.hasExpiry === 0, 'text-center bg-danger text-white' : document.is_date_expiry === 1, 'text-center': document.is_date_expiry === 0}">
                                      <div v-if="document.hasExpiry !== 0">
                                        {{ document.date_expiry ? document.date_expiry : '?' }}
                                      </div>
                                      <div v-if="document.hasExpiry === 0">
                                        No Expiry
                                      </div>

                                    </td>
                                    <td class=" text-center" style="width: 15% !important;">
                                      <small :class="{'remarks-no-data' : !document.document_no}">
                                        {{ document.document_no ? document.document_no.toUpperCase() : '?' }}
                                      </small>
                                    </td>
                                    <td class=" text-center" style="width: 8%;">
                                      <select class="form-control fomr-control-sm" v-model="document.possession" :disabled="!isEditEnable">
                                        <option value="0">--select--</option>
                                        <option value="1">Crew</option>
                                        <option value="2">Returned</option>
                                      </select>
                                    </td>
                                    <td class=" text-center" style="width: 19% !important;">
                                      <div v-if="remarks[document.document_id]"
                                           :title="remarks[document.document_id]" style="cursor: pointer;">
                                        {{ shortenRemark(remarks[document.document_id]) }}
                                        <i v-if="isEditEnable"
                                           @click="initRemark(document.document_id)"
                                           class="pmc-text-info remarks-icon"
                                           data-toggle="modal"
                                           data-target="#add-new-remark-modal"
                                           data-backdrop="static"
                                           data-keyboard="false">
                                          <font-awesome-icon icon="edit"/>
                                        </i>
                                      </div>
                                      <div v-if="!remarks[document.document_id]">

                                                      <span class="text-primary" data-toggle="modal"
                                                            v-if="isEditEnable"
                                                            style="cursor: pointer;"
                                                            data-target="#add-new-remark-modal"
                                                            @click="initRemark(document.document_id)">
                                                        No Comment
                                                      </span>
                                        <span v-else class="remarks-no-data">No Comment</span>
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </tr>
                        </tbody>
                      </table>
                      <div v-if="Object.keys(this.preDepartureChecklist).length > 0">
                        <div style="margin-bottom: -12px;margin-top: -14px;">
                          <table class="table table-sm">
                            <tbody>
                            <tr class="text-center ml-2" style="line-height: 25px;">
                              <i>--NOTHING FOLLOWS--</i>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div v-else>
                        <table class="table table-sm">
                          <tbody>
                          <tr class="text-center ml-2">
                            <i>--No Result--</i>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div v-else>
                      <table class="table table-sm">
                        <tbody>
                        <tr class="text-center ml-2 text-danger" style="line-height: 25px;">
                          <i class="font-weight-bolder" style="font-size: 1.5em;">Loading Data! Please Wait
                            ...</i>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="row text-left my-2 no-gutters" style="border: 1px solid rgba(0,0,0,0.2);">
            <div class="col-lg-12">
              <table class="table table-sm small table-main-header" width="100%">
                <thead>
                <tr>
                  <th class="th-no-color text-center">Acknowledgement Receipt</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <div class="row">
                      <div class="col-8">
                        <div class="row">
                          <div v-for="(signatory, index) in signatories" :key="index"
                               class="col-6 text-center" style="margin-top: 30px;">
                            <div v-if="index <= 3" >
                              <span style="text-decoration: underline;"><b>{{ signatory.name }}</b></span><br>
                              <label>{{ signatory.type }}</label>
                            </div>
                          </div>

                          <div v-for="(signatory, index) in signatories"
                               :key="index"
                               class="col-12 text-center justify-content-between"
                               style="margin-top: -0px;">
                            <div v-if="index === 4" >
                              <span style="text-decoration: underline;"><b>{{ signatory.name }}</b></span><br>
                              <label>{{ signatory.type }}</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-4 text-center border-left">
                        <div style="margin-top: 30px;">
                          <span style="text-decoration: underline;">____________________</span><br>
                          <label>Crew Signature/Date</label>
                        </div>

                        <div style="margin-top: 30px;margin-bottom: 10px;">
                          <label>Checked/Debrief By:</label>
                        </div>

                        <div class="form-group text-center" style="margin: auto;width: 70%;">
                          <select class="form-control form-control-sm text-center" v-model="checkedBy"
                                  @change="selectCheckedBy" :disabled="!isEditEnable">
                            <option value="1">C/E JOSE GALAROSA</option>
                            <option value="2">CAPT. ROMMEL MANALO</option>
                          </select>
                        </div>
                        <span v-model="this.checkedByPosition">{{ this.checkedByPosition }}</span>
                      </div>
                    </div>
                  </td>

                </tr>
                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="add-new-doc-modal" tabindex="-1" role="dialog" aria-labelledby="addNewDocument"
           aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title font-weight-bolder mr-5">Add Document</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">X</span>
              </button>
            </div>
            <div class="modal-body">
              <pmc-table :max-height="400" :loading="isReloading" :loaderColspan="2">
                <template v-slot:table-header>
                  <tr>
                    <th class="th-no-color text-left" style="width: 90%;">Document Name</th>
                    <th class="th-no-color text-center" style="width: 10%;">Select</th>
                  </tr>
                </template>

                <template v-slot:table-body>
                  <tr v-for="(crewDocList, index) in crewDocumentList" :key="crewDocList.id"
                      v-show="crewDocList.document_name">
                    <td class="text-left ml-2">{{ crewDocList.document_name }}</td>
                    <td><input :disabled="!crewDocList.document_name" type="checkbox" class="document-select-checkbox"
                               v-model="selectedDocuments" v-bind:value="crewDocList.document_id"/></td>
                  </tr>
                </template>

              </pmc-table>
            </div>
            <div class="modal-footer p-2">
              <button type="button" class="btn btn-primary btn-sm px-3 font-weight-normal" data-dismiss="modal"
                      @click="getSelectedDocuments"
              >
                Select
              </button>
            </div>
          </div>
        </div>

      </div>
      <div class="modal fade" id="add-new-remark-modal" tabindex="-1" role="dialog" aria-labelledby="addNewRemark"
           aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title font-weight-bolder mr-5">Add Remark</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">X</span>
              </button>
            </div>
            <div class="modal-body">
              <div style="padding:20px">
                <textarea class="form-control" rows="3" v-model="remarkText"></textarea>
              </div>
            </div>
            <div class="modal-footer p-2">
              <button type="button" class="btn btn-warning btn-sm px-3 font-weight-normal"
                      @click="clearRemark(selectedDocId, selectedRemarkId)">
                Clear
              </button>
              <button type="button" class="btn btn-primary btn-sm px-3 font-weight-normal" data-dismiss="modal"
                      @click="addRemark(selectedDocId, selectedRemarkId)">
                Add
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import alertMixins, {AlertMixins} from "@/mixins/alert.mixins"
import axios from "axios";
import {PMC} from "../../../../config/constants.config";
import {UtilityService} from "../../../../service/utility.service";
import {AlertService} from "../../../../service/alert.service";
import {FileService} from "@/service/file.service";
export default {
  name: "SubPreDepartureChecklist",
  mixins: [AlertMixins],
  props: {
    crew_id: 0,
  },
  /** data **/
  data() {
    return {
      isEditEnable: false,
      dateListCount: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],
      formParams: {
        searchValue: null,
        searchKey: 'all',
        sFirstName: null,
        sMiddleName: null,
        sLastName: null,
        sRank: null,
        count: 10,
        isTrue: false,
        page: null,
      },
      searchKeys: [
        {
          id: 1,
          key: 'all',
          display: 'All',
        },
        {
          id: 2,
          key: 'first_name',
          display: 'First Name',
        },
        {
          id: 3,
          key: 'middle_name',
          display: 'Middle Name',
        },
        {
          id: 4,
          key: 'last_name',
          display: 'Last Name',
        },
        {
          id: 5,
          key: 'rank',
          display: 'Rank',
        },
      ],
      selectedCrew: {},
      crewParam: {
        'crew_id': null,
        'checklist': 1,
      },
      testParam: {
        'crew_id': null,
        'helloworld': 1,
      },

      selectedDocuments: [],
      remarkTitle: '',
      remarkText: '',
      selectedRemarkId: null,
      selectedDocId: null,
      checklistLevel: null,
      isListChanged: false,
      tempList: {},
      checkedBy: 1,
      checkedByPosition: null,
      checklistParam: {
        crewId: null,
        documents: {},
        remarks: {},
        docLocation: {},
        checkedBy: null
      },
      crewId: 0,

      // Watched crew_document_checklist module js
      docLastIndex: 0,
      cchCategories: {},
      crewDocumentList: {},
      crewFormData: {},
      preDepartureChecklist: {},
      remarks: {},
      docLocation: {},
      signatories: {},

    };
  },
  /** methods**/
  methods: {
    async getCrewDocumentChecklist(params) {
      return await axios.post(PMC.GET_CREW_CHECKLIST, params).then((response) => {
        this.crewFormData = UtilityService.capitalizeProperties(response.data);
        this.preDepartureChecklist = this.crewFormData.pre_departure_checklist;
        this.cchCategories = this.crewFormData.newChecklist_category;
        this.crewDocumentList = this.appendIndex(this.crewFormData.crew_documents);
        this.docLastIndex = Object.keys(this.crewDocumentList).length;
        this.signatories = this.crewFormData.signatories;
        this.docLocation = this.crewFormData.doc_locations;
        this.remarks = this.crewFormData.remarks;
        this.docLastIndex = Object.assign({}, this.documentLastIndex);
        return true;
      }).catch((errors) => {
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(errors, 'Get Crew Paginated');
        return false;
      });
    },
    async getDefaultCrewDocumentChecklist(params) {
      return await axios.post(PMC.GET_CREW_CHECKLIST, params).then((response) => {
        this.crewFormData = UtilityService.capitalizeProperties(response.data);
        this.preDepartureChecklist = this.crewFormData.pre_departure_checklist;
        this.cchCategories = this.crewFormData.checklist_category;
        this.crewDocumentList = this.appendIndex(this.crewFormData.crew_documents);
        this.docLastIndex = Object.keys(this.crewDocumentList).length;
        this.signatories = this.crewFormData.signatories;
        this.docLocation = this.crewFormData.doc_locations;
        this.remarks = this.crewFormData.remarks;
        this.docLastIndex = Object.assign({}, this.documentLastIndex);
        return true;
      }).catch((errors) => {
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(errors, 'Get Crew Paginated');
        return false;
      });
    },

    showModal(crew_id) {
      this.isEditEnable = true
      this.crewId = crew_id;
      // this.$refs['pre-departure-modal'].show();
      this.isEditEnable = false;
      return this.crewId;
    },
    getSelectedDocuments() {
      const selDocs = this.selectedDocuments;
      for (let docId in selDocs) {
        this.updateSelectedCrewDocumentList(selDocs[docId]);
      }
      this.selectedDocuments = [];
      this.isListChanged = true;
    },
    updateSelectedCrewDocumentList(selectedDocumentId) {
      let newList = {};
      let newCchCategories = {};
      let docList = this.crewDocumentList;
      for (let doc in docList) {
        if (selectedDocumentId === docList[doc].document_id) {
          if (docList[doc].document !== null) { // Use !== for comparison
            let selectedDocuments = docList[doc];
            selectedDocuments.date_issued = selectedDocuments.date_issued ? this.parseDate(selectedDocuments.date_issued).replace(' ', '-') : '';
            selectedDocuments.date_expiry = selectedDocuments.date_expiry ? this.parseDate(selectedDocuments.date_expiry).replace(' ', '-') : '';
            selectedDocuments.hasExpiry = selectedDocuments.document.hasExpiry;
            selectedDocuments.is_date_expiry = this.daysSinceLastDate(selectedDocuments.date_expiry) > 0 ? 0 : 1;
            selectedDocuments.document_name = selectedDocuments.document.name.toUpperCase();
            if (typeof selectedDocuments.crew_document !== "undefined") {
              selectedDocuments.is_exist = 0;
            }
            this.$set(this.preDepartureChecklist, Object.keys(this.preDepartureChecklist).length, selectedDocuments);
            newCchCategories = this.appendToChecklistSelected(selectedDocuments, this.checklistLevel);
          }else {
            let selectedDocuments = docList[doc];
            selectedDocuments.is_date_expiry = this.daysSinceLastDate(selectedDocuments.date_expiry) > 0 ? 0 : 1;
            selectedDocuments.document_name = selectedDocuments.document_name.toUpperCase();
            if (typeof selectedDocuments.crew_document !== "undefined") {
              selectedDocuments.is_exist = 0;
            }
            this.$set(this.preDepartureChecklist, Object.keys(this.preDepartureChecklist).length, selectedDocuments);
            newCchCategories = this.appendToChecklistSelected(selectedDocuments, this.checklistLevel);
          }
        } else {
          newList[doc] = docList[doc];
        }
      }
      this.crewDocumentList = newList;
      this.isListChanged = false;
      this.cchCategories = newCchCategories;
    },
    async saveCrewChecklist(params) {
      return await axios.post(PMC.SAVE_CREW_CHECKLIST, params).then((response) => {
        return true;
      }).catch((errors) => {
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(errors, 'Get Crew Paginated');
        return false;
      });
    },
    async generateCrewChecklist(params) {
      return await axios.post(PMC.GENERATE_CREW_CHECKLIST, params).then(res => {
        return res.data.data ? FileService.base64FileToBlobUrl(res.data.data) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate Crew Checklist')
        return false
      });
    },
    parseDate(dateStr) {
      return new Date(dateStr).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    async getDocumentChecklist(crew_id) {
      this.crewParam['crew_id'] = crew_id;
      await this.getCrewDocumentChecklist(this.crewParam);
    },
    daysSinceLastDate(lastDate) {
      const currentDate = new Date();
      const previousDate = new Date(lastDate);
      const timeDifference = previousDate.getTime() - currentDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return daysDifference;
    },
    appendIndex(checklist) {
      for (let doc in checklist) {
        checklist[doc].index = parseInt(doc);
      }
      return checklist;
    },
    appendToChecklistSelected(selectedDocument, catLevel) {
      let cchCategories = this.cchCategories;

      for (let cch in cchCategories) {
        if (cchCategories[cch].sequence === catLevel) {
          selectedDocument.cch_details_category_id = cchCategories[cch].id;

          // Calculate the next index for the new document
          let nextIndex = Object.keys(cchCategories[cch].documents).length + 1;
          selectedDocument.index = nextIndex;

          // Update indices for existing documents
          for (let existingDoc in cchCategories[cch].documents) {
            cchCategories[cch].documents[existingDoc].index = parseInt(existingDoc) + 1;
          }

          // Add the new document
          this.$set(cchCategories[cch].documents, Object.keys(cchCategories[cch].documents).length, selectedDocument);
        }
      }

      this.cchCategories = cchCategories;
      return cchCategories;
    },

    removeFromChecklistSelected(catLevel, documentId) {
      this.isListChanged = true;
      let cchCategories = this.cchCategories;
      for (let cch in cchCategories) {
        if (cchCategories[cch].id === catLevel) {
          let newDocList = [];
          let newDocCount = 1; // Start with 1 for the first index
          let documentList = cchCategories[cch].documents;

          for (let doc in documentList) {
            if (documentList[doc].document_id !== documentId) {
              newDocList.push({
                ...documentList[doc],
                index: newDocCount
              });
              newDocCount++; // Increment the index for the next document
            } else {
              if (!documentList[doc].index) {
                this.$set(this.crewDocumentList, this.docLastIndex, documentList[doc]);
                this.docLastIndex = this.docLastIndex + 1;
              } else {
                this.$set(this.crewDocumentList, documentList[doc].index, documentList[doc]);
              }
            }
          }
          cchCategories[cch].documents = newDocList;
        }
      }
      this.cchCategories = cchCategories;
      this.isListChanged = false;
      return cchCategories;
    },


    initRemark(docId) {
      this.selectedDocId = docId;
      if (this.remarks[docId]) {
        this.remarkText = this.remarks[docId];
      } else this.remarkText = "";
    },
    addRemark(docId) {
      this.remarks[docId] = this.remarkText;
      this.selectedRemarkId = null;
      this.selectedDocId = null;
      this.remarkText = "";
    },
    clearRemark(docId) {
      if (this.remarks[docId]) {
        this.remarks[docId] = null;
      }
      this.remarkText = "";
    },
    shortenRemark(remark) {
      let remarkText = remark.split(" ");
      let newText = "";
      const y = remarkText.length;
      if (y > 4) {
        for (let x = 0; x < 4; x++) {
          if (x == 0) newText = remarkText[x];
          else newText = newText + ' ' + remarkText[x];
        }
        newText = newText + ' ...';
      } else newText = remark;
      return newText;

    },
    shortenString(remark) {
      let remarkText = remark.split("");
      let newText = "";
      const y = remarkText.length;
      ;
      if (y > 40) {
        for (let x = 0; x < 40; x++) {
          if (x == 0) newText = remarkText[x];
          else newText = newText + remarkText[x];
        }
        newText = newText + ' ...';
      } else newText = remark;
      return newText;
    },
    setChecklistHeader(hdrStr, level, isDisplay) {
      const sequence = ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
      return level + '. ' + hdrStr.toUpperCase();
    },
    setChecklistLevel(cLevel) {
      this.checklistLevel = cLevel;
    },
    selectCheckedBy() {
      if (parseInt(this.checkedBy) === 1) this.checkedByPosition = "Operations Manager";
      else if (parseInt(this.checkedBy) === 2) this.checkedByPosition = "Asst. Operations Manager";
    },
    async loadChecklistPDF() {
      let params = {
        'crew_id': this.crewParam.crew_id
      };
      let checklistFile = await this.generateCrewChecklist(params);
      if (checklistFile) {
        this.pdfViewer('Pre Departure Checklist', checklistFile);
      } else {
      }
    },
    set_editable() {
      this.isEditEnable = !this.isEditEnable;
    },
    cancel() {
      this.set_editable();
    },
    reset() {
      this.cchCategories = {};
      this.crewParam.checklist = 1;
      this.getCrewDocumentChecklist(this.crewParam);
    },
    defaultChecklist() {
      this.cchCategories = {};
      this.crewParam.checklist = 0;
      this.getDefaultCrewDocumentChecklist(this.crewParam);
      this.crewParam.checklist = 1;
    },
    async save() {

      let __save = AlertService.questionAlertService("Update Pre Departure Checklist.", 'Confirm Action', 'question');
      if (await __save) {
        let params = {
          crewId: this.crewParam.crew_id,
          documents: this.cchCategories,
          remarks: this.remarks,
          docLocation: this.docLocation,
          checkedBy: this.checkedBy,
        };

        let checklistData = await this.saveCrewChecklist(params);

        if (checklistData) {
          await AlertService.successAlertService("Pre Departure Checklist Saved!", 'Successful');
          this.reset();
          this.isEditEnable = false;
          //await this.getCrewChecklist(this.crewParam);
        }
      }
    },
  },
  /** mounted **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
      ],
    ),
  },

  mounted() {
    //this.getDocumentChecklist(this.crew_id);
    if(this.crew_id > 0){
      this.getDocumentChecklist(this.crew_id);
    }
    this.getDocumentChecklist(this.$route.params['crewId']);
  },
  created() {
    if(this.crew_id > 0){
      this.getDocumentChecklist(this.crew_id);
    }

    //this.selectCheckedBy();
  },
}
</script>

<style scoped>
  .table-main-header tr th{
    font-size: 9pt !important;
    font-weight: 800;
  }
  .noExpiry {
    background: #e0e0e0;
  }
  .remarks-icon {
    cursor: pointer;
    float: right;
  }
  .remarks-no-data {
    color: rgba(0,0,0,0.4);
  }
  .document-select-checkbox {
    height: 8px;
  }
  ::-webkit-scrollbar {
    width: 8px !important;
  }
  small[title]:hover::after {
    transition: all 0.1s ease 0.5s;
    border-radius: 2px;
    padding:20px;
  }

</style>
