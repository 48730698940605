<template>
  <div class="container-fluid">
    <div class="col p-3 no-gutters">
      <div class="row">
        <div class="col-lg-6 pr-0 text-left">
          <h4>Crew Change Plan</h4>
        </div>
      </div>

      <div class="row mt-2 text-left">
        <div class="col-lg-10">
          <div class="row">
            <div class="col-4">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-weight-bold">VESSEL</label>
                <span>:</span>
                <div class="col-sm-8">
                  <!--@input="onChangeVessel($event)"-->
                  <select class="custom-select custom-select-sm"
                          v-model="selectedVessel"
                          @change="onChangeVessel">
                    <option :value="null" class="text-center align-middle">--SELECT VESSEL--</option>
                    <option :value="vessel.id" v-for="(vessel,index) in objVessels" :key="vessel.id">
                      {{ vessel.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group row">
                <label class="col-md-2 font-weight-bold col-form-label">FLAG</label>
                <span class="col-form-label">:</span>
                <div class="col-md-9">
                  <input type="text"
                         class="form-control form-control-sm text-left align-middle"
                         v-model="flagName" readonly>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label font-weight-bold">MANNING</label>
                <span class="col-form-label">:</span>
                <div class="col-sm-8">
                  <input type="text" class="form-control form-control-sm overflow-auto
                        text-left align-middle"
                         v-model="manningName" readonly>
                </div>
              </div>
            </div>

            <!--            <div class="col-2">-->
            <!--              <div class="form-group row">-->
            <!--                <label class="col-sm-2 col-form-label font-weight-bold">Filter</label>-->
            <!--                <span class="col-form-label">:</span>-->
            <!--                <div class="col-sm-9">-->
            <!--                  &lt;!&ndash;                  <input type="text" class="form-control form-control-sm overflow-auto&ndash;&gt;-->
            <!--                  &lt;!&ndash;                        text-left align-middle">&ndash;&gt;-->

            <!--                  <select name="listCount" id="list-stat" class="form-control custom-select-sm"-->
            <!--                          v-model="crewChangePlanFormParam.selectedFilterOrder"-->
            <!--                          @change="onChangeFilterBy"-->
            <!--                  >-->
            <!--                    <option :value="1">By Rank</option>-->
            <!--                    <option :value="2">By CCM</option>-->
            <!--                  </select>-->

            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->


          </div>
        </div>

        <div class="col-lg-2">
          <div class="row">
            <button type="button"
                    class="btn btn-sm pmc-btn-prime border-0 mr-1 text-left"
                    @click="crewChangeMemo(0)"
                    :disabled="selectedCrewChange.length == 0">
              CC-Memo
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second border-0 mr-1 text-left"
                    :disabled="!selectedVessel"
                    data-toggle="modal"
                    data-target="#vessel-remark-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="getAllVesselRemarks">
              Vessel Remarks
              <sup class="badge badge-info small">{{ remarksCount }}</sup>
            </button>

            <div v-if="crewChangePlans !== null">
              <button v-if="crewChangePlans.length == 0" class="btn btn-sm pmc-btn-warning border-0 text-left"
                      data-toggle="modal"
                      data-target="#setup-cc-plan"
                      data-backdrop="static"
                      data-keyboard="false">
                Setup
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2">

        <div class="row justify-content-center">
          <div class="col col-md">
            <div class="table table-responsive pmc-table border-top" style="overflow-x: hidden !important;">
              <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                <thead>

                <tr>
                  <!--                  ccPlanSortByRanking-->
<!--                  @click="crewChangePlanSort2(ccPlanSort, 'sortByRank', false, 'ranking',crewChangePlans)"-->
                  <th scope="col" class="align-middle cc-font-size" style="width: 6%;"
                      @click="columnSort(ccPlanSort, 'sortByRank', false, 'ranking',crewChangePlans)"
                  >
                    <div class="row">
                      <div class="col">

                        <template v-if="crewChangePlans && crewChangePlans.length != 0 && ccPlanSort.sortByRank">
                          <font-awesome-icon icon="arrow-up" :class="{'text-success': ccPlanSort.sortByRank === 'asc',
                        'text-secondary': !ccPlanSort.sortByRank}"/>

                          <font-awesome-icon icon="arrow-down" :class="{'text-success': ccPlanSort.sortByRank === 'desc',
                        'text-secondary': !ccPlanSort.sortByRank}"/>
                        </template>

                        Rank
                        <button v-if="crewChangePlans && crewChangePlans.length != 0"
                                class="btn btn-xs btn-light"
                                @click.stop="addRankCCplanModal">
                          <font-awesome-icon icon="plus" class="text-success"/>
                        </button>
                      </div>
                    </div>
                  </th>
                  <!--                  @click="ccPlanSortByCrewSignOff"-->
                  <th scope="col" colspan="4" class="crew-name align-middle cc-font-size"
                      @click="crewChangePlanSort('sortBySignOffCrew', true, 'crewSignOff')"
                  >

                    <template v-if="crewChangePlans && crewChangePlans.length != 0 && ccPlanSort.sortBySignOffCrew">
                      <font-awesome-icon icon="arrow-up" :class="{'text-success': ccPlanSort.sortBySignOffCrew === 'asc',
                        'text-secondary': !ccPlanSort.sortBySignOffCrew}"/>

                      <font-awesome-icon icon="arrow-down" :class="{'text-success': ccPlanSort.sortBySignOffCrew === 'desc',
                        'text-secondary': !ccPlanSort.sortBySignOffCrew}"/>
                    </template>

                    Crew
                  </th>
                  <th scope="col" class="align-middle cc-font-size" style="width: 5%;">Promote</th>
                  <th scope="col" class="date-column align-middle cc-font-size" style=" width: 10%;"
                      @click="crewChangePlanSort('sortByDateDepart', true, 'ccOffDepartureDate')"
                  >

                    <template v-if="crewChangePlans && crewChangePlans.length != 0 && ccPlanSort.sortByDateDepart">
                      <font-awesome-icon icon="arrow-up" :class="{'text-success': ccPlanSort.sortByDateDepart === 'asc',
                        'text-secondary': !ccPlanSort.sortByDateDepart}"/>

                      <font-awesome-icon icon="arrow-down" :class="{'text-success': ccPlanSort.sortByDateDepart === 'desc',
                        'text-secondary': !ccPlanSort.sortByDateDepart}"/>
                    </template>
                    Depart
                  </th>
                  <th scope="col" class="date-column align-middle cc-font-size" style="width: 10%;"
                      @click="crewChangePlanSort('sortByDateSignOn', true, 'ccOffSignOnDate')"
                  >
                    <template v-if="crewChangePlans && crewChangePlans.length != 0 && ccPlanSort.sortByDateSignOn">
                      <font-awesome-icon icon="arrow-up" :class="{'text-success': ccPlanSort.sortByDateSignOn === 'asc',
                        'text-secondary': !ccPlanSort.sortByDateSignOn}"/>

                      <font-awesome-icon icon="arrow-down" :class="{'text-success': ccPlanSort.sortByDateSignOn === 'desc',
                        'text-secondary': !ccPlanSort.sortByDateSignOn}"/>
                    </template>
                    Joined
                  </th>
                  <th scope="col" colspan="2" class="align-middle cc-font-size" style=" width: 8%;"
                      @click="crewChangePlanSort('sortByDateEoc', true, 'ccOffEocDateFromDocLatestContract')"
                  >
                    <template v-if="crewChangePlans && crewChangePlans.length != 0 && ccPlanSort.sortByDateEoc">
                      <font-awesome-icon icon="arrow-up" :class="{'text-success': ccPlanSort.sortByDateEoc === 'asc',
                        'text-secondary': !ccPlanSort.sortByDateEoc}"/>

                      <font-awesome-icon icon="arrow-down" :class="{'text-success': ccPlanSort.sortByDateEoc === 'desc',
                        'text-secondary': !ccPlanSort.sortByDateEoc}"/>
                    </template>
                    EOC
                  </th>
                  <th scope="col" class="text-center align-middle cc-font-size" style=" width: 5%; height: auto;"
                      @click="crewChangePlanSort('sortByMosOnboard', false, 'monthsOnBoard')"
                  >
                    <template v-if="crewChangePlans && crewChangePlans.length != 0 && ccPlanSort.sortByMosOnboard">
                      <font-awesome-icon icon="arrow-up" :class="{'text-success': ccPlanSort.sortByMosOnboard === 'asc',
                        'text-secondary': !ccPlanSort.sortByMosOnboard}"/>

                      <font-awesome-icon icon="arrow-down" :class="{'text-success': ccPlanSort.sortByMosOnboard === 'desc',
                        'text-secondary': !ccPlanSort.sortByMosOnboard}"/>
                    </template>

                    Mo(s)
                    On
                    Board
                  </th>
                  <th scope="col" colspan="4" class="crew-name align-middle cc-font-size"
                      @click="crewChangePlanSort('sortBySignOnCrew', true, 'crewSignOn')"
                  >
                    <template v-if="crewChangePlans && crewChangePlans.length != 0 && ccPlanSort.sortBySignOnCrew">
                      <font-awesome-icon icon="arrow-up" :class="{'text-success': ccPlanSort.sortBySignOnCrew === 'asc',
                        'text-secondary': !ccPlanSort.sortBySignOnCrew}"/>

                      <font-awesome-icon icon="arrow-down" :class="{'text-success': ccPlanSort.sortBySignOnCrew === 'desc',
                        'text-secondary': !ccPlanSort.sortBySignOnCrew}"/>
                    </template>
                    Reliever
                  </th>
                  <th scope="col" class="align-middle cc-font-size" style=" width: 10%;"
                      @click="crewChangePlanSort('sortByCcmNo', true, 'cBatchingNo')"
                  >

                    <template v-if="crewChangePlans && crewChangePlans.length != 0 && ccPlanSort.sortByCcmNo">
                      <font-awesome-icon icon="arrow-up" :class="{'text-success': ccPlanSort.sortByCcmNo === 'asc',
                        'text-secondary': !ccPlanSort.sortByCcmNo}"/>

                      <font-awesome-icon icon="arrow-down" :class="{'text-success': ccPlanSort.sortByCcmNo === 'desc',
                        'text-secondary': !ccPlanSort.sortByCcmNo}"/>
                    </template>
                    CCM No
                  </th>
                </tr>

                </thead>
                <tbody>
                <tr v-if="isReloading">
                  <td colspan="16">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading . . .
                  </td>
                </tr>

                <template v-else>
                  <!--                  crewChangePlans-->
                  <tr v-if="!crewChangePlans">
                    <td colspan="16">
                      <span>Please select vessel to generate Change Plan</span>
                    </td>
                  </tr>
                  <!--                  crewChangePlans-->
                  <tr v-if="crewChangePlans.length !== 0" v-for="(crewChangePlan,index) in crewChangePlans"
                      :key="index">
                    <td class="cc-font-size">
                      <div class="row">
                        <div class="col">
                          {{ crewChangePlan.rankAlias?.toUpperCase() }}
                          <button v-if="!crewChangePlan.cBatchingId && !crewChangePlan.ccPromotionId && crewChangePlan.crewSignOff === null"
                                  class="btn btn-xs btn-light"
                                  @click="deleteRankChangePlan(crewChangePlan)">
                            <font-awesome-icon icon="times" class="text-danger"/>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td colspan="2" class="text-left align-middle cc-font-size" style="width: 20%">
                      <div class="row">
                        <div class="col-8">
                        <span v-if="crewChangePlan.crewSignOff">
                        <span v-if="crewChangePlan.cOffType !== (3 || 4)">
                          <a href="#"
                             style="font-size: 0.8rem !important;"
                             @click="gotoProfile(crewChangePlan.crewOffId,crewChangePlan.cOffType)">
                            {{ crewChangePlan.crewSignOff.toUpperCase() }}
                          </a>
                        </span>
                       <span v-else class="ml-2">{{ crewChangePlan.crewSignOff.toUpperCase() }}</span>
                    </span>
                        </div>

                        <div class="col-4">
                          <img class="custom-icon-size " :src="higherLicensePng" alt="higher license"
                               title="Crew Has Higher license" v-if="crewChangePlan.ccOffHasHigherLicense"/>
                          <img class="custom-icon-size " :src="docsExpiryPng" alt="doc expiry"
                               title="Crew has near expiry document" v-if="crewChangePlan.ccOffHasNearExpiryDoc"/>
                          <img type="button"
                               data-toggle="tooltip" data-placement="right"
                               class="custom-icon-size "
                               :src="docExpiredPng" alt="doc expired" title="Crew has expired document"
                               v-if="crewChangePlan.ccOffHasExpiredDoc"/>
                        </div>
                      </div>
                    </td>

                    <!--  ccm no remarks no -->
                    <td style="width: 4%;">
                      <button v-if="crewChangePlan.crewSignOff && crewChangePlan.cOffType !== (3 || 4)"
                              class="btn btn-img"
                              data-toggle="modal"
                              data-target="#crew-change-remarks-modal"
                              data-backdrop="static"
                              data-keyboard="false"
                              @click="getCrewChangeRemarks(crewChangePlan.ccOffId,2)">
                        <img class="custom-icon-size" v-if="crewChangePlan.ccOffRemarksCount == 0" :src="addCommentPng"
                             title="Add comment on crew" alt="add comment on crew">
                        <img class="custom-icon-size" v-if="crewChangePlan.ccOffRemarksCount > 0" :src="viewCommentPng"
                             title="View comment on crew" alt="view comment on crew">
                        <sup v-if="crewChangePlan.ccOffRemarksCount"
                             class="badge badge-info">{{ crewChangePlan.ccOffRemarksCount }}</sup>
                      </button>
                    </td>

                    <!-- Delete Sign Off Crew  -->
                    <td style="width: 1%;">
                      <!-- && crewChangePlan.cOffType === (3 || 4) -->
                      <div v-if="crewChangePlan.ccOffId
                    && (!crewChangePlan.ccOffSignOnDate && !crewChangePlan.eocDate)
                    && !crewChangePlan.cBatchingId
                    && !crewChangePlan.ccRecProActualize
                    && !crewChangePlan.ccPromotionId">
                        <button class="btn btn-xs btn-light" @click="deleteCrewChange(crewChangePlan,2)">
                          <font-awesome-icon icon="times" class="text-danger"/>
                        </button>
                      </div>

                      <!-- Adding Sign Off Crew -->
                      <div v-if="!crewChangePlan.crewSignOff && !crewChangePlan.cBatchingId" class="text-center">
                        <button class="btn btn-xs btn-img"
                                @click="settingCrewChangePlan({crewChangePlan},2,crewChangePlan.rankId,crewChangePlan.ccrId)">
                          <img class="custom-icon-size" :src="addCrewIcon" alt="Adding Crew">
                        </button>
                      </div>
                    </td>

                    <!-- recommendation of promotion -->
                    <td class="text-center align-middle">
                      <button v-if="(crewChangePlan.crewSignOff && crewChangePlan.ccOffSignOnDate)
                  && crewChangePlan.cOffType !== 3
                  && crewChangePlan.cOffType !== 4
                  && crewChangePlan.rankId != 1
                  && !crewChangePlan.ccRecProActualize"
                              title="Recommendation for promotion"
                              class="btn btn-xs"
                              :class="{
                            'pmc-btn-prime' : crewChangePlan.ccPromotionId,
                            'btn-secondary' : crewChangePlan.ccPromotionId == null
                        }"
                              @click="forPromotion(crewChangePlan)">
                        REC
                        <font-awesome-icon icon="arrow-up" class="fa-1x" :class="{
                    'pmc-text-success':crewChangePlan.ccPromotionDateApproved,
                    'pmc-text-prime': !crewChangePlan.ccPromotionId,
                    'pmc-text-warning' : crewChangePlan.ccPromotionId && !crewChangePlan.ccPromotionDateApproved
                  }"/>
                      </button>

                      <button v-if="crewChangePlan.ccRecProActualize" class="btn btn-sm pmc-btn-tertia"
                              @click="getRecommendation(crewChangePlan)"
                              title="Actualized recommendation">
                        <font-awesome-icon icon="arrow-up"/>
                        ACT
                      </button>
                    </td>

                    <!-- departure manila date -->
                    <td class="text-center align-middle cc-font-size">
                      {{ crewChangePlan.ccOffDepartureDate | moment("DD - MMM - YY") }}
                    </td>

                    <!-- sign on date -->
                    <td class="text-center align-middle cc-font-size">
                      {{ crewChangePlan.ccOffSignOnDate | moment("DD - MMM - YY") }}
                    </td>

                    <!-- end of contract date -->
                    <td class="text-center align-middle cc-font-size">

                      <span v-if="crewChangePlan.isExtension && findLatestDate(crewChangePlan.ccOffEocDateFromDocLatestContract, crewChangePlan.ccOffEocDate )"
                            class="text-primary"
                            title="Extended Contract">
                          {{ crewChangePlan.ccOffEocDateFromDocLatestContract | moment("DD - MMM - YY") }}
                      </span>

                      <span @click="viewEocDate(crewChangePlan.ccOffEocDate, crewChangePlan.ccOffId)" v-else>
                        {{ crewChangePlan.ccOffEocDate | moment("DD - MMM - YY") }}
                      </span>

                    </td>

                    <!-- Ext/Sho  -->
                    <td style="width: 1%;">
                      <button v-if="crewChangePlan.ccOffEocDateFromDocLatestContract" class="btn btn-img"
                              @click="forExtensionContract(crewChangePlan)">
                        <img class="custom-icon-size" :src="addExtShortPng" alt="add/shorten extension contract">
                      </button>
                    </td>

                    <!-- months on board -->
                    <td class="text-center align-middle cc-font-size">
                      {{ crewChangePlan.monthsOnBoard }}
                    </td>

                    <!-- crew change: sign on crew -->
                    <td colspan="2" class="text-left align-middle" style="width: 20%">
                      <div class="row">
                        <div class="col-8">
                       <span v-if="crewChangePlan.crewSignOn">
                        <span v-if="crewChangePlan.cOnType !== (3 || 4)">
                           <a href="#"
                              style="font-size: 0.8rem !important;"
                              @click="gotoProfile(crewChangePlan.crewOnId,crewChangePlan.cOnType)">
                             {{ crewChangePlan.crewSignOn.toUpperCase() }}
                           </a>
                        </span>
                        <span v-else class="ml-2 small">{{ crewChangePlan.crewSignOn }}</span>
                      </span>
                        </div>
                        <div class="col-4 justify-content-center align-content-center">
                          <img class="custom-icon-size" :src="higherLicensePng" alt="higher license"
                               title="Crew has higher license" v-if="crewChangePlan.ccOnHasHigherLicense"/>
                          <img class="custom-icon-size" :src="docsExpiryPng" alt="doc expiry"
                               title="Crew has expiry documents" v-if="crewChangePlan.ccOnHasNearExpiryDoc"/>
                          <img class="custom-icon-size" :src="docExpiredPng" alt="doc expired"
                               title="Crew has expired documents" v-if="crewChangePlan.ccOnHasExpiredDoc"/>
                        </div>
                      </div>
                    </td>

                    <!-- cc remarks -->
                    <td style="width: 4%;">
                      <div v-if="crewChangePlan.ccOnId">
                        <button class="btn btn-img"
                                data-toggle="modal"
                                data-target="#crew-change-remarks-modal"
                                data-backdrop="static"
                                data-keyboard="false"
                                @click="getCrewChangeRemarks(crewChangePlan.ccOnId,1)">
                          <img class="custom-icon-size" v-if="crewChangePlan.ccOnRemarksCount == 0" :src="addCommentPng"
                               alt="add comment on crew">
                          <img class="custom-icon-size" v-if="crewChangePlan.ccOnRemarksCount > 0" :src="viewCommentPng"
                               alt="view comment on crew">
                          <sup class="badge badge-info"
                               v-if="crewChangePlan.ccOnRemarksCount > 0">{{ crewChangePlan.ccOnRemarksCount }}</sup>
                        </button>
                      </div>
                    </td>

                    <td style="width: 1%;">
                      <!--   Adding Sign On Crew  -->
                      <button v-if="!crewChangePlan.ccOnId" class="btn btn-xs btn-img"
                              @click="settingCrewChangePlan({crewChangePlan},1,crewChangePlan.rankId)">
                        <img :src="addCrewIcon">
                      </button>
                      <button v-if="crewChangePlan.ccOnId && !crewChangePlan.cBatchingNo"
                              class="btn btn-xs btn-img"
                              @click="deleteCrewChange(crewChangePlan,1)"> <!-- deleteCrewSignOn  -->
                        <img class="custom-icon-size" :src="deleteCrewPng" title="Delete Crew"/>
                      </button>
                      <span v-if="crewChangePlan.ccOnId && crewChangePlan.cBatchingNo">--</span>
                    </td>

                    <!-- CCM NO -->
                    <td id="checkbox-row">
                      <input v-if="crewChangePlan.ccOnId && !crewChangePlan.cBatchingNo" :id="'cc-checkbox-'+index"
                             type="checkbox" class="form-check-inline this-custom-check"
                             @click="addForCCMemo(crewChangePlan.ccOnId,crewChangePlan.rankId,$event)">

                      <button v-if="crewChangePlan.cBatchingNo" class="btn btn-xs btn-light"
                              @click="crewChangeMemo(crewChangePlan.cBatchingId, crewChangePlan.cBatchingNo)">
                        <span class="font-weight-bold">{{ crewChangePlan.cBatchingNo }}</span>
                      </button>

                      <sup class="badge badge-success small" v-if="crewChangePlan.cBatchingRevision">
                  <span v-if="Object.is(Math.abs(crewChangePlan.cBatchingRevision),+crewChangePlan.cBatchingRevision)">
                    {{ `+` + crewChangePlan.cBatchingRevision }}
                  </span>
                        <span
                            v-if="!Object.is(Math.abs(crewChangePlan.cBatchingRevision),+crewChangePlan.cBatchingRevision)">
                       {{ crewChangePlan.cBatchingRevision }}
                  </span>
                      </sup>
                    </td>
                  </tr>

                </template>

                </tbody>
                <tfoot>
                <slot name="table-footer"></slot>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- CC Memo Modal   -->
    <div class="modal fade" name="crewChangeMemoModal" id="crew-change-memo-modal" tabindex="-10" role="dialog"
         aria-labelledby="ccMemoModal"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered full-screen-modal2" role="document">
        <div class="modal-content" style="height: 100%;">
          <div class="modal-header">
            <h4 class="modal-title" id="cc-modal-header-label">Crew Change Memo</h4>
            <button type="button" class="border-0" data-dismiss="modal" aria-label="Close"
                    @click="cancelCrewChangeMemo">
              <!--<span aria-hidden="true">&times;</span>-->
              <span aria-hidden="true" class="text-danger">
                <font-awesome-icon icon="times"/>
              </span>
              Close
            </button>
          </div>
          <div class="modal-body">
            <!--  :load-data="loadCcMemoData" -->
            <crew-change-memo :crews="selectedCrewChange"
                              :bus="bus"
                              :vessel-id="selectedVessel"/>
          </div>
          <!--          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="cancelCrewChangeMemo">
              Cancel
            </button>
          </div>-->
        </div>
      </div>
    </div>

    <!-- CC Adding CREW Modal    -->
    <div class="modal fade"
         name="ccAddCrewModal"
         id="cc-add-crew-modal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="ccAddCrewModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered full-screen-modal" role="document">
        <div class="modal-content" style="height: 100%;">
          <div class="modal-header">
            <h4 class="modal-title" id="cc-modal-header-label">Add Crew</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancelAddingCrew">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row text-left">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="crew-type-selections" class="col-form-label col-md-4">
                    FILTER BY
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-md-7">
                    <select name="crewTypeSelections"
                            id="crew-type-selections"
                            v-model="activeIndexTypeSelection"
                            class="form-control form-control-sm font-weight-bold"
                            @change="selectCrewType()">
                      <option :value="type.id"
                              v-for="(type,index) in crewTypeSelections"
                              :style="`background-color: ${type.backGroundColor} !important; color: ${type.color} !important;`"
                              class="text-left align-middle pl-lg-5 font-weight-bold"
                              :selected="activeIndexTypeSelection == type.id"
                              :key="type.id">
                        {{ type.name.toUpperCase() }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <pmc-table :loading="isReloading">
                  <template v-slot:table-header>
                    <tr>
                      <th style="width: 2%; " class="text-center align-middle">#</th>
                      <th style="width: 2%; " class="text-center align-middle">Rank</th>
                      <th style="width: 20%;" class="crew-name" colspan="2">Crew Name</th>
                      <th style="width: 12%;" class="text-center align-middle">
                        {{ activeIndexTypeSelection == 2 ? 'Current' : 'Last' }} Vessel
                      </th>
                      <th style="width: 10%;" class="text-center align-middle">Sign Off</th>
                      <th style="width: 10%" class="text-center align-middle">Availability</th>
                      <th style="width: 7%" class="text-center align-middle">TOB</th>
                      <th style="width: 5%;" class="text-center align-middle">Action</th>
                    </tr>
                  </template>
                  <template v-slot:table-body>
                    <tr v-for="(availableCrew,index) in availableCrewList" :key="availableCrew.ccId">
                      <td>{{ index + 1 }}</td>
                      <td class="p-3 text-center align-middle font-weight-bold">
                        {{ availableCrew.crewRankName }}
                      </td>
                      <td class="p-3 text-left align-middle">
                        <a href="#" class="text-dark font-weight-bold" @click="gotoProfile(availableCrew.crewId)">
                          <div class="row">
                            <div :class="availableCrew.crewStatus === 0 ? 'crew-status-inactive' : ''" class="col">
                              <span class="font-weight-bold">{{ availableCrew.crewName }}</span>
                            </div>
                            <div class="col">
                              <img v-if="availableCrew.hasHigherLicense" class="btn-img custom-icon-size"
                                   :src="higherLicensePng"/>
                              <img v-if="availableCrew.hasNearingExpiryDocs" class="btn-img custom-icon-size ml-1"
                                   :src="docsExpiryPng"/>
                              <img v-if="availableCrew.hasExpiredDocs" class="btn-img custom-icon-size ml-1"
                                   :src="docExpiredPng"/>
                              <img v-if="availableCrew.isDeceased" class="btn-img custom-icon-size ml-1"
                                   :src="deceasedIcon"/>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <span class="small text-danger"
                                    v-if="availableCrew.latestCcVesselName
                                    && activeIndexTypeSelection != 6
                                    && activeIndexTypeSelection != 7">
                                ({{
                                  availableCrew.latestCcVesselName
                                }})
                              </span>
                            </div>
                          </div>
                        </a>
                      </td>
                      <td>--</td>
                      <td class="ml-5 p-3 text-left align-middle">{{ availableCrew.lastCcVesselName }}</td>
                      <td class="ml-2 p-3 text-center align-middle">{{ availableCrew.ccSignOffDate }}</td>
                      <td class="ml-2 p-3 text-center align-middle">{{ availableCrew.crewDateAvailable }}</td>
                      <td class="ml-2 p-3 text-center align-middle">{{ availableCrew.countCrewOnBoard }}</td>
                      <td class="ml-2 p-3 align-middle">
                        <button
                            v-if="!availableCrew.isLinedUp || (activeIndexTypeSelection == 6 || activeIndexTypeSelection == 7 || activeIndexTypeSelection == 2 )"
                            class="btn btn-sm pmc-btn-prime"
                            @click="addingChangePlanCrew(availableCrew)">
                          <span class="font-weight-bold">SELECT</span>
                        </button>
                        <span v-else class="text-danger font-weight-bold">LINED UP</span>
                      </td>
                    </tr>
                  </template>
                </pmc-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Setup CC plan   -->
    <div class="modal fade" id="setup-cc-plan" tabindex="-1" role="dialog" aria-labelledby="setupCcPlanCenter"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="height: 100%">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Setup Crew Change</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetCcPlanSetup">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <pmc-table :max-height="350" class="mt-3">
              <template v-slot:table-header>
                <tr>
                  <th style="width: 5%;">
                    <button class="btn btn-sm pmc-btn-prime" @click="addCcPlanSetup">
                      #Add
                    </button>
                  </th>
                  <th style="width: 30%;" class="text-center align-middle p-1">Rank</th>
                  <th style="width: 15%;" class="text-center align-middle p-1">Pax</th>
                  <th style="width: 5%;" class="text-center align-middle p-1">Action</th>
                </tr>
              </template>

              <template v-slot:table-body>
                <tr v-for="(ccPlan,index) in setUpCcPlans" :key="ccPlan.id">
                  <td class="text-center align-middle p-0">{{ index + 1 }}</td>
                  <td class="text-center align-middle p-0">
                    <select name="setUpCcPlanRank" id="setup-ccplan-rank" class="form-control form-control-sm"
                            v-model="ccPlan.rankId"
                            @change="onSelectedSetUpRank($event)">
                      <option :value="null"></option>
                      <option v-for="(rank,index) in objRanks" :value="rank.id" :key="rank.id">{{ rank.name }}</option>
                    </select>
                  </td>
                  <td class="text-center align-middle p-0">
                    <input type="number"
                           min="0"
                           step="1"
                           name="setupCcPlanPax"
                           id="setup-ccplan-pax"
                           v-model="ccPlan.pax"
                           class="form-control form-control-sm">
                  </td>
                  <td class="text-center align-middle p-0">
                    <button class="btn btn-xs btn-light" @click="removeCcPlanSetup(ccPlan.id)">
                      <font-awesome-icon icon="times" class="text-danger"/>
                    </button>
                  </td>
                </tr>
              </template>
            </pmc-table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm pmc-btn-tertia" @click="saveSetUpCcPlan">Save Changes</button>
            <!--<button type="button" class="btn btn-sm pmc-btn-prime" @click="saveSetUpCcPlanAsTemplate">Save as Template</button>-->
            <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Rank CC plan   -->
    <div class="modal fade" id="add-rank-cc-plan" tabindex="-1" role="dialog" aria-labelledby="addRankChangePlan"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="height: 100%">
          <div class="modal-header">
            <h5 class="modal-title">Add Rank Change Plan</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    @click="cancelAddRankChangePlan">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-group row justify-content-center">
              <label for="add-rank-ccplan" class="col-form-label col-md-4 font-weight-bold text-left">Rank</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <select name="addRankCcPlan" id="add-rank-ccplan" class="form-control form-control-sm"
                        v-model="formAddRankCp.rankId">
                  <option :value="null" class="text-center align-middle">-- SELECT --</option>
                  <option :value="rank.id" v-for="(rank,i) in objRanks" :key="rank.id">
                    {{ rank.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row mt-2 justify-content-center">
              <label for="add-pax-ccplan" class="col-form-label col-md-4 font-weight-bold text-left">Pax</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="number"
                       min="0"
                       :max="[formAddRankCp.rankId ? 2 : 0]"
                       step="1"
                       id="add-pax-ccplan"
                       v-model="formAddRankCp.pax"
                       class="form-control form-control-sm"/>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button @click="addRankChangePlan" class="btn btn-sm pmc-btn-prime">Save</button>
            <button type="button" @click="cancelAddRankChangePlan" class="btn btn-sm pmc-btn-danger"
                    data-dismiss="modal" aria-label="Close">Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Vessel Remark Modal    -->
    <div class="modal fade" id="vessel-remark-modal" tabindex="-1" role="dialog" aria-labelledby="addRankChangePlan"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" style="height: 100%">
          <div class="modal-header">
            <h4 class="modal-title">Vessel Remarks</h4>
            <button id="btn-add-vremark" type="button" class="btn pmc-btn-prime ml-4" @click="setAddVesselRemark()">
              Add
            </button>
            <button id="btn-cancel-vremark" type="button" hidden class="btn pmc-btn-danger ml-4"
                    @click="cancelAddVesselRemark()">Cancel
            </button>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    @click="closeAddVesselRemark">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-content px-2">
            <div class="card mt-3 mb-2" hidden id="add-vessel-remark-modal">
              <div class="card-body">
                <p class="text-left">
                  <input readonly disabled type="text" id="labelCount" size="1" value="1000" class=" border-0">
                  <span class="text-muted">*Characters Left</span>
                </p>
                <textarea name="AddVesselRemark"
                          v-model="newVesselRemark.remarks"
                          id="add-vessel-remark"
                          cols="30"
                          rows="3"
                          class="custom-text-area"
                          @keyup="limitTextRemarks(`add-vessel-remark`,'labelCount',1000)"
                          placeholder="Remarks here..."/>
                <hr>
                <div class="row">
                  <div class="col text-left align-middle">
                    <button type="button" class="btn pmc-btn-prime" @click="addVesselRemark">
                      <font-awesome-icon icon="save"/>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="custom-modal" :style="vesselRemarkContainerCss">
              <div class="card py-1 mb-2" v-if="vesselRemarks.length == 0">
                <div class="card-body">
                  <div class="card-text text-center">
                    No remarks found
                  </div>
                </div>
              </div>

              <div class="card py-1 mb-2" v-for="(vesselRemark,index) in vesselRemarks" :key="vesselRemark.id">
                <div class="card-body">
                  <div class="card-title">
                    <div class="row">
                      <div class="col-6 text-left">
                        <p class="small" style="margin-bottom : 0 !important;">{{ vesselRemark.createdBy }}</p>
                        <p class="small" style="margin-bottom : 0 !important;">{{ vesselRemark.createdAt }}</p>
                      </div>
                    </div>
                  </div>
                  <textarea name="VesselRemark"
                            class="custom-text-area"
                            disabled
                            :id="`vessel-remark-`+vesselRemark.id"
                            v-model="vesselRemark.remarks"
                            cols="30"
                            rows="3"
                  />
                  <hr>
                  <div class="row" v-if="vesselRemark.createdById === authUser.id">
                    <div class="col text-left align-middle">
                      <!-- edit -->
                      <button :id="`btn-edit-remark-${vesselRemark.id}`" class="btn btn-xs pmc-btn-warning"
                              @click="setEditableVesselRemarks(vesselRemark)">
                        <font-awesome-icon icon="edit"/>
                        Edit
                      </button>

                      <!--  update vessel remarks                    -->
                      <button :id="`btn-update-remark-${vesselRemark.id}`" class="btn btn-xs ml-1 pmc-btn-prime"
                              @click="updateVesselRemark(vesselRemark)" hidden>
                        <font-awesome-icon icon="save"/>
                        Update
                      </button>

                      <!--  cancel add vessel remarks -->
                      <button :id="`btn-cancel-remark-${vesselRemark.id}`" class="btn btn-xs ml-1 pmc-btn-second"
                              @click="cancelEditableVesselRemarks(vesselRemark)" hidden>
                        <font-awesome-icon icon="ban"/>
                        Cancel
                      </button>

                      <!-- delete vessel remarks -->
                      <button :id="`btn-delete-remark-${vesselRemark.id}`" class="btn btn-xs ml-1 pmc-btn-danger"
                              @click="deleteVesselRemark(vesselRemark.id)">
                        <font-awesome-icon icon="trash"/>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Add Crew Change Remarks   -->
    <div class="modal fade" id="crew-change-remarks-modal" tabindex="-1" role="dialog"
         aria-labelledby="crewChangeRemarksModalLabel"
         aria-hidden="true">
      <div class="modal-dialog full-screen-modal modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="add-crew-change-remarks-modal">Crew Change Remarks</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body" :style="ccRemModalCustomCss">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <textarea class="form-control form-control-sm border-0"
                              id="cc-remark-input"
                              name="ccRemarkInput"
                              cols="2"
                              rows="2"
                              style="resize: none;
                              min-height: 100%;
                              min-width: 100%;"
                              v-model="newCrewChangeRemark.remarks"
                              placeholder="New remarks here ..."/>
                    <div class="btn-group-sm mt-2 text-left">
                      <button class="btn pmc-btn-prime" @click="createCrewChangeRemark">
                        <font-awesome-icon icon="save"/>
                        Save
                      </button>

                      <button class="ml-2 btn btn-secondary" @click="clearChangeRemarkInput">
                        <font-awesome-icon icon="eraser"/>
                        Clear
                      </button>
                    </div>
                  </div>
                </div>

                <div class="card mt-2">
                  <div class="card-body custom-tag">
                    <div id="loader-content" v-if="isCmRemarks">
                      <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>

                    <div id="not-content-data" v-if="crewChangeRemarks.length === 0 && !isCmRemarks">
                      <div class="row">
                        <div class="col-12">
                          <div class="text-center align-middle">
                            <span>No remarks found.</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="has-content-data" v-if="crewChangeRemarks.length > 0 && !isCmRemarks">
                      <div class="table table-responsive-lg pmc-table border-top custom-tag"
                           :style="ccRemTexAreaCustomCss">
                        <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                          <thead>
                          <tr>
                            <th class="text-center align-middle p-1" style="width: 1%;">#</th>
                            <th class="text-center align-middle p-1" style="width: 30%;">Remarks</th>
                            <th class="text-center align-middle p-1" style="width: 10%;">Status</th>
                            <th class="text-center align-middle p-1" style="width: 15%;">Created at</th>
                            <th class="text-center align-middle p-1" style="width: 15%;">Updated at</th>
                            <th class="text-center align-middle p-1" style="width: 13%;">Created By</th>
                            <th class="text-center align-middle p-1" style="width: 10%;">Action</th>
                          </tr>
                          </thead>

                          <tbody>
                          <tr v-for="(ccRemark,index) in crewChangeRemarks" :key="ccRemark.id">
                            <td>{{ index + 1 }}</td>
                            <td class="text-left align-middle" style="word-wrap: break-word;" :class="{
                               'cc-cancel-css': ccRemark.tagStatus ===3,
                            }">
                              <textarea :name="`ccRemarks`+index" :id="`cc-remarks-`+index"
                                        class="form-control font-weight-bold border-0"
                                        :class="{
                                              'cc-open-css' : ccRemark.tagStatus === 1,
                                              'cc-close-css' : ccRemark.tagStatus === 2,
                                        }"
                                        cols="30"
                                        rows="2"
                                        disabled
                                        v-model="ccRemark.remarks"/>
                            </td>
                            <td class="text-center align-middle">
                              <select name="ccRemarkStatus" :id="`cc-remark-status-`+index" v-model="ccRemark.tagStatus"
                                      class="form-control"
                                      disabled>
                                <option :value="tag.id" v-for="(tag,index) in tagStatusOpt" :key="tag.id">
                                  {{ tag.name.toUpperCase() }}
                                </option>
                              </select>
                            </td>
                            <td class="text-left align-middle pl-2 small">{{ ccRemark.createdAt }}</td>
                            <td class="text-left align-middle pl-2 small">{{ ccRemark.updatedAt }}</td>
                            <td class="text-center align-middle">{{ ccRemark.createdBy }}</td>
                            <td class="text-center align-middle">
                              <button :name="`btnUpdateCcRemark`+index"
                                      :id="`btn-update-cc-remark-`+index"
                                      type="button"
                                      class="btn btn-sm pmc-btn-prime"
                                      @click="updateCrewChangeRemark(ccRemark,index)"
                                      hidden="hidden">Update
                              </button>

                              <button :name="`btnCancelCcRemark`+index"
                                      :id="`btn-cancel-cc-remark-`+index"
                                      type="button"
                                      class="btn btn-sm pmc-btn-second ml-1"
                                      @click="setCancelCrewChangeRemark(ccRemark,index)"
                                      hidden="hidden">Cancel
                              </button>

                              <button :name="`btnEditCcRemark`+index"
                                      :id="`btn-edit-cc-remark-`+index"
                                      type="button"
                                      class="btn btn-sm pmc-btn-warning"
                                      @click="setEditCrewChangeRemark(index)">Edit
                              </button>

                              <button :name="`btnDeleteCcRemark`+index"
                                      :id="`btn-delete-cc-remark-`+index"
                                      type="button"
                                      class="btn btn-sm pmc-btn-danger ml-2"
                                      @click="deleteCrewChangeRemark(ccRemark)">Delete
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Crew promotion modal   -->
    <div class="modal fade" id="crew-promotion-modal" tabindex="-1" role="dialog" aria-labelledby="crewPromotionTitle"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document" style="min-width: 40%;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="crewPromotionModalTitle">Crew Promotion Onboard</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    @click="closeModalCrewPromotion">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-body">
                <div class="card-title text-left align-middle">
                  <div class="row">
                    <div class="col-6">
                      <span style="font-size: 1rem !important;"
                            class="font-weight-bold">{{ crewPromotion.crewName }}</span>
                    </div>

                    <div class="col-6">
                      <span v-if="crewPromotion.ccpId && !crewPromotion.dateApproved"
                            class="text-muted small font-weight-bold pl-lg-5">*PENDING FOR APPROVAL</span>
                      <span v-if="crewPromotion.ccpId && crewPromotion.dateApproved"
                            class="small font-weight-bold pmc-text-tertia ml-lg-2">*APPROVED AT : {{
                          crewPromotion.dateApproved
                        }}</span>
                    </div>
                  </div>
                </div>

                <form class="align-content-center">
                  <!-- Current Rank -->
                  <div class="form-group row px-lg-4 text-left">
                    <label for="crew-current-rank" class="col-form-label col-sm-4">Current Rank</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input v-model="crewPromotion.currentRankName" type="text" name="crewCurrentRank"
                             id="crew-current-rank"
                             class="form-control form-control form-control-plaintext text-left align-middle pl-lg-3"
                             readonly>
                    </div>
                  </div>

                  <!-- To Rank -->
                  <div class="form-group row px-lg-4 text-left mt-2">
                    <label for="crew-promoted-rank" class="col-form-label col-sm-4">Recommend Rank</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <select v-model="crewPromotion.promRankId"
                              name="crewPromotedRank"
                              id="crew-promoted-rank"
                              class="form-control form-control-sm">
                        <option :value="null" class="text-center align-middle">-- SELECT --</option>
                        <option :value="rank.id" v-for="(rank,index) in objRankPromKeys" :key="rank.id"
                                class="text-left ml-lg-3 align-middle text-left align-middle pl-lg-3">
                          {{ rank.alias }} - {{ rank.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- Recommend Date-->
                  <div class="form-group row px-lg-4 text-left mt-2">
                    <label for="crew-recommend-date" class="col-form-label col-sm-4">Recommend Date</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input v-model="crewPromotion.dateRecommend"
                             type="date"
                             name="crewRecommendDate" id="crew-recommend-date"
                             title="Date today"
                             class="form-control form-control-sm text-left align-middle pl-lg-3"/>
                      <span class="small pmc-text-danger" v-if="!crewPromotion.dateRecommend">*Default today</span>
                    </div>
                  </div>

                  <hr>

                  <div class="btn btn-group mt-2">
                    <button v-if="!crewPromotion.ccpId" type="button" class="btn btn-sm pmc-btn-prime"
                            @click="addCrewRecommendation">Recommend
                    </button>
                    <button v-if="crewPromotion.ccpId" type="button" class="btn btn-sm pmc-btn-danger ml-2"
                            @click="deleteCrewRecommendation">
                      <font-awesome-icon icon="trash"/>
                      Delete Recommendation
                    </button>

                    <button v-if="crewPromotion.ccpId  && !crewPromotion.dateApproved" type="button"
                            class="btn btn-sm pmc-btn-tertia ml-2" @click="approveCrewRec">
                      <font-awesome-icon icon="thumbs-up"/>
                      Approve
                    </button>

                    <button v-if="crewPromotion.ccpId && crewPromotion.dateApproved" type="button"
                            class="btn btn-sm pmc-btn-second ml-2" @click="disApprovedCrewRec">
                      <font-awesome-icon icon="thumbs-down"/>
                      DisApprove
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!--<div class="modal-footer">
                      <button type="button" class="btn btn-primary">Save</button>
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>-->
        </div>
      </div>
    </div>


    <!-- Update EOC modal    -->
    <div class="modal fade" id="crew-eoc-modal" tabindex="-1" role="dialog" aria-labelledby="crewEocModalTitle"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document" style="min-width: 40%;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="crewExtendModalTile">Update EOC</h5>
            <button class="close" data-dismiss="modal" @click="closeCrewContractModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card">
              <div class="card-body">
                <!-- Crew end of contract -->
                <form class="align-content-center">

                  <div class="form-group row px-lg-4 text-left mt-lg-2">
                    <label for="crew-current-rank" class="col-form-label col-sm-5">EOC Date</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                      <input type="date"
                             name="crewEoc"
                             id="crew_eoc"
                             v-model="updateEocDateParams.eocDate"
                             class="form-control form-control text-center align-middle pl-lg-3">
                    </div>
                  </div>

                  <hr>

                  <button type="button" class="btn btn-sm pmc-btn-danger" @click="updateEocDate">
                    Update
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Crew extend modal    -->
    <div class="modal fade" id="crew-extend-modal" tabindex="-1" role="dialog" aria-labelledby="crewExtendModalTitle"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document" style="min-width: 40%;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="crewExtendModalTile">Contract Extension</h5>
            <button class="close" data-dismiss="modal" @click="closeCrewContractModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="card">
              <div class="card-body">
                <div class="card-title text-left align-middle">
                  <span class="font-weight-bold" style="font-size: 1.2rem;">{{ crewContract.crewSignOff }}</span>
                </div>

                <!-- Crew end of contract -->
                <form class="align-content-center">
                  <!--  <div class="form-group row px-lg-4 text-left">
                      <label for="crew-departure-manila-date" class="col-form-label col-sm-5">Departure Manila Date</label>
                      <span class="col-form-label">:</span>
                      <div class="col-md-6">
                        <input type="date"
                               name="crewDepartureManilaDate"
                               id="crew-departure-manila-date"
                               v-model="crewContract.ccOffDepartureDate"
                               readonly
                               class="form-control form-control text-center align-middle pl-lg-3">
                      </div>
                    </div>-->

                  <!--<div class="form-group row px-lg-4 text-left mt-lg-2">
                      <label for="crew-joined-date" class="col-form-label col-sm-5">Joined Date</label>
                      <span class="col-form-label">:</span>
                      <div class="col-md-6">
                        <input type="date"
                               name="crewJoinedDate"
                               id="crew-joined-date"
                               v-model="crewContract.ccOffSignOnDate"
                               readonly
                               class="form-control form-control text-center align-middle pl-lg-3">
                      </div>
                    </div>-->

                  <div class="form-group row px-lg-4 text-left mt-lg-2">
                    <label for="crew-current-rank" class="col-form-label col-sm-5">Current Doc No.</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                      <span>
                      {{ crewContract.currentCrewDocNo }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row px-lg-4 text-left mt-lg-2">
                    <label for="crew-current-rank" class="col-form-label col-sm-5">Current Contract</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                      <span>
                      {{ crewContract.currentCrewDocExtensionType ?? crewContract.currentCrewDocName }}
                      </span>
                    </div>
                  </div>


                  <div class="form-group row px-lg-4 text-left mt-lg-2">
                    <label for="crew-current-rank" class="col-form-label col-sm-5">Select Contract Type</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                      <multi-select
                          v-model="renewCrewExtensionTypeDocId"
                          :options="renewThisObjDocs"
                          :custom-label="docs_lang"
                          :select-label="'Select Document'"
                          placeholder="Select Document"
                          label="Document"
                          track-by="id">
                      </multi-select>
                    </div>
                  </div>


                  <div class="form-group row px-lg-4 text-left mt-lg-2">
                    <label for="crew-current-rank" class="col-form-label col-sm-5">Ranks</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                      <multi-select
                          v-model="renewCrewDocRankId"
                          :options="objRanks"
                          :custom-label="ranks_lang"
                          :select-label="'Select Document'"
                          placeholder="Select Document"
                          label="Document"
                          track-by="id">
                      </multi-select>
                    </div>
                  </div>

                  <div class="form-group row px-lg-4 text-left mt-lg-2">
                    <label for="crew-current-rank" class="col-form-label col-sm-5">Document No.</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                      <input type="text"
                             v-model="renewCrewDocument.documentNo"
                             class="form-control form-control-sm"
                             name="FamFirst"
                             placeholder="--">
                    </div>
                  </div>

                  <div class="form-group row px-lg-4 text-left mt-lg-2">
                    <label for="crew-current-rank" class="col-form-label col-sm-5">Document No. 2</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                      <input
                          v-model="renewCrewDocument.documentNo2"
                          type="text"
                          class="form-control form-control-sm"
                          name="FamFirst"
                          placeholder="--">
                    </div>
                  </div>

                  <div class="form-group row px-lg-4 text-left mt-lg-2">
                    <label for="crew-current-rank" class="col-form-label col-sm-5">Date Issued</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                      <input type="date"
                             name="crewEoc"
                             id="crew_eoc"
                             v-model="renewCrewDocument.dateIssued"
                             class="form-control form-control text-center align-middle pl-lg-3">
                    </div>
                  </div>


                  <!--                  <div class="form-group row px-lg-4 text-left mt-lg-2">-->
                  <!--                    <label for="crew-current-rank" class="col-form-label col-sm-5">End of contract</label>-->
                  <!--                    <span class="col-form-label">:</span>-->
                  <!--                    <div class="col-md-6">-->
                  <!--                      <input type="date"-->
                  <!--                             name="crewEoc"-->
                  <!--                             id="crew_eoc"-->
                  <!--                             v-model="crewContract.ccOffEocDateFromDocLatestContract"-->
                  <!--                             class="form-control form-control text-center align-middle pl-lg-3">-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <div class="form-group row px-lg-4 text-left mt-lg-2">
                    <label for="crew-current-rank" class="col-form-label col-sm-5">Date Expiry</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                      <input type="date"
                             name="crewEoc"
                             id="crew_eoc"
                             v-model="renewCrewDocument.dateExpiry"
                             class="form-control form-control text-center align-middle pl-lg-3">
                    </div>
                  </div>

                  <div class="form-group row px-lg-4 text-left mt-lg-2">
                    <label for="crew-current-rank" class="col-form-label col-sm-5">Attachment</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                      <input type="file"
                             style="resize: none !important;"
                             class="form-control-file"
                             id="renewDocFile"
                             placeholder="--">
                    </div>
                  </div>


                  <hr>

                  <button type="button" class="btn btn-sm pmc-btn-danger" @click="extendDocumentContract">
                    Extend
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import appMixins, {AppMixins} from "@/mixins/app.mixins"
import alertMixins, {AlertMixins} from "@/mixins/alert.mixins"
import viewCommentIcon from '@/assets/viewcomment.png';
import addCommentIcon from "@/assets/addComment.png";
import docExpiryIcon from "@/assets/docs_expiry.png";
import docsExpired from "@/assets/docs_expired.png";

import addExtensionIcon from "@/assets/addExtshort.png";

import higherLicenseIcon from "@/assets/HL.png"
import addCrewIcon from "@/assets/addCrew.png";

import deceasedIcon from "@/assets/deceased.png";

import deleteCrewIcon from "@/assets/deleteCrew.png";
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";

import crew from "@/views/masterlist/Crew";
import CrewProfile from "@/components/modules/crewprofile/CrewProfile";

import {global_variables_config} from "@/config/global-variable.config";
import {toUpperCase} from "uri-js/dist/esnext/util";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";

export default {
  name: 'CrewChangePlan',
  mixins: [AppMixins, AlertMixins, CommonMixins],
  // props : {},

  components: {
    CrewProfile,
  },

  data() {
    return {
      manningName: null,
      flagName: null,
      selectedVessel: null,
      reloadTable: false,
      //loadCcMemoData: false,
      bus: new Vue(),
      crewBatchingId: 0,
      crewTypeSelections: [
        {id: 1, name: 'standby crew', code: 'st', backGroundColor: `#004000`, color: `#ffffff`},
        {id: 2, name: 'onboard crew', code: 'oc', backGroundColor: `#0B2D6A`, color: `#ffffff`},
        {id: 3, name: 'promoted crew', code: 'pc', backGroundColor: `#400040`, color: `#ffffff`},
        {id: 4, name: 'applicant', code: 'a', backGroundColor: `#065403`, color: `#ffffff`},
        {id: 5, name: 'inactive', code: 'in', backGroundColor: `#120308`, color: `#ffffff`},
        {id: 6, name: 'dummy crew', code: 'dc', backGroundColor: `#ababab`, color: `#120308`},
        {id: 7, name: 'reverting rank', code: 'rr', backGroundColor: `#DC4C4DFF`, color: `#ffffff`},
      ],
      activeIndexTypeSelection: 1,
      selectedCc: {},
      selectedCcPlanType: 1, // default to (reliever / on boarding)
      selectedRankId: null,

      //use for adding rank in change plans
      formAddRankCp: {
        vesselId: null,
        rankId: null,
        pax: null
      },

      vesselRemarkContainerCss: ``,
      isEditableRemark: false,
      ccRemTexAreaCustomCss: ``,
      ccRemModalCustomCss: ``,

      formCrewChangeId: null,

      /**
       *@deprecated
       */
      formCrewChangeRemark: {},
      formSelectedCrewId: null,


      // crew change plan filter
      selectedFilterOrder: null,
      isCcmNoOrder: false,
      isRankingOrder: false,


      crewChangePlanFormParam: {
        vesselId: null,
        selectedFilterOrder: 1,
      },

      ccPlanSort: {
        sortByRank: '',
        sortBySignOffCrew: '',
        sortByDateDepart: '',
        sortByDateSignOn: '',
        sortByDateEoc: '',
        sortByMosOnboard: '',
        sortBySignOnCrew: '',
        sortByCcmNo: '',
      },

      formParam: {
        docType: 1,  //default document
        crewId: null,
        docCategory: null,
      },


      contracts: {
        contractOfEmployment: 472,
        contractOfEmployment_1st: 856,
        contractOfEmployment_2nd: 861,
        contractOfEmployment_3rd: 862,
        contractOfEmployment_onboard_promotion: 857,
        contractOfEmployment_salary_amendment: 858,
      },

      renewCrewDocument: {
        crewDocId: '',
        documentId: '',
        documentName: '',
        documentNo: '',
        documentNo2: '',
        rankId: '',
        dateIssued: '',
        dateExpiry: '',
        isExtension: 0,
        extensionTypeOld: '',
        extensionTypeNew: '',
      },

      updateEocDateParams: {
        id: null,
        eocDate: null,
      },

    }
  },

  computed: {
    ...mapGetters(
        [
          'objVessels',
          'crewChangePlans',
          'isReloading',
          'selectedCrewChange', //selected crew change for crew change memo batching, arrays of cc id,
          "setUpCcPlans",
          "objRanks",
          "availableCrewList",
          "vesselRemark",
          "vesselRemarks",
          "newVesselRemark",
          "isLoadingVesselRemark",
          "authUser",

          "crewChangeRemarks",
          "crewChangeRemark",
          "newCrewChangeRemark",
          "tagStatusOpt",
          "isCmRemarks",

          //TODO
          "crewPromotion",
          "crewPromotions",
          "newCrewPromotion",
          "objRankPromKeys",
          "crewContract",
          "remarksCount",

          "objDocs",
          "newCrewDocument",

          // contract extension purposes
          'newCrewDocument',
        ]
    ),

    /**  asset icons  **/
    viewCommentPng() {
      return viewCommentIcon;
    },

    addCommentPng() {
      return addCommentIcon;
    },

    docsExpiryPng() {
      return docExpiryIcon;
    },

    docExpiredPng() {
      return docsExpired;
    },

    //addextshort
    addExtShortPng() {
      return addExtensionIcon;
    },

    higherLicensePng() {
      return higherLicenseIcon;
    },

    deleteCrewPng() {
      return deleteCrewIcon;
    },

    addCrewIcon() {
      return addCrewIcon;
    },

    deceasedIcon() {
      return deceasedIcon;
    },

    renewThisObjDocs() {
      return this.objDocs.filter(item => item.id === this.contracts.contractOfEmployment_1st ||
          item.id === this.contracts.contractOfEmployment_2nd ||
          item.id === this.contracts.contractOfEmployment_3rd ||
          item.id === this.contracts.contractOfEmployment_onboard_promotion ||
          item.id === this.contracts.contractOfEmployment_salary_amendment);
    },

    // thisObjDocs() {
    //   let filtered = this.objDocs.filter((docx, index) => {
    //     return this.crewDocuments.filter((cdocx, index) => {
    //       return cdocx.documentId == docx.id;
    //     }).length == 0;
    //   });
    //
    //   return filtered.filter(item => item.id === this.contracts.contractOfEmployment ||
    //       item.id === this.contracts.contractOfEmployment_1st ||
    //       item.id === this.contracts.contractOfEmployment_2nd  ||
    //       item.id === this.contracts.contractOfEmployment_3rd  ||
    //       item.id === this.contracts.contractOfEmployment_onboard_promotion ||
    //       item.id === this.contracts.contractOfEmployment_salary_amendment
    //   );
    // },

    newCrewDocumentId: {
      get() {
        let obj = this.objDocs.find((doc) => doc.id === this.newCrewDocument.documentId);
        return obj;
      },
      set(val) {
        this.newCrewDocument.documentId = val ? val.id : null;
      },
    },

    newCrewDocRankId: {
      get() {
        return this.objRanks.find((doc) => doc.id === this.newCrewDocument.rankId);
      },
      set(val) {
        this.newCrewDocument.rankId = val ? val.id : null;
      },
    },

    renewCrewExtensionTypeDocId: {
      get() {
        let obj = this.objDocs.find((doc) => doc.id === this.renewCrewDocument.extensionTypeNew);
        return obj;
      },
      set(val) {
        this.renewCrewDocument.extensionTypeNew = val ? val.id : '';
      },
    },

    renewCrewDocRankId: {
      get() {
        let obj = this.objRanks.find((doc) => doc.id === this.renewCrewDocument.rankId);
        return obj;
      },
      set(val) {
        this.renewCrewDocument.rankId = val ? val.id : '';
      },
    },


  },

  methods: {
    toUpperCase,
    ...mapActions([
      'reset_state_vessel_remarks',
      'set_crew_change_plans',
      'set_selected_crew_changed',
      'delete_selected_crew_change',
      'reset_selected_crew_change',
      'adding_crew_change_plan',
      'get_available_crew_list',
      "add_ccplan_setup",
      "remove_ccplan_setup",
      "reset_ccplan_setup",
      "null_obj_ranks",
      "null_obj_vessels",
      "save_setup_ccplan",
      'add_rank_change_plan',
      'delete_rank_change_plan',
      'delete_crew_change',
      'set_vessel_remark',
      'set_vessel_remarks',
      'create_vessel_remark',
      'update_vessel_remark',
      'delete_vessel_remark',
      'reset_state_vessel_remarks',
      'set_null_vessel_remarks',
      'reset_available_crews',
      'reset_state_crew_change_plan',
      'get_cc_memo_details',
      'getCrewComplimentPerVessel',

      //TODO crew change remarks module
      'set_crew_change_remarks',
      'set_crew_change_remark',
      'create_crew_change_remark',
      'update_crew_change_remark',
      'delete_crew_change_remark',
      'reset_state_crew_change_remark',
      "set_form_crew_change_remark",

      "create_new_promotion",
      "update_crew_promotion",
      "delete_crew_promotion",

      "get_obj_rank_prom_keys", //optional
      "get_crew_promotion",
      'reset_state_crew_promotion',

      "extend_crew_contract",
      "set_crew_contract",
      "reset_null_crew_contract",

      "getDocumentKeys",

      // contract extension purposes
      "renewCrewDoc",
      "createCrewDoc",
      "nullNewCrewDocuments",
      "nullNewCrewDocFile",

        // update eoc date plan
        'updateEOCDateCrewChangePlan',

    ]),

    async crewChangeMemo(crewBatchingId, crewBatchingNo) {
      if (crewBatchingId == 0) {
        let msgHtml = `Do you want to create batch of change memo for this following records?`
        if (await this.questionAlert(`${msgHtml}`, 'Crew Change Memo')) {
          $(`#crew-change-memo-modal`).modal({
            backdrop: 'static',
            keyboard: false,
          });
          this.bus.$emit('generateCcMemoDetails', "Bleep")
        } else {
          $(`#crew-change-memo-modal`).modal("hide")
          // return false;
        }
      } else {
        console.log('Check this out',crewBatchingId);
        this.gotoCrewChangeMemo(crewBatchingId, crewBatchingNo);
        // $(`#crew-change-memo-modal`).modal({
        //   backdrop: 'static',
        //   keyboard: false,
        // });
         //this.bus.$emit('getCcMemoDetails', crewBatchingId, crewBatchingNo); //monitor this emits due to loading longevity
      }
    },

    gotoCrewChangeMemo(batchId, ccmNumber) {
      console.log('This is something i need',this.selectedVessel)
      let routeData = this.$router.resolve({ //
        name: 'CrewChangePlanCCM',
        params: {
          batchId: `${batchId}`,
          ccmNumber: `${ccmNumber}`,
        },
        query : {
          selectedVesselId: `${this.selectedVessel}`,
          view : 'crew-change-plan'
        }
      });

      //console.log("screen",screen.availWidth);
      window.open(routeData.href, '_blank',"height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));
    },

    removeAllCheck() {
      let checkBoxes = $(`.this-custom-check`);
      let ids = checkBoxes.filter(':checked').map((_, el) => el.id).get();
      ids.forEach((val, i) => {
        $(`#${val}`).prop('checked', false)
      })
    },
    async cancelCrewChangeMemo() {
      this.reset_selected_crew_change();
      this.removeAllCheck();
      this.bus.$emit('setNullCcMemo', 'welcome');
      this.$nextTick(() => {
        this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
        // this.bus.$emit('cancelCcMemo')
        this.bus.$emit('resetCcMemoRemarkState')
      })
      /*
        //remove this code if the mechanics is stable upon testing
       let msgHtml = `<span>Please save any changes<br> before exit.</span>`;
        if (await this.questionAlert(msgHtml, 'Crew Change Memo')) {
        $(`#crew-change-memo-modal`).modal('hide');
      }*/
    },
    setAddVesselRemark() {
      $(`#add-vessel-remark-modal`).attr('hidden', false)
      $(`#btn-cancel-vremark`).attr('hidden', false)
      $(`#btn-add-vremark`).attr('hidden', true)
    },
    cancelAddVesselRemark() {
      this.resetVesselRemarksTextArea();
    },
    closeAddVesselRemark() {
      this.set_null_vessel_remarks();
      this.resetVesselRemarksTextArea();
      localStorage.getItem('selectedRemark')
    },
    resetVesselRemarksTextArea() {
      $(`#add-vessel-remark-modal`).attr('hidden', true)
      $(`#btn-cancel-vremark`).attr('hidden', true)
      $(`#btn-add-vremark`).attr('hidden', false)
      this.newVesselRemark.remarks = null;
      this.newVesselRemark.vesselId = null;
      $(`#labelCount`).val(1000);
    },
    async addVesselRemark() {
      let msg = `Do you want to add this remarks in this vessel`;
      if (await this.questionAlert(`${msg}`, "New Remarks")) {
        this.newVesselRemark.vesselId = this.selectedVessel;
        this.create_vessel_remark(this.newVesselRemark).then((r) => {
          if (r) {
            this.$nextTick(() => {
              $(`#labelCount`).val(1000)
              this.getAllVesselRemarks();
              this.successAlert("Added remarks successfully")
            })
          }
        })
      }
    },
    async getAllVesselRemarks() {
      await this.set_vessel_remarks({vesselId: this.selectedVessel});
    },
    async updateVesselRemark(obj) {
      let msg = `Do you want to save changes that you made?`;
      if (await this.questionAlert(`${msg}`, "Update Remarks")) {
        this.update_vessel_remark(obj).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("Updated remarks successfully");
              let i = obj.id;
              $(`#vessel-remark-${i}`).attr('disabled', true);
              $(`#btn-edit-remark-${i}`).attr('hidden', false);
              $(`#btn-update-remark-${i}`).attr('hidden', true);
              $(`#btn-cancel-remark-${i}`).attr('hidden', true);
              $(`#vessel-remark-${i}`).blur();
              this.isEditableRemark = false;
            })
          }
        });
      }
    },

    async deleteVesselRemark(vesselRemarkId) {
      let msg = `Are you sure you want to delete this remark`;
      if (await this.questionAlert(`${msg}`, "Delete Remark")) {
        this.delete_vessel_remark(vesselRemarkId).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("Deleted remarks successfully")
              localStorage.removeItem('selectedRemark')
            })
          }
        });
      }
    },
    setEditableVesselRemarks(vesselRemark) {
      localStorage.setItem('selectedRemark', JSON.stringify(vesselRemark));
      let i = vesselRemark.id;
      $(`#vessel-remark-${i}`).attr('disabled', false);
      $(`#btn-update-remark-${i}`).attr('hidden', false);
      $(`#btn-cancel-remark-${i}`).attr('hidden', false);
      $(`#btn-edit-remark-${i}`).attr('hidden', true);
      $(`#vessel-remark-${i}`).focus();
      this.isEditableRemark = true;
    },
    cancelEditableVesselRemarks(vesselRemark) {
      let i = vesselRemark.id;
      $(`#vessel-remark-${i}`).attr('disabled', true);
      $(`#btn-edit-remark-${i}`).attr('hidden', false);
      $(`#btn-update-remark-${i}`).attr('hidden', true);
      $(`#btn-cancel-remark-${i}`).attr('hidden', true);
      $(`#vessel-remark-${i}`).blur();
      this.isEditableRemark = false;
      let remark = this.vesselRemarks.find((val, index) => {
        return val.id === i;
      })

      Object.assign(remark, JSON.parse(localStorage.getItem("selectedRemark")))
    },

    clearAllSortingProperties() {
      for (const property in this.ccPlanSort) {
        this.ccPlanSort[property] = '';
      }
    },

    onChangeVessel(e = null) {
      this.clearAllSortingProperties();

      const formParam = {
        vesselId: null,
        selectedFilterOrder: this.selectedFilterOrder
      };
      this.reset_selected_crew_change()
      let vessel = this.objVessels.find((val, index) => {
        return val.id === parseInt(e.target.value, 10);
      })
      this.flagName = vessel ? vessel.flagName : null
      this.manningName = vessel ? vessel.manningName : null

      //formParam.vesselId = vessel != undefined ?   vessel.id : null;
      if (vessel != undefined) {
        this.crewChangePlanFormParam.vesselId = vessel.id;
        // localStorage.setItem('vessel-id', vessel.id);
      } else {
        this.crewChangePlanFormParam.vesselId = null;
        // localStorage.setItem('vesselId', null)
      }
      this.set_crew_change_plans(this.crewChangePlanFormParam)
    },

    //TODO
    getRecommendation(objChangePlan) {
      //TODO make the following ,
      console.log(objChangePlan)
      this.$nextTick(() => {
        // $(`#crew-promotion-modal`).modal({
        //   backdrop: 'static',
        //   keyboard: false,
        // })
      })
    },

    async forPromotion(crewChangePlan) {
      if (crewChangePlan != undefined) {
        let msgNoPromotion = `Do you want to recommend this crew for promotion?`;
        const formParams = {
          rankId: crewChangePlan.rankId,
          ccId: crewChangePlan.ccOffId
        }
        if (!crewChangePlan.ccPromotionId) {
          if (await this.questionAlert(`${msgNoPromotion}`, 'Recommendation')) {
            await this.get_crew_promotion(formParams).then((r) => {
              if (r) {
                this.$nextTick(() => {
                  $(`#crew-promotion-modal`).modal({
                    backdrop: 'static',
                    keyboard: false,
                  })
                })
              }
            });
          }
        } else {
          await this.get_crew_promotion(formParams).then((r) => {
            if (r) {
              this.$nextTick(() => {
                $(`#crew-promotion-modal`).modal({
                  backdrop: 'static',
                  keyboard: false,
                })
              })
            }
          });
        }
      }
    },
    closeModalCrewPromotion() {
      this.reset_state_crew_promotion();
    },
    addForCCMemo(crewChangeId, rankId, e) {
      if (e.target.checked) {
        this.set_selected_crew_changed(crewChangeId);

      } else {
        this.delete_selected_crew_change(crewChangeId)
      }
    },
    selectCrewType() {
      let selectedVal = this.crewTypeSelections.find((val, index) => {
        return val.id === this.activeIndexTypeSelection
      })
      document.getElementById(`crew-type-selections`).style.color = selectedVal.color;
      document.getElementById(`crew-type-selections`).style.background = selectedVal.backGroundColor;
      const params = {
        crewType: this.activeIndexTypeSelection, //standby ,onboard, promoted, applicant, dummy, revert
        vesselId: this.selectedVessel,
        ccPlanType: this.selectedCcPlanType,         //signOff or signOn
        rankId: this.selectedRankId,
      }
      this.get_available_crew_list(params);
    },

    settingCrewChangePlan({crewChangePlan}, ccPlanType, rankId) {
      Object.assign(this.selectedCc, crewChangePlan)
      this.selectedCcPlanType = ccPlanType //signOff or signOn
      this.selectedRankId = rankId;

      //

      this.$nextTick(() => {
        this.selectCrewType(1); //default to 1
        $(`#cc-add-crew-modal`).modal({
          backdrop: 'static',
          keyboard: false,
        });
      })
    },

    async addingChangePlanCrew(crewChangeObj) {
      const formParams = {
        selectedCcId: crewChangeObj.ccId,
        ccrId: this.selectedCc.ccrId,
        ccPlanType: this.selectedCcPlanType,
        vesselId: this.selectedVessel,
        rankId: this.selectedRankId,
        crewType: this.activeIndexTypeSelection,
        crewId: crewChangeObj.crewId
      }

      let msgHtml = `Do you want to add this crew in change plan.`
      if (await this.questionAlert(msgHtml, 'Crew Change Plan')) {
        this.adding_crew_change_plan(formParams).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert('Adding Crew Successfully')
              $(`#cc-add-crew-modal`).modal("hide")
              this.reset_available_crews();
              this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
            })
          }
        })
      }
    },

    /**
     *@author adding change plan crew cctype = 1:reliever - crew, 2:sign off crew
     */
    async deleteCrewChange(ccObj, ccType) {
      let msg = `Do you want to remove this crew in change plan line up?`
      if (await this.questionAlert(msg, 'Remove Crew')) {
        const formParam = {
          ccrId: ccObj.ccrId,
          ccType: ccType,
        }
        this.delete_crew_change(formParam).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("Deleted successfully")
              this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
            })
          }
        })
      }
    },

    /**
     * @author cancel adding change plan crew
     */
    cancelAddingCrew() {
      this.selectedCc = {}
      this.selectedCcPlanType = 1;
      this.activeIndexTypeSelection = 1;
      this.selectedRankId = 1;
      $(`#cc-add-crew-modal`).modal('toggle')
      this.reset_available_crews();
    },

    addCcPlanSetup() {
      let index = this.setUpCcPlans.length
      const ccPlanObj = {
        id: index + 1,
        rankId: null,
        pax: null,
      }
      this.add_ccplan_setup(ccPlanObj);
    },

    removeCcPlanSetup(params) {
      this.remove_ccplan_setup(params);
    },

    resetCcPlanSetup() {
      this.reset_ccplan_setup();
    },


    /**
     * @author good to have featured
     */
    /*onSelectedSetUp(e) {
      let val = e.target.value;
      if (val != undefined) {
        this.setupCc = val;
        if (val == 'use-setup') {
          this.resetCcPlanSetup();
        }
      }
    },*/

    onSelectedSetUpRank(e) {
      let valKey = e.target.value;
      let counter = {};
      let dupId = null;
      this.setUpCcPlans.forEach((val, index) => {
        if (val.rankId !== null) {
          counter[val.rankId] = (counter[val.rankId] || 0) + 1
          if (counter[val.rankId] > 1) {
            dupId = val.id;
            this.warningAlert("Duplicate entry detected", "Warning")
            return;
          }
        }
      })

      if (dupId !== null) {
        let setUpCcPlan = this.setUpCcPlans.find((val, index) => {
          return val.id === dupId;
        });
        setUpCcPlan.rankId = null;
      }
    },

    async saveSetUpCcPlan() {
      let msg = `Are you sure you want to save this setup`;
      if (this.setUpCcPlans.length == 0) {
        this.warningAlert("Please input a valid rank setup and pax before proceed")
      }

      if (await this.questionAlert(msg, "Setup Crew Change")) {
        const setUpCcPlansData = {
          setUpCcPlans: this.setUpCcPlans,
          vesselId: this.selectedVessel
        }
        this.save_setup_ccplan(setUpCcPlansData).then((r) => {
          if (r) {
            this.$nextTick(() => {
              $(`#setup-cc-plan`).modal("hide");
              this.successAlert("Crew change plan setup successfully")
              this.reset_ccplan_setup();
              this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
            })
          }
        });
      }
    },

    /**
     *
     * @author Good to have featured
     */
    /* async saveSetUpCcPlanAsTemplate() {
       let msg = "Do you want to saves this as template";
       if(await this.questionAlert(`${msg}`,"Save as template")){
       }
     },*/

    async addRankChangePlan() {
      this.formAddRankCp.vesselId = parseInt(this.selectedVessel, 10);
      let msg = "Do you want to add rank in vessel change plan"

      if (this.formAddRankCp.rankId === null && (this.formAddRankCp.pax === null || this.formAddRankCp.pax === "")) {
        this.warningAlert("Please provide a valid rank and pax");

      } else if (this.formAddRankCp.rankId === null && this.formAddRankCp.pax !== null) {
        this.warningAlert("Please provide a valid rank value");

      } else if ((this.formAddRankCp.pax === null || this.formAddRankCp.pax === "") && this.formAddRankCp.rankId !== null) {
        this.warningAlert("Please provide a valid pax value");

      } else if (this.formAddRankCp.pax > 1 && this.formAddRankCp.rankId === 1 && this.crewChangePlans.length !== undefined) {
        let ccPlan = this.crewChangePlans.filter((val, index) => {
          return val.rankId === 1;
        })
        if (ccPlan !== undefined) {
          this.warningAlert(`Invalid number of pax for rank master;\n Available number of pax for the rank is 1`)
        }
      } else {
        if (await this.questionAlert(`${msg}`, "Add rank change plan")) {
          this.add_rank_change_plan(this.formAddRankCp).then((r) => {
            if (r) {
              this.$nextTick(() => {
                this.formAddRankCp = {
                  vesselId: null,
                  rankId: null,
                  pax: null,
                }; //reset variable container
                $(`#add-rank-cc-plan`).modal("hide");
                this.successAlert("Adding rank in change plan successfully", "Add Rank")
              })
              this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
            }
          })
        }
      }
    },

    async deleteRankChangePlan(obj) {
      let msg = "Do you want to delete \n this rank change plan on this vessel"
      if (await this.questionAlert(`${msg}`, "Delete Rank Change plan", '', "align-middles")) {
        let ccrId = parseInt(obj.ccrId, 10);
        this.delete_rank_change_plan(ccrId).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("Rank deleted successfully in vessel change plan")
            })
            this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
          }
        })
      }
    },

    cancelAddRankChangePlan() {
      this.formAddRankCp = {
        vesselId: null,
        rankId: null,
        pax: null,
      }; //reset variable container
    },

    gotoProfile(thisCrewId, crewType) {
      if (crewType != (3 && 4)) {
        this.$nextTick(() => {
          this.formSelectedCrewId = thisCrewId;
          let routeData = this.$router.resolve({ //
            name: 'CrewProfile',
            params: {
              crewId: thisCrewId,
            },
            query: {
              view: 'ccplan'
            }
          });
          window.open(routeData.href, '_blank', "height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));
        })
      }
    },

    getCrewChangeRemarks(crewChangePlanId, ccPlanType) {
      this.formCrewChangeId = crewChangePlanId;
      this.selectedCcPlanType = ccPlanType;
      this.set_crew_change_remarks({crewChangeId: crewChangePlanId});
    },

    async createCrewChangeRemark() {
      let msg = `Do you want to add this remarks?`
      if (await this.questionAlert(`${msg}`, "New Remarks")) {
        this.newCrewChangeRemark.crewChangeId = this.formCrewChangeId;
        const formParam = {
          newCcRemarks: this.newCrewChangeRemark,
          selectedCcPlanType: this.selectedCcPlanType
        }

        this.create_crew_change_remark(formParam).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("Remarks add successfully", "Create")
            })
          }
        })
      }
    },

    clearChangeRemarkInput() {
      $(`#cc-remark-input`).val('');
      this.newCrewChangeRemark.remarks = "";
    },

    setEditCrewChangeRemark(index) {
      //this.set_form_crew_change_remark(crewChangeRemark);

      //Object.assign(this.formCrewChangeRemark,crewChangeRemark);

      $(`#cc-remark-status-${index}`).attr('disabled', false);
      $(`#cc-remarks-${index}`).attr('disabled', false);

      $(`#cc-remarks-${index}`).focus();

      $(`#btn-update-cc-remark-${index}`).attr('hidden', false)
      $(`#btn-cancel-cc-remark-${index}`).attr('hidden', false)


      $(`#btn-edit-cc-remark-${index}`).attr('hidden', true)
      $(`#btn-delete-cc-remark-${index}`).attr('hidden', true)

    },


    setCancelCrewChangeRemark(ccRemark, index) {
      /*let crewChangeRemark = this.crewChangeRemarks.find((val,index)=>{
        return val.id === this.formCrewChangeRemark.id;
      })*/

      // Object.assign(crewChangeRemark,this.formCrewChangeRemark)

      $(`#btn-edit-cc-remark-${index}`).attr('hidden', false);
      $(`#btn-delete-cc-remark-${index}`).attr('hidden', false);


      $(`#btn-update-cc-remark-${index}`).attr('hidden', true)
      $(`#btn-cancel-cc-remark-${index}`).attr('hidden', true)

      $(`#cc-remark-status-${index}`).attr('disabled', true);
      $(`#cc-remarks-${index}`).attr('disabled', true);

      this.set_crew_change_remark(ccRemark);

    },

    async updateCrewChangeRemark(ccRemark, index) {
      let msg = `Do you want to update remarks.?`
      if (await this.questionAlert(`${msg}`, "Update")) {
        this.update_crew_change_remark(ccRemark).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("Remarks update successfully", "Update")
              this.formCrewChangeRemark = {};
              $(`#btn-edit-cc-remark-${index}`).attr('hidden', false);
              $(`#btn-delete-cc-remark-${index}`).attr('hidden', false);

              $(`#btn-update-cc-remark-${index}`).attr('hidden', true)
              $(`#btn-cancel-cc-remark-${index}`).attr('hidden', true)

              $(`#cc-remark-status-${index}`).attr('disabled', true);
              $(`#cc-remarks-${index}`).attr('disabled', true);
            })
          }
        })
      }
    },

    async deleteCrewChangeRemark(ccRemarks) {
      let msg = `Do you want to delete this remark`
      if (await this.questionAlert(`${msg}`, "Delete Remark")) {
        const formCcRemark = {
          ccRemarks: ccRemarks,
          selectedCcPlanType: this.selectedCcPlanType,
        }
        this.delete_crew_change_remark(formCcRemark).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert('Remarks delete successfully', "Delete")
            })
          }
        })
      }
    },

    closeCcRemarkModal() {
      this.formCrewChangeId = null;
      this.formCrewChangeRemark = null;
      this.selectedCcPlanType = 1; //return to default sign-on
      this.crewChangeRemarks.forEach((val, index) => {
        $(`#btn-edit-cc-remark-${index}`).attr('hidden', false);
        $(`#btn-delete-cc-remark-${index}`).attr('hidden', false);

        $(`#btn-update-cc-remark-${index}`).attr('hidden', true)
        $(`#btn-cancel-cc-remark-${index}`).attr('hidden', true)

        $(`#cc-remark-status-${index}`).attr('disabled', true);
        $(`#cc-remarks-${index}`).attr('disabled', true);
      })
    },

    async addCrewRecommendation() {
      let msg = `Do you want to recommend this crew`
      if (await this.questionAlert(`${msg}`, "Add Recommendation")) {
        this.create_new_promotion(this.crewPromotion).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("Recommend crew for promotion successfully", "Recommendation")
              this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
            })
          }
        })
      }
    },

    async deleteCrewRecommendation() {
      let msg = `Are you sure you want to delete this recommendation`;
      if (await this.questionAlert(`${msg}`, "Delete Recommendation")) {
        this.delete_crew_promotion(this.crewPromotion).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("Delete recommendation successfully")
              this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
            })
          }
        })
      }
    },

    async approveCrewRec() {
      let msg = `Are you sure you want to approve this recommendation`;
      if (await this.questionAlert(`${msg}`, "Approved Recommendation")) {
        this.crewPromotion.isApproved = true;
        this.update_crew_promotion(this.crewPromotion).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
              this.successAlert("Approved successfully")
            })
          }
        })
      }
    },

    async disApprovedCrewRec() {
      let msg = `Do you want to disapprove this crew recommendation`;
      if (await this.questionAlert(`${msg}`, "Disapprove Crew Recommendation")) {
        this.crewPromotion.isApproved = false;
        this.update_crew_promotion(this.crewPromotion).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
              this.successAlert("Disapproved successfully")
            })
          }
        })
      }
    },

    forExtensionContract(crewChangePlan) {
      this.formParam.crewId = crewChangePlan.crewOffId;
      this.set_crew_contract(crewChangePlan);

      this.renewCrewDocument.crewDocId = crewChangePlan.currentCrewDocId_isExtension ?
          crewChangePlan.currentCrewDocId_isExtension : crewChangePlan.currentCrewDocId_isNotExtension

      this.renewCrewDocument.extensionTypeOld = crewChangePlan.currentExtensionType

      $(`#crew-extend-modal`).modal({
        backdrop: 'static',
        keyboard: false
      })
    },

    viewEocDate(eocDate, ccOffId) {
      // console.log(ccOffId)
      this.updateEocDateParams.id = ccOffId
      this.updateEocDateParams.eocDate = eocDate

      $(`#crew-eoc-modal`).modal({
        backdrop: 'static',
        keyboard: false
      })
    },

    async updateEocDate() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update EOC Date', 'question');
      if (await __update) {
        let params = {
          id: this.updateEocDateParams.id,
          eocDate: this.updateEocDateParams.eocDate ? this.updateEocDateParams.eocDate : '' ,
        };

        let updateEoc = await this.updateEOCDateCrewChangePlan(params);
        if (updateEoc) {
          AlertService.successAlertService("Updated successfully",'Update Eoc Date');
          this.hideModal('crew-eoc-modal')
          this.isEditable = false
        }
      }
    },

    async extendCrewContract() {
      let msg = `Do you want to extend the crew contract date`
      if (await this.questionAlert(`${msg}`, "Extending Contract")) {
        this.extend_crew_contract(this.crewContract).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.questionAlert('Extending contract successfully')
              this.reset_null_crew_contract();
              $(`#crew-extend-modal`).modal('hide');
              this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1})
            })
          }
        })
      }
    },

    empt_input_renew_doc() {
      $(`#renewDocFile`).val(null);
    },

    closeCrewContractModal() {
      this.reset_null_crew_contract();
      this.nullNewCrewDocuments();
      this.nullNewCrewDocFile();
      this.empt_input_renew_doc();

      this.nullRenewCrewDocument();
    },

    closeCcMemoModal() {
      $(`#crew-change-memo-modal`).modal('hide');

      this.set_crew_change_plans({vesselId: this.selectedVessel, selectedFilterOrder: 1});
    },


    crewChangePlanSort(sortBy, isString, ccPlanColumn) {

      const resetSortingState = (ccPlanSort, exceptSortBy) => {
        for (const property in ccPlanSort) {
          if (property !== exceptSortBy) {
            ccPlanSort[property] = '';
          }
        }
      };

      resetSortingState(this.ccPlanSort, sortBy);

      if (this.ccPlanSort[sortBy] === 'desc') {
        this.ccPlanSort[sortBy] = 'asc'; // set to ascending

        if (isString) {
          this.crewChangePlans.sort((a, b) => (a[ccPlanColumn] || '').localeCompare(b[ccPlanColumn] || ''));
        } else {
          this.crewChangePlans.sort((a, b) => (a[ccPlanColumn] || '') - (b[ccPlanColumn] || ''));
        }

      } else if (this.ccPlanSort[sortBy] === 'asc') {
        this.ccPlanSort[sortBy] = ''; // clear sorting
        this.crewChangePlans.sort((a, b) => a.ranking - b.ranking); // set to default

      } else {
        this.ccPlanSort[sortBy] = 'desc'; // set to descending

        if (isString) {
          this.crewChangePlans.sort((a, b) => (b[ccPlanColumn] || '').localeCompare(a[ccPlanColumn] || ''));
        } else {
          this.crewChangePlans.sort((a, b) => (b[ccPlanColumn] || '') - (a[ccPlanColumn] || ''));
        }

      }
    },


    async addRankCCplanModal() {
      $(`#add-rank-cc-plan`).modal({
        backdrop: 'static',
        keyboard: false,
      });
    },

    docs_lang({name}) {
      return `${name}`;
    },

    ranks_lang({
                 alias,
                 name,
               }) {
      return `[${alias}] - ${name}`;
    },

    async saveDocumentContract() {
      if (await this.questionAlert('Do you want to extend the crew contract date', 'Contract Extension')) {
        let file = document.getElementById('renewDocFile').files[0];
        const formData = new FormData();
        formData.append('crewId', this.formParam.crewId ? this.formParam.crewId.toString() : '');
        formData.append('documentId', this.newCrewDocument.documentId ? this.newCrewDocument.documentId : '');
        formData.append('documentNo', this.newCrewDocument.documentNo);
        formData.append('documentNo2', this.newCrewDocument.documentNo2);
        formData.append('dateIssued', this.newCrewDocument.dateIssued);
        formData.append('dateExpiry', this.newCrewDocument.dateExpiry);
        formData.append('rankId', this.newCrewDocument.rankId);
        formData.append('file', file ? file : '');
        this.createCrewDoc(formData).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert('Contract Extension Success');
              this.set_crew_change_plans(this.crewChangePlanFormParam)
              this.hideModal('crew-extend-modal');
              this.empt_input_renew_doc();
            })
          }
        });
      }
    },


    async extendDocumentContract() {
      if (await this.questionAlert(`Do you want to extend this contract`, 'Contract Extension')) {
        let fileParam = document.getElementById('renewDocFile').files[0];

        if (fileParam == (null || undefined)) {
          this.warningAlert('Please attach file when extending contract.');

        } else {
          const formData = new FormData();
          formData.append('crewDocId', this.renewCrewDocument.crewDocId);
          formData.append('documentNo', this.renewCrewDocument.documentNo ? this.renewCrewDocument.documentNo : '');
          formData.append('documentNo2', this.renewCrewDocument.documentNo2 ? this.renewCrewDocument.documentNo2 : '');
          formData.append('dateIssued', this.renewCrewDocument.dateIssued ? this.renewCrewDocument.dateIssued : '');
          formData.append('dateExpiry', this.renewCrewDocument.dateExpiry ? this.renewCrewDocument.dateExpiry : '');
          formData.append('isExtension', 1);
          formData.append('extensionTypeNew', this.renewCrewDocument.extensionTypeNew ? this.renewCrewDocument.extensionTypeNew : '');
          formData.append('extensionTypeOld', this.renewCrewDocument.extensionTypeOld ? this.renewCrewDocument.extensionTypeOld : '');
          formData.append('rankId',this.renewCrewDocument.rankId ? this.renewCrewDocument.rankId : '')
          formData.append('file', fileParam);
          this.renewCrewDoc(formData).then((r) => {
            if (r) {
              this.$nextTick(() => {
                this.successAlert('Renew crew document successfully', 'Renew Crew Document');
                this.nullRenewCrewDocument();
                this.successAlert('Contract Extension Success');
                this.successAlert("Crew Contract document is updated", "Contract Extension Success")
                this.set_crew_change_plans(this.crewChangePlanFormParam)
                this.hideModal('crew-extend-modal');
                this.empt_input_renew_doc();
              })
            }
          });
        }
      }
    },

    nullRenewCrewDocument() {
      this.renewCrewDocument.crewDocId = ''
      this.renewCrewDocument.documentId = ''
      this.renewCrewDocument.documentName = ''
      this.renewCrewDocument.documentNo = ''
      this.renewCrewDocument.documentNo2 = ''
      this.renewCrewDocument.rankId = ''
      this.renewCrewDocument.dateIssued = ''
      this.renewCrewDocument.dateExpiry = ''
      this.renewCrewDocument.isExtension = 0
      this.renewCrewDocument.extensionTypeOld = ''
      this.renewCrewDocument.extensionTypeNew  = ''
    },

    findLatestDate(coeDateStr, eocDateStr){

        const coeDate = new Date(coeDateStr);
        const eocDate = new Date(eocDateStr);

        return coeDate > eocDate;
    }

  },

  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
    this.bus.$on('closeCcMemoModal', this.closeCcMemoModal);
  },

  created() {
    this.$MyGlobalFunction = global_variables_config
    this.getDocumentKeys({docType: 1});
    this.getCrewComplimentPerVessel();
  },

  destroyed() {
    this.reset_state_vessel_remarks()
    this.reset_selected_crew_change()
    this.selectedCc = {}
    this.selectedCcPlanType = 1;
    this.activeIndexTypeSelection = 1;
    this.selectedRankId = null;
    this.null_obj_ranks();
    this.null_obj_vessels();
    this.reset_state_crew_change_plan();
    this.reset_state_crew_change_remark();
    this.reset_null_crew_contract();
  },

  watch: {
    vesselRemarks(val) {
      if (val.length >= 3) {
        this.vesselRemarkContainerCss = 'overflow: scroll; overflow-x: hidden; height: 400px;';
      } else {
        this.vesselRemarkContainerCss = "";
      }
    },

    crewChangeRemarks(val) {
      if ((val.length >= 5)) {
        this.ccRemTexAreaCustomCss = 'overflow-y: scroll; height: 250px;';
        this.ccRemModalCustomCss = 'height: 450px;';

      } else {

        this.ccRemTexAreaCustomCss = ``;
        this.ccRemModalCustomCss = ``;
      }
    },

    'ccPlanSort.sortByRank'() {
      if (!this.ccPlanSort.sortByRank) {
        this.crewChangePlans.sort((a, b) => a.ranking - b.ranking);
      }
    },

  }
}
</script>

<style scoped>
input[type="checkbox"], input[type="radio"] {
  transform: scale(2) !important;
}

.custom-modal ::-webkit-scrollbar {
  width: 10px !important;
}


.custom-tag::-webkit-scrollbar {
  width: 10px !important;
}

.custom-text-area {
  border: none !important;
  background-color: transparent !important;
  resize: none !important;
  outline: none !important;
  text-indent: 5% !important;
  width: 100% !important;

}

.custom-text-area:focus {
  outline: none !important;
  border: 3px solid #55ff41 !important;
  box-shadow: 0 0 10px #719ECE !important;
}


/* use for dynamic css for ccremarks */
.cc-cancel-css {
  text-decoration: line-through !important;
  color: red !important;
}

.cc-close-css {
  color: red !important;
}

.cc-open-css {
  color: #074103 !important;
}

.crew-status-inactive {
  color: red;
}

/* end of ccs for ccremarks */


/*.form-control-sm {*/
/*  height: calc(1.3em + 0.3rem + 2px) !important;*/
/*  padding: 0.25rem 0.5rem !important;*/
/*  font-size: 0.875rem !important;*/
/*  line-height: 1.5 !important;*/
/*  border-radius: 0.2rem !important;*/
/*}*/
</style>
