<template>
  <div class="container bg-white" style="height: 100vh;">
    <br>
    <br>
    <div class="d-flex justify-content-between">
      <span class="font-weight-bold">General Overview</span>
      <div class="d-flex">
        <button type="button"
                class="btn btn-sm pmc-bg-warning"
                v-show="!isEditable"
                v-if="authUser.department == $globalVariables.department.admin ||
                         authUser.department == $globalVariables.department.it ||
                         authUser.department == $globalVariables.department.management"
                @click="set_editInfo">
          <font-awesome-icon icon="edit"/>
          EDIT
        </button>

        <button type="button"
                class="btn btn-sm pmc-btn-tertia"
                @click="updateVesselDetails"
                v-show="isEditable"
        >
          <font-awesome-icon icon="save"/>
          SAVE
        </button>

        <button type="button" class="btn btn-sm pmc-btn-danger ml-3"
                v-show="isEditable"
                @click="set_cancelEdit">
          <font-awesome-icon icon="times"/>
          CANCEL
        </button>

        <button type="button"
                class="btn btn-sm btn-secondary"
                v-show="!isEditable"
                @click="goBack">
          <font-awesome-icon icon="undo"/>
          BACK
        </button>

      </div>
    </div>
    <div class="row text-small mt-3">
      <!--      <div class="col-sm-4 mx-auto text-center">-->
      <!--        <div class="row text-small m-3">-->
      <!--          <div class="col col-md-12">-->
      <!--&lt;!&ndash;                          <img&ndash;&gt;-->
      <!--            &lt;!&ndash;                            class="border vessel-pic float-left"&ndash;&gt;-->
      <!--            &lt;!&ndash;                            alt="Vessel Image"&ndash;&gt;-->
      <!--            &lt;!&ndash;                            :src="vesselInformation.vessel_image"&ndash;&gt;-->
      <!--            &lt;!&ndash;                          />&ndash;&gt;-->
      <!--            <img-->
      <!--              class="border vessel-pic float-left bg"-->
      <!--              alt="Vessel Image"-->
      <!--              :src="noPhoto"-->
      <!--            />-->
      <!--          </div>-->
      <!--          <div class="col col-lg-10 mt-3">-->

      <!--            <button type="button"-->
      <!--                    class="btn btn-sm btn-primary"-->
      <!--                    data-toggle="modal"-->
      <!--                    :data-target="'#'+ uploadVesselImageModal"-->
      <!--                    data-backdrop="static"-->
      <!--                    data-keyboard="false">-->
      <!--              <font-awesome-icon icon="paperclip"/>-->
      <!--              Image-->
      <!--            </button>-->

      <!--            <button type="button"-->
      <!--                    class="btn btn-sm pmc-bg-danger ml-3"-->
      <!--                    v-show="!isEditable"-->
      <!--                    @click="deleteUploadPhotoModal">-->
      <!--              <font-awesome-icon icon="edit"/>-->
      <!--              Delete-->
      <!--            </button>-->
      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->

      <div class="col-4">
        <div class="d-flex flex-column">
          <div class="p-2">
            <div class="vessel-img-container">

              <!--              <img-->
              <!--                v-if="!vessel.hasImg && !attachedImage"-->
              <!--                class="border vessel-pic"-->
              <!--                alt="Vessel Image"-->
              <!--                :src="noPhoto"-->
              <!--              />-->
              <font-awesome-icon v-if="!vesselData.hasImg && !attachedImage" icon="ship" id="vessel-img"/>
              <font-awesome-icon v-if="!vesselData.image && vesselData.hasImg" icon="ship" id="vessel-img"/>

              <img v-if="attachedImage"
                   :src="attachedImage"
                   alt="vessel-img"
                   class="img-fluid vessel-img">

              <img
                v-if="vesselData.image && !attachedImage"
                class="border vessel-pic"
                alt="Vessel Image"
                :src="vesselData.image"
              />

              <div>
              </div>

            </div>
          </div>
          <div class="p-2">
            <div class="btn-group" role="group" aria-label="Basic example">
              <template v-if="isEditable">
                <pmc-modal-btn :target-modal="uploadVesselImageModal" button-class="btn btn-light text-secondary"
                               button-label="Image">
                  <template slot="button-icon">
                    <font-awesome-icon icon="paperclip"/>
                  </template>
                </pmc-modal-btn>
                <button type="button" class="btn btn-light text-danger" v-if="vesselData.hasImg"
                        @click="deleteUploadPhotoModal">
                  <font-awesome-icon icon="trash"/>
                  Delete
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <table class="table table-sm" id="genInfoTable">
          <tbody>
          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="vessel-name"
              >
                Code:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <!--              <span>{{ vessel.code }}</span>-->
              <input type="text"
                     placeholder="input code"
                     class="form-control form-control"
                     :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                     :disabled="!isEditable"
                     v-model="vesselData.code">
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="flag"
              >
                Name:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     placeholder="input code"
                     class="form-control form-control"
                     :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                     :disabled="!isEditable"
                     v-model="vesselData.name">
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="vessel-type"
              >
                Alias:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     placeholder="input code"
                     class="form-control form-control"
                     :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                     :disabled="!isEditable"
                     v-model="vesselData.alias">
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="vessel-group"
              >
                Vessel Type:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input flag"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.vesselType && !isEditable"
                     v-model="vesselData.vesselType.name">
              <multi-select
                v-if="isEditable"
                v-model="vesselData.vesselType"
                :options="objVesselTypes"
                :custom-label="namesLang"
                :select-label="'Select'"
                placeholder="Select vessel type"
                label="VesselType"
                track-by="id">
              </multi-select>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="port"
              >
                Flag:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input flag"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.vesselType && !isEditable"
                     v-model="vesselData.flag.name">
              <multi-select
                v-if="isEditable"
                v-model="vesselData.flag"
                :options="objFlags"
                :custom-label="namesLang"
                :select-label="'Select'"
                placeholder="Select flag"
                label="VesselType"
                track-by="id">
              </multi-select>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="port"
              >
                Port of Registry:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input port of registry"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.vesselType && !isEditable"
                     v-model="vesselData.portOfRegistry.name">
              <multi-select
                v-if="isEditable"
                v-model="vesselData.portOfRegistry"
                :options="objFlags"
                :custom-label="namesLang"
                :select-label="'Select'"
                placeholder="Select flag"
                label="VesselType"
                track-by="id">
              </multi-select>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="port"
              >
                Beneficial:
              </label>
            </th>
            <td class="align-middle">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input flag"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.beneficial && !isEditable"
                     v-model="vesselData.beneficial.name">
              <multi-select
                v-if="isEditable"
                v-model="vesselData.beneficial"
                :options="objBeneficiary"
                :custom-label="namesLang"
                :select-label="'Select'"
                placeholder="Select beneficial"
                label="VesselType"
                track-by="id">
              </multi-select>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="imo"
              >
                Owner:
              </label>
            </th>
            <td class="align-middle">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input flag"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.owner && !isEditable"
                     v-model="vesselData.owner.name">
              <multi-select
                v-if="isEditable"
                v-model="vesselData.owner"
                :options="objOwners"
                :custom-label="namesLang"
                :select-label="'Select'"
                placeholder="Select owner"
                label="VesselType"
                track-by="id">
              </multi-select>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                  class="font-weight-bolder text-left form-label-xs mt-2"
                  for="imo"
              >
                Gear Owner:
              </label>
            </th>
            <td class="align-middle">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input flag"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.gearOwner && !isEditable"
                     v-model="vesselData.gearOwner.name">
              <multi-select
                  v-if="isEditable"
                  v-model="vesselData.gearOwner"
                  :options="objOwners"
                  :custom-label="namesLang"
                  :select-label="'Select'"
                  placeholder="Select owner"
                  label="VesselType"
                  track-by="id">
              </multi-select>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="imo"
              >
                 Principal:
              </label>
            </th>
            <td class="align-middle">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input flag"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.principal && !isEditable"
                     v-model="vesselData.principal.name">
              <multi-select
                v-if="isEditable"
                v-model="vesselData.principal"
                :options="objPrincipals"
                :custom-label="namesLang"
                :select-label="'Select'"
                placeholder="Select Principal"
                label="VesselType"
                track-by="id">
              </multi-select>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="imo"
              >
                Manning:
              </label>
            </th>
            <td class="align-middle">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input flag"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.manning && !isEditable"
                     v-model="vesselData.manning.name">
              <multi-select
                v-if="isEditable"
                v-model="vesselData.manning"
                :options="objMannings"
                :custom-label="namesLang"
                :select-label="'Select'"
                placeholder="Select manning"
                label="VesselType"
                track-by="id">
              </multi-select>
            </td>


          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="imo"
              >
                Engine Type:
              </label>
            </th>
            <td class="align-middle">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input flag"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.vesselEngineType && !isEditable"
                     v-model="vesselData.vesselEngineType.name">
              <multi-select
                v-if="isEditable"
                v-model="vesselData.vesselEngineType"
                :options="objVesselEngineTypes"
                :custom-label="namesLang"
                :select-label="'Select'"
                placeholder="Select Engine Type"
                label="EngineType"
                track-by="id">
              </multi-select>
            </td>
          </tr>


          </tbody>
        </table>
      </div>


      <div class="col-sm-4">
        <table class="table table-sm" id="genInfoTable">
          <tbody>
          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="official"
              >
                Traderoute:
              </label>
            </th>
            <td class="align-middle">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input flag"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.tradeRoute && !isEditable"
                     v-model="vesselData.tradeRoute.name">
              <multi-select
                v-if="isEditable"
                v-model="vesselData.tradeRoute"
                :options="objTradeRoutes"
                :custom-label="namesLang"
                :select-label="'Select'"
                placeholder="Select trade route"
                label="VesselType"
                track-by="id">
              </multi-select>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="classification"
              >
                Union:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     id="view-vessel-type"
                     placeholder="input flag"
                     class="form-control form-control form-control-plaintext"
                     disabled="disabled"
                     v-if="vesselData.union && !isEditable"
                     v-model="vesselData.union.name">
              <multi-select
                v-if="isEditable"
                v-model="vesselData.union"
                :options="objUnion"
                :custom-label="namesLang"
                :select-label="'Select'"
                placeholder="Select union"
                label="VesselType"
                track-by="id">
              </multi-select>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="hull_no"
              >
                Status.
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <div class="mt-2">
                <input type="checkbox" class="form-check-inline" v-model="vesselData.status" :disabled="!isEditable">
              </div>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="cba_type"
              >
                End Service Reason
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     placeholder="Enter value"
                     class="form-control form-control"
                     :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                     :disabled="!isEditable"
                     v-model="vesselData.endServiceReason">
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="cba_type"
              >
                Ecdis Type
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <select name="ecdisType"
                      class="form-control form-control-sm"
                      v-model="vesselData.ecdisTypeId"
                      :disabled="!isEditable">
                <option :value="null" class="text-center align-middle">- SELECT -</option>
                <option :value="ecdis.id" v-for="(ecdis,index) in objEcdisKeys" :key="index">{{ecdis.name}}</option>
              </select>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="vessel-name">
                Created By:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <span>{{vesselData.createdBy}}</span>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="flag">
                Updated By:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <span>{{vesselData.updatedBy}}</span>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="vessel-type"
              >
                Created At:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <span v-text="vesselData.createdAt"></span>
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="vessel-type"
              >
                Updated At:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <span v-text="vesselData.updatedAt"></span>
            </td>
          </tr>


          </tbody>
        </table>
      </div>
    </div>

    <hr/>

    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="specs-tab" data-toggle="tab" href="#specs" role="tab" aria-controls="specs"
           aria-selected="true">Specifications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="vesselHistory-tab" data-toggle="tab" href="#vesselHistory" role="tab"
           aria-controls="vesselHistory" aria-selected="false">Vessel History</a>
      </li>

    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active mt-4" id="specs" role="tabpanel" aria-labelledby="home-tab">
        <div class="row text-small mt-3">
          <div class="col-5">
            <table class="table table-sm">
              <tbody>
              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    Date Delivered
                  </div>
                </td>
                <td class="w-50">
                  <input type="date"
                         :disabled="!isEditable"
                         v-model="vesselData.dateDelivered"
                         class="form-control form-control-plaintext">
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    Date re-delivered
                  </div>
                </td>
                <td class="w-50">
                  <input type="date"
                         :disabled="!isEditable"
                         v-model="vesselData.dateReDelivered"
                         class="form-control form-control-plaintext">
                </td>
              </tr>


              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    Built Year
                  </div>
                </td>
                <td class="w-50">
                  <input type="text"
                         id="view-vessel-built-year"
                         disabled
                         v-model="vesselData.builtYear"
                         :disabled="!isEditable"
                         v-if="!isEditable "
                         class="form-control form-control-plaintext">
                  <select name="ViewVesselBuiltYear"
                          id="view-vessel-builtyear"
                          v-if="isEditable"
                          v-model="vesselData.builtYear"
                          class="form-control form-control">
                    <option value="">--select--</option>
                    <option :value="year" v-for="(year,index) in generateYear()">
                      {{ year }}
                    </option>
                  </select>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    Classification
                  </div>
                </td>
                <td class="w-50">
                  <select name="VesselClassification"
                          id="vessel-classification"
                          :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                          :disabled="!isEditable"
                          class="form-control form-control"
                          v-model="vesselData.classification">
                    <option value="0">---</option>
                    <option v-for="(vesselClass, index) in objVesselClassification" :value="vesselClass.id">
                      {{vesselClass.name}}
                    </option>
                  </select>
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    Contract Length
                  </div>
                </td>
                <td class="w-50">

                  <input type="number"
                         :disabled="!isEditable"
                         :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                         v-model="vesselData.contractLength"
                         class="form-control form-control">

                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    Max Overtime
                  </div>
                </td>
                <td class="w-50">
                  <input type="number"
                         :disabled="!isEditable"
                         :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                         min="0"
                         v-model="vesselData.maxOverTime"
                         class="form-control form-control">
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    Hours per week
                  </div>
                </td>
                <td class="w-50">
                  <input type="number"
                         :disabled="!isEditable"
                         :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                         min="0"
                         v-model="vesselData.hrsPerWeek"
                         class="form-control  form-control">
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    KW
                  </div>
                </td>
                <td class="w-50">
                  <input type="number"
                         :disabled="!isEditable"
                         :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                         min="0"
                         v-model="vesselData.kw"
                         class="form-control form-control">
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    GRT
                  </div>
                </td>
                <td class="w-50">
                  <input type="number"
                         :disabled="!isEditable"
                         :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                         min="0"
                         v-model="vesselData.grt"
                         class="form-control form-control">
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    DWT
                  </div>
                </td>
                <td class="w-50">
                  <input type="number"
                         :disabled="!isEditable"
                         :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                         min="0"
                         v-model="vesselData.dwt"
                         class="form-control form-control">
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    IMO NO
                  </div>
                </td>
                <td class="w-50">
                  <input type="text"
                         :disabled="!isEditable"
                         :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                         min="0"
                         v-model="vesselData.imoNo"
                         class="form-control form-control">
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    OFF NO
                  </div>
                </td>
                <td class="w-50">
                  <input type="text"
                         :disabled="!isEditable"
                         :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                         min="0"
                         v-model="vesselData.offNo"
                         class="form-control form-control">
                </td>
              </tr>

              <tr>
                <td class="font-weight-bold w-50">
                  <div class="mt-2">
                    Engine Type
                  </div>
                </td>
                <td class="w-50">
                  <select name="VesselEngineType"
                          id="vessel-classification"
                          :class="{'form-control-plaintext' : !isEditable}"
                          :disabled="!isEditable"
                          class="form-control form-control"
                          v-model="vesselData.engineType">
                    <option value="0">---</option>
                    <option v-for="(engineType, index) in objEngineTypes" :value="engineType.id">
                      {{engineType.name}}
                    </option>
                  </select>
                </td>
              </tr>


              </tbody>
            </table>
          </div>
          <!--          <div class="col-5">-->
          <!--            <table class="table table-sm">-->
          <!--              <tbody>-->
          <!--              <tr>-->
          <!--                <th class="font-weight-bold w-50">Date Re-delivered</th>-->
          <!--                <td class="w-50">28</td>-->
          <!--              </tr>-->
          <!--              </tbody>-->
          <!--            </table>-->
          <!--          </div>-->
        </div>
      </div>

      <div class="tab-pane fade" id="vesselHistory" role="tabpanel" aria-labelledby="vesselHistory-tab">
        Vessel History .....
      </div>
    </div>

    <pmc-modal :id="uploadVesselImageModal" :show-header-button-close="false">
      <template slot="custom-header">
        Upload Vessel image
      </template>
      <template slot="custom-body">
        <label for="attached-photo"></label>
        <input type="file" name="AttachedPhoto" id="attached-photo" @change="onChangeAttachedPhoto">
        <hr>
        <div class="vessel-img-container">
          <font-awesome-icon icon="ship" class="vessel-img" v-if="!attachedImage"/>
          <img v-if="attachedImage" id="upload-vessel-img" :src="attachedImage" alt="vessel-img"
               class="img-fluid vessel-img">
        </div>
      </template>
      <template slot="custom-footer">
        <button @click="attachedVesselImage" class="btn btn-sm btn-secondary">
          Attach Image
        </button>

        <button v-if="attachedImage" @click="removeAttachedImage" class="btn btn-sm pmc-btn-danger">
          Remove
        </button>

        <button v-if="!attachedImage" @click="cancelAttachedVesselImage" class="btn btn-sm pmc-btn-danger">
          Cancel
        </button>
      </template>
    </pmc-modal>

  </div>

</template>

<script>
import noPhoto from '../../../../assets/noPhoto.jpg';
import PmcLogo from "@/assets/pmc_logo.jpg";
import {mapActions, mapGetters} from "vuex";
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {global_variables_config} from "@/config/global-variable.config";
import {CommonMixins} from "@/mixins/common.mixins";

export default {
  name: "ViewVessel",

  // props: {
  //   crewId: {
  //     type: Number,
  //   }
  // },

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  data() {
    return {
      noPhoto: noPhoto,
      vesselId: this.$route.params.vesselId,
      isEditable: false,
      uploadVesselImageModal: 'upload-vessel-img-modal',

      selectedVessel: null,

      attachedImage: null,
      currentAttachedImage: null,

      vesselData: {},

    }
  },

  methods: {
    ...mapActions(
      [
        'getVesselDetails',
        'getVesselListPaginated',
        'updateVessel',

        'resetUpdateVessel',
        'resetVesselState',
        'resetVessel',
      ]
    ),

    set_editInfo() {
      this.isEditable = true
    },

    async set_cancelEdit() {
      this.vesselData = Object.assign({}, this.vessel);
      this.isEditable = false;
    },

    view_vessel() {
      this.getVesselDetails(this.$route.params.vesselId).then((r) => {
        if (!r) {
          this.goToFailOver();
        }
      });
    },

    /** .lang functions  **/
    namesLang({name}) {
      return `${name}`;
    },

    generateYear() {
      let currentYear = new Date().getFullYear();
      let start = 1992;
      const stop = currentYear + 1;
      return new Array(stop - start).fill(start).map((n, i) => n + i);
    },

    async updateVesselDetails() {
      let msg = 'Do you want to save the changes you made?';
      if (await this.questionAlert(`${msg}`, 'Update vessel details')) {
        const formPayload = new FormData;
        formPayload.append('id', this.vesselData.id);
        formPayload.append('code', this.vesselData.code);
        formPayload.append('name', this.vesselData.name);
        formPayload.append('alias', this.vesselData.alias);
        formPayload.append('vesselTypeId', this.vesselData.vesselType ? this.vesselData.vesselType.id : '');
        formPayload.append('flagId', this.vesselData.flag ? this.vesselData.flag.id : '');
        formPayload.append('portOfRegistryId', this.vesselData.portOfRegistry ? this.vesselData.portOfRegistry.id : '');
        formPayload.append('ownerId', this.vesselData.owner ? this.vesselData.owner.id : '');
        formPayload.append('gearOwnerId', this.vesselData.gearOwner ? this.vesselData.gearOwner.id : '');
        formPayload.append('principalId', this.vesselData.principal ? this.vesselData.principal.id : '');
        formPayload.append('manningId', this.vesselData.manning ? this.vesselData.manning.id : '');
        formPayload.append('tradeRouteId', this.vesselData.tradeRoute ? this.vesselData.tradeRoute.id : '');
        formPayload.append('unionId', this.vesselData.union ? this.vesselData.union.id : '');
        formPayload.append('beneficialId', this.vessel.beneficial ? this.vesselData.beneficial.id : '');
        formPayload.append('status', this.vesselData.status ? 1 : 0);
        formPayload.append('endServiceReason', this.vesselData.endServiceReason ? this.vesselData.endServiceReason : '');

        formPayload.append('dateDelivered', this.vesselData.dateDelivered ? this.vesselData.dateDelivered : '');
        formPayload.append('dateReDelivered', this.vesselData.dateReDelivered ? this.vesselData.dateReDelivered : '');
        formPayload.append('builtYear', this.vesselData.builtYear ? this.vesselData.builtYear : '');
        formPayload.append('vessel_classification', this.vesselData.classification ? this.vesselData.classification : '');
        formPayload.append('contractLength', this.vesselData.contractLength ? this.vesselData.contractLength : '');
        formPayload.append('maxOverTime', this.vesselData.maxOverTime ? this.vesselData.maxOverTime : '');
        formPayload.append('hrsPerWeek', this.vesselData.hrsPerWeek ? this.vesselData.hrsPerWeek : '');
        formPayload.append('kw', this.vesselData.kw ? this.vesselData.kw : '');
        formPayload.append('grt', this.vesselData.grt ? this.vesselData.grt : '');
        formPayload.append('dwt', this.vesselData.dwt ? this.vesselData.dwt : '');
        formPayload.append('imoNo', this.vesselData.imoNo ? this.vesselData.imoNo : '');
        formPayload.append('offNo', this.vesselData.offNo ? this.vesselData.offNo : '');
        formPayload.append('image', this.vesselData.image ? this.vesselData.image : ''); //todo if updating
        formPayload.append('engineType', this.vesselData.engineType ? this.vesselData.engineType : '');
        formPayload.append('ecdisTypeId', this.vesselData.ecdisTypeId ? this.vesselData.ecdisTypeId : '');

        this.updateVessel(formPayload).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert('Update vessel details successfully');
              this.isEditable = false;

              this.getVesselDetails(this.$route.params.vesselId).then((r) => {
                if (r) {
                  this.$nextTick(() => {
                    this.resetUpdateVessel(this.vessel);
                  });
                }
              });

            });
          }
        });
      }
    },

    onChangeAttachedPhoto(e) {
      const imgFile = e.target.files[0];
      const reader = new FileReader();
      if (imgFile != (null || undefined)) {
        reader.readAsDataURL(imgFile);
        reader.onload = ev => {
          let param = ev.target.result;
          this.attachedImage = param;
          this.currentAttachedImage = param;
        };

      } else {
        $(`#attached-photo`).val(null);
        this.attachedImage = null;

      }
    },

    attachedVesselImage() {
      const file = document.getElementById('attached-photo').files[0];
      this.vessel.image = file;
      const reader = new FileReader();
      if (file != (null || undefined)) {
        reader.readAsDataURL(file);
        reader.onload = ev => {
          let param = ev.target.result;
          this.attachedImage = param;
          this.currentAttachedImage = param;

        };
        this.$nextTick(() => {
          this.successAlert('Attached successfully');
          this.hideModal(this.uploadVesselImageModal);
        });
      } else {
        this.warningAlert('Please provide a valid vessel image.');
      }
    },

    cancelAttachedVesselImage() {
      this.hideModal(this.uploadVesselImageModal);
      $(`#attached-photo`).val(null);
      //this.vessel.image = null;
      this.attachedImage = null;

      this.getVesselDetails(this.$route.params.vesselId).then((r) => {
        if (r) {
          this.$nextTick(() => {
            this.resetUpdateVessel(this.vessel);
          });
        }
      });
    },

    removeAttachedImage() {
      if (this.attachedImage != (null || undefined)) {
        this.attachedImage = null;
        $(`#attached-photo`).val(null);

        this.getVesselDetails(this.$route.params.vesselId).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.resetUpdateVessel(this.vessel);
            });
          }
        });

      }
    },

    async deleteUploadPhotoModal() {
      let msgHtml = '<span>Do you want to delete photo attachment</span>';
      if (await this.questionAlert(msgHtml, 'Delete vessel photo')) {
        this.attachedImage = null;
        this.vesselData.image = '';
        $(`#attached-photo`).val(null);
      }
    },


  },

  computed: {
    ...mapGetters(
      [
        'vessel',

        'formSearchData',
        'objVesselTypes',
        'objFlags',
        'objOwners',
        'objPrincipals',
        'objMannings',
        'objVesselEngineTypes',
        'objVesselEngineTypes',
        'objBeneficiary',
        'objTradeRoutes',
        'objUnion',
        'objEcdisKeys',
        'objEmployeeKeys',
        'objEngineTypes',
        'objVesselClassification',

        "authUser",
      ]
    ),

    // image: {
    //   get() {
    //     return this.authUser.auth_image ? this.authUser.auth_image : this.noPhoto;
    //   }
    // }

  },

  watch: {
    'vessel'() {
      this.vesselData = Object.assign({}, this.vessel);
      // this.editable = false;
    },

    'attachedImage'() {
      // if (!this.attachedImage){
      //   this.getVesselDetails(this.$route.params.vesselId).then((r) => {
      //     if (r) {
      //       this.$nextTick(() => {
      //         this.resetUpdateVessel(this.vessel);
      //       });
      //     }
      //   });
      // }
    }

  },

  created() {
    this.view_vessel();
    this.getVesselListPaginated(this.formSearchData);
    this.$globalVariables = global_variables_config
  },



}
</script>

<style scoped>
.vessel-pic {
  width: 250px;
  height: auto;
}

#vessel-img-container {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

.vessel-img-container {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

.vessel-img {
  width: 200px;
  height: 200px;
}

#vessel-img {
  width: 200px;
  height: 200px;
}

</style>
