<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">View Crew Age Range</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.rankId"
                                @change="selectReportType"
                                required>
                          <option :value="null">All</option>
                          <option v-for="(rank, index) in objRanks" :value="rank.id">{{ rank.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">AGE RANGE</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="ageRangeSelected"
                                @change="selectReportType"
                                required>
                          <option :value="null">All</option>
                          <option value="20-30">20-30</option>
                          <option value="30-40">30-40</option>
                          <option value="40-50">40-50</option>
                          <option value="50-60">50-60</option>
                          <option value="60-70">60-70</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="getCrewAgeRange">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          :disabled="reportParam.rankId === 0"
                          @click="printReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
            <div class="row ml-2">
              <div class="col-sm-3">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label">FILTER BY : <b class="pl-2"
                                                                                    style="font-size: 1.1em;">{{
                          filterBy
                        }}</b></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;">SEAFARER NAME</th>
                    <th>RANK</th>
                    <th>AGE</th>
                    <th style="width: 10%;">SEA SERVICE STATUS</th>
                    <th># OF RECO LETTERS</th>
                    <th>LICENSE</th>
                    <th>US VISA EXP</th>
                    <th>SIRB EXP</th>
                    <th>PPORT EXP</th>
                    <th>COC/COE EXP</th>
                    <th>CREWTYPE</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td class="text-center align-middle pl-2"> {{ index + 1 }}</td>
                          <td class="text-center align-middle pl-2">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>
                          </td>
                          <td class="text-center align-middle pl-2"> {{ crew.rank_alias }}</td>
                          <td class="text-center align-middle pl-2"> {{ crew.age }}</td>
                          <td class="text-center align-middle pl-2"> {{ crew.seaServiceStatus }}</td>
                          <td class="text-center align-middle pl-2"> N/A</td>
                          <td class="text-center align-middle pl-2"> {{ crew.LicenseDoc }}</td>
                          <td class="text-center align-middle pl-2" :class="{'text-danger': crew.isUSVisaExpire}"> {{ crew.usVisaDocExpiry }}</td>
                          <td class="text-center align-middle pl-2" :class="{'text-danger': crew.isSIRBDocExpire}"> {{ crew.SIRBDocExpiry }}</td>
                          <td class="text-center align-middle pl-2" :class="{'text-danger': crew.isPassportExpire}"> {{ crew.passportDocExpiry }}</td>
                          <td class="text-center align-middle pl-2" :class="{'text-danger': crew.isCOCDocExpire}"> {{ crew.cocDoc }}</td>
                          <td class="text-center align-middle pl-2"> {{ crew.crewType }}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";

export default {
  name: "CrewAgeRange",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      isEditable: false,
      isGenerateOnboardButton: false,
      reportParam: {
        rankId: null,
        isPDF: false,
        rankName: null,
        ageRange: null,
        ageRangeSelected: null
      },

      isLoading: false,
      hasData: false,
      ranks: null,
      crewData: null,
      rankSelected: null,
      ageRangeSelected: null
    }
  },


  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
    ]),
    selectReportType() {
      this.hasData = false;
      this.rankSelected = this.getRankName(this.reportParam.rankId);
      if (this.ageRangeSelected != null) {
        const [from, to] = this.ageRangeSelected.split("-");

        this.reportParam.ageRange = {
          from: parseInt(from, 10),
          to: parseInt(to, 10),
        }
      } else
        this.reportParam.ageRange = null;
    },

    getRankName(rankId) {
      let rankName = '';
      const rankList = this.ranks
      for (let c in rankList) {
        if (parseInt(rankList[c].id) === parseInt(rankId)) {
          rankName = rankList[c].name;
        }
      }
      return rankName;
    },

    async getCrewAgeRange() {
      if (this.reportParam.rankId === 0) {
        this.errorAlert('Please Select Rank', '')
      } else {
        this.reportParam.isPDF = false;
        this.hasData = false;
        let params = this.reportParam;
        this.isLoading = true;
        return await axios.post(PMC.GET_CREW_AGE_RANGE, params).then(async r => {
          this.isLoading = false;
          if (Object.keys(r.data).length > 0) {
            this.hasData = true;
            this.crewData = r.data;
          } else {
            this.hasData = false;
            this.crewData = null;
          }
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
          return false
        });
      }
    },

    async getRanks() {
      let params = {};
      return await axios.get(PMC.GET_ACTIVE_RANK_LEVEL, params).then(async r => {
        this.ranks = r.data;
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
        return false
      });
    },

    async printCrewAgeRange() {

      this.reportParam.rankName = this.rankSelected;
      this.reportParam.isPDF = true;
      this.reportParam.ageRangeSelected = this.ageRangeSelected
      let params = this.reportParam;

      return await axios.post(PMC.GET_CREW_AGE_RANGE, params).then(async r => {
        this.isLoading = false;
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Crew Age Range')
        return false
      });
    },

    async printReport() {
      AlertService.loadingAlert('Please wait ...', 'Generating PDF')

        let pdfData = await this.printCrewAgeRange();
        if (pdfData) {
          await this.pdfViewer('File', pdfData);
          Swal.close();
        } else {
          Swal.close();
        }
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
        [
            'isReloading',
            'objRanks'
        ]),
    filterBy() {
      let filters = [];

      if (this.rankSelected){
        filters.push(this.getRankName(this.reportParam.rankId));
      } else
        filters.push("ALL")

      if (this.ageRangeSelected){
        filters.push(this.ageRangeSelected);
      }

      filters = filters.filter(Boolean).join(' / ');

      return filters;
    }
  },

  /** mounted **/
  mounted() {
    this.getRanks();
  },

  /** created **/
  async created() {
    this.$MyGlobalFunction = global_variables_config
    await this.getCrewComplimentPerVessel();

    if (this.$route.query.view != null) {
      this.reportParam.rankId = parseInt(this.$route.query.rankId);
      this.getCrewAgeRange();
    }

  },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },
}
</script>

<style scoped>

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}
</style>
