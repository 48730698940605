import {UtilityService} from '../../../service/utility.service';
import axios from 'axios';
import {PMC} from '../../../config/constants.config';

/** state **/
const state = {
  crewFamilies: [],
  crewFamily: {},
  newFamily: {
    firstName: null,
    middleName: null,
    lastName: null,
    suffixName: null,
    relationId: null,
    genderId: null,
    birthdayDate: null,
    email: null,
    address: null,
    telNum: null,
    telNum1: null,
    mobile: null,
    mobile2: null,
    isDependent: null,
    isAllottee: null,
    isICE: null,
    isBeneficiary: null,
  },
};

/** mutations **/
const mutations = {

  SET_CREW_FAMILIES(state, params) {
    state.crewFamilies = params;
  },

  SET_CREW_FAMILY(state, param) {
    state.crewFamily = param;
  },

  UPDATE_CREW_FAMILY(state, param) {
    let fam = state.crewFamilies.find((fam, index) => fam.id === param.id);
    Object.assign(fam, param);
  },

  CREATE_CREW_FAMILY(state, param) {
    state.crewFamilies.push(param);
    state.newFamily = {
      firstName: null,
      middleName: null,
      lastName: null,
      suffixName: null,
      relationId: null,
      genderId: null,
      birthdayDate: null,
      email: null,
      address: null,
      telNum: null,
      telNum1: null,
      mobile: null,
      mobile2: null,
      isDependent: null,
      isAllottee: null,
      isICE: null,
      isBeneficiary: null,
    };
  },

  DELETE_CREW_FAMILY(state, param) {
    let index = state.crewFamilies.findIndex((fam, index) => fam.id === param.id);
    state.crewFamilies.splice(index, 1);
  },

  NULL_FAMILIES(state) {
    state.crewFamilies = [];
  },

  NULL_FAMILY(state) {
    state.crewFamily = {};
  },
};

/** actions **/
const actions = {
  viewFamilyList({commit}, param) {
    commit('set_is_loading',true)
    return axios.get(`${PMC.GET_CREW_FAMILIES}/${param}`).then((response) => {
      commit('SET_CREW_FAMILIES', response.data);
      commit('set_is_loading',false)
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Family List');
      return false;
    });
  },

  viewFamily({commit}, param) {
    return axios.get(`${PMC.CREW_FAMILY}/${param}`).then((res) => {
      commit('SET_CREW_FAMILY', res.data);
      return true;

    }).catch((er) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(er, 'View Detail');
      return false;

    });
  },

  createFamily({commit}, params) {
    let paramParse = UtilityService.deCapitalizeProperties(params);
    return axios.post(PMC.CREW_FAMILY, params).then((res) => {
      commit('CREATE_CREW_FAMILY', res.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error);
      return false;

    });
  },

  updateFamily({commit}, param) {
    return axios.put(`${PMC.CREW_FAMILY}/${param.id}`, param).then((res) => {
      commit('UPDATE_CREW_FAMILY', res.data);
      return true;

    }).catch((err) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(err, 'Updating Crew Family');
      return false;

    });
  },

  deleteFamily({commit}, param) {
    return axios.delete(`${PMC.CREW_FAMILY}/${param}`).then((res) => {
      commit('DELETE_CREW_FAMILY', res.data);
      return true;

    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Deleting Crew Family');
      return false;

    });
  },

  nullFamily({commit}) {
    commit('NULL_FAMILY');
  },

};

/** getters **/
const getters = {
  crewFamilies: state => UtilityService.capitalizeProperties(state.crewFamilies),
  crewFamily: state => UtilityService.capitalizeProperties(state.crewFamily),
  newFamily: state => UtilityService.capitalizeProperties(state.newFamily),
};

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
};
