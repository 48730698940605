import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";

const initAgentState = () => {
  return {
    agentListDtls : [],
    objNotedByKeys : [],
    objBatches :  [],
  }
}

const state  = {
  ...initAgentState()
}

const mutations = {
  set_agent_list_state(state,payload){
    state.agentListDtls = payload.agentListDetails;
    state.objBatches = payload.objCcMemoBatches;
    state.objNotedByKeys = payload.objNotedByKeys;
  },
  reset_agent_list_state(state) {
    Object.assign(state,initAgentState())
  }
}

const actions = {
 async get_agent_list({commit},params) {
    return await axios.get(PMC.AGENT_LIST,{params}).then((r)=>{
      commit('set_agent_list_state',r.data)
      return true;
    }).catch((er)=>{
      return false;
    });
  },

 async print_agent_list({commit},params){
   return await axios.post(PMC.AGENT_LIST_PRINT,params).then((r)=>{
     //commit('set_agent_list_state',r.data)
     return r.data;
   }).catch((er)=>{
      return er;
   });
  },

  reset_agent_list_state({commit}){
    commit('reset_agent_list_state')
  }
}

const getters = {
  agentListDtls : state => state.agentListDtls,
  objNotedByKeys : state => UtilityService.capitalizeProperties(state.objNotedByKeys),
  objBatches : state => UtilityService.capitalizeProperties(state.objBatches),
}

export default {state,actions,mutations,getters}
