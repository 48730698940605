/** state **/
import { UtilityService } from '../../../service/utility.service'
import axios from 'axios'
import { PMC } from '../../../config/constants.config'
import {FileService} from "@/service/file.service";

const state = {
  referral: {},
  referrals: [],
  // newReferral : {} optional

}

/** mutations **/
const mutations = {
  set_referral (state, params) {
    state.referral = params
  },

  set_referrals (state, params) {
    state.referrals = params
  },

  //todo create_referral(state,param){},

  //todo update_referral(state,param){},

  //todo delete_referral(state,param){}

  null_referral(state,param){
    state.referral = {};
  },

  null_referrals(state,param){
    state.referrals = [];
  }
}

/** actions **/
const actions = {
  set_referral ({commit}, params) {

  },

 async set_referrals ({commit}, params) {
    commit('set_is_loading',true)
    return axios.get(`${PMC.GET_CREW_REFERRAL}/${params}`).then((r)=>{
      commit('set_referrals',r.data)
      commit('set_is_loading',false)
      return true
    }).catch((er)=>{
      UtilityService.generateResponseMessage(er,'Get refferal')
      return false
    })
  },

  async generateEndorsedCrew({commit}, params) {
    return await axios.post(`${PMC.GENERATE_ENDORSED_CREW}/${params}`).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate endorsed crew')
      return false
    });
  },

  null_referral ({commit}) {
    commit('null_referral')
  },

  null_referrals ({commit}) {
    commit('null_referrals')
  }
}

/** getters **/
const getters = {
  referral: state => UtilityService.capitalizeProperties(state.referral),
  referrals: state => UtilityService.capitalizeProperties(state.referrals),
}

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
}
