/** state **/
import { UtilityService } from '../../../service/utility.service'
import { PMC } from '../../../config/constants.config'
import axios from 'axios';
import { AlertService } from '../../../service/alert.service'

const state = {
  employee: {},
  employees: [],
  newEmployee: {
    last_name: "",
    middle_name: "",
    first_name: "",
    suffix_name: "",
    e_name: "",
    signatory_name: "",
    mobile_no1: "",
    mobile_no2: "",
    land_line1: "",
    land_line2: "",
    email1: "",
    email2: "",
    username: "",
    password: "",
    birthdate: "",
    date_hired: "",
    sss_no: "",
    tin_no: "",
    phil_health_no: "",
    pag_ibig_no: "",
    department_id: null,
    company_id: null,
    civil_status_id: null,
    gender_id: null,
    status: null,
    file : null,
  },

  //employee pagination
  employeePagination : {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },

  employeeFormParam: {
    searchValue: null,
    searchKey: 'all',
    count: 10,
    status: 1, //default true
    page: null,
  },

  employeeImage : null,
}

/** mutations **/
const mutations = {
  set_employee (state, params) {
    state.employee = params;
  },

  set_employee_paginated (state, params) {
    state.employees = params;
  },

  set_employees (state, params) {
    state.employees = params;
  },

  create_employee (state) {
    state.newEmployee = {
      last_name: "",
      middle_name: "",
      first_name: "",
      suffix_name: "",
      e_name: "",
      signatory_name: "",
      mobile_no1: "",
      mobile_no2: "",
      land_line1: "",
      land_line2: "",
      email1: "",
      email2: "",
      username: "",
      password: "",
      birthdate: "",
      date_hired: "",
      sss_no: "",
      tin_no: "",
      phil_health_no: "",
      pag_ibig_no: "",
      department_id: null,
      company_id: null,
      civil_status_id: null,
      gender_id: null,
      status: null,
      file : null,
      position: null,
    }
  },

  //update employee
  update_employee (state, params) {
    state.employee = params;
  },

  //delete employee
  delete_employee (state, params) {
    let index = state.employees.findIndex((val,index)=>val.id ===params.id);
    state.employees.splice(index,1);
  },

  null_employee(state){
    state.employee  = {};
  },

  null_employees(state){
    state.employees = [];
  },

  null_new_employee(state){
    state.newEmployee = {
      last_name: "",
      middle_name: "",
      first_name: "",
      suffix_name: "",
      e_name: "",
      signatory_name: "",
      mobile_no1: "",
      mobile_no2: "",
      land_line1: "",
      land_line2: "",
      email1: "",
      email2: "",
      username: "",
      password: "",
      birthdate: "",
      date_hired: "",
      sss_no: "",
      tin_no: "",
      phil_health_no: "",
      pag_ibig_no: "",
      department_id: null,
      company_id: null,
      civil_status_id: null,
      gender_id: null,
      status: null,
      file : null,
    }
  },

  set_employee_pagination(state,params){
    state.employeePagination = {
      from : params.from,
      currentPage: params.current_page,
      lastPage : params.last_page,
      perPage : params.per_page,
      to: params.to,
      total: params.total,
    }
  },

  reset_employee_pagination(state,params){
    state.employeepagination = {
      from: 1,
      currentPage: 1,
      lastPage: 0,
      perPage: 10,
      to: 10,
      total: 0,
    }
  },

  set_employee_form_param(state,params){
    state.employeeFormParam = {
      searchValue: params.searchValue,
      searchKey: params.searchKey,
      count: params.count,
      status: params.status,
      page: params.null,
    }
  },

  null_employee_form_param(state){
    state.employeeFormParam = {
      searchValue: null,
      searchKey: 'all',
      count: 10,
      status: 1,
      page: null,
    }
  },

  set_employee_image(state,param){
    state.employee.image = param;
  },

  null_employee_image(state){
    state.employee.image = null;
  },
}

/** actions **/
const actions = {
  async set_employee ({ commit }, params) {
    return await axios.get(`${PMC.EMPLOYEE}/${params}`).then((response)=>{
      commit('set_employee', response.data.employee);
      commit('set_obj_departments',response.data.departments);
      commit('set_obj_genders',response.data.genders);
      commit('set_obj_companies',response.data.companies);
      commit('set_obj_civilStatus',response.data.civilstatus);
      commit('set_obj_positions',response.data.positions);
      return true;
    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Get employee details');
      return false;

    });
  },

 async set_employee_paginated ({ commit }, params) {
    commit('set_is_loading',true)
    return await axios.get(PMC.EMPLOYEE,{ params}).then((response)=>{
      commit('set_employee_paginated', response.data.data);
      commit('set_employee_pagination',response.data);
      commit('set_is_loading',false)
      return true;

    }).catch((error)=>{
      return false;

    });
  },

  set_employees ({ commit }, params) {
    commit('set_employees');
  },

 async create_employee ({ commit }, params) {
    let create = AlertService.questionAlertService("Are you sure you want to add this new record",'New record','question');
    if(await create){
      const formData = new FormData();

      formData.append('last_name', params.last_name)
      formData.append('first_name', params.first_name)
      formData.append('middle_name', params.middle_name)
      formData.append('suffix_name', params.suffix_name)
      formData.append('e_name', params.e_name)
      formData.append('signatory_name', params.signatory_name)
      formData.append('mobile_no1', params.mobile_no1)
      formData.append('mobile_no2', params.mobile_no2)
      formData.append('land_line1', params.land_line1)
      formData.append('land_line2', params.land_line2)
      formData.append('email1', params.email1)
      formData.append('email2', params.email2)

      formData.append('birthdate', params.birthdate)
      formData.append('date_hired', params.date_hired)
      formData.append('date_separated', params.date_separated)
      formData.append('sss_no', params.sss_no)
      formData.append('tin_no', params.tin_no)
      formData.append('phil_health_no', params.phil_health_no)

      formData.append('username', params.username)
      formData.append('password', params.password)

      formData.append('gender_id', params.gender_id)
      formData.append('civil_status', params.civil_status)
      formData.append('department_id', params.department_id)
      formData.append('company_id', params.company_id)

      formData.append('status', params.status)
      formData.append('file', params.file)

      formData.append('pag_ibig_no', params.pag_ibig_no)
      // formData.append('civil_status', params.civil_status)
      formData.append('date_separated', params.date_separated)

      return axios.post(PMC.EMPLOYEE,formData).then((response)=>{
        AlertService.successAlertService("Record save successfully",'New record');
        commit('create_employee');
        return true;

      }).catch((error)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(error,'New employee record');
        return false;

      })
    }
  },

  update_employee ({ commit }, params) {
   return axios.put(`${PMC.EMPLOYEE}/${params.id}`,params).then((res)=>{
     //axios
     commit('update_employee',res.data);
    return true;

   }).catch((er)=>{
     UtilityService.generateResponseMessage(er,'Update employee');
     return false;

   });
  },

 async delete_employee ({ commit }, params) {
   let _del = AlertService.questionAlertService("Are you sure you want to delete this record",'Delete employee','question');
   if(await _del){
     return axios.delete(`${PMC.EMPLOYEE}/${params}`).then((res)=>{
       AlertService.successAlertService("Record delete successfully",'Delete employee');
       commit('delete_employee',res.data);
       return true;

     }).catch((er)=>{
       UtilityService.failProgressBar();
       UtilityService.generateResponseMessage(er,'Delete employee');
       return false;

     });
   }
  },

  null_employee ({commit}) {
    commit('null_employee');
  },

  null_employees ({commit}) {
    commit('null_employees');
  },

  null_new_employee ({commit}) {
    commit('null_new_employee');
  },

  //reset pagination
  reset_employee_pagination ({commit}) {
    commit('reset_employee_pagination');
  },

  //set employee form param;
  set_employee_form_param({commit},params){
    commit('set_employee_form_param',params);
  },

  null_employee_form_param ({commit}) {
    commit('null_employee_form_param');
  },

  upload_employee_image ({commit},params) {
      return axios.post(`${PMC.UPLOAD_EMPLOYEE_IMAGE}`,params.data).then((response)=>{
        let __user = JSON.parse(localStorage.getItem('userDetails')).id;
        if(__user == params.employeeId){
          commit('set_auth_img',response.data);

        }

        commit('set_employee_image',response.data);
        return true;

      }).catch((error)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(error,'Upload employee details.')
        return false;
      })
  },

 async delete_employee_image({commit},params){
    let del = AlertService.questionAlertService("Do you want to remove your image",'Remove image','question');
    if(await del){
      return axios.delete(`${PMC.DELETE_EMPLOYEE_IMAGE}/${params}`).then((response)=>{
        let __user = JSON.parse(localStorage.getItem('userDetails')).id;
        if(__user == params){
          commit('null_auth_img',response.data);
        }
        commit('null_employee_image');
        AlertService.successAlertService("Image successully deleted",'Remove image');
        return true;
      }).catch((error)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(error,'Delete employee image');
        return false;
      });
    }
  },

 async reset_employee_password({commit},params){
    console.log('reset_employee_password',params);
   let __reset = AlertService.questionAlertService("Are you sure you want \n to reset this employee password",'Reset password');
   if(await __reset){
     return axios.post(PMC.RESET_PASSWORD,params).then((response)=>{
       AlertService.successAlertService("Password reset into default succeessfuly.",'Reset password');
       commit('set_employee',response.data);
       $(`#reset-password-modal`).modal('hide');
       return true;
     }).catch((error)=>{
       UtilityService.generateResponseMessage(error,"Reset password");
       return false;
     });
   }
  }
}

/** getters **/
const getters = {
  employees: state => UtilityService.capitalizeProperties(state.employees),
  employee: state => UtilityService.capitalizeProperties(state.employee),
  newEmployee: state => UtilityService.capitalizeProperties(state.newEmployee),
  employeeFormParam : state => state.employeeFormParam,

  //put employee pagination here.
  employeePagination : state => state.employeePagination,

}

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
}
