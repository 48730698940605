import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  gearComplements: [],
  newGearComplement: {},

  objOwnersWithVessel: [],
  objVesselsWithOwner: [],


  gearComplementFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },

  gearComplementPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },

};


const mutations = {
  set_objOwnersWithVessel(state, params) {
    state.objOwnersWithVessel = params
  },

  set_objVesselsWithOwner(state, params) {
    state.objVesselsWithOwner = params
  },

  set_gearComplements(state, params) {
    state.gearComplements = params
  },


  create_gearComplement(state) {
    state.newGearComplement = {};
  },


  update_gearComplement(state, params) {
    let index = state.gearComplements.find((val, index) => val.id === params.id)
    Object.assign(index, params)
  },

  delete_gearComplement(state, gearComplementId) {
    let index = state.gearComplements.findIndex((val, index) => val.id === gearComplementId)
    state.gearComplements.splice(index, 1)
  },

  set_gearComplement_pagination(state, params) {
    state.gearSupplierRatePagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {

  getOwnersWithVessel ({ commit }, params) {
    return axios.get(PMC.GET_OWNER_WITH_VESSEL_KEYS, { params }).then((r) => {
      commit('set_objOwnersWithVessel', r.data)
      return true
    }).catch((err) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(err, 'Owners With Vessel Keys')
      return false

    })
  },

  getVesselsWithOwner ({ commit }, params) {
    return axios.get(PMC.GET_VESSEL_WITH_OWNER_KEYS, { params }).then((r) => {
      commit('set_objVesselsWithOwner', r.data)
      return true
    }).catch((err) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(err, 'Vessels With Owner Keys')
      return false

    })
  },


  async set_gearComplements({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GEAR_COMPLEMENT, {params}).then((r) => {
      commit('set_gearComplements', r.data.gearComplements)
      commit('set_gearComplement_pagination', r.data.gearComplements)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get gear complements')
      return false
    })
  },

  async create_gearComplement({commit}, params) {
    return axios.post(PMC.GEAR_COMPLEMENT, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_gearComplement', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create gear complement');
      return false;
    })
  },

  async update_gearComplement({commit}, params) {
    return axios.put(`${PMC.GEAR_COMPLEMENT}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_gearComplement',r.data.updatedGearComplement);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update gear complement');
      return false;

    });
  },

  async delete_gearComplement({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Gear Complement','question');
    if(await _del){
      return axios.delete(`${PMC.GEAR_COMPLEMENT}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete gear complement');
        commit('delete_gearComplement',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete gear");
        return false;

      })
    }
  },

};


const getters = {
  objOwnersWithVessel: state => UtilityService.capitalizeProperties(state.objOwnersWithVessel),
  objVesselsWithOwner: state => UtilityService.capitalizeProperties(state.objVesselsWithOwner),
  gearComplements: state => UtilityService.capitalizeProperties(state.gearComplements),
};


/** export **/
export default {state, actions, mutations, getters};
