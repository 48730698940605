<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom py-lg-0">
      <div class="container-fluid">
        <a class="navbar-brand p-0" @click="goToDashboard" v-if="!$route.query.view ">
          <img :src="pmcLogo" alt="" style="min-width: 275px; max-width: 450px; height: auto;">
        </a>

        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>


        <ul v-if="!form" class="navbar-nav ml-5">
          <div class="row">
            <div class="col">
              <div id="form-search-input"
                   class="d-none d-sm-inline-block form-inline mr-auto my-2 mt-3 mw-100 navbar-search px-2">
                <div class="input-group">
                  <input
                    @keypress.down="get_all_crew_paginated"
                    type="search"
                    id="search-value"
                    class="form-control border small"
                    placeholder="Quick Search Crew.."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    v-model="searchValue">

                  <div class="input-group-append">
                    <button
                      id="btn-search"
                      class="btn pmc-btn-second border"
                      type="button"
                      data-toggle="modal"
                      data-target="#searchCrew"
                      data-backdrop="static"
                      data-keyboard="false"
                      v-on:click="get_all_crew_paginated">
                      <font-awesome-icon icon="search"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--&lt;!&ndash; Nav Item - Alerts &ndash;&gt;
          <li class="nav-item dropdown no-arrow mx-1 d-flex align-items-center">
            <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <font-awesome-icon class="fas fa-fw"  icon="bell" />
              &lt;!&ndash; Counter - Alerts &ndash;&gt;
              <span class="badge badge-danger badge-counter">3+</span>
            </a>
            &lt;!&ndash; Dropdown - Alerts &ndash;&gt;
            <div class="dropdown-menu dropdown-menu-right shadow animated&#45;&#45;grow-in " aria-labelledby="alertsDropdown"></div>
          </li>-->

          <!-- Nav Item - Messages -->
          <!--<li class="nav-item dropdown no-arrow mx-1 d-flex align-items-center">
            <a class="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <font-awesome-icon class="fas fa-fw"  icon="envelope" />
              &lt;!&ndash; Counter - Messages &ndash;&gt;
              <span class="badge badge-danger badge-counter">7</span>
            </a>
            &lt;!&ndash; Dropdown - Messages &ndash;&gt;
            <div class="dropdown-menu dropdown-menu-right shadow animated&#45;&#45;grow-in " aria-labelledby="messagesDropdown">

            </div>
          </li>-->

          <div class="border d-none d-sm-block"></div>

          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow d-flex align-items-center">
            <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" id="userDropdown" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <!--<span class="mr-2 d-none d-lg-inline small text-left">{{ this.titleCase(authUser.name)}}</span>-->
              <span
                class="mr-2 d-none d-lg-inline text-left">{{ authUser.name ? authUser.name.toUpperCase() : '' }}</span>
              <!--<span class="mr-2 d-none d-lg-inline text-left" id="text-name">This is the sample of long name</span>-->
              <img :src="image" alt="image" class="img-fluid rounded" height="42" width="42">
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in " aria-labelledby="userDropdown">

              <p class="pmc-text-tertia text-center pl-2 mb-0 font-weight-bold fa-xs small">{{ b4rExpired }} d :
                remaining</p>

              <hr class="dropdown-divider"/>

              <router-link class="dropdown-item text-left" :to="{name:'AuthProfile'}">
                <font-awesome-icon icon="user-tie" class="fas fa-sm fa-fw mr-2 "/>
                <span>Profile</span>
              </router-link>
              <hr class="dropdown-divider"/>

              <a class="dropdown-item text-left" href="#" @click="logout_user">
                <font-awesome-icon icon="sign-out-alt" class="fas fa-sm fa-fw mr-2 "/>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>

import PmcLogo from '../../../assets/pmc_logo.jpg';
import Modal from '../../base/Modal'
import {mapActions, mapGetters} from 'vuex'
import {AppMixins} from '../../../mixins/app.mixins'
import {AlertMixins} from '../../../mixins/alert.mixins'
import noPhoto from '../../../assets/noPhoto.jpg';

import Vue from "vue";

export default {
  name: 'Header',
  mixins: [AppMixins, AlertMixins],
  data() {
    return {
      id: 'demo',
      pmcLogo: PmcLogo,
      crewSelected: null,

      isSearching: false,

      crewKeys: [
        {id: 1, name: 'Name1'},
        {id: 2, name: 'Name2'},
        {id: 3, name: 'Name3'},
        {id: 4, name: 'Name4'},
      ],

      noPhoto: noPhoto,
    }
  },

  methods: {
    ...mapActions(
      [
        'logoutUser',
        'setSearchParams',
        'getAlLCrewPaginated',
        'getAuthUser',
      ]
    ),

    get_all_crew_paginated() {
      this.getAlLCrewPaginated(this.searchParams);
    },

    async logout_user() {
      if (await this.questionAlert('Are you sure you want to logout', 'Logout')) {
        this.logoutUser().then((r) => {
          if (r) {
            this.$router.push({name: 'Login'});
          }
        });
      }
    },

    goToDashboard() {
        this.$router.push({name: 'Dashboard1'}).catch(err => {
        });
    },
  },

  computed: {
    ...mapGetters(
      [
        'searchParams',
        'authUser',
        'b4rExpired'
      ]
    ),

    searchValue: {
      get() {
        return this.searchParams.searchValue;
      },

      set(val) {
        this.searchParams.searchValue = val;
      }
    },

    image: {
      get() {
        return this.authUser.auth_image ? this.authUser.auth_image : this.noPhoto;
      }
    },

    form() {
      let form = this.$route.query.view;
      return form ? form : null;
    }

  },

  mounted() {
    $('#search-value').unbind("keyup").keyup(function (e) {
      let code = e.which;
      if (code == 13) $("#btn-search").click();
    });
  },

  created() {
    //this.getAuthUser();
  },

  destroyed() {

  },
}
</script>

<style scoped>

datalist {
  max-height: 10px !important;
  overflow-y: scroll;
}


</style>
