<template>
	<div class="row justify-content-center">
		<div class="col col-md">
			<div class="table table-responsive pmc-table">

				<div class="col-md-4">
					<div class="row">
						<div class="form-inline mb-3">
							<div class="form-group mb-2">
								<h5 class="font-weight-bold lead">Vessel: {{ currentVesselData?.name }} </h5>
							</div>

						</div>

					</div>
				</div>

				<table class="table table-bordered table-sm text-center small">
					<thead>
					<tr>
						<th style="width: 3%;">
							#
						</th>
						<th class="">Rank</th>
						<th class="">Crew</th>
						<th class="">Promote</th>
						<th class="">Depart</th>
						<th class="">Joined</th>
						<th class="">EOC</th>
						<th class="">Mo(s) On Board</th>
						<th class="">Reliever</th>
						<th class="">CCM No.</th>
						<th class="">View Checklist</th>

					</tr>
					</thead>
					<tbody>


<!--					<template v-if="loading">-->
<!--						<tr>-->
<!--							<td colspan="100%">-->
<!--								<span class="spinner-border text-primary" role="status" aria-hidden="true"></span>-->
<!--								<span style="size: 20px">-->
<!--                          Loading ...-->
<!--                        </span>-->
<!--							</td>-->
<!--						</tr>-->
<!--					</template>-->

					<template>
						<template>
							<tr v-for="(ccPlan, index) in crewChangePlans" :key="ccPlan.id">
								<td class="text-center align-middle">
									{{ index + 1 }}
								</td>
								<td class="text-center align-middle">
									{{ ccPlan.rankAlias?.toUpperCase() }}
								</td>

								<td class="text-center align-middle">
                  {{ ccPlan.crewSignOff?.toUpperCase() }}
								</td>

								<td class="text-center align-middle">
                  {{ ccPlan.ccPromotionDateApproved | moment("DD - MMM - YY") }}
								</td>

                <td class="text-center align-middle">
                  {{ ccPlan.ccOffDepartureDate | moment("DD - MMM - YY") }}
                </td>

                <td class="text-center align-middle">
                  {{ ccPlan.ccOffSignOnDate | moment("DD - MMM - YY") }}
                </td>

                <td class="text-center align-middle">
                  {{ ccPlan.ccOffEocDateFromDocLatestContract | moment("DD - MMM - YY") }}
                </td>

                <td class="text-center align-middle">
                  {{ ccPlan.monthsOnBoard }}
                </td>

                <td class="text-center align-middle">
                  {{ ccPlan.crewSignOn?.toUpperCase() }}
                </td>
                <td class="text-center align-middle">
                  <button v-if="ccPlan.cBatchingNo"
                          class="btn btn-xs btn-light"
                          @click="getCrewChangeMemo(ccPlan.cBatchingId, ccPlan.cBatchingNo)">
                    <span class="font-weight-bold">{{ ccPlan.cBatchingNo }}</span>
                  </button>
                </td>
                <td class="text-center align-middle">
                  <button v-if="ccPlan.cBatchingNo"
                          @click="ccmViewChecklistSignOn(ccPlan.cBatchingId, ccPlan.cBatchingNo)"
                          class="btn btn-xs btn-light">
                    <span class="font-weight-bold">View</span>
                  </button>
                </td>


							</tr>

						</template>

<!--						<template v-else>-->
<!--							<tr>-->
<!--								<td colspan="100%" class="bg-light-danger font-weight-bolder">-->
<!--									<p class="text-center mt-3">No Results</p>-->
<!--								</td>-->
<!--							</tr>-->
<!--						</template>-->


					</template>

					</tbody>

				</table>

			</div>


      <div class="table table-responsive pmc-table">

        <div class="col-md-4">
          <div class="row">
            <div class="form-inline mb-3">
              <div class="form-group mb-2">
                <h5 class="font-weight-bold lead">Remarks: </h5>
              </div>

            </div>

          </div>
        </div>

        <table class="table table-sm text-center small">

          <tbody>
          <template>
            <template>
              <tr v-for="(vesselRemark, index) in vesselRemarks" :key="vesselRemark.id">
                <td class="text-left align-middle" style="width: 18%;">
                  <span class="font-weight-bold">
                    {{ vesselRemark.createdAt }}
                  </span>
                </td>
                <td class="text-left align-middle">
                  {{ vesselRemark.remarks }}
                </td>

              </tr>

            </template>


          </template>

          </tbody>

        </table>

      </div>






		</div>



    <!-- CC Memo Modal   -->
<!--    <div class="modal fade" id="cc-memo-modal" tabindex="-1" role="dialog"-->
<!--         aria-labelledby="ccMemoModal"-->
<!--         aria-hidden="true">-->
<!--      <div class="modal-dialog modal-dialog-centered full-screen-modal2" role="document">-->
<!--        <div class="modal-content" style="height: 100%;">-->
<!--          <div class="modal-header">-->
<!--            <h4 class="modal-title" id="cc-modal-header-label">Crew Change Memo</h4>-->
<!--            <button class="border-0" @click="closeCcMemoModal()" aria-label="Close">-->
<!--              <font-awesome-icon icon="times" class="text-danger"/>-->
<!--              Close-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="modal-body">-->
<!--            <crew-change-memo :crews="selectedCrewChange"-->
<!--                              :bus="bus"-->
<!--                              :vessel-id="currentVesselData?.id"/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


    <!-- VIEW CHECKLIST SIGNON -->
    <div class="modal fade" id="view-ccplan-checklist" tabindex="-1" role="dialog" aria-labelledby="setupCcPlanCenter"
         aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-size" role="document">
        <!--      <div class="modal-dialog modal-dialog-centered full-screen-modal2" role="document">-->
        <div class="modal-content" style="height: 120%">
          <div class="modal-header">

            <div class="row d-flex align-items-center px-3">
              <h5 class="modal-title font-weight-bolder mr-5" id="CrewBiodataTitle">View Checklist</h5>
              <button type="button" class="btn btn-success btn-sm px-3 font-weight-normal"
                      @click="loadCcmMasterChecklist($globalVariables.ccmMaster.checklist)"
              >
                Print
              </button>

              <div class="form-group mb-2 ml-3">
                <select class="form-control form-control-sm" v-model="ccmMasterChecklistParams.signatory">
                  <option value="null">-- checked by ---</option>
                  <option :value="signatory" v-for="(signatory,index) in $globalVariables.ccm_master_checklist_signatories"
                          :key="signatory.id">
                    {{ signatory.name }}
                  </option>
                </select>
              </div>
            </div>


            <button class="btn btn-sm ml-2" @click="closeViewChecklistModal()" aria-label="Close">
              <font-awesome-icon icon="times" class="text-danger"/>
              Close
            </button>
          </div>
          <div class="modal-body" style="overflow-x: hidden !important; overflow-y: scroll;  height: 700px">

            <ccm-master-checklist-modal-body
              :loading="isReloading"
              :ccm-master-checklist="ccmMasterChecklist"
              :ccm-flag-id="ccmFlagId"
              :ccm-flag-name="ccmFlagName"
              :ccm-vessel-id="ccmVesselId"
              :ccm-vessel-ecdis-id="ccmVesselEcdisId"
              :ccm-vessel-ecdis-name="ccmVesselEcdisName"
            />

          </div>
          <div class="modal-footer">
            <table style="width:100%;">
              <tbody>
              <tr>
                <td><span style="font-weight:bold;">Legend : * - With one higher license</span></td>
                <td>
                </td>
                <td><span style="text-align:right;background-color: Gray; color:white;"><b>Non Mandatory</b></span></td>
                <td>
                </td>
                <td><span style="text-align:right;background-color: Yellow"><b>Near Expiry</b></span></td>
                <td>
                </td>
                <td><span style="text-align:right;background-color: #F5761A;color:Black;"><b>No File Upload</b></span>
                </td>
                <td>
                </td>
                <td><span
                  style="text-align:right;background-color: Red;color:white;"><b>Expired Documents</b></span>
                </td>
                <td>
                </td>
                <td><span
                  style="text-align:right;background-color:Purple;color:white"><b>Mismatch Document No.2 Detail</b></span>
                </td>
                <td>
                </td>
                <td><span
                  style="text-align:right;background-color:Black;color:white"><b>Mismatch Document Rank</b></span>
                </td>
                <td>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>


	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";

import {global_variables_config} from "@/config/global-variable.config";
import {AlertMixins} from "@/mixins/alert.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";

export default {
  name: "DashboardCrewChangePlanView",

  mixins: [AlertMixins],

  props: {

    crewChangePlans: {
      type: Array,
      default: [],
    },

    vesselRemarks: {
      type: Array,
      default: [],
    },

    currentVesselData: {
      type: Object,
      default: {},
    },

    loading: {
      type: Boolean,
      default: false,
    },

  },

  data () {
    return {

      bus: new Vue(),

      ccmMasterChecklistParams: {
        crewId: null,
        ccmMasterChecklist: null,
        vesselId: null,
        batchId: null,
        isSignOnCrews: 1,
        signatory: null,
        ccmNo: null,
      },
      ccmVesselEcdisName: null,

    }
  },

  methods: {
    ...mapActions([
      'set_selected_crew_changed',
      'delete_selected_crew_change',
      'reset_selected_crew_change',
      'getCcmMasterChecklistDetails',

      // generate ccm masterlist ex. view checklist, mlc olc, checklist docs
      'generateCcmMasterChecklist',
      'getCrewComplimentPerVessel',
      'get_cc_memo_details',

    ]),

    async getCrewChangeMemo(crewBatchingId, crewBatchingNo) {

        let routeData = this.$router.resolve({ //
            name: 'DashboardCrewChangePlanCCM',
            params: {
                batchId: `${crewBatchingId}`,
                ccmNumber: `${crewBatchingNo}`,
            },
            query : {
                selectedVesselId: `${this.currentVesselData?.id}`,
                view : 'dashboard-crew-change-plan'
            }
        });

        window.open(routeData.href, '_blank',"height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));

    },

    async ccmViewChecklistSignOn(crewBatchingId, cBatchingNo) {
      $(`#view-ccplan-checklist`).modal({
        backdrop: 'static',
        keyboard: false,
      });

      const params = {
        batchId: crewBatchingId,
        vesselId: this.currentVesselData.id,
      };

      this.ccmMasterChecklistParams.batchId = crewBatchingId;
      this.ccmMasterChecklistParams.ccmNo = cBatchingNo;
      this.ccmMasterChecklistParams.vesselId = this.currentVesselData.id;
      this.ccmMasterChecklistParams.isSignOnCrews = 1;
      await this.getCcmMasterChecklistDetails(this.ccmMasterChecklistParams)
      this.getVesselEcdis();

      await this.get_cc_memo_details(params);
    },

    async loadCcmMasterChecklist(ccmMasterChecklist) {
      this.ccmMasterChecklistParams.ccmMasterChecklist = ccmMasterChecklist;

      await AlertService.loadingAlert('Please wait ...', 'Generating PDF')

      let ccmDocumentFile = await this.generateCcmMasterChecklist(this.ccmMasterChecklistParams);
      if (ccmDocumentFile) {
        Swal.close();
        await this.pdfViewerMasterChecklist(global_variables_config.ccmMasterDocsName[ccmMasterChecklist] + ' File', ccmDocumentFile);
      } else {
        Swal.close();
      }
    },

    closeViewChecklistModal() {
      $(`#view-ccplan-checklist`).modal('hide');
    },

    getVesselEcdis() {
      const filteredEcdis = this.objEcdisKeys.filter(ecdis => ecdis.id === this.currentVesselData.ecdis_type_id);
      this.ccmVesselEcdisName = filteredEcdis && filteredEcdis.length > 0 ? filteredEcdis[0].name.replace(/-(?![^(]*\))/g, '') : null;
    },

  },

  computed: {
    ...mapGetters(
      [
        'selectedCrewChange',

        // ccm master checklist
        "ccmMasterChecklist",
        "isReloading",
        "ccmFlagId",
        "ccmFlagName",
        "ccmVesselId",
        "ccmVesselEcdisId",
        "objEcdisKeys",
        "crewChangeMemo",
      ]
    ),

  },

  /** created **/
  async created() {
    this.$globalVariables = global_variables_config
    await this.getCrewComplimentPerVessel();
    //this.getCcMemoDetails();
  },


}
</script>

<style scoped>

.custom-modal-size {
  max-width: 80%;
  margin: 0 auto;
}

</style>
