<template>
  <div>
<!--    <ccm-master-checklist-edit-document-->
<!--      @refresh-ccm-checklist="refreshFromCrewChangeMemo"-->
<!--      ref="checklistEditDoc"-->
<!--      :modal-id="viewCcmMasterChecklistModal"-->
<!--      :file-input-id="fileInputCcmMasterChecklist"-->
<!--    >-->
      <template>
        <div class="container-fluid">
          <div class="row py-4">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="form-inline mb-3">
                      <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Summary Report of Disembarkation</span>
                      </div>
                      <div class="form-group ">
                      </div>
                    </div>
                  </div>
                </div>

                <!--          &lt;!&ndash; Owner &ndash;&gt;-->
                <!--          <div class="col-sm-5">-->
                <!--            <div class="form-group row">-->
                <!--              <label for="list-count" class="col-sm-3 col-form-label">Owner</label>-->
                <!--              <span class="col-form-label">:</span>-->
                <!--              <div class="col-sm-7 text-left">-->
                <!--                <select class="custom-select custom-select-sm"-->
                <!--                >-->
                <!--                  <option :value="null" class="text-center align-middle">&#45;&#45;SELECT OWNER&#45;&#45;</option>-->
                <!--                  <option :value="owner.id" v-for="(owner,index) in objOwners" :key="owner.id">-->
                <!--                    {{ owner.name }}-->
                <!--                  </option>-->
                <!--                </select>-->
                <!--              </div>-->
                <!--            </div>-->
                <!--          </div>-->


              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="row ml-2">
                    <div class="col-sm-2">
                      <div class="form-group row">
                        <button type="button" class="btn btn-xs pmc-btn-danger ml-1"
                                @click="printReport"
                                :disabled="isReloading === true"
                        >
                          <font-awesome-icon icon="file"/>
                          Print Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <!-- table  -->
              <div id="table-section" class="py-3">

                <div class="row justify-content-center">
                  <div class="col col-md">
                    <div class="table table-responsive pmc-table border-top"
                         style="height : 550px">


                      <!--MASTERLIST FORMAT-->
                      <table class="table table-bordered table-sm text-center sticky-header-docs-format small table-hover">
                        <thead>
                        <tr>
                          <th rowspan="2">#</th>
                          <th rowspan="2" style="width: 8%;">Vessel Name</th>
                          <th rowspan="2">Flag</th>
                          <th rowspan="2">CCM No</th>
                          <th colspan="2">Designation</th>
                          <th rowspan="2">CC Port</th>
                          <th rowspan="2">CC Date</th>
                        </tr>
                        <tr>
                          <th>
                            <span class="d-block">Rank</span>
                          </th>
                          <th>
                            <span class="d-block">
                              Months Onboard
                            </span>
                          </th>
                        </tr>
                        </thead>

                        <template v-if="isReloading">
                          <tr>
                            <td colspan="100%">
                              <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                              <span style="size: 20px">
                          Loading ...
                        </span>
                            </td>
                          </tr>
                        </template>

                        <template v-if="isReloading === false && vesselThatHasCCM.length > 0">
                          <template v-if="vesselThatHasCCM.length > 0">
                            <template v-for="(list, plan_list_index) in vesselThatHasCCM">
                              <template v-for="(crew_change_data, crew_change_index) in list.crew_change_plans">
                                <template v-for="(data, data_index) in crew_change_data">
                                  <tr>
                                    <td class="align-middle" :rowspan="list.crew_change_plan_total_length" v-if = "crew_change_index === 0 && data_index === 0">{{ plan_list_index + 1 }}</td>
                                    <td class="align-middle" :rowspan="list.crew_change_plan_total_length" v-if = "crew_change_index === 0 && data_index === 0">{{ list.name?.toUpperCase() }}</td>
                                    <td class="align-middle" :rowspan="list.crew_change_plan_total_length" v-if = "crew_change_index === 0 && data_index === 0">{{ list.flag_name?.toUpperCase() }}</td>
                                    <td class="align-middle" :rowspan="crew_change_data.length" v-if="data_index === 0">
                                      <a href="#"
                                         style="font-size: 0.8rem !important;"
                                         @click="gotoCrewChangeMemo(data.cBatchingId, data.cBatchingNo, list.vessel_id)">
                                        {{ data.cBatchingNo }}
                                      </a>
                                    </td>
                                    <td>
                                      <a href="#"
                                         style="font-size: 0.8rem !important;"
                                         @click="gotoProfile(data.crewOffId,data.cOffType)">
                                        {{ data.rankAlias?.toUpperCase() }}
                                      </a>
                                    </td>
                                    <td class="align-middle" :class="getCriticality(parseInt(data.monthsOnBoard))">
                                      {{ data.monthsOnBoard }}
                                    </td>
                                    <td></td>  <!-- data for CC Port -->
                                    <td></td> <!-- data for CC Date -->
                                  </tr>
                                </template>
                              </template>
                            </template>
                          </template>
                          <template v-else>
                            <tr>
                              <td colspan="100%">
                                <span style="size: 20px;font-weight: bold;color: red;">
                                  No Data
                                </span>
                              </td>
                            </tr>
                          </template>

                        </template>

                      </table>

                    </div>
                  </div>
                </div>


                <table style="width:100%;">
                  <tbody>
                  <tr>
                    <td><span style="font-weight:bold;">Legend :</span></td>
                    <td><span style="text-align:right;" class="legend_near_critical"><b>Near Critical (5-7 mos / Danish 3-5 mos)</b></span></td>
                    <td><span style="text-align:right;" class="legend_critical"><b>Critical (8-10 mos / Danish 6-10 mos)</b></span></td>
                    <td><span style="text-align:right;" class="legend_over_critical"><b>Over Critical (11 and up mos)</b></span></td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>

          <!--  Edit Gear Size Modal modal   -->

          <!-- generating pdf loading modal -->
          <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
               aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
<!--    </ccm-master-checklist-edit-document>-->
  </div>
</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {FileService} from "@/service/file.service";
import {UtilityService} from "@/service/utility.service";
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ThreeMonthPlanSummary",

  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      summaryReportData : [],
      vesselThatHasCCM : null,
      isReloading : true
    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
    ]),
    getAllReport() {
      for (let counter = 0; counter < this.vesselIdsToRequest.length; counter++) {
        let vesselId = this.vesselIdsToRequest[counter];
        axios.get(PMC.GET_THREE_MONTH_PLAN_LIST + '?vesselId=' + vesselId).then(r => {
          r.data.vessel_data.vessel_id = vesselId;
          this.summaryReportData.push(r.data.vessel_data);
          if (counter >= (this.vesselIdsToRequest.length - 1)) {
            this.summaryReportData = _.orderBy(this.summaryReportData, ['name']);
            this.isReloading = false;
            this.setVesselThatHasCCM();
          }
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate three month plan list');
        });
      }
    },
    gotoProfile(thisCrewId, crewType) {
      if (crewType !== (3 && 4)) {
        this.$nextTick(() => {
          let routeData = this.$router.resolve({ //
            name: 'CrewProfile',
            params: {
              crewId: thisCrewId,
            },
          });
          window.open(routeData.href, '_blank', "height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));
        })
      }
    },
    gotoCrewChangeMemo(batchId, ccmNumber, vesselID) {
      let routeData = this.$router.resolve({ //
        name: 'CrewChangePlanCCM',
        params: {
          batchId: `${batchId}`,
          ccmNumber: `${ccmNumber}`,
        },
        query : {
          selectedVesselId: `${vesselID}`,
          view : 'crew-change-plan'
        }
      });

      window.open(routeData.href, '_blank',"height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));
    },
    printReport() {
      AlertService.loadingAlert('Please wait ...', 'Generating PDF for Three Month Plan Summary Report');
      axios.post(PMC.PRINT_THREE_MONTH_PLAN_SUMMARY, this.vesselThatHasCCM).then(r =>{
        let fScr = FileService.base64FileToBlobUrl(r.data);
        Swal.close();
        this.pdfViewer('File', fScr);
      }).catch((error)=>{
        Swal.close();
        return false;
      });
    },
    getCriticality(monthsOnBoard) {
      if (monthsOnBoard >= 5 && monthsOnBoard <= 7) {
        return 'legend_near_critical';
      }
      if (monthsOnBoard >= 8 && monthsOnBoard <= 10) {
        return 'legend_critical';
      }
      if (monthsOnBoard >= 11) {
        return 'legend_over_critical';
      }
      return '';
    },
    setVesselThatHasCCM() {
      let _this = this;
      let mapped_summary_data = _.map(this.summaryReportData, function(summaryData) {
        let newCrewChangePlan = _.filter(summaryData.crew_change_plans, (crew_change_data) => crew_change_data.cBatchingId !== null);
        let groupedByCcmNo = _.groupBy(newCrewChangePlan, 'cBatchingNo');
        let groupedUsingArray = _.map(groupedByCcmNo, (value) => value);
        return {
          crew_change_plans : groupedUsingArray,
          crew_change_plan_total_length : _this.getArrayOfArrayTotalLength(groupedUsingArray),
          flag_name : summaryData.flag_name,
          name : summaryData.name,
          vessel_id : summaryData.vessel_id,
        };
      });

      this.vesselThatHasCCM = _.filter(mapped_summary_data, function(summaryData) {
        return Object.keys(summaryData.crew_change_plans).length !== 0;
      });


    },
    getArrayOfArrayTotalLength(arrayData) {
      return arrayData.length === 0 ? 0 : (_.sumBy(arrayData, 'length'))
    }
  },
  async created() {
    await this.getCrewComplimentPerVessel();
    this.getAllReport();
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objVessels',
      ]
    ),
    vesselIdsToRequest() {
      let vesselIds = [];
      this.objVessels.forEach((vesselData) => {
        vesselIds.push(vesselData.id);
      });
      return vesselIds;
    }
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}


table {
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

.sticky-header-docs-format > thead {
  border-top: 0;
  position: sticky !important;
  top: 0;
  z-index: 100;
}

.legend_near_critical {
  background-color: green;
  color: white;
}

.legend_critical {
  background-color: rgba(255,255,0,255);
}

.legend_over_critical {
  background-color: red;
  color: white;
}





</style>
