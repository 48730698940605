import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  country: {},
  countries: [],
  newCountry: {},

  countryFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  countryPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },

};


const mutations = {
  set_countries(state, params) {
    state.countries = params
  },

  create_country (state) {
    state.newCountry = {};
  },

  update_country (state, params) {
    let country = state.countries.find((val, index) => val.id === params.id)
    Object.assign(country, params)
  },

  delete_country (state, params) {
    let index = state.countries.findIndex((val, index) => val.id === params)
    state.countries.splice(index, 1)
  },

  null_countries(state) {
    state.countries = []
  },

  null_country(state) {
    state.country = {}
  },

  set_country_pagination(state, params) {
    state.countryPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {
  async set_countries({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.COUNTRY, {params}).then((r) => {
      commit('set_countries', r.data.data)
      commit('set_country_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get countries')
      return false
    })
  },

  async create_country({commit}, params) {
    return axios.post(PMC.COUNTRY, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_country', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create country');
      return false;
    })
  },

  async update_country({commit}, params) {
    return axios.put(`${PMC.COUNTRY}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_country',r.data.updatedCountry);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update country');
      return false;

    });
  },

  async delete_country ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Country','question');
    if(await _del){
      return axios.delete(`${PMC.COUNTRY}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete country');
        commit('delete_country',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete rank");
        return false;

      })
    }
  },

  reset_country_pagination({commit}) {
    commit('set_country_pagination')
  },

  null_countries({commit}) {
    commit('null_countries')
  },

  null_country({commit}) {
    commit('null_country')
  },
};


const getters = {
  countries: state => UtilityService.capitalizeProperties(state.countries),

  countryPagination: state => state.countryPagination,
  countryFormParam: state => state.countryFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
