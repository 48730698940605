<template>

  <span>
    <template v-if="objectData && objectData.length != 0 && sortByObject[sortByValue]">
      <font-awesome-icon class="mb-2" icon="arrow-up" :class="{'text-success': sortByObject[sortByValue] === 'asc',
                        'text-secondary': !sortByObject[sortByValue] }"/>

      <font-awesome-icon class="mb-2" icon="arrow-down" :class="{'text-success': sortByObject[sortByValue]  === 'desc',
                        'text-secondary': !sortByObject[sortByValue] }"/>
    </template>
  </span>


</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import Vue from "vue";

export default {
  name: "SortArrow",

  mixins: [AlertMixins],

  props: {

    objectData: {
      type: Array,
      default: [],
    },

    sortByObject: {
      type: Object,
      default: {},
    },

    sortByValue: {
      type: String,
      default: {},
    },


  },

  data () {
    return {

    }
  },

}
</script>



<style scoped>

</style>
