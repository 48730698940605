import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";
import {PMC} from "@/config/constants.config";
import axios from "axios";

/**
 * @author crew change memo remarks module responsible for data manipulation related on
 * crew change memo.
 *
 * @param ccMemoRemarks,ccMemoRemark,newCcMemoRemark
 *
 */
const initStateCcMemoRemarks = () => {
  return {
    //use for displaying remarks,
    ccMemoRemarks: [],
    ccMemoRemark: {},

    newCcMemoRemark: {     // object
      ccMemoId: null,
      remarks: null,
    },

    isCcMemoRemark: true
  }
};

const state = {
  ...initStateCcMemoRemarks()
};

const mutations = {
  set_cc_memo_remarks(state, payload) {
    state.ccMemoRemarks = payload
  },

  set_cc_memo_remark(state, payload) {
    //state.ccMemoRemark = payload;
    let ccMemoRemark = state.ccMemoRemarks.find((val,index)=>{
      return val.id === payload.id;
    })
    Object.assign(ccMemoRemark,payload)
  },

  // create_batching_remarks(state, payload) {
  create_cc_memo_remarks(state, payload) {
    let ar = [];
    state.newCcMemoRemark = {
      remarks: "",
    }
    if (state.ccMemoRemarks == undefined) {
      ar.push(payload);
      state.ccMemoRemarks = ar;

    } else {
      state.ccMemoRemarks.unshift(payload);
    }
  },

  // update_batching_remarks(state, payload) {
  update_cc_memo_remarks(state, payload) {
    let ccMemoRemark = state.ccMemoRemarks.find((val, index) => {
      return val.id === payload.id
    })
    Object.assign(ccMemoRemark, payload)
  },

  // delete_batching_remark(state, payload) {
  delete_cc_memo_remarks(state, payload) {
    let index = state.ccMemoRemarks.findIndex((val, index) => {
      return val.id === payload.id;
    })
    state.ccMemoRemarks.splice(index, 1)
  },

  set_is_cc_memo_remark(state, payload = true) {
    state.isCcMemoRemark = payload;
  },

  reset_cc_memo_remark_state(state) {
    Object.assign(state, initStateCcMemoRemarks())
  },
};


const actions = {
  // set_cc_memo_remarks({commit}, params) {
  //   commit('set_cc_memo_remarks')
  // },

  async set_cc_memo_remarks({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.CC_MEMO_REMARK, {params}).then((r) => {
      commit('set_cc_memo_remarks', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get cc memo remarks')
      return false
    })
  },

  set_cc_memo_remark({commit}, params) {
    return axios.get(`${PMC.CC_MEMO_REMARK}/${params.id}`).then((r)=>{
      commit('set_cc_memo_remark', r.data)
      return true;

    }).catch((er)=>{
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, "Error")
      }
      return false;
    })
  },

  async create_cc_memo_remarks({commit}, params) {
    if (params.ccMemoId == null) {
      // no currently batch no need to call api
      commit('create_cc_memo_remarks', params)
      return true;

    } else {
      return axios.post(PMC.CC_MEMO_REMARK, params).then((r) => {
        commit('create_cc_memo_remarks', r.data)
        return true;
      }).catch((er) => {
        if (!er.response) {
          AlertService.errorAlertService("Cant establish server connection");
        } else {
          UtilityService.generateResponseMessage(er, "Error")
        }
        return false;
      })
    }
  },

  update_cc_memo_remarks({commit}, params) {
    return axios.put(`${PMC.CC_MEMO_REMARK}/${params.id}`,params).then((r)=>{
      commit('update_cc_memo_remarks', r.data)
      return true;

    }).catch((er)=>{
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, "Error")
      }
      return false;
    })
  },

  delete_cc_memo_remarks({commit}, params) {
    return axios.delete(`${PMC.CC_MEMO_REMARK}/${params}`).then((r)=>{
      commit('delete_cc_memo_remarks', r.data)
      return true;
    }).catch((er)=>{
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, "Error")
      }
      return false;
    })
  },

  reset_cc_memo_remark_state({commit}) {
    commit('reset_cc_memo_remark_state');
  },
};


const getters = {
  ccMemoRemark: state => state.ccMemoRemark,
  ccMemoRemarks: state => state.ccMemoRemarks,
  newCcMemoRemark: state => state.newCcMemoRemark,
};

export default {
  state,
  actions,
  mutations,
  getters
}
