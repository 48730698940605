import axios from "axios";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";


const initState = () => {
  return {

  }
};

const state = {
  ...initState()
};


const mutations = {};


const actions = {

  async generateCcmDocument({commit}, params) {
    return await axios.post(PMC.GENERATE_CCM_DOCUMENT, params).then(r => {
      if(params.ccmDocument === 6){
        let docs = {};
        docs.page1 = r.data.page1 ? FileService.base64FileToBlobUrl(r.data.page1) : false;
        docs.page2 = r.data.page2 ? FileService.base64FileToBlobUrl(r.data.page2) : false;
        return docs;
      }
      else return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate ccm document')
      return false
    });
  },

};


const getters = {};


/** export **/
export default {state, actions, mutations, getters};
