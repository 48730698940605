<template>
  <router-view/>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "RankSetup",

  /** mixins **/
  mixins: [],

  /** props **/
  props: {},

  /** data **/
  data () {
    return {}
  },

  /** methods**/
  methods: {
    ...mapActions([
      'null_ranks'
    ])
  },

  /**  computed  **/
  computed: {},

  /** mounted **/
  mounted () {
  },

  /** created **/
  created () {
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed () {
    this.null_ranks();
  },

}
</script>

<style scoped>

</style>
