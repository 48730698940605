<template>
  <div class="container bg-white" style="height: 100vh;">
    <br>
    <br>

    <!--    <div class="d-flex justify-content-start mb-3">-->
    <!--      <button type="button"-->
    <!--              class="btn btn-xs btn-secondary"-->
    <!--      >-->
    <!--        <font-awesome-icon icon="arrow-left"/>-->
    <!--        BACK-->
    <!--      </button>-->
    <!--    </div>-->

    <div class="row">

      <div class="col-md-4">
        <div class="row">
          <div class="form-inline mb-3">
            <div class="form-group mb-2">
              <span class="ml-3 font-weight-bold lead" style="font-size: 22px;">Gear Complements</span>
            </div>

          </div>

        </div>
      </div>


      <!--      ADD COMPLEMENT Hide when not 3 drop down select is empty-->
      <div class="modal fade" id="add-complement" tabindex="-1" role="dialog" aria-labelledby="setupCcPlanCenter"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-size" role="document">
          <div class="modal-content" style="height: 100%">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Add Gear Complements</h5>
              <button type="button" class="close" @click="closeAddTemplate">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <div class="row mb-2">
                <div class="col">

                  <div class="form-inline">
                    <div class="form-group mr-3">
                      <label class="col-form-label">Select Supplier: </label>
                    </div>
                    <!--                    {{ setUpGearComplement }}-->

                    <div class="form-group ">
                      <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                              @change="on_change_select_supplier_for_all"
                              v-model="supplierSelectedForAll"
                              required>
                        <option :value="null">--select--</option>
                        <option :value="supplier.id" v-for="(supplier,index) in objGearSuppliers" :key="supplier.id"
                                style="font-size: 14px;">
                          <span> {{ supplier.name }}</span>
                        </option>
                      </select>
                    </div>


                  </div>

                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col col-md">
                  <div class="table table-responsive pmc-table border-top"
                       style="overflow-x: hidden !important; height: 350px">
                    <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                      <thead>
                      <tr>
                        <th style="width: 5%;">
                          <button class="btn btn-xs btn-primary" @click="addGearComplement"
                          >
                            +
                            Add
                          </button>
                        </th>
                        <!--                        <th style="width: 20%;" class="text-center align-middle p-1">Supplier</th>-->
                        <th style="width: 20%;" class="text-center align-middle p-1">Gear</th>
                        <th style="width: 10%;" class="text-center align-middle p-1">Color</th>
                        <th style="width: 10%;" class="text-center align-middle p-1">Brand</th>
                        <th style="width: 10%;" class="text-center align-middle p-1">Rank</th>
                        <th style="width: 10%;" class="text-center align-middle p-1">Rate</th>
                        <th style="width: 10%;" class="text-center align-middle p-1">Qty</th>
                        <th style="width: 10%;" class="text-center align-middle p-1">Total</th>
                        <th style="width: 3%;" class="text-center align-middle p-1">Action</th>
                      </tr>
                      </thead>
                      <tbody>

                      <tr v-for="(gearComplement,index) in setUpGearComplement" :key="gearComplement.id">
                        <td class="text-center align-middle p-0">{{ index + 1 }}</td>

                        <!--                        This has a use case do not delete or comment out-->
                        <td class="text-center align-middle p-0" style="display: none;">
                          <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                                  @change="on_change_select_supplier(index, $event)"
                                  v-model="gearComplement.supplierId = supplierSelectedForAll"
                                  required>
                            <option :value="null">--select--</option>
                            <option :value="supplier.id" v-for="(supplier,index) in objGearSuppliers" :key="supplier.id"
                                    style="font-size: 14px;">
                              <span> {{ supplier.name }}</span>
                            </option>
                          </select>
                        </td>

                        <td class="text-center align-middle p-0">
                          <select id="select-gear-add" class="form-control form-control-sm"
                                  @change="on_change_select_gear(gearComplement.gear_id, index)"
                                  v-model="gearComplement.gear_id">
                            <option :value="null">--- SELECT ---</option>
                            <option v-for="(gearRate,index) in gearComplement.gearRates" :value="gearRate.gear_id"
                                    :key="gearRate.id">{{ gearRate.gear }}
                            </option>
                          </select>
                        </td>

                        <td>
                          <span> {{ gearComplement.color }} </span>
                        </td>

                        <td>
                          <span> {{ gearComplement.brand }} </span>
                        </td>

                        <td>
                          <span> {{ gearComplement.rank }} </span>
                        </td>

                        <td>
                          <span> {{ gearComplement.rateVal }} </span>
                        </td>


                        <td class="text-center align-middle p-0">
                          <input type="number"
                                 name="addRateInput"
                                 id="add-rate-input"
                                 class="form-control form-control-sm"
                                 v-model="gearComplement.qty"
                          >
                        </td>

                        <td>
                          <span>{{ (gearComplement.rateVal * gearComplement.qty).toFixed(2) }}</span>
                        </td>


                        <td class="text-center align-middle p-0">
                          <button class="btn btn-xs btn-light" @click="removeGearComplement(gearComplement.id)">
                            <font-awesome-icon icon="times" class="text-danger"/>
                          </button>
                        </td>
                      </tr>

                      <tr v-if="setUpGearComplement ? (setUpGearComplement.length == 0) : '' ">
                        <td colspan="100%" class="bg-light-danger font-weight-bolder">
                          <p class="text-center mt-3">Add Gear Complement</p>
                        </td>
                      </tr>


                      </tbody>

                    </table>
                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm pmc-btn-tertia" @click="saveComplement">Save</button>
              <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" @click="closeAddTemplate">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


      <!--      ADD RATE -->
      <div class="modal fade" id="add-complement-template" tabindex="-1" role="dialog"
           aria-labelledby="setupCcPlanCenter"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-size" role="document">
          <div class="modal-content" style="height: 100%">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Add Rate</h5>
              <button type="button" class="close" @click="closeAddTemplate">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <div class="row mb-2">
                <div class="col">

                  <div class="form-inline">
                    <div class="form-group mr-3">
                      <label class="col-form-label">Select Supplier: </label>
                    </div>
                    <div class="form-group ">
                      <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                              @change="on_change_select_supplier_for_all"
                              v-model="supplierSelectedForAll"
                              required>
                        <option :value="null">--select--</option>
                        <option :value="supplier.id" v-for="(supplier,index) in objGearSuppliers" :key="supplier.id"
                                style="font-size: 14px;">
                          <span> {{ supplier.name }}</span>
                        </option>
                      </select>
                    </div>


                  </div>

                </div>
              </div>


              <div class="row justify-content-center">
                <div class="col col-md">
                  <div class="table table-responsive pmc-table border-top"
                       style="overflow-x: hidden !important; height: 350px">
                    <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                      <thead>
                      <tr>
                        <th style="width: 5%;">
                          <button class="btn btn-xs btn-primary" @click="addGearComplement">
                            +
                            Add
                          </button>
                        </th>
                        <!--                        <th style="width: 20%;" class="text-center align-middle p-1">Supplier</th>-->
                        <th style="width: 20%;" class="text-center align-middle p-1">Gear</th>
                        <th style="width: 10%;" class="text-center align-middle p-1">Color</th>
                        <th style="width: 10%;" class="text-center align-middle p-1">Brand</th>
                        <th style="width: 10%;" class="text-center align-middle p-1">Rank</th>
                        <th style="width: 10%;" class="text-center align-middle p-1">Rate</th>
                        <th style="width: 3%;" class="text-center align-middle p-1">Action</th>
                      </tr>
                      </thead>
                      <tbody>

                      <tr v-for="(gearComplement,index) in setUpGearComplement" :key="gearComplement.id">
                        <td class="text-center align-middle p-0">{{ index + 1 }}</td>

                        <!--                        This has a use case do not delete or comment out-->
                        <td class="text-center align-middle p-0" style="display: none;">
                          <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                                  @change="on_change_select_supplier(index, $event)"
                                  v-model="gearComplement.supplierId = supplierSelectedForAll"
                                  required>
                            <option :value="null">--select--</option>
                            <option :value="supplier.id" v-for="(supplier,index) in objGearSuppliers" :key="supplier.id"
                                    style="font-size: 14px;">
                              <span> {{ supplier.name }}</span>
                            </option>
                          </select>
                        </td>

                        <td class="text-center align-middle p-0">
                          <select id="select-gear-add" class="form-control form-control-sm"
                                  @change="on_change_select_gear(gearComplement.gear_id, index)"
                                  v-model="gearComplement.gear_id">
                            <option :value="null">--- SELECT ---</option>
                            <option v-for="(gearRate,index) in gearComplement.gearRates" :value="gearRate.gear_id"
                                    :key="gearRate.id">{{ gearRate.gear }}
                            </option>
                          </select>
                        </td>

                        <td>
                          <span> {{ gearComplement.color }} </span>
                        </td>

                        <td>
                          <span> {{ gearComplement.brand }} </span>
                        </td>

                        <td>
                          <span> {{ gearComplement.rank }} </span>
                        </td>

                        <td>
                          <span> {{ gearComplement.rateVal }} </span>
                        </td>

                        <td class="text-center align-middle p-0">
                          <button class="btn btn-xs btn-light" @click="removeGearComplement(gearComplement.id)">
                            <font-awesome-icon icon="times" class="text-danger"/>
                          </button>
                        </td>
                      </tr>

                      <tr v-if="setUpGearComplement ? (setUpGearComplement.length == 0) : '' ">
                        <td colspan="100%" class="bg-light-danger font-weight-bolder">
                          <p class="text-center mt-3">Add Gear Complement</p>
                        </td>
                      </tr>


                      </tbody>

                    </table>
                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm pmc-btn-tertia" @click="saveComplementTemplate">Save</button>
              <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" @click="closeAddTemplate">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>


    </div>


    <hr/>


    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="pills-complements-tab" data-toggle="pill" href="#pills-complements" role="tab"
           aria-selected="true">Gear PRPO Quantity</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="pills-prpoTemplate-tab" data-toggle="pill" href="#pills-prpoTemplate" role="tab"
           aria-selected="false">Gears PRPO Rate</a>
      </li>
    </ul>

    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-complements" role="tabpanel"
           aria-labelledby="pills-complements-tab">
        <br>


        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">

                <div class="form-group mb-2 ml-3">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#add-complement"
                          data-backdrop="static"
                          data-keyboard="false"
                          v-if="gearComplementParam.ownerId && gearComplementParam.vesselId && gearComplementParam.rankId"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add
                  </button>

                </div>
              </div>

            </div>
          </div>
        </div>


        <div class="row">

          <!-- Owner -->
          <div class="col-sm-3">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Owner</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm"
                        v-model="gearComplementParam.ownerId"
                        @change="on_change_owner"
                >
                  <option :value="null">--select--</option>
                  <option :value="owner.id" v-for="(owner,index) in objOwnersWithVessel" :key="owner.id">
                    {{ owner.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- vessel -->
          <div class="col-sm-3">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Vessel</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="gearComplementParam.vesselId"
                        @change="on_change_vessel"
                        :disabled="!isSetOwnerFilter"
                >
                  <option :value="null">--select--</option>
                  <option :value="vessel.id" v-for="(vessel,index) in objVesselsWithOwner" :key="vessel.id">
                    {{ vessel.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!--      Rank-->
          <div class="col-sm-3">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Rank</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="gearComplementParam.rankId"
                        :disabled="!isSetVesselFilter"
                >
                  <option :value="null">--select--</option>
                  <option :value="rank.id" v-for="(rank,index) in objRanks" :key="rank.id">
                    {{ rank.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>


          <!--      <div class="col-sm-3">-->
          <!--        <div class="form-group row">-->
          <!--          <label for="list-count" class="col-sm-3 col-form-label">Supplier</label>-->
          <!--          <span class="col-form-label">:</span>-->
          <!--          <div class="col-sm-7 text-left">-->
          <!--            <select name="list-type" id="list-type" class="custom-select custom-select-sm"-->
          <!--                    @change="on_change_filter_supplier"-->
          <!--                    :disabled="!isSetVesselFilter"-->
          <!--            >-->
          <!--              <option :value="null">&#45;&#45;select&#45;&#45;</option>-->
          <!--              <option :value="supplier.id" v-for="(supplier,index) in objGearSuppliers" :key="supplier.id"-->
          <!--                      style="font-size: 14px;">-->
          <!--                <span> {{ supplier.name }}</span>-->
          <!--              </option>-->
          <!--            </select>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      </div>-->


          <div class="container bg-white" style="height: 100vh;">
            <!--            <br>-->
            <div id="table-section" class="py-3">
              <pmc-table :max-height="550" :loading="isReloading" :loader-colspan="14">
                <template v-slot:table-header>
                  <tr>
                    <!--            <th scope="row" class="align-middle" width="2%">#</th>-->
                    <th scope="row" class="text-center align-middle" width="4%">SUPPLIER</th>
                    <th scope="row" class="text-center align-middle" width="4%">COLOR</th>
                    <th scope="row" class="text-center align-middle" width="4%">BRAND</th>
<!--                    <th scope="row" class="text-center align-middle" width="4%">RANK</th>-->
                    <th scope="row" class="text-center align-middle" width="4%">GEAR TYPE</th>
                    <th scope="row" class="text-center align-middle" width="5%">DESCRIPTION</th>
                    <th scope="row" class="text-center align-middle" width="5%">CREW RANK TYPE</th>
                    <th scope="row" class="text-center align-middle" width="3%">RATE</th>
                    <th scope="row" class="align-middle" width="2%">QTY</th>
                    <th scope="row" class="align-middle" width="2%">TOTAL</th>
                    <th scope="row" class="text-center align-middle" width="5.5%"></th>
                  </tr>
                </template>

                <template v-if="isReloading">
                  <tr>
                    <td colspan="100%">
                      <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                      <span style="size: 20px">
                          Loading ...
                        </span>
                    </td>
                  </tr>
                </template>

                <template v-slot:table-body>
                  <tr v-for="(gearComplement,index) in gearComplementData" :key="gearComplement.id">
                    <td scope="col">
                      <span v-if="gearComplement.gearSupplier">
                      {{ gearComplement.gearSupplier }}
                      </span>

                      <span v-if="!gearComplement.gearSupplier" class="text-danger">
                        NO SUPPLIER
                      </span>
                    </td>
                    <td scope="col">

                      <span v-if="gearComplement.color">
                      {{ gearComplement.color }}
                      </span>

                      <span v-if="!gearComplement.color">
                        -
                      </span>

                    </td>
                    <td scope="col">

                      <span v-if="gearComplement.brand">
                      {{ gearComplement.brand }}
                      </span>

                      <span v-if="!gearComplement.brand">
                        -
                      </span>
                    </td>
<!--                    <td scope="col">-->

<!--                      <span v-if="gearComplement.rank">-->
<!--                      {{ gearComplement.rank }}-->
<!--                      </span>-->

<!--                      <span v-if="!gearComplement.rank">-->
<!--                        - -->
<!--                      </span>-->
<!--                    </td>-->

                    <td scope="col"> {{ gearComplement.gear_type }}</td>
                    <td scope="col">

                      <!--                      <span class="badge badge-pill badge-dark">{{ gearComplement.rank }}</span>-->
                      <!--                      <span class="badge badge-pill badge-info">{{ gearComplement.rank_type }}</span>-->

                      {{ gearComplement.gear }}

                      <span class="badge badge badge-secondary">{{ gearComplement.color }}</span>


                    </td>
                    <td scope="col"> {{ gearComplement.rank }} - {{ gearComplement.rank_type }}</td>

                    <td>
                      <span v-if="gearComplement.rate">
                      {{ gearComplement.rate }}
                      </span>

                      <span v-if="!gearComplement.rate" class="text-danger">
                        NO RATE
                      </span>

                    </td>


                    <td scope="col" class="p-0 text-left pl-3">

                      <input type="text"
                             placeholder="input rate"
                             class="form-control form-control"
                             v-model="gearComplementEditData.qty"
                             v-if="gearComplementEditIndex == index"

                      >

                      <span v-else>{{ gearComplement.qty }}</span>

                    </td>

                    <td>
                      <span> {{ (gearComplement.rate * gearComplement.qty).toFixed(2) }}</span>
                    </td>

                    <td>
                      <button type="button"
                              class="btn-xs btn btn-outline-primary mr-3"
                              @click="set_edit_complement(index, gearComplement)"
                              v-if="!isComplementEditable && gearComplementEditIndex != index"
                      >
                        <font-awesome-icon icon="edit"/>
                        edit
                      </button>

                      <button type="button"
                              class="btn btn-sm btn-xs pmc-btn-tertia mr-2"
                              v-show="gearComplementEditIndex == index"
                              @click="updateComplement(gearComplement)"
                      >
                        <font-awesome-icon icon="save"/>
                        save
                      </button>

                      <button type="button" class="btn btn-sm btn-xs btn-secondary ml-2 mr-2"
                              v-show="gearComplementEditIndex == index"
                              @click="set_cancel(gearComplement)"
                      >
                        <font-awesome-icon icon="times"/>
                        cancel
                      </button>

                      <button
                        class="btn btn-xs btn btn-outline-danger ml-2"
                        v-if="!isComplementEditable && gearComplementEditIndex != index"
                        @click="delete_gearComplement(gearComplement.id)"
                      >
                        <font-awesome-icon icon="trash"/>
                        del
                      </button>
                    </td>
                  </tr>

                  <tr v-if="gearComplementData ? (Object.keys(gearComplementData).length == 0) : '' ">
                    <td colspan="100%" class="bg-light-danger font-weight-bolder">
                      <p class="text-center mt-3">No Result</p>
                    </td>
                  </tr>

                  <tr v-if="!gearComplementData">
                    <td colspan="100%" class="bg-light-danger font-weight-bolder">
                      <p class="text-center mt-3">No Result</p>
                    </td>
                  </tr>


                </template>
              </pmc-table>
            </div>
          </div>

        </div>


      </div>
      <div class="tab-pane fade" id="pills-prpoTemplate" role="tabpanel" aria-labelledby="pills-prpoTemplate-tab">
        <br>

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">

                <div class="form-group mb-2 ml-3">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#add-complement-template"
                          data-backdrop="static"
                          data-keyboard="false"
                          v-if="gearComplementParam.ownerId && gearComplementParam.vesselId"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add
                  </button>

                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row">

          <!-- Owner -->
          <div class="col-sm-3">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Owner</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm"
                        v-model="gearComplementParam.ownerId"
                        @change="on_change_owner"
                >
                  <option :value="null">--select--</option>
                  <option :value="owner.id" v-for="(owner,index) in objOwnersWithVessel" :key="owner.id">
                    {{ owner.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- vessel -->
          <div class="col-sm-3">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Vessel</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="gearComplementParam.vesselId"
                        @change="on_change_vessel"
                        :disabled="!isSetOwnerFilter"
                >
                  <option :value="null">--select--</option>
                  <option :value="vessel.id" v-for="(vessel,index) in objVesselsWithOwner" :key="vessel.id">
                    {{ vessel.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>


          <div class="container bg-white" style="height: 100vh;">
            <!--            <br>-->
            <div id="table-section" class="py-3">
              <pmc-table :max-height="550" :loading="isReloading" :loader-colspan="14">
                <template v-slot:table-header>
                  <tr>
                    <!--            <th scope="row" class="align-middle" width="2%">#</th>-->
                    <th scope="row" class="text-center align-middle" width="4%">SUPPLIER</th>
                    <th scope="row" class="text-center align-middle" width="4%">COLOR</th>
                    <th scope="row" class="text-center align-middle" width="4%">BRAND</th>
                    <th scope="row" class="text-center align-middle" width="4%">GEAR TYPE</th>
                    <th scope="row" class="text-center align-middle" width="5%">DESCRIPTION</th>
                    <th scope="row" class="text-center align-middle" width="5%">GEAR RANK TYPE</th>
                    <th scope="row" class="text-center align-middle" width="3%">RATE</th>
                    <th scope="row" class="text-center align-middle" width="3%"></th>
                  </tr>
                </template>
                <template v-slot:table-body>
                  <tr v-for="(complementTemplate, index) in complementTemplates" :key="complementTemplate.id">
                    <td scope="col">

                      <span v-if="complementTemplate.gearSupplier">
                      {{ complementTemplate.gearSupplier }}
                      </span>

                      <span v-if="!complementTemplate.gearSupplier" class="text-danger">
                        NO SUPPLIER
                      </span>

                    </td>
                    <td scope="col">

                      <span v-if="complementTemplate.color">
                      {{ complementTemplate.color }}
                      </span>

                      <span v-if="!complementTemplate.color">
                        -
                      </span>

                    </td>
                    <td scope="col">
                      <span v-if="complementTemplate.brand">
                      {{ complementTemplate.brand }}
                      </span>

                      <span v-if="!complementTemplate.brand" class="text-danger">
                        -
                      </span>
                    </td>

                    <td scope="col"> {{ complementTemplate.gear_type }}</td>
                    <td scope="col">

                      <span class="badge badge-pill badge-dark">{{ complementTemplate.rank }}</span>
                      <span class="badge badge-pill badge-info">{{ complementTemplate.rank_type }}</span>

                      {{ complementTemplate.gear }}

                      <span class="badge badge badge-secondary">{{ complementTemplate.color }}</span>


                    </td>

                    <td scope="col"> {{ complementTemplate.rank_type }}</td>

                    <td>
                      <span v-if="complementTemplate.rate">
                      {{ complementTemplate.rate }}
                      </span>

                      <span v-if="!complementTemplate.rate" class="text-danger">
                        NO RATE
                      </span>

                    </td>

                    <td>
                      <button
                        class="btn btn-xs btn btn-outline-danger ml-2"
                        @click="delete_complementTemplates(complementTemplate.id)"
                      >
                        <font-awesome-icon icon="trash"/>
                        del
                      </button>
                    </td>
                  </tr>


                  <tr v-if="complementTemplates ? (Object.keys(complementTemplates).length == 0) : '' ">
                    <td colspan="100%" class="bg-light-danger font-weight-bolder">
                      <p class="text-center mt-3">No Result</p>
                    </td>
                  </tr>

                  <tr v-if="!complementTemplates">
                    <td colspan="100%" class="bg-light-danger font-weight-bolder">
                      <p class="text-center mt-3">No Result</p>
                    </td>
                  </tr>


                </template>
              </pmc-table>
            </div>
          </div>

        </div>

      </div>
    </div>


  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {

      gearComplementEditData: {},

      gearComplementEditIndex: null,


      gearComplementData: null,
      gearComplementDataCopy: null,

      isComplementEditable: false,

      // params for set_gearComplements
      gearComplementParam: {
        ownerId: null,
        vesselId: null,
        rankId: null,
        supplierId: null,
        qty: null,
      },

      supplierRateParam: {
        supplierId: null,
      },

      gearComplementAddIndex: null,

      setUpGearComplement: [],

      gearSupplierRateList: null,


      // gear complement filters
      isSetFilters: false,

      isSetOwnerFilter: false,
      isSetVesselFilter: false,
      isSetRankFilter: false,

      // Add Gear Complements
      supplierRateInAdd: null,
      supplierSelectedForAll: null,
      addedSupplierRows: [],


      // Add Gear Rate


      // for testing
      gearRates: [{
        "id": 12,
        "gear_id": 58,
        "fullGear": "jordan 1  hi 85 - deck",
        "gear": "jordan 1  hi 85",
        "color": "",
        "gear_supplier_id": 10,
        "gearSupplier": "jordan",
        "rank_type": "deck",
        "rank": "c\/m",
        "rate": "212.00",
        "created_by": "",
        "updated_by": "",
        "created_at": "04, Feb 2023",
        "updated_at": "04, Feb 2023"
      }, {
        "id": 20,
        "gear_id": 54,
        "fullGear": "jordan 4",
        "gear": "jordan 4",
        "color": "",
        "gear_supplier_id": 10,
        "gearSupplier": "jordan",
        "rank_type": null,
        "rank": null,
        "rate": "663.01",
        "created_by": "",
        "updated_by": "",
        "created_at": "05, Feb 2023",
        "updated_at": "05, Feb 2023"
      }, {
        "id": 21,
        "gear_id": 57,
        "fullGear": "chicago - deck",
        "gear": "chicago",
        "color": null,
        "gear_supplier_id": 10,
        "gearSupplier": "jordan",
        "rank_type": "deck",
        "rank": "bsn",
        "rate": "22.44",
        "created_by": "",
        "updated_by": "",
        "created_at": "05, Feb 2023",
        "updated_at": "05, Feb 2023"
      }],


    }
      ;


  },

  /** methods**/
  methods: {
    ...mapActions([
      'getOwnersWithVessel',
      'getVesselsWithOwner',
      'getRankKeys',
      'set_gearComplements',
      'set_gearSupplierRates',
      'update_gearComplement',
      'delete_gearComplement',
      'create_gearComplement',
      'getGearKeys',
      'getGearSupplierKeys',

      'getGearSupplierKeys',

      // Template
      'set_complementTemplates',
      'create_complementTemplates',
      'delete_complementTemplates',

    ]),

    async updateComplement(gearComplement) {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update gear', 'question');
      if (await __update) {
        let params = {
          id: gearComplement.id,
          owner_id: gearComplement.owner_id ? gearComplement.owner_id : '',
          vessel_id: gearComplement.vessel_id ? gearComplement.vessel_id : '',
          rank_id: this.gearComplementParam.rankId ? this.gearComplementParam.rankId : '',
          gear_id: gearComplement.gear_id ? gearComplement.gear_id : '',
          qty: gearComplement.qty ? gearComplement.qty : '',
        };

        let updatedGearComplement = await this.update_gearComplement(params);
        if (updatedGearComplement) {
          AlertService.successAlertService("Updated successfully", 'Update gear supplier rate');
          this.set_gearComplements(this.gearComplementParam);
          this.gearComplementEditIndex = null
          this.isComplementEditable = false
        }
      }
    },

    async saveComplement() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Gear Complements', 'question')
      if (await __create) {

        let params = {
          gearComplements: this.setUpGearComplement,
        }

        let newGearComplement = await this.create_gearComplement(params);
        if (newGearComplement) {
          AlertService.successAlertService('Create gear complement record successful', 'Create gear complements');
          this.hideModal('add-complement')
          await this.set_gearComplements(this.gearComplementParam);
          this.setUpGearComplement = []
        }

      }
    },

    async saveComplementTemplate() {

      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Gear Complement Template', 'question')
      if (await __create) {

        let params = {
          gearComplementTemplates: this.setUpGearComplement,
        }

        let newGearComplement = await this.create_complementTemplates(params);
        if (newGearComplement) {
          AlertService.successAlertService('Create gear complement template record successful', 'Create gear complement template');
          this.hideModal('add-complement-template')
          await this.set_complementTemplates(this.gearComplementParam);
          this.setUpGearComplement = []
        }

      }
    },


    on_change_owner(e) {
      this.gearComplementParam.ownerId = e.target.value;
      this.getVesselsWithOwner(this.gearComplementParam);

      this.isSetOwnerFilter = true;
      this.isSetVesselFilter = false;

      this.gearComplementParam.vesselId = null;
      this.gearComplementParam.rankId = null;

      this.set_gearComplements(this.gearComplementParam);

      if (!this.gearComplementParam.ownerId) {
        this.isSetOwnerFilter = false;
      }

    },

    on_change_vessel(e) {
      this.gearComplementParam.vesselId = e.target.value;
      this.isSetVesselFilter = true;

      // templates
      this.set_complementTemplates(this.gearComplementParam);
    },

    on_change_filter_supplier(e) {
      this.gearComplementParam.supplierId = e.target.value;
      this.set_gearComplements(this.gearFormParam);
    },


    on_change_select_supplier(index, e) {
      this.supplierSelectedForAll = null;
      this.supplierRateParam.supplierId = e.target.value;
      this.gearComplementAddIndex = index; // index where supplier is selected

      this.set_gearSupplierRates(this.supplierRateParam);

    },

    on_change_select_supplier_for_all(index, e) {
      this.supplierRateParam.supplierId = this.supplierSelectedForAll;

      this.setUpGearComplement.map(obj => {
        obj.supplierId = this.supplierSelectedForAll;
      });

      this.set_gearSupplierRates(this.supplierRateParam);

      // console.log(this.supplierSelectedForAll, 'supplierSelectedForAll')
      // console.log(this.gearComplementAddIndex, 'gearComplementAddIndex')


    },


    on_change_select_gear(gearRateId, index) {

      let counter = {};
      let duplicateId = null;

      this.setUpGearComplement.forEach((val, index) => {
        if (val.rankId !== null && val.gear_id !== null) {
          const key = `${val.gear_id}-${val.rankId}`; // create a unique key using both gear_id and rankId
          counter[key] = (counter[key] || 0) + 1;
          if (counter[key] > 1) {
            duplicateId = val.id;
            this.warningAlert("Duplicate entry detected", "Warning");
          }
        }
      });

      if (duplicateId !== null) {
        let setUpGearComplement = this.setUpGearComplement.find((val, index) => {
          return val.id === duplicateId;
        });
        setUpGearComplement.gear_id = null;
      }

      const convertGearComplementGearRate = Object.values(this.setUpGearComplement[index].gearRates);
      const gearComplementFind = convertGearComplementGearRate.find(complement => complement.gear_id == gearRateId);

      this.setUpGearComplement[index].rateVal = gearComplementFind.rate ? gearComplementFind.rate : '';
      this.setUpGearComplement[index].color = gearComplementFind.color ? gearComplementFind.color : '';
      this.setUpGearComplement[index].brand = gearComplementFind.brand ? gearComplementFind.brand : '';
      this.setUpGearComplement[index].rank = gearComplementFind.rank ? gearComplementFind.rank : '';


    },


    set_edit_complement(index, data) {
      this.gearComplementEditData = data;
      this.isComplementEditable = true
      this.gearComplementEditIndex = index

      // create a deep copy of rate data to used to backtrack previous data
      this.gearComplementDataCopy = _.cloneDeep(this.gearComplementData);
    },

    set_cancel(e) {
      this.gearComplementEditIndex = null
      this.isComplementEditable = false

      // this.set_gearSupplier(this.$route.params.gearSupplierId);
      if (this.gearComplementDataCopy) {
        this.gearComplementData = Object.assign({}, this.gearComplementDataCopy);
      }
    },


    addGearComplement() {
      let index = this.setUpGearComplement.length
      let id = index + 1
      while (this.setUpGearComplement.filter(v => v.id === id).length > 0) {
        id++
      }
      const params = {
        id: id,
        gear_id: this.setUpGearComplement.gear_id ?? null,
        ownerId: this.gearComplementParam.ownerId ?? null,
        vesselId: this.gearComplementParam.vesselId ?? null,
        rankId: this.gearComplementParam.rankId ?? null,
        qty: this.setUpGearComplement.qty ?? null,
        rateVal: null,
        gearRates: [],
      }
      this.setUpGearComplement.push(params);

      this.setUpGearComplement.forEach((item, index) => {
        this.setUpGearComplement[index].gearRates = Object.assign({}, this.gearSupplierRateList);
      });

    },

    removeGearComplement(gearRateIndex) {
      let index = this.setUpGearComplement.findIndex((val, index) => {
        return val.id === gearRateIndex;
      })
      this.setUpGearComplement.splice(index, 1);
    },


    onSelectedGear(e) {
      let gearKey = e.target.value;
      let counter = {}; // used to keep track of the occurrences of each gear_id in the setUpGearComplement array.
      let duplicateId = null;

      // checks if the property val.gear_id exists in the counter object.
      //  count the occurences of gear_id
      this.setUpGearComplement.forEach((val, index) => {
        if (val.gear_id !== null) {
          counter[val.gear_id] = (counter[val.gear_id] || 0) + 1
          if (counter[val.gear_id] > 1) {
            duplicateId = val.id;
            this.warningAlert("Duplicate entry detected", "Warning")
          }
        }
      })

      if (duplicateId !== null) {
        let setUpGearComplement = this.setUpGearComplement.find((val, index) => {
          return val.id === duplicateId;
        });
        setUpGearComplement.gear_id = null;
      }

    },


    closeAddTemplate() {
      this.setUpGearComplement = []
      this.supplierSelectedForAll = null
      $('#add-complement').modal('hide');
      $('#add-complement-template').modal('hide');
    },


  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'objOwnersWithVessel',
        'objVesselsWithOwner',
        'objRanks',
        'gearComplements',
        'gearSupplierRates',
        'objGears',
        'objGearSuppliers',

        //templates
        'complementTemplates',
        'gearTypeTemplates',

      ],
    ),

    // selectedGearComplement() {
    //   // return this.setUpGearComplement[this.gearComplementAddIndex].gearRates.find(item => item.id === 12);
    //
    //   const selectedGearComplement = this.setUpGearComplement[Object.keys(this.setUpGearComplement).find(key => this.setUpGearComplement[key].id === 12)];
    //
    // },

  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.getOwnersWithVessel();
    await this.getRankKeys();
    await this.getGearKeys();
    await this.getGearSupplierKeys();
  },

  /** watch **/
  watch: {
    gearComplementParam: {
      handler: function () {
        if (this.gearComplementParam.vesselId && this.gearComplementParam.rankId && this.gearComplementParam.ownerId) {
          this.set_gearComplements(this.gearComplementParam);
          this.isSetFilters = true;
        } else {
          this.isSetFilters = false;
        }

      },
      deep: true // This is important to track nested data changes
    },


    'gearComplements'() {
      this.gearComplementData = Object.assign({}, this.gearComplements);
    },

    'gearSupplierRates'() {
      this.gearSupplierRateList = Object.assign({}, this.gearSupplierRates);
      // this.setUpGearComplement[this.gearComplementAddIndex].gearRates = Object.assign({}, this.gearSupplierRateList);

      if (this.supplierSelectedForAll) {
        this.setUpGearComplement.forEach((item, index) => {
          this.setUpGearComplement[index].gearRates = Object.assign({}, this.gearSupplierRateList);
        });
      }


    }


  },

  /** destroyed **/
  destroyed() {
  },


}
</script>

<style scoped>

.custom-modal-size {
  max-width: 60%;
  margin: 0 auto;
}

</style>
