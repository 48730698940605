<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div style="width: 100%;margin-top: -20px;">

          <div style="width:100%;height:84px;">
            <div style="width:110px;height:105px;float:left;">
              <img :src="pmcLogo" alt="" style="width:70%; height:80%;">
            </div>
            <div class="mt-3" style="height:105px;float:left;">
              <div class="row">
                <span style="font-size: 20px">Promotable Crew List Report</span>
              </div>

              <div class="row">
                <span style="font-size: 14px">As of: {{ formatCurrentDate() }}</span>
              </div>

            </div>

          </div>
        </div>

        <hr>


        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table
                  class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;">NAME</th>
                    <th>RANK</th>
                    <th>TIME ONBOARD AS PER CURRENT RANK</th>
                    <th>AGE</th>
                    <th>LICENSE</th>
                    <th>NO. OF RECOMMENDATION</th>
                    <th>STATUS</th>
                    <th>UPGRADED TO HIGHER RANK</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="onboardHasRecommendation ? (onboardHasRecommendation.length != 0) : '' ">
                      <template v-for="(crew, index) in onboardHasRecommendation">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="text-left pl-2">
                            {{ crew.fullName }}
                          </td>
                          <td> {{ crew.rankAlias }}</td>
                          <td>
                            4
                          </td>
                          <td>5</td>
                          <td>6</td>
                          <td>7</td>
                          <td>8</td>
                          <td>9</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import axios from "axios";
import PmcLogo from '@/assets/pmc_logo_only.jpg';

export default {
  name: "PromotableCrewList",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      pmcLogo: PmcLogo,
      isEditable: false,
      isGenerateOnboardButton: false,
      isLoading: false,
      ranks: null,
      crewData: null,
      rankSelected: null,
    }
  },


  /** methods**/
  methods: {
    ...mapActions([
      'set_onboardHasRecommendation',
    ]),


    formatCurrentDate() {
      const options = {day: 'numeric', month: 'long', year: 'numeric'};
      return new Date().toLocaleDateString('en-GB', options);
    },

    formatCurrentDate2() {
      const options = {month: 'long', year: 'numeric'};
      return new Date().toLocaleDateString('en-GB', options);
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'onboardHasRecommendation',
        'isReloading',
      ]),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    this.$MyGlobalFunction = global_variables_config

    if (this.$route.query.view != null) {
      await this.set_onboardHasRecommendation(parseInt(this.$route.query.rankId));
    }


  },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },

}
</script>

<style scoped>

</style>
