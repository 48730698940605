<template>
  <div class="row justify-content-center">
    <div class="col col-md">
      <div class="table table-responsive pmc-table border-top">

        <!--                  Certificates-->
        <table class="table table-bordered table-sm text-center small">
          <thead>
          <tr>
            <th style="width: 1%;">
              #
            </th>
            <th class="">CREW NAME</th>
            <th class="">RANK</th>
            <th class="">DOCUMENT  </th>
            <th class="">DOCUMENT NO</th>
            <th class="">ISSUED </th>
            <th class="">VALIDITY </th>
            <th class="">SCHEDULE DATE (Y-M-D) </th>
            <th class="">RELEASE DATE (Y-M-D) </th>
            <th class="">STATUS </th>

          </tr>
          </thead>
          <tbody>

          <tr>
            <td class="text-center align-middle">
             1
            </td>
            <td class="text-center align-middle">
              Javellana, Jollen J.
            </td>

            <td class="text-center align-middle">
              BSN
            </td>

            <td class="text-center align-middle">
              MEDICAL EXAMINATION CERTIFICATE
            </td>


            <td class="text-center align-middle">
              SANDA
            </td>


            <td class="text-center align-middle">
              2023-01-10
            </td>


            <td class="text-center align-middle">
              2025-01-09
            </td>


            <td class="text-center align-middle">

            </td>


            <td class="text-center align-middle">

            </td>

            <td class="text-center align-middle">

            </td>

          </tr>

          </tbody>

        </table>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "CcmMasterChecklistDocCertMonitoring",

  props: {

    ccmMasterChecklist: {
      type: Array,
      default: [],
    },


  },

}
</script>

<style scoped>

</style>
