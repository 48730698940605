<template>
  <div>
    <ccm-master-checklist-edit-document
      @refresh-ccm-checklist="refreshFromCrewChangeMemo"
      ref="checklistEditDoc"
      :modal-id="viewCcmMasterChecklistModal"
      :file-input-id="fileInputCcmMasterChecklist"
    >
      <template v-slot:table-contents>
        <div class="container-fluid">
          <div class="row py-4">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="form-inline mb-3">
                      <div class="form-group mb-2 mr-5">
                    <span class="ml-4 font-weight-bold lead text-primary"
                          style="font-size: 22px;">Masterlist (Certs Format)</span>
                      </div>
                      <div class="form-group ">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr/>

              <div class="row mt-5">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group row">
                    <span class="ml-4 font-weight-bold lead" style="font-size: 18px;" v-if="selectedVesselName">
                      {{ selectedVesselName }}
                    </span>
                        <span class="ml-4 font-weight-bold lead" style="font-size: 18px;" v-else>
                      --SELECT VESSEL--
                    </span>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group row">
                        <label for="list-count" class="col-form-label">VESSEL</label>
                        <span class="col-form-label">:</span>
                        <div class="col-sm-7 text-left">
                          <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                                  v-model="selectedVessel"
                                  @change="onChangeVessel($event.target.selectedOptions[0].text)"
                                  required
                                  :disabled="isReloading"
                          >
                            <option :value="null">--SELECT VESSEL--</option>
                            <option :value="vessel.id" v-for="(vessel,index) in objVessels" :key="vessel.id">
                              {{ vessel.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group row">
                        <button type="button" class="btn btn-xs pmc-btn-tertia"
                                @click="loadOnboardCrewList"
                                v-if="isGenerateOnboardButton"
                        >
                          <font-awesome-icon icon="file"/>
                          Generate Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- table  -->
              <div id="table-section" class="py-3">
                <div class="row justify-content-center">
                  <div class="col col-md">
                    <div class="table table-responsive pmc-table border-top"
                         style="height : 550px">
                      <!--MASTERLIST FORMAT-->
                      <table class="table table-bordered table-sm text-center sticky-header-docs-format small table-hover">
                        <thead>
                        <tr>
                          <th rowspan="2">#</th>
                          <th rowspan="2" style="width: 8%;">Name</th>
                          <th rowspan="2">Rank</th>
                          <th rowspan="2">Age</th>
                          <th rowspan="2">COP BST</th>
                          <th rowspan="2">COP PSCRB</th>
                          <th rowspan="2">COP AFF</th>
                          <th rowspan="2">COP MECA</th>
                          <th rowspan="2">COP MEFA</th>
                          <th rowspan="2">COP SSO</th>
                          <th rowspan="2">COP SDSD</th>
                          <th rowspan="2"><span class="d-block">Deck / Engine</span>Watchkeeping</th>
                          <th rowspan="2">SDSD</th>
                          <th rowspan="2">PDOS</th>
                          <th rowspan="2"><span class="d-block">Anti-Piracy Training</span>Awareness</th>
                        </tr>
                        </thead>

                        <template v-if="isReloading">
                          <tr>
                            <td colspan="100%">
                              <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                              <span style="size: 20px">
                            Loading ...
                          </span>
                            </td>
                          </tr>
                        </template>

                        <template v-else>
                          <template v-if="onboardCrews ? (onboardCrews.length != 0) : '' ">
                            <tr v-for="(onboardCrew, index) in onboardCrews">
                              <td>{{ parseInt(index) + 1 }}</td>
                              <td
                                :class="{
                                'with-one-higher-license' : onboardCrew.hasHigherLicense,
                             }"
                              >
                                <span v-if="onboardCrew.hasHigherLicense">*</span>
                                <a href="#"
                                   @click.stop="gotoCrewProfileNewWindow(onboardCrew.crewId, 'all-info')"
                                   style="color:black;">
                                  <small><b>{{ onboardCrew.crewSignOff }} </b></small>
                                </a>
                              </td>
                              <td>{{ onboardCrew.rank }}</td>
                              <td>{{ onboardCrew.age }}</td>

                              <!--                      COP BST-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.copBst?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.copBst?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.copBst?.nearExpiry2months,
                                 }"
                              >
                                {{ onboardCrew.documentsData?.copBst?.dateExpiry }}
                              </td>

                              <!--                      COP PSCRB-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.copPscrb?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.copPscrb?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.copPscrb?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.copPscrb?.dateExpiry }}
                              </td>

                              <!--                      COP AFF-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.copAff?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.copAff?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.copAff?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.copAff?.dateExpiry }}
                              </td>

                              <!--                      COP MECA-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.copMeca?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.copMeca?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.copMeca?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.copMeca?.dateIssued }}
                              </td>

                              <!--                      COP MEFA-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.copMefa?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.copMefa?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.copMefa?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.copMefa?.dateIssued }}
                              </td>

                              <!--                      COP SSO-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.copSso?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.copSso?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.copSso?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.copSso?.dateIssued }}
                              </td>

                              <!--                      COP SDSD-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.copSdsd?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.copSdsd?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.copSdsd?.nearExpiry2months,
                                 }"
                              >
                                {{ onboardCrew.documentsData?.copSdsd?.dateIssued }}
                              </td>

                              <!--                      Deck / Engine Watchkeeping-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.deWatch?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.deWatch?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.deWatch?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.deWatch?.dateIssued }}
                              </td>

                              <!--                      SDSD-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.ssatSdsd?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.ssatSdsd?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.ssatSdsd?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.ssatSdsd?.dateIssued }}
                              </td>

                              <!--                      PDOS-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.pdos?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.pdos?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.pdos?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.pdos?.dateIssued }}
                              </td>

                              <!--                      Anti-Piracy Training Awareness-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.apas?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.apas?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.apas?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.apas?.dateIssued }}
                              </td>

                            </tr>
                          </template>

                          <template v-else>
                            <tr>
                              <td colspan="100%" class="bg-light-danger font-weight-bolder">
                                <p class="text-center mt-3">No Results</p>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </table>
                    </div>
                  </div>
                </div>

                <table style="width:100%;">
                  <tbody>
                  <tr>
                    <td><span style="font-weight:bold;">Legend :</span></td>
                    <td></td>
                    <td><span style="text-align:right;color:black;"><b>* - With one higher license</b></span></td>
                    <td></td>
                    <td></td>
                    <td><span style="text-align:right;color:blue;"><b>Promoted Onbooard</b></span></td>
                    <td></td>
                    <td><span style="text-align:right;color: green"><b>Newly Embarked</b></span></td>
                    <td></td>
                    <td><span style="text-align:right;background-color: Gray; color:white;"><b>Non Mandatory</b></span></td>
                    <td></td>
                    <td>
                      <span style="text-align:right;background-color: Yellow"><b>Near Expiry</b></span>
                    </td>
                    <td></td>
                    <td>
                      <span style="text-align:right;background-color: Red;color:white;">
                        <b>Expired / No Documents</b>
                      </span>
                    </td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </template>
    </ccm-master-checklist-edit-document>
  </div>
</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  name: "MasterlistCertsFormatList",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      isEditable: false,
      selectedVessel: null,
      selectedVesselName: null,
      onboardCrewListFormParam: {
        isGenerate: false,
        vesselId: null,
        reportType: global_variables_config.onboardCrewListReportType.masterlistCertsFormat,
      },
      isGenerateOnboardButton: false,
      viewCcmMasterChecklistModal: 'view-ccm-master-checklist-modal',
      fileInputCcmMasterChecklist: 'file-input-ccm-master-checklist',
    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
      // 'set_crew_change_plans',
      'set_onboardCrews',
      'generateOnboardCrewList',
      'resetOnboardCrews',
      'resetOnboardCrewDetails',
    ]),

    view_document(crewDocId) {
      this.$refs.checklistEditDoc.view_document(crewDocId);
    },

    refreshFromCrewChangeMemo() {
      this.$emit('refresh-ccm-checklist');
    },

    onChangeVessel(name) {
      this.selectedVesselName = name;
      this.onboardCrewListFormParam.vesselId = this.selectedVessel
      this.onboardCrewListFormParam.isGenerate = false
    },

    async loadOnboardCrewList() {
      this.onboardCrewListFormParam.isGenerate = true
      AlertService.loadingAlert('Please wait ...', 'Loading Masterlist ...');
      let onboardCrewListFile = await this.generateOnboardCrewList(this.onboardCrewListFormParam);
      if (onboardCrewListFile) {
        Swal.close();
        await this.pdfViewerMasterChecklist('File', onboardCrewListFile);
      } else {
        Swal.close();
      }
    },

    debouncedSetOnboardCrews: _.debounce(function() {
      this.set_onboardCrews(this.onboardCrewListFormParam);
    }, 500),

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objVessels',
        'onboardCrews',
        'onboardCrewDetails',
        'isReloading',
      ]),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  // async created() {
  //   this.$MyGlobalFunction = global_variables_config
  //   await this.getCrewComplimentPerVessel();
  // },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },

  /** destroyed **/
  destroyed() {
    this.resetOnboardCrews();
    this.resetOnboardCrewDetails();
  },

  /** watch **/
  watch: {
    selectedVesselName() {
      if (this.selectedVessel) {
        this.isGenerateOnboardButton = true
        this.debouncedSetOnboardCrews();
      } else {
        this.isGenerateOnboardButton = false
      }
    },

  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

table {
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

.sticky-header-docs-format > thead {
  border-top: 0;
  position: sticky !important;
  top: 0;
  z-index: 100;
}

</style>
