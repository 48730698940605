import Swal from "sweetalert2";

export const AlertService = {

  errorAlertService(text,actionType = ''){
    Swal.fire({
      title: `${actionType}`,
      icon: `error`,
      text: `${text}`,
      showCancelButton: false,
      allowOutsideClick: false,
      customClass : {
        container : 'swal-container'
      }
    }).then(r =>{
      return r.value;
    });
   },

  //error with response data service
    errorWithResponseDataService(message = '',html,actionType = '', icon){
    let iconParam = icon ? icon : `error`;
    Swal.fire({
      title : `${actionType}`,
      icon : `${iconParam}`,
      text : `${message}`,
      html : `${html}`,
      customClass:{
        confirmButton: 'btn pmc-btn-prime font-weight-bolder',
        cancelButton: 'btn pmc-btn-second font-weight-bolder',
        container : 'swal-container'
      },
      showCancelButton : false,
      allowOutsideClick : false
    }).then(r =>{
      return r.value;
    });
  },

  // info
  infoAlertService(text,actionType = ''){
    return Swal.fire({
      title: `${actionType}`,
      icon: `info`,
      text: `${text}`,
      // customClass:{
      //   confirmButton: 'btn btn-primary btn-sm font-weight-bolder',
      //   cancelButton: 'btn btn-secondary btn-sm font-weight-bolder',
      // },
      showCancelButton: false,
      allowOutsideClick: false,
      customClass: {
        container: 'swal-container'
      }
    }).then(r => {
      return r.value;
    });
  },

  // session expired message
  sessionExpiredAlertService(text,actionType = ''){
    return Swal.fire({
      title: `${actionType}`,
      icon: `info`,
      text: `${text}`,
      // customClass:{
      //   confirmButton: 'btn btn-primary btn-sm font-weight-bolder',
      //   cancelButton: 'btn btn-secondary btn-sm font-weight-bolder',
      // },
      showCancelButton: false,
      allowOutsideClick: false,
      customClass: {
        container: 'swal-container'
      }
    }).then(r => {
      if (r.isConfirmed) {
        location.reload(); // Reload the page
      }
    });
  },


  //question
  questionAlertService(text,actionType = '',icon,__customCss){
  return  Swal.fire({
      title : `${actionType}`,
      icon : `${icon}`,
      text : `${text}`,
      // html: `<pre class="ml-1 small multiline ${__customCss}">${_html}</pre>`,
      showCancelButton: true,
      allowOutsideClick: false,
      customClass : {
        container : 'swal-container',
        confirmButton: 'btn btn-primary font-weight-bolder',
        cancelButton: 'btn btn-secondary font-weight-bolder',
      }
    }).then(r =>{
      return r.value;
    });
  },

  // success
  successAlertService (text, actionType = '') {
    return Swal.fire({
      title: `${actionType}`,
      icon: `success`,
      text: `${text}`,
      showCancelButton: false,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-primary font-weight-bolder',
        container: 'swal-container'
      }
    }).then(r => {
      return r.value

    })
  },

  // warning
  warningActionService(text, actionType = ''){
    Swal.fire({
      title: `${actionType}`,
      icon: `warning`,
      text: `${text}`,
      showCancelButton : false,
      allowOutsideClick : false,
      customClass : {
        confirmButton: 'btn btn-warning font-weight-bolder',
        container : 'swal-container'
      }
    }).then(r =>{
      return r.value;

    });
  },

  // For validation in CCM module
  warningActionValidationService(text, actionType = ''){
    Swal.fire({
      title: `${actionType}`,
      icon: `warning`,
      html: "<pre>" + `${text}` + "</pre>",
      showCancelButton : false,
      allowOutsideClick : false,
      customClass : {
        confirmButton: 'btn btn-warning font-weight-bolder',
        container : 'swal-container',
        popup: 'format-pre'
      }
    }).then(r =>{
      return r.value;

    });
  },

  async loadingAlert(text, title){
    return await Swal.fire({
      title: `${title}`,
      text: `${text}`,
      timerProgressBar: false,
      showConfirmButton : false,
      allowOutsideClick : false,
      customClass : {
        container : 'swal-container'
      },
      didOpen: () => {
        Swal.showLoading()
      },

    });
  },


  async confirmUpdateAlert(message) {
    if(!message) message = 'Are you sure you want to update this record?';
    return await Swal.fire({
      title: 'CONFIRM UPDATE',
      text: message,
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      scrollbarPadding: false,
      customClass : {
        container : 'swal-container'
      }
    }).then((result) => {
      return result.value;
    });
  },

  // window.pdfViewer = Swal.mixin({
  //   showCloseButton: true,
  //   showConfirmButton: false,
  //   focusConfirm: false,
  //   imageWidth: 1500,
  //   customClass: 'swal-wide',
  // });

  async pdfViewer(title, fileData) {
    let html = `<iframe src="${fileData}" style="height: 800px;" frameborder="0" width="720" id="certFrame"></iframe>`;
    return await Swal.fire({
      showCloseButton: true,
      showConfirmButton: false,
      focusConfirm: false,
      imageWidth: 1500,
      customClass: 'swal-wide',
      title,
      html,
      scrollbarPadding: true
    });
  },

  async pdfViewerMasterChecklist(title, fileData) {
    let html = `<iframe src="${fileData}" style="height: 600px;" frameborder="0" width="1000" id="certFrame"></iframe>`;
    return await Swal.fire({
      showCloseButton: true,
      showConfirmButton: false,
      focusConfirm: false,
      imageWidth: 1700,
      customClass: 'swal-wide-gears-prpo',
      title,
      html,
      scrollbarPadding: true
    });
  },

  async pdfViewerGearsPRPO(title, fileData) {
    let html = `<iframe src="${fileData}" style="height: 600px;" frameborder="0" width="1000" id="certFrame"></iframe>`;
    return await Swal.fire({
      showCloseButton: true,
      showConfirmButton: false,
      focusConfirm: false,
      imageWidth: 1500,
      customClass: 'swal-wide-gears-prpo',
      title,
      html,
      scrollbarPadding: true
    });
  },

  async pdfViewerInterOcean(title, fileData, fileData2) {
    let html = `<iframe src="${fileData}" style="height: 800px;" frameborder="0" width="720" id="certFrame"></iframe><iframe src="${fileData2}" style="height: 800px;" frameborder="0" width="720" id="certFrame"></iframe>`;
    return await Swal.fire({
      showCloseButton: true,
      showConfirmButton: false,
      focusConfirm: false,
      imageWidth: 1500,
      customClass: 'swal-wide',
      title,
      html,
      scrollbarPadding: true
    });
  },

}


export default {AlertService};
