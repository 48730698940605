<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Crewing',

  data () {
    return {}
  },

  methods: {},

  computed: {},

  mounted () {
  },

  created () {
  },

  destroyed () {
  },

}
</script>

<style scoped>

</style>
