import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";


const state = {
  gear: {},
  gears: [],
  newGear: {},

  gearFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  gearPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },

};


const mutations = {
  set_gears(state, params) {
    state.gears = params
  },

  set_gear(state, params) {
    state.gear = params
  },

  create_gear(state) {
    state.newGear = {};
  },

  update_gear(state, params) {
    let gear = state.gears.find((val, index) => val.id === params.id)
    Object.assign(gear, params)
  },

  delete_gear(state, params) {
    let index = state.gears.findIndex((val, index) => val.id === params)
    state.gears.splice(index, 1)
  },

  set_gear_pagination(state, params) {
    state.gearPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },


};


const actions = {
  async set_gears({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GEAR, {params}).then((r) => {
      commit('set_gears', r.data.data)
      commit('set_gear_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get gears')
      return false
    })
  },

  async set_gear({commit}, param) {
    return await axios.get(`${PMC.GEAR}/${param}`).then((r) => {
      commit('set_gear', r.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Get gear');
      return false;
    });
  },

  async create_gear({commit}, params) {
    return axios.post(PMC.GEAR, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_gear', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create gear');
      return false;
    })
  },

  async update_gear({commit}, params) {
    return axios.put(`${PMC.GEAR}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_gear',r.data.updatedGear);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update gear');
      return false;

    });
  },

  async delete_gear({ commit }, params) {
    // let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Gear','question');
    let _del = AlertService.questionAlertService("This action will delete all records associated with this gear " +
      "in complements and suppliers, even if they are currently in use"
      ,'Are you sure? ','warning');
    if(await _del){
      return axios.delete(`${PMC.GEAR}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete gear');
        commit('delete_gear',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete gear");
        return false;

      })
    }
  },

};


const getters = {
  gears: state => UtilityService.capitalizeProperties(state.gears),
  gear: state => UtilityService.capitalizeProperties(state.gear),
  gearPagination: state => state.gearPagination,
  gearFormParam: state => state.gearFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
