import { render, staticRenderFns } from "./CreateCrew.vue?vue&type=template&id=327d6781&scoped=true&"
import script from "./CreateCrew.vue?vue&type=script&lang=js&"
export * from "./CreateCrew.vue?vue&type=script&lang=js&"
import style0 from "./CreateCrew.vue?vue&type=style&index=0&id=327d6781&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327d6781",
  null
  
)

export default component.exports