import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";

const state = {

  crewFormData: {},
  preDepartureChecklist: {},
  crewChecklistCategories: {},
  getCrewChecklistCategories: {},
  crewDocList:{},
  documentLastIndex: 0,
  signatories: {},
  docLocation: {},
  remarks: {},

};


const mutations = {
  set_crewFormData(state, params) {
    state.crewFormData = params
  },

  set_preDepartureChecklist(state, params) {
    state.preDepartureChecklist = params
  },

  set_crewChecklistCategories(state, params) {
    state.crewChecklistCategories = params;
  },

  set_newCrewChecklistCategories(state, params) {
    state.getCrewChecklistCategories = params;
  },

  set_crewDocList(state, params) {
    state.crewDocList = params;
  },

  set_documentLastIndex(state, params) {
    state.documentLastIndex = params
  },

  set_signatories(state, params) {
    state.signatories = params
  },

  set_docLocation(state, params) {
    state.docLocation = params
  },

  set_remarks(state, params) {
    state.remarks = params
  },


  // set_mutate_get_checklist(state,params){ //r.data
  //   state.preDepartureChecklist = params.pre_departure_checklist;
  //   state.cchCategories = ;
  //   state.crewDocumentList = params;
  //   state.docLastIndex = params;
  // },


};


const appendIndex = (checklist) => {
  for (let doc in checklist) {
    checklist[doc].index = parseInt(doc);
  }
  return checklist;
}

const appendToChecklist = (cchCategories, preDept) => {
  let cCntr = 0;
  let categories = {};
  let newChecklist = {};
  let newCntr = 0;
  const preDepartureChecklist = preDept;
  for (let cch in cchCategories) {
    let cntr = 0;
    let cchDocs = {};
    for (let preDoc in preDepartureChecklist) {
      if (cchCategories[cch].id === preDepartureChecklist[preDoc].cch_details_category_id) {
        cchDocs[cntr] = preDepartureChecklist[preDoc];
        newChecklist[newCntr] = preDepartureChecklist[preDoc];
        newCntr = newCntr + 1;
        cntr = cntr + 1;
      }
      if (Object.keys(cchCategories[cch].sub_categories).length > 0) {
        let cchSubCategories = cchCategories[cch].sub_categories;
        for (let cchSub in cchSubCategories) {
          let cchSubDocs = {};
          let subcntr = 0;
          for (let preDoc in preDepartureChecklist) {
            if (cchSubCategories[cchSub].id === preDepartureChecklist[preDoc].cch_details_category_id) {
              cchSubDocs[subcntr] = preDepartureChecklist[preDoc];
              newChecklist[newCntr] = preDepartureChecklist[preDoc];
              newCntr = newCntr + 1;
              subcntr = subcntr + 1;
            }
          }
          cchCategories[cch]['sub_categories'][cchSub]['documents'] = cchSubDocs;
        }
      }
    }
    cchCategories[cch]['documents'] = cchDocs;
    categories[cCntr] = cchCategories[cch];
    cCntr = cCntr + 1;
    if (Object.keys(cchCategories[cch].sub_categories).length > 0) {
      let subCategories = cchCategories[cch].sub_categories;
      for (let csub in subCategories) {
        categories[cCntr] = subCategories[csub];
        cCntr = cCntr + 1;
      }
    }
  }
  return categories;
}

const actions = {

  async getCrewDocumentChecklist({commit}, params) {
    return await axios.post(PMC.GET_CREW_CHECKLIST, params).then((r) => {
      let crewFormData = r.data
      let crewDocList = appendIndex(crewFormData.crew_documents)
      let crewChecklistCategories = crewFormData.newChecklist_category;
      let newCrewChecklistCategories = crewFormData.newChecklist_category;
      //let crewChecklistCategories = appendToChecklist(crewFormData.checklist_category, crewFormData.pre_departure_checklist)
      commit('set_crewFormData', crewFormData)
      commit('set_preDepartureChecklist', crewFormData.pre_departure_checklist)
      commit('set_crewChecklistCategories', crewFormData.newChecklist_category)
      commit('set_newCrewChecklistCategories', crewFormData.newChecklist_category)
      //commit('set_crewChecklistCategories', crewChecklistCategories)
      commit('set_crewDocList', crewDocList)
      commit('set_documentLastIndex', Object.keys(crewDocList).length)
      commit('set_signatories', crewFormData.signatories)
      commit('set_docLocation', crewFormData.doc_locations)
      commit('set_remarks', crewFormData.remarks)
      return true;
    }).catch((errors) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(errors, 'Get Crew Paginated');
      return false;
    });
  },
  async getDefaultCrewDocumentChecklist({commit}, params) {
    return await axios.post(PMC.GET_CREW_CHECKLIST, params).then((r) => {
      let crewFormData = r.data
      let crewDocList = appendIndex(crewFormData.crew_documents)
      let crewChecklistCategories = crewFormData.checklist_category;
      let newCrewChecklistCategories = crewFormData.checklist_category;
      //let crewChecklistCategories = appendToChecklist(crewFormData.checklist_category, crewFormData.pre_departure_checklist)
      commit('set_crewFormData', crewFormData)
      commit('set_preDepartureChecklist', crewFormData.pre_departure_checklist)
      commit('set_crewChecklistCategories', crewFormData.checklist_category)

      //commit('set_crewChecklistCategories', crewChecklistCategories)
      commit('set_crewDocList', crewDocList)
      commit('set_documentLastIndex', Object.keys(crewDocList).length)
      commit('set_signatories', crewFormData.signatories)
      commit('set_docLocation', crewFormData.doc_locations)
      commit('set_remarks', crewFormData.remarks)
      return true;
    }).catch((errors) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(errors, 'Get Crew Paginated');
      return false;
    });
  },
};

const getters = {
  crewFormData: state => UtilityService.capitalizeProperties(state.crewFormData),
  preDepartureChecklist: state => state.preDepartureChecklist,
  crewChecklistCategories: state => state.crewChecklistCategories,
  getCrewChecklistCategories: state => state.getCrewChecklistCategories,
  crewDocList: state => state.crewDocList,
  documentLastIndex: state => state.documentLastIndex,
  signatories: state => state.signatories,
  docLocation: state => state.docLocation,
  remarks: state => state.remarks,
};


/** export **/
export default {state, actions, mutations, getters};
