import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  documentCategory: {},
  documentCategories: [],
  newDocumentCategory: {},

  documentCategoryFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  documentCategoryPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_documentCategories(state, params) {
    state.documentCategories = params
  },

  set_documentCategory(state, params) {
    state.documentCategory = params
  },

  create_documentCategory (state) {
    state.newDocumentCategory = {};
  },

  update_documentCategory (state, params) {
    let flag = state.documentCategories.find((val, index) => val.id === params.id)
    Object.assign(flag, params)
  },

  delete_documentCategory (state, params) {
    let index = state.documentCategories.findIndex((val, index) => val.id === params)
    state.documentCategories.splice(index, 1)
  },


  null_documentCategories(state) {
    state.documentCategories = []
  },

  null_documentCategory(state) {
    state.documentCategory = {}
  },

  set_documentCategory_pagination(state, params) {
    state.documentCategoryPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {
  async set_documentCategories({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.DOCUMENT_CATEGORY, {params}).then((r) => {
      commit('set_documentCategories', r.data.data)
      commit('set_documentCategory_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get document categories')
      return false
    })
  },

  async create_documentCategory({commit}, params) {
    return axios.post(PMC.DOCUMENT_CATEGORY, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_documentCategory', r.data);
      return true;

    }).catch((er) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(er, 'Create document category');
      return false;
    })
  },

  async update_documentCategory({commit}, params) {
    return axios.put(`${PMC.DOCUMENT_CATEGORY}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_documentCategory',r.data.updatedDocumentCategory);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update document category');
      return false;

    });
  },

  async delete_documentCategory ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete document category','question');
    if(await _del){
      return axios.delete(`${PMC.DOCUMENT_CATEGORY}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete document category');
        commit('delete_documentCategory',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete document category");
        return false;

      })
    }
  },

  reset_documentCategory_pagination({commit}) {
    commit('set_documentCategory_pagination')
  },

  null_documentCategories({commit}) {
    commit('null_documentCategories')
  },

  null_documentCategory({commit}) {
    commit('null_documentCategory')
  },
};


const getters = {
  documentCategories: state => UtilityService.capitalizeProperties(state.documentCategories),

  documentCategoryPagination: state => state.documentCategoryPagination,
  documentCategoryFormParam: state => state.documentCategoryFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
