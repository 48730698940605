<template>
  <div class="container-fluid py-2 px-4">
    <div class="row py-2 text-left">
      <div class="col-md-12">
        <section style="height: 100vh !important;">
          <div class="card">
            <div class="card-header">
              <div class="row align-middle">
                <div class="col-md-3">
                  <h3>Applicant Details</h3>
                </div>
                <div class="col-md-4">
                  <pmc-btn
                    v-if="!isEditable"
                    @buttonEvent="goBack"
                    :button-class="'btn btn-xs btn-secondary'"
                    :button-label="'Go Back'">
                    <template slot="button-icon">
                      <font-awesome-icon icon="undo"/>
                    </template>
                  </pmc-btn>

                  <pmc-btn
                    v-if="!isEditable"
                    @buttonEvent="set_editable"
                    :button-class="'btn btn-xs pmc-btn-warning ml-1'"
                    :button-label="'Edit'">
                    <template slot="button-icon">
                      <font-awesome-icon icon="edit"/>
                    </template>
                  </pmc-btn>

                  <pmc-btn
                    v-if="isEditable"
                    :button-class="'btn btn-xs pmc-btn-tertia ml-1'"
                    :button-label="'Save'"
                    @buttonEvent="update">
                    <template slot="button-icon">
                      <font-awesome-icon icon="save"/>
                    </template>
                  </pmc-btn>

                  <pmc-btn
                    v-if="isEditable"
                    @buttonEvent="cancel"
                    :button-class="'btn btn-xs pmc-btn-danger ml-1'"
                    :button-label="'Cancel'">
                    <template slot="button-icon">
                      <font-awesome-icon icon="times"/>
                    </template>
                  </pmc-btn>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <h4>{{ applicant.crewName }}</h4>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-6">
                  <!-- date applicant status -->
                  <div class="form-group row mt-2">
                    <label for="applicant-status" class="col-sm-3 font-weight-bold">Applicant Status</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <select
                        :disabled="!isEditable"
                        v-model="applicant.applicantStatus"
                        :class="{'form-control form-control' : isEditable, 'form-control form-control form-control-plaintext' : !isEditable}"
                        name="ApplicantStatus"
                        @change="on_change_applicantStatus"
                        id="applicant-status">
                        <option :value="null" disabled>--select--</option>
                        <option :value="appStat.id" v-for="(appStat,index) in objApplicantStatuses" :key="appStat.id">
                          {{ appStat.name }}
                        </option>
                      </select>
                    </div>
                    <button v-if="isEditable" class="btn btn-xs" @click="applicant.applicantStatus = null">
                      <font-awesome-icon icon="times"/>
                    </button>
                  </div>

                  <!-- applicant type -->
                  <div class="form-group row mt-3">
                    <label for="applicant-type" class="col-sm-3 font-weight-bold">Applicant Type</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <select
                        :disabled="!isEditable"
                        v-model="applicant.applicantTypeId"
                        :class="{'form-control form-control' : isEditable, 'form-control form-control form-control-plaintext' : !isEditable}"
                        name="" id="applicant-type">
                        <option :value="null" disabled>--select--</option>
                        <option :value="appType.id" v-for="(appType,index) in objApplicantTypes" :key="appType.id">
                          {{ appType.name }}
                        </option>
                      </select>
                    </div>
                    <button v-if="isEditable" class="btn btn-xs" @click="applicant.applicantTypeId = null">
                      <font-awesome-icon icon="times"/>
                    </button>
                  </div>

                  <!--  endorsement type -->
                  <div class="form-group row mt-3">
                    <label for="endorsement-type" class="col-sm-3 font-weight-bold">Endorsement Type</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <select
                        :disabled="!isEditable"
                        v-model="applicant.endorsementTypeId"
                        :class="{'form-control form-control' : isEditable, 'form-control form-control form-control-plaintext' : !isEditable}"
                        name="EndorsementType"
                        @change="on_change_endorsemet_type"
                        id="endorsement-type">
                        <option value="" disabled>--select--</option>
                        <option :value="endorseType.id" v-for="(endorseType,index) in objEndorsementTypes" :key="endorseType.id">
                          {{ endorseType.name }}
                        </option>
                      </select>
                    </div>
                    <button v-if="isEditable" class="btn btn-xs" @click="applicant.endorsementTypeId =  ''">
                      <font-awesome-icon icon="times"/>
                    </button>
                  </div>

                  <!-- endorsed by --> <!--  TODO make list of employee   -->
                  <div v-if="isCrewEndorse" class="form-group row mt-3">
                    <label for="endorsed-by" class="col-sm-3 font-weight-bold">Endorsed By</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input type="text"
                             v-if="!isEditable"
                             disabled="disabled"
                             v-model="applicant.endorseBy"
                             class="form-control form-control-sm form-control-plaintext"
                             id="endorsed-by"
                             name="EndorsementBy"
                             placeholder="--">

                      <span v-if="isEditable && formEndorseBy != null && applicant.endorseById != null"
                            class="text-center align-middle form-control form-control-sm form-control-plaintext-custom">
                      {{ thisEndorseBy }}
                    </span>

                      <input v-if="isEditable"
                             type="button"
                             value="Search crew here."
                             @click="onSearchCrewEndorser"
                             data-backdrop="static"
                             data-keyboard="false"
                             data-toggle="modal"
                             data-target="#crew-endorser-modal"
                             class="form-control form-control">
                    </div>
                    <button v-if="isEditable && applicant.endorseById" class="btn btn-xs" @click="applicant.endorseById = null">
                      <font-awesome-icon icon="times"/>
                    </button>
                  </div>

                  <!-- endorsed by others  -->
                  <div class="form-group row mt-3">
                    <label for="endorsed-by-others" class="col-sm-3 font-weight-bold">Endorsed By Others</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input
                        :disabled="!this.isEditable"
                        type="text"
                        v-model="applicant.endorseByOthers"
                        :class="{'form-control form-control' : isEditable, 'form-control form-control form-control-plaintext' : !isEditable}"
                        id="endorsed-by-others"
                        :readonly="applicant.endorsementTypeId == 1"
                        name="EndorsementByOthers"
                        placeholder="--">
                    </div>
                  </div>

                  <hr>

                  <!-- date applied  -->
                  <div class="form-group row mt-3">
                    <label for="date-applied" class="col-sm-3 font-weight-bold">Date Applied</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input type="date"
                             v-if="isEditable"
                             v-model="applicant.dateApplied"
                             class="form-control form-control"
                             name="DateApplied"/>

                      <input type="text"
                             :disabled="!isEditable"
                             v-if="!isEditable"
                             v-model="applicant.dateApplied_str"
                             class="form-control form-control-plaintext"
                             id="date-applied"
                             name="DateApplied"
                             placeholder="N / A">
                    </div>

                    <button v-if="isEditable" class="btn btn-xs" @click="applicant.dateApplied = null">
                      <font-awesome-icon icon="times"/>
                    </button>
                  </div>

                  <!--  date qualified -->
                  <div class="form-group row mt-3">
                    <label for="date-qualified" class="col-sm-3 font-weight-bold">Date Qualified</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input type="date"
                             v-if="isEditable"
                             v-model="applicant.dateQualified"
                             class="form-control form-control"
                             name="DateApplied"/>

                      <input type="text"
                             disabled
                             v-model="applicant.dateQualified_str"
                             v-if="!isEditable"
                             class="form-control form-control-plaintext"
                             id="date-qualified"
                             name="DateQualified"
                             placeholder="N / A">
                    </div>

                    <button v-if="isEditable" class="btn btn-xs" @click="applicant.dateQualified = null">
                      <font-awesome-icon icon="times"/>
                    </button>
                  </div>

                  <!--  date accepted -->
                  <div class="form-group row mt-3">
                    <label for="date-accepted" class="col-sm-3 font-weight-bold">Date Accepted</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input type="date"
                             v-if="isEditable"
                             v-model="applicant.dateAccepted"
                             class="form-control form-control"
                             name="DateApplied"/>

                      <input type="text"
                             disabled
                             v-model="applicant.dateAccepted_str"
                             v-if="!isEditable"
                             class="form-control form-control-plaintext"
                             id="date-accepted"
                             name="DateQualified"
                             placeholder="--">
                    </div>

                    <button v-if="isEditable" class="btn btn-xs" @click="applicant.dateAccepted = null">
                      <font-awesome-icon icon="times"/>
                    </button>
                  </div>

                  <!-- date available -->
                  <div class="form-group row mt-3">
                    <label for="date-available" class="col-sm-3 font-weight-bold">Date Available</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input type="date"
                             v-if="isEditable"
                             v-model="applicant.dateAvailable"
                             class="form-control form-control"
                             name="DateAvailable"
                             placeholder="--">

                      <input type="text"
                             v-if="!isEditable"
                             disabled="disabled"
                             v-model="applicant.dateAvailable_str"
                             class="form-control form-control-plaintext"
                             id="date-available"
                             name="DateAvailable"
                             placeholder="N / A">
                    </div>
                    <button v-if="isEditable" class="btn btn-xs" @click="applicant.dateAvailable = null">
                      <font-awesome-icon icon="times"/>
                    </button>
                  </div>
                  <!-- date pmc-office -->
                  <div class="form-group row mt-3">
                    <label for="pmc-office" class="col-sm-3 font-weight-bold">PMC Office</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <select
                        :disabled="!isEditable"
                        v-model="thisPmcOffice"
                        :class="{'form-control form-control' : isEditable, 'form-control form-control form-control-plaintext' : !isEditable}"
                        name="EndorsementType"
                        id="pmc-office">
                        <option :value="null" disabled>--select--</option>
                        <option :value="office.name" v-for="(office,index) in objPmcOffices" :key="office.id">
                          {{ office.name }}
                        </option>
                      </select>
                    </div>
                    <button v-if="isEditable" class="btn btn-xs" @click="applicant.pmcOffice = null">
                      <font-awesome-icon icon="times"/>
                    </button>
                  </div>

                  <!-- date applicant remarks -->
                  <div class="form-group row mt-2">
                    <label for="applicant-remarks" class="col-sm-3 font-weight-bold">Applicant Remarks</label>
                    <span>:</span>
                    <div class="col-sm-8">
                    <textarea
                      :disabled="!isEditable"
                      v-model="applicant.remarks"
                      id="applicant-remarks"
                      :class="{'form-control form-control' : isEditable, 'form-control form-control form-control-plaintext' : !isEditable}"
                      placeholder="Applicant tracking remarks here.."
                      class="form-control"
                      rows="4"
                      style="resize: none;">
                    </textarea>
                    </div>
                  </div>
                  <hr>
                  <!-- Qualified by -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-3">Qualified by</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input type="text"
                             v-model="applicant.qualifiedBy"
                             class="form-control form-control form-control-plaintext"
                             readonly>
                    </div>
                  </div>

                  <!-- accepted by -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-3">Accepted by</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input type="text"
                             v-model="applicant.acceptedBy"
                             class="form-control form-control form-control-plaintext"
                             readonly>
                    </div>
                  </div>

                  <!-- created at -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-3">Created at</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input type="text"
                             v-model="applicant.createdAt"
                             class="form-control form-control form-control-plaintext"
                             readonly>
                    </div>
                  </div>

                  <!-- updated at -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-3">Updated at</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input type="text"
                             v-model="applicant.updatedAt"
                             class="form-control form-control form-control-plaintext"
                             readonly>
                    </div>
                  </div>
                </div>

                <div class="col-6 border-left">
                  <h6>Endorsed Crews</h6>
                  <!-- table -->
                  <pmc-table class="py-4" :max-height="250">
                    <template v-slot:table-header>
                      <tr>
                        <th width="2%">#</th>
                        <th width="15%">Rank</th>
                        <th width="20%">Name</th>
                      </tr>
                    </template>
                    <template slot="table-body">
                      <tr v-for="(endorse,index) in applicant.crewEndorseList" :key="endorse.id">
                        <td>{{ index + 1 }}</td>
                        <td class="text-left">
                          <span class="pl-2">
                            <!-- todo go to crew profile here -->
                            {{ endorse.crewRank }}
                          </span>
                        </td>
                        <td class="text-left">
                          <span class="pl-2">{{ endorse.crewName }}</span>
                        </td>
                      </tr>
                    </template>
                  </pmc-table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="crew-endorser-modal" tabindex="-1" role="dialog" aria-labelledby="crewEndorserModalTitle" aria-hidden="true">
      <div class="modal-dialog  modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Select Crew Endorser</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">
                <font-awesome-icon icon="times"/>
              </span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row py-2">
              <label for="" class="col-form-label col-md-3">Search</label>
              <span>:</span>
              <div class="col-sm-8">
                <input type="text" id="search-endorser"
                       name="searchEndorser"
                       placeholder="Search crew here..."
                       v-model="formSearchParams.searchValue"
                       @keydown="searchCrewDebounce"
                       class="form-control form-control-sm">
              </div>
            </div>

            <pmc-pagination :page-object="objectCrewPagination" @settingPage="setThisPage"></pmc-pagination>
            <pmc-table :max-height="300">
              <template v-slot:table-header>
                <tr>
                  <th>#</th>
                  <th>Rank</th>
                  <th>Crew Name</th>
                </tr>
              </template>

              <template v-slot:table-body>
                <tr v-for="(crewEndorser,index) in objCrewEndorsers" :key="crewEndorser.id" @click="onClickEndorserTbl(crewEndorser)">
                  <td>{{ index + objectCrewPagination.from }}</td>
                  <td>{{ crewEndorser.rank }}</td>
                  <td class="pl-2 text-left align-middle">{{ crewEndorser.fullName }}</td>
                </tr>

              </template>
            </pmc-table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from '../../../mixins/app.mixins';
import {AlertMixins} from '../../../mixins/alert.mixins';
import {mapActions, mapGetters} from 'vuex';
import __ from "lodash";

export default {
  name: 'ApplicantDetail',

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      applicantTrackingId: parseInt(this.$route.params.id, 10),
      isEditable: false,
      formSearchParams: {
        searchValue: '',
        searchKey: '',
        sRank: '',
        count: 10,
        isTrue: false,
      },

      formEndorseBy: null,

      isEndorseBy: false,
      isEndorseByOthers: false,

    };
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_applicant',
      'getApplicantTrackingKeys',
      'getAlLCrewPaginated',
      'null_applicant',
      'setNullCrews',
      'reset_object_crews_pagination',
      'update_applicant',
      "null_obj_crew_endorsers",
      "reset_state_applicant_module",
      "set_obj_crew_endorsers",
    ]),

    searchCrewDebounce: __.debounce(function () {
      this.searchCrew();
    }, 500),

    set_editable() {
      this.isEditable = !this.isEditable;
      if (!this.isEditable) {
        this.set_applicant(parseInt(this.applicant.applicantTrackingId,10));
      }
    },

    update() {
      this.update_applicant(this.applicant).then((r) => {
        if (r) {
          this.set_editable();
          this.formEndorseBy = null;
        }
      });
    },

    onClickEndorserTbl({id}) {
      this.applicant.endorseById = id;
      this.$nextTick(() => {
        $(`#crew-endorser-modal`).modal('hide');
      })
    },

    cancel() {
      this.formEndorseBy = null;
      this.set_editable();
    },

    searchCrew() {
      this.formSearchParams.page = 1;
      this.getAlLCrewPaginated(this.formSearchParams);
    },

    reset_this_form_applicant() {
      this.setNullCrews();
      // this.null_applicant();
    },

    set_endorse_by(crewId) {
      this.applicant.endorsedBy = crewId;
    },

    set_this_page(e) {
      this.searchCrewPagination.currentPage = e;
      this.formSearchParams.page = e;
      this.getAlLCrewPaginated(this.formSearchParams);
    },

    on_change_endorsemet_type() {
      if (this.applicant.endorsementTypeId == 1) {
        this.applicant.endorseByOthers = null;
      }
    },

    on_change_applicantStatus(e) {
      let val = e.target.value;
    },

    /**
     *
     *@deprecated
     */
    setDisabled(__appStat, __applicantStatus) {

      if (__appStat == __applicantStatus) {
        return true;
      }
      switch (__appStat) {
        case 1:
          return true;
          break;

        case 2:
          return true;
          break;

        case 3:
          return false;
          break;

        case 4:
          return true;
          break;
      }
    },

    setThisPage(e) {
      //TODO make the following
      // this.crewListPagination.currentPage = e;
      // this.formParams.page = e;
      // this.get_all_crew_paginated();
      this.objectCrewPagination.currentPage = e;
      this.formSearchParams.page = e;
      this.formSearchParams.searchKey = "all"
      this.getAlLCrewPaginated(this.formSearchParams);
    },

    onSearchCrewEndorser() {
      this.getAlLCrewPaginated(this.formSearchParams);
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters([
      'applicant',
      'objApplicantTypes',
      'objApplicantStatuses',
      'objEndorsementTypes',
      'objPmcOffices',
      'searchCrewPagination',
      'crews',
      'isReloading',
      "objCrewEndorsers",
      'objectCrewPagination',
    ]),

    isCrewEndorse() {
      return this.applicant.endorsementTypeId == 1 ? true : false;
    },

    thisPmcOffice: {
      get() {
        return this.applicant.pmcOffice ? this.applicant.pmcOffice : null;
      },
      set(val) {
        this.applicant.pmcOffice = val;
      },
    },

    thisEndorseBy: {
      get() {
        let test = this.objCrewEndorsers.find((val, index) => {
          return val.id === this.applicant.endorseById;
        })

        if (test != undefined) {
          this.formEndorseBy = test.fullName.toUpperCase();
        }

        return test ? test.fullName.toUpperCase() : this.applicant.endorsedBy;
      },
      set() {
        //this.applicant.endorseBy;
      }
    },
  },

  /** mounted **/
  mounted() {
    this.set_applicant(this.applicantTrackingId);
    this.thisCrewEndorser;
    // console.log(this.applicant.endorsementTypeId);
    this.endorsementType = this.applicant.endorsementTypeId;

  },

  /** created **/
  created() {
    this.getApplicantTrackingKeys();
    this.searchCrew();
    this.set_obj_crew_endorsers();
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.reset_this_form_applicant();
    this.reset_object_crews_pagination();
    this.reset_state_applicant_module();
    this.isEndorseBy = false;
    this.formEndorseBy = null;
  },
};
</script>

<style scoped>

</style>
