<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Flag</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newFlagModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Flag
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search flag name"
                v-model="flagFormParam.searchValue"
                @keypress.enter="searchFlag">

              <button class="btn btn-sm pmc-btn-prime" @click="searchFlag">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- status -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat"
                        class="form-control custom-select-sm"
                        v-model="flagFormParam.status"
                        @change="on_change_status">
                  <option :value="null">--select--</option>
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.placeholder }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="flagFormParam.count"
                        @change="on_change_count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>


        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="flagPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="4%">CODE</th>
                <th scope="row" class="align-middle" width="7%">NAME</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>AT</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>AT</th>
                <th scope="row" class="align-middle" width="5%">STATUS</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-if = "flags.length === 0">
                <td colspan="10">No Results</td>
              </tr>
              <tr v-else v-for="(flag, index) in flags" :key="flag.id">
                <td>{{ index + flagPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ flag.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ flag.name }}</td>
                <td scope="col" class="p-0 text-center">{{ flag.created_by }}</td>
                <td scope="col" class="p-0 text-center">{{ flag.updated_by }}</td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ flag.created_at }}
                </td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ flag.updated_at }}
                </td>
                <td scope="col" class="p-0 text-center">
                  {{ flag.status == 1 ? 'ACTIVE' : 'INACTIVE' }}
                </td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewFlagModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="flagViewtoFlagData = flag">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_flag(flag.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new flag modal   -->
    <div class="modal fade" id="newFlagModal" tabindex="-1" role="dialog" aria-labelledby="newFlagModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newFlagModalLabel">NEW FLAG</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newFlag.code"
                    />
                  </div>
                </div>

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newFlag.name"
                    />
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newFlag.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view flag   -->
    <div class="modal fade" id="viewFlagModal" tabindex="-1" role="dialog" aria-labelledby="viewFlagModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewFlagModalLabel">Flag</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="flagData.code"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="flagData.name"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           v-model="flagData.status"
                           class="form-check form-check-inline ml-2 mt-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CREATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           v-model="flagData.created_by"
                           class="form-control form-control-plaintext"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">UPDATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           v-model="flagData.updated_by"
                           class="form-control form-control-plaintext"
                           placeholder="---"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit">
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel">
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      flagViewtoFlagData: {},
      flagData: {},
      newFlag: {},
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,

      pageLastLeft: 1,

    };
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_flags',
      'create_flag',
      'update_flag',
      'delete_flag',
      'null_flags',
    ]),

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Flag', 'question')
      if (await __create) {

        let params = {
          code: this.newFlag.code ? this.newFlag.code : '',
          name: this.newFlag.name, // this is required
          status: this.newFlag.status ? 1 : 0,
        };

        let newFlag = await this.create_flag(params);
        if (newFlag) {
          AlertService.successAlertService('Create flag record successful', 'Create flag');
          this.hideModal('newPrincipalModal');
          this.newFlag = {};
          this.set_principals();
        }
      }
    },

    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update flag', 'question');
      if (await __update) {
        let params = {
          id: this.flagData.id,
          code: this.flagData.code ? this.flagData.code : '' ,
          name: this.flagData.name, // this is required
          status: this.flagData.status ? 1 : 0,

        };

        let updatedFlag = await this.update_flag(params);
        if (updatedFlag) {
          AlertService.successAlertService("Updated successfully",'Update flag');
          this.hideModal('viewFlagModal')
          this.isEditable = false
        }
      }

    },


    set_this_page(e) {
      this.flagFormParam.searchValue = ''
      this.flagPagination.currentPage = e
      this.flagFormParam.page = e
      this.pageLastLeft = e
      this.set_flags(this.flagFormParam);

      // this.flagFormParam.currentPage = e;
      // this.flagFormParam.page = e;
      // this.set_flags(this.flagFormParam);
    },

    searchFlag() {
      if (this.flagFormParam.searchValue === '') {
        this.flagFormParam.page = this.pageLastLeft
        this.set_flags(this.flagFormParam)
      }

      if (this.flagFormParam.searchValue) {
        this.flagFormParam.page = 1
      }

      this.set_flags(this.flagFormParam)

      // this.flagFormParam.page = 1;
      // this.set_flags(this.flagFormParam);
    },

    on_change_status(e) {
      this.flagFormParam.page = 1
      this.flagFormParam.status = e.target.value;
      this.set_flags(this.flagFormParam);
    },

    on_change_count(e) {
      this.flagFormParam.page = 1
      this.flagFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.flagFormParam.count = e.target.value
      this.set_flags(this.flagFormParam)

      // this.flagFormParam.count = e.target.value;
      // this.set_flags(this.flagFormParam);
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel() {
      this.isEditable = false
      this.flagData = Object.assign({}, this.flagViewtoFlagData);
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'flags',
        'flagPagination',
        'flagFormParam',
      ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_flags(this.flagFormParam)
  },


  /** watch **/
  watch: {
    'flagViewtoFlagData'() {
      this.flagData = Object.assign({}, this.flagViewtoFlagData);
    },
  },

  /** destroyed **/
  destroyed() {
    this.null_flags();
  },

}
</script>

<style scoped>

</style>
