import axios from "axios";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";
const initStateCrewPoolPerRankList = () => {
  return {
    crewPoolReportPerRankAll: [],
    crewPoolReportPerRankList: [],
    isCrewPoolReportLoading: false,
  }
};

const state = {
  ...initStateCrewPoolPerRankList()
};

const mutations = {

  set_crewPoolReportPerRankList(state, params) {
    state.crewPoolReportPerRankList = params
  },

  set_crewPoolReportPerRankAll(state, params) {
    state.crewPoolReportPerRankAll = params
  },

  set_isCrewPoolReportLoading(state, payload) {
    state.isCrewPoolReportLoading = payload
  },

}

const actions = {

  async set_crewPoolReportPerRankList({commit}, params) {
    commit('set_isCrewPoolReportLoading', true)
    return await axios.get(PMC.GET_CREW_POOL_REPORT_PER_RANK_LIST, {params}).then((r) => {
      commit('set_crewPoolReportPerRankList', r.data)
      commit('set_isCrewPoolReportLoading', false)
      return true;

    }).catch((er) => {
      commit('set_isCrewPoolReportLoading', false)
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get set_onboard crews doc certs')
      }
      return false;
    })
  },

  async set_crewPoolReportPerRankAll({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GET_CREW_POOL_REPORT_PER_RANK_ALL, {params}).then((r) => {
      commit('set_crewPoolReportPerRankAll', r.data)
      commit('set_is_loading', false)
      return true;

    }).catch((er) => {
      commit('set_is_loading', false)
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get set_onboard crews doc certs')
      }
      return false;
    })
  },

};

const getters = {
  crewPoolReportPerRankAll: state => UtilityService.capitalizeProperties(state.crewPoolReportPerRankAll),
  crewPoolReportPerRankList: state => UtilityService.capitalizeProperties(state.crewPoolReportPerRankList),
  isCrewPoolReportLoading: state => state.isCrewPoolReportLoading,
};


export default {state, actions, mutations, getters};

