<template>
  <div class="px-1 bg-white" style="width: 100%; overflow-x: hidden;">

    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom py-0">
          <!--          <button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" @click="is_side_nav">-->
          <!--            <font-awesome-icon icon="bars" style="transform: scaleX(1.5)"/>-->
          <!--          </button>-->
          <div class="collapse navbar-collapse ml-3" id="navbarResponsive">
            <ul class="navbar-nav ml-auto ml-md-0">
            </ul>
          </div>
        </nav>

        <div class="row">
          <div class="col-md-12">
            <div class="bg-white">
              <div id="mySidebar" style="width: 200px !important;"
                   class="sidenav text-left bg-white"
                   v-if="!form"
              >
                <!--                <a href="#" @click="showAdhoc = !showAdhoc"-->
                <!--                   class="mb-2"-->
                <!--                   :class="{active: isAdhocActive()}">-->
                <!--                  <font-awesome-icon icon="folder"/>-->
                <!--                  AD-HOC-->
                <!--                  <span v-if="showAdhoc"><font-awesome-icon icon="chevron-down"/></span>-->
                <!--                  <span v-else><font-awesome-icon icon="chevron-right"/></span>-->
                <!--                </a>-->
                <!--                <ul v-show="showAdhoc">-->
                <!--                  <router-link :to="{name :'POEAdocumentList'}" :class="{'active' :isRouteActive('POEAdocumentList')}">-->
                <!--                    <font-awesome-icon icon="caret-right"/>-->
                <!--                    POEA Documents-->
                <!--                  </router-link>-->
                <!--                </ul>-->
                <hr class="dropdown-divider"/>
                <a href="#" @click="showCrew = !showCrew"
                   class="activeParentSideNav">
                  <span class="font-weight-bold">
                     <font-awesome-icon icon="database"/>
                  CREW
                  <span v-if="showCrew"><font-awesome-icon icon="chevron-down"/></span>
                  <span v-else><font-awesome-icon icon="chevron-right"/></span>
                  </span>
                </a>

                <hr class="dropdown-divider" v-if="showCrew"/>
                <ul class="ulStyle" v-show="showCrew">
                  <!--                  <router-link :to="{name :'OnboardCrewList'}" :class="{'active' :isRouteActive('OnboardCrewList')}">-->
                  <!--                      <span class="ml-3">-->
                  <!--                        <font-awesome-icon icon="caret-right"/>-->
                  <!--                        Onboard Crew List-->
                  <!--                      </span>-->
                  <!--                  </router-link>-->


                  <!--                  CREW LIST-->
                  <a href="#" @click="showCrewList = !showCrewList"
                     class="mb-2 font-weight-bold"
                  >
                    <span class="ml-3">
                      CREW LIST
                      <span v-if="showCrewList"><font-awesome-icon icon="chevron-down"/></span>
                    <span v-else><font-awesome-icon icon="chevron-right"/></span>
                    </span>
                  </a>
                  <ul v-show="showCrewList">
                    <router-link :to="{name :'MasterlistDocsFormatList'}"
                                 :class="{'text-success': isRouteActive('MasterlistDocsFormatList')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        a. Masterlist (Docs Format)
                      </span>
                    </router-link>

                    <router-link :to="{name :'OwnerFormatList'}"
                                 :class="{'text-success': isRouteActive('OwnerFormatList')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        b. Masterlist (Owner Format)
                      </span>
                    </router-link>

                    <router-link :to="{name :'MasterlistCertsFormatList'}"
                                 :class="{'text-success': isRouteActive('MasterlistCertsFormatList')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        c. Masterlist (Certs Format)
                      </span>
                    </router-link>

                    <router-link :to="{name :'MasterlistShoeiFormatList'}"
                                 :class="{'text-success': isRouteActive('MasterlistShoeiFormatList')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        d. Masterlist (SHOEI Format)
                      </span>
                    </router-link>

                    <router-link :to="{name :'StandbyOnboardList'}"
                                 :class="{'text-success': isRouteActive('StandbyOnboardList')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        e. Standby and Onboard
                      </span>
                    </router-link>

                    <router-link :to="{name :'OnboardSeniorOfficerList'}"
                                 :class="{'text-success': isRouteActive('OnboardSeniorOfficerList')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        f. Onboard Senior Officers
                      </span>
                    </router-link>
                  </ul>

                  <hr class="dropdown-divider"/>
                  <!--                  Sign On / Sign Off Summary-->
                  <router-link :to="{name :'SingONSignOffSummary'}"
                               :class="{'text-success' :isRouteActive('SingONSignOffSummary')}">
                       <span class="ml-3 font-weight-bold">
                        Sign On / Sign Off Summary
                       </span>
                  </router-link>

                  <hr class="dropdown-divider"/>
                  <!--                  Manpower pool -->
                  <a href="#" @click="showManpowerPool = !showManpowerPool"
                     class="mb-2 font-weight-bold"
                  >
                    <span class="ml-3 text-uppercase">
                      Manpower Pool
                      <span v-if="showManpowerPool"><font-awesome-icon icon="chevron-down"/></span>
                    <span v-else><font-awesome-icon icon="chevron-right"/></span>
                    </span>
                  </a>
                  <ul v-show="showManpowerPool">
                    <router-link :to="{name :'ManPowerStandbyOnboard'}"
                                 :class="{'text-success': isRouteActive('ManPowerStandbyOnboard')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        a. Pool List
                      </span>
                    </router-link>

                    <router-link :to="{name :'HighestGRT'}"
                                 :class="{'text-success': isRouteActive('HighestGRT')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        b. Highest GRT
                      </span>
                    </router-link>

                    <router-link :to="{name :'CrewPerPrincipal'}"
                                 :class="{'text-success': isRouteActive('CrewPerPrincipal')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        <!--<font-awesome-icon icon="caret-right"/>-->
                        c. Crew Per Principal
                      </span>
                    </router-link>

                    <router-link :to="{name :'CovidVaccineReport'}"
                                 :class="{'text-success': isRouteActive('CovidVaccineReport')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        d. Covid Vaccine
                      </span>
                    </router-link>

                    <router-link :to="{name :'CrewAgeRange'}"
                                 :class="{'text-success': isRouteActive('CrewAgeRange')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        e. Crew Age Range
                      </span>
                    </router-link>

                    <router-link :to="{name :'InactiveList'}"
                                 :class="{'text-success': isRouteActive('InactiveList')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        f. Pool List (Inactive)
                      </span>
                    </router-link>

                    <router-link :to="{name :'MedicalIssueReport'}"
                                 :class="{'text-success': isRouteActive('MedicalIssueReport')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        g. Medical Issue
                      </span>
                    </router-link>

                    <router-link :to="{name :'CrewPoolSeaServiceLists'}"
                                 :class="{'text-success': isRouteActive('CrewPoolSeaServiceLists')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        h. Crew Pool Sea Service Lists
                      </span>
                    </router-link>

                    <router-link :to="{name :'CrewOnBoard'}"
                                 :class="{'text-success': isRouteActive('CrewOnBoard')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        i. Crew Onboard
                      </span>
                    </router-link>

                    <router-link :to="{name :'ScholarMonitoringList'}"
                                 :class="{'text-success': isRouteActive('ScholarMonitoringList')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        j. Scholars Monitoring List
                      </span>
                    </router-link>
                  </ul>

                  <hr class="dropdown-divider"/>

                  <!--  Promotion  -->
                  <a href="#" @click="showPromotion = !showPromotion"
                     class="mb-2 font-weight-bold">
                    <span class="ml-3 text-uppercase">
                      Promotion
                      <span v-if="showPromotion"><font-awesome-icon icon="chevron-down"/></span>
                    <span v-else><font-awesome-icon icon="chevron-right"/></span>
                    </span>
                  </a>
                  <ul v-show="showPromotion">
                    <router-link :to="{name :'CrewPromotionYearly'}"
                                 :class="{'text-success': isRouteActive('CrewPromotionYearly')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        a. Crew Promotion Yearly
                      </span>
                    </router-link>

                    <router-link :to="{name :'CrewPromotionPerRank'}"
                                 :class="{'text-success': isRouteActive('CrewPromotionPerRank')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        b. Crew Promotion Per Rank
                      </span>
                    </router-link>
                  </ul>

                  <hr class="dropdown-divider"/>

                  <!--  Agent list  -->
                  <router-link :to="{name: 'AgentList'}"
                               :class="{'text-success': isRouteActive('AgentList')}">
                     <span class="ml-3 font-weight-bold">
                        Agent List
                       </span>
                  </router-link>

                  <hr class="dropdown-divider"/>
                  <!--               3-Month Plan   -->
                  <router-link :to="{name :'ThreeMonthPlan'}"
                               :class="{'text-success' :isRouteActive('ThreeMonthPlan')}">
                       <span class="ml-3 font-weight-bold">
                        3-Month Plan
                       </span>
                  </router-link>

                  <hr class="dropdown-divider"/>

                  <!--                  Principal Format -->
                  <a href="#" @click="showPrincipalFormat = !showPrincipalFormat"
                     class="mb-2 font-weight-bold"
                  >
                    <span class="ml-3 text-uppercase">
                      Principal Format
                      <span v-if="showPrincipalFormat"><font-awesome-icon icon="chevron-down"/></span>
                    <span v-else><font-awesome-icon icon="chevron-right"/></span>
                    </span>
                  </a>
                  <ul v-show="showPrincipalFormat">
                    <router-link :to="{name :'KeiyoFormat'}"
                                 :class="{'text-success': isRouteActive('KeiyoFormat')}">
                      <span style="margin-left: 30px" class="font-weight-bold">
                        a. Keiyo Format
                      </span>
                    </router-link>
                  </ul>

                  <hr class="dropdown-divider"/>
                  <!--  Agent list  -->
                  <router-link :to="{name: 'StandbyRatio'}"
                               :class="{'text-success': isRouteActive('StandbyRatio')}">
                     <span class="ml-3 font-weight-bold">
<!--                         <font-awesome-icon icon="caret-right"/>-->
                        Retention
                       </span>
                  </router-link>

                </ul>

                <hr class="dropdown-divider"/>
                <a href="#" @click="showPreDeparture = !showPreDeparture"
                   class="mb-2">
                  <font-awesome-icon icon="database"/>
                  <span class="font-weight-bold text-uppercase">
                    Pre-Departure
                  </span>
                  <span v-if="showPreDeparture"><font-awesome-icon icon="chevron-down"/></span>
                  <span v-else><font-awesome-icon icon="chevron-right"/></span>
                </a>
                <ul v-show="showPreDeparture">
                  <router-link :to="{name:'DocsCertMonitoring'}"
                               class="font-weight-bold"
                               :class="{'text-success': isRouteActive('DocsCertMonitoring')}">
                    <span class="ml-3">
                        Docs & Cert Monitoring
                      </span>
                  </router-link>
                  <router-link :to="{name:'GearsPRPOList'}"
                               class="font-weight-bold"
                               :class="{'text-success': isRouteActive('GearsPRPOList')}">
                    <span class="ml-3">
                        Gears PR/PO
                      </span>
                  </router-link>
                </ul>
                <hr class="dropdown-divider"/>

<!--                @click="openInNewWindow(baseURL + vesselListRoute)"-->
                <a href="#" class="mb-2 activeParentSideNav"
                   @click="openInNewWindow(baseURL + liveServerPrefix + vesselListRoute)"
                >
                    <span class="font-weight-bold">
                       <font-awesome-icon icon="database"/> VESSEL
                    </span>
                </a>
              </div>


              <div id="main" class="bg-white" style=" height: calc(100vh - 102px) !important;">
                <div>
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {global_variables_config} from "@/config/global-variable.config";
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import CrewBiodata from "@/components/modules/crewprofile/_sub/biodata/CrewBiodata";

export default {
  name: "ReportList",
  components: {CrewBiodata},

  props: {
    crewId: {
      type: Number,
    }
  },

  mixins: [AppMixins, AlertMixins],

  data() {
    return {
      isSideNav: false,
      showAdhoc: false,

      //CREW
      showCrew: true,
      showCrewList: true,
      showSignonSignOff: false,
      showManpowerPool: true,

      //Promotion
      showPromotion: true,

      //(3) Month plan
      show3MonthPlan: true,
      showAgentList: true,

      // Principal Format
      showPrincipalFormat : true,

      // Pre Departure parent menu
      showPreDeparture: true,

      baseURL: '',
      vesselListRoute: '/h/report/vessel-lists?view=vesselList',
      liveServerPrefix: global_variables_config.liveServerPrefix,
    }
  },

  methods: {
    ...mapActions([]),

    // isRouteActive(routeName) {
    //   return this.$route.name === routeName;
    // },

    isRouteActive(routeName) {
      return this.$route.matched.some(preDep => {
        return preDep.name === routeName;
      });
    },


    is_side_nav() {
      this.isSideNav = !this.isSideNav;
    },


    openNav() {
      document.getElementById("mySidebar").style.width = "300px";
      document.getElementById("main").style.marginLeft = "300px";
    },

    closeNav() {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
    },

    gotoVesselList() {
      let routeData = this.$router.resolve({ //
        name: 'VesselActiveInactiveListView',
      });
      console.log(screen.availWidth);
      window.open(routeData.href, '_blank',"height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));
    },
  },

  computed: {
    ...mapGetters([]),

    form() {
      let form = this.$route.query.view;
      return form ? form : null;
    }

  },

  mounted() {
    this.openNav();
  },

  created() {
    this.baseURL = window.location.origin;
  },

  destroyed() {
    // this.nullCrew()
  },

  watch: {

    isSideNav(val) {
      if (val) {
        this.openNav();
      } else {
        this.closeNav();
      }
    },
  }


}
</script>

<style scoped>
#mySidebar{
  background-color: #ffffff !important;
  border-right: 0.1rem solid;
  border-color: #dfe2e7;
}

.sidenav {

  height: 100%;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 16px;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 20px;
  border: 1px;
}

.sidenav a {
  padding: 2px 2px 2px 20px;
  text-decoration: none;
  font-size: 14px;
  color: #252222;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover,
.sidenav a.active {
  background-color: var(--tertia) !important;
  /*background-color: #0d6efd !important;*/
  /*color: var(--light-font) !important;*/
  color: var(--light-font) !important;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 16px;
  margin-left: 50px;
}

.activeParentSideNav {
  color: red;
}

#main {
  transition: margin-left .5s;
  /*padding: 16px;*/
}


.main-color-black {
  color: black;
}

.ulStyle {
  margin-bottom: 0px;
}


</style>
