<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row pl-3">
        <div class="col-sm-6">
          <div class="row">
            <div class="col">
              <div class="row pt-3">
                <div class="col-8 font-weight-bold">
                  <h5>Permanent Address</h5>
                </div>
                <div class="col-4">
                  <!--                  v-if="crewPrmAddress && !isPermanent"-->
                  <button type="button" class="btn btn-xs pmc-btn-warning" v-if="!isPermanent"
                          @click="set_permanent_address">
                    <font-awesome-icon icon="edit"/>
                    Edit
                  </button>

                  <!--                  v-if="isPermanent && crewPrmAddress"-->
                  <button type="button" class="btn btn-xs pmc-btn-success ml-1" v-if="isPermanent && crewPrmAddress"
                          @click="update_crew_address(permanentParam)">
                    <font-awesome-icon icon="save"/>
                    Update
                  </button>

                  <!--                  v-if="!crewPrmAddress && isPermanent"-->
                  <button type="button" class="btn btn-xs pmc-btn-success ml-1" v-if="!crewPrmAddress && isPermanent"
                          @click="save_crew_address(permanentParam)">
                    <font-awesome-icon icon="save"/>
                    Save
                  </button>

                  <button type="button" class="btn btn-xs pmc-btn-danger ml-1" v-if="isPermanent"
                          @click="close_permanent_add">
                    <font-awesome-icon icon="ban"/>
                    Cancel
                  </button>
                </div>
              </div>
              <hr>
              <div class="form-group row">
                <label for="permanent-region" class="col-sm-3 col-form-label">Region</label>
                <!--                {{ crewPrmAddress }}-->
                <span>:</span>
                <div class="col-sm-8">
                  <input type="text"
                         v-if="crewPrmAddress?.region && !isPermanent"
                         readonly disabled
                         class="form-control-plaintext form"
                         id="permanent-region"
                         name="pRegion" placeholder="--"
                         v-model="crewPrmAddress.region.reg_desc">

                  <!--                  v-model="permanentParam.region"-->
                  <multi-select
                    v-if="isPermanent"
                    v-model="permanentParam.region"
                    :options="objRegions"
                    :custom-label="regionNameLang"
                    :select-label="'Select Region'"
                    placeholder="Select Region"
                    label="Region"
                    @input="on_reg_permanent"
                    track-by="reg_desc">
                  </multi-select>

                </div>
              </div>
              <div class="form-group row mt-2">
                <label for="permanent-province" class="col-sm-3 col-form-label">City / Province</label>
                <span>:</span>

                <!--                {{ provinceId }}-->
                <!--                {{ crewPrmAddress.province }}-->
                <!--                                {{ regionId }}-->
                <div class="col-sm-8">
                  <input type="text"
                         v-if="crewPrmAddress?.province && !isPermanent"
                         readonly disabled
                         class="form-control-plaintext form"
                         id="permanent-province"
                         name="pStreet"
                         placeholder="--"
                         v-model="crewPrmAddress.province.prov_desc">

                  <!--                    v-model="permanentParam.province"-->
                  <multi-select
                    v-if="isPermanent"
                    v-model="permanentParam.province"
                    :options="objProvince"
                    :custom-label="provinceNameLang"
                    :select-label="'Select Province'"
                    placeholder="Select Province"
                    label="Province"
                    track-by="id"
                    @input="on_prov_permanent">
                  </multi-select>
                </div>
              </div>


              <div class="form-group row mt-2">
                <label for="permanent-city" class="col-sm-3 col-form-label">City / Municipality</label>
                <span>:</span>
                <div class="col-sm-8">
                  <input
                    v-if="crewPrmAddress?.city && !isPermanent"
                    type="text"
                    readonly disabled
                    class="form-control-plaintext form"
                    id="permanent-city"
                    name="pStreet"
                    placeholder="--"
                    v-model="crewPrmAddress.city.city_desc">

                  <multi-select
                    v-if="isPermanent"
                    v-model="permanentParam.city"
                    :options="objCity"
                    :custom-label="cityNameLang"
                    :select-label="'Select Municipal'"
                    placeholder="Select Municipal"
                    label="Municipal"
                    track-by="id"
                    @input="on_city_permanent">
                  </multi-select>
                </div>
              </div>


              <div class="form-group row mt-2">
                <label for="permanent-barangay" class="col-sm-3 col-form-label">Barangay / Street</label>
                <span>:</span>
                <div class="col-sm-8">
                  <input type="text"
                         v-if="crewPrmAddress?.barangay && !isPermanent"
                         readonly disabled
                         class="form-control-plaintext form"
                         id="permanent-barangay" name="pStreet"
                         placeholder="--"
                         v-model="crewPrmAddress.barangay.brgy_desc">

                  <!--                  {{ objBarangay }}-->

                  <multi-select
                    v-if="isPermanent"
                    v-model="permanentParam.barangay"
                    :options="objBarangay"
                    :custom-label="brgyNameLang"
                    :select-label="'Barangay / Street'"
                    placeholder="Barangay / Street"
                    label="Barangay / Street"
                    @input="on_barangay_permanent"
                    track-by="id">
                  </multi-select>
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="permanent-desc" class="col-sm-3 col-form-label">Description</label>
                <span>:</span>
                <div class="col-sm-8">

                  <!--                  for display only-->
                  <input type="text"
                         v-if="crewPrmAddress && !isPermanent"
                         disabled
                         :class="[isPermanent ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                         placeholder="--"
                         v-model="crewPrmAddress.description">


                  <!--                  for update-->
                  <input type="text"
                         v-if="crewPrmAddress && isPermanent"
                         :class="[isPermanent ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                         id="permanent-desc"
                         name="PermanentAddDesc"
                         placeholder="--"
                         v-model="permanentParam.description">


                  <!--                  for create-->
                  <input type="text"
                         v-if="!crewPrmAddress && isPermanent"
                         :class="[isPermanent ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                         id="permanent-desc"
                         name="PermanentAddDesc"
                         placeholder="--"
                         v-model="permanentParam.description">

                </div>
              </div>

            </div>
          </div>

          <div class="row py-1 mt-3">
            <div class="col">
              <div class="row pt-3">
                <div class="col-8 font-weight-bold">
                  <h5>Temporary Address</h5>
                </div>
                <div class="col-4">
                  <button type="button" class="btn btn-xs pmc-btn-warning" v-if="!isTemporary"
                          @click="set_temporary_address">
                    <font-awesome-icon icon="edit"/>
                    Edit
                  </button>

                  <button type="button" class="btn btn-xs pmc-btn-success ml-1" v-if="isTemporary && crewTempAddress"
                          @click="update_crew_address(temporaryParam)">
                    <font-awesome-icon icon="save"/>
                    Update
                  </button>

                  <button type="button" class="btn btn-xs pmc-btn-success ml-1" v-if="isTemporary && !crewTempAddress"
                          @click="save_crew_address(temporaryParam)">
                    <font-awesome-icon icon="save"/>
                    Save
                  </button>

                  <button type="button" class="btn btn-xs pmc-btn-danger ml-1" v-if="isTemporary"
                          @click="close_temp_add">
                    <font-awesome-icon icon="ban"/>
                    Cancel
                  </button>
                </div>
              </div>
              <hr>

              <div class="form-group row mt-2">
                <label for="temp-region" class="col-sm-3 col-form-label">Region</label>
                <span>:</span>
                <div class="col-sm-8">
                  <input type="text"
                         v-if="crewTempAddress?.region && !isTemporary"
                         readonly disabled
                         class="form-control-plaintext form"
                         id="temp-region"
                         name="pRegion"
                         placeholder="--"
                         v-model="crewTempAddress.region.reg_desc">

                  <multi-select
                    v-if="isTemporary"
                    v-model="temporaryParam.region"
                    :options="objRegions"
                    :custom-label="regionNameLang"
                    :select-label="'Select Region'"
                    placeholder="Select Region"
                    label="Region"
                    @input="on_reg_temp"
                    track-by="reg_desc">
                  </multi-select>

                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="temp-province" class="col-sm-3 col-form-label">City / Province</label>
                <span>:</span>
                <div class="col-sm-8">
                  <input type="text"
                         v-if="crewTempAddress?.province && !isTemporary"
                         readonly disabled
                         class="form-control-plaintext form"
                         id="temp-province" name="pStreet" placeholder="--"
                         v-model="crewTempAddress.province.prov_desc">

                  <multi-select
                    v-if="isTemporary"
                    v-model="temporaryParam.province"
                    :options="objProvince"
                    :custom-label="provinceNameLang"
                    :select-label="'Select Province'"
                    placeholder="Select Province"
                    label="Province"
                    @input="on_prov_temp"
                    track-by="prov_desc">
                  </multi-select>

                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="temp-city" class="col-sm-3 col-form-label">City / Municipality</label>
                <span>:</span>
                <div class="col-sm-8">
                  <input
                    v-if="crewTempAddress?.city && !isTemporary"
                    type="text"
                    readonly disabled class="form-control-plaintext form"
                    id="temp-city"
                    name="pStreet"
                    placeholder="--"
                    v-model="crewTempAddress.city.city_desc">

                  <multi-select
                    v-if="isTemporary"
                    v-model="temporaryParam.city"
                    :options="objCity"
                    :custom-label="cityNameLang"
                    :select-label="'Select City'"
                    placeholder="Select City"
                    label="City"
                    @input="on_city_temp"
                    track-by="city_desc">
                  </multi-select>
                </div>

              </div>

              <div class="form-group row mt-2">
                <label for="temp-barangay" class="col-sm-3 col-form-label">Barangay / Street</label>
                <span>:</span>
                <div class="col-sm-8">
                  <input type="text"
                         v-if="crewTempAddress?.barangay && !isTemporary"
                         readonly disabled
                         class="form-control-plaintext form"
                         id="temp-barangay"
                         name="pStreet"
                         placeholder="--"
                         v-model="crewTempAddress.barangay.brgy_desc">

                  <multi-select
                    v-if="isTemporary"
                    v-model="temporaryParam.barangay"
                    :options="objBarangay"
                    :custom-label="brgyNameLang"
                    :select-label="'Select Barangay'"
                    placeholder="Select Barangay"
                    label="Barangay"
                    @input="on_brgy_temp"
                    track-by="brgy_desc">
                  </multi-select>
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="temp-desc" class="col-sm-3 col-form-label">Description</label>
                <span>:</span>
                <div class="col-sm-8">
                  <!--                  <input type="text"-->
                  <!--                         v-if="crewTempAddress"-->
                  <!--                         :disabled="!isTemporary"-->
                  <!--                         :class="[isTemporary ? 'form-control form-control-sm' : 'form-control-plaintext form']"-->
                  <!--                         id="temp-desc"-->
                  <!--                         name="tempDesc"-->
                  <!--                         placeholder="&#45;&#45;"-->
                  <!--                         v-model="crewTempAddress.description">-->

                  <!--                  for display only-->
                  <input type="text"
                         v-if="crewTempAddress && !isTemporary"
                         disabled
                         :class="[isTemporary ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                         placeholder="--"
                         v-model="crewTempAddress.description">

                  <!--                  for update-->
                  <input type="text"
                         v-if="crewTempAddress && isTemporary"
                         :class="[isTemporary ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                         id="permanent-desc"
                         name="PermanentAddDesc"
                         placeholder="--"
                         v-model="temporaryParam.description">

                  <!--                  for create-->
                  <input type="text"
                         v-if="!crewTempAddress && isTemporary"
                         :class="[isTemporary ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                         id="permanent-desc"
                         name="PermanentAddDesc"
                         placeholder="--"
                         v-model="temporaryParam.description">

                </div>
              </div>

            </div>
          </div>

          <div class="row py-1 mt-3">
            <div class="col">
              <div class="row pt-3">
                <div class="col-8 font-weight-bold">
                  <h5>Provincial Address</h5>
                </div>
                <div class="col-4">
                  <button type="button" class="btn btn-xs pmc-btn-warning" v-if="!isProvince" @click="set_prov_add">
                    <font-awesome-icon icon="edit"/>
                    Edit
                  </button>

                  <button type="button" class="btn btn-xs pmc-btn-success ml-1" v-if="isProvince && crewProvAddress"
                          @click="update_crew_address(provinceParam)">
                    <font-awesome-icon icon="edit"/>
                    Update
                  </button>

                  <button type="button" class="btn btn-xs pmc-btn-success ml-1" v-focus
                          v-if="isProvince && !crewProvAddress" @click="save_crew_address(provinceParam)">
                    <font-awesome-icon icon="save"/>
                    Save
                  </button>

                  <button type="button" class="btn btn-xs pmc-btn-danger ml-1" v-if="isProvince"
                          @click="close_prov_add">
                    <font-awesome-icon icon="ban"/>
                    Cancel
                  </button>
                </div>
              </div>

              <hr>

              <div class="form-group row">
                <label for="prov-region" class="col-sm-3 col-form-label">Region</label>
                <span>:</span>
                <div class="col-sm-8">
                  <input
                    type="text"
                    v-if="crewProvAddress?.region && !isProvince"
                    readonly disabled
                    class="form-control-plaintext form"
                    id="prov-region"
                    name="pRegion"
                    placeholder="--"
                    v-model="crewProvAddress.region.reg_desc">


                  <multi-select
                    v-if="isProvince"
                    v-model="provinceParam.region"
                    :options="objRegions"
                    :custom-label="regionNameLang"
                    :select-label="'Select Region'"
                    placeholder="Select Region"
                    label="Region"
                    @input="on_reg_prov"
                    track-by="regDesc">
                  </multi-select>
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="prov-province" class="col-sm-3 col-form-label">City / Province</label>
                <span>:</span>
                <div class="col-sm-8">
                  <input type="text"
                         v-if="crewProvAddress?.province && !isProvince"
                         readonly disabled
                         class="form-control-plaintext form"
                         id="prov-province"
                         name="pStreet"
                         placeholder="--"
                         v-model="crewProvAddress.province.prov_desc">

                  <multi-select
                    v-if="isProvince"
                    v-model="provinceParam.province"
                    :options="objProvince"
                    :custom-label="provinceNameLang"
                    :select-label="'Select Province'"
                    placeholder="Select Province"
                    label="Province"
                    @input="on_prov_prov"
                    track-by="provDesc">
                  </multi-select>

                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="prov-city" class="col-sm-3 col-form-label">City / Municipality</label>
                <span>:</span>
                <div class="col-sm-8">
                  <input
                    v-if="crewProvAddress?.city && !isProvince"
                    type="text" readonly disabled
                    class="form-control-plaintext form"
                    id="prov-city"
                    name="pStreet"
                    placeholder="--"
                    v-model="crewProvAddress.city.city_desc">

                  <multi-select
                    v-if="isProvince"
                    v-model="provinceParam.city"
                    :options="objCity"
                    :custom-label="cityNameLang"
                    :select-label="'Select City'"
                    placeholder="Select City"
                    label="City"
                    @input="on_city_prov"
                    track-by="cityDesc">
                  </multi-select>
                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="temp-barangay" class="col-sm-3 col-form-label">Barangay / Street</label>
                <span>:</span>
                <div class="col-sm-8">
                  <input type="text"
                         v-if="crewProvAddress?.barangay && !isProvince"
                         readonly disabled
                         class="form-control-plaintext form"
                         id="permanent-barangay" name="pStreet"
                         placeholder="--"
                         v-model="crewProvAddress.barangay.brgy_desc">

                  <multi-select
                    v-if="isProvince"
                    v-model="provinceParam.barangay"
                    :options="objBarangay"
                    :custom-label="brgyNameLang"
                    :select-label="'Select Barangay'"
                    placeholder="Select Barangay"
                    label="Barangay"
                    @input="on_brgy_prov"
                    track-by="brgyDesc">
                  </multi-select>

                </div>
              </div>

              <div class="form-group row mt-2">
                <label for="prov-barangay" class="col-sm-3 col-form-label">Description</label>
                <span>:</span>
                <div class="col-sm-8">

                  <!--                  for display only-->
                  <input type="text"
                         v-if="crewProvAddress && !isProvince"
                         disabled
                         :class="[isProvince ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                         placeholder="--"
                         v-model="crewProvAddress.description">


                  <!--                  for update-->
                  <input type="text"
                         v-if="crewProvAddress && isProvince"
                         :class="[isProvince ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                         id="permanent-desc"
                         name="PermanentAddDesc"
                         placeholder="--"
                         v-model="provinceParam.description">

                  <!--                  for create-->
                  <input type="text"
                         v-if="!crewTempAddress && isProvince"
                         :class="[isProvince ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                         id="permanent-desc"
                         name="PermanentAddDesc"
                         placeholder="--"
                         v-model="provinceParam.description">
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- contact details -->
        <div class="col-sm-6">
          <div class="row">
            <div class="col">
              <div class="form-inline mb-3 pt-3">
                <div class="form-group mb-2 mr-5">
                  <h5>Contact Details</h5>
                </div>
                <!--                <div class="form-group ">-->
                <!--                  <button type="button" class="btn btn-xs pmc-btn-tertia mb-2"-->
                <!--                          data-toggle="modal"-->
                <!--                          data-target="#newCrewContact"-->
                <!--                          data-backdrop="static"-->
                <!--                          data-keyboard="false"-->
                <!--                  >-->
                <!--                    <font-awesome-icon icon="plus"/>-->
                <!--                    Add Contact-->
                <!--                  </button>-->
                <!--                </div>-->
              </div>

              <!--              <hr>-->

              <!--              Add New Crew Contact-->
              <div class="modal fade" id="newCrewContact" tabindex="-1" role="dialog"
                   aria-labelledby="newCrewContactTitle"
                   aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <form class="modal-content" @submit.prevent="">
                    <div class="modal-header">
                      <h5 class="modal-title font-weight-bolder" id="newCrewContactTitle">
                        {{ currentContactType == 1 ? 'New Email Address' : '' }}
                        {{ currentContactType == 2 ? 'New Telephone Contact' : '' }}
                        {{ currentContactType == 3 ? 'New Work Contact' : '' }}
                        {{ currentContactType == 4 ? 'New Personal Contact' : '' }}
                        <!--                        {{ crewContactDetails?.emailAddresses[0]?.contact_type }}-->
                      </h5>
                      <button type="button" id="closeNewCrewContactTitle" class="close"
                              data-dismiss="modal" aria-label="Close" @click="currentContactType = null"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div class="modal-body">

                      <div class="form-group form-row mb-1 mt-2">
                        <label class="col col-sm-3 col-form-label font-weight-bolder text-right" for="date_hired">
                          {{ currentContactType == 1 ? 'Email Address:' : '' }}
                          {{ currentContactType == 2 ? 'Telephone #:' : '' }}
                          {{ currentContactType == 3 ? 'Work #:' : '' }}
                          {{ currentContactType == 4 ? 'Personal #:' : '' }}
                        </label>
                        <div class="col col-sm-6">
                          <input type="text" id="date_hired"
                                 class="form-control form-control-xs"
                                 v-model="newCrewContact.detail"
                          >
                        </div>
                      </div>

                    </div>

                    <div class="modal-footer">
                      <button type="submit" class="btn btn-sm btn-warning font-weight-bolder" @click="save">
                        <span class="fas fa-edit"></span>
                        Save
                      </button>
                    </div>

                  </form>
                </div>
              </div>


              <!--                            View Crew Contact-->
              <div class="modal fade" id="editCrewContact" tabindex="-1" role="dialog"
                   aria-labelledby="editCrewContactTitle"
                   aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <form class="modal-content" @submit.prevent="">
                    <div class="modal-header">
                      <h5 class="modal-title font-weight-bolder" id="editCrewContactTitle">
                        {{ currentContactType == 1 ? 'Edit Email Address' : '' }}
                        {{ currentContactType == 2 ? 'Edit Telephone Contact' : '' }}
                        {{ currentContactType == 3 ? 'Edit Work Contact' : '' }}
                        {{ currentContactType == 4 ? 'Edit Personal Contact' : '' }}
                      </h5>
                      <button type="button" id="closeEditCrewContact" class="close"
                              data-dismiss="modal" aria-label="Close"
                              @click="currentContactType = null"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div class="modal-body">

                      <div class="form-group form-row mb-1 mt-2">
                        <label class="col col-sm-3 col-form-label font-weight-bolder text-right" for="date_hired">
                          {{ currentContactType == 1 ? 'Email Address:' : '' }}
                          {{ currentContactType == 2 ? 'Telephone #:' : '' }}
                          {{ currentContactType == 3 ? 'Work #:' : '' }}
                          {{ currentContactType == 4 ? 'Personal #:' : '' }}
                        </label>
                        <div class="col col-sm-6">
                          <input type="text" id="date_hired"
                                 class="form-control form-control-xs"
                                 v-model="contactViewData.detail"
                          >
                        </div>
                      </div>

                    </div>

                    <div class="modal-footer">
                      <button type="submit" class="btn btn-sm btn-warning font-weight-bolder" @click="update">
                        <span class="fas fa-edit"></span>
                        Update
                      </button>
                    </div>

                  </form>
                </div>
              </div>

              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="emailAddress-tab" data-toggle="tab" href="#crewEmailAddress" role="tab"
                     aria-controls="home" aria-selected="true">Email Addresses</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="workContact-tab" data-toggle="tab" href="#workContact" role="tab"
                     aria-controls="profile" aria-selected="false">Work</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="telephoneContact-tab" data-toggle="tab" href="#telephoneContact" role="tab"
                     aria-controls="contact" aria-selected="false">Telephone</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="personalContact-tab" data-toggle="tab" href="#personalContact" role="tab"
                     aria-controls="contact" aria-selected="false">Personal</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="crewEmailAddress" role="tabpanel" aria-labelledby="home-tab">

                  <div class="form-group mt-3">
                    <button type="button" class="btn btn-xs pmc-btn-tertia mb-2"
                            data-toggle="modal"
                            data-target="#newCrewContact"
                            data-backdrop="static"
                            data-keyboard="false"
                            @click="selectedContactType(1)"
                    >
                      <font-awesome-icon icon="plus"/>
                      Add Email
                    </button>
                  </div>


                  <div class="row mt-2">
                    <div class="col-6">
                      <table class="table table-hover table-bordered table-template-1 text-small sticky-header">
                        <thead>
                        <tr>
                          <th class="col-type-width">Type</th>
                          <th class="col-expense-width">Contact No.</th>
                          <th style="width: 50px;">Action</th>
                        </tr>
                        </thead>
                        <tbody>


                        <tr v-for="(crewContact, index) in crewContactDetails.emailAddresses" :key="crewContact.id">
                          <td>
                            {{ crewContact.contact_type == 1 ? 'Email' : '' }}
                          </td>
                          <td>
                            {{ crewContact.detail }}
                          </td>

                          <td class="text-center">

                            <div class="btn-group" role="group" aria-label="Basic example">
                              <button type="button" class="btn btn-sm pmc-bg-warning border-0 text-left"
                                      v-if="!isPersonalNum"
                                      data-toggle="modal"
                                      data-target="#editCrewContact"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                      @click="editContactDetails(crewContact)"
                              >
                                <font-awesome-icon icon="edit"/>
                              </button>

                              <button type="button" class="btn btn-sm pmc-bg-danger border-0 text-left  ml-1"
                                      @click="deleteContact(crewContact.id, 1)"
                              >
                                <font-awesome-icon icon="ban"/>
                              </button>
                            </div>
                          </td>
                        </tr>

                        <tr
                          v-if="crewContactDetails.emailAddresses ? (crewContactDetails.emailAddresses.length == 0) : '' ">
                          <td colspan="100%" class="bg-light-danger font-weight-bolder">
                            <p class="text-center mt-3">No result detected</p>
                          </td>
                        </tr>

                        </tbody>

                      </table>
                    </div>
                  </div>


                </div>
                <div class="tab-pane fade" id="workContact" role="tabpanel" aria-labelledby="workContact-tab">


                  <div class="form-group mt-3">
                    <button type="button" class="btn btn-xs pmc-btn-tertia mb-2"
                            data-toggle="modal"
                            data-target="#newCrewContact"
                            data-backdrop="static"
                            data-keyboard="false"
                            @click="selectedContactType(3)"
                    >
                      <font-awesome-icon icon="plus"/>
                      Add Work Contact
                    </button>
                  </div>


                  <div class="row mt-3">
                    <div class="col-6">
                      <table class="table table-hover table-bordered table-template-1 text-small sticky-header">
                        <thead>
                        <tr>
                          <th class="col-type-width">Type</th>
                          <th class="col-expense-width">Contact No.</th>
                          <th style="width: 50px;">Action</th>
                        </tr>
                        </thead>
                        <tbody>


                        <tr v-for="(crewContact, index) in crewContactDetails.workContact" :key="crewContact.id">
                          <td>
                            {{ crewContact.contact_type == 3 ? 'Work' : '' }}
                          </td>
                          <td>
                            {{ crewContact.detail }}
                          </td>

                          <td class="text-center">

                            <div class="btn-group" role="group" aria-label="Basic example">
                              <button type="button" class="btn btn-sm pmc-bg-warning border-0 text-left"
                                      v-if="!isPersonalNum"
                                      data-toggle="modal"
                                      data-target="#editCrewContact"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                      @click="editContactDetails(crewContact)"
                              >
                                <font-awesome-icon icon="edit"/>
                              </button>

                              <button type="button" class="btn btn-sm pmc-bg-danger border-0 text-left  ml-1"
                                      @click="deleteContact(crewContact.id, 3)">
                                <font-awesome-icon icon="ban"/>
                              </button>
                            </div>
                          </td>
                        </tr>

                        <tr v-if="crewContactDetails.workContact ? (crewContactDetails.workContact.length == 0) : '' ">
                          <td colspan="100%" class="bg-light-danger font-weight-bolder">
                            <p class="text-center mt-3">No result detected</p>
                          </td>
                        </tr>

                        </tbody>

                      </table>
                    </div>
                  </div>

                </div>
                <div class="tab-pane fade" id="telephoneContact" role="tabpanel" aria-labelledby="telephoneContact-tab">

                  <div class="form-group mt-3">
                    <button type="button" class="btn btn-xs pmc-btn-tertia mb-2"
                            data-toggle="modal"
                            data-target="#newCrewContact"
                            data-backdrop="static"
                            data-keyboard="false"
                            @click="selectedContactType(2)"
                    >
                      <font-awesome-icon icon="plus"/>
                      Add Telephone
                    </button>
                  </div>

                  <div class="row mt-3">
                    <div class="col-6">
                      <table class="table table-hover table-bordered table-template-1 text-small sticky-header">
                        <thead>
                        <tr>
                          <th class="col-type-width">Type</th>
                          <th class="col-expense-width">Contact No.</th>
                          <th style="width: 50px;">Action</th>
                        </tr>
                        </thead>
                        <tbody>


                        <tr v-for="(crewContact, index) in crewContactDetails.telContact" :key="crewContact.id">
                          <td>
                            {{ crewContact.contact_type == 2 ? 'Telephone' : '' }}
                          </td>
                          <td>
                            {{ crewContact.detail }}
                          </td>

                          <td class="text-center">

                            <div class="btn-group" role="group" aria-label="Basic example">
                              <button type="button" class="btn btn-sm pmc-bg-warning border-0 text-left"
                                      v-if="!isPersonalNum"
                                      data-toggle="modal"
                                      data-target="#editCrewContact"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                      @click="editContactDetails(crewContact)"
                              >
                                <font-awesome-icon icon="edit"/>
                              </button>

                              <button type="button" class="btn btn-sm pmc-bg-danger border-0 text-left  ml-1"
                                      @click="deleteContact(crewContact.id, 2)">
                                <font-awesome-icon icon="ban"/>
                              </button>
                            </div>
                          </td>
                        </tr>

                        <tr v-if="crewContactDetails.telContact ? (crewContactDetails.telContact.length == 0) : '' ">
                          <td colspan="100%" class="bg-light-danger font-weight-bolder">
                            <p class="text-center mt-3">No result detected</p>
                          </td>
                        </tr>

                        </tbody>

                      </table>
                    </div>
                  </div>

                </div>

                <div class="tab-pane fade" id="personalContact" role="tabpanel" aria-labelledby="personalContact-tab">

                  <div class="form-group mt-3">
                    <button type="button" class="btn btn-xs pmc-btn-tertia mb-2"
                            data-toggle="modal"
                            data-target="#newCrewContact"
                            data-backdrop="static"
                            data-keyboard="false"
                            @click="selectedContactType(4)"
                    >
                      <font-awesome-icon icon="plus"/>
                      Add Personal
                    </button>
                  </div>

                  <div class="row mt-3">
                    <div class="col-6">
                      <table class="table table-hover table-bordered table-template-1 text-small sticky-header">
                        <thead>
                        <tr>
                          <th class="col-type-width">Type</th>
                          <th class="col-expense-width">Contact No.</th>
                          <th style="width: 50px;">Action</th>
                        </tr>
                        </thead>
                        <tbody>


                        <tr v-for="(crewContact, index) in crewContactDetails.personalContact" :key="crewContact.id">
                          <td>
                            {{ crewContact.contact_type == 4 ? 'Personal' : '' }}
                          </td>
                          <td>
                            {{ crewContact.detail }}
                          </td>

                          <td class="text-center">

                            <div class="btn-group" role="group" aria-label="Basic example">
                              <button type="button" class="btn btn-sm pmc-bg-warning border-0 text-left"
                                      v-if="!isPersonalNum"
                                      data-toggle="modal"
                                      data-target="#editCrewContact"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                      @click="editContactDetails(crewContact)"
                              >
                                <font-awesome-icon icon="edit"/>
                              </button>

                              <button type="button" class="btn btn-sm pmc-bg-danger border-0 text-left  ml-1"
                                      @click="deleteContact(crewContact.id, 4)">
                                <font-awesome-icon icon="ban"/>
                              </button>
                            </div>
                          </td>
                        </tr>

                        <tr v-if="crewContactDetails.telContact ? (crewContactDetails.telContact.length == 0) : '' ">
                          <td colspan="100%" class="bg-light-danger font-weight-bolder">
                            <p class="text-center mt-3">No result detected</p>
                          </td>
                        </tr>

                        </tbody>

                      </table>
                    </div>
                  </div>

                </div>


              </div>


            </div>
          </div>


          <div class="row py-2 pt-4">
            <div class="col">
              <div class="row pt-3">
                <div class="col-8 font-weight-bold">
                  <h5>In Case of Emergency</h5>
                </div>
              </div>

              <hr>

              <div class="form-group row mt-2" v-for="(contact,index) in emergencyContacts" :key="index">
                <label for="crew-telephone-num" class="col-sm-4 col-form-label">{{ contact.name }}</label>
                <span>:</span>
                <div class="col-sm-7">
                  <input type="text"
                         readonly
                         disabled
                         class="form-control-plaintext"
                         id="rel"
                         name="CrewEmail"
                         :value="contact.relation"
                         placeholder="Relation">

                  <input type="text"
                         readonly
                         disabled
                         class="form-control-plaintext mt-2"
                         id="email"
                         name="CrewEmail"
                         :value="contact.email"
                         placeholder="Email --">

                  <input type="text"
                         readonly
                         disabled
                         class="form-control-plaintext mt-2"
                         id="mobile"
                         name="CrewEmail"
                         :value="contact.mobile"
                         placeholder="Mobile # --">


                  <input type="text"
                         readonly
                         disabled
                         class="form-control-plaintext mt-2"
                         id="mobile2"
                         name="CrewEmail"
                         :value="contact.mobile2"
                         placeholder="Mobile #2 --">


                  <input type="text"
                         readonly
                         disabled
                         class="form-control-plaintext mt-2"
                         id="telNum1"
                         name="CrewEmail"
                         :value="contact.telNum"
                         placeholder="Telephone # --">

                  <input type="text"
                         readonly
                         disabled
                         class="form-control-plaintext mt-2"
                         id="telNum2"
                         name="CrewEmail"
                         :value="contact.telNum2"
                         placeholder="Telephone #2 --">
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {AppMixins} from '../../../../../../mixins/app.mixins'
import {AlertMixins} from '../../../../../../mixins/alert.mixins'
import {mapActions, mapGetters} from 'vuex'
import {AlertService} from "@/service/alert.service";

export default {
  name: 'CrewAddressAndContact',
  mixins: [AppMixins, AlertMixins],

  /** data **/
  data() {
    return {
      crewId: parseInt(this.$route.params.crewId, 10),
      isPermanent: false,
      isTemporary: false,
      isProvince: false,

      isEmail: false,
      isTelNum: false,
      isWorkNum: false,
      isPersonalNum: false,

      // permanentParam used to send params to backend for update
      permanentParam: {
        id: null,
        region: null,
        province: null,
        city: null,
        barangay: null,
        description: null,
        addressType: null,
        crewId: this.$route.params.crewId,
      },

      // temporaryParam used to send params to backend for update
      temporaryParam: {
        id: null,
        region: null,
        province: null,
        city: null,
        barangay: null,
        description: null,
        addressType: null,
        crewId: this.$route.params.crewId,
      },

      // provinceParam used to send params to backend for update
      provinceParam: {
        id: null,
        region: null,
        province: null,
        city: null,
        barangay: null,
        description: null,
        addressType: null,
        crewId: this.$route.params.crewId,
      },

      deleteCrewContactParams: {
        region: null,
        province: null,
      },

      sampleId: 2,

      contactType: [1, 2, 3, 4],

      contactViewtoContactData: {},
      contactViewData: {},

      newCrewContact: {},

      sampleProvince: {
        "id": 6,
        "provDesc": "CAGAYAN",
        "regCode": "02",
        "provCode": "0215"
      },

      currentContactType: null,


      contactTypes: [
        {id: 1, name: "email"},
        {id: 2, name: "work"},
        {id: 3, name: "personal"},
        {id: 4, name: "telephone"},
      ],

      crewAddressRegion: {},

    }
  },

  /** methods **/
  methods: {
    ...mapActions(
      [
        // 'viewCrewContacts',

        'getContactTypes',
        'viewAddressContacts',
        'setPerAddrNull',
        'setTempAddrNull',
        'setProvAddrNull',
        'null_obj_regions',
        'null_obj_province',
        'null_obj_cities',
        'null_obj_barangay',
        'updateCrewAddress',
        'getRegionKeys',
        'getProvinceKeys',
        'getCityKeys',
        'getBarangayKeys',
        'saveCrewAddress',
        'saveCrewContact',
        'updateCrewContact',
        'setNullNewPermanentAdd',
        'setNullNewTempAdd',
        'setNullNewProvAdd',
        'createCrewContact',
        'editCrewContact',
        'deleteCrewContact',

        'viewCrewContactDetails',

      ],
    ),

    selectedContactType(contactType) {
      this.currentContactType = contactType;
    },

    editContactDetails(crewContact) {
      this.currentContactType = crewContact.contact_type;
      this.contactViewtoContactData = crewContact
    },

    crew_address_contact() {
      this.viewAddressContacts(this.crewId)
    },

    update_crew_address(params) {

      // let params = {
      //   id: crewPrmAddress.id,
      //   region_id: this.permanentParam.region ? this.permanentParam.region.id  : '',
      //   province_id: this.permanentParam.province ? this.permanentParam.province.id : '',
      //   city_id: this.permanentParam.city ? this.permanentParam.city.id : '',
      //   barangay_id: this.permanentParam.barangay ? this.permanentParam.barangay.id : '',
      //   description: this.crewPrmAddress.description,
      // };

      this.updateCrewAddress(params).then((r) => {
        if (r) {
          let title = null;
          switch (params.addressType) {
            case 1:
              this.isPermanent = false
              this.resetPermanentParam()
              this.crew_address_contact()
              title = 'Permanent';
              break

            case 2:
              this.isTemporary = false
              this.resetTemporaryParam();
              this.crew_address_contact()
              title = 'Temporary';
              break

            case 3:
              this.isProvince = false;
              this.resetProvinceParam();
              this.crew_address_contact()
              title = 'Province';
              break

            default:
              break
          }
          this.successAlert('Crew address successfully updated', title);
        }
      })
    },

    save_crew_address(param) {
      // do not delete; for reference
      // this.saveCrewAddress(this.deCapitalizeProperties(param));
      this.saveCrewAddress(this.deCapitalizeProperties(param)).then((r) => {
        if (r) {
          let title = null;
          switch (param.addressType) {
            case 1:
              this.isPermanent = false
              title = 'Permanent';
              break

            case 2:
              this.isTemporary = false
              title = 'Temporary';
              break

            case 3:
              this.isProvince = false;
              title = 'Province';
              break

            default:
              break
          }
          this.successAlert('Crew address successfully added', title);
        }
      });


    },

    set_permanent_address() {
      // params sent to permanentParam for update are the ff:
      // id, region, province, city, barangay, description, addressType
      this.isPermanent = !this.isPermanent

      //set value of permanentParam.description, id data from axios
      this.permanentParam.id = this.crewPrmAddress?.id

      // set value to empty so it would not return an array to conversion error
      this.permanentParam.description = this.crewPrmAddress?.description ? this.crewPrmAddress?.description : ''
      //

      this.permanentParam.addressType = 1 // set address type to 1

      // permanentParam.region, province, city, barangay is the v-model to multi select
      // crewPrmAddress.region, province, city, barangay is the value of input from api
      // sets value in multi select when edit is true
      this.permanentParam.region = this.crewPrmAddress?.region
      this.permanentParam.province = this.crewPrmAddress?.province
      this.permanentParam.city = this.crewPrmAddress?.city
      this.permanentParam.barangay = this.crewPrmAddress?.barangay
      //

    },

    set_temporary_address() {
      // params sent to temporaryParam for update are the ff:
      // id, region, province, city, barangay, description, addressType
      this.isTemporary = !this.isTemporary


      this.temporaryParam.id = this.crewTempAddress?.id

      // set value to empty so it would not return an array to conversion error
      this.temporaryParam.description = this.crewTempAddress?.description ? this.crewTempAddress?.description : ''

      this.temporaryParam.addressType = 2

      this.temporaryParam.region = this.crewTempAddress?.region
      this.temporaryParam.province = this.crewTempAddress?.province
      this.temporaryParam.city = this.crewTempAddress?.city
      this.temporaryParam.barangay = this.crewTempAddress?.barangay


    },

    set_prov_add() {
      // params sent to temporaryParam for update are the ff:
      // id, region, province, city, barangay, description, addressType
      this.isProvince = !this.isProvince

      this.provinceParam.id = this.crewProvAddress?.id

      // set value to empty so it would not return an array to conversion error
      this.provinceParam.description = this.crewProvAddress?.description ? this.crewProvAddress?.description : ''

      this.provinceParam.addressType = 3

      this.provinceParam.region = this.crewProvAddress?.region
      this.provinceParam.province = this.crewProvAddress?.province
      this.provinceParam.city = this.crewProvAddress?.city
      this.provinceParam.barangay = this.crewProvAddress?.barangay

    },

    save_or_update_contact(param = null, type = null) {
      if (param) {
        if (param.id) {
          this.updateCrewContact(param).then((r) => {
            if (r) {
              this.successAlert(`Update successfully`, `${type}`);
            }
          });
        } else {
          this.saveCrewContact(param).then((r) => {
            if (r) {
              this.successAlert(`Save successfully`, `${type}`);
            }
          });
        }
      }
    },

    /** set email  **/
    set_email() {
      this.isEmail = !this.isEmail;
    },

    save_email(data = null) {
      this.save_or_update_contact(data, 'Email Detail')
      this.isEmail = false;
    },

    close_email() {
      if (this.isEmail) {
        this.isEmail = false;
        this.crew_address_contact();
      }
    },

    /** set tel num  **/
    set_tel_num() {
      this.isTelNum = !this.isTelNum;
    },

    save_tel_num(data = null) {
      this.save_or_update_contact(data, 'Telephone Number');
      this.isTelNum = false;
    },

    close_tel_num() {
      if (this.isTelNum) {
        this.isTelNum = false;
        this.crew_address_contact();
      }
    },

    /** set work num **/
    set_work_num() {
      this.isWorkNum = !this.isWorkNum;
    },

    save_work_num(param = null) {
      this.save_or_update_contact(param);
    },

    close_work_num() {
      if (this.isWorkNum) {
        this.isWorkNum = false;
        this.crew_address_contact();
      }
    },
    /** end work num **/

    /** set personal num  **/
    set_personal_num() {
      this.isPersonalNum = !this.isPersonalNum;
    },

    save_personal_num(param = null) {
      this.save_or_update_contact(param, 'Personal Number');
      this.isPersonalNum = false;
    },

    close_personal_num() {
      if (this.isPersonalNum) {
        this.isPersonalNum = false;
        this.crew_address_contact();
      }
    },
    /** end personal num  **/


    close_permanent_add() {
      if (this.isPermanent) {
        this.isPermanent = false
        this.setPerAddrNull()
        this.crew_address_contact()
        this.resetPermanentParam()
        this.null_obj_province()
        this.null_obj_cities()
        this.null_obj_barangay()
        this.setNullNewPermanentAdd();
      }
    },

    close_temp_add() {
      if (this.isTemporary) {
        this.isTemporary = false
        this.setTempAddrNull();
        this.crew_address_contact()
        this.resetTemporaryParam()
        this.null_obj_province()
        this.null_obj_cities()
        this.null_obj_barangay()
        this.setNullNewTempAdd()
      }
    },

    close_prov_add() {
      if (this.isProvince) {
        this.isProvince = false
        this.setProvAddrNull()
        this.crew_address_contact()
        this.resetProvinceParam()
        this.null_obj_province()
        this.null_obj_cities()
        this.null_obj_barangay()
        this.setNullNewProvAdd()
      }
    },

    regionNameLang({reg_code, reg_desc,}) {
      return `${reg_code} - ${reg_desc}`
    },

    provinceNameLang({prov_desc}) {
      return `${prov_desc}`
    },

    cityNameLang({city_desc}) {
      return `${city_desc}`
    },

    brgyNameLang({brgy_desc}) {
      return `${brgy_desc}`
    },

    on_reg_permanent() {
      this.permanentParam.province = null
      this.permanentParam.city = null
      this.permanentParam.barangay = null

      this.resetOnRegion();

      if (this.permanentParam.region != null) {
        this.getProvinceKeys(this.permanentParam.region)
      }

      // if (this.crewPrmAddress && this.permanentParam.region) {
      //   this.crewPrmAddress.region = this.permanentParam.region.regDesc
      // }

      // /** new record **/
      // if (!this.crewPrmAddress && this.permanentParam.region) {
      //   this.newPrmAddress.region = this.permanentParam.region.regDesc
      // }

    },

    on_prov_permanent() {
      this.permanentParam.city = null
      this.permanentParam.barangay = null
      this.null_obj_cities();

      if ((this.permanentParam.region && this.permanentParam.province) != null) {
        this.getCityKeys(this.permanentParam.province)
      }

      // if (this.crewPrmAddress && this.permanentParam.province) {
      //   this.crewPrmAddress.province = this.permanentParam.province.provDesc
      // }

      // /** new record **/
      // if (!this.crewPrmAddress && this.permanentParam.province) {
      //   this.crewPrmAddress.province = this.permanentParam.province.provDesc
      // }

    },

    on_city_permanent() {
      this.permanentParam.barangay = null
      this.null_obj_barangay();

      if ((this.permanentParam.region && this.permanentParam.province && this.permanentParam.city) != null) {
        this.getBarangayKeys(this.permanentParam.city)
      }

      // if (this.crewPrmAddress && this.permanentParam.city) {
      //   this.crewPrmAddress.city = this.permanentParam.city.cityDesc
      // }
      //
      // /** new record **/
      // if (!this.crewPrmAddress && this.permanentParam.city) {
      //   this.newPrmAddress.city = this.permanentParam.city.cityDesc
      // }
    },

    on_barangay_permanent() {
      if (this.crewPrmAddress && this.permanentParam.barangay != null) {
        this.crewPrmAddress.barangay = this.permanentParam.barangay.brgyDesc
      }

      // if (!this.crewPrmAddress && this.permanentParam.barangay != null) {
      //   this.newPrmAddress.barangay = this.permanentParam.barangay.brgyDesc
      // }
    },


    on_reg_temp() {
      this.temporaryParam.province = null
      this.temporaryParam.city = null
      this.temporaryParam.barangay = null
      this.resetOnRegion();

      if (this.temporaryParam.region != null) {
        this.getProvinceKeys(this.temporaryParam.region);
      }

      if (this.crewTempAddress && this.temporaryParam.region != null) {
        this.crewTempAddress.region = this.temporaryParam.region.regDesc;
      }

      /** new record **/
      if (this.crewTempAddress && this.temporaryParam.region != null) {
        this.newTempAddress.region = this.temporaryParam.region.regDesc;
      }
    },

    on_prov_temp() {
      this.temporaryParam.city = null
      this.temporaryParam.barangay = null

      this.null_obj_cities()  /** reset every changes **/

      if ((this.temporaryParam.region && this.temporaryParam.province) != null) {
        this.getCityKeys(this.temporaryParam.province)
      }

      if (this.crewTempAddress && this.temporaryParam.province) {
        this.crewTempAddress.province = this.temporaryParam.province.provDesc;
      }

      /** new record **/
      if (!this.crewTempAddress && this.temporaryParam.province) {
        this.newTempAddress.province = this.temporaryParam.province.provDesc;
      }
    },

    on_city_temp() {
      this.temporaryParam.barangay = null
      this.null_obj_barangay()

      if ((this.temporaryParam.region && this.temporaryParam.province && this.temporaryParam.city) != null) {
        this.getBarangayKeys(this.temporaryParam.city)
      }

      if (this.crewTempAddress && this.temporaryParam.city != null) {
        this.crewTempAddress.city = this.temporaryParam.city.cityDesc
      }

      /** new record **/
      if (!this.crewTempAddress && this.temporaryParam.city != null) {
        this.newTempAddress.city = this.temporaryParam.city.cityDesc
      }
    },

    on_brgy_temp() {
      if (this.crewTempAddress && this.temporaryParam.barangay != null) {
        this.crewTempAddress.barangay = this.temporaryParam.barangay.brgyDesc;
      }

      /** new record **/
      if (!this.crewProvAddress && this.temporaryParam.barangay) {
        this.newTempAddress.barangay = this.temporaryParam.barangay.brgyDesc;
      }
    },

    on_reg_prov() {
      this.provinceParam.province = null;
      this.provinceParam.city = null;
      this.provinceParam.barangay = null;

      this.resetOnRegion();

      if ((this.crewProvAddress && this.provinceParam.region) != null) {
        this.crewProvAddress.region = this.provinceParam.region.regDesc;
      }

      if (this.provinceParam.region != null) {
        this.getProvinceKeys(this.provinceParam.region);
      }

      /**  new record **/
      if (!this.crewProvAddress) {
        this.newProvAddress.region = this.provinceParam.region ? this.provinceParam.region.regDesc : null;
      }
    },

    on_prov_prov() {
      this.provinceParam.city = null;
      this.provinceParam.barangay = null;

      this.null_obj_cities();

      if (this.crewProvAddress && this.provinceParam.province) {
        this.crewProvAddress.province = this.provinceParam.province.provDesc;
      }

      if ((this.provinceParam.region && this.provinceParam.province) != null) {
        this.getCityKeys(this.provinceParam.province);
      }

      /**  new record **/
      if (!this.crewProvAddress && this.provinceParam.province) {
        this.newProvAddress.province = this.provinceParam.province ? this.provinceParam.province.provDesc : null;
      }
    },

    on_city_prov() {
      this.provinceParam.barangay = null;
      this.null_obj_barangay();

      if ((this.provinceParam.region && this.provinceParam.province && this.provinceParam.city) != null) {
        this.getBarangayKeys(this.provinceParam.city);
      }

      if (this.crewProvAddress && this.provinceParam.city) {
        this.crewProvAddress.city = this.provinceParam.city.cityDesc;
      }

      /**  new record **/
      if (!this.crewProvAddress && this.provinceParam.city) {
        this.newProvAddress.city = this.provinceParam.city ? this.provinceParam.city.cityDesc : null;
      }
    },

    on_brgy_prov() {
      if (this.crewProvAddress && this.provinceParam.barangay != null) {
        this.crewProvAddress.barangay = this.provinceParam.barangay.brgyDesc;
      }

      if (!this.crewProvAddress && this.provinceParam.barangay) {
        this.newProvAddress.barangay = this.provinceParam.barangay.brgyDesc;
      }
    },

    resetPermanentParam() {
      this.permanentParam = {
        region: null,
        province: null,
        city: null,
        barangay: null,
        description: null,
      }
    },

    resetTemporaryParam() {
      this.temporaryParam = {
        region: null,
        province: null,
        city: null,
        barangay: null,
        description: null,
      }
    },

    resetProvinceParam() {
      this.provinceParam = {
        region: null,
        province: null,
        city: null,
        barangay: null,
        description: null,
      }
    },

    resetContactDetails() {
      //todo
    },

    reset() {
      this.setPerAddrNull()
      this.setTempAddrNull()
      this.setProvAddrNull()
    },

    resetOnRegion() {
      this.null_obj_province();
      this.null_obj_cities();
      this.null_obj_barangay();
    },

    resetObjKeys() {
      this.resetPermanentParam();
      this.resetTemporaryParam();
      this.resetProvinceParam();
      this.null_obj_regions();
      this.null_obj_province();
      this.null_obj_cities();
      this.null_obj_barangay();
    },

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Crew Contact', 'question')
      if (await __create) {

        let params = {
          detail: this.newCrewContact.detail ? this.newCrewContact.detail : '',
          crew_id: this.$route.params.crewId,
          contact_type: this.currentContactType,
        };

        let newContact = await this.createCrewContact(params);
        if (newContact) {
          await AlertService.successAlertService('Create New Contact record successful', 'Create Contact');
          this.hideModal('newCrewContact')
          this.newCrewContact = {};
          // this.set_principals();
        }
      }
    },

    async deleteContact(contactId, contactType) {
      if (await this.questionAlert('Are you sure you want to delete this record.', 'Delete Crew Contact')) {
        let params = {
          contactId: contactId,
          contact_type: contactType,
        };

        this.deleteCrewContact(params).then((r) => {
          if (r) {
            AlertService.successAlertService("Deleted successfully", 'Delete Crew Contact');
          }
        })
      }
    },


    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update crew contact', 'question');
      if (await __update) {
        let params = {

          id: this.contactViewData.id,
          detail: this.contactViewData.detail ? this.contactViewData.detail : '',
          crew_id: this.$route.params.crewId,
          contact_type: this.currentContactType,

        };

        let updatedRank = await this.editCrewContact(params);
        if (updatedRank) {
          await AlertService.successAlertService("Updated successfully", 'Update crew contact');
          this.hideModal('editCrewContact')
        }
      }

    },


  },

  /** computed **/
  computed: {
    ...mapGetters(
      [
        'crewPrmAddress',
        'crewTempAddress',
        'crewProvAddress',
        'objRegions',
        'objProvince',
        'objCity',
        'objBarangay',
        'newPrmAddress',
        'newTempAddress',
        'newProvAddress',
        'contactDetails',
        'emergencyContacts',
        'crewContactTypes',

        // 'crewContacts',
        'crewContactDetails',
      ],
    ),


    // regionId: {
    //   get() {
    //     let obj = null
    //     if (this.crewPrmAddress.region != null) {
    //       obj = this.objRegions.find((vac) => vac.id === this.crewPrmAddress.region.id)
    //     }
    //     return obj
    //   },
    //   // set(val) {
    //   //   this.crewPrmAddress.region.id = val ? val.id : null
    //   // },
    // },
    //
    // provinceId: {
    //   get() {
    //     // let obj = null
    //     // if (this.crewPrmAddress.province != null) {
    //     //   obj = this.objProvince.find((vac) => vac.id === this.crewPrmAddress.province.id)
    //     // }
    //     return this.objProvince.find((vac) => vac.id === this.crewPrmAddress.province.id)
    //   },
    //   // set(val) {
    //   //   this.crewPrmAddress.province.id = val ? val.id : null
    //   // },
    // },

  },

  /** mounted **/
  mounted() {
  },

  watch: {
    'contactViewtoContactData'() {
      this.contactViewData = Object.assign({}, this.contactViewtoContactData);
    },

    // 'regionId'(){
    //   if (this.crewPrmAddress.region != null){
    //     this.getProvinceKeys(this.crewPrmAddress.province)
    //   }
    //
    // },

    'permanentParam.region'() {
      if (this.permanentParam.region != null) {
        this.getProvinceKeys(this.permanentParam.region)
      }

      if ((this.permanentParam.region && this.permanentParam.province) != null) {
        this.getCityKeys(this.permanentParam.province)
        this.getBarangayKeys(this.permanentParam.barangay);
      }

    },

    'temporaryParam.region'() {
      if (this.temporaryParam.region != null) {
        this.getProvinceKeys(this.temporaryParam.region)
      }

      if ((this.temporaryParam.region && this.temporaryParam.province) != null) {
        this.getCityKeys(this.temporaryParam.province)
        this.getBarangayKeys(this.temporaryParam.barangay);
      }

    },

    'provinceParam.region'() {
      if (this.provinceParam.region != null) {
        this.getProvinceKeys(this.provinceParam.region)
      }

      if ((this.provinceParam.region && this.provinceParam.province) != null) {
        this.getCityKeys(this.provinceParam.province)
        this.getBarangayKeys(this.provinceParam.barangay);
      }

    },


  },

  /** created **/
  created() {
    this.getRegionKeys();
    this.crew_address_contact()
    // this.viewCrewContacts(this.$route.params.crewId);
    this.viewCrewContactDetails(this.$route.params.crewId);
    this.getContactTypes();
  },

  destroyed() {
    this.reset()
    this.resetObjKeys();
  },

}
</script>

<style scoped>

</style>
