<template>
  <div>
    <div class="container-fluid">
      <div class="row py-3">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-lg-4">
                  <div class="text-left">
                    <h3>MY PROFILE</h3>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <!-- auth profile ,optional -->
                <div class="col-md-2">
                  <div id="crew-img-container">
<!--                    <img id="crew-img" :src="sampleImage" alt="crewImage" class="img-fluid">-->
                    <img id="crew-img" :src="image" alt="crewImage" class="img-fluid">
                    <div class="text-center mt-1">
                      <div role="group" aria-label="Basic example" class="btn-group-sm">
                        <button type="button"
                                class="btn btn-lg border-0 mt-1 text-left"
                                :class="{'btn-outline-warning' : authProfileDetails.auth_image, 'btn-outline-secondary' : !authProfileDetails.auth_image}"
                                data-toggle="modal"
                                data-target="#uploadImageModal"
                                data-backdrop="static"
                              data-keyboard="false">
                          <font-awesome-icon icon="upload"/>
                          {{authProfileDetails.auth_image ? 'UPDATE' : 'UPLOAD'}}
                        </button>

                        <button type="button"
                                class="btn btn-lg  btn-outline-danger border-0 text-left mt-1 ml-4"
                                @click="delete_employee_image">
                          <font-awesome-icon icon="trash"/>
                          DELETE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 text-left">
                  <!-- last name -->
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label">LAST NAME</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text"
                             class="form-control form-control-plaintext"
                             placeholder="---"
                             v-model="authProfileDetails.last_name"
                             readonly
                             disabled
                      />
                    </div>
                  </div>

                  <!-- first name -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-4 col-form-label">FIRST NAME</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text"
                             class="form-control form-control-plaintext"
                             placeholder="---"
                             v-model="authProfileDetails.first_name"
                             readonly
                             disabled
                      />
                    </div>
                  </div>

                  <!-- middle name -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-4 col-form-label">MIDDLE NAME</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text"
                             class="form-control form-control-plaintext"
                             placeholder="---"
                             v-model="authProfileDetails.middle_name"
                             readonly
                             disabled
                      />
                    </div>
                  </div>

                  <!-- suffix  name -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-4 col-form-label">SUFFIX NAME</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text"
                             class="form-control form-control-plaintext"
                             placeholder="---"
                             v-model="authProfileDetails.suffix_name"
                             readonly
                             disabled
                      />
                    </div>
                  </div>

                  <!-- birthdate -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-4 col-form-label">BIRTHDATE</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-plaintext"
                             v-model="authProfileDetails.birthdate"
                             readonly
                             disabled
                             placeholder="---"/>
                    </div>
                  </div>

                  <div class="form-group row mt-2">
                    <label class="col-sm-4 col-form-label">CIVIL STATUS</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-plaintext"
                             v-model="authProfileDetails.civil_status"
                             v-if="authProfileDetails.civil_status"
                             readonly
                             disabled
                             placeholder="---"/>
                    </div>
                  </div>
                </div>

                <div class="border d-none d-sm-block"></div>

                <div class="col-md-4  text-left ml-4">
                  <div class="form-group row mt-2">
                    <label class="col-sm-4 col-form-label">MOBILE <sup class="ml-2 text-muted">primary</sup></label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-6">
                      <input type="text" class="form-control form-control-plaintext"
                             v-model="authProfileDetails.mobile_no_1"
                             v-show="authProfileDetails.mobile_no_1"
                             readonly
                             disabled
                             placeholder="---"/>
                    </div>
                  </div>

                  <div class="form-group row mt-2">
                    <label class="col-sm-4  col-form-label">MOBILE <sup class="ml-2 text-muted">secondary</sup></label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-6">
                      <input type="text" class="form-control form-control-plaintext"
                             v-model="authProfileDetails.mobile_no_2"
                             v-show="authProfileDetails.mobile_no_2"
                             readonly
                             disabled
                             placeholder="---"/>
                    </div>
                  </div>

                  <div class="form-group row mt-2">
                    <label class="col-sm-4 col-form-label">LANDLINE <sup class="ml-2 text-muted">primary</sup></label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-plaintext"
                             v-model="authProfileDetails.landline_1"
                             v-show="authProfileDetails.landline_1"
                             readonly
                             disabled
                             placeholder="---"/>
                    </div>
                  </div>

                  <div class="form-group row mt-2">
                    <label class="col-sm-4 col-form-label">LANDLINE <sup class="ml-2 text-muted">secondary</sup></label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-plaintext"
                             v-model="authProfileDetails.landline_2"
                             v-show="authProfileDetails.landline_2"
                             readonly
                             disabled
                             placeholder="---"/>
                    </div>
                  </div>

                  <div class="form-group row mt-2">
                    <label class="col-sm-4  col-form-label">EMAIL <sup class="ml-2 text-muted">primary</sup></label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-plaintext"
                             v-model="authProfileDetails.email_1"
                             v-show=" authProfileDetails.email_1"
                             readonly
                             disabled
                             placeholder="---"/>
                    </div>
                  </div>

                  <div class="form-group row mt-2">
                    <label class="col-sm-4  col-form-label">EMAIL<sup class="ml-2 text-muted">secondary</sup></label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text" class="form-control form-control-plaintext"
                             v-model="authProfileDetails.email_2"
                             v-show="authProfileDetails.email_2"
                             readonly
                             disabled
                             placeholder="---"/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- todo Modal -->
      <div class="modal fade" id="uploadImageModal" tabindex="-1" role="dialog" aria-labelledby="uploadImageModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="uploadImageModalLabel">Upload Image</h5>
            </div>
            <div class="modal-body">
              <!-- upload image -->
              <div class="form-group row mt-2">
                <label class="col-sm-3 col-form-label">Upload Image</label>
                <span class="col-form-label">:</span>
                <div class="col-sm-7">
                  <input type="file"
                         class="form-control-file"
                         @change="on_chang_upload_image"
                         id="employee-image"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="on_close_upload_image">Close</button>
              <button type="button" class="btn btn-primary" @click="upload_employee_image">Save changes</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { AppMixins } from '../mixins/app.mixins'
import { AlertMixins } from '../mixins/alert.mixins'
import { mapActions, mapGetters } from 'vuex'

import noPhotoBlob from '../assets/noPhoto.jpg';

export default {
  name: 'AuthProfile',
  mixins: [AppMixins, AlertMixins],

  /** data **/
  data () {
    return {
      noPhoto : noPhotoBlob,
    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'getAuthProfile',
      'getAuthUser',
      'uploadAuthImage',
      'deleteAuthImage',
      'null_auth_details',
    ]),

    upload_employee_image(){
      let imageBlob = document.getElementById('employee-image').files[0];

      const formData = new FormData()
      formData.append('employee_id',this.authUser.id)
      formData.append('file',imageBlob);

      this.uploadAuthImage(formData).then((r)=>{
        if(r){
          this.successAlert("Image upload successfully",'Upload image')
          this.hideModal("uploadImageModal");
        }
      });
    },

   async delete_employee_image(){
      if(await this.questionAlert("Are you sure you want to delete this \n profile picture you have.",'Delete profile','warning')){
          await this.deleteAuthImage(this.authUser.id);
      }
    },

    //on close
    on_close_upload_image(){
    },

    on_chang_upload_image(e){
      let val = e.target.value;
      if(val != undefined){
        return;
      }else {
        $(`#employee-image`).val('');
      }
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters([
      'authProfileDetails',
      'authUser',
      'authImg',
    ]),

    image : {
      get(){
        return this.authProfileDetails.auth_image ? this.authProfileDetails.auth_image : this.noPhoto;
      },
    }
  },

  /** mounted **/
  mounted () {
  },

  /** created **/
 async created () {
  //this.getAuthUser()
   await this.getAuthProfile(this.authUser.id)
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed () {
    this.null_auth_details();
  },
}
</script>

<style scoped>
#crew-img-container {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

#crew-img {
  width: 200px;
  height: 200px;
}

.crew-img {
  width: 200px;
  height: 200px;
}
</style>
