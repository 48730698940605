<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5 mt-2">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Religions</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newReligionModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Religion
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search religion name"
                v-model="religionFormParam.searchValue"
                @keypress.enter="searchReligion"
              >

              <button class="btn btn-sm pmc-btn-prime" @click="searchReligion">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

                    <div class="col-sm-2">
                      <div class="form-group row">
                        <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
                        <span class="col-form-label">:</span>
                        <div class="col-sm-7 text-left">
                          <select name="listCount" id="list-stat" class="form-control custom-select-sm"
                                  v-model="religionFormParam.status"
                                  @change="on_change_status">
                            <option :value="null">--select--</option>
                            <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                              {{ status.placeholder }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="religionFormParam.count"
                        @change="on_change_count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="religionPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="4%">CODE</th>
                <th scope="row" class="align-middle" width="7%">NAME</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>AT</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>AT</th>
                <th scope="row" class="align-middle" width="7%">STATUS</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-if = "religions.length === 0">
                <td colspan="9">No Results</td>
              </tr>
              <tr v-else v-for="(religion, index) in religions" :key="religion.id">
                <td>{{ index + religionPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ religion.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ religion.name }}</td>
                <td scope="col" class="p-0 text-center">{{ religion.created_by }}</td>
                <td scope="col" class="p-0 text-center">{{ religion.updated_by }}</td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ religion.created_at }}
                </td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ religion.updated_at }}
                </td>
                <td scope="col" class="p-0 text-center">
                  {{ religion.status == 1 ? 'ACTIVE' : 'INACTIVE' }}
                </td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewReligionModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="religionViewtoReligionData = religion">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_religion(religion.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new religion modal   -->
    <div class="modal fade" id="newReligionModal" tabindex="-1" role="dialog" aria-labelledby="newPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newPrincipalModalLabel">NEW RELIGION</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newReligion.code"
                    />
                  </div>
                </div>

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newReligion.name"
                    />
                  </div>
                </div>

                <!--                &lt;!&ndash; new  address&ndash;&gt;-->
                <!--                <div class="form-group row mt-2">-->
                <!--                  <label class="col-sm-3 col-form-label">DESCRIPTION</label>-->
                <!--                  <span class="col-form-label">:</span>-->
                <!--                  <div class="col-sm-8">-->
                <!--                    <input-->
                <!--                      type="text"-->
                <!--                      class="form-control form-control-sm"-->
                <!--                      placeholder="-&#45;&#45;"-->
                <!--                    />-->
                <!--                  </div>-->
                <!--                </div>-->


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newReligion.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view religion route   -->
    <div class="modal fade" id="viewReligionModal" tabindex="-1" role="dialog"
         aria-labelledby="viewPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewPrincipalModalLabel">RELIGION</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="religionData.code"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="religionData.name"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!--                &lt;!&ndash; alias &ndash;&gt;-->
                <!--                <div class="form-group row mt-2">-->
                <!--                  <label class="col-sm-3 col-form-label">DESCRIPTION</label>-->
                <!--                  <span class="col-form-label">:</span>-->
                <!--                  <div class="col-sm-8">-->
                <!--                    <input type="text"-->
                <!--                           :disabled="!isEditable"-->
                <!--                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"-->
                <!--                           class="form-control form-control-sm"-->
                <!--                           placeholder="-&#45;&#45;"-->
                <!--                    />-->
                <!--                  </div>-->
                <!--                </div>-->


                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           class="form-check form-check-inline ml-2 mt-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                           v-model="religionData.status"
                    />
                  </div>
                </div>


                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CREATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="religionData.created_by"
                    />
                  </div>
                </div>

                <!-- updated by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">UPDATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="religionData.updated_by"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit"
            >
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel"
            >
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";

export default {
  name: "List",


  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      religionViewtoReligionData: {},
      religionData: {},
      newReligion: {},
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,

    };
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_religions',
      'create_religion',
      'update_religion',
      'delete_religion',
      'null_religions',
    ]),

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Religion', 'question')
      if (await __create) {

        let params = {
          code: this.newReligion.code ? this.newReligion.code : '',
          name: this.newReligion.name, // this is required
          status: this.newReligion.status ? 1 : 0,
        };

        let newFlag = await this.create_religion(params);
        if (newFlag) {
          AlertService.successAlertService('Create religion record successful', 'Create Religion');
          this.hideModal('newReligionModal');
          this.newReligion = {};
          this.set_religions();
        }
      }
    },

    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update Religion', 'question');
      if (await __update) {
        let params = {
          id: this.religionData.id,
          code: this.religionData.code ? this.religionData.code : '',
          name: this.religionData.name, // this is required
          status: this.religionData.status ? 1 : 0,

        };

        let updatedReligion = await this.update_religion(params);
        if (updatedReligion) {
          AlertService.successAlertService("Updated successfully", 'Update flag');
          this.hideModal('viewReligionModal')
          this.isEditable = false
        }
      }

    },

    set_this_page(e) {
      this.religionFormParam.searchValue = ''
      this.religionPagination.currentPage = e
      this.religionFormParam.page = e
      this.pageLastLeft = e
      this.set_religions(this.religionFormParam)

      // this.religionFormParam.currentPage = e;
      // this.religionFormParam.page = e;
      // this.set_religions(this.religionFormParam);
    },

    searchReligion() {
      if (this.religionFormParam.searchValue === '') {
        this.religionFormParam.page = this.pageLastLeft
        this.set_religions(this.religionFormParam)
      }

      if (this.religionFormParam.searchValue) {
        this.religionFormParam.page = 1
      }

      this.set_religions(this.religionFormParam)

      // this.religionFormParam.page = 1;
      // this.set_religions(this.religionFormParam);
    },

    on_change_status(e) {
      this.religionFormParam.page = 1
      this.religionFormParam.status = e.target.value;
      this.set_religions(this.religionFormParam);
    },

    on_change_count(e) {
      this.religionFormParam.page = 1
      this.religionFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.religionFormParam.count = e.target.value
      this.set_religions(this.religionFormParam)

      // this.religionFormParam.count = e.target.value;
      // this.set_religions(this.religionFormParam);
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel() {
      this.isEditable = false
      this.religionData = Object.assign({}, this.religionViewtoReligionData);
    },

  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'religions',
        'religionPagination',
        'religionFormParam',
      ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_religions(this.religionFormParam)
  },


  /** watch **/
  watch: {
    'religionViewtoReligionData'() {
      this.religionData = Object.assign({}, this.religionViewtoReligionData);
    },
  },

  /** destroyed **/
  destroyed() {
    this.null_religions();
  },


}
</script>

<style scoped>

</style>
