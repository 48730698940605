<template>
  <div class="container-fluid py-2 pl-3">
    <div class="row">
      <div class="col">
        <div class="row pt-3">
          <div class="col-2">
            <h4 class="font-weight-bold">
              Application Details
            </h4>
          </div>
          <div class="col-4">
            <button v-if="!isEditApplicantDetails" class="btn btn-sm pmc-btn-warning" @click="setEditableApplication">
              <font-awesome-icon icon="edit"/>
              Update
            </button>

            <button v-if="isEditApplicantDetails" class="btn btn-sm pmc-btn-danger ml-2" @click="setCancelEditApplication">
              <font-awesome-icon icon="times"/>
              Cancel
            </button>

            <button v-if="isEditApplicantDetails" class="btn btn-sm pmc-btn-tertia ml-2" @click="updateApplicantDetails">
              <font-awesome-icon icon="save"/>
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col-md-6">
        <!--  Applicant Status-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Applicant Status</label>
          <span>:</span>
          <div class="col-md-7">
            <select
                    :disabled="!isEditApplicantDetails"
                    class="form-control form-control-sm"
                    v-model="applicantDetails.applicantStatus">
              <option class="text-center align-middle" disabled :value="null">--SELECT--</option>
              <option class="text-left align-middle" :value="aplStatus.id"
                  v-for="(aplStatus,index) in objApplicantStatusKeys"
                      :key="aplStatus.id"
                      :selected="applicantDetails.applicantStatus === aplStatus.id">
                {{aplStatus.name}}
              </option>
            </select>
          </div>
        </div>

        <!--        Applicant Type-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Applicant Type</label>
          <span>:</span>
          <div class="col-md-7">
            <select :disabled="!isEditApplicantDetails"  class="form-control form-control-sm" v-model="applicantDetails.applicantTypeId">
              <option class="text-center align-middle" disabled :value="null">--SELECT--</option>
              <option class="text-left align-middle"
                      :value="aplType.id" v-for="(aplType,index) in objApplicantTypeKeys"
                      :selected="applicantDetails.applicantType === aplType.id"
                      :key="aplType.id">
                {{aplType.name}}
              </option>
            </select>
          </div>
        </div>
        <!--        Endorsement Type-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Endorsement Type</label>
          <span>:</span>
          <div class="col-md-7">
            <select :disabled="!isEditApplicantDetails" class="form-control form-control-sm" v-model="applicantDetails.endorsementTypeId" @change="onChangeEndorsementType($event)">
              <option class="text-center align-middle" :value="null">--SELECT--</option>
              <option class="text-left align-middle"
                      :value="endorseType.id"
                      :selected="applicantDetails.endorsementTypeId === endorseType.id"
                      v-for="(endorseType,index) in objEndorsementType" :key="endorseType.id">
                {{endorseType.name}}
              </option>
            </select>
          </div>
        </div>

        <!--        Endorsed By-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Endorse By</label>
          <span>:</span>
          <div class="col-md-6">
            <input v-if="!isEditApplicantDetails" disabled class="form-control form-control-sm" v-model="applicantDetails.endorseBy" placeholder="N / A"/>
            <input type="button"
                   v-if="isEditApplicantDetails"
                   disabled="disabled"
                   id="applicant-endorse-by"
                   class="form-control form-control-sm"
                   data-backdrop="static"
                   data-keyboard="false"
                   data-toggle="modal"
                   value="Search Crew Endorser"
                   data-target="#crew-endorser-modal">
            <input v-if="formSelectedEndorserBy"  class="form-control form-control-sm text-center align-middle form-control-plaintext" type="text" :value="thisEndorseBy">
          </div>
          <button class="btn btn-xs mr-3" @click="applicantDetails.endorseById = null">
            &#x2715;
          </button>
        </div>

        <!--        Endorsed By Others-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Endorse By Others</label>
          <span>:</span>
          <div class="col-md-7">
            <input disabled="disabled"
                   id="applicant-endorse-by-others"
                   class="form-control form-control-sm"
                   v-model="applicantDetails.endorseByOthers" placeholder="N / A"/>
          </div>
        </div>
        <!--        Date Applied-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Date Applied</label>
          <span>:</span>
          <div class="col-md-7">
            <input :disabled="!isEditApplicantDetails" type="date" class="form-control form-control-sm" v-model="applicantDetails.dateApplied">
          </div>
        </div>
        <!--        Date Qualified-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Date Qualified</label>
          <span>:</span>
          <div class="col-md-7">
            <input :disabled="!isEditApplicantDetails" type="date" class="form-control form-control-sm" v-model="applicantDetails.dateQualified">
          </div>
        </div>
        <!--        Date Accepted-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Date Accepted</label>
          <span>:</span>
          <div class="col-md-7">
            <input :disabled="!isEditApplicantDetails" type="date" class="form-control form-control-sm" v-model="applicantDetails.dateAccepted">
            <span class="small text-danger font-italic">Date accepted is base on when applicant changed the status into *Accepted</span>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <!--        Date Available-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Date Available</label>
          <span>:</span>
          <div class="col-md-7">
            <input :disabled="!isEditApplicantDetails" type="date" class="form-control form-control-sm" v-model="applicantDetails.dateAvailable">
          </div>
        </div>


        <!--        PMC Office-->
        <div class="form-group row pt-2">
          <label class="col-md-4">PMC Office</label>
          <span>:</span>
          <div class="col-md-7">
            <select :disabled="!isEditApplicantDetails" class="form-control form-control-sm" v-model="applicantDetails.pmcOffice">
              <option class="text-center align-middle" disabled :value="null">--SELECT--</option>
              <option class="text-left align-middle"
                      :value="pmcOffice.id"
                      v-for="(pmcOffice,index) in objPmcOffice"
                      :selected="applicantDetails.pmcOffice === pmcOffice.id"
                      :key="pmcOffice.id">
                {{pmcOffice.name}}
              </option>
            </select>
          </div>
        </div>

        <!--        Applicant Remarks-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Applicant Remarks</label>
          <span>:</span>
          <div class="col-md-7">
            <textarea
                   :disabled="!isEditApplicantDetails"
                    style="resize: none;"
                    class="form-control form-control-sm"
                    name="applicantRemarks"
                    id="applicant-remarks"
                    cols="30"
                    rows="5"
                    placeholder="Remarks here..."
                    v-model="applicantDetails.remarks" />
          </div>
        </div>


        <!-- Qualified by-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Qualified by</label>
          <span>:</span>
          <div class="col-md-7">
            <input type="text"
                   readonly
                   class="form-control form-control-sm form-control-plaintext"
                   placeholder="N / A"
                   v-model="applicantDetails.qualifiedBy">
          </div>
        </div>
        <!--        Accepted by-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Accepted By</label>
          <span>:</span>
          <div class="col-md-7">
            <input type="text"
                   readonly
                   placeholder="N / A"
                   class="form-control form-control-sm form-control-plaintext"
                   v-model="applicantDetails.acceptedBy">
          </div>
        </div>

        <!--        Created at-->
        <div class="form-group row pt-2">
          <label class="col-md-4">Created At</label>
          <span>:</span>
          <div class="col-md-7">
            <input type="text" readonly class="form-control form-control-sm form-control-plaintext" v-model="applicantDetails.createdAt">
          </div>
        </div>


        <!-- Updated at -->
        <div class="form-group row pt-2">
          <label class="col-md-4">Update At</label>
          <span>:</span>
          <div class="col-md-7">
            <input type="text" readonly class="form-control form-control-sm form-control-plaintext" v-model="applicantDetails.updatedAt">
          </div>
        </div>

      </div>
    </div>

    <!-- crew endorser modal    -->
    <div class="modal fade" id="crew-endorser-modal" tabindex="-1" role="dialog" aria-labelledby="crewEndorserModalTitle" aria-hidden="true">
      <div class="modal-dialog  modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Select Crew Endorser</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeSearchEndorserModal">
              <span aria-hidden="true">
                <font-awesome-icon icon="times"/>
              </span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row py-2">
              <label for="" class="col-form-label col-md-3">Search</label>
              <span>:</span>
              <div class="col-sm-8">
                <input type="text" id="search-endorser"
                       name="searchEndorser"
                       v-model="formSearchParams.searchValue"
                       @keydown="searEndorserDebounce"
                       placeholder="Search crew here..."
                       class="form-control form-control-sm">
              </div>
            </div>

            <pmc-pagination :page-object="objEndorserPagination" @settingPage="setThisPage"></pmc-pagination>
            <pmc-table :max-height="300">
              <template v-slot:table-header>
                <tr>
                  <th>#</th>
                  <th>Rank</th>
                  <th>Crew Name</th>
                </tr>
              </template>

              <template v-slot:table-body>
                <tr v-for="(endorser,index) in objEndorsers" :key="endorser.id" @click="onClickEndorserTbl(endorser)">
                  <td>{{ index + objEndorserPagination.from }}</td>
                  <td>{{ endorser.rank }}</td>
                  <td class="pl-2 text-left align-middle">{{ endorser.fullName }}</td>
                </tr>
              </template>
            </pmc-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";

import __ from "lodash"

export default {
  name: "CrewApplicationDetails",
  mixins: [AppMixins,AlertMixins],
  /** data **/
  data() {
      return {
      crewId : this.$route.params.crewId,
      isEditApplicantDetails : false,

      formSearchParams: {
        searchValue: '',
        count: 10,
        isTrue: false,
        page : 1,
      },
      formApplicantDetails : {},
      formSelectedEndorserBy : null,
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        "applicantDetails",
        "isApplicant",
        "isEditableApplicant",

        "objApplicantStatusKeys",
        "objApplicantTypeKeys",
        "objPmcOffice",
        "objEndorsementType",
        "objEndorsers",
        "objEndorserPagination",
      ],
    ),


    thisEndorseBy : {
      get(){
        let _endorseBy = this.formSelectedEndorserBy ? this.objEndorsers.find((val,index)=>{
          return val.id === this.applicantDetails.endorseById
        }) : null;

        return _endorseBy ? _endorseBy.fullName : null;
      },
      set(e){
        this.formSelectedEndorserBy = e
        this.applicantDetails.endorseBy
      }
    }
  },


  /** methods**/
  methods: {
    ...mapActions(
      [
        "set_application_details",
        "reset_state_applicant_details",
        "reset_obj_applicant_keys",
        "update_applicant_details",
        "get_endorsers_list",
        "reset_init_endorser_key"
      ],
    ),

    async updateApplicantDetails(){
      let msg = "Do you want to save changes you made?"
      if(await this.questionAlert(`${msg}`,"Update Applicant Details")){
        /*Object.keys(this.applicantDetails).forEach((key,index)=>{
          formData.append(`${key}`,this.applicantDetails[key])
        })*/
        this.update_applicant_details(this.applicantDetails).then((r)=>{
          if(r){
            this.$nextTick(()=>{
              this.successAlert("Application details update successfully")
              this.resetFormData();
            })
          }
        });
      }
    },

    searEndorserDebounce : __.debounce(function () {
      this.searEndorser();
    },500),


    searEndorser(){
      this.formSearchParams.page = 1;
      this.get_endorsers_list(this.formSearchParams)
    },

    setThisPage(e){
      this.formSearchParams.page = e;
      this.get_endorsers_list(this.formSearchParams)
    },

    onClickEndorserTbl({id}){
      this.formSelectedEndorserBy = id;
      this.applicantDetails.endorseById = id;
      this.$nextTick(()=>{
        $(`#crew-endorser-modal`).modal('hide')
      })
    },


    setEditableApplication(){
      this.isEditApplicantDetails = true
      Object.assign(this.formApplicantDetails,this.applicantDetails)
      this.enableEndorsedByOthersTxt(this.applicantDetails.endorsementTypeId)
    },

    setCancelEditApplication(){
      this.isEditApplicantDetails = false;
      this.$store.commit('set_application_details',this.formApplicantDetails)
      this.resetFormData();
    },

    closeSearchEndorserModal(){
      this.formSearchParams = {
        searchValue: '',
        count: 10,
        isTrue: false,
        page : 1,
      }
    },

    onChangeEndorsementType(e){
      let type = parseInt(e.target.value,10)
      this.applicantDetails.endorsementTypeId = type;
      this.enableEndorsedByOthersTxt(type)
    },

    enableEndorsedByOthersTxt(e){
      const x = [2,3,4];
      if(x.includes(e) && this.isEditApplicantDetails){
        this.applicantDetails.endorseByOthers = null;
        //todo make the fo
        $(`#applicant-endorse-by`).attr('disabled', true)
        $(`#applicant-endorse-by-others`).attr('disabled', false)

      }else {
        this.formSelectedEndorserBy = null;
        $(`#applicant-endorse-by`).attr('disabled', false)
        $(`#applicant-endorse-by-others`).attr('disabled', true)
      }
    },

    resetFormData(){
      this.enableEndorsedByOthersTxt(this.applicantDetails.endorsementTypeId)
      this.isEditApplicantDetails = false;
      this.formSelectedEndorserBy = null;
      this.formApplicantDetails = {}
      $(`#applicant-endorse-by-others`).attr('disabled', true);
    }
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  created() {
    this.set_application_details(this.crewId)
  },

  /** watch **/
  watch: {
    'applicantDetails.endorsementTypeId' : function(newVal,oldVal){
      this.enableEndorsedByOthersTxt(newVal)
    }
  },

  /** destroyed **/
  destroyed() {

  },
}
</script>

<style scoped>
input[readonly] {
  background-color: #ffffff !important;
}
</style>
