import { render, staticRenderFns } from "./AuthProfile.vue?vue&type=template&id=3aa025e0&scoped=true&"
import script from "./AuthProfile.vue?vue&type=script&lang=js&"
export * from "./AuthProfile.vue?vue&type=script&lang=js&"
import style0 from "./AuthProfile.vue?vue&type=style&index=0&id=3aa025e0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aa025e0",
  null
  
)

export default component.exports