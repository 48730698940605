<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"style="font-size: 22px;">Keiyo Format</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-danger ml-1"
                          @click="printReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>

<!--        <div class="col-md-12">-->
<!--          <div class="row ml-2">-->
<!--            <div class="form-group row">-->
<!--              NAME: {{ printKeiyoParam.full_name }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row ml-2">-->
<!--            <div class="form-group row">-->
<!--              RANK: {{ printKeiyoParam.rank }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row ml-2">-->
<!--            <div class="form-group row">-->
<!--              AGE: {{ printKeiyoParam.age }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row ml-2">-->
<!--            <div class="form-group row">-->
<!--              HEIGHT: {{ printKeiyoParam.height }}-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="row ml-2">-->
<!--            <div class="form-group row">-->
<!--              WEIGHT: {{ printKeiyoParam.weight }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <template>
                    <tr>
                      <td>NAME:</td>
                      <td colspan="7">{{ printKeiyoParam.full_name }}</td>
                    </tr>
                    <tr>
                      <td>RANK:</td>
                      <td>{{ printKeiyoParam.rank }}</td>
                      <td>AGE:</td>
                      <td>{{ printKeiyoParam.age }}</td>
                      <td>HEIGHT:</td>
                      <td>{{ printKeiyoParam.height }}</td>
                      <td>WEIGHT:</td>
                      <td>{{ printKeiyoParam.weight }}</td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>



<!--     Section for Sea Service Experience in PHOENIX MARITIME CORPORATION   -->
        <div>
          <h5 style="font-weight: bold">Sea Service Experience in PHOENIX MARITIME CORPORATION</h5>
        </div>
        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th >Rank</th>
                    <th>Vessel</th>
                    <th>Flag</th>
                    <th >Vessel Type</th>
                    <th >Grt</th>
                    <th >Engine</th>
                    <th >KW</th>
                    <th >Sign On</th>
                    <th >Sign Off</th>
                    <th >Reason</th>
                    <th >Agency</th>
                  </tr>
                  </thead>
                  <template v-if="seaServiceExp.in_pmc.length === 0">
                    <tr>
                      <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else v-for="crewService in seaServiceExp.in_pmc">
                    <tr>
                      <td>{{ crewService.rank }}</td>
                      <td>{{ crewService.vessel }}</td>
                      <td>{{ crewService.flag }}</td>
                      <td>{{ crewService.vesselType }}</td>
                      <td>{{ crewService.grt }}</td>
                      <td>{{ crewService.engine }}</td>
                      <td>{{ crewService.kw }}</td>
                      <td>{{ crewService.signOnDate | moment("DD MMM YYYY") }}</td>
                      <td>{{ crewService.signOffDate | moment("DD MMM YYYY") }}</td>
                      <td>{{ crewService.signOffReasonCode }}</td>
                      <td>{{ crewService.manning }}</td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

<!--     Section for Sea Service Experience in Other Agency   -->
        <div>
          <h5 style="font-weight: bold">Sea Service Experience in Other Agency</h5>
        </div>
        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th >Rank</th>
                    <th>Vessel</th>
                    <th>Flag</th>
                    <th >Vessel Type</th>
                    <th >Grt</th>
                    <th >Sign On</th>
                    <th >Sign Off</th>
                    <th >Reason</th>
                    <th >Route</th>
                    <th >Agency</th>
                  </tr>
                  </thead>
                  <template v-if="seaServiceExp.in_other_agency.length === 0">
                    <tr>
                      <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else v-for="crewService in seaServiceExp.in_other_agency">
                    <tr>
                      <td>{{ crewService.rank }}</td>
                      <td>{{ crewService.vessel }}</td>
                      <td>{{ crewService.flag }}</td>
                      <td>{{ crewService.vesselType }}</td>
                      <td>{{ crewService.grt }}</td>
                      <td>{{ crewService.signOnDate | moment("DD MMM YYYY") }}</td>
                      <td>{{ crewService.signOffDate | moment("DD MMM YYYY") }}</td>
                      <td>{{ crewService.signOffReasonCode }}</td>
                      <td>{{ crewService.tradeRouteCode }}</td>
                      <td>{{ crewService.manning }}</td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {FileService} from "@/service/file.service";

export default {
  name: "KeiyoFormatPrint",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      crewId : null,
      isGetPersonalDetailsDone : false,
      isGetCrewServiceDone : false
    }
  },
  watch : {
    allRequestDone () {
      if (this.allRequestDone === true) {
        Swal.close();
      }
    }
  },
  mounted() {
    AlertService.loadingAlert('Please wait ...', 'Opening Keiyo Format');
    this.prepareData();
  },


  /** methods**/
  methods: {
    ...mapActions(
      [
        "set_crew_sea_services",
        "viewPersonalDetails"
      ],
    ),
    printReport() {
      AlertService.loadingAlert('Please wait ...', 'Generating PDF for Keiyo Format');
      axios.post(PMC.PRINT_KEIYO_FORMAT,this.printKeiyoParam).then(r =>{
        let fScr = FileService.base64FileToBlobUrl(r.data);
        Swal.close();
        this.pdfViewer('File', fScr);
      }).catch((error)=>{
        Swal.close();
        return false;
      });
    },
    prepareData() {
      this.crewId = this.$route.query.crew_id ?? null;

      this.viewPersonalDetails(parseInt(this.crewId, 10)).then(() => {
        this.isGetPersonalDetailsDone = true;
      });

      this.set_crew_sea_services({
        crewId: parseInt(this.crewId, 10),
      }).then(() => {
        this.isGetCrewServiceDone = true;
      });
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'crewSeaServices',
        "crewInfo",
        "crewGenInfo"
      ],
    ),
    allRequestDone() {
      return this.isGetCrewServiceDone === true && this.isGetPersonalDetailsDone === true;
    },
    seaServiceExp() {
      let expInPmc = [];
      let expInOtherAgency = [];

      this.crewSeaServices.forEach((crewSeaService) => {
        if (crewSeaService.manningId === 878 || crewSeaService.manningId === 1304) {
          expInPmc.push(crewSeaService);
        } else {
          expInOtherAgency.push(crewSeaService);
        }
      });
      return {
        in_pmc : expInPmc,
        in_other_agency : expInOtherAgency
      };
    },
    printKeiyoParam() {
      return {
        full_name : this.crewGenInfo.lastName + ', ' + this.crewGenInfo.firstName + ' ' + this.crewGenInfo.middleName,
        rank : this.crewInfo.rankName,
        age : this.crewGenInfo.age,
        height : this.crewGenInfo.height + ' cm',
        weight : this.crewGenInfo.weight + ' kg',
        sea_service_exp : this.seaServiceExp
      }
    }
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

th, td {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}
</style>
