import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  flag: {},
  flags: [],
  newFlag: {},

  flagFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  flagPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_flags(state, params) {
    state.flags = params
  },

  set_flag(state, params) {
    state.flag = params
  },

  create_flag (state) {
    state.newFlag = {};
  },

  update_flag (state, params) {
    let flag = state.flags.find((val, index) => val.id === params.id)
    Object.assign(flag, params)
  },

  delete_flag (state, params) {
    let index = state.flags.findIndex((val, index) => val.id === params)
    state.flags.splice(index, 1)
  },

  null_flags(state) {
    state.flags = []
  },

  null_flag(state) {
    state.flag = {}
  },

  set_flag_pagination(state, params) {
    state.flagPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {

  async set_flags({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.FLAG, {params}).then((r) => {
      commit('set_flags', r.data.data)
      commit('set_flag_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get flags')
      return false
    })
  },

  async create_flag({commit}, params) {
    return axios.post(PMC.FLAG, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_flag', r.data);
      return true;

    }).catch((er) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(er, 'Create flag');
      return false;
    })

  },

  async update_flag({commit}, params) {
    return axios.put(`${PMC.FLAG}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_flag',r.data.updatedFlag);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update flag');
      return false;

    });
  },

  async delete_flag ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete flag','question');
    if(await _del){
      return axios.delete(`${PMC.FLAG}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete flag');
        commit('delete_flag',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete rank");
        return false;

      })
    }
  },


  reset_flag_pagination({commit}) {
    commit('set_flag_pagination')
  },

  null_flags({commit}) {
    commit('null_flags')
  },

  null_flag({commit}) {
    commit('null_flag')
  },
};


const getters = {
  flags: state => UtilityService.capitalizeProperties(state.flags),

  flagPagination: state => state.flagPagination,
  flagFormParam: state => state.flagFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
