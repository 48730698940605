<template>
  <div class="container-fluid bg-white">
    <div class="row py-2">
      <div class="col-md-12 text-left">
        <pmc-btn
            @buttonEvent="save_new_crew"
            :button-label="'Save'"
            :button-class="'btn btn-sm pmc-btn-tertia mr-2'">
          <template slot="button-icon">
            <font-awesome-icon icon="save"/>
          </template>
        </pmc-btn>

        <pmc-btn
            @buttonEvent="goBack"
            :button-class="'btn btn-sm btn-secondary'"
            :button-label="'Go Back'">
          <template slot="button-icon">
            <font-awesome-icon icon="undo"/>
          </template>
        </pmc-btn>

      </div>
    </div>

    <div class="row py-1">
      <div class="col-md-12 text-left">
        <div class="card">
          <div class="card-header">
            <h6>General Information</h6>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-2 border-right">
                <div class="form-group row text-center">
                  <div class="crew-img-container">
                    <img id="crew-img" :src="thisCrewImageBlob" alt="crewImage" class="img-fluid crew-img">
                  </div>
                </div>
                <div class="col-md-8 text-left mt-4">
                  <input type="file" id="input-crew-img"  refs="crewInputImage" @change="on_image_input" style="display: none;">
                  <div v-if="!crewImageBlob">
                    <label for="input-crew-img" class="btn btn-sm small pmc-bg-warning">
                      <font-awesome-icon icon="paperclip"/>
                      <small class="font-weight-bold">Attach Image</small>
                    </label>
                  </div>

                  <div v-if="crewImageBlob">
                    <pmc-btn
                        @buttonEvent="set_cancel_attachement_crewimg"
                        :button-label="'Cancel'"
                        :button-class="'btn btn-sm pmc-btn-danger'">
                      <template v-slot:button-icon>
                        <font-awesome-icon icon="times"/>
                      </template>
                    </pmc-btn>
                  </div>
                </div>
              </div>

              <div class="col-5 border-right">
                <div class="form-group row mt-1">
                  <label for="first-name"
                         :class="{'text-danger':!newCrew.generalInfo.firstName,'':newCrew.generalInfo.firstName}"
                         class="col-sm-4 col-form-label">
                    *First Name
                  </label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-sm"
                           :class="{'is-invalid':!newCrew.generalInfo.firstName,'is-valid':newCrew.generalInfo.firstName}"
                           id="first-name"
                           name="FirstName"
                           v-model="newCrew.generalInfo.firstName"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="middle-name"
                         :class="{'text-danger':!newCrew.generalInfo.middleName,'':newCrew.generalInfo.middleName}"
                         class="col-sm-4 col-form-label">
                    *Middle Name
                  </label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           :class="{'is-invalid':!newCrew.generalInfo.middleName,'is-valid':newCrew.generalInfo.middleName}"
                           class="form-control form-control-sm"
                           id="middle-name"
                           name="MiddleName"
                           v-model="newCrew.generalInfo.middleName"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="last-name"
                         :class="{'text-danger': !newCrew.generalInfo.lastName,'':newCrew.generalInfo.lastName}"
                         class="col-sm-4 col-form-label">
                    *Last Name
                  </label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           :class="{'is-invalid':!newCrew.generalInfo.lastName,'is-valid':newCrew.generalInfo.lastName}"
                           class="form-control form-control-sm"
                           id="last-name"
                           name="lastName"
                           v-model="newCrew.generalInfo.lastName"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="suffix-name" class="col-sm-4 col-form-label">Suffix Name</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-sm"
                           id="suffix-name"
                           name="SuffixName"
                           v-model="newCrew.generalInfo.suffixName"
                           placeholder="--">
                  </div>
                </div>


                <div class="form-group row mt-1">
                  <label for="birth-place" class="col-sm-4 col-form-label">Birth Place</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-sm"
                           id="birth-place"
                           name="BirthPlace"
                           v-model="newCrew.generalInfo.birthplace"
                           placeholder="--">
                  </div>
                </div>


                <hr>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">SSS</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-sm"
                           id="sss"
                           name="SSS"
                           v-model="newCrew.generalInfo.sss"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="tin" class="col-sm-4 col-form-label">TIN</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-sm"
                           id="tin"
                           name="TIN"
                           v-model="newCrew.generalInfo.tin"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="philhealth" class="col-sm-4 col-form-label">PHIL-HEALTH</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-sm"
                           id="philhealth"
                           name="PhilHealth"
                           v-model="newCrew.generalInfo.philHealth"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="gender" class="col-sm-4 col-form-label">PAG-IBIG</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-sm"
                           id="pagibig"
                           name="PagIbig"
                           v-model="newCrew.generalInfo.pagIbig"
                           placeholder="--">
                  </div>
                </div>
              </div>

              <div class="col-5">
                <div class="form-group row mt-1">
                  <label class="col-sm-4 col-form-label">Nationality</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        v-model="newCrew.generalInfo.nationality"
                        :options="objNationalities"
                        :custom-label="nationalityNameLang"
                        :select-label="'Select Nationality'"
                        placeholder="Select Nationality"
                        label="Nationality"
                        track-by="id">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="religion" class="col-sm-4 col-form-label">Religion</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <select name="Religion" id="religion" class="form-control form-control-sm" v-model="newCrew.generalInfo.religion">
                      <option value="" disabled>-- select --</option>
                      <option :value="regl.id" v-for="(regl,index) in objReligions">{{ religionNameLang(regl) }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="form-group row mt-1">
                  <label for="birthdate"
                         :class="{'text-danger':!newCrew.generalInfo.birthdate,'':newCrew.generalInfo.birthdate}"
                         class="col-sm-4 col-form-label">
                    *Birthdate
                  </label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="date"
                           :class="{'is-invalid': !newCrew.generalInfo.birthdate, 'is-valid': newCrew.generalInfo.birthdate}"
                           class="form-control form-control-sm"
                           id="birthdate"
                           name="Birthdate"
                    v-model="newCrew.generalInfo.birthdate"
                    placeholder="--">
                    <!--  remove to this to make the user can input date
                    onkeydown="event.preventDefault()" -->

                  </div>
                  <button
                      v-if="newCrew.generalInfo.birthdate"
                      class="btn btn-xs" @click="newCrew.generalInfo.birthdate = null">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <div class="form-group row mt-1">
                  <label for="civil-status" class="col-sm-4 col-form-label">Civil Status</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <select name="CivilStatus" id="civil-status" class="form-control form-control-sm" v-model="newCrew.generalInfo.civilStatus">
                      <option value="" disabled>-- select --</option>
                      <option :value="civStat.id" v-for="(civStat,index) in objCivilStatus">
                        {{ civStat.name.toUpperCase() }}
                      </option>
                    </select>
                  </div>
                  <button
                      v-if="newCrew.generalInfo.civilStatus"
                      class="btn btn-xs" @click="newCrew.generalInfo.civilStatus = null">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <div class="form-group row mt-1">
                  <label for="gender" class="col-sm-4 col-form-label">Gender</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <select class="form-control form-control-sm" name="Gender" id="gender" v-model="newCrew.generalInfo.gender">
                      <option value="" disabled>-- select --</option>
                      <option :value="gender.id" v-for="(gender,index) in objGenders">{{ gender.name.toUpperCase() }}
                      </option>
                    </select>
                  </div>
                  <button
                      v-if="newCrew.generalInfo.gender"
                      class="btn btn-xs" @click="newCrew.generalInfo.gender = null">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <div class="form-group row mt-1">
                  <label for="height" class="col-sm-4 col-form-label">Height</label>
                  <span>:</span>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control form-control-sm"
                           id="height"
                           name="Height"
                           v-model="newCrew.generalInfo.height"
                           placeholder="Centimeters">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="weight" class="col-sm-4 col-form-label">Weight</label>
                  <span>:</span>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control form-control-sm"
                           id="weight"
                           name="Weight"
                           v-model="newCrew.generalInfo.weight"
                           placeholder="Kilogram">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="weight" class="col-sm-4 col-form-label">Hair Color</label>
                  <span>:</span>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control form-control-sm"
                           id="hair-color"
                           name="CrewRank"
                           v-model="newCrew.generalInfo.hairColor"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="weight" class="col-sm-4 col-form-label">Eye Color</label>
                  <span>:</span>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control form-control-sm"
                           id="eye-color"
                           name="CrewRank"
                           v-model="newCrew.generalInfo.eyeColor"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="weight" class="col-sm-4 col-form-label">Blood Type</label>
                  <span>:</span>
                  <div class="col-sm-4">
                    <select
                        class="form-control form-control-sm"
                        name="BloodType"
                        id="blood-type"
                        v-model="newCrew.generalInfo.bloodType">
                      <option value="" disabled>-- select --</option>
                      <option :value="bloodType.code" v-for="(bloodType,index) in bloodTypes">{{
                          bloodTypeLang(bloodType)
                                                                                              }}
                      </option>
                    </select>
                  </div>
                  <button
                      v-if="newCrew.generalInfo.bloodType"
                      class="btn btn-xs" @click="newCrew.generalInfo.bloodType = null">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- address and contacts   -->
    <div class="row py-1">
      <div class="col-md-12 text-left">
        <div class="card">
          <div class="card-header">
            <h6 class="font-weight-bold">Address & Contacts</h6>
          </div>

          <div class="card-body">
            <div class="row">

              <!-- PERMANENT -->
              <div class="col-4 border-right">
                <div class="form-group row">
                  <label for="first-name" class="col-sm-5 font-weight-bold pb-3">Permanent Address</label>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">*Region</label>
                  <span>:</span>

                  <div class="col-sm-7">
                    <multi-select
                        :options="permanentRegions"
                        v-model="newCrew.addressAndContact.permanent.region"
                        :custom-label="regionNameLang"
                        :select-label="'Select Region'"
                        placeholder="Select Region"
                        label="Region"
                        track-by="regDesc">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">*City / Province</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="permanentProvinces"
                        v-model="newCrew.addressAndContact.permanent.province"
                        :custom-label="provinceNameLang"
                        :select-label="'Select Province'"
                        placeholder="Select Province"
                        label="Province"
                        track-by="provCode">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">*City / Municipality</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="permanentCities"
                        v-model="newCrew.addressAndContact.permanent.city"
                        :custom-label="cityNameLang"
                        :select-label="'Select City'"
                        placeholder="Select City"
                        label="City"
                        track-by="cityCode">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">Barangay</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="permanentBarangays"
                        v-model="newCrew.addressAndContact.permanent.barangay"
                        :custom-label="brgyNameLang"
                        :select-label="'Select Barangay'"
                        placeholder="Select Barangay"
                        label="Barangay"
                        track-by="brgyCode">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">Description</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input
                        v-model="newCrew.addressAndContact.permanent.description"
                        type="text"
                        class="form-control form-control-sm"
                        id="permanent-description"
                        name="CrewRank"
                        placeholder="--">
                  </div>
                </div>

                <hr>

                <!-- Contacts -->
                <div class="form-group row">
                  <label class="col-sm-5 font-weight-bold pb-2">Contacts</label>
                </div>

                <div class="form-group row mt-1">
                  <label for="email" class="col-sm-4 col-form-label">Email Address</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input
                        v-model="newCrew.addressAndContact.contacts.email"
                        type="text"
                        class="form-control form-control-sm"
                        id="email"
                        name="Email"
                        placeholder="--">
                  </div>
                </div>

                <!-- telephone number -->
                <div class="form-group row mt-1">
                  <label for="tel-number" class="col-sm-4 col-form-label">#No. Telephone</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input
                        v-model="newCrew.addressAndContact.contacts.telNumber"
                        type="text"
                        class="form-control form-control-sm"
                        id="tel-number"
                        name="TelephoneNumber"
                        placeholder="--">
                  </div>
                </div>

                <!-- work number -->
                <div class="form-group row mt-1">
                  <label for="work-number" class="col-sm-4 col-form-label">#No. Work</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input
                        v-model="newCrew.addressAndContact.contacts.workNumber"
                        type="text"
                        class="form-control form-control-sm"
                        id="work-number"
                        name="WorkNumber"
                        placeholder="--">
                  </div>
                </div>

                <!-- personal number -->
                <div class="form-group row mt-1">
                  <label for="personal-number" class="col-sm-4 col-form-label">#No. Personal</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input
                        v-model="newCrew.addressAndContact.contacts.perNumber"
                        type="text"
                        class="form-control form-control-sm"
                        id="personal-number"
                        name="PersonalNumber"
                        placeholder="--">
                  </div>
                </div>
              </div>

              <!-- TEMPORARY -->
              <div class="col-4 border-right">
                <label for="first-name" class="col-sm-5 font-weight-bold pb-3">Temporary Address</label>
                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">*Region</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="temporaryRegions"
                        v-model="newCrew.addressAndContact.temporary.region"
                        :custom-label="regionNameLang"
                        :select-label="'Select Region'"
                        placeholder="Select Region"
                        label="Region"
                        track-by="regCode">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">*City / Province</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="temporaryProvinces"
                        v-model="newCrew.addressAndContact.temporary.province"
                        :custom-label="provinceNameLang"
                        :select-label="'Select Province'"
                        placeholder="Select Province"
                        label="Province"
                        track-by="provCode">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">*City / Municipality</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="temporaryCities"
                        v-model="newCrew.addressAndContact.temporary.city"
                        :custom-label="cityNameLang"
                        :select-label="'Select City'"
                        placeholder="Select City"
                        label="City"
                        track-by="cityCode">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">Barangay</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="temporaryBarangays"
                        v-model="newCrew.addressAndContact.temporary.barangay"
                        :custom-label="brgyNameLang"
                        :select-label="'Select Barangay'"
                        placeholder="Select Barangay"
                        label="Barangay"
                        track-by="brgyCode">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">Description</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input
                        v-model="newCrew.addressAndContact.temporary.description"
                        type="text"
                        class="form-control form-control-sm"
                        id="temporary-description"
                        name="CrewRank"
                        placeholder="--">
                  </div>
                </div>
              </div>

              <!-- PROVINCIAL -->
              <div class="col-4">
                <label for="first-name" class="col-sm-5 font-weight-bold pb-3">Provincial Address</label>
                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">Region</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="provRegions"
                        v-model="newCrew.addressAndContact.province.region"
                        :custom-label="regionNameLang"
                        :select-label="'Select Regions'"
                        placeholder="Select Regions"
                        label="Regions"
                        track-by="regDesc">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">City / Province</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="provProvinces"
                        v-model="newCrew.addressAndContact.province.province"
                        :custom-label="provinceNameLang"
                        :select-label="'Select Province'"
                        placeholder="Select Province"
                        label="Province"
                        track-by="provDesc">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">City / Municipality</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="provCities"
                        v-model="newCrew.addressAndContact.province.city"
                        :custom-label="cityNameLang"
                        :select-label="'Select City'"
                        placeholder="Select City"
                        label="City"
                        track-by="cityDesc">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">Barangay</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <multi-select
                        :options="provBarangays"
                        v-model="newCrew.addressAndContact.province.barangay"
                        :custom-label="brgyNameLang"
                        :select-label="'Select Barangay'"
                        placeholder="Select Barangay"
                        label="Barangay"
                        track-by="brgyCode">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="first-name" class="col-sm-4 col-form-label">Description</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input
                        v-model="newCrew.addressAndContact.province.description"
                        type="text"
                        class="form-control form-control-sm"
                        id="provincial-description"
                        name="CrewRank"
                        placeholder="--">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--   Crew information -->
    <div class="row py-1">
      <div class="col-md-12 text-left">
        <div class="card">
          <div class="card-header">
            <h6 class="font-weight-bold">Crew Information</h6>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-4 text-left border-right">

                <!-- change code -->
                <div class="form-group row mt-1">
                  <label for="bridging-batch" class="col-sm-4 col-form-label">Change Code</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <select name="ChangeCode"
                            id="change-code"
                            :disabled="newCrew.tracking.applicantStatus != 4"
                            class="custom-select custom-select-sm"
                            :class="{'is-invalid':newCrew.tracking.applicantStatus != 4,'is-valid':newCrew.tracking.applicantStatus == 4}"
                            v-model="newCrew.crewInfo.changeCode"
                            style="font-size: 12px!important;">
                      <option value="" disabled>--select changecode --</option>
                      <option :value="changecode.id" :key="changecode.id" v-for="(changecode,index) in objChangeCodes" style="font-size: 12px!important;">{{ changecode.name }}</option>
                    </select>
                    <div :class="{'invalid-feedback':newCrew.tracking.applicantStatus != 4,'valid-feedback':newCrew.tracking.applicantStatus == 4}">
<!--                      Cant change code until applicant was accepted.-->
                      <span style="font-size: 10px;">{{newCrew.tracking.applicantStatus != 4 ? 'Cant change code until applicant was accepted.' : 'Looks good.'}}</span>
                    </div>
                  </div>

                  <button type="button" class="btn btn-xs" v-if="newCrew.crewInfo.changeCode" @click="newCrew.crewInfo.changeCode = ''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!-- crew type -->
                <div class="form-group row mt-1">
                  <label for="crew-type" class="col-sm-4 col-form-label">Crew Type</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <select
                        v-model="newCrew.crewInfo.crewType"
                        name="CrewType"
                        id="crew-type"
                        @change="on_change_crew_type"
                        class="form-control form-control-sm">
                      <option value="" disabled>-- select --</option>
                      <option :value="crewType.id" v-for="(crewType,index) in objCrewTypes" :key="crewType.id">
                        {{ crewType.name }}
                      </option>
                    </select>
                  </div>
                  <button
                      v-if="newCrew.crewInfo.crewType"
                      class="btn btn-xs" @click="newCrew.crewInfo.crewType = ''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!-- crew scholar type -->
                <div class="form-group row mt-1" v-if="newCrew.crewInfo.crewType == 1">
                  <label for="scholar-type" class="col-sm-4 col-form-label">Scholar Type</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <select
                        v-model="newCrew.crewInfo.scholarType"
                        name="ScholarType"
                        class="form-control form-control-sm"
                        @change="on_change_scholar_type"
                        id="scholar-type">
                      <option value="" disabled>-- select --</option>
                      <option :value="scholarType.id" v-for="(scholarType,index) in objScholarTypes" :key="scholarType.id">
                        {{ scholarType.name }}
                      </option>
                    </select>
                  </div>
                  <button
                      v-if="newCrew.crewInfo.scholarType"
                      class="btn btn-xs" @click="newCrew.crewInfo.scholarType = ''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!-- crew scholar prop -->
                <div class="form-group row mt-1" v-if="newCrew.crewInfo.scholarType == 4">
                  <label for="scholar-prop" class="col-sm-4 col-form-label">Scholar Prop</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <select
                        v-model="newCrew.crewInfo.scholarProp"
                        name="ScholarProp"
                        class="form-control form-control-sm"
                        id="scholar-prop">
                      <option value="" disabled>-- select --</option>
                      <option :value="scholarProp.id" v-for="(scholarProp,index) in objScholarProps" :key="scholarProp.id">
                        {{ scholarProp.name }}
                      </option>
                    </select>
                  </div>
                  <button
                      v-if="newCrew.crewInfo.scholarProp"
                      class="btn btn-xs" @click="newCrew.crewInfo.scholarProp = ''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!-- bridging batch -->
                <div class="form-group row mt-1" v-if="newCrew.crewInfo.scholarType == 1 && newCrew.crewInfo.crewType == 1">
                  <label for="bridging-batch" class="col-sm-4 col-form-label">Bridging Batch</label>
                  <span>:</span>
                  <div class="col-sm-7">
<!--                    <input type="text"-->
<!--                           class="form-control form-control-sm"-->
<!--                           id="bridging-batch"-->
<!--                           name="ScholarType"-->
<!--                           placeholder="&#45;&#45;">-->
                    <select
                      name="Crewbatching"
                      class="custom-select custom-select-sm"
                      id="crew-bridging-batch1"
                      v-model="newCrew.crewInfo.bridging_batch"
                    >
                      <option :value="null">--</option>
                      <option v-for="n in seriesCount(50)" :value="n">{{ n }}</option>
                    </select>

                  </div>
                </div>

              </div>

              <div class="col-4 text-left border-right">
                <!-- rank -->
                <div class="form-group row mt-1">
                  <label for="rank"
                         :class="{'text-danger':!newCrew.crewInfo.rank,'':newCrew.crewInfo.rank}"
                         class="col-sm-4 col-form-label text-danger">
                    *Rank <sup>tracking</sup>
                  </label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <select
                        v-model="newCrew.crewInfo.rank"
                        :class="{'is-invalid':!newCrew.crewInfo.rank,'is-valid':newCrew.crewInfo.rank}"
                        class="form-control form-control-sm"
                        name="Rank" id="rank">
                      <option value="" disabled>-- select --</option>
                      <option :value="rank.id" v-for="(rank,index) in objRanks" :key="rank.id">[{{ rank.alias }}] - {{ rank.name }}</option>
                    </select>
                  </div>

                  <button
                      v-if="newCrew.crewInfo.rank"
                      class="btn btn-xs" @click="newCrew.crewInfo.rank = null">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!-- status -->
                <div class="form-group row mt-4">
                  <label for="rank" class="col-sm-4 col-form-label">Status</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="checkbox" class="form-check-inline ml-2" v-model="newCrew.crewInfo.status">
                    <label>{{ newCrew.crewInfo.status ? 'Active' : 'Inactive' }}</label>
                  </div>
                </div>
              </div>

              <div class="col-4 text-left">
                <!-- cover all -->
                <div class="form-group row mt-1">
                  <label for="rank" class="col-sm-4 col-form-label">Cover All</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                        v-model="newCrew.crewInfo.coverAll"
                        type="text"
                        class="form-control form-control-sm"
                        id="cover-all"
                        name="CrewType"
                        placeholder="S/M/L">
                  </div>
                </div>

                <!-- jacket -->
                <div class="form-group row mt-1">
                  <label for="jacket" class="col-sm-4 col-form-label">Jacket</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                        v-model="newCrew.crewInfo.jacket"
                        type="text"
                        class="form-control form-control-sm"
                        id="jacket"
                        name="jacket"
                        placeholder="S/M/L">
                  </div>
                </div>

                <!-- shoes -->
                <div class="form-group row mt-1">
                  <label for="shoes" class="col-sm-4 col-form-label">Shoes</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                        v-model="newCrew.crewInfo.shoes"
                        type="text"
                        class="form-control form-control-sm"
                        id="shoes"
                        name="Shoes"
                        placeholder="6/7/8/ETCH">
                  </div>
                </div>

                <!-- long sleeves -->
                <div class="form-group row mt-1">
                  <label for="long-sleeves" class="col-sm-4 col-form-label">Long Sleeves</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                        v-model="newCrew.crewInfo.longSleeves"
                        type="text"
                        class="form-control form-control-sm"
                        id="long-sleeves"
                        name="LongSleeves"
                        placeholder="S/M/L">
                  </div>
                </div>

                <!-- short sleeves -->
                <div class="form-group row mt-1">
                  <label for="short-sleeves" class="col-sm-4 col-form-label">Short Sleeves</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                        v-model="newCrew.crewInfo.shortSleeves"
                        type="text"
                        class="form-control form-control-sm"
                        id="short-sleeves"
                        name="ShortSleeves"
                        placeholder="S/M/L">
                  </div>
                </div>

                <!-- pants -->
                <div class="form-group row mt-1">
                  <label for="pants" class="col-sm-4 col-form-label">Pants</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                        v-model="newCrew.crewInfo.pants"
                        type="text"
                        class="form-control form-control-sm"
                        id="pants"
                        name="Pants"
                        placeholder="S/M/L">
                  </div>
                </div>

                <!-- uniform -->
                <div class="form-group row mt-1">
                  <label for="uniform" class="col-sm-4 col-form-label">Uniform</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                        v-model="newCrew.crewInfo.uniform"
                        type="text"
                        class="form-control form-control-sm"
                        id="uniform"
                        name="Uniform"
                        placeholder="S/M/L">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row py-1">
      <div class="col-md-12 text-left">
        <div class="card">
          <div class="card-header">
            <h6 class="font-weight-bold">Tracking</h6>
          </div>

          <div class="card-body">
            <div class="row">

              <!-- date appliied -->
              <div class="col-6 text-left border-right">
                <div class="form-group row mt-1">
                  <label for="date-applied"
                         :class="{'text-danger' : !newCrew.tracking.dateApplied,'' : newCrew.tracking.dateApplied}"
                         class="col-sm-4 col-form-label">
                    *Date Applied
                  </label>
                  <span>:</span>
                  <div class="col-sm-5">
                    <input type="date"
                           id="date-applied"
                           name="DateApplied"
                           :class="{'is-invalid' : !newCrew.tracking.dateApplied,'is-valid' : newCrew.tracking.dateApplied}"
                           class="form-control form-control-sm"
                           v-model="newCrew.tracking.dateApplied">
                  </div>

                  <button class="btn btn-xs" v-if="newCrew.tracking.dateApplied" @click="newCrew.tracking.dateApplied = ''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!-- applicant type -->
                <div class="form-group row mt-1">
                  <label for="crew-type"
                         :class="{'text-danger' : !newCrew.tracking.applicantType,'' : newCrew.tracking.applicantType}"
                         class="col-sm-4 col-form-label">
                    *Applicant Type
                  </label>
                  <span>:</span>
                  <div class="col-sm-5">
                    <select
                        name="ApplicantType"
                        id="applicant-type"
                        :class="{'is-invalid' : !newCrew.tracking.applicantType,'is-valid' : newCrew.tracking.applicantType}"
                        class="form-control form-control-sm"
                        v-model="newCrew.tracking.applicantType">
                      <option value="" disabled>-- select --</option>
                      <option :value="applicantType.id" v-for="(applicantType,index) in objApplicantTypes">{{ applicantType.name }}</option>
                    </select>
                  </div>
                  <button class="btn btn-xs" v-if="newCrew.tracking.applicantType" @click="newCrew.tracking.applicantType = ''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!-- endorsement type -->
                <div class="form-group row mt-1">
                  <label for="endorsement-type"
                         :class="{'text-danger' : !newCrew.tracking.endorsementType,'' : newCrew.tracking.endorsementType}"
                         class="col-sm-4 col-form-label">
                    *Endorsement Type
                  </label>
                  <span>:</span>
                  <div class="col-sm-5">
                    <select
                        name="EndorsementType"
                        id="endorsement-type"
                        :class="{'is-invalid' : !newCrew.tracking.endorsementType,'is-valid' : newCrew.tracking.endorsementType}"
                        class="form-control form-control-sm"
                        @change="on_change_endorsement_type"
                        v-model="newCrew.tracking.endorsementType">
                      <option value="" disabled>-- select --</option>
                      <option :value="endorsemenType.id" v-for="(endorsemenType,index) in objEndorsementTypes">{{ endorsemenType.name }}</option>
                    </select>
                  </div>

                  <button class="btn btn-xs" v-if="newCrew.tracking.endorsementType" @click="newCrew.tracking.endorsementType = ''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!-- endorsed by  -->
                <div class="form-group row mt-1" v-if="newCrew.tracking.endorsementType == 1">
                  <label
                      :class="{'text-danger' : !newCrew.tracking.endorsedBy,'' : newCrew.tracking.endorsedBy}"
                      class="col-sm-4 col-form-label">
                    *Endorse By
                  </label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <pmc-modal-btn
                        @modalButtonEvent="search_crew"
                        v-if="!thisCrewEndorser" :button-label="'Select crew endorser'"
                        :button-class="{'form-control form-control-sm is-invalid' : !newCrew.tracking.endorsedBy,'form-control form-control-sm is-valid' : newCrew.tracking.endorsedBy}" :target-modal="crewEndorserModal">
                    </pmc-modal-btn>
                    <input readonly v-if="thisCrewEndorser" type="text" class="form-control form-control-sm" :value="thisCrewEndorser">
                  </div>

                  <button class="btn btn-xs" v-if="newCrew.tracking.endorsedBy" @click="newCrew.tracking.endorsedBy = null">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!-- endorsed by others  -->
                <div class="form-group row mt-1" v-if="newCrew.tracking.endorsementType != 1 && newCrew.tracking.endorsementType != ''">
                  <label for="endorse-by-others"
                         :class="{'text-danger' : !newCrew.tracking.endorseByOthers,'' : newCrew.tracking.endorseByOthers}"
                         class="col-sm-4 col-form-label">
                    *Endorse By Others
                  </label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           id="endorse-by-others"
                           name="EndorseByOthers"
                           :class="{'is-invalid' : !newCrew.tracking.endorseByOthers,'is-valid' : newCrew.tracking.endorseByOthers}"
                           class="form-control form-control-sm"
                           v-model="newCrew.tracking.endorseByOthers"/>
                  </div>
                  <button class="btn btn-xs" v-if="newCrew.tracking.endorseByOthers" @click="newCrew.tracking.endorseByOthers = ''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>


              </div>

              <div class="col-6 text-left">
                <!--  date available -->
                <div class="form-group row mt-1">
                  <label for="date-available" class="col-sm-4 col-form-label">Date Available</label>
                  <span>:</span>
                  <div class="col-sm-5">
                    <input
                        v-model="newCrew.tracking.dateAvailable"
                        type="date"
                        id="date-available"
                        name="DateAvailable" class="form-control form-control-sm">
                  </div>
                  <button class="btn btn-xs" v-if="newCrew.tracking.dateAvailable" @click="newCrew.tracking.dateAvailable = ''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!--  pmc office -->
                <div class="form-group row mt-1">
                  <label for="pmc-office"
                         :class="{'text-danger' : !newCrew.tracking.pmcOffice,'' : newCrew.tracking.pmcOffice}"
                         class="col-sm-4 col-form-label">
                    *Pmc Office
                  </label>
                  <span>:</span>
                  <div class="col-sm-5">
                    <select
                        v-model="thisNewCrewTrackingPmcOffice"
                        name="PmcOffice"
                        id="pmc-office"
                        :class="{'is-invalid' : !newCrew.tracking.pmcOffice,'is-valid' : newCrew.tracking.pmcOffice}"
                        class="form-control form-control-sm">
                      <option value="" disabled>-- select --</option>
                      <option :value="pmcOffice.name"
                              v-for="(pmcOffice,index) in objPmcOffices"
                              :key="pmcOffice.id">{{ pmcOffice.name }}
                      </option>
                    </select>
                  </div>

                  <button class="btn btn-xs" v-if="newCrew.tracking.pmcOffice" @click="newCrew.tracking.pmcOffice = ''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!--  applicant status -->
                <div class="form-group row mt-1">
                  <label for="applicant-status"
                         :class="{'text-danger' : !newCrew.tracking.applicantStatus,'' : newCrew.tracking.applicantStatus}"
                         class="col-sm-4 col-form-label">
                    *Applicant Status
                  </label>
                  <span>:</span>
                  <div class="col-sm-5">
                    <select
                        v-model="newCrew.tracking.applicantStatus"
                        name="PmcOffice"
                        id="applicant-status"
                        :class="{'is-invalid' : !newCrew.tracking.applicantStatus,'is-valid' : newCrew.tracking.applicantStatus}"
                        class="form-control form-control-sm">
                      <option value="" disabled>-- select --</option>
                      <option :value="applicantStats.id"
                              v-for="(applicantStats,index) in objApplicantStatuses"
                              :key="applicantStats.id">
                        {{ applicantStats.name }}
                      </option>
                    </select>
                  </div>
                  <button class="btn btn-xs" v-if="newCrew.tracking.applicantStatus" @click="newCrew.tracking.applicantStatus =''">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>

                <!--  applicant remarks -->
                <div class="form-group row mt-1">
                  <label for="applicant-status" class="col-sm-4 col-form-label">Applicant Remarks</label>
                  <span>:</span>
                  <div class="col-sm-7">
                    <textarea
                        v-model="newCrew.tracking.applicantRemarks"
                        placeholder="Remarks here.."
                        class="form-control form-control"
                        rows="4"
                        style="resize: none;">
                    </textarea>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal for crew  -->
    <pmc-modal :id="crewEndorserModal" xl_height lg_width @closingEvent="reset_this_form_endorser">
      <template slot="custom-header">
        <h5 class="font-weight-bold">Search Crew Endorser</h5>
      </template>
      <template slot="custom-body">
        <div class="col-md-6">
          <div class="input-group mb-2 mr-sm-2">
            <input
                v-model="formSearchParams.searchValue"
                type="text"
                class="form-control"
                id="formdata-searchvalue"
                @keypress="search_crew"
                placeholder="Search Crew here">

            <pmc-btn
                @buttonEvent="search_crew"
                :button-label="'search'"
                :button-class="'btn btn-sm pmc-btn-prime'">
              <template slot="button-icon">
                <font-awesome-icon icon="search"/>
              </template>
            </pmc-btn>
          </div>
        </div>

        <div class="col-md-12">
          <pmc-pagination :page-object="searchCrewPagination" v-on:settingPage="set_this_page"></pmc-pagination>
        </div>

        <pmc-table>
          <template v-slot:table-header>
            <tr>
              <th style="width: 5%;" scope="col">Rank</th>
              <th style="width: 60%;">Name</th>
            </tr>
          </template>

          <template slot="table-body">
            <tr v-for="(crew,index) in crews" :key="crew.id" @click="set_endorse_by(crew.id)">
              <td>{{ crew.rank }}</td>
              <td class="text-left pl-4">{{ crew.fullName }}</td>
            </tr>
          </template>
        </pmc-table>
      </template>
    </pmc-modal>
  </div>
</template>

<script>

import noImg from '../../../../assets/noPhoto.jpg'
import { AppMixins } from '../../../../mixins/app.mixins'
import { AlertMixins } from '../../../../mixins/alert.mixins'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CreateCrew',

  mixins: [AppMixins, AlertMixins],

  /** data **/
  data () {
    return {
      crewNoImageBlob: noImg,
      crewImageBlob: null,
      bloodTypes: [
        {
          id: 1,
          code: 'A+',
          name: 'A RhD positive',
        },
        {
          id: 2,
          code: 'A-',
          name: 'A RhD negative',
        },
        {
          id: 3,
          code: 'B+',
          name: 'B RhD positive',
        },
        {
          id: 4,
          code: 'B-',
          name: 'B RhD negative',
        },
        {
          id: 5,
          code: 'O+',
          name: 'O RhD positive',
        },
        {
          id: 6,
          code: 'O-',
          name: 'O RhD negative',
        },
        {
          id: 7,
          code: 'AB+',
          name: 'AB RhD positive',
        },
        {
          id: 8,
          code: 'AB-',
          name: 'AB RhD negative',
        },
      ],

      //for form data saving ,
      formDataAddress: {
        permanent: {
          region: '',
          province: '',
          city: '',
          barangay: '',
          description: '',
        },
        temporary: {
          region: '',
          province: '',
          city: '',
          barangay: '',
          description: '',
        },
        province: {
          region: '',
          province: '',
          city: '',
          barangay: '',
          description: '',
        },
      },

      crewEndorserModal: 'crewEndorserModal',

      formSearchParams: {
        searchValue: '',
        searchKey: '',
        sRank: '',
        page: 1,
        count: 10,
        isTrue: false,
      },
    }
  },

  /** methods**/
  methods: {

    ...mapActions([
      'getCreateNewCrewKeys',

      'getRegionAddress',

      //permanent address
      'getPermanentProvinceKeys',
      'getPermanentCityKeys',
      'getPermanentBarangay',
      'null_permanent_regions',
      'null_permanent_province',
      'null_permanent_cities',
      'null_permanent_barangay',

      //temporary address
      'getTemporaryProvinceKeys',
      'getTemporaryCityKeys',
      'getTemporaryBarangayKeys',
      'null_temporary_regions',
      'null_temporary_provinces',
      'null_temporary_cities',
      'null_temporary_barangay',

      //province address
      'getProvinceAddressKeys',
      'getProvinceCityKeys',
      'getProvinceBarangayKeys',
      'null_prov_regions',
      'null_prov_provinces',
      'null_prov_cities',
      'null_prov_barangay',

      'createNewCrew',

      'null_obj_ranks',
      'null_obj_religions',
      'null_obj_civilStatus',
      'null_obj_nationalities',
      'null_obj_crewTypes',
      'null_obj_genders',
      'null_obj_scholarTypes',
      'null_obj_scholarProps',

      'null_obj_change_codes',
      'null_obj_applicant_types',
      'null_obj_applicant_statuses',
      'null_obj_endorsement_types',
      'null_obj_pmc_offices',
      'getAlLCrewPaginated',

      'setNullCrews',
      'resetSearchCrewPagination',

    ]),

    resetThisFormKeys () {
      this.null_obj_change_codes()
      this.null_obj_ranks()
      this.null_obj_religions()
      this.null_obj_civilStatus()
      this.null_obj_nationalities()
      this.null_obj_crewTypes()
      this.null_obj_genders()
      this.null_obj_scholarTypes()
      this.null_obj_scholarTypes()
      this.null_obj_applicant_types()
      this.null_obj_applicant_statuses()
      this.null_obj_endorsement_types()
      this.null_obj_pmc_offices()
      this.null_form_search_param()
      this.setNullCrews()
      this.resetSearchCrewPagination()
    },

    bloodTypeLang ({
      code,
      name,
    }) {
      return `[${code}] - ${this.titleCase(name)}`
    },

    religionNameLang ({ name }) {
      let _stringName = name.length
      return `${this.titleCase(name)}`
    },

    regionNameLang ({
      reg_code,
      reg_desc,
    }) {
      return `${reg_code} - ${reg_desc}`
    },

    provinceNameLang ({ prov_desc }) {
      return `${prov_desc}`
    },

    cityNameLang ({ city_desc }) {
      return `${city_desc}`
    },

    brgyNameLang ({ brgy_desc }) {
      return `${brgy_desc}`
    },

    nationalityNameLang ({
      id,
      natl,
      name,
    }) {
      let _natl = natl ? natl.toUpperCase() : name.toUpperCase()
      return `${_natl}`
    },

    on_image_input (e) {
      const image = e.target.files[0]
      const reader = new FileReader()

      if (image != null || image !== undefined) {
        reader.readAsDataURL(image)
        reader.onload = e => {
          this.crewImageBlob = e.target.result
        }

      } else {
        this.crewImageBlob = null
        $(`#input-crew-img`).val(null)
      }
    },

    on_brgy_temp () {
      //optional
    },

    set_cancel_attachement_crewimg () {
      this.crewImageBlob = null
      $(`#input-crew-img`).val(null)
    },

    async save_new_crew () {
      //let file = document.getElementById('input-crew-img').files[0];
      if (await this.questionAlert('Are you sure you want to add this record.?', 'Create New Crew Record')) {
        const formData = new FormData()
        // let file = this.$refs.crewInputImage.files[0];
        //let file = $(`#input-crew-img`)[0].files;

        let file = document.getElementById('input-crew-img').files[0];

        formData.append('firstName', this.newCrew.generalInfo.firstName)
        formData.append('middleName', this.newCrew.generalInfo.middleName)
        formData.append('lastName', this.newCrew.generalInfo.lastName)
        formData.append('suffixName', this.newCrew.generalInfo.suffixName)
        formData.append('birthplace', this.newCrew.generalInfo.birthplace)
        formData.append('nationality', this.newCrew.generalInfo.nationality ? this.newCrew.generalInfo.nationality.id : '')
        // formData.append('religion', this.newCrew.generalInfo.religion)
        formData.append('religion', this.newCrew.generalInfo.religion)
        formData.append('birthdate', this.newCrew.generalInfo.birthdate)
        formData.append('civilStatus', this.newCrew.generalInfo.civilStatus)
        formData.append('gender', this.newCrew.generalInfo.gender)
        formData.append('height', this.newCrew.generalInfo.height)
        formData.append('weight', this.newCrew.generalInfo.weight)
        formData.append('hairColor', this.newCrew.generalInfo.hairColor)
        formData.append('eyeColor', this.newCrew.generalInfo.eyeColor)
        formData.append('bloodType', this.newCrew.generalInfo.bloodType)

        //contribution
        formData.append('sss', this.newCrew.generalInfo.sss)
        formData.append('tin', this.newCrew.generalInfo.tin)
        formData.append('philHealth', this.newCrew.generalInfo.philHealth)
        formData.append('pagIbig', this.newCrew.generalInfo.pagIbig)

        //permanent address
        // formData.append('permRegion', this.formDataAddress.permanent.region)
        formData.append('permRegion', this.formDataAddress.permanent.region)
        formData.append('permProvince', this.formDataAddress.permanent.province)
        formData.append('permCity', this.formDataAddress.permanent.city)
        formData.append('permBarangay', this.formDataAddress.permanent.barangay)
        formData.append('permDescription', this.formDataAddress.permanent.description)

        //temporary address
        formData.append('tempRegion', this.formDataAddress.temporary.region)
        formData.append('tempProvince', this.formDataAddress.temporary.province)
        formData.append('tempCity', this.formDataAddress.temporary.city)
        formData.append('tempBarangay', this.formDataAddress.temporary.barangay)
        formData.append('tempDescription', this.formDataAddress.temporary.description)

        //province address
        formData.append('provRegion', this.formDataAddress.province.region)
        formData.append('provProvince', this.formDataAddress.province.province)
        formData.append('provCity', this.formDataAddress.province.city)
        formData.append('provBarangay', this.formDataAddress.province.barangay)
        formData.append('provDescription', this.formDataAddress.province.description)

        //todo contacts details
        formData.append('email', this.newCrew.addressAndContact.contacts.email)
        formData.append('telNumber', this.newCrew.addressAndContact.contacts.telNumber)
        formData.append('workNumber', this.newCrew.addressAndContact.contacts.workNumber)
        formData.append('perNumber', this.newCrew.addressAndContact.contacts.perNumber)

        //crew infor
        formData.append('changeCode', this.newCrew.crewInfo.changeCode)
        formData.append('crewType', this.newCrew.crewInfo.crewType)
        formData.append('scholarType', this.newCrew.crewInfo.scholarType)
        formData.append('scholarProp', this.newCrew.crewInfo.scholarProp)
        formData.append('bridging_batch', this.newCrew.crewInfo.bridging_batch)
        formData.append('rank', this.newCrew.crewInfo.rank)
        formData.append('coverAll', this.newCrew.crewInfo.coverAll)
        formData.append('jacket', this.newCrew.crewInfo.jacket)
        formData.append('shoes', this.newCrew.crewInfo.shoes)
        formData.append('longSleeves', this.newCrew.crewInfo.longSleeves)
        formData.append('shortSleeves', this.newCrew.crewInfo.shortSleeves)
        formData.append('pants', this.newCrew.crewInfo.pants)
        formData.append('uniform', this.newCrew.crewInfo.uniform)
        formData.append('status', this.newCrew.crewInfo.status)

        //todo tracking
        formData.append('dateApplied', this.newCrew.tracking.dateApplied)
        formData.append('applicantType', this.newCrew.tracking.applicantType)
        formData.append('endorsementType', this.newCrew.tracking.endorsementType)
        formData.append('endorsedBy', this.newCrew.tracking.endorsedBy)
        formData.append('endorseByOthers', this.newCrew.tracking.endorseByOthers)
        formData.append('dateAvailable', this.newCrew.tracking.dateAvailable)
        formData.append('pmcOffice', this.newCrew.tracking.pmcOffice)
        formData.append('applicantStatus', this.newCrew.tracking.applicantStatus)
        formData.append('applicantRemarks', this.newCrew.tracking.applicantRemarks)

        //crew image
       formData.append('file', file)

        this.createNewCrew(formData).then((r) => {
          if (r) {
            this.successAlert('Record detail save successfully')
            $(`#input-crew-img`).val(null)
            this.resetSearchCrewPagination()
            this.null_form_search_param()
            this.setNullCrews()
            this.crewImageBlob = null
          }
        })
      }
    },

    crewNameLang ({
      id,
      rank,
      fullName,
    }) {
      return `[${rank}] ${fullName}`
    },

    search_crew () {
      this.getAlLCrewPaginated(this.formSearchParams)
    },

    set_this_page (e) {
      this.searchCrewPagination.currentPage = e
      this.formSearchParams.page = e
      this.search_crew()
    },

    reset_this_form_endorser () {
      this.resetSearchCrewPagination()
      this.formSearchParams.searchValue = ''
      this.formSearchParams.page = 1
      // this.setNullCrews();
    },

    set_endorse_by (e) {
      this.newCrew.tracking.endorsedBy = e
      this.hideModal(this.crewEndorserModal)
    },

    null_form_search_param () {
      this.formSearchParams = {
        searchValue: '',
        searchKey: '',
        sFirstName: '',
        sMiddleName: '',
        sLastName: '',
        sRank: '',
        count: 10,
        isTrue: false,
      }
    },

    on_change_endorsement_type () {
      if (this.newCrew.tracking.endorsedBy != null || this.newCrew.tracking.endorsedBy != undefined || this.newCrew.tracking.endorsedBy != '') {
        this.newCrew.tracking.endorsedBy = ''
      }
    },

    on_change_crew_type () {
      if (this.newCrew.crewInfo.crewType != null || this.newCrew.crewInfo.crewType != undefined || this.newCrew.crewInfo.crewType != '') {
        if (this.newCrew.crewInfo.crewType == 1) {
          this.newCrew.crewInfo.scholarType = ''
        }
      }
    },

    on_change_scholar_type () {
      if (this.newCrew.crewInfo.scholarType != null || this.newCrew.crewInfo.scholarType != undefined || this.newCrew.crewInfo.scholarType != '') {
        if (this.newCrew.crewInfo.scholarType == 1 || this.newCrew.crewInfo.scholarType == 4) {
          this.newCrew.crewInfo.scholarProp = ''
          this.newCrew.crewInfo.bridgingBatch = ''
        }
        this.newCrew.crewInfo.scholarProp = ''
        this.newCrew.crewInfo.bridgingBatch = ''
      }
    },

    seriesCount (count = null) {
      let start = 1
      const stop = count ? count : 10
      return new Array(stop - start).fill(start).map((n, i) => n + i)
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters([
      'objNationalities',
      'objGenders',
      'objCrewTypes',
      'objReligions',
      'objCivilStatus',
      'objScholarTypes',
      'objScholarProps',
      'objRanks',
      'newCrew',

      //addresses permanent
      'permanentRegions',
      'permanentProvinces',
      'permanentCities',
      'permanentBarangays',

      //address temporary
      'temporaryRegions',
      'temporaryProvinces',
      'temporaryCities',
      'temporaryBarangays',

      //address province
      'provRegions',
      'provProvinces',
      'provCities',
      'provBarangays',

      'objApplicantTypes',
      'objApplicantStatuses',
      'objEndorsementTypes',
      'objPmcOffices',
      'objChangeCodes',

      'searchCrewPagination',
      'searchParams',

      'crews',
    ]),

    thisCrewImageBlob () {
      return this.crewImageBlob ? this.crewImageBlob : this.crewNoImageBlob
    },

    /** This permanent */
    thisPermanentRegion () {
      return this.newCrew.addressAndContact.permanent.region
    },

    thisPermanentProvinces () {
      return this.newCrew.addressAndContact.permanent.province
    },

    thisPermanentCity () {
      return this.newCrew.addressAndContact.permanent.city
    },

    thisPermanentBarangay () {
      return this.newCrew.addressAndContact.permanent.barangay
    },

    thisPermanentDescription () {
      return this.newCrew.addressAndContact.permanent.description
    },

    /** This temporary */
    thisTemporaryRegion () {
      return this.newCrew.addressAndContact.temporary.region
    },

    thisTemporaryProvinces () {
      return this.newCrew.addressAndContact.temporary.province
    },

    thisTemporaryCity () {
      return this.newCrew.addressAndContact.temporary.city
    },

    thisTemporaryBarangay () {
      return this.newCrew.addressAndContact.temporary.barangay
    },

    thisTemporaryDescription () {
      return this.newCrew.addressAndContact.temporary.description
    },

    /** This province */
    thisProvinceRegion () {
      return this.newCrew.addressAndContact.province.region
    },

    thisProvinceAddress () {
      return this.newCrew.addressAndContact.province.province
    },

    thisProvinceCity () {
      return this.newCrew.addressAndContact.province.city
    },

    thisProvinceBarangay () {
      return this.newCrew.addressAndContact.province.barangay
    },

    thisProvinceDescription () {
      return this.newCrew.addressAndContact.province.description
    },

    thisCrewEndorser () {
      if (this.newCrew.tracking.endorsedBy) {
        let crew = this.crews.find((val, index) => val.id === this.newCrew.tracking.endorsedBy)
        return this.crewNameLang(crew)
      }
    },

    //newCrew.tracking.pmcOffice
    thisNewCrewTrackingPmcOffice: {
      get () {
        let __office = this.objPmcOffices.find((val, index) => val.name === `${this.newCrew.tracking.pmcOffice}`)
        return __office ? __office.name : ''
      },
      set (val) {
        this.newCrew.tracking.pmcOffice = val ? val : ''
      },
    },
  },

  /** mounted **/
  mounted () {
  },

  /** created **/
  created () {
    this.getCreateNewCrewKeys()
    this.getRegionAddress()

  },


  /** watch **/
  watch: {

    /** PERMANENT ADDRESSES **/
    thisPermanentRegion (val) {
      if (val != null) {
        this.getPermanentProvinceKeys(this.newCrew.addressAndContact.permanent.region)
      }

      this.null_permanent_province()
      this.null_permanent_cities()
      this.null_permanent_barangay()
      this.newCrew.addressAndContact.permanent.province = null
      this.newCrew.addressAndContact.permanent.city = null
      this.newCrew.addressAndContact.permanent.barangay = null

      this.formDataAddress.permanent.region = val ? val.id : null
    },

    thisPermanentProvinces (val) {
      if (val != null) {
        this.getPermanentCityKeys(this.newCrew.addressAndContact.permanent.province)
      }

      this.null_permanent_cities()
      this.null_permanent_barangay()
      this.newCrew.addressAndContact.permanent.city = null
      this.newCrew.addressAndContact.permanent.barangay = null

      this.formDataAddress.permanent.province = val ? val.id : ''
    },

    thisPermanentCity (val) {
      if (val != null) {
        this.getPermanentBarangay(this.newCrew.addressAndContact.permanent.city)
      }

      this.newCrew.addressAndContact.permanent.barangay = null
      this.null_permanent_barangay()

      this.formDataAddress.permanent.city = val ? val.id : null
    },

    thisPermanentBarangay (val) {
      this.formDataAddress.permanent.barangay = val ? val.id : null
    },

    thisPermanentDescription (val) {
      this.formDataAddress.permanent.description = val ? val : null
    },

    /** TEMPORARY ADDRESSES **/
    thisTemporaryRegion (val) {
      if (val != null) {
        this.getTemporaryProvinceKeys(this.newCrew.addressAndContact.temporary.region)

      }

      this.null_temporary_provinces()
      this.null_temporary_barangay()
      this.null_temporary_barangay()
      this.newCrew.addressAndContact.temporary.province = null
      this.newCrew.addressAndContact.temporary.city = null
      this.newCrew.addressAndContact.temporary.barangay = null

      this.formDataAddress.temporary.region = val ? val.id : null

    },

    thisTemporaryProvinces (val) {
      if (val != null) {
        this.getTemporaryCityKeys(this.newCrew.addressAndContact.temporary.province)
      }
      this.null_temporary_cities()
      this.null_temporary_barangay()
      this.newCrew.addressAndContact.temporary.city = null
      this.newCrew.addressAndContact.temporary.barangay = null

      this.formDataAddress.temporary.province = val ? val.id : null
    },

    thisTemporaryCity (val) {
      if (val != null) {
        this.getTemporaryBarangayKeys(this.newCrew.addressAndContact.temporary.city)
      }

      this.null_temporary_barangay()
      this.newCrew.addressAndContact.temporary.barangay = null

      this.formDataAddress.temporary.city = val ? val.id : null
    },

    thisTemporaryBarangay (val) {
      this.formDataAddress.temporary.barangay = val ? val.id : null
    },

    thisTemporaryDescription (val) {
      this.formDataAddress.temporary.description = val ? val : null
    },

    /** PROVINCE ADDRESSES **/
    thisProvinceRegion (val) {
      if (val != null) {
        this.getProvinceAddressKeys(this.newCrew.addressAndContact.province.region)

      }

      this.null_prov_provinces()
      this.null_prov_cities()
      this.null_prov_barangay()
      this.newCrew.addressAndContact.province.province = null
      this.newCrew.addressAndContact.province.city = null
      this.newCrew.addressAndContact.province.barangay = null

      this.formDataAddress.province.region = val ? val.id : null
    },

    thisProvinceAddress (val) {
      if (val != null) {
        this.getProvinceCityKeys(this.newCrew.addressAndContact.province.province)

      }
      this.null_prov_cities()
      this.null_prov_barangay()
      this.newCrew.addressAndContact.province.city = null
      this.newCrew.addressAndContact.province.barangay = null

      this.formDataAddress.province.province = val ? val.id : null
    },

    thisProvinceCity (val) {
      if (val != null) {
        this.getProvinceBarangayKeys(this.newCrew.addressAndContact.province.city)

      }

      this.null_prov_barangay()
      this.newCrew.addressAndContact.province.barangay = null

      this.formDataAddress.province.city = val ? val.id : null
    },

    thisProvinceBarangay (val) {
      this.formDataAddress.province.barangay = val ? val.id : null
    },

    thisProvinceDescription (val) {
      this.formDataAddress.province.description = val ? val : null
    },

  },

  /** destroyed **/
  destroyed () {
    this.resetThisFormKeys()
  },
}
</script>

<style scoped>
.crew-img-container {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

.crew-img {
  width: 200px;
  height: 200px;
}

input[type="checkbox"] {
  transform: scale(2) !important;
}

</style>
