<template>
<!--  <div class="container-fluid">-->
<!--    <div class="row py-4">-->
<!--      <div class="col-md-12">-->
<!--        <div style="width: 100%;margin-top: -20px;">-->
<!--          <div style="width:100%;height:84px;">-->
<!--            <div style="width:110px;height:105px;float:left;">-->
<!--              <img :src="pmcLogo" alt="" style="width:70%; height:80%;">-->
<!--            </div>-->
<!--            <div class="mt-3" style="height:105px;float:left;">-->
<!--              <div class="row">-->
<!--                <span style="font-size: 20px">3-Month Plan</span>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <span style="font-size: 14px">As of: {{ formatCurrentDate() }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <hr/>-->

<!--        &lt;!&ndash; table  &ndash;&gt;-->
<!--        <div id="table-section" class="py-3">-->
<!--          <div class="row justify-content-center">-->


<!--            &lt;!&ndash;-&#45;&#45; COUNT PER RANK LIST &#45;&#45;&ndash;&gt;-->
<!--            <div class="col-12">-->
<!--              <div>-->
<!--                &lt;!&ndash;MASTERLIST FORMAT&ndash;&gt;-->
<!--                <table class="table table-bordered table-sm text-center sticky-header-docs-format small table-hover onboard-doc-expiry-table">-->
<!--                  <thead>-->
<!--                  <tr>-->
<!--                    <th style="width: 4%;" rowspan="2">#</th>-->
<!--                    <th rowspan="2">RANKCODE</th>-->
<!--                    <th rowspan="2">ONBOARD</th>-->
<!--                    <th rowspan="2">STANDBY</th>-->
<!--                    <th rowspan="2">APPLICANTS</th>-->
<!--                    <th rowspan="2">TOTAL</th>-->
<!--                  </tr>-->
<!--                  </thead>-->

<!--                </table>-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;-&#45;&#45; COUNT PER RANK ALL &#45;&#45;&ndash;&gt;-->
<!--            <div class="col-12">-->
<!--              <div>-->
<!--                &lt;!&ndash;MASTERLIST FORMAT&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->



<!--          </div>-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--  </div>-->
  <div class="container">
    <div style="width: 100%; margin-bottom: -10px">
      <div style="width: 100%;">
        <div style="width:100%;height:84px;">
          <div style="width:110px;height:110px;float:left;">
            <img :src="pmcLogo" alt="" style="width:80%; height:80%;">
          </div>
          <div class="mt-3" style="height:105px;float:left; margin-top: -5px !important; margin-left: 10px">
            <div class="row">
              <span style="font-size: 20px">3-month Plan</span>
            </div>
            <div class="row">
              <span style="font-size: 11px; font-style: italic">As of: {{ formatCurrentDate() }}</span>
            </div>
            <div class="row">
                <span style="font-size: 15px">
                    {{ threeMonthPlanData.eoc_date_from.month + ' ' + threeMonthPlanData.eoc_date_from.year + ' - ' + threeMonthPlanData.eoc_date_to.month + ' ' + threeMonthPlanData.eoc_date_to.year + ' (' + threeMonthPlanData.vessel_name + ')' }}
                </span>
            </div>
            <div class="row">
              <span style="font-size: 12px">
                  Status Legend:
                  <span class="legend_near_critical">Near Critical (5-7 mos / Danish 3-5 mos)</span>&nbsp;
                  <span class="legend_critical">Critical (8-10 mos / Danish 6-10 mos)</span>&nbsp;
                  <span class="legend_over_critical">Over Critical (11 and up mos)</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table id = "three-month-plan-table">
      <thead>
      <tr>
        <th style="text-align: center; width: 2%;"> # </th>
        <th style="text-align: center; width: 3.5%"> Rank </th>
        <th style="text-align: center; width: 10%;"> Crew </th>
        <th style="text-align: center; width: 6%;"> Joined </th>
        <th style="text-align: center; width: 6%;"> Promoted </th>
        <th style="text-align: center; width: 6%;"> EOC </th>
        <th style="text-align: center; width: 10%;"> Reliever </th>
        <th style="text-align: center; width: 6.5%;"> Availability of Reliever </th>
        <th style="text-align: center; width: 5%;"> CCM </th>
        <th style="text-align: center; width: 5.5%;"> Months Onboard </th>
        <th style="text-align: center;" colspan="6"> Vessel Movement </th>
      </tr>
      </thead>
      <tbody>
      <template v-for="list in threeMonthPlanData.data_to_print">
        <tr style="background-color: rgba(199,199,204,255)">
          <td colspan="2">Vessel:</td>
          <td colspan="4">{{ list.name?.toUpperCase() }}</td>
          <td>Flag:</td>
          <td colspan="3">{{ list.flag_name?.toUpperCase() }}</td>
          <td style="width: 9%">Port of Call</td>
          <td style="width: 8%">Next Port of Call</td>
          <td style="width: 8%">ETA</td>
          <td style="width: 8%">ETB</td>
          <td style="width: 8%">ETD</td>
          <td style="width: 9%">Remarks</td>
        </tr>
        <template v-for="(crew_change_plan, change_plan_index) in list.crew_change_plans">
          <tr>
            <td>{{ change_plan_index + 1 }}</td>
            <td>{{ crew_change_plan.rankAlias?.toUpperCase() }}</td>
            <td>
              <span v-if="crew_change_plan.crewSignOff">
                <span v-if="crew_change_plan.cOffType !== (3 || 4)">
                  <a href="#"
                     style="font-size: 0.8rem !important;"
                     @click="gotoProfile(crew_change_plan.crewOffId,crew_change_plan.cOffType)">
                    {{ crew_change_plan.crewSignOff?.toUpperCase() }}
                  </a>
                </span>
              </span>
              <span v-else>{{ crew_change_plan.crewSignOff?.toUpperCase() }}</span>
            </td>
            <td>{{ crew_change_plan.ccOffSignOnDate }}</td>
            <td>{{ crew_change_plan.promoted }}</td>
            <td>
              <span v-if="crew_change_plan.isExtension">
                {{ crew_change_plan.ccOffEocDateFromDocLatestContract }}
              </span>
              <span v-else>
                {{ crew_change_plan.ccOffEocDate }}
              </span>
            </td>
            <td>
              <span v-if="crew_change_plan.crewSignOn">
                <span v-if="crew_change_plan.cOnType !== (3 || 4)">
                  <a href="#"
                     style="font-size: 0.8rem !important;"
                     @click="gotoProfile(crew_change_plan.crewOnId,crew_change_plan.cOnType)">
                    {{ crew_change_plan.crewSignOn?.toUpperCase() }}
                  </a>
                </span>
                <span v-else class="ml-2 small">{{ crew_change_plan.crewSignOn }}</span>
              </span>
            </td>
            <td>{{ crew_change_plan.availability }}</td>
            <td>{{ crew_change_plan.cBatchingNo }}</td>
            <td :class="getCriticality(parseInt(crew_change_plan.monthsOnBoard))">{{ crew_change_plan.monthsOnBoard }}</td>
            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
            </td>
            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
            </td>
            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
            </td>
            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
            </td>
            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
            </td>
            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
            </td>
          </tr>
        </template>
      </template>
      </tbody>
    </table>




  </div>
</template>
<script>
import {AppMixins} from "@/mixins/app.mixins";
import {global_variables_config} from "@/config/global-variable.config";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import {CommonMixins} from "@/mixins/common.mixins";
import PmcLogo from '../../../../../assets/pmc_logo_only.jpg';

export default {
  name: "ThreeMonthPlanPrint",

  data() {
    return {
      pmcLogo: PmcLogo,
      threeMonthPlanData : null
    }
  },
  created() {
    this.threeMonthPlanData = JSON.parse(localStorage.getItem('three_month_plan_data'));
  },

  /** methods**/
  methods: {
    formatCurrentDate() {
      const options = {day: 'numeric', month: 'long', year: 'numeric'};
      return new Date().toLocaleDateString('en-GB', options);
    },

    formatCurrentDate2() {
      const options = {month: 'long', year: 'numeric'};
      return new Date().toLocaleDateString('en-GB', options);
    },
    getCriticality(monthsOnBoard) {
      if (monthsOnBoard >= 5 && monthsOnBoard <= 7) {
        return 'legend_near_critical';
      }
      if (monthsOnBoard >= 8 && monthsOnBoard <= 10) {
        return 'legend_critical';
      }
      if (monthsOnBoard >= 11) {
        return 'legend_over_critical';
      }
      return '';
    },
  },




}
</script>


<style scoped>

.container {
  top: 0;
  left: 0;
  background: #fff;
  padding: 10px;
  box-sizing: border-box;
}

#three-month-plan-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}
#three-month-plan-table tr th, #three-month-plan-table tr td {
  border: 1px solid #333333;
}
#three-month-plan-table thead tr th {
  font-size: 8pt;
}
#three-month-plan-table tbody tr td {
  padding: 1px;
  text-align: center;
  vertical-align: middle;
  font-size: 6.5pt;
}

.legend_near_critical {
  background-color: green;
  color: white;
}

.legend_critical {
  background-color: rgba(255,255,0,255);
}

.legend_over_critical {
  background-color: red;
  color: white;
}
</style>
