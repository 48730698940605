<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Owner</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newOwnerModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Owner
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search owner name"
                v-model="ownerFormParam.searchValue"
                @keypress.enter="searchOwner"
              >

              <button class="btn btn-sm pmc-btn-prime" @click="searchOwner">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm"
                        v-model="ownerFormParam.status"
                        @change="on_change_status">
                  <option :value="null">--select--</option>
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.placeholder }}
                  </option>
                </select>
              </div>
            </div>
          </div>


          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        @change="on_change_count"
                        v-model="ownerFormParam.count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="ownerPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="4%">CODE</th>
                <th scope="row" class="align-middle" width="7%">NAME</th>
                <th scope="row" class="align-middle" width="7%">DESCRIPTION</th>
                <th scope="row" class="align-middle" width="7%">ADDRESS</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">STATUS</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-if = "owners.length === 0">
                <td colspan="9">No Results</td>
              </tr>
              <tr v-else v-for="(owner, index) in owners" :key="owner.id">
                <td>{{ index + ownerPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ owner.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ owner.name }}</td>
                <td scope="col" class="p-0 text-center">{{ owner.description }}</td>
                <td scope="col" class="p-0 text-center">{{ owner.address }}</td>
                <td scope="col" class="p-0 text-center">{{ owner.created_by }}</td>
                <td scope="col" class="p-0 text-center">{{ owner.updated_by }}</td>
                <td scope="col" class="p-0 text-center pl-3">{{ owner.status == 1 ? 'ACTIVE' : 'INACTIVE' }}</td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewOwnerModal"
                    data-backdrop="static"
                    @click="ownerViewtoOwnerData = owner"
                    data-keyboard="false">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_owner(owner.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new owner modal   -->
    <div class="modal fade" id="newOwnerModal" tabindex="-1" role="dialog" aria-labelledby="newPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newPrincipalModalLabel">NEW OWNER</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newOwner.code"
                    />
                  </div>
                </div>

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newOwner.name"
                    />
                  </div>
                </div>

                <!-- new  address-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">DESCRIPTION</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newOwner.description"
                    />
                  </div>
                </div>

                <!-- new  address-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ADDRESS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newOwner.address"
                    />
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newOwner.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view owner   -->
    <div class="modal fade" id="viewOwnerModal" tabindex="-1" role="dialog"
         aria-labelledby="viewOwnerModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewOwnerModalLabel">OWNER</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="ownerData.code"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="ownerData.name"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- alias -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">DESCRIPTION</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="ownerData.description"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- address -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ADDRESS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="ownerData.address"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>


                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           class="form-check form-check-inline ml-2 mt-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                           v-model="ownerData.status"
                    />
                  </div>
                </div>


                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CREATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="ownerData.created_by"
                    />
                  </div>
                </div>

                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">UPDATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="ownerData.updated_by"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit"
            >
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable"
            >
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel"
            >
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";

export default {
  name: "List",


  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      ownerViewtoOwnerData: {},
      ownerData: {},
      newOwner: {},
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,

      pageLastLeft: 1,

    };
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_owners',
      'create_owner',
      'update_owner',
      'delete_owner',
      'null_owners',
    ]),

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Owner', 'question')
      if (await __create) {

        let params = {
          code: this.newOwner.code ? this.newOwner.code : '',
          name: this.newOwner.name, // this is required
          description: this.newOwner.description,
          address: this.newOwner.address ? this.newOwner.address : '',
          status: this.newOwner.status ? this.newOwner.status : 0,
        };

        let newOwner = await this.create_owner(params);
        if (newOwner) {
          AlertService.successAlertService('Create owner record successful', 'Create owner');
          this.hideModal('newOwnerModal')
          this.newOwner = {};
          this.set_owners();
        }

      }

    },

    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update owner', 'question');
      if (await __update) {
        let params = {
          id: this.ownerData.id,
          code: this.ownerData.code ? this.ownerData.code : '',
          name: this.ownerData.name, // this is required
          description: this.ownerData.description ? this.ownerData.description : '',
          address: this.ownerData.address ? this.ownerData.address : '',
          status: this.ownerData.status,
        };

        let updatedOwner = await this.update_owner(params);
        if (updatedOwner) {
          AlertService.successAlertService("Updated successfully", 'Update owner');
          this.hideModal('viewOwnerModal')
          this.isEditable = false
        }
        else {
          AlertService.errorAlertService("Error on Update", 'Update owner');
        }
      }
    },


    set_this_page(e) {
      this.ownerFormParam.searchValue = ''
      this.ownerPagination.currentPage = e
      this.ownerFormParam.page = e
      this.pageLastLeft = e
      this.set_owners(this.ownerFormParam)

      // this.ownerFormParam.currentPage = e;
      // this.ownerFormParam.page = e;
      // this.set_owners(this.ownerFormParam);
    },

    searchOwner() {
      if (this.ownerFormParam.searchValue === '') {
        this.ownerFormParam.page = this.pageLastLeft
        this.set_owners(this.ownerFormParam)
      }

      if (this.ownerFormParam.searchValue) {
        this.ownerFormParam.page = 1
      }

      this.set_owners(this.ownerFormParam)

      // this.ownerFormParam.page = 1;
      // this.set_owners(this.ownerFormParam);
    },

    on_change_status(e) {
      this.ownerFormParam.page = 1
      this.ownerFormParam.status = e.target.value;
      this.set_owners(this.ownerFormParam);
    },

    on_change_count(e) {
      this.ownerFormParam.page = 1
      this.ownerFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.ownerFormParam.count = e.target.value
      this.set_owners(this.ownerFormParam)

      // this.ownerFormParam.count = e.target.value;
      // this.set_owners(this.ownerFormParam);
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel(e) {
      this.isEditable = false
      this.ownerData = Object.assign({}, this.ownerViewtoOwnerData);
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'owners',
        'ownerPagination',
        'ownerFormParam',
      ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_owners(this.ownerFormParam);
  },

  /** watch **/
  watch: {
    'ownerViewtoOwnerData'() {
      this.ownerData = Object.assign({}, this.ownerViewtoOwnerData);
    },
  },

  /** destroyed **/
  destroyed() {
    this.null_owners()
  },


}
</script>

<style scoped>

</style>
