<template>
    <div class="container-fluid  bg-white">
        <div class="row text-center align-middle mt-1">
            <div class="col-12">

              <div class="m-4">
                  <h4 class="title" id="preview-print-cc-memo-title">Preview Crew Change Memo Details</h4>
                  <div class="p-4" style="border: 1px solid rgba(0,0,0,0.1);">
                    <div id="cc-details-file-container"></div>
                    <hr class="mt-1 mb-1">
                    <div class="row">
                      <div class="col-3">

                        <!-- Prepared By -->
                        <div class="form-group row text-left">
                          <label class="col-form-label col-sm-5">Prepared by</label>
                          <span class="col-form-label">:</span>
                          <div class="col-sm-12">
                            <select name="preparedBy"
                                    id="prepared-by"
                                    v-model="formApprovalSignatories.prepBy"
                                    class="form-control form-control-sm"
                                    :class="[formApprovalSignatories.prepBy ? 'has-select-signatory' : '']"
                                    @change="onChangeSignatory">
                              <option :value="formApprovalSignatories.prepBy"
                                      v-if="formApprovalSignatories.prepBy"
                                      selected
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.prepBy.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>
                          </div>
                        </div>

                        <!--   Checked By -->
                        <!--                <div class="form-group row text-left mt-2">-->
                        <!--                  <label class="col-form-label col-sm-5">Checked By</label>-->
                        <!--                  <span class="col-form-label">:</span>-->
                        <!--                  <div class="col-sm-12">-->
                        <!--                    <select name="checkedBy"-->
                        <!--                            id="checked-by"-->
                        <!--                            v-model="formApprovalSignatories.checkBy"-->
                        <!--                            :class="[formApprovalSignatories.checkBy ? 'has-select-signatory' : '']"-->
                        <!--                            @change="onChangeSignatory"-->
                        <!--                            class="form-control form-control-sm">-->
                        <!--                      <option :value="formApprovalSignatories.checkBy"-->
                        <!--                              v-if="formApprovalSignatories.checkBy"-->
                        <!--                              selected-->
                        <!--                              class="text-left align-middle">-->
                        <!--                        <span>{{ formApprovalSignatories.checkBy.name }}</span>-->
                        <!--                      </option>-->
                        <!--                      <option :value="null" class="text-center align-middle">&#45;&#45;SELECTED&#45;&#45;</option>-->
                        <!--                      <option :value="emp"-->
                        <!--                              v-for="(emp,index) in objEmployees"-->
                        <!--                              :key="emp.id"-->
                        <!--                              style="font-size: 0.8rem; display: block"-->
                        <!--                              class="text-left align-middle">-->
                        <!--                        <span>{{ emp.name }}</span>-->
                        <!--                      </option>-->
                        <!--                    </select>-->


                        <!--                    <select name="checkedBy2"-->
                        <!--                            id="checked-by-2"-->
                        <!--                            v-model="formApprovalSignatories.checkBy2"-->
                        <!--                            :class="[formApprovalSignatories.checkBy2 ? 'has-select-signatory' : '']"-->
                        <!--                            @change="onChangeSignatory"-->
                        <!--                            class="form-control form-control-sm mt-1">-->
                        <!--                      <option :value="formApprovalSignatories.checkBy2"-->
                        <!--                              v-if="formApprovalSignatories.checkBy2"-->
                        <!--                              selected-->
                        <!--                              class="text-left align-middle">-->
                        <!--                        <span>{{ formApprovalSignatories.checkBy2.name }}</span>-->
                        <!--                      </option>-->
                        <!--                      <option :value="null" class="text-center align-middle">&#45;&#45;SELECTED&#45;&#45;</option>-->
                        <!--                      <option :value="emp"-->
                        <!--                              v-for="(emp,index) in objEmployees"-->
                        <!--                              :key="emp.id"-->
                        <!--                              style="font-size: 0.8rem; display: block"-->
                        <!--                              class="text-left align-middle">-->
                        <!--                        <span>{{ emp.name }}</span>-->
                        <!--                      </option>-->
                        <!--                    </select>-->
                        <!--                  </div>-->
                        <!--                </div>-->
                      </div>

                      <div class="col-3">

                        <!-- Noted by -->
                        <div class="form-group row text-left">
                          <label class="col-form-label col-sm-5">Noted By</label>
                          <span class="col-form-label">:</span>
                          <div class="col-sm-12">
                            <select name="notedBy"
                                    @change="onChangeSignatory"
                                    v-model="formApprovalSignatories.notedBy"
                                    :class="[formApprovalSignatories.notedBy ? 'has-select-signatory' : '']"
                                    class="form-control form-control-sm">
                              <option :value="formApprovalSignatories.notedBy"
                                      v-if="formApprovalSignatories.notedBy"
                                      selected
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedBy.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>

                            <select name="notedBy2"
                                    @change="onChangeSignatory"
                                    v-model="formApprovalSignatories.notedBy2"
                                    :class="[formApprovalSignatories.notedBy2 ? 'has-select-signatory' : '']"
                                    class="form-control form-control-sm mt-1">
                              <option :value="formApprovalSignatories.notedBy2"
                                      v-if="formApprovalSignatories.notedBy2"
                                      selected
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedBy2.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>

                            <select name="notedBy3"
                                    @change="onChangeSignatory"
                                    v-model="formApprovalSignatories.notedBy3"
                                    :class="[formApprovalSignatories.notedBy3 ? 'has-select-signatory' : '']"
                                    class="form-control form-control-sm mt-1">
                              <option :value="formApprovalSignatories.notedBy3"
                                      v-if="formApprovalSignatories.notedBy3"
                                      selected
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedBy3.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>

                            <select name="notedBy4"
                                    @change="onChangeSignatory"
                                    v-model="formApprovalSignatories.notedBy4"
                                    :class="[formApprovalSignatories.notedBy4 ? 'has-select-signatory' : '']"
                                    class="form-control form-control-sm mt-1">
                              <option v-if="formApprovalSignatories.notedBy4"
                                      :value="formApprovalSignatories.notedBy4"
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedBy4.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-3">

                        <!-- Reviewed By -->
                        <div class="form-group row text-left">
                          <label class="col-form-label col-sm-5">Reviewed By</label>
                          <span class="col-form-label">:</span>
                          <div class="col-sm-12">
                            <select name="reviewedBy"
                                    id="reviewed-by"
                                    v-model="formApprovalSignatories.reviewedBy"
                                    :class="[formApprovalSignatories.reviewedBy ? 'has-select-signatory' : '']"
                                    @change="onChangeSignatory"
                                    class="form-control form-control-sm">
                              <option :value="formApprovalSignatories.reviewedBy"
                                      v-if="formApprovalSignatories.reviewedBy"
                                      selected
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.reviewedBy.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>
                          </div>

                          <!--  TODO review by 2 s -->
                          <div class="col-sm-12 mt-1">
                            <select name="reviewedBy2"
                                    id="reviewed-by-2"
                                    v-model="formApprovalSignatories.reviewedBy2"
                                    :class="[formApprovalSignatories.reviewedBy2 ? 'has-select-signatory' : '']"
                                    @change="onChangeSignatory"
                                    class="form-control form-control-sm">
                              <option :value="formApprovalSignatories.reviewedBy2"
                                      v-if="formApprovalSignatories.reviewedBy2"
                                      selected
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.reviewedBy2.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>
                          </div>

                          <!--  TODO review 3  -->
                          <div class="col-sm-12 mt-1">
                            <select name="reviewedBy3"
                                    id="reviewed-by-3"
                                    v-model="formApprovalSignatories.reviewedBy3"
                                    :class="[formApprovalSignatories.reviewedBy3 ? 'has-select-signatory' : '']"
                                    @change="onChangeSignatory"
                                    class="form-control form-control-sm">
                              <option :value="formApprovalSignatories.reviewedBy3"
                                      v-if="formApprovalSignatories.reviewedBy3"
                                      selected
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.reviewedBy3.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-3">
                        <!-- Noted and Approved By -->
                        <div class="form-group row text-left">
                          <label class="col-form-label col-sm-8">Noted and Approved By</label>
                          <span class="col-form-label">:</span>
                          <div class="col-sm-12">
                            <select name="notedApprovedBy"
                                    @change="onChangeSignatory"
                                    v-model="formApprovalSignatories.notedAprBy"
                                    :class="[formApprovalSignatories.notedAprBy ? 'has-select-signatory' : '']"
                                    class="form-control form-control-sm">
                              <option :value="formApprovalSignatories.notedAprBy"
                                      v-if="formApprovalSignatories.notedAprBy"
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedAprBy.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>

                            <select name="notedApprovedBy2"
                                    @change="onChangeSignatory"
                                    v-model="formApprovalSignatories.notedAprBy2"
                                    :class="[formApprovalSignatories.notedAprBy2 ? 'has-select-signatory' : '']"
                                    class="form-control form-control-sm mt-1">
                              <option v-if="formApprovalSignatories.notedAprBy2"
                                      :value="formApprovalSignatories.notedAprBy2"
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedAprBy2.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>



                            <select name="notedApprovedBy3"
                                    @change="onChangeSignatory"
                                    v-model="formApprovalSignatories.notedAprBy3"
                                    :class="[formApprovalSignatories.notedAprBy3 ? 'has-select-signatory' : '']"
                                    class="form-control form-control-sm mt-1">
                              <option v-if="formApprovalSignatories.notedAprBy3"
                                      :value="formApprovalSignatories.notedAprBy3"
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedAprBy3.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>

                            <select name="notedApprovedBy4"
                                    @change="onChangeSignatory"
                                    v-model="formApprovalSignatories.notedAprBy4"
                                    :class="[formApprovalSignatories.notedAprBy4 ? 'has-select-signatory' : '']"
                                    class="form-control form-control-sm mt-1">
                              <option v-if="formApprovalSignatories.notedAprBy4"
                                      :value="formApprovalSignatories.notedAprBy4"
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedAprBy4.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>

                            <select name="notedApprovedBy5"
                                    @change="onChangeSignatory"
                                    v-model="formApprovalSignatories.notedAprBy5"
                                    :class="[formApprovalSignatories.notedAprBy5 ? 'has-select-signatory' : '']"
                                    class="form-control form-control-sm mt-1">
                              <option :value="formApprovalSignatories.notedAprBy5"
                                      v-if="formApprovalSignatories.notedAprBy5"
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedAprBy5.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>

                            <select name="notedApprovedBy6"
                                    @change="onChangeSignatory"
                                    v-model="formApprovalSignatories.notedAprBy6"
                                    :class="[formApprovalSignatories.notedAprBy6 ? 'has-select-signatory' : '']"
                                    class="form-control form-control-sm mt-1">
                              <option :value="formApprovalSignatories.notedAprBy6"
                                      class="text-left align-middle"
                                      v-if="formApprovalSignatories.notedAprBy6">
                                <span>{{ formApprovalSignatories.notedAprBy6.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>

                            <select name="notedApprovedBy7"
                                    @change="onChangeSignatory"
                                    :class="[formApprovalSignatories.notedAprBy7 ? 'has-select-signatory' : '']"
                                    v-model="formApprovalSignatories.notedAprBy7"
                                    class="form-control form-control-sm mt-1">
                              <option :value="formApprovalSignatories.notedAprBy7"
                                      v-if="formApprovalSignatories.notedAprBy7"
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedAprBy7.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED--</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>

                            <select name="notedApprovedBy8"
                                    @change="onChangeSignatory"
                                    :class="[formApprovalSignatories.notedAprBy8 ? 'has-select-signatory' : '']"
                                    v-model="formApprovalSignatories.notedAprBy8"
                                    class="form-control form-control-sm mt-1">
                              <option :value="formApprovalSignatories.notedAprBy8"
                                      v-if="formApprovalSignatories.notedAprBy8"
                                      class="text-left align-middle">
                                <span>{{ formApprovalSignatories.notedAprBy8.name }}</span>
                              </option>
                              <option :value="null" class="text-center align-middle">--SELECTED</option>
                              <option :value="emp"
                                      v-for="(emp,index) in objEmployees"
                                      :key="emp.id"
                                      style="font-size: 0.8rem; display: block"
                                      class="text-left align-middle">
                                <span>{{ emp.name }}</span>
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>

import {AlertMixins} from '@/mixins/alert.mixins';
import {AppMixins} from '@/mixins/app.mixins';
import {mapActions, mapGetters} from 'vuex';
import {global_variables_config} from '@/config/global-variable.config';
import Swal from 'sweetalert2';
import {AlertService} from '@/service/alert.service';
import Table from '@/components/base/Table.vue';
import Vue from 'vue';
export default {
  name: 'PreviewPrintCCMemo',

  /**
   *@author move custom component in component.config.js redo if the code not working accordingly.
   */
  components: {
    Table,
    // Button,
    // Pagination,
    // 'ccm-master-checklist-modal-body': CcMasterlistChecklist,
    // 'ccm-master-checklist-mlcolc-modal-body': CcMasterlistChecklistMLCOLC,
    // 'ccm-master-checklist-document-modal-body': CcMasterlistChecklistDocument,
    // 'ccm-master-checklist-doc-cert-monitoring-modal-body': CcMasterlistChecklistDocCertMonitoring,
  },
  mixins: [AlertMixins, AppMixins],

  props: {
    batchId: {
      type: Number,
    },
    vesselId: {
      type: Number,
    },
  },

  /** data **/
  data() {
    return {
      ccOnResHeight: 130,
      ccOffResHeight: 130,
      isCcMemoEditable: false,
      // deletedCcIds : [],
      formCcMemoRemarks: {},
      documentChecklist: {
        poeaContract: null,
        ITF: null,
        infoSheet: null,
        rps: null,
        rpsAmendment: null,
        crewChecklist: null,
        crewName: null,
      },

      ccmDocsParams: {
        crewId: null,
        vesselId: null,
        ccmDocument: null,
        signatory: null,
        contract: 9,
        printDate: new Date().toISOString().slice(0, 10),
        remarks: '',
        page: 1,
      },

      ccmMasterChecklistParams: {
        crewId: null,
        ccmMasterChecklist: null,
        vesselId: null,
        batchId: null,
        isSignOnCrews: 1,
        signatory: null,
        ccmNo: null,
      },

      crewBatchId: null,
      crewBatchNo: null,

      formApprovalSignatories: {
        prepBy: null,
        checkBy: null,
        checkBy2: null,
        reviewedBy: null,
        reviewedBy2: null,
        reviewedBy3: null,
        notedBy: null,
        notedBy2: null,
        notedBy3: null,
        notedBy4: null,
        notedAprBy: null,
        notedAprBy2: null,
        notedAprBy3: null,
        notedAprBy4: null,
        notedAprBy5: null,
        notedAprBy6: null,
        notedAprBy7: null,
        notedAprBy8: null,
      },

      tempRemArrSignatory: [],
    };
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          // 'vesselDetails',

          'crewChangeMemo',
          'isCcMemo',

          'ccMemoRemarks',
          'ccMemoRemark',
          'newCcMemoRemark',

          'isBatchListing',
          'noBatchSignOnList',
          'hasBatchSignOnList',
          'addInBatch',
          'removeInBatch',

          // ccm master checklist
          'ccmMasterChecklist',
          'ccmFlagId',
          'ccmFlagName',
          'isReloading',

          'ccMemoDetailFile',
          'objEmployees',
        ],
    ),
  },

  /** methods**/
  methods: {
    ...mapActions(
        [
          'reset_cc_memo_state',
          'update_crew_change_remark',
          'delete_crew_change_remark',
          'generate_cc_memo_details',
          'get_cc_memo_details',
          'create_cc_memo_details',

          //batching remark
          // 'set_batching_remark', ,
          // 'create_batching_remarks',
          // 'update_batching_remarks',
          // 'delete_batching_remark',

          //new batching remarks
          'set_cc_memo_remark',
          'create_cc_memo_remarks',
          'update_cc_memo_remarks',
          'delete_cc_memo_remarks',

          'set_null_cc_memo',

          'reset_null_batch_list_state',
          'set_update_cc_memo',
          'update_cc_memo_details',

          'add_in_batch',
          'remove_in_batch',

          'confirm_add_batch_memo',
          'confirm_delete_batch_memo',

          'set_null_add_in_batch',
          'set_null_remove_in_batch',
          'reset_cc_memo_remark_state',

          // generate ccm document ex. ITF, RPS, INFO SHEET
          'generateCcmDocument',

          // generate ccm masterlist ex. view checklist, mlc olc, checklist docs
          'generateCcmMasterChecklist',
          'getCcmMasterChecklistDetails',

          'preview_print_cc_memo',
          'download_cc_memo',
          'null_obj_employee',

          'revision_up_cc_memo',
          'revision_down_cc_memo',
          'approved_cc_memo',
          'dis_approved_cc_memo',

          //generate covid vaccine report
          'generateCcMemoCovidVaccineReport',
        ],
    ),

    /**
     * @param args collection | array
     * @param type 1: signOnCrew , 2:signOff
     */
    updateTableRowSize(args, type) {

      if (args !== undefined) {
        if (args.length >= 2) {
          if (type === 1) {
            this.ccOnResHeight = 160;

          } else {
            this.ccOffResHeight = 160;
          }
        }

        if (args.length >= 3) {
          if (type === 1) {
            this.ccOnResHeight = 180;
          } else {
            this.ccOffResHeight = 180;
          }
        }

        if (args.length >= 5) {
          if (type === 1) {
            this.ccOnResHeight = 200;
          } else {
            this.ccOffResHeight = 200;
          }
        }

        if (args.length >= 7) {
          if (type === 1) {
            this.ccOnResHeight = 220;
          } else {
            this.ccOffResHeight = 220;
          }
        }

        if (args.length >= 9) {
          if (type === 1) {
            this.ccOnResHeight = 240;
          } else {
            this.ccOffResHeight = 240;
          }
        }
      }
    },

    /** crew change memo function **/
    generateCcMemoDetails(e) {
      if (this.vesselId != 0) {
        let formData = new FormData();
        formData.append('selectedCrews', JSON.stringify(this.crews));
        formData.append('vesselId', this.vesselId);
        this.generate_cc_memo_details(formData);
      }
    },

    fileContainer(fileScr) {
      //#toolbar=0
      let fileFrame = `<iframe src="${fileScr}" id="cc-details-file-frame" style="height: 400px; width: 100%;" frameborder="0"></iframe>`;
      $(`#cc-details-file-container`).append(fileFrame);
    },

    async previewPrintCcMemo() {
      const formData = {
        vesselId: this.vesselId,
        batchId: this.batchId,
        isDownload: false,
      };

      let loading = `<div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>`;
      $(`#cc-details-file-container`).append(loading);
      this.$nextTick(() => {
        this.preview_print_cc_memo(formData).then((r) => {
          if (r) {
            $(`#cc-details-file-container`).empty();
            let fileScr = this.base64PdfParser(r);
            this.fileContainer(fileScr);
          }
        });
      });
    },

    //TODO downloadCcMemo
    async downloadCcMemo() {
      let objSignatories = this.formApprovalSignatories;
      let objectProp = Object.values(objSignatories).every(value => {
        if (value == null) {
          return true;
        }
        return false;
      });

      const formData = {
        vesselId: this.vesselId,
        batchId: this.batchId,
        isDownload: true,
      };

      if (!objectProp) {
        formData.formApprovalSignatories = this.formApprovalSignatories;
      }

      if (await this.questionAlert('Do you want to download')) {
        this.download_cc_memo(formData).then((r) => {
          if (r) {
            this.successAlert('Download Crew Change Memo Details Successfully');
          }
        });
      }
    },

    closePreviewPrintCcMemo() {
      //Close something , need to learn.
      $(`#cc-details-file-container`).empty();
      $(`#preview-print-cc-memo`).modal('hide');
      this.null_obj_employee();
      this.formApprovalSignatories = {
        prepBy: null,
        checkBy: null,
        checkBy2: null,
        reviewedBy: null,
        reviewedBy2: null,
        reviewedBy3: null,
        notedBy: null,
        notedBy2: null,
        notedBy3: null,
        notedBy4: null,
        notedAprBy: null,
        notedAprBy2: null,
        notedAprBy3: null,
        notedAprBy4: null,
        notedAprBy5: null,
        notedAprBy6: null,
        notedAprBy7: null,
        notedAprBy8: null,
      };
      const formData = {
        vesselId: this.vesselId,
        batchId: this.crewBatchId,
        isDownload: false,
      };
      //this.preview_print_cc_memo(formData).then((r) => {});
    },

    onChangeSignatory() {
      const formData = {
        vesselId: this.vesselId,
        batchId: this.batchId,
        formApprovalSignatories: this.formApprovalSignatories,
        isDownload: false,
      };
      $(`#cc-details-file-container`).empty();

      let loading = `<div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>`;

      $(`#cc-details-file-container`).append(loading);

      this.preview_print_cc_memo(formData).then((r) => {
        if (r) {
          $(`#cc-details-file-container`).empty();
          let fileScr = this.base64PdfParser(r);
          this.fileContainer(fileScr);
        }
      });
    },

    setUpdateCcMemo() {
      $(`#no-batch-list-modal`).modal({
        backdrop: 'static',
        keyboard: false,
      });

      const formData = {
        vesselId: this.vesselId,
        batchId: this.batchId,
      };

      this.set_update_cc_memo(formData).then((r) => {
        this.$nextTick(() => {
        });
      });
    },

    setAddInBatch(e, thisObj) {
      if (e.target.checked) {
        const formData = {
          type: 1,
          obj: thisObj,
        };
        this.add_in_batch(formData);

      } else {
        const formData = {
          type: 2,
          obj: thisObj,
        };
        this.add_in_batch(formData);
      }
    },

    setRemoveInBatch(e, thisObj) {
      if (e.target.checked) {
        const formData = {
          type: 1,
          obj: thisObj,
        };
        this.remove_in_batch(formData);
      } else {
        const formData = {
          type: 2,
          obj: thisObj,
        };
        this.remove_in_batch(formData);
      }
    },

    async confirmAddBatchMemo() {
      let msg = `Do you want to the changes do you made`;

      if (await this.questionAlert(`${msg}`, 'Confirm Add to Batch')) {
        const formData = {
          batchId: this.crewChangeMemo.ccMemoId,
          ccRelations: this.addInBatch,
          vesselId: this.vesselId,
        };
        this.confirm_add_batch_memo(formData).then((r) => {
          if (r) {
            this.$nextTick(() => {
              $(`input[id='no-batch-action']`).each(function() {
                this.checked = false;
              });
              this.set_null_add_in_batch();
              this.successAlert('Adding to batch successfully.');
              this.get_cc_memo_details(
                  {
                    batchId: this.crewChangeMemo.ccMemoId,
                    vesselId: this.vesselId,
                  },
              );
            });
          }
        });
      }
    },

    async confirmDeleteBatchMemo() {
      let msg = `Do you want to the changes do you made`;

      if (await this.questionAlert(`${msg}`, 'Confirm Remove to Batch')) {
        const formData = {
          batchId: this.crewChangeMemo.ccMemoId,
          ccRelations: this.removeInBatch,
          vesselId: this.vesselId,
        };
        this.confirm_delete_batch_memo(formData).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert('Removing to batch successfully');
              $(`input[id='has-batch-action']`).each(function() {
                this.checked = false;
              });

              this.set_null_remove_in_batch();

              if (this.hasBatchSignOnList.length == 0) {
                this.set_null_cc_memo();
                this.reset_null_batch_list_state();
                $(`#no-batch-list-modal`).modal('hide');
                this.bus.$emit('closeCcMemoModal');

              } else {
                this.get_cc_memo_details(
                    {
                      batchId: this.crewChangeMemo.ccMemoId,
                      vesselId: this.vesselId,
                    },
                );
              }
            });
          }
        });
      }
    },

    closeNoBatchList() {
      $(`#no-batch-list-modal`).modal('hide');
      this.reset_null_batch_list_state();
    },

    //save cc memo without batching details
    async saveCcMemo() {
      let msg = `Do you want to save the changes that you made?`;
      if (await this.questionAlert(`${msg}`, 'Save Crew Change Memo')) {
        let thisCcRelIds = [];

        if (this.crewChangeMemo.signOnCrews !== undefined) {
          this.crewChangeMemo.signOnCrews.forEach((val, index) => {
            thisCcRelIds.push(val.ccrId); //push crew relation id,
          });
        }

        const params = {
          ccRelationIds: thisCcRelIds,
          ccMemoVesselId: this.crewChangeMemo.ccMemoVesselId,
          ccMemoRemarks: this.ccMemoRemarks,
          ccMemoId: this.crewChangeMemo.ccMemoId,
        };

        //not having a ccMemoId
        if (!this.crewChangeMemo.ccMemoId) {
          await this.create_cc_memo_details(params).then((r) => {
            if (r) {
              this.$nextTick(() => {
                this.successAlert('CC Memo successfully created.');
              });
            }
          });
        } else {
        }
      }
    },

    async revisionUpCcMemo(objCcMemo) {
      console.log(objCcMemo);
      let ccMemoId = objCcMemo.ccMemoId;
      if (await this.questionAlert('Do you want to revision up this crew change memo.?', 'Revision up')) {
        this.revision_up_cc_memo(ccMemoId).finally(() => {
          this.$nextTick(() => {
            this.successAlert('Revision up successfully', 'Revision Up');
          });
        });
      }
    },

    async revisionDownCcMemo(objCcMemo) {
      let ccMemoId = objCcMemo.ccMemoId;
      if (await this.questionAlert('Do you want to revision down this crew change memo.?', 'Revision down')) {
        this.revision_down_cc_memo(ccMemoId).finally(() => {
          this.$nextTick(() => {
            this.successAlert('Revision down successfully', 'Revision down');
          });
        });
      }
    },

    async approvedCcMemo(objCcMemo) {
      console.log(objCcMemo);
      let ccMemoId = objCcMemo.ccMemoId;
      if (await this.questionAlert('Do you want to approve this crew change memo.?', 'Approve')) {
        this.approved_cc_memo(ccMemoId).finally(() => {
          // console.log('approvedCcMemo:finally',f
          this.successAlert('Approve successfully');
        });
      }
    },

    async disApprovedCcMemo(objCcMemo) {
      console.log(objCcMemo);
      let ccMemoId = objCcMemo.ccMemoId;
      if (await this.questionAlert('Do you want to approve this crew change memo', 'Approve')) {
        this.dis_approved_cc_memo(ccMemoId).finally(() => {
          // console.log("disApprovedCcMemo:finally",f);
          this.successAlert('Disapprove successfully');
        });
      }
    },

    viewCovidRecords() {
      //TODO goto covid vaccine routes .
    },

    /** end of crew change memo function methods **/

    /** start of batching remarks actions **/
    closeAddRemarks() {
      $(`#add-ccmemo-remarks-modal`).modal('hide');
    },

    closeDocumentListModal() {
      $(`#documentListModal`).modal('hide');
    },

    closeMasterCheckListModal() {
      $(`#checklist-reports`).modal('hide');
    },

    closeViewChecklistModal() {
      $(`#view-checklist-signon`).modal('hide');
    },

    closeViewChecklistMLCOLCModal() {
      $(`#view-checklist-mlcolc-signon`).modal('hide');
    },

    closeViewChecklistDocumentModal() {
      $(`#view-checklist-document-signon`).modal('hide');
    },

    closeViewChecklistDocCertMonitoringModal() {
      $(`#view-checklist-doc-cert-monitoring-signon`).modal('hide');
    },

    closeViewCovidModal() {
      $(`#covid-vaccine-record`).modal('hide');
    },

    clearRemarks() {
      this.newCcMemoRemark.remarks = '';
    },

    cancelRemarks() {
      this.closeAddRemarks();
    },

    async saveCcMemoRemarks() {
      let msg = `Do you want to save the inputed remarks?`;
      if (await this.questionAlert(`${msg}`, 'Add Remarks')) {
        this.newCcMemoRemark.ccMemoId = this.crewChangeMemo.ccMemoId;
        this.create_cc_memo_remarks(this.newCcMemoRemark).then((r) => {
          if (r) {
            this.successAlert('Remarks add successfully', 'New Remarks');
          }
        });
      }
    },

    setEditCcMemoRemarks(ccMemoRemark, index) {
      $(`#ccm-remarks-${index}`).attr('disabled', false);
      $(`#ccm-remarks-${index}`).focus();

      $(`#btn-ccmemo-remark-update-${index}`).attr('hidden', false);
      $(`#btn-ccmemo-remark-cancel-${index}`).attr('hidden', false);

      $(`#btn-ccmemo-remark-delete-${index}`).attr('hidden', true);
      $(`#btn-ccmemo-remark-edit-${index}`).attr('hidden', true);
    },

    setCancelCcMemoRemark(ccMemoRemark, index) {
      $(`#ccm-remarks-${index}`).attr('disabled', true);

      $(`#btn-ccmemo-remark-update-${index}`).attr('hidden', true);
      $(`#btn-ccmemo-remark-cancel-${index}`).attr('hidden', true);

      $(`#btn-ccmemo-remark-delete-${index}`).attr('hidden', false);
      $(`#btn-ccmemo-remark-edit-${index}`).attr('hidden', false);

      this.set_cc_memo_remark(ccMemoRemark);
    },

    async updateCcMemoRemarks(ccMemoRemark, index) {
      let msg = `Do you want to save the changes that you made in crew change memo details ?`;
      if (await this.questionAlert(`${msg}`, 'Update Remarks')) {
        this.update_cc_memo_remarks(ccMemoRemark).then((r) => {
          if (r) {
            this.successAlert('Remarks update successfully');
            $(`#ccm-remarks-${index}`).attr('disabled', true);

            $(`#btn-ccmemo-remark-update-${index}`).attr('hidden', true);
            $(`#btn-ccmemo-remark-cancel-${index}`).attr('hidden', true);

            $(`#btn-ccmemo-remark-delete-${index}`).attr('hidden', false);
            $(`#btn-ccmemo-remark-edit-${index}`).attr('hidden', false);
          }
        });
      }
    },

    async deleteCcMemoRemarks(ccMemoRemarkId) {
      let msg = `Do you want to delete this remarks record. ?`;
      if (await this.questionAlert(msg, 'Delete Remarks')) {
        this.delete_cc_memo_remarks(ccMemoRemarkId).then((r) => {
          this.successAlert('Remarks deleted successfully', 'Delete');
        });
      }
    },

    resetCcMemoRemarkState() {
      this.reset_cc_memo_remark_state();
    },

    /** end of batching remarks action  **/

    setNullCcMemo() { //
      this.set_null_cc_memo();
    },

    getCcMemoDetails(crewBatchingId, crewBatchingNo) {
      this.crewBatchId = crewBatchingId;
      this.crewBatchNo = crewBatchingNo;
      this.ccmMasterChecklistParams.ccmNo = crewBatchingNo;

      const formParam = {
        batchId: `${crewBatchingId}`, // crewBatchingId from the parent component.
        vesselId: this.vesselId,
      };
      this.get_cc_memo_details(formParam).then((r) => {
        if (r) {
          this.$nextTick(() => {
          });
        }
      });
    },

    crewPreDepChecklist(crewId) {
      const routeData = this.$router.resolve({name: 'SubPreDepartureChecklist', params: {crewId: crewId}});
      window.open(routeData.href, '_blank');
    },

    getCrewDocumentsParam(crewData) {
      this.documentChecklist.crewName = '(' + crewData.rankAlias + ') ' + crewData.crewName;
      this.documentChecklist.crewChecklist = crewData.crewId;
    },

    gotoCovidVaccineProfile(objCrewParam, ccMemoType) {
      if (objCrewParam && ccMemoType === undefined) {
        return;
      }

      let thisComponentName = 'ccmemo';
      let thisCrewId = objCrewParam.crewId;

      let routeData = this.$router.resolve({
            name: 'CrewCovidVaccine',
            params: {
              crewId: `${thisCrewId}`,
            },
            query: {
              view: `${thisComponentName}`,
              docType: 11, //index id of covid vaccine,
            },
          },
      );
      window.open(routeData.href, '_blank');
    },

    // async generateCrewInfoSheet(params) {
    //   return await axios.post(PMC.GENERATE_CREW_INFO_SHEET, params).then(res => {
    //     return res.data.data ? FileService.base64FileToBlobUrl(res.data.data) : false;
    //   }).catch(error => {
    //     UtilityService.generateResponseMessage(error, 'Generate Crew Info Sheet')
    //     return false
    //   });
    // },

    // async loadChecklistPDF(crewId) {
    //   let params = {
    //     'crew_id': crewId
    //   };
    //   let checklistFile = await this.generateCrewInfoSheet(params);
    //   if (checklistFile) {
    //     this.pdfViewer('Information Sheet', checklistFile);
    //   } else {
    //   }
    // },

    async loadCcmDocument(ccmDoc, crewId) {
      this.ccmDocsParams.ccmDocument = ccmDoc;
      this.ccmDocsParams.crewId = crewId;
      this.ccmDocsParams.vesselId = this.vesselId;

      AlertService.loadingAlert('Please wait ...', 'Generating PDF');
      let ccmDocumentFile = await this.generateCcmDocument(this.ccmDocsParams);
      if (ccmDocumentFile) {
        Swal.close();
        if (ccmDoc === 6) {
          await this.pdfViewerInterOcean(global_variables_config.ccmDocsName[ccmDoc] + ' File', ccmDocumentFile.page1,
              ccmDocumentFile.page2);
        } else {
          await this.pdfViewer(global_variables_config.ccmDocsName[ccmDoc] + ' File', ccmDocumentFile);
        }
      } else {
        Swal.close();
      }
    },

    async loadCcmMasterChecklist(ccmMasterChecklist, crewId) {
      this.ccmMasterChecklistParams.ccmMasterChecklist = ccmMasterChecklist;
      this.ccmMasterChecklistParams.batchId = this.crewChangeMemo.ccMemoId;
      this.ccmMasterChecklistParams.vesselId = this.vesselId;

      AlertService.loadingAlert('Please wait ...', 'Generating PDF');
      let ccmDocumentFile = await this.generateCcmMasterChecklist(this.ccmMasterChecklistParams);
      if (ccmDocumentFile) {
        Swal.close();
        await this.pdfViewerMasterChecklist(global_variables_config.ccmMasterDocsName[ccmMasterChecklist] + ' File',
            ccmDocumentFile);
      } else {
        Swal.close();
      }
    },

    getCcmMasterListSignOn(isSignOn) {
      this.ccmMasterChecklistParams.batchId = this.crewChangeMemo.ccMemoId;
      this.ccmMasterChecklistParams.vesselId = this.vesselId;
      this.ccmMasterChecklistParams.isSignOnCrews = isSignOn;
      this.getCcmMasterChecklistDetails(this.ccmMasterChecklistParams);
    },

    refreshGetCcmMasterChecklistDetails() {
      this.getCcmMasterChecklistDetails(this.ccmMasterChecklistParams);
    },

    refreshGetCcmCovid() {
      this.get_cc_memo_details(
          {
            batchId: this.crewChangeMemo.ccMemoId,
            vesselId: this.vesselId,
          },
      );
    },

    async loadCcMemoCovidVaccineReport() {
      this.ccmMasterChecklistParams.batchId = this.crewChangeMemo.ccMemoId;
      this.ccmMasterChecklistParams.vesselId = this.vesselId;

      AlertService.loadingAlert('Please wait ...', 'Generating PDF');
      let ccmCovidVaccineFile = await this.generateCcMemoCovidVaccineReport({
        batchId: this.crewChangeMemo.ccMemoId,
        vesselId: this.vesselId,
      });
      if (ccmCovidVaccineFile) {
        Swal.close();
        await this.pdfViewerMasterChecklist('Covid Vaccine Report File', ccmCovidVaccineFile);
      } else {
        Swal.close();
      }
    },

  },

  mounted() {
    this.previewPrintCcMemo();
    document.getElementById("top-navigation").remove();
  },
  /** created **/
  created() {
    this.$globalVariables = global_variables_config;
  },

  beforeDestroy() {
    this.$globalVariables = null;
  },

  /** watch **/
  watch: {
    crews(val) {
      this.updateTableRowSize(val, 1);
      this.updateTableRowSize(val, 2);
    },

      /*loadData(val) {
       if(val){
       this.loadCrewChangeMemo();
       }
       },*/

    crewBatchId(val) {
      if (val !== 0) {
        //TODO get the batching details from the back end.
      }
    },

    // signOnCrews(val){
    //   this.updateTableRowSize(val,1)
    // },

    // signOffCrews(val){
    //   this.updateTableRowSize(val,2)
    // }

    'crewChangeMemo.signOnCrews': function(newVal, OldVal) {
      this.updateTableRowSize(newVal, 1);
    },

    'crewChangeMemo.signOffCrews': function(newVal, OldVal) {
      this.updateTableRowSize(newVal, 2);
    },
  },

  /** destroyed **/
  destroyed() {
    this.reset_cc_memo_state();
    this.crewBatchId = null;
  },
};
</script>
<style scoped>

input[type="checkbox"], input[type="radio"] {
    transform: scale(2) !important;
}

#documentListModal .modal-content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    /*background: linear-gradient(265deg, rgba(2,0,36,1) 0%, rgba(8,20,224,1) 35%, rgba(0,212,255,1) 100%);*/
}


.mytable {
    border-collapse: collapse;
    width: 100%;
}

.mytable th, td {
    text-align: left;
    padding: 10px;
}

.mytable th {
    background-color: #4CAF50;
    color: white;
}

/* CSS for the font size */
.report-button-font-size {
    font-size: 33px;
}

.has-select-signatory {
    border: 3px solid #55ff41 !important;
    box-shadow: 0 0 10px #719ECE !important;
}

.default-crew-css {
    color: black !important;
}

/*
//TODO make a css design for scholar tagging
*/
.fast-track {
    color: #ff090a !important;
}

.bridging-cadet {
    color: #ff901f !important
}

.maap {
    color: #28a745 !important
}

.custom-modal-size {
    max-width: 80%;
    margin: 0 auto;
}

.msap {
    color: #9f03ff !important
}

.sponsored-hrm {
    color: #1205ff !important
}

.utility-program {
    color: #156276 !important
}
</style>
