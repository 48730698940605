<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Crew Promotion Per Rank</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">

                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">Year Range</label>
                      <span class="col-form-label">:</span>
                        <div style="width: 58.5%;" class="year-range row">
                          <div class="col-12 col-sm-6">
                            <select name="list-type"
                                    class="custom-select custom-select-sm"
                                    v-model="reportParam.year"
                                    @change="selectReportTypeYear"
                                    required>
                              <option :value="0">From</option>
                              <option v-for="(year, index) in years" :key="index" :value="year">{{ year }}</option>
                            </select>
                          </div>

                          <div class="col-12 col-sm-6">
                            <select name="list-type"
                                    class="custom-select custom-select-sm"
                                    v-model="reportParam.year2"
                                   @change="selectReportTypeYear2"
                                    :disabled="disabledSelect"
                                    required>
                              <option :value="0">To</option>
                              <option v-for="(year, index) in years2" :key="index" :value="year">{{ year }}</option>
                            </select>
                          </div>

                        </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-1">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">Rank</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left custom-select-rank">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="customizedRankIndex"
                                required>
                          <option :value="null">-- select rank --</option>
                          <option class="text-uppercase" disabled>GROUPINGS</option>
                          <option disabled>----------------------------------</option>
                          <template v-for="(rank, index) in customizedRanks">
                            <option
                              :value="index"
                              :key="index"
                            > {{ rank.label }}
                            </option>
                            <option v-if="index + 1 === 10" disabled>----------------------------------</option>
                          </template>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button"
                          @click="generateCrewPromotionPerRank"
                          class="btn btn-xs pmc-btn-tertia">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>

                <div class="form-group row mt-1">
                  <button type="button"
                          @click="printReport"
                          class="btn btn-xs pmc-btn-danger">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>

              </div>
            </div>

            <hr>
            <div class="row ml-2">
              <div class="col-12">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label">
                        FILTER BY YEAR :
                        <b v-if="yearSelected" class="pl-2 text-size">{{ yearSelected }}</b>
                        <span v-if="yearSelected2"> &ndash;</span>
                        <b v-if="yearSelected2" class="pl-2 text-size">{{ yearSelected2 }}</b>
                      </label>
                    </div>
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label text-uppercase">
                        FILTER BY RANK : <b class="pl-2 text-size">{{ filterByRankString }}</b>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">

                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Crew</th>
                      <th>Age</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Type</th>
                      <th>Vessel</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData && crewPromotionPerRank !== 0">
                      <template v-for="(crew, data) in crewPromotionPerRank">
                        <tr>
                          <td class="text-left font-weight-bold h5" colspan="5">
                            {{ data }}
                          </td>
                        </tr>
                        <template v-for="(row, index) in crew">
                          <tr>
                            <td>{{ index + 1 }}</td>
                            <td>
                              <a href="#"
                                 @click.stop="gotoCrewProfileNewWindow(row.id, 'all-info')"
                                 style="color:black;">
                                <small><b>{{ row.fullname }}</b></small>
                              </a>
                            </td>
                            <td>{{ row.age }}</td>
                            <td>{{ row.fromRankName }}</td>
                            <td>{{ row.toRankName }}</td>
                            <td>{{ row.type }}</td>
                            <td>{{ row.vessel }}</td>
                            <td>{{ row.date3 }}</td>
                          </tr>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import {FileService} from "@/service/file.service";

export default {
  name: "CrewPromotionPerRank",

  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      reportParam: {
        rank: null,
        rankName: '',
        year: 0,
        year2: 0,
        isPDF: false,
      },
      rankGroupings: [
        { value: 'key_officers', label: 'KEY OFFICERS' , rank_ids: [1,2,10,11]},
        { value: 'officers', label: 'OFFICERS', rank_ids:[1,2,3,4,5,10,11,12,13,14,28,31,34,36,37,39,40,41,42,45] },
        { value: 'ratings', label: 'RATINGS', rank_ids: [6,7,8,9,15,16,17,18,19,20,21,22,23,24,25,26,27,29,30,32,33,35,43,44,47,48] },
        { value: 'deck', label: 'DECK', rank_ids: [1,2,3,4,6,7,8,9,27,29,31,33,39,40,41,42] },
        { value: 'engine', label: 'ENGINE', rank_ids: [5,10,11,12,13,14,15,16,17,18,19,20,21,22,28,32,34,36,37,44,45] },
        { value: 'steward', label: 'STEWARD', rank_ids: [23,24,25,26,30,35,43,47] },
        { value: 'officers_deck', label: 'OFFICERS-DECK', rank_ids: [1,2,3,4,35,39,40,41,42] },
        { value: 'officers_engine', label: 'OFFICERS-ENGINE', rank_ids: [5,10,11,12,13,14,28,31,34,36,37,45] },
        { value: 'ratings_deck', label: 'RATINGS-DECK', rank_ids: [6,7,8,9,27,33,48] },
        { value: 'ratings_engine', label: 'RATINGS-ENGINE', rank_ids: [15,16,17,18,19,20,21,22,32,44] },
      ],
      years: [],
      years2: [],
      ranks: null,
      crewPromotionPerRank: null,
      customizedRankIndex : null,
      yearSelected: null,
      yearSelected2: null,
      hasData: false,
      isLoading: false,
      disabledSelect: true,
    }
  },

  /** methods**/
  methods: {
    ...mapActions([]),

    async getRanks() {
      let params = {};
      return await axios.get(PMC.GET_ACTIVE_RANK_LEVEL, params).then(async r => {
        this.ranks = r.data;
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
        return false
      });
    },

    selectReportTypeYear(id) {
      this.hasData = false;
      this.yearSelected = this.getYearName(this.reportParam.year);
      this.selectYears2();
      this.disabledSelect = false;
      this.yearSelected2 = null;
      this.reportParam.year2 = 0;
      if (this.yearSelected === 0 || this.yearSelected === "") {
        this.disabledSelect = true;
      }
    },

    selectReportTypeYear2() {
      this.yearSelected2 = this.getYearName2(this.reportParam.year2);
    },

    selectYears() {
      const currentYear = new Date().getFullYear();
      for (let previousYear = currentYear - 10; previousYear <= currentYear; previousYear++) {
        this.years.push(previousYear);
      }
      this.$nextTick(()=>{
        this.selectYears2();
      })
    },

    selectYears2() {
      const currentYear = new Date().getFullYear();
      const startYear = this.yearSelected;
      this.years2 = [];

      for (let year = startYear; year <= currentYear; year++) {
        this.years2.push(year);
      }
    },

    getYearName(value) {
      let yearName = '';
      const yearList = this.years;
      for (let c in yearList) {
        if (parseInt(yearList[c]) === parseInt(value)) {
          yearName = yearList[c];
        }
      }
      return yearName;
    },

    getYearName2(value) {
      let yearName = '';
      const yearList = this.years2;
      for (let c in yearList) {
        if (parseInt(yearList[c]) === parseInt(value)) {
          yearName = yearList[c];
        }
      }
      return yearName;
    },

    async generateCrewPromotionPerRank() {

      if(this.reportParam.year === 0 && this.reportParam.year2 === 0 && typeof this.rankSelectedData === 'undefined') {

        this.errorAlert('Please Select Year From, Year To and Rank', '');
        this.isLoading = false;

      } else if(this.reportParam.year === 0 && this.reportParam.year2 === 0 && typeof this.rankSelectedData !== 'undefined') {

        this.errorAlert('Please Select Year From and Year To', '');
        this.isLoading = false;

      } else if(this.reportParam.year2 === 0) {

        this.errorAlert('Please Select Year To', '');
        this.isLoading = false;

      } else if(typeof this.rankSelectedData === 'undefined') {
        this.errorAlert('Please Select Rank', '');
        this.isLoading = false;

      } else {

        this.reportParam.isPDF = false;
        this.reportParam.rank = this.rankSelectedData.rank_ids;
        this.reportParam.rankName = this.rankSelectedData.label;
        this.hasData = false;
        let params = this.reportParam;
        this.isLoading = true;
        return await axios.post(PMC.GENERATE_CREW_PROMOTION_PER_RANK, params).then(r => {
          this.isLoading = false;
          this.hasData = true;
          this.crewPromotionPerRank = r.data;
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate Crew Promotion Per Rank')
          return false
        });

      }

    },

    async printReport() {

      if(this.reportParam.year === 0 && this.reportParam.year2 === 0 && typeof this.rankSelectedData === 'undefined') {

        this.errorAlert('Please Select Year From, Year To and Rank', '');
        this.isLoading = false;

      } else if(this.reportParam.year === 0 && this.reportParam.year2 === 0 && typeof this.rankSelectedData !== 'undefined') {

        this.errorAlert('Please Select Year From and Year To', '');
        this.isLoading = false;

      } else if(this.reportParam.year2 === 0) {

        this.errorAlert('Please Select Year To', '');
        this.isLoading = false;

        } else if(typeof this.rankSelectedData === 'undefined') {

        this.errorAlert('Please Select Rank', '');
        this.isLoading = false;

      } else {

        AlertService.loadingAlert('Please wait ...', 'Loading Print Report');
        let crewPromotionPerRankFile = await this.printCrewPromotionPerRank();
        if (crewPromotionPerRankFile) {
          await this.pdfViewer('Crew Promotion Per Rank', crewPromotionPerRankFile);
          Swal.close();
        } else {
          Swal.close();
        }

      }

    },

    async printCrewPromotionPerRank() {

      this.reportParam.isPDF = true;
      this.reportParam.rank = this.rankSelectedData.rank_ids;
      this.reportParam.rankName = this.rankSelectedData.label;
      let params = this.reportParam;
      return await axios.post(PMC.GENERATE_CREW_PROMOTION_PER_RANK_PRINT, params).then(r => {
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate Crew Biodata')
        return false;
      });

    },

  },
  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objOwners',
        'isReloading',
      ]),

    rankSelectedData() {
      return this.customizedRanks[this.customizedRankIndex];
    },

    filterByRankString() {
      if (typeof this.rankSelectedData !== 'undefined') {
        return this.rankSelectedData.label;
      }
      return '';
    },

    customizedRanks() {
      let rankSelection = [];
      let finalKey = rankSelection.length;

      this.rankGroupings.forEach((group, key) => {
        rankSelection.push({
          id : key + 1,
          value : group.value,
          label : group.label,
          rank_ids : group.rank_ids
        });
      });

      if (this.ranks !== null) {
        this.ranks.forEach((rank, key) => {
          rankSelection.push({
            id : finalKey + key + 1,
            value : rank.name,
            label : rank.name,
            rank_ids : [rank.id]
          });
        });
      }

      return rankSelection;
    }

  },

  /** mounted **/
  mounted() {
    this.getRanks();
    this.selectYears();
    this.selectYears2();
  },

  /** created **/
  beforeDestroy() {
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}

.text-size {
  font-size: 1.1em;
}

.text-uppercase {
  text-transform: uppercase;
}

.year-range {
  margin-left: 0;
}
.custom-select-rank select option {
  padding: 20px;
}
</style>
