<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-3">
                <h4>Vessels</h4>
              </div>
              <div class="col-3">
                <pmc-modal-btn
                    :target-modal="newVesselModal"
                    button-label="Add Vessel"
                    button-class="btn btn-sm pmc-btn-tertia">
                  <template slot="button-icon">
                    <font-awesome-icon icon="plus"/>
                  </template>
                </pmc-modal-btn>
              </div>
            </div>
          </div>
        </div>

        <!-- pagination -->
        <div class="row">
          <!-- -->
          <div class="col-md-8">
            <div class="row">
              <div class="col-6">
                <pmc-pagination :page-object="vesselPagination" @settingPage="setThisPage"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table section  -->
        <pmc-table :loading="isReloading" :loader-colspan="6">
          <template v-slot:table-header>
            <tr>
              <th>
                <button class="btn-xs" @click="searchVessel">
                  <font-awesome-icon icon="undo"/>
                </button>
              </th>
              <th>
                <input type="search"
                       name="SearchCode"
                       id="search-code"
                       class="form-control form-control-sm"
                       v-model="formSearchData.code"
                       @keypress.enter="searchVessel"
                       placeholder="Search Code">
              </th>
              <th>
                <input type="search"
                       name="SearchName"
                       id="search-name"
                       class="form-control form-control-sm"
                       v-model="formSearchData.name"
                       @keypress.enter="searchVessel"
                       placeholder="Search Name">
              </th>
              <th>
                <select name="VesselType"
                        id="vessel-type"
                        v-model="formSearchData.vesselType"
                        @change="searchVessel"
                        class="form-control form-control-sm">
                  <option :value="''">ALL</option>
                  <option :value="vesselType.id" v-for="(vesselType,index) in objVesselTypes" :key="index">
                    {{ vesselType.name }}
                  </option>
                </select>
              </th>
              <th>
                <select name="VesselStatus"
                        id="vessel-status"
                        v-model="formSearchData.vesselStatus"
                        @change="searchVessel"
                        class="form-control form-control-sm">
                  <option :value="stat.id" v-for="(stat,index) in statusList" :key="index">
                    {{ stat.desc.toUpperCase() }}
                  </option>
                </select>
              </th>
              <th>
                <span>Menu</span>
              </th>
            </tr>
            <tr>
              <th class="text-center align-middle"
                  style="width: 1% !important;">#
              </th>
              <th class="text-center align-middle"
                  style="width: 5% !important;">
                Code
              </th>

              <th class="text-center align-middle ml-2" style="width: 20% !important;">
                Name
              </th>
              <th class="text-center align-middle ml-2" style="width: 10% !important;">Type</th>
              <th class="text-center align-middle ml-2" style="width: 10% !important;">Status</th>
              <th class="text-center align-middle ml-2" style="width: 5% !important;">Actions</th>
            </tr>
          </template>
          <template slot="table-body">
            <tr v-for="(vessel,index) in vessels" :key="vessel.id"
                :class="{
                  'pmc-bg-danger-light' : !vessel.status
                }">
              <td class="p-1">{{ index + vesselPagination.from }}</td>
              <td class="p-1">{{ vessel.code }}</td>
              <td class="p-1 text-left pl-lg-5">{{ vessel.name }}</td>
              <td class="p-1">
                <span v-if="vessel.vesselType">
                  {{ vessel.vesselType.name }}
                </span>
              </td>
              <td class="p-1">{{ vessel.status ? 'ACTIVE' : 'INACTIVE' }}</td>
              <td class="p-1">
<!--                <pmc-modal-btn :target-modal="viewVesselModal"-->
<!--                               button-label="View"-->
<!--                               button-class="btn btn-sm pmc-btn-prime"-->
<!--                               @modalButtonEvent="viewVesselDetails(vessel)">-->
<!--                  <template slot="button-icon">-->
<!--                    <font-awesome-icon icon="bars"/>-->
<!--                  </template>-->
<!--                </pmc-modal-btn>-->

                <button
                        class="btn btn-sm pmc-btn-prime"
                        type="button"
                        @click="view_vessel(vessel.id)"
                        data-keyboard="false">
                  <font-awesome-icon icon="bars"/>
                  View
                </button>

                <pmc-btn button-label="Delete"
                         v-if="authUser.department == $globalVariables.department.admin ||
                         authUser.department == $globalVariables.department.it ||
                         authUser.department == $globalVariables.department.management"
                         @buttonEvent="deleteVesselDetails(vessel)"
                         button-class="btn btn-sm pmc-btn-danger ml-1">
                  <template slot="button-icon">
                    <font-awesome-icon icon="trash"/>
                  </template>
                </pmc-btn>
              </td>
            </tr>
          </template>
        </pmc-table>
      </div>
    </div>

    <pmc-modal :id="newVesselModal" xl_width xxl_height>
      <template slot="custom-header">
        <h4>New Vessel Details</h4>
      </template>

      <template slot="custom-body">
        <ul class="nav nav-pills mb-3" id="new-pills-tab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active"
               id="new-pills-general-tab"
               data-toggle="pill"
               href="#new-general-tab"
               role="tab"
               aria-controls="pills-home"
               aria-selected="true">General</a>
          </li>

          <li class="nav-item">
            <a class="nav-link"
               id="new-pills-profile-tab"
               data-toggle="pill"
               href="#new-specification-tab"
               role="tab"
               aria-controls="pills-profile"
               aria-selected="false">Specifications</a>
          </li>

          <!-- todo <li class="nav-item">
              <a class="nav-link"
                 id="pills-ecdis-tab"
                 data-toggle="pill"
                 href="#ecdis-tab"
                 role="tab"
                 aria-controls="pills-ecdis"
                 aria-selected="false">Ecdis File</a>
            </li>-->
        </ul>

        <!--  tab-content      -->
        <div class="tab-content flex-sm-row" id="new-pills-tabContent">
          <div class="tab-pane fade show active"
               id="new-general-tab"
               role="tabpanel"
               aria-labelledby="pills-general-tab">
            <div class="row px-lg-1">
              <div class="col-3">
                <div class="d-flex flex-column">
                  <div class="p-2">
                    <div class="vessel-img-container">
                      <font-awesome-icon v-if="!attachedImage" icon="ship" class="vessel-img"/>
                      <img v-if="attachedImage" :src="attachedImage" alt="vessel-img" class="img-fluid vessel-img">
                    </div>
                  </div>
                  <div class="p-2">
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <pmc-modal-btn :target-modal="uploadVesselImageModal" button-class="btn btn-light text-secondary" button-label="Image">
                        <template slot="button-icon">
                          <font-awesome-icon icon="paperclip"/>
                        </template>
                      </pmc-modal-btn>
                      <button type="button" class="btn btn-light text-danger">
                        <font-awesome-icon icon="trash"/>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-8 text-left ">
                <!--  code -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">CODE</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="text"
                           placeholder="input code"
                           class="form-control form-control"
                           v-model="newVessel.code">
                  </div>
                </div>

                <!--  name  -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">NAME</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="text" placeholder="input name"
                           class="form-control form-control"
                           v-model="newVessel.name">
                  </div>
                </div>

                <!-- alias -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">ALIAS</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="text"
                           placeholder="input alias"
                           class="form-control form-control"
                           v-model="newVessel.alias">
                  </div>
                </div>

                <!--  vessel type  -->
                <div class="form-group row py-1">
                  <label for="view-vessel-type" class="col-md-4 col-form-label-sm font-weight-bold">VESSEL TYPE</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <multi-select
                        v-model="newVessel.vesselType"
                        :options="objVesselTypes"
                        :custom-label="namesLang"
                        :select-label="'Select'"
                        placeholder="Select VesselType"
                        label="VesselType"
                        @input="onUpdateVesselType"
                        track-by="id">
                    </multi-select>
                  </div>
                </div>

                <!--  flag  -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">FLAG</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <multi-select
                        v-model="newVessel.flag"
                        :options="objFlags"
                        :custom-label="namesLang"
                        :select-label="'Select'"
                        placeholder="Select Flag"
                        label="Flag"
                        @input="onUpdateFlag"
                        track-by="id">
                    </multi-select>
                  </div>
                </div>


                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">BENEFICIAL</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <multi-select
                        v-model="newVessel.beneficial"
                        :options="objBeneficiary"
                        :custom-label="namesLang"
                        :select-label="'Select'"
                        placeholder="Select Beneficial"
                        label="Beneficial"
                        @input="onUpdateBeneficial"
                        track-by="id">
                    </multi-select>
                  </div>
                </div>
              </div>
            </div>
            <hr>

            <div class="row px-lg-1">
              <div class="col-3"></div>
              <div class="col-8 text-left ">
                <!--  owner  -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">OWNER</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <multi-select
                        v-model="newVessel.owner"
                        :options="objOwners"
                        :custom-label="namesLang"
                        :select-label="'Select'"
                        placeholder="Select owner"
                        label="Owner"
                        @input="onUpdateOwner"
                        track-by="id">
                    </multi-select>
                  </div>
                </div>

                <!--  gear owner  -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">GEAR OWNER</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <multi-select
                      v-model="newVessel.gearOwner"
                      :options="objOwners"
                      :custom-label="namesLang"
                      :select-label="'Select'"
                      placeholder="Select owner"
                      label="Owner"
                      track-by="id">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">MANNING</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <multi-select
                        v-model="newVessel.manning"
                        :options="objMannings"
                        :custom-label="namesLang"
                        :select-label="'Select'"
                        placeholder="Select manning"
                        label="Manning"
                        @input="onUpdateManning"
                        track-by="id">
                    </multi-select>
                  </div>
                </div>

                <!-- trade route -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">TRADEROUTE</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <multi-select
                        v-model="newVessel.tradeRoute"
                        :options="objTradeRoutes"
                        :custom-label="namesLang"
                        :select-label="'Select'"
                        placeholder="Select"
                        label="Traderoute"
                        @input="onUpdateTradeRoute"
                        track-by="id">
                    </multi-select>
                  </div>
                </div>

                <!-- union -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">UNION</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <multi-select
                        v-model="newVessel.union"
                        :options="objUnion"
                        :custom-label="namesLang"
                        :select-label="'Select'"
                        placeholder="Select"
                        label="Union"
                        @input="onUpdateUnion"
                        track-by="id">
                    </multi-select>
                  </div>
                </div>

                <!--  status  -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">STATUS</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7 mt-1">
                    <input type="checkbox" class="form-check-inline" v-model="newVessel.status">
                  </div>
                </div>

                <!--  end service reason -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">END SERVICE REASON</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="text"
                           placeholder="Input end service reason"
                           class="form-control form-control"
                           v-model="newVessel.endServiceReason">
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="tab-pane fade"
               id="new-specification-tab"
               role="tabpanel"
               aria-labelledby="pills-profile-tab">
            <div class="row px-lg-1">
              <div class="col-12 text-left px-lg-5">
                <!-- date delivered -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">DATE DELIVERED</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="date"
                           v-model="newVessel.dateDelivered"
                           class="form-control form-control-plaintext">
                  </div>
                </div>

                <!-- date re delivered -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">DATE RE-DELIVERED</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="date"
                           v-model="newVessel.dateReDelivered"
                           class="form-control form-control-plaintext">
                  </div>
                </div>

                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">BUILT YEAR</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <select name="ViewVesselBuiltYear"
                            id="new-vessel-builtyear"
                            v-model="newVessel.builtYear"
                            class="form-control form-control">
                      <option value="">--select--</option>
                      <option :value="year" v-for="(year,index) in generateYear()">
                        {{ year }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- date vessel_classification -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">CLASSIFICATION</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <select name="VesselClassification"
                            id="new-vessel-classification"
                            class="form-control form-control"
                            v-model="newVessel.classification">
                      <option value="">--</option>
                      <option v-for="(vesselClass, index) in objVesselClassification" :value="vesselClass.id">
                        {{vesselClass.code}}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- contrat length -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">CONTRACT LENGTH</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="number"
                           v-model="newVessel.contractLength"
                           class="form-control form-control">
                  </div>
                </div>

                <!--      max overtime          -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">MAX OVERTIME</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="number"
                           min="0"
                           v-model="newVessel.maxOverTime"
                           class="form-control form-control">
                  </div>
                </div>

                <!--      hours per week         -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">HOURS PER WEEK</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="number"
                           min="0"
                           v-model="newVessel.hrsPerWeek"
                           class="form-control  form-control">
                  </div>
                </div>

                <!--  kw -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">KW</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="number"
                           min="0"
                           v-model="newVessel.kw"
                           class="form-control form-control">
                  </div>
                </div>

                <!-- grt -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">GRT</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="number"
                           min="0"
                           v-model="newVessel.grt"
                           class="form-control form-control">
                  </div>
                </div>

                <!-- dwt -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">DWT</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="number"
                           min="0"
                           v-model="newVessel.dwt"
                           class="form-control form-control">
                  </div>
                </div>

                <!-- imo no -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">IMO NO</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="text"
                           v-model="newVessel.imoNo"
                           placeholder="Input IMO NO of Vessel"
                           class="form-control form-control">
                  </div>
                </div>

                <!-- off no -->
                <div class="form-group row py-1">
                  <label class="col-md-4 col-form-label-sm font-weight-bold">OFF NO</label>
                  <div class="col-form-label-sm">:</div>
                  <div class="col-md-7">
                    <input type="text"
                           v-model="newVessel.offNo"
                           placeholder="Input OFF NO of Vessel"
                           class="form-control form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- todo <div class="tab-pane fade show active"
               id="ecdis-tab"
               role="tabpanel"
               aria-labelledby="pills-general-tab">
            <p>......</p>
          </div>-->
        </div>
      </template>

      <template slot="custom-footer">
        <button class="btn btn-primary" @click="saveVesselDetails">
          Save
        </button>

        <button class="btn pmc-btn-danger" @click="cancelSavingVesselDetails">
          Close
        </button>
      </template>
    </pmc-modal>

    <div class="modal fade" :id="viewVesselModal" tabindex="-1" role="dialog" :aria-labelledby="viewVesselModal+'CenterTitle'" aria-hidden="true" >
      <div class="modal-dialog modal-dialog-centered xxl-width"
           role="document">

        <div class="modal-content xxl-height">
          <div class="modal-header">
            <h4>View vessel details</h4>
<!--            <button type="button" class="close btn btn-xs" data-dismiss="modal" aria-label="Close" @click="closingUpdateVessel">-->
<!--              <span aria-hidden="true"><h5>&times;</h5></span>-->
<!--            </button>-->
          </div>

          <div class="modal-body">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active"
                   id="pills-general-tab"
                   data-toggle="pill"
                   href="#general-tab"
                   role="tab"
                   aria-controls="pills-home"
                   aria-selected="true">General</a>
              </li>

              <li class="nav-item">
                <a class="nav-link"
                   id="pills-profile-tab"
                   data-toggle="pill"
                   href="#specification-tab"
                   role="tab"
                   aria-controls="pills-profile"
                   aria-selected="false">Specifications</a>
              </li>

              <!-- todo <li class="nav-item">
                  <a class="nav-link"
                     id="pills-ecdis-tab"
                     data-toggle="pill"
                     href="#ecdis-tab"
                     role="tab"
                     aria-controls="pills-ecdis"
                     aria-selected="false">Ecdis File</a>
                </li>-->
            </ul>

            <!--  tab-content      -->
            <div class="tab-content flex-sm-row" id="pills-tabContent">
              <div class="tab-pane fade show active"
                   id="general-tab"
                   role="tabpanel"
                   aria-labelledby="pills-general-tab">
                <div class="row px-lg-1">
                  <div class="col-2">
                    <div class="d-flex flex-column">
                      <div class="p-2">
                        <div class="vessel-img-container">
                          <font-awesome-icon v-if="!vessel.hasImg && !attachedImage" icon="ship" id="vessel-img"/>
                          <img v-if="attachedImage" :src="attachedImage" alt="vessel-img" class="img-fluid vessel-img">
                          <img v-if="vessel.image && !attachedImage" :src="vessel.image" alt="vessel-img" class="img-fluid vessel-img">
                        </div>
                      </div>
                      <div class="p-2" v-if="isEditable">
                        <div class="btn-group" role="group" aria-label="Basic example">
                          <pmc-modal-btn :target-modal="uploadVesselImageModal" button-class="btn btn-light text-secondary" button-label="Image">
                            <template slot="button-icon">
                              <font-awesome-icon icon="paperclip"/>
                            </template>
                          </pmc-modal-btn>
                          <button type="button" class="btn btn-light text-danger" @click="deleteUploadPhotoModal">
                            <font-awesome-icon icon="trash"/>
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 text-left ">
                    <!--  code -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">CODE</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               placeholder="input code"
                               class="form-control form-control"
                               :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                               :disabled="!isEditable"
                               v-model="vessel.code">
                      </div>
                    </div>

                    <!--  name  -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">NAME</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text" placeholder="input name"
                               class="form-control form-control"
                               :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                               :disabled="!isEditable"
                               v-model="vessel.name">
                      </div>
                    </div>

                    <!-- alias -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">ALIAS</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               placeholder="input alias"
                               class="form-control form-control"
                               :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                               :disabled="!isEditable"
                               v-model="vessel.alias">
                      </div>
                    </div>

                    <!--  vessel type  -->
                    <div class="form-group row py-1">
                      <label for="view-vessel-type" class="col-md-4 col-form-label-sm font-weight-bold">VESSEL TYPE</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               id="view-vessel-type"
                               placeholder="input flag"
                               class="form-control form-control form-control-plaintext"
                               disabled="disabled"
                               v-if="vessel.vesselType && !isEditable"
                               v-model="vessel.vesselType.name">
                        <multi-select
                          v-if="isEditable"
                          v-model="vessel.vesselType"
                          :options="objVesselTypes"
                          :custom-label="namesLang"
                          :select-label="'Select'"
                          placeholder="Select VesselType"
                          label="VesselType"
                          @input="onUpdateVesselType"
                          track-by="id">
                        </multi-select>
                      </div>
                    </div>

                    <!--  flag  -->
                    <div class="form-group row py-1">
                      <label for="view-flag" class="col-md-4 col-form-label-sm font-weight-bold">FLAG</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               id="view-flag"
                               placeholder="input flag"
                               disabled="disabled"
                               class="form-control form-control form-control-plaintext"
                               v-if="vessel.flag && !isEditable"
                               v-model="vessel.flag.name">
                        <multi-select
                          v-if="isEditable"
                          v-model="vessel.flag"
                          :options="objFlags"
                          :custom-label="namesLang"
                          :select-label="'Select'"
                          placeholder="Select Flag"
                          label="Flag"
                          @input="onUpdateFlag"
                          track-by="id">
                        </multi-select>
                      </div>
                    </div>


                    <div class="form-group row py-1">
                      <label for="view-flag" class="col-md-4 col-form-label-sm font-weight-bold">BENEFICIAL</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               id="view-beneficial"
                               placeholder="input beneficial"
                               disabled="disabled"
                               class="form-control form-control form-control-plaintext"
                               v-if="vessel.beneficial && !isEditable"
                               v-model="vessel.beneficial.name">

                        <multi-select
                          v-if="isEditable"
                          v-model="vessel.beneficial"
                          :options="objBeneficiary"
                          :custom-label="namesLang"
                          :select-label="'Select'"
                          placeholder="Select Beneficial"
                          label="Beneficial"
                          @input="onUpdateBeneficial"
                          track-by="id">
                        </multi-select>
                      </div>
                    </div>
                  </div>

                  <div class="col-5 text-left ">
                    <!--  owner  -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">OWNER</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               placeholder="input alias"
                               v-if="vessel.owner && !isEditable"
                               disabled="disabled"
                               class="form-control form-control form-control-plaintext"
                               v-model="vessel.owner.name">
                        <multi-select
                          v-if="isEditable"
                          v-model="vessel.owner"
                          :options="objOwners"
                          :custom-label="namesLang"
                          :select-label="'Select'"
                          placeholder="Select owner"
                          label="Owner"
                          @input="onUpdateOwner"
                          track-by="id">
                        </multi-select>
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">MANNING</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               placeholder="input alias"
                               class="form-control form-control"
                               :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                               disabled="disabled"
                               v-if="vessel.manning && !isEditable"
                               v-model="vessel.manning.name">
                        <multi-select
                          v-if="isEditable"
                          v-model="vessel.manning"
                          :options="objMannings"
                          :custom-label="namesLang"
                          :select-label="'Select'"
                          placeholder="Select manning"
                          label="Manning"
                          @input="onUpdateManning"
                          track-by="id">
                        </multi-select>
                      </div>
                    </div>

                    <!-- trade route -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">TRADEROUTE</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text" placeholder="input trade route"
                               class="form-control form-control"
                               :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                               disabled="disabled"
                               v-if="vessel.tradeRoute && !isEditable"
                               v-model="vessel.tradeRoute.name">
                        <multi-select
                          v-if="isEditable"
                          v-model="vessel.tradeRoute"
                          :options="objTradeRoutes"
                          :custom-label="namesLang"
                          :select-label="'Select'"
                          placeholder="Select Trade Route"
                          label="Traderoute"
                          @input="onUpdateTradeRoute"
                          track-by="id">
                        </multi-select>
                      </div>
                    </div>

                    <!-- union -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">UNION</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text" placeholder="input union"
                               class="form-control form-control"
                               :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                               disabled="disabled"
                               v-if="vessel.union && !isEditable"
                               v-model="vessel.union.name">
                        <multi-select
                          v-if="isEditable"
                          v-model="vessel.union"
                          :options="objUnion"
                          :custom-label="namesLang"
                          :select-label="'Select'"
                          placeholder="Select union"
                          label="Union"
                          @input="onUpdateUnion"
                          track-by="id">
                        </multi-select>
                      </div>
                    </div>

                    <!--  status  -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">STATUS</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7 mt-1">
                        <input type="checkbox" class="form-check-inline" v-model="vessel.status" :disabled="!isEditable">
                      </div>
                    </div>

                    <!--  end service reason -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">END SERVICE REASON</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               placeholder=""
                               :class="{
                            'form-control-plaintext' : !isEditable
                           }"
                               class="form-control form-control"
                               v-model="vessel.endServiceReason">
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row px-lg-1">
                  <div class="col-2 text-left"></div>
                  <div class="col-5 text-left">

                    <!--  created by -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">CREATED BY</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               placeholder=""
                               disabled="disabled"
                               class="form-control form-control form-control-plaintext"
                               v-model="vessel.createdBy">
                      </div>
                    </div>

                    <!--  updated by -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">UPDATED BY</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               placeholder=""
                               disabled="disabled"
                               class="form-control form-control form-control-plaintext"
                               v-model="vessel.updatedBy">
                      </div>
                    </div>

                    <!--  created at -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">CREATED AT</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               placeholder=""
                               disabled="disabled"
                               class="form-control form-control form-control-plaintext"
                               v-model="vessel.createdAt">
                      </div>
                    </div>

                    <!--  updated at -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">UPDATED AT</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               placeholder=""
                               disabled="disabled"
                               class="form-control form-control form-control-plaintext"
                               v-model="vessel.updatedAt">
                      </div>
                    </div>
                  </div>
                  <div class="col-5"></div>
                </div>
              </div>

              <div class="tab-pane fade"
                   id="specification-tab"
                   role="tabpanel"
                   aria-labelledby="pills-profile-tab">
                <div class="row px-lg-1">
                  <div class="col-7 text-left px-lg-5">
                    <!-- date delivered -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">DATE DELIVERED</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="date"
                               :disabled="!isEditable"
                               v-model="vessel.dateDelivered"
                               class="form-control form-control-plaintext">
                      </div>
                    </div>

                    <!-- date re delivered -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">DATE RE-DELIVERED</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="date"
                               :disabled="!isEditable"
                               v-model="vessel.dateReDelivered"
                               class="form-control form-control-plaintext">
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">BUILT YEAR</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               id="view-vessel-built-year"
                               disabled
                               v-model="vessel.builtYear"
                               :disabled="!isEditable"
                               v-if="!isEditable "
                               class="form-control form-control-plaintext">
                        <select name="ViewVesselBuiltYear"
                                id="view-vessel-builtyear"
                                v-if="isEditable"
                                v-model="vessel.builtYear"
                                class="form-control form-control">
                          <option value="">--select--</option>
                          <option :value="year" v-for="(year,index) in generateYear()">
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- date vessel_classification -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">CLASSIFICATION</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <select name="VesselClassification"
                                id="vessel-classification"
                                :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                                :disabled="!isEditable"
                                class="form-control form-control"
                                v-model="vessel.classification">
                          <option value="">--</option>
                          <option :value=1>NK</option>
                          <option :value=2>NKK</option>
                        </select>
                      </div>
                    </div>

                    <!-- contrat length -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">CONTRACT LENGTH</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="number"
                               :disabled="!isEditable"
                               :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                               v-model="vessel.contractLength"
                               class="form-control form-control">
                      </div>
                    </div>

                    <!--      max overtime          -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">MAX OVERTIME</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="number"
                               :disabled="!isEditable"
                               :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                               min="0"
                               v-model="vessel.maxOverTime"
                               class="form-control form-control">
                      </div>
                    </div>

                    <!--      hours per week         -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">HOURS PER WEEK</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="number"
                               :disabled="!isEditable"
                               :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                               min="0"
                               v-model="vessel.hrsPerWeek"
                               class="form-control  form-control">
                      </div>
                    </div>

                    <!--  kw -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">KW</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="number"
                               :disabled="!isEditable"
                               :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                               min="0"
                               v-model="vessel.kw"
                               class="form-control form-control">
                      </div>
                    </div>

                    <!-- grt -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">GRT</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="number"
                               :disabled="!isEditable"
                               :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                               min="0"
                               v-model="vessel.grt"
                               class="form-control form-control">
                      </div>
                    </div>

                    <!-- dwt -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">DWT</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="number"
                               :disabled="!isEditable"
                               :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                               min="0"
                               v-model="vessel.dwt"
                               class="form-control form-control">
                      </div>
                    </div>

                    <!-- imo no -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">IMO NO</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               :disabled="!isEditable"
                               :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                               min="0"
                               v-model="vessel.imoNo"
                               class="form-control form-control">
                      </div>
                    </div>

                    <!-- off no -->
                    <div class="form-group row py-1">
                      <label class="col-md-4 col-form-label-sm font-weight-bold">OFF NO</label>
                      <div class="col-form-label-sm">:</div>
                      <div class="col-md-7">
                        <input type="text"
                               :disabled="!isEditable"
                               :class="{
                             'form-control-plaintext' : !isEditable
                           }"
                               min="0"
                               v-model="vessel.offNo"
                               class="form-control form-control">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- todo <div class="tab-pane fade show active"
                   id="ecdis-tab"
                   role="tabpanel"
                   aria-labelledby="pills-general-tab">
                <p>......</p>
              </div>-->
            </div>
          </div>

          <div class="modal-footer">
            <div v-if="isEditable">
              <button class="btn pmc-btn-prime mr-2" @click="updateVesselDetails">
                Update
              </button>

              <button class="btn pmc-bg-danger" @click="cancelUpdateVessel">
                Cancel
              </button>
            </div>
            <div v-else>
              <button class="btn pmc-btn-warning mr-2" @click="editVesselDetails">
                Edit
              </button>

              <button class="btn pmc-bg-second" @click="closeUpdateVessel">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>



    <pmc-modal :id="uploadVesselImageModal" :show-header-button-close="false">
      <template slot="custom-header">
        Upload Vessel image
      </template>
      <template slot="custom-body">
        <label for="attached-photo"></label>
        <input type="file" name="AttachedPhoto" id="attached-photo" @change="onChangeAttachedPhoto">
        <hr>
        <div class="vessel-img-container">
          <font-awesome-icon icon="ship" class="vessel-img" v-if="!attachedImage"/>
          <img v-if="attachedImage" id="upload-vessel-img" :src="attachedImage" alt="vessel-img" class="img-fluid vessel-img">
        </div>
      </template>
      <template slot="custom-footer">
        <button @click="attachedVesselImage" class="btn btn-sm btn-secondary">
          Attached
        </button>

        <button v-if="attachedImage" @click="removeAttachedImage" class="btn btn-sm pmc-btn-danger">
          Remove
        </button>

        <button v-if="!attachedImage" @click="cancelAttachedVesselImage" class="btn btn-sm pmc-btn-danger">
          Cancel
        </button>
      </template>
    </pmc-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {AppMixins} from '@/mixins/app.mixins';
import {AlertMixins} from '@/mixins/alert.mixins';

import {global_variables_config} from "@/config/global-variable.config";

import noPhoto from '@/assets/noPhoto.jpg';
import __ from 'lodash';

export default {
  name: 'List',

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      newVesselModal: 'new-vessel-modal',
      viewVesselModal: 'view-vessel-modal',
      uploadFileModal: 'upload-vessel-attachmend-modal', //todo
      uploadVesselImageModal: 'upload-vessel-img-modal',
      statusList: [
        {
          id: 2,
          desc: 'all',
        },
        {
          id: 1,
          desc: 'active',
        },
        {
          id: 0,
          desc: 'inactive',
        },
      ],
      vesselTypeVal: null,
      isEditable: false,
      noPhoto: noPhoto,
      selectedVessel: null,
      attachedImage: null,
    };
  },

  /** methods**/
  methods: {
    ...mapActions(
        [
          'resetVesselPagination',
          'getVesselListPaginated',
          'createVessel',
          'getVesselDetails',
          'updateVessel',
          'deleteVessel',
          'resetVesselState',
          'setVessel',
          'resetUpdateVessel',
          'null_vessel_image',
          'resetVessel',
          'deleteVesselImage',
          'resetNewVessel',
          'null_ecdis_keys',
        ],
    ),

    searchVessel() {
      this.formSearchData.page = 1;
      this.getVesselListPaginated(this.formSearchData);
    },

    closeNewVesselModal() {
      this.hideModal(this.newVesselModal);
    },

    /** .lang functions  **/
    namesLang({name}) {
      return `${name}`;
    },

    onUpdateVesselType() {
    },

    onUpdateFlag() {
    },

    onUpdateOwner() {
    },

    onUpdateManning() {
    },

    onUpdateTradeRoute() {
    },

    onUpdateUnion() {
    },

    onUpdateBeneficial() {
    },

    //todo on_vessel_status
    on_vessel_status() {
    },

    onChangeAttachedPhoto(e) {
      const imgFile = e.target.files[0];
      const reader = new FileReader();
      if (imgFile != (null || undefined)) {
        reader.readAsDataURL(imgFile);
        reader.onload = ev => {
          let param = ev.target.result;
          this.attachedImage = param;
        };

      } else {
        $(`#attached-photo`).val(null);
        this.attachedImage = null;

      }
    },

    setThisPage(e) {
      this.vesselPagination.currentPage = e;
      this.formSearchData.page = e;
      this.getVesselListPaginated(this.formSearchData);
      this.resetVessel();
    },

    viewVesselDetails(objVessel) {
      this.setVessel(objVessel);
      this.selectedVessel = objVessel.id;
    },

    editVesselDetails() {
      this.isEditable = true;
    },

    closeUpdateVessel() {
      this.hideModal(this.viewVesselModal);
      this.resetVessel();
    },

    async updateVesselDetails() {
      let msgHtml = '<span>Do you want to save the changes you made ?</span>';
      if (await this.questionAlert(msgHtml, 'Update vessel details')) {
        const formPayload = new FormData;
        formPayload.append('id', this.vessel.id);
        formPayload.append('code', this.vessel.code ? this.vessel.code : '');
        formPayload.append('name', this.vessel.name);
        formPayload.append('alias', this.vessel.alias ? this.vessel.alias : '');
        formPayload.append('vesselTypeId', this.vessel.vesselType ? this.vessel.vesselType.id : '');
        formPayload.append('flagId', this.vessel.flag ? this.vessel.flag.id : '');
        formPayload.append('ownerId', this.vessel.owner ? this.vessel.owner.id : '');
        formPayload.append('manningId', this.vessel.manning ? this.vessel.manning.id : '');
        formPayload.append('tradeRouteId', this.vessel.tradeRoute ? this.vessel.tradeRoute.id : '');
        formPayload.append('unionId', this.vessel.union ? this.vessel.union.id : '');
        formPayload.append('beneficialId', this.vessel.beneficial ? this.vessel.beneficial.id : '');
        formPayload.append('status', this.vessel.status ? 1 : 0);
        formPayload.append('endServiceReason', this.vessel.endServiceReason ? this.vessel.endServiceReason : '');

        formPayload.append('dateDelivered', this.vessel.dateDelivered ? this.vessel.dateDelivered : '');
        formPayload.append('dateReDelivered', this.vessel.dateReDelivered ? this.vessel.dateReDelivered : '');
        formPayload.append('builtYear', this.vessel.builtYear ? this.vessel.builtYear : '');
        formPayload.append('vessel_classification', this.vessel.classification ? this.vessel.classification : '');
        formPayload.append('contractLength', this.vessel.contractLength ? this.vessel.contractLength : '');
        formPayload.append('maxOverTime', this.vessel.maxOverTime ? this.vessel.maxOverTime : '');
        formPayload.append('hrsPerWeek', this.vessel.hrsPerWeek ? this.vessel.hrsPerWeek : '');
        formPayload.append('kw', this.vessel.kw ? this.vessel.kw : '');
        formPayload.append('grt', this.vessel.grt ? this.vessel.grt : '');
        formPayload.append('dwt', this.vessel.dwt ? this.vessel.dwt : '');
        formPayload.append('imoNo', this.vessel.imoNo ? this.vessel.imoNo : '');
        formPayload.append('offNo', this.vessel.offNo ? this.vessel.offNo : '');
        formPayload.append('image', this.vessel.image ? this.vessel.image : '');

        this.updateVessel(formPayload).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert('Update vessel details successfully');
              this.isEditable = false;
            });
          }
        });
      }
    },

    attachedVesselImage() {
      const file = document.getElementById('attached-photo').files[0];
      this.newVessel.image = file;
      const reader = new FileReader();
      if (file != (null || undefined)) {
        reader.readAsDataURL(file);
        reader.onload = ev => {
          let param = ev.target.result;
          this.attachedImage = param;

        };
        this.$nextTick(() => {
          this.successAlert('Attached successfully');
          this.hideModal(this.uploadVesselImageModal);
        });
      } else {
        this.warningAlert('Please provide a valid vessel image.');
      }
    },


    cancelAttachedVesselImage() {
      this.hideModal(this.uploadVesselImageModal);
      $(`#attached-photo`).val(null);
      //this.vessel.image = null;
      this.attachedImage = null;
    },

    removeAttachedImage() {
      if (this.attachedImage != (null || undefined)) {
        this.attachedImage = null;
        $(`#attached-photo`).val(null);
      }
    },

    async cancelUpdateVessel() {
      let msg = 'Do you want to redo the changes you made ?';
      if (await this.questionAlert(`${msg}`, 'Cancel update')) {
        this.isEditable = false;
        this.getVesselDetails(this.selectedVessel).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.resetUpdateVessel(this.vessel);
            });
          }
        });
      }
    },

    async closingUpdateVessel() {
      let msg = 'Force close\n and redo the changes made?';
      if (await this.questionAlert(`${msg}`, 'Cancel update')) {
        this.isEditable = false;
        this.getVesselDetails(this.selectedVessel).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.resetUpdateVessel(this.vessel);
            });
          }
        });
      } else {
        this.showModal(this.viewVesselModal);
      }
    },

    //todo deleteUploadPhotoModal
    async deleteUploadPhotoModal() {
      let msg = 'Do you want to delete photo attachment';
      if (await this.questionAlert(`${msg}`, 'Delete vessel photo')) {
      }
    },

    async deleteVesselDetails(objVessel) {
      let msg = 'Are you sure you want\n to delete this vessel ?';
      if (await this.questionAlert(`${msg}`, 'Delete Vessel')) {
        this.deleteVessel(objVessel.id).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert('Delete vessel successfully');
            });
          }
        });
      }
    },

    //todo  saveVesselDetails
   async saveVesselDetails(){
      let msgHtml = "Do you want to save the inputted vessel details";
      if(await this.questionAlert(msgHtml,'Save vessel details')){
        const formPayload = new FormData();
        formPayload.append('code', this.newVessel.code ? this.newVessel.code : '');
        formPayload.append('name', this.newVessel.name);
        formPayload.append('alias', this.newVessel.alias ? this.newVessel.alias : '');
        formPayload.append('vesselTypeId', this.newVessel.vesselType ? this.newVessel.vesselType.id : '');
        formPayload.append('flagId', this.newVessel.flag ? this.newVessel.flag.id : '');
        formPayload.append('ownerId', this.newVessel.owner ? this.newVessel.owner.id : '');
        formPayload.append('gearOwnerId', this.newVessel.gearOwner ? this.newVessel.gearOwner.id : '');
        formPayload.append('manningId', this.newVessel.manning ? this.newVessel.manning.id : '');
        formPayload.append('tradeRouteId', this.newVessel.tradeRoute ? this.newVessel.tradeRoute.id : '');
        formPayload.append('unionId', this.newVessel.union ? this.newVessel.union.id : '');
        formPayload.append('beneficialId', this.newVessel.beneficial ? this.newVessel.beneficial.id : '');
        formPayload.append('status', this.newVessel.status ? 1 : 0);
        formPayload.append('endServiceReason', this.newVessel.endServiceReason ? this.newVessel.endServiceReason : '');

        formPayload.append('dateDelivered', this.newVessel.dateDelivered ? this.newVessel.dateDelivered : '');
        formPayload.append('dateReDelivered', this.newVessel.dateReDelivered ? this.newVessel.dateReDelivered : '');
        formPayload.append('builtYear', this.newVessel.builtYear ? this.newVessel.builtYear : '');
        formPayload.append('vessel_classification', this.newVessel.classification ? this.newVessel.classification : '');
        formPayload.append('contractLength', this.newVessel.contractLength ? this.newVessel.contractLength : '');
        formPayload.append('maxOverTime', this.newVessel.maxOverTime ? this.newVessel.maxOverTime : '');
        formPayload.append('hrsPerWeek', this.newVessel.hrsPerWeek ? this.newVessel.hrsPerWeek : '');
        formPayload.append('kw', this.newVessel.kw ? this.newVessel.kw : 0);
        formPayload.append('grt', this.newVessel.grt ? this.newVessel.grt : 0);
        formPayload.append('dwt', this.newVessel.dwt ? this.newVessel.dwt : 0);
        formPayload.append('imoNo', this.newVessel.imoNo ? this.newVessel.imoNo : '');
        formPayload.append('offNo', this.newVessel.offNo ? this.newVessel.offNo : '');
        formPayload.append('image', this.newVessel.image ? this.newVessel.image : ''); //todo if updating

        this.createVessel(formPayload).then(r =>{
          if(r){
            this.$nextTick(()=>{
              this.successAlert('Save successfully')
            })
          }
        })
      }
    },

   async cancelSavingVesselDetails(){
      let msgHtml = "Do you want to cancel\n adding new vessel record details"
      if(await this.questionAlert(msgHtml,'Cancel adding')){
        this.$nextTick(()=> {
          this.hideModal(this.newVesselModal);
          this.attachedImage = null;
          this.resetNewVessel();
          this.newVessel.status = 1;
        })
      }
    },

    view_vessel(vesselId) {
      this.$router.push({
        name: 'ViewVessel',
        params: {vesselId: vesselId},
      });
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          'vesselPagination',
          'vessels',
          'vessel',
          'newVessel',
          'formSearchData',
          'isReloading',

          'objVesselTypes',
          'objFlags',
          'objOwners',
          'objMannings',
          'objBeneficiary',
          'objTradeRoutes',
          'objUnion',
          'objEcdisKeys',
          'objEmployeeKeys',
          'objVesselClassification',

          "authUser",

        ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  created() {
    this.getVesselListPaginated(this.formSearchData);
    this.$globalVariables = global_variables_config
  },

  /** watch
   searchText : {
      handler : 'fetchUserList',
      immediate : true,
    }
   **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.resetVesselState();
    this.resetVessel();
    this.selectedVessel = null;
    this.null_ecdis_keys();
    this.null_vessel_image();
  },
};
</script>

<style scoped>
multi-select {
  color: #000000 !important;
}

input[type=checkbox] {
  transform: scale(2);
}

#vessel-img-container {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

.vessel-img-container {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

.vessel-img {
  width: 200px;
  height: 200px;
}

#vessel-img {
  width: 200px;
  height: 200px;
}


.lg-width{
  max-width: 800px !important;
}

.xl-width{
  max-width: 1280px !important;
}

.xxl-width{
  max-width: 1620px !important;
}



.s-height{
  height: 250px !important;
}

.m-height{
  max-height: 500px !important;
}

.lg-height{
  height: 550px !important;
}

.xl-height{
  height: 750px !important;
}

.xxl-height{
  height: 920px !important;
}


</style>
