import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  clinic: {},
  clinics: [],
  newClinic: {},

  clinicFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  clinicPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_clinics(state, params) {
    state.clinics = params
  },

  create_clinic (state) {
    state.newPrincipal = {};
  },

  update_clinic (state, params) {
    let clinic = state.clinics.find((val, index) => val.id === params.id)
    Object.assign(clinic, params)
  },

  delete_clinic (state, params) {
    let index = state.clinics.findIndex((val, index) => val.id === params)
    state.clinics.splice(index, 1)
  },

  null_clinics(state) {
    state.clinics = []
  },

  null_clinic(state) {
    state.clinic = {}
  },

  set_clinic_pagination(state, params) {
    state.clinicPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {
  async set_clinics({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.CLINIC, {params}).then((r) => {
      commit('set_clinics', r.data.data)
      commit('set_clinic_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get clinics')
      return false
    })
  },

  async create_clinic({commit}, params) {
    return axios.post(PMC.CLINIC, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_clinic', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create principal');
      return false;
    })
  },

  async update_clinic({commit}, params) {
    return axios.put(`${PMC.CLINIC}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_clinic',r.data.updatedClinic);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update clinic');
      return false;

    });
  },

  async delete_clinic ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Clinic','question');
    if(await _del){
      return axios.delete(`${PMC.CLINIC}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete clinic');
        commit('delete_clinic',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete clinic");
        return false;

      })
    }
  },

  reset_clinic_pagination({commit}) {
    commit('set_principal_pagination')
  },

  null_clinics({commit}) {
    commit('null_clinics')
  },

  null_clinic({commit}) {
    commit('null_clinic')
  },
};


const getters = {
  clinics: state => UtilityService.capitalizeProperties(state.clinics),

  clinicPagination: state => state.clinicPagination,
  clinicFormParam: state => state.clinicFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
