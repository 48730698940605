import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";


const state = {
  principal: {},
  principals: [],
  newPrincipal: {},

  principalFormParam: {
    searchValue: null,
    filterType: 'all',
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  principalPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },

};


const mutations = {
  set_principals(state, params) {
    state.principals = params
  },

  set_principal(state, params) {
    state.principal = params
  },

  create_principal (state) {
    state.newPrincipal = {};
  },

  update_principal (state, params) {
    let principal = state.principals.find((val, index) => val.id === params.id)
    Object.assign(principal, params)
    //
    // let index = state.principals.findIndex(val => val.id == params.id);
    // state.principals.splice(index, 1, params);
  },

  delete_principal (state, params) {
    let index = state.principals.findIndex((val, index) => val.id === params)
    state.principals.splice(index, 1)
  },

  null_principals(state) {
    state.principals = []
  },

  null_principal(state) {
    state.principal = {}
  },

  set_principal_pagination(state, params) {
    state.principalPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },

};


const actions = {
  async set_principals({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.PRINCIPAL, {params}).then((r) => {
      commit('set_principals', r.data.data)
      commit('set_principal_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get principals')
      return false
    })
  },

  async create_principal({commit}, params) {
    return axios.post(PMC.PRINCIPAL, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_principal', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create principal');
      return false;
    })
  },

  async update_principal({commit}, params) {
    return axios.put(`${PMC.PRINCIPAL}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_principal',r.data.updatedPrincipal);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update rank');
      return false;

    });
  },

  async delete_principal ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Principal','question');
    if(await _del){
      return axios.delete(`${PMC.PRINCIPAL}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete principal');
        commit('delete_principal',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete principal");
        return false;

      })
    }
  },

  reset_principal_pagination({commit}) {
    commit('set_principal_pagination')
  },

  null_principals({commit}) {
    commit('null_principals')
  },

  null_principal({commit}) {
    commit('null_principal')
  },
};


const getters = {

  principals: state => UtilityService.capitalizeProperties(state.principals),
  newPrincipal: state => UtilityService.capitalizeProperties(state.newPrincipal),

  principalPagination: state => state.principalPagination,
  principalFormParam: state => state.principalFormParam,

};


/** export **/
export default {state, actions, mutations, getters};
