import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  checklistSignatory: {},
  checklistSignatories: [],
  newSignatory: {},

  checklistSignatoryFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  checklistSignatoryPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_checklistSignatories(state, params) {
    state.checklistSignatories = params
  },

  set_checklistSignatory(state, params) {
    state.checklistSignatory = params
  },


  create_checklistSignatory (state) {
    state.newSignatory = {};
  },

  update_checklistSignatory (state, params) {
    let signatory = state.checklistSignatories.find((val, index) => val.id === params.id)
    Object.assign(signatory, params)
  },

  delete_checklistSignatory (state, params) {
    let index = state.checklistSignatories.findIndex((val, index) => val.id === params)
    state.checklistSignatories.splice(index, 1)
  },


  set_checklistSignatory_pagination(state, params) {
    state.checklistSignatoryPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },

};


const actions = {

  async set_checklistSignatories({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.CREW_CHECKLIST_SIGNATORY, {params}).then((r) => {
      commit('set_checklistSignatories', r.data.data)
      commit('set_checklistSignatory_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get checklist signatories')
      return false
    })
  },

  async set_checklistSignatory({commit}, param) {
    return axios.get(`${PMC.CREW_CHECKLIST_SIGNATORY}/${param}`).then((r) => {
      commit('set_checklistSignatory', r.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Get Checklist signatory');
      return false;
    });
  },

  async create_checklistSignatory({commit}, params) {
    return axios.post(PMC.CREW_CHECKLIST_SIGNATORY, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_checklistSignatory', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create signatory');
      return false;
    })
  },

  async update_checklistSignatory({commit}, params) {
    return axios.put(`${PMC.CREW_CHECKLIST_SIGNATORY}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_checklistSignatory',r.data.updatedSignatory);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update signatory');
      return false;

    });
  },

  async delete_checklistSignatory ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Signatory','question');
    if(await _del){
      return axios.delete(`${PMC.CREW_CHECKLIST_SIGNATORY}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete signatory type');
        commit('delete_checklistSignatory',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete signatory");
        return false;

      })
    }
  },


};


const getters = {
  checklistSignatories: state => UtilityService.capitalizeProperties(state.checklistSignatories),
  checklistSignatory: state => UtilityService.capitalizeProperties(state.checklistSignatory),
  checklistSignatoryFormParam: state => state.checklistSignatoryFormParam,
  checklistSignatoryPagination: state => state.checklistSignatoryPagination,

};


/** export **/
export default {state, actions, mutations, getters};
