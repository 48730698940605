import {UtilityService} from '../../../service/utility.service'
import axios from 'axios'
import {PMC} from '../../../config/constants.config'
import {AlertService} from "@/service/alert.service";

/** state **/
const state = {
  newPrmAddress: {},
  newTempAddress: {},
  newProvAddress: {},

  crewAddressContacts: {
    prmAddress: {},
    tempAddress: {},
    provAddress: {},
    contactDetails: {},
    emergencyContacts: [],
  },

  crewContacts: [],
  crewContactTypes: [],

  crewContactDetails: [],

}

/** mutations **/
const mutations = {

  SET_CREW_PMR_ADDRESS(state, params) {
    state.crewAddressContacts.prmAddress = params
  },

  SET_CREW_TEM_ADDRESS(state, params) {
    state.crewAddressContacts.tempAddress = params
  },

  SET_CREW_PROV_ADDRESS(state, params) {
    state.crewAddressContacts.provAddress = params
  },

  SET_CREW_CONTACT_DTLS(state, params) {
    state.crewAddressContacts.contactDetails = params
  },

  CREATE_CREW_CONTACTS(state, params) {
    if (params.contact_type == 1) state.crewContactDetails.emailAddresses.push(params)
    if (params.contact_type == 2) state.crewContactDetails.telContact.push(params)
    if (params.contact_type == 3) state.crewContactDetails.workContact.push(params)
    if (params.contact_type == 4) state.crewContactDetails.personalContact.push(params)

  },

  EDIT_CREW_CONTACT(state, params) {
    // let crewContact = state.crewContactDetails.emailAddresses.find((val, index) => val.id === params.id)
    // Object.assign(crewContact, params)

    if (params.contact_type == 1) {
      let crewContact = state.crewContactDetails.emailAddresses.find((val, index) => val.id === params.id)
      Object.assign(crewContact, params)
    }

    if (params.contact_type == 2) {
      let crewContact = state.crewContactDetails.telContact.find((val, index) => val.id === params.id)
      Object.assign(crewContact, params)
    }

    if (params.contact_type == 3) {
      let crewContact = state.crewContactDetails.workContact.find((val, index) => val.id === params.id)
      Object.assign(crewContact, params)
    }

    if (params.contact_type == 4) {
      let crewContact = state.crewContactDetails.personalContact.find((val, index) => val.id === params.id)
      Object.assign(crewContact, params)
    }


  },

  DELETE_CREW_CONTACTS_EMAIL(state, params) {
    if (params.contact_type == 1) {
      let index = state.crewContactDetails.emailAddresses.findIndex((val, indexEmail) => val.id === params.id)
      state.crewContactDetails.emailAddresses.splice(index, 1)
    }

    if (params.contact_type == 2) {
      let index = state.crewContactDetails.telContact.findIndex((val, indexEmail) => val.id === params.id)
      state.crewContactDetails.telContact.splice(index, 1)
    }

    if (params.contact_type == 3) {
      let index = state.crewContactDetails.workContact.findIndex((val, indexEmail) => val.id === params.id)
      state.crewContactDetails.workContact.splice(index, 1)
    }

    if (params.contact_type == 4) {
      let index = state.crewContactDetails.personalContact.findIndex((val, indexEmail) => val.id === params.id)
      state.crewContactDetails.personalContact.splice(index, 1)
    }

  },


  SET_CREW_CONTACTS(state, params) {
    state.crewContacts = params
  },

  SET_CREW_CONTACT_DETAILS(state, params) {
    state.crewContactDetails = params
  },

  GET_CREW_CONTACT_TYPES(state, params) {
    state.crewContactTypes = params
  },

  SET_CREW_EMERGENCY_CONTACTS(state, params) {
    state.crewAddressContacts.emergencyContacts = params;
  },

  SET_CREW_PMR_ADDRESS_NULL(state) {
    state.crewAddressContacts.prmAddress = {}
  },

  SET_CREW_TEMP_ADDRESS_NULL(state) {
    state.crewAddressContacts.tempAddress = {}
  },

  SET_CREW_PROV_ADDRESS_NULL(state) {
    state.crewAddressContacts.provAddress = null
  },

  SET_NULL_NEW_PRM_ADD(state) {
    state.newPrmAddress = {}
  },

  SET_NULL_NEW_TEMP_ADD(state) {
    state.newTempAddress = {}
  },

  SET_NULL_NEW_PROV_ADD(state) {``
    state.newProvAddress = {}
  },
}

/** actions **/
const actions = {
  viewAddressContacts({commit}, param) {
    return axios.get(`${PMC.GET_CREW_ADDRESS_CONTACTS}/${param}`)
      .then((response) => {
        commit('SET_CREW_PMR_ADDRESS', response.data.permanent)
        commit('SET_CREW_TEM_ADDRESS', response.data.temporary)
        commit('SET_CREW_PROV_ADDRESS', response.data.province)
        commit('SET_CREW_CONTACT_DTLS', response.data.contactDetails)
        commit('SET_CREW_EMERGENCY_CONTACTS', response.data.emergencyContacts)
        return true
      }).catch((error) => {
        UtilityService.failProgressBar()
        UtilityService.generateResponseMessage(error, 'Addresses & Contacts')
        return false

      })
  },

  async createCrewContact({commit}, params) {
    return axios.post(PMC.CREATE_CREW_CONTACTS, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('CREATE_CREW_CONTACTS', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create crew contact');
      return false;
    })
  },

  async editCrewContact({commit}, params) {
    return axios.post(`${PMC.EDIT_CREW_CONTACTS}/${params.id}`, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('EDIT_CREW_CONTACT', r.data.updatedCrewContact);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Update crew contact');
      return false;
    });
  },


  async deleteCrewContact2({commit}, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.", 'Delete Crew Contact', 'question');
    if (await _del) {
      return axios.post(`${PMC.DELETE_CREW_CONTACT}/${params}`).then((r) => {
        AlertService.successAlertService("Deleted successfully", 'Delete Crew Contact');
        commit('DELETE_CREW_CONTACTS', r.data.data);
        return true;

      }).catch((er) => {
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er, "Delete Crew Contact");
        return false;

      })
    }
  },

  async deleteCrewContact({commit}, params) {
    return axios.post(`${PMC.DELETE_CREW_CONTACT}/${params.contactId}`, params).then((r) => {
      AlertService.successAlertService("Deleted successfully", 'Delete Crew Contact');
      commit('DELETE_CREW_CONTACTS_EMAIL', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create crew contact');
      return false;
    })
  },


  viewCrewContacts({commit}, param) {
    return axios.get(`${PMC.GET_CREW_CONTACTS}/${param}`).then((r) => {
      commit('SET_CREW_CONTACTS', r.data)
      return true
    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'View Crew Contacts')
      return false
    })
  },

  viewCrewContactDetails({commit}, param) {
    return axios.get(`${PMC.GET_CREW_CONTACT_DETAILS}/${param}`).then((r) => {
      commit('SET_CREW_CONTACT_DETAILS', r.data)
      return true
    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'View Crew Contacts')
      return false

    })
  },

  getContactTypes({commit}, param) {
    return axios.get(PMC.GET_CONTACT_TYPES).then((r) => {
      commit('GET_CREW_CONTACT_TYPES', r.data)
      return true
    }).catch((error) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(error, 'Get Contact Types')
      return false

    })
  },

  updateCrewAddress({commit}, params) {
    let parse = UtilityService.deCapitalizeProperties(params)
    return axios.put(`${PMC.CREW_ADDRESS}/${params.id}`, parse)
      .then((response) => {
        let addressType = response.data.addressType
        switch (addressType) {
          case 1:
            commit('SET_CREW_PMR_ADDRESS', response.data)
            break

          case 2:
            commit('SET_CREW_TEM_ADDRESS', response.data)
            break

          case 3:
            commit('SET_CREW_PROV_ADDRESS', response.data)
            break

          default:
            break
        }
        return true
      })
      .catch((error) => {
        UtilityService.failProgressBar()
        UtilityService.generateResponseMessage(error, 'updating crew address')
        return false
      })
  },

  saveCrewAddress({commit}, params) {
    return axios.post(`${PMC.CREW_ADDRESS}`, params).then((res) => {
      let addressType = res.data.addressType
      switch (addressType) {
        case 1:
          commit('SET_CREW_PMR_ADDRESS', res.data)
          break

        case 2:
          commit('SET_CREW_TEM_ADDRESS', res.data)
          break

        case 3:
          commit('SET_CREW_PROV_ADDRESS', res.data)
          break

        default:
          break
      }
      return true

    }).catch((er) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(er, 'Save Crew Address')
      return false
    })
  },

  saveCrewContact({commit}, params) {
    return axios.post(`${PMC.CREW_CONTACT}`, params).then((res) => {
      return true
    }).catch((error) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(error, 'Saving crew contact')
      return false
    })
  },

  updateCrewContact({commit}, params) {
    let parseParam = UtilityService.deCapitalizeProperties(params)
    return axios.put(`${PMC.CREW_CONTACT}/${parseParam.id}`, params)
      .then((res) => {
        return true

      })
      .catch((err) => {
        UtilityService.failProgressBar()
        UtilityService.generateResponseMessage(err, 'Updating crew contact')
        return false

      })
  },

  setPerAddrNull({commit}) {
    commit('SET_CREW_PMR_ADDRESS_NULL')
  },

  setTempAddrNull({commit}) {
    commit('SET_CREW_TEMP_ADDRESS_NULL')
  },

  setProvAddrNull({commit}) {
    commit('SET_CREW_PROV_ADDRESS_NULL')
  },

  setNullNewPermanentAdd({commit}) {
    commit('SET_NULL_NEW_PRM_ADD')
  },

  setNullNewTempAdd({commit}) {
    commit('SET_NULL_NEW_TEMP_ADD')
  },

  setNullNewProvAdd({commit}) {
    commit('SET_NULL_NEW_PROV_ADD')
  },
}

/** getters **/
const getters = {
  crewPrmAddress: state => UtilityService.capitalizeProperties(state.crewAddressContacts.prmAddress),
  crewTempAddress: state => UtilityService.capitalizeProperties(state.crewAddressContacts.tempAddress),
  crewProvAddress: state => UtilityService.capitalizeProperties(state.crewAddressContacts.provAddress),
  newPrmAddress: state => UtilityService.capitalizeProperties(state.newPrmAddress),
  newProvAddress: state => UtilityService.capitalizeProperties(state.newProvAddress),
  newTempAddress: state => UtilityService.capitalizeProperties(state.newTempAddress),
  contactDetails: state => UtilityService.capitalizeProperties(state.crewAddressContacts.contactDetails),
  emergencyContacts: state => UtilityService.capitalizeProperties(state.crewAddressContacts.emergencyContacts),


  crewContacts: state => state.crewContacts,
  crewContactTypes: state => UtilityService.capitalizeProperties(state.crewContactTypes),

  crewContactDetails: state => state.crewContactDetails,

}

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
}
