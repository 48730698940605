import alertService, {AlertService} from "@/service/alert.service"

export const AlertMixins = {
  data() {
    return {
      message: 'Hello world 2',
    }
  },

  methods: {
    //error
    errorAlert(text, actionType) {
      let msg = text ? text : '';
      return AlertService.errorAlertService(msg, actionType);
    },

    //info
    infoAlert(text = '', actionType = '') {
      return AlertService.infoAlertService(text, actionType);
    },

    //session expired
    sessionExpiredAlert(text = '', actionType = '') {
      return AlertService.sessionExpiredAlertService(text, actionType);
    },

    //question
    questionAlert(text, actionType, icon, _class) {
      let msg = text ? text : '';
      // let html = _html ? _html : '';
      let iconQ = icon ? icon : 'question';
      let customCss = _class ? _class : 'text-center';
      return AlertService.questionAlertService(msg, actionType, iconQ, customCss);
    },

    ///success
    successAlert(text, actionType = '') {
      let msg = text ? text : '';
      return AlertService.successAlertService(msg, actionType);
    },

    warningAlert(text, actionType = '') {
      let msg = text ? text : '';
      return AlertService.warningActionService(msg, actionType);
    },

    // For validation in CCM module
    warningValidationAlert(text, actionType = '') {
      let msg = text ? text : '';
      return AlertService.warningActionValidationService(msg, actionType);
    },

    errorAlertConflict(message, html, actionType = '', icon) {
      let msg = message ? message : '';
      let msgHtml = html ? html : '';
      let iconEr = icon ? icon : '';
      return AlertService.errorWithResponseDataService(msg, msgHtml, act, iconEr);
    },

    async pdfViewer(title, fileData) {
      return await AlertService.pdfViewer(title, fileData);
    },

    async pdfViewerMasterChecklist(title, fileData) {
      return await AlertService.pdfViewerMasterChecklist(title, fileData);
    },

    async pdfViewerGearsPRPO(title, fileData) {
      return await AlertService.pdfViewerGearsPRPO(title, fileData);
    },

    async pdfViewerInterOcean(title, fileData, fileData2) {
      return await AlertService.pdfViewerInterOcean(title, fileData, fileData2);
    },

    // loading() {
    //   return AlertService.loadingAlert("test123");
    // },

    async confirmUpdate(message) {
      return await alertService.confirmUpdateAlert(message);
    },

  }
}

export default {AlertMixins}
