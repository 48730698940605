<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">3-Month Plan</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-md-5">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">EOC DATE FROM</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-4 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="eoc_date_from.month"
                                required>
                          <option v-for="(month, index) in months" :key="index" :value="index + 1">{{
                              month
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-3 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="eoc_date_from.year"
                                required>
                          <option v-for="(years) in reportYears" :value="years">{{ years }}</option>

                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">VESSEL</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="vesselId"
                                @change="setVesselName"
                                required>
                          <option :value="0">All</option>
                          <option :value="vessel.id" v-for="(vessel,index) in objVessels" :key="vessel.id" :data-vessel-name="vessel.name">
                            {{ vessel.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">EOC DATE TO</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-4 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="eoc_date_to.month"
                                required>
                          <option v-for="(month, index) in months" :key="index" :value="index + 1">{{
                              month
                            }}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-3 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="eoc_date_to.year"
                                required>
                          <option v-for="(years) in reportYears" :value="years">{{ years }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia mr-1"
                          @click="generatePrintReport(false)" :disabled="isReloading === true">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          @click="generatePrintReport(true)" :disabled="isReloading === true">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs btn-secondary"
                          :disabled="isReloading === true"
                  @click="openInNewWindow(baseURL + liveServerPrefix + summaryReportRoute)">
                    <font-awesome-icon icon="file"/>
                    Summary Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Rank</th>
                    <th style="width: 10%;">Crew</th>
                    <th>Joined</th>
                    <th>Promoted</th>
                    <th>EOC</th>
                    <th style="width: 10%;">Reliever</th>
                    <th> Availability of Reliever</th>
                    <th>CCM</th>
                    <th>Months Onboard</th>
                    <th colspan="6">Vessel Movement</th>
                  </tr>
                  </thead>
                  <template v-if="isReloading === true">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-if="isReloading === false && threeMonthPlanLists.length > 0">
                    <template v-for="(list, plan_list_index) in threeMonthPlanLists">
                      <template v-if="vesselId !== 0 && list.crew_change_plans.length === 0 && isReloading === false">
                        <tr>
                          <td colspan="100%">
                            <span style="size: 20px;font-weight: bold;color: red;">No Data</span>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr style="background-color: rgba(199,199,204,255)">
                          <td colspan="2">Vessel:</td>
                          <td colspan="4">{{ list.name?.toUpperCase() }}</td>
                          <td>Flag:</td>
                          <td colspan="3">{{ list.flag_name?.toUpperCase() }}</td>
                          <td>Port of Call</td>
                          <td>Next Port of Call</td>
                          <td>ETA</td>
                          <td>ETB</td>
                          <td>ETD</td>
                          <td>Remarks</td>
                        </tr>
                        <template v-for="(crew_change_plan, change_plan_index) in list.crew_change_plans">
                          <tr>
                            <td>{{ change_plan_index + 1 }}</td>
                            <td>{{ crew_change_plan.rankAlias?.toUpperCase() }}</td>
                            <td>
                            <span v-if="crew_change_plan.crewSignOff">
                              <span v-if="crew_change_plan.cOffType !== (3 || 4)">
                                <a href="#"
                                   style="font-size: 0.8rem !important;"
                                   @click="gotoProfile(crew_change_plan.crewOffId,crew_change_plan.cOffType)">
                                  {{ crew_change_plan.crewSignOff?.toUpperCase() }}
                                </a>
                              </span>
                            </span>
                              <span v-else>{{ crew_change_plan.crewSignOff?.toUpperCase() }}</span>
                            </td>
                            <td>{{ crew_change_plan.ccOffSignOnDate | moment("DD MMM YYYY") }}</td>
                            <td>{{ crew_change_plan.promoted }}</td>
                            <td>
                            <span v-if="crew_change_plan.isExtension">
                              {{ crew_change_plan.ccOffEocDateFromDocLatestContract | moment("DD MMM YYYY") }}
                            </span>
                              <span v-else>
                              {{ crew_change_plan.ccOffEocDate | moment("DD MMM YYYY") }}
                            </span>
                            </td>
                            <td>
                              <span v-if="crew_change_plan.crewSignOn">
                                <span v-if="crew_change_plan.cOnType !== (3 || 4)">
                                  <a href="#"
                                     style="font-size: 0.8rem !important;"
                                     @click="gotoProfile(crew_change_plan.crewOnId,crew_change_plan.cOnType)">
                                    {{ crew_change_plan.crewSignOn?.toUpperCase() }}
                                  </a>
                                </span>
                                <span v-else class="ml-2 small">{{ crew_change_plan.crewSignOn }}</span>
                              </span>
                            </td>
                            <td>{{ crew_change_plan.availability }}</td>
                            <td>
                              <a href="#"
                                 style="font-size: 0.8rem !important;"
                                 @click="gotoCrewChangeMemo(crew_change_plan.cBatchingId, crew_change_plan.cBatchingNo, list.vessel_id)">
                                {{ crew_change_plan.cBatchingNo }}
                              </a>
                            </td>
                            <td :class="getCriticality(parseInt(crew_change_plan.monthsOnBoard))">{{ crew_change_plan.monthsOnBoard }}</td>
                            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
                              {{ change_plan_index === 0 ? crew_change_plan.portOfCall : '' }}
                            </td>
                            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
                              {{ change_plan_index === 0 ? crew_change_plan.nextPortOfCall : '' }}
                            </td>
                            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
                              {{ change_plan_index === 0 ? crew_change_plan.eta : '' }}
                            </td>
                            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
                              {{ change_plan_index === 0 ? crew_change_plan.etb : '' }}
                            </td>
                            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
                              {{ change_plan_index === 0 ? crew_change_plan.etd : '' }}
                            </td>
                            <td v-if="change_plan_index === 0" :rowspan="list.crew_change_plans.length" class="align-middle">
                              {{ change_plan_index === 0 ? crew_change_plan.remarks : '' }}
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <tr v-if = "isReloading === false">
                      <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div>

        </div>
        <table style="width:100%;">
          <tbody>
          <tr>
            <td><span style="font-weight:bold;">Legend :</span></td>
            <td><span style="text-align:right;" class="legend_near_critical"><b>Near Critical (5-7 mos / Danish 3-5 mos)</b></span></td>
            <td><span style="text-align:right;" class="legend_critical"><b>Critical (8-10 mos / Danish 6-10 mos)</b></span></td>
            <td><span style="text-align:right;" class="legend_over_critical"><b>Over Critical (11 and up mos)</b></span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {CommonMixins} from "@/mixins/common.mixins";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import {toUpperCase} from "uri-js/dist/esnext/util";
import axios from "axios";
import {FileService} from "@/service/file.service";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  name: "ThreeMonthPlan",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      printMethodBack : true,
      baseURL: window.location.origin,
      summaryReportRoute: '/h/three-month-plan-summary?view=three-month-plan-summary',
      printRoute : '/h/three-month-plan/print?view=three-month-plan/print',
      liveServerPrefix: global_variables_config.liveServerPrefix,
      vesselId : 0,
      selectedVessel : 'All Vessel',
      eoc_date_from : {
        month : new Date().getMonth() + 1, // Set the initial value to the current month
        year : new Date().getFullYear()
      },
      eoc_date_to : {
        month : new Date().getMonth() + 1, // Set the initial value to the current month
        year : new Date().getFullYear()
      },
      reportYears: {},
      allData : [],
      threeMonthPlanLists: [],
      threeMonthPlanListsToPrint: [],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      isReloading : true
    }
  },


  /** methods**/
  methods: {
    toUpperCase,
    ...mapActions([
      'getCrewComplimentPerVessel',
    ]),
    yearList() {
      let yearList = [];
      const currentYear = new Date().getFullYear();
      for (let i = 0; i < 3; i++) {
        yearList.push(currentYear + i);
      }
      this.reportYears = yearList;
    },
    filterByInputs() {
      let _this = this;

      let mappedData = _.map(this.allData, function (vesselData) {
        let newCrewChangePlan = _.filter(vesselData.crew_change_plans, (crew_change_data) => {
          let eocDate = crew_change_data.isExtension === true ? crew_change_data.ccOffEocDateFromDocLatestContract : crew_change_data.ccOffEocDate;
          const newDate = new Date(eocDate);
          const eocYear = newDate.getFullYear();
          const eocMonth = newDate.getMonth() + 1;

          return (eocYear >= _this.eoc_date_from.year && eocYear <= _this.eoc_date_to.year) && eocMonth >= _this.eoc_date_from.month && eocMonth <= _this.eoc_date_to.month;
        });

        return {
          crew_change_plans : newCrewChangePlan,
          flag_name : vesselData.flag_name,
          name : vesselData.name,
          vessel_id : vesselData.vessel_id,
        };
      });

      return _.filter(mappedData, function(vesselData) {
        let condition = Object.keys(vesselData.crew_change_plans).length !== 0;
        if (_this.vesselId !== 0) {
          condition = Object.keys(vesselData.crew_change_plans).length !== 0 && vesselData.vessel_id === _this.vesselId;
        }
        return condition;
      });
    },
    sendPrintReport(vesselData) {
      this.$nextTick(()=> {
        axios.post(PMC.PRINT_THREE_MONTH_PLAN_LIST,{
          vessel_name : this.selectedVessel.toLowerCase(),
          data_to_print : vesselData,
          eoc_date_from : {
            month : this.months[this.eoc_date_from.month - 1],
            year : this.eoc_date_from.year,
          },
          eoc_date_to   : {
            month : this.months[this.eoc_date_to.month - 1],
            year : this.eoc_date_to.year,
          },
        }).then(r =>{
          let fScr = FileService.base64FileToBlobUrl(r.data);
          Swal.close();
          this.pdfViewer('File', fScr);
        }).catch((error)=>{
          Swal.close();
          return false;
        });
      })
    },
    openPrintPopupWindow (vesselData) {
      Swal.close();
      localStorage.setItem('three_month_plan_data', JSON.stringify({
        vessel_name : this.selectedVessel.toLowerCase(),
        data_to_print : vesselData,
        eoc_date_from : {
          month : this.months[this.eoc_date_from.month - 1],
          year : this.eoc_date_from.year,
        },
        eoc_date_to   : {
          month : this.months[this.eoc_date_to.month - 1],
          year : this.eoc_date_to.year,
        },
      }));
      this.openInNewWindow(this.baseURL + this.liveServerPrefix + this.printRoute);
      },
    getAllReport() {
      this.isReloading = true;
      for (let counter = 0; counter < this.vesselIdsToRequest.length; counter++) {
          let vesselId = this.vesselIdsToRequest[counter];
            axios.get(PMC.GET_THREE_MONTH_PLAN_LIST + '?vesselId=' + vesselId).then(r => {
              r.data.vessel_data.vessel_id = vesselId;
              this.allData.push(r.data.vessel_data);
              if (counter >= (this.vesselIdsToRequest.length - 1)) {
                this.allData = _.orderBy(this.allData, ['name']);
                this.generatePrintReport(false);
              }
            }).catch(error => {
              UtilityService.generateResponseMessage(error, 'Generate three month plan list');
            });
      }
    },
    validateEocDateFromAndTo() {
      let validationResult = {
        status : true,
        message : 'Success'
      };

      if (this.eoc_date_from.year > this.eoc_date_to.year || this.eoc_date_from.month > this.eoc_date_to.month) {
        validationResult.status = false;
        validationResult.message = 'Invalid date range';
      } else if ((this.eoc_date_to.year - this.eoc_date_from.year) > 1) {
        validationResult.status = false;
        validationResult.message = 'Date Range is too long';
      }
      return validationResult;
    },
    generatePrintReport(printReport) {
      let eocDateRangeValidationResult = this.validateEocDateFromAndTo();
      if (eocDateRangeValidationResult.status === false) {
        return this.errorAlert(eocDateRangeValidationResult.message, '');
      }

      if (printReport === false) {
        this.isReloading = true;
      } else {
        AlertService.loadingAlert('Please wait ...', 'Generating PDF for Three Month Plan');
      }
      let _this = this;
      let filteredData = this.filterByInputs();
      setTimeout(() => {
        if (printReport === false) {
          this.isReloading = false;
          this.threeMonthPlanLists = filteredData;
        } else {
          if (this.printMethodBack === false) {
            _this.openPrintPopupWindow(filteredData);
          } else {
            this.sendPrintReport(filteredData);
          }
        }
      }, 100);
    },
    getCriticality(monthsOnBoard) {
      if (monthsOnBoard >= 5 && monthsOnBoard <= 7) {
        return 'legend_near_critical';
      }
      if (monthsOnBoard >= 8 && monthsOnBoard <= 10) {
        return 'legend_critical';
      }
      if (monthsOnBoard >= 11) {
        return 'legend_over_critical';
      }
      return '';
    },
    gotoCrewChangeMemo(batchId, ccmNumber, vesselID) {
      let routeData = this.$router.resolve({ //
        name: 'CrewChangePlanCCM',
        params: {
          batchId: `${batchId}`,
          ccmNumber: `${ccmNumber}`,
        },
        query : {
          selectedVesselId: `${vesselID}`,
          view : 'crew-change-plan'
        }
      });

      window.open(routeData.href, '_blank',"height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));
    },
    gotoProfile(thisCrewId, crewType) {
      if (crewType !== (3 && 4)) {
        this.$nextTick(() => {
          let routeData = this.$router.resolve({ //
            name: 'CrewProfile',
            params: {
              crewId: thisCrewId,
            },
          });
          window.open(routeData.href, '_blank', "height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));
        })
      }
    },
    setVesselName(e) {
      const selectedOption = e.target.options[event.target.selectedIndex];
      this.selectedVessel = selectedOption.getAttribute('data-vessel-name') === null ? 'All Vessel' : selectedOption.getAttribute('data-vessel-name');
    },
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objVessels',
      ]
    ),
    vesselIdsToRequest() {
      let vesselIds = [];
      if (this.vesselId > 0) {
        vesselIds = [this.vesselId];
      } else {
        this.objVessels.forEach((vesselData) => {
          vesselIds.push(vesselData.id);
        });
      }
      return vesselIds;
    }
  },

  /** mounted **/
  mounted() {
    this.yearList();
  },

  /** created **/
  async created() {
    await this.getCrewComplimentPerVessel();
    this.$globalVariables = global_variables_config;
    this.getAllReport();
  },

  beforeDestroy() {
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}

.legend_near_critical {
  background-color: green;
  color: white;
}

.legend_critical {
  background-color: rgba(255,255,0,255);
}

.legend_over_critical {
  background-color: red;
  color: white;
}
</style>
