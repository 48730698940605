<template>
  <div>
    <ccm-master-checklist-edit-document
        @refresh-ccm-checklist="refreshFromCrewChangeMemo"
        ref="checklistEditDoc"
        :modal-id="viewCcmMasterChecklistModal"
        :file-input-id="fileInputCcmMasterChecklist"
    >
      <template v-slot:table-contents>
        <!--                  Documents-->
        <div class="col-md-4">
          <div class="row">
            <div class="form-inline mb-3">
              <div class="form-group mb-2">
                <h5 class="font-weight-bold lead">Documents</h5>
              </div>

            </div>

          </div>
        </div>

        <table class="table table-bordered table-sm text-center small">
          <thead>
          <tr>
            <th style="width: 3%;">
              #
            </th>
            <th class="">NAME</th>
            <th class="">RANK</th>
            <th class="">AGE</th>
            <th class="">MEDICAL CERT ISSUED DATE</th>
            <th class="">Y-FEVER</th>
            <th class="">CHOLERA EXP</th>
            <th class="">POLIO</th>
            <th class="">SIRB VALIDITY</th>
            <th class="">PASSPORT VALIDITY</th>
            <th class="">US VISA VALIDITY</th>

            <template v-if="ccmFlagId == $globalVariables.flag.liberia">
              <th class="">LIBERIA EXP</th>
              <th class="">LIBERIA GMDDS</th>
              <th class="">LIBERIA SSO</th>
            </template>

            <template v-if="ccmFlagId == $globalVariables.flag.panama">
              <th class="">PANAMA EXP</th>
              <th class="">PANAMA GMDDS EXP</th>
              <th class="">PANAMA SSO EXP</th>
              <th class="">PANAMA SDSD ENDO EXP</th>
            </template>


            <template v-if="ccmFlagId == $globalVariables.flag.singapore">
              <th class="">SINGAPORE GMDDS ENDO</th>
            </template>

            <template v-if="ccmFlagId == $globalVariables.flag.marshallIslands">
              <th class="">MARSHALL ISLANDS EXP</th>
              <th class="">MARSHALL ISLANDS GMDDS ENDO</th>
              <th class="">MARSHALL ISLANDS SSO</th>
            </template>

            <th style="width: 1%;" class="">COC EXP DATE</th>
            <th class="" style="width: 5%;">GOC EXP</th>
            <th style="width: 11%;" class="">COP II/4 & III/4 ISSUED DATE</th>
            <th style="width: 11%;" class="">COP II/5 & III/5 ISSUED DATE</th>
            <!--            <th style="width: 35%;" class="">NCI & NCII & NCIII EXP DATE</th>-->
          </tr>
          </thead>
          <tbody>


          <template v-if="loading">
            <tr>
              <td colspan="100%">
                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                <span style="size: 20px">
                          Loading ...
                        </span>
              </td>
            </tr>
          </template>

          <template v-else>
            <template v-if="ccmMasterChecklist ? (ccmMasterChecklist.length != 0) : '' ">
              <tr v-for="(checklist, index) in ccmMasterChecklist" :key="checklist.id">
                <td class="text-center align-middle">
                  {{ index + 1 }}
                  {{ checklist.ccMemoVesselId }}
                </td>
                <td class="text-center align-middle">
                  <a href="#"
                     @click.stop="gotoCrewProfileNewWindow(checklist.crewId, 'all-info')"
                     style="color:black;">
                    <small><b>{{ checklist.crewName }} </b></small>
                  </a>
                </td>

                <td class="text-center align-middle">
                  {{ checklist.rankAlias }}
                </td>

                <td class="text-center align-middle">
                  {{ checklist.age }}
                </td>


                <!--                      MEDICAL CERT ISSUED DATE-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.medExamCert.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.medExamCert.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.medExamCert.hasFile == 0,
                           }"
                >
                  {{ checklist.medExamCert.dateIssued }}
                </td>


                <!--                      YELLOW FEVER-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.yellowFever.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.yellowFever.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.yellowFever.hasFile == 0,
                           }"
                >
                  {{ checklist.yellowFever.dateIssued }}
                  <br>
                  {{ checklist.yellowFever.document_no2 }}
                  <br>
                </td>


                <!--                      CHOLERA-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.cholera.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.cholera.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.cholera.hasFile == 0,
                           }"
                >
                  {{ checklist.cholera.dateExpiry }}
                </td>


                <!--                      POLIO-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.polio.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.polio.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.polio.hasFile == 0,
                           }"
                >
                  {{ checklist.polio.dateExpiry }}
                </td>


                <!--                      SIRB-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.sirb.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.sirb.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.sirb.hasFile == 0,
                           }"
                >
                  {{ checklist.sirb.dateExpiry }}
                </td>


                <!--                      PASSPORT-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.passport.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.passport.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.passport.hasFile == 0,
                           }"
                >
                  {{ checklist.passport.dateExpiry }}
                </td>


                <!--                      US VISA-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.usVisa.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.usVisa.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.usVisa.hasFile == 0,
                           }"
                >
                  {{ checklist.usVisa.dateExpiry }}
                </td>


                <!--                LIBERIA FLAG-->
                <template v-if="checklist.isLiberiaFlag">
                  <!--                      LIBERIA BOOK-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.liberiaBook.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.liberiaBook.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.liberiaBook.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.liberiaBook.rank && checklist.liberiaBook.rank && checklist.rankId,
                           }"
                  >
                    {{ checklist.liberiaBook.rankAlias }}
                    <br>
                    {{ checklist.liberiaBook.document_no }}
                    <br>
                    {{ checklist.liberiaBook.dateExpiry }}
                  </td>


                  <!--                      LIBERIA GMDDS-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.liberiaGmdds.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.liberiaGmdds.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.liberiaGmdds.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.liberiaGmdds.rank && checklist.liberiaGmdds.rank && checklist.rankId,
                           }"
                  >
                    {{ checklist.liberiaGmdds.rankAlias }}
                    <br>
                    {{ checklist.liberiaGmdds.document_no }}
                    <br>
                    {{ checklist.liberiaGmdds.dateExpiry }}
                  </td>


                  <!--                      LIBERIA SSO-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.liberiaSso.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.liberiaSso.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.liberiaSso.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.liberiaSso.rank && checklist.liberiaSso.rank && checklist.rankId,
                           }"
                  >
                    {{ checklist.liberiaSso.rankAlias }}
                    <br>
                    {{ checklist.liberiaSso.document_no }}
                    <br>
                    {{ checklist.liberiaSso.dateExpiry }}
                  </td>
                </template>


                <!--                PANAMA FLAG-->
                <template v-if="checklist.isPanamaFlag">
                  <!--                      PANAMA BOOK-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.panamaBook.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.panamaBook.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.panamaBook.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.panamaBook.rank && checklist.panamaBook.rank && checklist.rankId,
                           }"
                  >
                    {{ checklist.panamaBook.rankAlias }}
                    <br>
                    {{ checklist.panamaBook.document_no }}
                    <br>
                    {{ checklist.panamaBook.dateExpiry }}
                  </td>


                  <!--                      PANAMA GMDDS BOOK-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.panamaGmddsBook.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.panamaGmddsBook.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.panamaGmddsBook.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.panamaGmddsBook.rank && checklist.panamaGmddsBook.rank && checklist.rankId,
                           }"
                  >
                    {{ checklist.panamaGmddsBook.rankAlias }}
                    <br>
                    {{ checklist.panamaGmddsBook.document_no }}
                    <br>
                    {{ checklist.panamaGmddsBook.dateExpiry }}
                  </td>


                  <!--                      PANAMA SSO-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.panamaSso.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.panamaSso.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.panamaSso.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.panamaSso.rank && checklist.panamaSso.rank && checklist.rankId,

                           }"
                  >
                    {{ checklist.panamaSso.rankAlias }}
                    <br>
                    {{ checklist.panamaSso.document_no }}
                    <br>
                    {{ checklist.panamaSso.dateExpiry }}
                  </td>


                  <!--                      PANAMA SDSD ENDO-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.panamaSdsdEndo.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.panamaSdsdEndo.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.panamaSdsdEndo.hasFile == 0,
                           }"
                  >
                    {{ checklist.panamaSdsdEndo.dateExpiry }}
                  </td>
                </template>


                <!--                SINGAPORE FLAG-->
                <template v-if="checklist.isSingaporeFlag">
                  <!--                      SINGAPORE GMDDS ENDO-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.singaporeGmddsEndo.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.singaporeGmddsEndo.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.singaporeGmddsEndo.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.singaporeGmddsEndo.rank && checklist.singaporeGmddsEndo.rank && checklist.rankId,
                           }"
                  >
                    {{ checklist.singaporeGmddsEndo.rankAlias }}
                    <br>
                    {{ checklist.singaporeGmddsEndo.document_no }}
                    <br>
                    {{ checklist.singaporeGmddsEndo.dateExpiry }}
                  </td>
                </template>


                <!--                MARSHALL ISLANDS FLAG-->
                <template v-if="checklist.isMarshallIslandFlag">
                  <!--                      MARSHALL ISLANDS BOOK-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.marshallIslandBook.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.marshallIslandBook.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.marshallIslandBook.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.marshallIslandBook.rank && checklist.marshallIslandBook.rank && checklist.rankId,
                           }"
                  >
                    {{ checklist.marshallIslandBook.rankAlias }}
                    <br>
                    {{ checklist.marshallIslandBook.document_no }}
                    <br>
                    {{ checklist.marshallIslandBook.dateExpiry }}
                  </td>


                  <!--                      MARSHALL ISLANDS GMDDS ENDO-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.marshallIslandGmddsEndo.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.marshallIslandGmddsEndo.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.marshallIslandGmddsEndo.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.marshallIslandGmddsEndo.rank && checklist.marshallIslandGmddsEndo.rank && checklist.rankId,
                           }"
                  >
                    {{ checklist.marshallIslandGmddsEndo.rankAlias }}
                    <br>
                    {{ checklist.marshallIslandGmddsEndo.document_no }}
                    <br>
                    {{ checklist.marshallIslandGmddsEndo.dateExpiry }}
                  </td>


                  <!--                      MARSHALL ISLANDS SSO-->
                  <td class="text-center align-middle"
                      @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.marshallIslandSso.id) : null"
                      :class="{
                              'no-documents-or-expired' : checklist.marshallIslandSso.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.marshallIslandSso.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.marshallIslandSso.rank && checklist.marshallIslandSso.rank && checklist.rankId,
                           }"
                  >
                    {{ checklist.marshallIslandSso.rankAlias }}
                    <br>
                    {{ checklist.marshallIslandSso.document_no }}
                    <br>
                    {{ checklist.marshallIslandSso.dateExpiry }}
                  </td>
                </template>


                <!--                      COC-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.coc.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.coc.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.coc.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.coc.rank && checklist.coc.rank && checklist.rankId,
                              'mismatch-doc-no2-detail' : checklist.coc.isManagementRank && !checklist.coc.isCocMgmt,
                              'mismatch-doc-no2-detail' : !checklist.coc.isManagementRank && checklist.coc.isCocMgmt,
                           }"
                >
                  {{ checklist.coc.rankAlias }}
                  <br>
                  {{ checklist.coc.document_no }}
                  <br>
                  {{ checklist.coc.dateExpiry }}
                </td>


                <!--                      GOC-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.goc.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.goc.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.goc.hasFile == 0,
                           }"
                >
                  {{ checklist.goc.dateExpiry }}
                </td>


                <!--                      COP 4-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copDeckEngineRating_4.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copDeckEngineRating_4.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copDeckEngineRating_4.hasFile == 0,
                           }"
                >
                  {{ checklist.copDeckEngineRating_4.document_no }}
                  <br>
                  {{ checklist.copDeckEngineRating_4.dateIssued }}
                </td>

                <!--                      COP 5-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copDeckEngineRating_5.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copDeckEngineRating_5.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copDeckEngineRating_5.hasFile == 0,
                           }"
                >
                  {{ checklist.copDeckEngineRating_5.document_no }}
                  <br>
                  {{ checklist.copDeckEngineRating_5.dateIssued }}
                </td>

                <!--                      COC-->
                <!--            <td class="text-center align-middle"-->
                <!--                :class="{-->
                <!--                              'no-documents-or-expired' : checklist.coc_nc.isExpired,-->
                <!--                              'no-file-upload-or-old-file-uploaded' : checklist.coc_nc.hasFile == 0,-->
                <!--                           }"-->
                <!--            >-->
                <!--              {{ checklist.coc_nc.coc_nc_type ?? '' }}-->
                <!--              <br>-->
                <!--              {{ checklist.coc_nc.dateExpiry ?? '' }}-->
                <!--            </td>-->

              </tr>

            </template>

            <template v-else>
              <tr>
                <td colspan="100%" class="bg-light-danger font-weight-bolder">
                  <p class="text-center mt-3">No Results</p>
                </td>
              </tr>
            </template>


          </template>

          </tbody>

        </table>


        <div class="col-md-4">
          <div class="row">
            <div class="form-inline mb-3">
              <div class="form-group mb-2">
                <h5 class="font-weight-bold lead">Certificates</h5>
              </div>

            </div>

          </div>
        </div>
        <!--                  Certificates-->
        <table class="table table-bordered table-sm text-center small">
          <thead>
          <tr>
            <th style="width: 1%;">
              #
            </th>
            <th class="">NAME</th>
            <th class="">RANK</th>
            <th class="">COP BST</th>
            <th class="">COP PSCRB</th>
            <th class="">COP AFF</th>
            <th class="">COP MECA</th>
            <th class="">COP MEFA</th>
            <th class="">COP SSO</th>
            <th class="">COP SDSD</th>
            <!--            <th class=""> D/E Watch</th>-->
            <!--            <th class="">SDSD</th>-->
            <!--            <th class="">BTU</th>-->
            <!--            <th class="">BT</th>-->
            <!--            <th class="">BTR</th>-->
            <th v-if="ccmVesselEcdisName" style="width: 17%;" class="">
              {{ ccmVesselEcdisName }} Issued Date
            </th>
            <th v-else>No Ecdis</th>
            <th style="" class="">PDOS</th>
            <th style="" class="">APAS</th>
            <th style="" class="">AMCV EXP</th>
          </tr>
          </thead>
          <tbody>

          <template v-if="loading">
            <tr>
              <td colspan="100%">
                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                <span style="size: 20px">
                          Loading ...
                        </span>
              </td>
            </tr>
          </template>


          <template v-else>
            <template v-if="ccmMasterChecklist ? (ccmMasterChecklist.length != 0) : '' ">
              <tr v-for="(checklist, index) in ccmMasterChecklist" :key="checklist.id">
                <td class="text-center align-middle">
                  {{ index + 1 }}
                </td>
                <td class="text-center align-middle">
                  <a href="#"
                     @click.stop="gotoCrewProfileNewWindow(checklist.crewId, 'gears-po')"
                     style="color:black;">
                    <small><b>{{ checklist.crewName }} </b></small>
                  </a>

                </td>

                <td class="text-center align-middle">
                  {{ checklist.rankAlias }}
                </td>

                <!--                      COP BST-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copBst.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copBst.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copBst.hasFile == 0,
                           }"
                >
                  {{ checklist.copBst.dateExpiry }}
                </td>


                <!--                      COP PSCRB-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copPscrb.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copPscrb.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copPscrb.hasFile == 0,
                           }"
                >
                  {{ checklist.copPscrb.dateExpiry }}
                </td>


                <!--                      COP_AFF-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copAff.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copAff.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copAff.hasFile == 0,
                           }"
                >
                  {{ checklist.copAff.dateExpiry }}
                </td>


                <!--                      COP_MECA-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copMeca.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copMeca.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copMeca.hasFile == 0,
                           }"
                >
                  {{ checklist.copMeca.dateIssued }}
                </td>


                <!--                      COP_MEFA-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copMefa.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copMefa.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copMefa.hasFile == 0,
                           }"
                >
                  {{ checklist.copMefa.dateIssued }}
                </td>


                <!--                      COP_SSO-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copSso.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copSso.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copSso.hasFile == 0,
                           }"
                >
                  {{ checklist.copSso.dateIssued }}
                </td>


                <!--                      COP_SDSD-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copSdsd.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copSdsd.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copSdsd.hasFile == 0,
                           }"
                >
                  {{ checklist.copSdsd.dateIssued }}
                </td>


                <!--            &lt;!&ndash;                      DE_WATCH&ndash;&gt;-->
                <!--            <td class="text-center align-middle"-->
                <!--                :class="{-->
                <!--                              'no-documents-or-expired' : checklist.de_watch.isExpired,-->
                <!--                              'no-file-upload-or-old-file-uploaded' : checklist.de_watch.hasFile == 0,-->
                <!--                           }"-->
                <!--            >-->
                <!--              {{ checklist.de_watch.dateIssued }}-->
                <!--            </td>-->


                <!--            &lt;!&ndash;                      SSAT_SDSD&ndash;&gt;-->
                <!--            <td class="text-center align-middle"-->
                <!--                :class="{-->
                <!--                              'no-documents-or-expired' : checklist.ssat_sdsd.isExpired,-->
                <!--                              'no-file-upload-or-old-file-uploaded' : checklist.ssat_sdsd.hasFile == 0,-->
                <!--                           }"-->
                <!--            >-->
                <!--              {{ checklist.ssat_sdsd.dateIssued }}-->
                <!--            </td>-->


                <!--            &lt;!&ndash;                      BASIC_TRAINING_UPDATED&ndash;&gt;-->
                <!--            <td class="text-center align-middle"-->
                <!--                :class="{-->
                <!--                              'no-documents-or-expired' : checklist.basic_training_updated.isExpired,-->
                <!--                              'no-file-upload-or-old-file-uploaded' : checklist.basic_training_updated.hasFile == 0,-->

                <!--                           }"-->
                <!--            >-->
                <!--              {{ checklist.basic_training_updated.dateIssued }}-->
                <!--            </td>-->


                <!--            &lt;!&ndash;                      BASIC_TRAINING&ndash;&gt;-->
                <!--            <td class="text-center align-middle"-->
                <!--                :class="{-->
                <!--                              'no-documents-or-expired' : checklist.basic_training.isExpired,-->
                <!--                              'no-file-upload-or-old-file-uploaded' : checklist.basic_training.hasFile == 0,-->
                <!--                           }"-->
                <!--            >-->
                <!--              {{ checklist.basic_training.dateIssued }}-->
                <!--            </td>-->


                <!--            &lt;!&ndash;                      BASIC_TRAINING_REFRESHER&ndash;&gt;-->
                <!--            <td class="text-center align-middle"-->
                <!--                :class="{-->
                <!--                              'no-documents-or-expired' : checklist.basic_training_refresher.isExpired,-->
                <!--                              'no-file-upload-or-old-file-uploaded' : checklist.basic_training_refresher.hasFile == 0,-->
                <!--                           }"-->
                <!--            >-->
                <!--              {{ checklist.basic_training_refresher.dateIssued }}-->
                <!--            </td>-->


                <!--                      ECDIS_GENERIC-->
                <td class="text-center align-middle"
                    v-if="ccmVesselEcdisId === ecdisTypeId.ecdisGeneric"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.ecdis_generic.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.ecdis_generic.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.ecdis_generic.hasFile == 0,
                           }"
                >
                  {{ checklist.ecdis_generic.dateIssued }}
                </td>

                <!--                      ECDIS_SPECIFIC_TRAINING-->
                <td class="text-center align-middle"
                    v-else-if="ccmVesselEcdisId === ecdisTypeId.ecdisSpecificTraining"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.ecdis_specific_training.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.ecdis_specific_training.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.ecdis_specific_training.hasFile == 0,
                           }"
                >
                  {{ checklist.ecdis_specific_training.dateIssued }}
                </td>

                <!--                      ECDIS_JRC_701B_901B-->
                <td class="text-center align-middle"
                    v-else-if="ccmVesselEcdisId === ecdisTypeId.ecdisJrc701b901b"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.ecdis_jrc_jan_701b_901b.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.ecdis_jrc_jan_701b_901b.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.ecdis_jrc_jan_701b_901b.hasFile == 0,
                           }"
                >
                  {{ checklist.ecdis_jrc_jan_701b_901b.dateIssued }}
                </td>

                <!--                      ECDIS_FURUNO1-->
                <td class="text-center align-middle"
                    v-else-if="ccmVesselEcdisId === ecdisTypeId.ecdisFuruno1"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.ecdis_furuno_1.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.ecdis_furuno_1.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.ecdis_furuno_1.hasFile == 0,
                           }"
                >
                  {{ checklist.ecdis_furuno_1.dateIssued }}
                </td>

                <!--                      ECDIS_FURUNO2-->
                <td class="text-center align-middle"
                    v-else-if="ccmVesselEcdisId === ecdisTypeId.ecdisFuruno2"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.ecdis_furuno_2.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.ecdis_furuno_2.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.ecdis_furuno_2.hasFile == 0,
                           }"
                >
                  {{ checklist.ecdis_furuno_2.dateIssued }}
                </td>

                <!--                      ECDIS_JRC_JAN_7201_9201-->
                <td class="text-center align-middle"
                    v-else-if="ccmVesselEcdisId === ecdisTypeId.ecdisJrc72019201"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.ecdis_jrc_jan_7201_9201.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.ecdis_jrc_jan_7201_9201.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.ecdis_jrc_jan_7201_9201.hasFile == 0,
                           }"
                >
                  {{ checklist.ecdis_jrc_jan_7201_9201.dateIssued }}
                </td>

                <!--                      ECDIS_VISON_MASTER_FT-->
                <td class="text-center align-middle"
                    v-else-if="ccmVesselEcdisId === ecdisTypeId.ecdisVisonMasterFt"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.ecdis_visonmaster_ft.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.ecdis_visonmaster_ft.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.ecdis_visonmaster_ft.hasFile == 0,
                           }"
                >
                  {{ checklist.ecdis_visonmaster_ft.dateIssued }}
                </td>

                <!--                      ECDIS_TRANSAS-->
                <td class="text-center align-middle"
                    v-else-if="ccmVesselEcdisId === ecdisTypeId.ecdisTransas"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.ecdis_transas.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.ecdis_transas.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.ecdis_transas.hasFile == 0,
                            }"
                >
                  {{ checklist.ecdis_transas.dateIssued }}
                </td>

                <!--                      ECDIS_TOKYO_KEIKI-->
                <td class="text-center align-middle"
                    v-else-if="ccmVesselEcdisId === ecdisTypeId.ecdisTokyoKeiki"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.ecdis_tokyo_keiki.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.ecdis_tokyo_keiki.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.ecdis_tokyo_keiki.hasFile == 0,
                           }"
                >
                  {{ checklist.ecdis_tokyo_keiki.dateIssued }}
                </td>

                <!--                      NO_ECDIS-->
                <td v-else>---</td>

                <!--                      PDOS-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.pdos.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.pdos.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.pdos.hasFile == 0,
                           }"
                >
                  {{ checklist.pdos.dateIssued }}
                </td>

                <!--                      APAS-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.apas.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.apas.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.apas.hasFile == 0,
                           }"
                >
                  {{ checklist.apas.dateIssued }}
                </td>

                <!--                      AMCV-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.amcv.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.amcv.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.amcv.hasFile == 0,
                           }"
                >
                  {{ checklist.amcv.dateExpiry }}
                </td>

              </tr>
            </template>

            <template v-else>
              <tr>
                <td colspan="100%" class="bg-light-danger font-weight-bolder">
                  <p class="text-center mt-3">No Results</p>
                </td>
              </tr>
            </template>

          </template>

          </tbody>

        </table>

      </template>

    </ccm-master-checklist-edit-document>
  </div>
</template>

<script>
import Vue from "vue";
import {global_variables_config} from "@/config/global-variable.config";
import {mapActions, mapGetters} from "vuex";
import noDocumentPrev from "@/assets/noDocumentPrev.png";
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import Table from "@/components/base/Table.vue";
import {AlertService} from "@/service/alert.service";

export default {
  name: "CcmMasterChecklist",
  components: {Table},
  mixins: [AppMixins, AlertMixins],
  props: {

    ccmMasterChecklist: {
      type: Array,
      default: [],
    },

    ccmVesselId: {
      type: Number,
      default: 0,
    },

    ccmVesselEcdisId: {
      type: Number,
      default: 0,
    },

    ccmVesselEcdisName: {
      type: String,
      default: null,
    },

    ccmFlagId: {
      type: Number,
      default: 0,
    },

    ccmFlagName: {
      type: String,
      default: 'Flag Name',
    },

    loading: {
      type: Boolean,
      default: false,
    },

  },

  data() {
    return {
      viewCcmMasterChecklistModal: 'view-ccm-master-checklist-modal',
      fileInputCcmMasterChecklist: 'file-input-ccm-master-checklist',
      ecdisTypeId: {
        ecdisGeneric: 514,
        ecdisSpecificTraining: 597,
        ecdisJrc701b901b: 647,
        ecdisFuruno1: 648,
        ecdisFuruno2: 720,
        ecdisJrc72019201: 733,
        ecdisVisonMasterFt: 816,
        ecdisTransas: 827,
        ecdisTokyoKeiki: 831,
      },
    }
  },

  methods: {
    ...mapActions(
      [
        'get_cc_memo_details',
      ],
    ),
    view_document(crewDocId) {
      this.$refs.checklistEditDoc.view_document(crewDocId);
    },

    refreshFromCrewChangeMemo() {
      this.$emit('refresh-ccm-checklist');
    },
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'crewChangeMemo',
      ],
    ),
  },

  created() {
    this.$globalVariables = global_variables_config
  },

  beforeDestroy() {
    this.$globalVariables = null;
  },

}
</script>

<style scoped>

</style>
