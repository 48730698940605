export const API_URL = {
  index(){
    let currentUrl = window.location.href;
    let url = '';
    url = currentUrl.substring(0, currentUrl.indexOf("/", 9));
    return `${url}/pmc-api/public`+API_PMC;
  }
};

export const API_PMC = '/api/pmc/'

export default { API_URL,API_PMC }
