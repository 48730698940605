import axios from 'axios';
import {PMC} from '../../../config/constants.config';
import {UtilityService} from '../../../service/utility.service';

/** state **/
const state = {
  crewMedical: {},
  crewMedicals: [],

  newCrewMedical: {
    dateEndorsed: '',
    dateCheckUp: '',
    clinic: '',
    medicalIssues: [],
    isFitToWork: null,
    isCompleted: null,
    diagnosis: null,
    remarks: null,
    file: null,
  },

  crewMedicalBlobFile: null,
  crewMedicalBlobFile_gte10mb: null,

  formCrewMedicalBlob: null,
  formCrewMedicalBlob_gte10mb: null,

  is10mbMedical: false,
};

/** mutations **/
const mutations = {
  SET_CREW_MEDICAL(state, params) {
    state.crewMedical = params;
  },

  SET_CREW_MEDICALS(state, params) {
    state.crewMedicals = params;
  },

  CREATE_CREW_MEDICAL(state, params) {
    state.crewMedicals.unshift(params);
    state.newCrewMedical = {
      dateEndorsed: '',
      dateCheckup: '',
      clinic: '',
      medicalIssues: [],
      isFitToWork: null,
      isCompleted: null,
      diagnosis: null,
      remarks: null,
      file: null,

    };
  },

  UPDATE_CREW_MEDICAL(state, params) {
    let crewMedical = state.crewMedicals.find((medical, index) => medical.id === params.id);
    Object.assign(crewMedical, params);
  },

  DELETE_CREW_MEDICAL(state, params) {
    let index = state.crewMedicals.findIndex((val, index) => val.id === params.id);
    state.crewMedicals.splice(index, 1);

  },

  NULL_CREW_MEDICAL(state) {
    state.crewMedical = {};
  },

  NULL_CREW_MEDICALS(state) {
    state.crewMedicals = [];
  },

  NULL_NEW_CREW_MEDICAL() {
    state.newCrewMedical = {
      dateEndorsed: null,
      dateCheckup: null,
      clinic: null,
      medicalIssues: [],
      isFitToWork: null,
      isCompleted: null,
      diagnosis: null,
      remarks: null,
    };
  },

  NULL_CREW_MEDICAL_BLOBFILE(state) {
    state.crewMedicalBlobFile = null;
  },

  //crewMedicalBlobFile_gte10mb
  set_crew_medical_blob_file_gte10mb(state, param) {
    state.crewMedicalBlobFile_gte10mb = param;
  },

  null_crew_medical_blob_file_gte10mb(state) {
    state.crewMedicalBlobFile_gte10mb = null;
  },

  set_crew_medical_blob_file(state, params) {
    state.crewMedicalBlobFile = params;
  },

  null_crew_medical_blob_file(state) {
    state.crewMedicalBlobFile = null;
  },

  //formCrewMedicalBlob
  set_form_crewmedical_blob(state, param) {
    state.formCrewMedicalBlob = param;
  },

  null_form_crewmedical_blob(state, param) {
    state.formCrewMedicalBlob = null;
  },

  //is10mbMedical
  set_is10mb_medical(state, param) {
    state.is10mbMedical = param;
  },

  //formCrewMedicalBlob_gte10mb
  set_form_crew_medical_blob_gte10mb(state, param) {
    state.formCrewMedicalBlob_gte10mb = param;
  },

};

/** actions **/
const actions = {
  //todo getCrewMedicalPaginated({commit},params){}

  getCrewMedicals({commit}, params) {
    commit('set_is_loading', true);
    return axios.get(PMC.CREW_MEDICAL, {params}).then((res) => {
      commit('SET_CREW_MEDICALS', res.data);
      commit('set_is_loading',false)
      return true;

    }).catch((er) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(er, 'Crew Medicals');
      return false;

    });
  },

  viewCrewMedical({commit}, params) {
    return axios.get(`${PMC.CREW_MEDICAL}/${params}`).then((res) => {
      let file = res.data.file;

      commit('SET_CREW_MEDICAL', res.data.crewMedical);

      if (file != null || file != undefined) {
        let responeBlob = UtilityService.base64ToBlobParser(res.data.file);

        if (UtilityService.isGte2mb(responeBlob)) {
          commit('set_is10mb_medical', true);
          commit('set_crew_medical_blob_file_gte10mb', file);

        } else {
          commit('set_crew_medical_blob_file', file);
          commit('set_is10mb_medical', false);
        }
      }

      return file;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'View Crew Medical');
      return false;

    });
  },

  createCrewMedical({commit}, params) {
    return axios({
      method: 'post',
      url: `${PMC.CREW_MEDICAL}`,
      data: params,
      'Content-Type': 'multipart/form-data',
      maxContentLength: 10000000,
      maxBodyLength: 10000000,
    }, {
      onUploadProgress: progressEvent => {
      },
    }).then((res) => {
      commit('CREATE_CREW_MEDICAL', res.data);
      return true;

    }).catch((err) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(err, 'Create Crew Medical');
      return false;

    });
  },

  updateCrewMedical({commit}, params) {
    return axios({
      method: 'post',
      url: `${PMC.UPDATE_CREW_MEDICAL}`,
      data: params,
      'Content-Type': 'multipart/form-data',
      maxContentLength: 10000000,
      maxBodyLength: 10000000,
    }).then((res) => {
      commit('UPDATE_CREW_MEDICAL', res.data);
      return true;

    }).catch((err) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(err, 'Update Crew Medical');
      return false;

    });
  },

  deleteCrewMedical({commit}, params) {
    return axios.delete(`${PMC.CREW_MEDICAL}/${params}`).then((res) => {
      commit('DELETE_CREW_MEDICAL', res.data);
      return true;

    }).catch((err) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(err, 'Delete Crew Medical');
      return false;

    });
  },

  uploadCrewMedicalFile({commit}, params) {
    //todo
  },

  nullNewCrewMedical({commit}) {
    commit('NULL_NEW_CREW_MEDICAL');
  },

  NULL_CREW_MEDICAL({commit}) {
    commit('NULL_CREW_MEDICAL');
  },

  NULL_CREW_MEDICALS({commit}) {
    commit('NULL_CREW_MEDICALS');
  },

  //form blob
  set_form_crewmedical_blob({commit}, param) {
    commit('set_form_crewmedical_blob', param);
  },

  null_form_crewmedical_blob({commit}) {
    commit('null_form_crewmedical_blob');
  },

  set_is10mb_medical({commit}, param) {
    commit('set_is10mb_medical', param);
  },

  //form blob 10 gte
  set_form_crew_medical_blob_gte10mb({commit}, param) {
    commit('set_form_crew_medical_blob_gte10mb', param);
  },

  set_crew_medical_blob_file({commit}, params) {
    commit('set_crew_medical_blob_file', params);
  },

  null_crew_medical_blob_file({commit}) {
    commit('null_crew_medical_blob_file');
  },

  set_crew_medical_blob_file_gte10mb({commit}, param) {
    commit('set_crew_medical_blob_file_gte10mb', param);
  },

  null_crew_medical_blob_file_gte10mb({commit}) {
    commit('null_crew_medical_blob_file_gte10mb');
  },

};

/** getters **/
const getters = {
  crewMedical: state => state.crewMedical,
  crewMedicals: state => UtilityService.capitalizeProperties(state.crewMedicals),
  newCrewMedical: state => state.newCrewMedical,
  crewMedicalBlobFile: state => state.crewMedicalBlobFile,
  crewMedicalBlobFile_gte10mb: state => state.crewMedicalBlobFile_gte10mb,
  is10mbMedical: state => state.is10mbMedical,
  formCrewMedicalBlob: state => state.formCrewMedicalBlob,
  formCrewMedicalBlob_gte10mb: state => state.formCrewMedicalBlob_gte10mb,
};

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
};
