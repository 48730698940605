export let global_variables_config = {

  liveServerPrefix: process.env.NODE_ENV === 'production' ? '/pmc' : '',

  ccmDocs: {
    poea: 1,
    itf: 2,
    infoSheet: 3,
    rps: 4,
    rpsAmmendment: 5,
    interOcean: 6,
    itf_og: 7,
  },

  ccmMaster: {
    checklist: 1,
    checklistDoc: 2,
    mlc_olc: 3,
    docCertMonitoring: 4,
  },

  ccmDocsName: {
    1: 'POEA',
    2: 'ITF',
    3: 'Info Sheet',
    4: 'RPS',
    5: 'RPS Amendment',
    6: 'InterOcean'
  },

  ccmMasterDocsName: {
    1: 'Checklist',
    2: 'Checklist Document',
    3: 'Checklist MLC/OLC',
    4: 'Document & Certificates Monitoring',
  },

  ccm_doc_signatories: [
    {
      id: 1,
      name: 'CHRISTIAN EARLE LUCAS B. JACINTO',
      position: 'VICE-PRESIDENT',
    },

    {
      id: 2,
      name: 'CAPT. ROMMEL R. MANALO',
      position: 'OPERATIONS MANAGER',
    },

    {
      id: 3,
      name: 'CAPT. REYNALDO S. AUSAN',
      position: 'ASSISTANT OPERATIONS MANAGER',
    },

    {
      id: 4,
      name: 'REYMAR PAUL B. ROXAS',
      position: 'DIRECTOR',
    },

    {
      id: 5,
      name: 'REYMAR PAUL B. ROXAS',
      position: 'PRESIDENT',
    },
  ],

  ccm_rps_signatories: [
    {
      id: 0,
      name: 'RONIEL B. NAVARRO',
      position: 'LIAISON STAFF',
    },
    {
      id: 1,
      name: 'RACHEL R. VILLARUEL',
      position: 'LIAISON STAFF',
    },
    {
      id: 2,
      name: 'MARIA LORETA O. DELOS REYES',
      position: 'LIAISON STAFF',
    }
  ],


  ccm_master_checklist_signatories: [
    {
      id: 1,
      name: 'Capt. Rommel R. Manalo',
      position: 'Operations Manager',
    },

    {
      id: 2,
      name: 'Capt. Reynaldo S. Ausan',
      position: 'Assistant Operations Manager',
    },

  ],


  // used in clinics setup
  statuses: [
    {
      id: 1,
      key: '',
      placeholder: 'All',
    },
    {
      id: 2,
      key: 1,
      placeholder: 'Active',
    },
    {
      id: 3,
      key: 0,
      placeholder: 'Inactive',
    },
  ],

  isEditable: false,

  biodataDocument: [
    {
      id: 1,
      name: '',
    },

  ],


  onboardCrewListReportType: {
    ownerFormat: 1,
    flagFormat: 2,
    jlauFormat: 3,
    jlauServicesFormat: 4,
    shoeiFormat: 5,
    masterlistDocsFormat: 6,
    masterlistCertsFormat: 7,
  },


  vesselFlag: {
    panama_flag: 4,
    singapore_flag: 5,
  },

  crewChangePlanFilter:{
    orderByRank: 1,
    orderByCcmNo: 2,
    orderByCrewSignOff: 3,
    orderByDepartureDate: 4,
    orderBySignOnDate: 5,
    orderByCrewSignOn: 6,
    orderByEoc: 7,
    orderByMonthsOnboard: 8,
  },

  allGearTypes: {
    travelling_bag: 1,
    coverall: 2,
    jacket: 3,
    shoes: 4,
    s_sleeve: 5,
    l_sleeve: 6,
    pants: 7,
    steward_uniform: 8,
    hat_hairnet: 9,
    towels: 10,
  },

  department: {
    operation: 1,
    admin: 2,
    finance: 3,
    accounting: 4,
    management: 5,
    training: 6,
    it: 7
  },

  flag: {
    liberia: 2,
    panama: 4,
    singapore: 5,
    marshallIslands: 22,
  }


  /**
   * @author GLOBAL VARIABLES USAGE.
   *
   *   import {global_variables_config} from "@/config/global-variable.config";
   *
   *     created () {
   *     this.$globalVariables = global_variables_config
   *   },
   *
   *     beforeDestroy() {
   *     this.$globalVariables = null;
   *   },
   *
   *   <template>
   *
   * {{ $globalVariables }}
   *
   * </template>
   *
   *
   */

//   import {global_variables_config} from "@/config/global-variable.config";
//
//   created () {
//     this.$globalVariables = global_variables_config
//   },
//
//   beforeDestroy() {
//     this.$globalVariables = null;
//   },
//
// <template>
//
// {{ $globalVariables }}
//
// </template>


}
