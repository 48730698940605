<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5 mt-2">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Ports</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newPortModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Port
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search port name"
                v-model="portFormParam.searchValue"
                @keypress.enter="searchPort"
              >

              <button class="btn btn-sm pmc-btn-prime" @click="searchPort">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm"
                        v-model="portFormParam.status"
                        @change="on_change_status">
                  <option :value="null">--select--</option>
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.placeholder }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="portFormParam.count"
                        @change="on_change_count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="portPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="7%">CODE</th>
                <th scope="row" class="align-middle" width="7%">NAME</th>
                <th scope="row" class="align-middle" width="7%">COUNTRY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>AT</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>AT</th>
                <th scope="row" class="align-middle" width="5%">STATUS</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-if = "ports.length === 0">
                <td colspan="10">No Results</td>
              </tr>
              <tr v-else v-for="(port,index) in ports" :key="port.id">
                <td>{{ index + portPagination.from }}</td>
                <td class="p-0 text-left pl-3">{{ port.code }}</td>
                <td class="p-0 text-left pl-4">{{ port.name }}</td>
                <td class="p-0 text-center">{{ port.country_name }}</td>
                <td class="p-0 text-center">{{ port.created_by }}</td>
                <td class="p-0 text-center">{{ port.updated_by }}</td>
                <td class="p-0 text-left pl-3">
                  {{ port.created_at }}
                </td>
                <td class="p-0 text-left pl-3">
                  {{ port.updated_at }}
                </td>
                <td class="p-0 text-center">
                  {{ port.status == 1 ? 'ACTIVE' : 'INACTIVE' }}
                </td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewPortModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="portViewtoPortData = port">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_port(port.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new port modal   -->
    <div class="modal fade" id="newPortModal" tabindex="-1" role="dialog" aria-labelledby="newPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newPrincipalModalLabel">NEW PORT</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newPort.code"
                    />
                  </div>
                </div>

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newPort.name"
                    />
                  </div>
                </div>

                <!-- country-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">COUNTRY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select
                      class="form-control form-control-sm"
                      v-model="newPort.country_id">
                      <option :value="null">--select--</option>
                      <option :value="country.id" v-for="(country,index) in objCountries" :key="country.id"
                              style="font-size: 14px;">
                        <span> {{ country.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newPort.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view port   -->
    <div class="modal fade" id="viewPortModal" tabindex="-1" role="dialog"
         aria-labelledby="viewPortModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewPortModalLabel">OWNER</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="portData.code"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="portData.name"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- country-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">COUNTRY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <select :disabled="!isEditable"
                            class="form-control form-control-sm"
                            v-model="portData.country_id">
                      <option :value="null">--select--</option>
                      <option :value="country.id" v-for="(country,index) in objCountries" :key="country.id"
                              style="font-size: 14px;">
                        <span> {{ country.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           v-model="portData.status"
                           class="form-check form-check-inline ml-2 mt-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CREATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="portData.created_by"
                    />
                  </div>
                </div>

                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">UPDATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="portData.updated_by"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit"
            >
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel"
            >
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";

export default {
  name: "List",


  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      portViewtoPortData: {},
      portData: {},
      newPort: {},
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,

    };
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_ports',
      'create_port',
      'update_port',
      'delete_port',
      'null_ports',
      'null_obj_countries'
    ]),

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New port', 'question')
      if (await __create) {

        let params = {
          code: this.newPort.code ? this.newPort.code : '',
          name: this.newPort.name, // this is required
          country_id: this.newPort.country_id ? this.newPort.country_id : '',
          status: this.newPort.status ? 1 : 0,
        };

        let newPort = await this.create_port(params);
        if (newPort) {
          AlertService.successAlertService('Create port record successful', 'Create port');
          this.hideModal('newPortModal')
          this.newPort = {};
          this.set_ports();
        }
      }
    },

    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update port', 'question');
      if (await __update) {
        let params = {
          id: this.portData.id,
          code: this.portData.code ? this.portData.code : '',
          name: this.portData.name,
          country_id: this.portData.country_id,
          status: this.portData.status ? 1 : 0,

        };

        let updatedPort = await this.update_port(params);
        if (updatedPort) {
          AlertService.successAlertService("Updated successfully", 'Update port');
          this.hideModal('viewPortModal')
          this.isEditable = false
        }
      }
    },

    set_this_page(e) {
      this.portFormParam.searchValue = ''
      this.portPagination.currentPage = e
      this.portFormParam.page = e
      this.pageLastLeft = e
      this.set_ports(this.portFormParam)

      // this.portFormParam.currentPage = e;
      // this.portFormParam.page = e;
      // this.set_ports(this.portFormParam);
    },

    searchPort() {
      if (this.portFormParam.searchValue === '') {
        this.portFormParam.page = this.pageLastLeft
        this.set_ports(this.portFormParam)
      }

      if (this.portFormParam.searchValue) {
        this.portFormParam.page = 1
      }

      this.set_ports(this.portFormParam)

      // this.portFormParam.page = 1;
      // this.set_ports(this.portFormParam);
    },

    on_change_status(e) {
      this.portFormParam.page = 1
      this.portFormParam.status = e.target.value;
      this.set_ports(this.portFormParam);
    },

    on_change_count(e) {
      this.portFormParam.page = 1
      this.portFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.portFormParam.count = e.target.value
      this.set_ports(this.portFormParam)

      // this.portFormParam.count = e.target.value;
      // this.set_ports(this.portFormParam);
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel(e) {
      this.isEditable = false
      this.portData = Object.assign({}, this.portViewtoPortData);
    },
  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'ports',
        'portPagination',
        'portFormParam',
        'objCountries',
      ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_ports(this.portFormParam);
  },



  /** watch **/
  watch: {
    'portViewtoPortData'() {
      this.portData = Object.assign({}, this.portViewtoPortData);
    },
  },

  /** destroyed **/
  destroyed() {
    this.null_ports();
    this.null_obj_countries();
  },
}
</script>

<style scoped>

</style>
