<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Onboard Senior Officers</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">Vessel</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.vesselId"
                                @change="selectReportType"
                                required>
                          <option :value="null">--SELECT VESSEL--</option>
                          <option :value="vessel.id" v-for="(vessel,index) in objVessels" :key="vessel.id">
                            {{ vessel.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="getOnboardSeniorOfficer">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          :disabled="reportParam.vesselId === null"
                          @click="printReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
            <div class="row ml-2">
              <div class="">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <span class="col-form-label">
                        <label for="list-count" class="col-form-label">FILTER BY : <b class="pl-2"
                                                                                      style="font-size: 1.1em;">{{
                            vesselSelected
                          }}</b></label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
    <template v-if="isLoading">
      <p>

          <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
          <span style="size: 20px">Loading ...</span>
      </p>

    </template>
    <template v-else>
      <template v-if="hasData">
          <div class="row px-4" >
        <template v-for="(crew, index) in crewData">
            <div class="py-2 px-2 w-25" :key="index" >
              <div class="border border-dark py-1 img-container" @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')">
                <img id="crew-img" :src="crew.crewImg || noPhoto" alt="photo" class="border border-dark">
                <p>{{ crew.rankName }}</p>
                <p>{{ crew.fullName }}</p>
                <p class="font-weight-light">Embarked Date: {{ crew.embarkedDate }}</p>
              </div>
            </div>
        </template>
          </div>
      </template>
      <template v-else>
                <span style="size: 20px;font-weight: bold;color: red;">No Data</span>
      </template>
    </template>


  </div>

</template>

<script>
import noPhoto from '@/assets/noPhoto.jpg';
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {CommonMixins} from "@/mixins/common.mixins";
import Swal from "sweetalert2";
import {FileService} from "@/service/file.service";
import {AlertService} from "@/service/alert.service";

export default {
  name: "OnboardSeniorOfficerList",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      reportParam: {
        rankId: 0,
        isPDF: false,
        vesselName: null,
        vesselId: null,
      },
      noPhoto: noPhoto,
      isLoading: false,
      hasData: false,
      crewData: null,
      vesselSelected: null,
    }
  },


  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
    ]),
    selectReportType() {
      this.hasData = false;
      this.vesselSelected = this.getVesselName(this.reportParam.vesselId);
    },

    getVesselName(vesselId) {
      let vesselName = '';
      const vesselList = this.objVessels
      for (let c in vesselList) {
        if (parseInt(vesselList[c].id) === parseInt(vesselId)) {
          vesselName = vesselList[c].name;
        }
      }
      return vesselName;
    },

    async getOnboardSeniorOfficer() {
      if (this.reportParam.vesselId === null) {
        this.errorAlert('Please Select Vessel', '')
      } else {
        this.reportParam.isPDF = false;
        this.hasData = false;
        let params = this.reportParam;
        this.isLoading = true;
        return await axios.post(PMC.GET_ONBOARD_SENIOR_OFFICER_LIST, params).then(async r => {
          console.log(r.data);
          this.isLoading = false;
          if (Object.keys(r.data).length > 0) {
            this.hasData = true;
            this.crewData = r.data;
          } else {
            this.hasData = false;
            this.crewData = null;
          }
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate summary of crew with highest GRT')
          return false
        });
      }
    },

    async printOnboardSeniorOfficer() {
      this.reportParam.vesselName = this.vesselSelected;
      this.reportParam.isPDF = true;
      let params = this.reportParam;
      return await axios.post(PMC.GET_ONBOARD_SENIOR_OFFICER_LIST, params).then(async r => {
        this.isLoading = false;
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Sign On/Off Summary')
        return false
      });
    },

    async printReport() {
      AlertService.loadingAlert('Please wait ...', 'Generating PDF')

      let pdfData = await this.printOnboardSeniorOfficer();
      if (pdfData) {
        await this.pdfViewer('File', pdfData);
        Swal.close();
      } else {
        Swal.close();
      }
    },

  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objVessels',
        'isReloading',
      ]),
    crewImage() {
      return this.crewData.crewImg ? this.crewData.crewImg : this.noPhoto;
    },

  },

  /** mounted **/
  mounted() {

  },

  /** created **/
  async created() {
    this.getCrewComplimentPerVessel();
    this.$globalVariables = global_variables_config
  },

  /** created **/

  beforeDestroy() {
  },
}
</script>

<style scoped>

#crew-img {
  height: 150px;
  width: 150px;
}

p {
  margin-bottom: 0;
}

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}

.img-container {
  cursor: pointer;
}

.img-container:hover{
  background-color: dodgerblue;
}
</style>
