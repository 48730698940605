<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary" style="font-size: 22px;">Documents and Certificate Monitoring</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">

              <div class="col-sm-3">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">MONTH</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                v-model="reportParam.month"
                                class="custom-select custom-select-sm"
                                :disabled="reportParam.report === 3 || reportParam.report === 4"
                                required>
                          <option :value="'01'">January</option>
                          <option :value="'02'">February</option>
                          <option :value="'03'">March</option>
                          <option :value="'04'">April</option>
                          <option :value="'05'">May</option>
                          <option :value="'06'">June</option>
                          <option :value="'07'">July</option>
                          <option :value="'08'">August</option>
                          <option :value="'09'">September</option>
                          <option :value="'10'">October</option>
                          <option :value="'11'">November</option>
                          <option :value="'12'">December</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">YEAR</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.year"
                                :disabled="reportParam.report === 3 || reportParam.report === 4"
                                required>
                          <option v-for="(years) in reportYears" :value="years">{{ years }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.rank"
                                @change="resetPage"
                                required>
                          <option :value="null">All</option>
                          <option v-for="(objRank, index) in objRanks" :value="objRank.id">{{ objRank.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">VESSELS</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.vessel"
                                :disabled="reportParam.report === 3 || reportParam.report === 4"
                                required>
                          <option :value="null">All</option>
                          <option v-for="(objVessel, index) in vesselList" :value="objVessel.id">{{ objVessel.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="row">
                  <div class="col-md-12 text-left">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">DOCUMENT</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.document"
                                @change="resetPage"
                                required>
                          <option :value="null">All</option>
                          <option v-for="(objAllDoc) in objAllDocs" :value="objAllDoc.id">{{ objAllDoc.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">REPORT TYPE</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.report"
                                @change="reportOnchange"
                        >
                          <option :value="1">Onboard Document Expiry</option>
                          <option :value="2">Standby Document Expiry</option>
                          <option :value="3">Without Docs or Certs</option>
                          <option :value="4">With Docs or Certs</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia mr-1"
                          @click="generateDocsCert"
                          :disabled="this.isLoading">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>

                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          @click="printPdfReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>

        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">

              <!--  pagination  & count  -->
              <div v-if="withDocsOrCert" class="d-flex justify-content-between">
                <div>
                  <pmc-pagination :page-object="reportParam" @settingPage="set_this_page"></pmc-pagination>
                </div>
                <div class="form-group d-flex">
                  <!-- Temporary comment has a problem encounter when count 100 -->
<!--                  <span v-if="reportParam.document == null && reportParam.count == 100"-->
<!--                        class="mt-1 mr-3 text-danger">-->
<!--                    Notice: This will take too long to load pdf.-->
<!--                  </span>-->
                  <label for="list-count" class="col-form-label">COUNT:</label>
                  <div class="text-left ml-3">
                    <select name="listCount" id="list-count" class="custom-select custom-select" v-model="reportParam.count" @change="set_list_count">
                      <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <template v-if="reportParam.report === 1">
                    <tr>
                      <th style="width: 2%;">#</th>
                      <th style="width: 15%;">CREW</th>
                      <th style="width: 5%;">RANK</th>
                      <th style="width: 10%;">VESSEL</th>
                      <th style="width: 7%;">EMBARKED (Y-M-D)</th>
                      <th style="width: 7%;">CONTRACT EXP (Y-M-D)</th>
                      <th style="width: 20%;">DOCUMENT</th>
                      <th style="width: 7%;">VALIDITY (Y-M-D)</th>
                      <th style="width: 7%;">APPOINTMENT DATE (Y-M-D)</th>
                      <th style="width: 7%;">RELEASE DATE (Y-M-D)</th>
                      <th style="width: 5%;">STATUS</th>
                    </tr>
                  </template>
                  <template v-else-if="reportParam.report === 2">
                    <tr>
                      <th style="width: 2%;">#</th>
                      <th style="width: 10%;">EX-VESSEL</th>
                      <th style="width: 15%;">CREW</th>
                      <th style="width: 5%;">RANK</th>
                      <th style="width: 7%;">SIGNON</th>
                      <th style="width: 7%;">SIGNOFF</th>
                      <th style="width: 20%;">DOCUMENT</th>
                      <th style="width: 7%;">VALIDITY (Y-M-D)</th>
                      <th style="width: 7%;">APPOINTMENT DATE (Y-M-D)</th>
                      <th style="width: 7%;">RELEASE DATE (Y-M-D)</th>
                      <th style="width: 5%;">STATUS</th>
                    </tr>
                  </template>
                  <template v-else-if="reportParam.report === 3">
                    <tr>
                      <th style="width: 4%;">#</th>
                      <th style="width: 24%;">NAME</th>
                      <th style="width: 24%;">RANK</th>
                      <th style="width: 24%;">STATUS</th>
                      <th style="width: 24%;">PREVIOUS & PRESENT VESSEL</th>
                    </tr>
                  </template>
                  <template v-else-if="reportParam.report === 4">
                    <tr>
                      <th style="width: 3%;">#</th>
                      <th>NAME</th>
                      <th style="width: 6%;">RANK</th>
                      <th style="width: 12%;">PREVIOUS & PRESENT VESSEL</th>
                      <th style="width: 10%;">SEA SERVICE STATUS</th>
                      <th style="width: 12%;">DOCUMENT NO</th>
                      <th style="width: 8%;">ISSUED DATE (Y-M-D)</th>
                      <th style="width: 8%;">EXPIRY DATE (Y-M-D)</th>
                      <th style="width: 8%;">APPOINTMENT DATE (Y-M-D)</th>
                      <th style="width: 8%;">RELEASE DATE (Y-M-D)</th>
                      <th style="width: 5%;">STATUS</th>
                    </tr>
                  </template>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="onboardDocExpiry">
                      <template v-for="(crew, index) in onboardDocExpiry">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="text-left pl-2">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.crewId, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.crewName }} </b></small>
                            </a>

                          </td>
                          <td> {{ crew.rankAlias }}</td>
                          <td> {{ crew.vessel }}</td>
                          <td> {{ crew.embarkDate }}</td>
                          <td> {{ crew.contractExp }}</td>
                          <td> {{ crew.document }}</td>
                          <td> {{ crew.dateExpiry }}</td>
                          <td><a href="#" data-toggle="modal" class="text-primary font-weight-bold"
                                 data-backdrop="static" data-keyboard="false" style="text-decoration: none;"
                                 data-target="#update-document-date" @click="documentUpdate(crew.id, 1, crew)">
                            {{ crew.appointmentDate ?? '---' }} </a>
                          </td>
                          <td><a href="#" data-toggle="modal" class="text-primary font-weight-bold"
                                 data-backdrop="static" data-keyboard="false" style="text-decoration: none;"
                                 data-target="#update-document-date" @click="documentUpdate(crew.id, 2, crew)">
                            {{ crew.releaseDate ?? '---' }} </a></td>
                          <td><a href="#" data-toggle="modal" class="text-primary font-weight-bold"
                                 data-backdrop="static" data-keyboard="false" style="text-decoration: none;"
                                 data-target="#update-document-date" @click="documentUpdate(crew.id, 3, crew)">
                            {{ crew.monitoringStatus ?? '---' }} </a></td>
                        </tr>
                      </template>
                    </template>
                    <template v-else-if="standbyDocExpiry">
                      <template v-for="(crew, index) in standbyDocExpiry">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="text-left pl-2"> {{ crew.vessel }}</td>
                          <td class="text-left pl-2">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.crewId, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.crewName }} </b></small>
                            </a>
                          </td>
                          <td> {{ crew.rankAlias }}</td>
                          <td> {{ crew.signOnDate }}</td>
                          <td> {{ crew.signOffDate }}</td>
                          <td> {{ crew.document }}</td>
                          <td> {{ crew.dateExpiry }}</td>
                          <td><a href="#" data-toggle="modal" class="text-primary font-weight-bold"
                                 data-backdrop="static" data-keyboard="false" style="text-decoration: none;"
                                 data-target="#update-document-date" @click="documentUpdate(crew.id, 1, crew)">
                            {{ crew.appointmentDate ?? '---' }} </a>
                          </td>
                          <td><a href="#" data-toggle="modal" class="text-primary font-weight-bold"
                                 data-backdrop="static" data-keyboard="false" style="text-decoration: none;"
                                 data-target="#update-document-date" @click="documentUpdate(crew.id, 2, crew)">
                            {{ crew.releaseDate ?? '---' }} </a></td>
                          <td><a href="#" data-toggle="modal" class="text-primary font-weight-bold"
                                 data-backdrop="static" data-keyboard="false" style="text-decoration: none;"
                                 data-target="#update-document-date" @click="documentUpdate(crew.id, 3, crew)">
                            {{ crew.monitoringStatus ?? '---' }} </a></td>
                        </tr>
                      </template>
                    </template>
                    <template v-else-if="withoutDocsOrCert">
                      <template v-for="(crew, index) in withoutDocsOrCert">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="text-left pl-2">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ getFullName(crew) }} </b></small>
                            </a>
                          </td>
                          <td> {{ crew.rank_alias }}</td>
                          <td class="text-capitalize"> {{ crew.crew_sea_service_status }}</td>
                          <td class="text-capitalize"> {{ crew.last_boarded_vessel }}</td>
                        </tr>
                      </template>
                    </template>

                    <template v-else-if="withDocsOrCert">
                        <tr v-for="(crew, index) in withDocsOrCert" :key="index">
                          <td> {{ index + reportParam.from }}</td>
                          <td class="text-left pl-2">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ getFullName(crew) }} </b></small>
                            </a>
                          </td>
                          <td> {{ crew.rank_alias }}</td>
                          <td class="text-capitalize"> {{ crew.last_boarded_vessel }}</td>
                          <td class="text-capitalize"> {{ crew.crew_sea_service_status }}</td>
                          <td class="remove-spacing">
                            <div
                              v-for="(document, document_index) in crew.crewDocuments" :key="document_index"
                              :class="{
                                'remove-border-top': document_index === 0,
                                'remove-border-bottom': crew.crewDocuments.length - 1 || crew.crewDocuments.length === 1,
                              }"
                              class="document-border"
                            >
                              <span class="document-font-size">
                                {{ document.document_no === null || document.document_no === '' ? '--' : document.document_no }}
                              </span>
                            </div>
                          </td>
                          <td class="remove-spacing">
                            <div
                              v-for="(document, document_index) in crew.crewDocuments" :key="document_index"
                              :class="{
                                'remove-border-top': document_index === 0,
                                'remove-border-bottom': crew.crewDocuments.length - 1 || crew.crewDocuments.length === 1,
                              }"
                              class="document-border"
                            >
                              <span class="document-font-size">
                                {{ document.date_issued === null || document.date_issued === '0000-00-00' ? '--' : document.date_issued }}
                              </span>
                            </div>
                          </td>
                          <td class="remove-spacing">
                            <div
                              v-for="(document, document_index) in crew.crewDocuments" :key="document_index"
                              :class="{
                                'remove-border-top': document_index === 0,
                                'remove-border-bottom': crew.crewDocuments.length - 1 || crew.crewDocuments.length === 1,
                              }"
                              class="document-border"
                            >
                              <span class="document-font-size">
                                {{ document.date_expiry === null || document.date_expiry === '0000-00-00' ? '--' : document.date_expiry }}
                              </span>
                            </div>
                          </td>
                          <td class="remove-spacing">
                            <div
                              v-for="(document, document_index) in crew.crewDocuments" :key="document_index"
                              :class="{
                                'remove-border-top': document_index === 0,
                                'remove-border-bottom': crew.crewDocuments.length - 1 || crew.crewDocuments.length === 1,
                              }"
                              class="document-border"
                            >
                              <a href="#" data-toggle="modal" class="document-font-size text-primary font-weight-bold"
                                 data-backdrop="static" data-keyboard="false" style="text-decoration: none;"
                                 data-target="#update-document-date" @click="documentUpdate(document.id, 1, document)"
                              >
                                {{ document.appointmentDate === null || document.appointmentDate === '0000-00-00' ? '--' : document.appointmentDate }}
                              </a>
                            </div>
                          </td>
                          <td class="remove-spacing">
                            <div
                              v-for="(document, document_index) in crew.crewDocuments" :key="document_index"
                              :class="{
                                'remove-border-top': document_index === 0,
                                'remove-border-bottom': crew.crewDocuments.length - 1 || crew.crewDocuments.length === 1,
                              }"
                              class="document-border"
                            >
                              <a href="#" data-toggle="modal" class="document-font-size text-primary font-weight-bold"
                                 data-backdrop="static" data-keyboard="false" style="text-decoration: none;"
                                 data-target="#update-document-date" @click="documentUpdate(document.id, 2, document)"
                              >
                                {{ document.releaseDate === null || document.releaseDate === '0000-00-00' ? '--' : document.releaseDate }}
                              </a>
                            </div>
                          </td>
                          <td class="remove-spacing">
                            <div
                              v-for="(document, document_index) in crew.crewDocuments" :key="document_index"
                              :class="{
                                'remove-border-top': document_index === 0,
                                'remove-border-bottom': crew.crewDocuments.length - 1 || crew.crewDocuments.length === 1,
                              }"
                              class="document-border"
                            >
                              <a href="#" data-toggle="modal" class="document-font-size text-primary font-weight-bold"
                                 data-backdrop="static" data-keyboard="false" style="text-decoration: none;"
                                 data-target="#update-document-date" @click="documentUpdate(document.id, 3, document)"
                              >
                                {{ document.monitoringStatus ?? '--' }}
                              </a>
                            </div>
                          </td>
                        </tr>
                    </template>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="modal fade" id="update-document-date" tabindex="-1" role="dialog" aria-labelledby="setupCcPlanCenter"
         aria-hidden="true">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content" style="height: 100%">
          <div class="modal-header">
            <label class="modal-title" id="exampleModalLongTitle">{{ documentModalTitle }}</label>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center align-items-center">
            <div class="row m-2">
              <div class="col-12">
                <input v-if="documentModalTitle !== 'Document Status'" type="date"
                       class="form-control form-control-sm text-center align-items-center" style="font-size: 1em;"
                       v-model="documentDate"/>
                <select v-else class="form-control form-control-sm" v-model="documentStatus">
                  <option :value="null">--select status--</option>
                  <option :value="'ND'">No Document / Record</option>
                  <option :value="'D'">Scheduled to disembark</option>
                  <option :value="'NR'">Not Required</option>
                  <option :value="'FC1'">Received first deficiency letter</option>
                  <option :value="'FC2'">Received second deficiency letter</option>
                  <option :value="'R'">Released</option>
                  <option :value="'AR'">Awaiting for release</option>
                  <option :value="'C'">Deficiency Complied</option>
                  <option :value="'SIRB'">Seaman's Identification record Book</option>
                  <option :value="'PPORT'">Passport</option>
                  <option :value="'AMCV'">Australian Maritime Crew Visa</option>
                  <option :value="'TCC'">Temporary License ( 3months valid)</option>
                  <option :value="'CRA'">Certificate of Application</option>
                  <option :value="'FA'">For application / renewal / revalidation</option>
                  <option :value="'FD'">For application upon Disembarkation</option>
                </select>
                <button type="button" class="btn btn-sm pmc-btn-tertia mt-2" @click="documentUpdateConfirm">Save
                  Changes
                </button>
                <div class="mt-3" style="height: 30px;">
                  <h5 class="text-success">{{ documentUpdateMsg }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";

export default {
  name: "DocsCertMonitoring",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      isEditable: false,
      vesselList: null,
      reportParam: {
        year: 2024,
        month: '01',
        rank: null,
        vessel: null,
        document: null,
        report: 1,
        print: 0,
        count: 10,
        page: 1,
        from: 1,
        currentPage: 1,
        lastPage: 0,
        perPage: 10,
        to: 9,
        total: 0,
      },
      counts: [5, 10, 20, 30, 40, 50],
      isLoading: false,
      onboardDocExpiry: null,
      standbyDocExpiry: null,
      withoutDocsOrCert : null,
      withDocsOrCert: null,
      documentTypes: {},
      documentModalTitle: '',
      documentStatus: null,
      documentDate: null,
      documentUpdateType: null,
      documentId: null,
      documentUpdateMsg: '',
      reportYears: {},
      pageLastLeft: 1,
      showGenerateButton: false,
      notice: false,
    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'getOwnerKeys',
      'getCrewComplimentPerVessel',
    ]),
    yearList() {
      let year = 2027;
      let yearList = [];
      while (year >= 2012) {
        yearList.push(year);
        year = year - 1;
      }
      this.reportYears = yearList;
    },

    reportOnchange() {
      this.onboardDocExpiry = null;
      this.standbyDocExpiry = null;
      this.withoutDocsOrCert = null;
      this.withDocsOrCert = null;
    },

    set_this_page(e) {
      this.reportParam.currentPage = e
      this.reportParam.page = e
      this.pageLastLeft = e
      this.generateDocsCertMonitoring(this.reportParam);
    },

    documentUpdate(docId, type, documentModalData) {
      this.documentUpdateType = type;
      this.documentId = docId;
      this.documentUpdateMsg = '';

      if (type === 1) {
        this.documentModalTitle = "Appointment Date";
        this.documentDate = documentModalData?.appointmentDate
      } else if (type === 2) {
        this.documentModalTitle = "Release Date";
        this.documentDate = documentModalData?.releaseDate
      } else if (type === 3) {
        this.documentModalTitle = "Document Status";
        this.documentStatus = documentModalData?.monitoringStatus
      }

      this.documentDate = value;
      this.documentStatus = value;
      if(type === 1) this.documentModalTitle = "Appointment Date";
      else if(type === 2) this.documentModalTitle = "Release Date";
      else if(type === 3) this.documentModalTitle = "Document Status";
    },

    documentUpdateConfirm() {
      const updateType = {1: "appointment", 2: "release", 3: "status"};
      let param = {
        'id': this.documentId,
        'type': updateType[this.documentUpdateType],
        'value': this.documentUpdateType === 3 ? this.documentStatus : this.documentDate
      }
      this.documentUpdateRequest(param);
    },

    async printPdfReport() {
      AlertService.loadingAlert('Please wait ...', 'Generating PDF for Document Monitoring Report');
      const progressModal = $('#generatingAnimationModal')
      progressModal.modal('show');
      this.reportParam.print = 1;
      let pdfData = await this.printDocsCertMonitoring(this.reportParam);
      if (pdfData) {
        await this.pdfViewer('Document Monitoring Report', pdfData);
      }
      progressModal.modal('hide');
    },

    async generateDocsCertMonitoring(params) {
      return await axios.post(PMC.DOCS_CERT_MONITORING, params).then(r => {
        this.isLoading = false;
        if (params.report === 1) this.onboardDocExpiry = r.data.crewDocuments;
        else if (params.report === 2) this.standbyDocExpiry = r.data.crewDocuments;
        else if (params.report === 3) this.withoutDocsOrCert = r.data.crews;
        else if (params.report === 4) {
          this.withDocsOrCert = r.data.data;
          this.reportParam.from = r.data.from;
          this.reportParam.currentPage = r.data.current_page;
          this.reportParam.lastPage = r.data.last_page;
          this.reportParam.perPage = r.data.per_page;
          this.reportParam.to = r.data.to;
          this.reportParam.total = r.data.total;
        }
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate Docs and Cert Monitoring')
        return false
      });
    },

    async printDocsCertMonitoring(params) {
      return await axios.post(PMC.DOCS_CERT_MONITORING_PRINT, params).then(r => {
        this.isLoading = false;
        Swal.close();
        return r.data.pdf ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        Swal.close();
        UtilityService.generateResponseMessage(error, 'Generate Docs and Cert Monitoring')
        return false
      });
    },

    async documentUpdateRequest(params) {
      return await axios.post(PMC.DOCS_CERT_MONITORING_UPDATE, params).then(r => {
        this.documentUpdateMsg = "Updated Successfully!";
        this.updateDocumentList(params);
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate Docs and Cert Monitoring')
        return false
      });
    },

    generateDocsCert() {
      this.isLoading = true;
      this.reportParam.print = 0;
      if (this.reportParam.report === 4) this.showGenerateButton = true;
      this.generateDocsCertMonitoring(this.reportParam);
    },
    updateDocumentList(param) {
      let docList = {};
      if (this.reportParam.report === 1) docList = this.onboardDocExpiry;
      else if (this.reportParam.report === 2) docList = this.standbyDocExpiry;
      else if (this.reportParam.report === 4) docList = this.withDocsOrCert;

      if (this.reportParam.report === 4) {
        for (let x in docList) {
          for (let i in docList[x].crewDocuments) {
            if (docList[x].crewDocuments[i].id === param.id) {
              if (param.type === 'appointment') docList[x].crewDocuments[i].appointmentDate = param.value;
              else if (param.type === 'release') docList[x].crewDocuments[i].releaseDate = param.value;
              else if (param.type === 'status') docList[x].crewDocuments[i].monitoringStatus = param.value;
            }
          }
        }
      } else {
        for (let x in docList) {
          if (docList[x].id === param.id) {
            if (param.type === 'appointment') docList[x].appointmentDate = param.value;
            else if (param.type === 'release') docList[x].releaseDate = param.value;
            else if (param.type === 'status') docList[x].monitoringStatus = param.value;
          }
        }
      }

      if (this.reportParam.report === 1) this.onboardDocExpiry = docList;
      else if (this.reportParam.report === 2) this.standbyDocExpiry = docList;
      else if (this.reportParam.report === 4) this.withDocsOrCert = docList;
    },
    getFullName(crew) {

      let firstName = crew.first_name ?? null;
      let middleInitial = crew.middle_initial ?? null;
      let lastName = crew.last_name ?? null;
      let suffixName = crew.suffix_name ?? null;

      if (suffixName !== null) {
        return suffixName + ' ' +  lastName + ', ' + firstName + ' ' + middleInitial;
      }
      return lastName + ', ' + firstName + ' ' + middleInitial;
    },
    resetPage(e) {
      this.reportParam.page = 1;
    },
    set_list_count (e) {
      this.reportParam.page = 1
      this.pageLastLeft = 1
      this.reportParam.count = e.target.value
      this.generateDocsCertMonitoring(this.reportParam)
    },
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objOwners',
        'objVessels',
        'objRanks',
        'objAllDocs',
      ]),
  },

  /** mounted **/
  mounted() {
    this.yearList();
  },

  /** created **/
  async created() {
    this.$MyGlobalFunction = global_variables_config
    await this.getOwnerKeys();
    await this.getCrewComplimentPerVessel();
    this.vesselList = this.objVessels;
  },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}
.document-border {
  align-content: center;
  line-height: 1;
  width: 100%;
  height: 40px;
  border: 1px solid #dee2e6;
  border-left: 0;
  border-right: 0;
}
.document-font-size {
  text-transform: uppercase;
  font-size: 12px;
  padding: 0px 1px 0px 1px;
}
.remove-spacing {
  margin: 0;
  padding: 0;
}
.remove-border-top {
  border-top: 0;
}
.remove-border-bottom {
  border-bottom: 0;
}
</style>
