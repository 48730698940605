import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";
import axios from "axios";

const initStateVesselRemarks = () => {
  return {
    vesselRemarks: [],
    vesselRemark: {},
    newVesselRemark: {
      remarks: null,
      vesselId: null,
    },
    isLoadingVesselRemark: false,
    remarksCount : null
  }
};

const state = {
  ...initStateVesselRemarks()
};


const mutations = {
  set_vessel_remark(state, payload) {
    state.vesselRemark = payload;
  },

  set_vessel_remarks(state, payload) {
    state.vesselRemarks = payload;
  },

  set_null_vessel_remarks(state){
    state.vesselRemarks = [];
  },

  create_vessel_remark(state, payload) {
    state.newVesselRemark = {
      remarks: null,
      vesselId: null
    };
  },

  update_vessel_remark(state, payload) {
    let vesselRemark = state.vesselRemarks.find((val, index) => {
      return val.id === payload.id
    })
    Object.assign(vesselRemark, payload)
  },

  delete_vessel_remark(state, payload) {
    let index = state.vesselRemarks.findIndex((val, index) => {
      return val.id === payload.id
    })
    state.vesselRemarks.splice(index, 1)
  },

  reset_state_vessel_remarks(state) {
    Object.assign(state, initStateVesselRemarks())
  },

  set_remarks_count(state,payload){
    state.remarksCount = payload;
  },

  add_vessel_remarks_count(state){
    if(state.remarksCount != 0 || state.remarksCount != undefined){
      state.remarksCount += 1;
    }
  },

  sub_vessel_remarks_count(state){
    if(state.remarksCount != 0 || state.remarksCount != undefined){
      state.remarksCount -= 1;
    }
  }
};

const actions = {
  async set_vessel_remarks({commit}, params) {
    return await axios.get(`${PMC.GET_VESSEL_REMARK}/${params.vesselId}`)
      .then((r) => {
        commit('set_vessel_remarks', r.data)
        return true;

      }).catch((er) => {
        if (!er.response) {
          AlertService.errorAlertService("Cant establish server connection");

        } else {
          UtilityService.generateResponseMessage(er, 'Get Vessel Remarks')

        }
        return false;
      })
  },

  set_vessel_remark({commit}) {
    commit('set_vessel_remark')
  },

  set_null_vessel_remarks({commit}) {
    commit('set_null_vessel_remarks');
  },

  async create_vessel_remark({commit}, params) {
    return await axios.post(PMC.ADD_VESSEL_REMARK, params).then((r) => {
      commit('create_vessel_remark', params)
      commit('add_vessel_remarks_count');
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Adding Crew Change Plan')
      }
      return false;
    })
  },

  update_vessel_remark({commit}, params) {
    return axios.patch(`${PMC.UPDATE_VESSEL_REMARK}/${params.id}`, params).then((r) => {
      commit('update_vessel_remark', r.data)
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Adding Crew Change Plan')
      }
      return false;
    })
  },

  delete_vessel_remark({commit}, params) {
    return axios.delete(`${PMC.DELETE_VESSEL_REMARK}/${params}`).then((r)=>{
      commit('delete_vessel_remark', r.data)
      commit('sub_vessel_remarks_count');
      return true;
    }).catch((er)=>{
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, "Delete vessel")
      }
      return false;
    })
  },

  reset_state_vessel_remarks({commit}) {
    commit('reset_state_vessel_remarks')
  }
};

const getters = {
  vesselRemark: state => state.vesselRemark,
  vesselRemarks: state => state.vesselRemarks,
  newVesselRemark: state => state.newVesselRemark,
  isLoadingVesselRemark: state => state.isLoadingVesselRemark,
  remarksCount: state => state.remarksCount,
};

export default {
  namespace: false,
  state,
  actions,
  mutations,
  getters
}
