<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Principal</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newPrincipalModal"
                          data-backdrop="static"
                          data-keyboard="false"
                          @click="new_principal_modal_active"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Principal
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search principal name"
                v-model="principalFormParam.searchValue"
                @keypress.enter="searchPrincipal">

              <button class="btn btn-sm pmc-btn-prime" @click="searchPrincipal">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- status -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm"
                        v-model="principalFormParam.status"
                        @change="on_change_status">
                  <option :value="null">--select--</option>
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.placeholder }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="principalFormParam.count"
                        @change="on_change_count"
                        >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>


<!--        <div class="row">-->
<!--          &lt;!&ndash; searchbar &ndash;&gt;-->
<!--          <div class="col-md-3">-->
<!--            <div class="input-group mb-2 mr-sm-2">-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control form-control-sm"-->
<!--                id="formdata-searchvalue"-->
<!--                placeholder="Search Principal ...">-->

<!--              <button class="btn btn-sm pmc-btn-prime" >-->
<!--                <font-awesome-icon icon="search"/>-->
<!--                search-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="principalPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="4%">CODE</th>
                <th scope="row" class="align-middle" width="7%">NAME</th>
                <th scope="row" class="align-middle" width="7%">ADDRESS</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>AT</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>AT</th>
                <th scope="row" class="align-middle" width="5%">STATUS</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-if = "principals.length === 0">
                <td colspan="10">No Results</td>
              </tr>
              <tr v-else v-for="(principal, index) in principals" :key="principal.id">
                <td>{{ index + principalPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ principal.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ principal.name }}</td>
                <td scope="col" class="p-0 text-center">{{ principal.address }}</td>
                <td scope="col" class="p-0 text-center">{{ principal.created_by }}</td>
                <td scope="col" class="p-0 text-center">{{ principal.updated_by }}</td>
                <td scope="col" class="p-0 text-left pl-3">
                 {{ principal.created_at }}
                </td>
                <td scope="col" class="p-0 text-left pl-3">
                 {{ principal.updated_at }}
                </td>
                <td scope="col" class="p-0 text-center">{{ principal.status == 1 ? 'ACTIVE' : 'INACTIVE' }}</td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewPrincipalModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="principalViewtoPrincipalData = principal">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_principal(principal.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new principal modal   -->
    <div class="modal fade" id="newPrincipalModal" tabindex="-1" role="dialog" aria-labelledby="newPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newPrincipalModalLabel">NEW PRINCIPAL</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newPrincipal.code"
                    />
                  </div>
                </div>

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newPrincipal.name"
                    />
                  </div>
                </div>

                <!-- new  address-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ADDRESS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newPrincipal.address"
                    />
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newPrincipal.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view principal   -->
    <div class="modal fade" id="viewPrincipalModal" tabindex="-1" role="dialog" aria-labelledby="viewPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewPrincipalModalLabel">PRINCIPAL</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="principalData.code"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="principalData.name"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- alias -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ADDRESS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="principalData.address"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           v-model="principalData.status"
                           class="form-check form-check-inline ml-2 mt-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CREATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="principalData.created_by"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">UPDATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="principalData.updated_by"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit">
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel(principalData.id)">
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      principalViewtoPrincipalData: {},
      principalData: {},
      newPrincipal: {
        status: 1,
      },
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,

      pageLastLeft: 1,

    };
  },

  /** methods**/
methods: {
  ...mapActions([
    'set_principals',
    'create_principal',
    'update_principal',
    'delete_principal',
    'null_principals',
    'null_principal',
  ]),

    async save () {
      let __create =  AlertService.questionAlertService('Are you sure you want to add this record', 'New Principal', 'question')
      if (await __create){

        let params = {
          code: this.newPrincipal.code ? this.newPrincipal.code : '' ,
          name: this.newPrincipal.name, // this is required
          address: this.newPrincipal.address ? this.newPrincipal.address: '', // this is required
          status: this.newPrincipal.status ? 1 : 0,
        };

        let newPrincipal = await this.create_principal(params);
        if (newPrincipal){
          AlertService.successAlertService('Create principal record successful', 'Create principal');
          this.hideModal('newPrincipalModal')
          this.newPrincipal = {};
          this.set_principals();
        }

      }

    },


    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update principal', 'question');
      if (await __update) {
        let params = {
          id: this.principalData.id,
          code: this.principalData.code ? this.principalData.code : '' ,
          name: this.principalData.name, // this is required
          address: this.principalData.address, // this is required
          status: this.principalData.status ? 1 : 0,

        };

        let updatedPrincipal = await this.update_principal(params);
        if (updatedPrincipal) {
          AlertService.successAlertService("Updated successfully",'Update principal');
          this.hideModal('viewPrincipalModal')
          this.isEditable = false
        }
      }
    },

    set_this_page(e) {
      this.principalFormParam.searchValue = ''
      this.principalPagination.currentPage = e
      this.principalFormParam.page = e
      this.pageLastLeft = e
      this.set_principals(this.principalFormParam)

      // this.principalFormParam.currentPage = e;
      // this.principalFormParam.page = e;
      // this.set_principals(this.principalFormParam);
    },

    searchPrincipal() {

      if (this.principalFormParam.searchValue === '') {
        this.principalFormParam.page = this.pageLastLeft
        this.set_principals(this.principalFormParam)
      }

      if (this.principalFormParam.searchValue) {
        this.principalFormParam.page = 1
      }

      this.set_principals(this.principalFormParam)

      // this.principalFormParam.page = 1;
      // this.set_principals(this.principalFormParam);
    },

    on_change_status(e) {
      this.principalFormParam.page = 1
      this.principalFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.principalFormParam.status = e.target.value;
      this.set_principals(this.principalFormParam);
    },

    on_change_count(e) {
      this.principalFormParam.page = 1
      this.principalFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.principalFormParam.count = e.target.value
      this.set_principals(this.principalFormParam)

      // this.principalFormParam.count = e.target.value;
      // this.set_principals(this.principalFormParam);
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel(e) {
      this.isEditable = false
      this.principalData = Object.assign({}, this.principalViewtoPrincipalData);
    },

    new_principal_modal_active() {
      this.newPrincipal.status = 1;
    },

  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'principals',
        'principalPagination',
        'principalFormParam',
      ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_principals(this.principalFormParam);
  },

  /** watch **/
  watch: {
    'principalViewtoPrincipalData'() {
      this.principalData = Object.assign({}, this.principalViewtoPrincipalData);
    },
  },

  /** destroyed **/
  destroyed() {
    this.null_principals()
  },

}
</script>

<style scoped>

</style>
