<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">List of Gears</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#new-gear-modal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Gear
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search Gear name"
                v-model="gearFormParam.searchValue"
                @keypress.enter="searchGear"
              >

              <button class="btn btn-sm pmc-btn-prime" @click="searchGear">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

<!--          status-->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm"
                        v-model="gearFormParam.status"
                        @change="on_change_status">
                  <option :value="null">--select--</option>
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.placeholder }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="gearFormParam.count"
                        @change="on_change_count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="gearPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="7%">Description</th>
                <th scope="row" class="align-middle" width="7%">Rank Type</th>
                <th scope="row" class="align-middle" width="7%">Rank</th>
                <th scope="row" class="align-middle" width="7%">Color</th>
                <th scope="row" class="align-middle" width="7%">Brand</th>
                <th scope="row" class="align-middle" width="7%">Gear Type</th>
                <th scope="row" class="align-middle" width="7%">Gear Supplier</th>
                <th scope="row" class="align-middle" width="7%">Status</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-for="(gear, index) in gears"
                  :key="gear.id"

                  data-backdrop="static"
                  data-keyboard="false"
                  @click="viewGear(gear)"
              >
<!--                 remove view function when clicking the table-->
<!--                @click="viewGear(gear)"-->
<!--                data-toggle="modal"-->
<!--                data-target="#view-gear-modal"-->

                <td>{{ index + gearPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ gear.description }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ gear.rankType }}</td>
                <td scope="col" class="p-0 text-center">{{ gear.rank }}</td>
                <td scope="col" class="p-0 text-center">{{ gear.color }}</td>
                <td scope="col" class="p-0 text-center">{{ gear.brand }}</td>
                <td scope="col" class="p-0 text-center">{{ gear.gear_type }}
                  <span class="badge badge-pill badge-dark" v-if="gear.towel_usage_id == 1">Bath</span>
                  <span class="badge badge-pill badge-info" v-if="gear.towel_usage_id == 2">Face</span>
                </td>
                <td scope="col" class="p-0 text-center">

                  <span class="badge badge-pill badge-primary"
                        v-if="gear.gearSupplier"
                        @click.stop="goToSupplier(gear.gear_supplier_id)">
                    {{ gear.gearSupplier }}
                  </span>

<!--                  <span v-if="gear.gearSupplier">{{ gear.gearSupplier }}</span>-->
                  <span class="text-danger" v-if="!gear.gearSupplier" @click="goToSupplier"> NO SUPPLIER</span>

                </td>
                <td scope="col" class="p-0 text-center">{{ gear.status == 1 ? 'ACTIVE' : 'INACTIVE' }}</td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ gear.created_by }}
                </td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ gear.updated_by }}
                </td>
                <td>
                  <button
                    class="btn btn-xs btn-primary ml-2"
                    data-toggle="modal"
                    data-target="#view-gear-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="viewGear(gear)"
                  >
                    <font-awesome-icon icon="cog"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click.stop="delete_gear(gear.id)"
                  >
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new gear modal   -->
    <div class="modal fade" id="new-gear-modal" tabindex="-1" role="dialog" aria-labelledby="newPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newPrincipalModalLabel">NEW GEAR</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new gear -->
                <div class="form-group row">
                  <label for="middle-name"
                         :class="{'text-danger':!newGear.description,'':newGear.description}"
                         class="col-sm-3 col-form-label">
                    *NAME
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           :class="{'border-danger':!newGear.description,'':newGear.description}"
                           v-model="newGear.description"
                    />
                  </div>
                </div>

                <!-- new gear type -->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{'text-danger':!newGear.gear_type_id,'':newGear.gear_type_id}"
                         class="col-sm-3 col-form-label">
                    *GEAR TYPE
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="newGear.gear_type_id"
                            :class="{'border-danger':!newGear.gear_type_id,'':newGear.gear_type_id}"
                            required>
                      <option :value="null">--select--</option>
                      <option :value="type.id" v-for="(type,index) in objGearTypes" :key="type.id"
                              style="font-size: 14px;">
                        <span> {{ type.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>


                <!-- new towel type -->
                <div class="form-group row mt-2" v-if="isTowelRequired">
                  <label for="middle-name"
                         :class="{ 'text-danger': isTowelRequired && !newGear.towel_usage_id, '': isTowelRequired && newGear.towel_usage_id }"
                         class="col-sm-3 col-form-label">
                    *TOWEL USAGE
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="newGear.towel_usage_id"
                            :class="{ 'border-danger': isTowelRequired && !newGear.towel_usage_id, '': isTowelRequired && newGear.towel_usage_id }"
                            required>
                      <option :value="null">--select--</option>
                      <option :value="usage.id" v-for="(usage,index) in objTowelUsages" :key="usage.id"
                              style="font-size: 14px;">
                        <span> {{ usage.description }}</span>
                      </option>
                    </select>
                  </div>
                </div>


                <!-- new rank type -->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{'text-danger':!newGear.rank_type,'':newGear.rank_type}"
                         class="col-sm-3 col-form-label">
                    *RANK TYPE
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            @change="on_change_get_rank_type"
                            v-model="newGear.rank_type"
                            :class="{'border-danger':!newGear.rank_type,'':newGear.rank_type}"
                            required>
                      <option :value="null">--select--</option>
                      <option :value="type.id" v-for="(type,index) in objRankTypes" :key="type.id"
                              style="font-size: 14px;">
                        <span> {{ type.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- new rank-->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{ 'text-danger': isRankRequired && !newGear.rank_id, '': isRankRequired && newGear.rank_id }"
                         class="col-sm-3 col-form-label">
                    *RANK
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="newGear.rank_id"
                            :class="{ 'border-danger': isRankRequired && !newGear.rank_id, '': isRankRequired && newGear.rank_id }"
                            required>
                      <option :value="null">--select--</option>
                      <option :value="rank.id" v-for="(rank,index) in objRanks" :key="rank.id"
                              style="font-size: 14px;">
                        <span> {{ rank.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- new color-->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{ 'text-danger': isColorRequired && !newGear.color, '': isColorRequired && newGear.color }"
                         class="col-sm-3 col-form-label">
                    *COLOR
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newGear.color"
                      :class="{ 'border-danger': isColorRequired && !newGear.color, '': isColorRequired && newGear.color }"

                    />
                  </div>
                </div>

                <!-- new brand-->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{ 'text-danger': isBrandRequired && !newGear.brand, '': isBrandRequired && newGear.brand }"
                         class="col-sm-3 col-form-label">
                    *BRAND
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      :class="{ 'border-danger': isBrandRequired && !newGear.brand, '': isBrandRequired && newGear.brand }"
                      placeholder="---"
                      v-model="newGear.brand"
                    />
                  </div>
                </div>

                <!-- new supplier -->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{'text-danger':!newGear.gear_supplier_id,'':newGear.gear_supplier_id}"
                         class="col-sm-3 col-form-label">
                    *SUPPLIER
                  </label>
<!--                  <label class="col-sm-3 col-form-label">SUPPLIER</label>-->
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">

                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            :class="{'border-danger':!newGear.gear_supplier_id,'':newGear.gear_supplier_id}"
                            v-model="newGear.gear_supplier_id"
                            required>
                      <option :value="null">--select--</option>
                      <option :value="supplier.id" v-for="(supplier,index) in objGearSuppliers" :key="supplier.id"
                              style="font-size: 14px;">
                        <span> {{ supplier.name }}</span>
                      </option>
                      <option value="">--NOT IN LIST--</option>
                    </select>


                    <input
                      v-if="newGear.gear_supplier_id === ''"
                      v-model="newGear.new_gear_supplier"
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Type Supplier Name"
                    />

                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newGear.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view gear  -->
    <div class="modal fade" id="view-gear-modal" tabindex="-1" role="dialog"
         aria-labelledby="viewPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="view-gear-modal-label">GEAR</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- view gear -->
                <div class="form-group row">
                  <label for="middle-name"
                         :class="{'text-danger':!gearData.description,'':gearData.description}"
                         class="col-sm-3 col-form-label">
                    *DESCRIPTION
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="gearData.description"
                           :class="{'border-danger':!gearData.description,'':gearData.description}"
                           :disabled="!isEditable"
                    />
                  </div>
                </div>

                <!-- view gear type -->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{'text-danger':!gearData.gear_type_id,'':gearData.gear_type_id}"
                         class="col-sm-3 col-form-label">
                    *GEAR TYPE
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="gearData.gear_type_id"
                            :disabled="!isEditable"
                            :class="{'border-danger':!gearData.gear_type_id,'':gearData.gear_type_id}"
                            required>
                      <option :value="null">--select--</option>
                      <option :value="type.id" v-for="(type,index) in objGearTypes" :key="type.id"
                              style="font-size: 14px;">
                        <span> {{ type.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- new towel type -->
                <div class="form-group row mt-2" v-if="gearData.gear_type_id == allGearTypes.towels">
                  <label for="middle-name"
                         :class="{ 'text-danger': isTowelRequired && !gearData.towel_usage_id, '': isTowelRequired && gearData.towel_usage_id }"
                         class="col-sm-3 col-form-label">
                    *TOWEL USAGE
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="gearData.towel_usage_id"
                            :class="{ 'border-danger': isTowelRequired && !gearData.towel_usage_id, '': isTowelRequired && gearData.towel_usage_id }"

                            :disabled="!isEditable"
                            required>
                      <option :value="null">--select--</option>
                      <option :value="usage.id" v-for="(usage,index) in objTowelUsages" :key="usage.id"
                              style="font-size: 14px;">
                        <span> {{ usage.description }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- view rank type -->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{'text-danger':!gearData.rank_type,'':gearData.rank_type}"
                         class="col-sm-3 col-form-label">
                    *RANK TYPE
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="gearData.rank_type"
                            :class="{'border-danger':!gearData.rank_type,'':gearData.rank_type}"
                            @change="on_change_get_rank_type"
                            :disabled="!isEditable"
                            required>
                      <option :value="null">--select--</option>
                      <option :value="type.id" v-for="(type,index) in objRankTypes" :key="type.id"
                              style="font-size: 14px;">
                        <span> {{ type.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- view rank-->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{ 'text-danger': isRankRequired && !gearData.rank_id, '': isRankRequired && gearData.rank_id }"
                         class="col-sm-3 col-form-label">
                    *RANK
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="gearData.rank_id"
                            :class="{ 'border-danger': isRankRequired && !gearData.rank_id, '': isRankRequired && gearData.rank_id }"

                            :disabled="!isEditable"
                            required>
                      <option :value="null">--select--</option>
                      <option :value="rank.id" v-for="(rank,index) in objRanks" :key="rank.id"
                              style="font-size: 14px;">
                        <span> {{ rank.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- view color-->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{ 'text-danger': isColorRequired && !gearData.color, '': isColorRequired && gearData.color }"
                         class="col-sm-3 col-form-label">
                    *COLOR
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="gearData.color"
                      :class="{ 'border-danger': isColorRequired && !gearData.color, '': isColorRequired && gearData.color }"
                      :disabled="!isEditable"
                    />
                  </div>
                </div>

                <!-- view brand-->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{ 'text-danger': isBrandRequired && !gearData.brand, '': isBrandRequired && gearData.brand }"
                         class="col-sm-3 col-form-label">
                    *BRAND
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="gearData.brand"
                      :class="{ 'border-danger': isBrandRequired && !gearData.brand, '': isBrandRequired && gearData.brand }"
                      :disabled="!isEditable"
                    />
                  </div>
                </div>

                <!-- view supplier -->
                <div class="form-group row mt-2">
                  <label for="middle-name"
                         :class="{'text-danger':!gearData.gear_supplier_id,'':gearData.gear_supplier_id}"
                         class="col-sm-3 col-form-label">
                    *SUPPLIER
                  </label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="gearData.gear_supplier_id"
                            :class="{'border-danger':!gearData.gear_supplier_id,'':gearData.gear_supplier_id}"

                            required
                            :disabled="!isEditable"
                    >
                      <option :value="null">--select--</option>
                      <option :value="supplier.id" v-for="(supplier,index) in objGearSuppliers" :key="supplier.id"
                              style="font-size: 14px;">
                        <span> {{ supplier.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="gearData.status"
                      :disabled="!isEditable"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit"
            >
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel"
            >
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import {global_variables_config} from "@/config/global-variable.config";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      gearViewtoGearData: {},
      gearData: {},
      newGear: {
        color: null,
        status: 1,
      },
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,
      isSupplierInList: false,

      rankTypeParam: {
        rankType: null,
      },

      isTowelRequired: false,
      isColorRequired: false,
      isBrandRequired: false,
      isRankRequired: false,

      allGearTypes: {
        travelling_bag: global_variables_config.allGearTypes.travelling_bag,
        coverall: global_variables_config.allGearTypes.coverall,
        jacket: global_variables_config.allGearTypes.jacket,
        shoes: global_variables_config.allGearTypes.shoes,
        s_sleeve: global_variables_config.allGearTypes.s_sleeve,
        l_sleeve: global_variables_config.allGearTypes.l_sleeve,
        pants: global_variables_config.allGearTypes.pants,
        steward_uniform: global_variables_config.allGearTypes.steward_uniform,
        hat_hairnet: global_variables_config.allGearTypes.hat_hairnet,
        towels: global_variables_config.allGearTypes.towels,
      },

    };
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_gears',
      'set_gear',
      'create_gear',
      'update_gear',
      'delete_gear',
      'getGearSupplierKeys',

      'getGearKeysInRateSupplier',
      'getGearTypeKeys',
      'getRankTypeKeys',
      'getRankKeys',

      'getTowelUsageKeys',

    ]),

    async save () {
      let __create =  AlertService.questionAlertService('Are you sure you want to add this record', 'New Gear', 'question')
      if (await __create){

        let params = {
          description: this.newGear.description ? this.newGear.description : '' ,
          rank_type: this.newGear.rank_type ? this.newGear.rank_type : '' ,
          rank_id: this.newGear.rank_id ? this.newGear.rank_id : '' ,
          color: this.newGear.color ? this.newGear.color : '' ,
          brand: this.newGear.brand ? this.newGear.brand : '',
          gear_type_id: this.newGear.gear_type_id ? this.newGear.gear_type_id : '',
          gear_supplier_id: this.newGear.gear_supplier_id ? this.newGear.gear_supplier_id : '' ,
          towel_usage_id: this.newGear.towel_usage_id ? this.newGear.towel_usage_id : '' ,
          new_gear_supplier: this.newGear.new_gear_supplier ? this.newGear.new_gear_supplier : '' ,
          status: this.newGear.status ? 1 : 0,
        };

        let newGear = await this.create_gear(params);
        if (newGear){
          AlertService.successAlertService('Create gear record successful', 'Create gear');
          this.hideModal('new-gear-modal')
          await this.getGearSupplierKeys();
          this.newGear = {};
          this.set_gears();
        }

      }

    },


    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update gear', 'question');
      if (await __update) {
        let params = {
          id: this.gearData.id,
          description: this.gearData.description ? this.gearData.description : '' ,
          rank_type: this.gearData.rank_type ? this.gearData.rank_type : '',
          rank_id: this.gearData.rank_id ? this.gearData.rank_id : '',
          color: this.gearData.color ? this.gearData.color : '',
          brand: this.gearData.brand ? this.gearData.brand : '',
          gear_type_id: this.gearData.gear_type_id ? this.gearData.gear_type_id : '',
          gear_supplier_id: this.gearData.gear_supplier_id ? this.gearData.gear_supplier_id : '',
          towel_usage_id: this.gearData.towel_usage_id ? this.gearData.towel_usage_id : '' ,
          status: this.gearData.status ? 1 : 0,
        };

        let updatedGear = await this.update_gear(params);
        if (updatedGear) {
          AlertService.successAlertService("Updated successfully",'Update gear');
          this.hideModal('view-gear-modal')
          this.isEditable = false
        }
      }
    },

    set_this_page(e) {
      this.gearFormParam.currentPage = e;
      this.gearFormParam.page = e;
      this.set_gears(this.gearFormParam);
    },

    searchGear() {
      if (this.gearFormParam.searchValue === '') {
        this.gearFormParam.page = this.pageLastLeft
        this.set_gears(this.gearFormParam)
      }

      if (this.gearFormParam.searchValue) {
        this.gearFormParam.page = 1
      }

      this.set_gears(this.gearFormParam)
    },

    on_change_status(e) {
      this.gearFormParam.page = 1
      this.gearFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.gearFormParam.status = e.target.value;
      this.set_gears(this.gearFormParam);
    },

    on_change_count(e) {
      this.gearFormParam.page = 1
      this.gearFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.gearFormParam.count = e.target.value
      this.set_gears(this.gearFormParam)
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel(gearData) {
      this.isEditable = false
      this.rankTypeParam.rankType = gearData.rank_type;

      this.gearData = Object.assign({}, this.gear);
      this.getRankKeys(this.rankTypeParam);
    },

    on_change_get_rank_type(e){
      this.rankTypeParam.rankType = e.target.value;
      this.getRankKeys(this.rankTypeParam);

    },

    viewGear(gearData){
      this.rankTypeParam.rankType = gearData.rank_type;

      this.getRankKeys(this.rankTypeParam);
      this.set_gear(gearData.id)
    },


    goToSupplier(supplierId) {
      this.$router.push({
        name: 'GearSupplierRateList',
        params: {gearSupplierId: supplierId},
      });
    },


  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'gears',
        'gear',
        'gearPagination',
        'gearFormParam',
        'objRankTypes',
        'objRanks',
        'objGearSuppliers',
        'obj_gearsKeysSelection',
        'objGearTypes',
        'objTowelUsages',
      ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_gears(this.gearFormParam);
    await this.getRankTypeKeys();
    await this.getGearSupplierKeys();
    await this.getGearTypeKeys();
    await this.getTowelUsageKeys();

    this.$globalVariables = global_variables_config

  },

  /** watch **/
  watch: {
    'gear'() {
      this.gearData = Object.assign({}, this.gear);
    },

    'newGear.gear_supplier_id'(){
      if(this.newGear.gear_supplier_id != ''){
        this.newGear.new_gear_supplier = ''
      }

    },


    'newGear.gear_type_id'(){

      let requiredColor =
        [
          this.allGearTypes.coverall,
          this.allGearTypes.jacket,
          this.allGearTypes.s_sleeve,
          this.allGearTypes.l_sleeve,
          this.allGearTypes.pants,
        ];

      let requiredTowelUsage =
        [
          this.allGearTypes.towels,
        ];

      let requireBrand =
        [
          this.allGearTypes.shoes,
        ];

      let requireRank =
        [
          this.allGearTypes.steward_uniform,
          this.allGearTypes.hat_hairnet,
        ];


      if (requiredColor.includes(this.newGear.gear_type_id)) {
        this.isColorRequired = true
      }else{
        this.isColorRequired = false
        this.newGear.color = null
      }


      if (requiredTowelUsage.includes(this.newGear.gear_type_id)) {
        this.isTowelRequired = true
      }else{
        this.isTowelRequired = false
        this.newGear.towel_usage_id = null
      }

      if (requireBrand.includes(this.newGear.gear_type_id)) {
        this.isBrandRequired = true
      }else{
        this.isBrandRequired = false
        this.newGear.color = null
      }

      if (requireRank.includes(this.newGear.gear_type_id)) {
        this.isRankRequired = true
      }else{
        this.isRankRequired = false
        this.newGear.color = null
      }


    },


    'gearData.gear_type_id'(){

      let requiredColor =
        [
          this.allGearTypes.coverall,
          this.allGearTypes.jacket,
          this.allGearTypes.s_sleeve,
          this.allGearTypes.l_sleeve,
          this.allGearTypes.pants,
        ];

      let requiredTowelUsage =
        [
          this.allGearTypes.towels,
        ];

      let requireBrand =
        [
          this.allGearTypes.shoes,
        ];

      let requireRank =
        [
          this.allGearTypes.steward_uniform,
          this.allGearTypes.hat_hairnet,
        ];


      if (requiredColor.includes(this.gearData.gear_type_id)) {
        this.isColorRequired = true
      }else{
        this.isColorRequired = false
        // this.gearData.color = null
      }


      if (requiredTowelUsage.includes(this.gearData.gear_type_id)) {
        this.isTowelRequired = true
      }else{
        this.isTowelRequired = false
        this.gearData.towel_usage_id = null
      }

      if (requireBrand.includes(this.gearData.gear_type_id)) {
        this.isBrandRequired = true
      }else{
        this.isBrandRequired = false
        // this.gearData.color = null
      }

      if (requireRank.includes(this.gearData.gear_type_id)) {
        this.isRankRequired = true
      }else{
        this.isRankRequired = false
        // this.gearData.color = null
      }


    },


  },

  beforeDestroy() {
    this.$globalVariables = null;
  },

  /** destroyed **/
  destroyed() {
  },


}
</script>

<style scoped>

</style>
