<template>
  <div class="row justify-content-center">
    <div class="col col-md">
      <div class="table table-responsive pmc-table border-top">

        <!--                  Certificates-->
        <table class="table table-bordered table-sm text-center small">
          <thead>
          <tr>
            <th style="width: 1%;">
              #
            </th>
            <th class="">NAME</th>
            <th class="">RANK</th>
            <th class="">AGE</th>
            <th class=""> MLC FUNCTION 1 ISSUED DATE</th>
            <th class=""> MLC FUNCTION 2 ISSUED DATE</th>
            <th class=""> MLC FUNCTION 3 ISSUED DATE</th>
            <th class=""> MLC FUNCTION 4 ISSUED DATE</th>
            <th class=""> OLC FUNCTION 1 ISSUED DATE</th>
            <th class=""> OLC FUNCTION 2 ISSUED DATE</th>
            <th class=""> OLC FUNCTION 3 ISSUED DATE</th>
            <th class=""> OLC FUNCTION 4 ISSUED DATE</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(checklist, index) in ccmMasterChecklist" :key="checklist.id">
            <td class="text-center align-middle">
              {{ index + 1 }}
            </td>
            <td class="text-center align-middle">
              {{ checklist.crewName }}
            </td>

            <td class="text-center align-middle">
              {{ checklist.rankAlias }}
            </td>

            <td class="text-center align-middle">
              {{ checklist.age }}
            </td>


            <!--                      COP BST-->
            <td class="text-center align-middle"
                :class="{
                              'no-documents-or-expired' : checklist.mlc_func1.isExpired,
                           }"
            >
              {{ checklist.mlc_func1.dateIssued }}
            </td>


            <!--                      COP PSCRB-->
            <td class="text-center align-middle"
                :class="{
                              'no-documents-or-expired' : checklist.mlc_func2.isExpired,
                           }"
            >
              {{ checklist.mlc_func2.dateIssued }}
            </td>


            <!--                      COP_AFF-->
            <td class="text-center align-middle"
                :class="{
                              'no-documents-or-expired' : checklist.mlc_func3.isExpired,
                           }"
            >
              {{ checklist.mlc_func3.dateIssued }}
            </td>


            <!--                      COP_MECA-->
            <td class="text-center align-middle"
                :class="{
                              'no-documents-or-expired' : checklist.mlc_func4.isExpired,
                           }"
            >
              {{ checklist.mlc_func4.dateIssued }}
            </td>


            <!--                      COP_MEFA-->
            <td class="text-center align-middle"
                :class="{
                              'no-documents-or-expired' : checklist.olc_func1.isExpired,
                           }"
            >
              {{ checklist.olc_func1.dateIssued }}
            </td>


            <!--                      COP_SSO-->
            <td class="text-center align-middle"
                :class="{
                              'no-documents-or-expired' : checklist.olc_func2.isExpired,
                           }"
            >
              {{ checklist.olc_func2.dateIssued }}
            </td>


            <!--                      COP_SDSD-->
            <td class="text-center align-middle"
                :class="{
                              'no-documents-or-expired' : checklist.olc_func3.isExpired,
                           }"
            >
              {{ checklist.olc_func3.dateIssued }}
            </td>


            <!--                      DE_WATCH-->
            <td class="text-center align-middle"
                :class="{
                              'no-documents-or-expired' : checklist.olc_func4.isExpired,
                           }"
            >
              {{ checklist.olc_func4.dateIssued }}
            </td>


          </tr>

          </tbody>

        </table>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CcmMasterChecklistMLCOLC",

  props: {

    ccmMasterChecklist: {
      type: Array,
      default: [],
    },


  },

}
</script>

<style scoped>

</style>
