import { render, staticRenderFns } from "./GearSupplierRateList.vue?vue&type=template&id=113b5638&scoped=true&"
import script from "./GearSupplierRateList.vue?vue&type=script&lang=js&"
export * from "./GearSupplierRateList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "113b5638",
  null
  
)

export default component.exports