<template>
  <div>
    <div class="container-fluid">
      <div class="row py-3">
        <div class="col-md-6 text-left">
          <div class="row">
            <div class="col-3">
              <h4 class="font-weight-bold">Crew List</h4>
            </div>
            <div class="col-3 text-left">
              <pmc-btn
                @buttonEvent="add_crew_route"
                :button-label="`Add`"
                :button-class="`btn btn-sm pmc-btn-tertia`">
                <template slot="button-icon">
                  <font-awesome-icon icon="user-plus"/>
                </template>
              </pmc-btn>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="input-group mb-2 mr-sm-2">
            <div class="input-group-prepend">
              <select class="form-control small" v-model="formParams.searchKey">
                <option :value="searcKey.key" v-for="(searcKey,index) in searchKeys">{{ searcKey.display }}</option>
              </select>
            </div>

            <input
              v-model="formParams.searchValue"
              type="text"
              class="form-control"
              id="formdata-searchvalue"
              @keypress.enter="search_crew"
              placeholder="Search Crew here">

            <pmc-btn
              @buttonEvent="search_crew"
              :button-label="'search'"
              :button-class="'btn btn-sm pmc-btn-prime'">
              <template slot="button-icon">
                <font-awesome-icon icon="search"/>
              </template>
            </pmc-btn>

          </div>
        </div>

        <div class="col-2">
          <div class="form-group row">
            <label for="list-count" class="col-sm-4 col-form-label">Count</label>
            <span class="col-form-label">:</span>
            <div class="col-sm-4 text-left">
              <select name="listCount" id="list-count" class="form-control form-control-sm" v-model="formParams.count" @change="set_list_count">
                <option :value="count" v-for="(count ,index ) in dateListCount">{{ count }}</option>
              </select>
            </div>
          </div>
        </div>

      </div>

      <div class="row py-1">
        <div class="col-md-8">
          <div class="row">
            <div class="col-6">
              <pmc-pagination :page-object="crewListPagination" @settingPage="set_this_page"></pmc-pagination>
            </div>
          </div>
        </div>
      </div>

      <div id="table-section" class="py-0">
        <pmc-table :max-height="550" :loading="isReloading" :loaderColspan="13">
          <template v-slot:table-header>
            <tr>
              <th scope="col" style="width :2% !important;">#</th>
              <th class="text-left"><span class="ml-3">LAST NAME</span></th>
              <th class="text-left"><span class="ml-3">FIRST NAME</span></th>
              <th class="text-left"><span class="ml-3">MIDDLE NAME</span></th>
              <th class="text-left"><span class="ml-3">SUFF.</span></th>
              <th class="text-left"><span class="ml-3">RANK</span></th>
              <th class="text-left"><span class="ml-3">CREW TYPE</span></th>
              <th class="text-left"><span class="ml-3">SCHOLAR TYPE</span></th>
              <th class="text-left"><span class="ml-3">SEA SERVICE STATUS</span></th>
              <th class="text-left"><span class="ml-3">PREVIOUS VESSEL</span></th>
              <th class="text-left"><span class="ml-3">CURRENT VESSEL</span></th>
              <th class="text-left"><span class="ml-3">AVAILABILITY</span></th>
              <th class="text-center"><span class="ml-3">ACTION</span></th>
            </tr>
          </template>
          <template slot="table-body">
            <tr v-for="(crew,index) in setUpCrewList" :key="crew.id">
              <td scope="row">
                {{ index + crewListPagination.from }}
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.lastName ? crew.lastName : '--' }}</span>
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.firstName ? crew.firstName : '--' }}</span>
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.middleName ? crew.middleName : '--' }}</span>
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.suffixName ? crew.suffixName : '--' }}</span>
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.rank ? crew.rank : '--' }}</span>
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.crewType ? crew.crewType : '--' }}</span>
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.scholarType }}</span>
                <p class="ml-3" v-if="crew.scholarProp">( {{ crew.scholarProp }} )</p>
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.seaServiceStatus ? crew.seaServiceStatus.name : '--' }}</span>
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.previousVessel ? crew.previousVessel : '--' }}</span>
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.currentVessel ? crew.currentVessel : '--' }}</span>
              </td>

              <td scope="row" class="text-left">
                <span class="ml-3">{{ crew.availability ? crew.availability : '--' }}</span>
              </td>

              <td>
                <pmc-btn
                  @buttonEvent="crew_profile(crew.id)"
                  :button-class="'btn btn-xs pmc-btn-prime'"
                  :button-label="'view'">
                  <template slot="button-icon">
                    <font-awesome-icon icon="bars"/>
                  </template>
                </pmc-btn>

                <pmc-btn
                  @buttonEvent="delete_crew(crew.id)"
                  :button-class="'btn btn-xs pmc-btn-danger ml-2'"
                  :button-label="'delete'">
                  <template slot="button-icon">
                    <font-awesome-icon icon="trash"/>
                  </template>
                </pmc-btn>
              </td>

            </tr>
          </template>
        </pmc-table>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Button from '../../../base/Button';
import TopNav from '../../../app/layout/TopNav';
import {AlertMixins} from '../../../../mixins/alert.mixins';

export default {
  name: 'List',
  mixins: [AlertMixins],
  components: {
    TopNav,
    Button,
  },
  /** data **/
  data() {
    return {
      dateListCount: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      formParams: {
        searchValue: null,
        searchKey: 'all',
        sFirstName: null,
        sMiddleName: null,
        sLastName: null,
        sRank: null,
        count: 10,
        isTrue: false,
        page: null,
      },

      searchKeys: [
        {
          id: 1,
          key: 'all',
          display: 'All',
        },
        {
          id: 2,
          key: 'first_name',
          display: 'First Name',
        },
        {
          id: 3,
          key: 'middle_name',
          display: 'Middle Name',
        },
        {
          id: 4,
          key: 'last_name',
          display: 'Last Name',
        },
        {
          id: 5,
          key: 'rank',
          display: 'Rank',
        },
      ],
      pageLastLeft: 1
    };
  },

  /** methods**/
  methods: {
    ...mapActions(
      [
        'getCrewListPaginated',
        'delete_crew',
        'resetCrewListPagination',
        'nullSetupCrewList',
      ],
    ),

    get_all_crew_paginated() {
      this.getCrewListPaginated(this.formParams);
    },

    set_this_page(e) {
      this.crewListPagination.currentPage = e;
      this.formParams.page = e;
      this.get_all_crew_paginated();
    },

    set_list_count(e) {
      this.formParams.page = 1;
      this.formParams.searchValue = '';
      this.pageLastLeft = 1;
      this.formParams.count = e.target.value;
      this.get_all_crew_paginated();
    },

    search_crew() {
      this.formParams.page = 1;
      this.get_all_crew_paginated();
    },

    add_crew_route() {
      this.$router.push({name: 'CreateCrew'});
    },

    crew_profile(crewId) {
      this.$router.push({
        name: 'CrewProfile',
        params: {crewId: crewId},
      });
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'setUpCrewList',
        'crewListPagination',
        'isReloading'
      ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.get_all_crew_paginated();
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.resetCrewListPagination();
    this.nullSetupCrewList();
  },

};
</script>

<style scoped>

</style>
