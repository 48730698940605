import axios from 'axios'
import {UtilityService} from '../../../service/utility.service'

import {PMC} from '../../../config/constants.config'
import {AlertService} from '../../../service/alert.service'

/** state **/
const state = {
  document: {},
  documents: [],

  documentRanksMatrix: [],

  documentRankMatrix: {},

  newDocument: {
    code: '',
    name: '',
    nac_doc: '',
    hasExpiry: 0, //default false
    validity_key: null,
    validity_value: null,
    isMonitored: 0,
    isNeedRank: 0,
    doc_type: null,
    doc_category: null,
    status: 0,
  },

  documentFormParam: {
    searchValue: null,
    type: null,   //default document
    category: null, //default foreign
    count: 10,
    status: 1, //default true
    page: null,
  },

  documentPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },

  documentRanks: [],

}

/** mutations **/
const mutations = {

  set_documents(state, params) {
    state.documents = params
  },

  set_document_rank_matrix(state, params) {
    state.documentRanksMatrix = params
  },

  set_document(state, params) {
    state.document = params
  },

  create_document(state) {
    state.newDocument = {
      code: '',
      name: '',
      nac_doc: '',
      hasExpiry: 0, //default false
      validity_key: null,
      validity_value: null,
      isMonitored: 0,
      isNeedRank: 0,
      doc_type: null,
      doc_category: null,
      status: 0,
    }
  },

  update_document(state, params) {
    let document = state.documents.find((val, index) => val.id === params.id)
    Object.assign(document, params)
  },

  delete_document(state, params) {
    let index = state.documents.findIndex((val, index) => val.id === params.id)
    state.documents.splice(index, 1)
  },

  set_document_pagination(state, params) {
    state.documentPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },

  reset_document_formparam(state) {
    state.documentFormParam = {
      searchValue: null,
      type: 1,   //default document
      category: 1, //default foreign
      count: 10,
      status: 1, //default true
      page: null,
    }
  },

  reset_document_pagination(state) {
    state.documentPagination = {
      from: 1,
      currentPage: 1,
      lastPage: 0,
      perPage: 10,
      to: 10,
      total: 0,
    }
  },

  null_document(state) {
    state.document = {}
  },

  null_documents(state) {
    state.documents = []
  },

  null_new_document(state) {
    state.newDocument = {
      code: '',
      name: '',
      nac_doc: '',
      hasExpiry: 0, //default false
      validity_key: null,
      validity_value: null,
      isMonitored: 0,
      isNeedRank: 0,
      doc_type: null,
      doc_category: null,
      status: 0,
    }
  },

  set_document_ranks(state, params) {
    state.documentRanks = params
  },

  null_document_ranks(state) {
    state.documentRanks = []
  },

  update_documentRankMatrix(state) {
    state.documentRankMatrix = {};
  },

}

/** actions **/
const actions = {
  async set_documents({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.DOCUMENT, {params}).then((r) => {
      commit('set_documents', r.data.data)
      commit('set_document_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get documents')
      return false
    })
  },

  async set_document({commit}, params) {
    return await axios.get(`${PMC.DOCUMENT}/${params}`).then((r) => {
      commit('set_document', r.data)
      return true

    }).catch((er) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(er, 'Get document detail')
      return false
    })
  },

  async create_document({commit}, params) {

    let confirmation = AlertService.questionAlertService('Do you want to create new document instance.', 'New document', 'question')

    if (await confirmation) {
      return axios.post(`${PMC.DOCUMENT}`, UtilityService.deCapitalizeProperties(params)).then((r) => {
        commit('create_document', r.data)
        AlertService.successAlertService('New document successfully created', 'New Document')
        return true
      }).catch((er) => {
        UtilityService.failProgressBar()
        UtilityService.generateResponseMessage(er, 'Create document')
        return false
      })
    }
  },

  async update_document({commit}, params) {
    return axios.put(`${PMC.DOCUMENT}/${params.id}`, params).then((r) => {
      AlertService.successAlertService('Updating document detail successfully', 'Update document')
      commit('update_document', r.data)
      return true
    }).catch((error) => {
      //maintain this comment unless debugging
      //removing this comment will result to a "default" alert message
      UtilityService.generateResponseMessage(error, 'Update document')
      return false

    })
  },


  async delete_document({commit}, params) {
    let __del = AlertService.questionAlertService('Are you sure you want to delete this record.', 'Delete document', 'question')
    if (await __del) {
      return axios.delete(`${PMC.DOCUMENT}/${params}`).then((r) => {
        AlertService.successAlertService("Record delete successfully",'Delete document');
        commit('delete_document', r.data)
        return true

      }).catch((er) => {

        UtilityService.failProgressBar()
        UtilityService.generateResponseMessage(er, 'Delete document')
        return false

      })
    }
  },

  reset_document_formparam({commit}) {
    commit('reset_document_formparam')
  },

  reset_document_pagination({commit}) {
    commit('reset_document_pagination')
  },

  null_document({commit}) {
    commit('null_document')
  },

  null_documents({commit}) {
    commit('null_documents')
  },

  null_new_document({commit}) {
    commit('null_new_document')
  },

  get_document_prerequisite({commit}, param) {
    return axios.get(`${PMC.GET_DOCUMENT_PREREQUISITE}/${param}`).then((r) => {
      commit('set_document', r.data.document)
      commit('set_document_ranks', r.data.document_ranks)
      commit('set_obj_ranks', r.data.rank_keys)
      return true

    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Get document pre-requisite')
      return false

    })
  },

  null_document_ranks({commit}) {
    commit('null_document_ranks')
  },

  //todo
  async update_document_prerequisite({commit}, param) {
    let confirm = AlertService.questionAlertService('Do you want to save the changes you made.', 'Update document ranks')
    if (await confirm) {
      return axios.post(`${PMC.UPDATE_DOCUMENT_PREREQUISITE_RANK}`, param).then((r) => {
        commit('set_document_ranks', r.data)
        AlertService.successAlertService('Document pre-requisite successfully updated', 'Update Pre-requisite')
        return true

      }).catch((er) => {
        UtilityService.generateResponseMessage(er, 'Updating document pre-requisite [rank]')
        return false

      })
    }
  },

  async update_document_rank_matrix({commit}, params) {
    return axios.post(PMC.UPDATE_DOCUMENT_RANK_MATRIX, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('update_documentRankMatrix', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'update document rank matrix');
      return false;
    })
  },

  async set_document_rank_matrix({commit}, params) {
    // commit('set_is_loading', true)
    return await axios.get(PMC.GET_DOCUMENT_RANKS_MATRIX, {params}).then((r) => {
      commit('set_document_rank_matrix', r.data)
      // commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get document rank matrix')
      return false
    })
  },



}

/** getters **/
const getters = {
  document: state => UtilityService.capitalizeProperties(state.document),
  documents: state => UtilityService.capitalizeProperties(state.documents),
  newDocument: state => UtilityService.capitalizeProperties(state.newDocument),

  documentFormParam: state => state.documentFormParam,
  documentPagination: state => state.documentPagination,
  documentRanks: state => state.documentRanks,

  documentRanksMatrix: state => state.documentRanksMatrix,
}

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
}
