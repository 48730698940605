<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Manpower Pool List - Standby Not OverAge</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.rankId"
                                @change="selectReportType"
                                required>
                          <option :value="0">-- select rank --</option>
                          <option v-for="(rank, index) in ranks" :value="rank.id">{{ rank.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">REPORT TYPE</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.filterType"
                                @change="selectReportType"
                                required
                                disabled
                        >
                          <option value="standby">Standby</option>
                          <option value="onboard">Onboard</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="getManpowerPoolList">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          @click="printReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table v-if="reportParam.filterType === 'standby'"
                       class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;">Seafarer Name</th>
                    <th>RANK</th>
                    <th>TOB</th>
                    <th>AGE</th>
                    <th>PROMOTABLE</th>
                    <th>LICENSE</th>
                    <th>EX-VESSEL</th>
                    <th>SIGNOFF</th>
                    <th># MOS STANDBY</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="text-left pl-2">

                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>

                          </td>
                          <td> {{ crew.rank_alias }}</td>
                          <td>
                            {{ crew.cchCount }}
                          </td>
                          <td> {{ crew.age }}</td>
                          <td> N/A </td>
                          <td>{{ crew.LicenseDoc }}</td>
                          <td>{{ crew.exVessel }}</td>
                          <td> {{ crew.previousVesselSignOffDate }} </td>
                          <td> {{ crew.monthsStandBy}} </td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";
import {global_variables_config} from "@/config/global-variable.config";

export default {
  name: "StandbyNotOverAge",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      isEditable: false,
      isGenerateOnboardButton: false,
      reportParam: {
        rankId: 0,
        filterType: 'onboard',
        isPDF: false,
        rankName: null,
        isOverAge: 'all',
      },
      isLoading: false,
      hasData: false,
      ranks: null,
      crewData: null,
      rankSelected: null,
    }
  },


  /** methods**/
  methods: {
    ...mapActions([
      'getOwnerKeys',
      'getCrewComplimentPerVessel',
    ]),
    yearList() {
      let year = 2023;
      let yearList = [];
      while (year >= 2019) {
        yearList.push(year);
        year = year - 1;
      }
      this.reportYears = yearList;
    },
    selectReportType() {
      this.hasData = false;
      this.rankSelected = this.getRankName(this.reportParam.rankId);
    },

    getRankName(rankId) {
      let rankName = '';
      const rankList = this.ranks
      for (let c in rankList) {
        if (parseInt(rankList[c].id) === parseInt(rankId)) {
          rankName = rankList[c].name;
        }
      }
      return rankName;
    },

    async getManpowerPoolList() {
      if (this.reportParam.rankId === 0) {
        this.errorAlert('Please Select Rank', '')
      } else {
        this.reportParam.isPDF = false;
        this.hasData = false;
        let params = this.reportParam;
        this.isLoading = true;
        return await axios.post(PMC.GET_MANPOWER_POOL_STANDBY_ONBOARD_LIST, params).then(async r => {
          console.log(r.data);
          this.isLoading = false;
          if (Object.keys(r.data).length > 0) {
            this.hasData = true;
            this.crewData = r.data;
          } else {
            this.hasData = false;
            this.crewData = null;
          }

          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
          return false
        });
      }

    },

    async getRanks() {
      let params = {};
      return await axios.get(PMC.GET_ACTIVE_RANK_LEVEL, params).then(async r => {
        console.log(r.data);
        this.ranks = r.data;
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
        return false
      });
    },

    async printManpowerPoolList() {
      this.reportParam.rankName = this.rankSelected;
      this.reportParam.isPDF = true;
      let params = this.reportParam;
      return await axios.post(PMC.GET_MANPOWER_POOL_STANDBY_ONBOARD_LIST, params).then(async r => {
        this.isLoading = false;
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Sign On/Off Summary')
        return false
      });
    },

    async printReport() {
      const progressModal = $('#generatingAnimationModal')
      progressModal.modal('show');
      let pdfData = await this.printManpowerPoolList();
      if (pdfData) {
        await this.pdfViewer('File', pdfData);
      }
      progressModal.modal('hide');
    },

    monthStr(month) {
      const months = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      }
      return months[month];
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objOwners',
        'objVessels',
        'isReloading',
      ]),
  },

  /** mounted **/
  mounted() {
    this.yearList();
    this.getRanks();
  },

  /** created **/
  async created() {
    this.$MyGlobalFunction = global_variables_config
    await this.getOwnerKeys();
    await this.getCrewComplimentPerVessel();
    this.vesselList = this.objVessels;

    if (this.$route.query.view != null) {
      this.reportParam.rankId = parseInt(this.$route.query.rankId);
      this.reportParam.filterType = String(this.$route.query.filterType);
      this.reportParam.isOverAge = String(this.$route.query.isOverAge);
      this.getManpowerPoolList();
    }

  },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },

}
</script>


<style scoped>

</style>
