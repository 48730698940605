<template>
    <div class="container-fluid  bg-white">
        <div class="row text-left">
            <div class="col-lg-5">
                <div class="form-group row">
                    <label for="ccmemo-from" class="col-md-3 col-form-label">From</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-8">
                        <label id="ccmemo-from">
                            {{ crewChangeMemo.ccMemoFrom ? crewChangeMemo.ccMemoFrom : 'N/A' }}
                        </label>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="ccmemo-to" class="col-md-3 col-form-label">To</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-8">
                        <label id="ccmemo-to">
                            {{ crewChangeMemo.ccMemoTo ? crewChangeMemo.ccMemoTo : 'N/A' }}
                        </label>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="ccmemo-no" class="col-md-4 col-form-label">CCM #No.</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                        <label>{{ crewChangeMemo.ccMemoNo ? crewChangeMemo.ccMemoNo : 'N/A' }}</label>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="cc-memo-status" class="col-md-4 col-form-label font-weight-bold">Memo Status</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">

                        <div v-if="crewChangeMemo.ccMemoNo">
                 <span id="cc-memo-status" name="ccMemoStatus" class="col-form-label">
                  {{ crewChangeMemo.ccMemoIsApproved ? 'Approved' : 'Pending for update' }}
                </span>
                            -
                            <span class="ml-1">
                  <font-awesome-icon v-if="crewChangeMemo.ccMemoIsApproved" icon="thumbs-up" class="text-success"/>
                  <font-awesome-icon v-if="!crewChangeMemo.ccMemoIsApproved" icon="thumbs-down" class="text-danger"/>
                </span>
                        </div>
                        <div v-else>
                            <span>N/A</span>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="cc-memo-revision-no" class="col-md-4 col-form-label font-weight-bold">Revision
                        #No.</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                        <label type="text" id="cc-memo-revision-no"
                               name="ccMemoRevisionNo"
                               class="form-control form-control-sm form-control-plaintext-custom">

                            <span v-if="!Object.is(Math.abs(crewChangeMemo.ccMemoRevisionNo),+crewChangeMemo.ccMemoRevisionNo)
                                        || crewChangeMemo.ccMemoRevisionNo === 0
                                        || crewChangeMemo.ccMemoRevisionNo === null">
                                N / A
                            </span>

                            <span v-if="Object.is(Math.abs(crewChangeMemo.ccMemoRevisionNo),+crewChangeMemo.ccMemoRevisionNo)
                                        && crewChangeMemo.ccMemoRevisionNo !== 0
                                        && crewChangeMemo.ccMemoRevisionNo !== null">
                                {{ crewChangeMemo.ccMemoRevisionNo ? ordinalSuffixOf(crewChangeMemo.ccMemoRevisionNo)+' Amendment' : 'N / A' }}
                            </span>

<!--                          Negative not included          -->
<!--                            <span -->
<!--                              v-if="!Object.is(Math.abs(crewChangeMemo.ccMemoRevisionNo),+crewChangeMemo.ccMemoRevisionNo) && crewChangeMemo.ccMemoRevisionNo != null"-->
<!--                            >-->
<!--                                {{ crewChangeMemo.ccMemoRevisionNo ? ordinalSuffixOf(crewChangeMemo.ccMemoRevisionNo) + ` Amendment` : 'N/A' }}-->
<!--                            </span>-->

                        </label>
                    </div>
                </div>

                <div class="form-group row">
                    <!-- <label class="col-md-4 col-form-label font-weight-bold">SPAN</label>
										<span class="col-form-label">:</span>
										<div class="col-md-6">
											<input class=""/>
										</div> -->
                </div>
            </div>

            <div class="col-lg-4">
                <div class="form-group row">
                    <label for="ccmemo-vessel" class="col-md-3 col-form-label">Vessel</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-8">
                        <label id="ccmemo-vessel">
                            {{ crewChangeMemo.ccMemoVessel ? crewChangeMemo.ccMemoVessel : 'N/A' }}
                        </label>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="ccmemo-ecdis-type" class="col-md-3 col-form-label">ECDIS Type</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-8">
                        <label id="ccmemo-ecdis-type">
                            {{ crewChangeMemo.ccEcdisType ? crewChangeMemo.ccEcdisType : 'N/A' }}
                        </label>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="ccmemo-etd-date" class="col-md-3 col-form-label">ETD Date</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-8">
                        <label>{{ crewChangeMemo.ccEtdDate ? crewChangeMemo.ccEtdDate : 'N/A' }}</label>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="form-group row">
                    <label for="ccmemo-date-created" class="col-md-4 col-form-label">Created At</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                        <label>{{ crewChangeMemo.ccMemoCreatedAt ? crewChangeMemo.ccMemoCreatedAt : 'N/A' }}</label>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="ccmemo-date-updated" class="col-md-4 col-form-label">Updated At</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-6">
                        <label>
                            {{ crewChangeMemo.ccMemoUpdatedAt ? crewChangeMemo.ccMemoUpdatedAt : 'N/A' }}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <div class="row text-left align-middle mt-1">
            <div class="col">
                <span class="cc-font-size">*** DETAILS AS FOLLOWS ***</span>
            </div>
        </div>

        <!-- Menu buttons   -->
        <div class="row text-left align-middle mt-1">
            <div class="col-sm-9">
                <div class="btn-group" name="actionLabels" id="action-labels" role="group" aria-label="Action Labels">
                    <!--<div v-if="!isCcMemoEditable">
												<button type="button" class="btn btn-sm pmc-btn-warning ml-1" @click="editCcMemo">
													<font-awesome-icon icon="edit"/>
													Edit
												</button>
											</div>

											<button type="button" class="btn btn-sm pmc-btn-danger ml-1" @click="cancelCcMemo">
												<font-awesome-icon icon="times-circle"/>
												Cancel
											</button>-->

                    <button type="button" class="btn btn-sm btn-success ml-1" @click="saveCcMemo"
                            v-if="!crewChangeMemo.ccMemoId && !ccmMasterChecklistParams.ccmNo">
                        <font-awesome-icon icon="save"/>
                        Save
                    </button>

                    <div v-if="crewChangeMemo.ccMemoId">
                        <!--  todo -->
                        <button
                          type="button"
                          v-show="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null"
                          @click="setUpdateCcMemo"
                          class="btn pmc-btn-warning btn-sm ml-1">
                            <font-awesome-icon icon="plus-circle"/>
                            Add /
                            <font-awesome-icon icon="minus-circle"/>
                            Remove
                        </button>

                        <button type="button" class="btn btn-sm pmc-btn-prime ml-1" @click="previewPrintCcMemoNewWindow">
                            <font-awesome-icon icon="print"/>
                            Preview & Print
                        </button>

                        <button type="button" class="btn btn-sm pmc-btn-info ml-1"
                                @click="approvedCcMemo(crewChangeMemo)"
                                v-show="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null"
                                v-if="!crewChangeMemo.ccMemoIsApproved">
                            <font-awesome-icon icon="thumbs-up"/>
                            Approve
                        </button>

                        <button type="button" class="btn btn-sm pmc-btn-danger ml-1"
                                @click="disApprovedCcMemo(crewChangeMemo)"
                                v-show="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null"
                                v-if="crewChangeMemo.ccMemoIsApproved">
                            <font-awesome-icon icon="thumbs-down"/>
                            Disapprove
                        </button>

                        <button type="button" class="btn btn-sm pmc-btn-tertia ml-1"
                                v-show="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null"
                                @click="revisionUpCcMemo(crewChangeMemo)">
                            <font-awesome-icon icon="arrow-up"/>
                            Revision Up
                        </button>

                        <button type="button" class="btn btn-sm pmc-btn-second ml-1"
                                v-show="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null"
                                :disabled="!Object.is(Math.abs(crewChangeMemo.ccMemoRevisionNo),+crewChangeMemo.ccMemoRevisionNo) || crewChangeMemo.ccMemoRevisionNo === 0"
                                @click="revisionDownCcMemo(crewChangeMemo)">
                            <font-awesome-icon icon="arrow-down"/>
                            Revision Down
                        </button>

                        <button
                                type="button"
                                class="btn btn-sm btn-primary ml-1"
                                data-toggle="modal"
                                data-target="#covid-vaccine-record"
                        >
                            <font-awesome-icon icon="syringe"/>
                            Covid Vaccine Record
                        </button>

                    </div>

                    <!--<button type="button" class="btn btn-sm pmc-btn-prime ml-1" @clic="viewCovidRecords">
											View Covid Records
										</button>-->
                </div>
            </div>
        </div>

<!--      {{ crewChangeMemo.ccMemoVesselId }}-->

        <!--  sign on crew  -->
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="row">
                    <table class="mytable">
                        <tr>
                            <td class="text-left" style="width: 42.8%">
                                <button style="" type="button" class="btn btn-sm pmc-btn-info ml-1"
                                        data-toggle="modal"
                                        data-target="#checklist-reports"
                                        v-if="crewChangeMemo.ccMemoId"
                                        @click="getCcmMasterListSignOn(1)">
                                    <font-awesome-icon icon="file"/>
<!--                                  {{ vesselId }}-->
                                    View Master Checklist
                                </button>


                            </td>
                            <td class="text-left">
                                <span class="font-weight-bold text-primary">SIGN-ON CREW</span>
                                <span class="text-center align-middle small"
                                      v-if="crewChangeMemo.signOnCrews">( {{
                                        crewChangeMemo.signOnCrews.length
                                    }} )</span>
                                <span class="mr-2 ml-2text-primary">|</span>
                                <span class="text-left align-middle small font-weight-bold text-primary">
                  <font-awesome-icon icon="ship"/>
                  Promotion Onboard
                </span>
                            </td>
                        </tr>
                    </table>


                    <!--          <div class="col">-->
                    <!--            <span class="font-weight-bold">SIGN-ON CREW</span>-->
                    <!--            <span class="text-center align-middle small"-->
                    <!--                  v-if="crewChangeMemo.signOnCrews">( {{ crewChangeMemo.signOnCrews.length }} )</span>-->
                    <!--            <span class="mr-2 ml-2">|</span>-->
                    <!--            <span class="text-left align-middle small font-weight-bold">-->
                    <!--              <font-awesome-icon icon="ship"/>-->
                    <!--              Promotion Onboard-->
                    <!--            </span>-->
                    <!--          </div>-->
                </div>
                <div class="table table-responsive-lg">
                    <pmc-table :max-height="ccOnResHeight"
                               :loading="isCcMemo"
                               :loader-colspan="10">
                        <template v-slot:table-header>
                            <tr>
                                <th colspan="3" class="crew-name p-1">Name</th>
                                <th class="p-1">Rank</th>
                                <th class="p-1">Birthdate</th>
                                <th class="p-1">Birthplace</th>
                                <th class="p-1">SIRB Validity</th>
                                <th class="p-1">Passport Validity</th>
                                <th class="p-1">US Visa Validity</th>
                                <th class="p-1">Remarks</th>
                            </tr>
                        </template>
                        <template v-slot:table-body>
                            <tr v-for="(signOnCrew,index) in crewChangeMemo.signOnCrews" :key="index">
                                <td colspan="3" class="p-1 cc-font-size text-left align-middle pl-3">
                                    <!--  TODO: if  signOnCrew has crewChangePromotionRelation   show icon             -->
                                    <sup v-if="signOnCrew.isOnboardPromotion">
                                        <font-awesome-icon icon="ship"/>
                                    </sup>

                                    <a href="#"
                                       :class="[
                        signOnCrew.crewTypeId === 1 && signOnCrew.scholarTypeId === 1 ? 'bridging-cadet' : 'default-crew-css',
                        signOnCrew.crewTypeId === 1 && signOnCrew.scholarTypeId === 2 ? 'maap' : 'default-crew-css',
                        signOnCrew.crewTypeId === 1 && signOnCrew.scholarTypeId === 3 ? 'msap' : 'default-crew-css',
                        signOnCrew.crewTypeId === 1 && signOnCrew.scholarTypeId === 4 ? 'fast-track'  : 'default-crew-css',
                        signOnCrew.crewTypeId === 2 ? 'utility-program' : 'default-crew-css',
                        //TODO data related for the  sponsored hrm.
                     ]"
                                       @click="gotoCrewProfile(signOnCrew.crewId,'ccmemo')"
                                       v-if="signOnCrew.crewName && signOnCrew.crewTypeId != (3 || 4)">
                                        {{ signOnCrew.crewName }}
                                    </a>

                                    <!--  Sign on Crew Name -->
                                    <a v-if="signOnCrew.crewId" href="" class="text-primary" data-toggle="modal"
                                       data-target="#documentListModal"
                                       @click="getCrewDocumentsParam(signOnCrew)">
                                        <font-awesome-icon icon="file"
                                                           style="margin-left:6px;font-size: 1.3em !important;"/>
                                    </a>

                                    <button type="button" class="ml-2 btn btn border-0"
                                            @click="gotoCovidVaccineProfile(signOnCrew,1)">
                                        <!-- TODO go into crew profile under covid vaccine tab. -->
                                        <font-awesome-icon v-if="signOnCrew.hasCovidVaccine"
                                                           :icon="['fas', 'syringe']"
                                                           title="Crew has covid vaccine ..."
                                                           style="font-size: 1.3em !important"
                                                           class="pmc-text-tertia"/>
                                    </button>
                                </td>
                                <td class="p-1 cc-font-size text-center align-middle">{{ signOnCrew.rankAlias }}</td>
                                <td class="p-1 cc-font-size text-center align-middle">{{
                                        signOnCrew.birthDate | moment('DD MMM YYYY')
                                    }}
                                </td>
                                <td class="p-1 cc-font-size text-center align-middle">{{ signOnCrew.birthPlace }}</td>
                                <td class="p-1 cc-font-size text-center align-middle">
                                    <span style="display: block; font-size: 10px !important;">{{
                                            signOnCrew.sirbDocNo
                                        }}</span>
                                    <span class="font-weight-bold">{{
                                            signOnCrew.sirbExpiryDate | moment('DD MMM YYYY')
                                        }}</span>
                                </td>
                                <td class="p-1 cc-font-size text-center align-middle">
                                    <span style="display: block; font-size: 10px !important;">{{
                                            signOnCrew.passportDocNo
                                        }}</span>
                                    <span class="font-weight-bold"> {{
                                            signOnCrew.passportExpiryDate | moment('DD MMM YYYY')
                                        }}</span>
                                </td>
                                <td class="p-1 cc-font-size text-center align-middle">
                                    <span style="display: block; font-size: 10px !important;">{{
                                            signOnCrew.usVisaDocNo
                                        }}</span>
                                    <span class="font-weight-bold">{{
                                            signOnCrew.usVisaExpiryDate | moment('DD MMM YYYY')
                                        }}</span>
                                </td>
                                <td class="p-1 cc-font-size text-center align-middle">
                                    <span>{{ signOnCrew.ccMemoRemark }}</span>
                                </td>
                            </tr>
                        </template>
                    </pmc-table>
                </div>
            </div>
        </div>

        <!--  sign off crew   -->
        <div class="row">
            <div class="col-md-12">

                <!--        <div class="row">-->
                <!--          <div class="col text-center align-middle">-->
                <!--            <span class="font-weight-bold text-primary">SIGN-OFF CREW</span>-->
                <!--            <span class="text-center align-middle small mr-3"-->
                <!--                  v-if="crewChangeMemo.signOffCrews">( {{ crewChangeMemo.signOffCrews.length }} )</span>-->
                <!--          </div>-->
                <!--        </div>-->

                <div class="row">
                    <table class="mytable">
                        <tr>
                            <td class="text-left" style="width: 46%">
                                <button style="" type="button" class="btn btn-sm pmc-btn-info ml-1"
                                        data-toggle="modal"
                                        data-target="#checklist-reports"
                                        v-if="crewChangeMemo.ccMemoId"
                                        @click="getCcmMasterListSignOn(0)">
                                    <font-awesome-icon icon="file"/>
                                    View Master Checklist
                                </button>
                            </td>
                            <td class="text-left">
                                <span class="font-weight-bold text-primary">SIGN-OFF CREW</span>
                                <span class="text-center align-middle small mr-3"
                                      v-if="crewChangeMemo.signOffCrews">
                                  ( {{ crewChangeMemo.signOffCrews.length }} )
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>

                <pmc-table :max-height="ccOffResHeight" :loading="isCcMemo" :loader-colspan="8">
                    <template v-slot:table-header>
                        <!-- // -->
                        <tr>
                            <th colspan="3" class="crew-name cc-font-size">Name</th>
                            <th class="p-1 text-center align-middle">Rank</th>
                            <th class="p-1 text-center align-middle">Embark</th>
                            <th class="p-1 text-center align-middle">US Visa Validity</th>
                            <th class="p-1 text-center align-middle">Visa Type</th>
                            <th class="p-1 text-center align-middle">F/Contract</th>
                        </tr>
                    </template>
                    <template v-slot:table-body>
                        <tr v-for="(signOffCrew,index) in crewChangeMemo.signOffCrews" :key="index">
                            <td colspan="3" class="cc-font-size p-1 text-left align-middle pl-3">
                                <!-- <a href="#"
																			 @click="gotoCrewProfile(signOnCrew.crewId,'ccmemo')">{{signOnCrew.crewName}}</a> -->
                                <div v-if="signOffCrew.crewName && signOffCrew.crewTypeId != (3 || 4)">
                                    <a href="#"
                                       :class="[
                                           signOffCrew.crewTypeId === 1 && signOffCrew.scholarTypeId === 1 ? 'bridging-cadet' : 'default-crew-css',
                                           signOffCrew.crewTypeId === 1 && signOffCrew.scholarTypeId === 2 ? 'maap' : 'default-crew-css',
                                           signOffCrew.crewTypeId === 1 && signOffCrew.scholarTypeId === 3 ? 'msap' : 'default-crew-css',
                                           signOffCrew.crewTypeId === 1 && signOffCrew.scholarTypeId === 4 ? 'fast-track'  : 'default-crew-css',
                                          signOffCrew.crewTypeId === 2 ? 'utility-program' : 'default-crew-css',
                                          signOffCrew.scholarTypeId === null ? 'default-crew-css' : 'default-crew-css',
                                          //TODO data related for the  sponsored hrm.
                                       ]"
                                       @click="gotoCrewProfile(signOffCrew.crewId,'ccmemo')">
                                        {{ signOffCrew.crewName }}
                                    </a>

                                    <a v-if="signOffCrew.crewId" href="" data-toggle="modal"
                                       class="text-primary"
                                       data-target="#documentListModal"
                                       @click="getCrewDocumentsParam(signOffCrew)">
                                        <font-awesome-icon
                                          icon="file" style="margin-left:6px;font-size: 1.3em !important;"/>
                                    </a>

                                    <button type="button" class="ml-2 btn btn border-0"
                                            @click="gotoCovidVaccineProfile(signOffCrew,1)">
                                        <font-awesome-icon v-if="signOffCrew.hasCovidVaccine"
                                           :icon="['fas', 'syringe']"
                                           title="Crew has covid vaccine ..."
                                           style="font-size: 1.3em !important"
                                           class="pmc-text-tertia"/>
                                    </button>

                                </div>
                                <span v-else>N/A</span>
                            </td>
                            <td class="cc-font-size p-1 text-center align-middle">
                                {{ signOffCrew.crewName ? signOffCrew.rankAlias : '--' }}
                            </td>
                            <td class="cc-font-size p-1 text-center align-middle">
                                <span v-if="signOffCrew.dpManilaDate">
                                  {{ signOffCrew.dpManilaDate | moment('DD MMM YYYY') }}
                                </span>
                            </td>
                            <!-- this is department manila date -->
                            <td class="cc-font-size p-1 text-center align-middle">
                                <span style="display: block; font-size: 10px !important;">{{
                                        signOffCrew.usVisaDocNo
                                    }}</span>
                                <span class="font-weight-bold" v-if="signOffCrew.usVisaExpiryDate">{{
                                        signOffCrew.usVisaExpiryDate | moment('DD MMM YYYY')
                                    }}</span>
                            </td>
                            <td class="cc-font-size p-1 text-center align-middle">{{ signOffCrew.usVisaType }}</td>
                            <td class="cc-font-size p-1 text-center align-middle" v-if="signOffCrew.endOfContract">
                                {{ signOffCrew.endOfContract | moment('DD MMM YYYY') }}
                            </td>
                        </tr>
                    </template>
                </pmc-table>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-8">
                                <div class="row text-left">
                                    <div class="col-3">
                                        <label class="col-form-label font-weight-bold">
                                            Crew Change Memo Remarks
                                        </label>
                                    </div>

                                    <div class="col-3">
                                        <button type="button"
                                                class="btn btn-sm btn-primary"
                                                v-show="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null"
                                                data-toggle="modal"
                                                data-target="#add-ccmemo-remarks-modal"
                                                data-backdrop="static"
                                                data-keyboard="false">
                                            <font-awesome-icon icon="plus"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <pmc-table :max-height="140">
                            <template v-slot:table-header>
                                <tr>
                                    <td class="text-center align-middle p-1 cc-font-size" style="width: 50%;">Remarks</td>
                                    <td class="text-center align-middle p-1 cc-font-size" style="width: 15%;">Created By</td>
                                    <td class="text-center align-middle p-1 cc-font-size" style="width: 15%;">Created At</td>
                                    <td class="text-center align-middle p-1 cc-font-size" style="width: 10%;">Action</td>
                                </tr>
                            </template>
                            <template v-slot:table-body>
                                <tr v-if="ccMemoRemarks && ccMemoRemarks.length === 0">
                                    <td colspan="4" class="p-1 cc-font-size text-center align-middle">No remarks
                                        found.
                                    </td>
                                </tr>
                                <tr v-for="(ccMemoRemark,index) in ccMemoRemarks" :key="index">
                                    <td class="text-left p-1 align-middle cc-font-size pl-2">
                                      <textarea class="form-control form-control-sm border-0"
                                          v-model="ccMemoRemark.remarks"
                                          :id="`ccm-remarks-${index}`"
                                          cols="2"
                                          rows="7"
                                          style="min-height: 100%; min-width: 100%"
                                          disabled
                                      />
                                    </td>
                                    <td class="text-center p-1 align-middle cc-font-size">
                                        {{ ccMemoRemark.createdBy ? ccMemoRemark.createdBy : '--' }}
                                    </td>
                                    <td class="text-center p-1 align-middle cc-font-size">
                                        {{ ccMemoRemark.createdAt ? ccMemoRemark.createdAt : '--' }}
                                    </td>
                                    <td class="text-center p-1 align-middle cc-font-size">
                                        <button :name="`btnUpdateCcRemark`+index"
                                                :id="`btn-ccmemo-remark-update-`+index"
                                                type="button"
                                                v-show="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null"
                                                class="btn btn-sm"
                                                @click="updateCcMemoRemarks(ccMemoRemark,index)"
                                                hidden="hidden">
                                            <font-awesome-icon icon="save" class="pmc-text-prime"/>
                                            Update
                                        </button>

                                        <button :name="`btnCancelCcRemark`+index"
                                                :id="`btn-ccmemo-remark-cancel-`+index"
                                                type="button"
                                                v-show="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null"
                                                class="btn btn-sm ml-1"
                                                @click="setCancelCcMemoRemark(ccMemoRemark,index)"
                                                hidden="hidden">
                                            <font-awesome-icon icon="times" class="pmc-text-second"/>
                                            Cancel
                                        </button>

                                        <button class="btn btn-sm ml-1" :id="`btn-ccmemo-remark-edit-${index}`"
                                                v-show="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null"
                                                @click="setEditCcMemoRemarks(ccMemoRemark,index)">
                                            <font-awesome-icon icon="edit" class="pmc-text-warning"/>
                                            Edit
                                        </button>

                                        <button class="btn btn-sm ml-1" :id="`btn-ccmemo-remark-delete-${index}`"
                                                v-show="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null"
                                                @click="deleteCcMemoRemarks(ccMemoRemark.id)">
                                            <font-awesome-icon icon="trash" class="pmc-text-danger"/>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            </template>
                        </pmc-table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row text-left mt-2">
            <div class="col-md-12">
              <span class="text-left align-middle pl-3">
                <font-awesome-icon icon="square" class="fa-1x" style="color: #ff090a!important;"/>
                Fast Track Cadet
              </span>

              <span class="text-left align-middle pl-3">
                <font-awesome-icon icon="square" class="fa-1x" style="color: #ff901f !important;"/>
                Bridging Cadet
              </span>

              <span class="text-left align-middle pl-3">
                <font-awesome-icon icon="square" class="fa-1x" style="color: #28a745 !important;"/>
                 MAAP
              </span>

              <span class="text-left align-middle pl-3">
                <font-awesome-icon icon="square" class="fa-1x" style="color: #9f03ff !important;"/>
                MSAP
              </span>

              <span class="text-left align-middle pl-3">
                <font-awesome-icon icon="square" class="fa-1x" style="color: #1205ff !important;"/>
                Sponsored HRM
              </span>

              <span class="text-left align-middle pl-3">
                <font-awesome-icon icon="square" class="fa-1x" style="color: #156276 !important;"/>
                Utility Program
              </span>
            </div>
        </div>

        <!-- Add Remark Modal -->
        <div class="modal fade" id="add-ccmemo-remarks-modal" tabindex="-1" role="dialog"
             aria-labelledby="addCcMemoRemarksModal"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="add-ccmemo-remarks-modal-header">New Remarks</h5>
                        <button type="button" class="close" aria-label="Close" @click="closeAddRemarks">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    <textarea class="form-control"
                        v-model="newCcMemoRemark.remarks"
                        id="new-remarks"
                        placeholder="Add remarks here."
                        style="resize: none;
                        border: none;"
                        rows="8"
                    />
                    </div>
                    <div class="modal-footer">
                        <div class="btn-group mt-2"
                             name="remarksAction"
                             id="remarks-actions"
                             role="group"
                             aria-label="Remarks Actions">
                            <button class="btn btn-sm ml-2" @click="saveCcMemoRemarks">
                                <font-awesome-icon icon="save" class="text-success"/>
                                Save
                            </button>

                            <button class="btn btn-sm ml-2" @click="clearRemarks">
                                <font-awesome-icon icon="eraser" class="text-dark"/>
                                Clear
                            </button>

                            <button class="btn btn-sm ml-2" @click="cancelRemarks">
                                <font-awesome-icon icon="times" class="text-danger"/>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="no-batch-list-modal" tabindex="-1" role="dialog"
             aria-labelledby="noBatchListModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="no-batch-list-modal-label">Add / Remove Crew</h5>
                        <button type="button" class="close" @click="closeNoBatchList">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <!-- body -->
                    <div class="modal-body">
                        <div class="row">
                            <div class="col">
                                <pmc-table :max-height="500" :loading="isBatchListing" :loader-colspan="3">
                                    <template v-slot:table-header>
                                        <tr>
                                            <th colspan="3">
                                                In-Batch
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-center align-middle" style="width: 5%;">Rank</th>
                                            <th class="text-center align-middle" style="width: 65%;">Reliever Name</th>
                                            <th style="width: 15%;" class="text-center align-content-center">
                                                <button class="btn btn-light pmc-text-danger"
                                                        @click="confirmDeleteBatchMemo()">
                                                    <span class="font-weight-bold mr-2"
                                                          style="font-size: 0.8rem !important;">Remove</span>
                                                    <font-awesome-icon icon="arrow-right"/>
                                                </button>
                                            </th>
                                        </tr>
                                    </template>
                                    <template v-slot:table-body>
                                        <tr v-if="hasBatchSignOnList.length === 0">
                                            <td colspan="3">----</td>
                                        </tr>
                                        <tr v-if="hasBatchSignOnList" v-for="(hasBatchSignOn,index) in hasBatchSignOnListFinal"
                                            :key="hasBatchSignOn.id">
                                            <td class="text-center align-middle">{{ hasBatchSignOn.rankAlias }}</td>
                                            <td class="pl-3 text-left align-middle">
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="font-weight-bold"
                                                             style="font-size: 1.2rem!important;">
                                                            {{ hasBatchSignOn.crewSignOn }}
                                                        </div>
                                                        <div class="small text-muted"
                                                             style="font-size: 0.9rem!important;">
                                                            {{ hasBatchSignOn.crewSignOff }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td style="width: 20%!important;" class="text-center align-content-center">
                                                <input type="checkbox" id="has-batch-action"
                                                       v-model="hasBatchSignOn.checked"
                                                       :checked="hasBatchSignOn.checked ?? false"
                                                       @click="setRemoveInBatch($event,hasBatchSignOn)">
                                            </td>
                                        </tr>
                                    </template>
                                </pmc-table>
                            </div>

                            <div class="col">
                                <!--  todo make conditional in max-height -->
                                <pmc-table :max-height="500" :loading="isBatchListing" :loader-colspan="3">
                                    <template v-slot:table-header>
                                        <tr>
                                            <th colspan="3">No Batch</th>
                                        </tr>
                                        <tr>
                                            <th style="width: 15% !important;" class="text-center align-content-center">
                                                <button class="btn btn-light pmc-text-tertia"
                                                        @click="confirmAddBatchMemo()">
                                                    <font-awesome-icon icon="arrow-left"/>
                                                    <span class="font-weight-bold ml-2"
                                                          style="font-size: 0.8rem !important;">Add</span>
                                                </button>
                                            </th>
                                            <th style="width: 5% !important;" class="text-center align-middle">Rank</th>
                                            <th style="width: 65% !important;" class="text-center align-middle">Reliever
                                                Name
                                            </th>
                                        </tr>
                                    </template>
                                    <template v-slot:table-body>
                                        <tr v-if="noBatchSignOnList.length === 0">
                                            <td colspan="3">----</td>
                                        </tr>

                                        <tr v-if="noBatchSignOnList" v-for="(noBatchSignOn,index) in noBatchSignOnListFinal"
                                            :key="noBatchSignOn.id">
                                            <td class="text-center align-content-center">
                                                <input type="checkbox" id="no-batch-action"
                                                       v-model="noBatchSignOn.checked"
                                                       :checked="noBatchSignOn.checked ?? false"
                                                       @click="setAddInBatch($event,noBatchSignOn)">
                                            </td>
                                            <td class="text-center align-middle">{{ noBatchSignOn.rankAlias }}</td>
                                            <td class="pl-3 text-left align-middle">
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="font-weight-bold"
                                                             style="font-size: 1.2rem!important;">
                                                            {{ noBatchSignOn.crewSignOn }}
                                                        </div>
                                                        <div class="small text-muted"
                                                             style="font-size: 0.9rem!important;">
                                                            {{ noBatchSignOn.crewSignOff }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </pmc-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Add Crew Documents Modal -->
        <div class="modal fade" id="documentListModal" tabindex="-1" role="dialog"
             aria-labelledby="documentListModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-left" id="documentListModalTitle">Crew Documents <br><code
                                class="text-left pmc-text-second" style="float: left;">{{
                                documentChecklist.crewName
                            }}</code></h5>
                        <div class="btn-group">
                            <button class="btn btn-sm ml-2" @click="closeDocumentListModal()" aria-label="Close">
                                <font-awesome-icon icon="times" class="text-danger"/>
                                Close
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-7 align-content-start" style="float: left">
                            <span style="float: left" class="form-inline">
                              <label for="contract-duration" class="col-form-label-sm mr-2">Contract Duration : </label>
                                <select id="contract-duration" class="custom-select custom-select-sm"
                                        v-model="ccmDocsParams.contract">
                                  <option value="1">1 month</option>
                                  <option value="2">2 months</option>
                                  <option value="3">3 months</option>
                                  <option value="4">4 months</option>
                                  <option value="5">5 months</option>
                                  <option value="6">6 months</option>
                                  <option value="7">7 months</option>
                                  <option value="8">8 months</option>
                                  <option value="9">9 months</option>
                                  <option value="10">10 months</option>
                                  <option value="11">11 months</option>
                                  <option value="12">12 months</option>
                                </select>
                            </span>
                            <span style="float: right" class="form-inline">
                              <label for="contract-print-date1" class="col-form-label-sm mr-2">Print Date : </label>
                              <input type="date" id="contract-print-date1" class="form-control form-control-sm"
                                   v-model="ccmDocsParams.printDate">
                            </span>
                            </div>
                            <div class="col-md-5 align-content-start" style="float: left">
                              <span style="float: left; width: 100%;" class="form-inline">
                                <label for="contract-duration" class="col-form-label-sm mr-2">Reason/Remarks : </label>
                                  <!--                  <input type="text" id="contract-remarks" class="form-control form-control-sm"-->
                                  <!--                         v-model="ccmDocsParams.remarks">-->
                                <select id="contract-duration" class="custom-select custom-select-sm"
                                        v-model="ccmDocsParams.remarks">
                                    <option value="">-- Select Option--</option>
                                    <option value="CE">Contract Extension</option>
                                    <option value="OP">Onboard Promotion</option>
                                    <option value="SA">Salary Amendment</option>
                                    <option value="VNC">Vessel Name Change</option>
                                  </select>
                              </span>
                            </div>
                        </div>
                        <hr/>
                        <div class="table table-responsive-lg">
                            <pmc-table :max-height="320"
                                       :loader-colspan="10">
                                <template v-slot:table-header>
                                    <tr>
                                        <th colspan="4" style="width: 45%;font-weight: normal;">Document</th>
                                        <th colspan="4" style="width: 35%;font-weight: normal;">Signatory</th>
                                        <th colspan="2"></th>
                                    </tr>
                                </template>
                                <template v-slot:table-body>
                                    <tr>
                                        <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                                            POEA - Contract of Employment
                                        </td>
                                        <td colspan="4" class="p-2 cc-font-size text-center align-middle">
                                            <select class="form-control form-control-sm"
                                                    v-model="ccmDocsParams.signatory">
                                                <option value="null">-- checked by ---</option>
                                                <option :value="signatory"
                                                        v-for="(signatory,index) in $globalVariables.ccm_doc_signatories"
                                                        :key="signatory.id">
                                                    {{ signatory.name }}
                                                </option>
                                            </select>
                                        </td>
                                        <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                                            <button type="button" class="btn btn-sm btn-primary ml-1"
                                                    @click="loadCcmDocument($globalVariables.ccmDocs.poea, documentChecklist.crewChecklist)">
                                                <font-awesome-icon icon="print"/>
                                                Print
                                            </button>
                                        </td>
                                    </tr>
                                    <tr style="display: none;">
                                        <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                                            InterOcean Contract
                                        </td>
                                        <td colspan="4" class="p-2 cc-font-size text-center align-middle">
                                        </td>
                                        <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                                            <button type="button" class="btn btn-sm btn-primary ml-1"
                                                    @click="loadCcmDocument($globalVariables.ccmDocs.interOcean, documentChecklist.crewChecklist)">
                                                <font-awesome-icon icon="print"/>
                                                Print
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="p-2 cc-font-size text-left pl-3 font-weight-bold">
                                            ITF
                                        </td>
                                        <td colspan="4" class="p-2 cc-font-size text-center align-middle"></td>
                                        <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                                            <button type="button" class="btn btn-sm btn-primary ml-1"
                                                    @click="loadCcmDocument($globalVariables.ccmDocs.itf, documentChecklist.crewChecklist)">
                                                <font-awesome-icon icon="print"/>
                                                Print
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                                            Info Sheet
                                        </td>
                                        <td colspan="4" class="p-2 cc-font-size text-center align-middle"></td>
                                        <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                                            <button type="button" class="btn btn-sm btn-primary ml-1"
                                                    @click="loadCcmDocument($globalVariables.ccmDocs.infoSheet, documentChecklist.crewChecklist)">
                                                <font-awesome-icon icon="print"/>
                                                Print
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                                            Request For Processing Seafarer/s
                                        </td>
                                        <td colspan="4" class="p-2 cc-font-size text-center align-middle">
                                            <select class="form-control form-control-sm"
                                                    v-model="ccmDocsParams.signatory">
                                                <option value="null">-- checked by ---</option>
                                                <option :value="signatory"
                                                        v-for="(signatory,index) in $globalVariables.ccm_doc_signatories"
                                                        :key="signatory.id">
                                                    {{ signatory.name }}
                                                </option>
                                            </select>
                                        </td>
                                        <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                                            <button type="button" class="btn btn-sm btn-primary ml-1"
                                                    @click="loadCcmDocument($globalVariables.ccmDocs.rps, documentChecklist.crewChecklist)"
                                            >
                                                <font-awesome-icon icon="print"/>
                                                Print
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                                            Request For Processing Seafarer/s Amendment
                                        </td>
                                        <td colspan="4" class="p-2 cc-font-size text-center align-middle">
                                            <select class="form-control form-control-sm"
                                                    v-model="ccmDocsParams.signatory">
                                                <option value="null">-- checked by ---</option>
                                                <option :value="signatory"
                                                        v-for="(signatory,index) in $globalVariables.ccm_doc_signatories"
                                                        :key="signatory.id">
                                                    {{ signatory.name }}
                                                </option>
                                            </select>
                                        </td>
                                        <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                                            <button type="button" class="btn btn-sm btn-primary ml-1"
                                                    @click="loadCcmDocument($globalVariables.ccmDocs.rpsAmmendment, documentChecklist.crewChecklist)">
                                                <font-awesome-icon icon="print"/>
                                                Print
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                                            Crew Pre-Departure Checklist
                                        </td>
                                        <td colspan="4" class="p-2 cc-font-size text-center align-middle"></td>
                                        <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                                            <button type="button" class="btn btn-sm pmc-btn-tertia ml-1"
                                                    @click="crewPreDepChecklist(documentChecklist.crewChecklist)">
                                                <font-awesome-icon icon="file"/>
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </pmc-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Add Checklist Reports Modal -->
        <div class="modal fade" id="checklist-reports" tabindex="-1" role="dialog"
             aria-labelledby="documentListModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-left" id="documentListModalTitle">Checklist Reports <br><code
                                class="text-left pmc-text-second" style="float: left;">{{
                                documentChecklist.crewName
                            }}</code></h5>
                        <div class="btn-group">
                            <button class="btn btn-sm ml-2" @click="closeMasterCheckListModal()" aria-label="Close">
                                <font-awesome-icon icon="times" class="text-danger"/>
                                Close
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="table table-responsive-lg">
                            <pmc-table :max-height="320"
                                       :loader-colspan="10">
                                <template v-slot:table-header>
                                    <tr>
                                        <th colspan="4" style="width: 45%;font-weight: normal;">Document</th>
                                        <th colspan="2"></th>
                                    </tr>
                                </template>
                                <template v-slot:table-body>
                                    <tr>
                                        <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                                            View Checklist
                                        </td>
                                        <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                                            <button type="button" class="btn btn-sm btn-primary ml-1"
                                                    data-toggle="modal"
                                                    data-target="#view-checklist-signon"
                                                    data-backdrop="static"
                                                    data-keyboard="false"
                                            >
                                                <font-awesome-icon icon="file"/>
                                                View File
                                            </button>
                                        </td>
                                    </tr>
                                    <!--                  <tr>-->
                                    <!--                    <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">-->
                                    <!--                      View MLC/OLC Functions-->
                                    <!--                    </td>-->
                                    <!--                    <td colspan="2" class="p-2 cc-font-size text-center align-middle">-->
                                    <!--                      <button type="button" class="btn btn-sm btn-primary ml-1"-->
                                    <!--                              data-toggle="modal"-->
                                    <!--                              data-target="#view-checklist-mlcolc-signon"-->
                                    <!--                              data-backdrop="static"-->
                                    <!--                              data-keyboard="false"-->
                                    <!--                      >-->
                                    <!--                        <font-awesome-icon icon="file"/>-->
                                    <!--                        View File-->
                                    <!--                      </button>-->
                                    <!--                    </td>-->
                                    <!--                  </tr>-->

                                    <!--                  <tr>-->
                                    <!--                    <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">-->
                                    <!--                      Documents & Certificates Monitoring-->
                                    <!--                    </td>-->
                                    <!--                    <td colspan="2" class="p-2 cc-font-size text-center align-middle">-->
                                    <!--                      <button type="button" class="btn btn-sm btn-primary ml-1"-->
                                    <!--                              data-toggle="modal"-->
                                    <!--                              data-target="#view-checklist-doc-cert-monitoring-signon"-->
                                    <!--                              data-backdrop="static"-->
                                    <!--                              data-keyboard="false"-->
                                    <!--                      >-->
                                    <!--                        <font-awesome-icon icon="file"/>-->
                                    <!--                        View File-->
                                    <!--                      </button>-->
                                    <!--                    </td>-->
                                    <!--                  </tr>-->
                                    <tr>
                                        <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                                            View Checklist Document
                                        </td>
                                        <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                                            <button type="button" class="btn btn-sm btn-primary ml-1"
                                                    data-toggle="modal"
                                                    data-target="#view-checklist-document-signon"
                                                    data-backdrop="static"
                                                    data-keyboard="false">
                                                <font-awesome-icon icon="file"/>
                                                View File
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </pmc-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Covid Vaccine Records-->
        <div class="modal fade" id="covid-vaccine-record" tabindex="-1" role="dialog"
             aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-size" role="document">
                <!--      <div class="modal-dialog modal-dialog-centered full-screen-modal2" role="document">-->
                <div class="modal-content" style="height: 120%">
                    <div class="modal-header">
                        <div class="row d-flex align-items-center px-3">
                            <h5 class="modal-title font-weight-bolder mr-5">Covid Vaccine Records</h5>
                            <button type="button" class="btn btn-success btn-sm px-3 font-weight-normal"
                                    @click="loadCcMemoCovidVaccineReport">
                                Print
                            </button>
                        </div>
                        <button class="btn btn-sm ml-2" @click="closeViewCovidModal()" aria-label="Close">
                            <font-awesome-icon icon="times" class="text-danger"/>
                            Close
                        </button>
                    </div>
                    <div class="modal-body" style="overflow-x: hidden !important; overflow-y: scroll;  height: 700px">
                        <div class="row mb-3">
                            <div class="col-sm-4">
                                <div class="form-group row">
                                    <span class="ml-4 font-weight-bold lead" style="font-size: 17px;">
                                      Vessel Name
                                    </span>
                                    <span class="ml-3 font-weight-bold lead" style="font-size: 17px;">:</span>
                                    <div class="col-sm-7 text-left">
                                      <span class="font-weight-bold lead" style="font-size: 17px;">
                                        {{ crewChangeMemo.ccMemoVessel }}
                                      </span>
                                    </div>
                                </div>
                            </div>

                            <!-- vessels -->
                            <div class="col-sm-3">
                                <div class="form-group row">
                                    <span class="font-weight-bold lead" style="font-size: 17px;">Date</span>
                                    <span class="ml-3 font-weight-bold lead" style="font-size: 17px;">:</span>
                                    <div class="col-sm-7 text-left">
                                      <span class="font-weight-bold lead" style="font-size: 17px;">
                                        {{ crewChangeMemo.ccMemoCreatedAt }}
                                      </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ccm-covid-modal-body
                            :sign-off-crews="crewChangeMemo.signOffCrews"
                            :sign-on-crews="crewChangeMemo.signOnCrews"
                            :loading="isReloading"
                            @refresh-ccm-covid-record-list="refreshGetCcmCovid"
                        />
                    </div>
                </div>
            </div>
        </div>


        <!-- VIEW CHECKLIST SIGNON -->
        <div class="modal fade" id="view-checklist-signon" tabindex="-1" role="dialog"
             aria-labelledby="setupCcPlanCenter"
             aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-size" role="document">
                <!--      <div class="modal-dialog modal-dialog-centered full-screen-modal2" role="document">-->
                <div class="modal-content" style="height: 120%">
                    <div class="modal-header">

                        <div class="row d-flex align-items-center px-3">
                            <h5 class="modal-title font-weight-bolder mr-5" id="CrewBiodataTitle">View Checklist</h5>
                            <button type="button" class="btn btn-success btn-sm px-3 font-weight-normal"
                                    @click="loadCcmMasterChecklist($globalVariables.ccmMaster.checklist, documentChecklist.crewChecklist)"
                            >
                                Print
                            </button>

                            <div class="form-group mb-2 ml-3">
                                <select class="form-control form-control-sm"
                                        v-model="ccmMasterChecklistParams.signatory">
                                    <option value="null">-- checked by ---</option>
                                    <option :value="signatory"
                                            v-for="(signatory,index) in $globalVariables.ccm_master_checklist_signatories"
                                            :key="signatory.id">
                                        {{ signatory.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <button class="btn btn-sm ml-2" @click="closeViewChecklistModal()" aria-label="Close">
                            <font-awesome-icon icon="times" class="text-danger"/>
                            Close
                        </button>
                    </div>
                    <div class="modal-body" style="overflow-x: hidden !important; overflow-y: scroll;  height: 700px">
                        <ccm-master-checklist-modal-body
                                :loading="isReloading"
                                :ccm-master-checklist="ccmMasterChecklist"
                                :ccm-vessel-ecdis-id="ccmVesselEcdisId"
                                :ccm-vessel-id="ccmVesselId"
                                :ccm-vessel-ecdis-name="ccmVesselEcdisName"
                                :ccm-flag-id="ccmFlagId"
                                :ccm-flag-name="ccmFlagName"
                                @refresh-ccm-checklist="refreshGetCcmMasterChecklistDetails"
                        />
                    </div>
                    <div class="modal-footer">
                        <table style="width:100%;">
                            <tbody>
                            <tr>
                                <td><span style="font-weight:bold;">Legend : * - With one higher license</span></td>
                                <td>
                                </td>
                                <td><span
                                        style="text-align:right;background-color: Gray; color:white;"><b>Non Mandatory</b></span>
                                </td>
                                <td>
                                </td>
                                <td><span style="text-align:right;background-color: Yellow"><b>Near Expiry</b></span>
                                </td>
                                <td>
                                </td>
                                <td><span style="text-align:right;background-color: #F5761A;color:Black;"><b>No File Upload</b></span>
                                </td>
                                <td>
                                </td>
                                <td><span
                                        style="text-align:right;background-color: Red;color:white;"><b>Expired Documents</b></span>
                                </td>
                                <td>
                                </td>
                                <td><span
                                        style="text-align:right;background-color:Purple;color:white"><b>Mismatch Document No.2 Detail</b></span>
                                </td>
                                <td>
                                </td>
                                <td><span
                                        style="text-align:right;background-color:Black;color:white"><b>Mismatch Document Rank</b></span>
                                </td>
                                <td>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>


        <!-- VIEW CHECKLIST MLC OLC SIGNON -->
        <div class="modal fade" id="view-checklist-mlcolc-signon" tabindex="-1" role="dialog"
             aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-size" role="document">
                <div class="modal-content" style="height: 100%">
                    <div class="modal-header">

                        <div class="row d-flex align-items-center px-3">
                            <h5 class="modal-title font-weight-bolder mr-5" id="CrewBiodataTitle">MLC - OLC
                                CHECKLISTS</h5>
                            <button type="button" class="btn btn-success btn-sm px-3 font-weight-normal"
                                    @click="loadCcmMasterChecklist($globalVariables.ccmMaster.mlc_olc, documentChecklist.crewChecklist)"
                            >
                                Print
                            </button>
                        </div>

                        <button class="btn btn-sm ml-2" @click="closeViewChecklistMLCOLCModal" aria-label="Close">
                            <font-awesome-icon icon="times" class="text-danger"/>
                            Close
                        </button>
                    </div>
                    <div class="modal-body" style="overflow-x: hidden !important; overflow-y: scroll;  height: 700px">


                        <ccm-master-checklist-mlcolc-modal-body
                                :ccm-master-checklist="ccmMasterChecklist"
                        />

                    </div>
                    <div class="modal-footer">
                        <table style="width:100%;">
                            <tbody>
                            <tr>
                                <td><span style="font-weight:bold;">Legend : * - With one higher license</span></td>

                                <td><span
                                        style="text-align:right;background-color: Gray; color:white;"><b>Non Mandatory</b></span>
                                </td>

                                <td><span
                                        style="text-align:right;background-color: Red;color:white;"><b>	No Documents / Expired / No File Uploaded / Old Scanned Docs or Certs is Uploaded</b></span>
                                </td>
                                <td>
                                </td>


                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>


        <!-- VIEW CHECKLIST DOCUMENT SIGNON -->
        <div class="modal fade" id="view-checklist-document-signon" tabindex="-1" role="dialog"
             aria-labelledby="setupCcPlanCenter"
             aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-size" role="document">
                <!--      <div class="modal-dialog modal-dialog-centered full-screen-modal2" role="document">-->
                <div class="modal-content" style="height: 120%">
                    <div class="modal-header">

                        <div class="row d-flex align-items-center px-3">
                            <h5 class="modal-title font-weight-bolder mr-5" id="CrewBiodataTitle">View Checklist
                                Document</h5>
                            <button type="button" class="btn btn-success btn-sm px-3 font-weight-normal"
                                    @click="loadCcmMasterChecklist($globalVariables.ccmMaster.checklistDoc, documentChecklist.crewChecklist)"
                            >
                                Print
                            </button>

                            <div class="form-group mb-2 ml-3">
                                <select class="form-control form-control-sm"
                                        v-model="ccmMasterChecklistParams.signatory">
                                    <option value="null">-- checked by ---</option>
                                    <option :value="signatory"
                                            v-for="(signatory,index) in $globalVariables.ccm_master_checklist_signatories"
                                            :key="signatory.id">
                                        {{ signatory.name }}
                                    </option>
                                </select>
                            </div>

                        </div>


                        <button class="btn btn-sm ml-2" @click="closeViewChecklistDocumentModal()" aria-label="Close">
                            <font-awesome-icon icon="times" class="text-danger"/>
                            Close
                        </button>
                    </div>
                    <div class="modal-body" style="overflow-x: hidden !important; overflow-y: scroll;  height: 700px">

                        <ccm-master-checklist-document-modal-body
                                :loading="isReloading"
                                :ccm-master-checklist="ccmMasterChecklist"
                                @refresh-ccm-checklist="refreshGetCcmMasterChecklistDetails"
                        />

                    </div>
                    <div class="modal-footer">
                        <table style="width:100%;">
                            <tbody>
                            <tr>
                                <td><span style="font-weight:bold;">Legend : * - With one higher license</span></td>
                                <td>
                                </td>
                                <td><span
                                        style="text-align:right;background-color: Gray; color:white;"><b>Non Mandatory</b></span>
                                </td>
                                <td>
                                </td>
                                <td><span style="text-align:right;background-color: Yellow"><b>Near Expiry</b></span>
                                </td>
                                <td>
                                </td>
                                <td><span style="text-align:right;background-color: #F5761A;color:Black;"><b>No File Upload</b></span>
                                </td>
                                <td>
                                </td>
                                <td><span
                                        style="text-align:right;background-color: Red;color:white;"><b>Expired Documents</b></span>
                                </td>
                                <td>
                                </td>
                                <td><span
                                        style="text-align:right;background-color:Purple;color:white"><b>Mismatch Document No.2 Detail</b></span>
                                </td>
                                <td>
                                </td>
                                <td><span
                                        style="text-align:right;background-color:Black;color:white"><b>Mismatch Document Rank</b></span>
                                </td>
                                <td>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>


        <!-- VIEW CHECKLIST DOC CERT MONITORING SIGNON -->
        <div class="modal fade" id="view-checklist-doc-cert-monitoring-signon" tabindex="-1" role="dialog"
             aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-size" role="document">
                <div class="modal-content" style="height: 50%">
                    <div class="modal-header">

                        <div class="row d-flex align-items-center px-3">
                            <h5 class="modal-title font-weight-bolder mr-5" id="CrewBiodataTitle">Checklists Documents &
                                Certificates</h5>
                            <button type="button" class="btn btn-success btn-sm px-3 font-weight-normal"
                                    @click="loadCcmMasterChecklist($globalVariables.ccmMaster.docCertMonitoring, documentChecklist.crewChecklist)"
                            >
                                Print
                            </button>
                        </div>

                        <button class="btn btn-sm ml-2" @click="closeViewChecklistDocCertMonitoringModal"
                                aria-label="Close">
                            <font-awesome-icon icon="times" class="text-danger"/>
                            Close
                        </button>
                    </div>
                    <div class="modal-body" style="overflow-x: hidden !important; overflow-y: scroll;  height: 700px">


                        <ccm-master-checklist-doc-cert-monitoring-modal-body
                                :ccm-master-checklist="ccmMasterChecklist"
                        />

                    </div>
                    <div class="modal-footer">
                        <table style="width:100%;font-size:10pt;float:left;">
                            <tbody>
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td style="font-weight:bold;">Legend :</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td><b>ND</b> - No Document / Record.</td>
                                <td><b>D</b> - Scheduled to disembark</td>
                                <td><b>NR</b> - Not Required</td>
                                <td><b>R</b> - Released</td>
                                <td><b>FA</b> - For application / renewal / revalidation</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td><b>FC1</b> - Received first deficiency letter</td>
                                <td><b>FC2</b> - Received second deficiency letter</td>
                                <td><b>AR</b> - Awaiting for released</td>
                                <td><b>C</b> - Deficiency complied</td>
                                <td><b>FD</b> - For application upon Disembarkation</td>
                            </tr>

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>


        <!-- generating pdf loading modal -->
        <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- CC memo details print preview  -->
        <div class="modal fade" id="preview-print-cc-memo" tabindex="-1" role="dialog"
             aria-labelledby="previewPrintCcMemoLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="preview-print-cc-memo-title">Preview Crew Change Memo Details</h5>
                        <button type="button" class="close" @click="closePreviewPrintCcMemo">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="cc-details-file-container"></div>
                        <hr class="mt-1 mb-1">
                        <div class="row">
                            <div class="col-3">

                                <!-- Prepared By -->
                                <div class="form-group row text-left">
                                    <label class="col-form-label col-sm-5">Prepared by</label>
                                    <span class="col-form-label">:</span>
                                    <div class="col-sm-12">
                                        <select name="preparedBy"
                                                id="prepared-by"
                                                v-model="formApprovalSignatories.prepBy"
                                                class="form-control form-control-sm"
                                                :class="[formApprovalSignatories.prepBy ? 'has-select-signatory' : '']"
                                                @change="onChangeSignatory">
                                            <option :value="formApprovalSignatories.prepBy"
                                                    v-if="formApprovalSignatories.prepBy"
                                                    selected
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.prepBy.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <!--   Checked By -->
                                <!--                <div class="form-group row text-left mt-2">-->
                                <!--                  <label class="col-form-label col-sm-5">Checked By</label>-->
                                <!--                  <span class="col-form-label">:</span>-->
                                <!--                  <div class="col-sm-12">-->
                                <!--                    <select name="checkedBy"-->
                                <!--                            id="checked-by"-->
                                <!--                            v-model="formApprovalSignatories.checkBy"-->
                                <!--                            :class="[formApprovalSignatories.checkBy ? 'has-select-signatory' : '']"-->
                                <!--                            @change="onChangeSignatory"-->
                                <!--                            class="form-control form-control-sm">-->
                                <!--                      <option :value="formApprovalSignatories.checkBy"-->
                                <!--                              v-if="formApprovalSignatories.checkBy"-->
                                <!--                              selected-->
                                <!--                              class="text-left align-middle">-->
                                <!--                        <span>{{ formApprovalSignatories.checkBy.name }}</span>-->
                                <!--                      </option>-->
                                <!--                      <option :value="null" class="text-center align-middle">&#45;&#45;SELECTED&#45;&#45;</option>-->
                                <!--                      <option :value="emp"-->
                                <!--                              v-for="(emp,index) in objEmployees"-->
                                <!--                              :key="emp.id"-->
                                <!--                              style="font-size: 0.8rem; display: block"-->
                                <!--                              class="text-left align-middle">-->
                                <!--                        <span>{{ emp.name }}</span>-->
                                <!--                      </option>-->
                                <!--                    </select>-->


                                <!--                    <select name="checkedBy2"-->
                                <!--                            id="checked-by-2"-->
                                <!--                            v-model="formApprovalSignatories.checkBy2"-->
                                <!--                            :class="[formApprovalSignatories.checkBy2 ? 'has-select-signatory' : '']"-->
                                <!--                            @change="onChangeSignatory"-->
                                <!--                            class="form-control form-control-sm mt-1">-->
                                <!--                      <option :value="formApprovalSignatories.checkBy2"-->
                                <!--                              v-if="formApprovalSignatories.checkBy2"-->
                                <!--                              selected-->
                                <!--                              class="text-left align-middle">-->
                                <!--                        <span>{{ formApprovalSignatories.checkBy2.name }}</span>-->
                                <!--                      </option>-->
                                <!--                      <option :value="null" class="text-center align-middle">&#45;&#45;SELECTED&#45;&#45;</option>-->
                                <!--                      <option :value="emp"-->
                                <!--                              v-for="(emp,index) in objEmployees"-->
                                <!--                              :key="emp.id"-->
                                <!--                              style="font-size: 0.8rem; display: block"-->
                                <!--                              class="text-left align-middle">-->
                                <!--                        <span>{{ emp.name }}</span>-->
                                <!--                      </option>-->
                                <!--                    </select>-->
                                <!--                  </div>-->
                                <!--                </div>-->
                            </div>

                            <div class="col-3">

                                <!-- Noted by -->
                                <div class="form-group row text-left">
                                    <label class="col-form-label col-sm-5">Noted By</label>
                                    <span class="col-form-label">:</span>
                                    <div class="col-sm-12">
                                        <select name="notedBy"
                                                @change="onChangeSignatory"
                                                v-model="formApprovalSignatories.notedBy"
                                                :class="[formApprovalSignatories.notedBy ? 'has-select-signatory' : '']"
                                                class="form-control form-control-sm">
                                            <option :value="formApprovalSignatories.notedBy"
                                                    v-if="formApprovalSignatories.notedBy"
                                                    selected
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedBy.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>

                                        <select name="notedBy2"
                                                @change="onChangeSignatory"
                                                v-model="formApprovalSignatories.notedBy2"
                                                :class="[formApprovalSignatories.notedBy2 ? 'has-select-signatory' : '']"
                                                class="form-control form-control-sm mt-1">
                                            <option :value="formApprovalSignatories.notedBy2"
                                                    v-if="formApprovalSignatories.notedBy2"
                                                    selected
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedBy2.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>

                                        <select name="notedBy3"
                                                @change="onChangeSignatory"
                                                v-model="formApprovalSignatories.notedBy3"
                                                :class="[formApprovalSignatories.notedBy3 ? 'has-select-signatory' : '']"
                                                class="form-control form-control-sm mt-1">
                                            <option :value="formApprovalSignatories.notedBy3"
                                                    v-if="formApprovalSignatories.notedBy3"
                                                    selected
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedBy3.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>

                                        <select name="notedBy4"
                                                @change="onChangeSignatory"
                                                v-model="formApprovalSignatories.notedBy4"
                                                :class="[formApprovalSignatories.notedBy4 ? 'has-select-signatory' : '']"
                                                class="form-control form-control-sm mt-1">
                                            <option v-if="formApprovalSignatories.notedBy4"
                                                    :value="formApprovalSignatories.notedBy4"
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedBy4.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3">

                                <!-- Reviewed By -->
                                <div class="form-group row text-left">
                                    <label class="col-form-label col-sm-5">Reviewed By</label>
                                    <span class="col-form-label">:</span>
                                    <div class="col-sm-12">
                                        <select name="reviewedBy"
                                                id="reviewed-by"
                                                v-model="formApprovalSignatories.reviewedBy"
                                                :class="[formApprovalSignatories.reviewedBy ? 'has-select-signatory' : '']"
                                                @change="onChangeSignatory"
                                                class="form-control form-control-sm">
                                            <option :value="formApprovalSignatories.reviewedBy"
                                                    v-if="formApprovalSignatories.reviewedBy"
                                                    selected
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.reviewedBy.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>
                                    </div>

                                    <!--  TODO review by 2 s -->
                                    <div class="col-sm-12 mt-1">
                                        <select name="reviewedBy2"
                                                id="reviewed-by-2"
                                                v-model="formApprovalSignatories.reviewedBy2"
                                                :class="[formApprovalSignatories.reviewedBy2 ? 'has-select-signatory' : '']"
                                                @change="onChangeSignatory"
                                                class="form-control form-control-sm">
                                            <option :value="formApprovalSignatories.reviewedBy2"
                                                    v-if="formApprovalSignatories.reviewedBy2"
                                                    selected
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.reviewedBy2.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>
                                    </div>

                                    <!--  TODO review 3  -->
                                    <div class="col-sm-12 mt-1">
                                        <select name="reviewedBy3"
                                                id="reviewed-by-3"
                                                v-model="formApprovalSignatories.reviewedBy3"
                                                :class="[formApprovalSignatories.reviewedBy3 ? 'has-select-signatory' : '']"
                                                @change="onChangeSignatory"
                                                class="form-control form-control-sm">
                                            <option :value="formApprovalSignatories.reviewedBy3"
                                                    v-if="formApprovalSignatories.reviewedBy3"
                                                    selected
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.reviewedBy3.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-3">
                                <!-- Noted and Approved By -->
                                <div class="form-group row text-left">
                                    <label class="col-form-label col-sm-8">Noted and Approved By</label>
                                    <span class="col-form-label">:</span>
                                    <div class="col-sm-12">
                                        <select name="notedApprovedBy"
                                                @change="onChangeSignatory"
                                                v-model="formApprovalSignatories.notedAprBy"
                                                :class="[formApprovalSignatories.notedAprBy ? 'has-select-signatory' : '']"
                                                class="form-control form-control-sm">
                                            <option :value="formApprovalSignatories.notedAprBy"
                                                    v-if="formApprovalSignatories.notedAprBy"
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedAprBy.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>

                                        <select name="notedApprovedBy2"
                                                @change="onChangeSignatory"
                                                v-model="formApprovalSignatories.notedAprBy2"
                                                :class="[formApprovalSignatories.notedAprBy2 ? 'has-select-signatory' : '']"
                                                class="form-control form-control-sm mt-1">
                                            <option v-if="formApprovalSignatories.notedAprBy2"
                                                    :value="formApprovalSignatories.notedAprBy2"
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedAprBy2.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>



                                        <select name="notedApprovedBy3"
                                                @change="onChangeSignatory"
                                                v-model="formApprovalSignatories.notedAprBy3"
                                                :class="[formApprovalSignatories.notedAprBy3 ? 'has-select-signatory' : '']"
                                                class="form-control form-control-sm mt-1">
                                            <option v-if="formApprovalSignatories.notedAprBy3"
                                                    :value="formApprovalSignatories.notedAprBy3"
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedAprBy3.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>

                                        <select name="notedApprovedBy4"
                                                @change="onChangeSignatory"
                                                v-model="formApprovalSignatories.notedAprBy4"
                                                :class="[formApprovalSignatories.notedAprBy4 ? 'has-select-signatory' : '']"
                                                class="form-control form-control-sm mt-1">
                                            <option v-if="formApprovalSignatories.notedAprBy4"
                                                    :value="formApprovalSignatories.notedAprBy4"
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedAprBy4.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>

                                        <select name="notedApprovedBy5"
                                                @change="onChangeSignatory"
                                                v-model="formApprovalSignatories.notedAprBy5"
                                                :class="[formApprovalSignatories.notedAprBy5 ? 'has-select-signatory' : '']"
                                                class="form-control form-control-sm mt-1">
                                            <option :value="formApprovalSignatories.notedAprBy5"
                                                    v-if="formApprovalSignatories.notedAprBy5"
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedAprBy5.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>

                                        <select name="notedApprovedBy6"
                                                @change="onChangeSignatory"
                                                v-model="formApprovalSignatories.notedAprBy6"
                                                :class="[formApprovalSignatories.notedAprBy6 ? 'has-select-signatory' : '']"
                                                class="form-control form-control-sm mt-1">
                                            <option :value="formApprovalSignatories.notedAprBy6"
                                                    class="text-left align-middle"
                                                    v-if="formApprovalSignatories.notedAprBy6">
                                                <span>{{ formApprovalSignatories.notedAprBy6.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>

                                        <select name="notedApprovedBy7"
                                                @change="onChangeSignatory"
                                                :class="[formApprovalSignatories.notedAprBy7 ? 'has-select-signatory' : '']"
                                                v-model="formApprovalSignatories.notedAprBy7"
                                                class="form-control form-control-sm mt-1">
                                            <option :value="formApprovalSignatories.notedAprBy7"
                                                    v-if="formApprovalSignatories.notedAprBy7"
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedAprBy7.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED--</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>

                                        <select name="notedApprovedBy8"
                                                @change="onChangeSignatory"
                                                :class="[formApprovalSignatories.notedAprBy8 ? 'has-select-signatory' : '']"
                                                v-model="formApprovalSignatories.notedAprBy8"
                                                class="form-control form-control-sm mt-1">
                                            <option :value="formApprovalSignatories.notedAprBy8"
                                                    v-if="formApprovalSignatories.notedAprBy8"
                                                    class="text-left align-middle">
                                                <span>{{ formApprovalSignatories.notedAprBy8.name }}</span>
                                            </option>
                                            <option :value="null" class="text-center align-middle">--SELECTED</option>
                                            <option :value="emp"
                                                    v-for="(emp,index) in objEmployees"
                                                    :key="emp.id"
                                                    style="font-size: 0.8rem; display: block"
                                                    class="text-left align-middle">
                                                <span>{{ emp.name }}</span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!--<div class="modal-footer">
											<button type="button" class="btn btn-primary" @click="downloadCcMemo">Download</button>
										</div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {AlertMixins} from '@/mixins/alert.mixins';
import {AppMixins} from '@/mixins/app.mixins';
//import crew from "@/views/masterlist/Crew";
import {mapActions, mapGetters} from 'vuex';
import Vue from 'vue';
//import axios from "axios";
//import {PMC} from "../../../../config/constants.config";
//import {UtilityService} from "../../../../service/utility.service";
//import {FileService} from "@/service/file.service";
import {global_variables_config} from '@/config/global-variable.config';
//import Swal from "sweetalert2";
//import Button from "@/components/base/Button";
// import CcMasterlistChecklist from "@/components/modules/crewchange/_sub/master_checklist/CcmMasterChecklist";
// import CcMasterlistChecklistMLCOLC from "@/components/modules/crewchange/_sub/master_checklist/CcmMasterChecklistMLCOLC";
// import CcMasterlistChecklistDocument from "@/components/modules/crewchange/_sub/master_checklist/CcmMasterChecklistDocument";
// import CcMasterlistChecklistDocCertMonitoring from "@/components/modules/crewchange/_sub/master_checklist/CcmMasterChecklistDocCertMonitoring";
import Swal from 'sweetalert2';
import {AlertService} from '@/service/alert.service';
import Table from '@/components/base/Table.vue';
import _ from "lodash";

export default {
  name: 'CrewChangeMemo',

  /**
   *@author move custom component in component.config.js redo if the code not working accordingly.
   */
  components: {
    Table,
    // Button,
    // Pagination,
    // 'ccm-master-checklist-modal-body': CcMasterlistChecklist,
    // 'ccm-master-checklist-mlcolc-modal-body': CcMasterlistChecklistMLCOLC,
    // 'ccm-master-checklist-document-modal-body': CcMasterlistChecklistDocument,
    // 'ccm-master-checklist-doc-cert-monitoring-modal-body': CcMasterlistChecklistDocCertMonitoring,
  },
  mixins: [AlertMixins, AppMixins],

  props: {
    crews: {
      type: Array,
      default: [],
    },

      /*loadData: {
       type: Boolean,
       default: false,
       },*/

    bus: {
      type: Vue,
    },

    // crewBatchId : {
    //   type : Number,
    //   default : 0,
    // },

    vesselId: {
      type: Number,
      default: 0,
    },
  },

  /** data **/
  data() {
    return {
      ccOnResHeight: 130,
      ccOffResHeight: 130,
      isCcMemoEditable: false,
      // deletedCcIds : [],
      formCcMemoRemarks: {},
      documentChecklist: {
        poeaContract: null,
        ITF: null,
        infoSheet: null,
        rps: null,
        rpsAmendment: null,
        crewChecklist: null,
        crewName: null,
      },

      ccmDocsParams: {
        crewId: null,
        vesselId: null,
        ccmDocument: null,
        signatory: null,
        contract: 9,
        printDate: new Date().toISOString().slice(0, 10),
        remarks: '',
        page: 1,
      },

      ccmMasterChecklistParams: {
        crewId: null,
        ccmMasterChecklist: null,
        vesselId: null,
        batchId: null,
        isSignOnCrews: 1,
        signatory: null,
        ccmNo: null,
      },

      crewBatchId: null,
      crewBatchNo: null,

      formApprovalSignatories: {
        prepBy: null,
        checkBy: null,
        checkBy2: null,
        reviewedBy: null,
        reviewedBy2: null,
        reviewedBy3: null,
        notedBy: null,
        notedBy2: null,
        notedBy3: null,
        notedBy4: null,
        notedAprBy: null,
        notedAprBy2: null,
        notedAprBy3: null,
        notedAprBy4: null,
        notedAprBy5: null,
        notedAprBy6: null,
        notedAprBy7: null,
        notedAprBy8: null,
      },

      hasBatchSignOnListFinal : [],
      noBatchSignOnListFinal : [],
      ccmVesselEcdisName: null,

    };
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          // 'vesselDetails',

          'crewChangeMemo',
          'isCcMemo',

          'ccMemoRemarks',
          'ccMemoRemark',
          'newCcMemoRemark',

          'isBatchListing',
          'noBatchSignOnList',
          'hasBatchSignOnList',
          'addInBatch',
          'removeInBatch',

          // ccm master checklist
          'ccmMasterChecklist',
          'ccmFlagId',
          'ccmFlagName',
          'ccmVesselId',
          'ccmVesselEcdisId',

          'isReloading',

          'ccMemoDetailFile',
          'objEmployees',
        ],
    ),
  },

  /** methods**/
  methods: {
    ...mapActions(
        [
          'reset_cc_memo_state',
          'update_crew_change_remark',
          'delete_crew_change_remark',
          'generate_cc_memo_details',
          'get_cc_memo_details',
          'create_cc_memo_details',

          //batching remark
          // 'set_batching_remark', ,
          // 'create_batching_remarks',
          // 'update_batching_remarks',
          // 'delete_batching_remark',

          //new batching remarks
          'set_cc_memo_remark',
          'create_cc_memo_remarks',
          'update_cc_memo_remarks',
          'delete_cc_memo_remarks',

          'set_null_cc_memo',

          'reset_null_batch_list_state',
          'set_update_cc_memo',
          'update_cc_memo_details',

          'add_in_batch',
          'remove_in_batch',

          'confirm_add_batch_memo',
          'confirm_delete_batch_memo',

          'set_null_add_in_batch',
          'set_null_remove_in_batch',
          'reset_cc_memo_remark_state',

          // generate ccm document ex. ITF, RPS, INFO SHEET
          'generateCcmDocument',

          // generate ccm masterlist ex. view checklist, mlc olc, checklist docs
          'generateCcmMasterChecklist',
          'getCcmMasterChecklistDetails',

          'preview_print_cc_memo',
          'download_cc_memo',
          'null_obj_employee',

          'revision_up_cc_memo',
          'revision_down_cc_memo',
          'approved_cc_memo',
          'dis_approved_cc_memo',

          //generate covid vaccine report
          'generateCcMemoCovidVaccineReport',
        ],
    ),

    /**
     * @param args collection | array
     * @param type 1: signOnCrew , 2:signOff
     */
    updateTableRowSize(args, type) {

      if (args !== undefined) {
        if (args.length >= 2) {
          if (type === 1) {
            this.ccOnResHeight = 160;

          } else {
            this.ccOffResHeight = 160;
          }
        }

        if (args.length >= 3) {
          if (type === 1) {
            this.ccOnResHeight = 180;
          } else {
            this.ccOffResHeight = 180;
          }
        }

        if (args.length >= 5) {
          if (type === 1) {
            this.ccOnResHeight = 200;
          } else {
            this.ccOffResHeight = 200;
          }
        }

        if (args.length >= 7) {
          if (type === 1) {
            this.ccOnResHeight = 220;
          } else {
            this.ccOffResHeight = 220;
          }
        }

        if (args.length >= 9) {
          if (type === 1) {
            this.ccOnResHeight = 240;
          } else {
            this.ccOffResHeight = 240;
          }
        }
      }
    },

    /** crew change memo function **/
    generateCcMemoDetails(e) {
      if (this.vesselId != 0) {
        let formData = new FormData();
        formData.append('selectedCrews', JSON.stringify(this.crews));
        formData.append('vesselId', this.vesselId);
        this.generate_cc_memo_details(formData);
      }
    },

    // editCcMemo(){
    //   this.isCcMemoEditable = true;
    // },
    //
    // cancelCcMemo(){
    //   this.isCcMemoEditable = false;
    // },

    fileContainer(fileScr) {
      //#toolbar=0
      let fileFrame = `<iframe src="${fileScr}" id="cc-details-file-frame" style="height: 400px; width: 100%;" frameborder="0"></iframe>`;
      $(`#cc-details-file-container`).append(fileFrame);
    },

    async previewPrintCcMemo() {
      const formData = {
        vesselId: this.vesselId,
        batchId: this.crewBatchId,
        isDownload: false,
      };
      console.log(formData);
      $(`#preview-print-cc-memo`).modal(
          {
            backdrop: 'static',
            keyboard: false,
          },
      );

      let loading = `<div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>`;

      $(`#cc-details-file-container`).append(loading);

      this.$nextTick(() => {
        this.preview_print_cc_memo(formData).then((r) => {
          if (r) {
            $(`#cc-details-file-container`).empty();
            let fileScr = this.base64PdfParser(r);
            this.fileContainer(fileScr);
          }
        });
      });
    },

    previewPrintCcMemoNewWindow() {
      let batchId = this.crewBatchId;
      let vesselId = this.vesselId;
      let routeData = this.$router.resolve({ //
        name: 'PreviewPrintCCMemo',
        params: {
          batchId: `${batchId}`,
          vesselId: `${vesselId}`,
        },
      });
      window.open(routeData.href, '_blank',"height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));
    },

    //TODO downloadCcMemo
    async downloadCcMemo() {
      let objSignatories = this.formApprovalSignatories;
      let objectProp = Object.values(objSignatories).every(value => {
        if (value == null) {
          return true;
        }
        return false;
      });

      const formData = {
        vesselId: this.vesselId,
        batchId: this.crewBatchId,
        isDownload: true,
      };

      if (!objectProp) {
        formData.formApprovalSignatories = this.formApprovalSignatories;
      }

      if (await this.questionAlert('Do you want to download')) {
        this.download_cc_memo(formData).then((r) => {
          if (r) {
            this.successAlert('Download Crew Change Memo Details Successfully');
          }
        });
      }
    },

    closePreviewPrintCcMemo() {
      //Close something , need to learn.
      $(`#cc-details-file-container`).empty();
      $(`#preview-print-cc-memo`).modal('hide');
      this.null_obj_employee();
      this.formApprovalSignatories = {
        prepBy: null,
        checkBy: null,
        checkBy2: null,
        reviewedBy: null,
        reviewedBy2: null,
        reviewedBy3: null,
        notedBy: null,
        notedBy2: null,
        notedBy3: null,
        notedBy4: null,
        notedAprBy: null,
        notedAprBy2: null,
        notedAprBy3: null,
        notedAprBy4: null,
        notedAprBy5: null,
        notedAprBy6: null,
        notedAprBy7: null,
        notedAprBy8: null,
      };
      const formData = {
        vesselId: this.vesselId,
        batchId: this.crewBatchId,
        isDownload: false,
      };
      //this.preview_print_cc_memo(formData).then((r) => {});
    },

    onChangeSignatory() {
      const formData = {
        vesselId: this.vesselId,
        batchId: this.crewBatchId,
        formApprovalSignatories: this.formApprovalSignatories,
        isDownload: false,
      };
      $(`#cc-details-file-container`).empty();

      let loading = `<div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>`;

      $(`#cc-details-file-container`).append(loading);

      this.preview_print_cc_memo(formData).then((r) => {
        if (r) {
          $(`#cc-details-file-container`).empty();
          let fileScr = this.base64PdfParser(r);
          this.fileContainer(fileScr);
        }
      });
    },

    setUpdateCcMemo() {
      $(`#no-batch-list-modal`).modal({
        backdrop: 'static',
        keyboard: false,
      });

      const formData = {
        vesselId: this.vesselId,
        batchId: this.crewChangeMemo.ccMemoId,
      };

      this.set_update_cc_memo(formData).then((r) => {
        this.$nextTick(() => {
          this.hasBatchSignOnListFinal = [];
          this.noBatchSignOnListFinal = [];

          this.noBatchSignOnList.forEach((noBatchData) => {
            this.noBatchSignOnListFinal.push(noBatchData);
          });
          this.hasBatchSignOnList.forEach((hasBatchData) => {
            this.hasBatchSignOnListFinal.push(hasBatchData);
          });
        });
      });
    },

    setAddInBatch(e, thisObj) {
      if (e.target.checked) {
        const formData = {
          type: 1,
          obj: thisObj,
        };
        this.add_in_batch(formData);

      } else {
        const formData = {
          type: 2,
          obj: thisObj,
        };
        this.add_in_batch(formData);
      }
    },

    setRemoveInBatch(e, thisObj) {
      if (e.target.checked) {
        const formData = {
          type: 1,
          obj: thisObj,
        };
        this.remove_in_batch(formData);
      } else {
        const formData = {
          type: 2,
          obj: thisObj,
        };
        this.remove_in_batch(formData);
      }
    },

    async confirmAddBatchMemo() {
      let addedBatches = [];

      this.addInBatch.forEach((removedBatch) => {
          addedBatches.push(removedBatch);
      });

      let msg = `Do you want to the changes do you made`;

      if (await this.questionAlert(`${msg}`, 'Confirm Add to Batch')) {
        const formData = {
          batchId: this.crewChangeMemo.ccMemoId,
          ccRelations: this.addInBatch,
          vesselId: this.vesselId,
        };
        this.confirm_add_batch_memo(formData).then((r) => {
          if (r) {
            this.$nextTick(() => {

              this.set_null_add_in_batch();
              this.successAlert('Adding to batch successfully.');

              let addedCrewOnId = [];
              let addedCrew = [];

              addedBatches.forEach((addedBatch) => {
                addedBatch.checked = false;
                this.hasBatchSignOnListFinal.push(addedBatch);
                addedCrewOnId.push(addedBatch.ccrId);
                  addedCrew.push(addedBatch);
              });

              this.noBatchSignOnListFinal = this.noBatchSignOnListFinal.filter((noBatch) => {
                  return addedCrewOnId.includes(noBatch.ccrId) === false;
              });

              this.hasBatchSignOnListFinal = _.orderBy(
                  this.hasBatchSignOnListFinal, ['ranking'], ['asc']
              );

              addedCrew.forEach((crew) => {
                  const formData = {
                      type: 2,
                      obj: crew,
                  };
                  this.add_in_batch(formData);
              });

            });
          }
        });
      }
    },

    async confirmDeleteBatchMemo() {
      let removedBatches = [];

      this.removeInBatch.forEach((removedBatch) => {
          removedBatches.push(removedBatch);
      });

      let msg = `Do you want to the changes do you made`;

      if (await this.questionAlert(`${msg}`, 'Confirm Remove to Batch')) {
        const formData = {
          batchId: this.crewChangeMemo.ccMemoId,
          ccRelations: this.removeInBatch,
          vesselId: this.vesselId,
        };
        this.confirm_delete_batch_memo(formData).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert('Removing to batch successfully');
              $(`input[id='has-batch-action']`).each(function() {
                this.checked = false;
              });

              this.set_null_remove_in_batch();

              if (this.hasBatchSignOnList.length === 0) {
                this.set_null_cc_memo();
                this.reset_null_batch_list_state();
                $(`#no-batch-list-modal`).modal('hide');
                this.bus.$emit('closeCcMemoModal');

              } else {
                this.get_cc_memo_details(
                    {
                      batchId: this.crewChangeMemo.ccMemoId,
                      vesselId: this.vesselId,
                    },
                );
              }

              let removedCrewOnId = [];

              removedBatches.forEach((removedBatch) => {
                  removedBatch.checked = false;
                  this.noBatchSignOnListFinal.push(removedBatch);
                  removedCrewOnId.push(removedBatch.ccrId);
              });

                this.hasBatchSignOnListFinal = this.hasBatchSignOnListFinal.filter((hasBatch) => {
                    return removedCrewOnId.includes(hasBatch.ccrId) === false;
                });

              this.noBatchSignOnListFinal = _.orderBy(
                this.noBatchSignOnListFinal, ['ranking'], ['asc']
              );

            });
          }
        });
      }
    },

    // async updateCcMemoDetails() {
    //   let msg = `<span>Do you want to save change's that you made</span>`
    //   if (await this.questionAlert(msg)) {
    //     this.update_cc_memo_details().then((r) => {
    //       //TODO close modal
    //       //TODO call the get Change plan service.
    //       this.$nextTick(()=>{
    //       })
    //     })
    //   }
    // },

    closeNoBatchList() {
      $(`#no-batch-list-modal`).modal('hide');
      this.reset_null_batch_list_state();
    },

    //save cc memo without batching details
    async saveCcMemo() {
      let msg = `Do you want to save the changes that you made?`;
      if (await this.questionAlert(`${msg}`, 'Save Crew Change Memo')) {
        let thisCcRelIds = [];

        if (this.crewChangeMemo.signOnCrews !== undefined) {
          this.crewChangeMemo.signOnCrews.forEach((val, index) => {
            thisCcRelIds.push(val.ccrId); //push crew relation id,
          });
        }

        const params = {
          ccRelationIds: thisCcRelIds,
          ccMemoVesselId: this.crewChangeMemo.ccMemoVesselId,
          ccMemoRemarks: this.ccMemoRemarks,
          ccMemoId: this.crewChangeMemo.ccMemoId,
        };

        //not having a ccMemoId
        if (!this.crewChangeMemo.ccMemoId) {
          await this.create_cc_memo_details(params).then((r) => {
            if (r) {
              this.$nextTick(() => {
                this.successAlert('CC Memo successfully created.');
              });
            }
          });
        } else {
        }
      }
    },

    async revisionUpCcMemo(objCcMemo) {
      console.log(objCcMemo);
      let ccMemoId = objCcMemo.ccMemoId;
      if (await this.questionAlert('Do you want to revision up this crew change memo.?', 'Revision up')) {
        this.revision_up_cc_memo(ccMemoId).finally(() => {
          this.$nextTick(() => {
            this.successAlert('Revision up successfully', 'Revision Up');
          });
        });
      }
    },

    async revisionDownCcMemo(objCcMemo) {
      let ccMemoId = objCcMemo.ccMemoId;
      if (await this.questionAlert('Do you want to revision down this crew change memo.?', 'Revision down')) {
        this.revision_down_cc_memo(ccMemoId).finally(() => {
          this.$nextTick(() => {
            this.successAlert('Revision down successfully', 'Revision down');
          });
        });
      }
    },

    async approvedCcMemo(objCcMemo) {
      console.log(objCcMemo);
      let ccMemoId = objCcMemo.ccMemoId;
      if (await this.questionAlert('Do you want to approve this crew change memo.?', 'Approve')) {
        this.approved_cc_memo(ccMemoId).finally(() => {
          // console.log('approvedCcMemo:finally',f
          this.successAlert('Approve successfully');
        });
      }
    },

    async disApprovedCcMemo(objCcMemo) {
      console.log(objCcMemo);
      let ccMemoId = objCcMemo.ccMemoId;
      if (await this.questionAlert('Do you want to approve this crew change memo', 'Approve')) {
        this.dis_approved_cc_memo(ccMemoId).finally(() => {
          // console.log("disApprovedCcMemo:finally",f);
          this.successAlert('Disapprove successfully');
        });
      }
    },

    viewCovidRecords() {
      //TODO goto covid vaccine routes .
    },

    /** end of crew change memo function methods **/

    /** start of batching remarks actions **/
    closeAddRemarks() {
      $(`#add-ccmemo-remarks-modal`).modal('hide');
    },

    closeDocumentListModal() {
      $(`#documentListModal`).modal('hide');
    },

    closeMasterCheckListModal() {
      $(`#checklist-reports`).modal('hide');
    },

    closeViewChecklistModal() {
      $(`#view-checklist-signon`).modal('hide');
    },

    closeViewChecklistMLCOLCModal() {
      $(`#view-checklist-mlcolc-signon`).modal('hide');
    },

    closeViewChecklistDocumentModal() {
      $(`#view-checklist-document-signon`).modal('hide');
    },

    closeViewChecklistDocCertMonitoringModal() {
      $(`#view-checklist-doc-cert-monitoring-signon`).modal('hide');
    },

    closeViewCovidModal() {
      $(`#covid-vaccine-record`).modal('hide');
    },

    clearRemarks() {
      this.newCcMemoRemark.remarks = '';
    },

    cancelRemarks() {
      this.closeAddRemarks();
    },

    async saveCcMemoRemarks() {
      let msg = `Do you want to save the inputed remarks?`;
      if (await this.questionAlert(`${msg}`, 'Add Remarks')) {
        this.newCcMemoRemark.ccMemoId = this.crewChangeMemo.ccMemoId;
        this.create_cc_memo_remarks(this.newCcMemoRemark).then((r) => {
          if (r) {
            this.successAlert('Remarks add successfully', 'New Remarks');
          }
        });
      }
    },

    setEditCcMemoRemarks(ccMemoRemark, index) {
      $(`#ccm-remarks-${index}`).attr('disabled', false);
      $(`#ccm-remarks-${index}`).focus();

      $(`#btn-ccmemo-remark-update-${index}`).attr('hidden', false);
      $(`#btn-ccmemo-remark-cancel-${index}`).attr('hidden', false);

      $(`#btn-ccmemo-remark-delete-${index}`).attr('hidden', true);
      $(`#btn-ccmemo-remark-edit-${index}`).attr('hidden', true);
    },

    setCancelCcMemoRemark(ccMemoRemark, index) {
      $(`#ccm-remarks-${index}`).attr('disabled', true);

      $(`#btn-ccmemo-remark-update-${index}`).attr('hidden', true);
      $(`#btn-ccmemo-remark-cancel-${index}`).attr('hidden', true);

      $(`#btn-ccmemo-remark-delete-${index}`).attr('hidden', false);
      $(`#btn-ccmemo-remark-edit-${index}`).attr('hidden', false);

      this.set_cc_memo_remark(ccMemoRemark);
    },

    async updateCcMemoRemarks(ccMemoRemark, index) {
      let msg = `Do you want to save the changes that you made in crew change memo details ?`;
      if (await this.questionAlert(`${msg}`, 'Update Remarks')) {
        this.update_cc_memo_remarks(ccMemoRemark).then((r) => {
          if (r) {
            this.successAlert('Remarks update successfully');
            $(`#ccm-remarks-${index}`).attr('disabled', true);

            $(`#btn-ccmemo-remark-update-${index}`).attr('hidden', true);
            $(`#btn-ccmemo-remark-cancel-${index}`).attr('hidden', true);

            $(`#btn-ccmemo-remark-delete-${index}`).attr('hidden', false);
            $(`#btn-ccmemo-remark-edit-${index}`).attr('hidden', false);
          }
        });
      }
    },

    async deleteCcMemoRemarks(ccMemoRemarkId) {
      let msg = `Do you want to delete this remarks record. ?`;
      if (await this.questionAlert(msg, 'Delete Remarks')) {
        this.delete_cc_memo_remarks(ccMemoRemarkId).then((r) => {
          this.successAlert('Remarks deleted successfully', 'Delete');
        });
      }
    },

    resetCcMemoRemarkState() {
      this.reset_cc_memo_remark_state();
    },

    /** end of batching remarks action  **/

    setNullCcMemo() { //
      this.set_null_cc_memo();
    },

    getCcMemoDetails(crewBatchingId, crewBatchingNo) {
      console.log('check this out getCcMemoDetails',crewBatchingId, crewBatchingNo);
      this.crewBatchId = crewBatchingId;
      this.crewBatchNo = crewBatchingNo;
      this.ccmMasterChecklistParams.ccmNo = crewBatchingNo;

      const formParam = {
        batchId: `${crewBatchingId}`, // crewBatchingId from the parent component.
        vesselId: this.vesselId,
      };

      console.log("Check this out",formParam);
      this.get_cc_memo_details(formParam).then((r) => {
        if (r) {
          this.$nextTick(() => {
          });
        }
      });
    },

    crewPreDepChecklist(crewId) {
      const routeData = this.$router.resolve({name: 'SubPreDepartureChecklist', params: {crewId: crewId}});
      window.open(routeData.href, '_blank');
    },

    getCrewDocumentsParam(crewData) {
      this.documentChecklist.crewName = '(' + crewData.rankAlias + ') ' + crewData.crewName;
      this.documentChecklist.crewChecklist = crewData.crewId;
    },

    gotoCovidVaccineProfile(objCrewParam, ccMemoType) {
      if (objCrewParam && ccMemoType === undefined) {
        return;
      }

      let thisComponentName = 'ccmemo';
      let thisCrewId = objCrewParam.crewId;

      let routeData = this.$router.resolve({
            name: 'CrewCovidVaccine',
            params: {
              crewId: `${thisCrewId}`,
            },
            query: {
              view: `${thisComponentName}`,
              docType: 11, //index id of covid vaccine,
            },
          },
      );
      window.open(routeData.href, '_blank');
    },

    // async generateCrewInfoSheet(params) {
    //   return await axios.post(PMC.GENERATE_CREW_INFO_SHEET, params).then(res => {
    //     return res.data.data ? FileService.base64FileToBlobUrl(res.data.data) : false;
    //   }).catch(error => {
    //     UtilityService.generateResponseMessage(error, 'Generate Crew Info Sheet')
    //     return false
    //   });
    // },

    // async loadChecklistPDF(crewId) {
    //   let params = {
    //     'crew_id': crewId
    //   };
    //   let checklistFile = await this.generateCrewInfoSheet(params);
    //   if (checklistFile) {
    //     this.pdfViewer('Information Sheet', checklistFile);
    //   } else {
    //   }
    // },

    async loadCcmDocument(ccmDoc, crewId) {
      this.ccmDocsParams.ccmDocument = ccmDoc;
      this.ccmDocsParams.crewId = crewId;
      this.ccmDocsParams.vesselId = this.crewChangeMemo.ccMemoVesselId ;

      AlertService.loadingAlert('Please wait ...', 'Generating PDF');
      let ccmDocumentFile = await this.generateCcmDocument(this.ccmDocsParams);
      if (ccmDocumentFile) {
        Swal.close();
        if (ccmDoc === 6) {
          await this.pdfViewerInterOcean(global_variables_config.ccmDocsName[ccmDoc] + ' File', ccmDocumentFile.page1,
              ccmDocumentFile.page2);
        } else {
          await this.pdfViewer(global_variables_config.ccmDocsName[ccmDoc] + ' File', ccmDocumentFile);
        }
      } else {
        Swal.close();
      }
    },

    async loadCcmMasterChecklist(ccmMasterChecklist, crewId) {
      this.ccmMasterChecklistParams.ccmMasterChecklist = ccmMasterChecklist;
      this.ccmMasterChecklistParams.batchId = this.crewChangeMemo.ccMemoId;
      this.ccmMasterChecklistParams.vesselId = this.crewChangeMemo.ccMemoVesselId ;

      AlertService.loadingAlert('Please wait ...', 'Generating PDF');
      let ccmDocumentFile = await this.generateCcmMasterChecklist(this.ccmMasterChecklistParams);
      if (ccmDocumentFile) {
        Swal.close();
        await this.pdfViewerMasterChecklist(global_variables_config.ccmMasterDocsName[ccmMasterChecklist] + ' File',
            ccmDocumentFile);
      } else {
        Swal.close();
      }
    },

    getCcmMasterListSignOn(isSignOn) {
      this.ccmMasterChecklistParams.batchId = this.crewChangeMemo.ccMemoId;
      this.ccmMasterChecklistParams.vesselId = this.crewChangeMemo.ccMemoVesselId ;
      this.ccmMasterChecklistParams.isSignOnCrews = isSignOn;
      this.getCcmMasterChecklistDetails(this.ccmMasterChecklistParams);
      this.ccmVesselEcdisName = this.crewChangeMemo.ccEcdisType.replace(/-(?![^(]*\))/g, '') ?? null;
    },

    refreshGetCcmMasterChecklistDetails() {
      this.getCcmMasterChecklistDetails(this.ccmMasterChecklistParams);
    },

    refreshGetCcmCovid() {
      this.get_cc_memo_details(
          {
            batchId: this.crewChangeMemo.ccMemoId,
            vesselId: this.vesselId,
          },
      );
    },

    async loadCcMemoCovidVaccineReport() {
      this.ccmMasterChecklistParams.batchId = this.crewChangeMemo.ccMemoId;
      this.ccmMasterChecklistParams.vesselId = this.vesselId;

      AlertService.loadingAlert('Please wait ...', 'Generating PDF');
      let ccmCovidVaccineFile = await this.generateCcMemoCovidVaccineReport({
        batchId: this.crewChangeMemo.ccMemoId,
        vesselId: this.crewChangeMemo.ccMemoVesselId,
      });
      if (ccmCovidVaccineFile) {
        Swal.close();
        await this.pdfViewerMasterChecklist('Covid Vaccine Report File', ccmCovidVaccineFile);
      } else {
        Swal.close();
      }
    },

  },

  /** mounted **/
  mounted() {
    // this.getCcMemoDetails();
    this.bus.$on('setNullCcMemo', this.setNullCcMemo);
    this.bus.$on('getCcMemoDetails', this.getCcMemoDetails);
    this.bus.$on('generateCcMemoDetails', this.generateCcMemoDetails);
    // this.bus.$on('cancelCcMemo', this.cancelCcMemo)
    this.bus.$on('resetCcMemoRemarkState', this.resetCcMemoRemarkState);
  },

  /** created **/
  created() {
    this.$globalVariables = global_variables_config;
    //this.getCcMemoDetails();
  },

  beforeDestroy() {
    this.$globalVariables = null;
  },

  /** watch **/
  watch: {
    crews(val) {
      this.updateTableRowSize(val, 1);
      this.updateTableRowSize(val, 2);
    },

      /*loadData(val) {
       if(val){
       this.loadCrewChangeMemo();
       }
       },*/

    crewBatchId(val) {
      if (val !== 0) {
        //TODO get the batching details from the back end.
      }
    },

    // signOnCrews(val){
    //   this.updateTableRowSize(val,1)
    // },

    // signOffCrews(val){
    //   this.updateTableRowSize(val,2)
    // }

    'crewChangeMemo.signOnCrews': function(newVal, OldVal) {
      this.updateTableRowSize(newVal, 1);
    },

    'crewChangeMemo.signOffCrews': function(newVal, OldVal) {
      this.updateTableRowSize(newVal, 2);
    },
  },

  /** destroyed **/
  destroyed() {
    this.reset_cc_memo_state();
    this.crewBatchId = null;
  },
};
</script>
<style scoped>

input[type="checkbox"], input[type="radio"] {
    transform: scale(2) !important;
}

#documentListModal .modal-content {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    /*background: linear-gradient(265deg, rgba(2,0,36,1) 0%, rgba(8,20,224,1) 35%, rgba(0,212,255,1) 100%);*/
}


.mytable {
    border-collapse: collapse;
    width: 100%;
}

.mytable th, td {
    text-align: left;
    padding: 10px;
}

.mytable th {
    background-color: #4CAF50;
    color: white;
}

/* CSS for the font size */
.report-button-font-size {
    font-size: 33px;
}

.has-select-signatory {
    border: 3px solid #55ff41 !important;
    box-shadow: 0 0 10px #719ECE !important;
}

.default-crew-css {
    color: black !important;
}

/*
//TODO make a css design for scholar tagging
*/
.fast-track {
    color: #ff090a !important;
}

.bridging-cadet {
    color: #ff901f !important
}

.maap {
    color: #28a745 !important
}

.custom-modal-size {
    max-width: 80%;
    margin: 0 auto;
}

.msap {
    color: #9f03ff !important
}

.sponsored-hrm {
    color: #1205ff !important
}

.utility-program {
    color: #156276 !important
}
</style>
