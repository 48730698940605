<template>
    <div class="container-fluid  bg-white">
            <div class="col px-1 mt-3">
                <div class="row">
                    <h3 class="pl-4" style="margin-top: -3px;">Crew Change Manipulation</h3>
                </div>
                <div class="row px-1 no-gutters">
                    <div class="col-4 text-left">
                        <div class="row">
                            <div class="col">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button"
                                            class="btn pmc-btn-second btn-sm"
                                            :disabled="!crewChangeMemoDetails.hasOwnProperty('signOnCrews')"
                                            v-if="toBeActualizeList.length == 0"
                                            @click="applyChanges">Apply Changes
                                    </button>
                                    <!--<button class="btn pmc-btn-tertia btn-sm ml-2">Actualize Onboard</button> &lt;!&ndash; use if sign off is null &ndash;&gt;-->
                                    <!--<button class="btn pmc-btn-tertia btn-sm ml-1" type="button">Refresh</button>-->
                                    <button type="button"
                                            @click="actualizeOnboarding"
                                            class="btn pmc-btn-info btn-sm ml-2"
                                            v-if="toBeActualizeList.length > 0"
                                            title="Actualize onboarding">
                                        Actualize
                                        <span class="font-weight-bold">( {{ toBeActualizeList.length }} )</span>
                                    </button>
                                    <!--                <button type="button"-->
                                    <!--                        class="btn pmc-btn-prime btn-sm ml-1"-->
                                    <!--                        :disabled="!formCcManipParam" @click="viewChecklist">-->
                                    <!--                  View Check list-->
                                    <!--                </button>-->

                                    <button style="" type="button" class="btn pmc-btn-prime btn-sm ml-1"
                                            data-toggle="modal"
                                            data-target="#view-checklist-signon"
                                            @click="viewChecklist(1)"
                                            :disabled="!crewChangeMemoDetails.hasOwnProperty('signOnCrews')">
                                        View Checklist
                                    </button>
                                    <button type="button" class="btn btn-sm btn-secondary ml-1"
                                            :disabled="!crewChangeMemoDetails.hasOwnProperty('signOnCrews')"
                                            @click="generateAgentList">View Agent list
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="view-checklist-signon" tabindex="-1" role="dialog"
                         aria-labelledby="setupCcPlanCenter"
                         aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-size" role="document">
                            <!--      <div class="modal-dialog modal-dialog-centered full-screen-modal2" role="document">-->
                            <div class="modal-content" style="height: 120%">
                                <div class="modal-header">

                                    <div class="row d-flex align-items-center px-3">
                                        <h5 class="modal-title font-weight-bolder mr-5" id="CrewBiodataTitle">View
                                            Checklist</h5>
                                        <button type="button" class="btn btn-success btn-sm px-3 font-weight-normal"
                                                @click="loadViewChecklist($globalVariables.ccmMaster.checklist)">
                                            Print
                                        </button>
                                        <div class="form-group mb-2 ml-3">
                                            <select class="form-control form-control-sm"
                                                    v-model="viewChecklistParams.signatory">
                                                <option value="null">-- checked by ---</option>
                                                <option :value="signatory"
                                                        v-for="(signatory,index) in $globalVariables.ccm_master_checklist_signatories"
                                                        :key="signatory.id">
                                                    {{ signatory.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-sm ml-2" @click.prevent="closeViewChecklistModal()"
                                            aria-label="Close">
                                        <font-awesome-icon icon="times" class="text-danger"/>
                                        Close
                                    </button>
                                </div>
                                <div class="modal-body"
                                     style="overflow-x: hidden !important; overflow-y: scroll;  height: 700px">
                                    <ccm-master-checklist-modal-body
                                            :loading="isReloading"
                                            :ccm-master-checklist="ccmMasterChecklist"
                                            :ccm-flag-id="ccmFlagId"
                                            :ccm-flag-name="ccmFlagName"
                                    />
                                </div>
                                <div class="modal-footer">
                                    <table style="width:100%;">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <span style="font-weight:bold;">Legend : * - With one higher license</span>
                                            </td>
                                            <td>
                                            </td>
                                            <td>
                                                <span style="text-align:right;background-color: Gray; color:white;"><b>Non Mandatory</b></span>
                                            </td>
                                            <td>
                                            </td>
                                            <td><span
                                                    style="text-align:right;background-color: Yellow"><b>Near Expiry</b></span>
                                            </td>
                                            <td>
                                            </td>
                                            <td><span
                                                    style="text-align:right;background-color: #F5761A;color:Black;"><b>No File Upload</b></span>
                                            </td>
                                            <td>
                                            </td>
                                            <td><span
                                                    style="text-align:right;background-color: Red;color:white;"><b>Expired Documents</b></span>
                                            </td>
                                            <td>
                                            </td>
                                            <td><span
                                                    style="text-align:right;background-color:Purple;color:white"><b>Mismatch Document No.2 Detail</b></span>
                                            </td>
                                            <td>
                                            </td>
                                            <td><span
                                                    style="text-align:right;background-color:Black;color:white"><b>Mismatch Document Rank</b></span>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group row">
                                    <label for="open-crew-change"
                                           class="col-form-label">
                                        Crew Change Batches
                                    </label>
                                    <span class="mr-2 ml-2 col-form-label">:</span>
                                    <select name="openCrewChange"
                                            v-model="formCcManipParam"
                                            @change="onChangeCrewChangeBatches"
                                            id="open-crew-change"
                                            class="custom-select custom-select-sm"
                                            style="width: 60%;">
                                        <option :value="null" class="text-center align-middle">- SELECT -</option>
                                        <option :value="batch"
                                                v-for="(batch,index) in objCcMemoBatches"
                                                :key="batch.batchId">
                                            {{ batch.vesselName }} : {{ batch.batchNo }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group input-group row">
                                    <label for="search-ccm-no" class="col-form-label">Search CCM #No</label>
                                    <span class="mr-2 ml-2 col-form-label">:</span>
                                    <input id="search-ccm-no"
                                           @keydown.enter="deBounceSearchCcMemoNo"
                                           name="searchCcmNo"
                                           v-model="searchValue"
                                           class="form-control form-control-sm" aria-label="With textarea"/>
                                    <div class="input-group-prepend">
                                        <button type="button" class="btn btn-sm pmc-btn-prime"
                                                @click.prevent.exact="deBounceSearchCcMemoNo">Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row px-1 mt-3">
                    <div class="col-lg-12">
                        <div v-if="isDataReloading" class="d-flex justify-content-center">
                            <div class="spinner-grow" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="card" v-if="(crewChangeMemoDetails.signOnCrews?.length > 0 || crewChangeMemoDetails.signOffCrews?.length > 0) && !isDataReloading">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-5">
                                                <h4 class="text-left font-weight-bold">
                                                    {{ crewChangeMemoDetails.ccMemoVessel }}</h4>
                                            </div>
                                            <div class="col-5">
                                            <span class="pl-5"
                                                  :class="{'approve-css' : crewChangeMemoDetails.ccMemoIsApproved,
                                                   'not-yet-approve-css' : !crewChangeMemoDetails.ccMemoIsApproved
                                                    }">
                                                    {{ crewChangeMemoDetails.ccMemoIsApproved
                                                        ? 'Approved'
                                                        : 'Not yet approved'
                                                    }}
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="col-sm-12 pmc-bg-success-light">
                                    <h6 class="pt-2 text-left">Signing-On Crew
                                        <sup class="font-weight-bold text-danger"
                                             v-if="crewChangeMemoDetails.signOnCrews">
                                            ( {{ crewChangeMemoDetails.signOnCrews.length }} )
                                        </sup>
                                    </h6>
                                </div>

                                <div class="col-sm-12">
                                    <div class="table table-responsive pmc-table">
                                        <table class="table table-bordered table-sm text-center small table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col" style="width: 5% !important;">#</th>
                                                <th scope="col" style="width: 20% !important;" colspan="3"
                                                    class="crew-name">Name
                                                </th>
                                                <th scope="col" style="width: 5% !important;" class="date-column">Rank
                                                </th>

                                                <!--  departure date -->
                                                <th scope="col" style="width: 10% !important;" class="date-column">
                                                    <button @click="setHeaderDate(1,1)"
                                                            data-toggle="modal"
                                                            data-target="#header-date-input-modal"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            title="Departure date"
                                                            type="button"
                                                            class="btn btn-sm btn-light border-0 font-weight-bold">
                                                        Departure
                                                    </button>
                                                </th>

                                                <!--    sign on date                  -->
                                                <th scope="col" style="width: 10%;" class="date-column">
                                                    <button
                                                            title="Sign on date"
                                                            data-toggle="modal"
                                                            data-target="#header-date-input-modal"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            type="button"
                                                            @click="setHeaderDate(1,2)"
                                                            class="btn btn-sm btn-light border-0 font-weight-bold">
                                                        Sign-on
                                                    </button>
                                                </th>

                                                <!-- end of contract   -->
                                                <th scope="col"
                                                    style="width: 10% !important;"
                                                    class="date-column">
                                                    <button
                                                            title="End of contract"
                                                            data-toggle="modal"
                                                            data-target="#header-date-input-modal"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            type="button"
                                                            @click="setHeaderDate(1,3)"
                                                            class="btn btn-sm btn-light border-0 font-weight-bold">
                                                        E.O.C
                                                    </button>
                                                </th>

                                                <!--           Sign off date           -->
                                                <th style="width: 4%;" scope="col">Sign-off</th>
                                                <th style="width: 4%;" scope="col">Arrival</th>
                                                <th style="width: 5%;" scope="col">Remarks</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-if="signOnCrew" v-for="(signOnCrew,index) in crewChangeMemoDetails.signOnCrews"
                                                :key="signOnCrew.ccMemoId">
                                                <th class="text-center align-middle">{{ index + 1 }}</th>
                                                <td class="text-left align-middle"><a href="#" @click="gotoProfile(signOnCrew.crewId)">{{
                                                        signOnCrew.crewName
                                                    }}</a></td>
                                                <td class="text-center align-middle" style="width: 2% !important;"
                                                    v-if="signOnCrew.isToManualActualize">
                                                    <input type="checkbox"
                                                           name="signOnCheckBoxActualize"
                                                           v-if="(signOnCrew.dpManilaDate
                                        && signOnCrew.signOnDate
                                        && signOnCrew.eocDate !== null || undefined)
                                        && crewChangeMemoDetails.ccMemoIsApproved
                                        && signOnCrew.ccrStatus"
                                                           :id="`sign-on-check-box-actualize-${signOnCrew.ccId}`"
                                                           @click="onCheckActualizeOnboard($event,signOnCrew)">
                                                </td>
                                                <td v-else></td>
                                                <td class="text-center align-middle" style="width: 2% !important;">
                                                    <button
                                                            title="Undo inputed details"
                                                            type="button"
                                                            v-if="!signOnCrew.onBoardDepartureDate"
                                                            @click="revertDetails(1,signOnCrew.ccId)"
                                                            class="btn btn-sm btn-light">
                                                        <font-awesome-icon icon="undo"/>
                                                    </button>
                                                </td>

                                                <td class="text-center align-middle">{{ signOnCrew.rankAlias }}</td>

                                                <!-- signOnCrew dpManilaDate -->
                                                <td class="date-column text-center align-middle">
                                                    <!-- has-onboard-promotion  -->
                                                    <input type="date"
                                                           required
                                                           disabled
                                                           v-if="signOnCrew.onBoardDepartureDate"
                                                           v-model="signOnCrew.onBoardDepartureDate"
                                                           class="form-control form-control-sm bg-white text-center"
                                                           :class="[
                                  signOnCrew.onBoardDepartureDate ? 'has-onboard-promotion' : 'no-data-header-date',
                               ]"
                                                           aria-label="depart date picker">
                                                    <span v-if="signOnCrew.onBoardDepartureDate">
                            {{ signOnCrew.dpManilaDate = "" }}
                          </span>
                                                    <span v-if="signOnCrew.onBoardDepartureDate"
                                                          class="pmc-text-danger">OBPR FOUND!</span>

                                                    <input type="date"
                                                           required
                                                           @change="onChangeDepartureDate(signOnCrew, ccSignOns)"
                                                           @blur="onBlurDepartureDate(signOnCrew)"
                                                           v-if="!signOnCrew.onBoardDepartureDate"
                                                           v-model="signOnCrew.dpManilaDate"
                                                           class="form-control form-control-sm bg-white text-center"
                                                           :class="[
                                  signOnCrew.dpManilaDate ? 'has-data-header-date' : 'no-data-header-date'
                               ]"
                                                           aria-label="depart date picker"
                                                           :min="minDate"
                                                           :max="maxDate"
                                                   >
                                                </td>

                                                <!-- signOnCrew signOnDate -->
                                                <td class="date-column text-center align-middle">
                                                    <input type="date"
                                                           required
                                                           @change="onChangeSignOnDate(signOnCrew)"
                                                           @blur="onBlurSignOnDate(signOnCrew)"
                                                           :disabled="hasNoSignOnDate(signOnCrew)"
                                                           v-model="signOnCrew.signOnDate"
                                                           :class="[
                                 signOnCrew.signOnDate ? 'has-data-header-date' : 'no-data-header-date',
                                 {gray: hasNoSignOnDate(signOnCrew)}
                               ]"
                                                           class="form-control form-control-sm bg-white text-center"
                                                           aria-label="sign-on date picker"
                                                           :min="signOnCrew.dpManilaDate || signOnCrew.onBoardDepartureDate"
                                                           :max="getMaxDateInField(signOnCrew.crewId)">
                                                </td>

                                                <td class="date-column text-center align-middle">
                                                    <input type="date"
                                                           required
                                                           @change="onChangeEndOfContract(signOnCrew)"
                                                           @blur="onBlurEOCDate(signOnCrew)"
                                                           :disabled="hasNoSignOnDate(signOnCrew)"
                                                           v-model="signOnCrew.eocDate"
                                                           :class="[
                                 signOnCrew.eocDate ? 'has-data-header-date' : 'no-data-header-date',
                                 {gray: hasNoSignOnDate(signOnCrew)}
                                ]"
                                                           class="form-control form-control-sm bg-white text-center"
                                                           aria-label="eoc date picker"
                                                           :min="signOnCrew.dpManilaDate || signOnCrew.onBoardDepartureDate"
                                                           :max="getMaxDateInField(signOnCrew.crewId)">
                                                </td>

                                                <td class="date-column text-center align-middle"
                                                    style="background-color: #cbcbcb;"></td>
                                                <td class="date-column text-center align-middle"
                                                    style="background-color: #cbcbcb;"></td>
                                                <td class="date-column text-center align-middle">
                                                    <button
                                                            v-if="signOnCrew.ccRemarksCount > 0"
                                                            @click="getCrewChangeRemarks(signOnCrew)"
                                                            data-target="#cc-remarks-modal"
                                                            data-toggle="modal"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            class="btn btn-sm btn-light">
                                                        <span>{{ signOnCrew.ccRemarksCount }}</span>
                                                        <sup class="ml-1">
                                                            <font-awesome-icon icon="comment"/>
                                                        </sup>
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td colspan="12" class="text-left align-middle">
                                                    <small class="text-muted font-italic"
                                                           style="font-size: 0.9rem!important;">
                                                        Please Note: *Departure dates of (OBPR) Onboard Promotion Crew
                                                        must be equal to the previous
                                                        sea service.
                                                        *If the crew sign off is N/A | Reverting | Onboard lined-up
                                                        there respective reliever should
                                                        be
                                                        manual actualize.
                                                    </small>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>

                                <div class="col-sm-12 pmc-bg-success-light text-left">
                                    <h6 class="pt-2">Signing-Off Crew
                                        <sup class="font-weight-bold text-danger"
                                             v-if="crewChangeMemoDetails.signOffCrews">
                                            ( {{ crewChangeMemoDetails.signOffCrews.length }} )
                                        </sup>
                                    </h6>
                                </div>

                                <div class="col-sm-12">
                                    <div class="table table-responsive pmc-table">
                                        <table class="table table-bordered table-sm text-center small table-hover">
                                            <thead>
                                            <tr>
                                                <th style="width: 5%;" scope="col">#</th>
                                                <th style="width: 20%;" scope="col" colspan="2" class="crew-name">Name
                                                </th>
                                                <th style="width: 5%;" scope="col" class="date-column">Rank</th>
                                                <th style="width: 10%;" scope="col">Departure</th>
                                                <th style="width: 10%;" scope="col">Sign-on</th>
                                                <th style="width: 10%;" scope="col">E.O.C</th>
                                                <th style="width: 10%;" scope="col" class="date-column">
                                                    <button
                                                            type="button"
                                                            @click="setHeaderDate(2,4)"
                                                            class="btn btn-sm font-weight-bold"
                                                            data-toggle="modal"
                                                            data-target="#header-date-input-modal"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            title="Sign-off date">
                                                        Sign-off
                                                    </button>
                                                </th>
                                                <th style="width: 10%;" scope="col" class="date-column">
                                                    <button
                                                            type="button"
                                                            @click="setHeaderDate(2,5)"
                                                            class="btn btn-sm font-weight-bold"
                                                            data-toggle="modal"
                                                            data-target="#header-date-input-modal"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            title="Arrive">
                                                        Arrival
                                                    </button>
                                                </th>
                                                <th style="width: 10%;" scope="col" class="date-column">
                                                    Reason
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <tr v-if="signOffCrew" v-for="(signOffCrew,index) in crewChangeMemoDetails.signOffCrews"
                                                :key="signOffCrew.id">
                                                <th>{{ index + 1 }}</th>
                                                <td class="text-left">
                                                    <font-awesome-icon style="font-size: 1.3rem!important;"
                                                                       v-if="signOffCrew.isOnboardPromotion"
                                                                       class="pr-1" icon="arrow-up"
                                                                       title="Crew is onboard promotion"/>
                                                    <a href="#" @click="gotoProfile(signOffCrew.crewId)">{{ signOffCrew.crewName }}</a>
                                                    <span v-if="signOffCrew.isOnboardPromotion">{{
                                                            signOffCrew.onboardDepartureDate = ""
                                                        }}</span>
                                                </td>
                                                <td v-if="signOffCrew.crewTypeId !== 3 && signOffCrew.crewTypeId !== 4">
                                                    <button class="btn btn-sm btn-light"
                                                            type="button"
                                                            v-if="!signOffCrew.isOnboardPromotion"
                                                            @click="revertDetails(2,signOffCrew.ccId)">
                                                        <font-awesome-icon icon="undo"/>
                                                    </button>
                                                </td>

                                                <td>{{ signOffCrew.rankAlias }}</td>

                                                <!-- departure date -->
                                                <td>{{ signOffCrew.dpManilaDate }}</td>

                                                <!-- sign on date -->
                                                <td>{{ signOffCrew.signOnDate }}</td>

                                                <!-- end of contract  -->
                                                <td>{{ signOffCrew.eocDate }}</td>


                                                <!--  sign off date                     -->
                                                <td v-if="signOffCrew.crewTypeId !== 3 && signOffCrew.crewTypeId !== 4"
                                                    class="date-column">
                                                    <input type="date"
                                                           @change="onChangeSignOffDate(signOffCrew, ccSignOffs)"
                                                           @blur="onBlurSignOffDate(signOffCrew)"
                                                           :disabled="signOffCrew.isOnboardPromotion || !crewChangeMemoDetails.ccMemoIsApproved || signOffCrew.isOffBoardPromotion"
                                                           :class="[
                                                              signOffCrew.signOffDate
                                                                ? 'has-data-header-date'
                                                                : 'no-data-header-date',
                                                              signOffCrew.isOnboardPromotion
                                                                ? 'has-onboard-promotion'
                                                                : 'no-data-header-date',
                                                           ]"
                                                           v-model="signOffCrew.signOffDate"
                                                           class="form-control form-control-sm bg-white"
                                                           aria-label="sign-on date picker"
                                                           :min="minDate"
                                                           :max="maxDate">
                                                </td>
                                                <td v-else></td>

                                                <td v-if="signOffCrew.crewTypeId !== 3 && signOffCrew.crewTypeId !== 4"
                                                    class="date-column">
                                                    <input type="date"
                                                           @change="onChangeArrivalDate(signOffCrew)"
                                                           @blur="onBlurArrivalDate(signOffCrew)"
                                                           :disabled="signOffCrew.isOnboardPromotion || !crewChangeMemoDetails.ccMemoIsApproved || signOffCrew.isOffBoardPromotion || hasNoSignOffDate(signOffCrew)"
                                                           :class="[
                                                             signOffCrew.arrvManilaDate ? 'has-data-header-date' : 'no-data-header-date',
                                                             signOffCrew.isOnboardPromotion ? 'has-onboard-promotion' : 'no-data-header-date',
                                                             {gray: hasNoSignOffDate(signOffCrew)}
                                                           ]"
                                                           v-model="signOffCrew.arrvManilaDate"
                                                           class="form-control form-control-sm bg-white"
                                                           aria-label="eoc date picker"
                                                           :min="signOffCrew.signOffDate"
                                                           :max="getMaxDateInField(signOffCrew.crewId)">
                                                </td>
                                                <td v-else></td>


                                                <td v-if="signOffCrew.crewTypeId !== 3 && signOffCrew.crewTypeId !== 4">
                                                    <select
                                                            :disabled="signOffCrew.isOnboardPromotion || !crewChangeMemoDetails.ccMemoIsApproved || hasNoSignOffDate(signOffCrew)"
                                                            v-model="signOffCrew.signOffReasonId"
                                                            :class="[
                                                                 signOffCrew.signOffReasonId ? 'has-data-header-date' : 'no-data-header-date',
                                                                 signOffCrew.isOnboardPromotion ? 'has-onboard-promotion' : 'no-data-header-date',
                                                                 {gray: hasNoSignOffDate(signOffCrew)}
                                                           ]"
                                                            class="custom-select custom-select-sm">
                                                        <option :value="null">- Reason -</option>
                                                        <option :value="reason.id"
                                                                v-for="(reason,index) in objSignOffReasons"
                                                                :key="reason.id">
                                                            {{ reason.code }}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td v-else></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group row text-left align-middle">
                                            <label class="col-form-label pl-3"
                                                   style="max-width: 25%; -ms-flex: 0 0 27%; flex: 0 0 25%;">Country
                                                Name :</label>
                                            <div style="width: 66%;">
                                                <select name="ccBatchCountry"
                                                        :class="[
                                                          crewChangeMemoDetails.ccMemoCountryId ? 'has-data-header-date' : 'no-data-header-date'
                                                        ]"
                                                        :disabled="!crewChangeMemoDetails.ccMemoIsApproved || searchValue !== null"
                                                        v-model="crewChangeMemoDetails.ccMemoCountryId"
                                                        class="form-control form-control-sm text-left align-middle"
                                                        id="cc-batch-country">
                                                    <option :value="null" class="text-center align-middle">- Country -
                                                    </option>
                                                    <option :value="country.id"
                                                            :key="country.id"
                                                            v-for="(country,index) in objCountries">
                                                        {{ country.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <button v-if="searchValue === null"
                                                    type="button"
                                                    class="btn btn-sm btn-light border-0"
                                                    data-toggle="modal"
                                                    data-target="#newCountryModal"
                                                    data-backdrop="static"
                                                    data-keyboard="false">
                                                <span style="font-size: 0.7rem; vertical-align: middle">&plus; Add</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group row text-left align-middle">
                                            <label class="col-form-label col-md-3">Port Name :</label>
                                            <div style="width: 66%;">
                                                <select name="ccBatchCountry"
                                                        :class="[
                                                          crewChangeMemoDetails.ccMemoPortId ? 'has-data-header-date' : 'no-data-header-date'
                                                        ]"
                                                        :disabled="!crewChangeMemoDetails.ccMemoIsApproved || searchValue !== null"
                                                        v-model="crewChangeMemoDetails.ccMemoPortId"
                                                        class="form-control form-control-sm text-left align-middle"
                                                        id="cc-batch-port">
                                                    <option :value="null" class="text-center align-middle">- Port -
                                                    </option>
                                                    <option :value="port.id"
                                                            :key="port.id"
                                                            v-for="(port,index) in objPorts">
                                                        {{ port.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <button
                                                    v-if="searchValue === null"
                                                    type="button"
                                                    class="btn btn-sm btn-light border-0"
                                                    data-toggle="modal"
                                                    data-target="#newPortModal"
                                                    data-backdrop="static"
                                                    data-keyboard="false">
                                                <span style="font-size: 0.7rem;">&plus; Add</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group row text-left align-middle">
                                            <label class="col-form-label col-md-3">ETD :</label>
                                            <div style="width: 66%;">
                                                <input type="date"
                                                       :class="[
                                                           crewChangeMemoDetails.etd
                                                            ? 'has-data-header-date'
                                                            : 'no-data-header-date'
                                                       ]"
                                                       :disabled="!crewChangeMemoDetails.ccMemoIsApproved || searchValue !== null"
                                                       class="form-control form-control-sm"
                                                       v-model="crewChangeMemoDetails.etd"
                                                       name="ccBatchEtd"
                                                       id="cc-batch-etd"
                                                       :min="minDate"
                                                       :max="maxDate">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card" v-if="!isReloading && (!crewChangeMemoDetails.hasOwnProperty('signOnCrews') || !crewChangeMemoDetails.hasOwnProperty('signOffCrews')) && searchValue == null">
                            <div class="card-body">
                                <div class="d-flex justify-content-center">
                                    <div class="card-text">
                                        Currently no selected batch
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card"
                             hidden="hidden"
                             v-if="!crewChangeMemoDetails.hasOwnProperty('signOnCrews')
                                  && !crewChangeMemoDetails.hasOwnProperty('signOffCrews') && this.searchValue !== null"
                             id="searching-body-message">
                            <div class="card-body">
                                <div class="d-flex justify-content-center">
                                    <div class="card-text">
                                        No record found or the batch your searching is currently active for actualization
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal date header -->
            <div class="modal fade" id="header-date-input-modal" tabindex="-1" role="dialog"
                 aria-labelledby="headerDateInputLabel" aria-hidden="true">
                <div class="modal-dialog" style="max-width: 250px;" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="header-input-title">Input Date</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <input type="date"
                                   v-model="dateHeaderVal"
                                   id="header-date-input"
                                   class="form-control form-control-sm text-center align-middle">
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" @click="saveHeaderDate()">Save</button>
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Crew Change Remarks    -->
            <div class="modal fade" id="cc-remarks-modal" tabindex="-1" role="dialog"
                 aria-labelledby="cc-remarks-modal-label"
                 aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl modal" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="cc-remarks-modal-title">Crew Change Remarks</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span class="text-danger" aria-hidden="true">&times; Close</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <pmc-table :max-height="250">
                                <template v-slot:table-header>
                                    <tr>
                                        <th style="width: 40%;" class="p-1">Remarks</th>
                                        <th style="width: 5%;" class="p-1">Status</th>
                                        <th style="width: 20%;" class="p-1">Created at</th>
                                        <th style="width: 20%;" class="p-1">Updated at</th>
                                        <th style="width: 20%;" class="p-1">Created by</th>
                                    </tr>
                                </template>
                                <!-- -->
                                <template v-slot:table-body>
                                    <tr v-for="(remark,index) in crewChangeRemarks" :key="remark.id">
                                        <td class="text-left align-middle"> {{ remark.remarks }}</td>
                                        <td>
                                            {{ tagStatusOpt.find((val) => remark.tagStatus === val.id).name }}
                                        </td>
                                        <td>
                                            <span style="font-size: 0.8rem !important;">{{ remark.createdAt }}</span>
                                        </td>
                                        <td>
                                            <span style="font-size: 0.8rem !important;">{{ remark.updatedAt }}</span>
                                        </td>
                                        <td>{{ remark.createdBy }}</td>
                                    </tr>
                                </template>
                            </pmc-table>
                        </div>
                        <!--          <div class="modal-footer">-->
                        <!--            <button type="button" class="btn btn-primary">Save changes</button>-->
                        <!--            <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>-->
                        <!--          </div>-->
                    </div>
                </div>
            </div>
            <!-- CC Remarks Modal    -->
            <!--    <div class="modal fade" id="crew-detail-is-onboard" tabindex="-1" role="dialog" aria-labelledby="crew-detail-is-onboard-label" aria-hidden="true">-->
            <!--      <div class="modal-dialog modal-dialog-centered  modal" role="document">-->
            <!--        <div class="modal-content">-->
            <!--          <div class="modal-header">-->
            <!--            <h5 class="modal-title" id="cc-remarks-modal-title">On Board Promotion Found</h5>-->
            <!--            <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
            <!--              <span class="text-danger" aria-hidden="true">&times; Close</span>-->
            <!--            </button>-->
            <!--          </div>-->

            <!--          <div class="modal-body">-->
            <!--            <div id="crew-details-is-onboard">-->

            <!--            </div>-->
            <!--          </div>-->
            <!--        </div>-->
            <!--      </div>-->
            <!--    </div>-->

            <!--  new country modal   -->
            <div class="modal fade" id="newCountryModal" tabindex="-1" role="dialog"
                 aria-labelledby="newCountryModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">

                        <div class="modal-header">
                            <h5 class="modal-title" id="newCountryModalLabel">NEW COUNTRY</h5>
                        </div>

                        <div class="modal-body">
                            <div class="row ml-1">
                                <div class="col-md-12 text-left ml-2">
                                    <!-- new code -->
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">CODE</label>
                                        <span class="col-form-label">:</span>
                                        <div class="col-sm-5">
                                            <input type="text"
                                                   class="form-control form-control-sm"
                                                   placeholder="---"
                                                   v-model="newCountry.code"
                                            />
                                        </div>
                                    </div>

                                    <!-- new  name -->
                                    <div class="form-group row mt-2">
                                        <label class="col-sm-3 col-form-label">NAME</label>
                                        <span class="col-form-label">:</span>
                                        <div class="col-sm-5">
                                            <input
                                                    type="text"
                                                    class="form-control form-control-sm"
                                                    placeholder="---"
                                                    v-model="newCountry.name"
                                            />
                                        </div>
                                    </div>

                                    <!-- new  nationality-->
                                    <div class="form-group row mt-2">
                                        <label class="col-sm-3 col-form-label">NATIONALITY</label>
                                        <span class="col-form-label">:</span>
                                        <div class="col-sm-5">
                                            <input
                                                    type="text"
                                                    class="form-control form-control-sm"
                                                    placeholder="---"
                                                    v-model="newCountry.natl"
                                            />
                                        </div>
                                    </div>

                                    <!-- new  nationality code-->
                                    <div class="form-group row mt-2">
                                        <label class="col-sm-3 col-form-label">NATIONALITY CODE</label>
                                        <span class="col-form-label">:</span>
                                        <div class="col-sm-5">
                                            <input
                                                    type="text"
                                                    class="form-control form-control-sm"
                                                    placeholder="---"
                                                    v-model="newCountry.natl_code"
                                            />
                                        </div>
                                    </div>


                                    <!--   status -->
                                    <div class="form-group row mt-2">
                                        <label class="col-sm-3 col-form-label">STATUS</label>
                                        <span class="col-form-label">:</span>
                                        <div class="col-sm-5">
                                            <input
                                                    type="checkbox"
                                                    class="form-check form-check-inline ml-2 mt-2"
                                                    placeholder="---"
                                                    v-model="newCountry.status"
                                            />
                                            <span> ACTIVE</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button
                                    type="button"
                                    class="btn btn-sm pmc-btn-tertia"
                                    @click="saveCountry">
                                <font-awesome-icon icon="save"/>
                                SAVE
                            </button>

                            <button
                                    type="button"
                                    class="btn btn-sm pmc-btn-second"
                                    data-dismiss="modal">
                                <font-awesome-icon icon="undo"/>
                                CLOSE
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            <!--  new port modal   -->
            <div class="modal fade" id="newPortModal" tabindex="-1" role="dialog"
                 aria-labelledby="newPrincipalModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">

                        <div class="modal-header">
                            <h5 class="modal-title" id="newPrincipalModalLabel">NEW PORT</h5>
                        </div>

                        <div class="modal-body">
                            <div class="row ml-1">
                                <div class="col-md-12 text-left ml-2">
                                    <!-- new code -->
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label">CODE</label>
                                        <span class="col-form-label">:</span>
                                        <div class="col-sm-5">
                                            <input type="text"
                                                   class="form-control form-control-sm"
                                                   placeholder="---"
                                                   v-model="newPort.code"
                                            />
                                        </div>
                                    </div>

                                    <!-- new  name -->
                                    <div class="form-group row mt-2">
                                        <label class="col-sm-3 col-form-label">NAME</label>
                                        <span class="col-form-label">:</span>
                                        <div class="col-sm-6">
                                            <input
                                                    type="text"
                                                    class="form-control form-control-sm"
                                                    placeholder="---"
                                                    v-model="newPort.name"
                                            />
                                        </div>
                                    </div>

                                    <!-- country-->
                                    <div class="form-group row mt-2">
                                        <label class="col-sm-3 col-form-label">COUNTRY</label>
                                        <span class="col-form-label">:</span>
                                        <div class="col-sm-5">
                                            <select
                                                    class="form-control form-control-sm"
                                                    v-model="newPort.country_id">
                                                <option :value="null">--select--</option>
                                                <option :value="country.id" v-for="(country,index) in objCountries"
                                                        :key="country.id"
                                                        style="font-size: 14px;">
                                                    <span> {{ country.name }}</span>
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <!--   status -->
                                    <div class="form-group row mt-2">
                                        <label class="col-sm-3 col-form-label">STATUS</label>
                                        <span class="col-form-label">:</span>
                                        <div class="col-sm-5">
                                            <input
                                                    type="checkbox"
                                                    class="form-check form-check-inline ml-2 mt-2"
                                                    placeholder="---"
                                                    v-model="newPort.status"
                                            />
                                            <span> ACTIVE</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button
                                    type="button"
                                    class="btn btn-sm pmc-btn-tertia"
                                    @click="savePort">
                                <font-awesome-icon icon="save"/>
                                SAVE
                            </button>

                            <button
                                    type="button"
                                    class="btn btn-sm pmc-btn-second"
                                    data-dismiss="modal">
                                <font-awesome-icon icon="undo"/>
                                CLOSE
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <!-- view agentlist -->
            <div class="modal fade" id="cc-agentlist-modal" tabindex="-1" role="dialog"
                 aria-labelledby="cc-agentlist-modal-label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl  modal" role="document">
                    <div class="modal-content" style="max-height: 80%;">
                        <div class="modal-header">
                            <div class="col-3 text-left align-middle">
                                <h5 class="modal-title" id="cc-remarks-modal-title">Agent Checklist</h5>
                            </div>

                            <div class="col-3">
                                <div class="form-group row">
                                    <label for="cc-inputted-port" class="col-sm-3 col-form-label">PORT</label>
                                    <div class="col-form-label">:</div>
                                    <div class="col-sm-8">
                                        <input type="text"
                                               class="form-control form-control-sm"
                                               name="ccInputtedPort"
                                               placeholder="Port name here . . ."
                                               @keydown="onKeyDownPort"
                                               id="cc-inputted-port">
                                    </div>
                                </div>
                            </div>

                            <div class="col-5">
                                <div class="form-group row">
                                    <label for="agent-noted-by" class="col-sm-3 col-form-label">NOTED BY</label>
                                    <div class="col-form-label">:</div>
                                    <div class="col-md-8">
                                        <select
                                                @change="onChangeNotedBy($event)"
                                                class="form-control form-control-sm" name="agentNotedBy"
                                                id="agent-noted-by">
                                            <option :value="null" class="text-center align-middle">-- SELECT --</option>
                                            <option :value="notedBy.id" v-for="(notedBy,index) in objNotedByKeys"
                                                    :key="index">
                                                <span>{{ notedByNameSpace(notedBy) }}</span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-1 text-right align-middle">
                                <button type="button" class="btn btn-light" data-dismiss="modal" aria-label="Close"
                                        @click="closeAgentListModal">
                                    <span class="text-danger" aria-hidden="true">&times;</span>Close
                                </button>
                            </div>
                        </div>

                        <div class="modal-body">
                            <div id="agent-checklist-container"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!--      &lt;!&ndash; &lt;!&ndash; crew checklist &ndash;&gt;-->
            <!--            <div class="modal fade" id="crew-checklist-modal" tabindex="-1" role="dialog" aria-labelledby="crew-checklist-modal-label" aria-hidden="true">-->
            <!--              <div class="modal-dialog modal-dialog-centered modal-xl  modal" role="document">-->
            <!--                <div class="modal-content" style="max-height: 80%;">-->
            <!--                  <div class="modal-header">-->
            <!--                    <h5 class="modal-title" id="cc-remarks-modal-title">Crew Checklist</h5>-->
            <!--                    <button type="button" class="btn btn-light" data-dismiss="modal" aria-label="Close" @click="closeAgentListModal">-->
            <!--                      <span class="text-danger" aria-hidden="true">&times;</span>Close-->
            <!--                    </button>-->
            <!--                  </div>-->
            <!--                  <div class="modal-body">-->
            <!--                    <div id="crew-checklist-container"></div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>&ndash;&gt;-->

            <!-- generating pdf loading modal -->
            <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
                 aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import {AppMixins} from '../../../mixins/app.mixins'
import {AlertMixins} from '../../../mixins/alert.mixins'
import {mapActions, mapGetters} from "vuex";
import __, {parseInt} from "lodash";
import {AlertService} from "@/service/alert.service";
import {global_variables_config} from "@/config/global-variable.config";

export default {
    name: 'CrewChangeManipulation',
    mixins: [AppMixins, AlertMixins],
    data() {
        return {
            formCcManipParam: null,
            searchValue: null,
            isDataReloading: false,

            editedSignOnCrews: [],
            editedSignOffCrews: [],

            crewType: null,
            dateHeaderType: null,
            dateHeaderVal: null,

            ccSignOns: [],
            ccSignOffs: [],

            objNotedByKeys: [],

            formAgentParam: {},
            toBeActualizeList: [],
            qualifiedToActualize: false,

            viewChecklistParams: {
                crewId: null,
                ccmMasterChecklist: null,
                vesselId: null,
                batchId: null,
                isSignOnCrews: 1,
                signatory: null,
                authUserName: null,
                ccmNo: null,
            },
            //Create new country
            newCountry: {},
            newPort: {},
            hasIsToManualActualizeKey: false,
            minDate: null,
            maxDate: null,
            maxSignOnEOCDate: null,
            maxArrvDate: null,
            missingDates: [],
            maxDatesPerCrew: [],
        }
    },
    computed: {
        ...mapGetters(
            [
                "crewChangeMemoDetails",
                "objSignOffReasons",
                "objCcMemoBatches",
                "objCountries",
                "objPorts",
                "crewChangeRemarks",
                "tagStatusOpt",

                "ccmMasterChecklist",
                "authUser",
                'isReloading',
                'ccmFlagId',
                'ccmFlagName',
            ]
        ),
    },

    methods: {
        ...mapActions(
            [
                'get_crew_change_memo_details',
                'get_cc_manipulate_keys',
                "revert_details",
                "search_cc_memo_no",
                "set_crew_change_remarks",
                "reset_state_crew_change_remark",
                "apply_changes",
                "generate_agent_list",
                "actualize_onboarding",

                // generate ccm masterlist ex. view checklist, mlc olc, checklist docs
                'getCcmMasterChecklistDetails',
                'generateCcmMasterChecklist',

                //Country
                'create_country',

                //Ports
                'create_port',
                'reset_cc_memo_details',
                'reset_state_cc_manipulation',
                'update_cc_memo_details',
            ]
        ),
      hasNoSignOnDate(crew) {
        return (
          !crew.dpManilaDate && !crew.onBoardDepartureDate
        )
      },

      hasNoSignOffDate(crew) {
        return (
          !crew.signOffDate
        )
      },

      validateInputDepartureDate(crew) {
        const deptDate = new Date(crew.dpManilaDate || crew.onBoardDepartureDate);
        if (isNaN(deptDate.getFullYear()) || deptDate.getFullYear() < 1000 || deptDate.getFullYear() > 9999) {
          return;
        }
        if (crew.dpManilaDate < this.minDate) {
          crew.dpManilaDate = this.minDate;
        } else if (crew.dpManilaDate > this.maxDate) {
          crew.dpManilaDate = this.maxDate;
        }
      },

      // Keyboard input validation for sign-on date
      validateInputSignOnDate(crew){
        const signOnDate = new Date(crew.signOnDate);
        if (isNaN(signOnDate.getFullYear()) || signOnDate.getFullYear() < 1000 || signOnDate.getFullYear() > 9999) {
          return;
        }

        const crewMaxDate = this.getMaxDateInField(crew.crewId);

        if (crew.signOnDate < crew.dpManilaDate) {
          crew.signOnDate = crew.dpManilaDate;
        } else if (crew.signOnDate > crewMaxDate) {
          crew.signOnDate = crewMaxDate;
        } else {

        }
      },

      // Keyboard input validation for EOC date
      validateInputEOCDate(crew){
        const EOCDate = new Date(crew.eocDate);
        if (isNaN(EOCDate.getFullYear()) || EOCDate.getFullYear() < 1000 || EOCDate.getFullYear() > 9999) {
          return;
        }

        const crewMaxDate = this.getMaxDateInField(crew.crewId);

        if (crew.eocDate < crew.dpManilaDate) {
          crew.eocDate = crew.dpManilaDate;
        } else if (crew.eocDate > crewMaxDate) {
          crew.eocDate = crewMaxDate;
        } else {

        }
      },

      validateInputSignOffDate(crew) {
        const signOffDate = new Date(crew.signOffDate);
        if (isNaN(signOffDate.getFullYear()) || signOffDate.getFullYear() < 1000 || signOffDate.getFullYear() > 9999) {
          return;
        }
        if (crew.signOffDate < this.minDate) {
          crew.signOffDate = this.minDate;
        } else if (crew.signOffDate > this.maxDate) {
          crew.signOffDate = this.maxDate;
        }
      },

      validateInputArrivalDate(crew){
        const arrivalDate = new Date(crew.arrvManilaDate);
        if (isNaN(arrivalDate.getFullYear()) || arrivalDate.getFullYear() < 1000 || arrivalDate.getFullYear() > 9999) {
          return;
        }

        const crewMaxDate = this.getMaxDateInField(crew.crewId);

        if (crew.arrvManilaDate < crew.signOffDate) {
          crew.arrvManilaDate = crew.signOffDate;
        } else if (crew.arrvManilaDate > crewMaxDate) {
          crew.arrvManilaDate = crewMaxDate;
        } else {

        }
      },

      getMaxDateInField(id) {
        for (let i = 0; i < this.maxDatesPerCrew.length; i++) {
          if (this.maxDatesPerCrew[i].crewId === id) {
            return this.maxDatesPerCrew[i].maxDate;
          }
        }
        return null;
      },

      generateMinMaxDates() {
        const currentDate = new Date();
        this.minDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate()).toISOString().split('T')[0];
        this.maxDate = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate()).toISOString().split('T')[0];
      },

      loadToSignOffParam(crews) {
        crews?.forEach(crew => {
          const {crewId, dpManilaDate, signOnDate, eocDate, signOffDate} = crew;
          const crewSignOffDate = new Date(crew.signOffDate);
          const index = this.ccSignOffs.findIndex(crew => crew.crewId === crewId);
          this.maxArrvDate = new Date(crewSignOffDate.getFullYear() + 1, crewSignOffDate.getMonth(), crewSignOffDate.getDate()).toISOString().split('T')[0];
          if (dpManilaDate && signOnDate && eocDate && signOffDate) {
            if (index === -1) {
              this.ccSignOffs.push(crew);
              this.maxDatesPerCrew.push({
                'ccrId': crew.ccrId,
                'ccId': crew.ccId,
                'crewId': crew.crewId,
                'maxDate': this.maxArrvDate
              })
            }
          } else {
            if (index !== -1) {
              this.ccSignOffs.splice(index, 1);
            }
          }
        });
      },

      loadToSignOnParam(crews) {
        crews?.forEach(crew => {
          const {crewId, dpManilaDate, signOnDate, eocDate, onBoardDepartureDate} = crew;
          const index = this.ccSignOns.findIndex(crew => crew.crewId === crewId);
          // if ((dpManilaDate  && signOnDate && eocDate) || (onBoardDepartureDate)) {
          if ((dpManilaDate || onBoardDepartureDate) || signOnDate || eocDate) {
            const manilaDate = new Date(crew.dpManilaDate || crew.onBoardDepartureDate);
            this.maxSignOnEOCDate = new Date(manilaDate.getFullYear() + 1, manilaDate.getMonth(), manilaDate.getDate()).toISOString().split('T')[0];
            if (index === -1) {
              this.ccSignOns.push(crew);
              this.maxDatesPerCrew.push({
                'ccrId': crew.ccrId,
                'ccId': crew.ccId,
                'crewId': crew.crewId,
                'maxDate': this.maxSignOnEOCDate
              })
            }
          } else {
            if (index !== -1) {
              this.ccSignOns.splice(index, 1);
            }
          }
        });
      },

      updateSigningOnCrewDates(crew) {
        if (!crew.dpManilaDate) {
          crew.signOnDate = null
          crew.eocDate = null
        }
        crew.signOnDate = null
        crew.eocDate = null
        this.maxDatesPerCrew = this.maxDatesPerCrew.filter(el => el.crewId !== crew.crewId);
      },

      updateSigningOffCrewDates(crew) {
        if (!crew.signOffDate) {
          crew.arrvManilaDate = null
          crew.signOffReasonId = null
        }
        crew.arrvManilaDate = null
        crew.signOffReasonId = null
        this.maxDatesPerCrew = this.maxDatesPerCrew.filter(el => el.crewId !== crew.crewId);
      },

      addToSignOnParam(crew) {
        const {ccrId, dpManilaDate, signOnDate, eocDate, onBoardDepartureDate} = crew;
        const index = this.ccSignOns.findIndex(crew => crew.ccrId === ccrId);
        if ((dpManilaDate || onBoardDepartureDate) || signOnDate || eocDate) {
          if (index === -1) {
            this.ccSignOns.push(crew);
          } else {
            this.ccSignOns[index] = crew;
          }
        } else {
          if (index !== -1) {
            this.ccSignOns.splice(index, 1);
          }
        }
      },

      addToSignOffParam(crew) {
        const {ccrId, signOffDate, arrvManilaDate, signOffReasonId} = crew;
        const index = this.ccSignOffs.findIndex(crew => crew.ccrId === ccrId);
        if (signOffDate || arrvManilaDate || signOffReasonId) {
          if (index === -1) {
            this.ccSignOffs.push(crew);
          } else {
            this.ccSignOffs[index] = crew;
          }
        } else {
          if (index !== -1) {
            this.ccSignOffs.splice(index, 1);
          }
        }
      },

      checkMissingDates() {
        this.ccSignOns.forEach(crew => {
          let missingFields = [];
          if (!crew.signOnDate) {
            missingFields.push("Sign-on date");
          }
          if (!crew.eocDate) {
            missingFields.push("E.O.C date");
          }

          if (missingFields.length > 0) {
            this.missingDates.push(`${crew.crewLastName} is missing ${missingFields.join(' and ')}.`);
          }
        });
        this.ccSignOffs.forEach(crew => {
          let missingFields = [];
          if (!crew.arrvManilaDate && !crew.isOnboardPromotion) {
            missingFields.push("Arrival date");
          }
          if (!crew.signOffReasonId) {
            missingFields.push("Reason");
          }
          if (missingFields.length > 0) {
            this.missingDates.push(`${crew.crewLastName} is missing ${missingFields.join(' and ')}.`);
          }
        });
      },

      resetParams() {
        this.ccSignOns = [];
        this.ccSignOffs = [];
      },

      updateSignOnCrewMinMaxDates(crew) {
        let manilaDate = null;
        if (crew.dpManilaDate || crew.onBoardDepartureDate) {
          manilaDate = new Date(crew.dpManilaDate || crew.onBoardDepartureDate);
          this.maxSignOnEOCDate = new Date(manilaDate.getFullYear() + 1, manilaDate.getMonth(), manilaDate.getDate()).toISOString().split('T')[0];
          this.maxDatesPerCrew = this.maxDatesPerCrew.filter(el => el.crewId !== crew.crewId);
          this.maxDatesPerCrew.push({
            'ccrId': crew.ccrId,
            'ccId': crew.ccId,
            'crewId': crew.crewId,
            'maxDate': this.maxSignOnEOCDate
          });
        }
      },

      updateSignOffCrewMinMaxDates(crew) {
        let signOffDate = null;
        if (crew.signOffDate) {
          signOffDate = new Date(crew.signOffDate);
          this.maxArrvDate = new Date(signOffDate.getFullYear() + 1, signOffDate.getMonth(), signOffDate.getDate()).toISOString().split('T')[0];
          this.maxDatesPerCrew = this.maxDatesPerCrew.filter(el => el.crewId !== crew.crewId);
          this.maxDatesPerCrew.push({
            'ccrId': crew.ccrId,
            'ccId': crew.ccId,
            'crewId': crew.crewId,
            'maxDate': this.maxArrvDate
          });
        }
      },

      resetAfterSave(){
        const ccMemoSignOnCrews = this.crewChangeMemoDetails.signOnCrews;
        const ccMemoSignOffCrews = this.crewChangeMemoDetails.signOffCrews;

        this.missingDates = [];
        this.maxDatesPerCrew = [];
        this.resetParams();
        this.loadToSignOnParam(ccMemoSignOnCrews);
        this.loadToSignOffParam(ccMemoSignOffCrews);
      },

        //Add New Country
        async saveCountry() {
            let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Country', 'question')
            if (await __create) {

                let params = {
                    code: this.newCountry.code ? this.newCountry.code : '',
                    name: this.newCountry.name, // this is required
                    natl: this.newCountry.natl,
                    natl_code: this.newCountry.natl_code,
                    address: this.newCountry.address,
                    status: this.newCountry.status ? 1 : 0,
                };

                let newCountry = await this.create_country(params);
                if (newCountry) {
                    await AlertService.successAlertService('Create country record successful', 'Create country');
                    this.hideModal('newCountryModal')
                    this.newCountry = {};
                    this.get_cc_manipulate_keys()
                }
            }
        },

        gotoProfile(thisCrewId) {
            this.$nextTick(() => {
              this.formSelectedCrewId = thisCrewId;
              let routeData = this.$router.resolve({ //
                name: 'CrewProfile',
                params: {
                  crewId: thisCrewId,
                },
                query: {
                  view: 'ccplan'
                }
              });
              window.open(routeData.href, '_blank', "height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));
            })
        },

        //Save New port
        async savePort() {
            let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New port', 'question')
            if (await __create) {

                let params = {
                    code: this.newPort.code ? this.newPort.code : '',
                    name: this.newPort.name, // this is required
                    country_id: this.newPort.country_id ? this.newPort.country_id : '',
                    status: this.newPort.status ? 1 : 0,
                };

                let newPort = await this.create_port(params);
                if (newPort) {
                    await AlertService.successAlertService('Create port record successful', 'Create port');
                    this.hideModal('newPortModal')
                    this.newPort = {};
                    this.get_cc_manipulate_keys()
                }
            }
        },

        onChangeCrewChangeBatches() {
            this.isDataReloading = true;
            this.$nextTick(() => {
               //$(`#searching-body-message`).attr("hidden");
                this.deBounceGetCrewChangeMemoDetails()
            })
        },

        deBounceGetCrewChangeMemoDetails: __.debounce(function () {
            if (this.formCcManipParam !== null) {
                this.searchValue = null;
                delete this.formCcManipParam.searchByCcmNo;
                this.getCrewChangeMemoDetails(this.formCcManipParam)
                this.toBeActualizeList = [];
                this.resetParams();
                this.maxDatesPerCrew = [];
            } else {
              this.reset_cc_memo_details()
              this.isDataReloading = false;
            }
        }, 500),

        getCrewChangeMemoDetails({batchId, vesselId}) {
            this.get_crew_change_memo_details({batchId, vesselId}).then((r) => {
                if (r) {
                    this.isDataReloading = false;
                    this.loadToSignOnParam(this.crewChangeMemoDetails.signOnCrews);
                    this.loadToSignOffParam(this.crewChangeMemoDetails.signOffCrews);
                }
            });
        },

        async applyChanges() {

            // Object.assign(this.ccSignOns).forEach((val,i)=>{
            //   let error = [];
            //   let departureManila = new Date(val.dpManilaDate ? val.dpManilaDate : val.onBoardDepartureDate);
            //   let signOnDate = new Date(val.signOnDate);
            //   let eocDate = new Date(val.eocDate);
            //
            //   if(val.eocDate !== undefined && val.eocDate && val.eocDate !== ''){
            //     let msg =`${val.crewName}:`;
            //     let msg1 = '';
            //     let msg2 = '';
            //
            //     if(departureManila  >= eocDate){
            //        msg1 = ` | end of contract should not before departure date`
            //       error[i] = msg.concat(msg1)
            //     }
            //
            //     if(departureManila > signOnDate){
            //        msg2 = `\t\n | sign on date should not before departure date`
            //       error[i] = msg.concat(msg1,msg2)
            //     }
            //   }
            //   errorMes.push(error);
            // });
            //
            //
            // if(errorMes.length > 0){
            //   let msg = '';
            //   Object.keys(errorMes).forEach((key,index)=>{
            //     msg += `<p class="text-left align-middle">
            //                   ${index + 1}. <small>${errorMes[key]}</small>
            //              </p>`
            //   })
            //
            //   let html = ` <div class="col-md-12">
            //               ${msg}
            //           </div>`;
            //
            //   return AlertService.errorWithResponseDataService(null,html,"Invalid");
            //
            // }else {
            //
            //
            // }
            if (await this.questionAlert("Do you want to apply changes that you made?.")) {
              if (this.searchValue !== null) {
                let defaultCcMemo = JSON.parse(localStorage.getItem("defaultCcMemoDetails"));
                let latest = this.crewChangeMemoDetails;
                this.ccSignOns = this.getChanges(defaultCcMemo.signOnCrews, latest.signOnCrews);
                this.ccSignOffs = this.getChanges(defaultCcMemo.signOffCrews, latest.signOffCrews);
                let errorMes = [];
                const formParams = {
                  batchId: this.crewChangeMemoDetails.ccMemoId,
                  signOnCrews: this.crewChangeMemoDetails.signOnCrews,
                  signOffCrews: this.crewChangeMemoDetails.signOffCrews,
                };
                await this.update_cc_memo_details(formParams).then((r) => {
                  if (r) {
                    this.successAlert("Crew Change Manipulation updated successfully");
                  }
                });
              } else {
                const params = {
                  // signOnCrews: JSON.parse(JSON.stringify(this.ccSignOns)),
                  // signOffCrews : `${ccMemoSignOffCc}`
                }
                //ccMemoSignOffCc.length > 0 &&
                // if (this.ccSignOffs.length > 0 && this.ccSignOffs !== undefined) {
                //   params.signOffCrews = JSON.parse(JSON.stringify(this.ccSignOffs))
                // }
                params.countryId = this.crewChangeMemoDetails.ccMemoCountryId;
                params.portId = this.crewChangeMemoDetails.ccMemoPortId;
                params.etdDate = this.crewChangeMemoDetails.etd;
                params.ccMemoId = this.crewChangeMemoDetails.ccMemoId;

                this.checkMissingDates();

                if (this.missingDates.length > 0) {
                  this.warningValidationAlert(this.missingDates.join(`\n`));
                  this.missingDates = [];
                } else {
                  if (this.crewChangeMemoDetails.signOnCrews.length !== 0 && this.ccSignOns.length < this.ccSignOffs.length) {
                    this.errorAlert("Disembarking number of crew must be equal to the number of corresponding crew reliever");
                  } else if (this.ccSignOns.length > this.ccSignOffs.length && this.ccSignOffs.length === 0) {

                    params.signOnCrews = JSON.parse(JSON.stringify(this.ccSignOns));
                    params.signOffCrews = JSON.parse(JSON.stringify(this.ccSignOffs));

                    this.apply_changes(params).then((r) => {
                      if (r) {
                        this.resetAfterSave();
                        if (this.hasIsToManualActualizeKey) {
                          this.successAlert("Changes successfully applied but some crew may need to be actualized");
                        } else {
                          this.successAlert("Changes successfully applied");
                        }
                      }
                    })
                  } else if (this.ccSignOns.length === this.ccSignOffs.length || (this.ccSignOns.length > this.ccSignOffs.length && this.ccSignOffs.length > 0)) {

                    if (this.ccSignOffs.length === 0 && this.ccSignOns.length === 0) {
                      this.warningAlert("Please select crews");
                      return;
                    }

                    const signOnIds = this.ccSignOns.map(crew => crew.ccrId);
                    const signOffIds = this.ccSignOffs.map(crew => crew.ccrId);
                    const areMatched = signOnIds.every(signOnId => signOffIds.includes(signOnId));
                    if (areMatched) {
                      params.signOnCrews = JSON.parse(JSON.stringify(this.ccSignOns));
                      params.signOffCrews = JSON.parse(JSON.stringify(this.ccSignOffs));
                      this.apply_changes(params).then((r) => {
                        if (r) {
                          this.resetAfterSave();
                          if (this.hasIsToManualActualizeKey) {
                            this.successAlert("Changes successfully applied but some crew may need to be actualized");
                          } else {
                            this.successAlert("Changes successfully applied");
                          }
                        }
                      })
                    } else {
                      const matchedIds = signOnIds.filter(signOnId => signOffIds.includes(signOnId));
                      this.ccSignOns = this.ccSignOns.filter(signOnCrew => matchedIds.includes(signOnCrew.ccrId));
                      this.ccSignOffs = this.ccSignOffs.filter(signOffCrew => matchedIds.includes(signOffCrew.ccrId));
                      params.signOnCrews = JSON.parse(JSON.stringify(this.ccSignOns));
                      params.signOffCrews = JSON.parse(JSON.stringify(this.ccSignOffs));
                      if (this.ccSignOns.length > 0 && this.ccSignOffs.length > 0) {
                        this.apply_changes(params).then((r) => {
                          if (r) {
                            this.resetAfterSave();
                            if (this.hasIsToManualActualizeKey) {
                              this.successAlert("Changes successfully applied but some crew may need to be actualized");
                            } else{
                              this.successAlert("Changes successfully applied");
                            }
                          }
                        })
                      } else {
                        this.errorAlert('Unable to apply changes. Crew does not match');
                      }
                      this.resetAfterSave();
                    }
                  } else if (this.crewChangeMemoDetails.signOnCrews.length === 0 && this.ccSignOffs.length > 0) {
                    params.signOnCrews = JSON.parse(JSON.stringify(this.ccSignOns));
                    params.signOffCrews = JSON.parse(JSON.stringify(this.ccSignOffs));
                    this.apply_changes(params).then((r) => {
                      this.resetAfterSave();
                      if (r) {
                        if (this.hasIsToManualActualizeKey) {
                          this.successAlert("Changes successfully applied but some crew may need to be actualized");
                        } else
                          this.successAlert("Changes successfully applied");
                      }
                    })
                  }
                }
              }
            }
        },

        setHeaderDate(crewType, hdr) {
            this.crewType = crewType;
            switch (hdr) {
                //date today
                case 1://departure date
                    this.dateHeaderType = 1;
                    break;

                case 2:
                    //signon date
                    this.dateHeaderType = 2;
                    break;

                case 3:
                    //end of contract
                    this.dateHeaderType = 3;
                    break;

                case 4:
                    //sign off date
                    this.dateHeaderType = 4;
                    break;

                case 5:
                    //arrival date
                    this.dateHeaderType = 5;
                    break;

                default:
                    break;
            }
        },

        saveHeaderDate() {
            let crews = this.crewType === 1 ? this.crewChangeMemoDetails.signOnCrews : this.crewChangeMemoDetails.signOffCrews;
            Object.assign(crews).forEach((val, index) => {
                switch (this.dateHeaderType) {
                    case 1:
                        if (val.isOnboardPromotion) {
                            //val.dpManilaDate = val.onBoardDepartureDate;
                        } else {
                          if (this.dateHeaderVal > this.maxDate) {
                            val.dpManilaDate = this.maxDate
                          } else if (this.dateHeaderVal < this.minDate) {
                            val.dpManilaDate = this.minDate
                          } else
                            val.dpManilaDate = this.dateHeaderVal
                          const found = this.maxDatesPerCrew.some(el => el.crewId === val.crewId);
                          if (!found) {
                            // this.maxDatesAllFields.push({
                            //   'ccrId': val.ccrId,
                            //   'ccId' : val.ccId,
                            //   'crewId': val.crewId,
                            //   'maxDate': this.maxSignOnEOCDate
                            // })
                          }
                          if (found) {
                            this.maxDatesPerCrew = this.maxDatesPerCrew.filter(el => el.crewId !== val.crewId);
                          }
                          this.addToSignOnParam(val);
                          this.updateSignOnCrewMinMaxDates(val);
                          this.maxDatesPerCrew.push({
                            'ccrId': val.ccrId,
                            'ccId': val.ccId,
                            'crewId': val.crewId,
                            'maxDate': this.maxSignOnEOCDate
                          });
                          val.signOnDate = null;
                          val.eocDate = null;
                        }
                        break;

                    case 2:
                        if (this.hasNoSignOnDate(val)) {
                          val.signOnDate = null;
                        } else {
                          const found = this.maxDatesPerCrew.some(el => el.crewId === val.crewId);
                          const dateData = this.getMaxDateInField(val.crewId);

                          if (found && this.dateHeaderVal > this.getMaxDateInField(val.crewId)){
                            val.signOnDate = dateData;
                          } else if (found && this.dateHeaderVal < val.dpManilaDate){
                            val.signOnDate = val.dpManilaDate || val.onBoardDepartureDate;
                          } else{
                            val.signOnDate = this.dateHeaderVal;
                          }
                        }
                        break;

                    case 3:
                      if (this.hasNoSignOnDate(val)) {
                        val.eocDate = null;
                      } else {
                        const found = this.maxDatesPerCrew.some(el => el.crewId === val.crewId);
                        const dateData = this.getMaxDateInField(val.crewId);
                        if (found && this.dateHeaderVal > dateData){
                          val.eocDate = dateData;
                        } else if (found && this.dateHeaderVal < val.dpManilaDate){
                            val.eocDate = val.dpManilaDate || val.onBoardDepartureDate;
                        } else{
                            val.eocDate = this.dateHeaderVal;
                        }
                      }
                        break;

                    case 4:
                        if (val.isOnboardPromotion) {
                            //
                        } else {
                          if (this.dateHeaderVal > this.maxDate) {
                            val.signOffDate = this.maxDate
                          } else if (this.dateHeaderVal < this.minDate) {
                            val.signOffDate = this.minDate
                          } else
                            val.signOffDate = this.dateHeaderVal

                          const found = this.maxDatesPerCrew.some(el => el.crewId === val.crewId);

                          if (!found) {
                            // this.maxDatesAllFields.push({
                            //   'ccrId': val.ccrId,
                            //   'ccId' : val.ccId,
                            //   'maxDate': this.maxSignOnEOCDate
                            // })
                          }

                          if (found) {
                            this.maxDatesPerCrew = this.maxDatesPerCrew.filter(el => el.crewId !== val.crewId);
                          }
                          this.addToSignOffParam(val);
                          this.updateSignOffCrewMinMaxDates(val);
                          this.maxDatesPerCrew.push({
                            'ccrId': val.ccrId,
                            'ccId': val.ccId,
                            'crewId': val.crewId,
                            'maxDate': this.maxArrvDate
                          });
                          val.arrvManilaDate = null;
                        }
                        break;

                    case 5:
                        if (val.isOnboardPromotion) {
                            //
                        }else if (this.hasNoSignOffDate(val)){
                          val.arrvManilaDate = null;
                        } else {
                          const found = this.maxDatesPerCrew.some(el => el.crewId === val.crewId);
                          const dateData = this.getMaxDateInField(val.crewId);
                          if (found && this.dateHeaderVal > dateData){
                            val.arrvManilaDate = dateData;
                          } else if (found && this.dateHeaderVal < val.signOffDate){
                            val.arrvManilaDate = val.signOffDate;
                          } else{
                            val.arrvManilaDate = this.dateHeaderVal;
                          }
                        }
                        break;
                }
            });

            this.$nextTick(() => {
                this.dateHeaderVal = null;
                $(`#header-date-input-modal`).modal('hide');
                $(`#crew-details-is-onboard`).empty();
            })
        },


        /**
         * @author Use to compare object of array, if there is changes
         * e.g   obj1 =  [1,2,3] , obj2 [1,2,2],   return obj2 = [1,2,2]
         */
        getChanges(previous, current) {
            if (this.isPrimitive(previous) && this.isPrimitive(current)) {
                if (previous === current) {
                    return "";
                }
                return current;
            }

            if (this.isObject(previous) && this.isObject(current)) {
                const diff = this.getChanges(Object.entries(previous), Object.entries(current))

                return diff.reduce((merged, [key, value]) => {
                    return {
                        ...merged,
                        [key]: value
                    }
                }, {})
            }

            //temporary container for the changes
            const changes = [];

            //comparing two array of object is same length
            if (JSON.stringify(previous) === JSON.stringify(current)) {
                return changes;
            }

            //Loop for each element or property then compare
            for (let i = 0; i < current.length; i++) {
                const item = current[i];

                if (JSON.stringify(item) !== JSON.stringify(previous[i])) {
                    changes.push(item);
                }
            }
            return changes;
        },

        typeOf(o) {
            return Object.prototype.toString.call(o);
        },

        isObject(o) {
            return o !== null && !Array.isArray(o) && this.typeOf(o).split(" ")[1].slice(0, -1) === "Object";
        },

        isPrimitive(o) {
            let val = "";
            switch (typeof o) {
                case "object":
                    val = false;
                    break;

                case "function":
                    val = false;
                    break;

                default:
                    val = true;
                    break;
            }
            return val;
        },
        onChangeDepartureDate(crew) {
          this.updateSigningOnCrewDates(crew);
          this.validateInputDepartureDate(crew);
          this.addToSignOnParam(crew);
          this.updateSignOnCrewMinMaxDates(crew);
        },
        onChangeSignOnDate(crew) {
          if (!crew.signOnDate){
            crew.signOnDate = null;
          }
          this.validateInputSignOnDate(crew);
        },
        onChangeEndOfContract(crew) {
          if (!crew.eocDate){
            crew.eocDate = null;
          }
          this.validateInputEOCDate(crew);
        },
        onChangeSignOffDate(crew) {
          this.updateSigningOffCrewDates(crew);
          this.validateInputSignOffDate(crew);
          this.addToSignOffParam(crew)
          this.updateSignOffCrewMinMaxDates(crew);
        },
        onChangeArrivalDate(crew){
          if (!crew.arrvManilaDate){
            crew.arrvManilaDate = null;
          }
          this.validateInputArrivalDate(crew);
        },
        onBlurDepartureDate(crew) {
          const deptDate = new Date(crew.dpManilaDate || crew.onBoardDepartureDate);
          const currentDate = new Date();
          const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).toISOString().split('T')[0];
          if (isNaN(deptDate.getDate()) && isNaN(deptDate.getMonth())){
            return;
          }
          if (isNaN(deptDate.getFullYear()) || deptDate.getFullYear() < 1000 || deptDate.getFullYear() > 9999) {
            // crew.dpManilaDate = null;
            crew.dpManilaDate = today;
          }
          this.addToSignOnParam(crew);
          this.updateSignOnCrewMinMaxDates(crew);
        },
        onBlurSignOnDate(crew) {
          const signOnDate = new Date(crew.signOnDate);
          if (isNaN(signOnDate.getFullYear()) || signOnDate.getFullYear() < 1000 || signOnDate.getFullYear() > 9999) {
            crew.signOnDate = crew.dpManilaDate || crew.onBoardDepartureDate;
          }
        },
        onBlurEOCDate(crew) {
          const EOCDate = new Date(crew.eocDate);
          if (isNaN(EOCDate.getFullYear()) || EOCDate.getFullYear() < 1000 || EOCDate.getFullYear() > 9999) {
            crew.eocDate = crew.dpManilaDate || crew.onBoardDepartureDate;
          }
        },
        onBlurSignOffDate(crew) {
          const signOffDate = new Date(crew.signOffDate);
          const currentDate = new Date();
          const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).toISOString().split('T')[0];
          if (isNaN(signOffDate.getFullYear()) || signOffDate.getFullYear() < 1000 || signOffDate.getFullYear() > 9999) {
            crew.signOffDate = today;
          }
          this.addToSignOffParam(crew);
          this.updateSignOffCrewMinMaxDates(crew);
        },
        onBlurArrivalDate(crew) {
          const arrivalDate = new Date(crew.arrvManilaDate);
          if (isNaN(arrivalDate.getFullYear()) || arrivalDate.getFullYear() < 1000 || arrivalDate.getFullYear() > 9999) {
            crew.arrvManilaDate = crew.signOffDate;
          }
        },
        addCountry() {
        },
        addPort() {
        },
        deBounceSearchCcMemoNo: __.debounce(function () {
            this.isDataReloading = true;
            this.formCcManipParam = null;
            this.resetParams();
            this.maxDatesPerCrew = [];
            this.$nextTick(()=>{
              this.searchCcMemoNo()
            })
        }, 500),
        //searchCcMemoNo
        searchCcMemoNo() {
          this.search_cc_memo_no({searchValue: this.searchValue}).then((r) => {
                if (r) {
                    this.isDataReloading = false;
                    this.successAlert("Loading Crew Change Memo Successfully")
                    if(!this.crewChangeMemoDetails.hasOwnProperty('signOnCrews')
                        && !this.crewChangeMemoDetails.hasOwnProperty('signOffCrews') && this.searchValue !== null){
                        $(`#searching-body-message`).removeAttr('hidden');
                      }else {
                          $(`#searching-body-message`).attr('hidden');
                    }
                }
            })
        },

        /**
         * @author getCrewChangeRemarks
         */
        getCrewChangeRemarks({ccId}) {
            this.set_crew_change_remarks({crewChangeId: ccId})
            // this.reset_state_crew_change_remark()
        },

        revertDetails(type, ccId) {
            this.revert_details({type, ccId});
            this.ccSignOns = this.ccSignOns.filter(crew => crew.ccId !== ccId);
            this.ccSignOffs = this.ccSignOffs.filter(crew => crew.ccId !== ccId);
            this.maxDatesPerCrew = this.maxDatesPerCrew.filter(el => el.ccId !== ccId);
        },

        generateAgentList() {
            let objCcMemo = this.crewChangeMemoDetails
            this.formAgentParam.batchId = objCcMemo.ccMemoId;
            $(`#agent-checklist-container`).empty();

            $(`#cc-agentlist-modal`).modal({
                backdrop: 'static',
                keyboard: false,
            });
            if (this.searchValue !== null){
                this.formAgentParam.searchByCcmNo = true;
            }
            let loading = this.loaderTag();
            $(`#agent-checklist-container`).append(loading);

            this.$nextTick(() => {
                this.generate_agent_list(this.formAgentParam).then((r) => {
                    this.objNotedByKeys = r.objNotedByKeys;
                    let fScr = this.base64PdfParser(r.file)
                    let fileFrame = `<iframe src="${fScr}" id="cc-details-file-frame" style="height: 400px; width: 100%;" frameborder="0"></iframe>`;
                    $(`#agent-checklist-container`).empty();
                    $(`#agent-checklist-container`).append(fileFrame);

                }).catch((er) => {
                    $(`#agent-checklist-container`).empty();
                    $(`#agent-checklist-container`).append(`<p style="color: red;">Error while generating agent list report.</p>`);

                })
            })
        },
        closeAgentListModal() {
            $(`#agent-checklist-container`).empty();
            this.objNotedByKeys = [];
            this.formAgentParam = {};
            $(`#cc-inputted-port`).val(" ");
        },

        notedByNameSpace({name, position}) {
            return `${name.toUpperCase()} - ${position.toUpperCase()}`
        },

        onChangeNotedBy(e) {
            let notedByVal = parseInt(e.target.value, 10);
            this.$nextTick(() => {
                this.formAgentParam.notedBy = notedByVal;
                this.generateAgentList()
            })
        },

        onKeyDownPort: __.debounce(function (e) {
            let portVal = e.target.value;
            this.formAgentParam.portName = portVal;
            this.$nextTick(() => {
                this.generateAgentList();
            })
        }, 500),

        /**
         *
         *
         */
        onCheckActualizeOnboard(event, objCc) {
            if (event.target.checked) {
                //push value
                this.toBeActualizeList.push({ccrId: objCc.ccrId})
            } else {
                this.toBeActualizeList.splice(
                    this.toBeActualizeList.findIndex((val, index) => {
                        return val.ccrId = objCc.ccrId;
                    }), 1);
            }
        },

        async actualizeOnboarding() {
            let defaultCcMemo = JSON.parse(localStorage.getItem("defaultCcMemoDetails"));
            let latest = this.crewChangeMemoDetails;
            this.ccSignOns = this.getChanges(defaultCcMemo.signOnCrews, latest.signOnCrews);
            this.ccSignOffs = this.getChanges(defaultCcMemo.signOffCrews, latest.signOffCrews);
            let msg = `Are you sure you want to actualize the onboarding of selected records.`
            if (await this.questionAlert(`${msg}`, "Actualize")) {
              if(this.searchValue !== null){
                const formParams = {
                  batchId : this.crewChangeMemoDetails.ccMemoId,
                  signOnCrews: this.crewChangeMemoDetails.signOnCrews,
                  signOffCrews: this.crewChangeMemoDetails.signOffCrews,
                };
                await this.update_cc_memo_details(formParams).then((r) => {
                  if (r) {
                    this.successAlert("Crew Change Manipulation updated successfully");
                  }
                });
              }else {
                const params = {
                  signOnCrews: JSON.parse(JSON.stringify(this.ccSignOns)),
                }
                if (this.ccSignOffs.length > 0 && this.ccSignOffs !== undefined) {
                  params.signOffCrews = JSON.parse(JSON.stringify(this.ccSignOffs))
                }
                params.countryId = this.crewChangeMemoDetails.ccMemoCountryId;
                params.portId = this.crewChangeMemoDetails.ccMemoPortId;
                params.etdDate = this.crewChangeMemoDetails.etd;
                params.ccMemoId = this.crewChangeMemoDetails.ccMemoId;
                this.apply_changes(params).then((r) => {
                  if (r) {
                    const formData = {
                      ccMemoId: this.crewChangeMemoDetails.ccMemoId,
                      data: this.toBeActualizeList
                    }
                    this.actualize_onboarding(formData).then((r) => {
                      if (r) {
                        this.successAlert("Changes Successfully Applied", "Actualize")
                        this.$nextTick(() => {
                          window.location.reload();
                        })
                      }
                    })
                  }
                })
              }

            }
        },

        viewChecklist() {
            const isSignOn = 1;
            this.viewChecklistParams.batchId = this.crewChangeMemoDetails.ccMemoId;
            this.viewChecklistParams.vesselId = this.crewChangeMemoDetails.ccMemoVesselId;
            this.viewChecklistParams.isSignOnCrews = isSignOn;
            this.viewChecklistParams.ccmNo = this.crewChangeMemoDetails.ccMemoNo;
            this.viewChecklistParams.authUserName = this.authUser.name ? this.authUser.name.toUpperCase() : ''

            this.viewChecklistParams.searchByCcmNo = this.searchValue !== null;
            this.getCcmMasterChecklistDetails(this.viewChecklistParams)
        },

        closeViewChecklistModal() {
            $(`#view-checklist-signon`).modal('hide');
        },

        async loadViewChecklist(ccmMasterChecklist) {
            this.viewChecklistParams.ccmMasterChecklist = ccmMasterChecklist;
            this.viewChecklistParams.batchId = this.crewChangeMemoDetails.ccMemoId;
            this.viewChecklistParams.vesselId = this.crewChangeMemoDetails.ccMemoVesselId;

            const progressModal = $('#generatingAnimationModal')
            progressModal.modal('show');
            let ccmDocumentFile = await this.generateCcmMasterChecklist(this.viewChecklistParams);
            if (ccmDocumentFile) {
                progressModal.modal('hide');
                await this.pdfViewerMasterChecklist(global_variables_config.ccmMasterDocsName[ccmMasterChecklist] + ' File', ccmDocumentFile);
            } else {
                progressModal.modal('hide');
            }
        },
    },

    //mounted here.
    mounted() {
    },
    created() {
        this.get_cc_manipulate_keys()
        this.dateHeaderType = null;
        this.crewType = null;
        this.dateHeaderVal = null;
        this.$globalVariables = global_variables_config;
        this.generateMinMaxDates();
    },
    destroyed() {
        this.reset_state_crew_change_remark();
        this.reset_state_cc_manipulation();
        this.isQualifiedToActualize = false;
    },
}
</script>

<style scoped>

.no-data-header-date {
    border: 2px solid #FA5366FF !important;
    box-shadow: 0 0 10px #719ECE !important;
}

.gray {
  border: 1px solid rgb(128, 128, 128) !important;
  color: rgb(128, 128, 128);
  box-shadow: none !important;
}

.has-data-header-date {
    border: 2px solid #55ff41 !important;
    box-shadow: 0 0 10px #719ECE !important;
}


.has-onboard-promotion {
    border: 2px solid #ababab !important;
    box-shadow: 0 0 10px #719ECE !important;
}

.approve-css {
    color: green;
    font-weight: bold;
}

.not-yet-approve-css {
    color: red;
    font-weight: bold;
}

.custom-modal-size {
    max-width: 80%;
    margin: 0 auto;
}

</style>
