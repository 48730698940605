import axios from 'axios'
import { UtilityService } from '../../../service/utility.service'
import { PMC } from '../../../config/constants.config'

/** state **/
const state = {
  crewComment: {},
  crewComments: [],

  newCrewComment: {
    crewId: null,
    comment: null,
  },

  loadCrewComments : false,


}

/** mutations **/
const mutations = {
  SET_COMMENT () {
    //todo
  },

  SET_COMMENTS (state, params) {
    state.crewComments = params
  },

  CREATE_CREW_COMMENT (state, params) {
    state.crewComments.unshift(params)
    state.newCrewComment = {
      crewId: null,
      comment: null,
    }
  },

  UPDATE_CREW_COMMENT (state, params) {
    let crewCom = state.crewComments.find((ccom, index) => ccom.id === params.id)
    Object.assign(crewCom, params)
  },

  DELETE_CREW_COMMENT (state, params) {
    let index = state.crewComments.findIndex((ccom, index) => ccom.id === params.id)
    state.crewComments.splice(index, 1)
  },

  NULL_COMMENT (state) {
    state.crewComment = {}
  },

  NULL_COMMENTS (state) {
    state.crewComments = []
  },

  set_load_crew_comments(state,payload){
    state.loadCrewComments = payload
  }

}

/** actions **/
const actions = {

  //for admin
  //getAllCrewCommentPaginated(){},

  getCrewComments ({ commit }, params) {
    commit('set_load_crew_comments',true)
    return axios.get(PMC.CREW_COMMENT, { params }).then((res) => {
      commit('SET_COMMENTS', res.data)
      commit('set_load_crew_comments',false)
      return true
    }).catch((err) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(err, 'Crew Comments')
      return false
    })
  },

  // viewCrewComment({commit},param){},

  createCrewComment ({ commit }, params) {
    let parseParam = UtilityService.deCapitalizeProperties(params)
    return axios.post(PMC.CREW_COMMENT, parseParam).then((res) => {
      commit('CREATE_CREW_COMMENT', res.data)
      commit('add_crew_comment_count')
      return true

    }).catch((err) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(err, 'Create Crew Comment')
      return false

    })
  },

  updateCrewComment ({ commit }, params) {
    return axios.put(`${PMC.CREW_COMMENT}/${params.id}`, params).then((res) => {
      commit('UPDATE_CREW_COMMENT', res.data)
      return true
    }).catch((er) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(er, 'Update Comment')
      return false
    })
  },

  deleteCrewComment ({ commit }, params) {
    return axios.delete(`${PMC.CREW_COMMENT}/${params}`).then((res) => {
      commit('DELETE_CREW_COMMENT', res.data)
      commit('sub_crew_comment_count')
      return true
    }).catch((err) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(err, 'Delete Comment')
      return false
    })
  },

  nullCrewComments ({ commit }) {
    commit('NULL_COMMENTS')
  },

  nullCrewComment ({ commit }) {
    commit('NULL_COMMENT')
  },

}

/** getters **/
const getters = {
  crewComment: state => state.crewComment,
  crewComments: state => UtilityService.capitalizeProperties(state.crewComments),
  newCrewComment: state => state.newCrewComment,
  loadCrewComments : state => state.loadCrewComments,
}

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
}
