import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  rank: {},
  ranks: [],
  newRank: {},

  rankFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  rankPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },


};


const mutations = {
  set_ranks(state, params) {
    state.ranks = params
  },

  set_rank(state, params) {
    state.rank = params
  },

  create_rank (state) {
    state.newRank = {};
  },

  update_rank (state, params) {
    // let rank = state.ranks.find((val, index) => val.id === params.id)
    // // let rank = state.ranks.findIndex(val => val.id == params.id);
    // Object.assign(rank, params)

    let index = state.ranks.findIndex(val => val.id == params.id);
    state.ranks.splice(index, 1, params);

  },

  delete_rank (state, params) {
    let index = state.ranks.findIndex((val, index) => val.id === params)
    state.ranks.splice(index, 1)
  },

  set_rank_pagination(state, params) {
    state.rankPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },

  reset_rank_formparam(state) {
    state.rankFormParam = {
      searchValue: null,
      type: 1,
      level: 1,
      count: 10,
      status: 1,
      page: null,
    }
  },

  reset_rank_pagination(state) {
    state.rankPagination = {
      from: 1,
      currentPage: 1,
      lastPage: 0,
      perPage: 10,
      to: 10,
      total: 0,
    }
  },

  null_ranks(state) {
    state.ranks = []
  },

  null_rank(state) {
    state.rank = []
  },

};


const actions = {
  async set_ranks({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.RANK, {params}).then((r) => {
      commit('set_ranks', r.data.data)
      commit('set_rank_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get ranks')
      return false
    })
  },

  async set_rank({commit}, params) {
    return axios.get(`${PMC.RANK}/${params}`).then((r) => {
      commit('set_rank', r.data)
      return true
    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'Rank details')
      return false
    })
  },

  async create_rank({commit}, params) {
      return axios.post(PMC.RANK, UtilityService.deCapitalizeProperties(params)).then((r) => {
        commit('create_rank', r.data);
        return true;

      }).catch((error) => {
        UtilityService.generateResponseMessage(error, 'Create rank');
        return false;
      })
  },

  async update_rank({commit}, params) {
      return axios.put(`${PMC.RANK}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
        commit('update_rank',r.data.updatedRank);
        return true;

      }).catch((error)=>{
        UtilityService.generateResponseMessage(error,'Update rank');
        return false;

      });
  },

  async delete_rank ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Rank','question');
    if(await _del){
      return axios.delete(`${PMC.RANK}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete rank');
        commit('delete_rank',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.generateResponseMessage(er,"Delete rank");
        return false;

      })
    }
  },

  reset_rank_formparam({commit}) {
    commit('reset_rank_formparam')
  },

  reset_rank_pagination({commit}) {
    commit('reset_rank_pagination')
  },

  null_ranks({commit}) {
    commit('null_ranks')
  },

  null_rank({commit}) {
    commit('null_rank')
  },


};


const getters = {
  ranks: state => UtilityService.capitalizeProperties(state.ranks),
  rank: state => UtilityService.capitalizeProperties(state.rank),
  newRank: state => UtilityService.capitalizeProperties(state.newRank),

  rankFormParam: state => state.rankFormParam,
  rankPagination: state => state.rankPagination,
};


/** export **/
export default {state, actions, mutations, getters};
