<template>
  <div class="px-1 bg-white" style="width: 100%; overflow-x: hidden;">
    <div class="row pmc-bg-old-light">
      <div class="col-md-12">
        <div class="card border-0">
          <div class="card-body pmc-bg-old-light" style="height: 100%;">
            <div class="row">
              <div class="col-md-2">
                <div id="crew-img-container">
                  <img id="crew-img" :src="crewImage" alt="crewImage" class="img-fluid">

                  <div class="row text-center mt-1">
                    <div class="col justify-content-center">
                      <pmc-modal-btn :button-class="'btn btn-sm pmc-btn-warning'"
                                     :target-modal="imageUploadModal"
                                     :button-label="'update'">
                        <template slot="button-icon">
                          <font-awesome-icon icon="upload"/>
                        </template>
                      </pmc-modal-btn>

                      <button class="btn btn-sm pmc-btn-danger ml-1" v-if="crew.crewImg" v-on:click="remove_img">
                        <font-awesome-icon icon="user-times"/>
                        delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4 text-left">
                <div class="form-group row">
                  <label for="crew-name" class="col-sm-4 col-form-label">NAME</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           readonly disabled
                           class="form-control form-control-sm form-control-plaintext-custom"
                           id="crew-name"
                           name="CrewName"
                           placeholder="--"
                           :value="crew.name2">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="crew-rank" class="col-sm-4 col-form-label">RANK</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           readonly disabled
                           class="form-control form-control-sm form-control-plaintext-custom"
                           id="crew-rank"
                           name="CrewRank"
                           placeholder="--"
                           :value="crew.rank">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="crew-type" class="col-sm-4 col-form-label">CREW TYPE</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left">
                    <input type="text"
                           readonly disabled
                           class="form-control form-control-sm form-control-plaintext-custom"
                           id="crew-type"
                           value="Scholar"
                           placeholder="--"
                           :value="crew.crewType">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="crew-scholar" class="col-sm-4 col-form-label">SCHOLAR TYPE</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left">
                    <div type="text"
                         readonly disabled
                         class="form-control form-control-sm form-control-plaintext-custom"
                         id="crew-scholar">
                      {{ crew.scholarType }}
                    </div>
                    <span class="small" v-if="crew.scholarProp">({{ crew.scholarProp }})</span>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="crew-upgrade" class="col-sm-4 col-form-label">UPGRADE</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left small">
                    <input type="text"
                           readonly disabled
                           class="form-control form-control-sm form-control-plaintext-custom"
                           id="crew-upgrade"
                           placeholder="--"
                           :value="crew.updgrade">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label class="col-sm-4 col-form-label">BOOSTER</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left small">
                    <label v-if="crew.covidVaccine" class="form-control-plaintext-custom" id="crew-covid-vaccine">
                      {{ crew.covidBooster }}
                      <!--<span class="ml-2 rounded pmc-bg-warning small">{{crew.covidVaccineLabel}}</span>-->
                      <span class="badge pmc-bg-info badge-counter">{{ crew.covidBoosterLabel }}</span>
                    </label>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label class="col-sm-4 col-form-label">COVID VACCINE</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left small">
                    <label v-if="crew.covidVaccine" class="form-control-plaintext-custom" id="crew-covid-vaccine">
                      {{ crew.covidVaccine }}
                      <!--<span class="ml-2 rounded pmc-bg-warning small">{{crew.covidVaccineLabel}}</span>-->
                      <span class="badge pmc-bg-warning badge-counter">{{ crew.covidVaccineLabel }}</span>
                    </label>
                  </div>
                </div>


                <div class="form-group row mt-1">
                  <label for="crew-type" class="col-sm-4 col-form-label">AGE</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left">
                    <input type="text"
                           readonly disabled
                           class="form-control form-control-sm form-control-plaintext-custom"
                           placeholder="--"
                           :value="crew.age">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="crew-type" class="col-sm-4 col-form-label">BMI</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left">
                    <input type="text"
                           readonly disabled
                           class="form-control form-control-sm form-control-plaintext-custom"
                           placeholder="--"
                           :value="crew.bmi">
                  </div>
                </div>

              </div>

              <div class="col-md-5 h-75 text-left">
                <div class="form-group row">
                  <label for="crew-srn-number" class="col-sm-4 col-form-label">SRN NO</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left">
                    <input type="text"
                           v-model="crew.srnNumber"
                           readonly disabled
                           class="form-control form-control-sm form-control-plaintext-custom"
                           placeholder="--"
                           id="crew-srn-number"
                           name="crewSrnNumber">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="crew-service-status" class="col-sm-4 col-form-label">SEA SERVICE STATUS</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left">

                    <span class="text-primary" style="font-size: 13px;">{{ crew.seaServiceStatus }}</span>

<!--                    <div class="form-inline">-->
<!--                      <div class="form-group mr-3" v-if="crew.seaServiceStatus">-->
<!--                        <span class="form-control-plaintext-custom" style="font-size: 12px;">{{ crew.seaServiceStatus }}</span>-->
<!--                      </div>-->
<!--                      <div class="form-group mt-0">-->
<!--                       <span class="badge bg-success badge-counter" style="color: white" v-if="crew.lineUpCrewChangeVessel ">-->
<!--                      LINED UP - {{ crew.lineUpCrewChangeVessel }}-->
<!--                    </span>-->
<!--                      </div>-->
<!--                    </div>-->

                  </div>
                </div>


                <!--  TODO status , in active, decease, Not for rehire.  -->
                <div class="form-group row mt-1">
                  <label for="crew-current-vessel" class="col-sm-4 col-form-label">CREW STATUS</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left">
                    <input type="text"
                           readonly disabled
                           class="form-control form-control-sm form-control-plaintext-custom"
                           id="crew-current-vessel"
                           placeholder="--"
                           :value="crew.crewStatus">
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="crew-current-vessel" class="col-sm-4 col-form-label">CURRENT VESSEL</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left">
                    <input type="text"
                           readonly disabled
                           class="form-control form-control-sm form-control-plaintext-custom"
                           id="crew-current-vessel"
                           placeholder="--"
                           :value="crew.currentVessel">
                  </div>
                </div>


                <div class="form-group row mt-1">
                  <label for="crew-prv-vessel" class="col-sm-4 col-form-label">PREVIOUS VESSEL</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left">
                    <input type="text"
                           readonly disabled
                           class="form-control form-control-sm form-control-plaintext-custom"
                           id="crew-prv-vessel"
                           placeholder="--"
                           :value="crew.previousVessel">
                  </div>
                </div>

                <!-- todo make it clickable for updating crew date availability -->
                <div class="form-group row mt-1">
                  <label for="crew-availability" class="col-sm-4 col-form-label">AVAILABILITY</label>
                  <span>:</span>
                  <div class="col-sm-6 text-left">
                    <input type="text"
                       disabled
                       v-if="crew && !isDateAvailability"
                       :class="[isDateAvailability ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                       id="crew-date-started"
                       name="CrewSchoolName"
                       placeholder="--"
                       :value="crew.availability ? momentAvailability() : ''">
                    <input
                      type="date"
                      v-if="isDateAvailability && crew"
                      v-model="crew.availability"
                      class="form-control form-control-sm"
                      :min="restrictDate()">
                  </div>
                  <button
                      class="btn btn-xs" :class="[!isDateAvailability ? 'btn-warning' : 'btn-success']"
                      @click="editAvailability"
                      id="edit-date-availability"
                  >
                    <font-awesome-icon icon="edit" v-if="!isDateAvailability"/>
                    <font-awesome-icon icon="save" v-if="isDateAvailability"/>
                  </button>
                  <button class="btn btn-xs btn-danger ml-2" v-if="isDateAvailability" @click="cancelAvailability"
                          id="edit-date-availability">
                    <font-awesome-icon icon="times"/>
                  </button>
                </div>


                <div class="form-group row mt-1">
                  <label for="" class="col-sm-4 col-form-label">LAST COMMENT</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6 text-left">
                    <input v-model="crew.dateLastComment"
                           readonly disabled
                           type="text"
                           class="form-control form-control-sm form-control-plaintext-custom">
                  </div>
                  <button class="btn btn-xs pmc-btn-prime" title="Go to comment" @click="gotoComment">
                    <font-awesome-icon icon="comment"/>
                  </button>
                </div>

              </div>

              <div class="col-sm-1 text-left">
                <div class="btn-group-vertical" role="group" aria-label="Basic example" style="font-size: 0.9rem!important;">
                  <button type="button" class="btn btn-sm btn-outline-secondary border-0 mr-1 text-left"
                          @click="onclick_getBiodata"
                          data-toggle="modal" data-target="#biodata-modal">
                    <small class="font-weight-bold">Biodata</small>
                  </button>
                  <button type="button" class="btn btn-sm btn-outline-secondary border-0 text-left mt-1"
                          @click="getDocumentChecklist(crewId)"
                          data-toggle="modal"
                          data-target="#disembarkation-modal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <small class="font-weight-bold">Disembarked Checklist</small>
                  </button>
                  <button type="button" class="btn btn-sm btn-outline-secondary border-0 text-left mt-1"
                          @click="loadEndorsedCrewReport">
                    <small class="font-weight-bold">Endorsed Crew</small>
                  </button>

                  <!--                  <button type="button" class="btn btn-sm pmc-bg-prime-light border-0 text-left mt-1"-->
                  <!--                          @click="loadEndorsedCrewReport">-->
                  <!--                    <small>ITF</small>-->
                  <!--                  </button>-->

                  <!--                  <button type="button" class="btn btn-sm pmc-bg-prime-light border-0 text-left mt-1"-->
                  <!--                          @click="loadEndorsedCrewReport">-->
                  <!--                    <small>POEA</small>-->
                  <!--                  </button>-->

                  <!--                  <button type="button" class="btn btn-sm pmc-bg-prime-light border-0 text-left mt-1"-->
                  <!--                          @click="loadEndorsedCrewReport">-->
                  <!--                    <small>RPS</small>-->
                  <!--                  </button>-->

                  <!--                  <button type="button" class="btn btn-sm pmc-bg-prime-light border-0 text-left mt-1"-->
                  <!--                          @click="loadEndorsedCrewReport">-->
                  <!--                    <small>Info Sheet</small>-->
                  <!--                  </button>-->

                  <!--                  <button type="button" class="btn btn-sm pmc-bg-prime-light border-0 text-left mt-1"-->
                  <!--                          @click="loadEndorsedCrewReport">-->
                  <!--                    <small>RPS Ammendment</small>-->
                  <!--                  </button>-->
                </div>
              </div>

              <crew-biodata :crew-image="crewImage" :crew="crew"></crew-biodata>
              <!--              {{ crewImage }}-->


              <div class="modal fade" id="disembarkation-modal" tabindex="-1" role="dialog"
                   aria-labelledby="PreDepartureChecklist" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title font-weight-bolder" id="">Disembarkation Checklist</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">X</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="card">
                        <div class="card-header">
                          <div class="row">
                            <div class="col-md-3">
                              <h6>Crew Details</h6>
                            </div>
                            <div class="col-md-9">
                              <div class="form-inline" style="float: right;">
                                <div class="form-group">
                                  <pmc-btn
                                    v-if="!isEditEnable"
                                    @buttonEvent="set_editable"
                                    :button-class="'btn btn-xs pmc-bg-warning ml-1'"
                                    :button-label="'Edit'">
                                    <template slot="button-icon">
                                      <font-awesome-icon icon="edit"/>
                                    </template>
                                  </pmc-btn>
                                  <pmc-btn
                                    v-if="isEditEnable"
                                    @buttonEvent="cancel()"
                                    :button-class="'btn btn-xs pmc-btn-danger ml-1'"
                                    :button-label="'Cancel'">
                                    <template slot="button-icon">
                                      <font-awesome-icon icon="times"/>
                                    </template>
                                  </pmc-btn>
                                  <pmc-btn
                                    v-if="!isEditEnable"
                                    :button-class="'btn btn-xs pmc-btn-tertia ml-1'"
                                    :button-label="'Print'">
                                    <template slot="button-icon">
                                      <font-awesome-icon icon="save"/>
                                    </template>
                                  </pmc-btn>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-6">
                              <!-- Name of Crew  -->
                              <div class="form-group row mt-1 text-left">
                                <label for="name-of-crew" class="col-sm-3 font-weight-bold"
                                       style="padding: 0 0 0 15px;">Name of Crew</label>
                                <span>:</span>
                                <div class="col-sm-8" style="padding: 0 0 0 10px;">
                                  <input v-model="crewFormData.crewFullname"
                                         style="padding-left:5px;"
                                         type="text"
                                         id="name-of-crew"
                                         class="form-control form-control-sm form-control-plaintext-custom"
                                         placeholder="--"
                                         readonly disabled
                                  >
                                </div>
                              </div>
                              <!-- Departure  -->
                              <div class="form-group row mt-1 text-left">
                                <label for="name-of-rank" class="col-sm-3 font-weight-bold"
                                       style="padding: 0 0 0 15px;">Position</label>
                                <span>:</span>
                                <div class="col-sm-8" style="padding: 0 0 0 10px;">
                                  <input v-model="crewFormData.rank_alias"
                                         style="padding-left:5px;"
                                         type="text"
                                         id="name-of-rank"
                                         class="form-control form-control-sm form-control-plaintext-custom"
                                         placeholder="--"
                                         readonly disabled
                                  >
                                </div>
                              </div>

                            </div>
                            <div class="col-6 border-left">


                              <div class="form-group row mt-1 text-left">
                                <label for="name-of-vessel" class="col-sm-3 font-weight-bold"
                                       style="padding: 0 0 0 15px;">Vessel / Flag</label>
                                <span>:</span>
                                <div class="col-sm-8" style="padding: 0 0 0 10px;">
                                  <input v-model="crewFormData.vessel"
                                         style="padding-left:5px;"
                                         type="text"
                                         id="name-of-vessel"
                                         class="form-control form-control-sm form-control-plaintext-custom"
                                         placeholder="--"
                                         readonly disabled
                                  >
                                </div>
                              </div>
                              <!-- Departure  -->
                              <div class="form-group row mt-1 text-left">
                                <label for="signOffDate-date" class="col-sm-3 font-weight-bold"
                                       style="padding: 0 0 0 15px;">Signoff Date</label>
                                <span>:</span>
                                <div class="col-sm-8" style="padding: 0 0 0 10px;">
                                  <input
                                    style="padding-left:5px;"
                                    v-model="crewFormData.signOffDate"
                                    :disabled="true"
                                    type="text"
                                    :class="{'form-control form-control-sm' : isEditEnable, 'form-control form-control-sm form-control-plaintext' : !isEditEnable}"
                                    id="signOffDate-date"
                                    placeholder="N/A">
                                </div>
                              </div>

                            </div>
                          </div>
                          <br>
                          <div class="row text-left my-1 no-gutters" style="border: 1px solid rgba(0,0,0,0.2);">
                            <div class="col-lg-12">
                              <template>
                                <div class="row">
                                  <div class="col col-md-12">
                                    <div v-if="Object.keys(cchCategories).length > 0" class="table">
                                      <table class="table table-bordered table-sm text-center" v-model="isListChanged"
                                             style="overflow-x: hidden !important;">
                                        <tbody>
                                        <tr>
                                          <div style="margin-bottom: -12px;overflow-x: hidden !important;">
                                            <table class="table table-bordered table-sm sticky-header">
                                              <thead>
                                              <tr style="padding:0px;margin: 0px;">
                                                <th style="width: 40%;">Particulars</th>
                                                <th style="width: 8%;">Date Issued</th>
                                                <th style="width: 8%;font-size: 8pt;">Date Expire</th>
                                                <th style="width: 15%;">Remarks</th>
                                                <th style="width: 5%;">Crew</th>
                                                <th style="width: 5%;">Returned</th>
                                              </tr>
                                              </thead>
                                            </table>
                                          </div>
                                        </tr>
                                        <tr v-for="(checklist,index) in cchCategories" :key="checklist.id">

                                          <div v-if="Object.keys(checklist.documents).length > 0">
                                            <table class="table table-bordered table-sm">
                                              <tbody>
                                              <tr class="form-inline th-no-color text-left ml-2"
                                                  style="line-height: 30px;font-weight: bolder;">
                                                {{
                                                  setChecklistHeader(checklist.description, checklist.sequence, checklist.is_display_sequence)
                                                }}
                                              </tr>
                                              </tbody>
                                            </table>
                                            <div style="margin-top: -12px;margin-bottom: -12px;">
                                              <table class="table table-bordered table-sm table-hover">
                                                <tbody>
                                                <tr v-for="(document,index) in checklist.documents">
                                                  <td class="text-left" style="width: 40%;padding-left: 10px;">
                                                    <span>{{
                                                        document.document_name ? document.document_name : ""
                                                      }}</span>
                                                  </td>
                                                  <td class=" text-center bg-default" style="width: 8%;font-size: 8pt;"
                                                      :class="{'remarks-no-data': !document.date_issued}">
                                                    {{ document.date_issued ? document.date_issued : '?' }}
                                                  </td>
                                                  <td style="width: 8%;font-size: 8pt;"
                                                      :class="{'remarks-no-data': !document.date_expiry, 'noExpiry' : document.hasExpiry === 0, 'text-center bg-danger text-white' : document.is_date_expiry === 1, 'text-center': document.is_date_expiry === 0}">
                                                    <div v-if="document.hasExpiry !== 0">
                                                      {{ document.date_expiry ? document.date_expiry : '?' }}
                                                    </div>
                                                    <div v-if="document.hasExpiry === 0">
                                                      No Expiry
                                                    </div>

                                                  </td>
                                                  <td class=" text-center" style="width: 15% !important;">
                                                    <small :class="{'remarks-no-data' : !document.document_no}">
                                                      {{
                                                        document.document_no ? document.document_no.toUpperCase() : '?'
                                                      }}
                                                    </small>
                                                  </td>
                                                  <td class="text-center" style="width: 5%;"><input
                                                    class="form-check-input input-checkbox" type="checkbox" value=""
                                                    id="flexCheckDefault"></td>
                                                  <td class="text-center" style="width: 5%;"><input
                                                    class="form-check-input input-checkbox" type="checkbox" value=""
                                                    id="flexCheckDefault"></td>
                                                </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>

                                        </tr>
                                        </tbody>
                                      </table>
                                      <div v-if="Object.keys(this.preDepartureChecklist).length > 0">
                                        <div style="margin-bottom: -12px;margin-top: -14px;">
                                          <table class="table table-sm">
                                            <tbody>
                                            <tr class="text-center ml-2" style="line-height: 25px;">
                                              <i>--NOTHING FOLLOWS--</i>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div v-else>
                                        <table class="table table-sm">
                                          <tbody>
                                          <tr class="text-center ml-2">
                                            <i>--No Result--</i>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div v-else>
                                      <table class="table table-sm">
                                        <tbody>
                                        <tr class="text-center ml-2 text-danger" style="line-height: 25px;">
                                          <i class="font-weight-bolder" style="font-size: 1.5em;">Loading Data! Please
                                            Wait ...</i>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                          <div class="row text-left my-2 no-gutters" style="border: 1px solid rgba(0,0,0,0.2);">
                            <div class="col-lg-12">
                              <table class="table table-sm small table-main-header" width="100%">
                                <thead>
                                <tr>
                                  <th class="th-no-color text-center">Acknowledgement Receipt</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="row">
                                          <div class="col-6 text-center" style="margin-top: 30px;">
                                            <span style="text-decoration: underline;"><b>Ms. MK.Ang</b></span><br>
                                            <label>Crewing Supervisor</label>
                                          </div>

                                          <div class="col-6 text-center" style="margin-top: 30px;">
                                            <span
                                              style="text-decoration: underline;"><b>{{
                                                crewFormData.crew_name
                                              }}</b></span><br>
                                            <label>Crew Signature / Date</label>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </td>

                                </tr>
                                </tbody>

                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer p-2">
                      <button type="button" data-dismiss="modal"
                              class="btn btn-secondary btn-sm px-3 font-weight-normal">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom py-0">
          <button class="btn btn-link btn-lg order-1 order-lg-0"
                  id="sidebar_toggle"
                  @click="is_side_nav()"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Click to show other menu"
                  :disabled="disableSideNav">
            <font-awesome-icon icon="bars" style="transform: scaleX(1.5)"/>
          </button>
          <div class="collapse navbar-collapse ml-3" id="navbarResponsive">
            <ul class="navbar-nav ml-auto ml-md-0">
              <li class="nav-item">
                <router-link
                  tag="button"
                  class="nav-link border-0 font-weight-bold"
                  :disabled="isRouteActive('AllInfo')"
                  v-on:click.native="is_side_nav_route"
                  :to="{name:'AllInfo'}"
                  :class="{ 'active': isRouteActive('AllInfo')}">
                  All Info
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  tag="button"
                  class="nav-link border-0 font-weight-bold"
                  :disabled="isRouteActive('CrewSeaService')"
                  v-on:click.native="is_side_nav_route"
                  :to="{name:'CrewSeaService'}"
                  :class="{ 'active' : isRouteActive('CrewSeaService')}">
                  Sea Service
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  tag="button"
                  class="nav-link border-0 font-weight-bold"
                  :disabled="isRouteActive('CrewDocument')"
                  v-on:click.native="is_side_nav_route"
                  :to="{name:'CrewDocument'}"
                  :class="{ 'active' : isRouteActive('CrewDocument')}">
                  Crew Document
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  tag="button"
                  class="nav-link border-0 font-weight-bold"
                  :disabled="isRouteActive('CrewMedical')"
                  v-on:click.native="is_side_nav_route"
                  :to="{name : 'CrewMedical'}"
                  :class="{ 'active' : isRouteActive('CrewMedical')}">
                  Medical
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  tag="button"
                  class="nav-link border-0 font-weight-bold"
                  :disabled="isRouteActive('CrewScannedChecklist')"
                  v-on:click.native="is_side_nav_route"
                  :to="{name : 'CrewScannedChecklist'}"
                  :class="{ 'active' : isRouteActive('CrewScannedChecklist')}">
                  Scanned Checklist
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  tag="button"
                  class="nav-link border-0 font-weight-bold"
                  :disabled="isRouteActive('CrewComment')"
                  v-on:click.native="is_side_nav_route"
                  :to="{name : 'CrewComment'}"
                  :class="{ 'active' : isRouteActive('CrewComment')}">
                  Comments
                  <sup class="badge badge-warning badge-counter">{{ crew.commentsCount }}</sup>
                </router-link>
              </li>

              <!-- todo outside sea service -->
              <li class="nav-item">
                <router-link
                  tag="button"
                  class="nav-link border-0 font-weight-bold"
                  v-on:click.native="is_side_nav_route"
                  :to="{name : 'CrewOutSideService'}"
                  :disabled="isRouteActive('CrewOutSideService')"
                  :class="{ 'active' : isRouteActive('CrewOutSideService')}">
                  Outside Sea Service
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  tag="button"
                  class="nav-link border-0 font-weight-bold"
                  v-on:click.native="is_side_nav_route"
                  :disabled="isRouteActive('CrewEvaluation')"
                  :to="{name : 'CrewEvaluation'}"
                  :class="{ 'active' : isRouteActive('CrewEvaluation')}">
                  Evaluation
                </router-link>
              </li>

              <!-- TODO make the following -->
              <li class="nav-item">
                <router-link
                  tag="button"
                  class="nav-link border-0 font-weight-bold"
                  v-on:click.native="is_side_nav_route"
                  :disabled="isRouteActive('CrewWithdrawal')"
                  :to="{name : 'CrewWithdrawal'}"
                  :class="{ 'active' : isRouteActive('CrewWithdrawal')}">
                  Withdrawal
                </router-link>
              </li>
            </ul>
          </div>
        </nav>

        <div class="row">
          <div class="col-md-12">
            <div class="bg-white">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- upload image photo.-->
    <pmc-modal :m_height="true" m_width :id="imageUploadModal" :hide-header-button="false">
      <template slot="custom-header">
        <input id="crew-img-input" type="file" @change="onFileChange">
      </template>

      <template slot="custom-body">
        <img :src="crewImage" class="img-fluid crew-img" alt="image here"/>
      </template>

      <template slot="custom-footer">
        <pmc-btn :button-label="'Upload'" :class="'btn btn-sm pmc-btn-success'"
                 @buttonEvent="upload_crew_image">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>

        <pmc-btn :button-label="'Cancel'" :class="'btn btn-sm pmc-btn-danger'" @buttonEvent="cancel_upload_image">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>
      </template>
    </pmc-modal>
  </div>
</template>

<script>

import CrewBiodata from "@/components/modules/crewprofile/_sub/biodata/CrewBiodata";

import sample from '@/assets/sample.jpg';
import noPhoto from '@/assets/noPhoto.jpg';
import {AppMixins} from '@/mixins/app.mixins';
import {AlertMixins} from '@/mixins/alert.mixins';
import {mapActions, mapGetters} from 'vuex';
import vue_moment from "vue-moment";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import EXIF from 'exif-js';
import Swal from "sweetalert2";
import {AlertService} from "@/service/alert.service";

export default {
  name: 'CrewProfile',
  components: {CrewBiodata},

  props: {
    crewId: {
      type: Number,
    }
  },

  mixins: [AppMixins, AlertMixins],

  data() {
    return {
      sampleCrewImg: sample,
      noPhoto: noPhoto,
      isSideNav: true,
      isValidRoute: false,
      disableSideNav: false,

      imageUploadModal: 'imageUploadModal',
      imagePrev: false,

      isDateAvailability: false,
      formCrewDateAvailability: null,


      //disembarkation list
      isEditEnable: false,
      checkedByPosition: null,
      selectedCrew: {},

      // crewFormData: {},
      // cchCategories: {},
      // preDepartureChecklist: {},
      // signatories: {},

      crewParam: {
        'crew_id': null,
        'checklist': 1,
      },

      isListChanged: false,

      // Watched crew_document_checklist module js
      docLastIndex: 0,
      cchCategories: {},
      crewDocumentList: {},

    }
  },

  methods: {
    ...mapActions([
      'viewCrew',
      'setCrewImage',
      'nullCrewImage',
      'uploadCrewImage',
      'deleteCrewImage',
      'nullCrew',
      'getBiodataDetails',
      // 'viewPersonalDetails',

      "update_crew_availability",

      //get document checklist to view in disembarkation
      'getCrewDocumentChecklist',

      // generate endorsed crew
      'generateEndorsedCrew',

      //reset biodata
      'resetbiodataDetails',


    ]),

    is_side_nav(e) {
      this.isSideNav = !this.isSideNav;
    },

    is_side_nav_route() {
      if (this.isCrewProfileSideNav()) {
        this.isSideNav = false;
        this.disableSideNav = false;

        if (this.isSideNav == false) {
          const mySidenav = document.getElementById("mySidenav");
          const main = document.getElementById("main");

          if (mySidenav && main) {
            mySidenav.style.width = '155px';
            main.style.marginLeft = '155px';
          }
        }


      } else {
        this.disableSideNav = true;

      }
    },

    onFileChange(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      if (image != (null || undefined)) {
        reader.readAsDataURL(image);
        reader.onload = e => {
          let param = e.target.result;
          this.setCrewImage(param);
        }
      } else {
        this.emptyInputFile()
        this.nullCrewImage()
      }
    },

    async remove_img() {
      if (await this.questionAlert('Are you want to remove this image.', 'Remove picture')) {
        this.deleteCrewImage(this.crewId).then((r) => {
          if (r) {
            this.successAlert('Crew image successfully removed');
            this.view_crew();
          }
        });
      }
    },

    view_crew() {
      this.viewCrew(this.crewId).then((r) => {
        if (!r) {
          this.goToFailOver();
        }
      });
    },

    async upload_crew_image() {
      if (await this.questionAlert('Are you sure you want to upload this image', 'Upload Crew Image')) {
        const file = document.getElementById('crew-img-input').files[0];
        const formData = new FormData();
        let crewId = this.crewId.toString();
        formData.append('crewId', crewId);
        formData.append('file', file);

        // get image orientation
        const getOrientation = () => {
          return new Promise((resolve) => {
            EXIF.getData(file, function() {
              const orientation = EXIF.getTag(this, 'Orientation');
              resolve(orientation);
            });
          });
        };

        const imageOrientation = await getOrientation();
        formData.append('imageOrientation', imageOrientation);


        this.uploadCrewImage(formData).then((r) => {
          if (r) {
            this.successAlert('Uploaded successfully');
            this.hideModal(this.imageUploadModal);
            $(`#crew-img-input`).val("");
            this.imagePrev = true;
          }
        });
      }
    },

    cancel_upload_image() {
      this.hideModal(this.imageUploadModal);
      this.$nextTick(() => {
        this.view_crew();
      })
      this.emptyInputFile();
    },

    emptyInputFile() {
      $(`#crew-img-input`).val(null);
    },

    async editAvailability() {
      if (this.isDateAvailability == true) {
        //saving the edited .availability .
        if (await this.questionAlert("Do you want to save changes that you made", "Update Availability")) {
          this.update_crew_availability(this.crew).then((r) => {
            if (r) {
              this.$nextTick(() => {
                this.successAlert("Update successfully")
                this.isDateAvailability = false;
                this.view_crew();
              })
            }
          })
        } else {
          this.isDateAvailability = !this.isDateAvailability;
          this.crew.availability = this.formCrewDateAvailability;
          this.formCrewDateAvailability = null;
        }
      } else {
        this.formCrewDateAvailability = this.crew.availability;
        this.isDateAvailability = !this.isDateAvailability;

      }
    },

    cancelAvailability() {
      this.isDateAvailability = false;
      this.crew.availability = this.formCrewDateAvailability;
      this.formCrewDateAvailability = null;
    },

    gotoComment() {
      this.$nextTick(() => {
        this.$router.push({
          name: 'CrewComment',
          params: this.crewId,
        })
      })
    },

    setChecklistHeader(hdrStr, level, isDisplay) {
      const sequence = ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
      if (isDisplay === 1) return sequence[level] + '. ' + hdrStr.toUpperCase();
      else return hdrStr.toUpperCase();
    },

    getDocumentChecklist(crew_id) {
      this.crewParam.crew_id = crew_id
      this.getCrewDocumentChecklist(this.crewParam);
    },

    set_editable() {
      this.isEditEnable = !this.isEditEnable;
    },

    cancel() {
      this.set_editable();
    },

    async loadEndorsedCrewReport() {
      AlertService.loadingAlert('Please wait ...', 'Loading Endorsed Crews');
      let endorsedCrewFile = await this.generateEndorsedCrew(this.crewId);
      if (endorsedCrewFile) {
        Swal.close();
        await this.pdfViewer('Endorsed Crew', endorsedCrewFile);
      } else {
        Swal.close();
      }
    },


    async onclick_getBiodata(){
      this.getBiodataDetails(this.crewId)
    },

    momentAvailability() {
      return this.$moment(this.crew.availability).format('DD MMM YYYY').toUpperCase();
    },

    restrictDate() {
      const dateToday = new Date();
      let month = dateToday.getMonth() + 1;
      let day = dateToday.getDate();
      const year = dateToday.getFullYear();

      if (month < 10) {
        month = '0' + month.toString();
      }

      if (day < 10) {
        day = '0' + day.toString();
      }

      const restrictDate = `${year}-${month}-${day}`;

      return restrictDate;

    },

    // inputRestrictDate(e) {
    //   const inputDate = e.target.value;
    //   const minDate = this.restrictDate();
    //
    //   if (inputDate < minDate) {
    //     e.target.value = minDate;
    //   }
    // },

  },

  computed: {
    ...mapGetters([
      'crew',
      'crewImg',

      'setUpCrewList',

      // 'crewInfo',
      // 'crewGenInfo',

      //crew_document_checklist.module.js
      'crewFormData',
      'preDepartureChecklist',
      'crewChecklistCategories',
      'crewDocList',
      'documentLastIndex',
      'signatories',
      'docLocation',
      'remarks',


    ]),

    currentRouteName() {
      return this.$route.name;
    },

    crewImage() {
      return this.crew.crewImg ? this.crew.crewImg : this.noPhoto;
    },

    //v-if //span
    //v-else /
    ///2023-04-13
    // crewDateAvailable: {
    //   get() {
    //     let date = null;
    //     if (this.crew.availability && !this.isDateAvailability) {
    //       return this.$moment(this.crew.availability).format("DD-MM-YYYY");
    //     }
    //
    //     if (this.crew.availability && this.isDateAvailability) {
    //       // date = this.$moment(this.crew.availability).format("YYYY-MM-DD")
    //       return this.$moment(this.crew.availability).format("MM-DD-YYYY");
    //     }
    //
    //     // return this.moment(this.crew.availability).format("MM-DD-YYYY");
    //
    //     // return date;
    //   },
    //   set(e) {
    //     this.crew.availability = e;
    //   },
    // }

  },

  mounted() {
    //$(`[data-toggle="tooltip"]`).tooltip(options);
    $('#sidebar_toggle').tooltip()
    $('#edit-date-availability').tooltip()
  },

  created() {
    this.view_crew();
    //this.is_side_nav_route();
    // this.viewPersonalDetails(1563);
    this.is_side_nav_route();
  },

  destroyed() {
    this.resetbiodataDetails()
  },

  watch: {
    crewId() {
      this.view_crew();
    },

    // DEPRECATED;
    // do not remove for reference only
    // '$route'() {
    //   this.getBiodataDetails(this.crewId ? this.crewId : this.$route.params.crewId)
    //   // this.thisOpenSideNav()
    // },

    isSideNav: function (val) {
      if (val === false) {
        this.thisOpenSideNav()
      }

      if (val === true) {
        this.thisCloseSideNav();
        $('#sidebar_toggle').tooltip('hide')
      }
    },

    'documentLastIndex'() {
      this.docLastIndex = Object.assign({}, this.documentLastIndex);
    },

    'crewChecklistCategories'() {
      this.cchCategories = Object.assign({}, this.crewChecklistCategories);
    },

    'crewDocList'() {
      this.crewDocumentList = Object.assign({}, this.crewDocList);
    },


  }
}
</script>

<style scoped>
#crew-img-container {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

#crew-img {
  width: 150px;
  height: 150px;
}

.crew-img {
  width: 150px;
  height: 150px;
}

label {
  font-weight: bold !important;
}

.input-checkbox {
  width: 9px;
  height: 9px;
}

@media screen and (max-height: 450px) {
  .sidenav a {
    font-size: 2px;
  }
}

</style>
