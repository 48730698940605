<template>
  <div>
    <ccm-master-checklist-edit-document
        @refresh-ccm-checklist="refreshFromCrewChangeMemo"
        ref="checklistEditDoc"
        :modal-id="viewCcmMasterChecklistDocModal"
        :file-input-id="fileInputCcmMasterChecklistDoc"
    >
      <template v-slot:table-contents>

        <div class="col-md-4">
          <div class="row">
            <div class="form-inline mb-3">
              <div class="form-group mb-2">
                <h5 class="font-weight-bold lead">Documents</h5>
              </div>

            </div>

          </div>
        </div>


        <table class="table table-bordered table-sm text-center small">
          <thead>
          <tr>
            <th style="width: 5%;">
              #
            </th>
            <th class="">NAME</th>
            <th class="">RANK</th>
            <th class="">AGE</th>
            <th class="">PASSPORT VALIDITY</th>
            <th class="">US VISA VALIDITY</th>
            <th class="">SIRB VALIDITY</th>
            <th class="">MEDICAL CERT ISSUED DATE</th>
            <th class="">COC Certificate of Competency</th>
            <th class="">GOC EXP</th>
            <th class="">COP II/4 & III/4 ISSUED DATE</th>
            <th class="">COP II/5 & III/5 ISSUED DATE</th>
            <!--            <th class="">NCI - MM / NCIII - CCK Details</th>-->
            <th class="">COP BST</th>
            <th style="width: 1%;" class="">COP PSCRB</th>
            <th class="">COP AFF</th>
            <th style="width: 11%;" class="">COP MECA</th>
            <th style="width: 11%;" class="">COP MEFA</th>
            <th style="width: 35%;" class="">COP SSO</th>
            <th style="width: 35%;" class="">COP SDSD</th>
            <th style="width: 35%;" class="">Y FEVER</th>
            <th style="width: 35%;" class="">POLIO</th>
            <th style="width: 35%;" class="">CHOLERA</th>
            <th style="width: 35%;" class="">ECDIS</th>

          </tr>
          </thead>
          <tbody>

          <template v-if="loading">
            <tr>
              <td colspan="100%">
                <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                <span style="size: 20px">
                          Loading ...
                        </span>
              </td>
            </tr>
          </template>

          <template v-else>
            <template v-if="ccmMasterChecklist ? (ccmMasterChecklist.length != 0) : '' ">
              <tr v-for="(checklist, index) in ccmMasterChecklist" :key="checklist.id">
                <td class="text-center align-middle">
                  {{ index + 1 }}
                </td>
                <td class="text-center align-middle">
                  {{ checklist.crewName }}
                </td>

                <td class="text-center align-middle">
                  {{ checklist.rankAlias }}
                </td>


                <td class="text-center align-middle">
                  {{ checklist.age }}
                </td>


                <!--                      PASSPORT VALIDITY-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.passport.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.passport.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.passport.hasFile == 0,
                           }"
                >
                  {{ checklist.passport.dateExpiry }}
                </td>


                <!--                      US VISA VALIDITY-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.usVisa.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.usVisa.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.usVisa.hasFile == 0,
                           }"
                >
                  {{ checklist.usVisa.dateExpiry }}
                </td>


                <!--                      SIRB VALIDITY-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.sirb.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.sirb.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.sirb.hasFile == 0,
                           }"
                >
                  {{ checklist.sirb.dateExpiry }}
                </td>


                <!--                      MEDICAL CERT ISSUED DATE-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.medExamCert.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.medExamCert.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.medExamCert.hasFile == 0,
                           }"
                >
                  {{ checklist.medExamCert.dateIssued }}
                </td>


                <!--                      COC Certificate of Competency-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.coc.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.coc.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.coc.hasFile == 0,
                              'mismatch-doc-rank' : checklist.rankId != checklist.coc.rank && checklist.coc.rank && checklist.rankId,
                           }"
                >
                  {{ checklist.coc.rankAlias }}
                  <br>
                  {{ checklist.coc.document_no }}
                  <br>
                  {{ checklist.coc.dateExpiry }}
                </td>


                <!--                      GOC EXP-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.goc.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.goc.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.goc.hasFile == 0,
                           }"
                >

                  {{ checklist.goc.dateExpiry }}
                </td>


                <!--                      	COP II/4 & III/4 ISSUED DATE-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copDeckEngineRating_4.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copDeckEngineRating_4.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copDeckEngineRating_4.hasFile == 0,
                           }"
                >
                  {{ checklist.copDeckEngineRating_4.document_no }}
                  <br>
                  {{ checklist.copDeckEngineRating_4.dateIssued }}
                </td>


                <!--                      COP II/5 & III/5 ISSUED DATE-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copDeckEngineRating_5.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copDeckEngineRating_5.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copDeckEngineRating_5.hasFile == 0,
                           }"
                >
                  {{ checklist.copDeckEngineRating_5.document_no }}
                  <br>
                  {{ checklist.copDeckEngineRating_5.dateIssued }}
                </td>


                <!--                      NCI - MM / NCIII - CCK Details-->
                <!--            <td class="text-center align-middle"-->
                <!--                :class="{-->
                <!--                              'no-documents-or-expired' : checklist.coc_nc.isExpired,-->
                <!--                              'no-file-upload-or-old-file-uploaded' : checklist.coc_nc.hasFile == 0,-->
                <!--                           }"-->
                <!--            >-->
                <!--              {{ checklist.coc_nc.coc_nc_type ?? '' }}-->
                <!--              <br>-->
                <!--              {{ checklist.coc_nc.dateExpiry ?? '' }}-->
                <!--            </td>-->


                <!--                      COP BST-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.basic_training.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.basic_training.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.basic_training.hasFile == 0,
                           }"
                >
                  {{ checklist.basic_training.dateIssued }}
                </td>


                <!--                      COP PSCRB-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copPscrb.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copPscrb.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copPscrb.hasFile == 0,
                           }"
                >
                  {{ checklist.copPscrb.dateExpiry }}
                </td>


                <!--                      COP AFF-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copAff.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copAff.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copAff.hasFile == 0,
                           }"
                >
                  {{ checklist.copAff.dateExpiry }}
                </td>


                <!--                      COP MECA-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copMeca.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copMeca.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copMeca.hasFile == 0,
                           }"
                >
                  {{ checklist.copMeca.dateIssued }}
                </td>


                <!--                      COP MEFA-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copMefa.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copMefa.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copMefa.hasFile == 0,
                           }"
                >
                  {{ checklist.copMefa.dateIssued }}
                </td>

                <!--                      COP SSO-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copSso.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copSso.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copSso.hasFile == 0,
                           }"
                >
                  {{ checklist.copSso.dateIssued }}
                </td>

                <!--                      COP SDSD-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.copSdsd.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.copSdsd.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.copSdsd.hasFile == 0,
                           }"
                >
                  {{ checklist.copSdsd.dateIssued }}
                </td>

                <!--                      Y FEVER-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.yellowFever.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.yellowFever.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.yellowFever.hasFile == 0,
                           }"
                >
                  {{ checklist.yellowFever.dateIssued }}
                  <br>
                  {{ checklist.yellowFever.document_no2 }}
                  <br>
                </td>

                <!--                      POLIO-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.polio.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.polio.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.polio.hasFile == 0,
                           }"
                >
                  {{ checklist.polio.dateExpiry }}
                </td>

                <!--                      CHOLERA-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.cholera.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.cholera.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.cholera.hasFile == 0,
                           }"
                >
                  {{ checklist.cholera.dateExpiry }}
                </td>


                <!--                      ECDIS-->
                <td class="text-center align-middle"
                    @click.stop="crewChangeMemo.oldCcMemoNo === '' && crewChangeMemo.etd === null ? view_document(checklist.ecdis_generic.id) : null"
                    :class="{
                              'no-documents-or-expired' : checklist.ecdis_generic.isExpired,
                              'no-file-upload-or-old-file-uploaded' : checklist.ecdis_generic.hasFile == 0,
                           }"
                >
                  {{ checklist.ecdis_generic.dateIssued }}
                </td>


              </tr>
            </template>
          </template>

          </tbody>

        </table>


      </template>

    </ccm-master-checklist-edit-document>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import {global_variables_config} from "@/config/global-variable.config";
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";

export default {
  name: "CcmMasterChecklistDocument",
  mixins: [AppMixins, AlertMixins],
  props: {

    ccmMasterChecklist: {
      type: Array,
      default: [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

  },

  data() {
    return {
      viewCcmMasterChecklistDocModal: 'view-ccm-master-checklist-doc-modal',
      fileInputCcmMasterChecklistDoc: 'file-input-ccm-master-checklist-doc',
    }
  },

  methods: {
    view_document(crewDocId) {
      this.$refs.checklistEditDoc.view_document(crewDocId);
    },

    refreshFromCrewChangeMemo() {
      this.$emit('refresh-ccm-checklist');
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'crewChangeMemo',
      ],
    ),
  },

  created() {
    this.$globalVariables = global_variables_config
  },

  beforeDestroy() {
    this.$globalVariables = null;
  },


}
</script>

<style scoped>
</style>
