import { render, staticRenderFns } from "./TestChunkUpload.vue?vue&type=template&id=ff3a7210&scoped=true&"
import script from "./TestChunkUpload.vue?vue&type=script&lang=js&"
export * from "./TestChunkUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff3a7210",
  null
  
)

export default component.exports