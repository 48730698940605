<template>
  <!-- header row -->
  <div class="container">
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row text-left align-middle">
          <!-- title & add button     -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-7">
                <h4>{{ document.name }}</h4>
                <p>Document Pre-Requisite (RANK)</p>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-md-5">
                <!-- //demo  -->
                <button v-show="!isEditable" class="btn btn-xs btn-secondary" @click="goBack">
                  <font-awesome-icon icon="undo"/>
                  Go Back
                </button>

                <button v-show="!isEditable" class="btn btn-xs pmc-btn-warning ml-1" @click="on_edit">
                  <font-awesome-icon icon="edit"/>
                  Edit
                </button>

                <button v-show="isEditable" class="btn btn-xs pmc-btn-tertia ml-1" @click="on_save">
                  <font-awesome-icon icon="save"/>
                  Save
                </button>

                <button v-show="isEditable" class="btn btn-xs pmc-btn-danger ml-1" @click="on_cancel">
                  <font-awesome-icon icon="times"/>
                  Cancel
                </button>

              </div>
            </div>

            <hr>

            <div class="row mt-2">
              <div class="col-md-6">
                <pmc-table :max-height="600" :max-width="100">
                  <template v-slot:table-header>
                    <tr>
                      <th colspan="2" style="width: 20% !important;">RANKS</th>
                      <th style="width: 5% !important;">REQUIRED</th>
                    </tr>
                  </template>

                  <template v-slot:table-body>
                    <tr v-for="(rank,rankIndex) in objRanks">
                      <td width="5%" @click="check_all_byRow">{{ rank.alias }}</td>
                      <td width="35%" class="text-left pl-4">{{ rank.name }}</td>
                      <td width="5%">
                        <input type="checkbox"
                               :id="`req-${rank.id}`"
                               :disabled="!isEditable"
                               :value="rank.id"
                               :checked="documentRanks.some(docranks => docranks.id === rank.id)"
                               @change="is_check">
                      </td>
                    </tr>
                  </template>

                </pmc-table>

              </div>
            </div>
          </div>
          <!-- buttons here. -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AlertMixins} from '../../../../mixins/alert.mixins'
import {AppMixins} from '../../../../mixins/app.mixins'
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'
import {PMC} from '../../../../config/constants.config'

export default {
  name: 'DocPreRequisite',

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      documentId: this.$route.params.documentId,   //fetch the route parameter,

      columnHeader: [
        {
          id: 1,
          name: 'REQUIRED',
        },
        {
          id: 1,
          name: 'STATUS',
        },
      ],

      newValue: {
        rank_id: null,
        status: 1,
      },

      isEditable: false,
    }
  },

  /** methods**/
  methods: {
    ...mapActions(
      [
        'get_document_prerequisite',
        'null_document_ranks',
        'null_obj_ranks',
        'update_document_prerequisite',
      ],
    ),

    check_all_byRow() {
      //
    },

    check_all_byHeader() {
      //todo
    },

    on_click() {
      //todo
    },

    is_check(e) {
      let isCheck = e.target.checked;
      let __value = e.target.value;

      if (isCheck) {
        this.documentRanks.push({id: __value})

      } else {
        let index = this.documentRanks.findIndex((val, index) => val.id === parseInt(__value));
        this.documentRanks.splice(index, 1);

      }
    },

    del_cheked() {

    },

    on_edit() {
      this.isEditable = true;

    },

    on_save() {
    },

    on_cancel() {
      this.isEditable = false;
      this.null_document_ranks();
      this.null_obj_ranks();
      this.get_document_prerequisite(this.documentId);
    },


  },

  /**  computed  **/
  computed: {
    ...mapGetters([
      'document',
      'documentRanks',
      'objRanks',
    ]),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.document_prerequisite(this.documentId);
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.null_document_ranks()

  },
}
</script>

<style>
input[type="checkbox"] {
  transform: scale(2) !important;
}

</style>
