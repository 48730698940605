import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";


const state = {
  employeePosition: {},
  employeePositions: [],
  newEmployeePosition: {},

  employeePositionFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  employeePositionPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_employeePositions(state, params) {
    state.employeePositions = params
  },

  create_employeePosition (state) {
    state.employeePosition = {};
  },

  update_employeePosition (state, params) {
    let employeePosition = state.employeePositions.find((val, index) => val.id === params.id)
    Object.assign(employeePosition, params)
  },

  delete_employeePosition (state, params) {
    let index = state.employeePositions.findIndex((val, index) => val.id === params)
    state.employeePositions.splice(index, 1)
  },

  null_positions(state) {
    state.employeePositions = []
  },

  null_position(state) {
    state.employeePosition = {}
  },

  set_employeePosition_pagination(state, params) {
    state.employeePositionPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {
  async set_employeePositions({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.EMPLOYEE_POSITION, {params}).then((r) => {
      commit('set_employeePositions', r.data.data)
      commit('set_employeePosition_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get employee position')
      return false
    })
  },

  async create_employeePosition({commit}, params) {
    return axios.post(PMC.EMPLOYEE_POSITION, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_employeePosition', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create employee position');
      return false;
    })
  },

  async update_employeePosition({commit}, params) {
    return axios.put(`${PMC.EMPLOYEE_POSITION}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_employeePosition',r.data.updatedEmployeePosition);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update trade route');
      return false;

    });
  },

  async delete_employeePosition ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Employee Position','question');
    if(await _del){
      return axios.delete(`${PMC.EMPLOYEE_POSITION}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete employee position');
        commit('delete_employeePosition',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete employee position");
        return false;

      })
    }
  },


  reset_position_pagination({commit}) {
    commit('set_principal_pagination')
  },

  null_positions({commit}) {
    commit('null_positions')
  },

  null_position({commit}) {
    commit('null_position')
  },
};


const getters = {
  employeePositions: state => UtilityService.capitalizeProperties(state.employeePositions),

  employeePositionPagination: state => state.employeePositionPagination,
  employeePositionFormParam: state => state.employeePositionFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
