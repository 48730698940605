<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'ApplicantTracking',

  /** mixins **/
  mixins: [],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {};
  },

  /** methods**/
  methods: {},

  /**  computed  **/
  computed: {},

  /** mounted **/
  mounted() {},

  /** created **/
  created() {},

  /** watch
   searchText : {
			handler : 'fetchUserList',
			immediate : true,
		}
   **/
  watch: {},

  /** destroyed **/
  destroyed() {},

};
</script>

<style scoped>

</style>
