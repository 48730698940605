import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";


const state = {
  vesselEngineType: {},
  vesselEngineTypes: [],
  newVesselEngineType: {},

  vesselEngineTypeFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  vesselEngineTypePagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },

};



const mutations = {
  set_vesselEngineTypes(state, params) {
    state.vesselEngineTypes = params
  },

  set_vesselEngineType(state, params) {
    state.vesselEngineType = params
  },

  create_vesselEngineType(state) {
    state.newVesselEngineType = {};
  },

  update_vesselEngineType(state, params) {
    let engineType = state.vesselEngineTypes.find((val, index) => val.id === params.id)
    Object.assign(engineType, params)
  },

  delete_vesselEngineType(state, params) {
    let index = state.vesselEngineTypes.findIndex((val, index) => val.id === params)
    state.vesselEngineTypes.splice(index, 1)
  },

  set_vesselEngineType_pagination(state, params) {
    state.vesselEngineTypePagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },


};


const actions = {

  async set_vesselEngineTypes({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.VESSEL_ENGINE_TYPE, {params}).then((r) => {
      commit('set_vesselEngineTypes', r.data.data)
      commit('set_vesselEngineType_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get vessel engine types')
      return false
    })
  },

  async set_vesselEngineType({commit}, param) {
    return axios.get(`${PMC.VESSEL_ENGINE_TYPE}/${param}`).then((r) => {
      commit('set_vesselEngineType', r.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Get vessel engine type');
      return false;
    });
  },

  async create_vesselEngineType({commit}, params) {
    return axios.post(PMC.VESSEL_ENGINE_TYPE, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_vesselEngineType', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create vessel engine type');
      return false;
    })
  },

  async update_vesselEngineType({commit}, params) {
    return axios.put(`${PMC.VESSEL_ENGINE_TYPE}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_vesselEngineType',r.data.updatedVesselEngineType);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update vessel engine type');
      return false;

    });
  },

  async delete_vesselEngineType({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete vessel engine type','question');
    if(await _del){
      return axios.delete(`${PMC.VESSEL_ENGINE_TYPE}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete vessel engine type');
        commit('delete_vesselEngineType',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete vessel engine type");
        return false;

      })
    }
  },


};


const getters = {

  vesselEngineTypes: state => UtilityService.capitalizeProperties(state.vesselEngineTypes),
  vesselEngineType: state => UtilityService.capitalizeProperties(state.vesselEngineType),

  vesselEngineTypePagination: state => state.vesselEngineTypePagination,
  vesselEngineTypeFormParam: state => state.vesselEngineTypeFormParam,


};


/** export **/
export default {state, actions, mutations, getters};
