import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";


const state = {
  owner: {},
  owners: [],
  newOwner: {},

  ownerFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  ownerPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_owners(state, params) {
    state.owners = params
  },

  update_owner (state, params) {
    let owner = state.owners.find((val, index) => val.id === params.id)
    Object.assign(owner, params)
  },

  delete_owner (state, params) {
    let index = state.owners.findIndex((val, index) => val.id === params)
    state.owners.splice(index, 1)
  },

  create_owner (state) {
    state.owner = {};
  },

  null_owners(state) {
    state.owners = []
  },

  null_owner(state) {
    state.owner = {}
  },

  set_owner_pagination(state, params) {
    state.ownerPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {
  async set_owners({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.OWNER, {params}).then((r) => {
      commit('set_owners', r.data.data)
      commit('set_owner_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get owner route')
      return false
    })
  },

  async create_owner({commit}, params) {
    return axios.post(PMC.OWNER, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_owner', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create owner');
      return false;
    })
  },

  async update_owner({commit}, params) {
    return axios.put(`${PMC.OWNER}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_owner',r.data.updatedOwner);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update trade route');
      return false;

    });
  },

  async delete_owner ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Owner','question');
    if(await _del){
      return axios.delete(`${PMC.OWNER}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete owner');
        commit('delete_owner',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete owner");
        return false;

      })
    }
  },


  reset_tradeRoute_pagination({commit}) {
    commit('set_principal_pagination')
  },

  null_owners({commit}) {
    commit('null_owners')
  },

  null_owner({commit}) {
    commit('null_owner')
  },
};


const getters = {
  owners: state => UtilityService.capitalizeProperties(state.owners),

  ownerPagination: state => state.ownerPagination,
  ownerFormParam: state => state.ownerFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
