<template>
  <div id="top-navigation" class="sticky-top">
    <div style="position: relative;">
      <Header></Header>
      <Menu></Menu>
    </div>
  </div>
</template>

<script>

import Menu from './Menu'
import Header from './Header'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'TopNav',
  components : {
    Header,
    Menu,
  },
  data () {
    return {

    }
  },

  methods: {},

  computed: {},

  mounted () {},

  created () {},

  destroyed () {},

}
</script>

<style scoped>

</style>
