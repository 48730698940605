<template>
  <div class="card">
    <div class="card-body">
      <div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-inline  mb-2">

              <div class="form-group">
                <div class="form-check mr-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault1"
                         :value="null"
                         v-model="seaServiceFormParam.filterValue"
                         @change="on_change_filter_all"
                  >
                  <label class="form-check-label" for="flexRadioDefault1">
                    ALL
                  </label>
                </div>

                <div class="form-check mr-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault2"
                         v-model="seaServiceFormParam.filterValue"
                         @change="on_change_filter_emmc"
                         :value="1304"
                         checked>
                  <label class="form-check-label" for="flexRadioDefault2">
                    EMMC
                  </label>
                </div>

                <div class="form-check mr-3">
                  <input class="form-check-input"
                         type="radio"
                         name="flexRadioDefault"
                         id="flexRadioDefault3"
                         v-model="seaServiceFormParam.filterValue"
                         @change="on_change_filter_pmc"
                         :value="878"
                         checked>
                  <label class="form-check-label" for="flexRadioDefault3">
                    PMC
                  </label>
                </div>
              </div>
              <!--              {{ seaServiceAvailableRankKeys }}-->
              <select name="DocCathegory" id="doc-category" class="form-control form-control-sm mr-3"
                      v-model="seaServiceFormParam.rankId"
                      v-if="seaServiceFormParam.filterValue">
                <option :value="null">-- all --</option>
                <option :value="availRankKeys.rankId" v-for="(availRankKeys,index) in seaServiceAvailableRankKeys"
                        :key="availRankKeys.id">
                  {{ availRankKeys.rank }}
                </option>
              </select>

              <!-- Button trigger modal -->
              <button type="button"
                      data-toggle="modal"
                      data-target="#sea-service-generate"
                      data-backdrop="static"
                      class="btn btn-success btn-sm px-3 font-weight-normal"
                      v-if="seaServiceFormParam.filterValue"
              >
                <font-awesome-icon icon="file"/>
                Generate Sea Service
              </button>


              <!-- generate sea service modal -->
              <div class="modal fade" id="sea-service-generate" tabindex="-1" role="dialog"
                   aria-labelledby="documentListModalLabel"
                   aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title text-left" id="documentListModalTitle">Sea Service<br></h5>
                      <div class="btn-group">
                        <button class="btn btn-sm ml-2" @click="closeSeaServiceGenerateModal" aria-label="Close">
                          <font-awesome-icon icon="times" class="text-danger"/>
                          Close
                        </button>
                      </div>
                    </div>
                    <div class="modal-body">
                      <div class="table table-responsive-lg">

                        <div class="form-inline  mb-2">

                          <div class="form-group">
                            <select class="form-control"
                                    v-if="seaServiceFormParam.filterValue == 1304"
                                    v-model="seaServiceFormParam.signatory">
                              <option value="null">-- checked by ---</option>
                              <option :value="signatory"
                                      v-for="(signatory,index) in sea_services_signatories_emmc"
                                      :key="signatory.id">
                                {{ signatory.name }}
                              </option>
                            </select>

                            <select class="form-control"
                                    v-if="seaServiceFormParam.filterValue == 878"
                                    v-model="seaServiceFormParam.signatory">
                              <option value="null">-- checked by ---</option>
                              <option :value="signatory"
                                      v-for="(signatory,index) in sea_services_signatories_pmc"
                                      :key="signatory.id">
                                {{ signatory.name }}
                              </option>
                            </select>

                          </div>
                        </div>

                        <multi-select v-model="seaServiceFormParam.ranks"
                                      tag-placeholder="Add this as new tag"
                                      placeholder="Select Ranks to be included"
                                      label="rank"
                                      track-by="rankId"
                                      :options="seaServiceAvailableRankKeys"
                                      :multiple="true"
                                      :taggable="true">

                        </multi-select>


                        <button type="button"
                                @click="loadSeaServiceReport"
                                class="btn btn-success btn-sm px-3 font-weight-normal mt-3"
                                v-if="seaServiceFormParam.filterValue"
                        >
                          <font-awesome-icon icon="file"/>
                          Generate Sea Service
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- edit sea service modal -->
              <div class="modal fade" id="sea-service-edit" tabindex="-1" role="dialog"
                   aria-labelledby="documentListModalLabel"
                   aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title text-left" id="documentListModalTitle">Edit Sea Service<br></h5>
                      <div class="btn-group">
                        <button class="btn btn-sm ml-2" @click="closeSeaServiceEditModal" aria-label="Close">
                          <font-awesome-icon icon="times" class="text-danger"/>
                          Close
                        </button>
                      </div>
                    </div>
                    <div class="modal-body">
                      <div class="table table-responsive-lg">
                        <div class="row">
                            <label class="col-4">Departure Date</label>
                            <span class="col-1">:</span>
                            <input type="date" class="col-3 form-control form-control-sm" v-model="edit_sea_service.departureDate" />
                        </div>
                        <div class="row mt-2">
                          <label class="col-4">Arrival Date</label>
                          <span class="col-1">:</span>
                          <input type="date" class="col-3 form-control form-control-sm" v-model="edit_sea_service.arrivalDate" :disabled="edit_sea_service.signOffReason === null"/>
                        </div>
                        <div class="row mt-2">
                          <label class="col-4">Sign-On Date</label>
                          <span class="col-1">:</span>
                          <input type="date" class="col-3 form-control form-control-sm" v-model="edit_sea_service.signOnDate" />
                        </div>
                        <div class="row mt-2">
                          <label class="col-4">Sign-Off Date</label>
                          <span class="col-1">:</span>
                          <input type="date" class="col-3 form-control form-control-sm" v-model="edit_sea_service.signOffDate" :disabled="edit_sea_service.signOffReason === null"/>
                        </div>
                        <div class="row mt-2">
                          <label class="col-4">EOC Date</label>
                          <span class="col-1">:</span>
                          <input type="date" class="col-3 form-control form-control-sm" v-model="edit_sea_service.eocDate" />
                        </div>
                        <div class="row mt-2">
                          <label class="col-4"></label>
                          <span class="col-1"></span>
                          <button type="button" class="col-3 form-control form-control-sm btn btn btn-success" @click="updateSeaService">Update</button>
                        </div>
                        <div class="row mt-3">
                          <div class="col-12">
                            <h4 :class="update_sea_service_status ? 'text-success' : 'text-danger'">{{ update_sea_service_status ? 'Updated Successfully' : ''}}</h4>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

        <pmc-table>
          <template v-slot:table-header>
            <tr>
              <th class="text-center align-middle" style="width: 2%;">#</th>
              <th class="text-center align-middle" style="width: 15%;">Agency</th>
              <th class="text-center align-middle" style="width: 10%;">Vessel</th>
              <th class="text-center align-middle" style="width: 10%;">Duration Onboard</th>
              <th class="text-center align-middle" style="width: 3%;">Rank</th>
              <th class="text-center align-middle" style="width: 8%;">Sign on</th>
              <th class="text-center align-middle" style="width: 8%;">Sign off</th>
              <th class="text-center align-middle" style="width: 5%;">Reason</th>
              <th class="text-center align-middle" style="width: 5%;">Route</th>
              <th class="text-center align-middle" style="width: 10%;">Vessel Type</th>
              <th class="text-center align-middle" style="width: 5%;">Grt</th>
              <th class="text-center align-middle" style="width: 10%;">Engine</th>
              <th class="text-center align-middle" style="width: 5%;">Kw</th>
            </tr>
          </template>
          <template v-slot:table-body>
            <tr v-for="(crewSeaService,index) in crewSeaServices" :key="crewSeaService.id"
                @click="getSeaService(crewSeaService.id)"
                style="cursor: pointer;"
                data-toggle="modal"
                data-backdrop="static"
                data-target="#sea-service-edit">

              <td class="pl-3 text-left align-middle">{{ index + 1 }}</td>
              <td class="pl-3 text-left align-middle">{{ crewSeaService.manning }}</td>
              <td class="pl-3 text-left align-middle">{{ crewSeaService.vessel }}</td>
              <td class="pl-3 text-left align-middle">
                <span v-if="crewSeaService.durationOnboardDtls?.y >= 1">
                  {{ crewSeaService.durationOnboardDtls?.y }}YR&nbsp;&nbsp;
                </span>
                <span v-if="crewSeaService.durationOnboardDtls?.m >= 1">
                  {{ crewSeaService.durationOnboardDtls?.m }}MOS&nbsp;&nbsp;
                </span>
                <span v-if="crewSeaService.durationOnboardDtls?.d >= 1">
                  {{ crewSeaService.durationOnboardDtls?.d }}DYS
                </span>
              </td>
              <td class="pl-3 text-left align-middle">{{ crewSeaService.rank }}</td>
              <td class="text-center align-middle">{{ crewSeaService.signOnDate | moment("DD MMM YYYY") }}</td>
              <td class="text-center align-middle">{{ crewSeaService.signOffDate | moment("DD MMM YYYY") }}</td>
              <td class="text-center align-middle">{{ crewSeaService.signOffReasonCode }}</td>
              <td class="text-center align-middle">{{ crewSeaService.tradeRouteCode }}</td>
              <td class="pl-3 text-left align-middle">{{ crewSeaService.vesselType }}</td>
              <td class="pl-3 text-left align-middle">{{ crewSeaService.grt }}</td>
              <td class="pl-3 text-left align-middle">{{ crewSeaService.engine }}</td>
              <td class="pl-3 text-left align-middle">{{ crewSeaService.kw }}</td>
            </tr>
          </template>
        </pmc-table>
      </div>
    </div>


  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Vue from "vue";
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import Swal from "sweetalert2";
import {AlertService} from "@/service/alert.service";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";

export default {
  name: 'CrewSeaService',

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      seaServiceFormParam: {
        filterValue: null,
        rankId: null,
        crewId: parseInt(this.$route.params.crewId, 10),
        ranks: [],
        signatory: null,
      },


      sea_services_signatories_pmc: [
        {
          id: 1,
          name: 'Mr. Lucas Jacinto',
          position: 'Vice President',
        },

        {
          id: 2,
          name: 'Mr. Reymar Paul Roxas',
          position: 'President',
        },

        {
          id: 3,
          name: 'Capt. Rommel R. Manalo',
          position: 'Operations Manager',
        },

        {
          id: 4,
          name: 'Capt. Reynaldo S. Ausan',
          position: 'Assistant Operations Manager',
        },

      ],

      sea_services_signatories_emmc: [
        {
          id: 1,
          name: 'Mr. Lucas Jacinto',
          position: 'Vice President',
        },

        {
          id: 2,
          name: 'Mr. Reymar Paul Roxas',
          position: 'Director',
        },

        {
          id: 3,
          name: 'Capt. Reynaldo S. Ausan',
          position: 'Assistant Operations Manager',
        },

      ],

      edit_sea_service: {
        id: null,
        signOnDate: null,
        signOffDate: null,
        eocDate: null,
        arrivalDate: null,
        departureDate: null,
        signOffReason: null,
      },

      update_sea_service_status: false

    }
  },

  computed: {
    ...mapGetters(
      [
        "crewSeaServices",
        "crewSeaService",
        "seaServiceAvailableRankKeys",
        "objRanks"
      ]
    )
  },

  methods: {
    ...mapActions(
      [
        "set_crew_sea_services",
        "set_crew_sea_service",
        "reset_state_crew_sea_service",
        "generateSeaServiceReport",
        "getSeaServicesRanksAvailableKeys",
        "getRankKeys",
        "updateSeaServiceDates"
      ],
    ),

    on_change_filter_all(e) {
      this.seaServiceFormParam.filterValue = e.target.value;
      this.set_crew_sea_services(this.seaServiceFormParam);
    },

    on_change_filter_emmc(e) {
      this.seaServiceFormParam.filterValue = e.target.value;
      this.set_crew_sea_services(this.seaServiceFormParam);
      this.getSeaServicesRanksAvailableKeys(this.seaServiceFormParam);
    },

    on_change_filter_pmc(e) {
      this.seaServiceFormParam.filterValue = e.target.value;
      this.set_crew_sea_services(this.seaServiceFormParam);
      this.getSeaServicesRanksAvailableKeys(this.seaServiceFormParam);
    },

    async loadSeaServiceReport() {
      AlertService.loadingAlert('Please wait ...', 'Generating PDF');
      this.seaServiceFormParam.ranks = this.seaServiceFormParam.ranks.map(item => item.rankId);
      let seaServiceReportFile = await this.generateSeaServiceReport(this.seaServiceFormParam);
      if (seaServiceReportFile) {
        Swal.close();
        await this.pdfViewer('Sea Service Report', seaServiceReportFile);
        this.seaServiceFormParam.rankId = null
        this.seaServiceFormParam.ranks = []
      } else {
        Swal.close();
      }
    },

    closeSeaServiceGenerateModal() {
      $(`#sea-service-generate`).modal('hide');
      this.seaServiceFormParam.ranks = []
    },

    closeSeaServiceEditModal() {
      $(`#sea-service-edit`).modal('hide');
      this.update_sea_service_status = false;
    },

    getSeaService(id){
      this.clearEditSeaService();
      const crewSeaExps = this.crewSeaServices;
      let crewExp = crewSeaExps.filter(function (exp){
        if(exp.id === id) return exp;
      });
      if(crewExp[0]){
        crewExp = crewExp[0];
        this.edit_sea_service.id = crewExp.id;
        this.edit_sea_service.eocDate = crewExp.eocDate;
        this.edit_sea_service.signOnDate = crewExp.signOnDate;
        this.edit_sea_service.signOffDate = crewExp.signOffDate;
        this.edit_sea_service.arrivalDate = crewExp.arrivalDate;
        this.edit_sea_service.departureDate = crewExp.departureDate;
        this.edit_sea_service.signOffReason = crewExp.signOffReasonCode;
      }

      console.log(this.edit_sea_service);
    },

    clearEditSeaService(){
      this.edit_sea_service.id = null;
      this.edit_sea_service.eocDate = null;
      this.edit_sea_service.signOnDate = null;
      this.edit_sea_service.signOffDate = null;
      this.edit_sea_service.arrivalDate = null;
      this.edit_sea_service.departureDate = null;
      this.edit_sea_service.signOffReason = null;
    },

    async updateSeaService() {
      console.log(this.edit_sea_service);
      const seaServiceUpdate = await axios.post(PMC.UPDATE_SEA_SERVICE_DATES, this.edit_sea_service).then(r => {
        this.update_sea_service_status = r.data;
        this.set_crew_sea_services(this.seaServiceFormParam);
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Sea Service')
        return false
      });
      console.log(seaServiceUpdate);
    },



  },

  mounted() {
    this.set_crew_sea_services(this.seaServiceFormParam)
  },

  created() {
    this.getRankKeys();
  },

  watch: {
    // crewId : {
    //   immediate : true,
    //   deep : true,
    //   handler(newValue,oldValue){
    //   }
    // }
  }

  /* destroyed () {
     this.reset_state_crew_sea_service();
   },


   beforeMount() {
     if(this.crewSeaServices.length == 0){
       this.set_crew_sea_service(this.crewId)
     }
   }*/
}
</script>

<style scoped>

</style>
