import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";
import {FileService} from "@/service/file.service";


const state = {
  objRecentCrewChangePlanKeys: [],
  recentCrewBatches: [],
  recentCrewBatch: {},

  totalTravelBag: [],
  totalCoverall: [],
  totalJacket: [],
  totalShortSleeves: [],
  totalLongSleeves: [],
  totalPants: [],
  totalShoes: [],
  totalStewardUniform: [],
  totalHatHairnet: [],
  totalTowel: [],

  grandTotalGearsPRPO: [],

  gearsPRPODetails: {},

};


const mutations = {
  set_recent_crew_batches(state, payload) {
    state.recentCrewBatches = payload;
  },

  set_totalTravelBag(state, payload) {
    state.totalTravelBag = payload;
  },

  set_totalCoverall(state, payload) {
    state.totalCoverall = payload;
  },

  set_totalJacket(state, payload) {
    state.totalJacket = payload;
  },

  set_totalShortSleeves(state, payload) {
    state.totalShortSleeves = payload;
  },

  set_totalLongSleeves(state, payload) {
    state.totalLongSleeves = payload;
  },

  set_totalPants(state, payload) {
    state.totalPants = payload;
  },

  set_totalShoes(state, payload) {
    state.totalShoes = payload;
  },

  set_totalStewardUniform(state, payload) {
    state.totalStewardUniform = payload;
  },

  set_totalHatHairnet(state, payload) {
    state.totalHatHairnet = payload;
  },

  set_totalTowel(state, payload) {
    state.totalTowel = payload;
  },

  set_grandTotalGearsPRPO(state, payload) {
    state.grandTotalGearsPRPO = payload;
  },

  set_obj_recent_crew_plan_keys(state, payload) {
    state.objRecentCrewChangePlanKeys = payload;
  },

  SET_GEARS_PRPO_DETAILS(state, params) {
    state.gearsPRPODetails = params;
  },

  null_obj_recent_cc_plan_keys(state) {
    state.objRecentCrewChangePlanKeys = []
  },

};


const actions = {
  async set_recent_crew_batches({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GEAR_PRPO, {params}).then((r) => {
      commit('set_recent_crew_batches', r.data.gearsPRPO.transformedGearPRPO)
      commit('set_totalTravelBag', r.data.gearsPRPO.totalTravelBag)
      commit('set_totalCoverall', r.data.gearsPRPO.totalCoverall)
      commit('set_totalJacket', r.data.gearsPRPO.totalJacket)
      commit('set_totalShortSleeves', r.data.gearsPRPO.totalShortSleeves)
      commit('set_totalLongSleeves', r.data.gearsPRPO.totalLongSleeves)
      commit('set_totalPants', r.data.gearsPRPO.totalPants)
      commit('set_totalShoes', r.data.gearsPRPO.totalShoes)
      commit('set_totalStewardUniform', r.data.gearsPRPO.totalStewardUniform)
      commit('set_totalHatHairnet', r.data.gearsPRPO.totalHatHairnet)
      commit('set_totalTowel', r.data.gearsPRPO.totalTowel)
      commit('set_grandTotalGearsPRPO', r.data.gearsPRPO.grandTotal)
      commit('set_obj_vessels', r.data?.vesselKeys)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get recent crew batches')
      return false
    })
  },


  async set_recent_crew_change_plan_keys({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GEAR_PRPO_RECENT_CREW_CHANGE_PLAN_KEYS, {params}).then((r) => {
      commit('set_obj_recent_crew_plan_keys', r.data.recentCrewBatch)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get recent crew change plan keys')
      return false
    })
  },

  async generateGearsPRPO({commit}, params) {
    return await axios.post(PMC.GENERATE_GEARS_PRPO, params).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate gears prpo')
      return false
    });
  },

  getGearsPRPODetails({commit}, param) {
    return axios.get(PMC.GEARS_PRPO_GET_DETAILS, param).then((r) => {
      commit('SET_GEARS_PRPO_DETAILS', r.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'GEARS PRPO DETAILS');
      return false;
    });
  },


  updateCrewInfoFromGearsPRPO({commit}, params) {
    let parseParams = UtilityService.deCapitalizeProperties(params);
    return axios.post(`${PMC.UPDATE_CREW_INFO_FROM_GEARS_PRPO}/${params.crewId}`, parseParams).then((response) => {
      // commit('SET_CREW_INFO', response.data);

      console.log(response.data, 'response.data')
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, '');
      return false;

    });
  },

  null_obj_recent_cc_plan_keys({commit}) {
    commit('null_obj_recent_cc_plan_keys')
  },


}


const getters = {
  recentCrewBatches: state => UtilityService.capitalizeProperties(state.recentCrewBatches),
  // objVessels: state => UtilityService.capitalizeProperties(state.objVessels),
  objRecentCrewChangePlanKeys: state => state.objRecentCrewChangePlanKeys,

  totalTravelBag: state => state.totalTravelBag,
  totalCoverall: state => state.totalCoverall,
  totalJacket: state => state.totalJacket,
  totalShortSleeves: state => state.totalShortSleeves,
  totalLongSleeves: state => state.totalLongSleeves,
  totalPants: state => state.totalPants,
  totalShoes: state => state.totalShoes,
  totalStewardUniform: state => state.totalStewardUniform,
  totalHatHairnet: state => state.totalHatHairnet,
  totalTowel: state => state.totalTowel,
  grandTotalGearsPRPO: state => state.grandTotalGearsPRPO,

};


/** export **/
export default {state, actions, mutations, getters};
