const state = {
  searchParams:{
    searchValue: null,
    searchKey : null,
    sFirstName : null,
    sMiddleName : null,
    sLastName : null,
    sRank : null,
    count : 10,
    isTrue : false,
  }
}

const mutations = {
  SET_SEARCH_PARAMS (state,params){
    state.searchParams = params;
  },

  RESET_SEARCH_CREW_PARAMS(state){
    state.searchParams = {
      searchValue: null,
      searchKey : null,
      sFirstName : null,
      sMiddleName : null,
      sLastName : null,
      sRank : null,
      count : 10,
    }
  },

  SET_S_FIRST_NAME(state,param){
    state.searchParams.sFirstName = param;
  },

  SET_S_MIDDLE_NAME(state,param){
    state.searchParams.sMiddleName = param;
  },

  SET_S_LAST_NAME(state,param){
    state.searchParams.sLastName = param;
  },

  SET_S_RANK(state,param){
    state.searchParams.sRank = param;
  }
}

const actions = {
  setSearchParams({commit,params}){
    commit('SET_SEARCH_PARAMS',params);
  },

  resetSearchCrewParam({commit}){
    commit('RESET_SEARCH_CREW_PARAMS');
  },

  set_sFirstName({commit},param){
    commit('SET_S_FIRST_NAME',param);
  },

  set_sMiddleName({commit},param){
    commit('SET_S_MIDDLE_NAME',param);
  },

  set_sLastName({commit},param){
    commit('SET_S_LAST_NAME',param);
  },

  set_sRank({commit},param){
    commit('SET_S_RANK',param);
  }
}

const getters = {
  searchParams : state => state.searchParams,
}

export default {
  state,
  actions,
  mutations,
  getters,
}
