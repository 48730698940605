<template>
  <div>
    <div class="container-fluid">

      <div style="padding: 0 30px 0 30px;">
        <div class="row py-3">
          <div class="col-md-6 text-left">
            <div class="row">
              <div class="col-6">
                <h4 class="font-weight-bold">Ad Hoc Documents</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-8 ">
                <div class="input-group mb-2 mr-sm-2">
                  <input
                    v-model="formParams.searchValue"
                    type="text"
                    class="form-control"
                    @keypress.enter="search_crew"
                    placeholder="Search Crew here">
                  <pmc-btn
                    @buttonEvent="search_crew"
                    :button-label="'search'"
                    :button-class="'btn btn-sm btn-primary'">
                    <template slot="button-icon">
                      <font-awesome-icon icon="search"/>
                    </template>
                  </pmc-btn>
                </div>
                <hr>
                <!-- Name of Crew  -->
                <div class="form-group row mt-1 text-left">
                  <label for="name-of-crew" class="col-sm-4 font-weight-bold" style="padding: 0 0 0 15px;">Crew
                    Name</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <input
                      style="padding-left:5px;"
                      v-model="documentParams.crewName"
                      :disabled="true"
                      type="text"
                      :class="'form-control form-control-sm'"
                      id="name-of-crew"
                      name="CrewName"
                      placeholder="--">
                  </div>
                </div>
                <!-- Vessel  -->
                <div class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold"
                         style="padding: 0 0 0 15px;">Vessel</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <select class="form-control form-control-sm" v-model="documentParams.vesselId"
                            :disabled="!isEditEnable">
                      <option value="0">-- Select Vessel--</option>
                      <option v-for="(vessel, index) in vessels" :value="vessel.id">
                        {{ vessel.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Rank  -->
                <div class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold"
                         style="padding: 0 0 0 15px;">Rank</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <select class="form-control form-control-sm" v-model="documentParams.rankId"
                            :disabled="!isEditEnable"
                            @change="rankChange">
                      <option value="0">-- Select Rank--</option>
                      <option v-for="(rank, index) in ranks" :value="rank.id">
                        {{ rank.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Contract Duration Months -->
                <div class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold" style="padding: 0 0 0 15px;">Contract
                    Duration (m)</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <select class="form-control form-control-sm" v-model="documentParams.contract"
                            :disabled="!isEditEnable" @change="getDurationDays">
                      <option v-for="(duration, index) in durations" :value="index">
                        {{ duration }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Contract  Days-->
                <div class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold" style="padding: 0 0 0 15px;">Contract
                    Duration (d)</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <input
                      style="padding-left:5px;"
                      v-model="documentParams.contractDurationDay"
                      type="text"
                      :class="'form-control form-control-sm'"
                      :disabled="!isEditEnable"
                      placeholder="0">
                  </div>
                </div>
                <!-- Medical Date  -->
                <div class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold" style="padding: 0 0 0 15px;">Medical
                    Date</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <input
                      style="padding-left:5px;"
                      v-model="documentParams.medicalDate"
                      type="date"
                      :disabled="!isEditEnable"
                      :class="'form-control form-control-sm'"
                      placeholder="N/A">
                  </div>
                </div>
                <!-- Remarks  -->
                <div class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold"
                         style="padding: 0 0 0 15px;">Remarks</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <select id="contract-duration" class="custom-select custom-select-sm" :disabled="!isEditEnable"
                            @change="effectiveDateChange"
                            v-model="documentParams.remarks">
                      <option value="">-- Select Option--</option>
                      <option value="CE">Contract Extension</option>
                      <option value="OP">Onboard Promotion</option>
                      <option value="SA">Salary Amendment</option>
                      <option value="VNC">Vessel Name Change</option>
                    </select>
                  </div>
                </div>
                <!-- Effective Date  -->
                <div v-if="isRemarks" class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold" style="padding: 0 0 0 15px;">Effective
                    Date</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <input
                      style="padding-left:5px;"
                      v-model="documentParams.effectiveDate"
                      type="date"
                      :disabled="!isEditEnable"
                      :class="'form-control form-control-sm'"
                      placeholder="N/A">
                  </div>
                </div>
                <!-- School Address  -->
                <div style="display: none;" class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold" style="padding: 0 0 0 15px;">School
                    Address</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <textarea :class="'form-control form-control-sm'" :disabled="!isEditEnable">{{documentParams.schoolAddress}}</textarea>
                  </div>
                </div>
                <!-- Printed Date  -->
                <div class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold" style="padding: 0 0 0 15px;">Printed
                    Date</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <input
                      style="padding-left:5px;"
                      v-model="documentParams.printDate"
                      type="date"
                      :disabled="!isEditEnable"
                      :class="'form-control form-control-sm'"
                      placeholder="N/A">
                  </div>
                </div>

                <div class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold"
                         style="padding: 0 0 0 15px;">Signatories</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <select class="form-control form-control-sm" v-model="documentParams.signatory"
                            :disabled="!isEditEnable">
                      <option value="0">-- checked by ---</option>
                      <option :value="signatory" v-for="(signatory,index) in $globalVariables.ccm_doc_signatories"
                              :key="signatory.id">
                        {{ signatory.name }} - {{ signatory.position }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row mt-1 text-left">
                  <label for="departure-date" class="col-sm-4 font-weight-bold"
                         style="padding: 0 0 0 15px;">RPS Signatories</label>
                  <span>:</span>
                  <div class="col-sm-7" style="padding: 0 0 0 10px;">
                    <select class="form-control form-control-sm" v-model="documentParams.rpsSignatory"
                            :disabled="!isEditEnable">
                      <option value="0">-- submitted by ---</option>
                      <option :value="rpsSig" v-for="(rpsSig,index) in $globalVariables.ccm_rps_signatories"
                              :key="rpsSig.id">
                        {{ rpsSig.name }}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
              <div class="col-md-4 border-left">
                <div style="border: 1px solid rgba(0,0,0,0.2);padding:10px;background: rgba(0,0,0,0.05);">
                  <div class="row mt-1 text-left">
                    <div class="col-12">

                      <div class="form-group">
                        <label for="rank" class="font-weight-bold" style="padding: 0 0 0 10px;">Generate
                          Document</label>
                        <hr>
                        <div style="padding:10px;">
                          <!-- Infosheet  -->
                          <div class="form-group row mt-1 text-left">
                            <div class="col-sm-12" style="padding: 0 0 0 10px;">
                              <pmc-btn
                                @buttonEvent="loadCcmDocumentAdhoc($globalVariables.ccmDocs.infoSheet)"
                                :disabled="!isEditEnable"
                                :button-label="''"
                                :button-class="'btn btn-sm pmc-btn-danger'">
                                <template slot="button-icon">
                                  <font-awesome-icon icon="print"/>
                                </template>
                              </pmc-btn>
                              <label for="name-of-crew" class="font-weight-bold" style="padding: 0 0 0 15px;">Information
                                Sheet</label>
                            </div>
                          </div>
                          <!-- POEA  -->
                          <div class="form-group row mt-1 text-left">
                            <div class="col-sm-12" style="padding: 0 0 0 10px;">
                              <pmc-btn
                                @buttonEvent="loadCcmDocumentAdhoc($globalVariables.ccmDocs.poea)"
                                :disabled="!isEditEnable"
                                :button-label="''"
                                :button-class="'btn btn-sm pmc-btn-danger'">
                                <template slot="button-icon">
                                  <font-awesome-icon icon="print"/>
                                </template>
                              </pmc-btn>
                              <label for="name-of-crew" class="font-weight-bold"
                                     style="padding: 0 0 0 15px;">POEA</label>
                            </div>
                          </div>
                          <!-- ITF  -->
                          <div class="form-group row mt-1 text-left">
                            <div class="col-sm-12" style="padding: 0 0 0 10px;">
                              <pmc-btn
                                @buttonEvent="loadCcmDocumentAdhoc($globalVariables.ccmDocs.itf)"
                                :disabled="!isEditEnable"
                                :button-label="''"
                                :button-class="'btn btn-sm pmc-btn-danger'">
                                <template slot="button-icon">
                                  <font-awesome-icon icon="print"/>
                                </template>
                              </pmc-btn>
                              <label for="name-of-crew" class="font-weight-bold"
                                     style="padding: 0 0 0 15px;">ITF</label>
                            </div>
                          </div>
                          <!-- RPS  -->
                          <div class="form-group row mt-1 text-left">
                            <div class="col-sm-12" style="padding: 0 0 0 10px;">
                              <pmc-btn
                                @buttonEvent="loadCcmDocumentAdhoc($globalVariables.ccmDocs.rps)"
                                :disabled="!isEditEnable"
                                :button-label="''"
                                :button-class="'btn btn-sm pmc-btn-danger'">
                                <template slot="button-icon">
                                  <font-awesome-icon icon="print"/>
                                </template>
                              </pmc-btn>
                              <label for="name-of-crew" class="font-weight-bold"
                                     style="padding: 0 0 0 15px;">RPS</label>
                            </div>
                          </div>
                          <!-- RPS Amendment -->
                          <div class="form-group row mt-1 text-left">
                            <div class="col-sm-12" style="padding: 0 0 0 10px;">
                              <pmc-btn
                                @buttonEvent="loadCcmDocumentAdhoc($globalVariables.ccmDocs.rpsAmmendment)"
                                :disabled="!isEditEnable"
                                :button-label="''"
                                :button-class="'btn btn-sm pmc-btn-danger'">
                                <template slot="button-icon">
                                  <font-awesome-icon icon="print"/>
                                </template>
                              </pmc-btn>
                              <label for="name-of-crew" class="font-weight-bold" style="padding: 0 0 0 15px;">RPS
                                Amendment</label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div style="border:1px solid rgba(0,0,0,0.2);padding:2px;height: 700px;">
                  <iframe v-if="fileData" :src="fileData" style="height: 694px; width: 100%;" frameborder="0"
                          id="certFrame"></iframe>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="add-new-crew-modal" tabindex="-1" role="dialog" aria-labelledby="addNewCrew"
         ref="addNewCrewModal"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-l" role="crews">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bolder mr-5">Select Crew</h5>
            <button class="btn btn-sm ml-2" data-dismiss="modal" aria-label="Close">
              <font-awesome-icon icon="times" class="text-danger"/>
              Close
            </button>
          </div>
          <div class="modal-body">
            <div class="py-0">
              <div class="col-12">
                <pmc-pagination :max-height="250" :page-object="crewListPagination"
                                @settingPage="set_this_page"></pmc-pagination>
                <pmc-table :loading="isReloading" :loaderColspan="13">
                  <template v-slot:table-header>
                    <tr>
                      <th class="text-left"><span class="ml-3">LAST NAME</span></th>
                      <th class="text-left"><span class="ml-3">FIRST NAME</span></th>
                      <th class="text-left"><span class="ml-3">MIDDLE NAME</span></th>
                      <th class="text-center"><span class="ml-3">RANK</span></th>
                    </tr>
                  </template>
                  <template slot="table-body">
                    <tr v-for="(crew,index) in setUpCrewList" :key="crew.id" @click="getDocumentChecklist(crew.id)"
                        data-dismiss="modal">
                      <td scope="row" class="text-left">
                        <span class="ml-3">{{ crew.lastName ? crew.lastName : '--' }}</span>
                      </td>
                      <td scope="row" class="text-left">
                        <span class="ml-3">{{ crew.firstName ? crew.firstName : '--' }}</span>
                      </td>
                      <td scope="row" class="text-left">
                        <span class="ml-3">{{ crew.middleName ? crew.middleName : '--' }}</span>
                      </td>
                      <td scope="row" class="text-left">
                        <span class="ml-3">{{ crew.rank ? crew.rank : '--' }}</span>
                      </td>
                    </tr>
                  </template>
                </pmc-table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- generating pdf loading modal -->
    <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import alertMixins, {AlertMixins} from "@/mixins/alert.mixins"
import axios from "axios";
import {PMC} from '@/config/constants.config';
import {UtilityService} from '@/service/utility.service';
import {global_variables_config} from "@/config/global-variable.config";
import {FileService} from "@/service/file.service";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";

export default {
  name: 'CrewingAdHoc',
  mixins: [AlertMixins],
  /** data **/
  data() {
    return {
      isEditEnable: false,
      dateListCount: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],
      formParams: {
        searchValue: null,
        searchKey: 'all',
        sFirstName: null,
        sMiddleName: null,
        sLastName: null,
        sRank: null,
        count: 10,
        isTrue: false,
        page: null,
      },
      searchKeys: [
        {
          id: 1,
          key: 'all',
          display: 'All',
        },
        {
          id: 2,
          key: 'first_name',
          display: 'First Name',
        },
        {
          id: 3,
          key: 'middle_name',
          display: 'Middle Name',
        },
        {
          id: 4,
          key: 'last_name',
          display: 'Last Name',
        },
        {
          id: 5,
          key: 'rank',
          display: 'Rank',
        },
      ],
      crewParam: {
        'crew_id': null,
        'checklist': 1,
      },
      selectedDocuments: [],
      crewId: 0,
      documentParams: {
        crewName: null,
        crewId: null,
        rankId: 0,
        vesselId: 0,
        contract: 9,
        contractDurationDay: null,
        medicalDate: new Date().toISOString().slice(0, 10),
        remarks: '',
        printDate: new Date().toISOString().slice(0, 10),
        schoolAddress: '',
        signatory: 0,
        rpsSignatory: 0,
        effectiveDate: new Date().toISOString().slice(0, 10),
        isAdhoc: true,
      },
      ranks: {},
      vessels: {},
      durations: {},
      crew: null,
      ccmDocsParams: {
        crewId: null,
        vesselId: null,
        ccmDocument: null,
        signatory: null,
        contract: 9,
        printDate: null,
        remarks: '',
        page: 1,
      },
      fileData: null,
      isRemarks: false,
    };
  },
  /** methods**/
  methods: {
    ...mapActions(
      [
        'getCrewListPaginated',
        'resetCrewListPagination',
        'nullSetupCrewList',
        'getCrewDocumentChecklist',
        'generateCcmDocument',
      ],
    ),

    effectiveDateChange() {
      if (this.documentParams.remarks !== '') {
        this.isRemarks = true;
      } else this.isRemarks = false;
    },

    rankChange() {
      if (this.documentParams.rankId === 8 || this.documentParams.rankId === 18) {
        this.documentParams.contract = 12;
      } else {
        this.documentParams.contract = 9;
      }
    },

    showModal(crew_id) {
      this.isEditEnable = true
      this.crewId = crew_id;
      // this.$refs['pre-departure-modal'].show();
      this.isEditEnable = false;
      return this.crewId;
    },
    parseDate(dateStr) {
      return new Date(dateStr).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    async getDocumentChecklist(crew_id) {
      this.crewId = crew_id;
      for (let crew in this.setUpCrewList) {
        if (this.setUpCrewList[crew].id === crew_id) {
          this.selectedCrew = this.setUpCrewList[crew];
          this.crewParam['crew_id'] = crew_id;
          await this.getCrewDataAdHoc(this.crewParam);
        }
      }
    },

    async getCrewDataAdHoc(params) {
      console.log(params);
      return await axios.post(PMC.GET_CREW_ADHOC_DETAILS, params).then((r) => {
        console.log(r.data);
        this.crew = r.data;
        this.ranks = this.crew.ranks;
        this.vessels = this.crew.vessels;
        this.durations = this.crew.duration;
        this.documentParams.crewId = this.crew.crew_id;
        this.documentParams.vesselId = this.crew.vessel_id;
        this.documentParams.crewName = this.crew.crewFullname;
        this.documentParams.rankId = this.crew.rank_id;
        if (this.crew.rank_id === 8 || this.crew.rank_id === 18) {
          this.documentParams.contract = 12;
        } else {
          this.documentParams.contract = 9;
        }
        console.log(this.documentParams);
        this.isEditEnable = true;
        this.getDurationDays();
        return true;
      }).catch((errors) => {
        UtilityService.generateResponseMessage(errors, 'Get Crew Paginated');
        return false;
      });
    },

    getDurationDays() {
      this.documentParams.contractDurationDay = parseInt(this.documentParams.contract) * 30;
    },

    async loadCcmDocumentAdhoc(ccmDoc) {
      AlertService.loadingAlert('Please wait ...', 'Generating PDF')
      this.documentParams.ccmDocument = ccmDoc;
      this.documentParams.page = 1;
      let ccmDocumentFile = await this.generateCcmDocumentAdhoc(this.documentParams);
      if (ccmDocumentFile) {
        Swal.close();
        if (ccmDoc === 6) {
          await this.pdfViewerInterOcean(global_variables_config.ccmDocsName[ccmDoc] + ' File', ccmDocumentFile.page1, ccmDocumentFile.page2);
        } else {
          this.fileData = ccmDocumentFile;
          //await this.pdfViewer(global_variables_config.ccmDocsName[ccmDoc] + ' File', ccmDocumentFile);
        }
      } else {
        Swal.close();
      }
    },

    async generateCcmDocumentAdhoc(params) {

      return await axios.post(PMC.GENERATE_CCM_DOCUMENT, params).then(r => {
        if (params.ccmDocument === 6) {
          let docs = {};
          docs.page1 = r.data.page1 ? FileService.base64FileToBlobUrl(r.data.page1) : false;
          docs.page2 = r.data.page2 ? FileService.base64FileToBlobUrl(r.data.page2) : false;
          return docs;
        } else return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate ccm document')
        return false
      });
    },


    selectCheckedBy() {
      if (parseInt(this.checkedBy) === 1) this.checkedByPosition = "Operations Manager";
      else if (parseInt(this.checkedBy) === 2) this.checkedByPosition = "Asst. Operations Manager";
    },
    async loadChecklistPDF() {
      let params = {
        'crew_id': this.crewParam.crew_id
      };
      let checklistFile = await this.generateCrewChecklist(params);
      if (checklistFile) {
        this.pdfViewer('Pre Departure Checklist', checklistFile);
      } else {
      }
    },
    set_editable() {
      this.isEditEnable = !this.isEditEnable;
    },
    get_all_crew_paginated() {
      this.getCrewListPaginated(this.formParams);
    },
    set_this_page(e) {
      this.crewListPagination.currentPage = e;
      this.formParams.page = e;
      this.get_all_crew_paginated();
    },
    set_list_count() {
      this.get_all_crew_paginated();
    },
    search_crew() {
      this.formParams.page = 1;
      this.get_all_crew_paginated();
      let elemModal = this.$refs.addNewCrewModal;
      $(elemModal).modal('show');
    },
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'setUpCrewList',
        'crewListPagination',
        'isReloading',

        //crew_document_checklist.module.js
        'crewFormData',
        'preDepartureChecklist',
        'cchCategories',
        'crewDocumentList',
        'docLastIndex',
        'signatories',
        'docLocation',
        'remarks',

      ],
    ),
  },

  /** mounted **/
  mounted() {
    this.selectCheckedBy();
  },

  /** created **/
  created() {
    this.get_all_crew_paginated();
    this.$globalVariables = global_variables_config;
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.resetCrewListPagination();
    this.nullSetupCrewList();
  },

};
</script>

<style scoped>
.table-main-header tr th {
  font-size: 9pt !important;
  font-weight: 800;
}

.noExpiry {
  background: #e0e0e0;
}

.remarks-icon {
  cursor: pointer;
  float: right;
}

.remarks-no-data {
  color: rgba(0, 0, 0, 0.4);
}

.document-select-checkbox {
  height: 8px;
}

::-webkit-scrollbar {
  width: 8px !important;
}

small[title]:hover::after {
  transition: all 0.1s ease 0.5s;
  border-radius: 2px;
  padding: 20px;
}

</style>
