import axios from "axios";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";

const initStandbyRatio = () => {
  return {
    standbyRatios: [],
    onboardHasRecommendation: [],
  }
};

const state = {
  ...initStandbyRatio()
};

const mutations = {
  set_standbyRatios(state, params) {
    state.standbyRatios = params
  },

  set_onboardHasRecommendation(state, params) {
    state.onboardHasRecommendation = params
  },
}

const actions = {
  async set_standbyRatioList({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GET_STANDBY_RATIO_LIST, {params}).then((r) => {
      commit('set_standbyRatios', r.data)
      commit('set_is_loading', false)
      return true;

    }).catch((er) => {
      commit('set_is_loading', false)
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get standby ratio')
      }
      return false;
    })
  },

  async set_onboardHasRecommendation({commit}, params) {
    return await axios.get(`${PMC.GET_ONBOARD_HAS_RECOMMENDATION}/${params}`).then((r) => {
      commit('set_onboardHasRecommendation', r.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Get onboard recommendation');
      return false;
    });
  },

  async print_retention_list({commit},params){
    return await axios.post(PMC.STANDBY_RATIO_LIST_PRINT,params).then((r)=>{
      return r.data;
    }).catch((er)=>{
      console.log('get retention list',er)
      return er;
    });
  },
}

const getters = {
  standbyRatios: state => UtilityService.capitalizeProperties(state.standbyRatios),
  onboardHasRecommendation: state => UtilityService.capitalizeProperties(state.onboardHasRecommendation),
}

export default {state, actions, mutations, getters};
