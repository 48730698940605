<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-6">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Checklist Category</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#new-checklist-category-modal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Checklist Category
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search checklist category name"
                v-model="checklistCategoryFormParam.searchValue"
                @keypress.enter="searchChecklistCategory"
              >

              <button class="btn btn-sm pmc-btn-prime" @click="searchChecklistCategory">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- status -->
          <!--          <div class="col-sm-2">-->
          <!--            <div class="form-group row">-->
          <!--              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>-->
          <!--              <span class="col-form-label">:</span>-->
          <!--              <div class="col-sm-7 text-left">-->
          <!--                <select name="listCount" id="list-stat" class="form-control custom-select-sm"-->
          <!--                        v-model="tradeRouteFormParam.status"-->
          <!--                        @change="on_change_status">-->
          <!--                  <option :value="null">&#45;&#45;select&#45;&#45;</option>-->
          <!--                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">-->
          <!--                    {{ status.placeholder }}-->
          <!--                  </option>-->
          <!--                </select>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="checklistCategoryFormParam.count"
                        @change="on_change_count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="checklistCategoryPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="align-middle" width="7%">DESCRIPTION</th>
                <!--                <th scope="row" class="align-middle" width="7%">PARENT CATEGORY</th>-->
                <th scope="row" class="align-middle" width="7%">SEQUENCE</th>
                <th scope="row" class="align-middle" width="7%">STATUS</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-for="(checklistCategory, index) in checklistCategories">
                <td>{{ index + 1 }}</td>
                <td scope="col" class="p-0 text-center">{{ checklistCategory.description }}</td>
                <!--                <td scope="col" class="p-0 text-center">-->
                <!--                  <span class="badge badge-pill badge-warning" v-if="checklistCategory.parentCategoryId == 0">Parent Category</span>-->
                <!--                  <span class="badge badge-pill badge-success" v-else>{{ checklistCategory.parentCategoryName }}</span>-->
                <!--                </td>-->
                <td scope="col" class="p-0 text-center">{{ checklistCategory.sequence }}</td>
                <td scope="col" class="p-0 text-center">
                  <!--                  {{ checklistCategory.status == 1 ? 'ACTIVE' : 'INACTIVE' }}-->
                  <template v-if="checklistCategory.status == 1">
                    <font-awesome-icon icon="check" style="transform: scale(1.7); color:#28a745;" title="ACTIVE"/>
                  </template>

                  <template v-if="checklistCategory.status == 0">
                    <font-awesome-icon icon="minus" style="transform: scale(1.7); color:#DC4C64;" title="INACTIVE"/>
                  </template>

                </td>
                <td scope="col" class="p-0 text-center"> {{ checklistCategory.created_by }}</td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#view-checklist-category-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="viewCategory(checklistCategory.id)"
                  >
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="deleteChecklistCategory(checklistCategory.id)"
                  >
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new checklist category modal   -->
    <div class="modal fade" id="new-checklist-category-modal" tabindex="-1" role="dialog"
         aria-labelledby="newPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newPrincipalModalLabel">NEW CHECKLIST CATEGORY</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new Replace -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">DESCRIPTION</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newChecklistCategory.description"
                    />
                  </div>
                </div>


                <!--                <div class="form-group row mt-2" v-if="objChecklistCategories ? (objChecklistCategories.length != 0) : '' ">-->
                <!--                  <label for="permanent-city" class="col-sm-3 col-form-label">PARENT CATEGORY</label>-->
                <!--                  <span>:</span>-->
                <!--                  <div class="col-sm-5">-->
                <!--                    <multi-select-->
                <!--                      v-model="newChecklistCategory.parentCategory"-->
                <!--                      :options="objChecklistCategories"-->
                <!--                      :custom-label="categoryDescription"-->
                <!--                      :select-label="'Select parent category'"-->
                <!--                      placeholder="Select parent category"-->
                <!--                      label="employee"-->
                <!--                      track-by="id"-->
                <!--                    >-->
                <!--                    </multi-select>-->
                <!--                  </div>-->
                <!--                </div>-->


                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">SEQUENCE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="number"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newChecklistCategory.sequence"
                    />
                  </div>
                </div>

                <!--   status -->
                <!--                <div class="form-group row mt-2">-->
                <!--                  <label class="col-sm-3 col-form-label">IS PARENT ? </label>-->
                <!--                  <span class="col-form-label">:</span>-->
                <!--                  <div class="col-sm-5">-->
                <!--                    <input-->
                <!--                      type="checkbox"-->
                <!--                      class="form-check form-check-inline ml-2 mt-2"-->
                <!--                      placeholder="-&#45;&#45;"-->
                <!--                    />-->
                <!--                  </div>-->
                <!--                </div>-->

                <!--                <div class="form-group row mt-2">-->
                <!--                  <label class="col-sm-3 col-form-label">PARENT</label>-->
                <!--                  <span class="col-form-label">:</span>-->
                <!--                  <div class="col-sm-5">-->
                <!--                    <input type="number"-->
                <!--                           class="form-control form-control-sm"-->
                <!--                           placeholder="-&#45;&#45;"-->
                <!--                    />-->
                <!--                  </div>-->
                <!--                </div>-->


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newChecklistCategory.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view checklist category  -->
    <div class="modal fade" id="view-checklist-category-modal" tabindex="-1" role="dialog"
         aria-labelledby="viewPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="view-checklist-category-modal-label">CHECKLIST CATEGORY</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new Replace -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">DESCRIPTION</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="checklistCategoryData.description"
                           :disabled="!isEditable"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">SEQUENCE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="number"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="checklistCategoryData.sequence"
                           :disabled="!isEditable"
                    />
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="checklistCategoryData.status"
                      :disabled="!isEditable"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit"
            >
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel"
            >
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** data **/
  data() {
    return {

      newChecklistCategory: {},
      checklistCategoryData: {},

      isEditable: false,

      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      checklistCategoryParam: {
        id: null,
        name: null,
      },

    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_checklistCategories',
      'create_checklistCategory',
      'update_checklistCategory',
      'delete_checklistCategory',

      'getChecklistCategoryKeys',
      'set_checklistCategory',
    ]),

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Checklist Category', 'question')
      if (await __create) {

        let params = {
          description: this.newChecklistCategory.description ? this.newChecklistCategory.description : '',
          parent: this.newChecklistCategory.parentCategory ? this.newChecklistCategory.parentCategory.id : '',
          sequence: this.newChecklistCategory.sequence ? this.newChecklistCategory.sequence : '',
          is_display_sequence: 1,
          status: this.newChecklistCategory.status ? 1 : 0,
        };

        let checklistCategory = await this.create_checklistCategory(params);
        if (checklistCategory) {
          AlertService.successAlertService('Create checklist category record successful', 'Create checklist category');
          this.hideModal('new-checklist-category-modal')
          this.newChecklistCategory = {};
          this.set_checklistCategories();
          this.getChecklistCategoryKeys();
        }
      }
    },

    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update Checklist Category', 'question');
      if (await __update) {
        let params = {
          id: this.checklistCategoryData.id,
          description: this.checklistCategoryData.description ? this.checklistCategoryData.description : '',
          sequence: this.checklistCategoryData.sequence ? this.checklistCategoryData.sequence : '',
          status: this.checklistCategoryData.status ? 1 : 0,
        };

        let checklistCategory = await this.update_checklistCategory(params);
        if (checklistCategory) {
          AlertService.successAlertService("Updated successfully", 'Update checklist category');
          this.hideModal('view-checklist-category-modal')
          this.isEditable = false
        }
      }
    },

    async deleteChecklistCategory(checklistCategoryId) {
      let _del = await this.delete_checklistCategory(checklistCategoryId);
      if (_del) {
        this.getChecklistCategoryKeys();
      }
    },

    viewCategory(categoryId) {
      this.set_checklistCategory(categoryId)
    },


    set_edit() {
      this.isEditable = true
    },

    set_cancel(e) {
      this.isEditable = false
      this.checklistCategoryData = Object.assign({}, this.checklistCategory);
    },

    categoryDescription({description}) {
      return `${description}`
    },

    set_this_page(e) {
      this.checklistCategoryFormParam.searchValue = ''
      this.checklistCategoryPagination.currentPage = e
      this.checklistCategoryFormParam.page = e
      this.pageLastLeft = e
      this.set_checklistCategories(this.checklistCategoryFormParam)
    },

    searchChecklistCategory() {
      if (this.checklistCategoryFormParam.searchValue === '') {
        this.checklistCategoryFormParam.page = this.pageLastLeft
        this.set_checklistCategories(this.checklistCategoryFormParam)
      }

      if (this.checklistCategoryFormParam.searchValue) {
        this.checklistCategoryFormParam.page = 1
      }

      this.set_checklistCategories(this.checklistCategoryFormParam)
    },

    on_change_count(e) {
      this.checklistCategoryFormParam.page = 1
      this.checklistCategoryFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.checklistCategoryFormParam.count = e.target.value
      this.set_checklistCategories(this.checklistCategoryFormParam)
    },

  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'checklistCategories',

        'objChecklistCategories',
        'checklistCategory',

        'checklistCategoryFormParam',
        'checklistCategoryPagination',

      ],
    ),
  },

  /**  created  **/
  async created() {
    await this.set_checklistCategories();
    await this.getChecklistCategoryKeys();
  },

  /** watch **/
  watch: {
    'checklistCategory'() {
      this.checklistCategoryData = Object.assign({}, this.checklistCategory);
    },
  },


}
</script>

<style scoped>

</style>
