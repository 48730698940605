import {UtilityService} from '@/service/utility.service';


/**
 *@author put all the obj keys needed in state management that are not in the object.key.module
 * object2.key.module
 */
const initObject2Keys = () => {
  return {
    objEcdisKeys: [],
    objEmployeeKeys: [],
    objVessels: [],
    objCountries : [],
    objPorts : [],
    objSignOffReasons : [],
    objCrewEndorsers : [],
  };
};

/** state **/
const state = {
  ...initObject2Keys(),
};

/** mutations **/
const mutations = {
  //region set keys
  set_obj_ecdis(state, payload) {
    state.objEcdisKeys = payload;
  },

  set_obj_employee(state, payload) {
    state.objEmployeeKeys = payload;
  },

  set_obj_vessels(state, payload) {
    state.objVessels = payload;
  },

  set_obj_countries(state,payload){
    state.objCountries = payload;
  },

  set_obj_ports(state,payload){
    state.objPorts = payload;
  },

  set_obj_sign_off_reasons(state,payload){
    state.objSignOffReasons = payload;
  },

  set_obj_crew_endorsers(state,payload){
    state.objCrewEndorsers = payload;
  },

  //endregion

  //region null keys
  null_ecdis_keys(state) {
    state.objEcdisKeys = [];
  },

  null_employee_keys(state) {
    state.objEmployeeKeys = [];
  },

  null_obj_vessels(state) {
    state.objVesselKeys = [];
  },

  null_obj_countries(state){
    Object.assign(state.objCountries,[])
  },

  null_obj_ports(state){
    Object.assign(state.objPorts,[]);
  },

  null_obj_sign_off_reasons(state){
    Object.assign(state.objSignOffReasons,[]);
  },

  null_obj_crew_endorsers(state){
    Object.assign(state.objCrewEndorsers,[])
  },
  //endregion

  /** reset all objectKeys 2 **/
  reset_object2_keys(state) {
    Object.assign(state, initObject2Keys());
  },
};

/** actions **/
const actions = {
  set_ecdis_keys({commit}, payload) {
    commit('set_ecdis_keys', payload);
  },

  set_employee_keys({commit}, payload) {
    commit('set_employee_keys', payload);
  },

  set_obj_countries({commit}, payload) {
    commit('set_obj_countries',payload)
  },

  set_obj_ports({commit}, payload) {
    commit('set_obj_ports',payload)
  },

  set_obj_sign_off_reasons({commit}, payload) {
    commit('set_obj_sign_off_reasons',payload)
  },

  //objCrewEndorsers
  set_obj_crew_endorsers({commit},payload){
    commit('set_obj_crew_endorsers',payload);
  },

  null_ecdis_keys({commit}) {
    commit('null_ecdis_keys');
  },

  null_obj_vessels({commit}) {
    commit('null_obj_vessels')
  },

  null_employee_keys({commit}) {
    commit('null_employee_keys');
  },

  null_obj_countries({commit}) {
    commit('null_obj_countries')
  },

  null_obj_ports({commit}) {
    commit('null_obj_ports');
  },

  null_obj_sign_off_reasons({commit}) {
    commit('null_obj_sign_off_reasons')
  },

  null_obj_crew_endorsers({commit}){
    commit('null_obj_crew_endorsers')
  },

  resetObject2Keys({commit}) {
    commit('reset_object2_keys');
  },
};

/** getters **/
const getters = {
  objEcdisKeys: state => UtilityService.capitalizeProperties(state.objEcdisKeys),
  objEmployeeKeys: state => UtilityService.capitalizeProperties(state.objEmployeeKeys),
  objVessels: state => UtilityService.capitalizeProperties(state.objVessels),
  objCountries : state => UtilityService.capitalizeProperties(state.objCountries),
  objPorts : state => UtilityService.capitalizeProperties(state.objPorts),
  objSignOffReasons : state => UtilityService.capitalizeProperties(state.objSignOffReasons),
  objCrewEndorsers : state => UtilityService.capitalizeProperties(state.objCrewEndorsers),
};

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
};
