<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Masterlist (SHOEI Format)</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>


        </div>

        <hr/>


        <div class="row mt-5">

          <div class="col-md-12">
            <div class="row">

              <div class="col-sm-3">
                <div class="form-group row">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 18px;" v-if="selectedVesselName">
                    {{ selectedVesselName }}
                  </span>
                  <span class="ml-4 font-weight-bold lead" style="font-size: 18px;" v-else>
                    --SELECT VESSEL--
                  </span>
                  <!--                  <span class="ml-4 font-weight-bold lead" style="font-size: 15px;"> {{ selectedVesselName }} </span>-->

                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group row">
                  <label for="list-count" class="col-form-label">VESSEL</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7 text-left">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="selectedVessel"
                            @change="onChangeVessel($event.target.selectedOptions[0].text)"
                            required
                            :disabled="isReloading"
                    >
                      <option :value="null">--SELECT VESSEL--</option>
                      <option :value="vessel.id" v-for="(vessel,index) in objVessels" :key="vessel.id">
                        {{ vessel.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="loadOnboardCrewList"
                          v-if="isGenerateOnboardButton"
                  >
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
              </div>


            </div>
          </div>


        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <!--                <pmc-pagination :page-object="tradeRoutePagination" @settingPage="set_this_page"></pmc-pagination>-->
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">

          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">

                <!--SHOEI FORMAT-->
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 8%;">Seafarer Name</th>
                    <th>Rank</th>
                    <th>Birth Date</th>
                    <th>Birth Place</th>
                    <th>SIRB</th>
                    <th><span class="d-block">SIRB</span>Validity</th>
                    <th>Passport</th>
                    <th><span class="d-block">Passport</span>Validity</th>
                    <th><span class="d-block">US Visa</span>Expiry</th>
                    <th>Sign-On Date</th>
                  </tr>
                  </thead>

                  <template v-if="isReloading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>


                  <template v-else>
                    <template v-if="onboardCrews ? (onboardCrews.length != 0) : '' ">
                      <tbody>
                      <tr v-for="(onboardCrew, index) in onboardCrews">
                        <td>
                          {{ parseInt(index) + 1 }}
                        </td>
                        <td
                          :class="{
                              'with-one-higher-license' : onboardCrew.hasHigherLicense,
                           }"
                        >
                          <span v-if="onboardCrew.hasHigherLicense">*</span>
                          <a href="#"
                             @click.stop="gotoCrewProfileNewWindow(onboardCrew.crewId, 'all-info')"
                             style="color:black;">
                            <small><b>{{ onboardCrew.crewSignOff }} </b></small>
                          </a>


                        </td>
                        <td>
                          {{ onboardCrew.rank }}
                        </td>
                        <td>
                          {{ onboardCrew.birthdate }}
                        </td>
                        <td>
                          {{ onboardCrew.birthplace }}
                        </td>
                        <td>
                          {{ onboardCrew.documentsData?.sirb?.document_no }}
                        </td>
                        <td :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.sirb?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.sirb?.nearExpiry2months,
                                }">
                          {{ onboardCrew.documentsData?.sirb?.dateExpiryFormat2 }}
                        </td>
                        <td>
                          {{ onboardCrew.documentsData?.passport?.document_no }}
                        </td>
                        <td :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.passport?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.passport?.nearExpiry2months,
                                }">
                          {{ onboardCrew.documentsData?.passport?.dateExpiryFormat2 }}
                        </td>
                        <td :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.usVisa?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.usVisa?.nearExpiry2months,
                                }">
                          {{ onboardCrew.documentsData?.usVisa?.dateExpiryFormat2 }}
                        </td>
                        <td>
                          {{ onboardCrew.ccOffSignOnDate }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%" class="bg-light-danger font-weight-bolder">
                          <p class="text-center mt-3">No Results</p>
                        </td>
                      </tr>
                    </template>


                  </template>

                </table>

              </div>
            </div>
          </div>


          <template>
            <table style="width:75%; margin-left: 50px">
              <tbody>
              <tr>
                <td class="left-align">Total Crew: <b>{{ onboardCrewDetails.onboardCrewCount }}</b></td>
                <td style="text-align: left;">Officer: <b>{{ onboardCrewDetails.officerLevelCount }}</b></td>
                <td style="text-align: left;">Rating: <b>{{ onboardCrewDetails.ratingLevelCount }}</b></td>
              </tr>
              </tbody>
            </table>
            <br>
            <div class="row">
              <div class="col-6">
                <table style="width:50%;">
                  <tbody>
                  <tr>
                    <td class="left-align"><b>GOC License</b></td>
                  </tr>
                  </tbody>
                </table>
                <table style="width:100%;float:left;">
                  <tbody>
                  <tr class="left-align" v-for="(topFourCrew, index) in onboardCrewDetails.topFourCrewRanks">
                    <th scope="row" style="width: 5%">{{ index + 1 }}</th>
                    <td style="width: 10%">{{ topFourCrew.rankCaptain ?? null }}</td>
                    <td style="width: 40%">{{ topFourCrew.crewSignOff ?? null}}</td>
                    <td style="width: 40%">{{ topFourCrew.documentsData?.goc?.document_no }}
                      <span v-if="topFourCrew.documentsData?.goc.document_no &&
                                    topFourCrew.documentsData?.goc.dateExpiryFormat2">
                          /
                        </span>
                      {{ topFourCrew.documentsData?.goc?.dateExpiryFormat2 }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-6" v-if="onboardCrewDetails.flagId == $MyGlobalFunction.vesselFlag.panama_flag">
                <table style="width:50%;">
                  <tbody>
                  <tr>
                    <td class="left-align"><b>PANAMA GMDSS (optional - PNM Flag Vsl only)</b></td>
                  </tr>
                  </tbody>
                </table>
                <table style="width:100%;float:left;">
                  <tbody>
                  <tr class="left-align" v-for="(topFourCrew, index) in onboardCrewDetails.topFourCrewRanks">
                    <th scope="row" style="width: 5%">{{ index + 1 }}</th>
                    <td style="width: 10%">{{ topFourCrew.rankCaptain }}</td>
                    <td style="width: 40%">{{ topFourCrew.crewSignOff }}</td>
                    <td style="width: 40%">
                      {{ topFourCrew.documentsData?.panamaGmddsBook?.document_no }}
                      <span v-if="topFourCrew.documentsData?.panamaGmddsBook.document_no &&
                                    topFourCrew.documentsData?.panamaGmddsBook.dateExpiryFormat2">
                          /
                        </span>
                      {{ topFourCrew.documentsData?.panamaGmddsBook?.dateExpiryFormat2 }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-6" v-if="onboardCrewDetails.flagId == $MyGlobalFunction.vesselFlag.singapore_flag">
                <table style="width:100%;">
                  <tbody>
                  <tr>
                    <td class="left-align"><b>IDA COE SINGAPORE GMDSS (Singapore)</b></td>
                  </tr>
                  </tbody>
                </table>

                <table style="width:100%;float:left;">
                  <tbody>
                  <tr class="left-align" v-for="(topFourCrew, index) in onboardCrewDetails.topFourCrewRanks">
                    <th scope="row" style="width: 5%">{{ index + 1 }}</th>
                    <td style="width: 10%">{{ topFourCrew.rankCaptain }}</td>
                    <td style="width: 40%">{{ topFourCrew.crewSignOff }}</td>
                    <td style="width: 40%">{{ topFourCrew.documentsData?.singaporeGmddsEndo?.document_no }}
                      <span v-if="topFourCrew.documentsData?.singaporeGmddsEndo.document_no &&
                                  topFourCrew.documentsData?.singaporeGmddsEndo.dateExpiryFormat2"
                      >
                        /
                      </span>
                      {{ topFourCrew.documentsData?.singaporeGmddsEndo?.dateExpiryFormat2 }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table style="width:100%; margin-top: 20px; text-align: left">
              <tbody>
              <tr>
                <td><span style="font-weight:bold;">Legend : * - With one higher license</span></td>
                <td></td>
                <td></td>
                <td><span style="text-align:right;color:blue;"><b>Promoted Onbooard</b></span></td>
                <td></td>
                <td><span style="text-align:right;color: green"><b>Newly Embarked</b></span></td>
                <td></td>
                <td>
                  <span style="text-align:right;background-color: Yellow"><b>Near Expiry (2 Mos.↓)</b></span>
                </td>
                <td></td>
                <td>
                      <span style="text-align:right;background-color: Red;color:white;">
                        <b>Expired Documents (1 Mos.↓)</b>
                      </span>
                </td>
                <td></td>
              </tr>
              </tbody>
            </table>

          </template>
        </div>
      </div>
    </div>

    <!--  Edit Gear Size Modal modal   -->

    <!-- generating pdf loading modal -->
    <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  name: "MasterlistShoeiFormatList",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      isEditable: false,
      selectedVessel: null,
      selectedVesselName: null,
      onboardCrewListFormParam: {
        isGenerate: false,
        vesselId: null,
        reportType: global_variables_config.onboardCrewListReportType.shoeiFormat,
      },
      isGenerateOnboardButton: false,
    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
      // 'set_crew_change_plans',
      'set_onboardCrews',
      'generateOnboardCrewList',
      'resetOnboardCrews',
      'resetOnboardCrewDetails',
    ]),

    onChangeVessel(name) {
      this.selectedVesselName = name;
      this.onboardCrewListFormParam.vesselId = this.selectedVessel
      this.onboardCrewListFormParam.isGenerate = false
    },

    async loadOnboardCrewList() {
      this.onboardCrewListFormParam.isGenerate = true
      AlertService.loadingAlert('Please wait ...', 'Loading Masterlist ...');
      let onboardCrewListFile = await this.generateOnboardCrewList(this.onboardCrewListFormParam);
      if (onboardCrewListFile) {
        Swal.close();
        await this.pdfViewerMasterChecklist('File', onboardCrewListFile);
      } else {
        Swal.close();
      }
    },

    debouncedSetOnboardCrews: _.debounce(function () {
      this.set_onboardCrews(this.onboardCrewListFormParam);
    }, 500),


  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objVessels',
        // 'crewChangePlans',
        'onboardCrews',
        'onboardCrewDetails',
        'isReloading',
      ]),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    this.$MyGlobalFunction = global_variables_config
    await this.getCrewComplimentPerVessel();
  },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },

  /** destroyed **/
  destroyed() {
    this.resetOnboardCrews();
    this.resetOnboardCrewDetails();
  },


  /** watch **/
  watch: {
    selectedVesselName() {
      if (this.selectedVessel) {
        this.isGenerateOnboardButton = true
        this.debouncedSetOnboardCrews();
      } else {
        this.isGenerateOnboardButton = false
      }
    },
  },


}
</script>

<style scoped>
.left-align {
  text-align: left;
}
</style>
