<template>
  <div class="container-fluid py-2 px-4">
    <div class="row py-2 text-left">
      <div class="col-md-12">
        <h5>Applicant Tracking</h5>
        <div class="row  py-1">
          <div class="col-3">
            <div class="input-group mb-2 mr-sm-2">
              <div class="this-append-key-selector">
                <div class="input-group-prepend">
                  <select class="form-control form-control-sm text-left" v-model="formSearchParams.searchKey">
                    <option :value="searcKey.key" v-for="(searcKey,index) in searchKeys">{{ searcKey.display }}</option>
                  </select>
                </div>
              </div>

              <input
                  v-model="formSearchParams.searchValue"
                  @keypress.enter="set_search_crew"
                  type="text"
                  class="form-control form-control-sm"
                  id="formdata-searchvalue"
                  placeholder="Search Crew here">

              <pmc-btn
                  @buttonEvent="set_search_crew"
                  :button-label="'search'"
                  :button-class="'btn btn-xs pmc-btn-prime'">
                <template slot="button-icon">
                  <font-awesome-icon icon="search"/>
                </template>
              </pmc-btn>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group row mt-1">
              <label class="col-sm-4 col-form-label">Applicant Status</label>
              <span>:</span>
              <div class="col-sm-5">
                <select
                    @change="on_applicant_status"
                    v-model="formSearchParams.aplStatus"
                    name="CountPicker"
                    class="custom-select custom-select-sm">
                  <option :value="null">--All--</option>
                  <option :value="apStat.id" v-for="(apStat,index) in applicantStatuses" :key="apStat.id">
                    {{ titleCase(apStat.name) }}
                  </option>
                </select>
              </div>
            </div>
          </div>


          <div class="col-3">
            <div class="form-group row mt-1" style="padding: 0; margin: 0;">
              <label class="col-sm-4 col-form-label">Select Year</label>
              <span>:</span>
              <div class="col-sm-4">
                <select
                    name="YearPicker"
                    id="year-picker"
                    class="custom-select custom-select-sm"
                    v-model="formSearchParams.year" @change="set_year">
                  <option :value="null" disabled>--select--</option>
                  <option :value="year" v-for="(year,index) in generateYear()" :selected="formSearchParams.year === year">{{ year }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div class="form-group row mt-1">
              <label class="col-sm-4 col-form-label">Count</label>
              <span>:</span>
              <div class="col-sm-4">
                <select
                    name="CountPicker"
                    class="custom-select custom-select-sm"
                    v-model="formSearchParams.count" @change="set_list_count">
                  <option :value="count" v-for="(count,index) in listCounts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <div class="row py-1">
          <div class="col-6">
            <pmc-pagination
                :page-object="applicantPagination"
                @settingPage="set_this_page"
            ></pmc-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="py-1 text-left">
      <pmc-table :loading="isReloading" :loader-colspan="12">
        <template v-slot:table-header>
          <tr>
            <th scope="col" style="width: 2% !important;" class="align-middle">CrewId</th>
            <th scope="col" style="width: 2% !important;" class="align-middle">Crewcode</th>
            <th scope="col" style="width: 13% !important;" class="align-middle">Name</th>
            <th scope="col" style="width: 2% !important;" class="align-middle">Rank</th>
            <th scope="col" style="width: 5% !important;" class="align-middle">Date <br> Application</th>
            <th scope="col" style="width: 5% !important;" class="align-middle">Date <br> Qualified</th>
            <th scope="col" style="width: 5% !important;" class="align-middle">Date <br> Accepted</th>
            <th scope="col" style="width: 8% !important;" class="align-middle">Endorsed <br> By / Others</th>
            <th scope="col" style="width: 8% !important;" class="align-middle">Qualified By</th>
            <th scope="col" style="width: 8% !important;" class="align-middle">Accepted By</th>
            <th scope="col" style="width: 5% !important;" class="align-middle">Status</th>
            <th scope="col" style="width: 4% !important;" class="align-middle">Action</th>
          </tr>
        </template>

        <template slot="table-body">
          <tr v-for="(applicant,index)  in applicants" :key="applicant.applicationId">
            <td>
              <span>{{ applicant.crewId }}</span>
            </td>

            <td class="text-center">
              <span>{{ applicant.crewCode }}</span>
            </td>

            <td class="text-left">
              <span class="pl-1 on-hover" @click="goto_crewprofile(applicant.crewId)" style="cursor: pointer;">{{ applicant.crewName }}</span>
            </td>

            <td class="text-center">
              <span>{{ applicant.rank }}</span>
            </td>

            <td class="text-center">
              <span>{{ applicant.dateApplied_str }}</span>
            </td>

            <td class="text-center">
              <span>{{ applicant.dateQualified_str }}</span>
            </td>

            <td class="text-center">
              <span>{{ applicant.dateAccepted_str }}</span>
            </td>

            <td class="text-left">

                <span class="small" v-if="applicant.endorseBy && !applicant.endorseByOthers">
                  <sup>*</sup>
                  {{ applicant.endorseBy }}
                </span>

              <span class="small" v-if="!applicant.endorseBy && applicant.endorseByOthers">
                  <sup>*</sup>
                  {{ applicant.endorseByOthers }}
                </span>
            </td>

            <td class="text-left">
              <span class="pl-1">{{ applicant.qualifiedBy }}</span>
            </td>

            <td class="text-left">
              <span class="pl-1">{{ applicant.acceptedBy }}</span>
            </td>

            <td class="text-left align-middle">
              <font-awesome-icon
                  class="mr-1"
                  :icon="applicantStatIcon(applicant.statusId)"
                  v-bind:class="applicantStatClass(applicant.applicantStatus)"/>
              <span>{{ applicant.status.toUpperCase() }}</span>
            </td>

            <td>
              <pmc-btn
                  @buttonEvent="go_to_details(applicant.applicantTrackingId)"
                  :button-class="'btn btn-xs pmc-btn-prime'"
                  :button-label="'details'">
                <template slot="button-icon">
                  <font-awesome-icon icon="bars"/>
                </template>
              </pmc-btn>

              <!--                <pmc-btn>-->
              <!--                  <template>-->
              <!--                  </template>-->
              <!--                </pmc-btn>-->

            </td>
          </tr>
        </template>
      </pmc-table>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {AppMixins} from '../../../mixins/app.mixins';
import {AlertMixins} from '../../../mixins/alert.mixins';

export default {
  name: 'ApplicantTracking',

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      formSearchParams: {
        searchValue: '',
        searchKey: 'all',
        year: new Date().getFullYear(),
        count: 10,
        aplStatus: null,
        isTrue: false,
      },
      searchKeys: [
        {
          id: 1,
          key: 'all',
          display: 'All',
        },
        {
          id: 2,
          key: 'first_name',
          display: 'First Name',
        },
        {
          id: 3,
          key: 'middle_name',
          display: 'Middle Name',
        },
        {
          id: 4,
          key: 'last_name',
          display: 'Last Name',
        },
        {
          id: 5,
          key: 'rank',
          display: 'Rank',
        },
      ],
      listCounts: [5, 10, 15, 20, 30, 40, 50, 100, 200],
      applicantStatuses: [
        {
          id: 1,
          name: 'applicant',
        },
        {
          id: 2,
          name: 'qualified',
        },
        {
          id: 3,
          name: 'reject',
        },
        {
          id: 4,
          name: 'accepted',
        },
      ],
    };
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_applicant',
      'set_applicants',
      'update_applicant',
      'delete_applicant',
      'null_applicant',
      'null_applicants',
    ]),

    search_crew() {
      this.set_applicants(this.formSearchParams);
    },

    reset_keys() {
      this.null_applicant();
      this.null_applicants();
    },

    set_this_page(e) {
      this.applicantPagination.currentPage = e;
      this.formSearchParams.page = e;
      this.search_crew();
    },

    set_list_count() {
      this.formSearchParams.page = 1;
      this.search_crew();
    },

    set_year() {
      this.formSearchParams.page = 1;
      this.search_crew();
    },

    set_search_crew() {
      this.formSearchParams.year = this.formSearchParams.searchValue ? '' : new Date().getFullYear();
      this.formSearchParams.page = this.formSearchParams.searchValue ? 1 : this.formSearchParams.page;
      this.search_crew();
    },

    on_applicant_status() {
      this.search_crew();
    },

    go_to_details(applicationId) {
      this.$router.push({
        name: 'ApplicantDetail',
        params: {id: applicationId},
      });
    },

    applicantStatClass(applicantStatus) {
      let bg = null;
      switch (applicantStatus) {
        case 1:
          bg = 'text-secondary';
          break;

        case 2:
          bg = 'text-warning';
          break;

        case 3:
          break;

        case 4:
          bg = 'text-success';
          break;

        default:
          bg = '';
          break;
      }
      return bg;
    },

    applicantStatIcon(statId) {
      let icon = null;
      if (statId === 4) {
        icon = 'check-circle';
      } else {
        icon = 'circle';
      }
      return icon;
    },

    //todo
    goto_crewprofile(thisCrewId) {
      this.$nextTick(() => {
        this.formSelectedCrewId = thisCrewId;
        let routeData = this.$router.resolve({ //
          name: 'CrewProfile',
          params: {
            crewId: thisCrewId,
          },
          query: {
            view: 'ccplan'
          }
        });
        window.open(routeData.href, '_blank', "height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));
      })
    },
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          'applicant',
          'applicants',
          'applicantPagination',
          'isReloading',
        ],
    ),
  },

  /** mounted **/
  mounted() {},

  /** created **/
  created() {
    this.search_crew();
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.reset_keys();
  },
};
</script>

<style scoped>
.this-append-key-selector {
  width: 32% !important;
  font: small-caption !important;
}

.on-hover {

}
</style>
