<template>
  <div class="container-fluid py-lg-3">
    <div class="row">
      <div class="col-lg-6 mr-auto text-left align-content-center">
        <div class="row pt-3">
          <label class="font-weight-bold ml-sm-4">
            <h4>Crew Withdrawal</h4>
          </label>
          <div class="col-8 font-weight-bold justify-content-center">
            <button class="btn btn-sm pmc-btn-prime"
                    data-toggle="modal"
                    data-target="#new-crew-withdrawal-modal"
                    data-backdrop="static"
                    data-keyboard="false">
              <font-awesome-icon icon="pencil-alt"/>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <pmc-table>
          <template v-slot:table-header>
            <tr>
              <th class="p-1 text-center align-middle" style="width: 10%!important;">Fill-up Date</th>
              <th class="p-1 text-center align-middle" style="width: 10%!important;">Effective Date</th>
              <th class="p-1 text-center align-middle" style="width: 5%!important;">NFR</th>
              <th class="p-1 text-center align-middle" style="width: 10%!important;">Lifted Date</th>
              <th class="p-1 text-center align-middle" style="width: 25%!important;">Reason</th>
              <th class="p-1 text-center align-middle" style="width: 5%!important;">File</th>
              <th class="p-1 text-center align-middle" style="width: 10%!important;">Attachments</th>
              <th class="p-1 text-center align-middle" style="width: 7%!important;">Action</th>
            </tr>
          </template>
          <template v-slot:table-body>
            <tr v-for="(withdrawal,index) in crewWithdrawals" :key="withdrawal.id">
              <td class="p-1">{{withdrawal.fillupDate | moment("DD - MMM - YYYY")}}</td>
              <td class="p-1">{{withdrawal.effectiveDate | moment("DD - MMM - YYYY")}}</td>
              <td class="p-1">
                <font-awesome-icon v-if="withdrawal.nfr" icon="check"  style="width: 25px!important; height: 25px!important;"/>
              </td>
              <td class="p-1">{{withdrawal.liftedDate | moment("DD - MMM - YYYY")}}</td>
              <td class="p-1 text-left pl-3">{{withdrawalReasonLang(withdrawal)}}</td>
              <td>
                <img v-if="withdrawal.hasFile"
                     class="btn-img"
                     title="Click to view withdrawal file"
                     type="button" :src="pdfIcon"
                     @click="pdfViewer(`Withdrawal File`, base64PdfParser(withdrawal.file))"
                     style="height: 25px !important;
                     width: 25px !important;">
              </td>
              <td class="text-center align-content-center">
                <div class="btn-group" v-if="withdrawal.otherAttachments">
                  <img class="btn-img ml-3" :id="`other-file-${index}`" :src="pdfIcon" v-for="(otherFile,index) in withdrawal.otherAttachments" :key="index"
                       :title="`Click to view withdrawal file other ${index}`"
                       @click="pdfViewer(`Withdrawal Attachment ${index+1}`, base64PdfParser(otherFile))"
                       style="height: 25px !important; width: 25px !important; cursor: pointer !important;"/>
                </div>
              </td>
              <td class="p-1">
                <button class="ml-2 btn btn-xs pmc-btn-prime"
                        data-toggle="modal"
                        data-target="#view-crew-withdrawal-modal"
                        data-backdrop="static"
                        title="View crew withdrawal details"
                        data-keyboard="false"
                        @click="setCrewWithdrawal(withdrawal)">
                  <font-awesome-icon icon="eye"/>
                  View
                </button>
                <button class="ml-2 btn btn-xs pmc-btn-danger"
                        title="Delete crew withdrawal details"
                        @click="deleteCrewWithdrawal(withdrawal)">
                  <font-awesome-icon icon="trash"/>
                  Delete
                </button>
              </td>
            </tr>
          </template>
        </pmc-table>
      </div>
    </div>

    <!-- new crew withdrawal modal-->
    <div class="modal fade" id="new-crew-withdrawal-modal" tabindex="-1" role="dialog" aria-labelledby="newCrewWithdrawalModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="newCrewWithdrawalModalTitle">New Withdrawal Record</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">
                <font-awesome-icon icon="times"/>
              </span>
            </button>
          </div>

          <div class="modal-body ">
            <div class="row">
              <div class="col-md-6">
                <form class="text-left align-content-center px-lg-2">
                  <div class="form-group row">
                    <label for="" class="col-md-4 col-form-label">
                      Fillup Date
                      <span class="text-danger">*</span>
                    </label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input type="date"
                             id="new-fill-up-date"
                             v-model="newCrewWithdrawal.fillupDate"
                             class="form-control form-control">
                    </div>
                  </div>

                  <div class="form-group row mt-3">
                    <label for="" class="col-md-4 col-form-label">
                      Effective Date
                      <span class="text-danger">*</span>
                    </label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input type="date"
                             id="new-effective-date"
                             v-model="newCrewWithdrawal.effectiveDate"
                             class="form-control form-control">
                    </div>
                  </div>

                  <div class="form-group row mt-3">
                    <label for="" class="col-md-4 col-form-label">
                      Lifted Date
                      <span class="text-danger">*</span>
                    </label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input type="date"
                             id="new-lifted-date"
                             v-model="newCrewWithdrawal.liftedDate"
                             class="form-control form-control">
                    </div>
                  </div>

                  <div class="form-group row mt-3">
                    <label for="" class="col-md-4 col-form-label">Salary Offered</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input type="number"
                             id="new-salary-offered"
                             v-model="newCrewWithdrawal.salaryOffered"
                             class="form-control form-control">
                    </div>
                  </div>

                  <div class="form-group row mt-3">
                    <label for="" class="col-md-4 col-form-label">Reason</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <select name="withdrawalReason"
                              id="new-withdrawal-reason"
                              v-model="newCrewWithdrawal.wrId"
                              class="form-control form-control">
                        <option value="">--SELECT--</option>
                        <option :value="objWithdrawalReason.id"
                                v-for="(objWithdrawalReason,index) in objWithdrawalReasonKeys"
                                :key="objWithdrawalReason.id">
                          {{ objWithdrawalReason.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-3">
                    <label class="col-form-label col-md-4">NFR</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input type="checkbox"
                             id="new-nfr"
                             class="ml-2"
                             v-model="newCrewWithdrawal.nfr"
                             style="vertical-align: middle !important; position: relative;top: 2px !important;">
                    </div>
                  </div>

                  <div class="form-group row mt-3 align-middle">
                    <label for="" class="col-md-4 col-form-label">Withdrawal File</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input type="file"
                             id="new-withdrawal-file"
                             accept="application/pdf"
                             class="form-control-file">
                    </div>
                  </div>

                  <div class="form-group row mt-3 align-middle">
                    <label for="" class="col-md-4 col-form-label">Attachments</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input type="file"
                             id="new-other-attachment"
                             class="form-control-file" multiple
                             accept="application/pdf">
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-md-6 border-left">
                <div class="form-group row text-left">
                  <label for="new-remarks" class="col-form-label col-sm-3">Remarks</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                   <textarea class="form-control form-control-sm form-text"
                             name="newRemarks"
                             id="new-remarks"
                             cols="30"
                             rows="3"
                             placeholder="Remarks here..."
                             style="resize: none;"
                             v-model="formRemarks.message"/>
                  </div>
                </div>


                <div class="form-group row text-left mt-2">
                  <label for="new-remarks-attachment" class="col-form-label col-sm-3">Attachment</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="file" disabled="disabled" class="form-control-file" id="new-remarks-attachment" @change="onChangeNewAttachmentRemarks($event)">
                  </div>
                </div>


                <div class="form-group row text-left mt-2">
                  <label class="col-form-label col-sm-3">Action</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <div class="row">
                      <div class="col ml-auto text-left">
                        <button disabled="disabled" class="btn btn-sm pmc-btn-prime" id="btn-new-add-remarks" @click="newAddRemarks">
                          <font-awesome-icon icon="plus" />
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <hr>

                <pmc-table :max-height="250">
                  <template v-slot:table-header>
                    <tr>
                      <th class="text-center align-middle" style="width: 65%;">
                        Remarks
                      </th>
                      <th class="text-center align-middle" style="width: 10%;">Attchment</th>
                      <th class="text-center align-middle" style="width: 25%;">Action</th>
                    </tr>
                  </template>
                  <template v-slot:table-body>
                    <tr v-for="(remarks,index) in newCrewWithdrawal.remarks" :key="index">
                      <td class="text-left align-middle" style="text-overflow:ellipsis;
                           display:block;">
                        <div class="card border-0">
                          <div class="card-body">
                            <textarea class="form-control form-control border-0"
                                      name="remarksMessage"
                                      :id="`remarks-message-${index}`"
                                      cols="30"
                                      style="resize: none;"
                                      rows="5"
                              v-model="remarks.message"></textarea>
<!--                            <hr>
                            <div class="row">
                              <div class="col-6 mr-auto" style="font-size: 0.8rem !important;">
                                <span>System admin</span>
                              </div>
                              <div class="col-6 ml-auto" style="font-size: 0.8rem !important;">
                                <span>Mar 01, 2023 - 12:30pm</span>
                              </div>
                            </div>-->
                          </div>
                        </div>
                      </td>
                      <td class="text-center align-middle">
                        <font-awesome-icon icon="file" v-if="remarks.file" class="fa-1x"/>
                        <span v-if="!remarks.file">N / A</span>
                      </td>
                      <td class="text-center align-middle">
                        <button class="btn btn-xs btn-light ml-2 pmc-btn-danger" @click="newDeleteRemarks(index)">
                          <font-awesome-icon icon="trash"/>
                          Delete
                        </button>
                      </td>
                    </tr>
                  </template>
                </pmc-table>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button id="btn-create-withdrawal" type="button" class="btn btn-sm btn-primary" @click="createCrewWithdrawal">Save</button>
            <button id="btn-cancel-withdrawal" type="button" class="btn btn-sm pmc-btn-danger" @click="cancelCreationWithdrawal">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <!-- view-crew-withdrawal-modal  -->
    <div class="modal fade" id="view-crew-withdrawal-modal" tabindex="-1" role="dialog" aria-labelledby="viewCrewWithdrawalModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewCrewWithdrawalModalTitle">View Withdrawal Record</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancelSetCrewWithdrawal">
              <span aria-hidden="true">
                <font-awesome-icon icon="times"/>
              </span>
            </button>
          </div>

          <div class="modal-body ">
            <div class="row">
              <div class="col-md-6">
                <form class="text-left align-content-center px-lg-2">
                  <div class="form-group row">
                    <label for="" class="col-md-4 col-form-label">Fillup Date</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input :disabled="!isEditWithdrawalDetails" v-model="crewWithdrawal.fillupDate" type="date" id="crew-fill-up-date" class="form-control form-control">
                    </div>
                  </div>

                  <div class="form-group row mt-3">
                    <label for="" class="col-md-4 col-form-label">Effective Date</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input :disabled="!isEditWithdrawalDetails" v-model="crewWithdrawal.effectiveDate" type="date" id="crew-effective-date" class="form-control form-control">
                    </div>
                  </div>

                  <div class="form-group row mt-3">
                    <label for="" class="col-md-4 col-form-label">Lifted Date</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input :disabled="!isEditWithdrawalDetails" v-model="crewWithdrawal.liftedDate" type="date" id="crew-lifted-date" class="form-control form-control">
                    </div>
                  </div>

                  <div class="form-group row mt-3">
                    <label for="" class="col-md-4 col-form-label">Salary Offered</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input :disabled="!isEditWithdrawalDetails" v-model="crewWithdrawal.salaryOffered" type="number" id="crew-salary-offered" class="form-control form-control">
                    </div>
                  </div>

                  <div class="form-group row mt-3">
                    <label for="" class="col-md-4 col-form-label">Reason</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <select :disabled="!isEditWithdrawalDetails" v-model="crewWithdrawal.wrId"  name="withdrawalReason" id="crew-withdrawal-reason" class="form-control form-control">
                        <option value="">--SELECT--</option>
                        <option :value="objWithdrawalReason.id"
                                v-for="(objWithdrawalReason,index) in objWithdrawalReasonKeys"
                                :key="objWithdrawalReason.id">
                          {{ objWithdrawalReason.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-3">
                    <label class="col-form-label col-md-4">NFR</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input type="checkbox"
                             :disabled="!isEditWithdrawalDetails"
                             v-model="crewWithdrawal.nfr"
                             id="crew-salary-offered"
                             class="ml-2"
                             style="vertical-align: middle !important; position: relative;top: 2px !important;">
                    </div>
                  </div>

                  <div class="form-group row text-left align-middle mt-3">
                    <label class="col-form-label col-md-4">Action</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <div class="btn-group">
                        <button v-if="!isEditWithdrawalDetails"class="btn btn-sm pmc-btn-warning" type="button" @click="setUpdateCrewWithdrawalDetails">
                          Update
                        </button>
                        <button v-if="isEditWithdrawalDetails" class="btn btn-sm pmc-btn-danger" type="button" @click="cancelUpdateCrewWithdrawalDetails">
                          Cancel
                        </button>
                        <button v-if="isEditWithdrawalDetails" class="btn btn-sm pmc-btn-tertia ml-2" type="button" @click="updateCrewWithdrawalDetails">
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="form-group row mt-3 align-middle">
                    <label for="" class="col-md-4 col-form-label">Withdrawal File</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                        <input type="checkbox"  id="checkbox-replace-withdrawal-file" @change.prevent="onCheckboxReplaceWithdrawalFile($event)"  class="ml-4"/>
                        <label for="checkbox-replace-withdrawal-file" class="ml-3">
                          {{crewWithdrawal.hasFile ? "Replace" : "Upload"}}
                        </label>
                        <img v-if="!isReplaceWithdrawalFile && crewWithdrawal.hasFile"
                           class="btn-img ml-4 mb-2"
                           title="Click to view withdrawal file on separate tab"
                           type="button" :src="pdfIcon"
                           @click="pdfViewer(`Withdrawal File`, base64PdfParser(crewWithdrawal.file))"
                           style="height: 25px !important;
                               width: 25px !important;">
                       <input type="file" v-if="isReplaceWithdrawalFile" id="crew-withdrawal-file" class="form-control-file" @change="onCrewWithdrawalFile($event)">
                    </div>
                  </div>

                  <div class="form-group row mt-3 align-middle mt-2">
                    <label for="" class="col-md-4 col-form-label">Attachments</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-7">
                      <input type="checkbox"  id="checkbox-add-attachment-file" @change.prevent="onCheckboxAddAttachmentFile($event)"  class="ml-4"/>
                      <label for="checkbox-add-attachment-file" class="mt-1 ml-3">Add</label>
                      <input multiple
                             type="file"
                             v-if="isAddAttachmentFile"
                             id="crew-attachment-file"
                             class="form-control-file"
                             @change="onAddAttachmentFile($event)">
                    </div>
                  </div>

                  <pmc-table :max-height="200" class="mt-2">
                    <template v-slot:table-header>
                      <tr>
                        <th class="p-1 text-center align-middle" style="width: 10%;">#</th>
                        <th class="p-1 text-center align-middle" style="width: 90%;">File</th>
                      </tr>
                    </template>
                    <template v-slot:table-body>
                      <tr v-for="(attachment,index) in crewWithdrawal.otherAttachments" :key="index">
                        <td>{{index+1}}</td>
                        <td>
                          <img class="btn-img"
                               v-if="attachment"
                               :src="pdfIcon"
                               @click="pdfViewer(`Other Attachment ${index+1}`, base64PdfParser(attachment))"
                               style="height: 25px !important; width: 25px !important; cursor: pointer !important;">
                        </td>
                      </tr>
                    </template>
                  </pmc-table>
                </form>
              </div>
              <div class="col-md-6 border-left">
                <div class="mt-2 text-left align-middle px-lg-2">
                  <div class="form-group row text-left align-content-lg-end">
                    <label for="new-remarks" class="col-form-label col-sm-3">Remarks</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-8">
                   <textarea class="form-control form-control-sm form-text"
                             name="newRemarks"
                             id="remarks"
                             cols="30"
                             rows="3"
                             placeholder="Remarks here..."
                             v-model="formViewRemarks.message"
                             style="resize: none;"/>
                    </div>
                  </div>

                  <div class="form-group row text-left mt-2">
                    <label for="new-remarks-attachment" class="col-form-label col-sm-3">Attachment</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <input type="file" disabled="disabled" class="form-control-file" id="remarks-attachment" @change="onChangeAttachmentRemarks($event)">
                    </div>
                  </div>

                  <div class="form-group row text-left mt-2">
                    <label class="col-form-label col-sm-3">Action</label>
                    <span>:</span>
                    <div class="col-sm-8">
                      <div class="row">
                        <div class="col ml-auto text-left">
                          <button disabled="disabled" class="btn btn-sm pmc-btn-prime" id="btn-add-remarks" @click="addRemark">
                            <font-awesome-icon icon="plus" />
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr>
                  <pmc-table :max-height="400" class="mt-2">
                    <template v-slot:table-header>
                      <tr>
                        <th style="width: 65%!important;">Remarks</th>
                        <th style="width: 10%!important;">File</th>
                        <th style="width: 25%!important;">Action</th>
                      </tr>
                    </template>
                    <template v-slot:table-body>
                      <tr v-for="(remark,index) in crewWithdrawal.remarks" :key="index">
                        <td>
                          <div class="card border-0">
                            <div class="card-body">
                              <div class="row text-left">
                                <div class="col-12 mr-auto">
                                  <p style="font-size: 0.8rem !important; padding-bottom: 0px !important;">{{remark.createdBy.toUpperCase()}}</p>
                                  <p style="font-size: 0.7rem !important; padding-bottom: 0px !important;">{{remark.createdAt.toUpperCase()}}</p>
                                </div>
                              </div>
                              <hr>
                              <textarea class="form-control form-control border-0"
                                      readonly
                                      name="remarksMessage"
                                      :id="`view-remarks-message-${remark.id}`"
                                      cols="30"
                                      style="resize: none;"
                                      rows="2"
                                      v-model="remark.message.toUpperCase()"></textarea>
                            </div>
                          </div>
                        </td>
                        <td class="text-center align-middl align-content-center">
                          <img v-if="remark.file"
                               class="btn-img"
                               title="Click to view remark attachment file"
                               type="button" :src="pdfIcon"
                               @click="pdfViewer(`Remark Attachment File`, base64PdfParser(remark.file))"
                               style="height: 25px !important;
                     width: 25px !important;">
                        </td>
                        <td class="text-center align-middle align-content-center">
                          <div class="btn btn-group-vertical" v-if="authUser.id === remark.createdById">
                            <button :id="`btn-remarks-attachment-edit-${remark.id}`"
                                    type="button"
                                    class="btn btn-xs pmc-btn-warning"
                                    @click="setEditRemarks(remark)">
                              Edit
                            </button>

                            <button :id="`btn-remarks-attachment-update-${remark.id}`"
                                    hidden="hidden"
                                    type="button"
                                    @click="updateRemarks(remark)"
                                    class="btn btn-xs pmc-btn-tertia mt-2">
                              Update
                            </button>

                            <button :id="`btn-remarks-attachment-cancel-${remark.id}`"
                                    hidden="hidden"
                                    type="button"
                                    @click="setCancelRemarks(remark)"
                                    class="btn btn-xs btn-secondary mt-2">
                              Cancel
                            </button>


                            <button :id="`btn-remarks-attachment-delete-${remark.id}`"
                                    type="button"
                                    @click="deleteRemarks(remark)"
                                    class="btn btn-xs pmc-btn-danger mt-2">
                              Delete
                            </button>

                            <button :id="`btn-remarks-attachment-upload-${remark.id}`"
                                    type="button"
                                    :class="[ remark.file ? 'pmc-btn-second' : 'pmc-btn-prime']"
                                    class="btn btn-xs mt-2"
                                    @click="uploadRemarksAttachFile(remark)">
                              {{ remark.file ? "Replace file" : "Upload file" }}
                            </button>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </pmc-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal replace new attachment -->
    <div class="modal fade" v-if="formSelectedRemark != null"
         :id="`replace-attach-file-modal-${formSelectedRemark.id}`"
         tabindex="-1"
         role="dialog"
         aria-labelledby="replace-attach-file-label" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="replace-attach-file-modal-title">{{this.formSelectedRemark.file ? "Replace" : "Upload"}} File</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <font-awesome-icon icon="times" />
                </span>
            </button>
          </div>
          <div class="modal-body">
            <input type="file" :id="`upload-attach-file-${formSelectedRemark.id}`" @change="onChangeUploadRemarksAttachFile($event)">
          </div>
          <div class="modal-footer">
              <!--<button type="button" class="btn btn-primary">Save changes</button>-->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";

import PdfIcon from "@/assets/viewpdf.png";
import {mapActions, mapGetters} from "vuex";

import __ from "lodash"

export default {
  name: "CrewWithdrawal",
  mixins: [AlertMixins, AppMixins],
  /** data **/
  data() {
    return {
      pdfIcon: PdfIcon,
      crewId: parseInt(this.$route.params.crewId, 10),

      isEditWithdrawalDetails : false,
      isReplaceWithdrawalFile : false,
      isAddAttachmentFile : false,

      isEditRemarks : false,
      formSelectedRemark : null,
      formViewRemarks : {}, //sub var container for existing remarks,
      formAttachFile : null,
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        "crewWithdrawals",
        "crewWithdrawal",
        "newCrewWithdrawal",
        "objWithdrawalReasonKeys",
        "isWithDrawalReason",
        "formRemarks",
        "authUser",
      ]
    )
  },


  /** methods**/
  methods: {
    ...mapActions(
      [
        "set_crew_withdrawals",
        "create_crew_withdrawal",
        "update_crew_withdrawal",
        "delete_crew_withdrawal",
        "reset_state_crew_withdrawal",

        "add_remark",
        "update_remark",
        "delete_remark",


        "upload_remarks_attach_file",

        "update_withdrawal_file",
        "upload_additional_attachment_file",
      ]
    ),
    setCrewWithdrawals() {
      this.set_crew_withdrawals({crewId: this.crewId}).then((r) => {
        if (r) {
        }
      })
    },
    setCrewWithdrawal(withdrawal) {
      this.$store.commit('set_crew_withdrawal', withdrawal);
    },
    cancelSetCrewWithdrawal() {
      localStorage.removeItem('withdrawalDetails')
      this.isEditWithdrawalDetails = false;
    },
    async createCrewWithdrawal() {
      if((this.newCrewWithdrawal.fillupDate || this.newCrewWithdrawal.effectiveDate || this.newCrewWithdrawal.effectiveDate) === ""){
        this.warningAlert("Please provide details for the required fields (*)")
      }else {
        let msg = `Do you want to save this record?`
        if (await this.questionAlert(`${msg}`, "Create new record")) {
          let formData = new FormData();
          let newWithdrawalFileInput = document.getElementById(`new-withdrawal-file`);
          let newOtherFileInput = document.getElementById(`new-other-attachment`);

          formData.append('crewId', this.crewId)

          Object.keys(this.newCrewWithdrawal).forEach((key, index) => {
            if (key !== "remarks") {
              formData.append(`${key}`, this.newCrewWithdrawal[key])
            }
          })

          //crewWithdrawalFile
          if (newWithdrawalFileInput.files[0] !== undefined) {
            formData.append('crewWithdrawalFile', newWithdrawalFileInput.files[0]);
          }

          //remarks
          if (this.newCrewWithdrawal.remarks.length !== 0 || this.newCrewWithdrawal.remarks !== undefined) {
            this.newCrewWithdrawal.remarks.forEach((val, index) => {
              if (val.file !== undefined) {
                formData.append(`remarksAttachments[${index}]`, this.newCrewWithdrawal.remarks[index].file)
              }

              if (val.message !== undefined) {
                formData.append(`remarks[]`, JSON.stringify(this.newCrewWithdrawal.remarks[index].message))
              }
            })
          }

          //crewWithdrawalFiles
          for (let x = 0; x < newOtherFileInput.files.length; x++) {
            formData.append("crewWithdrawalFiles[]", newOtherFileInput.files[x], `${x}.pdf`)
          }

          this.create_crew_withdrawal(formData).then((r) => {
            if (r) {
              this.$nextTick(() => {
                this.successAlert("Withdrawal record successfully save");
              })
            }
          })
        }
      }
    },
    cancelCreationWithdrawal() {
      $(`#new-crew-withdrawal-modal`).modal('hide')
      this.resetFormRemarksData();
    },
    setUpdateCrewWithdrawalDetails() {
      this.isEditWithdrawalDetails = true;
      const details = {
        fillupDate: this.crewWithdrawal.fillupDate,
        effectiveDate: this.crewWithdrawal.effectiveDate,
        liftedDate: this.crewWithdrawal.liftedDate,
        salaryOffered: this.crewWithdrawal.salaryOffered,
        wrId: this.crewWithdrawal.wrId,
        nfr: this.crewWithdrawal.nfr ? 1 : 0,
      }
      localStorage.setItem('withdrawalDetails', JSON.stringify(details))
    },
    cancelUpdateCrewWithdrawalDetails() {
      this.isEditWithdrawalDetails = false;
      this.$store.commit("reset_with_drawal_details", JSON.parse(localStorage.getItem('withdrawalDetails')));
    },
    async updateCrewWithdrawalDetails() {
      const formData = {
        id : this.crewWithdrawal.id,
        fillupDate : this.crewWithdrawal.fillupDate,
        effectiveDate : this.crewWithdrawal.effectiveDate,
        liftedDate : this.crewWithdrawal.liftedDate,
        salaryOffered : this.crewWithdrawal.salaryOffered,
        crewId : this.crewWithdrawal.crewId,
        wrId : this.crewWithdrawal.wrId,
        nfr : this.crewWithdrawal.nfr,
      }

      if (await this.questionAlert("Do you want to save the changes that you made", "Update Withdrawal Details")) {
        this.update_crew_withdrawal(formData).then((r) => {
          this.$nextTick(() => {
            this.successAlert("Update details successfully", "Withdrawal Details")
            this.isEditWithdrawalDetails = false;
          })
        })
      }
    },
    onCheckboxReplaceWithdrawalFile(e) {
      if (e.target.checked) {
        this.isReplaceWithdrawalFile = true;
      } else {
        this.isReplaceWithdrawalFile = false;
      }
    },
    async onCrewWithdrawalFile(e) {
      let msg = "Do you want to replace the existing file with selected file?"
      if (await this.questionAlert(msg, "Replace File")) {
        let formData = new FormData();

        if(e.target.files[0] !== undefined){
          formData.append('crewWithdrawalFile',e.target.files[0])
          formData.append('cwId',this.crewWithdrawal.id)
          //TODO vuex end point for updating ,crew withdrawal files
          this.update_withdrawal_file(formData).then((r)=>{
            if(r){
              this.$nextTick(()=>{
                this.isReplaceWithdrawalFile = false;
                this.successAlert("Upload Successfully")
                $(`#checkbox-replace-withdrawal-file`).prop("checked",false)
              })
            }
          })
        }
      } else {
        /* crew-withdrawal-file */
        $(`#crew-withdrawal-file`).val("");
      }
    },


    onCheckboxAddAttachmentFile(e) {
      if (e.target.checked) {
        this.isAddAttachmentFile = true;
      } else {
        this.isAddAttachmentFile = false;
      }
    },
    async onAddAttachmentFile(e) {
      let msg = "Do you want to add withdrawal attachments.?"
      if (await this.questionAlert(msg, "Add Attachment Files")) {
        let formData  = new FormData();
        if(e.target.files[0] !== undefined){
          //crewWithdrawalFiles
          for (let x = 0; x < e.target.files.length; x++) {
            formData.append("crewWithdrawalFiles[]", e.target.files[x], `${x}.pdf`)
          }
          formData.append('cwId',this.crewWithdrawal.id)
          this.upload_additional_attachment_file(formData).then((r)=>{
            if(r){
              this.$nextTick(()=>{
                this.successAlert("Upload Successfully")
                this.isAddAttachmentFile = false;
                $(`#checkbox-add-attachment-file`).prop("checked",false);
              })
            }
          })
        }
      } else {
        $(`#crew-attachment-file`).val("")
      }
    },

    async deleteCrewWithdrawal({id}) {
      if(await this.questionAlert("Are you sure you want to delete this withdrawal record","Deleting Withdrawal Record")){
        this.delete_crew_withdrawal(id).then((r) => {
          if(r){
            this.$nextTick(() => {
              this.successAlert("Withdrawal record deleted successfully","Delete Withdrawal")
            })
          }
        });
      }
    },

    onChangeNewAttachmentRemarks(e) {
      const file = e.target.files[0];

      //TODO ALERT IF MAX IN 2mb
      if(this.gte2mb(file)){
        this.warningAlert("Sorry! Maximum allowed size for remarks attachment  is 2mb")
        $(`#new-remarks-attachment`).val("");
      }else {
        this.formRemarks.file =  file;
      }
    },

    withdrawalReasonLang({wrId}){
      let objWithdrawal = this.objWithdrawalReasonKeys.find((val,index)=>{
        return val.id === wrId;
      })
       return objWithdrawal ? objWithdrawal.name : null;
    },

    resetFormRemarksData(){
      this.$store.commit('set_remarks',{})
      this.$store.commit('reset_new_form_remarks_data')
      $(`#btn-new-add-remarks`).attr('disabled',true)
      $(`#new-remarks-attachment`).attr('disabled',true)
      $(`#new-remarks-attachment`).val("")
    },

    //start for new withrawal  remarks records
    newAddRemarks(){
      if(__.isEmpty(this.formRemarks)){
        this.warningAlert("Please provide a valid remarks.")
      }

      this.formRemarks.createdBy = this.authUser.name;
      this.formRemarks.createdById = this.authUser.id;
      this.formRemarks.isNew = true;
      this.$store.commit("add_remark",this.formRemarks)
      this.$nextTick(()=>{
        this.$store.commit('set_remarks',{})
      })
    },

    newDeleteRemarks(e){
      let index = this.newCrewWithdrawal.remarks.findIndex((val,thisIndex)=>{
        return thisIndex === e
      })
      this.newCrewWithdrawal.remarks.splice(index,1)
    },
    //end for new withdrawal remarks records.

    onChangeAttachmentRemarks(e){
      this.formViewRemarks.file = e.target.files[0];
    },

    async addRemark() {
      let msg ="Do you want to add this remarks.?"
      if(await this.questionAlert(msg,"Add remarks")){
        let formData = new FormData();
        if(this.formViewRemarks.file){
          formData.append("file",this.formViewRemarks.file);
        }
        formData.append("message",this.formViewRemarks.message);
        formData.append("crewWithdrawalId",this.crewWithdrawal.id)
        this.add_remark(formData).then((r)=>{
          if(r){
            this.$nextTick(()=>{
              this.successAlert("Successfully added")
              this.formViewRemarks = {};
            })
          }
        })
      }
    },

    setEditRemarks(objRem){
      // set the button not hidden.
      $(`#btn-remarks-attachment-edit-${objRem.id}`).attr("hidden",true)
      $(`#btn-remarks-attachment-update-${objRem.id}`).attr("hidden",false)
      $(`#btn-remarks-attachment-cancel-${objRem.id}`).attr("hidden",false)
      $(`#btn-remarks-attachment-delete-${objRem.id}`).attr("hidden",true)
      $(`#btn-remarks-attachment-upload-${objRem.id}`).attr("hidden",false)

      //input text area
      $(`#view-remarks-message-${objRem.id}`).attr("readonly",false)
      localStorage.setItem('selectedRemark',JSON.stringify(objRem.message))
    },

    setCancelRemarks(objRem){
      let remarks = this.crewWithdrawal.remarks.find((val,index)=>{
        return val.id === objRem.id;
      });

      this.defaultStateOfButtonRemarks(objRem);

      remarks.message = JSON.parse(localStorage.getItem('selectedRemark'));
      localStorage.removeItem('selectedRemark')
    },


    defaultStateOfButtonRemarks(objRem){
      $(`#btn-remarks-attachment-edit-${objRem.id}`).attr("hidden",false)
      $(`#btn-remarks-attachment-update-${objRem.id}`).attr("hidden",true)
      $(`#btn-remarks-attachment-cancel-${objRem.id}`).attr("hidden",true)
      $(`#btn-remarks-attachment-delete-${objRem.id}`).attr("hidden",false)
      $(`#btn-remarks-attachment-upload-${objRem.id}`).attr("hidden",false)

      $(`#view-remarks-message-${objRem.id}`).attr("readonly",true)
    },

    async updateRemarks(remarks) {
      if(await this.questionAlert("Do you want to update remarks","Update Remarks")){
        this.update_remark(remarks).then((r)=>{
         if(r){
           this.$nextTick(()=>{
             this.successAlert("Successfully updated","Update Remark")
             this.defaultStateOfButtonRemarks(remarks);
             localStorage.removeItem('selectedRemark')
           })
         }
        })
      }
    },

    async deleteRemarks(remark) {
      let msg = 'Deleting this remarks will permanently remove on the record.?'
      if(await this.questionAlert(msg,"Delete Remarks")){
        this.delete_remark(remark).then((r)=>{
          if(r){
            this.successAlert("Deleted successfully")
          }
        })
      }
    },

    // uploadRemarksAttachFile
    uploadRemarksAttachFile(remark){
      this.formSelectedRemark = remark;
      this.$nextTick(()=>{
        $(`#replace-attach-file-modal-${remark.id}`).modal({
          backdrop: 'static',
          keyboard: false,
          show : true
        })
      })
    },

    async onChangeUploadRemarksAttachFile(e){
      let title = '';
      title = this.formSelectedRemark.file !== null ? 'replace' : 'upload'
      if(e.target.files[0] !== undefined){
        if(await this.questionAlert(`Do you want to ${title} selected file to attach in this remarks`,`${title.toUpperCase()} FILE`)){
          let formData = new FormData();
          formData.append('file',e.target.files[0])
          formData.append('cwrId',this.formSelectedRemark.id)

          this.upload_remarks_attach_file(formData).then((r)=>{
            if(r){
              this.$nextTick(()=>{
                this.successAlert(`Successfully ${title}`)
                $(`#replace-attach-file-modal-${this.formSelectedRemark.id}`).modal("hide");
                $(`#upload-attach-file-${this.formSelectedRemark.id}`).val("");
              })
            }
          })
        }else {
          $(`#upload-attach-file-${this.formSelectedRemark.id}`).val("");
        }
      }
    },
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  created() {
    this.setCrewWithdrawals();
  },

  /** watch **/
  watch: {
    formRemarks : {
      immediate : true,
      deep : true,
      handler(newVal,oldVal){
        if(newVal.message === '' || newVal.message === undefined){
          $(`#btn-new-add-remarks`).attr('disabled',true)
          $(`#new-remarks-attachment`).attr('disabled',true)
          $(`#new-remarks-attachment`).val("")
          //view add remarks

        }else {
          $(`#new-remarks-attachment`).attr('disabled',false)
          $(`#btn-new-add-remarks`).attr('disabled',false)
          //view add remarks

        }
      }
    },

    formViewRemarks : {
      immediate : true,
      deep : true,
      handler(newVal,oldVal){
        if(newVal.message === "" || newVal.message === undefined){
          $(`#remarks-attachment`).attr("disabled",true)
          $(`#btn-add-remarks`).attr("disabled",true)
          $(`#remarks-attachment`).val("");

        }else {
          $(`#remarks-attachment`).attr("disabled",false)
          $(`#btn-add-remarks`).attr("disabled",false)
        }
      }
    },
  },

  /** destroyed **/
  destroyed() {
    this.reset_state_crew_withdrawal();
    this.formSelectedRemark = null;
  },
}
</script>

<style scoped>

</style>
