import axios from 'axios'
import { UtilityService } from '../../../service/utility.service'

import { PMC } from '../../../config/constants.config'
import { AlertService } from '../../../service/alert.service'

/** state **/
const state = {
  school: {},
  schools: [],
  newSchool: {
    code: '',
    name: '',
    address: null,
    status: 0, //false default
  },

  schoolFormParam: {
    searchValue: null,
    count: 10,
    status: 1, //default true
    page: null,
  },

  schoolPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
}

/** mutations **/
const mutations = {
  //index
  set_schools (state, params) {
    state.schools = params
  },

  //view
  set_school (state, param) {
    state.school = param
  },

  create_school (state) {
    state.newSchool = {
      code: '',
      name: '',
      address: null,
      status: 0, //false default
    }
  },

  //update
  update_school (state, params) {
    let school = state.schools.find((val, index) => val.id === params.id)
    Object.assign(school, params)
  },

  //delete
  delete_school (state, params) {
    let index = state.schools.findIndex((val, index) => val.id === params);
    state.schools.splice(index, 1)
  },

  set_null_school (state) {
    state.school = {}
  },

  set_null_schools (state) {
    state.schools = []
  },

  set_null_new_school (state) {
    state.newSchool = {
      code: null,
      name: null,
      address: null,
      status: false,
    }
  },

  //set_school_params(state,params){},

  null_school_form_params (state) {
    state.schoolFormParam = {
      searchValue: null,
      count: 10,
      status: 1,
      page: null,
    }
  },

  set_school_pagination (state, params) {
    state.schoolPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },

  reset_school_pagination (state) {
    state.schoolPagination = {
      from: 1,
      currentPage: 1,
      lastPage: 0,
      perPage: 10,
      to: 10,
      total: 0,
    }
  },
}

/** actions **/
const actions = {
  set_school_paginated ({ commit }, params) {
    commit('set_is_loading',true)
    return axios.get(PMC.SCHOOL, { params }).then((r) => {
      commit('set_schools', r.data.data)
      commit('set_school_pagination', r.data)
      commit('set_is_loading',false)
      return true

    }).catch((er) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(er, 'Get school paginated')
      return false

    })
  },

 async set_school ({ commit }, params) {
    return axios.get(`${PMC.SCHOOL}/${params}`).then((r)=>{
      commit('set_school',r.data);
      return true;
    }).catch((er)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(er,'School details');
      return false;
    });
  },

  create_school ({ commit }, params) {
    return axios.post(PMC.SCHOOL, UtilityService.deCapitalizeProperties(params))
      .then((response) => {
        AlertService.successAlertService('Successfully add', 'New school')
        commit('create_school')
        return true
      })
      .catch((er) => {
        UtilityService.failProgressBar()
        UtilityService.generateResponseMessage(er, 'Create School')
        return false
      })
  },

  //todo update
  update_school ({ commit }, params) {
    return axios.put(`${PMC.SCHOOL}/${params.id}`, UtilityService.deCapitalizeProperties(params)).then((r) => {
      AlertService.successAlertService('Update successfully', 'Update school')
      commit('update_school', r.data)
      return true

    }).catch((er) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(er, 'Update school')
      return false

    })
  },

 async delete_school ({ commit }, params) {
    let __del = AlertService.questionAlertService(
      'Do you want to delete this record', 'Delete school', 'question')
    if (await __del) {
      return axios.delete(`${PMC.SCHOOL}/${params}`).then((r)=>{
        AlertService.successAlertService("Delete school record successfuly",'Delete school');
        commit('delete_school',r.data.id);
        return true;
      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete school");
        return false;

      })
    }
  },

  set_null_school ({ commit }) {
    commit('set_null_school')
  },

  set_null_schools ({ commit }) {
    commit('set_null_schools')
  },

  set_null_new_school ({ commit }) {
    commit('set_null_new_school')
  },

  set_school_pagination ({ commit }, params) {
    commit('set_school_pagination', params)
  },

  reset_school_pagination ({ commit }) {
    commit('reset_school_pagination')
  },
}

/** getters **/
const getters = {
  schools: state => UtilityService.capitalizeProperties(state.schools),
  school: state => UtilityService.capitalizeProperties(state.school),
  newSchool: state => UtilityService.capitalizeProperties(state.newSchool),

  schoolPagination: state => state.schoolPagination,
  schoolFormParam: state => state.schoolFormParam,
}

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
}
