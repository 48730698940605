import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  union: {},
  unions: [],
  newUnion: {},

  unionFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  unionPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_unions(state, params) {
    state.unions = params
  },

  create_union (state) {
    state.union = {};
  },

  update_union (state, params) {
    let flag = state.unions.find((val, index) => val.id === params.id)
    Object.assign(flag, params)
  },

  delete_union (state, params) {
    let index = state.unions.findIndex((val, index) => val.id === params)
    state.unions.splice(index, 1)
  },

  null_unions(state) {
    state.unions = []
  },

  null_union(state) {
    state.union = {}
  },

  set_union_pagination(state, params) {
    state.unionPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {

  async set_unions({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.UNION, {params}).then((r) => {
      commit('set_unions', r.data.data)
      commit('set_union_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get union')
      return false
    })
  },

  async create_union({commit}, params) {
    return axios.post(PMC.UNION, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_union', r.data);
      return true;

    }).catch((er) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(er, 'Create union');
      return false;
    })
  },

  async update_union({commit}, params) {
    return axios.put(`${PMC.UNION}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_union',r.data.updatedUnion);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update union');
      return false;

    });
  },

  async delete_union({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete document category','question');
    if(await _del){
      return axios.delete(`${PMC.UNION}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete document category');
        commit('delete_union',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete union");
        return false;

      })
    }
  },

  reset_union_pagination({commit}) {
    commit('set_union_pagination')
  },

  null_unions({commit}) {
    commit('null_unions')
  },

  null_union({commit}) {
    commit('null_union')
  },
};


const getters = {
  unions: state => UtilityService.capitalizeProperties(state.unions),

  unionPagination: state => state.unionPagination,
  unionFormParam: state => state.unionFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
