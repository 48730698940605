import {library} from '@fortawesome/fontawesome-svg-core';

import {
  faCoffee,
  faChild,
  faCircle,
  faArchive,
  faComment,
  faSignInAlt,
  faEyeSlash,
  faEye,
  faStepBackward,
  faStepForward,
  faBars,
  faUserFriends,
  faBookmark,
  faCalendarDay,
  faCalendarAlt,
  faHome,
  faAngleDown,
  faCogs,
  faChartArea,
  faEdit,
  faFileDownload,
  faFileUpload,
  faFile,
  faUndo,
  faPrint,
  faBook,
  faTimes,
  faSignOutAlt,
  faArrowsAltH,
  faWindowClose,
  faSave,
  faBan,
  faSearch,
  faPlus,
  faCheck,
  faHotel,
  faExchangeAlt,
  faTrash,
  faArrowRight,
  faExclamation,
  faUserPlus,
  faPaperclip,
  faComments,
  faCommentDots,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faTachometerAlt,
  faAsterisk,
  faMailBulk,
  faBell,
  faEnvelope,
  faUserTie,
  faSpinner,
  faFilter,
  faUpload,
  faUserTimes,
  faPlusCircle,
  faChevronCircleUp,
  faSync,
  faCheckCircle,
  faKey,
  faUserLock,
  faSyringe,
  faCircleNotch,
  faLocationArrow,
  faShip,
  faSquare,
  faThumbsUp,
  faThumbsDown,
  faEraser,
  faArrowUp,
  faArrowDown,
  faMinusCircle,
  faTimesCircle,
  faCog,
  faBackward,
  faArrowLeft,
  faWrench,
  faFolder,
  faUser,
  faChevronRight,
  faAnchor,
  faChevronDown,
  faArrowCircleUp,
  faLongArrowAltUp,
  faMinus, faPencilAlt,
  faMarker,
  faDatabase,
  faCheckDouble,
  faWindowMaximize
} from '@fortawesome/free-solid-svg-icons';

export const Icons = {
  config() {
    library.add(
        faCoffee, faChild, faCircle, faArchive, faComment, faSignInAlt, faEyeSlash, faEye,
        faStepBackward,
        faStepForward,
        faBars, faUserFriends, faBookmark, faCalendarDay, faCalendarAlt, faHome, faAngleDown,
        faCogs, faChartArea,
        faEdit, faBookmark, faFileDownload, faFileUpload, faFile, faUndo, faPrint, faBook, faTimes,
        faSignOutAlt,
        faArrowsAltH, faWindowClose, faArrowsAltH, faSave, faBan, faSearch, faPlus, faCheck,
        faHotel, faExchangeAlt,
        faTrash,
        faArrowRight, faExclamation, faComments, faCommentDots, faCaretDown, faCaretUp, faUserPlus,
        faPaperclip,
        faTachometerAlt, faAsterisk, faMailBulk, faBell, faEnvelope, faUserTie, faSpinner, faFilter,
        faUpload, faUserTimes, faPlusCircle, faChevronCircleUp, faSync, faCheckCircle, faKey,
        faUserLock,
        faSyringe, faCircleNotch, faLocationArrow,faShip,faSquare,faThumbsUp,faThumbsDown,faEraser,
       faArrowUp,faArrowDown,faMinusCircle,faTimesCircle, faCog, faBackward, faArrowLeft,faArrowCircleUp, faCaretRight,
      faWrench, faFolder,faUser, faChevronRight, faAnchor, faChevronDown,faLongArrowAltUp, faMinus,faPencilAlt,faMarker,
      faDatabase,faCheckDouble, faWindowMaximize
    );
  },
};

export default {Icons};
