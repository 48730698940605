<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Summary of Crew with Highest GRT</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.rankId"
                                @change="selectReportType"
                                required>
                          <option :value="null">All</option>
                          <option v-for="(rank, index) in objRanks" :value="rank.id">{{ rank.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="getHighestGRT">
                    <font-awesome-icon icon="file"/>

                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger" @click="printReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
            <div class="row ml-2">
              <div class="col-sm-3">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label">FILTER BY : <b class="pl-2"
                                                                                    style="font-size: 1.1em;">{{
                          rankSelected
                        }}</b></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;">Seafarer Name</th>
                    <th>Rank</th>
                    <th>Sea Service Status</th>
                    <th>Crew Type</th>
                    <th>Vessel</th>
                    <th>GRT</th>
                    <th>SIRB</th>
                    <th>SIRB Validity</th>
                    <th>Passport</th>
                    <th>Passport Validity</th>
                    <th>COC Expiry</th>
                    <th>Reg.</th>
                    <th>US Visa Expiry</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td class="text-center align-middle"> {{ index + 1 }}</td>
                          <td class="text-center align-middle">

                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>

                          </td>
                          <td class="text-center align-middle"> {{ crew.rank_alias }}</td>
                          <td class="text-center align-middle" style="width: 10%;"> {{ crew.seaServiceStatus }}</td>
                          <td class="text-center align-middle"> {{ crew.crewType }}</td>
                          <td class="text-center align-middle"> {{ crew.vessel }}</td>
                          <td class="text-center align-middle"> {{ crew.grt }}</td>
                          <td class="text-center align-middle"> {{ crew.SIRBDocNo }}</td>
                          <td class="text-center align-middle" :class="{'text-danger': crew.isSIRBDocExpire}"> {{ crew.SIRBDocExpiry }}</td>
                          <td class="text-center align-middle"> {{ crew.PassportDocNo }}</td>
                          <td class="text-center align-middle" :class="{'text-danger': crew.isPassportExpire}"> {{ crew.passportDocExpiry }}</td>
                          <td class="text-center align-middle" :class="{'text-danger': crew.isCOCDocExpire}"> {{ crew.COCExpiry }}</td>
                          <td class="text-center align-middle"> {{ crew.reg }}</td>
                          <td class="text-center align-middle" :class="{'text-danger': crew.isUSVisaExpire}"> {{ crew.UsVisaDocExpiry }}</td>

                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {CommonMixins} from "@/mixins/common.mixins";
import Swal from "sweetalert2";
import {FileService} from "@/service/file.service";
import {AlertService} from "@/service/alert.service";
import {global_variables_config} from "@/config/global-variable.config";

export default {
  name: "HighestGRT",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      reportParam: {
        rankId: null,
        isPDF: false,
        rankName: null,
      },
      ranks: null,
      rankSelected: null,
      isLoading: false,
      hasData: false,
      crewData: null
    }
  },


  /** methods**/
  methods: {
    ...mapActions([
        'getCrewComplimentPerVessel',
    ]),
    selectReportType() {
      this.hasData = false;
      this.rankSelected = this.getRankName(this.reportParam.rankId);
    },

    getRankName(rankId) {
      let rankName = '';
      const rankList = this.ranks
      for (let c in rankList) {
        if (parseInt(rankList[c].id) === parseInt(rankId)) {
          rankName = rankList[c].name;
        }
      }
      return rankName;
    },

    async getHighestGRT() {
      if (this.reportParam.rankId === 0) {
        this.errorAlert('Please Select Rank', '')
      } else {
        this.reportParam.isPDF = false;
        this.hasData = false;
        let params = this.reportParam;
        this.isLoading = true;
        return await axios.post(PMC.GET_HIGHEST_GRT, params).then(async r => {
          this.isLoading = false;
          if (Object.keys(r.data).length > 0) {
            this.hasData = true;
            this.crewData = r.data;
          } else {
            this.hasData = false;
            this.crewData = null;
          }
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate summary of crew with highest GRT')
          return false
        });
      }
    },

    async getRanks() {
      let params = {};
      return await axios.get(PMC.GET_ACTIVE_RANK_LEVEL, params).then(async r => {
        this.ranks = r.data;
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
        return false
      });
    },

    async printHighestGRT() {
      this.reportParam.rankName = this.rankSelected;
      this.reportParam.isPDF = true;
      let params = this.reportParam;
      return await axios.post(PMC.GET_HIGHEST_GRT, params).then(async r => {
        this.isLoading = false;
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Sign On/Off Summary')
        return false
      });
    },

    async printReport(){
      AlertService.loadingAlert('Please wait ...', 'Generating PDF')

      let pdfData = await this.printHighestGRT();
      if (pdfData) {
        await this.pdfViewer('File', pdfData);
        Swal.close();
      } else {
        Swal.close();
      }
    },

  },



  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          'objOwners',
          'isReloading',
          'objRanks'
        ]),
  },

  /** mounted **/
  mounted() {
    this.getRanks();
  },

  async created() {
    this.$MyGlobalFunction = global_variables_config
    await this.getCrewComplimentPerVessel();

    if (this.$route.query.view != null) {
      this.reportParam.rankId = parseInt(this.$route.query.rankId);
    }
  },

  /** created **/

  beforeDestroy() {
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}
</style>
