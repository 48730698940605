<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"style="font-size: 22px;">Sign On / Sign Off Summary</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>



        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">SHIP OWNERS</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="ownerId"
                                @change="getVesselsByOwner"
                                required>
                          <option :value="0">All</option>
                          <option v-for="(objOwner, index) in objOwners" :value="objOwner.id">{{objOwner.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">VESSELS</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.vesselId"
                                required>
                          <option :value="0">All</option>
                          <option v-for="(objVessel, index) in vesselList" :value="objVessel.id">{{objVessel.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">DURATION</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportDuration"
                                @change="onDurationChange"
                                required>
                          <option :value="1">Monthly</option>
                          <option :value="2">Yearly</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">REPORT TYPE</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                                v-model="reportParam.reportType"
                                @change="selectReportType"
                                required>
                          <option :value="1">Sign On Crew Summary</option>
                          <option :value="2">Sign Off Crew Summary</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="row">
                  <div class="col-md-12 text-left">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">YEAR</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.year"
                                required>
                          <option v-for="(years) in reportYears" :value="years">{{years}}</option>
                        </select>
                      </div>
                    </div>
                    <div v-if="isMonthly" class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">MONTH</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                v-model="reportParam.month"
                                class="custom-select custom-select-sm"
                                required>
                          <option :value="1">January</option>
                          <option :value="2">February</option>
                          <option :value="3">March</option>
                          <option :value="4">April</option>
                          <option :value="5">May</option>
                          <option :value="6">June</option>
                          <option :value="7">July</option>
                          <option :value="8">August</option>
                          <option :value="9">September</option>
                          <option :value="10">October</option>
                          <option :value="11">November</option>
                          <option :value="12">December</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="generateSignOnOffSummary">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          @click="printReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
            <div class="row ml-2">
              <div class="col-sm-3">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label">TOTAL CREW CHANGES : <b class="pl-2" style="font-size: 1.1em;">{{totalCrewChange}}</b></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <span class="col-form-label">
                        <span v-if="reportParam.reportType === 1">SIGN ON</span>
                        <span v-else-if="reportParam.reportType === 2">SIGN OFF</span>
                        CREW FOR THE
                        <span v-if="isMonthly">MONTH</span>
                        <span v-else>YEAR</span>
                        OF :
                        <b class="pl-2" style="font-size: 1.1em;"><label v-if="isMonthly">{{monthStr(reportParam.month)}}</label> {{reportParam.year}}</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                    <tr>
                      <th style="width: 5%;">#</th>
                      <th style="width: 20%;">Name</th>
                      <th style="width: 10%;">Rank</th>
                      <th style="width: 15%;">Vessel</th>
                      <th style="width: 20%;">Country - Joining Port</th>
                      <th style="width: 10%;">
                        <span v-if="reportParam.reportType === 1"> Departure Date</span>
                        <span v-else-if="reportParam.reportType === 2"> Arrival Date</span>
                      </th>
                      <th style="width: 10%;">
                        <span v-if="reportParam.reportType === 1"> Sign On Date</span>
                        <span v-else-if="reportParam.reportType === 2"> Sign Off Date</span>
                      </th>
                      <th style="width: 10%;">CCM #</th>
                     </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else >
                    <template v-if="hasData" >
                      <template v-for="(crew, index) in sigOnData">
                      <tr>
                        <td> {{index + 1}} </td>
                        <td class="text-left pl-2">
                          <a href="#"
                             @click.stop="gotoCrewProfileNewWindow(crew.crewId, 'all-info')"
                             style="color:black;">
                            <small><b>{{ crew.crew }} </b></small>
                          </a>

                        </td>
                        <td> {{crew.rank}} </td>
                        <td> {{crew.vessel}} </td>
                        <td> {{crew.country}} </td>
                        <td>
                          <span v-if="reportParam.reportType === 1"> {{crew.departure}} </span>
                          <span v-else-if="reportParam.reportType === 2"> {{crew.arrival}} </span>
                        </td>
                        <td>
                          <span v-if="reportParam.reportType === 1"> {{crew.signon}} </span>
                          <span v-else-if="reportParam.reportType === 2"> {{crew.signoff}} </span>
                        </td>
                        <td> {{crew.ccm}} </td>
                      </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";

export default {
  name: "SingOnSignOffSummary",


  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      isEditable: false,
      isMonthly: true,
      isGenerateOnboardButton: false,
      reportYears: {},
      reportDuration: 1,
      ownerId: 0,
      vesselList: null,
      reportParam: {
        duration: 1,
        year: 2024,
        month: 1,
        ownerId: 0,
        vesselId: 0,
        reportType: 1,
        isPDF: false,
      },
      sigOnData: null,
      isLoading: false,
      hasData: false,
      totalCrewChange: 0,
    }
  },


  /** methods**/
  methods: {
    ...mapActions([
      'getOwnerKeys',
      'getCrewComplimentPerVessel',
    ]),
    yearList(){
      let year = 2027;
      let yearList = [];
      while (year >= 2012){
        yearList.push(year);
        year = year - 1;
      }
      this.reportYears = yearList;
    },
    selectReportType(){
      this.hasData = false;
    },
    onDurationChange() {
      this.isMonthly = this.reportDuration === 1;
      this.hasData = false;
      console.log(this.reportDuration);
      console.log(this.objOwners);
    },
    async loadOnboardCrewList() {
      const progressModal = $('#generatingAnimationModal')
      progressModal.modal('show');
      let onboardCrewListFile = await this.generateOnboardCrewList(this.onboardCrewListFormParam);
      if (onboardCrewListFile) {
        progressModal.modal('hide');
        await this.pdfViewerMasterChecklist('File', onboardCrewListFile);
      } else {
        progressModal.modal('hide');
      }
    },
    async getVesselsByOwner() {
      if(this.ownerId > 0){
        let params = {'ownerId': this.ownerId}
        return await axios.get(PMC.GET_VESSEL_WITH_OWNER_KEYS, {params}).then((r) => {
          console.log(r.data);
          this.vesselList = r.data;
          return true
        }).catch((er) => {
          UtilityService.generateResponseMessage(er, 'Owner Keys')
          return false
        });
      }
      else {
        this.vesselList = this.objVessels;
      }

    },

    async generateSignOnOffSummary() {
      this.reportParam.isPDF = false;
      this.isLoading = true;
      this.reportParam.ownerId = this.ownerId;
      this.reportParam.duration = this.reportDuration;
      let params = this.reportParam;
      console.log(params);
      return await axios.post(PMC.GET_SIGN_ON_OFF_CREW_SUMMARY, params).then(async r => {
        this.isLoading = false;
        this.sigOnData = r.data.list;
        this.totalCrewChange = r.data.count;
        if (this.totalCrewChange > 0) this.hasData = true;
        else this.hasData = false;
        console.log(this.sigOnData);
        console.log(this.hasData);
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
        return false
      });
    },

    async printSignOnOffSummary(params) {
      return await axios.post(PMC.GET_SIGN_ON_OFF_CREW_SUMMARY, params).then(async r => {
        this.isLoading = false;
        return  r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Sign On/Off Summary')
        return false
      });
    },

    async printReport(){
      const progressModal = $('#generatingAnimationModal')
      progressModal.modal('show');
      this.reportParam.isPDF = true;
      this.reportParam.ownerId = this.ownerId;
      this.reportParam.duration = this.reportDuration;
      let pdfData = await this.printSignOnOffSummary(this.reportParam);
      if(pdfData){
        await this.pdfViewer('File', pdfData);
      }
      progressModal.modal('hide');
    },

    monthStr(month){
      const months = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      }
      return months[month];
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objOwners',
        'objVessels',
      ]),
  },

  /** mounted **/
  mounted() {
    this.yearList();
  },

  /** created **/
  async created() {
    this.$MyGlobalFunction = global_variables_config
    await this.getOwnerKeys();
    await this.getCrewComplimentPerVessel();
    this.vesselList = this.objVessels;
  },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}
</style>
