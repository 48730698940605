<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"style="font-size: 22px;">Manpower Pool List</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.rankId"
                                @change="selectReportType"
                                required>
                          <option :value="0">{{ reportParam.filterType === 'inactive-12-months-up' ? 'All' : '-- select rank --' }}</option>
                          <option v-for="(rank, index) in ranks" :value="rank.id">{{rank.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">REPORT TYPE</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.filterType"
                                @change="selectReportType"
                                required>
                          <option value="onboard">Onboard</option>
                          <option value="standby">Standby</option>
                          <option value="applicant">Applicant</option>
                          <option value="inactive-12-months-up">Inactive 12 Months Up</option>
                          <option value="promotable-onboard-with-2-rl">Promotable Onboard w/ 2 RL</option>
                            <option value="promotable-standby-with-2-rl">Promotable Standby w/ 2 RL</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="getManpowerPoolList" :disabled="isLoading === true">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          @click="printReport" :disabled="isLoading === true">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
            <div class="row ml-2">
              <div class="col-sm-3">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label">FILTER BY : <b class="pl-2" style="font-size: 1.1em;">{{
                          rankSelected
                        }}</b></label>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="col-sm-4">-->
              <!--                <div class="ml-1 row text-left ">-->
              <!--                  <div class="col-md-12">-->
              <!--                    <div class="form-group row">-->
              <!--                      <span class="col-form-label">-->
              <!--                        Good Hope Overseas Management, Inc.-->
              <!--                      </span>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table v-if="reportParam.filterType === 'onboard'"
                       class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;"
                        @click="columnSort(manPowerPoolListSort, 'sortByReliever', true, 'relieverLineup', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByReliever'"
                        :object-data="crewData"
                      />
                      Reliever
                    </th>
                    <th
                        @click="columnSort(manPowerPoolListSort, 'sortByCode', false, 'changecode_id', crewData)"
                    >
                      Code
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByCode'"
                        :object-data="crewData"
                      />
                    </th>
                    <th style="width: 10%;"
                        @click="columnSort(manPowerPoolListSort, 'sortBySeafarerName', true, 'fullName', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySeafarerName'"
                        :object-data="crewData"
                      />
                      Seafarer Name
                    </th>
                    <th>
                      Rank
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByTob', false, 'cchCount', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByTob'"
                        :object-data="crewData"
                      />
                      TOB
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByAge', false, 'age', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAge'"
                        :object-data="crewData"
                      />
                      AGE
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByRL', false, 'countRL', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByRL'"
                        :object-data="crewData"
                      />
                      # OF RECO LETTERS
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByLicense', true, 'LicenseDoc', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByLicense'"
                        :object-data="crewData"
                      />
                      LICENSE
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByVessel', true, 'vessel_name', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByVessel'"
                        :object-data="crewData"
                      />
                      VESSEL
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByEmbarked', true, 'embarkDateSort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByEmbarked'"
                        :object-data="crewData"
                      />
                      EMBARKED
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByContractExp', true, 'eocDateSort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByContractExp'"
                        :object-data="crewData"
                      />
                      CONTRACT EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByMosOnboard', false, 'monthsOnboard', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByMosOnboard'"
                        :object-data="crewData"
                      />
                      MOS ONBOARD
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByUsVisaExp', true, 'usVisaDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByUsVisaExp'"
                        :object-data="crewData"
                      />
                      US VISA EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortBySirbExp', true, 'SIRBDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySirbExp'"
                        :object-data="crewData"
                      />
                      SIRB EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByPportExp', true, 'passportDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByPportExp'"
                        :object-data="crewData"
                      />
                      PPORT EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByCocCoeExp', true, 'cocDocSort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByCocCoeExp'"
                        :object-data="crewData"
                      />
                      COC/COE EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByCrewType', true, 'scholar', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByCrewType'"
                        :object-data="crewData"
                      />
                      CREWTYPE
                    </th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="text-left pl-2">

                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.relieverData?.crew_id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.relieverLineup }} </b></small>
                            </a>

                          </td>
                          <td> {{ crew.changecode_id }}</td>
                          <td>

                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>

                          </td>
                          <td> {{ crew.rank_alias }}</td>
                          <td> {{ crew.cchCount }}</td>
                          <td> {{ crew.age }}</td>
                          <td :class="crew.countRL > 1  ? 'recommendation-letter' : ''">
                            {{ `(` + crew.countRL + `)` + `-` +  (crew.countRL > 1 ? `YES` : `NO`)  }}
                          </td>
                          <td> {{ crew.LicenseDoc }}</td>
                          <td> {{ crew.vessel_name }}</td>
                          <td> {{ crew.embarkDate }}</td>
                          <td>
                            <span v-if="crew.nearExpireContract === 1" class="text-danger">{{ crew.eocDate }}</span>
                            <span v-else>{{ crew.eocDate }}</span>
                          </td>

                          <td v-if="crew.nearExpireMoOnboard === 1" class="bg-danger">{{ crew.monthsOnboard }} Months
                          </td>
                          <td v-else-if="crew.nearExpireMoOnboard === 2" class="bg-success">{{ crew.monthsOnboard }}
                            Months
                          </td>
                          <td v-else>{{ crew.monthsOnboard }} Months</td>

                          <td> {{ crew.usVisaDocExpiry }}</td>
                          <td> {{ crew.SIRBDocExpiry }}</td>
                          <td> {{ crew.passportDocExpiry }}</td>
                          <td> {{ crew.cocDoc }}</td>
                          <td> {{ crew.scholar }}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
                <table v-if="reportParam.filterType === 'standby'"
                       class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;"
                        @click="columnSort(manPowerPoolListSort, 'sortByAssignVessel', true, 'vesselLineup', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAssignVessel'"
                        :object-data="crewData"
                      />
                      ASSIGN VESSEL
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByCode', false, 'changecode_id', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByCode'"
                        :object-data="crewData"
                      />
                      CODE
                    </th>
                    <th style="width: 10%;"
                        @click="columnSort(manPowerPoolListSort, 'sortBySeafarerName', true, 'fullName', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySeafarerName'"
                        :object-data="crewData"
                      />
                      SEAFARER NAME
                    </th>
                    <th>RANK</th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByTob', false, 'cchCount', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByTob'"
                        :object-data="crewData"
                      />
                      TOB
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByAge', false, 'age', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAge'"
                        :object-data="crewData"
                      />
                      AGE
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByRL', false, 'countRL', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByRL'"
                        :object-data="crewData"
                      />
                      # OF RECO LETTERS
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByLicense', true, 'LicenseDoc', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByLicense'"
                        :object-data="crewData"
                      />
                      LICENSE
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByExVessel', true, 'vessel_name', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByExVessel'"
                        :object-data="crewData"
                      />
                      EX-VESSEL
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortBySignOff', true, 'disEmbarkDateSort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySignOff'"
                        :object-data="crewData"
                      />
                      SIGNOFF
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByMosStandby', false, 'monthsStandBy', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByMosStandby'"
                        :object-data="crewData"
                      />
                      MOS STANDBY
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByAvail', true, 'availabilitySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAvail'"
                        :object-data="crewData"
                      />
                      AVAIL
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByMedicalValidity', true, 'medicalDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByMedicalValidity'"
                        :object-data="crewData"
                      />
                      MEDICAL VALIDITY
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByUsVisaExp', true, 'usVisaDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByUsVisaExp'"
                        :object-data="crewData"
                      />
                      US VISA EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortBySirbExp', true, 'SIRBDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySirbExp'"
                        :object-data="crewData"
                      />
                      SIRB EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByPportExp', true, 'passportDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByPportExp'"
                        :object-data="crewData"
                      />
                      PPORT EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByCocCoeExp', true, 'cocDocSort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByCocCoeExp'"
                        :object-data="crewData"
                      />
                      COC/COE EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByType', true, 'scholar', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByType'"
                        :object-data="crewData"
                      />
                      TYPE
                    </th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="text-left pl-2"> {{ crew.vesselLineup }}</td>
                          <td> {{ crew.changecode_id }}</td>
                          <td class="pl-2">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>
                          </td>
                          <td> {{ crew.rank_alias }}</td>
                          <td> {{ crew.cchCount }}</td>
                          <td> {{ crew.age }}</td>
                          <td :class="crew.countRL > 1  ? 'recommendation-letter' : ''">
                            {{ `(` + crew.countRL + `)` + `-` +  (crew.countRL > 1 ? `YES` : `NO`)  }}
                          </td>
                          <td> {{ crew.LicenseDoc }}</td>
                          <td> {{ crew.vessel_name }}</td>
                          <td> {{ crew.disEmbarkDate }}</td>
                          <td> {{ crew.monthsStandBy }} Mos</td>
                          <td> {{ crew.availability }}</td>
                          <td v-if="crew.nearExpireMedical === 1" class="bg-danger">{{ crew.medicalDocExpiry }}</td>
                          <td v-else> {{ crew.medicalDocExpiry }}</td>
                          <td> {{ crew.usVisaDocExpiry }}</td>
                          <td> {{ crew.SIRBDocExpiry }}</td>
                          <td> {{ crew.passportDocExpiry }}</td>
                          <td> {{ crew.cocDoc }}</td>
                          <td> {{ crew.scholar }}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
                <table v-if="reportParam.filterType === 'applicant'" class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;"
                        @click="columnSort(manPowerPoolListSort, 'sortByVesselAssignment', true, 'vesselAssignment', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByVesselAssignment'"
                        :object-data="crewData"
                      />
                      Vessel Assignment
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByCrewId', false, 'id', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByCrewId'"
                        :object-data="crewData"
                      />
                      CREWID
                    </th>
                    <th style="width: 10%;"
                        @click="columnSort(manPowerPoolListSort, 'sortBySeafarerName', true, 'fullName', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySeafarerName'"
                        :object-data="crewData"
                      />
                      Seafarer Name
                    </th>
                    <th>Rank</th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByEndorsedBy', true, 'endorsedBy', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByEndorsedBy'"
                        :object-data="crewData"
                      />
                      Endorsed By
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByApplicationDate', true, 'dateAppliedSort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByApplicationDate'"
                        :object-data="crewData"
                      />
                      Application Date
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByAvailableDate', true, 'dateAvailableSort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAvailableDate'"
                        :object-data="crewData"
                      />
                      Available Date
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortBySirb', true, 'SIRBDocNo', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySirb'"
                        :object-data="crewData"
                      />
                      SIRB
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortBySirbExp', true, 'SIRBDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySirbExp'"
                        :object-data="crewData"
                      />
                      SIRB Validity
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByPport', true, 'passportDocNo', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByPport'"
                        :object-data="crewData"
                      />
                      Passport
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByPportExp', true, 'passportDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByPportExp'"
                        :object-data="crewData"
                      />
                      Passport Validity
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByUsVisaExp', true, 'usVisaDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByUsVisaExp'"
                        :object-data="crewData"
                      />
                      US Visa Expiry
                    </th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else >
                    <template v-if="hasData" >
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td> {{index + 1}} </td>
                          <td class="text-center align-middle pl-2">  {{crew.vesselAssignment}} </td>
                          <td> {{crew.id}} </td>
                          <td class="pl-2">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>
                            </td>
                          <td> {{crew.rank_alias}} </td>
                          <td> {{crew.endorsedBy}} </td>
                          <td> {{crew.dateApplied}} </td>
                          <td> {{crew.dateAvailable}} </td>
                          <td class="text-center pl-2"> {{crew.SIRBDocNo}} </td>
                          <td> {{crew.SIRBDocExpiry}} </td>
                          <td class="text-center pl-2"> {{crew.passportDocNo}}</td>
                          <td> {{crew.passportDocExpiry}} </td>
                          <td> {{crew.usVisaDocExpiry}} </td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
                <table v-if="reportParam.filterType === 'inactive-12-months-up'"
                       class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;"
                        @click="columnSort(manPowerPoolListSort, 'sortByAssignVessel', true, 'vesselLineup', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAssignVessel'"
                        :object-data="crewData"
                      />
                      ASSIGN VESSEL
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByCode', false, 'changecode_id', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByCode'"
                        :object-data="crewData"
                      />
                      CODE
                    </th>
                    <th style="width: 10%;"
                        @click="columnSort(manPowerPoolListSort, 'sortBySeafarerName', true, 'fullName', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySeafarerName'"
                        :object-data="crewData"
                      />
                      SEAFARER NAME
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByRankId', false, 'rankId', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByRankId'"
                        :object-data="crewData"
                      />
                      RANK
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByTob', false, 'cchCount', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByTob'"
                        :object-data="crewData"
                      />
                      TOB
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByAge', false, 'age', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAge'"
                        :object-data="crewData"
                      />
                      AGE
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByRL', false, 'countRL', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByRL'"
                        :object-data="crewData"
                      />
                      PROMOTABLE
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByLicense', true, 'LicenseDoc', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByLicense'"
                        :object-data="crewData"
                      />
                      LICENSE
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByExVessel', true, 'vessel_name', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByExVessel'"
                        :object-data="crewData"
                      />
                      EX-VESSEL
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortBySignOff', true, 'disEmbarkDateSort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySignOff'"
                        :object-data="crewData"
                      />
                      SIGNOFF
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByMosStandby', false, 'monthsStandBy', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByMosStandby'"
                        :object-data="crewData"
                      />
                      MOS STANDBY
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByAvail', true, 'availabilitySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAvail'"
                        :object-data="crewData"
                      />
                      AVAIL
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByMedicalValidity', true, 'medicalDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByMedicalValidity'"
                        :object-data="crewData"
                      />
                      MEDICAL VALIDITY
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByUsVisaExp', true, 'usVisaDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByUsVisaExp'"
                        :object-data="crewData"
                      />
                      US VISA EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortBySirbExp', true, 'SIRBDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySirbExp'"
                        :object-data="crewData"
                      />
                      SIRB EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByPportExp', true, 'passportDocExpirySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByPportExp'"
                        :object-data="crewData"
                      />
                      PPORT EXP
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByType', true, 'scholar', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByType'"
                        :object-data="crewData"
                      />
                      TYPE
                    </th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="text-left pl-2"> {{ crew.vesselLineup }}</td>
                          <td> {{ crew.changecode_id }}</td>
                          <td class="pl-2">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>
                          </td>
                          <td> {{ crew.rank_alias }}</td>
                          <td> {{ crew.cchCount }}</td>
                          <td> {{ crew.age }}</td>
                          <td :class="crew.countRL > 1  ? 'recommendation-letter' : ''">
                            {{ `(` + crew.countRL + `)` + `-` +  (crew.countRL > 1 ? `YES` : `NO`)  }}
                          </td>
                          <td> {{ crew.LicenseDoc }}</td>
                          <td> {{ crew.vessel_name }}</td>
                          <td> {{ crew.disEmbarkDate }}</td>
                          <td> {{ crew.monthsStandBy }} Mos</td>
                          <td> {{ crew.availability }}</td>
                          <td v-if="crew.nearExpireMedical === 1" class="bg-danger">{{ crew.medicalDocExpiry }}</td>
                          <td v-else> {{ crew.medicalDocExpiry }}</td>
                          <td> {{ crew.usVisaDocExpiry }}</td>
                          <td> {{ crew.SIRBDocExpiry }}</td>
                          <td> {{ crew.passportDocExpiry }}</td>
                          <td> {{ crew.scholar }}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
                <table v-if="reportParam.filterType === 'promotable-standby-with-2-rl'"
                       class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;"
                        @click="columnSort(manPowerPoolListSort, 'sortByAssignVessel', true, 'vesselLineup', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAssignVessel'"
                        :object-data="crewData"
                      />
                      VESSEL ASSIGNMENT
                    </th>
                    <th style="width: 10%;"
                        @click="columnSort(manPowerPoolListSort, 'sortBySeafarerName', true, 'fullName', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySeafarerName'"
                        :object-data="crewData"
                      />
                      SEAFARER NAME
                    </th>
                    <th>RANK</th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByTob', false, 'cchCount', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByTob'"
                        :object-data="crewData"
                      />
                      TOB AS PER CURRENT RANK
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByAge', false, 'age', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAge'"
                        :object-data="crewData"
                      />
                      AGE
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByRL', false, 'countRL', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByRL'"
                        :object-data="crewData"
                      />
                      # OF RECO LETTERS
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByLicense', true, 'LicenseDoc', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByLicense'"
                        :object-data="crewData"
                      />
                      LICENSE
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByExVessel', true, 'vessel_name', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByExVessel'"
                        :object-data="crewData"
                      />
                      EX-VESSEL
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortBySignOff', true, 'disEmbarkDateSort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortBySignOff'"
                        :object-data="crewData"
                      />
                      DISEMBARKED
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByMosStandby', false, 'monthsStandBy', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByMosStandby'"
                        :object-data="crewData"
                      />
                      # MOS STANDBY
                    </th>
                    <th
                      @click="columnSort(manPowerPoolListSort, 'sortByAvail', true, 'availabilitySort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="manPowerPoolListSort"
                        :sort-by-value="'sortByAvail'"
                        :object-data="crewData"
                      />
                      AVAILABLE
                    </th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="text-left pl-2"> {{ crew.vesselLineup }}</td>
                          <td class="pl-2">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>
                          </td>
                          <td> {{ crew.rank_alias }}</td>
                          <td> {{ crew.cchCount }}</td>
                          <td> {{ crew.age }}</td>
                          <td :class="crew.countRL > 1  ? 'recommendation-letter' : ''">
                            {{ `(` + crew.countRL + `)` + `-` +  (crew.countRL > 1 ? `YES` : `NO`)  }}
                          </td>
                          <td> {{ crew.LicenseDoc }}</td>
                          <td> {{ crew.vessel_name }}</td>
                          <td> {{ crew.disEmbarkDate }}</td>
                          <td> {{ crew.monthsStandBy }} Months</td>
                          <td :class="crew.availability !== '---'  ? 'recommendation-letter' : ''"> {{ crew.availability }}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
               <table v-if="reportParam.filterType === 'promotable-onboard-with-2-rl'"
                         class="table table-bordered table-sm text-center small sticky-header table-hover">
                      <thead>
                      <tr>
                          <th>#</th>
                          <th style="width: 10%;"
                              @click="columnSort(manPowerPoolListSort, 'sortByReliever', true, 'relieverLineup', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByReliever'"
                                      :object-data="crewData"
                              />
                              Replacement
                          </th>
                          <th style="width: 10%;"
                              @click="columnSort(manPowerPoolListSort, 'sortBySeafarerName', true, 'fullName', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortBySeafarerName'"
                                      :object-data="crewData"
                              />
                              Seafarer Name
                          </th>
                          <th>Rank</th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByTob', false, 'cchCount', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByTob'"
                                      :object-data="crewData"
                              />
                              TOB as per Current Rank
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByAge', false, 'age', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByAge'"
                                      :object-data="crewData"
                              />
                              AGE
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByRL', false, 'countRL', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByRL'"
                                      :object-data="crewData"
                              />
                              # OF RECO LETTERS
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByLicense', true, 'LicenseDoc', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByLicense'"
                                      :object-data="crewData"
                              />
                              LICENSE
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByVessel', true, 'vessel_name', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByVessel'"
                                      :object-data="crewData"
                              />
                              VESSEL
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByEmbarked', true, 'embarkDateSort', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByEmbarked'"
                                      :object-data="crewData"
                              />
                              EMBARKED
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByContractExp', true, 'eocDateSort', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByContractExp'"
                                      :object-data="crewData"
                              />
                              CONTRACT EXP
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByMosOnboard', false, 'monthsOnboard', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByMosOnboard'"
                                      :object-data="crewData"
                              />
                              Onboard Status
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByUsVisaExp', true, 'usVisaDocExpirySort', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByUsVisaExp'"
                                      :object-data="crewData"
                              />
                              US VISA EXP
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortBySirbExp', true, 'SIRBDocExpirySort', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortBySirbExp'"
                                      :object-data="crewData"
                              />
                              SIRB EXP
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByPportExp', true, 'passportDocExpirySort', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByPportExp'"
                                      :object-data="crewData"
                              />
                              PPORT EXP
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByCocCoeExp', true, 'cocDocSort', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByCocCoeExp'"
                                      :object-data="crewData"
                              />
                              COC/COE EXP
                          </th>
                          <th
                                  @click="columnSort(manPowerPoolListSort, 'sortByCrewType', true, 'scholar', crewData)"
                          >
                              <sort-arrow
                                      :sort-by-object="manPowerPoolListSort"
                                      :sort-by-value="'sortByCrewType'"
                                      :object-data="crewData"
                              />
                              Scholar
                          </th>
                      </tr>
                      </thead>
                      <template v-if="isLoading">
                          <tr>
                              <td colspan="100%">
                                  <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                                  <span style="size: 20px">
                          Loading ...
                        </span>
                              </td>
                          </tr>
                      </template>
                      <template v-else>
                          <template v-if="hasData">
                              <template v-for="(crew, index) in crewData">
                                  <tr>
                                      <td>{{ index + 1 }}</td>
                                      <td class="text-left pl-2">
                                          <a href="#"
                                             @click.stop="gotoCrewProfileNewWindow(crew.relieverData?.crew_id, 'all-info')"
                                             style="color:black;">
                                              <small><b>{{ crew.relieverLineup }} </b></small>
                                          </a>

                                      </td>
                                      <td>
                                          <a href="#"
                                             @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                             style="color:black;">
                                              <small><b>{{ crew.fullName }} </b></small>
                                          </a>
                                      </td>
                                      <td> {{ crew.rank_alias }}</td>
                                      <td> {{ crew.cchCount }}</td>
                                      <td> {{ crew.age }}</td>
                                      <td :class="crew.countRL > 1  ? 'recommendation-letter' : ''">
                                          {{ `(` + crew.countRL + `)` + `-` +  (crew.countRL > 1 ? `YES` : `NO`)  }}
                                      </td>
                                      <td> {{ crew.LicenseDoc }}</td>
                                      <td> {{ crew.vessel_name }}</td>
                                      <td> {{ crew.embarkDate }}</td>
                                      <td>
                                          <span v-if="crew.nearExpireContract === 1" class="text-danger">{{ crew.eocDate }}</span>
                                          <span v-else>{{ crew.eocDate }}</span>
                                      </td>
                                      <td v-if="crew.nearExpireMoOnboard === 1" class="bg-danger">{{ crew.monthsOnboard }} Months
                                      </td>
                                      <td v-else-if="crew.nearExpireMoOnboard === 2" class="bg-success">{{ crew.monthsOnboard }}
                                          Months
                                      </td>
                                      <td v-else>{{ crew.monthsOnboard }} Months</td>
                                      <td> {{ crew.usVisaDocExpiry }}</td>
                                      <td> {{ crew.SIRBDocExpiry }}</td>
                                      <td> {{ crew.passportDocExpiry }}</td>
                                      <td> {{ crew.cocDoc }}</td>
                                      <td> {{ crew.scholar }}</td>
                                  </tr>
                              </template>
                          </template>
                          <template v-else>
                              <tr>
                                  <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                                  </td>
                              </tr>
                          </template>
                      </template>
                  </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";

export default {
  name: "ManPowerStandbyOnboard",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      isEditable: false,
      isGenerateOnboardButton: false,
      reportParam: {
        rankId: 0,
        filterType: 'onboard',
        isPDF: false,
        rankName: null,
        isOverAge: 'all',
      },

      manPowerPoolListSort: {
        sortBySeafarerName: '',
        sortByReliever: '',
        sortByCode: '',
        sortByTob: '',
        sortByRL: '',
        sortByAge: '',
        sortByLicense: '',
        sortByVessel: '',
        sortByEmbarked: '',
        sortByContractExp: '',
        sortByMosOnboard: '',
        sortByUsVisaExp: '',
        sortBySirbExp: '',
        sortByPportExp: '',
        sortByCocCoeExp: '',
        sortByCrewType: '',
        sortByAssignVessel: '',
        sortByExVessel: '',
        sortByVesselAssignment: '',
        sortBySignOff: '',
        sortByMosStandby: '',
        sortByAvail: '',
        sortByMedicalValidity: '',
        sortByType: '',
        sortByRankId: '',
        sortByCrewId: '',
        sortByEndorsedBy: '',
        sortByApplicationDate: '',
        sortByAvailableDate: '',
        sortBySirb: '',
        sortByPport: '',
      },


      isLoading: false,
      hasData: false,
      ranks: null,
      crewData: null,
      rankSelected: null,
    }
  },


  /** methods**/
  methods: {
    ...mapActions([
      'getOwnerKeys',
      'getCrewComplimentPerVessel',
    ]),
    yearList() {
      let year = 2023;
      let yearList = [];
      while (year >= 2019) {
        yearList.push(year);
        year = year - 1;
      }
      this.reportYears = yearList;
    },
    selectReportType() {
      this.hasData = false;
      this.rankSelected = this.getRankName(this.reportParam.rankId);
      // console.log(this.reportParam.filterType)
    },

    getRankName(rankId){
      let rankName = '';
      const rankList = this.ranks
      for (let c in rankList) {
        if (parseInt(rankList[c].id) === parseInt(rankId)) {
          rankName = rankList[c].name;
        }
      }
      return rankName;
    },
    async getManpowerPoolList() {
      if (this.reportParam.rankId === 0 && this.reportParam.filterType !== 'inactive-12-months-up') {
        this.errorAlert('Please Select Rank', '')
      } else {
        this.reportParam.isPDF = false;
        this.hasData = false;
        let params = this.reportParam;
        this.isLoading = true;
        return await axios.post(PMC.GET_MANPOWER_POOL_STANDBY_ONBOARD_LIST, params).then(async r => {
          console.log(r.data);
          this.isLoading = false;
          if (Object.keys(r.data).length > 0) {
            this.hasData = true;
            this.crewData = r.data;
          } else {
            this.hasData = false;
            this.crewData = null;
          }

          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
          return false
        });
      }
    },

    async getRanks() {
      let params = {};
      return await axios.get(PMC.GET_ACTIVE_RANK_LEVEL, params).then(async r => {
        console.log(r.data);
        this.ranks = r.data;
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
        return false
      });
    },

    async printManpowerPoolList() {
      this.reportParam.rankName = this.rankSelected;
      this.reportParam.isPDF = true;
      let params = this.reportParam;

      return await axios.post(PMC.GET_MANPOWER_POOL_STANDBY_ONBOARD_LIST, params).then(async r => {
        this.isLoading = false;
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Sign On/Off Summary')
        return false
      });
    },

    async printReport(){
      AlertService.loadingAlert('Please wait ...', 'Generating PDF')

      let pdfData = await this.printManpowerPoolList();
      if(pdfData){
        await this.pdfViewer('File', pdfData);
        Swal.close();
      } else {
        Swal.close();
      }
    },

    monthStr(month) {
      const months = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      }
      return months[month];
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objOwners',
        'objVessels',
        'isReloading',
      ]),
  },

  /** mounted **/
  mounted() {
    this.yearList();
    this.getRanks();
  },

  /** created **/
  async created() {
    this.$MyGlobalFunction = global_variables_config
    await this.getOwnerKeys();
    await this.getCrewComplimentPerVessel();
    this.vesselList = this.objVessels;

    if(this.$route.query.view != null){
      this.reportParam.rankId = parseInt(this.$route.query.rankId);
      this.reportParam.filterType = String(this.$route.query.filterType);
      this.reportParam.isOverAge = String(this.$route.query.isOverAge);
      this.getManpowerPoolList();
    }

  },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}

.recommendation-letter {
  background: green;
  color: white;
}
</style>
