
const initPagination = () => {
  return {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  }
}

const state = {
  searchCrewPagination : initPagination(),
  crewListPagination : initPagination(),
  applicantPagination : initPagination(),
  vesselPagination : initPagination()
}

const mutations = {
  SET_SEARCH_CREW_PAGINATION (state,params){
    state.searchCrewPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },

  set_crew_list_pagination(state,params){
    state.crewListPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },

  set_applicants_pagination(state,params){
    state.applicantPagination = {
      from : params.from,
      currentPage: params.current_page,
      lastPage : params.last_page,
      perPage : params.per_page,
      to: params.to,
      total: params.total,
    }
  },

  set_vessel_pagination(state,params){
    state.vesselPagination = {
      from : params.from,
      currentPage: params.current_page,
      lastPage : params.last_page,
      perPage : params.per_page,
      to: params.to,
      total: params.total,
    }
  },

  // RESET_SEARCH_CREW_PAGINATION(state){
  reset_search_crew_pagination(state){
    Object.assign(state.searchCrewPagination,initPagination())
  },

  reset_crew_list_pagination(state){
    Object.assign(state.crewListPagination,initPagination())
  },

  reset_applicants_pagination(state){
    Object.assign(state.applicantPagination,initPagination())
  },

  reset_vessel_pagination(state){
    Object.assign(state.vesselPagination,initPagination())
  }
}

const actions = {
  resetSearchCrewPagination({commit}){
    commit('reset_search_crew_pagination');
  },

  resetCrewListPagination ({commit}) {
    commit('reset_crew_list_pagination');
  },

  resetApplicantsPagination ({commit}) {
    commit('reset_applicants_pagination');
  },

  resetVesselPagination({commit}){
    commit('reset_vessel_pagination');
  },
}

const getters = {
  searchCrewPagination : state => state.searchCrewPagination,
  crewListPagination : state => state.crewListPagination,
  applicantPagination : state => state.applicantPagination,
  vesselPagination : state => state.vesselPagination,
}

export default {
  state,
  actions,
  mutations,
  getters,
}
