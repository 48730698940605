<template>
  <div class="container-fluid py-2 pl-3">
    <div class="row">
      <div class="col">
        <div class="row pt-3">
          <div class="col-2 font-weight-bold">
            <h4 class="font-weight-bold">Education</h4>
          </div>

          <div class="col-4">
            <button type="button" class="btn btn-sm pmc-btn-prime ml-5"
                    data-toggle="modal"
                    :data-target="'#'+newCrewEducModal"
                    data-backdrop="static"
                    data-keyboard="false">
              <font-awesome-icon icon="plus-circle"/>
              Add
            </button>
          </div>

        </div>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col">
        <pmc-table :loading="isReloading" :loaderColspan="6">
          <template v-slot:table-header>
            <tr>
              <th scope="col" style="width : 15% !important;">Level</th>
              <th scope="col" style="width :20% !important;">Course</th>
              <th scope="col" style="width: 20% !important;">School</th>
              <th>Year Started</th>
              <th>Year Graduated</th>
              <!--<th>Year Graduated</th>-->
              <!--<th>Year Graduated</th>-->
              <th>Action</th>
            </tr>
          </template>

          <template v-slot:table-body>
            <tr v-for="(crewEducation,index) in crewEducations" :key="crewEducation.id">
              <td class="text-left pl-2">{{ crewEducation.level.toUpperCase() }}</td>
              <td>{{ crewEducation.courseName.toUpperCase() }}</td>
              <td>{{ crewEducation.schoolName.toUpperCase() }}</td>
              <td>{{ crewEducation.yearStarted.toUpperCase() }}</td>
              <td>{{ crewEducation.yearGraduated.toUpperCase() }}</td>
              <td>
                <button class="btn btn-xs pmc-btn-prime" @click="edit_crew_educ(crewEducation.id)"
                        type="button"
                        data-toggle="modal"
                        data-target="#crewEducModal"
                        data-backdrop="static"
                        data-keyboard="false">
                  <font-awesome-icon icon="bars"/>
                  view
                </button>

                <button class="btn btn-xs pmc-btn-danger ml-2" @click="del_crew_educ(crewEducation.id)">
                  <font-awesome-icon icon="trash"/>
                  delete
                </button>
              </td>
            </tr>
          </template>
        </pmc-table>
      </div>
    </div>

    <pmc-modal lg_height lg_width :id="crewEducModal" @closingEvent="close_crew_educ_modal">
      <template v-slot:custom-header>
        <h5>Crew Education</h5>
      </template>

      <template slot="custom-body">
        <div class="col">
          <div class="form-group row mt-2">
            <label for="crew-phil-health" class="col-sm-3 col-form-label">Level</label>
            <span>:</span>
            <div class="col-sm-7">
              <input type="text"
                     disabled
                     v-if="crewEducation && !isEditable"
                     :class="[isEditable ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                     id="crew-phil-health"
                     name="CrewPhilHealth"
                     placeholder="--"
                     v-model="crewEducation.level">

              <select
                  v-if="crewEducation && isEditable"
                  v-model="crewEducation.levelId"
                  name="EducationLevel"
                  id="education-level"
                  class="form-control form-control-sm">
                <option :value="educLevel.id"
                        v-for="(educLevel,index) in objEducationLevels"
                        :key="educLevel.id">
                  <span>
                    <small>
                      [ {{ titleCase(educLevel.code) }} ] - {{ titleCase(educLevel.name) }}
                    </small>
                  </span>
                </option>
              </select>
            </div>
          </div>

          <!--  school name -->
          <div class="form-group row mt-2">
            <label for="crew-phil-health" class="col-sm-3 col-form-label">School Name</label>
            <span>:</span>
            <div class="col-sm-7">
              <input type="text"
                     disabled
                     v-if="crewEducation && !isEditable"
                     :class="[isEditable ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                     id="crew-school-name"
                     name="CrewSchoolName"
                     placeholder="--"
                     v-model="crewEducation.schoolName">

              <multi-select
                  v-if="crewEducation && isEditable"
                  v-model="school"
                  :options="objSchools"
                  :custom-label="courseNameLang"
                  :select-label="'Select School'"
                  placeholder="Select School"
                  label="School"
                  track-by="id">
              </multi-select>
            </div>
          </div>


          <!-- course name  -->
          <div class="form-group row mt-2">
            <label for="crew-phil-health" class="col-sm-3 col-form-label">Course Name</label>
            <span>:</span>
            <div class="col-sm-7">
              <input type="text"
                     disabled
                     v-if="crewEducation && !isEditable"
                     :class="[isEditable ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                     id="crew-course-name"
                     name="CrewCourseName"
                     placeholder="--"
                     v-model="crewEducation.courseName">

              <multi-select
                  v-if="crewEducation && isEditable"
                  v-model="course"
                  :options="objCourses"
                  :custom-label="courseNameLang"
                  :select-label="'Select Course'"
                  placeholder="Select Course"
                  label="Course"
                  track-by="id">
              </multi-select>
            </div>
          </div>

          <div class="form-group row mt-2">
            <label for="crew-phil-health" class="col-sm-3 col-form-label">YearDate Started</label>
            <span>:</span>
            <div class="col-sm-4">
              <input type="text"
                     disabled
                     v-if="crewEducation && !isEditable"
                     :class="[isEditable ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                     id="crew-date-started"
                     name="CrewSchoolName"
                     placeholder="--"
                     v-model="crewEducation.yearStarted">

              <input type="date" v-if="isEditable && crewEducation" v-model="crewEducation.yearS" class="form-control form-control-sm">
            </div>
          </div>

          <div class="form-group row mt-2">
            <label for="crew-phil-health" class="col-sm-3 col-form-label">YearDate Graduated</label>
            <span>:</span>
            <div class="col-sm-4">
              <input type="text"
                     disabled
                     v-if="crewEducation && !isEditable"
                     :class="[isEditable ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                     id="crew-date-graduated"
                     name="CrewSchoolName"
                     placeholder="--"
                     v-model="crewEducation.yearGraduated">

              <input type="date" v-if="isEditable && crewEducation" v-model="crewEducation.yearG" class="form-control form-control-sm">
            </div>
          </div>

          <div class="form-group row mt-2">
            <label for="created-by" class="col-sm-3 col-form-label">Created By</label>
            <span>:</span>
            <div class="col-sm-6">
              <input type="text"
                     disabled
                     readonly
                     v-if="crewEducation"
                     class="form-control-plaintext form"
                     id="created-by"
                     name="CreatedBy"
                     placeholder="--"
                     v-model="crewEducation.created_by">
            </div>
          </div>

          <div class="form-group row mt-2">
            <label for="updated-by" class="col-sm-3 col-form-label">Updated By</label>
            <span>:</span>
            <div class="col-sm-6">
              <input type="text"
                     disabled
                     readonly
                     v-if="crewEducation"
                     class="form-control-plaintext form"
                     id="updated-by"
                     name="UpdatedBy"
                     placeholder="--"
                     v-model="crewEducation.updated_by">

            </div>
          </div>
        </div>
      </template>

      <template slot="custom-footer">
        <button class="btn btn-sm pmc-btn-warning" v-if="!isEditable" @click="setEditable">
          <font-awesome-icon icon="edit"/>
          Edit
        </button>
        <button class="btn btn-sm pmc-btn-success" v-if="isEditable" @click="update_crew_educ">
          <font-awesome-icon icon="save"/>
          Update
        </button>

        <button class="btn btn-sm pmc-btn-danger" v-if="isEditable" @click="setEditable">
          <font-awesome-icon icon="ban"/>
          Cancel
        </button>

        <button class="btn btn-sm pmc-btn-danger" v-if="!isEditable" @click="close_crew_educ_modal">
          <font-awesome-icon icon="times"/>
          Close
        </button>
      </template>
    </pmc-modal>

    <pmc-modal m_height xl_width :id="newCrewEducModal" @closingEvent="close_new_crew_educ_modal">
      <template slot="custom-header">
        <h5>New Crew Education</h5>
      </template>

      <template slot="custom-body">
        <div class="col">
          <div class="form-group row mt-2">
            <label for="new-crew-school-level" class="col-sm-2 col-form-label">Level</label>
            <span>:</span>
            <div class="col-sm-8">
              <select
                  v-model="newCrewEducation.levelId"
                  name="EducationLevel"
                  id="new-crew-school-level"
                  class="form-control form-control-sm">
                <option :value="null" disabled>-- Select education level --</option>
                <option :value="educLevel.id"
                        v-for="(educLevel,index) in objEducationLevels"
                        :key="educLevel.id">
                  <span>
                    <small>
                      [{{titleCase(educLevel.code)}}] -  {{ titleCase(educLevel.name) }}
                    </small>
                  </span>
                </option>
              </select>
            </div>


            <div class="col-sm-1">
              <button class="btn btn-xs pmc-btn-second" v-show="newCrewEducation.levelId" @click="newCrewEducation.levelId = null">
                <font-awesome-icon icon="times"/>
              </button>
            </div>
          </div>


          <!-- school name -->
          <div class="form-group row mt-2">
            <label class="col-sm-2 col-form-label">School Name</label>
            <span>:</span>
            <div class="col-sm-8">
              <multi-select
                  :disabled="isAddSchoolTag"
                  v-model="school"
                  :taggable="false"
                  :multiple="false"
                  :options="objSchools"
                  :custom-label="courseNameLang"
                  :select-label="'Select School'"
                  placeholder="Select School"
                  label="School"
                  track-by="id">
              </multi-select>
            </div>

            <div class="col-sm-1">
              <button
                  v-show="school != null"
                  v-on:click="school = null"
                  type="button"
                  class="btn btn-xs pmc-btn-second"
                  id="clear-school">
                <font-awesome-icon icon="times"/>
              </button>
              <button v-show="school == null" type="button"
                      class="btn btn-xs pmc-btn-prime"
                      data-toggle="modal"
                      :data-target="'#'+newSchoolModal"
                      data-backdrop="static"
                      id="new-school"
                      data-keyboard="false">
                <font-awesome-icon icon="plus-circle"/>
              </button>
            </div>
          </div>

          <!-- course name -->
          <div class="form-group row mt-2">
            <label class="col-sm-2 col-form-label">Course Name</label>
            <span>:</span>
            <div class="col-sm-8">
              <multi-select
                  :disabled="isAddCourseTag"
                  v-model="course"
                  :taggable="false"
                  :multiple="false"
                  :options="objCourses"
                  :custom-label="courseNameLang"
                  :select-label="'Select Course'"
                  placeholder="Select Course"
                  label="Course"
                  track-by="id">
              </multi-select>
            </div>

            <div class="col-sm-1">
              <button
                  v-show="course != null"
                  v-on:click="course = null"
                  type="button"
                  class="btn btn-xs pmc-btn-second"
                  id="clear-course">
                <font-awesome-icon icon="times"/>
              </button>
              <button v-show="course == null" type="button"
                      class="btn btn-xs pmc-btn-prime"
                      data-toggle="modal"
                      :data-target="'#'+newCourseModal"
                      data-backdrop="static"
                      id="new-course"
                      data-keyboard="false">
                <font-awesome-icon icon="plus-circle"/>
              </button>
            </div>
          </div>

          <!--  date started -->
          <div class="form-group row mt-2">
            <label for="new-date-started" class="col-sm-2 col-form-label">Date Started</label>
            <span>:</span>
            <div class="col-sm-3">
              <input type="date" id="new-date-started" v-model="newCrewEducation.yearS" class="form-control form-control-sm">
            </div>
          </div>

          <!--  date graduated -->
          <div class="form-group row mt-2">
            <label for="new-date-graduated" class="col-sm-2 col-form-label">Date Graduated</label>
            <span>:</span>
            <div class="col-sm-3">
              <input type="date" id="new-date-graduated" v-model="newCrewEducation.yearG" class="form-control form-control-sm">
              {{newCrewEducation.yearG}}
            </div>
          </div>
        </div>
      </template>

      <template slot="custom-footer">
        <button type="button" class="btn btn-sm pmc-btn-success" @click="add_education">
          <font-awesome-icon icon="save"/>
          Save
        </button>

        <button type="button" class="btn btn-sm pmc-btn-danger" @click="close_new_crew_educ_modal">
          <font-awesome-icon icon="times"/>
          Close
        </button>
      </template>
    </pmc-modal>


    <!-- new course modal  -->
    <pmc-modal m_height lg_width :id="newCourseModal">
      <template slot="custom-header">
        <h6>Create New Course</h6>
      </template>

      <template slot="custom-body">
          <div class="col">
            <div class="form-group row mt-2">
              <label for="course-code" class="col-sm-2 col-form-label">Code</label>
              <span>:</span>
              <div class="col-sm-9">
                <input type="text" id="course-code"  class="form-control form-control-sm">
              </div>
            </div>

            <div class="form-group row mt-2">
              <label for="course-name" class="col-sm-2 col-form-label">Name</label>
              <span>:</span>
              <div class="col-sm-9">
                <input type="text" id="course-name"  class="form-control form-control-sm">
              </div>
            </div>

            <div class="form-group row mt-2">
              <label for="course-status" class="col-sm-2 col-form-label">Status</label>
              <span>:</span>
              <div class="col-sm-9">
                <label class="mr-4">Active</label>
                <input type="checkbox" id="course-status"  class="form form-check-inline" style="transform: scale(2);">
              </div>
            </div>
          </div>
      </template>

      <template slot="custom-footer">
        <button type="button" class="btn btn-sm pmc-btn-success">
          <font-awesome-icon icon="save"/>
          Save
        </button>

        <button type="button" class="btn btn-sm pmc-btn-danger" @click="close_new_tag_course">
          <font-awesome-icon icon="times"/>
          Close
        </button>

      </template>
    </pmc-modal>

    <!--  new school modal  -->
    <pmc-modal m_height lg_width :id="newSchoolModal" @closingEvent="close_new_tag_school">
      <template slot="custom-header">
        <h5>Create New School</h5>
      </template>

      <template slot="custom-body">
        <div class="col">
          <div class="form-group row mt-2">
            <label for="school-code" class="col-sm-3 col-form-label">School Code</label>
            <span>:</span>
            <div class="col-sm-4">
              <input type="text" v-focus v-model="newSchool.code" id="school-code"  class="form-control form-control-sm" placeholder="--">
            </div>
          </div>

          <div class="form-group row mt-2">
            <label for="school-name" class="col-sm-3 col-form-label">School Name</label>
            <span>:</span>
            <div class="col-sm-6">
              <input type="text" v-focus v-model="newSchool.name" id="school-name"  class="form-control form-control-sm" placeholder="--">
            </div>
          </div>

          <div class="form-group row mt-2">
            <label for="school-address" class="col-sm-3 col-form-label">School Address</label>
            <span>:</span>
            <div class="col-sm-8">
              <input type="text" v-model="newSchool.address" id="school-address"  class="form-control form-control-sm" placeholder="--">
            </div>
          </div>

          <div class="form-group row mt-2">
            <label for="school-status" class="col-sm-3 col-form-label">Status</label>
            <span>:</span>
            <div class="col-sm-7">
              <label class="mr-4">Active</label>
              <input type="checkbox" v-model="newSchool.status" id="school-status"  class="form-check-inline" style="transform: scale(2);" v-focus>
            </div>
          </div>
        </div>
      </template>

      <template slot="custom-footer">
        <button type="button" class="btn btn-sm pmc-btn-success" v-on:click="createSchool">
          <font-awesome-icon icon="save"/>
          Save
        </button>

        <button type="button" class="btn btn-sm pmc-btn-danger" @click="close_new_tag_school">
          <font-awesome-icon icon="times"/>
          Close
        </button>
      </template>
    </pmc-modal>
  </div>
</template>

<script>
import { AppMixins } from '../../../../../../mixins/app.mixins'
import { AlertMixins } from '../../../../../../mixins/alert.mixins'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CrewEducation',
  mixins: [AppMixins, AlertMixins],
  data () {
    return {
      crewEducModal: 'crewEducModal',
      newCrewEducModal: 'newCrewEducModal',

      newSchoolModal: 'newSchoolModal',
      newCourseModal: 'newCourseModal',

      crewId: parseInt(this.$route.params.crewId, 10),
      isEditable: false,

      courseModel: null,
      schoolModel: null,

      isAddCourseTag: false,
      isAddSchoolTag: false,

      newCourse: {
        code: null,
        name: null,
        status: null,
      },
    }
  },

  methods: {
    ...mapActions(
        [
          'viewEducations',
          'saveCrewEducation',
          'viewCrewEducation',
          'updateCrewEducation',
          'deleteCrewEducation',
         'setNullCrewEducations',
          'setNullCrewEducation',
          'null_obj_education_level',
          'null_obj_course',
          'null_obj_schools',
          'getCrewEducationKeys',
          'setNullNewCrewEduc',
          'create_school',
          'set_null_new_school',
        ],
    ),

    async add_education () {
      if (await this.questionAlert('Are you sure you want to add new record of education', 'Education Record')) {
        this.newCrewEducation.crewId = this.crewId
        this.saveCrewEducation(this.newCrewEducation).then((r) => {
          if (r) {
            this.successAlert('Successfully added', 'Crew Education')
            this.setNullNewCrewEduc()
            this.hideModal(this.newCrewEducModal)
          }
        })
      }
    },

    edit_crew_educ (id) {
      this.viewCrewEducation(id)
    },

    async update_crew_educ () {
      if (await this.questionAlert('Are you sure you want to update this record?', 'Update Crew Education')) {
        this.updateCrewEducation(this.crewEducation).then((r) => {
          if (r) {
            this.successAlert('Successfully updated', 'Crew Education')
            this.hideModal(this.crewEducModal)
            this.isEditable = false
            this.setNullNewCrewEduc()
          }
        })
      }
    },

    async del_crew_educ (id) {
      if (await this.questionAlert('Are you sure you want to delete this record?', 'Crew Education', 'warning')) {
        this.deleteCrewEducation(id).then((r)=>{
          if(r){
            this.successAlert('Delete successfully');
          }
        })
      }
    },

    close_crew_educ_modal () {
      this.setNullNewCrewEduc()
      this.hideModal(this.crewEducModal)
      this.isEditable = false
      this.school = null;
      this.course = null;
    },

    close_new_crew_educ_modal () {
      this.isAddCourseTag = false
      this.setNullNewCrewEduc()
      this.hideModal(this.newCrewEducModal)
    },

    courseNameLang ({ name }) {
      return `${name}`
    },

    setEditable () {
      this.isEditable = !this.isEditable
    },

    close_new_tag_course () {
      if (this.isAddCourseTag) {
        this.isAddCourseTag = false
      }
      this.hideModal(this.newCourseModal);
    },

    close_new_tag_school () {
      if(this.isAddSchoolTag){
        this.isAddSchoolTag = false;
      }
      this.hideModal(this.newSchoolModal);
      this.setNullNewSchool();
    },

   async createSchool(){
      if(await this.questionAlert('Are you sure you want to add this school record.?','School Record')){
        this.create_school(this.newSchool).then((r)=>{
          if(r){
            this.successAlert('School record add successfully','School Record');
            this.getCrewEducationKeys();
            this.setNullNewSchool();
            this.hideModal(this.newSchoolModal)
          }
        });
      }
    },

    setNullNewSchool(){
      this.set_null_new_school();
    }
  },

  computed: {
    ...mapGetters([
      'crewEducations',
      'crewEducation',
      'newCrewEducation',
      'objEducationLevels',
      'objSchools',
      'objCourses',
      'newSchool',
      'isReloading'
    ]),

    /** course **/
    course: {
      get () {
        if (this.courseModel == null) {
          let val = this.objCourses.find((course, index) => course.id === this.crewEducation.courseId)
          return val
        }
        return this.courseModel
      },
      set (val) {
        this.courseModel = val
        this.crewEducation.courseId = val ? val.id : null
        this.newCrewEducation.courseId = val ? val.id : null
      },
    },

    /** school **/
    school: {
      get () {
        if (this.schoolModel == null) {
          let val = this.objSchools.find((school, index) => school.id === this.crewEducation.schoolId)
          return val
        }
        return this.schoolModel
      },
      set (val) {
        this.schoolModel = val
        this.crewEducation.schoolId = val ? val.id : null
        this.newCrewEducation.schoolId = val ? val.id : null
      },
    },
  },

  mounted () {
  },

  created () {
    this.getCrewEducationKeys()
    this.viewEducations(this.crewId)
  },

  destroyed () {
    this.setNullCrewEducations()
    this.null_obj_education_level();
    this.null_obj_schools();
    this.null_obj_course();
    this.setNullNewCrewEduc();
  },

}
</script>

<style scoped>

</style>
