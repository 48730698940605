import Vue from 'vue';
import VueRouter from 'vue-router';

/** Dashboard **/
import Dashboard1 from '@/components/modules/dashboard/Dashboard1';
import DashboardOnboardDocCertsExpiry from "@/components/modules/dashboard/DashboardOnboardDocCertsExpiry.vue";
import DashboardCrewPoolReportPerRankList from "@/components/modules/dashboard/DashboardCrewPoolReportPerRankList.vue";

import Home from '@/views/Home.vue';
import Login from '@/views/Login';
import Crewing from '@/views/Crewing';
import CrewChangePlan from '@/components/modules/crewchange/CrewChangePlan';
import CrewChangeManipulation from '@/components/modules/crewchange/CrewChangeManipulation';

/** Crew compliment **/
import Compliment from '@/views/compliment/Compliment'
import CrewCompliment from '@/components/modules/crewcompliment/CrewCompliment';

/** applicant tracking  **/
import ApplicantTracking from '@/views/tracking/ApplicantTracking'
import ApplicantList from '@/components/modules/applicanttracking/ApplicantList';
import ApplicantDetail from '@/components/modules/applicanttracking/ApplicantDetail';

/** Crew Checklist **/
import DocumentChecklist from '@/components/modules/documentchecklist/DocumentChecklist';
import SubPreDepartureChecklist from '@/components/modules/documentchecklist/_sub/SubPreDepartureChecklist';

import MasterList from '@/views/MasterList';

import CrewMasterList from '@/views/masterlist/Crew'
import CrewList from '@/components/modules/masterlist/crew/List';
import CreateCrew from '@/components/modules/masterlist/crew/CreateCrew';

import VesselMasterList from '@/views/masterlist/Vessel'
import VesseList from '@/components/modules/masterlist/vessel/List'
import ViewVessel from "@/components/modules/masterlist/vessel/ViewVessel";

import CrewProfile from '@/components/modules/crewprofile/CrewProfile';

//index
import AllInfo from '@/components/modules/crewprofile/_sub/allinfo/AllInfo.vue';

//children
import CrewPersonal from '@/components/modules/crewprofile/_sub/allinfo/_sub/CrewPersonal';

import CrewAddressAndContact from '@/components/modules/crewprofile/_sub/allinfo/_sub/CrewAddressAndContact';
import CrewEducation from '@/components/modules/crewprofile/_sub/allinfo/_sub/CrewEducation';
import CrewFamily from '@/components/modules/crewprofile/_sub/allinfo/_sub/CrewFamily';
import CrewReferral from '@/components/modules/crewprofile/_sub/allinfo/_sub/CrewReferral';
import CrewApplicationDetails from "@/components/modules/crewprofile/_sub/allinfo/_sub/CrewApplicationDetails";

import CrewSeaService from '@/components/modules/crewprofile/_sub/sea_service/CrewSeaService';
import CrewMedical from '@/components/modules/crewprofile/_sub/medical/CrewMedical';
import CrewComment from '@/components/modules/crewprofile/_sub/comment/CrewComment';
import CrewScannedChecklist from '@/components/modules/crewprofile/_sub/scannedchecklist/CrewScannedChecklist';
import CrewEvaluation from '@/components/modules/crewprofile/_sub/evaluation/CrewEvaluation';
import CrewDocument from '@/components/modules/crewprofile/_sub/document/CrewDocument';
import CrewCovidVaccine from '@/components/modules/crewprofile/_sub/document/_sub/CovidVaccine';
import CrewOutSideService from '@/components/modules/crewprofile/_sub/outside-service/CrewOutSideService';

import Checklist from '@/components/modules/crewchange/_sub/Checklist';
import CrewWithdrawal from "@/components/modules/crewprofile/_sub/withdrawal/CrewWithdrawal";

import ChangePassword from '@/views/ChangePassword';
import AuthProfile from '@/views/AuthProfile';
import {PMC, TOKEN} from '@/config/constants.config';
import FailOverPage from '@/components/base/FailOverPage';


import Setup from '../views/setup/App';  // setup index component.

/** employee setup route **/
import EmployeeSetup from '../views/setup/com/EmployeeSetup';
import EmployeeList from '../components/modules/setup/employee/List';
import ViewEmployee from '../components/modules/setup/employee/ViewEmployee';

/** school setup  **/
import SchoolSetup from '@/views/setup/com/SchoolSetup';
import SchoolList from '@/components/modules/setup/school/List';

/** course setup **/
import CourseSetup from '@/views/setup/com/Course';
import CourseList from '@/components/modules/setup/course/List';

/** document setup **/
import DocumentSetup from '@/views/setup/com/DocumentSetup';
import DocumentList from '@/components/modules/setup/document/List';
import DocPreRequisite from '@/components/modules/setup/document/DocPreRequisite';
import DocumentView from '@/components/modules/setup/document/DocumentView';

import TestChunkUpload from '../views/TestChunkUpload';

/** rank setup **/
import RankSetup from '@/views/setup/com/RankSetup';
import RankList from '@/components/modules/setup/rank/List';

/** Principal setup **/
import PrincipalSetup from "@/views/setup/com/PrincipalSetup";
import PrincipalList from '@/components/modules/setup/principal/List';

/** Flag setup **/
import FlagSetup from "@/views/setup/com/FlagSetup";
import FlagList from '@/components/modules/setup/flag/List';

/** Country setup **/
import CountrySetup from "@/views/setup/com/CountrySetup";
import CountryList from '@/components/modules/setup/country/List';

/** Clinic setup **/
import ClinicSetup from "@/views/setup/com/ClinicSetup";
import ClinicList from '@/components/modules/setup/clinic/List';

/** Trade Route setup **/
import TradeRouteSetup from "@/views/setup/com/TradeRouteSetup";
import TradeRouteList from '@/components/modules/setup/trade_route/List';

/** Owner setup **/
import OwnerSetup from "@/views/setup/com/OwnerSetup";
import OwnerList from '@/components/modules/setup/owner/List';

/** Position setup **/
import EmployeePositionSetup from "@/views/setup/com/EmployeePositionSetup";
import EmployeePositionList from '@/components/modules/setup/employee_position/List';


/** Religion setup **/
import ReligionSetup from "@/views/setup/com/ReligionSetup";
import ReligionList from '@/components/modules/setup/religion/List';

/** Document Category setup **/
import DocumentCategorySetup from "@/views/setup/com/DocumentCategorySetup";
import DocumentCategoryList from '@/components/modules/setup/document_category/List';

/** Port setup **/
import PortSetup from "@/views/setup/com/PortSetup";
import PortList from '@/components/modules/setup/port/List';

/** Union setup **/
import UnionSetup from "@/views/setup/com/UnionSetup";
import UnionList from '@/components/modules/setup/union/List';

/** Gear Supplier setup **/
import GearSupplierSetup from "@/views/setup/com/GearSupplierSetup";
import GearSupplierList from '@/components/modules/setup/gear_supplier/List';

/** Gear setup **/
import GearSetup from "@/views/setup/com/GearSetup";
import GearList from '@/components/modules/setup/gear/List';
import GearSupplierRateList from '@/components/modules/setup/gear_supplier/GearSupplierRateList';

/** Gear Complement Setup **/
import GearComplementSetup from "@/views/setup/com/GearComplementSetup";
import GearComplementList from '@/components/modules/setup/gear_complement/List';

/** Checklist Setup **/
import ChecklistCategorySetup from "@/views/setup/com/CrewChecklisDetailstCategorySetup";
import ChecklistCategoryList from '@/components/modules/setup/checklist/crew_checklist_category/List';

import ChecklistSignatorySetup from "@/views/setup/com/CrewChecklistSignatorySetup";
import ChecklistSignatoryList from '@/components/modules/setup/checklist/crew_checklist_signatory/List';

import ChecklistSignatoryTypeSetup from "@/views/setup/com/CrewChecklistSignatoryTypeSetup";
import ChecklistSignatoryTypeList from '@/components/modules/setup/checklist/crew_checklist_signatory_type/List';


/** Report Setup **/
import ReportSetup from "@/views/report/Report";
import ReportList from "@/components/modules/report/ReportList";

/** Report > Operation **/
import GearsPRPOList from "@/components/modules/report/operation/GearsPRPOList";
import POEAdocumentList from "@/components/modules/report/operation/POEAdocumentList";

/** Report > Crew > CrewList **/
import MasterlistDocsFormatList from "@/components/modules/report/crew/crewlist/MasterlistDocsFormatList";
import MasterlistCertsFormatList from "@/components/modules/report/crew/crewlist/MasterlistCertsFormatList";
import OwnerFormatList from "@/components/modules/report/crew/crewlist/OwnerFormatList";
import MasterlistShoeiFormatList from "@/components/modules/report/crew/crewlist/MasterlistShoeiFormatList.vue";

/** Report > Crew > Manpowerpool **/
import StandbyOnboardList from "@/components/modules/report/crew/manpowerpool/StandbyOnboardList";

/** Report > Crew > CrewList **/
import ScholarMonitoringList from "@/components/modules/report/crew/crewlist/ScholarMonitoringList.vue";

/** Report > Crew > CrewList **/
import OnboardSeniorOfficerList from "@/components/modules/report/crew/crewlist/OnboardSeniorOfficerList.vue";

/** Report > Crew > Manpowerpool **/
import ManPowerStandbyOnboard from "@/components/modules/report/crew/manpowerpool/ManPowerStandbyOnboard";
import HighestGRT from "@/components/modules/report/crew/manpowerpool/HighestGRT.vue";
import CrewPerPrincipal from "@/components/modules/report/crew/manpowerpool/CrewPerPrincipal.vue";
import CovidVaccineReport from "@/components/modules/report/crew/manpowerpool/CovidVaccineReport.vue";
import CrewAgeRange from "@/components/modules/report/crew/manpowerpool/CrewAgeRange.vue";
import InactiveList from "@/components/modules/report/crew/manpowerpool/InactiveList.vue";
import MedicalIssueReport from "@/components/modules/report/crew/manpowerpool/MedicalIssueReport.vue";
import CrewPoolSeaServiceLists from "@/components/modules/report/crew/manpowerpool/CrewPoolSeaServiceLists";
import CrewOnBoard from "@/components/modules/report/crew/manpowerpool/CrewOnBoard";

/** Report > Crew > Promotion **/
import CrewPromotionYearly from "@/components/modules/report/crew/promotion/CrewPromotionYearly";

/** Report > Crew > Promotion **/
import CrewPromotionPerRank from "@/components/modules/report/crew/promotion/CrewPromotionPerRank";

/** Report > Crew > Retention **/
import StandbyRatio from "@/components/modules/report/crew/retention/StandbyRatio.vue";
import StandbyNotOverAge from "@/components/modules/report/crew/retention/StandbyNotOverAge.vue";
import PromotableCrewList from "@/components/modules/report/crew/retention/PromotableCrewList.vue";

/** Report > Crew > 3-Month Plan **/
import ThreeMonthPlan from "@/components/modules/report/crew/ThreeMonthPlan/ThreeMonthPlan";
import ThreeMonthPlanPrint from "@/components/modules/report/crew/ThreeMonthPlan/ThreeMonthPlanPrint";
import ThreeMonthPlanSummary from "@/components/modules/report/crew/ThreeMonthPlan/ThreeMonthPlanSummary";

/** Report > Crew > Principal Format **/
import KeiyoFormat from "@/components/modules/report/crew/PrincipalFormat/Keiyo/KeiyoFormat";
import KeiyoFormatPrint from "@/components/modules/report/crew/PrincipalFormat/Keiyo/KeiyoFormatPrint";

/** Vessel Classification setup **/
import VesselClassificationSetup from "@/views/setup/com/VesselClassificationSetup";
import VesselClassificationList from '@/components/modules/setup/vessel_classification/List';

/** Vessel Engine Type setup **/
import VesselEngineTypeSetup from "@/views/setup/com/VesselEngineTypeSetup";
import VesselEngineTypeList from '@/components/modules/setup/vessel_engine_type/List';

import CrewingAdHoc from '@/components/modules/crewchange/CrewingAdHoc.vue'

/** Report > Crew **/
import SingONSignOffSummary from "@/components/modules/report/crew/SignOnSignOffSummary";

import AgentList from "@/components/modules/report/crew/AgentList";

/** Report Vessel Active Inactive List **/
import VesselActiveInactiveList from "@/components/modules/report/vessel/VesselActiveInactiveList";
import CrewChangePlanCCM from "@/components/modules/crewchange/CrewChangePlanCCM.vue";
import PreviewPrintCCMemo from "@/components/modules/crewchange/_sub/PreviewPrintCCMemo.vue";
import DocsCertMonitoring from "@/components/modules/report/crew/DocsCertMonitoring.vue";

/** Crewing Direct Promotion **/
import DirectPromotion from "@/components/modules/directpromotion/CrewingDirectPromotion.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },

  //dashboard
  {
    path: '/h',
    name: 'Home',
    component: Home,
    redirect: '/h/dashboard',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'caveman',
        name: 'FailOver',
        component: FailOverPage,
      },
      {
        path: 'auth-profile',
        name: 'AuthProfile',
        component: AuthProfile,

      },

      /** dashboard **/
      {
        path: 'dashboard',
        name: 'Dashboard1',
        component: Dashboard1,
      },
      {
        path: 'onboard-doc-certs-expiry',
        name: 'DashboardOnboardDocCertsExpiry',
        component: DashboardOnboardDocCertsExpiry,
      },
      {
        path: 'crew-pool-report-per-rank-list',
        name: 'DashboardCrewPoolReportPerRankList',
        component: DashboardCrewPoolReportPerRankList,
      },
      // for printing three month plan using new popup window
      {
        path: 'three-month-plan/print',
        name: 'ThreeMonthPlanPrint',
        component: ThreeMonthPlanPrint,
      },
      // to view the three month plan summary report using new popup window
      {
        path: 'three-month-plan-summary',
        name: 'ThreeMonthPlanSummary',
        component: ThreeMonthPlanSummary,
      },
      // to view the printing page of keiyo format in new popup window
      {
        path: 'keiyo-format-print',
        name: 'KeiyoFormatPrint',
        component: KeiyoFormatPrint,
      },
      /** \ **/
      {
        path: 'crewing',
        name: 'Crewing',
        component: Crewing,
        children: [
          {
            path: 'crew-change',
            name: 'CrewChangePlan',
            component: CrewChangePlan,
            props: true,
            //todo put the details here.
          },
          {
            path: 'ccm',
            name: 'CrewChangeManipulation',
            component: CrewChangeManipulation,
            props: true,
            children: [
              {
                path: 'checklist',
                name: 'CCSubChecklist',
                component: Checklist,
              },

              //todo pre departure checklist
              //todo crew detail summary
            ],
          },

          //todo crew compliment
          {
            path: 'tracking',
            name: 'ApplicantTracking',
            component: ApplicantTracking,
            redirect: 'tracking/applicants',
            children: [
              //application tracking
              {
                path: 'applicants',
                name: 'ApplicantList',
                component: ApplicantList,
              },
              {
                path: 'applicant/:id',
                name: 'ApplicantDetail',
                component: ApplicantDetail,
              },
            ]
          },
          {
            path: 'document-checklist',
            name: 'DocumentChecklist',
            component: DocumentChecklist,
            props: true,
          },
          {
            path: 'crewing-ad-hoc',
            name: 'CrewingAdHoc',
            component: CrewingAdHoc,
            props: true,
          },
          {
            path: 'direct-promotion',
            name: 'DirectPromotion',
            component: DirectPromotion,
            props: true,
          },
          {
            path: 'document-checklist/crew/:crewId',
            name: 'SubPreDepartureChecklist',
            component: SubPreDepartureChecklist,
            props: (route) => {
              const crewId = Number.parseInt(route.params.crewId, 10);
              if (Number.isNaN(crewId)) {
                return 0;
              }
              return {crewId};
            },
          }
        ],
      },
      {
        path: 'dashboard/crew-change/:batchId/:ccmNumber',
        name: 'DashboardCrewChangePlanCCM',
        component: CrewChangePlanCCM,
        props: (route) => {
          const batchId = Number.parseInt(route.params.batchId, 10);
          const ccmNumber = route.params.ccmNumber;
          return {batchId, ccmNumber};
        },
      },
      {
        path: 'crewing/crew-change/:batchId/:ccmNumber',
        name: 'CrewChangePlanCCM',
        component: CrewChangePlanCCM,
        props: (route) => {
          const batchId = Number.parseInt(route.params.batchId, 10);
          const ccmNumber = route.params.ccmNumber;
          return {batchId, ccmNumber};
        },
      },
      {
        path: 'crewing/crew-change/print-ccm/:vesselId/:batchId',
        name: 'PreviewPrintCCMemo',
        component: PreviewPrintCCMemo,
        props: (route) => {
          const batchId = Number.parseInt(route.params.batchId, 10);
          const vesselId = Number.parseInt(route.params.vesselId, 10);
          return {batchId, vesselId};
        },
      },

      /** masterlist **/
      {
        path: 'masterlist',
        name: 'MasterList',
        component: MasterList,
        redirect: 'masterlist/crew',
        children:
          [
            {
              path: 'crew',
              name: 'CrewMasterList',
              component: CrewMasterList,
              redirect: 'crew/list',
              children: [
                {
                  path: 'list',
                  name: 'CrewList',
                  component: CrewList,
                },
                {
                  path: 'create',
                  name: 'CreateCrew',
                  component: CreateCrew,
                },
              ]
            },
            {
              path: 'vessel',
              name: 'VesselMasterList',
              redirect: 'vessel/list',
              component: VesselMasterList,
              children: [
                {
                  path: 'list',
                  name: 'VesselList',
                  component: VesseList,
                }
              ]
            }
          ]
      },

      /** view vessel **/
      {
        path: 'vessel/:vesselId',
        name: 'ViewVessel',
        component: ViewVessel,
        props: (route) => {
          const vesselId = Number.parseInt(route.params.vesselId, 10);
          if (Number.isNaN(vesselId)) {
            return 0;
          }
          return {vesselId};
        },
      },

      /** crew profile **/
      {
        path: 'profile/:crewId',
        name: 'CrewProfile',
        component: CrewProfile,
        redirect: 'profile/:crewId/all-info',
        props: (route) => {
          const crewId = Number.parseInt(route.params.crewId, 10);
          if (Number.isNaN(crewId)) {
            return 0;
          }
          return {crewId};
        },
        children: [
          {
            path: 'all-info',
            name: 'AllInfo',
            component: AllInfo,
            redirect: 'all-info/personal',
            children: [
              {
                path: 'personal',
                name: 'CrewPersonal',
                component: CrewPersonal,
                props: true,
              },
              {
                path: 'contact',
                name: 'CrewAddress&Contact',
                component: CrewAddressAndContact,
                props: true,
                children: [
                  //TODO
                ],
              },
              {
                path: 'education',
                name: 'CrewEducation',
                component: CrewEducation,
                props: true,
              },
              {
                path: 'family',
                name: 'CrewFamily',
                component: CrewFamily,
                props: true,
              },
              {
                path: 'application-details',
                name: 'CrewApplicationDetails',
                component: CrewApplicationDetails
              },
              {
                path: 'referral',
                name: 'CrewReferral',
                component: CrewReferral,
                props: true,
              },
            ],
          },
          {
            path: 'sea-service',
            name: 'CrewSeaService',
            component: CrewSeaService,
          },
          {
            path: 'crew-document',
            name: 'CrewDocument',
            component:
            CrewDocument,
            props: true,
            children: [
              {
                path: 'vaccine',
                name: 'CrewCovidVaccine',
                component: CrewCovidVaccine,
              },
            ],
          },
          {
            path: 'crew-medical',
            name: 'CrewMedical',
            component: CrewMedical,
          },
          {
            path: 'crew-scan-checklist',
            name: 'CrewScannedChecklist',
            component: CrewScannedChecklist,
          },
          {
            path: 'crew-comments',
            name: 'CrewComment',
            component: CrewComment,
          },
          {
            path: 'crew-evaluation',
            name: 'CrewEvaluation',
            component: CrewEvaluation,
          },

          /* todo CrewOutSideService  */
          {
            path: 'outside-searvice',
            name: 'CrewOutSideService',
            component: CrewOutSideService
          },
          {
            path: 'crew-withdrawal',
            name: 'CrewWithdrawal',
            component: CrewWithdrawal,
          }
        ],
      },

      /** setup routes **/
      {
        path: 'setup',
        name: 'Setup',
        component: Setup,
        children: [
          {
            path: 'compliment',
            name: 'Compliment',
            component: Compliment,
            props: true,
            children: [
              {
                path: 'crew-compliment',
                name: 'CrewCompliment',
                props: true,
                component: CrewCompliment,
              }
            ]
          },

          //crew setup
          {
            path: 'employee',
            name: 'EmployeeSetup',
            component: EmployeeSetup,
            redirect: 'employee/list',
            children: [
              {
                path: 'list',
                name: 'EmployeeList',
                component: EmployeeList,
              },
              {
                path: ':employeeId/view',
                name: 'ViewEmployee',
                component: ViewEmployee,
                props: (route) => {
                  const employeeId = Number.parseInt(route.params.employeeId, 10);
                  if (Number.isNaN(employeeId)) {
                    return 0;
                  }
                  return {employeeId};
                },
              },
            ],
          },
          {
            path: 'school',
            name: 'SchoolSetup',
            component: SchoolSetup,
            redirect: 'school/list',
            children: [
              {
                path: 'list',
                name: 'SchoolList',
                component: SchoolList,
              },
            ],
          },
          {
            path: 'course',
            name: 'CourseSetup',
            component: CourseSetup,
            redirect: 'course/list',
            children: [
              {
                path: 'list',
                name: 'CourseList',
                component: CourseList,
              },
            ],
          },
          {
            path: 'document',
            name: 'DocumentSetup',
            component: DocumentSetup,
            redirect: 'document/list',
            children: [
              {
                path: 'list',
                name: 'DocumentList',
                component: DocumentList,
              },
              {
                path: ':documentId/pre-req',
                name: 'DocPreRequisite',
                component: DocPreRequisite,
                props: (route) => {
                  const documentId = Number.parseInt(route.params.documentId, 10);
                  if (Number.isNaN(documentId)) {
                    return 0;
                  }
                  return {documentId};
                },
              },
              {
                path: ':documentId/view',
                name: 'DocumentView',
                component: DocumentView,
                props: (route) => {
                  const documentId = Number.parseInt(route.params.documentId, 10);
                  if (Number.isNaN(documentId)) {
                    return 0;
                  }
                  return {documentId};
                },
              },
            ],
          },
          {
            path: 'rank',
            name: 'RankSetup',
            component: RankSetup,
            redirect: 'rank/list',
            children: [
              {
                path: 'list',
                name: 'RankList',
                component: RankList,
              }
            ]
          },
          {
            path: 'principal',
            name: 'PrincipalSetup',
            component: PrincipalSetup,
            redirect: 'principal/list',
            children: [
              {
                path: 'list',
                name: 'PrincipalList',
                component: PrincipalList,
              }
            ]
          },
          {
            path: 'flag',
            name: 'FlagSetup',
            component: FlagSetup,
            redirect: 'flag/list',
            children: [
              {
                path: 'list',
                name: 'FlagList',
                component: FlagList,
              }
            ]
          },
          {
            path: 'country',
            name: 'CountrySetup',
            component: CountrySetup,
            redirect: 'country/list',
            children: [
              {
                path: 'list',
                name: 'CountryList',
                component: CountryList,
              }
            ]
          },
          {
            path: 'clinic',
            name: 'ClinicSetup',
            component: ClinicSetup,
            redirect: 'clinic/list',
            children: [
              {
                path: 'list',
                name: 'ClinicList',
                component: ClinicList,
              }
            ]
          },
          {
            path: 'trade-route',
            name: 'TradeRouteSetup',
            component: TradeRouteSetup,
            redirect: 'trade-route/list',
            children: [
              {
                path: 'list',
                name: 'TradeRouteList',
                component: TradeRouteList,
              }
            ]
          },
          {
            path: 'owner',
            name: 'OwnerSetup',
            component: OwnerSetup,
            redirect: 'owner/list',
            children: [
              {
                path: 'list',
                name: 'OwnerList',
                component: OwnerList,
              }
            ]
          },
          {
            path: 'employee-position',
            name: 'EmployeePositionSetup',
            component: EmployeePositionSetup,
            redirect: 'employee-position/list',
            children: [
              {
                path: 'list',
                name: 'EmployeePositionList',
                component: EmployeePositionList,
              }
            ]
          },
          {
            path: 'religion',
            name: 'ReligionSetup',
            component: ReligionSetup,
            redirect: 'religion/list',
            children: [
              {
                path: 'list',
                name: 'ReligionList',
                component: ReligionList,
              }
            ]
          },
          {
            path: 'document-category',
            name: 'DocumentCategorySetup',
            component: DocumentCategorySetup,
            redirect: 'document-category/list',
            children: [
              {
                path: 'list',
                name: 'DocumentCategoryList',
                component: DocumentCategoryList,
              }
            ]
          },
          {
            path: 'port',
            name: 'PortSetup',
            component: PortSetup,
            redirect: 'port/list',
            children: [
              {
                path: 'list',
                name: 'PortList',
                component: PortList,
              }
            ]
          },
          {
            path: 'union',
            name: 'UnionSetup',
            component: UnionSetup,
            redirect: 'union/list',
            children: [
              {
                path: 'list',
                name: 'UnionList',
                component: UnionList,
              }
            ]
          },
          {
            path: 'gear-supplier',
            name: 'GearSupplierSetup',
            component: GearSupplierSetup,
            redirect: 'gear-supplier/list',
            children: [
              {
                path: 'list',
                name: 'GearSupplierList',
                component: GearSupplierList,
              },
              {
                path: ':gearSupplierId/gear-rate',
                name: 'GearSupplierRateList',
                component: GearSupplierRateList,
                props: (route) => {
                  const gearSupplierId = Number.parseInt(route.params.gearSupplierId, 10);
                  if (Number.isNaN(gearSupplierId)) {
                    return 0;
                  }
                  return {gearSupplierId};
                },
              },
            ]
          },
          {
            path: 'gear',
            name: 'GearSetup',
            component: GearSetup,
            redirect: 'gear/list',
            children: [
              {
                path: 'list',
                name: 'GearList',
                component: GearList,
              }
            ]
          },
          {
            path: 'gear-complement',
            name: 'GearComplementSetup',
            component: GearComplementSetup,
            redirect: 'gear-complement/list',
            children: [
              {
                path: 'list',
                name: 'GearComplementList',
                component: GearComplementList,
              }
            ]
          },
          {
            path: 'checklist-category',
            name: 'ChecklistCategorySetup',
            component: ChecklistCategorySetup,
            redirect: 'checklist-category/list',
            children: [
              {
                path: 'list',
                name: 'ChecklistCategoryList',
                component: ChecklistCategoryList,
              }
            ]
          },
          {
            path: 'checklist-signatory',
            name: 'ChecklistSignatorySetup',
            component: ChecklistSignatorySetup,
            redirect: 'checklist-signatory/list',
            children: [
              {
                path: 'list',
                name: 'ChecklistSignatoryList',
                component: ChecklistSignatoryList,
              }
            ]
          },
          {
            path: 'checklist-signatory-type',
            name: 'ChecklistSignatoryTypeSetup',
            component: ChecklistSignatoryTypeSetup,
            redirect: 'checklist-signatory-type/list',
            children: [
              {
                path: 'list',
                name: 'ChecklistSignatoryTypeList',
                component: ChecklistSignatoryTypeList,
              }
            ]
          },
          {
            path: 'vessel_classification',
            name: 'VesselClassificationSetup',
            component: VesselClassificationSetup,
            redirect: 'vessel_classification/list',
            children: [
              {
                path: 'list',
                name: 'VesselClassificationList',
                component: VesselClassificationList,
              }
            ]
          },
          {
            path: 'vessel_engine_type',
            name: 'VesselEngineTypeSetup',
            component: VesselEngineTypeSetup,
            redirect: 'vessel_engine_type/list',
            children: [
              {
                path: 'list',
                name: 'VesselEngineTypeList',
                component: VesselEngineTypeList,
              }
            ]
          },
        ],
      },
      {
        path: 'report',
        name: 'ReportList',
        component: ReportList,
        // redirect: 'report/masterlist-docs-format-list',
        children: [
          // CREW
          {
            path: 'masterlist-docs-format-list',
            name: 'MasterlistDocsFormatList',
            component: MasterlistDocsFormatList,
          },
          {
            path: 'owner-format-list',
            name: 'OwnerFormatList',
            component: OwnerFormatList,
          },
          {
            path: 'masterlist-certs-format-list',
            name: 'MasterlistCertsFormatList',
            component: MasterlistCertsFormatList,
          },
          {
            path: 'masterlist-shoei-format-list',
            name: 'MasterlistShoeiFormatList',
            component: MasterlistShoeiFormatList,
          },
          {
            path: 'standby-onboard-list',
            name: 'StandbyOnboardList',
            component: StandbyOnboardList,
          },
          {
            path: 'scholar-monitoring-list',
            name: 'ScholarMonitoringList',
            component: ScholarMonitoringList,
          },

          {
            path: 'onboard-senior-officer-list',
            name: 'OnboardSeniorOfficerList',
            component: OnboardSeniorOfficerList,
          },

          // PRE DEPARTURE
          {
            path: 'gears-po',
            name: 'GearsPRPOList',
            component: GearsPRPOList,
          },
          {
            path: 'docs-cert-monitoring',
            name: 'DocsCertMonitoring',
            component: DocsCertMonitoring,
          },
          {
            path: 'poea-docs',
            name: 'POEAdocumentList',
            component: POEAdocumentList,
          },
          {
            path: 'sing-on-off-crew-summary',
            name: 'SingONSignOffSummary',
            component: SingONSignOffSummary,
          },
          {
            path: 'agent-list',
            name: 'AgentList',
            component: AgentList
          },
          {
            path: 'manpower-pool-standby-onboard',
            name: 'ManPowerStandbyOnboard',
            component: ManPowerStandbyOnboard,
          },
          {
            path: 'manpower-pool-highest-grt',
            name: 'HighestGRT',
            component: HighestGRT,
          },
          {
            path: 'manpower-pool-crew-per-principal',
            name: 'CrewPerPrincipal',
            component: CrewPerPrincipal,
          },
          {
            path: 'manpower-pool-covid-vaccine',
            name: 'CovidVaccineReport',
            component: CovidVaccineReport,
          },
          {
            path: 'manpower-pool-age-range',
            name: 'CrewAgeRange',
            component: CrewAgeRange,
          },
          {
            path: 'manpower-pool-inactive',
            name: 'InactiveList',
            component: InactiveList,
          },
          {
            path: 'manpower-pool-medical-issue',
            name: 'MedicalIssueReport',
            component: MedicalIssueReport,
          },
          {
            path: 'manpower-pool-crew-pool-sea-service-lists',
            name: 'CrewPoolSeaServiceLists',
            component: CrewPoolSeaServiceLists,
          },
          {
            path: 'manpower-pool-crew-on-board',
            name: 'CrewOnBoard',
            component: CrewOnBoard,
          },
          {
            path: 'crew-promotion-yearly',
            name: 'CrewPromotionYearly',
            component: CrewPromotionYearly,
          },
          {
            path: 'crew-promotion-per-rank',
            name: 'CrewPromotionPerRank',
            component: CrewPromotionPerRank,
          },
          {
            path: 'three-month-plan',
            name: 'ThreeMonthPlan',
            component: ThreeMonthPlan,
          },
          {
            path: 'keiyo-format',
            name: 'KeiyoFormat',
            component: KeiyoFormat,
          },
          {
            path: 'standby-ratio',
            name: 'StandbyRatio',
            component: StandbyRatio,
          },
          {
            path: 'standby-not-overage',
            name: 'StandbyNotOverAge',
            component: StandbyNotOverAge,
          },
          {
            path: 'promotable-crew-list',
            name: 'PromotableCrewList',
            component: PromotableCrewList,
          },
          {
            path: 'vessel-lists',
            name: 'VesselActiveInactiveList',
            component: VesselActiveInactiveList,
          },
        ],
      },
      {
        path: 'vessel-lists-view',
        name: 'VesselActiveInactiveListView',
        component: VesselActiveInactiveList,
      },
    ],
  },
  {
    path: '/change-pass',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/testChunk',
    name: 'TestChunk',
    component: TestChunkUpload,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

// adding meta for the authenticated routes
router.beforeEach(function (to, from, next) {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //AUTH
    if (localStorage.getItem(TOKEN.PMC) != null) {
      if (!hasQueryParams(to) && hasQueryParams(from)) {
        next({name: to.name, query: from.query});
      } else {
        next()
      }
    } else {
      next({name: 'Login'});
    }

  } else {
    if (!localStorage.getItem(TOKEN.PMC)) {
      next();
    } else {
      next({name: 'Home'});
    }
  }
});

export default router;
