import {FileService} from "@/service/file.service";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";

const state = {

  biodataDetails: [],


};


const mutations = {

  SET_BIODATA_DETAILS(state, params) {
    state.biodataDetails = params;
  },

  reset_biodataDetails(){
    state.biodataDetails = [];
  },

};


const actions = {

  async generateCrewBioData({commit}, params) {
    return await axios.post(PMC.GENERATE_CREW_BIO_DATA, params).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate Crew Biodata')
      return false
    });
  },


  // getBiodataCrewInfo({commit}, params) {
  //   return axios.get(PMC.BIODATA_CREW_INFO, params).then((response) => {
  //     // commit('SET_CREW_INFO', response.data.crewInfo);
  //     // commit('SET_CREW_GEN_INFO', response.data.crewGenInfo);
  //     // commit('SET_CREW_CONTRIB', response.data.crewContrib);
  //     return true;
  //   }).catch((error) => {
  //     UtilityService.failProgressBar();
  //     UtilityService.generateResponseMessage(error, 'Biodata Crew Information');
  //     return false;
  //   });
  // },


  getBiodataDetails({commit}, param) {
    return axios.get(`${PMC.BIODATA_GET_DETAILS}/${param}`).then((r) => {
      commit('SET_BIODATA_DETAILS', r.data);
      console.log(r.data.data)
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'BIODATA GET DETAILS');
      return false;
    });
  },

  resetbiodataDetails({commit}){
    commit('reset_biodataDetails');
  },

};


const getters = {

  biodataDetails: state => UtilityService.capitalizeProperties(state.biodataDetails),

};


/** export **/
export default {state, actions, mutations, getters};
