<template>
   <div class="container bg-white" style="height: 80vh;">
     <h4 class="my-4">Quick Links</h4>
<!--     {{ $MyGlobalFunction }}-->
<!--     <div class="row">-->
<!--       <div class="col-4 font-weight-bold">Reference No.</div>-->
<!--       <div class="col-3">PMC-022621-MGT-22104</div>-->
<!--       <div class="col-3">-->
<!--         <button class="btn pmc-btn-prime btn-sm mb-2" style="width: 120px;">-->
<!--           <i class="fas fa-paper-plane pr-1"></i>-->
<!--           Send Email</button>-->
<!--       </div>-->
<!--     </div>-->

<!--     <div class="row">-->
<!--       <div class="col-4 font-weight-bold">CCM No.</div>-->
<!--       <div class="col-3">-->
<!--         <input type="text" class="form-control form-control-sm" aria-label="Search CCM Number" placeholder="input ccm no.">-->
<!--       </div>-->

<!--       <div class="col-3">-->
<!--         <button class="btn pmc-btn-prime btn-sm mb-2" style="width: 120px;">-->
<!--           <i class="fas fa-eye pr-1"></i>-->
<!--           View CCM-->
<!--         </button>-->
<!--       </div>-->
<!--     </div>-->


<!--     <div class="row">-->
<!--       <div class="col-4 font-weight-bold">Crew Change Plan View</div>-->
<!--       <div class="col-3">-->
<!--         <select class="custom-select custom-select-sm"-->
<!--                 v-model="selectedVessel"-->
<!--                 @change="onChangeVessel"-->
<!--         >-->
<!--           <option :value="null">&#45;&#45;SELECT VESSEL&#45;&#45;</option>-->
<!--           <option :value="vessel.id" v-for="(vessel,index) in objVessels" :key="vessel.id">-->
<!--             {{ vessel.name }}-->
<!--           </option>-->
<!--         </select>-->
<!--       </div>-->
<!--       <div class="col-3">-->
<!--         <button class="btn pmc-btn-prime btn-sm mb-2"-->
<!--                 style="width: 120px;"-->
<!--                 data-toggle="modal"-->
<!--                 data-target="#ccm-plan-modal"-->
<!--                 data-backdrop="static"-->
<!--                 data-keyboard="false"-->
<!--                 :disabled="!(crewChangePlans ? crewChangePlans.length : 0)"-->
<!--         >-->
<!--           <i class="fas fa-eye pr-1"></i>-->
<!--           View Plan-->
<!--         </button>-->
<!--       </div>-->
<!--     </div>-->

<!--     <div class="row">-->
<!--       <div class="col-4 font-weight-bold">Total Crew</div>-->
<!--       <div class="col-3">1108</div>-->
<!--       <div class="col-3">-->
<!--         <button class="btn pmc-btn-prime btn-sm mb-2" style="width: 120px;">-->
<!--           <i class="fas fa-eye pr-1"></i>-->
<!--           View Crews-->
<!--         </button>-->
<!--       </div>-->
<!--     </div>-->


<!--     style="display: ;"-->
<!--     <div class="row">-->
     <!--       <div class="col-4 font-weight-bold">Total Onboard Docs & Certs Expiry</div>-->
     <!--       <div class="col-3">As of {{ formattedDate }} is {{ onboardCrewsDocCertsCount }}</div>-->
     <!--       <div class="col-3">-->
     <!--         <button class="btn pmc-btn-prime btn-sm mb-2"-->
     <!--                 style="width: 120px;"-->
     <!--                 @click="openInNewWindow(baseURL + dashboardRoute)"-->
     <!--         >-->
     <!--           <i class="fas fa-eye pr-1"></i>-->
     <!--           View Expiry-->
     <!--         </button>-->
     <!--       </div>-->
     <!--     </div>-->

     <div class="col d-flex flex-wrap justify-content-center">
       <div class="card">
         <div class="card-header">Crew Change Plan View</div>
         <div class="card-body">
           <div class="d-flex">
            <div class="input-group-sm mr-auto" style="width: 193px;">
              <input
                v-model="searchCcmNo"
                v-on:keyup.enter="getCcmBatchNo"
                class="form-control"
                type="text">
            </div>
             <button
               @click="getCcmBatchNo"
               class="btn btn-sm btn-primary text"
               style="width: 82px;">
               View CCM
             </button>
           </div>

           <hr />
           <div class="d-flex">
             <select class="custom-select custom-select-sm mr-2"
                     v-model="selectedVessel"
                     @change="onChangeVessel"
             >
               <option :value="null">--SELECT VESSEL--</option>
               <option :value="vessel.id" v-for="(vessel,index) in objVessels" :key="vessel.id">
                 {{ vessel.name }}
               </option>
             </select>
             <button class="btn btn-sm btn-primary text"
                     style="width: 120px;"
                     data-toggle="modal"
                     data-target="#ccm-plan-modal"
                     data-backdrop="static"
                     data-keyboard="false"
                     :disabled="!selectedVessel"
             >
               View Plan
             </button>
           </div>
         </div>
       </div>

       <div class="card ml-5">
         <div class="card-header">Total Onboard Docs & Certs Expiry</div>
         <div class="card-body">
           <p class="card-title">As of {{ formattedDate }} is {{ onboardCrewsDocCertsCount }}</p>
           <button class="btn pmc-btn-prime btn-sm mb-2"
                   style="width: 120px;"
                   @click="openInNewWindow(baseURL + liveServerPrefix + dashboardRoute)"
           >
             <i class="fas fa-eye pr-1"></i>
             View Expiry
           </button>

         </div>
       </div>

       <div class="card ml-5">
         <div class="card-header">Crew Pool Report Per Rank List</div>
         <div class="card-body">
           <br />
           <button class="btn pmc-btn-prime btn-sm mb-2"
                   style="width: 120px;"
                   @click="openInNewWindow(baseURL + liveServerPrefix + dashboardRankList)"
           >
             <i class="fas fa-eye pr-1"></i>
             View Rank List
           </button>
         </div>
       </div>

     </div>

     <div class="modal fade" id="ccm-plan-modal" tabindex="-10" role="dialog"
          aria-labelledby="ccMemoModal"
          aria-hidden="true">
       <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-size" role="document">
         <div class="modal-content" style="height: 100%;">
           <div class="modal-header">
<!--             <h4 class="modal-title" id="cc-modal-header-label">Crew Change Plan</h4>-->


             <div class="form-inline">
               <div class="form-group mr-5">
                 <span class="font-weight-bold lead" style="font-size: 20px;">Crew Change Plan</span>
               </div>
               <div class="form-group ">
                 <button type="button" class="btn btn-xs pmc-btn-tertia"
                         @click="loadCrewChangePlan"
                 >
                   <font-awesome-icon icon="plus"/>
                   Print
                 </button>
               </div>
             </div>

             <button type="button" class="border-0" data-dismiss="modal" aria-label="Close">
               <!--<span aria-hidden="true">&times;</span>-->
               <span aria-hidden="true" class="text-danger">
                <font-awesome-icon icon="times"/>
              </span>
               Close
             </button>
           </div>
           <div class="modal-body">
             <!--  :load-data="loadCcMemoData" -->
             <dashboard-crew-change-plan-view
               :current-vessel-data="currentVesselData"
               :crew-change-plans="crewChangePlans"
               :vessel-remarks="vesselRemarks"
               :loading="isReloading"
             />

           </div>
           <!--          <div class="modal-footer">
             <button type="button" class="btn btn-secondary" @click="cancelCrewChangeMemo">
               Cancel
             </button>
           </div>-->
         </div>
       </div>
     </div>


   </div>
</template>

<script>
import {global_variables_config} from "@/config/global-variable.config";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";

export default {
  name: 'Dashboard1',
  mixins: [AppMixins, AlertMixins],

  data () {
    return {
      baseURL: '',
      dashboardRoute: '/h/onboard-doc-certs-expiry?view=onboard-doc-certs-expiry',
      liveServerPrefix: global_variables_config.liveServerPrefix,
      dashboardRankList: '/h/crew-pool-report-per-rank-list?view=crew-pool-report-per-rank-list', //View Rank List
      selectedVessel: null,
      currentVesselData: null,
      currentDate: new Date(),
      crewChangePlanFormParam: {
        selectedFilterOrder: 1,
        vesselId: null,
        vesselName: null,
      },
      searchCcmNo: null,
    }
  },

  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
      'set_crew_change_plans',
      'reset_selected_crew_change',
      'resetComplimentState',
      'resetNewCrewCompliment',

      // remarks
      'set_vessel_remarks',

      // generate crew change plan
      'generateCrewChangePlan',

      // onboard docs cert expiry
      'set_onboardDocsCertsExpiry',
    ]),

    onChangeVessel(e = null) {

      if(this.selectedVessel !== null) {
        this.reset_selected_crew_change()
        let vessel = this.objVessels.find((val, index) => {
          return val.id === parseInt(e.target.value, 10);
        })

        this.currentVesselData = vessel

        if (vessel != undefined) {
          this.crewChangePlanFormParam.vesselId = vessel.id;
        } else {
          this.crewChangePlanFormParam.vesselId = null;
        }

        this.set_crew_change_plans(this.crewChangePlanFormParam)
        this.set_vessel_remarks({vesselId: vessel.id});
      } else {

        return false;

      }

    },

    async loadCrewChangePlan() {
      this.crewChangePlanFormParam.vesselName = this.currentVesselData.name

      AlertService.loadingAlert('Please wait ...', 'Generating PDF')

      let ccmPlanFile = await this.generateCrewChangePlan(this.crewChangePlanFormParam);
      if (ccmPlanFile) {
        Swal.close();
        await this.pdfViewerMasterChecklist('File', ccmPlanFile);
      } else {
        Swal.close();
      }
    },

    async getCcmBatchNo() {
      const param = {
        "searchCcmNo": this.searchCcmNo,
      };
      return await axios.post(PMC.GET_BATCH_CCM_NO, param).then((r) => {
        const data = r.data[0];
        this.getBatchCcmNo(data.id, data.ccmNo, data.vesselId);
      }).catch((er) => {
        if(!er.response) AlertService.warningActionService("Cannot find CCM No.", "View CCM No");
        return false;
      })
    },

    getBatchCcmNo(id, ccmNo, vesselId) {
      if (vesselId !== null) {
        const routeData = this.$router.resolve({
          name: 'DashboardCrewChangePlanCCM',
          params: {
            batchId: `${id}`,
            ccmNumber: `${ccmNo}`,
          },
          query: {
            selectedVesselId: `${vesselId}`,
            view: 'dashboard-crew-change-plan'
          }
        });
        window.open(routeData.href, '_blank',"height="+((screen.availHeight / 5) * 4)+",width="+((screen.availWidth / 5) * 4));

      } else {
        AlertService.warningActionService("Vessel not found", "View CCM No");
      }
    }

  },

  computed: {
    ...mapGetters(
      [
        'objVessels',
        'crewChangePlans',
        'vesselRemarks',
        'onboardCrewsDocCertsCount',
        'isReloading',
      ]),

    formattedDate() {
      return this.currentDate.toLocaleString('default', {
        month: 'long',
        year: 'numeric'
      });
    }

  },

  mounted () {
  },

  created () {
    this.$MyGlobalFunction = global_variables_config
    this.getCrewComplimentPerVessel();
    this.baseURL = window.location.origin;
    this.set_onboardDocsCertsExpiry();
  },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },

  destroyed () {
    this.resetComplimentState();
    this.resetNewCrewCompliment();
  },

}
</script>

<style scoped>
.custom-modal-size {
  max-width: 80%;
  margin: 0 auto;
}
</style>

