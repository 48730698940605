<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Summary Report of Crew & Officer for COVID VACCINE</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">STATUS</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.status"
                                @change="selectReportType"
                                required>
                          <option value="all">All</option>
                          <option value="standby">Standby</option>
                          <option value="onboard">Onboard</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.rankId"
                                @change="selectReportType"
                                required>
                          <option :value="null">All</option>
                          <option v-for="(objRank, index) in objRanks" :value="objRank.id">{{ objRank.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">VESSEL</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.vesselId"
                                @change="selectReportType"
                                :disabled="reportParam.status === 'standby'"
                                required>
                          <option :value="null">All</option>
                          <option :value="vessel.id" v-for="(vessel,index) in objVessels">
                            {{ vessel.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">VACCINE BRAND</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                                v-model="reportParam.vaccineId"
                                @change="selectReportType"
                                :disabled="reportParam.noVaccine === true"
                                required>
                          <option :value="null">All</option>
                          <option :value="vaccine.id" v-for="(vaccine,index) in objCovidVaccineBrands">
                            {{ vaccine.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="row">
                  <div class=" text-left">
                    <div class="form-check">
                      <div class="checkbox-container mt-1">
                        <input type="checkbox" class="form-check-input" id="select-all" v-model="reportParam.selectAll"
                               :disabled="janssenSelected"
                               @change="selectAllChanged">
                        <label class="form-check-label" for="select-all">Select All</label><br>
                      </div>
                      <hr>
                      <div class="checkbox-container">
                        <input type="checkbox" class="form-check-input" id="full-vaccine"
                               v-model="reportParam.fullVaccine" :disabled="janssenSelected"
                               @change="checkboxChanged('fullVaccine')">
                        <label class="form-check-label" for="full-vaccine">Full Vaccine</label><br>
                      </div>
                      <div class="checkbox-container">
                        <input type="checkbox" class="form-check-input" id="inc-vaccine"
                               v-model="reportParam.incVaccine" :disabled="janssenSelected"
                               @change="checkboxChanged('incVaccine')">
                        <label class="form-check-label" for="inc-vaccine">Incomplete Vaccine</label><br>
                      </div>
                      <div class="checkbox-container">
                        <input type="checkbox" class="form-check-input" id="no-vaccine" v-model="reportParam.noVaccine"
                               :disabled="janssenSelected" @change="checkboxChanged('noVaccine')">
                        <label class="form-check-label" for="no-vaccine">No Vaccine</label><br>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="getCrewReportForCovidVaccine">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          @click="printReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
            <div class="row ml-2">
              <div class="">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <span class="col-form-label">
                        <label for="list-count" class="col-form-label">FILTER BY : <b class="pl-2"
                                                                                      style="font-size: 1.1em;">{{
                            filterBy
                          }}</b></label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Rank</th>
                    <th style="width: 10%;">Name</th>
                    <th>{{ reportParam.status === 'standby' ? "Ex-Vessel" : "Vessel" }}</th>
                    <th>Status</th>
                    <th style="width: 10%;">
                      Date Given<br>1st Dose
                    </th>
                    <th style="width: 10%;">
                      Date Given<br>2nd Dose
                    </th>
                    <th>Brand</th>
                    <th style="width: 15%;">Remarks</th>
                    <th>File</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td class="text-center align-middle pl-2"> {{ crew.rank_alias }}</td>

                          <td class="pl-2 text-center align-middle">
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.fullName }} </b></small>
                            </a>
                          </td>
                          <td class="text-center align-middle pl-2"> {{ crew.vessel }}</td>

                          <td class="text-center align-middle pl-2" style="width: 10%;"> {{ crew.seaServiceStatus }}
                          </td>
                          <td class="text-center align-middle pl-2"> {{ crew.firstDose }}</td>
                          <td class="text-center align-middle pl-2"> {{ crew.secondDose }}</td>
                          <td class="text-center align-middle pl-2"> {{ crew.vaccineBrand }}</td>
                          <td class="text-center align-middle pl-2"> {{ crew.remarks }}</td>
                          <td class="text-center align-middle pl-2">
                            <template v-if="crew.hasFile">
                                <img
                                     class="btn-img"
                                     title="Click to view remark attachment file"
                                     type="button" :src="pdfIcon"
                                     @click="viewPdfOnNewWindow(crew.docBlob)"
                                     style="height: 25px !important; width: 25px !important;" alt="PDF icon">
                            </template>
                            <template v-else>
                              {{ crew.docBlob }}
                            </template>
                          </td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {CommonMixins} from "@/mixins/common.mixins";
import PdfIcon from "@/assets/viewpdf.png";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import axios from "axios";
import {UtilityService} from "@/service/utility.service";
import {PMC} from "@/config/constants.config";
import Swal from "sweetalert2";
import {AlertService} from "@/service/alert.service";
import {FileService} from "@/service/file.service";

export default {
  name: "CovidVaccineReport",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      reportParam: {
        rankId: null,
        isPDF: false,
        rankName: null,
        vaccineId: null,
        vesselId: null,
        status: "all",
        selectAll: false,
        fullVaccine: false,
        incVaccine: false,
        noVaccine: false,
        filteredBy: null
      },
      hasData: false,
      ranks: null,
      rankSelected: null,
      vesselSelected: null,
      vaccines: null,
      vaccineSelected: null,
      filters: null,
      isLoading: false,
      crewData: null,
      pdfIcon: PdfIcon
    }

  },


  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
      'getVaccineBrandKeys',
    ]),

    selectReportType() {
      this.hasData = false;

      if (this.reportParam.status === 'standby') {
        this.reportParam.vesselId = null;
      }

      if (this.reportParam.vaccineId === 3) {
        this.reportParam.noVaccine = false;
        this.reportParam.fullVaccine = false;
        this.reportParam.incVaccine = false;
        this.reportParam.selectAll = false;

      }

    },

    async getCrewReportForCovidVaccine() {
      this.reportParam.isPDF = false;
      this.hasData = false;
      let params = this.reportParam;
      this.isLoading = true;
      return await axios.post(PMC.GET_COVID_VACCINE_REPORT, params).then(async r => {
        this.isLoading = false;
        if (Object.keys(r.data).length > 0) {
          this.hasData = true;
          this.crewData = r.data;
        } else {
          this.hasData = false;
          this.crewData = null;
        }
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate summary of crew for covid vaccine')
        return false
      });
    },

    async printCovidVaccineReport() {
      this.reportParam.filteredBy = this.filters;
      this.reportParam.isPDF = true;
      let params = this.reportParam;
      return await axios.post(PMC.GET_COVID_VACCINE_REPORT, params).then(async r => {
        this.isLoading = false;
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Covid Vaccine Summary')
        return false
      });
    },

    async printReport() {
      AlertService.loadingAlert('Please wait ...', 'Generating PDF')

      let pdfData = await this.printCovidVaccineReport();
      if (pdfData) {
        await this.pdfViewer('File', pdfData);
        Swal.close();
      } else {
        Swal.close();
      }
    },

    getVaccineName(vaccineId) {
      let vaccineName = '';
      const vaccineList = this.vaccines
      for (let vaccine in vaccineList) {
        if (parseInt(vaccineList[vaccine].id) === parseInt(vaccineId)) {
          vaccineName = vaccineList[vaccine].name;
        }
      }
      return vaccineName;
    },

    getRankName(rankId) {
      let rankName = '';
      const rankList = this.objRanks
      for (let c in rankList) {
        if (parseInt(rankList[c].id) === parseInt(rankId)) {
          rankName = rankList[c].name;
        }
      }
      return rankName;
    },

    getVesselName(vesselId) {
      let vesselName = '';
      const vesselList = this.objVessels
      for (let c in vesselList) {
        if (parseInt(vesselList[c].id) === parseInt(vesselId)) {
          vesselName = vesselList[c].name;
        }
      }
      return vesselName;
    },

    async getVaccines() {
      let params = {};
      return await axios.get(PMC.GET_COVID_VACCINE_BRAND_KEYS, params).then(async r => {
        this.vaccines = r.data;
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
        return false
      });
    },

    selectAllChanged() {
      this.reportParam.fullVaccine = this.reportParam.selectAll;
      this.reportParam.incVaccine = this.reportParam.selectAll;
      this.reportParam.noVaccine = this.reportParam.selectAll;

      if (this.reportParam.noVaccine === true)
        this.reportParam.vaccineId = null;
    },

    checkboxChanged(checkboxName) {
      if (!this.reportParam[checkboxName]) {
        this.reportParam.selectAll = false;
      }
      if (this.reportParam.noVaccine === true)
        this.reportParam.vaccineId = null;
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objVessels',
        'objRanks',
        'objCovidVaccineBrands'

      ]
    ),
    janssenSelected() {
      if (this.reportParam.vaccineId === 3)
        return true;
    },
    filterBy() {
      const filters = [];

      if (this.reportParam.status !== 'All') {
        filters.push(this.reportParam.status.toUpperCase());
      }

      if (this.reportParam.rankId) {
        // filters.push(this.getRankName(this.reportParam.rankId));
        filters.push("RANK");
      }

      if (this.reportParam.vesselId) {
        // filters.push(this.getVesselName(this.reportParam.vesselId));
        filters.push("VESSEL");
      }

      if (this.reportParam.vaccineId) {
        // filters.push(this.getVaccineName(this.reportParam.vaccineId).toUpperCase());
        filters.push("VACCINE");
      }
      const vaccineFilters = [];
      if (this.reportParam.fullVaccine) {
        vaccineFilters.push('Full Vaccine');
      }
      if (this.reportParam.incVaccine) {
        vaccineFilters.push('Incomplete Vaccine');
      }
      if (this.reportParam.noVaccine) {
        vaccineFilters.push('No Vaccine');
      }

      const nonVaccineFilters = filters.length > 0 ? filters.join(' / ') : '';

      const combinedVaccineFilters = vaccineFilters.length > 0 ? vaccineFilters.join(', ') : '';

      this.filters = [nonVaccineFilters, combinedVaccineFilters].filter(Boolean).join(' / ');

      return this.filters;
    },

  },

  /** mounted **/
  mounted() {
    this.getVaccines();
  },

  /** created **/
  async created() {
    this.getCrewComplimentPerVessel();
    this.getVaccineBrandKeys();
    this.$globalVariables = global_variables_config
  },


  beforeDestroy() {
  },
}
</script>

<style scoped>

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}

.checkbox-container:not(:first-child) {
  margin: 10px 0;
}

.form-check-label {
  margin-left: 10px;
}
</style>
