import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";


const state = {
  tradeRoute: {},
  tradeRoutes: [],
  newTradeRoute: {},

  tradeRouteFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  tradeRoutePagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_tradeRoutes(state, params) {
    state.tradeRoutes = params
  },

  update_tradeRoute (state, params) {
    let tradeRoute = state.tradeRoutes.find((val, index) => val.id === params.id)
    Object.assign(tradeRoute, params)
  },

  delete_tradeRoute (state, params) {
    let index = state.tradeRoutes.findIndex((val, index) => val.id === params)
    state.tradeRoutes.splice(index, 1)
  },

  create_tradeRoute (state) {
    state.tradeRoute = {};
  },

  null_tradeRoutes(state) {
    state.tradeRoutes = []
  },

  null_tradeRoute(state) {
    state.tradeRoute = {}
  },

  set_tradeRoute_pagination(state, params) {
    state.tradeRoutePagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {
  async set_tradeRoutes({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.TRADE_ROUTE, {params}).then((r) => {
      commit('set_tradeRoutes', r.data.data)
      commit('set_tradeRoute_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get trade route')
      return false
    })
  },

  async create_tradeRoute({commit}, params) {
    return axios.post(PMC.TRADE_ROUTE, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_tradeRoute', r.data);
      return true;

    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'Create trade route');
      return false;
    })
  },

  async update_tradeRoute({commit}, params) {
    return axios.put(`${PMC.TRADE_ROUTE}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_tradeRoute',r.data.updatedTradeRoute);
      return true;

    }).catch((error)=>{
      UtilityService.generateResponseMessage(error,'Update trade route');
      return false;

    });
  },

  async delete_tradeRoute ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Trade Route','question');
    if(await _del){
      return axios.delete(`${PMC.TRADE_ROUTE}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete trade route');
        commit('delete_tradeRoute',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.generateResponseMessage(er,"Delete trade route");
        return false;

      })
    }
  },

  reset_tradeRoute_pagination({commit}) {
    commit('set_principal_pagination')
  },

  null_tradeRoutes({commit}) {
    commit('null_tradeRoutes')
  },

  null_tradeRoute({commit}) {
    commit('null_tradeRoute')
  },
};


const getters = {
  tradeRoutes: state => UtilityService.capitalizeProperties(state.tradeRoutes),

  tradeRoutePagination: state => state.tradeRoutePagination,
  tradeRouteFormParam: state => state.tradeRouteFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
