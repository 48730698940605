

export const CommonMixins = {

  methods: {


    /**
     * Author: Bien Magno
     * notes: can be improved by adding validations and type safety configurations
     *
     */
    columnSort(sortByObject, sortByValue, isString, column, objectData) {

      /**
       * sortByObject (Object)
       * - Object format {}
       * - accepts object containing 'sortByValue'
       * ex. ccPlanSort: {
       *         sortByRank: '',
       *       },
       *
       *
       * sortByValue (String)
       * - String format ''
       * - values in sortByObject, containing columns ex. 'sortByRank', 'sortBySignOffCrew'
       * ex. ccPlanSort.sortByRank; sortByRank from ccPlanSort object
       *
       *
       * isString (Bool)
       * - Boolean format true|false
       * - if the value is string, not num, object or array.
       *
       *
       * column (String)
       * - String format ''
       * - value in objectData
       * ex. crewChangePlans[0].rankId; rankId is the column
       *
       *
       * objectData (Array)
       * - Array format []
       * - contains the data to be sorted
       *ex. crewChangePlans array
       *
       *
       * Optional: Implement sort-arrow component
       *
       *   <sort-arrow
       *       :sort-by-object="onboardDocumentExpirySort"
       *       :sort-by-value="'sortByCrew'"
       *       :object-data="onboardCrewsDocCerts"
       *     />
       *
       */

      const resetSortingState = (sort, exceptSortBy) => {
        for (const property in sort) {
          if (property !== exceptSortBy) {
            sort[property] = '';
          }
        }
      };

      resetSortingState(sortByObject, sortByValue);

      if (sortByObject[sortByValue] === 'desc') {
        sortByObject[sortByValue] = 'asc'; // set to ascending

        if (isString) {
          objectData.sort((a, b) => (a[column] || '').localeCompare(b[column] || ''));
        } else {
          objectData.sort((a, b) => (a[column] || '') - (b[column] || ''));
        }

      } else if (sortByObject[sortByValue] === 'asc') {
        sortByObject[sortByValue] = ''; // clear sorting
        objectData.sort((a, b) => a.index - b.index); // set to default

      } else {
        sortByObject[sortByValue] = 'desc'; // set to descending

        if (isString) {
          objectData.sort((a, b) => (b[column] || '').localeCompare(a[column] || ''));
        } else {
          objectData.sort((a, b) => (b[column] || '') - (a[column] || ''));
        }

      }
    },

  },



}

export default {CommonMixins}
