<template>
  <router-view/>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DocumentSetup',

  /** mixins **/
  mixins: [],

  /** props **/
  props: {},

  /** data **/
  data () {
    return {}
  },

  /** methods**/
  methods: {
    ...mapActions([
        'null_documents',
        'null_obj_docCategory',
        'null_obj_doctypes',
        'null_obj_ranks',
    ])
  },

  /**  computed  **/
  computed: {},

  /** mounted **/
  mounted () {
  },

  /** created **/
  created () {
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed () {
    this.null_documents()
    this.null_obj_docCategory()
    this.null_obj_doctypes()
  },
}
</script>

<style scoped>

</style>
