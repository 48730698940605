import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  checklistSignatoryType: {},
  checklistSignatoryTypes: [],
  newSignatoryType: {},

  checklistSignatoryTypeFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  checklistSignatoryTypePagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_checklistSignatoryTypes(state, params) {
    state.checklistSignatoryTypes = params
  },


  create_checklistSignatoryTypes (state) {
    state.newSignatoryType = {};
  },

  update_checklistSignatoryTypes (state, params) {
    let signatoryTypes = state.checklistSignatoryTypes.find((val, index) => val.id === params.id)
    Object.assign(signatoryTypes, params)
  },

  delete_checklistSignatoryTypes (state, params) {
    let index = state.checklistSignatoryTypes.findIndex((val, index) => val.id === params)
    state.checklistSignatoryTypes.splice(index, 1)
  },


  set_checklistSignatoryType_pagination(state, params) {
    state.checklistSignatoryTypePagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },


};


const actions = {
  async set_checklistSignatoryTypes({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.CREW_CHECKLIST_SIGNATORY_TYPE, {params}).then((r) => {
      commit('set_checklistSignatoryTypes', r.data.data)
      commit('set_checklistSignatoryType_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get checklist signatory type')
      return false
    })
  },

  async create_checklistSignatoryTypes({commit}, params) {
    return axios.post(PMC.CREW_CHECKLIST_SIGNATORY_TYPE, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_checklistSignatoryTypes', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create signatory type');
      return false;
    })
  },

  async update_checklistSignatoryTypes({commit}, params) {
    return axios.put(`${PMC.CREW_CHECKLIST_SIGNATORY_TYPE}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_checklistSignatoryTypes',r.data.updatedSignatoryType);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update signatory type');
      return false;

    });
  },

  async delete_checklistSignatoryTypes ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Signatory Type','question');
    if(await _del){
      return axios.delete(`${PMC.CREW_CHECKLIST_SIGNATORY_TYPE}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete signatory type');
        commit('delete_checklistSignatoryTypes',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete signatory type");
        return false;

      })
    }
  },


};


const getters = {
  checklistSignatoryTypes: state => UtilityService.capitalizeProperties(state.checklistSignatoryTypes),
  checklistSignatoryTypeFormParam: state => state.checklistSignatoryTypeFormParam,
  checklistSignatoryTypePagination: state => state.checklistSignatoryTypePagination,
};


/** export **/
export default {state, actions, mutations, getters};
