"use strict";

import Vue from 'vue';
import VueAxios from 'vue-axios'
import axios from 'axios'

import VueProgressBar from 'vue-progressbar'
import Swal from 'vue-sweetalert2'
import {TOKEN} from './constants.config';
import {API_URL} from './api-url.config';

import {AlertMixins} from "@/mixins/alert.mixins";
import {AlertService} from "@/service/alert.service";

export const AppConfig = {
  appAxios() {
    let newVue = new Vue({});
    Vue.use(VueAxios, axios);
    axios.defaults.baseURL = API_URL.index();
    axios.interceptors.request.use(
      (config) => {
        newVue.$Progress.start();
        let token = localStorage.getItem(TOKEN.PMC)
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
      },
      (error) => {
        newVue.$Progress.fail();
        return Promise.reject(error);
      },
    );
    axios.interceptors.response.use(
      response => {
        newVue.$Progress.finish();
        return Promise.resolve(response);
      },
      error => {
        newVue.$Progress.fail();
        if (error.response.status === 401) {
          AlertService.sessionExpiredAlertService('Please log in again to continue.', 'Session Expired').then(r => {})
          localStorage.removeItem("PMC_TOKEN");
          localStorage.removeItem("vuex_pmc");
          localStorage.removeItem("isSetupAccess");
        } else {
          return Promise.reject(error);
        }
      }
    )
  },
  appSweetAlert2() {
    return window.Swal = Swal;
  },
  appProgressBar() {
    Vue.use(VueProgressBar, {
      color: '#22fc0c',
      failedColor: '#fc040c',
      thickness: '5px',
      height: '2px',
      transition: {
        speed: '2s',
        opacity: '0.6s',
        termination: 100,
      },
      autoRevert: true,
      location: 'top',
      inverse: false,
    })
  },
  elementFocus() {
    Vue.directive('focus', {
      inserted: function (el) {
        el.focus();
      }
    })
  }
}
export default {AppConfig}
