<template>
  <div class="py-2 pl-3">
    <div class="row">
      <div class="col">
        <div class="row pt-3">
          <div class="col-8 font-weight-bold justify-content-center">
            <h4>Referral List</h4>
          </div>
        </div>
      </div>
    </div>

    <!--  table   -->
    <div>
      <pmc-table :max-height="330" :loading="isReloading" :loaderColspan="10">
        <template v-slot:table-header>
          <tr>
            <th scope="col" style="width :2% !important;">#</th>
            <th scope="col" style=" width :15% !important;">Rank</th>
            <th scope="col" style="width:20% !important;">Crew Name</th>
            <th scope="col">Date Application</th>
            <th scope="col">Date Qualified</th>
            <th scope="col">Date Accepted</th>
            <th scope="col">Date Available</th>
            <th scope="col">Qualified By</th>
            <th scope="col">Accepted By</th>
            <th scope="col">Action</th>
          </tr>
        </template>

        <template v-slot:table-body>
          <tr v-for="(referral,index) in referrals" :key="referral.id">
            <td>{{ index + 1 }}</td>
            <td scope="row" class="text-left align-middle pl-2 p-1">{{ referral.crewRank }}</td>
            <td scope="row" class="text-left align-middle pl-4 p-1">{{ referral.crewName }}</td>
            <td scope="row" class="p-1">{{ referral.dateApplied }}</td>
            <td scope="row" class="p-1">{{ referral.dateQualified }}</td>
            <td scope="row" class="p-1">{{ referral.dateAccepted }}</td>
            <td scope="row" class="p-1">{{ referral.dateAvailable }}</td>
            <td scope="row" class="p-1 align-middle text-left pr-1">{{ referral.qualifiedBy }}</td>
            <td scope="row" class="p-1 align-middle text-left pr-1">{{ referral.acceptedBy }}</td>
            <td scope="row" class="align-middle">
              <button type="button" class="btn btn-xs pmc-btn-prime" style="font-size: 0.7em!important;"
                      @click="viewReferralDetails(referral)">
                <font-awesome-icon icon="bars"/>
                details
              </button>
            </td>
          </tr>
        </template>

      </pmc-table>
    </div>

    <!-- todo referral modal detail   -->
    <pmc-modal lg_height lg_width :id="viewReferralModal">
      <template slot="custom-header">
        <span>this is the header</span>
      </template>

      <template slot="custom-body">
        <span>this is the header</span>
      </template>

      <template slot="custom-footer">
        <span> this is footer</span>
      </template>
    </pmc-modal>
  </div>
</template>

<script>

import failOver from '../../../../../../assets/failOver.gif'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'CrewReferral',

  data() {
    return {
      viewReferralModal: 'viewReferralModal',
      crewId: parseInt(this.$route.params.crewId, 10),
      cavemanPage: failOver,
    }
  },

  methods: {
    ...mapActions([
      'set_referrals',
      'null_referrals'
    ]),

    viewReferralDetails(__referral) {
    }
  },

  computed: {
    ...mapGetters([
      'referrals',
      'isReloading'
    ]),
  },

  mounted() {
  },

  created() {
    this.set_referrals(this.crewId)
  },

  destroyed() {
    this.null_referrals()
  },
}
</script>

<style scoped>

</style>
