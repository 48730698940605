import {PMC} from '../../config/constants.config';
import {UtilityService} from '../../service/utility.service';
import axios from 'axios';
import {counter} from '@fortawesome/fontawesome-svg-core';
import {AlertService} from "../../service/alert.service";

const state = {
  crew: {},
  crews: [],

  newCrew: {
    generalInfo: {
      firstName: '',
      middleName: '',
      lastName: '',
      suffixName: '',
      gender: '',
      birthdate: '',
      birthplace: '',
      civilStatus: '',
      nationality: '',
      religion: '',
      height: '',
      weight: '',
      hairColor: '',
      eyeColor: '',
      bloodType: '',
      sss: '',
      tin: '',
      philHealth: '',
      pagIbig: '',
    },

    addressAndContact: {
      permanent: {
        region: '',
        province: '',
        city: '',
        barangay: '',
        description: '',
      },

      temporary: {
        region: '',
        province: '',
        city: '',
        barangay: '',
        description: '',
      },

      province: {
        region: '',
        province: '',
        city: '',
        barangay: '',
        description: '',
      },

      contacts: {
        email: '',
        telNumber: '',
        workNumber: '',
        perNumber: '',
      },
    },

    crewInfo: {
      changeCode: '',
      crewType: '',
      scholarType: '',
      scholarProp: '',
      bridgingBatch: '',
      rank: '',

      coverAll: '',
      jacket: '',
      shoes: '',
      longSleeves: '',
      shortSleeves: '',
      pants: '',
      uniform: '',

      status: false,

    },

    tracking: {
      dateApplied: '',
      applicantType: '',
      endorsementType: '',
      endorsedBy: '',
      endorseByOthers: '',
      dateAvailable: '',    //can use for date_available crew info
      pmcOffice: 'MANILA',
      applicantStatus: 1,
      applicantRemarks: '',
    },
  },

  crewPersonal: {
    crewInfo: {},
    crewGenInfo: {},
    crewContrib: {},
  },

  crewImg: null,
  setUpCrewList: [],
};

const mutations = {
  SET_CREW(state, param) {
    state.crew = param;
  },

  SET_CREWS(state, params) {
    state.crews = params;
  },

  SET_NEW_CREW(state, params) {
    state.newCrew = {
      generalInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        suffixName: '',
        gender: '',
        birthdate: '',
        birthplace: '',
        civilStatus: '',
        nationality: '',
        religion: '',
        height: '',
        weight: '',
        hairColor: '',
        eyeColor: '',
        bloodType: '',
        sss: '',
        tin: '',
        philHealth: '',
        pagIbig: '',
      },

      addressAndContact: {
        permanent: {
          region: '',
          province: '',
          city: '',
          barangay: '',
          description: '',
        },

        temporary: {
          region: '',
          province: '',
          city: '',
          barangay: '',
          description: '',
        },

        province: {
          region: '',
          province: '',
          city: '',
          barangay: '',
          description: '',
        },

        contacts: {
          email: '',
          telNumber: '',
          workNumber: '',
          perNumber: '',
        },
      },

      crewInfo: {
        changeCode: '',
        crewType: '',
        scholarType: '',
        scholarProp: '',
        bridgingBatch: '',
        rank: '',

        coverAll: '',
        jacket: '',
        shoes: '',
        longSleeves: '',
        shortSleeves: '',
        pants: '',
        uniform: '',

        //todo is hired

        status: false,

      },

      tracking: {
        dateApplied: '',
        applicantType: '',
        endorsementType: '',
        endorsedBy: '',
        endorseByOthers: '',
        dateAvailable: '',    //can use for date_available crew info
        pmcOffice: 'MANILA',
        applicantStatus: 1,
        applicantRemarks: '',
      },
    };
  },

  SET_UPDATE_CREW(state, params) {
    let index = state.setUpCrewList.findIndex((val, index) => val.id === params)
    state.setUpCrewList.splice(index, 1)
  },


  SET_NULL_CREW(state) {
    state.crew = {};
  },

  SET_NULL_CREWS(state) {
    state.crews = [];
  },

  SET_CREW_INFO(state, params) {
    state.crewPersonal.crewInfo = params;
  },

  SET_CREW_GEN_INFO(state, params) {
    state.crewPersonal.crewGenInfo = params;
  },

  SET_CREW_CONTRIB(state, params) {
    state.crewPersonal.crewContrib = params;
  },

  SET_CREW_INFO_NULL(state) {
    state.crewPersonal.crewInfo = {};
  },

  SET_CREW_GEN_INFO_NULL(state) {
    state.crewPersonal.crewGenInfo = {};
  },

  SET_CREW_CONTRIB_NULL(state) {
    state.crewPersonal.crewContrib = {};
  },

  SET_CREW_IMG(state, param) {
    state.crewImg = param;      // param val
    state.crew.crewImg = param; // response val
  },

  NULL_CREW_IMG(state) {
    state.crewImg = null;
  },

  SET_SETUP_CREW_LIST(state, params) {
    state.setUpCrewList = params;
  },

  NULL_SETUP_CREW_LIST(state) {
    state.setUpCrewList = [];
  },

  set_crew_covid_vaccine(state, params) {
    if (state.crew != null || state.crew != undefined || state.crew != {}) {
      state.crew.covidVaccineLabel = params.covidVaccineLabel;
      state.crew.covidVaccineLabel = params.vaccineName;
    }
  },

  add_crew_comment_count(state) {
    if (state.crew != null || state.crew != undefined) {
      state.crew.commentsCount += 1;
    }
  },

  sub_crew_comment_count(state, params) {
    if (state.crew != null || state.crew != undefined) {
      state.crew.commentsCount -= 1;
    }
  },

  add_crew_sea_exp_count(state){
    if(state.crew != null || state.crew != undefined){
      state.crew.seaExpCount += 1;
    }
  },

  sub_crew_sea_exp_count(state){
    if(state.crew != null || state.crew != undefined){
      state.crew.seaExpCount -= 1;
    }
  },

  add_crew_other_sea_exp_count(state){
    if(state.crew != null || state.crew != undefined){
      state.crew.otherSeaExpCount += 1;
    }
  },

  sub_crew_other_sea_exp_count(state){
    if(state.crew != null || state.crew != undefined){
      state.crew.otherSeaExpCount -= 1;
    }
  },


  NULL_CREW(state){
    state.crew = {}
  }

};

const actions = {
  /** top  nav pagination **/
  async getAlLCrewPaginated({commit}, params) {
    commit('set_is_loading',true)
    return await axios.post(PMC.GET_ALL_CREW_PAGINATED, params).then((response) => {
      commit('SET_CREWS', response.data.data);
      commit('SET_SEARCH_CREW_PAGINATION', response.data);
      commit('set_is_loading',false)

      //mutations from applicant tracking
      commit('set_object_crew_pagination',response.data)
      commit('set_obj_crew_endorsers',response.data.data)

      return true;

    }).catch((errors) => {
      commit('set_is_loading',false)
      UtilityService.generateResponseMessage(errors, 'Get Crew Paginated');
      return false;

    });
  },

  /** set up crew list paginaiton  **/
  async getCrewListPaginated({commit}, params) {
    commit('set_is_loading', true);
    return await axios.post(PMC.GET_ALL_CREW_PAGINATED, params).then((response) => {
      commit('SET_SETUP_CREW_LIST', response.data.data);
      commit('set_crew_list_pagination', response.data);
      commit('set_is_loading',false);
      return true;
    }).catch((errors) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(errors, 'Get Crew Paginated');
      return false;
    });
  },

  viewCrew({commit}, param) {
    return axios.get(`${PMC.CREW}/${param}`).then((response) => {
      commit('SET_CREW', response.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error);
      return false;
    });
  },

  viewPersonalDetails({commit}, param) {
    return axios.get(`${PMC.GET_CREW_PERSONAL_DTLS}/${param}`).then((response) => {
      commit('SET_CREW_INFO', response.data.crewInfo);
      commit('SET_CREW_GEN_INFO', response.data.crewGenInfo);
      commit('SET_CREW_CONTRIB', response.data.crewContrib);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Personal Details');
      return false;
    });
  },

  updateCrewInformation({commit}, params) {
    let parseParams = UtilityService.deCapitalizeProperties(params);
    return axios.post(`${PMC.UPDATE_CREW_INFO}/${params.crewId}`, parseParams).then((response) => {
      commit('SET_CREW_INFO', response.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, '');
      return false;

    });
  },

  updateCrewGenInformation({commit}, params) {
    let parseParams = UtilityService.deCapitalizeProperties(params);
    return axios.post(`${PMC.UPDATE_CREW_GEN_INFO}/${params.crewId}`,
      parseParams).then((response) => {
      commit('SET_CREW_GEN_INFO', response.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,
        'Update General Information');
      return false;
    });
  },

  updateCrewContribInfo({commit}, params) {
    let parseParams = UtilityService.deCapitalizeProperties(params);
    return axios.post(`${PMC.UPDATE_CREW_CONTRIB_INFO}/${params.crewId}`,
      parseParams).then((response) => {
      commit('SET_CREW_CONTRIB', response.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Crew Contribution');
      return false;

    });
  },

  uploadCrewImage({commit}, params) {
    return axios({
      method: 'post',
      url: `${PMC.UPLOAD_CREW_IMAGE}`,
      data: params,
    }).then((r) => {
      commit('SET_CREW_IMG', r.data);
      return true;

    }).catch((e) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(e, 'Upload Image');
      return false;

    });
  },

  deleteCrewImage({commit}, params) {
    return axios.post(`${PMC.DELETE_CREW_IMAGE}/${params}`, params).then((response) => {
      commit('SET_CREW_IMG', null);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Remove Image');
      return false;
    });
  },

  //Done Adding Delete Crew - Please check
  async delete_crew ({ commit }, params) {
    console.log(params)
    let _del = AlertService.questionAlertService("Do you want to delete this crew.",'Delete Crew','question');
    if(await _del){
      return axios.delete(`${PMC.CREW}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete crew');
        commit('SET_UPDATE_CREW',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete crew");
        return false;

      })
    }
  },


  createNewCrew({commit}, params) {
    return axios({
      method: 'POST',
      url: `${PMC.CREW}`,
      data: params,
    }).then((r) => {
      commit('SET_NEW_CREW');
      return true;

    }).catch((er) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(er, 'Create new crew record');
      return false;
    });
  },

  setNullCrew({commit}) {
    commit('SET_NULL_CREW');
  },

  setNullCrews({commit}) {
    commit('SET_NULL_CREWS');
  },

  setCrewInfoNull({commit}) {
    commit('SET_CREW_INFO_NULL');
  },

  setCrewGenInfoNull({commit}) {
    commit('SET_CREW_GEN_INFO_NULL');
  },

  setCrewContribNull({commit}) {
    commit('SET_CREW_CONTRIB_NULL');
  },

  setCrewImage({commit}, param) {
    commit('SET_CREW_IMG', param);
  },

  nullCrewImage({commit}) {
    commit('NULL_CREW_IMG');
  },

  nullSetupCrewList({commit}) {
    commit('NULL_SETUP_CREW_LIST');
  },

  set_crew_covid_vaccine({commit}, params) {
    commit('set_covid_vaccine', params);
  },

  nullCrew({commit}){
    commit('NULL_CREW');
  },

  update_crew_availability({commit},params){
    return axios.post(`${PMC.UPDATE_CREW_AVAILABILITY}/${params.crewId}`,params)
      .then((r)=>{
        return true;
      }).catch((er)=>{
        UtilityService.generateResponseMessage(er,"Update availability")
        return false;
      })
  }
};

const getters = {
  crew: state => UtilityService.capitalizeProperties(state.crew),
  crews: state => UtilityService.capitalizeProperties(state.crews),
  setUpCrewList: state => UtilityService.capitalizeProperties(state.setUpCrewList),
  crewInfo: state => UtilityService.capitalizeProperties(state.crewPersonal.crewInfo),
  crewGenInfo: state => UtilityService.capitalizeProperties(state.crewPersonal.crewGenInfo),
  crewContrib: state => state.crewPersonal.crewContrib,
  crewImg: state => state.crewImg,
  newCrew: state => state.newCrew,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
