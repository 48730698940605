<template>
  <div class="container-fluid py-2 pl-3">
    <div class="row">
      <div class="col">
        <div class="row pt-3">
          <div class="col-2 font-weight-bold justify-content-center">
            <h4 class="font-weight-bold">Family Background</h4>
          </div>

          <div class="col-4">
            <pmc-modal-btn :button-class="'btn btn-sm pmc-btn-prime ml-5'"
                           :target-modal="newFamModal"
                           :button-label="'add'">
              <template slot="button-icon">
                <font-awesome-icon icon="plus-circle"/>
              </template>
            </pmc-modal-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-2">
      <div class="col">
        <pmc-table :max-height="330" :loading="isReloading" :loaderColspan="9">
          <template v-slot:table-header>
            <tr>
              <th scope="col" style=" width :20% !important;">Name</th>
              <th scope="col" style="width :10% !important;">Relation</th>
              <th scope="col" style="width :25% !important;">Address</th>
              <th scope="col">Birthdate</th>
              <th scope="col" style="width :10% !important;">Contact</th>
              <th scope="col" style=" width :5% !important;">Dependent</th>
              <th scope="col" style=" width :5% !important;">Allottee</th>
              <th scope="col" style=" width :5% !important;">ICE</th>
              <th scope="col" style=" width :5% !important;">Benefeciary</th>
              <th scope="col" style=" width :10% !important;">Action</th>
            </tr>
          </template>

          <template slot="table-body">
            <tr v-for="(fam,index) in crewFamilies" :key="fam.id">
              <td scope="row" class="text-left">
                <span class="ml-3">
                  {{ fam.fullName }}
                </span>
              </td>
              <td scope="row" class="text-center">
                <span>
                  {{ fam.relation }}
                </span>
              </td>
              <td scope="row" class="text-left">
                <span class="ml-3">
                  {{ fam.address }}
                </span>
              </td>
              <td scope="row" class="text-center">
                <span>
                  {{ fam.brithdayString }}
                </span>
              </td>
              <td scope="row" class="text-center">
                <span>
                  {{ fam.mobileNum }}
                </span>
              </td>
              <td>
                <!--<input class="remove-pointer" type="checkbox" v-model="fam.isDependent">-->
                <font-awesome-icon v-if="fam.isDependent" icon="check" style="width: 20px !important; height: 20px !important;"/>
              </td>
              <td>
                <!-- <input class="remove-pointer" type="checkbox" v-model="fam.isAllottee">-->
                <font-awesome-icon v-if="fam.isAllottee" icon="check" style="width: 20px !important; height: 20px !important;"/>
              </td>
              <td>
                <!-- <input class="remove-pointer" type="checkbox" v-model="fam.isICE">-->
                <font-awesome-icon v-if="fam.isICE" icon="check" style="width: 20px !important; height: 20px !important;"/>
              </td>
              <td>
                <!-- <input class="remove-pointer" type="checkbox" v-model="fam.isICE">-->
                <font-awesome-icon icon="check" v-if="fam.isBeneficiary" style="width: 20px !important; height: 20px !important;"/>
              </td>

              <td>
                <pmc-modal-btn :button-class="'btn btn-xs pmc-btn-prime fa-xs'"
                               @modalButtonEvent="view_family(fam.id)"
                               :target-modal="viewFamModal"
                               :button-label="'view'">
                  <template slot="button-icon">
                    <font-awesome-icon icon="bars"/>
                  </template>
                </pmc-modal-btn>

                <pmc-btn
                  :button-class="'btn btn-xs pmc-btn-danger ml-1'"
                  :button-label="'del'"
                  @buttonEvent="delete_family(fam.id)">
                  <template slot="button-icon">
                    <font-awesome-icon icon="trash"/>
                  </template>
                </pmc-btn>
              </td>
            </tr>
          </template>

        </pmc-table>
      </div>
    </div>

    <!-- view family modal  -->
    <pmc-modal xl_width :id="viewFamModal">
      <template slot="custom-header">
        <h3>FULL DETAILS</h3>
      </template>

      <template slot="custom-body">
        <div class="row">
          <div class="col-md-12 px-5">
            <div class="row">
              <div class="col">
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">FIRST NAME</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           :readonly="!isEditedFamily"
                           :disabled="!isEditedFamily"
                           :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                           name="FamFirst"
                           v-model="crewFamily.firstName"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">MIDDLE NAME</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-focus
                           :readonly="!isEditedFamily"
                           :disabled="!isEditedFamily"
                           :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                           name="FamMiddle"
                           v-model="crewFamily.middleName"
                           placeholder="--">
                  </div>
                </div>

                <div v-if="crewFamily.relationId === 13" class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">MAIDEN NAME</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-focus
                           :readonly="!isEditedFamily"
                           :disabled="!isEditedFamily"
                           :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                           name="FamMiddle"
                           v-model="crewFamily.maidenName"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">LAST NAME</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-focus
                           :readonly="!isEditedFamily"
                           :disabled="!isEditedFamily"
                           :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                           name="FamLast"
                           v-model="crewFamily.lastName"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">SUFFIX NAME</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           :readonly="!isEditedFamily"
                           :disabled="!isEditedFamily"
                           :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                           name="FamSuffix"
                           v-model="crewFamily.suffixName"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">GENDER</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <select name="FamGender" id="fam-gender"
                            :disabled="!isEditedFamily"
                            v-model="crewFamily.genderId"
                            :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']">
                      <option :value="null">--select--</option>
                      <option v-for="(gender,index) in objGenders"
                              :value="gender.id"
                              :key="gender.id"
                              :selected="gender.id === crewFamily.genderId"
                      >{{ gender.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">FAMILY RELATION</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <select name="FamGender" id="fam-rel"
                            :disabled="!isEditedFamily"
                            v-model="crewFamily.relationId"
                            :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']">
                      <option :value="null">--select--</option>
                      <option v-for="(famType,index) in objFamTypes"
                              :key="famType.id"
                              :value="famType.id"
                              :selected="famType.id === crewFamily.relationId"
                      >{{ famType.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">BIRTHDATE</label>
                  <span>:</span>
                  <div class="col-sm-4">
                    <input
                      :disabled="!isEditedFamily"
                      v-if="!isEditedFamily"
                      class="form-control-plaintext form"
                      type="text"
                      v-model="crewFamily.brithdayString"
                      name="FamBirthdate"/>

                    <input
                      v-if="isEditedFamily"
                      class="form-control"
                      type="date"
                      v-model="crewFamily.birthdayDate"
                      name="FamBirthdate" />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">EMAIL</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           :readonly="!isEditedFamily"
                           :disabled="!isEditedFamily"
                           :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                           v-model="crewFamily.email"
                           name="Email"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">Address</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <textarea
                      :readonly="!isEditedFamily"
                      :disabled="!isEditedFamily"
                      type="text" style="width: 100%; position: relative; resize: none;"
                      name="FamAddress"
                      :class="[isEditedFamily ? 'form-control ' :'form-control']"
                      v-model="crewFamily.address"
                      rows="2"
                      placeholder="Address">
                    </textarea>
                  </div>
                </div>

              </div>

              <div class="col">
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">TEL #</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           :readonly="!isEditedFamily"
                           :disabled="!isEditedFamily"
                           :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                           v-model="crewFamily.telNum"
                           name="TelNum"
                           placeholder="telephone number">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">TEL #2</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           :readonly="!isEditedFamily"
                           :disabled="!isEditedFamily"
                           :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                           v-model="crewFamily.telNum2"
                           name="TelNum2"
                           placeholder="telephone number">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">MOBILE #</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           :readonly="!isEditedFamily"
                           :disabled="!isEditedFamily"
                           :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                           v-model="crewFamily.mobileNum"
                           name="FamAddress"
                           placeholder="mobile number 0-9">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">MOBILE #2</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           :readonly="!isEditedFamily"
                           :disabled="!isEditedFamily"
                           :class="[isEditedFamily ? 'form-control form-control-sm' : 'form-control-plaintext form']"
                           v-model="crewFamily.mobileNum2"
                           name="FamAddress"
                           placeholder="mobile number 0-9">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">DEPENDENT</label>
                  <span>:</span>
                  <div class="col-sm-6 ml-1">
                    <input type="checkbox"
                           :class="[isEditedFamily ? 'form-check-inline' : 'form-check-inline  remove-pointer']"
                           v-model="crewFamily.isDependent"
                           name="FamAddress"/>
                    <label class="ml-3" :readonly="!isEditedFamily">{{ crewFamily.isDependent ? 'yes' : 'no' }}</label>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ALLOTTEE</label>
                  <span>:</span>
                  <div class="col-sm-6 ml-1">
                    <input type="checkbox"
                           :class="[isEditedFamily ? 'form-check-inline' : 'form-check-inline  remove-pointer']"
                           v-model="crewFamily.isAllottee"
                           name="FamAddress"/>
                    <label class="ml-3" :readonly="!isEditedFamily">{{ crewFamily.isAllottee ? 'yes' : 'no' }}</label>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">IN CASE OF EMERGENCY</label>
                  <span>:</span>
                  <div class="col-sm-6 ml-1">
                    <input type="checkbox"
                           :class="[isEditedFamily ? 'form-check-inline' : 'form-check-inline  remove-pointer']"
                           v-model="crewFamily.isICE"
                           name="FamAddress"/>
                    <label class="ml-3" :readonly="!isEditedFamily">{{ crewFamily.isICE ? 'yes' : 'no' }}</label>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">BENEFICIARY</label>
                  <span>:</span>
                  <div class="col-sm-6 ml-1">
                    <input type="checkbox"
                           :class="[isEditedFamily ? 'form-check-inline' : 'form-check-inline  remove-pointer']"
                           v-model="crewFamily.isBeneficiary"
                           name="FamAddress"/>
                    <label class="ml-3" :readonly="!isEditedFamily">{{ crewFamily.isBeneficiary ? 'yes' : 'no' }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template slot="custom-footer">
        <pmc-btn v-if="!isEditedFamily"
                 :button-label="'Edit'"
                 :button-class="'btn btn-sm pmc-btn-warning'"
                 @buttonEvent="set_edit_family">
          <template slot="button-icon">
            <font-awesome-icon icon="edit"/>
          </template>
        </pmc-btn>

        <pmc-btn v-if="isEditedFamily"
                 :button-label="'Update'"
                 :button-class="'btn btn-sm pmc-btn-success'"
                 @buttonEvent="update_family">
          <template slot="button-icon">
            <font-awesome-icon icon="edit"/>
          </template>
        </pmc-btn>

        <pmc-btn v-if="isEditedFamily" :button-label="'Cancel'"
                 :button-class="'btn btn-sm pmc-btn-danger'"
                 @buttonEvent="set_cancel_family">
          <template slot="button-icon">
            <font-awesome-icon icon="ban"/>
          </template>
        </pmc-btn>

        <pmc-btn v-if="!isEditedFamily"
                 :button-label="'Close'"
                 :button-class="'btn btn-sm pmc-btn-danger'"
                 @buttonEvent="set_close_family">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>

      </template>

    </pmc-modal>

    <!--  new family modal  -->
    <pmc-modal xl_width :id="newFamModal">
      <template slot="custom-header">
        <h3>Add Family Details</h3>
      </template>
      <!-- col 1     -->
      <template slot="custom-body">
        <div class="row">
          <div class="col-md-12 px-5">
            <div class="row">
              <div class="col">
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">FIRST NAME</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="newFamily.firstName"
                           class="form-control form-control-sm"
                           name="FamFirst"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">MIDDLE NAME</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="newFamily.middleName"
                           class="form-control form-control-sm"
                           name="FamFirst"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">LAST NAME</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="newFamily.lastName"
                           class="form-control form-control-sm"
                           name="FamFirst"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">SUFFIX NAME</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="newFamily.suffixName"
                           class="form-control form-control-sm"
                           name="FamFirst"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">GENDER</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <select name="NewFarmGender"
                            id="new-farm-gender"
                            v-model="newFamily.genderId"
                            class="form-control form-control-sm">
                      <option :value="null">--select--</option>
                      <option v-for="(gender,index) in objGenders"
                              :value="gender.id"
                              :key="gender.id">{{ gender.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">FAMILY RELATION</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <select name="FamGender" id="new-farm-relation"
                            v-model="newFamily.relationId"
                            class="form-control form-control-sm">
                      <option :value="null">--select--</option>
                      <option v-for="(famType,index) in objFamTypes"
                              :key="famType.id"
                              :value="famType.id">{{ famType.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">BIRTHDATE</label>
                  <span>:</span>
                  <div class="col-sm-4">
                    <input
                      class="form-control"
                      type="date"
                      v-model="newFamily.birthdayDate"
                      name="FamBirthdate" onkeydown="event.preventDefault()"/>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">EMAIL</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           class="form-control form-control-sm"
                           v-model="newFamily.email"
                           name="Email"
                           placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">Address</label>
                  <span>:</span>
                  <div class="col-sm-8">
                    <textarea
                      v-model="newFamily.address"
                      type="text" style="width: 100%; position: relative; resize: none;"
                      name="FamAddress"
                      class="form-control"
                      rows="2"
                      placeholder="Address">
                    </textarea>
                  </div>
                </div>

              </div>

              <div class="col">
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">TEL #</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           v-model="newFamily.telNum"
                           class="form-control form-control-sm"
                           name="TelNum"
                           placeholder="telephone number">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">TEL #2</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           v-model="newFamily.telNum2"
                           class="form-control form-control-sm"
                           name="TelNum2"
                           placeholder="telephone number">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">MOBILE #</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           v-model="newFamily.mobileNum"
                           class="form-control form-control-sm"
                           name="FamAddress"
                           placeholder="mobile number 0-9">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">MOBILE #2</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           v-model="newFamily.mobileNum2"
                           class="form-control form-control-sm"
                           name="FamAddress"
                           placeholder="mobile number 0-9">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">DEPENDENT</label>
                  <span>:</span>
                  <div class="col-sm-6 ml-1">
                    <input type="checkbox"
                           v-model="newFamily.isDependent"
                           class="form-check-inline"
                           name="FamAddress"/>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ALLOTTEE</label>
                  <span>:</span>
                  <div class="col-sm-6 ml-1">
                    <input type="checkbox"
                           v-model="newFamily.isAllottee"
                           class="form-check-inline"
                           name="FamAddress"/>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">IN CASE OF EMERGENCY</label>
                  <span>:</span>
                  <div class="col-sm-6 ml-1">
                    <input type="checkbox"
                           v-model="newFamily.isICE"
                           class="form-check-inline"
                           name="FamAddress"/>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">BENEFICIARY</label>
                  <span>:</span>
                  <div class="col-sm-6 ml-1">
                    <input type="checkbox"
                           v-model="newFamily.isBeneficiary"
                           class="form-check-inline"
                           name="famBeneficiary"/>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </template>

      <template slot="custom-footer">
        <pmc-btn
          :button-class="'btn btn-sm pmc-btn-success ml-1'"
          :button-label="'Save'"
          @buttonEvent="add_family">
          <template slot="button-icon">
            <font-awesome-icon icon="trash"/>
          </template>
        </pmc-btn>

        <pmc-btn
          :button-class="'btn btn-sm pmc-btn-danger ml-1'"
          :button-label="'Cancel'"
          @buttonEvent="set_close_new_family">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>
      </template>
    </pmc-modal>

  </div>
</template>
<script>
import {AppMixins} from '../../../../../../mixins/app.mixins'
import {AlertMixins} from '../../../../../../mixins/alert.mixins'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'CrewFamily',
  mixins: [AppMixins, AlertMixins],
  data() {
    return {
      crewId: parseInt(this.$route.params.crewId, 10),
      viewFamModal: 'viewFamModal',
      newFamModal: 'newFamModal',

      isEditedFamily: false,

    }
  },

  methods: {
    ...mapActions(
      [
        'viewFamilyList',
        'viewFamily',
        'null_obj_famTypes',
        'nullFamily',
        'null_obj_genders',
        'getCrewFamilyKeys',
        'updateFamily',
        'deleteFamily',
        'createFamily'
      ],
    ),

    async add_family() {
      if (await this.questionAlert('Are you sure you want to add new family record ?', 'Family Record')) {
        this.newFamily.crewId = this.crewId;
        this.createFamily(this.newFamily).then((r) => {
          if (r) {
            this.successAlert('Record save successfully');
            this.hideModal(this.newFamModal);
          }
        });
      }
    },

    view_family(id) {
      this.viewFamily(id)
    },

    async update_family() {
      if (await this.questionAlert('Are you sure you want to update this family record?', 'Family Details')) {
        this.updateFamily(this.crewFamily).then((r) => {
          if (r) {
            this.successAlert('Updated successfully');
            this.hideModal(this.viewFamModal);
            this.nullFamily();
            this.isEditedFamily = !this.isEditedFamily;
          }
        });
      }
    },

    async delete_family(id) {
      if (await this.questionAlert('Are you want to delete this record?', 'Delete Family Record')) {
        this.deleteFamily(id).then((r) => {
          if (r) {
            this.successAlert('Record Delete Successfully');
          }
        });
      }
    },

    set_edit_family() {
      this.isEditedFamily = !this.isEditedFamily
    },

    set_cancel_family() {
      if (this.isEditedFamily) {
        this.isEditedFamily = false
      }
    },

    set_close_family() {
      this.hideModal(this.viewFamModal)
    },

    set_close_new_family() {
      this.hideModal(this.newFamModal);
    },

    // removeSpaceMobileNum(event) {
    //   this.crewFamily.mobileNum = event.target.value.replace(/\s/g, '');
    // },
    //
    // removeSpaceMobileNum2(event) {
    //   this.crewFamily.mobileNum2 = event.target.value.replace(/\s/g, '');
    // }

  },

  computed: {
    ...mapGetters(
      [
        'crewFamilies',
        'crewFamily',
        'objFamTypes',
        'objGenders',
        'newFamily',
        'isReloading'
      ],
    ),
  },

  mounted() {

  },

  created() {
    this.viewFamilyList(this.crewId)
    this.getCrewFamilyKeys()
  },

  destroyed() {
    this.null_obj_genders()
    this.null_obj_famTypes()
  },
}
</script>

<style scoped>

input[type="checkbox"] {
  transform: scale(1.5) !important;
}

/*input[type="checkbox"]:disabled + label::before{*/
/*  background: gray !important;*/
/*}*/

/*input[type="checkbox"]:disabled + label:hover::before{*/
/*  background: gray !important;*/
/*  border: 1px solid #d4d4d5 !important;*/
/*}*/
</style>
