import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  complementTemplates: [],
  gearTypeTemplates: [],

  newComplementTemplate: {},


};


const mutations = {
  set_complementTemplates(state, params) {
    state.complementTemplates = params
  },

  set_gearTypeTemplates(state, params){
    state.gearTypeTemplates = params
  },

  create_complementTemplates(state) {
    state.newComplementTemplate = {};
  },

  delete_complementTemplates(state, complementTemplateId) {
    let index = state.complementTemplates.findIndex((val, index) => val.id === complementTemplateId)
    state.complementTemplates.splice(index, 1)
  },

};


const actions = {
  async set_complementTemplates({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GEAR_COMPLEMENT_TEMPLATE, {params}).then((r) => {
      commit('set_complementTemplates', r.data.gearComplements)
      commit('set_gearTypeTemplates', r.data.gearType)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get complement templates')
      return false
    })
  },

  async create_complementTemplates({commit}, params) {
    return axios.post(PMC.GEAR_COMPLEMENT_TEMPLATE, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_complementTemplates', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create complement templates');
      return false;
    })
  },

  async delete_complementTemplates({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Gear Complement Template','question');
    if(await _del){
      return axios.delete(`${PMC.GEAR_COMPLEMENT_TEMPLATE}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete gear complement template');
        commit('delete_complementTemplates',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete gear");
        return false;

      })
    }
  },


};



const getters = {
  complementTemplates: state => UtilityService.capitalizeProperties(state.complementTemplates),
  gearTypeTemplates: state => state.gearTypeTemplates,
};


/** export **/
export default {state, actions, mutations, getters};
