<template>
  <div class="bg-white">
    <pmc-side-nav>
      <template slot="contents">
        <router-link :to="{name:'CrewPersonal'}" :class="{'active': isRouteActive('CrewPersonal')}">
          Personal
        </router-link>

        <router-link :to="{name :'CrewAddress&Contact'}" :class="{'active' :isRouteActive('CrewAddress&Contact')}">
          Address & Contact
        </router-link>

        <router-link :to="{name:'CrewEducation'}" :class="{'active' :isRouteActive('CrewEducation')}">
          Education
        </router-link>

        <router-link :to="{name:'CrewFamily'}" :class="{'active' :isRouteActive('CrewFamily')}">
          Family
        </router-link>

        <!-- TODO   -->
        <router-link :to="{name:'CrewApplicationDetails'}" :class="{'active' :isRouteActive('CrewApplicationDetails')}">
          Application
        </router-link>

        <router-link :to="{name:'CrewReferral'}" :class="{'active' :isRouteActive('CrewReferral')}">
          Referrals
        </router-link>
      </template>
    </pmc-side-nav>

    <div id="main" class="text-left bg-white">
      <div style=" height: 100vh !important;">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>

import {AppMixins} from '../../../../../mixins/app.mixins';

export default {
  name: 'AllInfo',
  mixins: [AppMixins],
  data() {
    return {};
  },

  methods: {},

  computed: {},

  mounted() {},

  created() {},

  destroyed() {

  },
};
</script>

<style scoped>

</style>
