import axios from "axios";
import {UtilityService} from "@/service/utility.service";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {commit} from "lodash/seq";
import {FileService} from "@/service/file.service";


const initStateCrewChangePlan = () => {
  return {
    crewChangePlan: {},
    crewChangePlans: [],
    selectedCrewChange: [],
    availableCrewList: [],
    setUpCcPlans: [],
    selectedCrews: [], //TODO for selected crew change.
    isLoadingAvailableCrew: false,

    // crewPromotion: {
    //   ccId: null,
    //   promotedRankId: null,
    //   dateRecommend: null,
    // },

    crewContract: {},
  }
};

const state = {
  ...initStateCrewChangePlan()
};

const mutations = {

  set_crew_change_plan(state, payload) {
    state.crewChangePlan = payload;

  },

  set_crew_change_plans(state, payload) {
    state.crewChangePlans = payload;
  },

  reset_state_crew_change_plan(state) {
    Object.assign(state, initStateCrewChangePlan())
  },

  set_selected_crew_changed(state, payload) {
    state.selectedCrewChange.push(payload);
  },

  delete_selected_crew_change(state, payload) {
    let index = state.selectedCrewChange.findIndex((val, index) => {
      return val.id === payload;
    })
    state.selectedCrewChange.splice(index, 1)

  },

  reset_selected_crew_change(state) {
    state.selectedCrewChange = []
  },

  set_available_crew_list(state, payload) {
    state.availableCrewList = payload;
  },

  add_ccplan_setup(state, payload) {
    state.setUpCcPlans.push(payload);
  },

  remove_ccplan_setup(state, payload) {
    let index = state.setUpCcPlans.findIndex((val, index) => {
      return val.id === payload;
    })
    state.setUpCcPlans.splice(index, 1);
  },

  reset_ccplan_setup(state) {
    state.setUpCcPlans = [];
  },

  delete_rank_change_plan(state, payload) {
    let index = state.crewChangePlans.findIndex((val, i) => {
      return val.ccOffId === payload.id
    })
    state.crewChangePlans.splice(index, 1);
  },

  set_available_crews(state, payload) {
    state.availableCrewList = payload;
  },

  reset_available_crews(state) {
    state.availableCrewList = [];
  },

  set_selected_crews(state, payload) {
    state.selectedCrews = payload;
  },

  delete_selected_crew(state, payload) {
    let index = state.selectedCrews.findIndex((val, index) => {
      return val.id === payload;
    })
    state.selectedCrews.splice(index, 1);
  },

  reset_selected_crews(state) {
    state.selectedCrews = [];
  },

  add_cc_remarks_count(state, payload) {
    let crewChangePlan = null;

    switch (payload.selectedCcPlanType) {
      case 1:
        crewChangePlan = state.crewChangePlans.find((val, index) => {
          return val.ccOnId === payload.crewChangeId;
        });

        if (crewChangePlan != null) {
          crewChangePlan.ccOnRemarksCount += 1
        }
        break;

      case 2:
        crewChangePlan = state.crewChangePlans.find((val, index) => {
          return val.ccOffId === payload.crewChangeId;
        });

        if (crewChangePlan != null) {
          crewChangePlan.ccOffRemarksCount += 1
        }
        break;
    }
  },

  sub_cc_remarks_count(state, payload) {
    let crewChangePlan = null;
    switch (payload.selectedCcPlanType) {
      case 1:
        crewChangePlan = state.crewChangePlans.find((val, index) => {
          return val.ccOnId === payload.crewChangeId;
        });

        if (crewChangePlan != null) {
          crewChangePlan.ccOnRemarksCount -= 1
        }
        break;

      case 2:
        crewChangePlan = state.crewChangePlans.find((val, index) => {
          return val.ccOffId === payload.crewChangeId;
        });

        if (crewChangePlan != null) {
          crewChangePlan.ccOffRemarksCount -= 1
        }
        break;
    }
  },
  //
  // set_crew_promotion(state,payload){
  //   state.crewPromotion = payload;
  // },

  set_crew_contract(state, payload) {
    state.crewContract = payload;
  },

  reset_null_crew_contract(state) {
    state.crewContract = {};
  },

  //update eoc date
  update_eoc_date(state, params) {
    let crewChangePlan = state.crewChangePlans.find((val, index) => val.ccOffId === params.id)
    crewChangePlan.ccOffEocDate = params.eoc_date
  },

};

const actions = {
  async set_crew_change_plan({commit}, payload) {
    commit('set_is_loading', true)
    return await axios.get().then((r) => {
      commit('set_crew_change_plan')
      commit('set_is_loading', false)
      return true;

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get Crew Change Plan');
      return false;

    })
  },

  /**
   * @author load the crew change.
   */
  async set_crew_change_plans({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GET_CREW_CHANGE_PLAN, {params}).then((r) => {
      commit('set_crew_change_plans', r.data.crewChangePlans)

      commit('set_is_loading', false)
      commit('set_obj_vessels', r.data?.objVessels)
      commit('set_obj_ranks', r.data?.objRanks)
      commit('set_remarks_count', r.data?.vesselRemarksCounts)


      if (r.data.crewChangePlans && params !== undefined) {
        if (r.data.crewChangePlans.length === 0) {
          AlertService.infoAlertService('Vessel doesnt have any change plan', 'Crew Change Plan').then((r) => {
          })
        }
      }

      return true;

    }).catch((er) => {
      commit('set_is_loading', false)
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get Crew Change Plan')
      }
      return false;
    })
  },

  set_selected_crew_changed({commit}, payload) {
    commit('set_selected_crew_changed', payload)
  },

  delete_selected_crew_change({commit}, payload) {
    commit('delete_selected_crew_change', payload)
  },

  reset_selected_crew_change({commit}) {
    commit('reset_selected_crew_change')
  },

  /** adding crew change plan   **/
  adding_crew_change_plan({commit}, params) {
    return axios.post(PMC.ADD_CREW_CHANGE_PLAN, params).then((r) => {
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService('Cant establish server connection')

      } else {
        UtilityService.generateResponseMessage(er, 'Adding Crew Change Plan')
      }
      return false
    })
  },

  get_available_crew_list({commit}, params) {
    return axios.get(PMC.GET_AVAILABLE_CREW_LIST, {params}).then((r) => {
      commit('set_available_crews', r.data)
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, "Get Available Crew List")
      }
      return false;
    })
  },

  reset_available_crews({commit}) {
    commit('reset_available_crews')
  },

  async add_ccplan_setup({commit}, params) {
    await commit('add_ccplan_setup', params)
  },

  remove_ccplan_setup({commit}, params) {
    commit('remove_ccplan_setup', params)
  },

  reset_ccplan_setup({commit}) {
    commit('reset_ccplan_setup')
  },

  save_setup_ccplan({commit}, params) {
    return axios.post(PMC.SAVE_SETUP_CCPLANS, params).then((r) => {
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, "Get Available Crew List")
      }
      return false;
    })
  },

  /**
   * @author Good to have featured
   */
  /*save_setup_ccplan_astemp({commit},params) {
   return axios.post(PMC.SAVE_SETUP_CCPLAN_AS_TEMP).then((r)=>{
       //connection .
    }).catch((er)=>{
      if(!er.response){
        AlertService.errorAlertService("Cant establish server connection")
      }else {
        UtilityService.generateResponseMessage(er,"Get Available Crew list")
      }
    })
  }*/

  add_rank_change_plan({commit}, params) {
    return axios.post(PMC.ADD_RANK_CHANGE_PLAN, params).then((r) => {
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, "Adding rank change plan")
      }
      return false;
    })
  },

  /**
   * @author for deleting both crew change sign on and crew sign off
   */
  delete_rank_change_plan({commit}, params) {
    return axios.delete(`${PMC.DELETE_RANK_CHANGE_PLAN}/${params}`).then((r) => {
      commit('delete_rank_change_plan', r.data)
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, "Delete rank change plan")
      }
      return false;
    })
  },


  delete_crew_change({commit}, params) {
    return axios.post(PMC.DELETE_CREW_CHANGE, params).then((r) => {
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection")
      } else {
        UtilityService.generateResponseMessage(er, "Delete Crew Sign Off")
      }

      return false;
    })
  },

  reset_state_crew_change_plan({commit}) {
    commit('reset_state_crew_change_plan')
  },

  extend_crew_contract({commit}, params) {
    return axios.post(`${PMC.EXTEND_CREW_CONTRACT}/${params.ccOffId}`, params).then((r) => {
      return true;

    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection")
      } else {
        UtilityService.generateResponseMessage(er, "Delete Crew Sign Off")
      }
      return false;
    })
  },

  set_crew_contract({commit}, payload) {
    commit('set_crew_contract', payload)
  },

  reset_null_crew_contract({commit}) {
    commit('reset_null_crew_contract')
  },

  async generateCrewChangePlan({commit}, params) {
    return await axios.post(PMC.GENERATE_CREW_CHANGE_PLAN, params).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate crew change plan')
      return false
    });
  },

  // async updateEOCDateCrewChangePlan(params) {
  //   return await axios.post(PMC.UPDATE_EOC_DATE_CREW_CHANGE_PLAN, params).then(r => {
  //     return r.data ?? null;
  //   }).catch(error => {
  //     UtilityService.generateResponseMessage(error, 'Update EOC Date Crew Change Plan')
  //     return false
  //   });
  // },


  /**
   * Updating EOC date in Crew Change Plan
   *
   */
  async updateEOCDateCrewChangePlan({commit}, params) {
    return axios.post(PMC.UPDATE_EOC_DATE_CREW_CHANGE_PLAN, params).then((r) => {
      commit('update_eoc_date', r.data)
      console.log(r.data, 'r.data')
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Update EOC Date Crew Change Plan');
      return false;

    });
  },

};


const getters = {
  crewChangePlan: state => state.crewChangePlan,
  crewChangePlans: state => state.crewChangePlans,
  selectedCrewChange: state => state.selectedCrewChange,
  availableCrewList: state => state.availableCrewList,
  setUpCcPlans: state => state.setUpCcPlans,
  crewContract: state => UtilityService.capitalizeProperties(state.crewContract),
};

//TODO
// PMC-S1-00011
// PMC-S1-00012
// PMC-S1-00009
// PMC-S1-00008

export default {
  state,
  actions,
  mutations,
  getters
}
