import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  port: {},
  ports: [],
  newPort: {},

  portFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  portPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_ports(state, params) {
    state.ports = params
  },

  set_port(state, params) {
    state.port = params
  },

  create_port(state) {
    state.newPort = {};
  },

  update_port (state, params) {
    let index = state.ports.findIndex(val => val.id == params.id);
    state.ports.splice(index, 1, params);

  },

  delete_port (state, params) {
    let index = state.ports.findIndex((val, index) => val.id === params)
    state.ports.splice(index, 1)
  },

  null_ports(state) {
    state.ports = []
  },

  null_port(state) {
    state.port = {}
  },

  set_port_pagination(state, params) {
    state.portPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {
  async set_ports({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.PORT, {params}).then((r) => {
      let responseData = r.data;

      commit('set_port_pagination', responseData.ports)
      commit('set_ports', responseData.ports.data)
      commit('set_obj_countries',responseData.objCountries)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get ports')
      return false
    })
  },

  async create_port({commit}, params) {
    return axios.post(PMC.PORT, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_port', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create port');
      return false;
    })
  },

  async update_port({commit}, params) {
    return axios.put(`${PMC.PORT}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_port',r.data.updatedPort);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update port');
      return false;

    });
  },

  async delete_port ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Port','question');
    if(await _del){
      return axios.delete(`${PMC.PORT}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete Port');
        commit('delete_port',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete port");
        return false;

      })
    }
  },

  reset_port_pagination({commit}) {
    commit('set_flag_pagination')
  },

  null_ports({commit}) {
    commit('null_ports')
  },

  null_port({commit}) {
    commit('null_port')
  },
};


const getters = {
  ports: state => UtilityService.capitalizeProperties(state.ports),

  portPagination: state => state.portPagination,
  portFormParam: state => state.portFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
