import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  religion: {},
  religions: [],
  newReligion: {},

  religionFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },


  religionPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_religions(state, params) {
    state.religions = params
  },

  set_religion(state, params) {
    state.religion = params
  },

  create_religion (state) {
    state.newFlag = {};
  },

  update_religion (state, params) {
    let religion = state.religions.find((val, index) => val.id === params.id)
    Object.assign(religion, params)
  },

  delete_religion (state, params) {
    let index = state.religions.findIndex((val, index) => val.id === params)
    state.religions.splice(index, 1)
  },


  null_religions(state) {
    state.religions = []
  },

  null_religion(state) {
    state.religion = {}
  },

  set_religion_pagination(state, params) {
    state.religionPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {
  async set_religions({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.RELIGION, {params}).then((r) => {
      commit('set_religions', r.data.data)
      commit('set_religion_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get religions')
      return false
    })
  },

  async create_religion({commit}, params) {
    return axios.post(PMC.RELIGION, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_religion', r.data);
      return true;

    }).catch((er) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(er, 'Create religion');
      return false;
    })

  },

  async update_religion({commit}, params) {
    return axios.put(`${PMC.RELIGION}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_religion',r.data.updatedReligion);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update religion');
      return false;

    });
  },

  async delete_religion ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete religion','question');
    if(await _del){
      return axios.delete(`${PMC.RELIGION}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete religion');
        commit('delete_religion',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete rank");
        return false;

      })
    }
  },

  reset_religion_pagination({commit}) {
    commit('set_religion_pagination')
  },

  null_religions({commit}) {
    commit('null_religions')
  },

  null_religion({commit}) {
    commit('null_religion')
  },
};


const getters = {
  religions: state => UtilityService.capitalizeProperties(state.religions),

  religionPagination: state => state.religionPagination,
  religionFormParam: state => state.religionFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
