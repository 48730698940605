import Vue from 'vue';
import {AlertService} from './alert.service'
import {PMC_APP_KEYS} from '@/config/constants.config';

export const UtilityService = {
  caseException: [
    'status',
    'email',
    'route',
    'password',
    'username',
    'confirmPassword',
    'detail',
    'crewImg',
    'mobile_no_1',
    'mobile_no_2',
    'landline_1',
    'landline_2',
    'email_1',
    'email_2',
    'auth_image',
    'image',
    'hasExpiry',
    'isMonitored',
    'isNeedRank',
    'file',
    'docBlob',  //base64Image,
  ],


  capitalizeProperties(value) {
    try {
      Object.keys(value).forEach(key => {
        if (value[key] && typeof value[key] === 'string') {
          if (!this.caseException.includes(key)) {
            value[key] = (value[key]) ? String(value[key]).toUpperCase() : '';
          }
        } else {
          value[key] = this.capitalizeProperties(value[key]);
        }
      });
    } catch (e) {
      return value;
    }
    return value;
  },

  deCapitalizeProperties(value) {
    let newValue = {};
    try {
      Object.keys(value).forEach(key => {
        if (!this.caseException.includes(key) &&
          !(typeof value[key] === 'number')) {
          if (typeof value[key] === 'string') {
            newValue[key] = (value[key]) ? String(value[key]).toLowerCase() : '';
          } else if (value[key] === null) {
            newValue[key] = '';
          } else {
            newValue[key] = this.deCapitalizeProperties(value[key]);
          }
        } else {
          newValue[key] = value[key];
        }
      });
    } catch (e) {
      return newValue;
    }
    return newValue;
  },

  /**
   * @deprecated
   * @author dont use only for work around:
   *      code improve see app.config.js appAxios()
   */
  failProgressBar() {
    let newVue = new Vue({});
    newVue.$Progress.fail();
  },

  finishProgressBar() {
    let newVue = new Vue({});
    newVue.$Progress.finish();
  },

  generateResponseMessage(obj, actionType = '') {
    let stat = "";
    let msg = "";

    if (obj.response || obj.hasOwnProperty('response')) {
      stat = obj.response.status ? obj.response.status : obj.status;

      switch (stat) {
        case 200:
          //success
          AlertService.successAlertService(`${actionType} success`, actionType)
            .then(r => {
              return r
            });
          break;

        case 201:
          //success created
          AlertService.successAlertService(`${actionType} success`)
            .then(r => {
              return r
            });
          break;

        case 203:
          // NON_AUTHORITATIVE_INFORMATION
          console.log(203);
          break;

        case 204:
          console.log(204);
          break;

        case 301:
          console.log(301);
          break;

        case 400:
          this.handleReqErrors(obj.response.data.errors, actionType);
          return this.parseErrors(obj.response.data.errors);
          break;

        case 403:
          this.handleReqErrors(obj.response.data.errors, 'Forbidden');
          return this.parseErrors(obj.response.data.errors);
          break;

        case 405:
          this.handleReqErrors(obj.response.data.errors, 'Forbidden');
          return this.parseErrors(obj.response.data.errors);
          break;

        case 409:
          this.handleReqErrors(obj.response.data.errors, 'Conflict');
          return this.parseErrors(obj.response.data.errors);
          break;

        case 401:
          if (obj.response.data.hasOwnProperty('message')) {
            msg = obj.response.data.message;
          } else {
            msg = 'You dont have authorize to access this service';
          }
          AlertService.errorAlertService(msg, 'Unauthorized');


          break;

        case 404:
          AlertService.errorAlertService([], 'Not Found');
          break;

        case 422:
         return this.handleReqErrors(obj.response.data.errors, "Warning message");
          break;

        case 500:
          AlertService.errorAlertService('Internal server error', actionType);
          break;

        case 501:
          AlertService.errorAlertService('Failed to implement', actionType);
          break;

        case 503:
          AlertService.errorAlertService('Service un-available', actionType);
          break;

        default:
          AlertService.warningActionService('', 'Default');
          break;
      }

    }
  },


  handleReqErrors(erMsg, source) {
    let msgHtml = '';
    if (erMsg) {
      Object.keys(erMsg).forEach((key, index) => {
        msgHtml += `<p class="text-left">${index + 1}. ${erMsg[key]}</p>`;
      })
    }

    AlertService.errorWithResponseDataService(null, msgHtml, source, 'warning');
  },

  parseErrors(errors) {
    let erMsg = [];

    Array.isArray(errors) ? Object.keys(errors).forEach(column => erMsg[column] = errors[column].join(', ')) : erMsg = [];

    return {errors: erMsg};
  },

  titleCase(str) {
    if (str) {
      let splitStr = str.toLowerCase().split(' ');
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        if (splitStr[i] === 'And') {
          splitStr[i] = splitStr[i].toLowerCase();
        }
      }
      // Directly return the joined string
      return splitStr.join(' ');
    }
  },

  base64ToBlobParser(pdf_obj = null) {
    const binaryString = window.atob(pdf_obj.replace(/^data:application\/pdf;base64,/, ''));
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], {type: 'application/pdf'});
    // const fileUrl = URL.createObjectURL(blob);
    // return fileUrl;
    return blob;
  },

  base64FileToBlobUrl(base64File) {
    const blob = this.base64ToBlobParser(base64File);
    return URL.createObjectURL(blob);
  },


  isGte2mb(__blob) {
    // let demo = this.base64ToBlobParser(__blob)
    if (Math.round(__blob.size / 1024) >= 2048 || Math.round(__blob.size / 1024) >= 1024) {
      return true
    }
    return false
  },

  /**
   * @author return the persistent state of object into vuex,
   */
  originalPersistentState(__objectModule, __property) {
    let val = JSON.parse(localStorage.getItem(PMC_APP_KEYS.ENCRYPTED_KEY))
    if (val.hasOwnProperty(`${__objectModule}`)) {
      if (val[`${__objectModule}`].hasOwnProperty(`${__property}`)) {
        return val[`${__objectModule}`][`${__property}`]
      }
    }
  },

  /**
   * @author check if the object is empty
   */
  isObjectEmpty(obj) {
    for (let prop in obj) {
      if (Object.prototype.hasOwnProperty.call((obj, prop))) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({})
  },

}

export default {UtilityService}
