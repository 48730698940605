import axios from "axios";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";

const initState = () => {
  return {
    crewChangeRemarks: [],
    crewChangeRemark: {},
    newCrewChangeRemark: {
      remarks: null,
      crewChangeId: null,
      tagStatus: 1, //default as open.
    },
    tagStatusOpt: [
      {id: 1, name: "Open"},
      {id: 2, name: "Close"},
      {id: 3, name: "Cancel"},
    ],
    isCmRemarks: true,
  }
};

const state = {
  ...initState()
};

const mutations = {
  //get
  set_crew_change_remarks(state, payload) {
    state.crewChangeRemarks = payload;
  },

  set_crew_change_remark(state, payload) {
    let crewChangeRemark = state.crewChangeRemarks.find((val, index) => {
      return val.id === payload.id;
    })
    Object.assign(crewChangeRemark, payload)
  },

  create_crew_change_remark(state, payload) {
    state.newCrewChangeRemark = {};
    state.crewChangeRemarks.unshift(payload);
  },

  update_crew_change_remark(state, payload) {
    let remark = state.crewChangeRemarks.find((val, index) => {
      return val.id === payload.id
    })

    Object.assign(remark, payload)
  },

  delete_crew_change_remark(state, payload) {
    let index = state.crewChangeRemarks.findIndex((val, index) => {
      return val.id === payload.id
    })

    state.crewChangeRemarks.splice(index, 1);
  },


  set_is_cc_remarks(state, payload = true) {
    state.isCmRemarks = payload;
  },

  reset_state_crew_change_remark(state) {
    Object.assign(state, initState())
  }
};


const actions = {
  async set_crew_change_remarks({commit}, params) {
    commit('set_is_cc_remarks');
    return await axios.get(PMC.CREW_CHANGE_REMARK, {params}).then((r) => {
      commit('set_is_cc_remarks', false);
      commit('set_crew_change_remarks', r.data)
      return true;

    }).catch((er) => {

      commit('set_is_cc_remarks', false);
      return false;

    })
  },

  async set_crew_change_remark({commit}, params) {
    return await axios.get(`${PMC.CREW_CHANGE_REMARK}/${params.id}`).then((r) => {
      commit('set_crew_change_remark', r.data)
      return true;

    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");

      } else {
        UtilityService.generateResponseMessage(er, 'Get Remarks')
      }
      return false;
    })
  },

  set_form_crew_change_remark({commit}, payload) {
    commit('set_crew_change_remark', payload)
    return axios.get(`${PMC.CREW_CHANGE_REMARK}/${params.id}`).then((r) => {
      return true;

    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");

      } else {
        UtilityService.generateResponseMessage(er, 'Create remarks')
      }
      return false;
    })
  },


  create_crew_change_remark({commit}, params) {
    const formCcRemMeta = {
      crewChangeId: params.newCcRemarks.crewChangeId,
      selectedCcPlanType: params.selectedCcPlanType,
    }

    return axios.post(PMC.CREW_CHANGE_REMARK, params.newCcRemarks).then((r) => {
      commit('create_crew_change_remark', r.data);
      commit('add_cc_remarks_count', formCcRemMeta)
      return true;

    }).catch((er) => {

      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");

      } else {
        UtilityService.generateResponseMessage(er, 'Create remarks')
      }
      return false;

    })
  },

  update_crew_change_remark({commit}, params) {
    return axios.put(`${PMC.CREW_CHANGE_REMARK}/${params.id}`, params).then((r) => {
      commit('update_crew_change_remark', r.data)
      return true;

    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");

      } else {
        UtilityService.generateResponseMessage(er, 'Update remarks')
      }
      return false;
    })
  },

  delete_crew_change_remark({commit}, params) {
    const formCcRemMeta = {
      crewChangeId: params.ccRemarks.crewChangeId,
      selectedCcPlanType: params.selectedCcPlanType,
    }
    return axios.delete(`${PMC.CREW_CHANGE_REMARK}/${params.ccRemarks.id}`).then((r) => {
      commit('delete_crew_change_remark', r.data)
      commit('sub_cc_remarks_count', formCcRemMeta)
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");

      } else {
        UtilityService.generateResponseMessage(er, 'Delete remarks')

      }
      return false;
    })
  },

  reset_state_crew_change_remark({commit}) {
    commit('reset_state_crew_change_remark')
  },
};

const getters = {
  crewChangeRemarks: state => UtilityService.capitalizeProperties(state.crewChangeRemarks),
  crewChangeRemark: state => UtilityService.capitalizeProperties(state.crewChangeRemark),
  newCrewChangeRemark: state => state.newCrewChangeRemark,
  tagStatusOpt: state => state.tagStatusOpt,
  isCmRemarks: state => state.isCmRemarks,
};

export default {
  state,
  actions,
  mutations,
  getters
}
