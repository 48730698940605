<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row">
          <div class="col-4">
            <div class="form-group row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Crew Salary Matrix</span>
                </div>
                <div class="form-group">
                  <pmc-modal-btn v-if="formSearchDataCompliment.vesselId" :target-modal="newCrewComplimentModal"
                                 button-label="Add Crew Salary Matrix" button-class="btn btn-xs pmc-btn-tertia">
                    <template slot="button-icon">
                      <font-awesome-icon icon="plus"/>
                    </template>
                  </pmc-modal-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group row">
              <div class="col-sm-4 col-form-label">SELECT VESSEL</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <select name="vesselList"
                        id="vessel-list"
                        class="custom-select custom-select-sm"
                        v-model="formSearchDataCompliment.vesselId"
                        @change="onVessel" >
                  <option :value="null" >--Select Vessel--</option>
                  <option :value="vessel.id" v-for="(vessel,index) in objVessels" :key="vessel.id" :selected="formSearchDataCompliment.vessel === vessel.id">
                    {{vessel.name}}
                  </option>
                </select>
                <!--          <multi-select
                                  style="z-index: 200!important;"
                                  v-model="formSearchDataCompliment.vessel"
                                  :options="objVessels"
                                  :custom-label="nameLang"
                                  :select-label="'Select vessel'"
                                  placeholder="Select vessel"
                                  label="Vessel"
                                  @input="onVessel"
                                  track-by="id">
                                </multi-select>-->
              </div>
            </div>
            <div class="form-group row mt-1">
              <div class="col-sm-4 col-form-label">CONTRACT TYPE</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <select name="vesselList"
                        id="vessel-list"
                        class="custom-select custom-select-sm"
                        v-model="formSearchDataCompliment.contractType"
                        @change="onVessel" >
                  <option :value="'all'" >All</option>
                  <option :value="'poea'" >For POEA</option>
                  <option :value="'itf'" >For ITF</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <pmc-table class="py-5" :max-height="480" :loading="isLoadingCrewCompliment" :loader-colspan="8">
          <template v-slot:table-header>
            <tr>
              <th style="width: 5%">RANK</th>
              <th style="width: 15%">BASIC SALARY</th>
              <th style="width: 15%">FOT GOT</th>
              <th style="width: 10%">OT RATE</th>
              <th style="width: 15%">FIXED ALLOWANCE</th>
              <th style="width: 15%">SUBSTINENCE ALLOWANCE</th>
              <th style="width: 10%">LEAVE PAY</th>
              <th v-if="formSearchDataCompliment.vesselId === 145" style="width: 10%">SENIORITY</th>
              <th v-if="formSearchDataCompliment.vesselId === 145" style="width: 10%">MONTHLY SPF</th>
              <th style="width: 8%">ACTION</th>
            </tr>
          </template>
          <template slot="table-body">
            <tr v-if="crewCompliments == null ">
              <td v-if="formSearchDataCompliment.vesselId === 145" colspan="10">
                {{ formSearchDataCompliment.vesselId && crewCompliments == null ? 'No record found..' : 'Select vessel' }}
              </td>
              <td v-else colspan="8">
                {{ formSearchDataCompliment.vesselId && crewCompliments == null ? 'No record found..' : 'Select vessel' }}
              </td>
            </tr>
            <tr v-else v-for="(crewCompliment,index) in crewCompliments" :key="crewCompliment.id">
              <td class="text-center align-middle">{{ crewCompliment.rankName }}</td>
              <td>{{ crewCompliment.basicSalary }}</td>
              <td>{{ crewCompliment.fotGot }}</td>
              <td>{{ crewCompliment.otRate }}</td>
              <td>{{ crewCompliment.fixedAllowance }}</td>
              <td>{{ crewCompliment.substinenceAllowance }}</td>
              <td>{{ crewCompliment.leavePay }}</td>
              <td v-if="formSearchDataCompliment.vesselId === 145">{{ crewCompliment.seniority }}</td>
              <td v-if="formSearchDataCompliment.vesselId === 145">{{ crewCompliment.monthlySPF }}</td>
              <td>
                <pmc-modal-btn @modalButtonEvent="editCrewComp(crewCompliment)" :target-modal="viewCrewComplimentModal"
                               button-label="view" class="btn btn-xs pmc-btn-prime">
                  <template slot="button-icon">
                    <font-awesome-icon icon="bars"/>
                  </template>
                </pmc-modal-btn>
                <button
                  class="btn btn-xs pmc-btn-danger ml-2"
                  @click="deleteCrewComp(crewCompliment.id)">
                  <font-awesome-icon icon="trash"/>
                  delete
                </button>
              </td>
            </tr>
          </template>
        </pmc-table>
      </div>
    </div>

    <pmc-modal :id="newCrewComplimentModal" :show-header-button-close="false" m_width lg_height>
      <template slot="custom-header">
        <h4 class="font-weight-bold">New Salary Matrix Record</h4>
      </template>
      <template slot="custom-body">
        <div class="row">
          <div class="col-12 text-left mx-auto px-lg-5">

            <!--<div class="form-group row">
              <label for="qty" class="col-form-label col-md-4">Number</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="number" min="0" name="qty" id="qty" class="form-control form-control-sm"
                       v-model="newCrewCompliment.qty">
              </div>
            </div>-->

            <!-- rank -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">RANK</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <multi-select
                  style="z-index: 200!important;"
                  v-model="selectedRank"
                  :options="computedAvailableRanks"
                  :custom-label="nameLang"
                  :select-label="'Select rank'"
                  placeholder="Select rank"
                  label="Ranks"
                  @input="onNewCrewComplimentRank"
                  aria-required="true"
                  track-by="id">
                </multi-select>
              </div>
            </div>

            <!-- contract type -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">TYPE</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <select
                  class="form-control form-control"
                  v-model="newCrewCompliment.contractType">
                  <option :value="'all'">All</option>
                  <option :value="'poea'">For POEA Contract</option>
                  <option :value="'itf'">For ITF Contract</option>
                </select>
              </div>
            </div>

            <!-- basic salary -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">BASIC SALARY</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number"
                       class="form-control form-control-sm"
                       min="0"
                       @change="onChangeNewSalaryField(1)"
                       step="0.25"
                       v-model="newCrewCompliment.basicSalary"
                >
              </div>
            </div>

            <!-- fot got -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">FOT GOT</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control-sm"
                       min="0"
                       @change="onChangeNewSalaryField(2)"
                       step="0.25"
                       v-model="newCrewCompliment.fotGot"
                >
              </div>
            </div>

            <!-- ot rate -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">OT RATE</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control-sm"
                       min="0"
                       @change="onChangeNewSalaryField(3)"
                       step="0.25"
                       v-model="newCrewCompliment.otRate"
                >
              </div>
            </div>

            <!-- fixedAllowance -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">FIXED ALLOWANCE</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control-sm"
                       min="0"
                       @change="onChangeNewSalaryField(4)"
                       step="0.25"
                       v-model="newCrewCompliment.fixedAllowance"
                >
              </div>
            </div>

            <!-- substinenceAllowance  -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">SUBSTINENCE ALLOWANCE</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control-sm"
                       min="0"
                       @change="onChangeNewSalaryField(5)"
                       step="0.25"
                       v-model="newCrewCompliment.substinenceAllowance">
              </div>
            </div>

            <!-- leavePay  -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">LEAVE PAY</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control-sm"
                       min="0"
                       @change="onChangeNewSalaryField(6)"
                       step="0.25"
                       v-model="newCrewCompliment.leavePay">
              </div>
            </div>

            <!-- Seniority -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">SENIORITY (OG GINKGO)</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control"
                       min="0"
                       @change="onChangeNewSalaryField(7)"
                       step="0.25"
                       v-model="newCrewCompliment.seniority"
                >
              </div>
            </div>

            <!-- Monthly SPF -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">MONTHLY SPF (OG GINKGO)</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control"
                       min="0"
                       @change="onChangeNewSalaryField(8)"
                       step="0.25"
                       v-model="newCrewCompliment.monthlySPF"
                >
              </div>
            </div>

          </div>
        </div>
      </template>
      <template slot="custom-footer">
        <button class="btn btn-sm pmc-btn-tertia"
                @click="createNew">
          <font-awesome-icon icon="save"/>
          SAVE
        </button>
        <button
          @click="cancelCreateNew"
          class="btn btn-sm pmc-btn-second">
          <font-awesome-icon icon="undo"/>
          CLOSE
        </button>
      </template>
    </pmc-modal>

    <!-- todo view crew compliment-->
    <pmc-modal :id="viewCrewComplimentModal" :show-header-button-close="false" lg_width lg_height>
      <template slot="custom-header">
        <h4>Crew Compliment Details</h4>
      </template>
      <template slot="custom-body">
        <div class="row">
          <div class="col-12 text-left px-lg-5">
            <!-- rank -->
            <div class="form-group row">
              <div class="col-md-4 col-form-label font-weight-bold">RANK</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <multi-select
                  :disabled="!isEditable"
                  style="z-index: 200!important;"
                  v-model="computedViewRank"
                  :options="computedAvailableRanks"
                  :custom-label="nameLang"
                  :select-label="'Select rank'"
                  placeholder="Select rank"
                  label="Ranks"
                  track-by="id">
                </multi-select>
              </div>
            </div>

            <!-- contract type -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">TYPE</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <select
                  :disabled="!isEditable"
                  class="form-control form-control"
                  v-model="crewCompliment.contractType">
                  <option :value="'all'">All</option>
                  <option :value="'poea'">For POEA Contract</option>
                  <option :value="'itf'">For ITF Contract</option>
                </select>
              </div>
            </div>

            <!-- basic salary -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">BASIC SALARY</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number"
                       class="form-control form-control"
                       min="0"
                       :disabled="!isEditable"
                       @change="onChangeUpdateSalaryField(1)"
                       step="0.25"
                       v-model="crewCompliment.basicSalary"
                >
              </div>
            </div>

            <!-- fot got -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">FOT GOT</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number"
                       class="form-control form-control-sm"
                       min="0"
                       :disabled="!isEditable"
                       @change="onChangeNewSalaryField(2)"
                       step="0.25"
                       v-model="crewCompliment.fotGot"
                >
              </div>
            </div>

            <!-- ot rate -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">OT RATE</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control"
                       min="0"
                       :disabled="!isEditable"
                       @change="onChangeUpdateSalaryField(3)"
                       step="0.25"
                       v-model="crewCompliment.otRate"
                >
              </div>
            </div>

            <!-- fixedAllowance -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">FIXED ALLOWANCE</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control"
                       min="0"
                       :disabled="!isEditable"
                       @change="onChangeUpdateSalaryField(4)"
                       step="0.25"
                       v-model="crewCompliment.fixedAllowance"
                >
              </div>
            </div>

            <!-- substinenceAllowance  -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">SUBSTINENCE ALLOWANCE</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control"
                       min="0"
                       :disabled="!isEditable"
                       @change="onChangeUpdateSalaryField(5)"
                       step="0.25"
                       v-model="crewCompliment.substinenceAllowance"
                >
              </div>
            </div>

            <!-- leavePay  -->
            <div class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">LEAVE PAY</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control"
                       min="0"
                       :disabled="!isEditable"
                       @change="onChangeUpdateSalaryField(6)"
                       step="0.25"
                       v-model="crewCompliment.leavePay"
                >
              </div>
            </div>

            <!-- Seniority -->
            <div v-if="formSearchDataCompliment.vesselId === 145" class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">SENIORITY (OG GINKGO)</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control"
                       min="0"
                       :disabled="!isEditable"
                       @change="onChangeUpdateSalaryField(7)"
                       step="0.25"
                       v-model="crewCompliment.seniority"
                >
              </div>
            </div>
            <!-- Monthly SPF -->
            <div v-if="formSearchDataCompliment.vesselId === 145" class="form-group row mt-2">
              <div class="col-md-4 col-form-label font-weight-bold">MONTHLY SPF (OG GINKGO)</div>
              <div class="col-form-label">:</div>
              <div class="col-md-7">
                <input type="number" class="form-control form-control"
                       min="0"
                       :disabled="!isEditable"
                       @change="onChangeUpdateSalaryField(8)"
                       step="0.25"
                       v-model="crewCompliment.monthlySPF"
                >
              </div>
            </div>

          </div>
        </div>
      </template>

      <template slot="custom-footer">
        <div v-if="!isEditable">
          <button @click="setViewCrewCompEditable"
                  class="btn btn-sm pmc-bg-warning mr-2">
            <font-awesome-icon icon="edit"/>
            EDIT
          </button>
          <button @click="closeUpdateCrewComp"
                  class="btn btn-sm pmc-btn-second">
            <font-awesome-icon icon="undo"/>
            CLOSE
          </button>
        </div>
        <div v-if="isEditable">
          <button @click="updateCrewComp"
                  class="btn btn-sm pmc-btn-tertia mr-2">
            <font-awesome-icon icon="save"/>
            SAVE
          </button>
          <button @click="cancelUpdateCrewComp"
                  class="btn btn-sm pmc-btn-danger">
            <font-awesome-icon icon="times"/>
            CANCEL
          </button>
        </div>
      </template>

    </pmc-modal>
  </div>
</template>

<script>
import {AppMixins} from '@/mixins/app.mixins';
import {AlertMixins} from '@/mixins/alert.mixins';
import {mapActions, mapGetters} from 'vuex';
import __ from 'lodash';

export default {
  name: 'CrewCompliment',

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      newCrewComplimentModal: 'new-crew-compliment-modal',
      viewCrewComplimentModal: 'view-crew-compliment-modal',
      selectedVessel: null,
      selectedRank: null,
      isEditable: false,
      formSearchDataCompliment: {
        vesselId: null,
        contractType: 'all'
      }

    };
  },

  /** methods**/
  methods: {
    ...mapActions(
      [
        'getCrewComplimentPerVessel',
        'resetNewCrewCompliment',
        'resetComplimentState',
        'createCrewVesselCompliment',
        'updateCrewVesselCompliment',
        'deleteCrewVesselCompliment',
        'setCrewCompliment',
        'null_obj_vessels',
      ],
    ),

    loadCrewCompliment() {
      const payload = {
        vesselId: this.formSearchDataCompliment.vesselId,
        contractType: this.formSearchDataCompliment.contractType,
      };
      this.getCrewComplimentPerVessel(payload);
    },

    nameLang({name}) {
      return `${name}`;
    },

    onVessel() {
      this.loadCrewCompliment();
    },

    onNewCrewComplimentRank() {
      this.newCrewCompliment.rankId = this.selectedRank?.id;
    },

    async createNew() {
      let msg = 'Are you sure you want to add this record?';
      this.newCrewCompliment.vesselId = this.formSearchDataCompliment.vesselId;
      if (this.selectedRank == null) {
        this.warningAlert("Please select a valid rank.")
      } else {
        if (await this.questionAlert(msg, 'Create new crew compliment')) {
          this.createCrewVesselCompliment(this.newCrewCompliment).then((r) => {
            if (r) {
              this.selectedRank = null;
              this.hideModal(this.newCrewComplimentModal);
              this.resetNewCrewCompliment();
              this.successAlert('Adding crew compliment successfully')
            }
          })
        }
      }
    },

    cancelCreateNew() {
      this.hideModal(this.newCrewComplimentModal);
      this.resetNewCrewCompliment();
      this.selectedRank = null;
    },

    setTwoNumDecimal(__value) {
      return parseFloat(__value).toFixed(2);
    },

    onChangeNewSalaryField(__type) {
      switch (__type) {
        case 1:
          this.newCrewCompliment.basicSalary = this.setTwoNumDecimal(this.newCrewCompliment.basicSalary);
          break;

        case 2:
          this.newCrewCompliment.fotGot = this.setTwoNumDecimal(this.newCrewCompliment.fotGot);
          break;

        case 3:
          this.newCrewCompliment.otRate = this.setTwoNumDecimal(this.newCrewCompliment.otRate);
          break;

        case 4:
          this.newCrewCompliment.fixedAllowance = this.setTwoNumDecimal(this.newCrewCompliment.fixedAllowance);
          break;

        case 5:
          this.newCrewCompliment.substinenceAllowance = this.setTwoNumDecimal(this.newCrewCompliment.substinenceAllowance);
          break;
        case 6:
          this.newCrewCompliment.leavePay = this.setTwoNumDecimal(this.newCrewCompliment.leavePay);
          break;
        case 7:
          this.newCrewCompliment.seniority = this.setTwoNumDecimal(this.newCrewCompliment.seniority);
          break;
        case 8:
          this.newCrewCompliment.monthlySPF = this.setTwoNumDecimal(this.newCrewCompliment.monthlySPF);
          break;
      }
    },

    onChangeUpdateSalaryField(__type) {
      switch (__type) {
        case 1:
          this.crewCompliment.basicSalary = this.setTwoNumDecimal(this.crewCompliment.basicSalary);
          break;

        case 2:
          this.crewCompliment.fotGot = this.setTwoNumDecimal(this.crewCompliment.fotGot);
          break;

        case 3:
          this.crewCompliment.otRate = this.setTwoNumDecimal(this.crewCompliment.otRate);
          break;

        case 4:
          this.crewCompliment.fixedAllowance = this.setTwoNumDecimal(this.crewCompliment.fixedAllowance);
          break;

        case 5:
          this.crewCompliment.substinenceAllowance = this.setTwoNumDecimal(this.crewCompliment.substinenceAllowance);
          break;

        case 6:
          this.crewCompliment.leavePay = this.setTwoNumDecimal(this.crewCompliment.leavePay);
          break;

        case 7:
          this.crewCompliment.seniority = this.setTwoNumDecimal(this.crewCompliment.seniority);
          break;

        case 8:
          this.crewCompliment.monthlySPF = this.setTwoNumDecimal(this.crewCompliment.monthlySPF);
          break;
      }
    },

    setViewCrewCompEditable() {
      this.isEditable = true;
    },

    //view
    editCrewComp(__objCrewCompliment) {
      this.setCrewCompliment(__objCrewCompliment);
    },

    async updateCrewComp() {
      let msg = "Do you want to save the changes you made"
      if (await this.questionAlert(msg, 'Update details')) {
        this.updateCrewVesselCompliment(this.crewCompliment).then((r) => {
          if (r) {
            this.isEditable = false;
            this.hideModal(this.viewCrewComplimentModal);
            this.successAlert('Update crew compliment details successfully')
          }
        })
      }
    },

    cancelUpdateCrewComp() {
      this.isEditable = false;
      let orgCrewComplimentState = this.originalPersistentStateForm('CrewCompliment', 'crewCompliment');
      this.setCrewCompliment(orgCrewComplimentState);
    },

    closeUpdateCrewComp() {
      this.hideModal(this.viewCrewComplimentModal);
    },

    async deleteCrewComp(__val) {
      let msg = "Are you sure you want to delete this record";
      if (await this.questionAlert(msg, 'Delete crew compliment')) {
        this.deleteCrewVesselCompliment(__val).then((r) => {
          if (r) {
            this.successAlert('Delete crew compliment record success')
          }
        })
      }
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objVessels',
        'objRanks',
        'formSearchDataCompliment',
        'crewCompliments',
        'crewCompliment',
        'newCrewCompliment',
        'isLoadingCrewCompliment',
      ],
    ),
    computedAvailableRanks() {
      if (this.crewCompliments !== null) {
        const usedRankIds = this.crewCompliments.map(crewCompliment => crewCompliment.rankId);
        return this.objRanks.filter(rank => !usedRankIds.includes(rank.id));
      }
      return [];
    },
    computedViewRank: {
      get() {
        return this.crewCompliment.rankId
          ? this.objRanks.find((v, k) => {
            return v.id === this.crewCompliment.rankId;
          })
          : this.selectedRank;
      },

      set(val) {
        this.crewCompliment.rankId = val ? val.id : null;
        this.selectedRank = val;
      },
    },
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  created() {
    this.loadCrewCompliment();
  },

  /** watch
   searchText : {
			handler : 'fetchUserList',
			immediate : true,
		}
   **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.resetComplimentState();
    this.null_obj_vessels();
  },

};
</script>

<style scoped>

</style>
