import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";
import {PMC} from "@/config/constants.config";
import axios from "axios";

const initState = () => {
  return {
    applicantDetails : {},
    isApplicant : false,
    isEditableApplicant : false,
  }
};

const initApplicantKeys = () => {
  return {
    objApplicantStatusKeys : [],
    objApplicantTypeKeys : [],
    objEndorsementType : [],
    objPmcOffice : [],
  }
}

const initEndorserKey = () =>{
  return {
    objEndorsers : [],
  }
}

const initEndorseKeyPagination = () =>{
  return {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  }
}

const state = {
  ...initState(),
  ...initApplicantKeys(),
  ...initEndorserKey(),

    objEndorserPagination : initEndorseKeyPagination()
};

const mutations = {
  set_application_details(state,payload){
    state.applicantDetails = payload;
  },

  set_is_editable_applicant(state,payload = false){
    state.isApplicant = payload
  },

  set_is_applicant(state,payload = false){
    state.isEditableApplicant = payload
  },

  update_applicant_details(state,payload){
    state.applicantDetails = payload;
  },

  reset_state_applicant_details(state){
    Object.assign(state,...initState());
  },

  set_obj_applicant_keys(state,payload){
    state.objApplicantStatusKeys = payload.objApplicantStatusKeys;
    state.objApplicantTypeKeys = payload.objApplicantTypeKeys;
    state.objEndorsementType = payload.objEndorsementType;
    state.objPmcOffice = payload.objPmcOffice
    // state.objEndorsers.push(payload.objEndorserPagination.data)
  },

  set_obj_endorsers(state,payload){
    state.objEndorsers = payload;
  },

  set_obj_endorser_pagination(state,payload){
    state.objEndorserPagination = {
      from: payload.from,
      currentPage: payload.current_page,
      lastPage: payload.last_page,
      perPage: payload.per_page,
      to: payload.to,
      total: payload.total,
    };
  },

  reset_obj_applicant_keys(state){
    Object.assign(state,initApplicantKeys)
  },

  reset_init_endorser_key(state){
    Object.assign(state,
      initEndorserKey()
    )
  }
};

const actions = {
  async set_application_details({commit}, params) {
    return await axios.get(`${PMC.GET_CREW_APPLICATION_DETAILS}/${params}`).then((r)=>{
      commit('set_application_details',r.data.applicantDetails);
      commit('set_obj_applicant_keys',r.data);
      commit('set_obj_applicant_keys',r.data);
      commit('set_obj_endorser_pagination',r.data.objEndorserPagination);
      commit('set_obj_endorsers',r.data.objEndorserPagination.data);
      return true;

    }).catch((er)=>{
      if(!er.response){
        AlertService.errorAlertService("Cant establish server connection")

      }else {
        UtilityService.generateResponseMessage(er,"Get Available Crew list")
      }
      return false;
    })
  },

  //TODO : set_is_editable_applicant
  set_is_editable_applicant({commit},params) {
    commit('set_is_editable_applicant',params)
  },

  //TODO : set_is_applicant
  set_is_applicant({commit},params) {
    commit('set_is_applicant',params)
  },

  //UPDATE
  update_applicant_details({commit},params){
    return axios.post(`${PMC.UPDATE_APPLICANT_DETAILS}/${params.applicantTrackingId}`,params).then((r)=>{
      commit('update_applicant_details',r.data);
      return true;

    }).catch((er)=>{
      if(!er.response){
        AlertService.errorAlertService("Cant establish server connection")
      }else {
        UtilityService.generateResponseMessage(er,"Update Applicant Details")
      }
      return false;
    })
  },

  //same endpoint with crew paginated list
  get_endorsers_list({commit},params){
      return axios.post(PMC.GET_ALL_CREW_PAGINATED,params).then((r)=>{
        commit('set_obj_endorsers',r.data.data);
        commit('set_obj_endorser_pagination',r.data);
        return true;
      }).catch((er)=>{
        if(!er.response){
          AlertService.errorAlertService("Cant establish server connection")
        }else {
          UtilityService.generateResponseMessage(er,"Get Available Crew list")
        }
        return false;
      })
  },


  reset_state_applicant_details({commit}) {
    commit('reset_state_applicant_details')
  },

  reset_obj_applicant_keys({commit}) {
    commit('reset_obj_applicant_keys')
  },

  reset_init_endorser_key({commit}){
    commit('reset_init_endorser_key')
  }
};

const getters = {
  applicantDetails: state => UtilityService.capitalizeProperties(state.applicantDetails),
  isApplicant: state => state.isApplicant,
  isEditableApplicant: state => state.isEditableApplicant,

  objApplicantStatusKeys: state => UtilityService.capitalizeProperties(state.objApplicantStatusKeys),
  objApplicantTypeKeys: state => UtilityService.capitalizeProperties(state.objApplicantTypeKeys),
  objPmcOffice: state => UtilityService.capitalizeProperties(state.objPmcOffice),
  objEndorsementType: state => UtilityService.capitalizeProperties(state.objEndorsementType),
  objEndorsers: state => UtilityService.capitalizeProperties(state.objEndorsers),
  objEndorserPagination: state => UtilityService.capitalizeProperties(state.objEndorserPagination),
};

export default {
  state,
  actions,
  mutations,
  getters
}
