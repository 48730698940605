import axios from "axios";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";

const initState = () => {
  return {
    crewSeaService : {},
    crewSeaServices : [],

    seaServiceAvailableRankKeys: [],
    // newCrewSeaService : {} //optional
  }
};

const state = {
  ...initState()
};


const mutations = {
  set_crew_sea_services(state,payload){
    state.crewSeaServices = payload;
  },

  set_crew_sea_service (state,payload){
    state.crewSeaService   = payload;
  },

  set_available_crew_service_rank_keys (state,payload){
    state.seaServiceAvailableRankKeys   = payload;
  },

  //create_crew_sea_service(state,payload){}, optional
  //update_crew_sea_service(state,payload){} optional

  reset_state_crew_sea_service(state){
    Object.assign(state,initState())
  },
};


const actions = {
 async set_crew_sea_services({commit}, params) {
   return await axios.get(PMC.GET_CREW_SEA_SERVICES, {params})
     .then((r)=>{
      commit('set_crew_sea_services',r.data);
     return true;
   }).catch((er)=>{
       UtilityService.generateResponseMessage(er, 'Get Crew Sea Service')
       return false;
   })
  },

  async generateSeaServiceReport({commit}, params) {
    return await axios.post(PMC.GENERATE_SEA_SERVICE_REPORT, params).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate gears prpo')
      return false
    });
  },

  async getSeaServicesRanksAvailableKeys({commit}, params) {
    return await axios.get(PMC.GET_SEA_SERVICES_RANKS_AVAILABLE, {params}).then((res) => {
      commit('set_available_crew_service_rank_keys', res.data)
      return true
    }).catch((er) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(er, 'Get sea service rank available  keys')
      return false
    })
  },

  async updateSeaServiceDates(params) {
    return await axios.post(PMC.UPDATE_SEA_SERVICE_DATES, params).then(r => {
      return r.data ?? null;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Sea Service')
      return false
    });
  },

  set_crew_sea_service(state, params) {
    //TODO set_crew_sea_service
  },

  /**
   * @author Optional
   *   //create
   *   //update
   *   //delete
   */


  reset_state_crew_sea_service({commit}) {
    commit('reset_state_crew_sea_service')
  }
};

const getters = {
  crewSeaService : state => state.crewSeaService,
  crewSeaServices : state => UtilityService.capitalizeProperties(state.crewSeaServices),
  seaServiceAvailableRankKeys : state => UtilityService.capitalizeProperties(state.seaServiceAvailableRankKeys),
};

export default {
  state,
  actions,
  mutations,
  getters
}
