<template>
  <div class="container-fluid">
    <p class="font-weight-bold my-3">Crew Scanned Checklist History</p>
    <pmc-table :max-height="400">
      <template v-slot:table-header>
        <th>Crew Name</th>
        <th>Vessel Line Up</th>
        <th>CCM No</th>
        <th>Made Date</th>
        <th>Made By</th>
        <th>File</th>
      </template>
      <template v-slot:table-body>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <a href=""><font-awesome-icon icon="file" class="icon-margin-top"/></a>
          </td>
        </tr>
      </template>
    </pmc-table>
  </div>
</template>

<script>
export default {
  name: 'CrewScannedChecklist',

  data () {
    return {}
  },

  methods: {},

  computed: {},

  mounted () {
  },

  created () {
  },

  destroyed () {
  },

}
</script>

<style scoped>

</style>
