<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-3">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Gears PR/PO</span>
                </div>
              </div>

            </div>
          </div>

          <!-- vessels -->
          <div class="col-sm-3 mt-1">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Vessel</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select class="form-control custom-select-sm"
                        @change="on_change_select_vessel($event.target.value, $event.target.selectedOptions[0].text)"
                >
                  <option :value="null" class="text-center align-middle">--SELECT VESSEL--</option>
                  <option :value="vessel.id" v-for="(vessel,index) in objVessels" :key="vessel.id">
                    {{ vessel.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <template v-if="recentCrewChangePlanKeysParam.vesselId">
            <div class="col-sm-3 mt-1">
              <div class="form-group row">
                <label for="list-count" class="col-form-label">Active CCM No</label>
                <span class="col-form-label">:</span>
                <div class="col-sm-7 text-left">
                  <select name="list-type" id="list-type" class="form-control custom-select-sm"
                          @change="on_change_select_crew_batch($event.target.value, $event.target.selectedOptions[0].text)"
                          v-model="recentCrewBatchesParam.crewBatchId"
                          @click="displayActiveCcmNo"
                          required>
                    <option value="null" class="text-center align-middle">--SELECT CCM NO--</option>
                    <option :value="recentChangePlanKey.crew_batch_id"
                            v-for="(recentChangePlanKey,index) in objRecentCrewChangePlanKeys"
                            :key="recentChangePlanKey.crew_batch_id"
                            style="font-size: 14px;">
                      {{ recentChangePlanKey.ccm_no }}
                    </option>
                  </select>
                </div>

              </div>
            </div>

            <div class="col-sm-2 mr-3 mt-1">
              <div class="form-group row">
                <div class="input-group mb-2">
                  <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Search CCM No"
                      v-model="recentCrewBatchesParam.ccmNo"
                      @keydown.enter="searchCcmNo"
                  >

                  <button class="btn btn-sm pmc-btn-prime"
                          @click="searchCcmNo"
                  >
                    <font-awesome-icon icon="search"/>
                  </button>
                </div>
              </div>
            </div>

          </template>

          <!--          <div class="col-sm-3">-->
          <!--            <div class="input-group mb-2 mr-sm-2">-->
          <!--              <input-->
          <!--                type="text"-->
          <!--                class="form-control form-control-sm"-->
          <!--                id="formdata-searchvalue"-->
          <!--                placeholder="Search Crew Change"-->
          <!--              >-->

          <!--              <button class="btn btn-sm pmc-btn-prime">-->
          <!--                <font-awesome-icon icon="search"/>-->
          <!--                search-->
          <!--              </button>-->
          <!--            </div>-->
          <!--          </div>-->

        </div>

        <hr/>


        <!--        <template v-for="(type, index) in gearTypeTemplates">-->
        <!--        <template v-for="(rate, colorIndex) in type.rates">-->

        <!--          <template v-if="rate">{{rate.towel_usage_id }}-->
        <!--          -->
        <!--          </template>-->

        <!--        </template>-->
        <!--        </template>-->


        <div class="row mt-5">

          <div class="col-sm-4">
            <div class="row">

              <div class="col-sm-4">

                <div class="form-inline mb-3">
                  <div class="form-group mb-2 mr-5">
                    <span class="font-weight-bold lead" style="font-size: 18px;">{{ selectedVesselName }} </span>
                    <button type="button" class="btn btn-xs pmc-btn-tertia ml-4 "
                            @click="loadGearsPRPO"
                            v-if="showGenerateButton"
                    >
                      <font-awesome-icon icon="file"/>
                      Generate Report
                    </button>
                  </div>
                </div>

              </div>


            </div>
          </div>


          <!-- vessels -->
          <div class="col-sm-4">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Rank Type</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        @change="on_change_select_rank_type($event.target.value)"
                        v-model="recentCrewBatchesParam.rankType"
                >
                  <option value="null" class="text-center align-middle">--SELECT RANK TYPE--</option>
                  <option :value="type.id" v-for="(type,index) in objRankTypes" :key="type.id"
                          style="font-size: 14px;">
                    <span> {{ type.name }}</span>
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Supplier</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="list-type" id="list-type" class="form-control custom-select-sm"
                        v-model="gearComplementTemplateParam.supplierId"
                        @change="on_change_select_supplier($event.target.value, $event.target.selectedOptions[0].text)"
                        required>
                  <option value="null" class="text-center align-middle">--SELECT SUPPLIER--</option>
                  <option :value="supplier.id" v-for="(supplier,index) in objGearSuppliers" :key="supplier.id"
                          style="font-size: 14px;">
                    <span> {{ supplier.name }}</span>
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!--          <div class="col-sm-3">-->
          <!--            <button type="button" class="btn btn-xs btn-primary"-->
          <!--                    @click="updateGearsPRPOList"-->
          <!--            >-->
          <!--              <font-awesome-icon icon="file"/>-->
          <!--              refresh-->
          <!--            </button>-->
          <!--          </div>-->




        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <!--                <pmc-pagination :page-object="tradeRoutePagination" @settingPage="set_this_page"></pmc-pagination>-->
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">


          <!--          style="border-left:0.1px Solid white;border-right:0.1px Solid white; border-top:0.1px Solid white;"-->

          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">

                <template v-if="isReloading">
                  <table
                      class="table table-bordered table-sm text-center small sticky-header table-hover"
                  >
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </table>
                </template>


                <template v-else>
                  <table
                      class="table table-bordered table-sm text-center small sticky-header-gears-prpo table-hover"
                      v-if="gearTypeTemplates.length > 0"
                  >
                    <thead>
                    <!--                  <tr>-->
                    <!--                    <td width="18px"></td>-->
                    <!--                    <td width="168px"></td>-->
                    <!--                    <td width="37.5px"></td>-->
                    <!--                    <td width="69px"></td>-->
                    <!--                    <td width="15px"></td>-->
                    <!--                    <td width="52.5px"></td>-->
                    <!--                    <td width="21px"></td>-->
                    <!--                    <td width="52.5px"></td>-->
                    <!--                    <td width="15px"></td>-->
                    <!--                    <td width="52.5px"></td>-->
                    <!--                    <td width="18px"></td>-->
                    <!--                    <td width="52.5px"></td>-->
                    <!--                    <td width="21px"></td>-->
                    <!--                    <td width="52.5px"></td>-->
                    <!--                    <td width="21px"></td>-->
                    <!--                    <td width="52.5px"></td>-->
                    <!--                    <td width="21px"></td>-->
                    <!--                    <td width="52.5px"></td>-->
                    <!--                    <td width="21px"></td>-->
                    <!--                    &lt;!&ndash;<td width=""></td>&ndash;&gt;-->
                    <!--                    <td width="52.5px"></td>-->
                    <!--                    <td width="21px"></td>-->
                    <!--                    <td width="52.5px"></td>-->
                    <!--                    <td width="21px"></td>-->
                    <!--                    <td width="52.5px"></td>-->
                    <!--                    <td width="45px"></td>-->
                    <!--                    <td width="45px"></td>-->
                    <!--                    <td width="67.5px"></td>-->
                    <!--                    <td width="67.5px"></td>-->
                    <!--                    <td width="67.5px"></td>-->
                    <!--                    <td width="67.5px"></td>-->
                    <!--                    <td width="67.5px"></td>-->
                    <!--                  </tr>-->

                    <tr>
                      <th rowspan="3"></th>
                      <th rowspan="3">Name of Crew
                      </th>
                      <th rowspan="3">Rank</th>

                      <template v-for="(type, index) in gearTypeTemplates">

                        <!--                      sampleDataItem-->
                        <!--                      <th :rowspan="2">{{ type.name }}</th>-->

                        <th :colspan="type.spanCount"
                            v-if="type.id == travelling_bag"
                        >
                          {{ type.name }}
                        </th>

                        <th :colspan="type.spanCount"
                            v-if="type.id == coverall"
                        >
                          {{ type.name }}
                        </th>

                        <th :colspan="type.spanCount"
                            v-if="type.id == jacket"
                        >
                          {{ type.name }}
                        </th>

                        <th :colspan="type.spanCount"
                            v-if="type.id == s_sleeve"
                        >
                          {{ type.name }}
                        </th>

                        <th :colspan="type.spanCount"
                            v-if="type.id == l_sleeve"
                        >
                          {{ type.name }}
                        </th>
                        <th :colspan="type.spanCount"
                            v-if="type.id == pants"
                        >
                          {{ type.name }}
                        </th>


                        <th :colspan="type.spanCount"
                            v-if="type.id == shoes"
                        >
                          {{ type.name }}
                        </th>

                        <th :colspan="type.spanCount"
                            v-if="type.id == steward_uniform"
                        >
                          {{ type.name }}
                        </th>

                        <th :colspan="type.spanCount"
                            v-if="type.id == hat_hairnet"
                        >
                          {{ type.name }}
                        </th>

                        <th :colspan="type.spanCount"
                            v-if="type.id == towels"
                        >
                          {{ type.name }}
                        </th>


                      </template>


                      <th rowspan="3">Total Amount
                      </th>
                      <th rowspan="3">Date Rcvd</th>
                      <th rowspan="3">Rcvd By</th>
                    </tr>

                    <tr>

                      <template v-for="(type, index) in gearTypeTemplates">

                        <!--<editor-fold desc="coverall color">-->
                        <th :colspan="type.spanCount" v-if="type.id == travelling_bag"> -</th>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="coverall color">-->
                        <template v-for="(color, colorIndex) in type.colors">
                          <th colspan="2" v-if="color != null && type.id == coverall"> {{ color }}</th>

                          <th colspan="2" v-if="color == null && type.id == coverall"> -</th>

                        </template>
                        <!--</editor-fold>-->


                        <!--<editor-fold desc="jacket color">-->
                        <template v-for="(color, colorIndex) in type.colors">
                          <th colspan="2" v-if="color != null && type.id == jacket"> {{ color }}</th>

                          <th colspan="2" v-if="color == null && type.id == jacket"> -</th>

                        </template>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="s_sleeve color">-->
                        <template v-for="(color, colorIndex) in type.colors">
                          <th colspan="2" v-if="color != null && type.id == s_sleeve"> {{ color }}</th>

                          <th colspan="2" v-if="color == null && type.id == s_sleeve"> -</th>

                        </template>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="l_sleeve color">-->
                        <template v-for="(color, colorIndex) in type.colors">
                          <th colspan="2" v-if="color != null && type.id == l_sleeve"> {{ color }}</th>

                          <th colspan="2" v-if="color == null && type.id == l_sleeve"> -</th>

                        </template>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="pants color">-->
                        <template v-for="(color, colorIndex) in type.colors">
                          <th colspan="2" v-if="color != null && type.id == pants"> {{ color }}</th>

                          <th colspan="2" v-if="color == null && type.id == pants"> -</th>

                        </template>
                        <!--</editor-fold>-->


                        <!--<editor-fold desc="shoes color">-->
                        <template v-for="(brand, colorIndex) in type.brands">
                          <th colspan="2" v-if="brand != null && type.id == shoes"> {{ brand }}</th>

                          <th colspan="2" v-if="brand == null && type.id == shoes"> -</th>

                        </template>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="steward_uniform color">-->
                        <template v-for="(rankSteward, colorIndex) in type.ranks">
                          <th colspan="2" v-if="rankSteward != null && type.id == steward_uniform"> {{
                              rankSteward
                            }}
                          </th>

                          <th colspan="2" v-if="rankSteward == null && type.id == steward_uniform"> -</th>

                        </template>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="hat_hairnet color">-->
                        <template v-for="(rankHat, colorIndex) in type.ranks">
                          <th colspan="1" v-if="rankHat != null && type.id == hat_hairnet"> {{ rankHat }}</th>

                          <th colspan="1" v-if="rankHat == null && type.id == hat_hairnet"> -</th>
                          <th colspan="1" v-if="rankHat == null && type.id == hat_hairnet"> -</th>
                        </template>
                        <!--</editor-fold>-->


                        <!--<editor-fold desc="towels bath face">-->
                        <template v-for="(typeRate, colorIndex) in type.rates">
                          <!--                          <th colspan="1" v-if="towelUsage != null && type.id == towels"> {{ towelUsage }}</th>-->
                          <template v-if="typeRate">
                            <template v-if="type.rates.length == 2">
                              <th colspan="1" v-if="type.id == towels && typeRate.towel_usage_id == 1"> Bath</th>
                              <th colspan="1" v-if="type.id == towels && typeRate.towel_usage_id == 2"> Face</th>
                            </template>

                            <template v-if="type.rates.length < 2 && typeRate.towel_usage_id == 1">
                              <th colspan="1" v-if="type.id == towels && typeRate.towel_usage_id == 1"> Bath</th>
                              <th colspan="1" v-if="type.id == towels"> Face</th>
                            </template>

                            <template v-if="type.rates.length < 2 && typeRate.towel_usage_id == 2">
                              <th colspan="1" v-if="type.id == towels"> Bath5</th>
                              <th colspan="1" v-if="type.id == towels && typeRate.towel_usage_id == 2"> Face</th>
                            </template>
                          </template>

                        </template>

                        <template v-if="type.rates[0] == null">
                          <th colspan="1" v-if="type.id == towels"> Bath</th>
                          <th colspan="1" v-if="type.id == towels"> Face</th>
                        </template>

                        <!--</editor-fold>-->
                      </template>


                    </tr>
                    <tr>

                      <template v-for="(type, index) in gearTypeTemplates">
                        <template v-if="type.rates">
                          <template v-for="(rate, index) in type.rates">


                            <!--<editor-fold desc="travelling bag rate">-->
                            <td :colspan="2" v-if="rate !== null && type.id == travelling_bag">
                              <!--                            && rate.rank_type_id == recentCrewBatchesParam.rankType-->
                              <span style="color: #007BFF">
                            ${{ rate.rate }}
                            </span>
                            </td>
                            <td colspan="2" v-if="!rate && type.id == travelling_bag">
                              <span style="color: #007BFF">-</span>
                            </td>

                            <!--</editor-fold>-->


                            <!--<editor-fold desc="coverall rate">-->
                            <td :colspan="2"
                                v-if="rate !== null && type.id == coverall">
                            <span style="color: #007BFF">
                            ${{ rate.rate }}
                            </span>
                            </td>

                            <td colspan="2" v-if="!rate && type.id == coverall">
                              <span style="color: #007BFF">-</span>
                            </td>

                            <!--</editor-fold>-->


                            <!--<editor-fold desc="jacket rate">-->
                            <td :colspan="2"
                                v-if="rate !== null && type.id == jacket">
                            <span style="color: #007BFF">
                            ${{ rate.rate }}
                            </span>
                            </td>

                            <td colspan="2" v-if="!rate && type.id == jacket">
                              <span style="color: #007BFF">-</span>
                            </td>

                            <!--</editor-fold>-->

                            <!--<editor-fold desc="s_sleeve rate">-->
                            <td :colspan="2"
                                v-if="rate !== null && type.id == s_sleeve">
                            <span style="color: #007BFF">
                            ${{ rate.rate }}
                            </span>
                            </td>

                            <td colspan="2" v-if="!rate && type.id == s_sleeve">
                              <span style="color: #007BFF">-</span>
                            </td>

                            <!--</editor-fold>-->

                            <!--<editor-fold desc="l_sleeve rate">-->
                            <td :colspan="2"
                                v-if="rate !== null && type.id == l_sleeve">
                            <span style="color: #007BFF">
                            ${{ rate.rate }}
                            </span>
                            </td>

                            <td colspan="2" v-if="!rate && type.id == l_sleeve">
                              <span style="color: #007BFF">-</span>
                            </td>

                            <!--</editor-fold>-->

                            <!--<editor-fold desc="pants rate">-->
                            <td :colspan="2"
                                v-if="rate !== null && type.id == pants">
                            <span style="color: #007BFF">
                            ${{ rate.rate }}
                            </span>
                            </td>

                            <td colspan="2" v-if="!rate && type.id == pants">
                              <span style="color: #007BFF">-</span>
                            </td>

                            <!--</editor-fold>-->

                            <!--<editor-fold desc="shoes rate">-->
                            <td :colspan="2"
                                v-if="rate !== null && type.id == shoes">
                            <span style="color: #007BFF">
                            ${{ rate.rate }}
                            </span>
                            </td>

                            <td colspan="2" v-if="!rate && type.id == shoes">
                              <span style="color: #007BFF">-</span>
                            </td>

                            <!--</editor-fold>-->


                            <!--<editor-fold desc="steward_uniform rate">-->
                            <td colspan="2"
                                v-if="rate !== null && type.id == steward_uniform">
                            <span style="color: #007BFF">
                            ${{ rate.rate }}
                            </span>
                            </td>

                            <td colspan="2" v-if="!rate && type.id == steward_uniform">
                              <span style="color: #007BFF">-</span>
                            </td>

                            <!--</editor-fold>-->


                            <!--<editor-fold desc="hat_hairnet rate">-->
                            <td :colspan="1"
                                v-if="rate !== null && type.id == hat_hairnet">
                            <span style="color: #007BFF">
                            ${{ rate.rate }}
                            </span>
                            </td>

                            <td colspan="1" v-if="!rate && type.id == hat_hairnet">
                              <span style="color: #007BFF">-</span>
                            </td>

                            <td colspan="1" v-if="!rate && type.id == hat_hairnet">
                              <span style="color: #007BFF">-</span>
                            </td>

                            <!--</editor-fold>-->


                          </template>

                        </template>


                        <!--<editor-fold desc="towels rate">-->
                        <template v-for="(typeRate, colorIndex) in type.rates">
                          <template v-if="typeRate">
                            <template v-if="type.rates.length == 2">
                              <th colspan="1" v-if="type.id == towels && typeRate.towel_usage_id == 1"
                                  style="color: #007BFF; font-weight: normal">
                                ${{
                                  typeRate.rate
                                }}
                              </th>
                              <th colspan="1" v-if="type.id == towels && typeRate.towel_usage_id == 2"
                                  style="color: #007BFF; font-weight: normal">
                                ${{
                                  typeRate.rate
                                }}
                              </th>
                            </template>

                            <template v-if="type.rates.length < 2 && typeRate.towel_usage_id == 1">
                              <th colspan="1" v-if="type.id == towels && typeRate.towel_usage_id == 1"
                                  style="color: #007BFF; font-weight: normal">
                                ${{
                                  typeRate.rate
                                }}
                              </th>
                              <th colspan="1" v-if="type.id == towels" style="color: #007BFF; font-weight: normal"> -
                              </th>
                            </template>

                            <template v-if="type.rates.length < 2 && typeRate.towel_usage_id == 2">
                              <th colspan="1" v-if="type.id == towels" style="color: #007BFF; font-weight: normal"> -
                              </th>
                              <th colspan="1" v-if="type.id == towels && typeRate.towel_usage_id == 2"
                                  style="color: #007BFF; font-weight: normal">
                                ${{
                                  typeRate.rate
                                }}
                              </th>
                            </template>
                          </template>
                        </template>

                        <template v-if="type.rates[0] == null">
                          <th colspan="1" v-if="type.id == towels" style="color: #007BFF; font-weight: normal"> -</th>
                          <th colspan="1" v-if="type.id == towels" style="color: #007BFF; font-weight: normal"> -</th>
                        </template>
                        <!--</editor-fold>-->


                      </template>


                    </tr>
                    </thead>
                    <tbody>
                    <!--                  <tr v-if="loading">-->
                    <!--                    <td :colspan="loaderColspan">-->
                    <!--                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>-->
                    <!--                      Loading...-->
                    <!--                    </td>-->
                    <!--                  </tr>-->
                    <tr height="50px" v-for="(crew, index) in recentCrewBatches"
                        data-toggle="modal"
                        data-target="#editCrewGearSize"
                        data-backdrop="static"
                        data-keyboard="false"
                        @click="editCrewGearSize(crew)"
                    >
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td title="">

                        <a href="#"
                           @click.stop="gotoCrewProfileNewWindow(crew.crew_id, 'all-info')"
                           style="color:black;">
                          <small><b>{{ crew.fullName }} </b></small>
                        </a>


                      </td>


                      <td>{{ crew.rank_alias }}
                      </td>


                      <template v-for="(type, index) in gearTypeTemplates">
                        <template v-if="type.rates">

                          <!--<editor-fold desc="travelling bag qty">-->
                          <template>
                            <!--                            v-for="(rate, index) in type.rates"-->
                            <!--                            <td v-if="rate !== null && type.id == travelling_bag">-->
                            <!--                                {{ rate.qty }}-->
                            <!--                            </td>-->

                            <template v-if="crew.travelBagComplementTemplateMatch.gearComplementTemplate.length > 0">
                              <template
                                  v-for="(trav, index) in crew.travelBagComplementTemplateMatch.gearComplementTemplate">
                                <template v-if="type.id == travelling_bag">
                                  <td colspan="2">{{ trav.qty }}</td>
                                </template>
                              </template>
                            </template>

                            <template v-if="crew.travelBagComplementTemplateMatch.gearComplementTemplate.length < 1">
                              <template v-if="type.id == travelling_bag">
                                <td colspan="2">N/A</td>
                              </template>
                            </template>

                          </template>


                          <!--</editor-fold>-->


                          <!--<editor-fold desc="coverall qty">-->
                          <template>

                            <template v-if="crew.coverallComplementTemplateMatch.gearComplementTemplate.length > 0">
                              <template
                                  v-for="(cov, index) in crew.coverallComplementTemplateMatch.gearComplementTemplate">
                                <template v-if="type.id == coverall">
                                  <td>{{ cov.qty }}</td>
                                  <td>{{ cov.qty == '-' ? '' : crew.cover_all }}</td>
                                </template>
                              </template>
                            </template>

                            <template v-if="crew.coverallComplementTemplateMatch.gearComplementTemplate.length < 1">
                              <template v-if="type.id == coverall">
                                <td>N/A</td>
                                <td>-</td>
                              </template>
                            </template>

                          </template>
                          <!--</editor-fold>-->


                          <!--<editor-fold desc="jacket qty">-->
                          <template>

                            <template v-if="crew.jacketComplementTemplateMatch.gearComplementTemplate.length > 0">
                              <template
                                  v-for="(jac, index) in crew.jacketComplementTemplateMatch.gearComplementTemplate">
                                <template v-if="type.id == jacket">
                                  <td>{{ jac.qty }}</td>
                                  <td>{{ jac.qty == '-' ? '' : crew.jacket }}</td>
                                </template>
                              </template>
                            </template>

                            <template v-if="crew.jacketComplementTemplateMatch.gearComplementTemplate.length < 1">
                              <template v-if="type.id == jacket">
                                <td>N/A</td>
                                <td>-</td>
                              </template>
                            </template>

                            <!--                            <td v-if="rate == null && type.id == jacket">jacket</td>-->
                            <!--                            <td v-if="rate == null && type.id == jacket">-</td>-->

                          </template>
                          <!--</editor-fold>-->

                          <!--<editor-fold desc="s_sleeve qty">-->
                          <template>
                            <!--                            v-for="(rate, index) in type.rates"-->
                            <!--                            <td v-if="rate !== null && type.id == s_sleeve">{{ rate.qty }}</td>-->
                            <!--                            <td v-if="rate != null && type.id == s_sleeve">{{ rate.size }}</td>-->


                            <template v-if="crew.s_sleevesComplementTemplateMatch.gearComplementTemplate.length > 0">
                              <template
                                  v-for="(ssleeve, index) in crew.s_sleevesComplementTemplateMatch.gearComplementTemplate">
                                <template v-if="type.id == s_sleeve">
                                  <td>{{ ssleeve.qty }}</td>
                                  <td>{{ ssleeve.qty == '-' ? '' : crew.short_sleeves }}</td>
                                </template>
                              </template>
                            </template>

                            <template v-if="crew.s_sleevesComplementTemplateMatch.gearComplementTemplate.length < 1">
                              <template v-if="type.id == s_sleeve">
                                <td>N/A</td>
                                <td>-</td>
                              </template>
                            </template>

                          </template>
                          <!--</editor-fold>-->

                          <!--<editor-fold desc="l_sleeve qty">-->
                          <template>
                            <!--                            v-for="(rate, index) in type.rates"-->
                            <!--                            <td v-if="rate !== null && type.id == l_sleeve">{{ rate.qty }}</td>-->
                            <!--                            <td v-if="rate != null && type.id == l_sleeve">{{ rate.size }}</td>-->


                            <template v-if="crew.l_sleevesComplementTemplateMatch.gearComplementTemplate.length > 0">
                              <template
                                  v-for="(lsleeve, index) in crew.l_sleevesComplementTemplateMatch.gearComplementTemplate">
                                <template v-if="type.id == l_sleeve">
                                  <td>{{ lsleeve.qty }}</td>
                                  <td>{{ lsleeve.qty == '-' ? '' : crew.long_sleeves }}</td>
                                </template>
                              </template>
                            </template>

                            <template v-if="crew.l_sleevesComplementTemplateMatch.gearComplementTemplate.length < 1">
                              <template v-if="type.id == l_sleeve">
                                <td>N/A</td>
                                <td>-</td>
                              </template>
                            </template>

                          </template>
                          <!--</editor-fold>-->

                          <!--<editor-fold desc="pants qty">-->
                          <template>
                            <!--                            v-for="(rate, index) in type.rates"-->
                            <!--                            <td v-if="rate !== null && type.id == pants">{{ rate.qty }}</td>-->
                            <!--                            <td v-if="rate != null && type.id == pants">{{ rate.size }}</td>-->


                            <template v-if="crew.pantsComplementTemplateMatch.gearComplementTemplate.length > 0">
                              <template
                                  v-for="(pantsMatch, index) in crew.pantsComplementTemplateMatch.gearComplementTemplate">
                                <template v-if="type.id == pants">
                                  <td>{{ pantsMatch.qty }}</td>
                                  <td>{{ pantsMatch.qty == '-' ? '' : crew.pants }}</td>
                                </template>
                              </template>
                            </template>

                            <template v-if="crew.pantsComplementTemplateMatch.gearComplementTemplate.length < 1">
                              <template v-if="type.id == pants">
                                <td>N/A</td>
                                <td>-</td>
                              </template>
                            </template>

                          </template>
                          <!--</editor-fold>-->

                          <!--<editor-fold desc="shoes qty">-->
                          <template>
                            <!--                            v-for="(rate, index) in type.rates"-->
                            <!--                            <td v-if="rate !== null && type.id == shoes">{{ rate.qty }}</td>-->
                            <!--                            <td v-if="rate != null && type.id == shoes">{{ rate.size }}</td>-->

                            <template v-if="crew.shoesComplementTemplateMatch.gearComplementTemplate.length > 0">
                              <template
                                  v-for="(shoe, index) in crew.shoesComplementTemplateMatch.gearComplementTemplate">
                                <template v-if="type.id == shoes">
                                  <td>{{ shoe.qty }}</td>
                                  <td>{{ shoe.qty == '-' ? '' : crew.shoes }}</td>
                                </template>
                              </template>
                            </template>

                            <template v-if="crew.shoesComplementTemplateMatch.gearComplementTemplate.length < 1">
                              <template v-if="type.id == shoes">
                                <td>N/A</td>
                                <td>-</td>
                              </template>
                            </template>

                            <!--                            <td v-if="rate == null && type.id == shoes">N/A</td>-->
                            <!--                            <td v-if="rate == null && type.id == shoes">-</td>-->

                            <!--                            <td v-if="rate == null && type.id == shoes">N/A</td>-->
                            <!--                            <td v-if="rate == null && type.id == shoes">-</td>-->

                          </template>
                          <!--</editor-fold>-->

                          <!--<editor-fold desc="steward_uniform qty">-->
                          <template>
                            <!--                            v-for="(rate, index) in type.rates"-->
                            <!--                            <td v-if="rate !== null && type.id == steward_uniform">{{ rate.qty }}</td>-->
                            <!--                            <td v-if="rate != null && type.id == steward_uniform">{{ rate.size }}</td>-->

                            <template
                                v-if="crew.stewardUniformComplementTemplateMatch.gearComplementTemplate.length > 0">
                              <template
                                  v-for="(steward, index) in crew.stewardUniformComplementTemplateMatch.gearComplementTemplate">
                                <template v-if="type.id == steward_uniform">
                                  <td>{{ steward.qty }}</td>
                                  <td>{{ steward.qty == '-' ? '' : crew.steward_uniform }}</td>
                                </template>
                              </template>
                            </template>

                            <template
                                v-if="crew.stewardUniformComplementTemplateMatch.gearComplementTemplate.length < 1">
                              <template v-if="type.id == steward_uniform">
                                <td>N/A</td>
                                <td>-</td>
                              </template>
                            </template>

                          </template>
                          <!--</editor-fold>-->


                          <!--<editor-fold desc="hat_hairnet bag qty">-->
                          <template>
                            <!--                            v-for="(rate, index) in type.rates"-->
                            <!--                            <td v-if="rate !== null && type.id == hat_hairnet">{{ rate.qty }}</td>-->

                            <template v-if="crew.hatHairnetComplementTemplateMatch.gearComplementTemplate.length > 0">
                              <template
                                  v-for="(hatHairnet, index) in crew.hatHairnetComplementTemplateMatch.gearComplementTemplate">
                                <template v-if="type.id == hat_hairnet">
                                  <td>{{ hatHairnet.qty }}</td>
                                </template>
                              </template>
                            </template>

                            <template v-if="crew.hatHairnetComplementTemplateMatch.gearComplementTemplate.length < 1">
                              <template v-if="type.id == hat_hairnet">
                                <td>N/A</td>
                                <td>N/A</td>
                              </template>
                            </template>

                            <!--                            <td v-if="type.id == hat_hairnet">hh -</td>-->
                          </template>
                          <!--</editor-fold>-->

                        </template>

                        <!--<editor-fold desc="towels qty">-->
                        <template>
                          <!--                          v-for="(rate, colorIndex) in type.rates"-->
                          <!--                          <template v-if="rate != null && type.rates.length == 2">-->
                          <!--                            <td colspan="1" v-if="type.id == towels && rate.towel_usage_id == 1"> {{ rate.qty }}</td>-->
                          <!--                            <td colspan="1" v-if="type.id == towels && rate.towel_usage_id == 2"> {{ rate.qty }}</td>-->
                          <!--                          </template>-->

                          <!--                          <template v-if="rate != null && type.rates.length < 2 && rate.towel_usage_id == 1">-->
                          <!--                            <td colspan="1" v-if="type.id == towels && rate.towel_usage_id == 1"> {{ rate.qty }}</td>-->
                          <!--                            <td colspan="1" v-if="type.id == towels"> -</td>-->
                          <!--                          </template>-->

                          <!--                          <template v-if="rate != null && type.rates.length < 2 && rate.towel_usage_id == 2">-->
                          <!--                            <td colspan="1" v-if="type.id == towels"> -</td>-->
                          <!--                            <td colspan="1" v-if="type.id == towels && rate.towel_usage_id == 2"> {{ rate.qty }}</td>-->
                          <!--                          </template>-->

                          <!--                          <template v-if="rate == null">-->
                          <!--                            -->
                          <!--                          </template>-->
                          <template v-if="crew.towelComplementTemplateMatch.gearComplementTemplate.length > 0">
                            <template
                                v-for="(towel, index) in crew.towelComplementTemplateMatch.gearComplementTemplate">
                              <template v-if="towel">
                                <template v-if="type.id == towels">

                                  <template v-if="crew.towelComplementTemplateMatch.gearComplementTemplate.length == 2">
                                    <td colspan="1" v-if="towel.towel_usage_id == 1"> {{ towel.qty }}</td>
                                    <td colspan="1" v-if="towel.towel_usage_id == 2"> {{ towel.qty }}</td>
                                  </template>

                                  <template
                                      v-if="crew.towelComplementTemplateMatch.gearComplementTemplate.length < 2 && towel.towel_usage_id == 1">
                                    <td colspan="1" v-if="towel.towel_usage_id == 1"> {{ towel.qty }}</td>
                                    <td colspan="1"> -</td>
                                  </template>

                                  <template
                                      v-if="crew.towelComplementTemplateMatch.gearComplementTemplate.length < 2 && towel.towel_usage_id == 2">
                                    <td colspan="1"> -</td>
                                    <td colspan="1" v-if="towel.towel_usage_id == 2"> {{ towel.qty }}</td>
                                  </template>

                                </template>
                              </template>
                            </template>
                          </template>

                          <template v-if="crew.towelComplementTemplateMatch.gearComplementTemplate.length < 1">
                            <template v-if="type.id == towels">
                              <td colspan="1" v-if="type.id == towels">-</td>
                              <td colspan="1" v-if="type.id == towels">-</td>
                            </template>
                          </template>


                        </template>
                        <!--</editor-fold>-->

                      </template>


                      <!--  total amount -->
                      <td style="font-weight:bold;">
                        ${{ crew.gearTotalPerCrew.toFixed(2) }}
                      </td>

                      <!-- uncomment/ comment for test.  -->
                      <!--<td style="text-align:center;border-right:0.1px Solid Black;border-bottom:0.1px Solid Black;;"></td>-->

                      <td></td>
                      <td></td>

                    </tr>
                    <tr style="font-weight:bold;">
                      <td colspan="3"
                          style="">GRAND
                        TOTAL
                      </td>


                      <template v-for="(type, index) in gearTypeTemplates">

                        <!--<editor-fold desc="travelling bag total">-->
                        <template v-for="(travellingTotal, index) in totalTravelBag">
                          <td :colspan="2" v-if="type.id == travelling_bag">
                            ${{ travellingTotal.toFixed(2) }}
                          </td>
                        </template>

                        <template v-if="totalTravelBag.length < 1 || !travelling_bag">
                          <template v-if="type.id == travelling_bag">
                            <td :colspan="2">
                              -
                            </td>
                          </template>
                        </template>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="coverall total">-->
                        <template v-for="(coverallTotal, index) in totalCoverall">
                          <td :colspan="2" v-if="type.id == coverall">
                            ${{ coverallTotal.toFixed(2) }}
                          </td>
                        </template>

                        <template v-if="totalCoverall.length < 1 || !totalCoverall">
                          <template v-if="type.id == coverall">
                            <td :colspan="2">
                              -
                            </td>
                          </template>
                        </template>
                        <!--</editor-fold>-->


                        <!--<editor-fold desc="jacket total">-->

                        <template v-for="(jacketTotal, index) in totalJacket">
                          <td :colspan="2" v-if="type.id == jacket">
                            ${{ jacketTotal.toFixed(2) }}
                          </td>
                        </template>

                        <template v-if="totalJacket.length < 1 || !totalJacket">
                          <td :colspan="2" v-if="type.id == jacket">
                            -
                          </td>

                        </template>


                        <!--</editor-fold>-->

                        <!--<editor-fold desc="s_sleeve total">-->

                        <template v-for="(shortSleevesTotal, index) in totalShortSleeves">
                          <td :colspan="2" v-if="type.id == s_sleeve">
                            ${{ shortSleevesTotal.toFixed(2) }}
                          </td>
                        </template>

                        <template v-if="totalShortSleeves.length < 1 || !totalShortSleeves">
                          <td :colspan="2" v-if="type.id == s_sleeve">
                            -
                          </td>

                        </template>

                        <!--</editor-fold>-->

                        <!--<editor-fold desc="l_sleeve total">-->
                        <template v-for="(longSleevesTotal, index) in totalLongSleeves">
                          <td :colspan="2" v-if="type.id == l_sleeve">
                            ${{ longSleevesTotal.toFixed(2) }}
                          </td>
                        </template>

                        <template v-if="totalLongSleeves.length < 1 || !totalLongSleeves">
                          <td :colspan="2" v-if="type.id == l_sleeve">
                            -
                          </td>

                        </template>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="pants total">-->
                        <template v-for="(pantsTotal, index) in totalPants">
                          <td :colspan="2" v-if="type.id == pants">
                            ${{ pantsTotal.toFixed(2) }}
                          </td>
                        </template>

                        <template v-if="totalPants.length < 1 || !totalPants">
                          <td :colspan="2" v-if="type.id == pants">
                            -
                          </td>

                        </template>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="shoes total">-->
                        <template v-for="(shoesTotal, index) in totalShoes">
                          <td :colspan="2" v-if="type.id == shoes">
                            ${{ shoesTotal.toFixed(2) }}
                          </td>
                        </template>

                        <template v-if="totalShoes.length < 1 || !totalShoes">
                          <td :colspan="2" v-if="type.id == shoes">
                            -
                          </td>

                        </template>
                        <!--</editor-fold>-->

                        <!--<editor-fold desc="steward_uniform total">-->
                        <template v-for="(stewardUniformTotal, index) in totalStewardUniform">
                          <td :colspan="2" v-if="type.id == steward_uniform">
                            ${{ stewardUniformTotal.toFixed(2) }}
                          </td>
                        </template>

                        <template v-if="totalStewardUniform.length < 1 || !totalStewardUniform">
                          <td :colspan="2" v-if="type.id == steward_uniform">
                            -
                          </td>

                        </template>
                        <!--</editor-fold>-->


                        <!--<editor-fold desc="hat_hairnet bag total">-->
                        <template v-for="(hatHairnetTotal, index) in totalHatHairnet">
                          <td :colspan="1" v-if="type.id == hat_hairnet">
                            ${{ hatHairnetTotal.toFixed(2) }}
                          </td>
                        </template>

                        <template v-if="totalHatHairnet.length < 1 || !totalHatHairnet">
                          <td :colspan="1" v-if="type.id == hat_hairnet">
                            -
                          </td>

                          <td :colspan="1" v-if="type.id == hat_hairnet">
                            -
                          </td>
                        </template>
                        <!--</editor-fold>-->


                        <!--<editor-fold desc="towels total">-->
                        <template v-if="type.id == towels">
                          <template v-for="(rate, rateIndex) in type.rates">

                            <template v-if="totalTowel.length == 2">
                              <td :colspan="1" v-if="rate?.towel_usage_id == 1">{{ totalTowel[0]?.toFixed(2) }}</td>
                              <td :colspan="1" v-if="rate?.towel_usage_id == 2">{{ totalTowel[1]?.toFixed(2) }}</td>
                            </template>

                            <template v-if="totalTowel.length < 2 & rate?.towel_usage_id == 1">
                              <td :colspan="1" v-if="rate?.towel_usage_id == 1">
                                ${{ totalTowel[0]?.toFixed(2) }}
                              </td>
                              <td :colspan="1">
                                -
                              </td>
                            </template>

                            <template v-if="totalTowel.length < 2 & rate?.towel_usage_id == 2">
                              <td :colspan="1" v-if="rate?.towel_usage_id == 2">
                                -
                              </td>
                              <td :colspan="1">
                                ${{ totalTowel[1]?.toFixed(2) }}
                              </td>
                            </template>


                          </template>
                        </template>

                        <template v-if="totalTowel.length < 1 || !totalTowel">
                          <td :colspan="1" v-if="type.id == towels">-</td>
                          <td :colspan="1" v-if="type.id == towels">-</td>
                        </template>

                        <!--</editor-fold>-->


                      </template>


                      <!--<editor-fold desc="grand total">-->
                      <td colspan="1" v-if="!grandTotalGearsPRPO">
                        &nbsp;$0
                      </td>

                      <td colspan="1" v-else>
                        ${{ grandTotalGearsPRPO.toFixed(2) }}
                      </td>

                      <!--</editor-fold>-->


                      <!--<td colspan="1" style="text-align:center;border-right:0.1px Solid Black;border-bottom:0.1px Solid Black;;"></td>-->
                    </tr>
                    </tbody>
                    <tfoot>
                    <slot name="table-footer"></slot>
                    </tfoot>
                  </table>
                </template>

              </div>
            </div>
          </div>


        </div>

      </div>
    </div>

    <!--  Edit Gear Size Modal modal   -->
    <div class="modal fade" id="editCrewGearSize" tabindex="-1" role="dialog" aria-labelledby="editGearSizeModal"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <span class="modal-title " id="editGearSizeModal">
              <span class="font-weight-bold lead" style="font-size: 15px;">
                EDIT GEAR SIZE:
              </span>

              <span style="color: green; font-size: 15px;">{{ crewGearSizeData.fullName }}</span>
            </span>

          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">

                <!--coverall size-->
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">COVERALL</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-4 ">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="crewGearSizeData.cover_all"
                           :disabled="!isEditable"
                    />
                  </div>
                  <label class="col-form-label">(S/M/L)</label>
                </div>

                <!--jacket size-->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">JACKET</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-4">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="---"
                        v-model="crewGearSizeData.jacket"
                        :disabled="!isEditable"
                    />
                  </div>
                  <label class="col-form-label">(S/M/L)</label>
                </div>

                <!--shoes size-->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">SHOES</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-4">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="---"
                        v-model="crewGearSizeData.shoes"
                        :disabled="!isEditable"
                    />
                  </div>
                  <label class="col-form-label">(6/7/8/etc)</label>
                </div>

                <!--long sleeves size-->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">LONG SLEEVES</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-4">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="---"
                        v-model="crewGearSizeData.long_sleeves"
                        :disabled="!isEditable"
                    />
                  </div>
                  <label class="col-form-label">(S/M/L)</label>
                </div>

                <!--short sleeves size-->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">SHORT SLEEVES</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-4">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="---"
                        v-model="crewGearSizeData.short_sleeves"
                        :disabled="!isEditable"
                    />
                  </div>
                  <label class="col-form-label">(S/M/L)</label>
                </div>


                <!--pants size-->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">PANTS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-4">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="---"
                        v-model="crewGearSizeData.pants"
                        :disabled="!isEditable"
                    />
                  </div>
                  <label class="col-form-label">(W36/L40)</label>
                </div>


                <!-- uniform set size-->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">UNIFORM SET</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-4">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="---"
                        v-model="crewGearSizeData.steward_uniform"
                        :disabled="!isEditable"
                    />
                  </div>
                  <label class="col-form-label">(S/M/L)</label>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="setEditGearSize"
            >
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update_gear_size"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="setCancelEditGearSize"
            >
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>


    <!-- generating pdf loading modal -->
    <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {global_variables_config} from "@/config/global-variable.config";

export default {
  name: "GearsPRPOList",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      isEditable: false,

      travelling_bag: global_variables_config.allGearTypes.travelling_bag,
      coverall: global_variables_config.allGearTypes.coverall,
      jacket: global_variables_config.allGearTypes.jacket,
      shoes: global_variables_config.allGearTypes.shoes,
      s_sleeve: global_variables_config.allGearTypes.s_sleeve,
      l_sleeve: global_variables_config.allGearTypes.l_sleeve,
      pants: global_variables_config.allGearTypes.pants,
      steward_uniform: global_variables_config.allGearTypes.steward_uniform,
      hat_hairnet: global_variables_config.allGearTypes.hat_hairnet,
      towels: global_variables_config.allGearTypes.towels,

      rankTypes: {
        deck: 1,
        engine: 2,
        radio: 3,
        steward: 4,
      },

      // supplierRateParam: {
      //   supplierId: null,
      // },

      recentCrewChangePlanKeysParam: {
        vesselId: null,
        isCcmSearch: null,
      },

      recentCrewBatchesParam: {
        ccmNo: null,
        crewBatchId: null,
        rankType: null,
        supplierId: null,
      },

      selectedVesselName: null,
      selectedCCM: null,
      selectedSupplierName: null,

      fourItems: [1, 2, 3, 4],
      colSpanRelateToRatesCount: [1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],

      // params for set_gearComplements
      gearComplementTemplateParam: {
        ownerId: null,
        vesselId: null,
        rankId: null,
        supplierId: null,
      },

      // do not delete this solves
      // [Vue warn]: Property or method "rate" is not defined on the instance but referenced during render.
      rate: null,


      crewGearSizeData: {},

      crewGearSizeParams: {
        crewId: '',

        coverAll: '',
        jacket: '',
        shoes: '',
        longSleeves: '',
        shortSleeves: '',
        pants: '',
        uniform: '',
      },

      showGenerateButton: false,

    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'getGearSupplierKeys',
      'getGearTypeKeys',
      // 'set_gearSupplierRates',
      'set_recent_crew_batches',
      'set_recent_crew_change_plan_keys',
      // 'set_recent_crew_batches',
      'getRankTypeKeys',

      // Template
      'set_complementTemplates',

      //Generate gears prpo
      'generateGearsPRPO',

      //update gear size
      'updateCrewInfoFromGearsPRPO',

      'null_obj_recent_cc_plan_keys',

    ]),


    on_change_select_supplier(supplierId, supplierName) {
      // this.supplierRateParam.supplierId = e.target.value;
      // this.set_gearSupplierRates(this.supplierRateParam);

      this.recentCrewBatchesParam.supplierId = supplierId;
      this.set_recent_crew_batches(this.recentCrewBatchesParam)

      //template
      this.gearComplementTemplateParam.supplierId = supplierId;
      this.set_complementTemplates(this.gearComplementTemplateParam);

      this.selectedSupplierName = supplierName;

    },

    updateGearsPRPOList() {
      this.set_recent_crew_batches(this.recentCrewBatchesParam)
      this.set_complementTemplates(this.gearComplementTemplateParam);
    },

    on_change_select_vessel(id, name) {
      if (this.gearComplementTemplateParam.vesselId
          && this.recentCrewChangePlanKeysParam.vesselId) {

        this.recentCrewBatchesParam.supplierId = null
        this.gearComplementTemplateParam.supplierId = null
        this.gearComplementTemplateParam.vesselId = null

        this.recentCrewChangePlanKeysParam.vesselId = null

        this.recentCrewBatchesParam.crewBatchId = null

        this.recentCrewBatchesParam.rankType = null

      }

      this.gearComplementTemplateParam.vesselId = id;
      this.recentCrewChangePlanKeysParam.vesselId = id;
      this.selectedVesselName = name;
      this.set_recent_crew_change_plan_keys(this.recentCrewChangePlanKeysParam);



    },

    on_change_select_crew_batch(crew_batch_id, ccm) {
      this.recentCrewBatchesParam.crewBatchId = crew_batch_id
      this.selectedCCM = ccm
      this.set_recent_crew_batches(this.recentCrewBatchesParam)
    },

    async displayActiveCcmNo() {
      if (this.recentCrewBatchesParam.ccmNo) {
        this.recentCrewBatchesParam.ccmNo = null
        this.recentCrewChangePlanKeysParam.isCcmSearch = null
        await this.set_recent_crew_change_plan_keys(this.recentCrewChangePlanKeysParam);
      }
    },

    on_change_select_rank_type(rankType) {
      this.recentCrewBatchesParam.rankType = rankType

      if (this.gearComplementTemplateParam.supplierId
          && this.recentCrewBatchesParam.supplierId) {
        this.set_recent_crew_batches(this.recentCrewBatchesParam)
      }

    },

    async editCrewGearSize(crew) {
      // this.crewGearSizeData = Object.assign({}, crew);
      this.crewGearSizeData = crew;
      this.crewGearSizeParams.crewId = crew.crew_id

    },

    async setEditGearSize() {
      this.isEditable = true;
    },

    async setCancelEditGearSize() {
      this.isEditable = false;
    },


    async loadGearsPRPO() {
      if (this.recentCrewBatchesParam.crewBatchId && this.recentCrewBatchesParam.rankType && this.gearComplementTemplateParam.supplierId) {

        let params = {
          // ownerId: this.gearComplementTemplateParam.ownerId ,
          vesselId: this.gearComplementTemplateParam.vesselId,
          supplierId: this.gearComplementTemplateParam.supplierId,

          crewBatchId: this.recentCrewBatchesParam.crewBatchId,
          rankType: this.recentCrewBatchesParam.rankType,
          vesselName: this.selectedVesselName,
          selectedRankTypeId: this.recentCrewBatchesParam.rankType,
          ccmNo: this.selectedCCM,
          supplierName: this.selectedSupplierName,
          // supplierId: this.recentCrewBatchesParam.supplierId ,
        }

        const progressModal = $('#generatingAnimationModal')
        progressModal.modal('show');
        let gearPRPOFile = await this.generateGearsPRPO(params);
        if (gearPRPOFile) {
          progressModal.modal('hide');
          await this.pdfViewerGearsPRPO('Gears PRPO', gearPRPOFile);
        } else {
          progressModal.modal('hide');
        }

      } else {
        await this.infoAlert('Please complete dropdown select details', 'Generate Report')
      }

    },


    async update_gear_size() {
      if (await this.questionAlert('Are you sure you want to update this crew record.', 'Crew Information')) {

        this.crewGearSizeParams.coverAll = this.crewGearSizeData.cover_all
        this.crewGearSizeParams.jacket = this.crewGearSizeData.jacket
        this.crewGearSizeParams.shoes = this.crewGearSizeData.shoes
        this.crewGearSizeParams.longSleeves = this.crewGearSizeData.long_sleeves
        this.crewGearSizeParams.shortSleeves = this.crewGearSizeData.short_sleeves
        this.crewGearSizeParams.pants = this.crewGearSizeData.pants
        this.crewGearSizeParams.uniform = this.crewGearSizeData.steward_uniform

        this.updateCrewInfoFromGearsPRPO(this.crewGearSizeParams).then((r) => {
          if (r) {
            this.successAlert('Record Updated Successfully', 'Crew Information')
            this.isEditable = false
          }
        })
      }
    },

    checkGenerateButton() {
      if (this.recentCrewBatchesParam.crewBatchId !== null &&
          this.recentCrewBatchesParam.rankType !== null &&
          this.gearComplementTemplateParam.supplierId !== null) {
        this.showGenerateButton = true;
      } else {
        this.showGenerateButton = false;
      }
    },


    isInRankTypes(rankTypeId) {
      const rankTypes = {
        deck: 1,
        engine: 2,
        radio: 3,
        steward: 4,
      };

      return Object.values(rankTypes).includes(rankTypeId);
    },

    async searchCcmNo() {
      this.recentCrewChangePlanKeysParam.isCcmSearch = 1
      await this.set_recent_crew_change_plan_keys(this.recentCrewChangePlanKeysParam);

      const crewChangePlanKey = this.objRecentCrewChangePlanKeys.find(recentChangePlanKey => {
        return recentChangePlanKey.ccm_no === this.recentCrewBatchesParam.ccmNo;
      });

      this.recentCrewBatchesParam.crewBatchId = crewChangePlanKey?.crew_batch_id
      this.set_recent_crew_batches(this.recentCrewBatchesParam)

      this.null_obj_recent_cc_plan_keys()

    },


  },


  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          'isReloading',
          'gearSupplierRates',
          'objGearSuppliers',
          'objGearTypes',
          'gearTypeCounts',

          'objVessels',
          'objRecentCrewChangePlanKeys',
          'recentCrewBatches',

          'objRankTypes',

          //template
          'gearTypeTemplates',


          // Total = QTY * Rates
          'totalTravelBag',
          'totalCoverall',
          'totalJacket',
          'totalShortSleeves',
          'totalLongSleeves',
          'totalPants',
          'totalShoes',
          'totalStewardUniform',
          'totalHatHairnet',
          'totalTowel',

          // Grand total of Gears PRPO
          'grandTotalGearsPRPO',

        ],
    ),


  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.getGearSupplierKeys();
    await this.getGearTypeKeys();
    await this.set_recent_crew_batches();
    await this.getRankTypeKeys();
    this.$globalVariables = global_variables_config
  },


  /** watch **/
  watch: {

    recentCrewBatchesParam: {
      handler: function () {
        this.checkGenerateButton();
      },
      deep: true
    },
    gearComplementTemplateParam: {
      handler: function () {
        this.checkGenerateButton();
      },
      deep: true
    }

  },

  beforeDestroy() {
    this.$globalVariables = null;
  },


}
</script>

<style scoped>

/*table {*/
/*  border-collapse: collapse; !* Don't collapse *!*/
/*  border-spacing: 0;*/
/*}*/

table.table-bordered {
  border: 1px solid black;
  margin-top: 20px;
}

table.table-bordered > thead > tr > th {
  border: 1px solid black;
}

table.table-bordered > thead > tr > td {
  border: 1px solid black;
}

table.table-bordered > thead > tr:first-child > td {
  border-style: solid;
  border-color: #f0f3f3;
  border-bottom: 0.1px Solid Black;
}

table.table-bordered > tbody > tr > td {
  border: 1px solid black;
}


.sticky-header-gears-prpo > thead {
  border-top: 0;
  position: sticky !important;
  top: 0;
  z-index: 100;
}

</style>
