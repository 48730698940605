<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Clinic</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newClinicModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Clinic
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search clinic name"
                v-model="clinicFormParam.searchValue"
                @keypress.enter="searchClinic"
              >

              <button class="btn btn-sm pmc-btn-prime" @click="searchClinic">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- status -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm"
                        v-model="clinicFormParam.status"
                        @change="on_change_status">
                  <option :value="null">--select--</option>
                  <option :value="status.key" v-for="(status,index) in $globalVariableConfig.statuses" :key="status.id">
                    {{ status.placeholder }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="clinicFormParam.count"
                        @change="on_change_count">
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>


        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="clinicPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="align-middle" width="10%">NAME</th>
                <th scope="row" class="align-middle" width="10%">ADDRESS</th>
                <th scope="row" class="align-middle" width="7%">CONTACT NO 1</th>
                <th scope="row" class="align-middle" width="7%">CONTACT NO 2</th>
                <th scope="row" class="align-middle" width="7%">CONTACT NO 3</th>
                <th scope="row" class="align-middle" width="7%">CONTACT NO 4</th>
                <th scope="row" class="align-middle" width="7%">CONTACT NO 5</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>AT</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>AT</th>
                <th scope="row" class="align-middle" width="5%">STATUS</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-if = "clinics.length === 0">
                <td colspan="14">No Results</td>
              </tr>
              <tr v-else v-for="(clinic, index) in clinics" :key="clinic.id">
                <td>{{ index + clinicPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ clinic.name }}</td>
                <td scope="col" class="p-0 text-center">{{ clinic.address }}</td>
                <td scope="col" class="p-0 text-center">{{ clinic.contact_no1 }}</td>
                <td scope="col" class="p-0 text-center">{{ clinic.contact_no2 }}</td>
                <td scope="col" class="p-0 text-center">{{ clinic.contact_no3 }}</td>
                <td scope="col" class="p-0 text-center">{{ clinic.contact_no4 }}</td>
                <td scope="col" class="p-0 text-center">{{ clinic.contact_no5 }}</td>
                <td scope="col" class="p-0 text-center">{{ clinic.created_by }}</td>
                <td scope="col" class="p-0 text-center">{{ clinic.updated_by }}</td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ clinic.created_at }}
                </td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ clinic.updated_at }}
                </td>
                <td scope="col" class="p-0 text-center">
                  {{ clinic.status == 1 ? 'ACTIVE' : 'INACTIVE' }}
                </td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewClinicModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="clinicViewtoClinicData = clinic">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_clinic(clinic.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new clinic modal   -->
    <div class="modal fade" id="newClinicModal" tabindex="-1" role="dialog" aria-labelledby="newClinicModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newClinicModalLabel">NEW CLINIC</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClinic.name"
                    />
                  </div>
                </div>

                <!-- new  address-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ADDRESS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClinic.address"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO. 1</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClinic.contact_no1"
                    />
                  </div>
                </div>


                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO. 2</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClinic.contact_no2"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO. 3</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClinic.contact_no3"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO. 4</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClinic.contact_no4"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO. 5</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClinic.contact_no5"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">FAX NO.</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClinic.fax_no"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT PERSON</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClinic.contact_person"
                    />
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newClinic.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>

    <!-- modal view clinic   -->
    <div class="modal fade" id="viewClinicModal" tabindex="-1" role="dialog" aria-labelledby="viewClinicModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewClinicModalLabel">CLINIC</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="clinicData.name"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- alias -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ADDRESS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="clinicData.address"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO. 1</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      :disabled="!isEditable"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="clinicData.contact_no1"
                    />
                  </div>
                </div>


                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO. 2</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      :disabled="!isEditable"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="clinicData.contact_no2"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO. 3</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      :disabled="!isEditable"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="clinicData.contact_no3"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO. 4</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      :disabled="!isEditable"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="clinicData.contact_no4"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO. 4</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      :disabled="!isEditable"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="clinicData.contact_no5"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">FAX NO.</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      :disabled="!isEditable"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="clinicData.fax_no"
                    />
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT PERSON</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      :disabled="!isEditable"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="clinicData.contact_person"
                    />
                  </div>
                </div>

                <!-- status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           class="form-check form-check-inline ml-2 mt-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                           v-model="clinicData.status"
                    />
                  </div>
                </div>


                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CREATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">UPDATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit">
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel">
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import {global_variables_config} from "@/config/global-variable.config";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      clinicViewtoClinicData: {},
      clinicData: {},
      newClinic: {},
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      // statuses: [
      //   {
      //     id: 1,
      //     key: '',
      //     placeholder: 'All',
      //   },
      //   {
      //     id: 2,
      //     key: 1,
      //     placeholder: 'Active',
      //   },
      //   {
      //     id: 3,
      //     key: 0,
      //     placeholder: 'Inactive',
      //   },
      // ],

      isEditable: false,

      pageLastLeft: 1,

    };
  },



  /** methods**/
  methods: {
    ...mapActions([
      'set_clinics',
      'create_clinic',
      'update_clinic',
      'delete_clinic',
      'null_clinics',
      'null_clinic',
    ]),

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Clinic', 'question')
      if (await __create) {

        let params = {
          name: this.newClinic.name,
          address: this.newClinic.address ? this.newClinic.address : '',
          contact_no1: this.newClinic.contact_no1 ? this.newClinic.contact_no1 : '',
          contact_no2: this.newClinic.contact_no2 ? this.newClinic.contact_no2 : '',
          contact_no3: this.newClinic.contact_no3 ? this.newClinic.contact_no3 : '',
          contact_no4: this.newClinic.contact_no4 ? this.newClinic.contact_no4 : '',
          contact_no5: this.newClinic.contact_no5 ? this.newClinic.contact_no5 : '',
          fax_no: this.newClinic.fax_no ? this.newClinic.fax_no : '',
          contact_person: this.newClinic.contact_person ? this.newClinic.contact_person : '',
          status: this.newClinic.status ? 1 : 0,
        };

        let newClinic = await this.create_clinic(params);
        if (newClinic) {
          this.$nextTick(()=>{
            this.successAlert('Create clinic record successfull', 'Create clinic')
            this.hideModal('newClinicModal')
            this.newClinic = '';
            this.set_clinics();
          })
        }
      }
    },

    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update clinic', 'question');
      if (await __update) {
        let params = {
          id: this.clinicData.id,
          name: this.clinicData.name,
          address: this.clinicData.address ? this.clinicData.address : '',
          contact_no1: this.clinicData.contact_no1 ? this.clinicData.contact_no1 : '',
          contact_no2: this.clinicData.contact_no2 ? this.clinicData.contact_no2 : '',
          contact_no3: this.clinicData.contact_no3 ? this.clinicData.contact_no3 : '',
          contact_no4: this.clinicData.contact_no4 ? this.clinicData.contact_no4 : '',
          contact_no5: this.clinicData.contact_no5 ? this.clinicData.contact_no5 : '',
          fax_no: this.clinicData.fax_no ? this.clinicData.fax_no : '',
          contact_person: this.clinicData.contact_person ? this.clinicData.contact_person : '',
          status: this.clinicData.status ? 1 : 0,

        };

        let updatedClinic = await this.update_clinic(params);
        if (updatedClinic) {
          await AlertService.successAlertService("Updated successfully", 'Update clinic');
          this.hideModal('viewClinicModal')
          this.isEditable = false
        }
      }
    },

    set_this_page(e) {
      this.clinicFormParam.searchValue = ''
      this.clinicPagination.currentPage = e
      this.clinicFormParam.page = e
      this.pageLastLeft = e
      this.set_clinics(this.clinicFormParam)

      // this.clinicFormParam.currentPage = e;
      // this.clinicFormParam.page = e;
      // this.set_clinics(this.clinicFormParam);
    },

    searchClinic() {
      if (this.clinicFormParam.searchValue === '') {
        this.clinicFormParam.page = this.pageLastLeft
        this.set_clinics(this.clinicFormParam)
      }

      if (this.clinicFormParam.searchValue) {
        this.clinicFormParam.page = 1
      }

      this.set_clinics(this.clinicFormParam)

      // this.clinicFormParam.page = 1;
      // this.set_clinics(this.clinicFormParam);
    },

    on_change_status(e) {
      this.clinicFormParam.page = 1
      this.clinicFormParam.status = e.target.value;
      this.set_clinics(this.clinicFormParam);
    },

    on_change_count(e) {
      this.clinicFormParam.page = 1
      this.clinicFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.clinicFormParam.count = e.target.value
      this.set_clinics(this.clinicFormParam)

      // this.clinicFormParam.count = e.target.value;
      // this.set_clinics(this.clinicFormParam);
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel(e) {
      this.isEditable = false
      this.clinicData = Object.assign({}, this.clinicViewtoClinicData);
    },

  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'clinics',
        'clinicPagination',
        'clinicFormParam',
      ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    this.$globalVariableConfig = global_variables_config
    await this.set_clinics(this.clinicFormParam);
  },

  beforeDestroy() {
    this.$globalVariableConfig = null;
  },

  /** watch **/
  watch: {
    'clinicViewtoClinicData'() {
      this.clinicData = Object.assign({}, this.clinicViewtoClinicData);
    },
  },

  /** destroyed **/
  destroyed() {
    this.null_clinics()
  },


}
</script>

<style scoped>

</style>
