<template>
  <div>
    <button id="back-to-top" href="#" class="btn btn-lg pmc-bg-prime" role="button" title="Go back on top">
      <font-awesome-icon icon="chevron-circle-up"/>
    </button>
  </div>
</template>
<script>
export default {
  name: 'ButtonGotoUp',

  /** data **/
  data () {
    return {}
  },

  /** methods**/
  methods: {},

  /**  computed  **/
  computed: {},

  /** mounted **/
  mounted () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('#back-to-top').fadeIn();
      } else {
        $('#back-to-top').fadeOut();
      }
    });
    // scroll body to 0px on click
    $('#back-to-top').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 400);
      return false;
    });
  },

  /** created **/
  created () {
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed () {
  },

}
</script>

<style scoped>
#back-to-top{
  position : fixed;
  bottom: 50px;
  right: 50px;
  height: auto !important;
  width: auto !important;
  display: none;
  color: white;
}
</style>
