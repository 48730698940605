<template>

  <div class="modal fade" id="biodata-modal" tabindex="-1" role="dialog" aria-labelledby="CrewBiodataTitle"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header p-2">
          <div class="row d-flex align-items-center px-3">
            <h5 class="modal-title font-weight-bolder mr-5" id="CrewBiodataTitle">Crew Biodata</h5>
            <button type="button" class="btn btn-success btn-sm px-3 font-weight-normal"
                    v-if="chosenBiodataDoc != 3"
                    @click="loadCrewBiodata"
            >
              Download
            </button>

            <button type="button" class="btn btn-success btn-sm px-3 font-weight-normal"
                    v-if="chosenBiodataDoc == 3"
                    @click="loadInterocean($globalVariables.ccmDocs.interOcean, params.crewId)"
            >
              Download
            </button>
          </div>

          <div class="form-inline mb-3">
            <div class="form-group mb-2 ml-5">
              <label for="selectContractPeriod" class="font-weight-bold">Biodata Document:</label>
            </div>
            <div class="form-group ">
              <select id="select-certifier" class="form-control form-control-sm ml-3" v-model="chosenBiodataDoc">
                <!--                <option :value="null">&#45;&#45;Select&#45;&#45;</option>-->
                <option :value="doc.id" v-for="(doc,index) in biodata_docs" :key="doc.id">
                  {{ doc.name }}
                </option>
              </select>
            </div>
          </div>


          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">X</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">

            <div class="col-12">
              <div class="crew-biodata" id="CrewBiodataValue">
                <div class="row d-flex align-items-center text-center no-gutters">
                  <div class="col-lg-10">
                    <span style="font-size: 18px !important;" class="font-weight-bold">Seafarer Biodata</span>
                    <!--                    {{ crew }}-->
                    <!--                    {{ crewInfo }}-->
                    <!--                    {{ crewGenInfo }}-->
                  </div>
                  <div class="col-lg-2">
                  </div>


                  <div class="col-md-2">
                    <img :src="noPhoto" style="width:100%;height:110%;"
                         v-if="!chosenBiodataDoc || chosenBiodataDoc == 3">
                    <img :src="pmcLogoImage" style="width:100%;height:110%;" v-if="chosenBiodataDoc == 1">
                    <img :src="emmcLogoImage" style="width:100%;height:110%;" v-if="chosenBiodataDoc == 2">
                  </div>

                  <div class="col-md-8 text-left">

                    <div class="row no-gutters">
                      <div class="col-md-12">

                        <div class="row justify-content-md-center mt-3">
                          <div class="col-md-7">
                            <table width="100%" class="single-table">
                              <tr>
                                <td>Position Applied For</td>
                                <td>:</td>
                                <!--                                <td>{{ biodataDetails?.crewBiodataApplicationTracking?.rankApplied }}</td>-->
                                <td>{{ biodataDetails?.crewBiodataInformation?.crewInfo?.rankName }}</td>
                              </tr>
                              <tr>
                                <td>Rank</td>
                                <td>:</td>
                                <td>{{ biodataDetails?.crewBiodataInformation?.crewInfo?.rankName }}</td>
                              </tr>
                              <tr>
                                <td>License</td>
                                <td>:</td>
                                <td>{{ biodataDetails?.coc?.document_no }}</td>
                              </tr>
                              <tr>
                                <td>Assigned Vessel</td>
                                <td>:</td>
                                <td>{{ biodataDetails?.crew?.assignedVessel }}</td>
                              </tr>
                              <tr>
                                <td>Date Available</td>
                                <td>:</td>
                                <td>{{ biodataDetails?.crewBiodataApplicationTracking?.dateAvailable }}</td>
                              </tr>
                              <tr>
                                <td>Application Date</td>
                                <td>:</td>
                                <!--                                <td>{{ biodataApplicationTracking.dateApplied_str }}</td>-->
                                <td>{{ biodataDetails?.crewBiodataApplicationTracking?.dateApplied }}</td>
                              </tr>
                              <tr>
                                <td>Crew Type</td>
                                <td>:</td>
                                <td>{{
                                    biodataDetails?.crew?.crewAndScholarType ?
                                      biodataDetails?.crew?.crewAndScholarType:
                                      biodataDetails?.crew?.crewType

                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>SRN</td>
                                <td>:</td>
                                <td>-</td>
                              </tr>
                            </table>
                          </div>
                        </div>


                      </div>


                    </div>
                  </div>

                  <div class="col-md-2">
                    <img :src="crewImage" style="width:100%;height:110%;">
                  </div>
                </div>


                <div class="row my-5 no-gutters">
                  <div class="col-lg-12 pr-3">
                    <div style="width:100%;">
                      <center>
                        <table style="width:86%;">
                          <tbody>
                          <tr>
                            <td width="10%"
                                style="border-bottom:1px solid black;padding:5px;font-size:14px;font-weight:Bold;text-align:center;">
                              <span style="font-size: 14px !important;" class="font-weight-bold">
<!--                                {{ biodataCrewGenInfo.lastName }}-->
                                {{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.lastName }}
                              </span>
                            </td>

                            <td width="10%"
                                style="border-bottom:1px solid black;padding:5px;font-size:14px;font-weight:Bold;text-align:center;">
                             <span style="font-size: 14px !important;" class="font-weight-bold">
<!--                               {{ biodataCrewGenInfo.firstName }}-->
                               {{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.firstName }}
                             </span>
                            </td>

                            <td width="10%"
                                style="border-bottom:1px solid black;padding:5px;font-size:14px;font-weight:Bold;text-align:center;">
                              <span style="font-size: 14px !important;"
                                    class="font-weight-bold">
<!--                                {{ biodataCrewGenInfo.middleName }} &nbsp;&nbsp;|&nbsp; {{biodataCrewGenInfo.age }}-->
                                {{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.middleName }} &nbsp;&nbsp;|&nbsp; {{
                                  biodataDetails?.crewBiodataInformation?.crewGenInfo?.age
                                }}

                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align:center;font-size:0.7em;font-weight:Bold;">Last Name</td>
                            <td style="text-align:center;font-size:0.7em;font-weight:Bold;">First Name</td>
                            <td style="text-align:center;font-size:0.7em;font-weight:Bold;">Middle Name</td>
                          </tr>
                          </tbody>
                        </table>
                      </center>
                    </div>


                  </div>
                </div>


                <!--                Personal Detail-->
                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold" style="font-size: 13px">Personal Detail</span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-4 ">
                    <table class="detail-table" width="100%">
                      <tr>
                        <td>Date of Birth</td>
                        <td>:</td>
                        <!--                        <td> {{ biodataCrewGenInfo.birthdateFormat1 }}</td>-->
                        <td>{{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.birthdateFormat1 }}</td>
                      </tr>
                      <tr>
                        <td>Civil Status</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataCrewGenInfo.civilStatus }}</td>-->
                        <td>{{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.civilStatus }}</td>
                      </tr>
                      <tr>
                        <td>Height</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataCrewGenInfo.height }}</td>-->
                        <td>{{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.height }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-lg-4">
                    <table class="detail-table" width="100%">
                      <tr>
                        <td>Place of Birth</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataCrewGenInfo.birthplace }}</td>-->
                        <td>{{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.birthplace }}</td>
                      </tr>
                      <tr>
                        <td>Nationality</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataCrewGenInfo.natl }}</td>-->
                        <td>{{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.natl }}</td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataCrewGenInfo.weight }}</td>-->
                        <td>{{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.weight }}</td>
                      </tr>

                    </table>
                  </div>

                  <div class="col-lg-4">
                    <table class="detail-table" width="100%">
                      <tr>
                        <td>Age</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataCrewGenInfo.age }}</td>-->
                        <td>{{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.age }}</td>
                      </tr>
                      <tr>
                        <td>Religion</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataCrewGenInfo.religion }}</td>-->
                        <td>{{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.religion }}</td>
                      </tr>
                      <tr>
                        <td>BMI</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataCrewGenInfo.bmi }}</td>-->
                        <td>{{ biodataDetails?.crewBiodataInformation?.crewGenInfo?.bmi }}</td>
                      </tr>

                    </table>
                  </div>
                </div>


                <!--                Educational Attainment-->
                <div class="row text-left my-2 no-gutters mt-3">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold" style="font-size: 13px">Educational Attainment</span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <table class="educ-attain-table" width="100%">
                      <tr>
                        <td class="font-weight-bold">Educational Attainment</td>
                        <td>:</td>
                        <!--                        <td class="color-blue">{{ biodataCrewEducation.courseName }}</td>-->
                        <td class="color-blue">{{ biodataDetails?.crewEducation?.courseName }}</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-6 ">
                    <table class="school-attend" width="100%">
                      <tr>
                        <td>School Attended</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataCrewEducation.schoolName }}</td>-->
                        <td>{{ biodataDetails?.crewEducation?.schoolName }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-lg-6">
                    <table class="school-attend" width="100%">
                      <tr>
                        <td>Year Completed</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataCrewEducation.yearGraduated }}</td>-->
                        <td>{{ biodataDetails?.crewEducation?.yearGraduated }}</td>
                      </tr>

                    </table>
                  </div>

                </div>


                <!--                Contact Information-->
                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold" style="font-size: 13px">Contact Information</span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-4">
                    <table class="detail-table" width="100%">
                      <tr v-for="(tel, index) in biodataDetails?.addressContacts?.telContact">
                        <td>Telephone # {{ index + 1 }}</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataContactDetails.t ? biodataContactDetails.t.detail : '' }}</td>-->
                        <td>{{ tel.detail }}</td>
                      </tr>
                    </table>
                  </div>

                  <div class="col-lg-4">
                    <table class="detail-table" width="100%">
                      <tr v-for="(work, index) in biodataDetails?.addressContacts?.workContact">
                        <td>Work # {{ index + 1 }}</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataContactDetails.t ? biodataContactDetails.t.detail : '' }}</td>-->
                        <td>{{ work.detail }}</td>
                      </tr>
                    </table>
                  </div>

                  <div class="col-lg-4">
                    <table class="detail-table" width="100%">
                      <tr v-for="(personal, index) in biodataDetails?.addressContacts?.personalContact">
                        <td>Personal # {{ index + 1 }}</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataContactDetails.t ? biodataContactDetails.t.detail : '' }}</td>-->
                        <td>{{ personal.detail }}</td>
                      </tr>
                    </table>
                  </div>
                </div>


                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-4 ">
                    <table class="detail-table" width="100%">
                      <tr v-for="(email, index) in biodataDetails?.addressContacts?.emailAddresses">
                        <td>Email {{ index + 1 }}</td>
                        <td>:</td>
                        <!--                        <td>{{ biodataContactDetails.t ? biodataContactDetails.t.detail : '' }}</td>-->
                        <td>{{ email.detail }}</td>
                      </tr>
                    </table>
                  </div>
                </div>


                <!--                National Documents-->
                <div class="row text-left my-2 no-gutters mt-3">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold" style="font-size: 13px">National Documents</span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <table class="table table-bordered table-sm text-center small" width="100%">
                      <thead>
                      <tr>
                        <th class="th-no-color text-center">Document</th>
                        <th class="th-no-color text-center">Document No.</th>
                        <th class="th-no-color text-center">Issue Date</th>
                        <th class="th-no-color text-center">Valid Until</th>
                        <th class="th-no-color text-center">Rank</th>

                      </tr>
                      </thead>

                      <tbody>
                      <tr v-for="(crewDocsNational, index) in biodataDetails.biodataDocsNational"
                          :key="crewDocsNational.id">
                        <td class="align-middle">{{ crewDocsNational.documentName }}</td>
                        <td>{{ crewDocsNational.documentNo }}</td>
                        <td class="align-middle">{{ crewDocsNational.dateIssued_str }}</td>
                        <td class="align-middle">{{ crewDocsNational.dateExpiry_str }}</td>
                        <td class="align-middle">{{ crewDocsNational.rank }}</td>
                      </tr>


                      </tbody>

                    </table>
                  </div>
                </div>


                <!--                Training Courses-->
                <div class="row text-left my-2 no-gutters mt-3">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold" style="font-size: 13px">Training Courses</span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <table class="table table-bordered table-sm text-center small" width="100%">
                      <thead>
                      <tr>
                        <th class="th-no-color text-center">Document</th>
                        <th class="th-no-color text-center">Document No.</th>
                        <th class="th-no-color text-center">Issue Date</th>

                      </tr>
                      </thead>

                      <tbody>
                      <tr v-for="(training, index) in biodataDetails.biodataDocsTraining" :key="training.id">
                        <td class="align-middle">{{ training.documentName }}</td>
                        <td>{{ training.documentNo }}</td>
                        <td class="align-middle">{{ training.dateIssued_str }}</td>
                      </tr>
                      </tbody>

                    </table>
                  </div>
                </div>


                <!--                ECDIS-->
                <div class="row text-left my-2 no-gutters mt-3">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold" style="font-size: 13px">ECDIS</span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <table class="table table-bordered table-sm text-center small" width="100%">
                      <thead>
                      <tr>
                        <th class="th-no-color text-center">Document</th>
                        <th class="th-no-color text-center">Document No.</th>
                        <th class="th-no-color text-center">Issue Date</th>

                      </tr>
                      </thead>

                      <tbody>
                      <tr v-for="(ecdis, index) in biodataDetails.biodataDocsEcdis" :key="ecdis.id">
                        <td class="align-middle">{{ ecdis.documentName }}</td>
                        <td>{{ ecdis.documentNo }}</td>
                        <td class="align-middle">{{ ecdis.dateIssued_str }}</td>
                      </tr>
                      </tbody>

                    </table>
                  </div>
                </div>


                <!--                COP Certificates-->
                <div class="row text-left my-2 no-gutters mt-3">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold" style="font-size: 13px">COP Certificates</span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <table class="table table-bordered table-sm text-center small" width="100%">
                      <thead>
                      <tr>
                        <th class="th-no-color text-center">Document</th>
                        <th class="th-no-color text-center">Document No.</th>
                        <th class="th-no-color text-center">Issue Date</th>

                      </tr>
                      </thead>

                      <tbody>
                      <tr v-for="(cop, index) in biodataDetails.biodataDocsCop" :key="cop.id">
                        <td class="align-middle">{{ cop.documentName }}</td>
                        <td>{{ cop.documentNo }}</td>
                        <td class="align-middle">{{ cop.dateIssued_str }}</td>
                      </tr>
                      </tbody>

                    </table>
                  </div>
                </div>


                <!--                Medical Certificates-->
                <div class="row text-left my-2 no-gutters mt-3">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold"
                            style="font-size: 13px">Medical Certificates</span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <table class="table table-bordered table-sm text-center small" width="100%">
                      <thead>
                      <tr>
                        <th class="th-no-color text-center">Document</th>
                        <th class="th-no-color text-center">Validity Period</th>

                      </tr>
                      </thead>

                      <tbody>
                      <tr v-for="(medical, index) in biodataDetails.biodataDocsMedical" :key="medical.id">
                        <td class="align-middle">{{ medical.documentName }}</td>
                        <td>{{ medical.dateExpiry_str }}</td>
                      </tr>

                      </tbody>

                    </table>
                  </div>
                </div>

                <!--                Covid Vaccine & Booster -->
                <div class="row text-left my-2 no-gutters mt-3">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold"
                            style="font-size: 13px"> Covid Vaccine & Booster</span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <table class="table table-bordered table-sm text-center small" width="100%">
                      <thead>
                      <tr>
                        <th class="th-no-color text-center">Document</th>
                        <th class="th-no-color text-center">Is Booster</th>
                        <th class="th-no-color text-center">Inoculate Date</th>
                        <th class="th-no-color text-center">Completion Date</th>

                      </tr>
                      </thead>

                      <tbody>
                      <tr v-for="(covid, index) in biodataDetails.biodataDocsCovidBooster"
                          :key="covid.id">
                        <td class="align-middle">{{ covid.vaccineName }}</td>
                        <td class="align-middle">{{ covid.isBooster == 1 ? 'YES' : 'NO' }}</td>
                        <td>{{ covid.inoculateDate_str }}</td>
                        <td>{{ covid.completionDate_str }}</td>
                      </tr>

                      </tbody>

                    </table>
                  </div>
                </div>


                <!--                Foreign Documents-->
                <div class="row text-left my-2 no-gutters mt-3">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold"
                            style="font-size: 13px">Foreign Documents</span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <table class="table table-bordered table-sm text-center small" width="100%">
                      <thead>
                      <tr>
                        <th class="th-no-color text-center">Document</th>
                        <th class="th-no-color text-center">Document No</th>
                        <th class="th-no-color text-center">Issue Date</th>
                        <th class="th-no-color text-center">Valid Until</th>
                        <th class="th-no-color text-center">Rank</th>

                      </tr>
                      </thead>

                      <tbody>
                      <tr v-for="(crewDocsForeign, index) in biodataDetails.biodataDocsForeign"
                          :key="crewDocsForeign.id">
                        <td class="align-middle">{{ crewDocsForeign.documentName }}</td>
                        <td>{{ crewDocsForeign.documentNo }}</td>
                        <td class="align-middle">{{ crewDocsForeign.dateIssued_str }}</td>
                        <td class="align-middle">{{ crewDocsForeign.dateExpiry_str }}</td>
                        <td class="align-middle">{{ crewDocsForeign.rank }}</td>
                      </tr>

                      </tbody>

                    </table>
                  </div>
                </div>


                <!--                Sea Service Experiences from PHOENIX & EMM-->
                <div class="row text-left my-2 no-gutters mt-3">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold"
                            style="font-size: 13px">Sea Service Experiences from PHOENIX & EMM </span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <table class="table table-bordered table-sm text-center small" width="100%">
                      <thead>
                      <tr>
                        <th class="th-no-color text-center">Agency</th>
                        <th class="th-no-color text-center">Vessel</th>
                        <th class="th-no-color text-center">Rank</th>
                        <th class="th-no-color text-center">Sign On</th>
                        <th class="th-no-color text-center">Sign Off</th>
                        <th class="th-no-color text-center">Reason</th>
                        <th class="th-no-color text-center">Route</th>
                        <th class="th-no-color text-center">Vessel Type</th>
                        <th class="th-no-color text-center">GRT</th>
                        <th class="th-no-color text-center">Engine</th>
                        <th class="th-no-color text-center">KW</th>
                      </tr>
                      </thead>

                      <tbody>
                      <tr v-for="(seaService, index) in biodataDetails.getCrewSeaServices">
                        <td>{{ seaService.manning }}</td>
                        <td>{{ seaService.vessel }}</td>
                        <td>{{ seaService.rank }}</td>
                        <td>{{ seaService.signOnDate }}</td>
                        <td>{{ seaService.signOffDate }}</td>
                        <td>{{ seaService.signOffReasonCode }}</td>
                        <td>{{ seaService.tradeRouteCode }}</td>
                        <td>{{ seaService.vesselType }}</td>
                        <td>{{ seaService.grt }}</td>
                        <td>{{ seaService.engine }}</td>
                        <td>{{ seaService.kw }}</td>
                      </tr>

                      <!--                      <tr>-->
                      <!--                        <td colspan="100%" class="text-left">-->
                      <!--                          <span class="font-weight-bold">Eval By:</span>-->
                      <!--                          &#45;&#45; | &#45;&#45; | &#45;&#45; | &#45;&#45;-->
                      <!--                        </td>-->
                      <!--                      </tr>-->


                      </tbody>

                    </table>
                  </div>
                </div>

                <!--                Sea Service Experiences from Other Agencies-->
                <div class="row text-left my-2 no-gutters mt-3">
                  <div class="col-lg-12">
                    <p class="lead font-weight-bold"
                       style="width:100%;background-color:#DCDCDC; padding:2 0 2 5; font-weight:Bold;">
                      <span class="ml-1 font-weight-bold"
                            style="font-size: 13px">Sea Service Experiences from Other Agencies </span>
                    </p>
                  </div>
                </div>

                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <table class="table table-bordered table-sm text-center small" width="100%">
                      <thead>
                      <tr>
                        <th class="th-no-color text-center">Agency</th>
                        <th class="th-no-color text-center">Vessel</th>
                        <th class="th-no-color text-center">Rank</th>
                        <th class="th-no-color text-center">Sign On</th>
                        <th class="th-no-color text-center">Sign Off</th>
                        <th class="th-no-color text-center">Reason</th>
                        <th class="th-no-color text-center">Route</th>
                        <th class="th-no-color text-center">Vessel Type</th>
                        <th class="th-no-color text-center">GRT</th>
                        <th class="th-no-color text-center">Engine</th>
                        <th class="th-no-color text-center">KW</th>

                      </tr>
                      </thead>

                      <tbody>
                      <tr v-for="(seaService, index) in biodataDetails.getCrewOtherSeaServices">
                        <td>{{ seaService.manning }}</td>
                        <td>{{ seaService.vessel }}</td>
                        <td>{{ seaService.rank }}</td>
                        <td>{{ seaService.signOnDate }}</td>
                        <td>{{ seaService.signOffDate }}</td>
                        <td>{{ seaService.signOffReason }}</td>
                        <td>{{ seaService.tradeRouteCode }}</td>
                        <td>{{ seaService.vesselType }}</td>
                        <td>{{ seaService.grt }}</td>
                        <td>{{ seaService.mainEngine }}</td>
                        <td>{{ seaService.kw }}</td>
                      </tr>

                      <!--                      <tr>-->
                      <!--                        <td colspan="100%" class="text-left">-->
                      <!--                          <span class="font-weight-bold">Eval By:</span>-->
                      <!--                          &#45;&#45; | &#45;&#45; | &#45;&#45; | &#45;&#45;-->
                      <!--                        </td>-->
                      <!--                      </tr>-->


                      </tbody>

                    </table>
                  </div>
                </div>

                <br>
                <br>

                <hr>
                <div class="row text-left my-2 no-gutters">
                  <div class="col-lg-12">
                    <span class="font-weight-bold" style="font-size: 13px;">Legend :</span>
                    <small>
                      &nbsp;<b>FC -</b> Finished Contract&nbsp;&nbsp;&nbsp;&nbsp;<b>PR -</b> Promoted,&nbsp;&nbsp;&nbsp;&nbsp;<b>TR
                      -</b> Transferred,&nbsp;&nbsp;&nbsp;&nbsp;<b>PR&amp;TR -</b> Promoted and Transferred,&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>VW -</b> Vessel Wrecked/Vessel Laid-Off,&nbsp;&nbsp;&nbsp;&nbsp;<b>VS -</b> Vessel Sold/Changed
                      Management,&nbsp;&nbsp;&nbsp;&nbsp;<b>VCN -</b> Vessel Change Name,&nbsp;&nbsp;&nbsp;&nbsp;<b>PNI
                      -</b> Protect &amp; Indemnification Case/Sickness &amp; Injury,&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>NP -</b> Non-Performing Crew,&nbsp;&nbsp;&nbsp;&nbsp;<b>CO -</b> Criminal Offense,&nbsp;&nbsp;&nbsp;&nbsp;<b>AC
                      -</b> Administrative Case, <b>OR -</b> Own Request,&nbsp;&nbsp;&nbsp;&nbsp; <b>CG -</b>
                      Compassionate Ground.&nbsp;&nbsp;&nbsp;&nbsp;
                    </small>
                  </div>
                </div>

                <hr>

                <!--                <div class="form-group row mt-3">-->
                <!--                  <div class="form-inline mb-3">-->
                <!--                    <div class="form-group mb-2 mr-5">-->
                <!--                      <span class="font-weight-bold" style="font-size: 13px;">Overall Assesment Legend :</span>-->
                <!--                    </div>-->
                <!--                    <div class="form-group ">-->
                <!--                      <small>-->
                <!--                        <b>1.00-1.99 : </b>Very Good &nbsp;&nbsp;&nbsp;&nbsp;<b>2.00-2.99 : </b>Good &nbsp;&nbsp;&nbsp;&nbsp;<b>3.00-3.99-->
                <!--                        : </b>Average &nbsp;&nbsp;&nbsp;&nbsp;<b>4.00 : </b>Poor-->
                <!--                      </small>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->


                <!--                <div class="mt-5">-->
                <!--                  <table>-->
                <!--                    <tbody>-->
                <!--                    <tr>-->
                <!--                      <td colspan="2" style="text-align:left;">-->
                <!--                        <span style="font-size: 14px !important;" class="font-weight-bold">  Certified By:</span>-->
                <!--                      </td>-->
                <!--                    </tr>-->
                <!--                    <tr>-->
                <!--                      <td colspan="2">&nbsp;</td>-->
                <!--                    </tr>-->
                <!--                    <tr>-->
                <!--                      <td width="20%">&nbsp;</td>-->
                <!--                      <td style="font-weight:Bold;">-->
                <!--                        <br>-->
                <!--                        <br>-->
                <!--                        <br>-->
                <!--                        <br>-->
                <!--                        <span style="font-size: 14px !important;" class="font-weight-bold">{{-->
                <!--                            defaultCertifier.name-->
                <!--                          }}</span>-->
                <!--                        <br>-->
                <!--                        <label>-->
                <!--                          {{ defaultCertifier.position }}-->
                <!--                        </label>-->
                <!--                        <br>-->
                <!--                      </td>-->
                <!--                    </tr>-->
                <!--                    </tbody>-->
                <!--                  </table>-->
                <!--                </div>-->


              </div>

            </div>
          </div>
        </div>
        <div class="modal-footer p-2">
          <button type="button" data-dismiss="modal"
                  class="btn btn-secondary btn-sm px-3 font-weight-normal"
          >Close
          </button>
          <button type="button" class="btn btn-success btn-sm px-3 font-weight-normal"
                  @click="loadCrewBiodata"
          >
            Download
          </button>
        </div>
      </div>
    </div>

    <!-- generating pdf loading modal -->
    <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import noPhoto from '@/assets/noPhoto.jpg';
import pmcLogo from '@/assets/pmcLogo.jpg';
import emmcLogo from '@/assets/emmcLogo.jpg';
import {mapActions, mapGetters} from "vuex";
import {AlertMixins} from '@/mixins/alert.mixins'
import {AppMixins} from "@/mixins/app.mixins";
import utilityService from "@/service/utility.service";

import {global_variables_config} from "@/config/global-variable.config";
import Swal from "sweetalert2";
import {AlertService} from "@/service/alert.service";

export default {
  name: "CrewBiodata",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  /** props **/
  props: {

    // crewDataFromCrewProfile: {
    //   type: Object,
    //   default() {
    //     return {};
    //   }
    // },

    crewImage: {
      type: String,
      default() {
        return {};
      }
    },

    crew: {
      type: Object,
      default() {
        return {};
      }
    },


    // biodataDetails: {
    //   type: Object,
    //   default() {
    //     return {};
    //   }
    // },

  },


  data() {
    return {
      // crew: {},
      // crewGenInfo: {},
      // crewInfo: {},

      params: {
        crewId: this.$route.params.crewId,
      },

      noPhoto: noPhoto,
      pmcLogo: pmcLogo,
      emmcLogo: emmcLogo,

      chosenBiodataDoc: 1,

      biodata: '',
      biodataLoading: false,

      certifiers: [
        {
          id: 1,
          name: 'Christian Earle Lucas B. Jacinto',
          position: 'Vice President',
        },
        {
          id: 2,
          name: 'Erica Feliz M. Jacinto',
          position: 'Management Representative',
        },
      ],

      biodata_docs: [
        {
          id: 1,
          name: 'PMC',
        },
        {
          id: 2,
          name: 'EMMC',
        },
        {
          id: 3,
          name: 'Inter Ocean',
        },
      ],

      ccmDocsParams: {
        crewId: null,
        ccmDocument: null,
      },

    }
  },

  methods: {
    ...mapActions([
      'generateCrewBioData',
      'getBiodataDetails',

      // generate ccm document ex. ITF, RPS, INFO SHEET
      'generateCcmDocument',

    ]),

    async loadBiodata() {
      this.pdfViewer('Seafarer Biodata');
    },

    async loadCrewBiodata() {
      const biodataModal = $('#biodata-modal')
      AlertService.loadingAlert('Please wait ...', 'Loading Biodata');
      let params = {
        crewId: this.$route.params.crewId,
        biodataDocLogo: this.chosenBiodataDoc,
      }

      let crewBiodataFile = await this.generateCrewBioData(params);
      if (crewBiodataFile) {
        Swal.close();
        biodataModal.modal('hide');
        this.pdfViewerMasterChecklist('Seafarer Biodata', crewBiodataFile);
      } else {
        Swal.close();
      }
    },


    async loadInterocean(ccmDoc, crewId) {
      this.ccmDocsParams.ccmDocument = ccmDoc;
      this.ccmDocsParams.crewId = crewId;
      const biodataModal = $('#biodata-modal')
      AlertService.loadingAlert('Please wait ...', 'Loading Interocean');
      let ccmDocumentFile = await this.generateCcmDocument(this.ccmDocsParams);
      if (ccmDocumentFile) {
        Swal.close();
        biodataModal.modal('hide');
        if (ccmDoc === 6) {
          await this.pdfViewerInterOcean(global_variables_config.ccmDocsName[ccmDoc] + ' File', ccmDocumentFile.page1, ccmDocumentFile.page2);
        } else await this.pdfViewer(global_variables_config.ccmDocsName[ccmDoc] + ' File', ccmDocumentFile);
      } else {
        Swal.close();
      }
    },

    initializeData() {
      this.$globalVariables = global_variables_config
    },

  },

  computed: {
    ...mapGetters([
      'biodataDetails'
    ]),

    image: {
      get() {
        return this.noPhoto;
      }
    },

    pmcLogoImage: {
      get() {
        return this.pmcLogo;
      }
    },

    emmcLogoImage: {
      get() {
        return this.emmcLogo;
      }
    },

  },

  watch: {
    // DEPRECATED;
    // do not remove for reference only
    // reason: biodata doesnt update when crew profile changes
    // this reacts to route changes
    // $route(to, from) {
    //   this.initializeData();
    // }

  },


  /** created **/
  created() {
    this.initializeData();
  },

  beforeDestroy() {
    this.$globalVariables = null;
  },


}
</script>

<style scoped>
.lg-width {
  max-width: 800px !important;
}

.xl-width {
  max-width: 1280px !important;
}

.xxl-width {
  max-width: 1620px !important;
}

#CrewBiodataValue * {
  margin: 0;
  font-size: 12px;
}

table thead * {
  font-size: 12px !important;
}


.single-table tr td:first-child {
  width: 140px;
  font-weight: bolder;
}

.single-table tr td:nth-child(2) {
  width: 20px;
}

.single-table tr td:nth-child(3) {
  border-bottom: 1px solid #6c757d;
  padding: 0 7px;
  color: blue;
}


.detail-table tr td:first-child {
  width: 90px;
  font-weight: bolder;
}

.detail-table tr td:nth-child(2) {
  width: 20px;
}

.detail-table tr td:nth-child(3) {
  /*border-bottom: 1px solid #6c757d;*/
  padding: 0 7px;
  color: blue;
}


.educ-attain-table tr td:first-child {
  width: 150px;
  font-weight: bolder;
}

.educ-attain-table tr td:nth-child(2) {
  width: 20px;
}

.educ-attain-table tr td:nth-child(3) {
  /*border-bottom: 1px solid #6c757d;*/
  padding: 0 7px;
  color: blue;
}

.school-attend tr td:first-child {
  width: 150px;
  font-weight: bolder;
}

.school-attend tr td:nth-child(2) {
  width: 20px;
}

.school-attend tr td:nth-child(3) {
  /*border-bottom: 1px solid #6c757d;*/
  padding: 0 7px;
  color: blue;
}

.th-no-color {
  background-color: white;
}


.bottom-underline {
  border-bottom: 1px solid black;
  padding: 5px;
}


.medical-table tr td:first-child, .medical-table tr td:nth-child(3n + 1) {
  width: 140px;
}

.medical-table tr td:nth-child(3n + 4) {
  text-align: right;
}

.medical-table tr td:nth-child(3n + 2) {
  width: 20px;
}

.medical-table tr td:nth-child(3n + 3) {
  border-bottom: 1px solid #6c757d;
  padding: 0 7px;
}

#CrewBiodata .modal-header .close {
  padding: .5rem .5rem;
  margin: -.5rem -.5rem -1rem auto;
}

.color-black * {
  color: #000000;
}

.color-blue {
  color: blue;
}


@media print {
  #CrewBiodataValue * {
    color: #000000;
  }
}


</style>
