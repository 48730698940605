<template>
  <div class="row">
    <div class="container-fluid">
      <p class="font-weight-bold my-3">Crew Evaluation</p>
      <pmc-table :max-height="600" :loading="isReloading" :loaderColspan="11">
        <template v-slot:table-header>
          <tr>
            <th>#</th>
            <th>Rank</th>
            <th>Vessel Name</th>
            <th>Agency</th>
            <th>Vessel Type</th>
            <th>Date Signed On</th>
            <th>Date Signed Off</th>
            <th>1st Quarter</th>
            <th>2nd Quarter</th>
            <th>3rd Quarter</th>
            <th>4th Quarter</th>
          </tr>
        </template>
        <template v-slot:table-body >
          <tr v-for="(crewEvalDetails,index) in crewEvaluationData" :key="crewEvalDetails.id" class=""
              data-toggle="modal"
              data-target="#crew-evaluation-modal"
              data-backdrop="static"
              data-keyboard="false"
              @click="setEvaluation(crewEvalDetails.crew_change_id, crewEvalDetails.rank_id)">
            <td>{{ index + 1 }}</td>
            <td>{{crewEvalDetails.rank}}</td>
            <td>{{crewEvalDetails.vessel}}</td>
            <td>{{crewEvalDetails.agency}}</td>
            <td>{{crewEvalDetails.vesselType}}</td>
            <td>{{crewEvalDetails.sign_on}}</td>
            <td>{{crewEvalDetails.sign_off}}</td>
            <td>
              <span :class="[crewEvaluations[crewEvalDetails.crew_change_id][1] ? rateViewEnabled : rateViewDisabled]">
                {{crewEvaluations[crewEvalDetails.crew_change_id][1] ? crewEvaluations[crewEvalDetails.crew_change_id][1].overallgrade : 'No Rating'}}
              </span>
            </td>
            <td>
              <span :class="[crewEvaluations[crewEvalDetails.crew_change_id][2] ? rateViewEnabled : rateViewDisabled]">
                {{crewEvaluations[crewEvalDetails.crew_change_id][2] ? crewEvaluations[crewEvalDetails.crew_change_id][2].overallgrade : 'No Rating'}}
              </span>
            </td>
            <td>
              <span :class="[crewEvaluations[crewEvalDetails.crew_change_id][3] ? rateViewEnabled : rateViewDisabled]">
                {{crewEvaluations[crewEvalDetails.crew_change_id][3] ? crewEvaluations[crewEvalDetails.crew_change_id][3].overallgrade : 'No Rating'}}
              </span>
            </td>
            <td>
              <span :class="[crewEvaluations[crewEvalDetails.crew_change_id][4] ? rateViewEnabled : rateViewDisabled]">
                {{crewEvaluations[crewEvalDetails.crew_change_id][4] ? crewEvaluations[crewEvalDetails.crew_change_id][4].overallgrade : 'No Rating'}}
              </span>
            </td>
          </tr>
        </template>
      </pmc-table>

      <div class="modal fade" id="crew-evaluation-modal" tabindex="-1" role="dialog"  aria-labelledby="crewEvaluationModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">({{crew.rank}}) {{crew.name}}</h5>
              <div class="btn-group">
                <button class="btn btn-sm ml-2" aria-label="Close" data-dismiss="modal">
                  <font-awesome-icon icon="times" class="text-danger"/>
                  Close
                </button>
              </div>
            </div>
            <div class="modal-body">
              <div>
                  <div class="row">
                    <div class="col-6">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="font-weight-bold nav-link active" id="emailAddress-tab" data-toggle="tab" href="#firstQuarter" role="tab"
                             aria-controls="home" aria-selected="true" @click="enableEvaluation(0)">1st Quarter</a>
                        </li>
                        <li class="nav-item">
                          <a class="font-weight-bold nav-link" id="workContact-tab" data-toggle="tab" href="#secondQuarter" role="tab"
                             aria-controls="profile" aria-selected="false" @click="enableEvaluation(0)">2nd Quarter</a>
                        </li>
                        <li class="nav-item">
                          <a class="font-weight-bold nav-link" id="telephoneContact-tab" data-toggle="tab" href="#thirdQuarter" role="tab"
                             aria-controls="contact" aria-selected="false" @click="enableEvaluation(0)">3rd Quarter</a>
                        </li>
                        <li class="nav-item">
                          <a class="font-weight-bold nav-link" id="personalContact-tab" data-toggle="tab" href="#fourthQuarter" role="tab"
                             aria-controls="contact" aria-selected="false" @click="enableEvaluation(0)">4th Quarter</a>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade eval-content show active" id="firstQuarter" role="tabpanel" aria-labelledby="home-tab">
                          <div class="row mt-2">
                            <div class="col-12">
                              <div class="p-3 pt-4">
                                <div class="row p-2 text-left">
                                  <div class="col-5">
                                    Total Rating : <b class="font-weight-bolder" style="font-size: 1.1em;">{{currentEval[1].overallgrade}}</b>
                                  </div>
                                  <div class="col-7">
                                    Evaluation Date : <b>{{currentEval[1].date_evaluation}}</b>
                                  </div>
                                </div>
                                <div style="border:1px solid rgba(0,0,0,0.2);padding: 10px 25px;height: 450px; overflow-y: scroll; overflow-x: hidden; scrollbar-width: auto;">
                                  <div v-for="(evaluate,index) in currentEval[1].evaluation">
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Rating <span style="float: right;">:</span>
                                      </div>
                                      <div class="col-7">
                                        <input type="text" class="form-control" v-model="evaluate.avg_grade" />
                                      </div>
                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Comment/Remark
                                      </div>
                                      <div class="col-7">
                                        <textarea class="form-control" rows="4" v-model="evaluate.remarks"></textarea>
                                      </div>

                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Evaluator
                                      </div>
                                      <div class="col-7">
                                        <multi-select
                                          v-model="evaluate.evaluatorData"
                                          :options="crewEvaluators"
                                          :custom-label="namesLang"
                                          :select-label="'Select'"
                                          placeholder="Select Evaluator"
                                          label="VesselType"
                                          track-by="id">
                                        </multi-select>
                                      </div>

                                    </div>

                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Recommended Rank
                                      </div>
                                      <div class="col-7">
                                        <select class="form-control" v-model="evaluate.recommended_rank">
                                          <option v-for="(rank, index) in recommendedRanks" :value="rank.id">{{rank.name}}</option>
                                        </select>
                                      </div>

                                    </div>
                                    <div class="row p-1 text-left">
                                      <div class="col-12">
                                        <button type="button"
                                                class="btn btn-primary btn-sm float-right evaluation-btn"
                                                data-toggle="modal"
                                                v-model="currentEval[1].id ?? ''"
                                                @click="parseEvaluationRatings(1, index); resetEditRating()"
                                                data-target="#crew-evaluation-form-data">
                                          View Evaluation
                                        </button>
                                        <button type="button"
                                                class="btn btn-success btn-sm float-right mr-1 evaluation-btn"
                                                @click="enableEvaluation(evaluate, 1)">
                                          Update
                                        </button>
                                        <button type="button"
                                                v-if="index > 0 && evaluate.id === 0"
                                                class="btn btn-danger btn-sm float-right mr-1 evaluation-btn"
                                                @click="removeEvaluation(1,index)">
                                          Remove
                                        </button>
                                        <button type="button" v-if="!evaluate.hasDoc"
                                                class="btn btn-outline-dark btn-light btn-sm float-right mr-1 evaluation-btn"
                                                @click="triggerUpload(evaluate.id)">
                                          Upload Document
                                        </button>
                                      </div>
                                    </div>
                                    <div class="row text-left">
                                      <div class="col-12">
                                        <button type="button"
                                                v-if="evaluate.hasDoc"
                                                class="btn pmc-btn-info btn-sm float-right mr-1 evaluation-btn"
                                                @click="getEvaluationDoc(evaluate.id)">
                                          View Document
                                        </button>
                                        <button type="button"
                                                v-if="evaluate.hasDoc"
                                                class="btn btn-danger btn-sm float-right mr-1 evaluation-btn"
                                                @click="removeEvaluationDoc(evaluate.id)">
                                          Remove Document
                                        </button>
                                        <input type="file"
                                               style="display: none !important;"
                                               ref="fileUpload"
                                               id="attachmentFile"
                                               class="form-control-file"
                                               @change="on_up_file_changes"
                                               placeholder="--">
                                      </div>
                                    </div>
                                    <hr>
                                  </div>
                                </div>
                                <button type="button"
                                        class="btn btn-warning btn-sm float-right mr-1 mt-2 mb-2"
                                        @click="newEvaluation(1)">
                                  Add New Evaluation
                                </button>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade eval-content" id="secondQuarter" role="tabpanel" aria-labelledby="workContact-tab">
                          <div class="row mt-2">
                            <div class="col-12">
                              <div class="p-3 pt-4">
                                <div class="row p-2 text-left">
                                  <div class="col-5">
                                    Total Rating : <b class="font-weight-bolder" style="font-size: 1.1em;">{{currentEval[2].overallgrade}}</b>
                                  </div>
                                  <div class="col-7">
                                    Evaluation Date : <b>{{currentEval[2].date_evaluation}}</b>
                                  </div>
                                </div>
                                <div style="border:1px solid rgba(0,0,0,0.2);padding: 10px 25px;height: 450px; overflow-y: scroll; overflow-x: hidden; scrollbar-width: auto;">
                                  <div v-for="(evaluate,index) in currentEval[2].evaluation">
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Rating <span style="float: right;">:</span>
                                      </div>
                                      <div class="col-7">
                                        <input type="text" class="form-control" v-model="evaluate.avg_grade" />
                                      </div>
                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Comment/Remark
                                      </div>
                                      <div class="col-7">
                                        <textarea class="form-control" rows="4" v-model="evaluate.remarks"></textarea>
                                      </div>

                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Evaluator
                                      </div>
                                      <div class="col-7">
                                        <multi-select
                                          v-model="evaluate.evaluatorData"
                                          :options="crewEvaluators"
                                          :custom-label="namesLang"
                                          :select-label="'Select'"
                                          placeholder="Select Evaluator"
                                          label="VesselType"
                                          track-by="id">
                                        </multi-select>
                                      </div>

                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Recommended Rank
                                      </div>
                                      <div class="col-7">
                                        <select class="form-control" v-model="evaluate.recommended_rank">
                                          <option v-for="(rank, index) in recommendedRanks" :value="rank.id">{{rank.name}}</option>
                                        </select>
                                      </div>

                                    </div>
                                    <div class="row p-1 text-left">
                                      <div class="col-12">
                                        <button type="button"
                                                class="btn btn-primary btn-sm float-right evaluation-btn"
                                                data-toggle="modal"
                                                v-model="currentEval[2].id ?? ''"
                                                @click="parseEvaluationRatings(2, index); resetEditRating()"
                                                data-target="#crew-evaluation-form-data">
                                          View Evaluation
                                        </button>
                                        <button type="button"
                                                class="btn btn-success btn-sm float-right mr-1 evaluation-btn"
                                                @click="enableEvaluation(evaluate, 2)">
                                          Update
                                        </button>
                                        <button type="button"
                                                v-if="index > 0 && evaluate.id === 0"
                                                class="btn btn-danger btn-sm float-right mr-1 evaluation-btn"
                                                @click="removeEvaluation(2,index)">
                                          Remove
                                        </button>
                                        <button type="button" v-if="!evaluate.hasDoc"
                                                class="btn btn-outline-dark btn-light btn-sm float-right mr-1 evaluation-btn"
                                                @click="triggerUpload(evaluate.id)">
                                          Upload Document
                                        </button>
                                      </div>
                                    </div>
                                    <div class="row text-left">
                                      <div class="col-12">
                                        <button type="button"
                                                v-if="evaluate.hasDoc"
                                                class="btn pmc-btn-info btn-sm float-right mr-1 evaluation-btn"
                                                @click="getEvaluationDoc(evaluate.id)">
                                          View Document
                                        </button>
                                        <button type="button"
                                                v-if="evaluate.hasDoc"
                                                class="btn btn-danger btn-sm float-right mr-1 evaluation-btn"
                                                @click="removeEvaluationDoc(evaluate.id)">
                                          Remove Document
                                        </button>
                                        <input type="file"
                                               style="display: none !important;"
                                               ref="fileUpload"
                                               id="attachmentFile"
                                               class="form-control-file"
                                               @change="on_up_file_changes"
                                               placeholder="--">
                                      </div>
                                    </div>
                                    <hr>
                                  </div>
                                </div>
                                <button type="button"
                                        class="btn btn-warning btn-sm float-right mr-1 mt-2 mb-2"
                                        @click="newEvaluation(2)">
                                  Add New Evaluation
                                </button>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade eval-content" id="thirdQuarter" role="tabpanel" aria-labelledby="telephoneContact-tab">
                          <div class="row mt-2">
                            <div class="col-12">
                              <div class="p-3 pt-4">
                                <div class="row p-2 text-left">
                                  <div class="col-5">
                                    Total Rating : <b class="font-weight-bolder" style="font-size: 1.1em;">{{currentEval[3].overallgrade}}</b>
                                  </div>
                                  <div class="col-7">
                                    Evaluation Date : <b>{{currentEval[3].date_evaluation}}</b>
                                  </div>
                                </div>
                                <div style="border:1px solid rgba(0,0,0,0.2);padding: 10px 25px;height: 450px; overflow-y: scroll; overflow-x: hidden; scrollbar-width: auto;">
                                  <div v-for="(evaluate,index) in currentEval[3].evaluation">
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Rating <span style="float: right;">:</span>
                                      </div>
                                      <div class="col-7">
                                        <input type="text" class="form-control" v-model="evaluate.avg_grade" />
                                      </div>
                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Comment/Remark
                                      </div>
                                      <div class="col-7">
                                        <textarea class="form-control" rows="4" v-model="evaluate.remarks"></textarea>
                                      </div>

                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Evaluator
                                      </div>
                                      <div class="col-7">
                                        <multi-select
                                          v-model="evaluate.evaluatorData"
                                          :options="crewEvaluators"
                                          :custom-label="namesLang"
                                          :select-label="'Select'"
                                          placeholder="Select Evaluator"
                                          label="VesselType"
                                          track-by="id">
                                        </multi-select>
                                      </div>

                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Recommended Rank
                                      </div>
                                      <div class="col-7">
                                        <select class="form-control" v-model="evaluate.recommended_rank">
                                          <option v-for="(rank, index) in recommendedRanks" :value="rank.id">{{rank.name}}</option>
                                        </select>
                                      </div>

                                    </div>
                                    <div class="row p-1 text-left">
                                      <div class="col-12">
                                        <button type="button"
                                                class="btn btn-primary btn-sm float-right evaluation-btn"
                                                data-toggle="modal"
                                                v-model="currentEval[3].id ?? ''"
                                                @click="parseEvaluationRatings(3, index); resetEditRating()"
                                                data-target="#crew-evaluation-form-data">
                                          View Evaluation
                                        </button>
                                        <button type="button"
                                                class="btn btn-success btn-sm float-right mr-1 evaluation-btn"
                                                @click="enableEvaluation(evaluate, 3)">
                                          Update
                                        </button>
                                        <button type="button"
                                                v-if="index > 0 && evaluate.id === 0"
                                                class="btn btn-danger btn-sm float-right mr-1 evaluation-btn"
                                                @click="removeEvaluation(3,index)">
                                          Remove
                                        </button>
                                        <button type="button" v-if="!evaluate.hasDoc"
                                                class="btn btn-outline-dark btn-light btn-sm float-right mr-1 evaluation-btn"
                                                @click="triggerUpload(evaluate.id)">
                                          Upload Document
                                        </button>
                                      </div>
                                    </div>
                                    <div class="row text-left">
                                      <div class="col-12">
                                        <button type="button"
                                                v-if="evaluate.hasDoc"
                                                class="btn pmc-btn-info btn-sm float-right mr-1 evaluation-btn"
                                                @click="getEvaluationDoc(evaluate.id)">
                                          View Document
                                        </button>
                                        <button type="button"
                                                v-if="evaluate.hasDoc"
                                                class="btn btn-danger btn-sm float-right mr-1 evaluation-btn"
                                                @click="removeEvaluationDoc(evaluate.id)">
                                          Remove Document
                                        </button>
                                        <input type="file"
                                               style="display: none !important;"
                                               ref="fileUpload"
                                               id="attachmentFile"
                                               class="form-control-file"
                                               @change="on_up_file_changes"
                                               placeholder="--">
                                      </div>
                                    </div>
                                    <hr>
                                  </div>
                                </div>
                                <button type="button"
                                        class="btn btn-warning btn-sm float-right mr-1 mt-2 mb-2"
                                        @click="newEvaluation(3)">
                                  Add New Evaluation
                                </button>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade eval-content" id="fourthQuarter" role="tabpanel" aria-labelledby="personalContact-tab">
                          <div class="row mt-2">
                            <div class="col-12">
                              <div class="p-3 pt-4">
                                <div class="row p-2 text-left">
                                  <div class="col-5">
                                    Total Rating : <b class="font-weight-bolder" style="font-size: 1.1em;">{{currentEval[4].overallgrade}}</b>
                                  </div>
                                  <div class="col-7">
                                    Evaluation Date : <b>{{currentEval[4].date_evaluation}}</b>
                                  </div>
                                </div>
                                <div style="border:1px solid rgba(0,0,0,0.2);padding: 10px 25px;height: 450px; overflow-y: scroll; overflow-x: hidden; scrollbar-width: auto;">
                                  <div v-for="(evaluate,index) in currentEval[4].evaluation">
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Rating <span style="float: right;">:</span>
                                      </div>
                                      <div class="col-7">
                                        <input type="text" class="form-control" v-model="evaluate.avg_grade" />
                                      </div>
                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Comment/Remark
                                      </div>
                                      <div class="col-7">
                                        <textarea class="form-control" rows="4" v-model="evaluate.remarks"></textarea>
                                      </div>

                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Evaluator
                                      </div>
                                      <div class="col-7">
                                        <multi-select
                                          v-model="evaluate.evaluatorData"
                                          :options="crewEvaluators"
                                          :custom-label="namesLang"
                                          :select-label="'Select'"
                                          placeholder="Select Evaluator"
                                          label="VesselType"
                                          track-by="id">
                                        </multi-select>
                                      </div>

                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-5">
                                        Recommended Rank
                                      </div>
                                      <div class="col-7">
                                        <select class="form-control" v-model="evaluate.recommended_rank">
                                          <option v-for="(rank, index) in recommendedRanks" :value="rank.id">{{rank.name}}</option>
                                        </select>
                                      </div>

                                    </div>
                                    <div class="row p-2 text-left">
                                      <div class="col-12">
                                        <button type="button"
                                                class="btn btn-primary btn-sm float-right evaluation-btn"
                                                data-toggle="modal"
                                                v-model="currentEval[4].id ?? ''"
                                                @click="parseEvaluationRatings(4, index); resetEditRating()"
                                                data-target="#crew-evaluation-form-data">
                                          View Evaluation
                                        </button>
                                        <button type="button"
                                                class="btn btn-success btn-sm float-right mr-1 evaluation-btn"
                                                @click="enableEvaluation(evaluate, 4)">
                                          Update
                                        </button>
                                        <button type="button"
                                                v-if="index > 0 && evaluate.id === 0"
                                                class="btn btn-danger btn-sm float-right mr-1 evaluation-btn"
                                                @click="removeEvaluation(4,index)">
                                          Remove
                                        </button>
                                        <button type="button" v-if="!evaluate.hasDoc"
                                                class="btn btn-outline-dark btn-light btn-sm float-right mr-1 evaluation-btn"
                                                @click="triggerUpload(evaluate.id)">
                                          Upload Document
                                        </button>
                                      </div>
                                    </div>
                                    <div class="row text-left">
                                      <div class="col-12">
                                        <button type="button"
                                                v-if="evaluate.hasDoc"
                                                class="btn pmc-btn-info btn-sm float-right mr-1 evaluation-btn"
                                                @click="getEvaluationDoc(evaluate.id)">
                                          View Document
                                        </button>
                                        <button type="button"
                                                v-if="evaluate.hasDoc"
                                                class="btn btn-danger btn-sm float-right mr-1 evaluation-btn"
                                                @click="removeEvaluationDoc(evaluate.id)">
                                          Remove Document
                                        </button>
                                        <input type="file"
                                               style="display: none !important;"
                                               ref="fileUpload"
                                               id="attachmentFile"
                                               class="form-control-file"
                                               @change="on_up_file_changes"
                                               placeholder="--">
                                      </div>
                                    </div>
                                    <hr>
                                  </div>
                                </div>
                                <button type="button"
                                        class="btn btn-warning btn-sm float-right mr-1 mt-2 mb-2"
                                        @click="newEvaluation(4)">
                                  Add New Evaluation
                                </button>
                                <br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <span class="font-weight-bold">EVALUATION DOCUMENT</span>
                              <span><br>{{formDocFile.filename}}</span>
                            </div>
                            <div class="row mt-3">
                              <div class="col">
                                <div class="prev-container" style="border: 1px solid rgba(0,0,0,0.2);height: 545px;">
                                  <div v-if="formDocFile.file" style="width: auto; height: 498px;padding: 1px;">
                                    <iframe :src="formDocFile.file" style="height: 100%;" frameborder="0" width="100%" id="certFrame"></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
              <hr>
              <div style="float: left !important;">
                <span style="color: red;float: left !important;" >Note: Direct update on the average rating without using the evaluation form may differ the actual computation.</span>
              </div>
              <br>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="add-evaluation" tabindex="-1" role="dialog"  aria-labelledby="addEvaluation" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addCrewEvaluation">{{ evaluationId }} Quarter Evaluation</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table class="table-bordered evaluation-table">
                <thead>
                <tr>
                  <th> # </th>
                  <th>Evaluator</th>
                  <th>Evaluation Form</th>
                  <th>Rating</th>
                  <th>Remarks</th>
                  <th>Attachment</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(evalHdr,index) in evaluationHdrs.crewEvalHdr" :key="evalHdr.id">
                  <td>{{(index + 1)}}</td>
                  <td>
                    <select v-model="evalHdr.evaluator_id" name="evaluator" class="custom-select" id="crew-evaluator">>
                      <option :value="evaluator.id"
                              v-for="(evaluator,index) in evaluators"
                              :key="evaluator.id"
                              :selected="evaluator.id == 0">
                        {{'(' + evaluator.rank_alias + ') ' + capitalizePhrase(evaluator.fullname) }}
                      </option>
                    </select>
                  </td>
                  <td><a href="" type="button" @click="parseCrewEvaluationDetails(evalHdr.id)"
                         class="btn btn-sm pmc-btn-primary btn-view-form"
                         data-toggle="modal"
                         data-target="#crew-evaluation-form"
                         data-keyboard="false">
                    <font-awesome-icon icon="edit" />
                    View Form
                  </a></td>
                  <td>{{evalHdr.avg_grade}}</td>
                  <td>N/A</td>
                  <td></td>
                </tr>
                <tr v-if="!evaluationHdrs.crewEvalHdr">
                  <td>1</td>
                  <td>(MSTR) Roy F. Palayon</td>
                  <td><a href="" type="button"
                         class="btn btn-sm pmc-btn-primary btn-view-form"
                         data-toggle="modal"
                         data-target="#crew-evaluation-form"
                         data-keyboard="false">
                    <font-awesome-icon icon="edit"/>
                    View Form
                  </a></td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td></td>
                </tr>
                <tr class="bold-row">
                  <td></td>
                  <td>Total Rating</td>
                  <td><a href="" type="button" @click="resetRatings"
                         class="btn btn-sm pmc-btn-primary btn-view-form"
                         data-toggle="modal"
                         data-target="#"
                         data-keyboard="false">
                    <font-awesome-icon icon="edit"/>
                    View Form
                  </a></td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td></td>
                </tr>
                </tbody>

              </table>
            </div>
            <div class="modal-footer">
              <!--<button type="button" class="btn pmc-btn-prime" >Update</button>-->
              <button type="button" class="btn pmc-btn-second" data-dismiss="modal" >Close</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="crew-evaluation-form" tabindex="-1" role="dialog"  aria-labelledby="addEvaluation" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="crewEvaluationForm">Crew Performance Evaluation Report</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table style="width: 100%;">
                <table class="table-bordered evaluation-table-head">
                  <thead>
                  <tr>
                    <th class="evaluation-table-count"> INDEX </th>
                    <th> CLASSIFICATION </th>
                    <th  class="evaluation-table-rating"> RATING </th>
                  </tr>
                  </thead>
                </table>
                <tr  v-if="evalCriteria.criteria" v-for="(evalCriteria,index) in evaluationCriteria" :key="evalCriteria.id">
                  <table class="table-bordered evaluation-table">
                    <thead>
                    <tr v-if="evalCriteria.group">
                      <th class="evaluation-table-count"> {{evalCriteria.id}} </th>
                      <th> {{evalCriteria.group}}</th>
                      <th  class="evaluation-table-rating">{{subAverageRating[evalCriteria.index]}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(criterion,index) in evalCriteria.criteria" :key="criterion.id">
                      <td class="evaluation-table-count"> {{evalCriteria.id+'.'+criterion.position}} </td>
                      <td> {{capitalizeString(criterion.criteria)}}</td>
                      <td class="evaluation-table-rating">
                        <input v-model="evaluationRating[evalCriteria.index][criterion.eval_criteria_id ? criterion.eval_criteria_id : criterion.id]"
                               @change="getSubRating(evaluationRating[evalCriteria.index][criterion.eval_criteria_id ? criterion.eval_criteria_id : criterion.id],evalCriteria.index,criterion.eval_criteria_id ? criterion.eval_criteria_id : criterion.id)"
                               type="text" pattern="[1-4]" oninput="validity.valid||(value='')" value="criterion.grade ? parseInt(criterion.grade) : ''" class="form-control" style="text-align: center; -moz-appearance: textfield" required/>
                      </td>
                    </tr>
                    </tbody>

                  </table>

                </tr>
                <tr>
                  <table class="table-bordered evaluation-table">
                    <thead>
                    <tr>
                      <th class="evaluation-table-count">  </th>
                      <th> OVERALL RATING </th>
                      <th class="evaluation-table-rating"><span>{{overallRating}}</span></th>
                    </tr>
                    </thead>
                  </table>
                </tr>
              </table>
              <br />
              <table style="width: 100%;">
                <tr>
                  <table class="evaluation-table">
                    <thead>
                    <tr>
                      <th> Legend for Assessment </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr><td>1. An asset to the company. For promotion</td></tr>
                    <tr><td>2. A good man. Could be considerable for promotion</td></tr>
                    <tr><td>3. Satisfactory but acceptable for continued employment</td></tr>
                    <tr><td>4. Doubtful case maybe to be given further chance or NFR</td></tr>
                    <tr><td></td></tr>
                    <tr><td><small class="text-danger">NOTE: Kindly address with your honest evaluation when performing this task.</small></td></tr>
                    </tbody>
                  </table>
                </tr>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn pmc-btn-prime" @click="saveCrewEvaluation">{{isEvalNew ? 'Save' : 'Update'}}</button>
              <button type="button" class="btn pmc-btn-second" data-dismiss="modal" >Close</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="crew-evaluation-form-data" tabindex="-1" role="dialog"  aria-labelledby="addEvaluation" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="crewEvaluationForm">Crew Performance Evaluation Report</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="height: 600px; overflow-y: auto; scrollbar-width: auto;">
              <table style="width: 100%;">
                <tr>
                  <td>
                    <table class="table-bordered evaluation-table-head">
                      <thead>
                      <tr>
                        <th class="evaluation-table-count"> INDEX </th>
                        <th> CLASSIFICATION </th>
                        <th  class="evaluation-table-rating"> RATING </th>
                      </tr>
                      </thead>
                    </table>
                  </td>
                </tr>
                <tr v-for="(evalCriteria,index) in crewEvaluationCriteria.groups" :key="evalCriteria.id">
                  <td v-if="Object.keys(evalCriteria.criteria).length > 0">
                    <table class="table-bordered evaluation-table">
                      <thead>
                      <tr>
                        <th class="evaluation-table-count"> {{evalCriteria.id}} </th>
                        <th> {{capitalizeString(evalCriteria.group)}}</th>
                        <th  class="evaluation-table-rating"></th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(criterion,index) in evalCriteria.criteria" :key="criterion.id">
                          <td class="evaluation-table-count"> {{evalCriteria.id+'.'+criterion.position}} </td>
                          <td> {{capitalizeString(criterion.criteria)}}</td>
                          <td class="evaluation-table-rating">
                            <input :class="[!getTotalRating(criterion.grade, criterion.id) ? 'input-no-rating' : '']" @change="getTotalRating(criterion.grade, criterion.id)" :disabled="!enableRating" type="text" pattern="[1-4]" oninput="validity.valid||(value='')" v-model="criterion.grade" class="form-control" style="text-align: center;" required/>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </td>
                </tr>
                <tr>
                  <table class="table-bordered evaluation-table">
                    <thead>
                    <tr>
                      <th class="evaluation-table-count">  </th>
                      <th> OVERALL RATING </th>
                      <th class="evaluation-table-rating"><span>{{crewEvaluationCriteria.avg_grade}}</span></th>
                    </tr>
                    </thead>
                  </table>
                </tr>
              </table>
              <br />
              <table style="width: 100%;">
                <tr>
                  <table class="evaluation-table">
                    <thead>
                    <tr>
                      <th> Legend for Assessment </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr><td>1. An asset to the company. For promotion</td></tr>
                    <tr><td>2. A good man. Could be considerable for promotion</td></tr>
                    <tr><td>3. Satisfactory but acceptable for continued employment</td></tr>
                    <tr><td>4. Doubtful case maybe to be given further chance or NFR</td></tr>
                    <tr><td></td></tr>
                    <tr><td><small class="text-danger">NOTE: Kindly address with your honest evaluation when performing this task.</small></td></tr>
                    </tbody>
                  </table>
                </tr>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button"
                      v-if="!parseEvalParam.isNew"
                      class="btn btn-primary btn-sm float-right mr-1"
                      @click="loadEvaluationFormPDF(crewEvaluationCriteria.id,crewEvaluationCriteria.cer_id,crewEvaluationCriteria)">
                Print
              </button>
              <button type="button"
                      v-if="!enableRating"
                      class="btn btn-info btn-sm float-right mr-1"
                      @click="enableRating = !enableRating">
                Modify
              </button>
              <button type="button"
                      v-if="enableRating"
                      class="btn btn-success btn-sm float-right mr-1"
                      @click="updateEvaluationRating">
                Update
              </button>
              <button type="button"
                      v-if="enableRating"
                      class="btn btn-warning btn-sm float-right mr-1"
                      @click="cancelEvalModify">
                Cancel
              </button>
              <button type="button"
                      class="btn btn-danger btn-sm float-right mr-1"
                      data-dismiss="modal"
                      @click="resetEditRating">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PMC } from '@/config/constants.config'
import { AlertService } from '@/service/alert.service'
import { UtilityService } from '@/service/utility.service'
import axios from 'axios'
import ButtonGotoUp from '@/components/base/ButtonGotoUp.vue'
import alertMixins, {AlertMixins} from "@/mixins/alert.mixins"
import { FileService } from '@/service/file.service'
import { AppMixins } from '@/mixins/app.mixins'

export default {
    name: 'CrewEvaluation',
    components: { ButtonGotoUp },
    mixins: [AppMixins, AlertMixins],
    data () {
      return {
        crewId : this.$route.params.crewId,
        crewChangeId: null,
        crewEvaluationData: null,
        evaluationId: null,
        quarterRating: {},
        seaServicesData: {},
        evaluationCriteria: {},
        evaluationRating:{
          "character": {},
          "ability": {},
          "conduct": {},
          "ism-code": {},
          "leadership": {},
          "aggressiveness-for-promotion": {},
        },
        subAverageRating: {
          "character": null,
          "ability": null,
          "conduct": null,
          "ism-code": null,
          "leadership": null,
          "aggressiveness-for-promotion": null,
        },
        evaluatorRating: {},
        overallRating: null,
        isFirstQuarter: false,
        btnViewDisabled: 'disable-form-view',
        btnViewEnabled: 'enable-form-view',
        rateViewEnabled: 'with-rate-data',
        rateViewDisabled: 'without-rate-data',
        evaluator: null,
        evaluatorId: null,
        evaluationData: {},
        evaluationHdrs: {},
        evaluationDtls: {},
        isReloading: true,
        isEvalNew: true,
        evaluators: {},
        crewEvaluations: {},
        currentEval: {
          1: {
            'cer_no': 1,
            'id': null,
            'overallgrade': null,
            'date_evaluation': null,
            'isEdit': true,
            'evaluation': {
              0 : {
                'avg_grade': null,
                'remarks': null,
                'evaluator_id': null,
                'evaluator_rank_id': null,
                'recommended_rank': null,
                'id': 0,
              }
            },
          },
          2: {
            'cer_no': 2,
            'id': null,
            'overallgrade': null,
            'date_evaluation': null,
            'isEdit': true,
            'evaluation': {
              0 : {
                'avg_grade': null,
                'remarks': null,
                'evaluator_id': null,
                'evaluator_rank_id': null,
                'recommended_rank': null,
                'id': 0,
              }
            },
          },
          3: {
            'cer_no': 3,
            'id': null,
            'overallgrade': null,
            'date_evaluation': null,
            'isEdit': true,
            'evaluation': {
              0 : {
                'avg_grade': null,
                'remarks': null,
                'evaluator_id': null,
                'evaluator_rank_id': null,
                'recommended_rank': null,
                'id': 0,
              }
            },
          },
          4: {
            'cer_no': 4,
            'id': null,
            'overallgrade': null,
            'date_evaluation': null,
            'isEdit': true,
            'evaluation': {
              0 : {
                'avg_grade': null,
                'remarks': null,
                'evaluator_id': null,
                'evaluator_rank_id': null,
                'recommended_rank': null,
                'id': 0,
              }
            },
          }
        },
        isEnableEval: {},
        crewEvaluationCriteria: {},
        CERList: {},
        enableRating: false,
        parseEvalParam: {
          cchNo: null,
          rankId: null,
          cerNo: null,
          index: null,
          isNew: true,
        },
        tempRatings: null,
        crewEvaluators: [],
        recommendedRanks: {},
        hasRating: true,
        formDocFile: {
          evaluationId: null,
          file: null,
          filename: null,
        },
        selectedCrewChangeID: null,
      }
    },
    methods: {
      ...mapActions(
        [
          "set_is_2mbUp",
          "set_form_10mb_docfile",
          "is2mbUp",
        ]
      ),
      namesLang({name}) {
        return `${name}`;
      },

      triggerUpload(evalId) {
        this.formDocFile.evaluationId = evalId;
        document.getElementById("attachmentFile").click();
      },

      emp_input_up_doc() {
        $(`#attachmentFile`).val(null);
      },

      resetFormDoc(){
        this.formDocFile = {
          evaluationId: null,
            file: null,
            filename: null,
        };
      },
      resetEditRating(){
        this.enableRating = false;
      },

      getEvaluationId(evalId, rankId) {
        evalId = evalId.split(':');
        this.crewChangeId = evalId[0];
        this.evaluationId = evalId[1];
        if(this.quarterRating[this.crewChangeId][this.evaluationId[0]]) {
          this.evaluationHdrs = this.quarterRating[this.crewChangeId][this.evaluationId[0]];
          this.isEvalNew = false;
          this.evaluators = this.evaluationHdrs.evaluators;
        }
        else {
          this.evaluationHdrs = {};
          this.resetRatings();
          this.getEvaluationDetails({'crew_id': this.crewId,'rank_id':rankId});
        }
      },
      async getEvaluationDetails(param) {
        return await axios.post(PMC.GET_EVALUATION_CRITERIA, param)
          .then((r)=>{
            this.evaluationCriteria = r.data;
            return r.data;
          }).catch((er)=>{
            if (!er.response) {
              AlertService.errorAlertService("Cant establish server connection");
            } else {
              UtilityService.generateResponseMessage(er, 'Get Crew Sea Service')
            }
            return false;
          })
      },
      capitalizeString(str) {
        if(str.length < 1) {this.str = ''; return}
        this.str = str.replace(/^./, str[0].toUpperCase());
        return this.str;
      },
      capitalizePhrase(phr){
        phr = phr.split(" ");
        let phrase = "";
        for(let i=0;i < phr.length;i++){
          if(i == 0) phrase = this.capitalizeString(phr[i]);
          else phrase = phrase + ' ' + this.capitalizeString(phr[i]);
        }
        return phrase;
      },
      getSubRating(value,key1,key2){
        if(this.evaluationRating) {
          if(parseInt(value) <= 0 || parseInt(value) > 4 || value == null || Number.isInteger(parseFloat(value)) === false) this.evaluationRating[key1][key2] = 0;
          let ratings = this.evaluationRating;
          let overallRating = 0;
          let subTotalRating = 0;
          let groupCount = 0;
          for (let key in ratings) {
            let groupAveRating = null;
            let groupRating = 0;
            let groupCriteria = ratings[key];
            let groupRatingsCount = 0;
            for (let gc in groupCriteria) {
              if(parseInt(groupCriteria[gc]) > 0) {
                groupRating = groupRating + parseInt(groupCriteria[gc]);
                groupRatingsCount = groupRatingsCount + 1;
                groupCount = groupCount + 1;
              }
            }
            if (groupRating > 0) {
              overallRating = overallRating + groupRating; //Total rating computed by number of criteria
              groupAveRating = groupRating / groupRatingsCount;
              this.subAverageRating[key] = groupAveRating.toFixed(2);
              //groupCount = groupCount + 1;
            }
            else this.subAverageRating[key] = groupAveRating;
            //overallRating = overallRating + groupAveRating; //Total rating computed by group
          }
          subTotalRating = overallRating / groupCount;
          if(subTotalRating) this.overallRating = subTotalRating.toFixed(2);
          else this.overallRating = null;
        }
        if(parseInt(value) <= 0 || parseInt(value) > 4 || Number.isInteger(parseFloat(value)) === false) this.evaluationRating[key1][key2] = null;
      },
      resetRatings(){
        this.evaluationRating = {
          "character": {},
          "ability": {},
          "conduct": {},
          "ism-code": {},
          "leadership": {},
          "aggressiveness-for-promotion": {},
        };
        this.subAverageRating = {
          "character": null,
          "ability": null,
          "conduct": null,
          "ism-code": null,
          "leadership": null,
          "aggressiveness-for-promotion": null,
        };
        this.overallRating = null;
        this.isEvalNew = true;
      },
      getCrewChangeIds(crewChangeData){
        let crewChangeID = [];
        for(let cr in crewChangeData){
          crewChangeID.push(crewChangeData[cr].id);
        }
      },
      async saveCrewEvaluation(){
        this.enableRating = !this.enableRating;
        return true;
        this.evaluationData['details'] = this.evaluationRating;
        this.evaluationData['group'] = this.subAverageRating;
        this.evaluationData['overallRating'] = this.overallRating;
        const formData = new FormData();
        formData.append('isEvalNew', this.isEvalNew);
        formData.append('crewId', this.crewId);
        formData.append('crewChangeId', this.crewChangeId);
        formData.append('evaluatorId', this.evaluator);
        formData.append('evaluationId', this.evaluationId);
        formData.append('ratings', JSON.stringify(this.evaluationData));
        return await axios.post(PMC.SAVE_CREW_EVALUATION_CRITERIA, formData).then(r => {
          this.getEvaluationDetails(this.crewId);
          alert('Evaluation Added');
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Create Crew Evaluation')
          return false;
        });

      },
      async getCrewService(params) {
        return await axios.post(PMC.GET_CREW_EVALUATIONS, {'crew_id': params})
          .then((r)=>{
            this.crewEvaluationData = r.data;
              let evalData = r.data;
              for(let i in evalData) {
                let evalDetails = evalData[i].evaluations;
                let evalValue = [];
                for(let e in evalDetails){
                  if(evalDetails[e].cer_no) this.isFirstQuarter = true;
                  if(evalDetails[e].overallgrade === null ) {
                    evalDetails[e].overallgrade = 'No Rating';
                  }
                  else evalValue[evalDetails[e].cer_no] = evalDetails[e];
                }
                this.crewEvaluations[evalData[i].crew_change_id] = evalValue;
              }
            this.isReloading = false;
            return true;
          }).catch((er)=>{
            if (!er.response) {
              AlertService.errorAlertService("Cant establish server connection");
            } else {
              UtilityService.generateResponseMessage(er, 'Get Crew Sea Service')
            }
            return false;
          })
      },
      parseCrewEvaluationDetails(headerId){
        this.resetRatings();
        let crwEvalDtls = this.evaluationHdrs.crewEvalDtls;
        let noHeaderCheck = false;
        for(let crc in crwEvalDtls){
          if(crc == headerId){
            noHeaderCheck = true;
            this.isEvalNew = false;
            this.evaluationDtls = crwEvalDtls[crc];
            this.evaluationCriteria = crwEvalDtls[crc];
            for(let evalKey in this.evaluationRating){
              for(let detKey in this.evaluationCriteria){
                let crData = this.evaluationCriteria[detKey].criteria;
                if(evalKey == this.evaluationCriteria[detKey].index){
                  for(let crKey in crData){
                    let crId = crData[crKey].eval_criteria_id;
                    this.evaluationRating[evalKey][crId] = parseInt(crData[crKey].grade); //Parse Grade to Integer
                    this.getSubRating(parseInt(crData[crKey].grade),evalKey,crId);
                  }
                }

              }
            }
          }
        }
        if(noHeaderCheck == false){
          this.isEvalNew = true;
          this.getEvaluationDetails(this.crewId);
        }
      },
      async getEvaluators(param) {
        return await axios.get(`${PMC.GET_EVALUATORS}/${param}`)
          .then((r)=>{
            return r.data;
          }).catch((er)=>{
            if (!er.response) {
              AlertService.errorAlertService("Cant establish server connection");
            } else {
              UtilityService.generateResponseMessage(er, 'Get Crew Sea Service')
            }
            return false;
          })
      },
      setEvaluation(evalID, rankId){
        this.selectedCrewChangeID = evalID;
        let tempEvaluation = {
          0 : {
            'avg_grade': null,
            'remarks': null,
            'evaluator_id': null,
            'evaluator_rank_id': null,
            'recommended_rank': null,
            'id': 0,
            'crew_change_id': evalID,
          }
        };
        this.currentEval = {
          1: {
            'crew_change_id': evalID,
            'cer_no': 1,
            'id': null,
            'overallgrade': null,
            'date_evaluation': null,
            'isEdit': true,
            'evaluation': tempEvaluation,
          },
          2: {
            'crew_change_id': evalID,
            'cer_no': 2,
            'id': null,
            'overallgrade': null,
            'date_evaluation': null,
            'isEdit': true,
            'evaluation': tempEvaluation,
          },
          3: {
            'crew_change_id': evalID,
            'cer_no': 3,
            'id': null,
            'overallgrade': null,
            'date_evaluation': null,
            'isEdit': true,
            'evaluation': tempEvaluation,
          },
          4: {
            'crew_change_id': evalID,
            'cer_no': 4,
            'id': null,
            'overallgrade': null,
            'date_evaluation': null,
            'isEdit': true,
            'evaluation': tempEvaluation,
          }
        };
        this.getCrewEvaluationData(evalID, rankId);
        this.parseEvalParam.cchNo = evalID;
        this.parseEvalParam.rankId = rankId;
        this.getSignatories(rankId);
      },
      async enableEvaluation (evalData, cer_no) {
        if(evalData) {
          if (await AlertService.questionAlertService('Are you sure you want to update the Evaluation Details?', 'Confirm Update', 'question')) {
            let param = {
              'cch_id' : evalData.crew_change_id,
              'id' : evalData.id,
              'evaluator_id' : evalData.evaluatorData.id,
              'remarkStr' : evalData.remarks,
              'recommended_rank' : evalData.recommended_rank,
              'grade' : evalData.avg_grade,
              'crew_id' : this.crewId,
              'cer_no' : cer_no,
            };
            if(await this.updateCrewEvaluation(param)){
              if(this.formDocFile.file){
                this.upload_file_doc();
              }
              await AlertService.successAlertService('Evaluations Updated Successfully', 'Success');
            }
            else await AlertService.errorAlertService('Evaluations Updated Unsuccessfully', 'Error on Update');
            this.setEvaluation(this.parseEvalParam.cchNo, this.parseEvalParam.rankId);
          }
        }
        else this.resetFormDoc();
      },
      enableEvaluationOld(evalID,cId){
        if(evalID === 1) this.isEnableEval.first[cId] = false;
        else if(evalID === 2) this.isEnableEval.second = !this.isEnableEval.second;
        else if(evalID === 3) this.isEnableEval.third = !this.isEnableEval.third;
        else if(evalID === 4) this.isEnableEval.fourth = !this.isEnableEval.fourth;
        else {
          this.isEnableEval.first[cId] = true;
          this.isEnableEval.second = true;
          this.isEnableEval.third = true;
          this.isEnableEval.fourth = true;
        }
      },
      async getEvaluationData(cer_id, rank_id) {
        return await axios.post(PMC.GET_CREW_EVALUATION_DETAILS, {'cer_id': cer_id,'rank_id': rank_id, 'crew_id': this.crewId})
          .then((r)=>{
            this.crewEvaluationCriteria = r.data.details[0];
          }).catch((er)=>{
            if (!er.response) {
              AlertService.errorAlertService("Cant establish server connection");
            } else {
              UtilityService.generateResponseMessage(er, 'Get Crew Sea Service')
            }
            return false;
          });
        },
      async getCrewEvaluationData(evalID, rankId) {
        return await axios.post(PMC.GET_CREW_EVALUATION_DATA, {'ccId': evalID, 'rankId': rankId, 'crew_id': this.crewId})
          .then((r)=>{
            let cerData = r.data;
            for(let c in cerData){
              let evalList = cerData[c].evaluationDetails;
              for(let e in evalList){
                this.isEnableEval[evalList[e].id] = true;
                if(evalList[e].crewEvaluationDetails){
                  let evalListInner = evalList[e].crewEvaluationDetails;
                  let totalEval = 0;
                  let cntr = 0;
                  for(let a in evalListInner){
                    totalEval = parseInt(totalEval) + parseInt(evalListInner[a].grade);
                    cntr = cntr + 1;
                  }
                  totalEval = totalEval / cntr;
                }
              }
              this.currentEval[cerData[c].cer_no] = {
                'cer_no': cerData[c].cer_no,
                'id': cerData[c].id,
                'overallgrade': cerData[c].overallgrade,
                'date_evaluation': cerData[c].date_evaluation,
                'isEdit': true,
                'evaluation': evalList,
              };
            }
            this.CERList = r.data;
            this.isEvalNew = false;
            return this.currentEval;
          }).catch((er)=>{
            if (!er.response) {
              AlertService.errorAlertService("Cant establish server connection");
            } else {
              UtilityService.generateResponseMessage(er, 'Get Crew Sea Service')
            }
            return false;
          });
      },
      parseEvaluationRatings(cerNo, index){
        index = parseInt(index);
        this.parseEvalParam.cerNo = cerNo;
        this.parseEvalParam.index = index;
        let evalCrew = this.currentEval[cerNo].evaluation;
        if(evalCrew[index].id === 0){
          this.parseEvalParam.isNew = true;
          this.getEvaluationDefaults(this.parseEvalParam.rankId);
        }
        else {
          this.parseEvalParam.isNew = false;
          this.crewEvaluationCriteria = evalCrew[index];
        }
      },
      cancelEvalModify(){
        this.getCrewEvaluationData(this.parseEvalParam.cchNo,this.parseEvalParam.rankId).then( r => {
          let evalCrew = r[this.parseEvalParam.cerNo].evaluation;
          this.crewEvaluationCriteria = evalCrew[this.parseEvalParam.index];
          if(this.crewEvaluationCriteria.id === 0){
            this.getEvaluationDefaults(this.parseEvalParam.rankId);
          }
        });
        this.enableRating = !this.enableRating;
      },
      async updateEvaluationRating () {
        let errorMsg = "";
        let isRatingComplete = true;
        let evalUpdate = {};
        let evalForUpdate = this.crewEvaluationCriteria.groups;
        for (let i in evalForUpdate) {
          let evalGroup = evalForUpdate[i].criteria;
          for (let x in evalGroup) {
            if (evalGroup[x].grade === null) {
              errorMsg = "Please complete the evaluation form.";
              isRatingComplete = false;
            }
            evalUpdate[evalGroup[x].id] = parseInt(evalGroup[x].grade);
          }
        }
        if (this.crewEvaluationCriteria.evaluator_id === null || this.crewEvaluationCriteria.evaluator_id === 0) {
          isRatingComplete = false;
          errorMsg = "Please select an evaluator."
        }
        if (!isRatingComplete) {
          AlertService.errorAlertService(errorMsg, "Error on Update");
        } else {
          if (await AlertService.questionAlertService('Are you sure you want to update this Evaluation Form?', 'Confirm Update','question')) {
            let param = {
              'evaluations': evalUpdate,
              'evaluator_id': this.crewEvaluationCriteria.evaluator_id,
              'recommended_rank': this.crewEvaluationCriteria.recommended_rank,
              'remarks': this.crewEvaluationCriteria.remarks,
              'cer_id': this.crewEvaluationCriteria.cer_id,
              'cer_hdr_id': this.crewEvaluationCriteria.id,
              'crew_change_no': this.parseEvalParam.cchNo,
              'cer_no': this.parseEvalParam.cerNo,
              'index': this.parseEvalParam.index,
              'rankId': this.parseEvalParam.rankId,
              'isNew': this.parseEvalParam.isNew,
            };
            return await axios.post(PMC.SAVE_CREW_EVALUATION_DETAILS, param).then(r => {
              this.enableRating = !this.enableRating;
              AlertService.successAlertService('Evaluations Updated Successfully', 'Success');
              this.setEvaluation(this.parseEvalParam.cchNo,this.parseEvalParam.rankId);
              this.getCrewService(this.crewId);
              return r.data;
            }).catch(error => {
              UtilityService.generateResponseMessage(error, 'Create Crew Evaluation')
              return false;
            });
          }
        }

      },
      getTotalRating(value, cId){
        let thisHasRating = this.checkRatingInput(value, cId);
        if(thisHasRating){
          let counter = 0;
          let totalRating = 0;
          let evalForUpdate = this.crewEvaluationCriteria.groups;
          for (let i in evalForUpdate) {
            let evalGroup = evalForUpdate[i].criteria;
            for (let x in evalGroup) {
              if(evalGroup[x].grade){
                totalRating = totalRating + parseInt(evalGroup[x].grade);
              }
              counter = counter + 1;
            }
          }
          totalRating = totalRating / counter;
          this.crewEvaluationCriteria.avg_grade = totalRating.toFixed(2);
          this.updateRatingInput(value, cId);
        }

        return this.checkRatingInput(value, cId);
        //return thisHasRating !== null;

        //return thisHasRating;
      },
      async getSignatories(rankId){
        return await axios.post(PMC.GET_EVALUATION_SIGNATORIES, {'rank_id': rankId})
          .then((r)=>{
            this.crewEvaluators = r.data.evaluators;
            this.recommendedRanks = r.data.ranks;
          }).catch((er)=>{
            if (!er.response) {
              AlertService.errorAlertService("Cant establish server connection");
            } else {
              UtilityService.generateResponseMessage(er, 'Get Crew Sea Service')
            }
            return false;
          });
      },
      checkRatingInput(value, cId){
        let thisValue = null;
        if(parseInt(value) <= 0 || parseInt(value) > 4 || value == null || Number.isInteger(parseFloat(value)) === false){
          thisValue = null;
        }
        else thisValue = value;
        let evalForUpdate = this.crewEvaluationCriteria.groups;
        for (let i in evalForUpdate) {
          let evalGroup = evalForUpdate[i].criteria;
          for (let x in evalGroup) {
            if(evalGroup[x].id === cId){
              if(thisValue) thisValue = parseInt(thisValue).toFixed(2);
              evalForUpdate[i].criteria[x].grade = thisValue;
            }
          }
        }
        this.crewEvaluationCriteria.groups = evalForUpdate;
        //return thisValue;
        if (thisValue) return true;
        else return false;
      },
      updateRatingInput(value, cId){
        let thisValue = null;
        if(parseInt(value) <= 0 || parseInt(value) > 4 || value == null || Number.isInteger(parseFloat(value)) === false){
          thisValue = null;
        }
        else thisValue = value;
        let evalForUpdate = this.crewEvaluationCriteria.groups;
        for (let i in evalForUpdate) {
          let evalGroup = evalForUpdate[i].criteria;
          for (let x in evalGroup) {
            if(evalGroup[x].id === cId){
              if(thisValue) thisValue = parseInt(thisValue).toFixed(2);
              evalForUpdate[i].criteria[x].grade = thisValue;
            }
          }
        }
        this.crewEvaluationCriteria.groups = evalForUpdate;
      },
      async updateCrewEvaluation(param){
        return await axios.post(PMC.UPDATE_CREW_EVALUATION_HDR, param).then(r => {
          this.formDocFile.evaluationId = r.data;
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Create Crew Evaluation')
          return false;
        });
      },
      async getEvaluationDefaults (rankId) {
        return await axios.post(PMC.GET_EVALUATION_DEFAULT, {'rankId': rankId}).then(r => {
          this.crewEvaluationCriteria.groups = r.data;
          this.crewEvaluationCriteria.avg_grade = null;
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Get Evaluation Defaults')
          return false;
        });
      },
      async saveCrewEvaluationDetails(){
        this.enableRating = !this.enableRating;
        return true;
        this.evaluationData['details'] = this.evaluationRating;
        this.evaluationData['group'] = this.subAverageRating;
        this.evaluationData['overallRating'] = this.overallRating;
        const formData = new FormData();
        formData.append('isEvalNew', this.isEvalNew);
        formData.append('crewId', this.crewId);
        formData.append('crewChangeId', this.crewChangeId);
        formData.append('evaluatorId', this.evaluator);
        formData.append('evaluationId', this.evaluationId);
        formData.append('ratings', JSON.stringify(this.evaluationData));
        return await axios.post(PMC.SAVE_CREW_EVALUATION_CRITERIA, formData).then(r => {
          this.getEvaluationDetails(this.crewId);
          alert('Evaluation Added');
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Create Crew Evaluation')
          return false;
        });

      },
      newEvaluation(cerNo){
        let evalDataArr = [];
        let evalData = this.currentEval[cerNo].evaluation;
        for(let n in evalData){
          evalDataArr.push(evalData[n]);
        }
        let tempEvaluation = {
            'avg_grade': null,
            'remarks': null,
            'evaluator_id': null,
            'evaluator_rank_id': null,
            'recommended_rank': null,
            'id': 0,
            'crew_change_id': this.selectedCrewChangeID,
        };
        evalDataArr.push(tempEvaluation);
        this.currentEval[cerNo].evaluation = evalDataArr;
      },
      removeEvaluation(cerNo,index){
        let evalDataArr = [];
        let evalData = this.currentEval[cerNo].evaluation;
        for(let n in evalData){
          evalDataArr.push(evalData[n]);
        }
        evalDataArr.splice(index,1);
        this.currentEval[cerNo].evaluation = evalDataArr;
      },
      async loadEvaluationFormPDF (cchId, cerId,data) {
        let params = {
          'cchId': cchId,
          'cerId': cerId,
          'type': 'evaluationForm',
        };
        let isForm = await this.generateEvaluationForm(params);
        if (isForm) {
          this.pdfViewer('Evaluation Form', isForm);
        } else {
          AlertService.errorAlertService('Printing Unsuccessful', 'Error');
        }
      },
      async generateEvaluationForm(params) {
        return await axios.post(PMC.PRINT_EVALUATION_FORMS, params).then(res => {
          return res.data ? FileService.base64FileToBlobUrl(res.data) : false;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Get Evaluation Defaults')
          return false;
        });
      },
      on_up_file_changes(e) {
        const image = e.target.files[0];
        const reader = new FileReader();
        if (image !== undefined) {
          reader.readAsDataURL(image);
          reader.onload = async e => {
            let param = e.target.result;
            if (this.gte2mb(image)) {
              this.set_is_2mbUp(true);
              this.set_form_10mb_docfile(param);
              let msg = 'It seem\'s like you want to upload a file more than 3mb. \n' +
                'Do you want to preview. ?';
              if (await this.questionAlert(`${msg}`, 'File Preview', 'warning')) {
                this.base64ToBlob(param);   //view file on separate tab.
              }
            }
            else {
              this.formDocFile.file = param;
            }
            this.formDocFile.filename = image.name;
          };
        }
        else {
          this.resetUploadFile();
        }
      },
      upload_file_doc() {
        if(this.formDocFile.evaluationId) {
          let fileParam = document.getElementById('attachmentFile').files[0];
          const formData = new FormData();
          formData.append('evaluationId', this.formDocFile.evaluationId);
          formData.append('file', fileParam);
          this.uploadEvaluationDocFile(formData).then((r) => {
            if (r) {
              this.emp_input_up_doc();
              this.formDocFile = {
                evaluationId: null,
                file: null,
                filename: null,
              };
            }
          });
        }
      },
      async uploadEvaluationDocFile(params) {
        return await axios({
          method: 'post',
          url: `${PMC.UPLOAD_EVALUATION_DOC}`,
          data: params,
          'Content-Type': 'multipart/form-data',
          maxContentLength: 10000000,
          maxBodyLength: 10000000,
        }).then((response) => {
          return true;
        }).catch((error) => {
          UtilityService.generateResponseMessage(error, 'Upload Crew Doc file.');
          return false;

        });
      },
      resetUploadFile() {
        $(`#attachmentFile`).val(null);
        this.formDocFile.file = null;
        this.set_is_2mbUp(false);
        this.set_form_10mb_docfile(null);
      },
      gte2mb(__blob) {
        const maxFileSize = 2 * 1024 * 1024;
        // if (Math.round(__blob.size / 1024) >= 2048 || Math.round(__blob.size / 1024) >= 1024) {
        if(__blob.size > maxFileSize) {
          return true
        }
        return false
      },
      async getEvaluationDoc(evalId) {
        let params = {
          'evalId':evalId,
          'crewId': this.crewId
        };
        return await axios.post(PMC.GET_EVALUATION_DOC, params).then((r) => {
          this.viewEvaluationDoc(r.data);
          return true;
        }).catch((er) => {
          UtilityService.generateResponseMessage(er, 'View Evaluation Documents');
          return false;
        });
      },
      async removeEvaluationDoc(evalId) {
        if (await this.questionAlert('Remove Document?', 'File Preview', 'warning')) {
          let params = {
            'evalId':evalId,
            'crewId': this.crewId
          };
          return await axios.post(PMC.DELETE_EVALUATION_DOC, params).then((r) => {
            AlertService.successAlertService('Successfully deleted the document!', 'Success');
            this.setEvaluation(this.parseEvalParam.cchNo, this.parseEvalParam.rankId);
            this.formDocFile.file = null;
            return true;
          }).catch((er) => {
            UtilityService.generateResponseMessage(er, 'View Evaluation Documents');
            return false;
          });
        }



      },
      viewEvaluationDoc(docData){
        this.formDocFile.file = docData;
      },
      removeEvaluationDocument(){

      }
    },
    computed: {
      ...mapGetters([
        'crew',
        'signatories',
      ]),
    },
    mounted () {
      this.getEvaluators(15400);
      this.getEvaluationDetails(this.crewId);
      this.crewChangeId = this.crewSeaServices;
      this.getCrewService(this.crewId);
    },
    created () {
      this.getCrewChangeIds(this.crewSeaServices);
    },
    destroyed () {},
    watch: {
      'isEnableEval'() {
      },
    },
  }
</script>
<style scoped>
  .evaluation-btn {
    height: 20px;
    padding: 1px 5px 1px 5px;
    font-size: 10px;
    font-weight: bold;
  }
  .eval-content {
    border-bottom: 1px solid rgba(0,0,0,0.1);
    border-left: 1px solid rgba(0,0,0,0.1);
    border-right: 1px solid rgba(0,0,0,0.1);
    margin-top: -7px;
  }
  .evaluation-table {
    width: 100%;
    text-align: left;
  }
  .evaluation-table-head {
    width: 100%;
    text-align: left;
    color: #7e0200;
  }
  .evaluation-table-head th {
    font-size: 8pt !important;
  }
  .evaluation-table th {
    padding: 5px;
    font-size: 8pt !important;
  }
  .evaluation-table td {
    padding: 5px;
    font-size: 9pt !important;
  }
  .bold-row td {
    font-weight: bold;
  }

  .btn-view-form {
    color: #3466ff;
  }
  .evaluation-table-count {
    width: 7%;
    text-align: right;
    padding-right: 10px;
  }
  .evaluation-table-rating {
    width: 7%;
    text-align: center;
  }
  .disable-form-view{
    pointer-events: none;
    display: none;
    color:darkgrey;
  }

  .enable-form-view{
    pointer-events: auto;
    display: block;
    color: #3466ff;
  }

  .with-rate-data{
    font-weight: bolder !important;
    font-size: 9pt !important;
    color:black;
  }
  .without-rate-data{
    font-weight: normal;
    font-size: 7pt !important;
    color:darkgrey;
  }

  .input-no-rating {
    border: 1px solid red;
  }

</style>
