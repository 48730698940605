<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">
        <div style="width: 100%;margin-top: -20px;">
          <div style="width:100%;height:84px;">
            <div style="width:110px;height:105px;float:left;">
              <img :src="pmcLogo" alt="" style="width:70%; height:80%;">
            </div>
            <div class="mt-3" style="height:105px;float:left;">
              <div class="row">
                <span style="font-size: 20px">Crew Pool Report Per Rank List</span>
              </div>
              <div class="row">
                <span style="font-size: 14px">As of: {{ formatCurrentDate() }}</span>
              </div>
              <div class="row my-2">
                <button type="button" class="btn btn-xs pmc-btn-danger"
                        @click="printReport">
                  <font-awesome-icon icon="file"/>
                  Print Report
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr/>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <div class="row justify-content-center">

            <!----- COUNT PER RANK LIST ---->
            <div class="col-12">
              <div>
                <!--MASTERLIST FORMAT-->
                <table class="table table-bordered table-sm text-center sticky-header-docs-format small table-hover">
                  <thead>
                    <tr>
                      <th style="width: 4%;" rowspan="2">#</th>
                      <th rowspan="2">RANKCODE</th>
                      <th rowspan="2">ONBOARD</th>
                      <th rowspan="2">STANDBY</th>
                      <th rowspan="2">APPLICANTS</th>
                      <th rowspan="2">TOTAL</th>
                    </tr>
                  </thead>

                  <template v-if="isCrewPoolReportLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>

                  <template v-else>
                    <template v-if="crewPoolReportPerRankList">
                      <tr
                        v-for="(crewPoolReportRankList, index) in crewPoolReportPerRankList"
                        :key="crewPoolReportRankList.id">
                        <td class="align-middle table-font-size">{{ index + 1 }}</td>
                        <td class="align-middle table-font-size">{{ crewPoolReportRankList.rankAlias }}</td>
                        <td class="align-middle table-font-size">{{ crewPoolReportRankList.onboard }}</td>
                        <td class="align-middle table-font-size">{{ crewPoolReportRankList.standby }}</td>
                        <td class="align-middle table-font-size">{{ crewPoolReportRankList.applicant }}</td>
                        <td class="align-middle table-font-size rank-list-total">{{ crewPoolReportRankList.total }}</td>
                      </tr>
                    </template>

                    <template v-else>
                      <tr>
                        <td colspan="100%" class="bg-light-danger font-weight-bolder">
                          <p class="text-center mt-3">No Results</p>
                        </td>
                      </tr>
                    </template>
                  </template>

                </table>
              </div>
            </div>

            <!----- COUNT PER RANK ALL ---->
            <div class="col-12">
              <div>
                <!--MASTERLIST FORMAT-->
                <table class="table table-bordered table-sm text-center sticky-header-docs-format small table-hover">
                  <thead>
                    <tr>
                      <th rowspan="2">ONBOARD</th>
                      <th rowspan="2">STANDBY</th>
                      <th rowspan="2">APPLICANTS</th>
                      <th rowspan="2">TOTAL CREW</th>
                    </tr>
                  </thead>

                  <template v-if="isCrewPoolReportLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>

                  <template v-else>
                    <template v-if="crewPoolReportPerRankAll ? (crewPoolReportPerRankAll.length != 0) : '' ">
                      <tr
                        style="color: green;"
                        :key="crewPoolReportRankAll">
                        <td class="align-middle table-font-size">{{ crewPoolReportPerRankAll['onboard'] }}</td>
                        <td class="align-middle table-font-size">{{ crewPoolReportPerRankAll['standby'] }}</td>
                        <td class="align-middle table-font-size">{{ crewPoolReportPerRankAll['applicant'] }}</td>
                        <td class="align-middle table-font-size">{{ crewPoolReportPerRankAll['total'] }}</td>
                      </tr>
                    </template>

                    <template v-else>
                      <tr>
                        <td colspan="100%" class="bg-light-danger font-weight-bolder">
                          <p class="text-center mt-3">No Results</p>
                        </td>
                      </tr>
                    </template>
                  </template>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {AppMixins} from "@/mixins/app.mixins";
import {global_variables_config} from "@/config/global-variable.config";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import {CommonMixins} from "@/mixins/common.mixins";
import PmcLogo from '../../../assets/pmc_logo_only.jpg';
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {FileService} from "@/service/file.service";
import {UtilityService} from "@/service/utility.service";
import {AlertMixins} from "@/mixins/alert.mixins";

export default {
  name: "ViewCrewPoolReportPerRankList",

  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      pmcLogo: PmcLogo,
      showPrintButton: true,
      reportParam: {
        isPDF: false,
      },
    }
  },

  /** methods**/
  methods: {

    ...mapActions([
      'set_crewPoolReportPerRankList',
      'set_crewPoolReportPerRankAll',
    ]),

    formatCurrentDate() {
      const options = {day: 'numeric', month: 'long', year: 'numeric'};
      return new Date().toLocaleDateString('en-GB', options);
    },

    formatCurrentDate2() {
      const options = {month: 'long', year: 'numeric'};
      return new Date().toLocaleDateString('en-GB', options);
    },

    closeOnboardDocCertsExpiryModal() {
      $(`#onboard-docs-certs-expiry`).modal('hide');
    },

    async printCrewPoolReportPerRank() {
        this.reportParam.isPDF = true;
        let params = this.reportParam;
        return await axios.post(PMC.GET_CREW_POOL_REPORT_PER_RANK_PRINT, params).then(r => {
            return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
        }).catch(error => {
            UtilityService.generateResponseMessage(error, 'Generate Crew Biodata')
            return false;
        });
    },

    async printReport() {
      AlertService.loadingAlert('Please wait ...', 'Loading Print Report');
      let crewPoolReportPerRankFile = await this.printCrewPoolReportPerRank();
      if (crewPoolReportPerRankFile) {
        await this.pdfViewer('Crew Pool Report Per Rank List', crewPoolReportPerRankFile);
        Swal.close();
      } else {
        Swal.close();
      }
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isCrewPoolReportLoading',
        'crewPoolReportPerRankAll',
        'crewPoolReportPerRankList',
      ]),
  },

  /** created **/
  async created() {
    this.set_crewPoolReportPerRankList();
    this.set_crewPoolReportPerRankAll();
  },

}
</script>


<style scoped>

  .rank-list-total {
    font-weight: bold;
    color: blue;
  }
  .table-font-size {
    font-size: 1.125rem;
  }

</style>
