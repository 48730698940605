import Vue from 'vue';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {FontAwesomeIcon,FontAwesomeLayers,FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';
import vue_moment from 'vue-moment'

import Modal from '../components/base/Modal';
import Pagination from '../components/base/Pagination';
import SideNav from '../components/base/SideNav';
import Table from '../components/base/Table';
import FailOverPage from '../components/base/FailOverPage';
import ModalButton from '../components/base/ModalButton';
import Button from '../components/base/Button';
import ButtonGotoUp from '../components/base/ButtonGotoUp';

import CrewChangeMemo from "@/components/modules/crewchange/_sub/CrewChangeMemo";
import CrewChangeRemark from "@/components/modules/crewchange/_sub/CrewChangeRemark";

//Master Checklist Component
import CcMasterlistChecklist from "@/components/modules/crewchange/_sub/master_checklist/CcmMasterChecklist";
import CcMasterlistChecklistMLCOLC from "@/components/modules/crewchange/_sub/master_checklist/CcmMasterChecklistMLCOLC";
import CcMasterlistChecklistDocument from "@/components/modules/crewchange/_sub/master_checklist/CcmMasterChecklistDocument";
import CcMasterlistChecklistDocCertMonitoring from "@/components/modules/crewchange/_sub/master_checklist/CcmMasterChecklistDocCertMonitoring";
import CcmMasterChecklistEditDocument from "@/components/modules/crewchange/_sub/master_checklist/CcmMasterChecklistEditDocument.vue";

// Dashboard
import DashboardCrewChangePlanView from "@/components/modules/dashboard/DashboardCrewChangePlanView";

//Crew Change Plan
import CrewChangeMemoCovid from "@/components/modules/crewchange/_sub/CrewChangeMemoCovid.vue";

import SortArrow from "@/components/base/SortArrow.vue";




export const ComponentConfig = {
  pluginComponents(){
    Vue.use(vue_moment);
    Vue.component('font-awesome-icon',FontAwesomeIcon)
    Vue.component('font-awesome-layers',FontAwesomeLayers)
    Vue.component('font-awesome-layers-text',FontAwesomeLayersText)
    Vue.component('multi-select',Multiselect);
  },

  customComponents(){
    Vue.component('pmc-side-nav',SideNav);
    Vue.component('pmc-modal',Modal);
    Vue.component('pmc-modal-btn',ModalButton)
    Vue.component('pmc-btn',Button)
    Vue.component('pmc-table',Table);
    Vue.component('pmc-pagination',Pagination);
    Vue.component('pmc-fail-over',FailOverPage);
    Vue.component('pmc-btn-goto-up',ButtonGotoUp);
    Vue.component('sort-arrow',SortArrow);

    Vue.component('crew-change-memo',CrewChangeMemo)
    Vue.component('crew-change-remark',CrewChangeRemark)

    Vue.component('ccm-master-checklist-edit-document',CcmMasterChecklistEditDocument)
    Vue.component('ccm-master-checklist-modal-body',CcMasterlistChecklist)
    Vue.component('ccm-master-checklist-mlcolc-modal-body',CcMasterlistChecklistMLCOLC)
    Vue.component('ccm-master-checklist-document-modal-body',CcMasterlistChecklistDocument)
    Vue.component('ccm-master-checklist-doc-cert-monitoring-modal-body',CcMasterlistChecklistDocCertMonitoring)

    Vue.component('ccm-covid-modal-body',CrewChangeMemoCovid)

    Vue.component('dashboard-crew-change-plan-view',DashboardCrewChangePlanView)
  },
}

export default { ComponentConfig }
