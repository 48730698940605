<template>
  <div class="container bg-white" style="height: 100vh;">
    <br>
    <br>

    <div class="d-flex justify-content-start mb-3">
      <button type="button"
              class="btn btn-xs btn-secondary"
              @click="goBack"
      >
        <font-awesome-icon icon="arrow-left"/>
        BACK
      </button>
    </div>

    <div class="d-flex justify-content-between" @click="set_cancel_rate">
      <div class="form-inline mb-3">
        <div class="form-group mb-2 mr-5">
          <span class="font-weight-bold lead">Supplier Details</span>
        </div>
        <div class="form-group ">

          <button type="button"
                  class="btn btn-sm btn-xs btn-warning mr-3"
                  v-show="!isEditable"
                  @click="set_edit_supplier(gearSupplierData)"
          >
            <font-awesome-icon icon="edit"/>
            EDIT
          </button>

          <button type="button"
                  class="btn btn-sm btn-xs pmc-btn-tertia"
                  v-show="isEditable"
                  @click="updateSupplier"
          >
            <font-awesome-icon icon="save"/>
            SAVE
          </button>

          <button type="button" class="btn btn-sm btn-xs pmc-btn-danger ml-3 mr-3"
                  v-show="isEditable"
                  @click="set_cancel"
          >
            <font-awesome-icon icon="times"/>
            CANCEL
          </button>

        </div>

      </div>
    </div>

    <div class="row text-small mt-3" @click="set_cancel_rate">

      <div class="col-sm-5">

        <table class="table table-sm" id="genInfoTable">
          <tbody>
          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="vessel-name"
              >
                Code:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <!--              <span>{{ vessel.code }}</span>-->
              <input type="text"
                     placeholder="input code"
                     class="form-control form-control"
                     :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                     v-model="gearSupplierEditData.code"
                     :disabled="!isEditable">
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="flag"
              >
                Name:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     placeholder="input name"
                     class="form-control form-control"
                     :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                     v-model="gearSupplierEditData.name"
                     :disabled="!isEditable">
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="flag"
              >
                Address:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     placeholder="input address"
                     class="form-control form-control"
                     :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                     v-model="gearSupplierEditData.address"
                     :disabled="!isEditable">
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="flag"
              >
                Contact No:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     placeholder="input contact no."
                     class="form-control form-control"
                     :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                     v-model="gearSupplierEditData.contact_no"
                     :disabled="!isEditable">
            </td>
          </tr>


          </tbody>
        </table>
      </div>


      <div class="col-sm-6">
        <table class="table table-sm" id="genInfoTable">
          <tbody>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="flag"
              >
                Contact Person:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     placeholder="input contact person"
                     class="form-control form-control"
                     :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                     v-model="gearSupplierEditData.contact_person"
                     :disabled="!isEditable">
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="flag"
              >
                Fax No:
              </label>
            </th>
            <td class="align-middle text-uppercase">
              <input type="text"
                     placeholder="input fax no."
                     class="form-control form-control"
                     :class="{
                              'form-control-plaintext' : !isEditable
                           }"
                     v-model="gearSupplierEditData.fax_no"
                     :disabled="!isEditable">
            </td>
          </tr>

          <tr>
            <th scope="row" class="align-middle">
              <label
                class="font-weight-bolder text-left form-label-xs mt-2"
                for="classification"
              >
                Status:
              </label>
            </th>
            <td class="align-left text-uppercase">
              <div class="row">
                <div class="col-md-2 mr-auto mt-1">
                  <input
                    type="checkbox"
                    class="form-check form-check-inline ml-2 mt-2"
                    placeholder="---"
                    v-model="gearSupplierEditData.status"
                    :disabled="!isEditable"
                  />
<!--                    v-model="gearSupplierEditData.status"-->
                </div>
              </div>

            </td>
          </tr>


          </tbody>
        </table>
      </div>
    </div>

    <hr/>

    <div class="row" @click="set_cancel_rate">

      <div class="col-md-4">
        <div class="form-inline ">
          <div class="form-group mr-5">
            <span class="font-weight-bold lead">Gear Rates</span>
          </div>
          <div class="form-group ">
            <button type="button" class="btn btn-xs btn-primary"
                    data-toggle="modal"
                    data-target="#add-supplier-rate"
                    data-backdrop="static"
                    data-keyboard="false" >
              <font-awesome-icon icon="plus"/>
              Add rate
            </button>
          </div>

          <div class="form-group ">

<!--            <button type="button" class="btn btn-xs pmc-btn-tertia ml-3"-->
<!--                    data-toggle="modal"-->
<!--                    data-target="#new-gear-modal"-->
<!--                    data-backdrop="static"-->
<!--                    data-keyboard="false"-->
<!--            >-->
<!--              <font-awesome-icon icon="plus"/>-->
<!--              Add Gear-->
<!--            </button>-->

          </div>

        </div>
      </div>


      <div class="modal fade" id="add-supplier-rate" tabindex="-1" role="dialog" aria-labelledby="setupCcPlanCenter"
           aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" style="height: 100%">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Add Gear Supplier Rates</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <pmc-table :max-height="350" class="mt-3">
                <template v-slot:table-header>
                  <tr>
                    <th style="width: 5%;">
                      <button class="btn btn-xs btn-primary" @click="addGearSupplierRate">
                        +
                        Add
                      </button>
                    </th>
                    <th style="width: 30%;" class="text-center align-middle p-1">Gear</th>
                    <th style="width: 15%;" class="text-center align-middle p-1">Rate</th>
                    <th style="width: 5%;" class="text-center align-middle p-1">Action</th>
                  </tr>
                </template>

                <template v-slot:table-body>
                  <tr v-for="(gearRate,index) in setUpGearRate" :key="gearRate.id">
                    <td class="text-center align-middle p-0">{{ index + 1 }}</td>
                    <td class="text-center align-middle p-0">
                      <select id="select-gear-add" class="form-control form-control-sm"
                              v-model="gearRate.gear_id"
                              @change="onSelectedGear($event)">
                        <option :value="null">--- SELECT ---</option>
                        <option v-for="(gear,index) in obj_gearsKeysSelection" :value="gear.id" :key="gear.id">
                          {{ gear.description }}
                          {{ gear.color }}
                        </option>
                      </select>
                    </td>
                    <td class="text-center align-middle p-0">
                      <input type="number"
                             name="addRateInput"
                             id="add-rate-input"
                             class="form-control form-control-sm"
                             v-model="gearRate.rate"
                      >
                    </td>
                    <td class="text-center align-middle p-0">
                      <button class="btn btn-xs btn-light" @click="removeGearSupplierRate(gearRate.id)">
                        <font-awesome-icon icon="times" class="text-danger"/>
                      </button>
                    </td>
                  </tr>

                  <tr v-if="setUpGearRate ? (setUpGearRate.length == 0) : '' ">
                    <td colspan="100%" class="bg-light-danger font-weight-bolder">
                      <p class="text-center mt-3">Add Rate</p>
                    </td>
                  </tr>

                </template>
              </pmc-table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm pmc-btn-tertia" @click="saveRate">Save</button>
              <button type="button" class="btn btn-sm btn-secondary" @click="set_add_rate" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <!--  new gear modal   -->
      <div class="modal fade" id="new-gear-modal" tabindex="-1" role="dialog" aria-labelledby="newPrincipalModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">

            <div class="modal-header">
              <h5 class="modal-title" id="newPrincipalModalLabel">NEW GEAR</h5>
            </div>

            <div class="modal-body">
              <div class="row ml-1">
                <div class="col-md-12 text-left ml-2">
                  <!-- new gear -->
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">DESCRIPTION</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-7">
                      <input type="text"
                             class="form-control form-control-sm"
                             placeholder="---"
                             v-model="addNewGear.description"
                      />
                    </div>
                  </div>

                  <!-- new gear type -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-3 col-form-label">GEAR TYPE</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-5">
                      <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                              v-model="addNewGear.gear_type_id"
                              required>
                        <option :value="null">--select--</option>
                        <option :value="type.id" v-for="(type,index) in objGearTypes" :key="type.id"
                                style="font-size: 14px;">
                          <span> {{ type.name }}</span>
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- new towel type -->
                  <div class="form-group row mt-2" v-if="isTowelRequired">
                    <label class="col-sm-3 col-form-label">TOWEL USAGE</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-5">
                      <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                              :style="{ 'outline-color': isTowelRequired ? 'red' : '', 'border-color': isTowelRequired ? 'red' : '' }"
                              v-model="addNewGear.towel_usage_id"
                              required>
                        <option :value="null">--select--</option>
                        <option :value="usage.id" v-for="(usage,index) in objTowelUsages" :key="usage.id"
                                style="font-size: 14px;">
                          <span> {{ usage.description }}</span>
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- new rank type -->
                  <div class="form-group row mt-2">
                    <label class="col-sm-3 col-form-label">RANK TYPE</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-5">
                      <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                              @change="on_change_get_rank_type"
                              v-model="addNewGear.rank_type"
                              required>
                        <option :value="null">--select--</option>
                        <option :value="type.id" v-for="(type,index) in objRankTypes" :key="type.id"
                                style="font-size: 14px;">
                          <span> {{ type.name }}</span>
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- new rank-->
                  <div class="form-group row mt-2">
                    <label class="col-sm-3 col-form-label">RANK</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-5">
                      <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                              v-model="addNewGear.rank_id"
                              :style="{ 'outline-color': isRankRequired ? 'red' : '', 'border-color': isRankRequired ? 'red' : '' }"
                              required>
                        <option :value="null">--select--</option>
                        <option :value="rank.id" v-for="(rank,index) in objRanks" :key="rank.id"
                                style="font-size: 14px;">
                          <span> {{ rank.name }}</span>
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- new color-->
                  <div class="form-group row mt-2">
                    <label class="col-sm-3 col-form-label">COLOR</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-5">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="---"
                        :style="{ 'outline-color': isColorRequired ? 'red' : '', 'border-color': isColorRequired ? 'red' : '' }"
                        v-model="addNewGear.color"
                      />
                    </div>
                  </div>

                  <!-- new brand-->
                  <div class="form-group row mt-2">
                    <label class="col-sm-3 col-form-label">BRAND</label>
                    <span class="col-form-label">:</span>
                    <div class="col-sm-5">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="---"
                        :style="{ 'outline-color': isBrandRequired ? 'red' : '', 'border-color': isBrandRequired ? 'red' : '' }"
                        v-model="addNewGear.brand"
                      />
                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                v-show="!isEditable"
                type="button"
                class="btn btn-sm pmc-btn-tertia"
                @click="saveGear"
              >
                <font-awesome-icon icon="save"/>
                SAVE
              </button>

              <button
                v-show="!isEditable"
                type="button"
                class="btn btn-sm pmc-btn-second"
                data-dismiss="modal">
                <font-awesome-icon icon="undo"/>
                CLOSE
              </button>

            </div>
          </div>
        </div>
      </div>




<!--      &lt;!&ndash; count &ndash;&gt;-->
<!--      <div class="col-sm-2">-->
<!--        <div class="form-group row">-->
<!--          <label for="list-count" class="col-sm-3 col-form-label">Count</label>-->
<!--          <span class="col-form-label">:</span>-->
<!--          <div class="col-sm-7 text-left">-->
<!--            <select name="listCount" id="list-count" class="form-control custom-select-sm"-->
<!--                    v-model="gearSupplierRateFormParam.count"-->
<!--                    @change="on_change_count"-->
<!--            >-->
<!--              <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

    </div>


    <div class="row" @click="set_cancel_rate" v-if="showAddRate">
      <div class="col-md-4">
        <div class="form-inline mt-2">
          <div class="form-group mr-5">
            <span class="font-weight-bold">Total Rate: {{ totalRateValue }}</span>
          </div>

        </div>
      </div>
      </div>



<!--    &lt;!&ndash;  pagination      &ndash;&gt;-->
<!--    <div class="row py-1">-->
<!--      <div class="col-md-9">-->
<!--        <div class="row">-->
<!--          <div class="col-5">-->
<!--            <pmc-pagination :page-object="gearSupplierRatePagination" @settingPage="set_this_page"></pmc-pagination>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div id="table-section" class="py-3">
      <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
        <template v-slot:table-header>
          <tr>
<!--            <th scope="row" class="align-middle" width="2%">#</th>-->
            <th scope="row" class="align-middle" width="2%">DATE ADDED</th>
            <th scope="row" class="text-center align-middle" width="5%">BRAND</th>
            <th scope="row" class="text-center align-middle" width="5%">GEAR TYPE</th>
            <th scope="row" class="text-center align-middle" width="7%">DESCRIPTION</th>
            <th scope="row" class="align-middle" width="2%">RATE</th>
            <th scope="row" class="text-center align-middle" width="3%"></th>
          </tr>
        </template>
        <template v-slot:table-body>
          <tr v-for="(gearRate, index) in gearSupplierRatesData" :key="gearRate.id" style="height: 30px;">
<!--            <td scope="col" class="p-0 text-left pl-3" @click="set_cancel_rate"> {{ index + 1 }}</td>-->
            <td>
              <span>{{ gearRate.created_at }}</span>
            </td>

            <td>
              <span>{{ gearRate.brand }}</span>
            </td>

            <td>
              <span>{{ gearRate.gear_type }}</span>
            </td>

            <td scope="col"  @click="set_cancel_rate">
              <span class="badge badge-pill badge-dark">{{ gearRate.rank }}</span>
              <span class="badge badge-pill badge-info">{{ gearRate.rank_type }}</span>

              {{ gearRate.gear }}

              <span class="badge badge badge-secondary">{{ gearRate.color }}</span>

              <span class="badge badge-pill badge-warning" v-if="gearRate.towel_usage_id == 1">Bath</span>
              <span class="badge badge-pill badge-primary" v-if="gearRate.towel_usage_id == 2">Face</span>

            </td>
            <td scope="col" class="p-0 text-left pl-3">

<!--              <span v-if="!isRateEditable">{{ gearRate.rate }}</span>-->

<!--              when input is true this will remove outline active-->
<!--                     :class="{-->
<!--                              'form-control-plaintext' : !isRateEditable-->
<!--                           }"-->
              <input type="text"
                     placeholder="input rate"
                     class="form-control form-control"
                     v-model="gearRateEditData.rate"
                     v-if="gearRateEditIndex == index"
              >

<!--              <span v-else>{{ gearRate.rate }}</span>-->
              <span v-else>{{ gearRate.rate }}</span>

            </td>

            <td>
              <button type="button"
                      class="btn-xs btn btn-outline-primary mr-3"
                      @click="set_edit_rate(index, gearRate)"
                      v-if="!isRateEditable && gearRateEditIndex != index"
              >
<!--                      @click="set_edit_rate(index, gearRate)"-->
                <font-awesome-icon icon="edit"/>
                edit
              </button>

              <button type="button"
                      class="btn btn-sm btn-xs pmc-btn-tertia mr-2"
                      v-show="gearRateEditIndex == index"
                      @click="updateRate(gearRate)"
              >
                <font-awesome-icon icon="save"/>
                 save
              </button>

              <button type="button" class="btn btn-sm btn-xs btn-secondary ml-2 mr-2"
                      v-show="gearRateEditIndex== index"
                      @click="set_cancel_rate(gearRate)"
              >
                <font-awesome-icon icon="times"/>
                 cancel
              </button>


              <button
                class="btn btn-xs btn btn-outline-danger ml-2"
                v-if="!isRateEditable && gearRateEditIndex != index"
                @click="deleteRate(gearRate.id)"
              >
                <font-awesome-icon icon="trash"/>
                del
              </button>
            </td>

            <td colspan="100%" class="bg-light-danger font-weight-bolder" v-if="gearRate ? (gearRate.length == 0) : '' ">
              <p class="text-center mt-3">Add Rate</p>
            </td>

          </tr>


          <tr v-if="!showAddRate">
            <td colspan="100%" class="bg-light-danger font-weight-bolder">
              <p class="text-center mt-3">No Record</p>
            </td>
          </tr>

        </template>
      </pmc-table>
    </div>


  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import {global_variables_config} from "@/config/global-variable.config";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      gearSupplierEditDataToGearSupplierEditData: {}, // contains data from gearSupplierData; an edit parameter
      gearSupplierData: {}, // used in edit button as parameter
      gearSupplierEditData: {}, // gearSupplierEditData is v-model


      gearRateEditData: {},
      gearRateEditIndex: null,

      gearSupplierRatesData: null,
      gearSupplierRatesDataCopy: null,
      rateCopy: {},

      addNewGear: {},
      setUpGearRate: [],

      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      isEditable: false,
      isRateEditable: false,
      isRateRowActive: false,

      gearSupplierRateFormParam: {
        searchValue: null,
        type: null,
        level: null,
        count: 10,
        status: null,
        page: null,
        supplierId: this.$route.params.gearSupplierId,
      },

      rankTypeParam: {
        rankType: null,
      },

      isTowelRequired: false,
      isColorRequired: false,
      isBrandRequired: false,
      isRankRequired: false,

      allGearTypes: {
        travelling_bag: global_variables_config.allGearTypes.travelling_bag,
        coverall: global_variables_config.allGearTypes.coverall,
        jacket: global_variables_config.allGearTypes.jacket,
        shoes: global_variables_config.allGearTypes.shoes,
        s_sleeve: global_variables_config.allGearTypes.s_sleeve,
        l_sleeve: global_variables_config.allGearTypes.l_sleeve,
        pants: global_variables_config.allGearTypes.pants,
        steward_uniform: global_variables_config.allGearTypes.steward_uniform,
        hat_hairnet: global_variables_config.allGearTypes.hat_hairnet,
        towels: global_variables_config.allGearTypes.towels,
      },


    };
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_gearSupplier',
      'set_gearSupplierRates',
      'create_gearSupplierRate',
      'update_gearSupplier',
      'update_gearSupplierRate',
      'delete_gearSupplierRate',
      'getGearKeysInRateSupplier',

      'getGearTypeKeys',

      // create gear modal
      'getCrewPersonalObjectKeys',
      'getTowelUsageKeys',
      'create_gear',
      'getRankTypeKeys',

    ]),


    async updateSupplier() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update gear supplier', 'question');
      if (await __update) {
        let params = {
          id: this.gearSupplierEditData.id,
          code: this.gearSupplierEditData.code ? this.gearSupplierEditData.code : '',
          name: this.gearSupplierEditData.name ? this.gearSupplierEditData.name : '',
          address: this.gearSupplierEditData.address ? this.gearSupplierEditData.address : '',
          contact_no: this.gearSupplierEditData.contact_no ? this.gearSupplierEditData.contact_no : '',
          contact_person: this.gearSupplierEditData.contact_person ? this.gearSupplierEditData.contact_person : '',
          fax_no: this.gearSupplierEditData.fax_no ? this.gearSupplierEditData.fax_no : '',
          status: this.gearSupplierEditData.status ? 1 : 0,
        };

        let updatedGearSupplier = await this.update_gearSupplier(params);
        if (updatedGearSupplier) {
          AlertService.successAlertService("Updated successfully", 'Update gear supplier');
          this.hideModal('view-supplier-modal')
          this.isEditable = false
        }
      }
    },

    async updateRate(gearRate) {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update gear', 'question');
      if (await __update) {
        let params = {
          id: gearRate.id,
          gear_supplier_id: gearRate.gear_supplier_id ? gearRate.gear_supplier_id : '' ,
          gear_id: gearRate.gear_id ? gearRate.gear_id : '' ,
          rate: gearRate.rate ? gearRate.rate : '' ,
        };

        let updatedGearSupplierRate = await this.update_gearSupplierRate(params);
        if (updatedGearSupplierRate) {
          AlertService.successAlertService("Updated successfully",'Update gear supplier rate');
          this.set_gearSupplierRates(this.gearSupplierRateFormParam);
          this.gearRateEditIndex = null
          this.isRateEditable = false
        }
      }
    },


    async saveRate () {
      let __create =  AlertService.questionAlertService('Are you sure you want to add this record', 'New Gear Supplier Rates', 'question')
      if (await __create){

        let params = {
          gearRates: this.setUpGearRate,
        }

        let newGearSupplierRate = await this.create_gearSupplierRate(params);
        if (newGearSupplierRate){
          AlertService.successAlertService('Create gear supplier rate record successful', 'Create gear supplier rates');
          this.hideModal('add-supplier-rate')
          await this.set_gearSupplierRates(this.gearSupplierRateFormParam);
          await this.getGearKeysInRateSupplier(this.gearSupplierRateFormParam);
          this.setUpGearRate = []
        }

      }

    },

    async saveGear () {
      let __create =  AlertService.questionAlertService('Are you sure you want to add this record', 'New Gear', 'question')
      if (await __create){

        let params = {
          description: this.addNewGear.description ? this.addNewGear.description : '' ,
          rank_type: this.addNewGear.rank_type ? this.addNewGear.rank_type : '' ,
          rank_id: this.addNewGear.rank_id ? this.addNewGear.rank_id : '' ,
          color: this.addNewGear.color ? this.addNewGear.color : '' ,
          brand: this.addNewGear.brand ? this.addNewGear.brand : '',
          gear_type_id: this.addNewGear.gear_type_id ? this.addNewGear.gear_type_id : '',
          towel_usage_id: this.addNewGear.towel_usage_id ? this.addNewGear.towel_usage_id : '' ,
          new_gear_supplier: '',
          gear_supplier_id: this.$route.params.gearSupplierId ? this.$route.params.gearSupplierId : '' ,
          status: 1,
        };

        let newGear = await this.create_gear(params);
        if (newGear){
          AlertService.successAlertService('Create gear record successful', 'Create gear');
          this.hideModal('new-gear-modal')
          this.addNewGear = {};
          await this.getGearKeysInRateSupplier(this.gearSupplierRateFormParam);
        }

      }

    },

    async deleteRate(gearRate) {
      let _del = await this.delete_gearSupplierRate(gearRate);
      if (_del) {
        await this.getGearKeysInRateSupplier(this.gearSupplierRateFormParam);
      }
    },

    set_this_page(e) {
      this.gearSupplierRateFormParam.currentPage = e;
      this.gearSupplierRateFormParam.page = e;
      this.set_gearSupplierRates(this.gearSupplierRateFormParam);
    },

    on_change_count(e) {
      this.gearSupplierRateFormParam.count = e.target.value;
      this.set_gearSupplierRates(this.gearSupplierRateFormParam);
    },

    set_edit_supplier(data) {
      this.isEditable = true
      // transfter data to gearSupplierEditDataToGearSupplierEditData
      this.gearSupplierEditDataToGearSupplierEditData = data;
    },

    set_cancel(e) {
      this.isEditable = false
      // this.set_gearSupplier(this.$route.params.gearSupplierId);
      this.gearSupplierEditData = Object.assign({}, this.gearSupplier);
    },

    set_add_rate() {
      this.setUpGearRate = []
    },

    set_cancel_rate(gearRate) {
      this.gearRateEditIndex = null
      this.isRateEditable = false
      // retrieve the deep copy to rollback previous data when cancelled is clicked
      if (this.gearSupplierRatesDataCopy){
        this.gearSupplierRatesData = Object.assign({}, this.gearSupplierRatesDataCopy);
      }
    },

    set_edit_rate(index, data){
      this.gearRateEditData = data;
      this.isRateEditable = true
      this.gearRateEditIndex = index

      // create a deep copy of rate data to used to backtrack previous data
      this.gearSupplierRatesDataCopy = _.cloneDeep(this.gearSupplierRatesData);
    },


    addGearSupplierRate() {
      let index = this.setUpGearRate.length
      let id = index + 1
      while(this.setUpGearRate.filter(v => v.id === id).length > 0){
        id++
      }
      const params = {
        id: id,
        gear_id: this.setUpGearRate.gear_id ?? null,
        gearSupplierId: this.$route.params.gearSupplierId,
        rate: this.setUpGearRate.rate,
      }
      this.setUpGearRate.push(params);
    },

    removeGearSupplierRate(gearRateIndex) {
      let index = this.setUpGearRate.findIndex((val, index) => {
        return val.id === gearRateIndex;
      })
      this.setUpGearRate.splice(index, 1);
    },


    onSelectedGear(e) {
      let gearKey = e.target.value;
      let counter = {}; // used to keep track of the occurrences of each gear_id in the setUpGearRate array.
      let duplicateId = null;

      // checks if the property val.gear_id exists in the counter object.
      //  count the occurences of gear_id

      this.setUpGearRate.forEach((val, index) => {
        if (val.gear_id !== null) {
          counter[val.gear_id] = (counter[val.gear_id] || 0) + 1
          if (counter[val.gear_id] > 1) {
            duplicateId = val.id;
            this.warningAlert("Duplicate entry detected", "Warning")
          }
        }
      })

      if (duplicateId !== null) {
        let setUpGearRate = this.setUpGearRate.find((val, index) => {
          return val.id === duplicateId;
        });
        setUpGearRate.gear_id = null;
      }

    },

    on_change_get_rank_type(e){
      this.rankTypeParam.rankType = e.target.value;
      this.getCrewPersonalObjectKeys(this.rankTypeParam);
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'gearSupplier',
        'gearSupplierRates',
        'totalRateValue',

        'gearSupplierRatePagination',
        // 'gearSupplierRateFormParam',
        'isReloading',
        'obj_gearsKeysSelection',

        'objRankTypes',
        'objRanks',

        'objGearTypes',
        'objTowelUsages',

      ],
    ),

    showAddRate() {
      // return this.gearSupplierRatesData && this.gearSupplierRatesData.length === 0;
      return this.gearSupplierRatesData ? Object.keys(this.gearSupplierRatesData).length > 0 : null;
    }

  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_gearSupplier(this.$route.params.gearSupplierId);
    await this.set_gearSupplierRates(this.gearSupplierRateFormParam);
    await this.getGearKeysInRateSupplier(this.gearSupplierRateFormParam);

    await this.getGearTypeKeys();
    // await this.getCrewPersonalObjectKeys();
    await this.getRankTypeKeys();
    await this.getTowelUsageKeys();

    this.$globalVariables = global_variables_config


    // fills gearSupplierEditData(v-model) data from mapgetter(backend)
    // fills data to page upon loading
    this.gearSupplierEditData = this.gearSupplier
  },

  /** watch **/
  watch: {
    // watch mapgetter gearSupplier(backend) and fill gearSupplierData(used as edit parameter)
    // gearSupplierData(used as edit parameter) is used in edit supplier as parameter
    'gearSupplier'() {
      this.gearSupplierData = Object.assign({}, this.gearSupplier);
    },

    // transfer data to gearSupplierEditData(v-model)
    'gearSupplierEditDataToGearSupplierEditData'() {
      this.gearSupplierEditData = Object.assign({}, this.gearSupplierEditDataToGearSupplierEditData);
    },

    // watch mapgetter gearSupplierRates(backend) and fill gearSupplierRatesData
    'gearSupplierRates'() {
      this.gearSupplierRatesData = Object.assign({}, this.gearSupplierRates);
    },

    'addNewGear.gear_type_id'(){

      let requiredColor =
        [
          this.allGearTypes.coverall,
          this.allGearTypes.jacket,
          this.allGearTypes.s_sleeve,
          this.allGearTypes.l_sleeve,
          this.allGearTypes.pants,
        ];

      let requiredTowelUsage =
        [
          this.allGearTypes.towels,
        ];

      let requireBrand =
        [
          this.allGearTypes.shoes,
        ];

      let requireRank =
        [
          this.allGearTypes.steward_uniform,
          this.allGearTypes.hat_hairnet,
        ];


      if (requiredColor.includes(this.addNewGear.gear_type_id)) {
        this.isColorRequired = true
      }else{
        this.isColorRequired = false
        this.addNewGear.color = null
      }


      if (requiredTowelUsage.includes(this.addNewGear.gear_type_id)) {
        this.isTowelRequired = true
      }else{
        this.isTowelRequired = false
        this.addNewGear.towel_usage_id = null
      }

      if (requireBrand.includes(this.addNewGear.gear_type_id)) {
        this.isBrandRequired = true
      }else{
        this.isBrandRequired = false
        this.addNewGear.color = null
      }

      if (requireRank.includes(this.addNewGear.gear_type_id)) {
        this.isRankRequired = true
      }else{
        this.isRankRequired = false
        this.addNewGear.color = null
      }


    },


  },

  beforeDestroy() {
    this.$globalVariables = null;
  },


  /** destroyed **/
  destroyed() {
  },


}
</script>

<style scoped>

</style>
