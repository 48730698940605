import axios from 'axios'
import { UtilityService } from '@/service/utility.service'
import { PMC } from '@/config/constants.config'
import {FileService} from "@/service/file.service";
import {AlertService} from "@/service/alert.service";

const state = {};


const mutations = {};


const actions = {
  async get_booster_file_attachment ({ commit }, params) {
    return axios.post(PMC.GET_COVID_VACCINE_BOOSTER_FILE, params).then((r) => {
      return r.data.file ? FileService.base64FileToBlobUrl(r.data.file) : false;
    }).catch((er) => {

      UtilityService.generateResponseMessage(er, 'Get booster file attachment')
      return false
    })
  },

  async delete_booster_file_attachment ({ commit }, params) {
    let __confirmation = AlertService.questionAlertService('Are you sure you want to delete this record.','Covid vaccine booster','question');
    if(await __confirmation){
      return axios.post(PMC.DELETE_COVID_VACCINE_BOOSTER_FILE, params).then((r)=>{
        AlertService.successAlertService('Record deleted successfully','Deleted');
        return true;
      }).catch((er)=>{
        UtilityService.generateResponseMessage(er,'Delete booster file attachment');
        return false;

      })
    }
  },

};


const getters = {};


/** export **/
export default {state, actions, mutations, getters};
