import { render, staticRenderFns } from "./SchoolSetup.vue?vue&type=template&id=1d0fd3ba&scoped=true&"
import script from "./SchoolSetup.vue?vue&type=script&lang=js&"
export * from "./SchoolSetup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d0fd3ba",
  null
  
)

export default component.exports