<template>
  <div class="container-fluid">
    <!-- header row -->
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-3">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Employees</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-sm pmc-btn-prime"
                          data-toggle="modal"
                          data-target="#newEmployeeModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add
                  </button>
                </div>
              </div>

<!--              <div class="col-3">-->
<!--                <h4>Employees</h4>-->
<!--              </div>-->
<!--              <div class="col-3">-->
<!--                <button class="btn btn-sm pmc-btn-info"-->
<!--                        data-toggle="modal"-->
<!--                        data-target="#newEmployeeModal"-->
<!--                        data-backdrop="static"-->
<!--                        data-keyboard="false">-->
<!--                  <font-awesome-icon icon="user-plus"/>-->
<!--                  <span>Add</span>-->
<!--                </button>-->
<!--              </div>-->

            </div>
          </div>

          <div class="col-sm-5">
            <div class="input-group">
              <div class="input-group-append">
                <select class="form-control form-control small" v-model="employeeFormParam.searchKey" style="font-size: 13px!important;">
                  <option class="small" :value="searcKey.key" v-for="(searcKey,index) in searchKeys">{{ searcKey.display }}</option>
                </select>
              </div>
              <input
                v-model="employeeFormParam.searchValue"
                type="text"
                class="form-control form-control"
                id="formdata-searchvalue"
                @keypress.enter="search_employee"
                placeholder="Search Employee here">
<!--              <pmc-btn-->
<!--                  @buttonEvent="search_employee"-->
<!--                  :button-label="'search'"-->
<!--                  :button-class="'btn btn-xs pmc-btn-prime'">-->
<!--                <template slot="button-icon">-->
<!--                  <font-awesome-icon icon="search"/>-->
<!--                </template>-->
<!--              </pmc-btn>-->
              <button class="btn btn-sm pmc-btn-prime" @click="search_employee">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="emp-status" class="custom-select custom-select" v-model="employeeFormParam.status" @change="set_list_status">
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.display }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="custom-select custom-select" v-model="employeeFormParam.count" @change="set_list_count">
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- pagination   -->
        <div class="row py-0">
          <div class="col-md-8">
            <div class="row">
              <div class="col-6">
                <pmc-pagination :page-object="employeePagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table section  -->
        <!--        bugfixed-->
        <div id="table-section" class="py-0">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="12">
            <template slot="table-header">
              <tr>
                <th scope="row">#</th>
                <th scope="row" width="11%">LAST NAME</th>
                <th scope="row" width="11%">FIRST NAME</th>
                <th scope="row" width="11%">MIDDLE NAME</th>
                <th scope="row" width="6%">SUFFIX</th>
                <th scope="row">CIVIL STATUS</th>
                <th scope="row">USERNAME</th>
                <th scope="row" width="8%">BIRTHDATE</th>
                <th scope="row">DATE HIRED</th>
                <th scope="row">DEPARTMENT</th>
                <th scope="row">STATUS</th>
                <th scope="row" width="7%">ACTION</th>
              </tr>

            </template>
            <template slot="table-body">
              <tr v-if = "employees.length === 0">
                <td colspan="12">No Results</td>
              </tr>
              <tr v-else v-for="(employee,index) in employees" :key="employee.id">
                <td scope="col">{{ index + employeePagination.from }}</td>

                <!-- last_name -->
                <td scope="col" class="text-left pl-3">
                  <span>{{ employee.last_name }}</span>
                </td>

                <!--  first name          -->
                <td scope="col" class="text-left pl-3">
              <span>
                {{ employee.first_name }}
              </span>
                </td>

                <td scope="col" class="text-left pl-3">
              <span>
                {{ employee.middle_name }}
              </span>
                </td>

                <td scope="col" class="text-left pl-3">
              <span>
                {{ employee.suffix_name }}
              </span>
                </td>

                <td>
              <span>
                {{ employee.civil_status }}
              </span>
                </td>
                <td scope="col" class="text-left pl-3">
              <span>
                {{ employee.username }}
              </span>
                </td>
                <td scope="col" class="text-center">
              <span>
                {{ employee.birthdate }}
              </span>
                </td>
                <td scope="col" class="text-center">
              <span>
                {{ employee.date_hired }}
              </span>
                </td>
                <td scope="col" class="text-left pl-3">
              <span>
                {{ employee.department }}
              </span>
                </td>
                <td scope="col" class="text-center">
              <span>
                {{ employee.status.toUpperCase() }}
              </span>
                </td>
                <td>
                  <button class="btn btn-xs pmc-btn-prime mr-2" @click="view_employee(employee.id)">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button class="btn btn-xs pmc-btn-danger" @click="delete_employee(employee.id)">
                    <font-awesome-icon icon="trash"/>
                    del
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>

    </div>

    <!--  modal new employee   -->
    <div class="modal fade" id="newEmployeeModal" tabindex="-1" role="dialog" aria-labelledby="newEmployeeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="newEmployeeModalLabel">New Employee</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-2">
                <div id="crew-img-container">
                  <img id="crew-img" :src="newEmpImage" alt="crewImage" class="img-fluid">
                  <div class="text-center mt-1">
                    <!-- upload image -->
                    <div class="form-group row text-center">
                      <label for="new-emp-image" class="col-sm-7 col-form-label">
                        <font-awesome-icon icon="paperclip"/>
                        Image
                      </label>
                      <div class="col-sm-5">
                        <input type="file"
                               id="new-emp-image"
                               @change="on_change_emp_image"
                               class="form-control-file form-control-sm" style="display: none;"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4 text-left">

                <!-- last name -->
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">LAST NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.last_name"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- first name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">FIRST NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.first_name"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- middle name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">MIDDLE NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.middle_name"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- suffix name               -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">SUFFIX NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.suffix_name"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- suffix name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">E NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.e_name"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- signature name               -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">SIGNATURE NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.signatory_name"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- birthdate -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">BIRTHDATE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="date"
                           v-model="newEmployee.birthdate"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-3 text-left">
                <!-- gender -->
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">GENDER</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <select name="newEmpGender" id="new-emp-gender" class="custom-select custom-select-sm" v-model="newEmployee.gender_id">
                      <option :value="null">--select--</option>
                      <option :value="val.id" v-for="(val,index) in objGenders" :key="val.id">{{ val.name }}</option>
                    </select>
                  </div>
                </div>


                <!-- mobile 1 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">MOBILE #1</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.mobile_no1"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- mobile 2 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">MOBILE #2</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.mobile_no2"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- LANDLINE 1 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">LANDLINE #1</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.land_line1"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- LANDLINE 2 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">LANDLINE #2</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.land_line2"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- email 1 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">EMAIL #1</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="email"
                           v-model="newEmployee.email1"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- email 2 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">EMAIL #2</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="email"
                           v-model="newEmployee.email2"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-3 text-left">
                <!-- sss no -->
                <div class="form-group row ">
                  <label class="col-sm-4 col-form-label">SSS #NO.</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.sss_no"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- phil health -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">PHIL HEALTH</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.phil_health_no"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- pag ibig -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">PAG-IBIG NO#.</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.pag_ibig_no"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- civil status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">CIVIL STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <select name="newEmpCivilStatus" id="new-emp-civil-status" class="custom-select custom-select-sm" v-model="newEmployee.civil_status_id">
                      <option :value="null" class="small">--select--</option>
                      <option :value="val.id"
                              v-for="(val,index) in objCivilStatus"
                              :key="val.id"
                              class="text-left small">
                        {{ val.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- date hired -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">DATE HIRED</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="date"
                           v-model="newEmployee.date_hired"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- date hired -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">SEPARATED</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="date"
                           v-model="newEmployee.date_separated"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="checkbox" class="form-check form-check-inline" v-model="newEmployee.status"/>
                    <span>{{ newEmployee.status ? 'ACTIVE' : 'IN-ACTIVE' }}</span>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <div class="row text-left">
              <div class="col-md-2"></div>

              <div class="col-md-4">
                <!-- department  -->
                <div class="form-group row ">
                  <label class="col-sm-4 col-form-label">DEPARTMENT</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <select name="newEmpDepartment" id="new-emp-department" class="custom-select custom-select-sm" v-model="newEmployee.department_id">
                      <option :value="null" class="small">--select--</option>
                      <option :value="val.id"
                              v-for="(val,index) in objDepartments"
                              :key="val.id"
                              class="text-left small">
                        {{ val.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- ccompany  -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">COMPANY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <select name="newEmpCompany" id="new-emp-company" class="custom-select custom-select-sm" v-model="newEmployee.company_id">
                      <option :value="null" class="small">--select--</option>
                      <option :value="val.id"
                              v-for="(val,index) in objCompanies"
                              :key="val.id"
                              class="text-left" style="font-size: 12px !important;">
                        {{ val.code }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!-- username -->
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">USERNAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.username"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>


                <!-- password  -->
                <div class="form-group row mt-2">
                  <label class="col-sm-4 col-form-label">PASSWORD</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input type="text"
                           v-model="newEmployee.password"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3"></div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="on_close_addmodal">Close</button>
            <button type="button" class="btn btn-primary" @click="new_employee">Save changes</button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { AppMixins } from '../../../../mixins/app.mixins'
import { AlertMixins } from '../../../../mixins/alert.mixins'
import { mapActions, mapGetters } from 'vuex'
import {AlertService} from "@/service/alert.service";

export default {
  name: 'List',

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data () {
    return {
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          display: 'All',
        },
        {
          id: 2,
          key: 1,
          display: 'Active',
        },
        {
          id: 3,
          key: 0,
          display: 'Inactive',
        },
      ],

      searchKeys: [
        {
          id: 1,
          key: 'all',
          display: 'All',
        },
        {
          id: 2,
          key: 'first_name',
          display: 'First Name',
        },
        {
          id: 3,
          key: 'middle_name',
          display: 'Middle Name',
        },
        {
          id: 4,
          key: 'last_name',
          display: 'Last Name',
        },
        {
          id: 5,
          key: 'suffix_name',
          display: 'Suffix Name',
        },
      ],

      newPhoto: null,


      pageLastLeft: 1,

    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_employee_paginated',
      'set_employee_form_param',
      'reset_employee_pagination',
      'null_employee_form_param',
      'delete_employee',
      'null_employees',
      'null_employee',
      'null_new_employee',
      'null_obj_departments',
      'null_obj_genders',
      'null_obj_companies',
      'null_obj_civilStatus',
      'getNewEmployeeKeys',
      'create_employee',
    ]),

    search_employee () {
      if (this.employeeFormParam.searchValue === '') {
        this.employeeFormParam.page = this.pageLastLeft
        this.set_employee_paginated(this.employeeFormParam)
      }

      if (this.employeeFormParam.searchValue) {
        this.employeeFormParam.page = 1
      }

      this.set_employee_paginated(this.employeeFormParam)

    },

    set_this_page (e) {
      this.employeeFormParam.searchValue = ''
      this.employeePagination.currentPage = e
      this.employeeFormParam.page = e
      this.pageLastLeft = e
      this.set_employee_paginated(this.employeeFormParam)
    },

    set_list_count (e) {
      this.employeeFormParam.page = 1
      this.employeeFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.employeeFormParam.count = e.target.value
      this.set_employee_paginated(this.employeeFormParam)
    },

    set_list_status (e) {
      this.employeeFormParam.page = 1
      this.employeeFormParam.status = e.target.value
      this.set_employee_paginated(this.employeeFormParam)
    },

    view_employee (__id) {
      this.$router.push({
        name: 'ViewEmployee',
        params: { employeeId: __id },
      })
    },

    on_change_emp_image (e) {
      let file = e.target.files[0]

      this.newEmployee.file = file

      const reader = new FileReader()
      if (file != (null || undefined)) {
        reader.readAsDataURL(file)
        reader.onload = e => {
          let param = e.target.result
          this.newPhoto = param
        }
      }
    },

    // new_employee () {
    //   this.create_employee(this.newEmployee).then((r) => {
    //     if (r) {
    //       this.hideModal('newEmployeeModal')
    //     }
    //   })
    // },

    async new_employee() {
      let params = {
        last_name: this.newEmployee.last_name ? this.newEmployee.last_name : '',
        middle_name: this.newEmployee.middle_name ? this.newEmployee.middle_name : '',
        first_name: this.newEmployee.first_name ? this.newEmployee.first_name : '',
        suffix_name: this.newEmployee.suffix_name ? this.newEmployee.suffix_name : '',
        e_name: this.newEmployee.e_name ? this.newEmployee.e_name : '',
        signatory_name: this.newEmployee.signatory_name ? this.newEmployee.signatory_name : '',
        mobile_no1: this.newEmployee.mobile_no1 ? this.newEmployee.mobile_no1 : '',
        mobile_no2: this.newEmployee.mobile_no2 ? this.newEmployee.mobile_no2 : '',
        land_line1: this.newEmployee.land_line1 ? this.newEmployee.land_line1 : '',
        land_line2: this.newEmployee.land_line2 ? this.newEmployee.land_line2 : '',
        email1: this.newEmployee.email1 ? this.newEmployee.email1 : '',
        email2: this.newEmployee.email2 ? this.newEmployee.email2 : '',

        birthdate: this.newEmployee.birthdate ? this.newEmployee.birthdate : '',
        date_hired: this.newEmployee.date_hired ? this.newEmployee.date_hired : '',

        sss_no: this.newEmployee.sss_no ? this.newEmployee.sss_no : '',
        tin_no: this.newEmployee.tin_no ? this.newEmployee.tin_no : '',
        phil_health_no: this.newEmployee.phil_health_no ? this.newEmployee.phil_health_no : '',

        username: this.newEmployee.username ? this.newEmployee.username : '',
        password: this.newEmployee.password ? this.newEmployee.password : '',

        gender_id: this.newEmployee.gender_id ? this.newEmployee.gender_id : '',

        department_id: this.newEmployee.department_id ? this.newEmployee.department_id : '',
        company_id: this.newEmployee.company_id ? this.newEmployee.company_id : '',

        status: this.newEmployee.status ? this.newEmployee.status : '',
        file: this.newEmployee.file ? this.newEmployee.file : '',

        pag_ibig_no: this.newEmployee.pag_ibig_no ? this.newEmployee.pag_ibig_no : '',
        civil_status: this.newEmployee.civil_status_id ? this.newEmployee.civil_status_id : '',
        date_separated: this.newEmployee.date_separated ? this.newEmployee.date_separated : '',

      };
      let newEmployee = await this.create_employee(params);
      if (newEmployee) {
        this.hideModal('newEmployeeModal')

      }

    },

    on_close_addmodal () {
      $(`#new-emp-image`).val('')
      this.newPhoto = null
      this.null_new_employee()
    },
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          'employees',
          'employeeFormParam',
          'employeePagination',
          'objDepartments',
          'objGenders',
          'objCompanies',
          'objCivilStatus',
          'newEmployee',
          'isReloading',
        ],
    ),

    newEmpImage: {
      get () {
        return this.newPhoto ? this.newPhoto : this.noPhoto
      },
    },
  },

  /** mounted **/
  mounted () {

  },

  /** created **/
  async created () {
    await this.set_employee_paginated(this.employeeFormParam)
    await this.getNewEmployeeKeys()

  },

  /** watch **/
  watch: {

    // employeeFormParam: {
    //   handler: function () {
    //     if (this.employeeFormParam.page == 1) {
    //     }
    //
    //   },
    //   deep: true // This is important to track nested data changes
    // },

  },

  /** destroyed **/
  destroyed () {
    this.null_employees()
    this.null_employee()
    this.null_new_employee()
    this.reset_employee_pagination()
    this.null_employee_form_param()
    this.null_obj_departments()
    this.null_obj_genders()
    this.null_obj_companies()
    this.null_obj_civilStatus()
  },
}
</script>

<style scoped>
#crew-img-container {
  position: relative;
  height: 100% !important;
  width: 100% !important;
}

#crew-img {
  width: 200px;
  height: 200px;
}

.crew-img {
  width: 200px;
  height: 200px;
}

input[type="checkbox"] {
  transform: scale(2) !important;
}

.modal-xl {
  max-width: 90% !important;
}
</style>
