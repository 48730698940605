<template>
  <div class="container text-left" style="height: 100%;">
    <div class="row">
      <div class="col-lg-12 py-4">
        <pmc-modal-btn :button-class="'btn btn-sm pmc-btn-prime'"
                       :target-modal="newCommentModal"
                       :button-label="'Add'">
          <template slot="button-icon">
            <font-awesome-icon icon="comment"/>
          </template>
        </pmc-modal-btn>

        <div v-if="crewComments.length == 0 && loadCrewComments == false" class="card my-3">
          <h4 class="pmc-text-prime">
            <span> No comments yet.</span>
          </h4>
        </div>

        <div v-if="loadCrewComments == true" class="py-4">
          <h4 class="pmc-text-prime text-center">
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </h4>
        </div>

        <div v-else class="card my-3" v-for="(crewCom,index) in crewComments" :key="index">
          <div class="card-header">
            <div class="row d-flex justify-content-between align-items-center">
              <div class="col-lg-auto">
                <p class="font-weight-bold my-0">
                  {{ crewCom.createdBy }}
                  <small class="pmc-text-less-dark"> — &nbsp;&nbsp; created: {{ crewCom.createdAt_str }}</small>
                </p>
              </div>

              <div class="col-lg-auto">
                <pmc-btn :button-label="'Edit'"
                         :class="'btn btn-xs pmc-btn-warning'"
                         v-if="is_author(crewCom) && !isEditableComment"
                         @buttonEvent="set_editable_comment(index)">
                  <template slot="button-icon">
                    <font-awesome-icon icon="edit"/>
                  </template>
                </pmc-btn>


                <pmc-btn :button-label="'Update'"
                         :class="'btn btn-xs pmc-btn-tertia ml-2'"
                         v-if="is_author(crewCom) && isEditableComment"
                         @buttonEvent="update_comment(crewCom,index)">
                  <template slot="button-icon">
                    <font-awesome-icon icon="save"/>
                  </template>
                </pmc-btn>

                <pmc-btn :button-label="'Cancel'"
                         :class="'btn btn-xs btn-secondary ml-2'"
                         v-if="is_author(crewCom) && isEditableComment"
                         @buttonEvent="cancel_editable_comment(index)">
                  <template slot="button-icon">
                    <font-awesome-icon icon="undo"/>
                  </template>
                </pmc-btn>

                <pmc-btn :button-label="'Delete'"
                         :class="'btn btn-xs pmc-btn-danger ml-2'"
                         v-if="is_author(crewCom) && !isEditableComment"
                         @buttonEvent="delete_comment(crewCom.id)">
                  <template slot="button-icon">
                    <font-awesome-icon icon="trash"/>
                  </template>
                </pmc-btn>
              </div>
            </div>
          </div>

          <div class="card-body">
            <textarea class="form-control"
                      :id="'updateComment'+index"
                      disabled
                      style="height: 100%;
                      resize: none;"
                      v-model="crewCom.comment"
                      placeholder="Comments here...."
                      @input="limitTextRemarks(`updateComment${index}`,'comCount',300)"
                      rows="4"/>
          </div>
        </div>
      </div>
    </div>

    <!-- new comment modal here.  -->
    <pmc-modal :id="newCommentModal" lg_width m_height @closingEvent="cancel_comment">
      <template slot="custom-header">
        <span class="font-weight-bold">New Crew Comment</span>
      </template>

      <template slot="custom-body">
        <div class="col-md-12 text-left">
          <input readonly disabled type="text" id="comCount" value="300" size="1" class=" border-0">
          <span class="text-muted">*Characters Left</span>
          <div class="form-group mt-3">
            <textarea class="form-control"
                      id="newCrewComment"
                      style="height: 200px;
                      resize: none;"
                      v-model="newCrewComment.comment"
                      placeholder="Comments here...."
                      @input="limitTextRemarks('newCrewComment','comCount',300)"
                      :maxlength="textareaMaxLength"
                      rows="4"/>
          </div>
        </div>
      </template>

      <template slot="custom-footer">
        <pmc-btn :button-label="'Save'" :class="'btn btn-xs pmc-btn-tertia'" @buttonEvent="save_comment">
          <template slot="button-icon">
            <font-awesome-icon icon="save"/>
          </template>
        </pmc-btn>

        <pmc-btn :button-label="'Cancel'" :class="'btn btn-xs pmc-btn-danger'" @buttonEvent="cancel_comment">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>

      </template>
    </pmc-modal>

    <pmc-btn-goto-up></pmc-btn-goto-up>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {AppMixins} from '../../../../../mixins/app.mixins'
import {AlertMixins} from '../../../../../mixins/alert.mixins'

export default {
  name: 'CrewComment',
  mixins: [AppMixins, AlertMixins],
  data() {
    return {
      crewId: parseInt(this.$route.params.crewId, 10),
      newCommentModal: 'newCommentModal',

      isEditableComment: false,
      textareaMaxLength: 300,
    }
  },

  methods: {
    ...mapActions(
      [
        'getCrewComments',
        'createCrewComment',
        'deleteCrewComment',
        'updateCrewComment',
        'nullCrewComment',
        'getAuthUser',
      ]
    ),


    get_all_crew_comments() {
      this.getCrewComments({crewId: this.crewId});
    },

    async save_comment() {
      if (await this.questionAlert('Are you sure you want to add this comment', 'Add Comment')) {
        this.newCrewComment.crewId = this.crewId;
        this.createCrewComment(this.newCrewComment).then((r) => {
          if (r) {
            this.successAlert('Successfuly save');
            this.hideModal(this.newCommentModal);
            let comCount = document.getElementById('comCount');
            comCount.value = 300;
          }
        })
      }
    },

    set_editable_comment(index) {
      document.getElementById(`updateComment${index}`).removeAttribute('disabled');
      if (!this.isEditableComment) {
        this.isEditableComment = true;
      }
    },

    cancel_editable_comment(index) {
      document.getElementById(`updateComment${index}`).setAttribute('disabled', 'true');
      if (this.isEditableComment) {
        this.isEditableComment = false;
      }
    },


    //update
    async update_comment(object, index) {
      if (await this.questionAlert('Are you sure want to update this comment', 'Update Comment')) {
        this.updateCrewComment(object).then((r) => {
          if (r) {
            this.successAlert('Successful updated');
            this.cancel_editable_comment(index);
          }
        })
      };
    },


    //del
    async delete_comment(param) {
      if (await this.questionAlert('Are you sure you want to delete this comment.?')) {
        this.deleteCrewComment(param).then((r) => {
          if (r) {
            this.successAlert('Successful deleted.');
          }
        })
      }
    },

    cancel_comment() {
      let comCount = document.getElementById('comCount');
      this.hideModal(this.newCommentModal);
      this.newCrewComment.comment = null;
      comCount.value = 300;
    },

    is_author(object) {
      return object.createdById === this.authUser.id;
    }
  },

  computed: {
    ...mapGetters(
      [
        'crewComment', 'crewComments', 'newCrewComment', 'authUser', 'loadCrewComments'
      ]
    ),
  },

  mounted() {
    this.crtlVPlusC('crewComment');
  },

  created() {
    this.$nextTick(() => {
      this.get_all_crew_comments();
    })
    // this.getAuthUser();
  },

  destroyed() {
    //
  },
}
</script>

<style scoped>

</style>
