<template>
  <div class="container-fluid">
      <div class="py-3 text-left">
        <h4 class="font-weight-bold">Direct Promotion</h4>
      </div>
      <div class="row">
      <div class="col-md-12 col-lg-2 pl-lg-5">
        <div class="text-left">
          <img id="crew-img" :src="crewImage ?? noPhoto" alt="photo" class="image-border-dark">
        </div>
      </div>
      <div class="col-md-12 col-lg-4 pl-5">
        <div class="form-group row mt-2 text-left">
          <div class="col-4 p-0 m-0">
            <label for="name-of-crew" class="font-weight-bold">
              Crew Id
            </label>
          </div>
          <span class="col-1 p-0 m-0">:</span>
          <div class="col-6 p-0 m-0">
            <input
              v-model="crewPromotionData.crewId"
              :disabled="true"
              type="text"
              class="form-control form-control-sm"
              id="id-of-crew"
              placeholder="--">
          </div>
        </div>
        <div class="form-group row mt-2 text-left">
          <div class="col-4 p-0 m-0">
            <label for="name-of-crew" class="font-weight-bold">
              Family Name
            </label>
          </div>
          <span class="col-1 p-0 m-0">:</span>
          <div class="col-6 p-0 m-0">
            <input
              v-model="crewPromotionData.lastName"
              :disabled="true"
              type="text"
              class="form-control form-control-sm"
              id="family-name-of-crew"
              placeholder="--">
          </div>
        </div>
        <div class="form-group row mt-2 text-left">
          <div class="col-4 p-0 m-0">
            <label for="name-of-crew" class="font-weight-bold">
              Given Name
            </label>
          </div>
          <span class="col-1 p-0 m-0">:</span>
          <div class="col-6 p-0 m-0">
            <input
              v-model="crewPromotionData.firstName"
              :disabled="true"
              type="text"
              class="form-control form-control-sm"
              id="given-name-of-crew"
              placeholder="--">
          </div>
        </div>
        <div class="form-group row mt-2 text-left">
          <div class="col-4 p-0 m-0">
            <label for="name-of-crew" class="font-weight-bold">
              Middle Name
            </label>
          </div>
          <span class="col-1 p-0 m-0">:</span>
          <div class="col-6 p-0 m-0">
            <input
              v-model="crewPromotionData.middleName"
              :disabled="true"
              type="text"
              class="form-control form-control-sm"
              id="middle-name-of-crew"
              placeholder="--">
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4 pl-5">
          <div class="form-group row mt-2 text-left">
            <div class="col-4 p-0 m-0">
              <label for="name-of-crew" class="font-weight-bold">
                Current Rank
              </label>
            </div>
            <span class="col-1 p-0 m-0">:</span>
            <div class="col-6 p-0 m-0">
              <input
                v-model="crewPromotionData.currentRankAlias"
                :disabled="true"
                type="text"
                class="form-control form-control-sm"
                id="current-rank-of-crew"
                placeholder="--">
            </div>
          </div>
          <div class="form-group row mt-2 text-left">
            <div class="col-4 p-0 m-0">
              <label for="name-of-crew" class="font-weight-bold">
                Promoted Rank
              </label>
            </div>
            <span class="col-1 p-0 m-0">:</span>
            <div class="col-sm-6 p-0 m-0">
              <input v-if="isViewProfile || crewPromotionData.currentRankId === 1"
                     class="form-control form-control-sm"
                     type="text"
                     placeholder="--"
                     disabled>
              <select v-else id="promoted-rank-of-crew"
                      v-model="crewPromotionData.promotedRankId"
                      class="custom-select custom-select-sm form-control form-control-sm">
                <option :value="null">--</option>
                <option :value="rank.id" v-for="(rank,index) in filteredRanks" :key="rank.id">
                  {{ rank.alias }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row mt-2 text-left">
            <div class="col-4 p-0 m-0">
              <label for="name-of-crew" class="font-weight-bold">
                Date Recommended
              </label>
            </div>
            <span class="col-1 p-0 m-0">:</span>
            <div class="col-6 p-0 m-0 position-relative">
                <input v-if="isViewProfile || crewPromotionData.currentRankId === 1"
                       class="form-control form-control-sm"
                       type="text"
                       placeholder="--"
                       disabled>
                <input v-else
                       v-model="crewPromotionData.dateRecommended"
                       type="date"
                       @blur="validateDate(1)"
                       class="form-control form-control-sm"
                       id="date-recommended-of-crew"
                       :min="minDate"
                       :max="maxDate">
                <button class="btn btn-xs position-absolute"
                        v-if="crewPromotionData.dateRecommended"
                        @click="crewPromotionData.dateRecommended = null"
                        style="top: 3px; right: -20px;">
                  <font-awesome-icon icon="times"/>
                </button>
            </div>
          </div>
          <div class="form-group row mt-2 text-left">
            <div class="col-4 p-0 m-0">
              <label for="name-of-crew" class="font-weight-bold">
                Date Approved
              </label>
            </div>
            <span class="col-1 p-0 m-0">:</span>
            <div class="col-6 p-0 m-0 position-relative">
              <input v-if="isViewProfile || crewPromotionData.currentRankId === 1"
                     class="form-control form-control-sm"
                     type="text"
                     placeholder="--"
                     disabled>
              <input v-else
                v-model="crewPromotionData.dateApproved"
                type="date"
                @blur="validateDate(2)"
                class="form-control form-control-sm"
                id="date-approved-of-crew"
               :min="crewPromotionData.dateRecommended !== null ? crewPromotionData.dateRecommended : minDate"
               :max="maxDate">
              <button class="btn btn-xs position-absolute"
                      v-if="crewPromotionData.dateApproved"
                      @click="crewPromotionData.dateApproved = null"
                      style="top: 3px; right: -20px;">
                <font-awesome-icon icon="times"/>
              </button>
            </div>
          </div>
        </div>
      <div class="col-md-12 col-lg-2">
        <button class="btn mt-2 btn-sm pmc-bg-prime"
                @click="go_to_crew_profile(crewPromotionData.crewId, 'all-info')"
                style="width: 70%"
                :disabled="isViewProfile">
          <font-awesome-icon icon="user-tie" class="fas fa-fw "/>
          View Profile
        </button>
        <button class="btn mt-2 btn-sm pmc-bg-prime"
                style="width: 70%"
                data-toggle="modal"
                data-target="#searchCrewDirectPromotion"
                data-backdrop="static"
                data-keyboard="false"
                v-on:click="get_all_crew_paginated">
          <font-awesome-icon icon="search"/>
          Search
        </button>
        <button class="btn mt-2 btn-sm pmc-bg-danger"
                @click="cancelCrew()"
                style="width: 70%"
                :disabled="isViewProfile">
          <font-awesome-icon icon="times"/>
          Cancel
        </button>
        <button class="btn mt-2 btn-sm pmc-bg-tertia"
                style="width: 70%"
                @click="save()"
                :disabled="isViewProfile || crewPromotionData.currentRankId === 1">
          <font-awesome-icon icon="save"/>
          Save
        </button>
      </div>
    </div>
    <div v-if="crewPromotionData.currentRankId === 1">
      <span style="size: 20px;font-weight: bold;color: red;">
        Crew is unable to promote, already reach higher rank.
      </span>
    </div>
    <pmc-modal :id="searchingModal" :lg_width="true" @closingEvent="reset_search_crew_param">
      <template v-slot:custom-header>
        <h5 class="modal-title">Search Crew For Direct Promotion</h5>
      </template>
      <template v-slot:custom-body>
        <div class="row mt-2">
          <div class="col-md-9">
            <pmc-pagination :page-object="searchCrewPagination" v-on:settingPage="set_this_page"></pmc-pagination>
          </div>
          <div class="col-md-3">
            <select class="custom-select custom-select-sm w-50" id="Count" v-model="searchParams.count"
                    v-on:change="search_crew">
              <option :value="count" v-for="(count,index) in listCount" :selected="count === searchParams.count">
                {{ count }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <pmc-table :max-height="450" :loading="isReloading" :loader-colspan="6">
              <template slot="table-header">
                <tr>
                  <th class="border-bottom-0 border-right-0 border-top-0" style="width:2%;">
                    <font-awesome-icon icon="filter"/>
                  </th>

                  <!-- search rank -->
                  <th class="border-bottom-0 border-right-0 border-top-0" style="width:10%;">
                    <input
                      id="search-rank"
                      name="SearchRank"
                      style="font-size: 0.7em;"
                      type="text"
                      class="form-control border small"
                      placeholder="Rank .."
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="searchParams.sRank"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Press enter to search"
                      @keypress.enter="on_key_press">
                  </th>

                  <!-- last name -->
                  <th class="border-bottom-0 border-right-0 border-top-0" style="width:20%;">
                    <input
                      id="search-lastname"
                      name="SearchLastName"
                      style="font-size: 0.7em;"
                      type="text"
                      class="form-control border small"
                      placeholder="Last Name .."
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      @keypress.enter="on_key_press"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Press enter to search"
                      v-model="searchParams.sLastName">
                  </th>

                  <!-- first name -->
                  <th class="border-bottom-0 border-right-0 border-top-0" style="width:20%;">
                    <input
                      id="search-firstname"
                      name="SearchFirstName"
                      style="font-size: 0.7em;"
                      type="text"
                      class="form-control border small"
                      placeholder="First Name .."
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="searchParams.sFirstName"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Press enter to search"
                      @keypress.enter="on_key_press">
                  </th>

                  <!-- middle name -->
                  <th class="border-bottom-0 border-right-0 border-top-0" style="width:20%;">
                    <input
                      id="search-middlename"
                      name="SearchMiddleName"
                      style="font-size: 0.7em;"
                      type="text"
                      class="form-control border small"
                      placeholder="Middle Name .."
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      @keypress.enter="on_key_press"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Press enter to search"
                      v-model="searchParams.sMiddleName">
                  </th>
                  <th class="border-bottom-0 border-top-0">&nbsp;</th>
                </tr>
                <tr>
                  <th class="border-bottom-0 border-right-0">#</th>
                  <th class="border-bottom-0 border-right-0">Rank</th>
                  <th class="border-bottom-0 border-right-0">Last Name</th>
                  <th class="border-bottom-0 border-right-0">First Name</th>
                  <th class="border-bottom-0 border-right-0">Middle Name</th>
                  <th class="border-bottom-0 border-right-0">Status</th>
                </tr>
              </template>
              <template slot="table-body">
                <tr v-for="(crew,index) in filteredCrews"
                    :key="crew.id"
                    :class="crew.seaServiceStatus.id === 7 ? 'bg-red' : ''"
                    class="text-left pl-3"
                    v-on:click="go_to_profile(crew.id)">
                  <td class="border-top-0 border-right-0">{{ index + searchCrewPagination.from }}</td>
                  <td class="pl-2 border-top-0 border-right-0">{{ crew.rank ? crew.rank : null }}</td>
                  <td class="pl-2 border-top-0 border-right-0">{{ crew.lastName }}</td>
                  <td class="pl-2 border-top-0 border-right-0">{{ crew.firstName }}</td>
                  <td class="pl-2 border-top-0 border-right-0">{{ crew.middleName }}</td>
                  <td class="pl-2 border-top-0 border-right-0">{{ crew.seaServiceStatus.name }}</td>
                </tr>
              </template>
            </pmc-table>
          </div>
        </div>
      </template>
    </pmc-modal>
    <div id="table-section" class="py-3">
      <div class="row justify-content-center">
        <div class="col col-md">
          <div class="table table-responsive pmc-table border-top"
               style="height : 550px">
            <table class="table table-bordered table-sm text-center small sticky-header table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Rank From</th>
                <th>Rank To</th>
                <th>Date Recommended</th>
                <th>Date Approved</th>
                <th>PR Type</th>
                <th>Action</th>
              </tr>
              </thead>
              <template v-if="isLoading">
                <tr>
                  <td colspan="100%">
                    <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                    <span style="size: 20px">
                        Loading ...
                    </span>
                  </td>
                </tr>
              </template>
              <template v-else>
                <template v-if="hasData">
                  <template v-for="(row, index) in directPromotion">
                    <tr class="text-uppercase">
                      <td>{{ index + 1 }}</td>
                      <td>{{ row.fromRankAlias }}</td>
                      <td>{{ row.toRankAlias }}</td>
                      <td>{{ row.date_recommend ?? '--' }}</td>
                      <td>{{ row.date_approved ?? '--' }}</td>
                      <td>{{ row.type }}</td>
                      <td>
                        <button
                          class="btn btn-xs pmc-btn-prime ml-2"
                          @click="editCrewDirectPromotion(row.crew_promotion_id, row.toRankId)"
                          data-toggle="modal"
                          data-target="#editDirectPromotion"
                          data-backdrop="static"
                          data-keyboard="false"
                        >
                          <font-awesome-icon icon="bars"/>
                          view
                        </button>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="100%">
                      <span style="size: 20px;font-weight: bold;color: red;">
                        No Data
                      </span>
                    </td>
                  </tr>
                </template>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>
    <pmc-modal :id="editModal" :lg_width="true" @closingEvent="reset_direct_promotion">
      <template v-slot:custom-header>
        <h5 class="modal-title">Direct Promotion</h5>
      </template>
      <template v-slot:custom-body>
        <div class="row ml-1">
          <div class="col-md-12 text-left ml-2">

            <!-- Rank From -->
            <div class="form-group row my-2">
              <label class="col-sm-3 col-form-label text-left">Rank From</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-6">
                <select id="edit-promotion-from-rank"
                        :disabled="!isEditable"
                        v-model="editCrewPromotion.fromRankId"
                        :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                        class="custom-select custom-select-sm form-control form-control-sm">
                  <option :value="rank.id" v-for="(rank,index) in filterRankFrom" :key="rank.id">
                    {{ rank.alias }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Rank To -->
            <div class="form-group row my-2">
              <label class="col-sm-3 col-form-label text-left">Rank To</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-6">
                <select id="edit-promotion-to-rank"
                        :disabled="!isEditable"
                        v-model="editCrewPromotion.toRankId"
                        :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                        class="custom-select custom-select-sm form-control form-control-sm">
                  <option :value="rank.id" v-for="(rank,index) in filterRankTo" :key="rank.id">
                    {{ rank.alias }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Date Recommend -->
            <div class="form-group row my-2">
              <label class="col-sm-3 col-form-label text-left">Date Recommend</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-6">
                <input type="date"
                       :disabled="!isEditable"
                       @change="filteredDate(1)"
                       @blur="validateDate(3)"
                       v-model="editCrewPromotion.dateRecommended"
                       id="edit-promotion-date-recommend"
                       :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                       class="form-control form-control-sm"
                       placeholder="---"
                       :min="minDate"
                       :max="maxDate"
                />
              </div>
            </div>

            <!-- Date Approved -->
            <div class="form-group row my-2">
              <label class="col-sm-3 col-form-label text-left">Date Approved</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-6">
                <input type="date"
                       :disabled="!isEditable"
                       @change="filteredDate(2)"
                       @blur="validateDate(4)"
                       v-model="editCrewPromotion.dateApproved"
                       id="edit-promotion-date-approved"
                       :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                       class="form-control form-control-sm"
                       placeholder="---"
                       :min="editCrewPromotion.dateRecommended !== '' ? editCrewPromotion.dateRecommended : minDate"
                       :max="maxDate"
                />
              </div>
            </div>

          </div>
        </div>
      </template>
      <template v-slot:custom-footer>
        <button type="button"
                @click="set_edit"
                v-if="editPromotedRankId === crewPromotionData.currentRankId"
                v-show="!isEditable"
                class="btn btn-sm pmc-bg-warning">
          <font-awesome-icon icon="edit"/>
          EDIT
        </button>
        <button type="button"
                class="btn btn-sm pmc-btn-second"
                data-dismiss="modal"
                v-show="!isEditable"
                @click="reset_direct_promotion">
          <font-awesome-icon icon="undo"/>
          CLOSE
        </button>
        <button type="button"
                class="btn btn-sm pmc-btn-tertia"
                v-show="isEditable"
                :disabled="isValid"
                @click="update">
          <font-awesome-icon icon="save"/>
          SAVE
        </button>
        <button type="button" class="btn btn-sm pmc-btn-danger"
                v-show="isEditable"
                @click="set_cancel"
        >
          <font-awesome-icon icon="times"/>
          CANCEL
        </button>
      </template>
    </pmc-modal>
  </div>
</template>

<script>
import noPhoto from '@/assets/noPhoto.jpg';
import {mapActions, mapGetters} from 'vuex';
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import {AppMixins} from "@/mixins/app.mixins";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import _ from "lodash";

export default {
  name: 'CrewingDirectPromotion',
  mixins: [AppMixins],
  data() {
    return {
      searchingModal: 'searchCrewDirectPromotion',
      editModal: 'editDirectPromotion',
      listCount: [5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 100, 200],
      onSearching: null,
      crewImage: noPhoto,
      isViewProfile: true,
      isValid: false,
      editPromotedRankId: null,
      minDate: null,
      maxDate: null,
      crewPromotionData: {
        crewId: null,
        firstName: null,
        lastName: null,
        middleName: null,
        currentRankAlias: null,
        currentRankId: null,
        promotedRankId: null,
        dateRecommended: null,
        dateApproved: null,
      },
      editCrewPromotion: {
        crewPromotionId: null,
        crewId: null,
        fromRankId: null,
        toRankId: null,
        dateRecommended: null,
        dateApproved: null,
      },
      filteredRanks: [],
      filterRankFrom: [],
      filterRankTo: [],
      directPromotion: [],
      crewDirectPromotion: [],
      editDirectPromotion: [],
      isLoading: false,
      hasData: false,
      isEditable: false,
    };
  },
  methods: {
    ...mapActions(
      [
        'set_sFirstName',
        'set_sMiddleName',
        'set_sLastName',
        'set_sRank',
        'resetSearchCrewParam',
        'setNullCrews',
        'resetSearchCrewPagination',
        'getAlLCrewPaginated',
        'getRankKeys',
      ],
    ),
    on_key_press(e) {
      if ((this.searchParams.sFirstName || this.searchParams.sMiddleName || this.searchParams.sLastName) !== null) {
        this.searchParams.searchKey = null;
        this.searchParams.searchValue = null;
      }
      this.onSearching = e.target.value;
      this.searchParams.page = 1;
      this.search_crew();
    },
    search_crew() {
      this.searchParams.page = 1;
      this.getAlLCrewPaginated(this.searchParams);
    },
    reset_search_crew_param() {
      this.onSearching = null;
      this.resetSearchCrewPagination();
      this.setNullCrews();
      this.resetSearchCrewParam();
    },
    set_this_page(e) {
      this.searchCrewPagination.currentPage = e;
      this.searchParams.page = e;
      this.getAlLCrewPaginated(this.searchParams);
    },
    async go_to_profile(crewId) {

      const ONBOARD = 3;
      const INACTIVE = 7;
      const INACTIVE_LINE_UP = 8;
      const STANDBY_LINE_UP = 5;
      const ONBOARD_LINE_UP = 4;
      const APPLICANT_LINE_UP = 2;
      const crew = this.crews.filter(crew => crew.id === crewId);

      if(crew[0].seaServiceStatus.id === ONBOARD
        || crew[0].seaServiceStatus.id === INACTIVE
        || crew[0].seaServiceStatus.id === INACTIVE_LINE_UP
        || crew[0].seaServiceStatus.id === STANDBY_LINE_UP
        || crew[0].seaServiceStatus.id === ONBOARD_LINE_UP
        || crew[0].seaServiceStatus.id === APPLICANT_LINE_UP) {

        let name = crew[0].seaServiceStatus.name.toLowerCase();
        let words = name.split(' ');
        let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        let capitalized = capitalizedWords.join(' ');
        AlertService.warningActionService( "Crew is "+capitalized, 'Direct Promotion is not allowed');

      } else {
          this.hideModal(this.searchingModal);
          const crew = this.crews.filter(crew => crew.id === crewId);
          const ranks = this.objRanks.filter(rank => rank.id < crew[0].rank_id);
          this.crewPromotionData.crewId = crew[0].id;
          this.crewPromotionData.firstName = crew[0].firstName;
          this.crewPromotionData.lastName = crew[0].lastName;
          this.crewPromotionData.middleName = crew[0].middleName;
          this.crewPromotionData.currentRankAlias = crew[0].rank;
          this.crewPromotionData.currentRankId = crew[0].rank_id;
          this.crewImage = crew[0].crewImg;
          this.filteredRanks = ranks;
          this.isViewProfile = false;
          await this.get_direct_promotion(crew[0].id);
      }
    },
    async go_to_crew_profile(id, children = '') {

      let thisComName = children.toLowerCase();
      let routeData = this.$router.resolve({
        name: 'CrewSeaService',
        params: {
          crewId: `${id}`,
        },
        query: {
          view: `${thisComName}`
        }
      });
      window.open(routeData.href, '_blank', 'width=1200,height=800');
    },
    cancelCrew() {
      this.crewPromotionData.crewId = null;
      this.crewPromotionData.firstName = null;
      this.crewPromotionData.lastName = null;
      this.crewPromotionData.middleName = null;
      this.crewPromotionData.currentRankAlias = null;
      this.crewPromotionData.currentRankId = null;
      this.crewPromotionData.promotedRankId = null;
      this.crewPromotionData.dateApproved = null;
      this.crewPromotionData.dateRecommended = null;
      this.filteredRanks = [];
      this.directPromotion = [];
      this.crewImage = null;
      this.isViewProfile = true;
      this.hasData = false;
    },
    get_all_crew_paginated() {
      this.getAlLCrewPaginated(this.searchParams);
    },

    goto_changePass() {
      this.$router.push({name: 'ChangePassword'});
    },
    async get_direct_promotion(crewId) {
      const param = {
        id: crewId,
        crewPromotionId: 5,
      };
      this.isLoading = true;
      return await axios.post(PMC.GET_DIRECT_PROMOTION, param).then((r) => {
        this.directPromotion = r.data;
        this.isLoading = false;
        if(this.directPromotion.length !== 0) this.hasData = true;
        else this.hasData = false;
      }).catch((errors) => {
        this.isLoading = false;
        this.hasData = false;
        UtilityService.generateResponseMessage(errors, 'Get Direct Promotion');
        return false;
      });
    },
    async save() {

      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Direct Promotion', 'question')
      if (await __create) {
        const directPromotion = await axios.post(PMC.CREATE_DIRECT_PROMOTION, UtilityService.deCapitalizeProperties(this.crewPromotionData)).then((r) => {
          return r.data;
        }).catch((error) => {
          UtilityService.generateResponseMessage(error, 'Create Direct Promotion');
          return false;
        })

        if (directPromotion) {
          AlertService.successAlertService('Create direct promotion successful', 'Create direct promotion');
          const ranks = this.objRanks.filter(rank => rank.id === this.crewPromotionData.promotedRankId)
          const reFilteredRanks = this.objRanks.filter(rank => rank.id < ranks[0].id)
          this.get_direct_promotion(this.crewPromotionData.crewId);
          this.crewPromotionData.currentRankAlias = ranks[0].alias;
          this.crewPromotionData.currentRankId = ranks[0].id;
          this.crewPromotionData.promotedRankId = null;
          this.crewPromotionData.dateApproved = null;
          this.crewPromotionData.dateRecommended = null;
          this.filteredRanks = reFilteredRanks;
          this.isEditable = false;
        }
      }

    },
    set_edit() {
      this.isEditable = true;
      this.isValid = true;
    },
    set_cancel() {
      this.isEditable = false;
      this.editCrewDirectPromotion(this.editCrewPromotion.crewPromotionId, this.crewPromotionData.currentRankId);
    },
    async editCrewDirectPromotion(id, rankId) {
      const param = {
        id: id,
      };
      this.editPromotedRankId = rankId;
      return await axios.post(PMC.GET_CREW_DIRECT_PROMOTION, param).then((r) => {
        let data = r.data;
        this.editCrewPromotion.crewPromotionId = data.id;
        this.editCrewPromotion.crewId = data.crew_id;
        this.editCrewPromotion.fromRankId =  data.current_rank_id;
        this.editCrewPromotion.toRankId = data.promoted_rank_id;
        this.editCrewPromotion.dateRecommended = data.date_recommend;
        this.editCrewPromotion.dateApproved = data.date_approved;
        this.filterRankFrom = this.objRanks.filter(rank => rank.id <= this.editCrewPromotion.fromRankId);
        this.filterRankTo = this.objRanks.filter(rank => rank.id <= this.editCrewPromotion.toRankId);
        this.editDirectPromotion = r.data;
      }).catch((errors) => {
        UtilityService.generateResponseMessage(errors, 'Get Crew Direct Promotion');
        return false;
      });
    },
    reset_direct_promotion() {
      this.isEditable = false;
      this.editCrewDirectPromotion(this.editCrewPromotion.crewPromotionId, this.editCrewPromotion.toRankId);
    },
    async update() {

      let __create = AlertService.questionAlertService('Do you want to save changes that you made.', 'Update direct promotion', 'question')
      if (await __create) {
        const directPromotion = await axios.post(PMC.UPDATE_DIRECT_PROMOTION, UtilityService.deCapitalizeProperties(this.editCrewPromotion)).then((r) => {
          return r.data;
        }).catch((error) => {
          UtilityService.generateResponseMessage(error, 'Update Direct Promotion');
          return false;
        })
        if (directPromotion) {
          AlertService.successAlertService('Updated successfully', 'Update direct promotion');
          const ranks = this.objRanks.filter(rank => rank.id === this.editCrewPromotion.toRankId)
          const reFilteredRanks = this.objRanks.filter(rank => rank.id < ranks[0].id)
          this.crewPromotionData.currentRankAlias = ranks[0].alias;
          this.crewPromotionData.currentRankId = ranks[0].id;
          this.crewPromotionData.promotedRankId = null;
          this.crewPromotionData.dateApproved = null;
          this.crewPromotionData.dateRecommended = null;
          this.filteredRanks = reFilteredRanks;
          this.isEditable = false;
          this.get_direct_promotion(this.crewPromotionData.crewId);
          this.editCrewDirectPromotion(this.editCrewPromotion.crewPromotionId);
          this.hideModal('editDirectPromotion')
        }
      }

    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    getMinDate() {
      let date = new Date();
      date.setMonth(date.getMonth() - 9);
      this.minDate = this.formatDate(date);
    },
    getMaxDate() {
      let date = new Date();
      date.setMonth(date.getMonth() + 9);
      this.maxDate = this.formatDate(date);
    },
    filteredDate(actionType) {
      if(actionType === 1) {
        const year = new Date(this.editCrewPromotion.dateRecommended).getFullYear();
        if(this.editCrewPromotion.dateRecommended) this.editCrewPromotion.dateApproved = null;
        if (isNaN(year) || year < 1000 || year > 9999) {
          return;
        }
        if (this.editCrewPromotion.dateRecommended < this.minDate) {
          this.editCrewPromotion.dateRecommended = this.minDate;
        } else if (this.editCrewPromotion.dateRecommended > this.maxDate) {
          this.editCrewPromotion.dateRecommended = this.maxDate;
        }
      }
      if(actionType === 2) {
        const year = new Date(this.editCrewPromotion.dateApproved).getFullYear();
        if (isNaN(year) || year < 1000 || year > 9999) {
          return;
        }
        if (this.editCrewPromotion.dateApproved < this.editCrewPromotion.dateRecommended) {
          this.editCrewPromotion.dateApproved = this.editCrewPromotion.dateRecommended ?? this.minDate;
        } else if (this.editCrewPromotion.dateApproved > this.maxDate) {
          this.editCrewPromotion.dateApproved = this.maxDate;
        }
      }
    },
    getDateValidation(state, property) {
      const currentDate = new Date();
      const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).toISOString().split('T')[0];
      const year = new Date(state[property]).getFullYear();
      if (isNaN(year) || year < 1000 || year > 9999) state[property] = today;
    },
    validateDate(actionType) {
      switch(actionType) {
        case 1:
          this.getDateValidation(this.crewPromotionData, 'dateRecommended');
          break;
        case 2:
          this.getDateValidation(this.crewPromotionData, 'dateApproved');
          break;
        case 3:
          this.getDateValidation(this.editCrewPromotion, 'dateRecommended');
          break;
        case 4:
          this.getDateValidation(this.editCrewPromotion, 'dateApproved');
          break;
        default:
          break;
      }
    }
  },
  computed: {
    ...mapGetters(
      [
        'searchParams',
        'crews',
        'searchCrewPagination',
        'b4rExpired',
        'isReloading',
        'objRanks',
      ],
    ),
    filteredCrews() {
      return this.crews.filter(crew => crew.rank_id !== "");
    }
  },
  created() {
    this.getRankKeys();
    this.getMinDate();
    this.getMaxDate();
  },
  mounted() {
    if (this.b4rExpired == 0) {
      this.goto_changePass();
    }
    $('[data-toggle="tooltip"]').tooltip();
  },
  watch: {
    editCrewPromotion: {
      handler: function (value) {
        if(this.editDirectPromotion.current_rank_id === value.fromRankId
          && this.editDirectPromotion.promoted_rank_id === value.toRankId
          && this.editDirectPromotion.date_recommend === value.dateRecommended
          && this.editDirectPromotion.date_approved ===  value.dateApproved
          || value.dateRecommended === ''
          || value.dateApproved === ''
          || value.toRankId > value.fromRankId
          || value.toRankId === value.fromRankId) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      },
      deep: true,
    },
    'crewPromotionData.dateRecommended'(value) {
      const year = new Date(this.crewPromotionData.dateRecommended).getFullYear();
      if(this.crewPromotionData.dateRecommended) this.crewPromotionData.dateApproved = null;
      if (isNaN(year) || year < 1000 || year > 9999) {
        return;
      }
      if (this.crewPromotionData.dateRecommended < this.minDate) {
        this.crewPromotionData.dateRecommended = this.minDate;
      } else if (this.crewPromotionData.dateRecommended > this.maxDate) {
        this.crewPromotionData.dateRecommended = this.maxDate;
      }
    },
    'crewPromotionData.dateApproved'(value) {
      const year = new Date(this.crewPromotionData.dateApproved).getFullYear();
      if (isNaN(year) || year < 1000 || year > 9999) {
        return;
      }
      if (this.crewPromotionData.dateApproved < this.crewPromotionData.dateRecommended) {
        this.crewPromotionData.dateApproved = this.crewPromotionData.dateRecommended;
      } else if (this.crewPromotionData.dateApproved > this.maxDate) {
        this.crewPromotionData.dateApproved = this.maxDate;
      }
    },
  },
  // destroyed() {},
};
</script>

<style>
  .pmc-bg-prime:hover {
    color: white !important;
  }
  .pmc-bg-danger:hover {
    color: white !important;
  }
  .image-border-dark {
    border: 1px solid black;
    width: 150px;
    height: 150px;
  }
  .bg-red {
    background: #ee2a22;
  }
</style>
