<template>
  <div class="container-fluid">
    <!-- header row -->
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row">
          <!-- title & add button     -->
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Courses</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newCourseModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Course
                  </button>
                </div>
              </div>

<!--              <div class="col-3">-->
<!--                <h4>Courses</h4>-->
<!--              </div>-->
<!--              <div class="col-3">-->
<!--                <button class="btn btn-sm pmc-btn-tertia"-->
<!--                        data-toggle="modal"-->
<!--                        data-target="#newCourseModal"-->
<!--                        data-backdrop="static"-->
<!--                        data-keyboard="false">-->
<!--                  <font-awesome-icon icon="user-plus"/>-->
<!--                  <span>Add</span>-->
<!--                </button>-->
<!--              </div>-->


            </div>
          </div>

          <!-- searchbar -->
          <div class="col-md-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                  v-model="courseFormParam.searchValue"
                  type="text"
                  class="form-control form-control-sm"
                  id="formdata-searchvalue"
                  @keypress.enter="search_course"
                  placeholder="Search course name">

              <button class="btn btn-sm pmc-btn-prime" @click="search_course">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- status -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm" v-model="courseFormParam.status" @change="on_change_status">
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.display }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm" v-model="courseFormParam.count" @change="on_change_count">
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-8">
            <div class="row">
              <div class="col-6">
                <pmc-pagination :page-object="coursePagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table section -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="9">
            <template v-slot:table-header>
              <tr>
                <th scope="row" width="2%">#</th>
                <th scope="row" width="5%">CODE</th>
                <th scope="row" width="20%">NAME</th>
                <th scope="row" width="10%">CREATED BY</th>
                <th scope="row" width="10%">UPDATED BY</th>
                <th scope="row" width="5%">CREATED AT</th>
                <th scope="row" width="5%">UPDATED AT</th>
                <th scope="row" width="6%">STATUS</th>
                <th scope="row" width="6%">ACTION</th>
              </tr>
            </template>

            <template v-slot:table-body>
              <tr v-if = "courses.length === 0">
                <td colspan="9">No Results</td>
              </tr>
              <tr v-else v-for="(course,index) in courses" :key="course.id">
                <td>{{ index + coursePagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ course.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ course.name }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ course.createdBy }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ course.updatedBy }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ course.created_at }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ course.updated_at }}</td>
                <td scope="col" class="p-0 text-center">{{ course.status ? 'ACTIVE' : 'IN-ACTIVE' }}</td>
                <td>
                  <button type="button" class="btn btn-xs pmc-btn-prime"
                          data-toggle="modal"
                          data-target="#viewCourseModal"
                          data-backdrop="static"
                          data-keyboard="false"
                          @click="set_course(course.id)">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>

                  <button type="button" class="btn btn-xs pmc-btn-danger ml-3"
                          @click="delete_course(course.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!-- modal new course -->
    <div class="modal fade"
         id="newCourseModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="newCourseModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="newCourseModalLabel">New Course</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           v-model="newCourse.code"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input type="text"
                           v-model="newCourse.name"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- status -->
                <div class="form-group row mt-3">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           v-model="newCourse.status"
                           class="form-check form-check-inline ml-2"
                           placeholder="---"
                    />
                    <span>{{ newCourse.status ? 'ACTIVE' : 'IN-ACTIVE' }}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-sm pmc-btn-tertia" @click="save">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>
            <button type="button" class="btn btn-sm pmc-btn-second" data-dismiss="modal" @click="close_new_course_modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- modal view course   -->
    <div class="modal fade" id="viewCourseModal" tabindex="-1" role="dialog" aria-labelledby="viewCourseModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewCourseModalLabel">Course</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="course.code"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="course.name"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           v-model="course.status"
                           class="form-check form-check-inline ml-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                    />
                    <span>{{ course.status ? 'ACTIVE' : 'IN-ACTIVE' }}</span>
                  </div>
                </div>


                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CREATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="course.createdBy"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">UPDATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="course.updatedBy"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    @click="set_edit"
                    v-show="!isEditable">
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>
            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    @click="close_view_course_modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>
            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>
            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel(course.id)">
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { AlertMixins } from '../../../../mixins/alert.mixins'
import { AppMixins } from '../../../../mixins/app.mixins'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'List',

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data () {
    return {
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          display: 'All',
        },
        {
          id: 2,
          key: 1,
          display: 'Active',
        },
        {
          id: 3,
          key: 0,
          display: 'Inactive',
        },
      ],

      isEditable: false,

      pageLastLeft: 1,
    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_courses',
      'set_course',
      'update_course',
      'delete_course',
      'create_course',
      'reset_course_pagination',
      'reset_course_form_param',
      'null_course',
      'null_courses',
    ]),

    search_course () {
      if (this.courseFormParam.searchValue === '') {
        this.courseFormParam.page = this.pageLastLeft
        this.set_courses(this.courseFormParam)
      }

      if (this.courseFormParam.searchValue) {
        this.courseFormParam.page = 1
      }

      this.set_courses(this.courseFormParam)
    },

    set_this_page (e) {

      this.courseFormParam.searchValue = ''
      this.coursePagination.currentPage = e
      this.courseFormParam.page = e
      this.pageLastLeft = e
      this.set_courses(this.courseFormParam)
    },

    on_change_status () {
      this.courseFormParam.page = 1
      this.set_courses(this.courseFormParam)
    },

    on_change_count (e) {

      this.courseFormParam.page = 1
      this.courseFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.courseFormParam.count = e.target.value
      this.set_courses(this.courseFormParam)

    },

    save () {
      this.create_course(this.newCourse).then((r) => {
        if (r) {
          this.hideModal('newCourseModal')
        }
      })
    },

    update () {
      this.update_course(this.course).then((r) => {
        if (r) {
          this.hideModal('viewCourseModal')
          this.null_course()
          this.isEditable = false
        }
      })
    },

    set_edit () {
      this.isEditable = true
    },

    set_cancel (e) {
      this.isEditable = false
      this.set_course(e)
    },

    close_view_course_modal () {
      this.isEditable = false
      this.null_course()
    },

    close_new_course_modal () {
      this.isEditable = false
      this.null_course()
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          'course',
          'courses',
          'newCourse',
          'courseFormParam',
          'coursePagination',
          'isReloading',
        ],
    ),
  },

  /** mounted **/
  mounted () {
  },

  /** created **/
 async created () {
    await this.set_courses(this.courseFormParam)
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed () {
    this.null_course()
    this.null_courses()
    this.reset_course_pagination()
    this.reset_course_form_param()
  },
}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(2) !important;
}
</style>
