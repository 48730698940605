<template>
  <div class="row py-1">
    <div class="col-md-12">


      <div class="row">
        <div class="col-md-8">
          <div class="row pt-3">
            <label class="font-weight-bold ml-sm-4">
              <h4>Covid Vaccine</h4>
            </label>
            <div class="col-8 font-weight-bold justify-content-center">
              <pmc-modal-btn
                  :button-class="'btn btn-sm pmc-btn-prime ml-5'"
                  :target-modal="newCovidVaccineModal"
                  :button-label="'Add'">
                <template slot="button-icon">
                  <font-awesome-icon icon="plus-circle"/>
                </template>
              </pmc-modal-btn>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="row pt-3">
            <div class="form-inline mb-3">
              <div class="form-group mb-2 mr-5">
                <span class="ml-4 font-weight-bold">Filter</span>
              </div>
              <div class="form-group">
                <select id="vaccine-filter" class="form-control form-control-sm" @change="on_change_vaccine">
                  <option :value="null">-- all --</option>
                  <option :value="1">Booster</option>
                  <option :value="0">Vaccine</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row pt-2">
        <div class="col-md-12">
          <pmc-table :max-height="400">
            <template v-slot:table-header>
              <tr>
                <th scope="col" class="p-0 align-middle" style="width: 2% !important;">#</th>
                <th scope="col" style="width: 20% !important;">Brand Name • [Dosage Required]</th>
                <th scope="col" style="width: 10% !important;">Inoculate Date</th>
                <th scope="col" style="width: 10% !important;">Completion Date</th>
                <th scope="col" style="width: 10% !important;">Is Booster</th>
                <th scope="col" style="width: 30% !important;">Remarks</th>
                <th scope="col" style="width: 8% !important;">Action</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-for="(covidVaccine,index) in covidVaccines" :key="covidVaccine.id">
                <td class="p-1">{{ index + 1 }}</td>
                <td class="p-1 text-left pl-3">{{ covidVaccine.vaccineName }}

                  <template v-if="covidVaccine.first_dose_only == 1">
                    <span class="badge badge-pill badge-dark">1 DOSE</span>
                  </template>

                  <template v-if="covidVaccine.first_dose_only != 1">
                    <span class="badge badge-pill badge-info">2 DOSES</span>
                  </template>

                </td>
                <td>{{ covidVaccine.inoculateDate_str }}</td>
                <td v-if="covidVaccine.first_dose_only == 1">
                  <!--                  <span class="badge badge-pill badge-warning">FULL DOSE</span>-->
                  {{ covidVaccine.inoculateDate_str }}
                </td>
                <td v-if="covidVaccine.first_dose_only != 1">
                  {{ covidVaccine.completionDate_str }}
                </td>

                <td>
                  <template v-if="covidVaccine.isBooster != 0">
                    <font-awesome-icon icon="check" style="transform: scale(1.7); color:#28a745;"/>
                  </template>
                  <!--                  {{ isExistsVaccine }}-->
                </td>
                <td class="text-left pl-3">{{ covidVaccine.remarks }}</td>
                <td class="text-center">
                  <pmc-modal-btn
                      :button-class="'btn btn-xs pmc-btn-prime'"
                      :target-modal="viewCovidVaccineModal"
                      :button-label="'View'"
                      @modalButtonEvent="set_covid_vaccine(covidVaccine.id)">
                    <template v-slot:button-icon>
                      <font-awesome-icon icon="bars"/>
                    </template>
                  </pmc-modal-btn>

                  <button class="ml-1 btn btn-xs pmc-btn-danger" @click="delete_vaccine(covidVaccine.id)">
                    <font-awesome-icon icon="trash"/>
                    <span class="ml-1">Delete</span>
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>


      <!--  new modal     -->
      <pmc-modal @closingEvent="close_new_vaccine_modal" lg_height xl_width :id="newCovidVaccineModal">
        <template v-slot:custom-header>
          <span class="font-weight-bold">New Vaccine Details</span>
        </template>

        <template v-slot:custom-body>
          <div class="row">
            <div class="col-md-6">
              <!--            vaccine brand  -->
              <div class="form-group row mt-3 align-middle">
                <label for="new-vaccine-brand-id" class="col-sm-3 col-form-label">Vaccine Name</label>
                <span class="col-form-label">:</span>
                <div class="col-md-8">
                  <multi-select
                      id="new-vaccine-brand-id"
                      v-model="newCovidVaccineId"
                      :options="objCovidVaccineBrands"
                      :custom-label="vaccine_lang"
                      :select-label="'Select vaccine name'"
                      placeholder="Select vaccine name"
                      label="Vaccine names"
                      track-by="id">
                  </multi-select>
                </div>
              </div>

              <!-- first date issue -->
              <div class="form-group row mt-3">
                <label for="new-first-dose-date" class="col-sm-3 col-form-label">Date Issue</label>
                <span class="col-form-label">:</span>
                <div class="col-md-5">
                  <input type="date"
                         id="new-first-dose-date"
                         class="form-control form-control-sm"
                         v-model="newCovidVaccine.inoculateDate"
                  />
                </div>
                <button v-if="newCovidVaccine.inoculateDate" class="btn btn-xs bg-white"
                        @click="newCovidVaccine.inoculateDate = null">
                  <font-awesome-icon icon="times"/>
                </button>
              </div>

              <!-- second date issue -->
              <div class="form-group row mt-3"
                   v-if="(newCovidVaccineId ? newCovidVaccineId.first_dose_only : '') === 0">
                <label for="new-second-dose-date" class="col-sm-3 col-form-label">Date Completion</label>
                <span class="col-form-label">:</span>
                <div class="col-md-5">
                  <input type="date"
                         id="new-second-dose-date"
                         class="form-control form-control-sm"
                         v-model="newCovidVaccine.completionDate"
                  />
                </div>
                <button v-if="newCovidVaccine.completionDate" class="btn btn-xs bg-white"
                        @click="newCovidVaccine.completionDate = null">
                  <font-awesome-icon icon="times"/>
                </button>
              </div>

              <!--              <div class="form-group row mt-3">-->
              <!--                <label for="new-is-booster-shot"></label>-->
              <!--                <div class="col-md-5">-->
              <!--                  <input type="checkbox" name="newIsBoosterShot" id="new-is-booster-shot" class="form-control">-->
              <!--                </div>-->
              <!--              </div>-->

              <!-- remarks -->
              <div class="form-group row mt-3">
                <label for="new-remarks" class="col-sm-3 col-form-label">Remarks</label>
                <span class="col-form-label">:</span>
                <div class="col-md-8">
                <textarea
                    id="new-remarks"
                    rows="5"
                    style="resize: none; width: 100%;"
                    v-model="newCovidVaccine.remarks"
                    class="form-control form-control-sm">
                </textarea>
                </div>
              </div>

              <!-- attach new file  -->
              <div class="form-group row mt-3">
                <label for="new-vaccine-file" class="col-sm-3 col-form-label">Attach file</label>
                <span class="col-form-label">:</span>
                <div class="col-md-7">
                  <input
                      type="file"
                      @change="on_change_new_vaccine_file"
                      id="new-vaccine-file"
                      class="form-control-file"/>
                </div>
              </div>

              <!-- Is Booster? -->
              <div class="form-group row mt-3" v-if="isExistsVaccine">
                <label for="vaccine-file" class="col-sm-3 col-form-label">
                  Is Booster ?
                </label>
                <span class="col-form-label">:</span>

                <div class="col-md-8">
                  <div class="form-inline mb-3">
                    <div class="form-group mb-2 mr-3 ml-2">
                      <input type="checkbox" v-model="newCovidVaccine.isBooster"/>
                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div class="col-md-6 border-left">
              <div class="prev-container">
                <div v-if="!is10mbUp" style="height: 400px;">
                  <div class="center-alignment">
                    <span v-if="!thisCovidVaccineBlob">
                      <font-awesome-icon icon="file" class="small"/>
                    </span>
                  </div>

                  <div v-if="thisCovidVaccineBlob">
                    <object style="height: 380px;" width="100%" height="100%"
                            :data="thisCovidVaccineBlob+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=50,10,0'"
                            type="application/pdf"></object>
                  </div>
                </div>

                <!--  if 10 up todo  -->
                <div v-if="is10mbUp" style="height: 400px;">
                  <div class="center-alignment">
                    <button class="btn btn-xs btn-outline-danger" @click="preiview_file_document()">
                      <font-awesome-icon icon="file"/>
                      <small>document</small>
                    </button>
                    <p class="small ml-1">click to preview file more than 3mb.</p>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </template>

        <template slot="custom-footer">
          <button class="btn btn-sm pmc-btn-tertia" @click="save">
            <font-awesome-icon icon="save"/>
            Save
          </button>

          <button class="btn btn-sm pmc-btn-danger" @click="close_new_vaccine_modal">
            <font-awesome-icon icon="times"/>
            Cancel
          </button>
        </template>
      </pmc-modal>

      <div class="modal fade" id="viewCovidVaccineModal" tabindex="-1" role="dialog"
           aria-labelledby="viewCovidVaccineModalCenterTitle"
           aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered xl-width"
             role="document">

          <div class="modal-content xl_height">
            <div class="modal-header">
              <span>{{ covidVaccine?.crewName }}</span>
              <button type="button" id="close-view-attachments-modal" class="close"
                      @click="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">

                  <div class="row">
                    <div class="col text-left mb-3">
                      <h4>Covid Details</h4>
                    </div>
                  </div>

                  <!--            vaccine brand  -->
                  <div class="form-group row mt-3 align-middle">
                    <label for="vaccine-brand-id" class="col-sm-3 col-form-label">Vaccine Name</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-8">
                      <multi-select
                          id="vaccine-brand-id"
                          v-model="covidVaccineId"
                          :options="objCovidVaccineBrands"
                          :custom-label="vaccine_lang"
                          :select-label="'Select vaccine name'"
                          placeholder="Select vaccine name"
                          label="Vaccine names"
                          :disabled="!isEditable"
                          track-by="id">
                      </multi-select>
                    </div>
                    <!--                    <button class="btn btn-xs pmc-btn-prime">-->
                    <!--                      <font-awesome-icon icon="plus-circle"/>-->
                    <!--                    </button>-->
                  </div>

                  <!-- first date issue -->
                  <div class="form-group row mt-3">
                    <label for="first-dose-date" class="col-sm-3 col-form-label">First dose date</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-5">
                      <input type="date"
                             id="first-dose-date"
                             class="form-control form-control-sm"
                             v-if="covidVaccine"
                             v-model="covidVaccine.inoculateDate"
                             :disabled="!isEditable"
                      />
                    </div>
                  </div>

                  <!-- second date issue -->
                  <div class="form-group row mt-3" v-if="(covidVaccineId ? covidVaccineId.first_dose_only : '') == 0">
                    <label for="second-dose-date" class="col-sm-3 col-form-label">Second dose date</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-5">
                      <input type="date"
                             id="second-dose-date"
                             class="form-control form-control-sm"
                             v-if="covidVaccine"
                             v-model="covidVaccine.completionDate"
                             :disabled="!isEditable"
                      />
                    </div>
                  </div>

                  <!-- remarks -->
                  <div class="form-group row mt-3">
                    <label for="remarks" class="col-sm-3 col-form-label">Remarks</label>
                    <span class="col-form-label">:</span>
                    <div class="col-md-8">
                <textarea
                    id="remarks"
                    rows="5"
                    style="resize: none; width: 100%;"
                    v-if="covidVaccine"
                    v-model="covidVaccine.remarks"
                    :disabled="!isEditable"
                    class="form-control form-control-sm">
                </textarea>
                    </div>
                  </div>

                  <!-- attach file  -->
                  <div class="form-group row mt-3">
                    <label for="vaccine-file" class="col-sm-3 col-form-label" v-if="covidVaccine">
                      {{ covidVaccine.hasFile ? 'Replace file' : 'Attach file' }}
                    </label>
                    <span class="col-form-label">:</span>

                    <div class="col-md-8">
                      <div class="form-inline mb-3">
                        <div class="form-group mb-2 mr-3 ml-2">
                          <input :disabled="!isEditable"
                                 type="checkbox"
                                 v-model="isDocReplace"
                                 @change="on_change_file"/>
                        </div>
                        <div class="form-group ">
                          <!--                  class="form-control-sm"-->
                          <input
                              :disabled="!isDocReplace"
                              type="file"
                              @change="on_change_view_vaccine_file"
                              id="vaccine-file"/>
                        </div>
                      </div>
                    </div>


                    <!--                <div class="col-md-8">-->
                    <!--                  <input :disabled="!isEditable"-->
                    <!--                         type="checkbox"-->
                    <!--                         v-model="isDocReplace"-->
                    <!--                         @change="on_change_file"-->
                    <!--                         class="form-check form-check-inline ml-2"/>-->
                    <!--                  <input-->
                    <!--                    :disabled="!isDocReplace"-->
                    <!--                    type="file"-->
                    <!--                    @change="on_change_view_vaccine_file"-->
                    <!--                    id="vaccine-file"/>-->
                    <!--                  class="form-control-sm"-->
                    <!--                </div>-->

                  </div>


                  <div class="form-group row mt-3">
                    <label for="vaccine-file" class="col-sm-3 col-form-label">
                      Is Booster ?
                    </label>
                    <span class="col-form-label">:</span>

                    <div class="col-md-8">
                      <div class="form-inline mb-3">
                        <div class="form-group mb-2 mr-3 ml-2">
                          <template v-if="covidVaccine">
                            <input type="checkbox" v-model="covidVaccine.isBooster" :disabled="!isEditable"/>
                          </template>
                        </div>

                      </div>
                    </div>
                  </div>

                  <hr/>


                  <!--                  &lt;!&ndash;<editor-fold desc="(DO NOT DELETE) Booster Details">&ndash;&gt;-->
                  <!--                  &lt;!&ndash;              v-if="(((covidVaccineId ? covidVaccineId.first_dose_only : '') == 0) && covidVaccine.completionDate) || (((covidVaccineId ? covidVaccineId.first_dose_only : '') == 1) && covidVaccine.inoculateDate)"&ndash;&gt;-->
                  <!--                  <template v-if="covidVaccineId">-->
                  <!--                    <template v-if="((covidVaccineId ? covidVaccineId.first_dose_only : '') == 0 && covidVaccine.completionDate) ||-->
                  <!--                              ((covidVaccineId ? covidVaccineId.first_dose_only : '') == 1 && covidVaccine.inoculateDate)">-->

                  <!--                      <div class="row">-->
                  <!--                        <div class="col text-left mb-3 mt-3">-->
                  <!--                          <h4>Booster Details</h4>-->
                  <!--                        </div>-->
                  <!--                      </div>-->

                  <!--                      <div class="form-group row mt-3">-->
                  <!--                        <label for="booster-file" class="col-sm-2 col-form-label">Attach Booster</label>-->
                  <!--                        <span class="col-form-label">:</span>-->
                  <!--                        <div class="col-md-5">-->
                  <!--                          <input-->
                  <!--                            :disabled="!isEditable"-->
                  <!--                            type="file"-->
                  <!--                            id="booster-file"-->
                  <!--                            @change="on_change_view_vaccine_brand"-->
                  <!--                            class="form-control-file"/>-->
                  <!--                        </div>-->

                  <!--                        <div class="col-md-4" v-if="showBoosterBrand">-->
                  <!--                          <select class="form-control" aria-label="Default select example"-->
                  <!--                                  v-model="covidVaccine.vaccineBrandId">-->
                  <!--                            <option :value="null">&#45;&#45;select&#45;&#45;</option>-->
                  <!--                            <option :value="vaccineBrands.id" v-for="(vaccineBrands,index) in objCovidVaccineBrands">-->
                  <!--                              {{ vaccineBrands.name }}-->
                  <!--                            </option>-->
                  <!--                          </select>-->
                  <!--                        </div>-->
                  <!--                      </div>-->


                  <!--                      <div class=" table-responsive text-small mb-3 mt-5 addScroll">-->
                  <!--                        <table class="table table-hover table-bordered table-template-1 text-small sticky-header">-->
                  <!--                          <thead>-->
                  <!--                          <tr>-->
                  <!--                            <th class="col-type-width" style="text-align: center;">Attachment</th>-->
                  <!--                            <th style="text-align: center;">Brand Name</th>-->
                  <!--                            <th style="text-align: center;">File</th>-->
                  <!--                            <th v-if="isEditable"></th>-->
                  <!--                          </tr>-->
                  <!--                          </thead>-->

                  <!--                          <tbody>-->
                  <!--                          &lt;!&ndash;                  v-if="covidVaccine.vaccineBooster "&ndash;&gt;-->
                  <!--                          <template v-if="covidVaccine">-->
                  <!--                            <tr v-for="(booster, index) in covidVaccine.vaccineBooster" :key="index">-->
                  <!--                              <td style="text-align: center;">-->
                  <!--                                <span class="bg-light-danger font-weight-bolder">Booster File {{ index + 1 }}</span>-->
                  <!--                              </td>-->
                  <!--                              <td style="text-align: center;">-->
                  <!--                                <span class="bg-light-danger font-weight-bolder">{{ booster.vaccine_name }}</span>-->
                  <!--                              </td>-->
                  <!--                              <td style="text-align: center;">-->
                  <!--                                <a type="button" class="text-primary"-->
                  <!--                                   title="View Attachment"-->
                  <!--                                   @click="loadBoosterAttachmentFile(booster.crew_id, booster.covid_vaccine_id, booster.id)"-->
                  <!--                                >View File</a>-->
                  <!--                              </td>-->
                  <!--                              <td style="text-align: center;" v-if="isEditable">-->
                  <!--                                <button type="button"-->
                  <!--                                        class="btn btn-outline-danger btn-sm btn-xs font-weight-bold"-->
                  <!--                                        :disabled="!isEditable"-->
                  <!--                                        @click="delete_booster(booster.crew_id, booster.covid_vaccine_id, booster.id)"-->
                  <!--                                >-->
                  <!--                                  &lt;!&ndash;                    <i class="fas fa-minus"></i>&ndash;&gt;-->
                  <!--                                  ✖-->
                  <!--                                </button>-->
                  <!--                              </td>-->
                  <!--                            </tr>-->
                  <!--                          </template>-->

                  <!--                          &lt;!&ndash;v-if="covidVaccine.vaccineBooster ? (covidVaccine.vaccineBooster.length == 0) : '' "&ndash;&gt;-->
                  <!--                          <template v-if="covidVaccine">-->
                  <!--                            <tr v-if="covidVaccine.vaccineBooster ? (covidVaccine.vaccineBooster.length == 0) : '' ">-->
                  <!--                              <td colspan="100%" class="bg-light-danger font-weight-bolder">-->
                  <!--                                <p class="text-center mt-3">No attachments found</p>-->
                  <!--                              </td>-->
                  <!--                            </tr>-->
                  <!--                          </template>-->

                  <!--                          </tbody>-->
                  <!--                        </table>-->

                  <!--                      </div>-->

                  <!--                    </template>-->
                  <!--                  </template>-->
                  <!--                  &lt;!&ndash;</editor-fold>&ndash;&gt;-->


                </div>


                <div class="col-md-6 border-left">
                  <div class="prev-container">
                    <div v-if="!is10mbUp" style="height: 550px;">
                      <div class="center-alignment">
                    <span v-if="!thisCovidVaccineBlob">
                      <font-awesome-icon icon="file" class="small"/>
                    </span>
                      </div>

                      <div v-if="thisCovidVaccineBlob">
                        <object style="height: 550px;" width="100%" height="100%"
                                :data="thisCovidVaccineBlob+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=50,10,0'"
                                type="application/pdf"></object>
                      </div>
                    </div>

                    <!--  if 10 up todo  -->
                    <div v-if="is10mbUp" style="height: 400px;">
                      <div class="center-alignment">
                        <button class="btn btn-xs btn-outline-danger" @click="preiview_file_document()">
                          <font-awesome-icon icon="file"/>
                          <small>document</small>
                        </button>
                        <p class="small ml-1">click to preview file more than 3mb.</p>
                      </div>
                    </div>

                  </div>
                </div>


              </div>
            </div>

            <div class="modal-footer">
              <button v-if="!isEditable" class="btn btn-sm pmc-bg-warning" @click="set_edit">
                <font-awesome-icon icon="edit"/>
                Edit
              </button>

              <button v-if="!isEditable" class="btn btn-sm pmc-btn-second" @click="close_view_vaccine_modal">
                <font-awesome-icon icon="times"/>
                Close
              </button>

              <button v-if="isEditable" class="btn btn-sm pmc-btn-tertia" @click="update">
                <font-awesome-icon icon="save"/>
                Save
              </button>

              <button v-if="isEditable && covidVaccine.hasFile" class="btn btn-sm pmc-btn-danger"
                      @click="delete_covid_vaccine_file(covidVaccine.id)">
                <font-awesome-icon icon="trash"/>
                Delete Attached File
              </button>

              <button v-if="isEditable" class="btn btn-sm pmc-btn-second" @click="set_cancel">
                <font-awesome-icon icon="undo"/>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>


      <!--      <pmc-modal @closingEvent="close_view_vaccine_modal"-->
      <!--                 :id="viewCovidVaccineModal"-->
      <!--                 xl_height-->
      <!--                 xl_width>-->

      <!--        <template slot="custom-header">-->
      <!--          <span>Covid Vaccine & Booster Record</span>-->
      <!--        </template>-->

      <!--        <template slot="custom-body">-->
      <!--          <div class="row">-->
      <!--            <div class="col-md-6">-->

      <!--              <div class="row">-->
      <!--                <div class="col text-left mb-3">-->
      <!--                  <h4>Covid Details</h4>-->
      <!--                </div>-->
      <!--              </div>-->

      <!--              &lt;!&ndash;            vaccine brand  &ndash;&gt;-->
      <!--              <div class="form-group row mt-3 align-middle">-->
      <!--                <label for="vaccine-brand-id" class="col-sm-3 col-form-label">Vaccine Name</label>-->
      <!--                <span class="col-form-label">:</span>-->
      <!--                <div class="col-md-8">-->
      <!--                  <multi-select-->
      <!--                    id="vaccine-brand-id"-->
      <!--                    v-model="covidVaccineId"-->
      <!--                    :options="objCovidVaccineBrands"-->
      <!--                    :custom-label="vaccine_lang"-->
      <!--                    :select-label="'Select vaccine name'"-->
      <!--                    placeholder="Select vaccine name"-->
      <!--                    label="Vaccine names"-->
      <!--                    :disabled="!isEditable"-->
      <!--                    track-by="id">-->
      <!--                  </multi-select>-->
      <!--                </div>-->
      <!--                <button class="btn btn-xs pmc-btn-prime">-->
      <!--                  <font-awesome-icon icon="plus-circle"/>-->
      <!--                </button>-->
      <!--              </div>-->

      <!--              &lt;!&ndash; first date issue &ndash;&gt;-->
      <!--              <div class="form-group row mt-3">-->
      <!--                <label for="first-dose-date" class="col-sm-3 col-form-label">First dose date</label>-->
      <!--                <span class="col-form-label">:</span>-->
      <!--                <div class="col-md-5">-->
      <!--                  <input type="date"-->
      <!--                         id="first-dose-date"-->
      <!--                         class="form-control form-control-sm"-->
      <!--                         v-if="covidVaccine"-->
      <!--                         v-model="covidVaccine.inoculateDate"-->
      <!--                         :disabled="!isEditable"-->
      <!--                  />-->
      <!--                </div>-->
      <!--              </div>-->

      <!--              &lt;!&ndash; second date issue &ndash;&gt;-->
      <!--              <div class="form-group row mt-3" v-if="(covidVaccineId ? covidVaccineId.first_dose_only : '') == 0">-->
      <!--                <label for="second-dose-date" class="col-sm-3 col-form-label">Second dose date</label>-->
      <!--                <span class="col-form-label">:</span>-->
      <!--                <div class="col-md-5">-->
      <!--                  <input type="date"-->
      <!--                         id="second-dose-date"-->
      <!--                         class="form-control form-control-sm"-->
      <!--                         v-if="covidVaccine"-->
      <!--                         v-model="covidVaccine.completionDate"-->
      <!--                         :disabled="!isEditable"-->
      <!--                  />-->
      <!--                </div>-->
      <!--              </div>-->

      <!--              &lt;!&ndash; remarks &ndash;&gt;-->
      <!--              <div class="form-group row mt-3">-->
      <!--                <label for="remarks" class="col-sm-3 col-form-label">Remarks</label>-->
      <!--                <span class="col-form-label">:</span>-->
      <!--                <div class="col-md-8">-->
      <!--                <textarea-->
      <!--                  id="remarks"-->
      <!--                  rows="5"-->
      <!--                  style="resize: none; width: 100%;"-->
      <!--                  v-if="covidVaccine"-->
      <!--                  v-model="covidVaccine.remarks"-->
      <!--                  :disabled="!isEditable"-->
      <!--                  class="form-control form-control-sm">-->
      <!--                </textarea>-->
      <!--                </div>-->
      <!--              </div>-->

      <!--              &lt;!&ndash; attach file  &ndash;&gt;-->
      <!--              <div class="form-group row mt-3">-->
      <!--                <label for="vaccine-file" class="col-sm-3 col-form-label" v-if="covidVaccine">-->
      <!--                  {{ covidVaccine.hasFile ? 'Replace file' : 'Attach file' }}-->
      <!--                </label>-->
      <!--                <span class="col-form-label">:</span>-->

      <!--                <div class="col-md-8">-->
      <!--                  <div class="form-inline mb-3">-->
      <!--                    <div class="form-group mb-2 mr-3 ml-2">-->
      <!--                      <input :disabled="!isEditable"-->
      <!--                             type="checkbox"-->
      <!--                             v-model="isDocReplace"-->
      <!--                             @change="on_change_file"/>-->
      <!--                    </div>-->
      <!--                    <div class="form-group ">-->
      <!--                      &lt;!&ndash;                  class="form-control-sm"&ndash;&gt;-->
      <!--                      <input-->
      <!--                        :disabled="!isDocReplace"-->
      <!--                        type="file"-->
      <!--                        @change="on_change_view_vaccine_file"-->
      <!--                        id="vaccine-file"/>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->


      <!--                &lt;!&ndash;                <div class="col-md-8">&ndash;&gt;-->
      <!--                &lt;!&ndash;                  <input :disabled="!isEditable"&ndash;&gt;-->
      <!--                &lt;!&ndash;                         type="checkbox"&ndash;&gt;-->
      <!--                &lt;!&ndash;                         v-model="isDocReplace"&ndash;&gt;-->
      <!--                &lt;!&ndash;                         @change="on_change_file"&ndash;&gt;-->
      <!--                &lt;!&ndash;                         class="form-check form-check-inline ml-2"/>&ndash;&gt;-->
      <!--                &lt;!&ndash;                  <input&ndash;&gt;-->
      <!--                &lt;!&ndash;                    :disabled="!isDocReplace"&ndash;&gt;-->
      <!--                &lt;!&ndash;                    type="file"&ndash;&gt;-->
      <!--                &lt;!&ndash;                    @change="on_change_view_vaccine_file"&ndash;&gt;-->
      <!--                &lt;!&ndash;                    id="vaccine-file"/>&ndash;&gt;-->
      <!--                &lt;!&ndash;                  class="form-control-sm"&ndash;&gt;-->
      <!--                &lt;!&ndash;                </div>&ndash;&gt;-->


      <!--              </div>-->

      <!--              <hr/>-->


      <!--              &lt;!&ndash;              v-if="(((covidVaccineId ? covidVaccineId.first_dose_only : '') == 0) && covidVaccine.completionDate) || (((covidVaccineId ? covidVaccineId.first_dose_only : '') == 1) && covidVaccine.inoculateDate)"&ndash;&gt;-->
      <!--              <template v-if="covidVaccineId">-->
      <!--                <template v-if="((covidVaccineId ? covidVaccineId.first_dose_only : '') == 0 && covidVaccine.completionDate) ||-->
      <!--                              ((covidVaccineId ? covidVaccineId.first_dose_only : '') == 1 && covidVaccine.inoculateDate)">-->

      <!--                  <div class="row">-->
      <!--                    <div class="col text-left mb-3 mt-3">-->
      <!--                      <h4>Booster Details</h4>-->
      <!--                    </div>-->
      <!--                  </div>-->

      <!--                  <div class="form-group row mt-3">-->
      <!--                    <label for="booster-file" class="col-sm-2 col-form-label">Attach Booster</label>-->
      <!--                    <span class="col-form-label">:</span>-->
      <!--                    <div class="col-md-5">-->
      <!--                      <input-->
      <!--                        :disabled="!isEditable"-->
      <!--                        type="file"-->
      <!--                        id="booster-file"-->
      <!--                        @change="on_change_view_vaccine_brand"-->
      <!--                        class="form-control-file"/>-->
      <!--                    </div>-->

      <!--                    <div class="col-md-4" v-if="showBoosterBrand">-->
      <!--                      <select class="form-control" aria-label="Default select example"-->
      <!--                              v-model="covidVaccine.vaccineBrandId">-->
      <!--                        <option :value="null">&#45;&#45;select&#45;&#45;</option>-->
      <!--                        <option :value="vaccineBrands.id" v-for="(vaccineBrands,index) in objCovidVaccineBrands">-->
      <!--                          {{ vaccineBrands.name }}-->
      <!--                        </option>-->
      <!--                      </select>-->
      <!--                    </div>-->
      <!--                  </div>-->


      <!--                  <div class=" table-responsive text-small mb-3 mt-5 addScroll">-->
      <!--                    <table class="table table-hover table-bordered table-template-1 text-small sticky-header">-->
      <!--                      <thead>-->
      <!--                      <tr>-->
      <!--                        <th class="col-type-width" style="text-align: center;">Attachment</th>-->
      <!--                        <th style="text-align: center;">Brand Name</th>-->
      <!--                        <th style="text-align: center;">File</th>-->
      <!--                        <th v-if="isEditable"></th>-->
      <!--                      </tr>-->
      <!--                      </thead>-->

      <!--                      <tbody>-->
      <!--                      &lt;!&ndash;                  v-if="covidVaccine.vaccineBooster "&ndash;&gt;-->
      <!--                      <template v-if="covidVaccine">-->
      <!--                        <tr v-for="(booster, index) in covidVaccine.vaccineBooster" :key="index">-->
      <!--                          <td style="text-align: center;">-->
      <!--                            <span class="bg-light-danger font-weight-bolder">Booster File {{ index + 1 }}</span>-->
      <!--                          </td>-->
      <!--                          <td style="text-align: center;">-->
      <!--                            <span class="bg-light-danger font-weight-bolder">{{ booster.vaccine_name }}</span>-->
      <!--                          </td>-->
      <!--                          <td style="text-align: center;">-->
      <!--                            <a type="button" class="text-primary"-->
      <!--                               title="View Attachment"-->
      <!--                               @click="loadBoosterAttachmentFile(booster.crew_id, booster.covid_vaccine_id, booster.id)"-->
      <!--                            >View File</a>-->
      <!--                          </td>-->
      <!--                          <td style="text-align: center;" v-if="isEditable">-->
      <!--                            <button type="button"-->
      <!--                                    class="btn btn-outline-danger btn-sm btn-xs font-weight-bold"-->
      <!--                                    :disabled="!isEditable"-->
      <!--                                    @click="delete_booster(booster.crew_id, booster.covid_vaccine_id, booster.id)"-->
      <!--                            >-->
      <!--                              &lt;!&ndash;                    <i class="fas fa-minus"></i>&ndash;&gt;-->
      <!--                              ✖-->
      <!--                            </button>-->
      <!--                          </td>-->
      <!--                        </tr>-->
      <!--                      </template>-->

      <!--                      &lt;!&ndash;v-if="covidVaccine.vaccineBooster ? (covidVaccine.vaccineBooster.length == 0) : '' "&ndash;&gt;-->
      <!--                      <template v-if="covidVaccine">-->
      <!--                        <tr v-if="covidVaccine.vaccineBooster ? (covidVaccine.vaccineBooster.length == 0) : '' ">-->
      <!--                          <td colspan="100%" class="bg-light-danger font-weight-bolder">-->
      <!--                            <p class="text-center mt-3">No attachments found</p>-->
      <!--                          </td>-->
      <!--                        </tr>-->
      <!--                      </template>-->

      <!--                      </tbody>-->
      <!--                    </table>-->

      <!--                  </div>-->

      <!--                </template>-->
      <!--              </template>-->

      <!--            </div>-->


      <!--            <div class="col-md-6 border-left">-->
      <!--              <div class="prev-container">-->
      <!--                <div v-if="!is10mbUp" style="height: 550px;">-->
      <!--                  <div class="center-alignment">-->
      <!--                    <span v-if="!thisCovidVaccineBlob">-->
      <!--                      <font-awesome-icon icon="file" class="small"/>-->
      <!--                    </span>-->
      <!--                  </div>-->

      <!--                  <div v-if="thisCovidVaccineBlob">-->
      <!--                    <object style="height: 550px;" width="100%" height="100%"-->
      <!--                            :data="thisCovidVaccineBlob+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=50,10,0'"-->
      <!--                            type="application/pdf"></object>-->
      <!--                  </div>-->
      <!--                </div>-->

      <!--                &lt;!&ndash;  if 10 up todo  &ndash;&gt;-->
      <!--                <div v-if="is10mbUp" style="height: 400px;">-->
      <!--                  <div class="center-alignment">-->
      <!--                    <button class="btn btn-xs btn-outline-danger" @click="preiview_file_document()">-->
      <!--                      <font-awesome-icon icon="file"/>-->
      <!--                      <small>document</small>-->
      <!--                    </button>-->
      <!--                    <p class="small ml-1">click to preview file more than 3mb.</p>-->
      <!--                  </div>-->
      <!--                </div>-->

      <!--              </div>-->
      <!--            </div>-->


      <!--          </div>-->
      <!--        </template>-->

      <!--        <template v-slot:custom-footer>-->
      <!--          <button v-if="!isEditable" class="btn btn-sm pmc-bg-warning" @click="set_edit">-->
      <!--            <font-awesome-icon icon="edit"/>-->
      <!--            Edit-->
      <!--          </button>-->

      <!--          <button v-if="!isEditable" class="btn btn-sm pmc-btn-second" @click="close_view_vaccine_modal">-->
      <!--            <font-awesome-icon icon="times"/>-->
      <!--            Close-->
      <!--          </button>-->

      <!--          <button v-if="isEditable" class="btn btn-sm pmc-btn-tertia" @click="update">-->
      <!--            <font-awesome-icon icon="save"/>-->
      <!--            Save-->
      <!--          </button>-->

      <!--          <button v-if="isEditable" class="btn btn-sm pmc-btn-danger" @click="set_cancel">-->
      <!--            <font-awesome-icon icon="undo"/>-->
      <!--            Cancel-->
      <!--          </button>-->

      <!--        </template>-->
      <!--      </pmc-modal>-->

      <!--      <pmc-modal>-->

      <!--      </pmc-modal>-->


    </div>
  </div>
</template>

<script>
import {AlertMixins} from '../../../../../../mixins/alert.mixins'
import {AppMixins} from '../../../../../../mixins/app.mixins'
import {mapActions, mapGetters} from 'vuex'
import router from '../../../../../../router'
import {AlertService} from "@/service/alert.service";

export default {
  name: 'CovidVaccine',

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {

      showBoosterBrand: false,

      crewId: parseInt(this.$route.params.crewId, 10),
      isEditable: false,
      isDocReplace: false,
      vaccines: [
        {
          id: 1,
          name: 'vac1',
        },
        {
          id: 2,
          name: 'vac2',
        },
      ],
      newCovidVaccineModal: 'newCovidVaccineModal',
      viewCovidVaccineModal: 'viewCovidVaccineModal',

      vaccineParam: {
        isBoosterShot: null,
        crewId: parseInt(this.$route.params.crewId, 10),
      },


    }
  },

  /** methods**/
  methods: {

    ...mapActions(
        [
          'set_covid_vaccine',
          'set_covid_vaccines',
          'update_covid_vaccine',
          'null_covid_vaccines',
          'null_covid_vaccine',
          'set_form_covid_vaccine_blob',
          'set_form_covid_vaccine_blob_get3mb',

          'null_covid_vaccine_blob',
          'null_covid_vaccine_blob_gte3mb',
          'null_form_covid_vaccine_blob',
          'null_form_covid_vaccine_blob_get3mb',

          'set_is_2mbUp',  //use from crewDocument.module.
          'create_covid_vaccine',
          'delete_covid_vaccine',
          'viewCrew',
          'get_booster_file_attachment',
          'delete_booster_file_attachment',
          'delete_covid_vaccine_file_attachment',
        ],
    ),

    vaccine_lang({name}) {
      return name
    },

    close_new_vaccine_modal() {
      this.newCovidVaccine.isBooster = false;

      this.hideModal(this.newCovidVaccineModal)
      $(`#new-vaccine-file`).val('')
      this.reset_form_file()
      document.getElementById('new-vaccine-file').value = null;
      // document.getElementById('new-booster-file').value = null;
    },

    close_view_vaccine_modal() {
      this.hideModal(this.viewCovidVaccineModal)
      $(`#vaccine-file`).val('')
      this.reset_form_file()
    },

    preiview_file_document() {
      let __fileParam = this.thisCovidVaccineBlob_gte3mb
      this.base64ToBlob(__fileParam)
    },

    //save upload the file
    async save() {

      let file = document.getElementById('new-vaccine-file').files[0]
      const formData = new FormData()
      formData.append('file', file ? file : null)
      formData.append('vaccineId', this.newCovidVaccine.vaccineId)
      formData.append('crewId', this.crewId)
      formData.append('inoculateDate', this.newCovidVaccine.inoculateDate)
      formData.append('completionDate', this.newCovidVaccine.completionDate)
      formData.append('remarks', this.newCovidVaccine.remarks)
      formData.append('isBooster', this.newCovidVaccine.isBooster === true ? 1 : 0)


      let saveVaccine = await this.create_covid_vaccine(formData);
      if (saveVaccine) {
        this.close_new_vaccine_modal()
        this.set_covid_vaccines(this.vaccineParam)
      }


      // this.create_covid_vaccine(formData).then((r) => {
      //   if (r) {
      //     this.close_new_vaccine_modal()
      //     this.set_covid_vaccines({crewId: this.crewId})
      //   }
      // })

    },

    async update() {
      if (await this.questionAlert('Do you want to save the changes you made.', 'Update vaccine details', 'question')) {
        let file = document.getElementById('vaccine-file').files[0]
        // let boosterFile = document.getElementById('booster-file').files[0]
        const formData = new FormData()
        formData.append('file', file ? file : null)
        // formData.append('boosterFile', boosterFile ? boosterFile : null)
        formData.append('id', this.covidVaccine.id)
        formData.append('vaccineId', this.covidVaccine.vaccineId)
        formData.append('crewId', this.covidVaccine.crewId)
        formData.append('inoculateDate', this.covidVaccine.inoculateDate)
        formData.append('completionDate', this.covidVaccine.completionDate)
        formData.append('remarks', this.covidVaccine.remarks)
        formData.append('isBooster', this.covidVaccine.isBooster === true ? 1 : 0)

        this.update_covid_vaccine(formData).then((r) => {
          if (r) {
            this.successAlert('Update vaccine details successfully', 'Update vaccine details')
            this.set_covid_vaccines(this.vaccineParam)
            this.close_view_vaccine_modal()
            document.addEventListener('DOMContentLoaded', function () {
              document.getElementById('booster-file').value = '';
              document.getElementById('vaccine-file').value = '';
            });

          }
        })

      }

    },

    delete_vaccine(__id) {
      this.delete_covid_vaccine(__id).then((r) => {
        if (r) {
          this.set_covid_vaccines({crewId: this.crewId})
          this.viewCrew(this.crewId)
        }
      })
    },

    async delete_booster(crewId, covidVaccineId, boosterAttachmentId) {
      let params = {
        crewId: crewId,
        covidVaccineId: covidVaccineId,
        boosterAttachmentId: boosterAttachmentId,
      }

      let deletedBooster = await this.delete_booster_file_attachment(params);
      if (deletedBooster) {
        this.close_view_vaccine_modal()
        document.getElementById('booster-file').value = null;
        document.getElementById('vaccine-file').value = null;
      }

    },

    async delete_covid_vaccine_file(covidVaccineId) {
      let deletedVaccineFile = await this.delete_covid_vaccine_file_attachment(covidVaccineId);
      if (deletedVaccineFile) {
        this.close_view_vaccine_modal()
        document.getElementById('vaccine-file').value = null;
      }
    },

    on_change_new_vaccine_file(e) {
      let blobFile = e.target.files[0]
      const reader = new FileReader()
      if (blobFile != (null || undefined)) {
        reader.readAsDataURL(blobFile)
        reader.onload = async e => {
          let param = e.target.result
          if (this.gte2mb(blobFile)) {
            this.set_is_2mbUp(true)
            this.set_form_covid_vaccine_blob_get3mb(param)
            let msg = 'It seem\'s like you want to upload a file more than 3mb. \n' +
                'Do you want to preview. ?'

            if (await this.questionAlert(`${msg}`, 'File Preview', 'warning')) {
              this.base64ToBlob(param)
            }

          } else {
            this.set_is_2mbUp(false)
            this.set_form_covid_vaccine_blob(param)

          }
        }
      } else {
        this.reset_form_file()

      }
    },

    on_change_view_vaccine_file(e) {
      let blobFile = e.target.files[0]
      const reader = new FileReader()
      if (blobFile != null || undefined) {
        reader.readAsDataURL(blobFile)
        reader.onload = async e => {
          let param = e.target.result
          if (this.gte2mb(blobFile)) {
            this.set_is_2mbUp(true)
            this.set_form_covid_vaccine_blob_get3mb(param)
            let msg = 'It seem\'s like you want to upload a file more than 3mb. \n' + 'Do you want to preview. ?'
            if (await this.questionAlert(`${msg}`, 'File Preview', 'warning')) {
              this.base64ToBlob(param)
            }

          } else {
            this.set_is_2mbUp(false)
            this.set_form_covid_vaccine_blob(param)
          }
        }
      }
    },

    on_change_file() {
      if (!this.isDocReplace) {
        $(`#vaccine-file`).val('')
      }
    },

    reset_form_file() {
      this.set_is_2mbUp(false)
      this.null_covid_vaccine_blob()
      this.null_covid_vaccine_blob_gte3mb()
      this.null_form_covid_vaccine_blob()
      this.null_form_covid_vaccine_blob_get3mb()
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel() {
      this.$nextTick(() => {
        this.isEditable = false
        this.isDocReplace = false
        $(`#vaccine-file`).val('')
        $(`#booster-file`).val('')
        this.set_covid_vaccine(this.covidVaccine.id)
        this.null_form_covid_vaccine_blob()
        this.null_form_covid_vaccine_blob_get3mb()
        this.showBoosterBrand = false
      })
    },

    async loadBoosterAttachmentFile(crewId, covidVaccineId, boosterAttachmentId) {
      let params = {
        crewId: crewId,
        covidVaccineId: covidVaccineId,
        boosterAttachmentId: boosterAttachmentId,
      }
      let boosterAttachmentFile = await this.get_booster_file_attachment(params);
      if (boosterAttachmentFile) {
        this.pdfViewer(boosterAttachmentId, boosterAttachmentFile);
      }
    },

    on_change_view_vaccine_brand() {
      this.showBoosterBrand = true
      // let boosterFile = document.getElementById('booster-file').files[0]
      //
      // if (document.getElementById("booster-file").files[0]) {
      //   // this.boosterFileInput = true;
      //   this.isEditable = true
      // }

    },

    closeModal() {
      $('#viewCovidVaccineModal').modal('hide');
    },

    on_change_vaccine(e) {
      this.vaccineParam.isBoosterShot = e.target.value;
      this.vaccineParam.crewId = this.crewId;
      this.set_covid_vaccines(this.vaccineParam)
    },

  },

  /**  computed  **/
  computed: {

    ...mapGetters(
        [
          'newCovidVaccine',
          'covidVaccines',
          'covidVaccine',
          'covidVaccineBlob',
          'covidVaccineBlob_gte3mb',
          'formCovidVaccineBlob',
          'formCovidVaccineBlob_gte3mb',
          'is10mbUp',  //use from crewDocument.module.
          'objCovidVaccineBrands',

          'isExistsVaccine',

        ],
    ),

    newCovidVaccineId: {
      get() {
        let obj = this.objCovidVaccineBrands.find((vac) => vac.id === this.newCovidVaccine.vaccineId)
        return obj
      },
      set(val) {
        this.newCovidVaccine.vaccineId = val ? val.id : null
      },
    },

    covidVaccineId: {
      get() {
        let obj = null
        if (this.covidVaccine != null) {
          obj = this.objCovidVaccineBrands.find((vac) => vac.id === this.covidVaccine.vaccineId)
        }
        return obj
      },
      set(val) {
        this.covidVaccine.vaccineId = val ? val.id : null
      },
    },

    thisCovidVaccineBlob() {
      return this.formCovidVaccineBlob ? this.formCovidVaccineBlob : this.covidVaccineBlob
    },

    thisCovidVaccineBlob_gte3mb() {
      return this.formCovidVaccineBlob_gte3mb ? this.formCovidVaccineBlob_gte3mb : this.covidVaccineBlob_gte3mb
    },
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  created() {
    this.set_covid_vaccines(this.vaccineParam);
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.null_covid_vaccine()
  },

}
</script>

<style scoped>

.addScroll {
  overflow-y: auto;
  height: 150px;
}

input[type=checkbox] {
  transform: scale(1);
}

.xl-width {
  max-width: 1280px !important;
}

.xl-height {
  height: 750px !important;
}


</style>
