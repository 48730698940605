import axios from 'axios';
import {PMC} from '../../../config/constants.config';
import {UtilityService} from '../../../service/utility.service';

const state = {
  crewEducations: [],
  crewEducation: {},
  newCrewEducation: {
    levelId: null,
    courseId: null,
    schoolId: null,
    yearS: null,
    yearG: null,
  },
};

/** mutations **/
const mutations = {
  SET_CREW_EDUCATIONS(state, params) {
    state.crewEducations = params;
  },

  SET_CREW_EDUCATION(state, payload) {
    state.crewEducation = payload;
  },

  SET_NEW_CREW_EDUCATION(state, param) {
    state.crewEducations.push(param);
    state.newCrewEducation = {
      levelId: null,
      courseId: null,
      schoolId: null,
      yearG: null,
      yearS: null,
    };
  },

  SET_UPDATE_CREW_EDUCATION(state, params) {
    let crewEduc = state.crewEducations.find(
        (crewEduc, index) => crewEduc.id == params.id);
    Object.assign(crewEduc, params);
  },

  SET_DELETE_CREW_EDUCATION(state, params) {
    let index = state.crewEducations.findIndex(
        (crewEducation, index) => crewEducation.id === params.id);
    state.crewEducations.splice(index, 1);
  },

  SET_NULL_CREW_EDUCATIONS(state) {
    state.crewEducations = [];
  },

  SET_NULL_CREW_EDUCATION(state) {
    state.crewEducation = {};
  },

  SET_NULL_NEW_CREW_EDUC(state) {
    state.newCrewEducation = {
      levelId: null,
      courseId: null,
      schoolId: null,
      yearS: null,
      yearG: null,
    };
  },
};

/** actions **/
const actions = {

  /** crew education endpoint here **/
  //list
  async viewEducations({commit}, param) {
    commit('set_is_loading', true);
    return await axios.get(`${PMC.GET_CREW_EDUCATIONS}/${param}`).then((res) => {
      commit('SET_CREW_EDUCATIONS', res.data);
      commit('set_is_loading',false);
      return true;
    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'View Education');
      return false;
    });
  },

  saveCrewEducation({commit}, params) {
    return axios.post(`${PMC.CREW_EDUCATION}`, params).then((res) => {
      commit('SET_NEW_CREW_EDUCATION', res.data);
      return true;

    }).catch((error) => {

      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,
          'Crew education new record');
      return false;
    });
  },

  viewCrewEducation({commit}, params) {
    return axios.get(`${PMC.CREW_EDUCATION}/${params}`).then((res) => {
      commit('SET_CREW_EDUCATION', res.data);
      return true;

    }).catch((error) => {

      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Crew Education');
      return false;

    });
  },

  updateCrewEducation({commit}, params) {
    return axios.put(`${PMC.CREW_EDUCATION}/${params.id}`, params).then((res) => {
      commit('SET_UPDATE_CREW_EDUCATION', res.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      // UtilityService.generateResponseMessage(error,'Update Crew Educaiton');
      return false;
    });
  },

  deleteCrewEducation({commit}, param) {
    return axios.delete(`${PMC.CREW_EDUCATION}/${param}`).then((response) => {
      commit('SET_DELETE_CREW_EDUCATION', response.data);
      return true;

    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'Del Crew Education');
      return false;

    });
  },

  setNullCrewEducation({commit}) {
    commit('SET_NULL_CREW_EDUCATION');
  },

  setNullCrewEducations({commit}) {
    commit('SET_NULL_CREW_EDUCATIONS');
  },

  setNullNewCrewEduc({commit}) {
    commit('SET_NULL_NEW_CREW_EDUC');
  },
};
/** getters **/
const getters = {
  crewEducations: state => state.crewEducations,
  crewEducation: state => UtilityService.capitalizeProperties(state.crewEducation),
  newCrewEducation: state => state.newCrewEducation,
};

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
};
