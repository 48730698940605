var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":_vm.id,"name":_vm.name,"tabindex":"-1","role":"dialog","aria-labelledby":_vm.id+'CenterTitle',"aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",class:{
       's-width'  : _vm.s_width,
       'm-width'  : _vm.m_width,
       'lg-width' : _vm.lg_width,
       'xl-width' : _vm.xl_width,
       'xxl-width' : _vm.xxl_width
    },attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content",class:{
      'm-height' : _vm.m_height,
      'lg-height' : _vm.lg_height,
      'xl-height' : _vm.xl_height,
      'xxl-height' : _vm.xxl_height,
    }},[_c('div',{staticClass:"modal-header"},[_vm._t("custom-header"),(_vm.showHeaderButtonClose)?_c('button',{staticClass:"close btn btn-xs",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.thisCloseEvent}},[_vm._m(0)]):_vm._e()],2),_c('div',{staticClass:"modal-body"},[_vm._t("custom-body")],2),_c('div',{staticClass:"modal-footer"},[_vm._t("custom-footer")],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{attrs:{"aria-hidden":"true"}},[_c('h5',[_vm._v("×")])])
}]

export { render, staticRenderFns }