import axios from 'axios'
import {PMC} from '../../../config/constants.config'
import {UtilityService} from '../../../service/utility.service'

// import { AlertService } from '../../../service/alert.service'

const state = {
  objRanks: [],
  objRankTypes: [],
  objRankLevels: [],
  objCrewTypes: [],
  objScholarTypes: [],
  objScholarProps: [],
  objGenders: [],
  objCivilStatus: [],
  objReligions: [],
  objNationalities: [],

  objRegions: [],
  objProvince: [],
  objCity: [],
  objBarangay: [],

  objEducationLevels: [],
  objSchools: [],
  objCourses: [],

  objFamTypes: [],

  objDocs: [],
  objAllDocs: [],
  objDocTypes: [],
  objDocCategory: [],

  objClinics: [],
  objMedicalIssues: [],
  objChangeCodes: [],

  objApplicantTypes: [],
  objApplicantStatuses: [],
  objEndorsementTypes: [],
  objPmcOffices: [],

  objDepartments: [],
  objPositions: [],
  objCompanies: [],

  objCovidVaccineBrands: [],

  isLoading: false,

  objVesselTypes: [],
  objFlags: [],
  objOwners: [],
  objPrincipals: [],
  objMannings: [],
  objVesselEngineTypes: [],
  objBeneficiary: [],
  objTradeRoutes: [],
  objUnion: [],
  objEngineTypes: [],
  objVesselClassification: [],

  objGearSuppliers: [],
  objGears: [],
  objGearTypes: [],
  objTowelUsages: [],

  objAllRanks: [],
  objDeckRanks: [],
  objEngineRanks: [],
  objRadioRanks: [],
  objStewardRanks: [],

  objEmployees: [],

  objSignatoryTypes: [],
  objChecklistCategories: [],
  objChecklistTypes: [],

}

const mutations = {
  /** start set mutations  **/
  set_obj_ranks(state, params) {
    state.objRanks = params
  },

  set_obj_rankTypes(state, params) {
    state.objRankTypes = params
  },

  set_obj_rankLevels(state, params) {
    state.objRankLevels = params
  },

  set_obj_crewTypes(state, params) {
    state.objCrewTypes = params
  },

  set_obj_scholarTypes(state, params) {
    state.objScholarTypes = params
  },

  set_obj_scholarProps(state, params) {
    state.objScholarProps = params
  },

  set_obj_genders(state, params) {
    state.objGenders = params
  },

  set_obj_civilStatus(state, params) {
    state.objCivilStatus = params
  },

  set_obj_religions(state, params) {
    state.objReligions = params
  },

  set_obj_nationalities(state, params) {
    state.objNationalities = params
  },

  set_obj_regions(state, params) {
    state.objRegions = params
  },

  set_obj_province(state, params) {
    state.objProvince = params
  },

  set_obj_cities(state, params) {
    state.objCity = params
  },

  set_obj_barangay(state, params) {
    state.objBarangay = params
  },

  set_obj_education_levels(state, params) {
    state.objEducationLevels = params
  },

  set_obj_schools(state, params) {
    state.objSchools = params
  },

  set_obj_courses(state, params) {
    state.objCourses = params
  },

  set_obj_famTypes(state, param) {
    state.objFamTypes = param
  },

  set_obj_docs(state, params) {
    state.objDocs = params
  },

  set_obj_all_docs(state, params) {
    state.objAllDocs = params
  },

  set_obj_clinics(state, params) {
    state.objClinics = params
  },

  set_obj_medical_issues(state, params) {
    state.objMedicalIssues = params
  },

  set_obj_change_codes(state, params) {
    state.objChangeCodes = params
  },

  set_obj_applicant_types(state, params) {
    state.objApplicantTypes = params
  },

  set_obj_applicant_statuses(state, params) {
    state.objApplicantStatuses = params
  },

  set_obj_endorsement_types(state, params) {
    state.objEndorsementTypes = params
  },

  set_obj_pmc_offices(state, params) {
    state.objPmcOffices = params
  },

  set_obj_departments(state, params) {
    state.objDepartments = params
  },

  set_obj_positions(state, params) {
    state.objPositions = params
  },

  set_obj_companies(state, params) {
    state.objCompanies = params
  },

  set_obj_doctypes(state, params) {
    state.objDocTypes = params
  },

  set_obj_docCategory(state, params) {
    state.objDocCategory = params;
  },

  set_obj_covid_vaccines(state, payload) {
    state.objCovidVaccineBrands = payload;
  },

  set_is_loading(state, payload) {
    state.isLoading = payload
  },

  set_obj_vessel_types(state, payload) {
    state.objVesselTypes = payload;
  },

  set_obj_flags(state, payload) {
    state.objFlags = payload;
  },

  set_obj_owner(state, payload) {
    state.objOwners = payload
  },

  set_obj_principal(state, payload) {
    state.objPrincipals = payload
  },

  set_obj_engine_types(state, payload) {
    state.objEngineTypes = payload
  },

  set_obj_vessel_classification(state, payload) {
    state.objVesselClassification = payload
  },

  set_obj_manning(state, payload) {
    state.objMannings = payload
  },

  set_obj_engineType(state, payload) {
    state.objVesselEngineTypes = payload
  },

  set_obj_beneficiary(state, payload) {
    state.objBeneficiary = payload;
  },

  set_obj_trade_route(state, payload) {
    state.objTradeRoutes = payload
  },

  set_obj_union(state, payload) {
    state.objUnion = payload
  },

  set_obj_gearSuppliers(state, payload) {
    state.objGearSuppliers = payload
  },

  set_obj_gears(state, payload) {
    state.objGears = payload
  },

  set_obj_gearTypes(state, payload) {
    state.objGearTypes = payload
  },

  set_obj_allRanks(state, payload) {
    state.objAllRanks = payload
  },

  set_obj_deckRanks(state, payload) {
    state.objDeckRanks = payload
  },

  set_obj_engineRanks(state, payload) {
    state.objEngineRanks = payload
  },

  set_obj_radioRanks(state, payload) {
    state.objRadioRanks = payload
  },

  set_obj_stewardRanks(state, payload) {
    state.objStewardRanks = payload
  },

  set_obj_employee(state, payload) {
    state.objEmployees = payload
  },

  set_obj_signatoryTypes(state, payload) {
    state.objSignatoryTypes = payload
  },

  set_obj_checklistCategories(state, payload) {
    state.objChecklistCategories = payload
  },

  set_obj_checklistTypes(state, payload) {
    state.objChecklistTypes = payload
  },

  set_obj_towelUsages(state, payload) {
    state.objTowelUsages = payload
  },


  /** end set mutations  **/

  /** start null mutations  **/
  null_obj_ranks(state) {
    state.objRanks = []
  },

  null_obj_rankTypes(state) {
    state.objRankTypes = []
  },

  null_obj_crewTypes(state) {
    state.objCrewTypes = []
  },

  null_obj_scholarTypes(state) {
    state.objScholarTypes = []
  },

  null_obj_scholarProps(state) {
    state.objScholarProps = []
  },

  null_obj_genders(state) {
    state.objGenders = []
  },

  null_obj_civilStatus(state) {
    state.objCivilStatus = []
  },

  null_obj_religions(state) {
    state.objReligions = []
  },

  null_obj_nationalities(state) {
    state.objNationalities = []
  },

  null_obj_regions(state) {
    state.objRegions = []
  },

  null_obj_province(state) {
    state.objProvince = []
  },

  null_obj_cities(state) {
    state.objCity = []
  },

  null_obj_barangay(state) {
    state.objBarangay = []
  },

  null_obj_education_levels(state) {
    state.objEducationLevels = []
  },

  null_obj_schools(state) {
    state.objSchools = []
  },

  null_obj_courses(state) {
    state.objCourses = []
  },

  null_obj_famTypes(state) {
    state.objFamTypes = []
  },

  null_obj_docs(state) {
    state.objDocs = []
  },

  null_obj_clinics(state) {
    state.objClinics = []
  },

  null_obj_medical_issues(state) {
    state.objMedicalIssues = []
  },

  null_obj_change_codes(state) {
    state.objChangeCodes = []
  },

  null_obj_applicant_types(state) {
    state.objApplicantTypes = []
  },

  null_obj_applicant_statuses(state) {
    state.objApplicantStatuses = []
  },

  null_obj_endorsement_types(state) {
    state.objEndorsementTypes = []
  },

  null_obj_pmc_offices(state) {
    state.objPmcOffices = []
  },

  null_obj_departments(state) {
    state.objDepartments = []
  },

  null_obj_positions(state) {
    state.objPositions = []
  },

  null_obj_companies(state) {
    state.objCompanies = []
  },

  null_obj_doctypes(state) {
    state.objDocTypes = []
  },

  null_obj_docCategory(state) {
    state.objDocCategory = [];
  },

  null_obj_covid_vaccine_brands(state) {
    state.objCovidVaccineBrands = [];
  },

  null_obj_vessel_types(state) {
    state.objVesselTypes = [];
  },

  null_obj_flags(state) {
    state.objFlags = []
  },

  null_obj_owners(state) {
    state.objOwners = []
  },

  null_obj_mannings(state) {
    state.objMannings = []
  },

  null_obj_beneficiary(state) {
    state.objBeneficiary = []
  },

  null_obj_trade_route(state) {
    state.objTradeRoutes = []
  },

  null_obj_union(state) {
    state.objUnion = [];
  },

  null_obj_gearSuppliers(state) {
    state.objGearSuppliers = [];
  },

  null_obj_employee(state) {
    state.objEmployees = [];
  }
}

const actions = {
  /** start of calling endpoint for keys  **/
  async getCrewPersonalObjectKeys({commit}, params) {
    return await axios.get(PMC.GET_CREW_PERSONAL_OBJ_KEYS, {params}).then((response) => {
      commit('set_obj_ranks', response.data.ranks)
      commit('set_obj_rankTypes', response.data.rankTypes)
      commit('set_obj_rankLevels', response.data.rankLevels)
      commit('set_obj_crewTypes', response.data.crewTypes)
      commit('set_obj_scholarTypes', response.data.scholarTypes)
      commit('set_obj_scholarProps', response.data.scholarProps)
      commit('set_obj_genders', response.data.genders)
      commit('set_obj_civilStatus', response.data.civilStatus)
      commit('set_obj_religions', response.data.religions)
      commit('set_obj_nationalities', response.data.nationalities)
      commit('set_obj_change_codes', response.data.changeCodes)
      return true

    }).catch((error) => {
      return false
    })
  },

  async getRegionKeys({commit}) {
    return await axios.get(PMC.GET_REGION_KEYS).then((res) => {
      commit('set_obj_regions', res.data)
      return true
    }).catch((er) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(er, 'Get region keys')
      return false
    })
  },

  getProvinceKeys({commit}, params) {
    return axios.get(PMC.GET_PROVINCE_KEYS, {params}).then((res) => {
      commit('set_obj_province', res.data)
      return true
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Province Keys')
      return false
    })
  },

  getCityKeys({commit}, params) {
    return axios.get(PMC.GET_CITY_KEYS, {params}).then((res) => {
      commit('set_obj_cities', res.data)
      return true
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'City Keys')
      return false
    })
  },

  getBarangayKeys({commit}, params) {
    return axios.get(PMC.GET_BARANGAY_KEYS, {params}).then((res) => {
      commit('set_obj_barangay', res.data)
      return true
    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Barangay Keys')
      return false

    })
  },

  getCrewEducationKeys({commit}) {
    return axios.get(PMC.GET_CREW_EDUCATION_KEYS).then((response) => {
      commit('set_obj_education_levels', response.data.educationLevels)
      commit('set_obj_schools', response.data.schools)
      commit('set_obj_courses', response.data.courses)
      return true

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Crew Education Keys')
      return false

    })
  },

  getCrewFamilyKeys({commit}) {
    return axios.get(PMC.GET_CREW_FAMILY_KEYS).then((response) => {
      commit('set_obj_genders', response.data.genders)
      commit('set_obj_famTypes', response.data.familyTypes)
      return true
    }).catch((error) => {
      return false
    })
  },

  async getDocumentKeys({commit}, params) {
    return await axios.get(PMC.GET_DOCUMENT_KEYS, {params}).then((res) => {
      commit('set_obj_docs', res.data)
      return true
    }).catch((error) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(error)
      return false
    })
  },

  async getRankKeys({commit}, params) {
    return await axios.get(PMC.GET_RANK_KEYS, {params}).then((res) => {
      commit('set_obj_ranks', res.data)
      return true

    }).catch((error) => {
      return false

    })
  },

  getRankTypeKeys({commit}) {
    return axios.get(PMC.GET_RANK_TYPE_KEYS).then((r) => {
      commit('set_obj_rankTypes', r.data)

    }).catch((error) => {
      return false

    })
  },

  getCrewMedicalKeys({commit}) {
    return axios.get(PMC.GET_CREW_MEDICAL_KEYS).then((res) => {
      commit('set_obj_clinics', res.data.clinics)
      commit('set_obj_medical_issues', res.data.medicalIssues)
      return true

    }).catch((er) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(er, 'CrewMedical Keys')
      return false

    })
  },

  getCreateNewCrewKeys({commit}) {
    return axios.get(PMC.GET_CREATE_NEW_CREW_KEYS).then((response) => {
      commit('set_obj_ranks', response.data.ranks)
      commit('set_obj_religions', response.data.religions)
      commit('set_obj_civilStatus', response.data.civilStatuses)
      commit('set_obj_nationalities', response.data.countries)
      commit('set_obj_crewTypes', response.data.crewTypes)
      commit('set_obj_genders', response.data.genders)

      commit('set_obj_scholarTypes', response.data.scholarTypes)
      commit('set_obj_scholarProps', response.data.scholarProps)

      commit('set_obj_applicant_types', response.data.applicantTypes)
      commit('set_obj_applicant_statuses', response.data.applicantStatuses)
      commit('set_obj_endorsement_types', response.data.endorsementTypes)
      commit('set_obj_pmc_offices', response.data.pmcOffices)

      commit('set_obj_change_codes', response.data.changeCodes)

      return true

    }).catch((errors) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(errors, 'Create New Crew Keys')
      return false
    })
  },

  getApplicantTrackingKeys({commit}) {
    return axios.get(PMC.GET_APPLICANT_TRACKING_KEYS).then((r) => {
      commit('set_obj_applicant_types', r.data.applicantTypes)
      commit('set_obj_applicant_statuses', r.data.applicantStatuses)
      commit('set_obj_endorsement_types', r.data.endorsementTypes)
      commit('set_obj_pmc_offices', r.data.pmcOffices)
      return true
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Get Applicanttracking keys')
      return false
    })
  },

  getNewEmployeeKeys({commit}) {
    return axios.get(PMC.GET_NEW_EMPLOYEE_KEYS).then((r) => {
      commit('set_obj_departments', r.data.departments)
      commit('set_obj_genders', r.data.genders)
      commit('set_obj_companies', r.data.companies)
      commit('set_obj_civilStatus', r.data.civilStatuses)
      return true

    }).catch((er) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(er, 'Getting objec keys')
      return false
    })
  },

  getDocumentTypes({commit}) {
    return axios.get(PMC.GET_DOCTYPE_KEYS).then((r) => {
      commit('set_obj_doctypes', r.data)
      return true

    }).catch((er) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(er, 'Get doctype keys')
      return false
    })
  },

  //document setup object keys
  getDocumentSetupKeys({commit}) {
    return axios.get(PMC.GET_DOCUMENT_SET_UP_KEYS).then((r) => {
      commit('set_obj_docCategory', r.data.categories);
      commit('set_obj_doctypes', r.data.documentTypes);
      return true;

    }).catch((er) => {
      return false;

    })
  },

  //vaccine brand keys
  async getVaccineBrandKeys({commit}) {
    return await axios.get(PMC.GET_COVID_VACCINE_BRAND_KEYS).then((r) => {
      commit('set_obj_covid_vaccines', r.data);
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Getting vaccine brand keys')
      return false;

    })
  },

  //gear supplier keys
  async getGearSupplierKeys({commit}) {
    return await axios.get(PMC.GET_GEAR_SUPPLIER_KEYS).then((r) => {
      commit('set_obj_gearSuppliers', r.data);
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Getting gear supplier keys')
      return false;

    })
  },

  //gear supplier keys
  async getGearKeys({commit}, params) {
    return await axios.get(PMC.GET_GEAR_KEYS, {params}).then((r) => {
      commit('set_obj_gears', r.data);
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Getting gear supplier keys')
      return false;

    })
  },

  //gear type keys
  async getGearTypeKeys({commit}, params) {
    return await axios.get(PMC.GET_GEAR_TYPE_KEYS, {params}).then((r) => {
      commit('set_obj_gearTypes', r.data);
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Getting gear type keys')
      return false;

    })
  },

  //gear type keys
  async getTowelUsageKeys({commit}, params) {
    return await axios.get(PMC.GET_TOWEL_USAGE_KEYS, {params}).then((r) => {
      commit('set_obj_towelUsages', r.data);
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Getting towel usage keys')
      return false;

    })
  },

  //get all rank keys
  async getAllRanks({commit}, params) {
    return await axios.get(PMC.GET_ALL_RANK_KEYS, {params}).then((r) => {
      commit('set_obj_allRanks', r.data);
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Getting all rank keys')
      return false;

    })
  },

  //get all deck ranks
  async getDeckRanks({commit}, params) {
    return await axios.get(PMC.GET_DECK_RANK_KEYS, {params}).then((r) => {
      commit('set_obj_deckRanks', r.data);
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Getting deck rank keys')
      return false;

    })
  },

  //get all engine ranks
  async getEngineRanks({commit}, params) {
    return await axios.get(PMC.GET_ENGINE_RANK_KEYS, {params}).then((r) => {
      commit('set_obj_engineRanks', r.data);
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Getting engine rank keys')
      return false;

    })
  },

  //get all radio ranks
  async getRadioRanks({commit}, params) {
    return await axios.get(PMC.GET_RADIO_RANK_KEYS, {params}).then((r) => {
      commit('set_obj_radioRanks', r.data);
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Getting radio rank keys')
      return false;

    })
  },

  //get all steward ranks
  async getStewardRanks({commit}, params) {
    return await axios.get(PMC.GET_STEWARD_RANK_KEYS, {params}).then((r) => {
      commit('set_obj_stewardRanks', r.data);
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Getting steward rank keys')
      return false;

    })
  },

  getEmployeeKeys({commit}, params) {
    return axios.get(PMC.GET_EMPLOYEE_KEYS, {params}).then((r) => {
      commit('set_obj_employee', r.data)
      return true
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Employee Keys')
      return false
    })
  },

  getSignatoryTypeKeys({commit}, params) {
    return axios.get(PMC.GET_SIGNATORY_TYPE_KEYS, {params}).then((r) => {
      commit('set_obj_signatoryTypes', r.data)
      return true
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Signatory Type Keys')
      return false
    })
  },

  getChecklistCategoryKeys({commit}, params) {
    return axios.get(PMC.GET_CHECKLIST_CATEGORY_KEYS, {params}).then((r) => {
      commit('set_obj_checklistCategories', r.data)
      return true
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Checklist Category Keys')
      return false
    })
  },

  getChecklistTypeKeys({commit}, params) {
    return axios.get(PMC.GET_CHECKLIST_TYPE_KEYS, {params}).then((r) => {
      commit('set_obj_checklistTypes', r.data)
      return true
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Checklist Type Keys')
      return false
    })
  },


  getOwnerKeys({commit}, params) {
    return axios.get(PMC.GET_OWNER_KEYS, {params}).then((r) => {
      commit('set_obj_owner', r.data)
      return true
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Owner Keys')
      return false
    })
  },


  //for loading
  set_is_loading({commit}, payload) {
    commit('set_is_loading', payload)
  },
  /** end of calling endpoint for keys  **/

  /** start declaring null action **/

  null_obj_ranks({commit}) {
    commit('null_obj_ranks')
  },

  null_obj_rankTypes({commit}) {
    commit('null_obj_crewTypes')
  },

  null_obj_crewTypes({commit}) {
    commit('null_obj_crewTypes')
  },

  null_obj_scholarTypes({commit}) {
    commit('null_obj_scholarTypes')
  },

  null_obj_scholarProps({commit}) {
    commit('null_obj_scholarProps')
  },

  null_obj_genders({commit}) {
    commit('null_obj_genders')
  },

  null_obj_civilStatus({commit}) {
    commit('null_obj_civilStatus')
  },

  null_obj_religions({commit}) {
    commit('null_obj_religions')
  },

  null_obj_nationalities({commit}) {
    commit('null_obj_nationalities')
  },

  null_obj_regions({commit}) {
    commit('null_obj_regions')
  },

  null_obj_province({commit}) {
    commit('null_obj_province')
  },

  null_obj_cities({commit}) {
    commit('null_obj_cities')
  },

  null_obj_barangay({commit}) {
    commit('null_obj_barangay')
  },

  null_obj_education_level({commit}) {
    commit('null_obj_education_levels')
  },

  null_obj_course({commit}) {
    commit('null_obj_courses')
  },

  null_obj_schools({commit}) {
    commit('null_obj_schools')
  },

  null_obj_famTypes({commit}) {
    commit('null_obj_famTypes')
  },

  null_obj_docs({commit}) {
    commit('null_obj_docs')
  },

  null_obj_clinics({commit}) {
    commit('null_obj_clinics')
  },

  null_obj_medical_issues({commit}) {
    commit('null_obj_medical_issues')
  },

  null_obj_change_codes({commit}) {
    commit('null_obj_change_codes')
  },

  null_obj_applicant_types({commit}) {
    commit('null_obj_applicant_types')
  },

  null_obj_applicant_statuses({commit}) {
    commit('null_obj_applicant_statuses')
  },

  null_obj_endorsement_types({commit}) {
    commit('null_obj_endorsement_types')
  },

  null_obj_pmc_offices({commit}) {
    commit('null_obj_pmc_offices')
  },

  null_obj_departments({commit}) {
    commit('null_obj_departments')
  },

  null_obj_companies({commit}) {
    commit('null_obj_companies')
  },

  null_obj_doctypes({commit}) {
    commit('null_obj_doctypes')
  },

  null_obj_docCategory({commit}) {
    commit('null_obj_docCategory');
  },

  null_obj_covid_vaccine_brands({commit}) {
    commit('null_obj_covid_vaccine_brands');
  },

  null_obj_vessel_types({commit}) {
    commit('null_obj_vessel_types');
  },

  null_obj_flags({commit}) {
    commit('null_obj_flags')
  },

  null_obj_owners({commit}) {
    commit('null_obj_owners')
  },

  null_obj_mannings({commit}) {
    commit('null_obj_mannings')
  },

  null_obj_beneficiary({commit}) {
    commit('null_obj_beneficiary')
  },

  null_obj_trade_route({commit}) {
    commit('null_obj_trade_route')
  },

  null_obj_union({commit}) {
    commit('null_obj_union')
  },

  null_obj_gearSuppliers({commit}) {
    commit('null_obj_gearSuppliers')
  },

  null_obj_employee({commit}) {
    commit('null_obj_employee')
  }

  /** end declaring null action **/
}

const getters = {
  objRanks: state => UtilityService.capitalizeProperties(state.objRanks),
  objRankTypes: state => UtilityService.capitalizeProperties(state.objRankTypes),
  objRankLevels: state => UtilityService.capitalizeProperties(state.objRankLevels),
  objCrewTypes: state => UtilityService.capitalizeProperties(state.objCrewTypes),
  objScholarTypes: state => UtilityService.capitalizeProperties(state.objScholarTypes),
  objScholarProps: state => UtilityService.capitalizeProperties(state.objScholarProps),
  objGenders: state => UtilityService.capitalizeProperties(state.objGenders),
  objCivilStatus: state => UtilityService.capitalizeProperties(state.objCivilStatus),
  objReligions: state => UtilityService.capitalizeProperties(state.objReligions),
  objNationalities: state => UtilityService.capitalizeProperties(state.objNationalities),
  objRegions: state => UtilityService.capitalizeProperties(state.objRegions),
  objProvince: state => UtilityService.capitalizeProperties(state.objProvince),
  objCity: state => UtilityService.capitalizeProperties(state.objCity),
  objBarangay: state => UtilityService.capitalizeProperties(state.objBarangay),

  objEducationLevels: state => state.objEducationLevels,
  objSchools: state => UtilityService.capitalizeProperties(state.objSchools),
  objCourses: state => UtilityService.capitalizeProperties(state.objCourses),
  objFamTypes: state => UtilityService.capitalizeProperties(state.objFamTypes),
  objDocs: state => UtilityService.capitalizeProperties(state.objDocs),
  objAllDocs: state => UtilityService.capitalizeProperties(state.objAllDocs),


  objClinics: state => UtilityService.capitalizeProperties(state.objClinics),
  objMedicalIssues: state => UtilityService.capitalizeProperties(state.objMedicalIssues),
  objChangeCodes: state => UtilityService.capitalizeProperties(state.objChangeCodes),

  objApplicantTypes: state => UtilityService.capitalizeProperties(state.objApplicantTypes),
  objApplicantStatuses: state => UtilityService.capitalizeProperties(state.objApplicantStatuses),
  objEndorsementTypes: state => UtilityService.capitalizeProperties(state.objEndorsementTypes),
  objPmcOffices: state => UtilityService.capitalizeProperties(state.objPmcOffices),
  objDepartments: state => UtilityService.capitalizeProperties(state.objDepartments),
  objPositions: state => UtilityService.capitalizeProperties(state.objPositions),
  objCompanies: state => UtilityService.capitalizeProperties(state.objCompanies),
  objDocTypes: state => UtilityService.capitalizeProperties(state.objDocTypes),
  objDocCategory: state => UtilityService.capitalizeProperties(state.objDocCategory),
  objCovidVaccineBrands: state => UtilityService.capitalizeProperties(state.objCovidVaccineBrands),
  isReloading: state => state.isLoading,

  //todo call this from vessel component.
  objVesselTypes: state => UtilityService.capitalizeProperties(state.objVesselTypes),
  objFlags: state => UtilityService.capitalizeProperties(state.objFlags),
  objOwners: state => UtilityService.capitalizeProperties(state.objOwners),
  objPrincipals: state => UtilityService.capitalizeProperties(state.objPrincipals),
  objMannings: state => UtilityService.capitalizeProperties(state.objMannings),
  objVesselEngineTypes: state => UtilityService.capitalizeProperties(state.objVesselEngineTypes),
  objBeneficiary: state => UtilityService.capitalizeProperties(state.objBeneficiary),
  objTradeRoutes: state => UtilityService.capitalizeProperties(state.objTradeRoutes),
  objUnion: state => UtilityService.capitalizeProperties(state.objUnion),
  objGearSuppliers: state => UtilityService.capitalizeProperties(state.objGearSuppliers),
  objGears: state => UtilityService.capitalizeProperties(state.objGears),
  objGearTypes: state => UtilityService.capitalizeProperties(state.objGearTypes),
  objTowelUsages: state => UtilityService.capitalizeProperties(state.objTowelUsages),
  objEngineTypes: state => UtilityService.capitalizeProperties(state.objEngineTypes),
  objVesselClassification: state => UtilityService.capitalizeProperties(state.objVesselClassification),

  objAllRanks: state => UtilityService.capitalizeProperties(state.objAllRanks),
  objDeckRanks: state => UtilityService.capitalizeProperties(state.objDeckRanks),
  objEngineRanks: state => UtilityService.capitalizeProperties(state.objEngineRanks),
  objRadioRanks: state => UtilityService.capitalizeProperties(state.objRadioRanks),
  objStewardRanks: state => UtilityService.capitalizeProperties(state.objStewardRanks),
  objEmployees: state => UtilityService.capitalizeProperties(state.objEmployees),
  objSignatoryTypes: state => UtilityService.capitalizeProperties(state.objSignatoryTypes),
  objChecklistCategories: state => UtilityService.capitalizeProperties(state.objChecklistCategories),
  objChecklistTypes: state => UtilityService.capitalizeProperties(state.objChecklistTypes),
}

export default {
  state,
  actions,
  mutations,
  getters,
}
