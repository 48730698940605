<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Standby Ratios</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr/>


        <div class="row mt-5">

          <div class="col-md-12">
            <div class="row">
              <div class="col-sm-3 ml-4">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="printRetention" :disabled="isReloading === true">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- table  -->
        <div id="table-section" class="py-3">

          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">


                <!--RETENTION REPORT-->
                <table class="table table-bordered table-sm text-center sticky-header small table-hover">
                  <thead>
                  <tr>
                    <th style="width: 4%;" rowspan="2">#</th>
                    <th style="width: 6%;" rowspan="2">RANK</th>
                    <th style="width: 11%;" rowspan="2">ACTUAL ONBOARD</th>
                    <th style="width: 11%;" rowspan="2">ACTUAL STANDBY WHO ARE NOT RETIRABLE / OVER AGE</th>
                    <th style="width: 15%;" rowspan="2">UNAVAILABLE</th>
                    <th style="width: 11%;" rowspan="2">STANDBY & ONBOARD RETIRABLE / OVER AGE CAP</th>
                    <th style="width: 8%;" rowspan="2">REQUIRED STANDBY</th>
                    <th style="width: 6%;" rowspan="2">STANDBY RATIO</th>
                    <th style="width: 6%;" rowspan="2">SURPLUS / DEFICIT</th>
                    <th style="width: 6%;" rowspan="2">WITH RECOMMENDATION</th>
                  </tr>

                  </thead>

                  <template v-if="isReloading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>

                  <template v-else>
                    <template v-if="standbyRatios ? (standbyRatios.length != 0) : '' ">
                      <tr v-for="(standbyRatio, index) in standbyRatios" :key="standbyRatio.id">
                        <td class="align-middle">{{ index + 1 }}</td>
                        <td class="align-middle">{{ standbyRatio.alias }}</td>

                        <td class="align-middle">{{ standbyRatio.actualOnboard }}</td>
                        <td class="align-middle">
                          <a href="#" class="mb-2 text-primary"
                             @click="openInNewWindow(baseURL + liveServerPrefix + manpowerPoolStandbyNotOverAgeRoute, standbyRatio.rankId)"
                          >
                          <span class="font-weight-bold">
                             {{ standbyRatio.actualStandbyNotOverAge }}
                          </span>
                          </a>
                        </td>
                        <td class="align-middle">-</td>
                        <td class="align-middle" :class="{'bg-danger': standbyRatio.actualStandbyOverAge != 0}">
                          <a href="#" class="mb-2"
                             :class="{'text-white': standbyRatio.actualStandbyOverAge != 0}"
                             @click="openInNewWindow(baseURL + liveServerPrefix + manpowerPoolStandbyOverAgeRoute, standbyRatio.rankId)"
                          >
                          <span class="font-weight-bold">
                             {{ standbyRatio.actualStandbyOverAge }}
                          </span>
                          </a>
                        </td>
                        <td class="align-middle">-</td>

                        <td class="align-middle text-success">{{ standbyRatio.standbyRatio }}</td>
                        <td class="align-middle">-</td>
                        <td class="align-middle"
                            :class="{'bg-success': standbyRatio.hasRecommendation != 0}">

                          <a href="#" class="mb-2"
                             :class="{'text-white': standbyRatio.hasRecommendation != 0}"
                             @click="openInNewWindow(baseURL + liveServerPrefix + promotableCrewListRoute, standbyRatio.rankId)"
                          >
                          <span class="font-weight-bold">
                             {{ standbyRatio.hasRecommendation }}
                          </span>
                          </a>


                        </td>
                      </tr>
                    </template>

                    <template v-else>
                      <tr>
                        <td colspan="100%" class="bg-light-danger font-weight-bolder">
                          <p class="text-center mt-3">No Results</p>
                        </td>
                      </tr>
                    </template>


                  </template>

                </table>

              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

    <!-- modal here.   -->
    <div class="modal fade" id="cc-retention-modal" tabindex="-1" role="dialog" aria-labelledby="cc-retention-modal-label" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl  modal" role="document">
        <div class="modal-content" style="max-height: 80%;">
          <div class="modal-header">
            <div class="col-3 text-left align-middle">
              <h5 class="modal-title" id="cc-retention-modal-title">Standby Ratios</h5>
            </div>

            <div class="col-1 text-right align-middle">
              <button type="button" class="btn btn-light" data-dismiss="modal" aria-label="Close">
                <span class="text-danger" aria-hidden="true">&times;</span>Close
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div id="retention-file-container"></div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {global_variables_config} from "@/config/global-variable.config";
import {mapActions, mapGetters} from "vuex";
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";

export default {
  name: "StandbyRatio",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      baseURL: '',
      manpowerPoolStandbyNotOverAgeRoute: `/h/report/standby-not-overage?view=manpowerPool&filterType=standby&isOverAge=0`,
      manpowerPoolStandbyOverAgeRoute: `/h/report/manpower-pool-standby-onboard?view=manpowerPool&filterType=standby&isOverAge=1`,
      promotableCrewListRoute: `/h/report/promotable-crew-list?view=promotableCrewList`,
      liveServerPrefix: global_variables_config.liveServerPrefix,
    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_standbyRatioList',
      'print_retention_list'
    ]),
    printRetention() {
      $(`#retention-file-container`).empty();
      $(`#cc-retention-modal`).modal({
        backdrop : 'static',
        keyboard : false,
      })
      let loaderTag  = this.loaderTag()
      $(`#retention-file-container`).append(loaderTag);
      this.$nextTick(()=> {
        this.print_retention_list({
          data_from_front : this.standbyRatios
        }).then((r)=>{
          let fScr = this.base64PdfParser(r) //filesource call back
          let fileFrame = `<iframe src="${fScr}" id="retention-file-frame" style="height: 400px; width: 100%;" frameborder="0"></iframe>`;
          $(`#retention-file-container`).empty();
          $(`#retention-file-container`).append(fileFrame);
        }).catch((er)=>{
          $(`#retention-file-container`).empty();
          $(`#retention-file-container`).append(`<p style="color: red;">Error while generating retention list report.</p>`);
        })
      })
    }

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'standbyRatios',
      ]),
  },

  /** created **/
  async created() {
    this.set_standbyRatioList();
    this.baseURL = window.location.origin;
  },


}
</script>

<style scoped>

</style>

