<template>
  <div>
    <nav v-if="!form" class="navbar navbar-expand-lg navbar-light bg-white border-bottom py-0">
      <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <router-link class="nav-link"
                           :to="{name:'Dashboard1'}"
                           :class="{ 'active' : isRouteActive('Dashboard1')}">Home
              </router-link>
            </li>

            <!-- crewing  -->
            <li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle"
                 href="#" id="navbarDropdownCrewChange"
                 data-toggle="dropdown"
                 aria-haspopup="true"
                 aria-expanded="false"
                 :class="{'active' : isRouteActive('Crewing')}">
                Crewing
              </a>

              <div class="dropdown-menu dropdown-menu-left p-0" aria-labelledby="navbarDropdownCrewChange">
                <router-link class="dropdown-item" :to="{name : 'CrewChangePlan'}"
                             :class="{'active' : isRouteActive('CrewChangeView')}">
                  Crew Change Plan
                </router-link>

                <router-link class="dropdown-item" :to="{name : 'CrewChangeManipulation'}"
                             :class="{'active' : isRouteActive('CrewChangeManipulation','Crewing')}">
                  Crew Change Manipulation
                </router-link>

                <router-link class="dropdown-item" :to="{name :'ApplicantTracking'}"
                             :class="{'active' : isRouteActive('ApplicantTracking','Crewing')}">
                  Applicant Tracking
                </router-link>

                <router-link class="dropdown-item" :to="{name :'DocumentChecklist'}" :class="{'active' : isRouteActive('DocumentChecklist','Crewing')}">
                  Document Checklist
                </router-link>

                <router-link class="dropdown-item" :to="{name :'CrewingAdHoc'}" :class="{'active' : isRouteActive('AdHoc','Crewing')}">
                  Ad Hoc
                </router-link>

                <router-link class="dropdown-item" :to="{name :'DirectPromotion'}" :class="{'active' : isRouteActive('DirectPromotion','Crewing')}">
                  Direct Promotion
                </router-link>

              </div>
            </li>

            <!--<li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownPayroll" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Payroll
              </a>
              <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarDropdownPayroll">
                <a class="dropdown-item" href="#">Allotment Info Sheet</a>
                <a class="dropdown-item" href="#">Hiring Fee</a>
                <a class="dropdown-item" href="#">Allotment Payroll</a>
                <a class="dropdown-item" href="#">VLP</a>
                <a class="dropdown-item" href="#">Loan Schedule</a>
                <a class="dropdown-item" href="#">Monitoring</a>
              </div>
            </li>-->

            <li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#"
                 id="navbarDropdownSetup"
                 data-toggle="dropdown"
                 aria-haspopup="true"
                 :class="{'active' : isRouteActive('MasterList')}"
                 aria-expanded="false">
                MasterList
              </a>
              <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarDropdownSetup">
                <router-link class="dropdown-item"
                             :to="{name :'CrewList'}"
                             :class="{'active' : isRouteActive('CrewMasterList')}">
                  Crew list
                </router-link>

                <router-link class="dropdown-item"
                             :to="{name :'VesselList'}"
                             :class="{'active' : isRouteActive('VesselMasterList')}">
                  Vessel list
                </router-link>
              </div>
            </li>

            <!-- setup -->
            <li v-if="setupAccess" class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#"
                 id="navbarDropdownSetup"
                 data-toggle="dropdown"
                 aria-haspopup="true"
                 :class="{'active' : isRouteActive('Setup')}"
                 aria-expanded="false">
                Setup
              </a>
              <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarDropdownSetup">

                <!-- todo -->
                <router-link class="dropdown-item" :to="{name : 'CrewCompliment'}"
                             :class="{'active' : isRouteActive('CrewCompliment','Crewing')}">
                  Crew Salary Matrix
                </router-link>

                <router-link class="dropdown-item"
                             :to="{name : 'EmployeeSetup'}"
                             :class="{'active' : isRouteActive('EmployeeList','ViewEmployee')}">
                  Employees
                </router-link>

                <!-- <a class="dropdown-item" href="#">Schools</a> -->
                <router-link class="dropdown-item"
                             :to="{name : 'SchoolSetup'}"
                             :class="{'active' : isRouteActive('SchoolList')}">
                  Schools
                </router-link>

                <router-link class="dropdown-item"
                             :to="{name : 'CourseSetup'}"
                             :class="{'active' : isRouteActive('CourseList')}">
                  Courses
                </router-link>


                <!-- todo  -->
                <router-link class="dropdown-item"
                             :to="{name : 'DocumentSetup'}"
                             :class="{'active' : isRouteActive('DocumentList')}">
                  Documents
                </router-link>

                <hr v-if="expandChecklistSetup">
                <a class="dropdown-item"
                   data-toggle="collapse"
                   href="#checklistCollapse"
                   role="button"
                   aria-expanded="false"
                   aria-controls="gearsCollapse"
                   @click="expandChecklistSetup = !expandChecklistSetup"
                >
                  Checklist Setup
                  <font-awesome-icon v-if="expandChecklistSetup" icon="caret-down" class="ml-2"
                                     style="transform: scale(1.5)"/>
                  <font-awesome-icon v-else icon="caret-right" class="ml-2" style="transform: scale(1.5)"/>
                </a>
                <div class="collapse" id="checklistCollapse">

                  <router-link class="dropdown-item"
                               :to="{name : 'ChecklistSignatorySetup'}"
                               :class="{'active' : isRouteActive('ChecklistSignatoryList')}">
                   All Signatories
                  </router-link>

                  <router-link class="dropdown-item"
                               :to="{name : 'ChecklistSignatoryTypeSetup'}"
                               :class="{'active' : isRouteActive('ChecklistSignatoryTypeList')}">
                    Signatory Types
                  </router-link>

                  <router-link class="dropdown-item"
                               :to="{name : 'ChecklistCategorySetup'}"
                               :class="{'active' : isRouteActive('ChecklistCategoryList')}">
                    Checklist Categories
                  </router-link>
                <hr>
                </div>
                <hr v-if="expandGearSetup">
                <a class="dropdown-item"
                   data-toggle="collapse"
                   href="#gearsCollapse"
                   role="button"
                   aria-expanded="false"
                   aria-controls="gearsCollapse"
                   @click="expandGearSetup = !expandGearSetup">
                  Gears Setup
                  <font-awesome-icon v-if="expandGearSetup" icon="caret-down" class="ml-2"
                                     style="transform: scale(1.5)"/>
                  <font-awesome-icon v-else icon="caret-right" class="ml-2" style="transform: scale(1.5)"/>
                </a>
                <div class="collapse" id="gearsCollapse">
                  <router-link class="dropdown-item"
                               :to="{name : 'GearSetup'}"
                               :class="{'active' : isRouteActive('GearList')}">
                    All Gears
                  </router-link>

                  <router-link class="dropdown-item"
                               :to="{name : 'GearComplementSetup'}"
                               :class="{'active' : isRouteActive('GearComplementList')}">
                    Gears Complement
                  </router-link>

                  <router-link class="dropdown-item"
                               :to="{name : 'GearSupplierSetup'}"
                               :class="{'active' : isRouteActive('GearSupplierList')}">
                    Gear Suppliers
                  </router-link>

                  <hr>
                </div>

                <hr v-if="expandVesselSetup">
                <a class="dropdown-item"
                   data-toggle="collapse"
                   href="#vesselCollapse"
                   role="button"
                   aria-expanded="false"
                   aria-controls="vesselCollapse"
                   @click="expandVesselSetup = !expandVesselSetup">
                  Vessel Setup
                  <font-awesome-icon v-if="expandVesselSetup" icon="caret-down" class="ml-2"
                                     style="transform: scale(1.5)"/>
                  <font-awesome-icon v-else icon="caret-right" class="ml-2" style="transform: scale(1.5)"/>
                </a>
                <div class="collapse" id="vesselCollapse">
                  <router-link class="dropdown-item"
                               :to="{name : 'VesselClassificationSetup'}"
                               :class="{'active' : isRouteActive('VesselClassificationList')}">
                    Classification
                  </router-link>

                  <router-link class="dropdown-item"
                               :to="{name : 'VesselEngineTypeSetup'}"
                               :class="{'active' : isRouteActive('VesselEngineTypeList')}">
                    Engine Type
                  </router-link>

<!--                  <router-link class="dropdown-item"-->
<!--                               :to="{name : ''}"-->
<!--                               :class="{'active' : isRouteActive('')}">-->
<!--                    Engine Type-->
<!--                  </router-link>-->



                  <hr>
                </div>


                <router-link class="dropdown-item"
                             :to="{name : 'RankSetup'}"
                             :class="{'active' : isRouteActive('RankList')}">
                  Ranks
                </router-link>

                <router-link class="dropdown-item"
                             :to="{name : 'PrincipalSetup'}"
                             :class="{'active' : isRouteActive('PrincipalList')}">
                  Principals
                </router-link>

                <router-link class="dropdown-item"
                             :to="{name : 'FlagSetup'}"
                             :class="{'active' : isRouteActive('FlagList')}">
                  Flags
                </router-link>

                <router-link class="dropdown-item"
                             :to="{name : 'CountrySetup'}"
                             :class="{'active' : isRouteActive('CountryList')}">
                  Countries
                </router-link>


                <router-link class="dropdown-item"
                             :to="{name : 'ClinicSetup'}"
                             :class="{'active' : isRouteActive('ClinicList')}">
                  Clinics
                </router-link>

                <router-link class="dropdown-item"
                             :to="{name : 'TradeRouteSetup'}"
                             :class="{'active' : isRouteActive('TradeRouteList')}">
                  Trade Routes
                </router-link>

                <router-link class="dropdown-item"
                             :to="{name : 'OwnerSetup'}"
                             :class="{'active' : isRouteActive('OwnerList')}">
                  Owners
                </router-link>

                <router-link class="dropdown-item"
                             :to="{name : 'EmployeePositionSetup'}"
                             :class="{'active' : isRouteActive('EmployeePositionList')}">
                  Positions
                </router-link>
                <router-link class="dropdown-item"
                             :to="{name : 'ReligionSetup'}"
                             :class="{'active' : isRouteActive('ReligionList')}">
                  Religions
                </router-link>
                <router-link class="dropdown-item"
                             :to="{name : 'DocumentCategorySetup'}"
                             :class="{'active' : isRouteActive('DocumentCategoryList')}">
                  Document Categories
                </router-link>
                <router-link class="dropdown-item"
                             :to="{name : 'PortSetup'}"
                             :class="{'active' : isRouteActive('PortList')}">
                  Ports
                </router-link>
                <router-link class="dropdown-item"
                             :to="{name : 'UnionSetup'}"
                             :class="{'active' : isRouteActive('UnionList')}">
                  Union
                </router-link>



                <!--                <a class="dropdown-item" href="#">Ports</a>-->
                <!--                <a class="dropdown-item" href="#">ACTG Matrix</a>-->
                <!--                <a class="dropdown-item" href="#">Finance lvl 1</a>-->
                <!--                <a class="dropdown-item" href="#">ACTG Level 1</a>-->
                <!--                <a class="dropdown-item" href="#">ACTG Level 2</a>-->
                <!--                <a class="dropdown-item" href="#">Crew Complement</a>-->
                <!--                <a class="dropdown-item" href="#">OPR Level 1</a>-->
                <!--                <a class="dropdown-item" href="#">OPR Level 2</a>-->
                <!--  -->
              </div>
            </li>

            <!--<li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownExpense" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Expense
              </a>
              <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarDropdownExpense">
                <a class="dropdown-item" href="#">ECRE</a>
                <a class="dropdown-item" href="#">Crew Change Monitoring</a>
              </div>
            </li>-->

            <!--      reports      -->
            <!--            <li class="nav-item dropdown no-arrow">-->
            <!--              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownReports" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
            <!--                Reports-->
            <!--              </a>-->
            <!--              <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarDropdownReports">-->
            <!--                <a class="dropdown-item" href="#">Operations</a>-->
            <!--                <a class="dropdown-item" href="#">PMC/EMMC</a>-->
            <!--                <a class="dropdown-item" href="#">Accounting</a>-->
            <!--                <a class="dropdown-item" href="#">Admin</a>-->
            <!--                <a class="dropdown-item" href="#">Top Management</a>-->
            <!--                <a class="dropdown-item" href="#">Vessels Movement</a>-->
            <!--                <a class="dropdown-item" href="#">Gears PR/PO</a>-->
            <!--              </div>-->
            <!--            </li>-->


            <!--            <li class="nav-item dropdown no-arrow">-->
            <!--              <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
            <!--                Reports-->
            <!--              </a>-->
            <!--              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">-->
            <!--                <li class="dropdown-submenu">-->
            <!--                  <a class="dropdown-item dropdown-toggle" href="#">-->
            <!--                    Operations-->
            <!--                    <font-awesome-icon icon="caret-right" style="margin-left: 10px; font-size: 13px;" color="green"/>-->
            <!--                  </a>-->
            <!--                  <ul class="dropdown-menu">-->
            <!--                    <li><a class="dropdown-item" href="#">Ad-Hoc</a></li>-->
            <!--                    <li><a class="dropdown-item" href="#">Crew</a></li>-->

            <!--                    <li class="dropdown-submenu">-->
            <!--                      <a class="dropdown-item dropdown-toggle" href="#">-->
            <!--                        Pre Departure-->
            <!--                        <font-awesome-icon icon="caret-right" style="margin-left: 10px; font-size: 13px;" color="green"/>-->
            <!--                      </a>-->
            <!--                      <ul class="dropdown-menu">-->
            <!--                        <li><a class="dropdown-item" href="#">Checklist</a></li>-->
            <!--                        <li><a class="dropdown-item" href="#">Docs & Certs</a></li>-->
            <!--                        <li><a class="dropdown-item" href="#">Gears PR/PO</a></li>-->
            <!--                      </ul>-->
            <!--                    </li>-->

            <!--                    <li><a class="dropdown-item" href="#">Vessel</a></li>-->

            <!--                  </ul>-->
            <!--                </li>-->

            <!--                <li><a class="dropdown-item" href="#">PMC/EMMC</a></li>-->
            <!--                <li><a class="dropdown-item" href="#">Accounting</a></li>-->
            <!--                <li><a class="dropdown-item" href="#">Admin</a></li>-->
            <!--                <li><a class="dropdown-item" href="#">Top Management</a></li>-->
            <!--                <li><a class="dropdown-item" href="#">Vessels Movement</a></li>-->

            <!--                <li class="dropdown-submenu">-->
            <!--                  <a class="dropdown-item dropdown-toggle" href="#">Submenu</a>-->
            <!--                  <ul class="dropdown-menu">-->
            <!--                    <li><a class="dropdown-item" href="#">Submenu action</a></li>-->
            <!--                    <li><a class="dropdown-item" href="#">Another submenu action</a></li>-->
            <!--                  </ul>-->
            <!--                </li>-->

            <!--              </ul>-->
            <!--            </li>-->


            <!--            <li class="nav-item dropdown no-arrow">-->
            <!--              <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
            <!--                Operations-->
            <!--              </a>-->
            <!--              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">-->


            <!--                <li><a class="dropdown-item" href="#">Ad-Hoc</a></li>-->
            <!--                <li><a class="dropdown-item" href="#">Crew</a></li>-->

            <!--                <li class="dropdown-submenu">-->
            <!--                  <a class="dropdown-item dropdown-toggle" href="#">-->
            <!--                    Pre-Departure-->
            <!--                    <font-awesome-icon icon="caret-right" style="margin-left: 10px; font-size: 13px;" color="green"/>-->
            <!--                  </a>-->
            <!--                  <ul class="dropdown-menu">-->
            <!--                    <li><a class="dropdown-item" href="#">Checklist</a></li>-->
            <!--                    <li><a class="dropdown-item" href="#">Docs & Certs</a></li>-->
            <!--                    <li><a class="dropdown-item" href="#">Gears PR/PO</a></li>-->
            <!--                  </ul>-->
            <!--                </li>-->

            <!--                <li><a class="dropdown-item" href="#">Vessel</a></li>-->

            <!--                <li><a class="dropdown-item" href="#">Gears PR/PO</a></li>-->

            <!--              </ul>-->
            <!--            </li>-->


            <li class="nav-item dropdown no-arrow">
              <router-link class="nav-link dropdown-toggle"
                           :to="{name : 'ReportList'}"
                           :class="{'active' : isRouteActive('ReportList')}">
                Reports
              </router-link>
            </li>


            <!--<li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownDocuments" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Documents
              </a>
              <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarDropdownDocuments">
                <a class="dropdown-item" href="#">File Manipulation</a>
                <a class="dropdown-item" href="#">Travel Bidding</a>
              </div>
            </li>-->

            <li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownReference" data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                Reference
              </a>
              <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarDropdownReference">
                <a class="dropdown-item" href="#">REFSYS</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {AppMixins} from '../../../mixins/app.mixins'

export default {
  name: 'Menu',
  mixins: [AppMixins],
  data() {
    return {

      expandChecklistSetup: false,
      expandGearSetup: false,
      expandVesselSetup: false,
      setupAccess: false,
    }
  },

  methods: {},

  computed: {
    form() {
      let form = this.$route.query.view;
      return form ? form : null;
    }
  },

  mounted() {
    // $('.dropdown').hover(function() {
    //   $(this).find('.dropdown-menu').stop(true, true).delay(10).fadeIn(100);
    // }, function() {
    //   $(this).find('.dropdown-menu').stop(true, true).delay(10).fadeOut(100);
    // });
    //
    //
    // $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
    //   if (!$(this).next().hasClass('show')) {
    //     $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
    //   }
    //   const $subMenu = $(this).next('.dropdown-menu');
    //   $subMenu.toggleClass('show');
    //
    //
    //   $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
    //     $('.dropdown-submenu .show').removeClass('show');
    //   });
    //
    //   return false;
    // });


    $(".dropdown").hover(
      function () {
        $('>.dropdown-menu', this).stop(true, true).fadeIn("fast");
        $(this).addClass('open');
      },
      function () {
        $('>.dropdown-menu', this).stop(true, true).fadeOut("fast");
        $(this).removeClass('open');
      });
  },

  created() {
    let setupRes = localStorage.getItem('isSetupAccess');
    if(setupRes === 'Y') this.setupAccess = true;
  },

  destroyed() {
  },

}
</script>

<style scoped>

nav .dropdown-item.router-link-active,
nav .dropdown-item.router-link-exact-active {
  background-color: var(--tertia) !important;
  color: var(--light-font) !important;
}

</style>
