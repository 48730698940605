<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Scholars Monitoring List</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

          <!--          &lt;!&ndash; Owner &ndash;&gt;-->
          <!--          <div class="col-sm-5">-->
          <!--            <div class="form-group row">-->
          <!--              <label for="list-count" class="col-sm-3 col-form-label">Owner</label>-->
          <!--              <span class="col-form-label">:</span>-->
          <!--              <div class="col-sm-7 text-left">-->
          <!--                <select class="custom-select custom-select-sm"-->
          <!--                >-->
          <!--                  <option :value="null" class="text-center align-middle">&#45;&#45;SELECT OWNER&#45;&#45;</option>-->
          <!--                  <option :value="owner.id" v-for="(owner,index) in objOwners" :key="owner.id">-->
          <!--                    {{ owner.name }}-->
          <!--                  </option>-->
          <!--                </select>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->


        </div>

        <hr/>


        <div class="row mt-5">

          <div class="col-md-12">
            <div class="row">

              <div class="col-sm-2 ml-4">
                <div class="form-group row">
                  <label for="list-count" class="col-form-label">RANK</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7 text-left">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="selectedFilter.selectedRank"
                            @change="onChangeRank"
                            required>
                      <option :value="null">--SELECT--</option>
                      <option :value="rank.id" v-for="(rank,index) in objRanks" :key="rank.id">
                        {{ rank.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group row">
                  <label for="list-count" class="col-form-label">GROUPS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7 text-left">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="selectedFilter.selectedGroup"
                            @change="onChangeGroupFilter"
                            required>
                      <option :value="null">--SELECT--</option>
                      <option :value="1">Deck</option>
                      <option :value="2">Engine</option>
                      <option :value="3">Radio</option>
                      <option :value="4">Steward</option>
                      <!--                      <option :value="5">Key Officers</option>-->
                      <!--                      <option :value="6">Officers</option>-->
                      <!--                      <option :value="7">Ratings</option>-->
                      <!--                      <option :value="8">Officers-Deck</option>-->
                      <!--                      <option :value="9">Officers-Engine</option>-->
                      <!--                      <option :value="10">Ratings-Deck</option>-->
                      <!--                      <option :value="11">Ratings-Engine</option>-->
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group row">
                  <label for="list-count" class="col-form-label">CREW TYPE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7 text-left">
                    <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                            v-model="selectedFilter.selectedCrewType"
                            @change="onChangeCrewType"
                            required>
                      <option :value="null">--SELECT--</option>
                      <option :value="1">Scholar Monitoring List</option>
                      <option :value="2">Utility Monitoring List</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="loadScholarMonitoringList"
                  >
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
              </div>


            </div>
          </div>


        </div>

        <!--  pagination      -->
        <div class="row py-1 mt-3">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination v-if="selectedFilter.selectedRank !== null || selectedFilter.selectedGroup !== null || selectedFilter.selectedCrewType !== null"
                  :page-object="scholarPagination"
                  @settingPage="set_this_page"
                ></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">

          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">


                <!--MASTERLIST FORMAT-->
                <table class="table table-bordered table-sm text-center sticky-header-docs-format small table-hover">
                  <thead>
                  <tr>
                    <th style="width: 6%;" rowspan="2">#</th>
                    <th style="width: 11%;" rowspan="2">NAME</th>
                    <th style="width: 6%;" rowspan="2">RANK</th>
                    <th style="width: 6%;" rowspan="2">TOB</th>
                    <th style="width: 15%;" rowspan="2">LICENSE</th>
                    <th style="width: 11%;" rowspan="2">Next/Current/Ex-Vessel</th>
                    <th style="width: 8%;" rowspan="2">TYPE</th>
                    <th style="width: 6%;" rowspan="2">AGE</th>
                    <th style="width: 6%;" rowspan="2">YR GRAD</th>
                    <th style="width: 6%;" rowspan="2">SCHOOL</th>
                    <th style="width: 6%;" rowspan="2">STATUS</th>
                    <th style="width: 6%;" rowspan="2">MOS OB/SB</th>
                    <th style="width: 6%;" rowspan="2">RATINGS EXP</th>
                    <th style="width: 6%;" rowspan="2">RL</th>
                    <th style="width: 11%;" rowspan="2">SCHED TO BOARD</th>
                    <th style="width: 11%;" rowspan="2">REMARKS 1</th>
                    <th style="width: 11%;" rowspan="2">REMARKS 2</th>
                  </tr>

                  </thead>

                  <template v-if="isReloading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>

                  <template v-else>
                    <template v-if="scholars && selectedFilter.selectedRank !== null
                    || selectedFilter.selectedGroup !== null
                    || selectedFilter.selectedCrewType !== null
                    ? (scholars.length != 0) : '' ">
                      <tr v-for="(scholar, index) in scholars" :key="scholar.id">
                        <td class="align-middle">{{ index + 1 }}</td>
                        <td class="align-middle">
                          <a href="#"
                             @click.stop="gotoCrewProfileNewWindow(scholar.crewId, 'all-info')"
                             style="color:black;">
                            <small><b>{{ scholar.fullName }} </b></small>
                          </a>
                        </td>

                        <td class="align-middle">{{ scholar.rankAlias }}</td>
                        <td class="align-middle">{{ scholar.tob }}</td>
                        <td class="align-middle">{{ scholar.license }}</td>
                        <td class="align-middle">{{ scholar.nextCurrentExVessel }}</td>
                        <td class="align-middle">{{ scholar.scholarType }}</td>

                        <td class="align-middle">{{ scholar.age }}</td>
                        <td class="align-middle">{{ scholar.yearGraduated }}</td>
                        <td class="align-middle">{{ scholar.school }}</td>
                        <td class="align-middle">{{ scholar.seaServiceStatus }}</td>
                        <td class="align-middle">
                          <span v-if="scholar.seaServiceStatusId == onboardCrew ||
                                      scholar.seaServiceStatusId == onboardCrewLinedUp
                                  ">
                            {{ scholar.monthsOnboard }}
                          </span>
                          <span v-if="scholar.seaServiceStatusId == standbyCrew ||
                                      scholar.seaServiceStatusId == standbyCrewLinedUp
                                  ">
                            {{ scholar.monthsStandby }}
                          </span>
                        </td>
                        <td class="align-middle">{{ scholar.ratingsExp }}</td>
                        <td class="align-middle" :class="scholar.countRL > 1  ? 'recommendation-letter' : ''">
                          <span>{{ `(` + scholar.countRL + `)` + `-` +  (scholar.countRL > 1 ? `YES` : `NO`)  }}</span>
                        </td>
                        <td class="align-middle"
                            data-toggle="modal"
                            data-target="#scholar-remarks"
                            data-backdrop="static"
                            data-keyboard="false"
                            @click="viewScholarMonitoringRemark(scholar.crewId, scholar.scholarRemarkId)"
                        >
                          {{ scholar.schedToBoard }}
<!--                          {{ scholarMonitoringRemark.schedToBoard  }}-->
                        </td>
                        <td class="align-middle"
                            data-toggle="modal"
                            data-target="#scholar-remarks"
                            data-backdrop="static"
                            data-keyboard="false"
                            @click="viewScholarMonitoringRemark(scholar.crewId, scholar.scholarRemarkId)"
                        >
                          {{ scholar.remarks1 }}
                        </td>
                        <td class="align-middle"
                            data-toggle="modal"
                            data-target="#scholar-remarks"
                            data-backdrop="static"
                            data-keyboard="false"
                            @click="viewScholarMonitoringRemark(scholar.crewId, scholar.scholarRemarkId)"
                        >
                          {{ scholar.remarks2 }}
                        </td>

                      </tr>
                    </template>

                    <template v-else>
                      <tr>
                        <td colspan="100%" class="bg-light-danger font-weight-bolder">
                          <p class="text-center mt-3">No Results</p>
                        </td>
                      </tr>
                    </template>


                  </template>

                </table>

              </div>
            </div>
          </div>


        </div>
      </div>
    </div>


    <div class="modal fade" id="scholar-remarks" tabindex="-1" role="dialog" aria-labelledby="documentListModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-left">Remarks
              <button
                type="button"
                @click="updateScholarMonitoringRemark"
                class="btn btn-xs pmc-btn-tertia"
              >
                <font-awesome-icon icon="save"/>
                SAVE
              </button>
            </h5>
            <div class="btn-group">
              <button class="btn btn-sm ml-2" @click="closeScholarRemarksModal()" aria-label="Close">
                <font-awesome-icon icon="times" class="text-danger"/>
                Close
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="table table-responsive-lg">
              <pmc-table :max-height="320"
                         :loader-colspan="10"
                         :is-table-hover="false"
              >
                <template v-slot:table-header>
                  <tr>
                    <th colspan="4" style="width: 45%;font-weight: normal;">Document</th>
                    <th colspan="2"></th>
                  </tr>
                </template>
                <template v-slot:table-body>
                  <tr>
                    <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                      SCHED TO BOARD
                    </td>
                    <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                      <textarea
                        id="new-remarks"
                        rows="3"
                        style="resize: none; width: 100%;"
                        v-model="scholarMonitoringRemark.schedToBoard"
                        class="form-control form-control-sm">
                      </textarea>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                      REMARKS 1
                    </td>
                    <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                      <textarea
                        id="new-remarks"
                        rows="3"
                        style="resize: none; width: 100%;"
                        v-model="scholarMonitoringRemark.remarks1"
                        class="form-control form-control-sm">
                      </textarea>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                      REMARKS 2
                    </td>
                    <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                      <textarea
                        id="new-remarks"
                        rows="3"
                        style="resize: none; width: 100%;"
                        v-model="scholarMonitoringRemark.remarks2"
                        class="form-control form-control-sm">
                      </textarea>
                    </td>
                  </tr>

                </template>
              </pmc-table>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";

export default {
  name: "ScholarMonitoringList",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      onboardCrew: 3,
      onboardCrewLinedUp: 4,
      standbyCrew: 5,
      standbyCrewLinedUp: 6,

      selectedFilter:{
        selectedRank: null,
        selectedGroup: null,
        selectedCrewType: null,
      },


      crewId: null,

      pageLastLeft: 1,

    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_scholarsMonitoringList',
      'set_scholar_monitoring_remark',
      'update_scholar_monitoring_remark',
      'generateScholarMonitoringList',
      'getRankKeys',
    ]),

    onChangeCrewType() {
      this.scholarFormParam.crewType = this.selectedFilter.selectedCrewType;
      this.scholarFormParam.page = 1;
      this.set_scholarsMonitoringList(this.scholarFormParam);
    },

    onChangeRank() {
      this.selectedFilter.selectedGroup = null
      this.scholarFormParam.groupFilterId = null
      this.scholarFormParam.rankId = this.selectedFilter.selectedRank
      this.scholarFormParam.page = 1;
      this.set_scholarsMonitoringList(this.scholarFormParam);
    },

    onChangeGroupFilter() {
      this.selectedFilter.selectedRank = null
      this.scholarFormParam.rankId = null
      this.scholarFormParam.groupFilterId = this.selectedFilter.selectedGroup
      this.scholarFormParam.page = 1;
      this.set_scholarsMonitoringList(this.scholarFormParam);
    },

    set_this_page(e) {
      this.scholarFormParam.searchValue = ''
      this.scholarPagination.currentPage = e
      this.scholarFormParam.page = e
      this.pageLastLeft = e
      this.set_scholarsMonitoringList(this.scholarFormParam)
    },

    async loadScholarMonitoringList() {

      if (this.selectedFilter.selectedRank !== null ||
        this.selectedFilter.selectedGroup !== null ||
        this.selectedFilter.selectedCrewType !== null) {

        AlertService.loadingAlert('Please wait ...', 'Loading Scholar Monitoring List');
        let params = {
          count: this.scholarCount,
          crewType: this.scholarFormParam.crewType,
          rankId: this.scholarFormParam.rankId,
          groupFilterId: this.scholarFormParam.groupFilterId,
        }

        let scholarMonitoringFile = await this.generateScholarMonitoringList(params);
        if (scholarMonitoringFile) {
          Swal.close();
          this.pdfViewerMasterChecklist('Scholars Monitoring List', scholarMonitoringFile);
        } else {
          Swal.close();
        }

      } else {
        AlertService.errorAlertService('Please select field here', '');
      }

    },

    closeScholarRemarksModal() {
      $(`#scholar-remarks`).modal('hide');
    },

    viewScholarMonitoringRemark(crewId, scholarRemarkId){
      this.crewId = crewId
      this.set_scholar_monitoring_remark(crewId)
    },

    async updateScholarMonitoringRemark() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update Scholar Monitoring Remark', 'question');
      if (await __update) {
        let params = {
          id: this.scholarMonitoringRemark.id,
          crewId: this.crewId,
          schedToBoard: this.scholarMonitoringRemark.schedToBoard ? this.scholarMonitoringRemark.schedToBoard : '' ,
          remarks1: this.scholarMonitoringRemark.remarks1 ? this.scholarMonitoringRemark.remarks1 : '',
          remarks2: this.scholarMonitoringRemark.remarks2 ? this.scholarMonitoringRemark.remarks2 : '',
        };

        let updateScholarMonitoringRemark = await this.update_scholar_monitoring_remark(params);
        if (updateScholarMonitoringRemark) {
          AlertService.successAlertService("Updated successfully",'Update Scholar Monitoring Remark');
          this.hideModal('scholar-remarks')
        }
      }
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'scholars',
        'objRanks',
        'scholarPagination',
        'scholarFormParam',
        'scholarCount',
        'scholarMonitoringRemark',
      ]),
  },


  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    this.getRankKeys();
  },

  /** watch **/
  watch: {
  },


}
</script>

<style scoped>

.recommendation-letter {
  background: green;
  color: white;
}

</style>
