<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Summary of Crew Per Principal</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">

                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.rankId"
                                @change="selectReportType(1)"
                                required>
                          <option :value="null">All</option>
                          <option v-for="(rank, index) in ranks" :value="rank.id">{{ rank.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>


                  <div class="col-md-12 mt-1">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">PRINCIPAL</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.principalId"
                                @change="selectReportType(2)"
                                required>
                          <option :value="0">-- select principal --</option>
                          <option v-for="(principal, index) in principals" :value="principal.id">{{ principal.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <div class="col-sm-2">

                <div class="form-group row">
                  <button type="button"
                          @click="generateCrewPrincipal"
                          class="btn btn-xs pmc-btn-tertia">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>

                <div class="form-group row mt-1">
                  <button type="button"
                    @click="printReport"
                    class="btn btn-xs pmc-btn-danger">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>

              </div>
            </div>
            <hr>
            <div class="row ml-2">
              <div class="col-12">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label">FILTER BY RANK : <b class="pl-2 text-size">{{ rankSelected }}</b></label>
                    </div>
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label">FILTER BY PRINCIPAL : <b class="pl-2 text-size text-uppercase">{{ principalSelected }}</b></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 10%;">Seafarer Name</th>
                    <th>Rank</th>
                    <th style="width: 10%;">Seafarer Service Status</th>
                    <th>Crew Type</th>
                    <th>Vessel</th>
                    <th>SIRB</th>
                    <th>SIRB Validity</th>
                    <th>Passport</th>
                    <th>Passport Validity</th>
                    <th>COC Expiry</th>
                    <th>Reg.</th>
                    <th>US Visa Expiry</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, index) in crewPrincipal">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td>
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ crew.seafererName }} </b></small>
                            </a>
                          </td>
                          <td>{{ crew.rank }}</td>
                          <td>{{ crew.seaServiceStatus }}</td>
                          <td>{{ crew.crewType }}</td>
                          <td>{{ crew.vessel }}</td>
                          <td>{{ crew.sirb }}</td>
                          <td>{{ crew.sirbValidity }}</td>
                          <td>{{ crew.passport }}</td>
                          <td>{{ crew.passportValidity }}</td>
                          <td>{{ crew.cocExpiry }}</td>
                          <td>{{ crew.reg }}</td>
                          <td>{{ crew.usVisaExpiry }}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import {FileService} from "@/service/file.service";

export default {
  name: "CrewPerPrincipal",

  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      reportParam: {
        rankId: null,
        principalId: 0,
        isPDF: false,
      },
      ranks: null,
      rankSelected: "All",
      principalSelected: null,
      principals: null,
      crewPrincipal: null,
      hasData: false,
      isLoading: false,
    }
  },


  /** methods**/
  methods: {
    ...mapActions([]),
    selectReportType(id) {
      this.hasData = false;
      if (id == 1) this.rankSelected = this.getRankName(this.reportParam.rankId);
      if(id == 2) this.principalSelected = this.getPrincipalName(this.reportParam.principalId);

    },

    getRankName(rankId) {
      let rankName = "All";
      const rankList = this.ranks;
      for (let r in rankList) {
        if (parseInt(rankList[r].id) === parseInt(rankId)) {
          rankName = rankList[r].name;
        }
      }
      return rankName;
    },

    getPrincipalName(principalId) {
      let principalName = '';
      const principalList = this.principals;
      for(let p in principalList) {
        if(parseInt(principalList[p].id) === parseInt(principalId)) {
          principalName = principalList[p].name;
        }
      }
      return principalName;
    },

    async getRanks() {
      let params = {};
      return await axios.get(PMC.GET_ACTIVE_RANK_LEVEL, params).then(async r => {
        this.ranks = r.data;
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
        return false
      });
    },

    async getPrincipals() {
      let params = {};
      return await axios.get(PMC.GET_PRINCIPAL_KEYS, params).then(async r => {
        this.principals = r.data;
        return r.data;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
        return false
      });
    },

    async generateCrewPerPrincipal(params) {

      if(this.reportParam.principalId === 0) {

        this.errorAlert('Please Select Principal', '');
        this.isLoading = false;

      }
      else {

        this.isLoading = true;
        return await axios.post(PMC.GENERATE_CREW_PER_PRINCIPAL, params).then(r => {
          this.isLoading = false;
          if (Object.keys(r.data).length > 0) {
            this.hasData = true;
            this.crewPrincipal = r.data;
          } else {
            this.hasData = false;
            this.crewPrincipal = null;
          }
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate Crew Per Principal')
          return false
        });

      }
    },

    async printReport() {

      if(this.reportParam.principalId === 0) {
        this.errorAlert('Please Select Principal', '');
        this.isLoading = false;
      }
      else {
        AlertService.loadingAlert('Please wait ...', 'Loading Print Report');
        let crewPoolReportPerRankFile = await this.printCrewPerPrincipal();
        if (crewPoolReportPerRankFile) {
          await this.pdfViewer('Crew Per Principal', crewPoolReportPerRankFile);
          Swal.close();
        } else {
          Swal.close();
        }
      }

    },

    async printCrewPerPrincipal() {
      this.reportParam.isPDF = true;
      let params = this.reportParam;
      return await axios.post(PMC.GET_CREW_PER_PRINCIPAL_PRINT, params).then(r => {
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate Crew Biodata')
        return false;
      });
    },

    generateCrewPrincipal() {
      // this.reportParam.print = 0;
      this.generateCrewPerPrincipal(this.reportParam);

    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objOwners',
        'isReloading',
      ]),
  },

  /** mounted **/
  mounted() {
    this.getRanks();
    this.getPrincipals();
  },

  /** created **/

  beforeDestroy() {
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}

.text-size {
  font-size: 1.1em;
}

.text-uppercase {
  text-transform: uppercase;
}
</style>
