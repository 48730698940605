<template>
  <div>
    <ccm-master-checklist-edit-document
      @refresh-ccm-checklist="refreshFromCrewChangeMemo"
      ref="checklistEditDoc"
      :modal-id="viewCcmMasterChecklistModal"
      :file-input-id="fileInputCcmMasterChecklist"
    >
      <template v-slot:table-contents>
        <div class="container-fluid">
          <div class="row py-4">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <div class="row">
                    <div class="form-inline mb-3">
                      <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Masterlist (Docs Format)</span>
                      </div>
                      <div class="form-group ">
                      </div>
                    </div>
                  </div>
                </div>

                <!--          &lt;!&ndash; Owner &ndash;&gt;-->
                <!--          <div class="col-sm-5">-->
                <!--            <div class="form-group row">-->
                <!--              <label for="list-count" class="col-sm-3 col-form-label">Owner</label>-->
                <!--              <span class="col-form-label">:</span>-->
                <!--              <div class="col-sm-7 text-left">-->
                <!--                <select class="custom-select custom-select-sm"-->
                <!--                >-->
                <!--                  <option :value="null" class="text-center align-middle">&#45;&#45;SELECT OWNER&#45;&#45;</option>-->
                <!--                  <option :value="owner.id" v-for="(owner,index) in objOwners" :key="owner.id">-->
                <!--                    {{ owner.name }}-->
                <!--                  </option>-->
                <!--                </select>-->
                <!--              </div>-->
                <!--            </div>-->
                <!--          </div>-->


              </div>
              <hr/>
              <div class="row mt-5">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group row">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 18px;" v-if="selectedVesselName">
                    {{ selectedVesselName }}
                  </span>
                        <span class="ml-4 font-weight-bold lead" style="font-size: 18px;" v-else>
                    --SELECT VESSEL--
                  </span>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group row">
                        <label for="list-count" class="col-form-label">VESSEL</label>
                        <span class="col-form-label">:</span>
                        <div class="col-sm-7 text-left">
                          <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                                  v-model="selectedVessel"
                                  @change="onChangeVessel($event.target.selectedOptions[0].text)"
                                  required
                                  :disabled="isReloading"
                          >
                            <option :value="null">--SELECT VESSEL--</option>
                            <option :value="vessel.id" v-for="(vessel,index) in objVessels" :key="vessel.id">
                              {{ vessel.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group row">
                        <button type="button" class="btn btn-xs pmc-btn-tertia"
                                @click="loadOnboardCrewList"
                                v-if="isGenerateOnboardButton"
                                :disabled="isReloading"
                                v-show="onboardCrews.length !== 0"
                        >
                          <font-awesome-icon icon="file"/>
                          Generate Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--  pagination      -->
              <div class="row py-1">
                <div class="col-md-9">
                  <div class="row">
                    <div class="col-5">
                      <!--                <pmc-pagination :page-object="tradeRoutePagination" @settingPage="set_this_page"></pmc-pagination>-->
                    </div>
                  </div>
                </div>
              </div>

              <!-- table  -->
              <div id="table-section" class="py-3">
                <div class="row justify-content-center">
                  <div class="col col-md">
                    <div class="table table-responsive pmc-table border-top" style="height : 550px">

                      <!--MASTERLIST FORMAT-->
                      <table class="table table-bordered table-sm text-center sticky-header-docs-format small table-hover">
                        <thead>
                          <tr>
                            <th rowspan="2">#</th>
                            <th rowspan="2" style="width: 8%;">Name</th>
                            <th rowspan="2">Rank</th>
                            <th rowspan="2">Age</th>
                            <th rowspan="2"><span class="d-block">Departure</span>Date</th>
                            <th rowspan="2"><span class="d-block">Sign-On</span>Date</th>
                            <th rowspan="2"><span class="d-block">POEA</span><span class="d-block">Contract</span>Exp</th>
                            <th rowspan="2"><span class="d-block">Medical</span><span class="d-block">Certificate</span>Validity</th>
                            <th rowspan="2"><span class="d-block">Yellow</span><span class="d-block">Fever</span>Validity</th>
                            <th rowspan="2"><span class="d-block">Cholera</span>Validity</th>
                            <th rowspan="2"><span class="d-block">SIRB</span>Validity</th>
                            <th rowspan="2"><span class="d-block">Passport</span>Validity</th>
                            <th rowspan="2"><span class="d-block">US Visa</span>Validity</th>
                            <th v-if="selectedVesselFlag" style="text-transform: capitalize;" :colspan="getFlagColspan()">
                              {{ selectedVesselFlag }} Validity
                            </th>
                            <th v-else colspan="4">No Flag</th>
                            <th rowspan="2"><span class="d-block">COC</span><span class="d-block">Fever</span>Validity</th>
                            <th rowspan="2"><span class="d-block">GOC</span><span class="d-block">Fever</span>Validity</th>
                            <th rowspan="2"><span class="d-block">II/4 & III/4</span><span class="d-block">(COP)</span>Issued Date</th>
                            <th rowspan="2"><span class="d-block">II/5 & III/5</span><span class="d-block">(COP)</span>Issued Date</th>
                            <th v-if="hasVesselEcdis" rowspan="2" style="width: 6%;">
                              <span class="d-block text-break">{{ selectedVesselEcdisName }}</span>Issued Date
                            </th>
                            <th v-else rowspan="2">No Ecdis</th>
                            <th rowspan="2"><span class="d-block">AMCV</span>Validity</th>
                          </tr>

                          <!--            PANAMA VALIDITY-->
                          <tr v-if="selectedVesselFlag === 'panama'">
                            <th><span class="d-block">Booklet /</span>License</th>
                            <th><span class="d-block">GMDSS</span><span class="d-block">Booklet w/</span>License</th>
                            <th>SDSD Endo</th>
                            <th><span class="d-block">SSO /</span>CCk</th>
                          </tr>
                          <tr v-else-if="selectedVesselFlag === 'marshall islands'">
                            <th>Booklet EXP</th>
                            <th>GMDSS EXP</th>
                            <th>ENDO EXP</th>
                          </tr>
                          <tr v-else-if="selectedVesselFlag === 'liberia'">
                            <th>Booklet</th>
                            <th>COC</th>
                            <th>GMDSS EXP</th>
                            <th><span class="d-block">ECDIS</span>Issued Date</th>
                            <th>SSO/CCk EXP</th>
                            <th><span class="d-block">MECA</span>Issued Date</th>
                          </tr>
                          <tr v-else>
                            <th>No Certificate</th>
                          </tr>
                        </thead>
                        <template v-if="isReloading">
                          <tr>
                            <td colspan="100%">
                              <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                              <span style="size: 20px">
                          Loading ...
                        </span>
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <template v-if="onboardCrews ? (onboardCrews.length != 0) : '' ">
                            <tr v-for="(onboardCrew, index) in onboardCrews">
                              <td>{{ parseInt(index) + 1 }}</td>
                              <td
                                :class="{
                                  'with-one-higher-license' : onboardCrew.hasHigherLicense,
                                }"
                              >
                                <span v-if="onboardCrew.hasHigherLicense">*</span>
                                <a href="#"
                                   @click.stop="gotoCrewProfileNewWindow(onboardCrew.crewId, 'all-info')"
                                   style="color:black;">
                                  <small><b>{{ onboardCrew.crewSignOff }} </b></small>
                                </a>
                              </td>
                              <td>{{ onboardCrew.rank }}</td>
                              <td>{{ onboardCrew.age }}</td>
                              <td>{{ onboardCrew.ccOffDepartureDate }}</td>
                              <td>{{ onboardCrew.ccOffSignOnDate }}</td>
                              <td>{{ onboardCrew.ccOffEocDate }}</td>

                              <!--                      MEDICAL CERT EXPIRY DATE-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.medExamCert?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.medExamCert?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.medExamCert?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.medExamCert?.dateExpiry }}
                              </td>

                              <!--                      YELLOW FEVER-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.yellowFever?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.yellowFever?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.yellowFever?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.yellowFever?.document_no2 }}
                              </td>

                              <!--                      CHOLERA-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.cholera?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.cholera?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.cholera?.nearExpiry2months,
                                 }"
                              >
                                {{ onboardCrew.documentsData?.cholera?.dateExpiry }}
                              </td>

                              <!--                      SIRB-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.sirb?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.sirb?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.sirb?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.sirb?.dateExpiry }}
                              </td>

                              <!--                      PASSPORT-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.passport?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.passport?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.passport?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.passport?.dateExpiry }}
                              </td>

                              <!--                      US VISA-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.usVisa?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.usVisa?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.usVisa?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.usVisa?.dateExpiry }}
                              </td>

                              <!--                      PANAMA FLAG-->
                              <template v-if="selectedVesselFlag === 'panama'">
                                <!--                      PANAMA BOOK-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.panamaBook?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.panamaBook?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.panamaBook?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.panamaBook?.rankAlias }}
                                  <br>
                                  {{ onboardCrew.documentsData?.panamaBook?.dateExpiry }}
                                </td>

                                <!--                      PANAMA GMDDS BOOK-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.panamaGmddsBook?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.panamaGmddsBook?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.panamaGmddsBook?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.panamaGmddsBook?.dateExpiry }}
                                </td>

                                <!--                      PANAMA SDSD ENDO-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.panamaSdsdEndo?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.panamaSdsdEndo?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.panamaSdsdEndo?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.panamaSdsdEndo?.dateExpiry }}
                                </td>

                                <!--                      PANAMA SSO-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.panamaSso?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.panamaSso?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.panamaSso?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.panamaSso?.dateExpiry }}
                                </td>
                              </template>

                              <!--                      MARSHALL ISLAND FLAG-->
                              <template v-else-if="selectedVesselFlag === 'marshall islands'">

                                <!--                      MARSHALL ISLAND BOOKLET EXP-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.marshallIslandBookletExp?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.marshallIslandBookletExp?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.marshallIslandBookletExp?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.marshallIslandBookletExp?.rankAlias }}
                                  <br>
                                  {{ onboardCrew.documentsData?.marshallIslandBookletExp?.dateExpiry }}
                                </td>

                                <!--                      MARSHALL ISLAND GMDDS EXP-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.marshallIslandGmdssExp?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.marshallIslandGmdssExp?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.marshallIslandGmdssExp?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.marshallIslandGmdssExp?.dateExpiry }}
                                </td>

                                <!--                      MARSHALL ISLAND ENDO EXP-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.marshallIslandEndoExp?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.marshallIslandEndoExp?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.marshallIslandEndoExp?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.marshallIslandEndoExp?.dateExpiry }}
                                </td>
                              </template>

                              <!--                      LIBERIA FLAG-->
                              <template v-else-if="selectedVesselFlag === 'liberia'">

                                <!--                      LIBERIA BOOKLET-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.liberiaBooklet?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.liberiaBooklet?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.liberiaBooklet?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.liberiaBooklet?.rankAlias }}
                                  <br>
                                  {{ onboardCrew.documentsData?.liberiaBooklet?.dateExpiry }}
                                </td>

                                <!--                      LIBERIA COC-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.liberiaCoc?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.liberiaCoc?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.liberiaCoc?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.liberiaCoc?.dateExpiry }}
                                </td>

                                <!--                      LIBERIA GMDSS-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.liberiaGmdssSqc?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.liberiaGmdssSqc?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.liberiaGmdssSqc?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.liberiaGmdssSqc?.dateExpiry }}
                                </td>

                                <!--                      LIBERIA ECDIS-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.liberiaEcdisSqc?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.liberiaEcdisSqc?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.liberiaEcdisSqc?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.liberiaEcdisSqc?.dateExpiry }}
                                </td>

                                <!--                      LIBERIA SSO-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.liberiaSsoCckSqc?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.liberiaSsoCckSqc?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.liberiaSsoCckSqc?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.liberiaSsoCckSqc?.dateExpiry }}
                                </td>

                                <!--                      LIBERIA MECA-->
                                <td
                                  @click.stop="view_document(onboardCrew.documentsData?.liberiaMecaSqc?.id)"
                                  :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.liberiaMecaSqc?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.liberiaMecaSqc?.nearExpiry2months,
                                }"
                                >
                                  {{ onboardCrew.documentsData?.liberiaMecaSqc?.dateExpiry }}
                                </td>
                              </template>

                              <!--                      NO FLAG-->
                              <template v-else>
                                <td>--</td>
                              </template>

                              <!--                      COC-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.coc?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.coc?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.coc?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.coc?.rankAlias }}
                                <br>
                                {{ onboardCrew.documentsData?.coc?.dateExpiry }}
                              </td>

                              <!--                      GOC-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.goc?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.goc?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.goc?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.goc?.dateExpiry }}
                              </td>

                              <!--                      COP 4-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.copDeckEngineRating_4?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.copDeckEngineRating_4?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.copDeckEngineRating_4?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.copDeckEngineRating_4?.dateIssued }}
                              </td>

                              <!--                      COP 5-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.copDeckEngineRating_5?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.copDeckEngineRating_5?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.copDeckEngineRating_5?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.copDeckEngineRating_5?.dateIssued }}
                              </td>

                              <!--                      ECDIS TYPE -->
                              <td
                                v-if="selectedVesselEcdisId === ecdisTypeId.ecdisGeneric"
                                @click.stop="view_document(onboardCrew.documentsData?.ecdis_generic?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.ecdis_generic?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.ecdis_generic?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.ecdis_generic?.dateIssued }}
                              </td>
                              <td
                                v-else-if="selectedVesselEcdisId === ecdisTypeId.ecdisSpecificTraining"
                                @click.stop="view_document(onboardCrew.documentsData?.ecdis_specific_training?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.ecdis_specific_training?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.ecdis_specific_training?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.ecdis_specific_training?.dateIssued }}
                              </td>
                              <td
                                v-else-if="selectedVesselEcdisId === ecdisTypeId.ecdisJrc72019201"
                                @click.stop="view_document(onboardCrew.documentsData?.ecdis_jrc_jan_7201_9201?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.ecdis_jrc_jan_7201_9201?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.ecdis_jrc_jan_7201_9201?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.ecdis_jrc_jan_7201_9201?.dateIssued }}
                              </td>
                              <td
                                v-else-if="selectedVesselEcdisId === ecdisTypeId.ecdisJrc701b901b"
                                @click.stop="view_document(onboardCrew.documentsData?.ecdis_jrc_jan_701b_901b?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.ecdis_jrc_jan_701b_901b?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.ecdis_jrc_jan_701b_901b?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.ecdis_jrc_jan_701b_901b?.dateIssued }}
                              </td>
                              <td
                                v-else-if="selectedVesselEcdisId === ecdisTypeId.ecdisFuruno1"
                                @click.stop="view_document(onboardCrew.documentsData?.ecdis_furuno_1?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.ecdis_furuno_1?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.ecdis_furuno_1?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.ecdis_furuno_1?.dateIssued }}
                              </td>
                              <td
                                v-else-if="selectedVesselEcdisId === ecdisTypeId.ecdisFuruno2"
                                @click.stop="view_document(onboardCrew.documentsData?.ecdis_furuno_2?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.ecdis_furuno_2?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.ecdis_furuno_2?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.ecdis_furuno_2?.dateIssued }}
                              </td>
                              <td
                                v-else-if="selectedVesselEcdisId === ecdisTypeId.ecdisVisonMasterFt"
                                @click.stop="view_document(onboardCrew.documentsData?.ecdis_visonmaster_ft?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.ecdis_visonmaster_ft?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.ecdis_visonmaster_ft?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.ecdis_visonmaster_ft?.dateIssued }}
                              </td>
                              <td
                                v-else-if="selectedVesselEcdisId === ecdisTypeId.ecdisTransas"
                                @click.stop="view_document(onboardCrew.documentsData?.ecdis_transas?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.ecdis_transas?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.ecdis_transas?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.ecdis_transas?.dateIssued }}
                              </td>
                              <td
                                v-else-if="selectedVesselEcdisId === ecdisTypeId.ecdisTokyoKeiki"
                                @click.stop="view_document(onboardCrew.documentsData?.ecdis_tokyo_keiki?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.ecdis_tokyo_keiki?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.ecdis_tokyo_keiki?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.ecdis_tokyo_keiki?.dateIssued }}
                              </td>
                              <td v-else>--</td>

                              <!--                      AMCV-->
                              <td
                                @click.stop="view_document(onboardCrew.documentsData?.amcv?.id)"
                                :class="{
                                  'no-documents-or-expired' : onboardCrew.documentsData?.amcv?.expired1monthBelow,
                                  'near-expiry' : onboardCrew.documentsData?.amcv?.nearExpiry2months,
                                }"
                              >
                                {{ onboardCrew.documentsData?.amcv?.dateIssued }}
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr>
                              <td colspan="100%" class="bg-light-danger font-weight-bolder">
                                <p class="text-center mt-3">No Results</p>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </table>
                    </div>
                  </div>
                </div>
                <table style="width:100%;">
                  <tbody>
                  <tr>
                    <td><span style="font-weight:bold;">Legend :</span></td>
                    <td></td>
                    <td><span style="text-align:right;color:black;"><b>* - With one higher license</b></span></td>
                    <td></td>
                    <td></td>
                    <td><span style="text-align:right;color:blue;"><b>Promoted Onbooard</b></span></td>
                    <td></td>
                    <td><span style="text-align:right;color: green"><b>Newly Embarked</b></span></td>
                    <td></td>
                    <td><span style="text-align:right;background-color: Gray; color:white;"><b>Non Mandatory</b></span></td>
                    <td></td>
                    <td>
                      <span style="text-align:right;background-color: Yellow"><b>Near Expiry (2 Mos.↓)</b></span>
                    </td>
                    <td></td>
                    <td>
                    <span style="text-align:right;background-color: Red;color:white;">
                      <b>Expired Documents (1 Mos.↓)</b>
                    </span>
                    </td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!--  Edit Gear Size Modal modal   -->
          <!-- generating pdf loading modal -->
          <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
               aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ccm-master-checklist-edit-document>
  </div>
</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  name: "OnboardCrewListing",

  /** mixins **/
  mixins: [AlertMixins, AppMixins],

  data() {
    return {
      isEditable: false,
      selectedVessel: null,
      selectedVesselName: null,
      selectedVesselFlag: null,
      selectedVesselEcdisName: null,
      selectedVesselEcdisId: null,
      hasVesselEcdis: false,
      onboardCrewListFormParam: {
        isGenerate: false,
        vesselId: null,
        reportType: global_variables_config.onboardCrewListReportType.masterlistDocsFormat,
      },
      isGenerateOnboardButton: false,
      viewCcmMasterChecklistModal: 'view-ccm-master-checklist-modal',
      fileInputCcmMasterChecklist: 'file-input-ccm-master-checklist',
      ecdisTypeId: {
        ecdisGeneric: 514,
        ecdisSpecificTraining: 597,
        ecdisJrc701b901b: 647,
        ecdisFuruno1: 648,
        ecdisFuruno2: 720,
        ecdisJrc72019201: 733,
        ecdisVisonMasterFt: 816,
        ecdisTransas: 827,
        ecdisTokyoKeiki: 831,
      },
      flagHeader: {
        'panama': 4,
        'marshall islands': 3,
        'liberia': 6,
      },
    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
      // 'set_crew_change_plans',
      'set_onboardCrews',
      'generateOnboardCrewList',
      'resetOnboardCrews',
      'resetOnboardCrewDetails',
    ]),

    view_document(crewDocId) {
      this.$refs.checklistEditDoc.view_document(crewDocId);
    },

    refreshFromCrewChangeMemo() {
      this.$emit('refresh-ccm-checklist');
    },

    onChangeVessel(name) {
      let filteredVessel = this.objVessels.filter(vessel => vessel.id === this.selectedVessel);
      let filteredEcdis = this.objEcdisKeys.filter(ecdis => ecdis.id === filteredVessel[0].ecdis_type_id);
      this.selectedVesselName = name;
      this.onboardCrewListFormParam.vesselId = this.selectedVessel
      this.onboardCrewListFormParam.isGenerate = false

      if(filteredVessel[0].ecdis_type_id) {
        this.selectedVesselEcdisName = filteredEcdis[0].name.replace(/-(?![^(]*\))/g, '');
        this.selectedVesselEcdisId = filteredEcdis[0].id;
        this.hasVesselEcdis = true;
      } else {
        this.hasVesselEcdis = false;
      }

      this.selectedVesselFlag = filteredVessel[0].flagName.toLowerCase() ?? null;
      this.getFlagColspan();
    },

    async loadOnboardCrewList() {
      this.onboardCrewListFormParam.isGenerate = true
      AlertService.loadingAlert('Please wait ...', 'Loading Masterlist ...');
      let onboardCrewListFile = await this.generateOnboardCrewList(this.onboardCrewListFormParam);
      if (onboardCrewListFile) {
        Swal.close();
        await this.pdfViewerMasterChecklist('File', onboardCrewListFile);
      } else {
        Swal.close();
      }
    },

    debouncedSetOnboardCrews: _.debounce(function() {
      this.set_onboardCrews(this.onboardCrewListFormParam);
    }, 500),

    getFlagColspan() {
      return this.flagHeader[this.selectedVesselFlag];
    }

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objVessels',
        'objEcdisKeys',
        // 'crewChangePlans',
        'onboardCrews',
        'onboardCrewDetails',
        'isReloading',
      ]),
  },

  /** mounted **/
  mounted() {},

  /** created **/
  async created() {
    this.$MyGlobalFunction = global_variables_config
    await this.getCrewComplimentPerVessel();
  },

  beforeDestroy() {
    this.$myGlobalFunction = null;
  },

  /** destroyed **/
  destroyed() {
    this.resetOnboardCrews();
    this.resetOnboardCrewDetails();
  },

  /** watch **/
  watch: {
    selectedVesselName() {
      if (this.selectedVessel) {
        this.isGenerateOnboardButton = true
        this.debouncedSetOnboardCrews();
      } else {
        this.isGenerateOnboardButton = false
      }
    },

  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

table {
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

.sticky-header-docs-format > thead {
  border-top: 0;
  position: sticky !important;
  top: 0;
  z-index: 100;
}
</style>
