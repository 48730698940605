<template>
  <div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
      <main>
        <br>
        <br>
        <br>
        <br>
        <div class="container mt-0" id="container">

          <div class="form-container sign-in-container">
            <form  @submit.prevent="login_user">
              <h2>Sign in</h2>
              <input type="text" placeholder="Username"  v-model="authData.username" />
              <div class="password-container">
                <input type="password" placeholder="Password" id="password" v-model="authData.password">
                <font-awesome-icon :icon="this.icon" class="fa-eye" @click="showPassword"/>
              </div>
              <button>Sign In</button>
            </form>

          </div>
          <div class="overlay-container">
            <div class="overlay">
              <div class="overlay-panel overlay-right">
                <img height="330" width="330" src="../assets/cdms.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";

export default {
  name: "Login",

  mixins : [AlertMixins,AppMixins],
  data () {
    return {
      icon: 'eye-slash',
    }
  },

  /** methods **/
  methods: {
    ...mapActions(
        [
          'loginUser',
        ],
    ),

    showPassword () {
      const xinput = document.getElementById('password')
      if (xinput.type === 'password') {
        xinput.type = 'text'
        this.icon = 'eye'
      } else {
        xinput.type = 'password'
        this.icon = 'eye-slash'
      }
    },

    login_user(){
      this.loginUser(this.authData).then((r)=>{
        if(r){
          this.goto_dashboard();
          this.successAlert("Log in successfully")
        }else {
          if(!this.isObjEmpty(this.authUser)){
            this.goto_changePass();
          }
        }
      });
    },

    goto_dashboard(){
      //todo push router into main dashboard.
      this.$router.push({name : 'Dashboard1'}).catch(err => {});
    },

    goto_changePass(){
      this.$router.push({name : 'ChangePassword'}).catch(err => {});
    }
  },

  /** computed **/
  computed: {
    ...mapGetters(
        [
          'authData',
          'isReloading',
          'authUser'
        ],
    ),
  },
  mounted () {},
  created () {},
  destroyed () {},
}
</script>

<style scoped>
.mt-0 {
  margin-top: 50px !important;
}

* {
  box-sizing: border-box;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  border: 1px solid #614385;
  background: linear-gradient(to bottom, #272467 , #354f8a);
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

form {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.password-container{
  width: 100%;
  position: relative;
}
.password-container input[type="password"],
.password-container input[type="text"]{
  width: 100%;
  padding: 12px 36px 12px 12px;
  box-sizing: border-box;
}

.fa-eye{
  position: absolute;
  top: 40%;
  right: 4%;
  cursor: pointer;
  color: #272467;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  /*box-shadow: 0 10px 24px rgba(0,0,0,0.25),*/
  /*0 6px 6px rgba(0,0,0,0.22);*/
  /*box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;*/
  /*box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;*/
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width:55%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

@keyframes show {
  0%, 49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%, 100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 48%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container{
  transform: translateX(-100%);
}

.overlay {
  /*background: #FF416C;*/
  /*background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);*/
  /*background: linear-gradient(to right, #FF4B2B, #FF416C);*/
  background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
  background: linear-gradient(to right, #FFFFFF , #FFFFFF);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

button:hover {
  color: #FFFFFF;
}

</style>
