<template>
  <router-view/>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'Vessel',

  /** mixins **/
  mixins: [],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {};
  },

  /** methods**/
  methods: {
    ...mapActions(
        [
            'resetVesselState',
            'null_obj_vessel_types',
        ]
    )
  },

  /**  computed  **/
  computed: {},

  /** mounted **/
  mounted() {},

  /** created **/
  created() {},

  /** watch
   searchText : {
			handler : 'fetchUserList',
			immediate : true,
		}
   **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.resetVesselState();
    this.null_obj_vessel_types();
  },
};
</script>

<style scoped>

</style>
