<template>
  <div>

    <hr/>
    <div class="col-md-4">
      <div class="row">
        <div class="form-inline">
          <div class="form-group">
            <h5 class="font-weight-bold lead text-primary">Sign-On Crew</h5>
          </div>

        </div>

      </div>
    </div>

    <table class="table table-bordered table-hover table-sm text-center small">
      <thead>
      <tr>
        <th style="width: 3%;">
          #
        </th>
        <th class="">Rank</th>
        <th class="" style="width: 15%;">Name</th>
        <th class="">Date Given (1st Dose)</th>
        <th class="">Date Given (2nd Dose)</th>
        <th class="">Brand</th>
        <th class="">Remarks</th>

      </tr>
      </thead>

      <tbody>
      <template v-if="loading">
        <tr>
          <td colspan="100%">
            <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
            <span style="size: 20px">
                          Loading ...
                        </span>
          </td>
        </tr>
      </template>

      <template v-else>
        <template v-if="signOnCrews ? (signOnCrews.length != 0) : '' ">
          <tr v-for="(signOnCrew,index) in signOnCrews" :key="index">
            <template>
              <td class="text-center align-middle">
                {{ index + 1 }}
              </td>
              <td class="text-center align-middle" @click="view_document(signOnCrew.covidVaccineId)">
                {{ signOnCrew.rankAlias }}
              </td>
              <td class="text-left align-middle" @click="view_document(signOnCrew.covidVaccineId)">
                <a href="#"
                   @click.stop="gotoCrewProfileNewWindow(signOnCrew.crewId, 'all-info')"
                   style="color:black;">
                  <small><b>{{ signOnCrew.crewName }} </b></small>
                </a>
              </td>
              <td class="text-center align-middle" @click="view_document(signOnCrew.covidVaccineId)">
                {{ signOnCrew.inoculateDate }}
              </td>
              <td class="text-center align-middle" @click="view_document(signOnCrew.covidVaccineId)">
                <span v-if="signOnCrew.firstDoseOnly == 1">
                  {{ signOnCrew.inoculateDate }}
                </span>

                <span v-if="signOnCrew.firstDoseOnly != 1">
                  {{ signOnCrew.completionDate }}
                </span>

              </td>
              <td class="text-center align-middle" @click="view_document(signOnCrew.covidVaccineId)">
                {{ signOnCrew.vaccineBrand }}
              </td>
              <td class="text-center align-middle" @click="view_document(signOnCrew.covidVaccineId)">
                {{ signOnCrew.vaccinRemarks }}
              </td>
            </template>
          </tr>
        </template>
      </template>
      </tbody>

    </table>


    <hr/>
    <div class="col-md-4">
      <div class="row">
        <div class="form-inline">
          <div class="form-group">
            <h5 class="font-weight-bold lead text-primary">Sign-Off Crew</h5>
          </div>

        </div>

      </div>
    </div>

    <table class="table table-bordered table-hover table-sm text-center small">
      <thead>
      <tr>
        <th style="width: 3%;">
          #
        </th>
        <th class="">Rank</th>
        <th class="" style="width: 15%;">Name</th>
        <th class="">Date Given (1st Dose)</th>
        <th class="">Date Given (2nd Dose)</th>
        <th class="">Brand</th>
        <th class="">Remarks</th>

      </tr>
      </thead>

      <tbody>

      <template v-if="loading">
        <tr>
          <td colspan="100%">
            <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
            <span style="size: 20px">
                          Loading ...
                        </span>
          </td>
        </tr>
      </template>

      <template v-else>
        <template v-if="signOffCrews ? (signOffCrews.length != 0) : '' ">
          <tr v-for="(signOffCrew,index) in signOffCrews" :key="index">
            <td class="text-center align-middle" @click="view_document(signOffCrew.covidVaccineId)">
              {{ index + 1 }}
            </td>
            <td class="text-center align-middle" @click="view_document(signOffCrew.covidVaccineId)">
              {{ signOffCrew.rankAlias }}
            </td>
            <td class="text-left align-middle" @click="view_document(signOffCrew.covidVaccineId)">
              <a href="#"
                 @click.stop="gotoCrewProfileNewWindow(signOffCrew.crewId, 'all-info')"
                 style="color:black;">
                <small><b>{{ signOffCrew.crewName }} </b></small>
              </a>
            </td>
            <td class="text-center align-middle" @click="view_document(signOffCrew.covidVaccineId)">
              {{ signOffCrew.inoculateDate }}
            </td>
            <td class="text-center align-middle" @click="view_document(signOffCrew.covidVaccineId)">
              {{ signOffCrew.completionDate }}
            </td>
            <td class="text-center align-middle" @click="view_document(signOffCrew.covidVaccineId)">
              {{ signOffCrew.vaccineBrand }}
            </td>
            <td class="text-center align-middle" @click="view_document(signOffCrew.covidVaccineId)">
              {{ signOffCrew.vaccinRemarks }}
            </td>
          </tr>
        </template>
      </template>
      </tbody>

    </table>

    <div class="overlay" v-if="isModalOpenOverlayBackdrop"></div>
    <div class="modal fade" :id="editCovidVaccineModal" tabindex="-1" role="dialog"
         aria-labelledby="documentListModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document"
           :class="{'xl-width' : isPdfViewerOpen, 'edit-doc-modal-width' : !isPdfViewerOpen }">
        <div class="modal-content">
          <div class="modal-header">
            <h6 v-if="">Update Covid Vaccine Details</h6>
            <div class="btn-group">
              <button class="btn btn-sm ml-2" @click="doc_editable_close" aria-label="Close">
                <font-awesome-icon icon="times" class="text-danger"/>
                Close
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="text-left" :class="{'col-md-6' : isPdfViewerOpen, 'col-md-11' : !isPdfViewerOpen }">
                <!--                {{ covidVaccine }}-->
                <!--                {{ objCovidVaccineBrands }}-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">Vaccine Name</label>
                  <span>:</span>
                  <div class="col-md-8">
                    <multi-select
                        id="vaccine-brand-id"
                        v-model="covidVaccineId"
                        :options="objCovidVaccineBrands"
                        :custom-label="vaccine_lang"
                        :select-label="'Select vaccine name'"
                        placeholder="Select vaccine name"
                        label="Vaccine names"
                        :disabled="!isEditable"
                        track-by="id">
                    </multi-select>
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">Date Issue</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                        type="date"
                        :disabled="!isEditable"
                        v-model="covidVaccine.inoculateDate"
                        class="form-control form-control-sm"
                        placeholder="--">
                  </div>
                </div>

                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">Date Completion</label>
                  <span>:</span>
                  <div class="col-sm-6">
                    <input
                        type="date"
                        :disabled="!isEditable"
                        v-model="covidVaccine.completionDate"
                        class="form-control form-control-sm"
                        placeholder="--">
                  </div>
                </div>

                <!-- remarks -->
                <div class="form-group row mt-3">
                  <label for="remarks" class="col-sm-3 col-form-label">Remarks</label>
                  <span class="col-form-label">:</span>
                  <div class="col-md-8">
                <textarea
                    id="remarks"
                    rows="5"
                    style="resize: none; width: 100%;"
                    v-if="covidVaccine"
                    v-model="covidVaccine.remarks"
                    :disabled="!isEditable"
                    class="form-control form-control-sm">
                </textarea>
                  </div>
                </div>


                <!--                <div class="form-group row mt-3">-->
                <!--                  <label class="col-sm-3 col-form-label">-->
                <!--                    {{ thisCovidVaccineBlob ? 'Replace file' : 'Attach file' }}-->
                <!--                  </label>-->
                <!--                  <span class="col-form-label">:</span>-->
                <!--                  <input :disabled="!isEditable" type="checkbox" class="form-check form-check-inline ml-sm-4"-->
                <!--                         v-model="isDocReplace"-->
                <!--                         @change="on_change_file"-->
                <!--                  />-->
                <!--                  <div class="col-sm-8">-->
                <!--                    <input type="file"-->
                <!--                           :disabled="!isDocReplace"-->
                <!--                           style="resize: none !important;"-->
                <!--                           id="vaccine-file"-->
                <!--                           @change="on_change_view_vaccine_file"-->
                <!--                           class="form-control-file"-->
                <!--                           placeholder="&#45;&#45;"-->
                <!--                    />-->
                <!--                  </div>-->
                <!--                </div>-->


                <div class="form-group row mt-3">
                  <label for="vaccine-file" class="col-sm-3 col-form-label" v-if="covidVaccine">
                    Attach file
                  </label>
                  <span class="col-form-label">:</span>

                  <div class="col-md-8">
                    <div class="form-inline mb-3">
                      <div class="form-group mb-2 mr-3 ml-2">
                        <input :disabled="!isEditable"
                               type="checkbox"
                               v-model="isDocReplace"
                               @change="on_change_file"/>
                      </div>
                      <div class="form-group ">
                        <!--                  class="form-control-sm"-->
                        <input
                            :disabled="!isDocReplace"
                            type="file"
                            @change="on_change_view_vaccine_file"
                            id="vaccine-file"/>
                      </div>
                    </div>
                  </div>

                </div>


              </div>

              <div class="border-left vertical-center-container col-md-1" v-if="thisCovidVaccineBlob">
                <button class="btn-secondary" @click="openPdfViewer" v-if="isPdfViewerOpen">
                  <font-awesome-icon icon="arrow-left"/>
                </button>

                <button class="btn-secondary" @click="openPdfViewer" v-else>
                  <font-awesome-icon icon="arrow-right"/>
                </button>
              </div>

              <div :class="{'col-md-5' : isPdfViewerOpen, 'col-md-1' : !isPdfViewerOpen }">

                <div class="prev-container" v-if="isPdfViewerOpen">
                  <div v-if="!is2mbUp" style="height: 400px;">
                                  <span v-if="!thisCovidVaccineBlob">
                                    <font-awesome-icon icon="file" style="height: 80%; "/>
                                  </span>
                    <object v-if="thisCovidVaccineBlob" width="100%" height="100%"
                            :data="thisCovidVaccineBlob+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=50,10,0'"
                            type="application/pdf"></object>
                    <!--                    <object v-if="thisDocImageBlob && !formDocImgFile" width="100%" height="100%"-->
                    <!--                            :data="thisDocImageBlob+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=50,10,0'"-->
                    <!--                            type="application/pdf"></object>-->
                  </div>

                  <!--                  <div v-if="is2mbUp">-->
                  <!--                    <div class="center-alignment">-->
                  <!--                      <button class="btn btn-xs btn-outline-danger" @click="previewFileDocument(this10mbDocImageBlob)">-->
                  <!--                        <font-awesome-icon icon="file"/>-->
                  <!--                        <small class="ml-2">document</small>-->
                  <!--                      </button>-->
                  <!--                      <p class="small ml-1">Click to preview file more than 3mb.</p>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                </div>

              </div>

            </div>

          </div>

          <div class="modal-footer p-2">
            <button type="button"
                    class="btn btn-primary btn-sm px-3 font-weight-normal"
                    v-if="thisCovidVaccineBlob"
                    @click="openInNewWindow(thisCovidVaccineBlob)"
            >
              <font-awesome-icon icon="file"/>
              Open File
            </button>

            <button type="button"
                    class="btn btn-danger btn-sm px-3 font-weight-normal"
                    v-if="!thisCovidVaccineBlob"
                    readonly
            >
              <font-awesome-icon icon="file"/>
              No File
            </button>

            <button type="button"
                    class="btn btn-danger btn-sm px-3 font-weight-normal"
                    v-if="isEditable && covidVaccine.hasFile"
                    @click="delete_covid_vaccine_file(covidVaccine.id)"
            >
              <font-awesome-icon icon="file"/>
              Delete Attached File
            </button>

            <button type="button"
                    v-if="!isEditable"
                    class="btn btn-warning btn-sm px-3 font-weight-normal"
                    @click="set_edit"
            >
              <font-awesome-icon icon="edit"/>
              Edit
            </button>

            <button type="button"
                    v-if="isEditable"
                    class="btn btn-success btn-sm px-3 font-weight-normal"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              Save
            </button>

            <button type="button"
                    v-if="isEditable"
                    class="btn btn-danger btn-sm px-3 font-weight-normal"
                    @click="set_cancel"
            >
              <font-awesome-icon icon="undo"/>
              Cancel
            </button>

            <button type="button"
                    v-if="!isEditable"
                    @click="doc_editable_close"
                    class="btn btn-danger btn-sm px-3 font-weight-normal"
            >
              <font-awesome-icon icon="times"/>
              Close
            </button>

          </div>

        </div>
      </div>
    </div>


  </div>
</template>


<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import Table from "@/components/base/Table.vue";
import {AlertService} from "@/service/alert.service";

export default {
  name: "CrewChangeMemoCovid",
  components: {Table},

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {

    signOnCrews: {
      type: Array,
      default: function () {
        return [];
      }
    },

    signOffCrews: {
      type: Array,
      default: function () {
        return [];
      }
    },


    loading: {
      type: Boolean,
      default: false,
    },
  },

  /** data **/
  data() {
    return {
      editCovidVaccineModal: 'editCovidVaccineModal',

      isEditable: false,
      isDocReplace: false,

      isModalOpenOverlayBackdrop: false,
      isPdfViewerOpen: false,

    }
  },


  /** methods**/
  methods: {
    ...mapActions([
      'set_covid_vaccine',
      'update_covid_vaccine',
      'getVaccineBrandKeys',
      'set_form_covid_vaccine_blob',
      'set_form_covid_vaccine_blob_get3mb',
      'set_is_2mbUp',

      'null_covid_vaccine_blob',
      'null_covid_vaccine_blob_gte3mb',
      'null_form_covid_vaccine_blob',
      'null_form_covid_vaccine_blob_get3mb',

      'delete_covid_vaccine_file_attachment',
    ]),

    view_document(covidDocId) {
      if(covidDocId){
        this.set_covid_vaccine(covidDocId)
        this.isModalOpenOverlayBackdrop = true;
        this.showModalBackdropStatic(this.editCovidVaccineModal);
      }else{
        AlertService.infoAlertService("This crew has no document of this type",'No Document');
        this.isModalOpenOverlayBackdrop = false;
      }
    },

    doc_editable_close() {
      this.hideModal(this.editCovidVaccineModal);
      this.isDocReplace = false
      this.isPdfViewerOpen = false;
      this.isEditable = false;
      this.isModalOpenOverlayBackdrop = false;
      $(`#vaccine-file`).val('')
      this.reset_form_file()
    },

    vaccine_lang({name}) {
      return name
    },


    set_edit() {
      this.isEditable = true
    },

    set_cancel() {
      this.$nextTick(() => {
        this.isEditable = false
        this.isDocReplace = false
        $(`#vaccine-file`).val('')
        this.set_covid_vaccine(this.covidVaccine.id)
        this.null_form_covid_vaccine_blob()
        this.null_form_covid_vaccine_blob_get3mb()
      })
    },

    openPdfViewer() {
      this.isPdfViewerOpen = !this.isPdfViewerOpen;
    },

    openInNewWindow(pdfBlob) {
      this.viewPdfOnNewWindow(pdfBlob);
    },

    async update() {
      if (await this.questionAlert('Do you want to save the changes you made.', 'Update vaccine details', 'question')) {
        let file = document.getElementById('vaccine-file').files[0]
        // let boosterFile = document.getElementById('booster-file').files[0]
        const formData = new FormData()
        formData.append('file', file ? file : null)
        // formData.append('boosterFile', boosterFile ? boosterFile : null)
        formData.append('id', this.covidVaccine.id)
        formData.append('vaccineId', this.covidVaccine.vaccineId)
        formData.append('crewId', this.covidVaccine.crewId)
        formData.append('inoculateDate', this.covidVaccine.inoculateDate)
        formData.append('completionDate', this.covidVaccine.completionDate)
        formData.append('remarks', this.covidVaccine.remarks)
        formData.append('isBooster', this.covidVaccine.isBooster === true ? 1 : 0)

        this.update_covid_vaccine(formData).then((r) => {
          if (r) {
            this.successAlert('Update vaccine details successfully', 'Update vaccine details')
            this.$emit('refresh-ccm-covid-record-list');
            this.doc_editable_close()
            document.getElementById('vaccine-file').value = null;
          }
        })

        console.log(this.update_covid_vaccine(formData))


      }
    },

    on_change_view_vaccine_file(e) {
      let blobFile = e.target.files[0]
      const reader = new FileReader()
      if (blobFile != null || undefined) {
        reader.readAsDataURL(blobFile)
        reader.onload = async e => {
          let param = e.target.result
          if (this.gte2mb(blobFile)) {
            this.set_is_2mbUp(true)
            this.set_form_covid_vaccine_blob_get3mb(param)
            let msg = 'It seem\'s like you want to upload a file more than 3mb. \n' + 'Do you want to preview. ?'
            if (await this.questionAlert(`${msg}`, 'File Preview', 'warning')) {
              this.base64ToBlob(param)
            }

          } else {
            this.set_is_2mbUp(false)
            this.set_form_covid_vaccine_blob(param)
          }
        }
      }
    },


    on_change_file() {
      if (!this.isDocReplace) {
        $(`#vaccine-file`).val('')
      }
    },

    reset_form_file() {
      this.set_is_2mbUp(false)
      this.null_covid_vaccine_blob()
      this.null_covid_vaccine_blob_gte3mb()
      this.null_form_covid_vaccine_blob()
      this.null_form_covid_vaccine_blob_get3mb()
    },


    async delete_covid_vaccine_file(covidVaccineId) {
      let deletedVaccineFile = await this.delete_covid_vaccine_file_attachment(covidVaccineId);
      if (deletedVaccineFile) {
        this.doc_editable_close()
      }
    },



  },


  /**  computed  **/
  computed: {
    ...mapGetters(
        [
          'covidVaccine',
          'objCovidVaccineBrands',
          'is2mbUp',
          'covidVaccineBlob_gte3mb',
          'covidVaccineBlob',
          'formCovidVaccineBlob',
          'formCovidVaccineBlob_gte3mb',
        ],
    ),


    covidVaccineId: {
      get() {
        let obj = null
        if (this.covidVaccine != null) {
          obj = this.objCovidVaccineBrands.find((vac) => vac.id === this.covidVaccine.vaccineId)
        }
        return obj
      },
      set(val) {
        this.covidVaccine.vaccineId = val ? val.id : null
      },
    },

    thisCovidVaccineBlob() {
      return this.formCovidVaccineBlob ? this.formCovidVaccineBlob : this.covidVaccineBlob
    },

    thisCovidVaccineBlob_gte3mb() {
      return this.formCovidVaccineBlob_gte3mb ? this.formCovidVaccineBlob_gte3mb : this.covidVaccineBlob_gte3mb
    },

  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    this.getVaccineBrandKeys();
  },

  /** watch **/
  watch: {},

}

</script>


<style>
.edit-doc-modal-width {
  max-width: 700px !important;
}

.xl-width {
  max-width: 1280px !important;
}

.vertical-center-container {
  display: flex;
  align-items: center; /* Center vertically */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
