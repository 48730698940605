import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import axios from 'axios';

const initCrewWithdrawalState = () => {
  return {
    //todo
    crewWithdrawals: [], //TODO fetch also the remarks,
    crewWithdrawal: {},
    newCrewWithdrawal: {
      fillupDate: "",
      effectiveDate: "",
      liftedDate: "",
      salaryOffered: "",
      nfr: "",
      // crewId: "", //append dynamically
      wrId: "",
      remarks: [],
    },
    objWithdrawalReasonKeys: [],
    isWithDrawalReason: false,
    formRemarks: {},
  }
};

const state = {
  ...initCrewWithdrawalState()
};

const mutations = {
  set_crew_withdrawals(state, payload) {
    state.crewWithdrawals = payload;
  },

  set_crew_withdrawal(state, payload) {
    state.crewWithdrawal = payload;
  },

  create_crew_withdrawal(state, payload) {
    state.newCrewWithdrawal = {
      fillupDate: "",
      effectiveDate: "",
      liftedDate: "",
      salaryOffered: "",
      nfr: "",
      crewId: "",
      wrId: "",
      remarks: [],
    }
    state.crewWithdrawals.unshift(payload)
  },

  update_crew_withdrawal(state, payload) {
    let crewWithdrawal = state.crewWithdrawals.find((val, index) => {
      return payload.id === val.id;
    })
    Object.assign(crewWithdrawal, payload);
  },

  delete_crew_withdrawal(state, payload) {
    let index = state.crewWithdrawals.findIndex((val, index) => {
      return payload.id === val.id;
    })
    state.crewWithdrawals.splice(index, 1);
  },

  set_obj_withdrawal_reason_keys(state, payload) {
    state.objWithdrawalReasonKeys = payload;
  },

  set_is_with_drawal_reason(state, payload = true) {
    state.isWithDrawalReason = payload;
  },

  reset_state_crew_withdrawal(state) {
    Object.assign(state, initCrewWithdrawalState())
  },


  add_remark(state, payload) {
    if (payload.isNew) {
      state.newCrewWithdrawal.remarks.unshift(payload);
    } else {
      state.crewWithdrawal.remarks.unshift(payload);
    }
  },

  update_remark(state, payload) {
    let withdrawalRemarks = state.crewWithdrawal.remarks.find((val, index) => {
      return val.id === payload.id;
    })
    Object.assign(withdrawalRemarks, payload)
  },


  delete_remark(state, payload) {
    let oldIndex = state.crewWithdrawal.remarks.findIndex((val, index) => {
      return val.id === payload.id;
    })
    state.crewWithdrawal.remarks.splice(oldIndex, 1);
  },

  set_remarks(state, payload) {
    state.formRemarks = payload;
  },

  reset_with_drawal_details(state, payload) {
    state.crewWithdrawal.fillupDate = payload.fillupDate
    state.crewWithdrawal.effectiveDate = payload.effectiveDate
    state.crewWithdrawal.liftedDate = payload.liftedDate
    state.crewWithdrawal.salaryOffered = payload.salaryOffered
    state.crewWithdrawal.wrId = payload.wrId
    state.crewWithdrawal.nfr = payload.nfr
  },

  reset_new_form_remarks_data(state) {
    state.newCrewWithdrawal = {
      remarks: [],
    }
  }
};


const actions = {
  set_crew_withdrawals({commit}, params) {
    commit('set_is_with_drawal_reason')
    return axios.get(PMC.CREW_WITHDRAWAL, {params}).then((r) => {
      commit('set_crew_withdrawals', r.data.crewWithdrawals)
      commit('set_obj_withdrawal_reason_keys', r.data.objWithdrawalReasonKeys)
      commit('set_is_with_drawal_reason', false)
      return true;

    }).catch((er) => {
      commit('set_is_with_drawal_reason', false)
      UtilityService.generateResponseMessage(er, 'Get Crew withdrawals')
      return false;
    })
  },

  //calling the endpoint
  get_crew_withdrawal({commit}, params) {
    commit('set_crew_withdrawal')
    return axios.get(PMC.CREW_WITHDRAWAL, {params}).then((r) => {
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");

      } else {
        UtilityService.generateResponseMessage(er, 'Get crew withdraw')

      }
      return false;
    })
  },

  create_crew_withdrawal({commit}, params) {
    return axios.post(PMC.CREW_WITHDRAWAL, params).then((r) => {
      commit('create_crew_withdrawal', r.data)
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");

      } else {
        UtilityService.generateResponseMessage(er, 'Create crew withdrawal')
      }
      return false;
    })

  },

  update_crew_withdrawal({commit}, params) {
    return axios.put(`${PMC.CREW_WITHDRAWAL}/${params.id}`, params).then((r) => {
      commit('update_crew_withdrawal', r.data)
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");

      } else {
        UtilityService.generateResponseMessage(er, 'Update crew withdrawal')

      }
      return false;
    })
  },

  delete_crew_withdrawal({commit}, params) {
    return axios.delete(`${PMC.CREW_WITHDRAWAL}/${params}`).then((r) => {
      commit('delete_crew_withdrawal', r.data)
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get Vessel Remarks')
      }
      return false;
    })
  },

  reset_state_crew_withdrawal({commit}) {
    commit('reset_state_crew_withdrawal')
  },


  update_withdrawal_file({commit}, params) {
    let cwId = params.get('cwId');
    return axios.post(`${PMC.UPDATE_WITHDRAWAL_FILE}/${cwId}`, params)
      .then((r) => {
        commit("update_crew_withdrawal", r.data);
        return true;
      }).catch((er) => {
        return false;
      })
  },


  upload_additional_attachment_file({commit}, params) {
    let cwId = params.get('cwId');
    return axios.post(`${PMC.UPLOAD_ADDITIONAL_ATTACHMENT_FILE}/${cwId}`, params)
      .then((r) => {
        commit("update_crew_withdrawal", r.data);
        return true;
      }).catch((er) => {
        return false;
      })
  },

  add_remark({commit}, params) {
    return axios.post(PMC.CREW_WITHDRAWAL_REMARKS, params).then((r) => {
      commit('add_remark', r.data, false)
      return true;
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, "Add Remark");
      return false;
    })
  },

  update_remark({commit}, params) {
    // let cwrId = parseInt(params.get('id'),10)
    return axios.put(`${PMC.CREW_WITHDRAWAL_REMARKS}/${params.id}`, params).then((r) => {
      commit('update_remark', r.data);
      return true;
    }).catch((er) => {
      UtilityService.generateResponseMessage(er, "Update Remark")
      return false;
    })
  },

  delete_remark({commit}, params) {
    return axios.delete(`${PMC.CREW_WITHDRAWAL_REMARKS}/${params.id}`).then((r) => {
      commit('delete_remark', r.data);
      return true;
    }).catch((er) => {
      UtilityService.generateResponseMessage(er)
      return false;
    })
  },

  upload_remarks_attach_file({commit}, params) {
    return axios.post(PMC.UPLOAD_REMARKS_ATTACH_FILE, params).then((r) => {
      commit('update_remark', r.data)
      return true;
    }).catch((er) => {
      UtilityService.generateResponseMessage(er)
      return false;
    })
  },
};

const getters = {
  crewWithdrawals: state => state.crewWithdrawals,
  crewWithdrawal: state => state.crewWithdrawal,
  newCrewWithdrawal: state => state.newCrewWithdrawal,
  objWithdrawalReasonKeys: state => UtilityService.capitalizeProperties(state.objWithdrawalReasonKeys),
  isWithDrawalReason: state => state.isWithDrawalReason,
  formRemarks: state => state.formRemarks,
};

export default {
  state,
  actions,
  mutations,
  getters
}

