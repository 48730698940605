import axios from "axios";
import {UtilityService} from "@/service/utility.service";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {commit} from "lodash/seq";
import {FileService} from "@/service/file.service";



const initStateCrewChangePlan = () => {
  return {
    standbyOnboardCrew: {},
    standbyOnboardCrews: [],

    standbyOnboardFormParam: {
      searchValue: null,
      type: null,
      filterType: 'all',
      rankId: null,
      provinceId: null,
      count: 10,
      status: null,
      page: null,
    },


    standbyOnboardPagination: {
      from: 1,
      currentPage: 1,
      lastPage: 0,
      perPage: 10,
      to: 9,
      total: 0,
    },


  }
};

const state = {
  ...initStateCrewChangePlan()
};


const mutations = {
  set_standbyOnboardCrews(state, params) {
    state.standbyOnboardCrews = params
  },

  set_standbyOnboard_pagination(state, params) {
    state.standbyOnboardPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },

};


const actions = {
  async set_standbyOnboardCrews({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GET_CREW_STANDBY_ONBOARD_LIST_REPORT, {params}).then((r) => {
      commit('set_standbyOnboardCrews', r.data.data)
      commit('set_standbyOnboard_pagination', r.data)
      // console.log(r.data)
      commit('set_is_loading', false)
      return true;

    }).catch((er) => {
      commit('set_is_loading', false)
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get Standby and Onboard Crews')
      }
      return false;
    })
  },

  async generateCrewStandbyOnboardList({commit}, params) {
    return await axios.post(PMC.GENERATE_CREW_STANDBY_ONBOARD_LIST, params).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate standby and onboard crew list')
      return false
    });
  },



};


const getters = {
  standbyOnboardCrews: state => UtilityService.capitalizeProperties(state.standbyOnboardCrews),

  standbyOnboardPagination: state => state.standbyOnboardPagination,
  standbyOnboardFormParam: state => state.standbyOnboardFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
