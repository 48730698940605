<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"
                        style="font-size: 22px;">Crew Promotion Yearly</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">

            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">

                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">CREW</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type"
                                v-model="reportParam.crewPromotionId"
                                @change="selectReportType(1)"
                                class="custom-select custom-select-sm"
                                required>
                          <option :value="0">-- select crew --</option>
                          <option v-for="(crew, index) in crews" :key="crew.id" :value="crew.id">{{
                              crew.name
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-1">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">YEAR</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.year"
                                @change="selectReportType(2)"
                                required>
                          <option :value="0">-- select year --</option>
                          <option v-for="(year, index) in years" :key="year" :value="year">{{ year }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">

                <div class="form-group row">
                  <button type="button"
                          @click="generateCrewPromotionYearly"
                          class="btn btn-xs pmc-btn-tertia">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>

                <div class="form-group row mt-1">
                  <button type="button"
                          @click="printReport"
                          class="btn btn-xs pmc-btn-danger">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>

              </div>
            </div>

            <hr>
            <div class="row ml-2">
              <div class="col-12">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label">FILTER BY CREW : <b
                        class="pl-2 text-size">{{ crewSelected }}</b></label>
                    </div>
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label">FILTER BY YEAR : <b
                        class="pl-2 text-size">{{ yearSelected }}</b></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">

                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Vessel</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Crew</th>
                    <th v-if="reportParam.crewPromotionId === 3">Type</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="hasData">
                      <template v-for="(crew, data) in crewPromotionYearly.data">
                        <tr>
                          <td class="text-left font-weight-bold h5" colspan="5">
                            {{ data }}
                          </td>
                        </tr>
                        <template v-for="(row, index) in crew">
                          <tr>
                            <td>{{ index + 1 }}</td>
                            <td>{{ row.vessel }}</td>
                            <td>{{ row.fromRankAlias }}</td>
                            <td>{{ row.toRankAlias }}</td>
                            <td>
                              <a href="#"
                                 @click.stop="gotoCrewProfileNewWindow(row.id, 'all-info')"
                                 style="color:black;">
                                <small><b>{{ row.fullname }}</b></small>
                              </a>
                            </td>
                            <td v-if="reportParam.crewPromotionId === 3">{{ row.type }}</td>
                          </tr>
                        </template>
                      </template>
                      <tr>
                        <td class="text-right font-italic h5 pr-3" :colspan="reportParam.crewPromotionId === 3 ? 6 : 5">Total Crew Promoted: {{ crewPromotionYearly.count }}</td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import {FileService} from "@/service/file.service";
import crewPromotionYearly from "@/components/modules/report/crew/promotion/CrewPromotionYearly.vue";

export default {
  name: "CrewPromotionYearly",

  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      reportParam: {
        year: 0,
        crewPromotionId: 0,
        isPDF: false,
      },
      years: [],
      crews: [
        {
          id: 1,
          name: 'Crew Onboard',
        },
        {
          id: 2,
          name: 'Crew Direct',
        },
        {
          id: 3,
          name: 'Crew Onboard & Direct',
        },
      ],
      crewPromotionYearly: null,
      crewSelected: null,
      yearSelected: null,
      hasData: false,
      isLoading: false,
    }
  },


  /** methods**/
  methods: {
    ...mapActions([]),
    async generateCrewPromotionYearly() {

      if(this.reportParam.crewPromotionId === 0 && this.reportParam.year === 0) {

        this.errorAlert('Please Select Crew And Year', '');
        this.isLoading = false;

      } else if(this.reportParam.crewPromotionId === 0 && this.reportParam.year !== 0) {

        this.errorAlert('Please Select Crew', '');
        this.isLoading = false;

      } else if(this.reportParam.crewPromotionId !== 0 && this.reportParam.year === 0) {

        this.errorAlert('Please Select Year', '');
        this.isLoading = false;

      } else {

        this.reportParam.isPDF = false;
        this.hasData = false;
        let params = this.reportParam;
        this.isLoading = true;
        return await axios.post(PMC.GENERATE_CREW_PROMOTION_YEARLY, params).then(r => {
          this.isLoading = false;
          this.hasData = true;
          this.crewPromotionYearly = r.data;
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate Crew Per Principal')
          return false
        });

      }

    },

    selectYears() {
      const currentYear = new Date().getFullYear();
      for (let previousYear = currentYear - 10; previousYear <= currentYear; previousYear++) {
        this.years.push(previousYear);
      }
      this.reportParam.year = 0;
    },

    selectReportType(id) {
      this.hasData = false;
      if (id === 1) this.crewSelected = this.getCrewName(this.reportParam.crewPromotionId);
      if (id === 2) this.yearSelected = this.getYearName(this.reportParam.year);
    },

    getCrewName(id) {
      let crewName = '';
      const crewList = this.crews;
      for (let c in crewList) {
        if (parseInt(crewList[c].id) === parseInt(id)) {
          crewName = crewList[c].name;
        }
      }
      this.reportParam.crewPromotionId = id;
      return crewName;
    },

    getYearName(value) {
      let yearName = '';
      const yearList = this.years;
      for (let c in yearList) {
        if (parseInt(yearList[c]) === parseInt(value)) {
          yearName = yearList[c];
        }
      }
      return yearName;
    },

    async printReport() {

      if(this.reportParam.crewPromotionId === 0 && this.reportParam.year === 0) {

        this.errorAlert('Please Select Crew And Year', '');
        this.isLoading = false;

      } else if(this.reportParam.crewPromotionId === 0 && this.reportParam.year !== 0) {

        this.errorAlert('Please Select Crew', '');
        this.isLoading = false;

      } else if(this.reportParam.crewPromotionId !== 0 && this.reportParam.year === 0) {

        this.errorAlert('Please Select Year', '');
        this.isLoading = false;

      } else {

        AlertService.loadingAlert('Please wait ...', 'Loading Print Report');
        let crewPromotionYearlyFile = await this.printCrewPromotionYearly();
        if (crewPromotionYearlyFile) {
          await this.pdfViewer('Crew Promotion Yearly', crewPromotionYearlyFile);
          Swal.close();
        } else {
          Swal.close();
        }

      }

    },

    async printCrewPromotionYearly() {
      this.reportParam.isPDF = true;
      let params = this.reportParam;
      return await axios.post(PMC.GENERATE_CREW_PROMOTION_YEARLY_PRINT, params).then(r => {
        return r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate Crew Biodata')
        return false;
      });
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objOwners',
        'isReloading',
      ]),
  },

  /** mounted **/
  mounted() {
    this.selectYears();
  },

  /** created **/
  beforeDestroy() {},
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}

.text-size {
  font-size: 1.1em;
}

.text-uppercase {
  text-transform: uppercase;
}
</style>
