import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import Auth from '@/store/modules/auth.module'
import TopNav from '@/store/modules/app/topnav.module'
import Pagination from '@/store/modules/app/pagination.module'
import Crew from '@/store/modules/crew.module'
import ObjectKeys from '@/store/modules/app/object.key.module'
import CrewEducation from '@/store/modules/crewprofile/crewEducation.module'
import CrewAddressContact from '@/store/modules/crewprofile/crewAddressContact.module'
import CrewFamily from '@/store/modules/crewprofile/crewFamily.module'
import CrewDocument from '@/store/modules/crewprofile/crewDocument.module'
import CrewComment from '@/store/modules/crewprofile/crewComment.module'
import CrewMedical from '@/store/modules/crewprofile/crewMedical.module'
import CrewReferral from '@/store/modules/crewprofile/crewReferral.module'
import Addresses from '@/store/modules/app/addresses.module'
import Applicant from '@/store/modules/applicant/applicant.module'
import CovidVaccine from '@/store/modules/crewprofile/crewCovidVaccine.module';
import VesselModule from '@/store/modules/masterlist/vessel.module';
import Object2KeyModule from '@/store/modules/app/object2.key.module';
import CovidVaccineBooster from "@/store/modules/crewprofile/crewCovidVaccineBooster.module";

import CrewCompliment from '@/store/modules/crewcompliment/crewcompliment.module'

import CrewChangeManipulation from "@/store/modules/crewchange/crewchangemanipulation.module"
import CrewChangeMemo from "@/store/modules/crewchange/crewchangememo.module"
import CrewChangePlan from "@/store/modules/crewchange/crewchangeplan.module"
import CcMemoRemarksModule from "@/store/modules/crewchange/ccmemoremarks.module";

import VesselRemark from "@/store/modules/crewchange/vesselremarks.module"

// Crew Biodata
import CrewBiodata from "@/store/modules/crewprofile/crewBiodata.module";

import createPersistedState from 'vuex-persistedstate';

/** all setup module store here.. **/
import School from '@/store/modules/setup/school.module'
import Employee from '@/store/modules/setup/employee.module'
import Course from '@/store/modules/setup/course.module'
import Document from '@/store/modules/setup/document.module'
import Rank from '@/store/modules/setup/rank.module'
import Principal from "@/store/modules/setup/principal.module";
import Flag from "@/store/modules/setup/flag.module";
import Country from "@/store/modules/setup/country.module";
import Clinic from "@/store/modules/setup/clinic.module";
import TradeRoute from "@/store/modules/setup/traderoute.module";
import Owner from "@/store/modules/setup/owner.module";
import EmployeePosition from "@/store/modules/setup/employee_position.module";
import Religion from "@/store/modules/setup/religion.module";
import DocumentCategory from "@/store/modules/setup/document_category.module";
import Union from "@/store/modules/setup/union.module";
import Port from "@/store/modules/setup/port.module";
import GearSupplier from "@/store/modules/setup/gear_supplier.module";
import Gear from "@/store/modules/setup/gear.module";
import GearSupplierRate from "@/store/modules/setup/gear_supplier_rate.module";
import GearComplement from "@/store/modules/setup/gear_complement.module";
import CrewSeaServiceModule from "@/store/modules/crewprofile/crewSeaService.module";
import CrewOutSideServiceModule from "@/store/modules/crewprofile/crewOutSideService.module";
import CrewchangeRemarksModule from "@/store/modules/crewchange/crewchangeremarks.module";
import GearPRPO from "@/store/modules/report/gear_prpo.module";
import GearComplementTemplate from "@/store/modules/setup/gear_complement_template.module";
import CrewPromotionModule from "@/store/modules/crewchange/crewpromotion.module";
import CrewChecklistSignatoryTypeModule from "@/store/modules/checklist/crew_checklist_signatory_type.module";
import CrewChecklistDetailsCategoryModule from "@/store/modules/checklist/crew_checklist_details_category.module";
import CrewChecklistSignatoryModule from "@/store/modules/checklist/crew_checklist_signatory.module";
import VesselClassificationModule from "@/store/modules/setup/vessel_classification.module";
import VesselEngineTypeModule from "@/store/modules/setup/vessel_engine_type.module";

import CrewWithdrawalModule from "@/store/modules/crewprofile/crewWithdrawal.module";
import CrewApplicationModule from "@/store/modules/crewprofile/crewApplication.module";

import CrewDocumentChecklistModule from "@/store/modules/crew_checklist/crew_document_checklist.module";

import CcmDocument from "@/store/modules/crewchange/ccm_document_generation/ccmDocument.module";
import CcmMasterlist from "@/store/modules/crewchange/ccm_document_generation/master_checklist/ccmMasterChecklist.module";

import OnboardCrewList from "@/store/modules/report/onboard_crew_list.module";
import StandbyOnboardList from "@/store/modules/report/standby_onboard_list.module";

import AgentListModule from '@/store/modules/report/agent_list.module'

import ScholarMonitoringListModule from '@/store/modules/report/scholar_monitoring_list.module'
import OnboardDocsCertsExpiryModule from "@/store/modules/dashboard/onboardDocsCertsExpiry.module";
import CrewPoolReportPerRankList from "@/store/modules/dashboard/crewPoolReportPerRankList.module";

import StandbyRatioModule from "@/store/modules/report/retention/standby_ratio.module";

import {PMC, PMC_APP_KEYS} from '@/config/constants.config';

const modules = () => {
  return {
    Auth, TopNav, Pagination, Crew, ObjectKeys, CrewEducation, CrewAddressContact, CrewFamily,
    CrewDocument, CrewComment, CrewMedical, Addresses, Applicant, School,
    Employee, Course, Document, Rank, Principal, CovidVaccine,
    CrewReferral, VesselModule, Object2KeyModule, CrewCompliment, Flag, Country,
    Clinic, TradeRoute, Owner, EmployeePosition, Religion, DocumentCategory, Union,
    Port, CovidVaccineBooster, CrewChangePlan, CrewChangeMemo, CrewChangeManipulation, VesselRemark,
    CcMemoRemarksModule, CrewBiodata, GearSupplier, Gear, GearSupplierRate, GearComplement,
    CrewSeaServiceModule,CrewOutSideServiceModule,CrewchangeRemarksModule,GearPRPO, GearComplementTemplate,
    CrewPromotionModule, CrewChecklistSignatoryTypeModule, CrewChecklistDetailsCategoryModule, CrewChecklistSignatoryModule,
    CrewWithdrawalModule, CrewApplicationModule, CrewDocumentChecklistModule, CcmDocument, CcmMasterlist,
    VesselClassificationModule, VesselEngineTypeModule, OnboardCrewList, StandbyOnboardList,AgentListModule,
    ScholarMonitoringListModule, OnboardDocsCertsExpiryModule, StandbyRatioModule, CrewPoolReportPerRankList
  }
}

export default new Vuex.Store({
  state: {} ,
  mutations: {},
  actions: {},
  modules: modules(),
  plugins: [
    createPersistedState({
      key: PMC_APP_KEYS.ENCRYPTED_KEY,
      paths: [
        'Auth.authUser',
        'Auth.b4rExpired',
        'Auth.authProfileDetails',
        'CrewCompliment.crewCompliment',
        'CrewCompliment.crewCompliments',
      ]
    })
  ],
  storage: window.localStorage
})
