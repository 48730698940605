<template>
  <div>
    <!-- Button trigger modal -->
    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
      Launch demo modal
    </button>

    <!-- test Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input type="file" @change="select">
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="`width:${progress}%`" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { AlertMixins } from '../mixins/alert.mixins'
import { AppMixins } from '../mixins/app.mixins'
import axios from 'axios'
import { PMC } from '../config/constants.config'

export default {
  name: 'TestChunkUpload',

  /** mixins **/
  mixins: [AlertMixins,AppMixins],

  /** props **/
  props: {},

  /** data **/
  data () {
    return {
      file: null,
      chunks: [],
      uploaded: 0,
      progressVal : 0,
    }
  },

  /** methods**/
  methods: {

    select(event) {
      this.file = event.target.files.item(0);
      this.createChunks();
    },

    upload() {
      axios(this.config).then(response => {
        this.chunks.shift();
      }).catch(error => {});
    },

    createChunks() {
      //1g - 1024000kb
      //10 mb - 10240kb
      let size = 1024000, chunks = Math.ceil(this.file.size / size);

      for (let i = 0; i < chunks; i++) {
        this.chunks.push(this.file.slice(
            i * size, Math.min(i * size + size, this.file.size), this.file.type
        ));
      }
    }
  },

  /**  computed  **/
  computed: {
    progress : {
      get(){
        if(this.file != null){
          // return Math.floor((this.uploaded * 100) / this.file.size);
          //Math.round(progressEvent.loaded / progressEvent.total * 100);

          return Math.round(this.uploaded / this.file.size * 100);
        }
      },set(val){

      }
    },

    formData() {
      let formData = new FormData;

      formData.set('is_last', this.chunks.length === 1);
      formData.set('file', this.chunks[0], `${this.file.name}.part`);

      return formData;
    },

    config() {
      return {
        method: 'POST',
        data: this.formData,
        url: PMC.DEMO_UPLOAD,
        headers: {
          'Content-Type': 'application/octet-stream',
          'RateLimit-Limit': 500,
        },
        onUploadProgress: progressEvent => {
          // this.progressVal += Math.round(progressEvent.loaded / progressEvent.total * 100);
          this.uploaded += progressEvent.loaded;
        }
      };
    }
  },

  /** mounted **/
  mounted () {
  },

  /** created **/
  created () {
  },

  /** watch **/
  watch: {
    chunks(n, o) {
      if (n.length > 0) {
        this.upload();
      }
    }
  },

  /** destroyed **/
  destroyed () {

  },
}
</script>

<style scoped>

</style>
