<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Classification</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newClassificationModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add classification
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search vessel classification"
                v-model="vesselClassificationFormParam.searchValue"
                @keypress.enter="searchVesselClassification"
              >

              <button class="btn btn-sm pmc-btn-prime" @click="searchVesselClassification">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- status -->
          <!--          <div class="col-sm-2">-->
          <!--            <div class="form-group row">-->
          <!--              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>-->
          <!--              <span class="col-form-label">:</span>-->
          <!--              <div class="col-sm-7 text-left">-->
          <!--                <select name="listCount" id="list-stat" class="form-control custom-select-sm"-->
          <!--                        v-model="tradeRouteFormParam.status"-->
          <!--                        @change="on_change_status">-->
          <!--                  <option :value="null">&#45;&#45;select&#45;&#45;</option>-->
          <!--                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">-->
          <!--                    {{ status.placeholder }}-->
          <!--                  </option>-->
          <!--                </select>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="vesselClassificationFormParam.count"
                        @change="on_change_count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="vesselClassificationPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="7%">CODE</th>
                <th scope="row" class="align-middle" width="7%">NAME</th>
                <th scope="row" class="align-middle" width="7%">DESCRIPTION</th>
                <th scope="row" class="align-middle" width="7%">STATUS</th>

                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-for="(vesselClassification, index) in vesselClassifications">
                <td>{{ index + 1 }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ vesselClassification.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ vesselClassification.name }}</td>
                <td scope="col" class="p-0 text-center">{{ vesselClassification.description }}</td>

                <td scope="col" class="p-0 text-center">

                  <template v-if="vesselClassification.status == 1">
                    <font-awesome-icon icon="check" style="transform: scale(1.7); color:#28a745;" title="ACTIVE"/>
                  </template>

                  <template v-if="vesselClassification.status == 0">
                    <font-awesome-icon icon="minus" style="transform: scale(1.7); color:#DC4C64;" title="INACTIVE"/>
                  </template>

                </td>

                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewVesselClassification"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="viewVesselClassification(vesselClassification.id)"
                  >
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_vesselClassification(vesselClassification.id)"
                  >
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new classification modal   -->
    <div class="modal fade" id="newClassificationModal" tabindex="-1" role="dialog"
         aria-labelledby="newClassificationModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newClassificationModalLabel">NEW CLASSIFICATION</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newClassification.code"
                    />
                  </div>
                </div>

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClassification.name"
                    />
                  </div>
                </div>

                <!-- new  address-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">DESCRIPTION</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newClassification.description"
                    />
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newClassification.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view trade route   -->
    <div class="modal fade" id="viewVesselClassification" tabindex="-1" role="dialog"
         aria-labelledby="viewPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewPrincipalModalLabel">VESSEL CLASSIFICATION</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="classificationData.code"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="classificationData.name"
                    />
                  </div>
                </div>

                <!-- alias -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">DESCRIPTION</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="classificationData.description"
                    />
                  </div>
                </div>


                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           class="form-check form-check-inline ml-2 mt-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                           v-model="classificationData.status"
                    />
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="isEditable = true"
            >
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable"
                    @click="isEditable = false"
            >
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel"
            >
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],


  data() {
    return {

      classificationViewtoClassificationData: {},
      classificationData: {},
      newClassification: {},
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,
    }

  },


  /** methods**/
  methods: {
    ...mapActions([
      'set_vesselClassifications',
      'set_vesselClassification',
      'create_vesselClassification',
      'update_vesselClassification',
      'delete_vesselClassification',
    ]),

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Classification', 'question')
      if (await __create) {

        let params = {
          code: this.newClassification.code ? this.newClassification.code : '',
          name: this.newClassification.name ? this.newClassification.name : '',
          description: this.newClassification.description ? this.newClassification.description : '',
          status: this.newClassification.status ? 1 : 0,
        };

        let classification = await this.create_vesselClassification(params);
        if (classification) {
          AlertService.successAlertService('Create classification record successful', 'Create Classification');
          this.hideModal('newClassificationModal')
          this.newClassification = {};
          this.set_vesselClassifications();
        }

      }

    },

    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update Classification', 'question');
      if (await __update) {
        let params = {
          id: this.classificationData.id,
          code: this.classificationData.code ? this.classificationData.code : '',
          name: this.classificationData.name ? this.classificationData.name : '',
          description: this.classificationData.description ? this.classificationData.description : '',
          status: this.classificationData.status ? 1 : 0,
        };

        let classification = await this.update_vesselClassification(params);
        if (classification) {
          AlertService.successAlertService("Updated successfully", 'Update Classification');
          this.hideModal('viewVesselClassification')
          this.isEditable = false
        }
      }
    },

    viewVesselClassification(vesselClassificationId) {
      this.set_vesselClassification(vesselClassificationId);
    },

    set_cancel(e) {
      this.isEditable = false
      this.classificationData = Object.assign({}, this.vesselClassification);
    },


    set_this_page(e) {
      this.vesselClassificationFormParam.searchValue = ''
      this.vesselClassificationPagination.currentPage = e
      this.vesselClassificationFormParam.page = e
      this.pageLastLeft = e
      this.set_vesselClassifications(this.vesselClassificationFormParam)
    },


    searchVesselClassification() {
      if (this.vesselClassificationFormParam.searchValue === '') {
        this.vesselClassificationFormParam.page = this.pageLastLeft
        this.set_vesselClassifications(this.vesselClassificationFormParam)
      }

      if (this.vesselClassificationFormParam.searchValue) {
        this.vesselClassificationFormParam.page = 1
      }

      this.set_vesselClassifications(this.vesselClassificationFormParam)

    },

    on_change_count(e) {
      this.vesselClassificationFormParam.page = 1
      this.vesselClassificationFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.vesselClassificationFormParam.count = e.target.value
      this.set_vesselClassifications(this.vesselClassificationFormParam)
    },

  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'vesselClassifications',
        'vesselClassification',
        'vesselClassificationPagination',
        'vesselClassificationFormParam',
      ],
    ),
  },

  /**  created  **/
  async created() {
    await this.set_vesselClassifications();
  },

  /** watch **/
  watch: {
    'vesselClassification'() {
      this.classificationData = Object.assign({}, this.vesselClassification);
    },
  },


}
</script>

<style scoped>

</style>
