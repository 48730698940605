<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">List of Suppliers</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#new-supplier-modal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Supplier
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search Supplier name"
                v-model="gearSupplierFormParam.searchValue"
                @keypress.enter="searchGearSupplier"
              >

              <button class="btn btn-sm pmc-btn-prime" @click="searchGearSupplier">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

<!--          status-->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-stat" class="form-control custom-select-sm"
                        v-model="gearSupplierFormParam.status"
                        @change="on_change_status">
                  <option :value="null">--select--</option>
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.placeholder }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="gearSupplierFormParam.count"
                        @change="on_change_count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="gearSupplierPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="350" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="7%">CODE</th>
                <th scope="row" class="align-middle" width="7%">NAME</th>
                <th scope="row" class="align-middle" width="7%">ADDRESS</th>
                <th scope="row" class="align-middle" width="7%">CONTACT NO</th>
                <th scope="row" class="align-middle" width="7%">CONTACT PERSON</th>
                <th scope="row" class="align-middle" width="7%">FAX NO</th>
                <th scope="row" class="align-middle" width="7%">STATUS</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>BY</th>
                <th scope="row" class="text-center align-middle" width="10%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-if = "gearSuppliers.length === 0">
                <td colspan="11">No Results</td>
              </tr>
              <tr v-for="(gearSupplier, index) in gearSuppliers" :key="gearSupplier.id" @click="gear_rate(gearSupplier.id)">
                <td>{{ index + gearSupplierPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3"> {{ gearSupplier.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ gearSupplier.name }}</td>
                <td scope="col" class="p-0 text-center">{{ gearSupplier.address }}</td>
                <td scope="col" class="p-0 text-center">{{ gearSupplier.contact_no }}</td>
                <td scope="col" class="p-0 text-center">{{ gearSupplier.contact_person }}</td>
                <td scope="col" class="p-0 text-center">{{ gearSupplier.fax_no }}</td>
                <td scope="col" class="p-0 text-center">{{ gearSupplier.status == 1 ? 'ACTIVE' : 'INACTIVE' }}</td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ gearSupplier.created_by }}
                </td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ gearSupplier.updated_by }}
                </td>
                <td>
                  <button
                    class="btn btn-xs btn-primary ml-2"
                    @click.stop="gear_rate(gearSupplier.id)"
                  >
                    <font-awesome-icon icon="cog"/>
                    view
                  </button>

                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click.stop="deleteSupplier(gearSupplier)"
                  >
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new supplier modal   -->
    <div class="modal fade" id="new-supplier-modal" tabindex="-1" role="dialog" aria-labelledby="newPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newPrincipalModalLabel">NEW SUPPLIER</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newGearSupplier.code"
                    />
                  </div>
                </div>

                <!-- new name-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newGearSupplier.name"
                    />
                  </div>
                </div>

                <!-- new  address-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ADDRESS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newGearSupplier.address"
                    />
                  </div>
                </div>

                <!-- new contact_no-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT NO.</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newGearSupplier.contact_no"
                    />
                  </div>
                </div>

                <!-- new person-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CONTACT PERSON</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newGearSupplier.contact_person"
                    />
                  </div>
                </div>

                <!-- new person-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">FAX NO.</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-7">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newGearSupplier.fax_no"
                    />
                  </div>
                </div>

                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newGearSupplier.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      gearSupplierViewtoGearSupplierData: {},
      gearSupplierData: {},
      newGearSupplier: {},
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],
      gearSupplierCount: 10,

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,

    };
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_gearSuppliers',
      'create_gearSupplier',
      'delete_gearSupplier',
    ]),

    async save () {
      let __create =  AlertService.questionAlertService('Are you sure you want to add this record', 'New Gear Supplier', 'question')
      if (await __create){

        let params = {
          code: this.newGearSupplier.code ? this.newGearSupplier.code : '' ,
          name: this.newGearSupplier.name ? this.newGearSupplier.name : '' ,
          address: this.newGearSupplier.address ? this.newGearSupplier.address : '' ,
          contact_no: this.newGearSupplier.contact_no ? this.newGearSupplier.contact_no : '' ,
          contact_person: this.newGearSupplier.contact_person ? this.newGearSupplier.contact_person : '',
          fax_no: this.newGearSupplier.fax_no ? this.newGearSupplier.fax_no : '',
          status: this.newGearSupplier.status ? 1 : 0,
        };

        let newGearSupplier = await this.create_gearSupplier(params);
        if (newGearSupplier){
          AlertService.successAlertService('Create gear supplier record successful', 'Create gear supplier');
          this.hideModal('new-supplier-modal')
          this.newGearSupplier = {};
          this.set_gearSuppliers();
        }

      }

    },

    async deleteSupplier(gearSupplier) {

      let delSupplierParams = {
        supplierId: gearSupplier.id,
      }

      let _del = await this.delete_gearSupplier(delSupplierParams);
      if (_del) {
      }
    },


    set_this_page(e) {
      this.gearSupplierFormParam.currentPage = e;
      this.gearSupplierFormParam.page = e;
      this.set_gearSuppliers(this.gearSupplierFormParam);
    },

    searchGearSupplier() {
      if (this.gearSupplierFormParam.searchValue === '') {
        this.gearSupplierFormParam.page = this.pageLastLeft
        this.set_gearSuppliers(this.gearSupplierFormParam)
      }

      if (this.gearSupplierFormParam.searchValue) {
        this.gearSupplierFormParam.page = 1
      }

      this.set_gearSuppliers(this.gearSupplierFormParam)
    },

    on_change_status(e) {
      this.gearSupplierFormParam.status = e.target.value;
      this.set_gearSuppliers(this.gearSupplierFormParam);
    },

    on_change_count(e) {
      this.gearSupplierFormParam.page = 1
      this.gearSupplierFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.gearSupplierFormParam.count = e.target.value
      this.set_gearSuppliers(this.gearSupplierFormParam)
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel(e) {
      this.isEditable = false
      this.gearSupplierData = Object.assign({}, this.gearSupplierViewtoGearSupplierData);
    },

    gear_rate(gearSupplierId) {
      this.$router.push({
        name: 'GearSupplierRateList',
        params: {gearSupplierId: gearSupplierId},
      });
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'gearSuppliers',
        'gearSupplierPagination',
        'gearSupplierFormParam',
      ],
    ),
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_gearSuppliers(this.gearSupplierFormParam);
  },

  /** watch **/
  watch: {
    'gearSupplierViewtoGearSupplierData'() {
      this.gearSupplierData = Object.assign({}, this.gearSupplierViewtoGearSupplierData);
    },
  },

  /** destroyed **/
  destroyed() {
  },

}
</script>

<style scoped>

</style>
