<template>
  <button @click="thisButtonEvent"
          :class="buttonClass"
          type="button"
          data-toggle="modal"
          :data-target="'#'+targetModal"
          data-backdrop="static"
          data-keyboard="false">
    <slot name="button-icon"/>
    {{buttonLabel}}
  </button>
</template>

<script>
export default {
  name: 'ModalButton',

  props: {
    id: {
      type: String,
      default: 'ex-btn-modal',
    },

    name: {
      type: String,
      default: 'ExButtonModal',
    },

    buttonLabel:{
      type : String,
      default: 'my-btn-modal'
    },

    buttonClass:{
      type : String,
      default : 'btn btn-sm',
    },

    targetModal: {
      type: String,
      default: 'example',
    },
  },

  /** data **/
  data () {
    return {}
  },

  /** methods**/
  methods: {
    thisButtonEvent (id) {
      this.$emit('modalButtonEvent', id)
    },
  },

  /**  computed  **/
  computed: {},

  /** mounted **/
  mounted () {
  },

  /** created **/
  created () {
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed () {
  },

}
</script>

<style scoped>

</style>
