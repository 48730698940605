<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-5 text-left">

            <div class="form-group row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Rank</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#newRankModal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Rank
                  </button>
                </div>
              </div>

              <!--              <label class="col-sm-3"><h4>Rank</h4></label>-->

              <!--              <div class="col-sm-5">-->
              <!--                <button class="btn btn-xs pmc-btn-tertia in"-->
              <!--                        data-toggle="modal"-->
              <!--                        data-target="#newRankModal"-->
              <!--                        data-backdrop="static"-->
              <!--                        data-keyboard="false">-->
              <!--                  <font-awesome-icon icon="plus"/>-->
              <!--                  Add-->
              <!--                </button>-->
              <!--              </div>-->


            </div>
          </div>

        </div>

        <div class="row">
          <!-- searchbar -->
          <div class="col-md-3">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                v-model="rankFormParam.searchValue"
                @keypress.enter="searchRank"
                placeholder="Search Rank ...">

              <button class="btn btn-sm pmc-btn-prime" @click="searchRank">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- rank type -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-type" class="col-sm-3 col-form-label">Type</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-8 text-left">
                <select name="list-type" id="list-type" class="custom-select custom-select-sm"
                        v-model="rankFormParam.type"
                        @change="on_change_type"
                        required>
                  <option :value="null">--select--</option>
                  <option :value="type.id" v-for="(type,index) in objRankTypes" :key="type.id" style="font-size: 14px;">
                    <span> {{ type.name }}</span>
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!--rank level-->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-category" class="col-sm-3 col-form-label">Level</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-8 text-left">
                <select name="listCategory" id="list-category" class="custom-select custom-select-sm"
                        v-model="rankFormParam.level"
                        @change="on_change_level"
                        required>
                  <option :value="null">--select--</option>
                  <option :value="level.id" v-for="(level,index) in objRankLevels" :key="level.id"
                          style="font-size: 14px;">
                    <span> {{ level.name }}</span>
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- status -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listStat" id="list-stat" class="form-control custom-select-sm"
                        v-model="rankFormParam.status"
                        @change="on_change_status">
                  <option :value="null">--select--</option>
                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">
                    {{ status.placeholder }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-right">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="rankFormParam.count"
                        @change="on_change_count">
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                <pmc-pagination :page-object="rankPagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="text-center align-middle" width="4%">CODE</th>
                <th scope="row" class="align-middle" width="7%">NAME</th>
                <th scope="row" class="align-middle" width="7%">ALIAS</th>
                <th scope="row" class="align-middle" width="7%">ALIAS 2</th>
                <th scope="row" class="align-middle" width="5%">ALIAS 3</th>
                <th scope="row" class="align-middle" width="7%">RANK TYPE</th>
                <th scope="row" class="align-middle" width="7%">RANK LEVEL</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="align-middle" width="5%">UPDATED<br>BY</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>AT</th>
                <th scope="row" class="align-middle" width="7%">UPDATED<br>AT</th>
                <th scope="row" class="align-middle" width="5%">STATUS</th>
                <th scope="row" class="text-center align-middle" width="12%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-if = "ranks.length === 0">
                <td colspan="14">No Results</td>
              </tr>
              <tr v-else v-for="(rank,index) in ranks" :key="rank.id">
                <td>{{ index + rankPagination.from }}</td>
                <td scope="col" class="p-0 text-left pl-3">{{ rank.code }}</td>
                <td scope="col" class="p-0 text-left pl-4">{{ rank.name }}</td>
                <td scope="col" class="p-0 text-center">{{ rank.alias }}</td>
                <td scope="col" class="p-0 text-center">{{ rank.alias2 }}</td>
                <td scope="col" class="p-0 text-center">{{ rank.alias3 }}</td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ rank.rank_type_name }}
                  <!--                  <select v-model="rank.rank_type" class="rankType" disabled>-->
                  <!--                    <option :value="type.id" v-for="(type,index) in objRankTypes" :key="type.id"-->
                  <!--                            style="font-size: 14px;">-->
                  <!--                      <span> {{ type.name }}</span>-->
                  <!--                    </option>-->
                  <!--                  </select>-->


                </td>
                <td scope="col" class="p-0 text-left pl-3">
                  {{ rank.rank_level_name }}
                  <!--                  <select v-model="rank.rank_level" class="rankType" disabled>-->
                  <!--                    <option :value="level.id" v-for="(level,index) in objRankLevels" :key="level.id"-->
                  <!--                            style="font-size: 14px;">-->
                  <!--                      <span> {{ level.name }}</span>-->
                  <!--                    </option>-->
                  <!--                  </select>-->

                </td>
                <td scope="col" class="p-0 text-center">{{ rank.created_by }}</td>
                <td scope="col" class="p-0 text-center">{{ rank.updated_by }}</td>
                <td scope="col" class="p-0 text-center">{{ rank.created_at }}</td>
                <td scope="col" class="p-0 text-center">{{ rank.updated_at }}</td>
                <td scope="col" class="p-0 text-center">{{ rank.status == 1 ? 'ACTIVE' : 'INACTIVE' }}</td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#viewRankModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="rankViewtoRankData = rank">
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
<!--                  <button-->
<!--                    class="btn btn-xs pmc-btn-info ml-2"-->
<!--                    data-toggle="modal"-->
<!--                    data-target="#viewRankModal"-->
<!--                    data-backdrop="static"-->
<!--                    data-keyboard="false"-->
<!--                    @click="rankViewtoRankData = rank">-->
<!--                    <font-awesome-icon icon="file"/>-->
<!--                    doc matrix-->
<!--                  </button>-->
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_rank(rank.id)">
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>

                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new rank modal   -->
    <div class="modal fade" id="newRankModal" tabindex="-1" role="dialog" aria-labelledby="newRankModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="newRankModalLabel">NEW RANK</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           id="new_rank_code"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newRank.code"
                    />
                  </div>
                </div>


<!--                <input type="text"-->
<!--                       id="new_rank_code"-->
<!--                       class="form-control form-control-sm"-->
<!--                       placeholder="-&#45;&#45;"-->
<!--                       v-model="newRank.code"-->
<!--                       hidden-->
<!--                />-->

                <!-- new  name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      id="new_rank_name"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newRank.name"
                    />
                  </div>
                </div>

                <!-- new  alias 1 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ALIAS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newRank.alias"
                    />
                  </div>
                </div>

                <!-- new  alias 2 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ALIAS 2</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newRank.alias2"
                    />
                  </div>
                </div>

                <!-- new  alias 3 -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ALIAS 3</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="---"
                      v-model="newRank.alias3"
                    />
                  </div>
                </div>

                <!-- rank type-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">RANK TYPE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select
                      class="form-control form-control-sm"
                      v-model="newRank.rank_type">
                      <option :value="null">--select--</option>
                      <option :value="type.id" v-for="(type,index) in objRankTypes" :key="type.id"
                              style="font-size: 14px;">
                        <span> {{ type.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- rank level-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">RANK LEVEL</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <select
                      class="form-control form-control-sm"
                      v-model="newRank.rank_level">
                      <option :value="null">--select--</option>
                      <option :value="level.id" v-for="(level,index) in objRankLevels" :key="level.id"
                              style="font-size: 14px;">
                        <span> {{ level.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">KEY OFFICER</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newRank.key_officer"
                    />
                    <span> KEY OFFICER</span>
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newRank.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view rank   -->
    <div class="modal fade" id="viewRankModal" tabindex="-1" role="dialog" aria-labelledby="viewRankModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewRankModalLabel">Rank</h5>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 text-left ml-2">
                <!-- code -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">CODE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           v-model="rankData.code"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- name -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">NAME</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="rankData.name"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- alias -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ALIAS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="rankData.alias"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- alias 2-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ALIAS 2</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="rankData.alias2"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- alias 3-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">ALIAS 3</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <input type="text"
                           v-model="rankData.alias3"
                           :disabled="!isEditable"
                           :class="{'' : isEditable,'form-control-plaintext' : !isEditable}"
                           class="form-control form-control-sm"
                           placeholder="---"
                    />
                  </div>
                </div>

                <!-- rank type-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">RANK TYPE</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <select :disabled="!isEditable"
                            class="form-control form-control-sm"
                            v-model="rankData.rank_type">
                      <option :value="null">--select--</option>
                      <option :value="type.id" v-for="(type,index) in objRankTypes" :key="type.id"
                              style="font-size: 14px;">
                        <span> {{ type.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- rank level-->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">RANK LEVEL</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-8">
                    <select :disabled="!isEditable"
                            class="form-control form-control-sm"
                            v-model="rankData.rank_level">
                      <option :value="null">--select--</option>
                      <option :value="level.id" v-for="(level,index) in objRankLevels" :key="level.id"
                              style="font-size: 14px;">
                        <span> {{ level.name }}</span>
                      </option>
                    </select>
                  </div>
                </div>

                <!-- key officer -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">KEY OFFICER</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           v-model="rankData.key_officer"
                           class="form-check form-check-inline ml-2 mt-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                    />
                    <!--                    <span>{{ rankData.key_officer }}</span>-->
                  </div>
                </div>


                <!-- status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="checkbox"
                           v-model="rankData.status"
                           class="form-check form-check-inline ml-2 mt-2 "
                           :disabled="!isEditable"
                           placeholder="---"
                    />
                    <!--                    <span>{{ rankData.status == 1 ? 'ACTIVE' : 'INACTIVE' }}</span>-->
                  </div>
                </div>


                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">CREATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="rankData.created_by"
                    />
                  </div>
                </div>

                <!-- created by -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">UPDATED BY</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           disabled
                           class="form-control form-control-plaintext"
                           placeholder="---"
                           v-model="rankData.updated_by"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    @click="set_edit"
                    v-show="!isEditable">
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    @click="close_view_rank_modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel(rankData.id)">
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      sampleStoreCode: '',

      rankViewtoRankData: {},
      rankData: {},
      newRank: {},
      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

      isEditable: false,

      pageLastLeft: 1,

      documentFormParam: {
        searchValue: null,
        type: null,   //default document
        category: null, //default foreign
        count: 10,
        status: 1, //default true
        page: null,
      },

    };
  },


  /** methods**/
  methods: {
    ...mapActions(
      [
        'set_ranks',
        'set_rank',
        'update_rank',
        'create_rank',
        'delete_rank',
        'getCrewPersonalObjectKeys',
        'reset_rank_formparam',
        'reset_rank_pagination',
        'null_rank',
        'null_ranks',
        'set_documents',
      ],
    ),

    set_this_page(e) {
      this.rankFormParam.searchValue = ''
      this.rankPagination.currentPage = e
      this.rankFormParam.page = e
      this.pageLastLeft = e
      this.set_ranks(this.rankFormParam)
      this.set_documents(this.documentFormParam);
      // this.rankFormParam.currentPage = e;
      // this.rankFormParam.page = e;
      // this.set_ranks(this.rankFormParam);
    },

    searchRank() {

      if (this.rankFormParam.searchValue === '') {
        this.rankFormParam.page = this.pageLastLeft
        this.set_ranks(this.rankFormParam)
      }

      if (this.rankFormParam.searchValue) {
        this.rankFormParam.page = 1
      }

      this.set_ranks(this.rankFormParam)

      // this.rankFormParam.page = 1;
      // this.set_ranks(this.rankFormParam);

    },

    on_change_status(e) {
      this.rankFormParam.page = 1
      this.rankFormParam.status = e.target.value;
      this.set_ranks(this.rankFormParam);
    },

    on_change_type(e) {
      this.rankFormParam.type = e.target.value;
      this.set_ranks(this.rankFormParam);
    },

    on_change_level(e) {
      this.rankFormParam.level = e.target.value;
      this.set_ranks(this.rankFormParam);
    },

    on_change_count(e) {
      this.rankFormParam.page = 1
      this.rankFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.rankFormParam.count = e.target.value
      this.set_ranks(this.rankFormParam)

      // this.rankFormParam.count = e.target.value;
      // this.set_ranks(this.rankFormParam);
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel(e) {
      this.isEditable = false
      // this.set_rank(e);
      this.rankData = Object.assign({}, this.rankViewtoRankData);
    },

    close_view_rank_modal() {
      this.isEditable = false
      this.null_rank()
    },

    close_new_rank_modal() {
      this.isEditable = false
      this.null_rank()
    },

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New rank', 'question')
      if (await __create) {

        let params = {
          code: this.newRank.code ? this.newRank.code : '',
          name: this.newRank.name, // this is required
          alias: this.newRank.alias ? this.newRank.alias : '',
          alias2: this.newRank.alias2 ? this.newRank.alias2 : '',
          alias3: this.newRank.alias3 ? this.newRank.alias3 : '',
          rank_type: this.newRank.rank_type ? this.newRank.rank_type : '',
          rank_level: this.newRank.rank_level ? this.newRank.rank_level : '',
          key_officer: this.newRank.key_officer ? 1 : 0,
          status: this.newRank.status ? 1 : 0,
        };

        let newRank = await this.create_rank(params);
        if (newRank) {
          AlertService.successAlertService('Create rank record successful', 'Create rank');
          this.hideModal('newRankModal')
          this.newRank = {};
          this.set_ranks();
        }

      }

    },


    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update rank', 'question');
      if (await __update) {
        let params = {

          id: this.rankData.id,
          code: this.rankData.code ? this.rankData.code : '',
          name: this.rankData.name, // this is required
          alias: this.rankData.alias ? this.rankData.alias : '',
          alias2: this.rankData.alias2 ? this.rankData.alias2 : '',
          alias3: this.rankData.alias3 ? this.rankData.alias3 : '',
          rank_type: this.rankData.rank_type ? this.rankData.rank_type : '',
          rank_level: this.rankData.rank_level ? this.rankData.rank_level : '',
          key_officer: this.rankData.key_officer ? 1 : 0,
          status: this.rankData.status ? 1 : 0,


        };

        let updatedRank = await this.update_rank(params);
        if (updatedRank) {
          AlertService.successAlertService("Updated successfully", 'Update rank');
          this.hideModal('viewRankModal')
          this.null_rank()
          this.isEditable = false
          // this.rankData = Object.assign({}, updatedRank);
        }
      }


      // if (updatedRank){
      //   this.hideModal('viewRankModal')
      //   this.null_rank()
      //   this.isEditable = false
      //   this.rankData = Object.assign({}, updatedRank);
      // }

      // this.update_rank(this.rank).then((r) => {
      //   if (r) {
      //     this.hideModal('viewRankModal')
      //     this.null_rank()
      //     this.isEditable = false
      //     this.rank = Object.assign({}, r);
      //   }
      // })


    },

  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'ranks',
        'rank',
        'rankPagination',
        'rankFormParam',
        'objRankTypes',
        'objRankLevels',
      ],
    ),

    // codeVal: function () {
    //   let nameTocode = this.newRank.name ? this.newRank.name.substring(0, 3) : ''
    //
    //   const codeValue = document.getElementById('new_rank_code')
    //   let value = codeValue ? codeValue.value : '';
    //
    //   let codeNewVal = value = nameTocode;
    //
    //   return this.newRank.code = codeNewVal;
    //
    // }


  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  async created() {
    await this.set_ranks(this.rankFormParam);
    await this.getCrewPersonalObjectKeys();
  },


  /** watch **/
  watch: {
    'rankViewtoRankData'() {
      this.rankData = Object.assign({}, this.rankViewtoRankData);
      // this.editable = false;
    },

    // 'newRank.name'() {
    //   let nameTocode = this.newRank.name ? this.newRank.name.substring(0, 3) : ''
    //   this.newRank.code = JSON.parse(JSON.stringify(nameTocode));
    // }

  },

  /** destroyed **/
  destroyed() {
    this.null_rank()
    this.null_ranks()
    // this.reset_rank_formparam();
    // this.reset_rank_pagination();
  },


}
</script>

<style scoped>
.custom-select-sm {
  font-size: 0.75rem !important;
}

.rankType {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
</style>
