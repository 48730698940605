<template>
  <div class="container justify-content-center">
    <div class="row py-4">
      <div class="col-md-12">
        <div class="row text-left align-middle">
          <div class="col-md-7">
            <h4>AUSTRALIAN MARITIME CREW VISA (AMCV)</h4>
            <p>Document Pre-Requisite (RANK)</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li v-for="(tab,index) in tabs" :key="tab.id" @click="selectTab(tab)">
                <a class="nav-link"
                   :id="`#`+`${tab.content}`+`-tab`"
                   data-toggle="tab"
                   :href="`#`+`${tab.content}`"
                   role="tab"
                   :aria-controls="`${tab.content}`"
                   :aria-selected="selectedTab === tab.id"
                   :class="{'active selected-tab':selectedTab === tab.id}">
                  <font-awesome-icon icon="location-arrow" v-if="selectedTab === tab.id"/>
                  {{ tab.name }}
                </a>
              </li>
            </ul>

            <!-- nav tab container  -->
            <div class=" tab-content" id="myTabContent">
              <div v-if="selectedTab == 1">
                <div class="tab-pane fade" :class="{'show active' : selectedTab === 1}" id="document-fields"
                     role="tabpanel" aria-labelledby="document-fields-tab">
                  <div class="col-md-12 text-left py-lg-4">
<!--                    <div class="btn-group" role="group" aria-label="Basic example">-->
<!--                      <button type="button" class="btn btn-xs btn-secondary" v-if="!isEditable" @click="goBack">Go-->
<!--                        Back-->
<!--                      </button>-->
<!--                      <button type="button" class="btn btn-xs pmc-btn-warning" v-if="!isEditable" @click="setEditable">-->
<!--                        Update-->
<!--                      </button>-->
<!--                      <button type="button" class="btn btn-xs pmc-btn-tertia" v-if="isEditable" @click="save">Save-->
<!--                      </button>-->
<!--                      <button type="button" class="btn btn-xs pmc-btn-danger" v-if="isEditable" @click="cancel">Cancel-->
<!--                      </button>-->
<!--                    </div>-->

                    <div class="form-inline ">
                      <div class="form-group ">
                        <button type="button" class="btn btn-xs btn-secondary" v-if="!isEditable" @click="goBack">Go
                          Back
                        </button>
                      </div>

                      <div class="form-group ">
                        <button type="button" class="btn btn-xs pmc-btn-warning ml-3" v-if="!isEditable"
                                @click="setEditable">Edit
                        </button>
                      </div>

                      <div class="form-group ">
                        <button type="button" class="btn btn-xs pmc-btn-tertia" v-if="isEditable" @click="save">Save
                        </button>
                      </div>

                      <div class="form-group ">
                        <button type="button" class="btn btn-xs pmc-btn-danger ml-3" v-if="isEditable" @click="cancel">Cancel
                        </button>
                      </div>

                    </div>

                    <!--       code           -->
                    <div class="form-group row mt-lg-5">
                      <label class="col-sm-3 col-form-label">Code</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input type="text"
                               :disabled="!isEditable"
                               v-model="document.code"
                               class="form-control form-control-sm"
                               placeholder="---"
                        />
                      </div>
                    </div>

                    <!--       name           -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Name</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          :disabled="!isEditable"
                          v-model="document.name"
                          class="form-control form-control-sm"
                          placeholder="---"
                        />
                      </div>
                    </div>

                    <!--       has expiry     -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Has Expiry</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input
                          type="checkbox"
                          :disabled="!isEditable"
                          v-model="document.hasExpiry"
                          class="form-check form-check-inline ml-2 "
                          placeholder="---"
                        />
                      </div>
                    </div>

                    <!--       validity       -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Validity</label>
                      <span class="col-form-label">:</span>

                      <!--  validity value   -->
                      <div class="col-sm-2">
                        <input
                          type="number"
                          min="0"
                          v-model="document.validityValue"
                          :disabled="!isEditable"
                          class="form-control form-control-sm text-center"
                          @input="inputValidity"
                          placeholder="e.g. 10-100"
                        />
                      </div>

                      <!-- validity key   -->
                      <div class="col-sm-4">
                        <select :disabled="!isEditable"
                                v-model="document.validityKey"
                                class="form-control form-control-sm">
                          <option :value="null" selected>--select--</option>
                          <option :value="validity.id" v-for="(validity,index) in validities" :key="validity.id">
                            <span> {{ validity.key.toUpperCase() }}</span>
                          </option>
                        </select>
                      </div>
                    </div>

                    <!--       type           -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Type</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input
                          v-if="!isEditable"
                          v-model="documentType"
                          type="text"
                          disabled
                          class="form-check form-control-plaintext ml-2 form-control-sm"
                          placeholder="---"/>
                        <select v-show="isEditable"
                                v-model="document.docType"
                                class="custom-select custom-select-sm">
                          <option :value="null">--select--</option>
                          <option :value="doctype.id" v-for="(doctype,index) in objDocTypes" :key="doctype.id"
                                  style="font-size: 14px;">
                            <span> {{ doctype.name }}</span>
                          </option>
                        </select>
                      </div>
                    </div>

                    <!--       category       -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Category</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input
                          type="text"
                          v-if="!isEditable"
                          v-model="documentCategory"
                          class="form-check form-control-plaintext ml-2 form-control-sm"
                          placeholder="---"/>
                        <select v-show="isEditable" class="custom-select custom-select-sm"
                                v-model="document.docCategory">
                          <option :value="null">--select--</option>
                          <option :value="category.id" v-for="(category,index) in objDocCategory" :key="category.id"
                                  style="font-size: 14px;">
                            <span> {{ category.name }}</span>
                          </option>
                        </select>
                      </div>
                    </div>

                    <!--       monitored      -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Monitored</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input
                          :disabled="!isEditable"
                          v-model="document.isMonitored"
                          type="checkbox"
                          class="form-check form-check-inline ml-2 "
                          placeholder="---"
                        />
                        <span></span>
                      </div>
                    </div>

                    <!--       need rank      -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Need Rank</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input
                          v-model="document.isNeedRank"
                          :disabled="!isEditable"
                          type="checkbox"
                          class="form-check form-check-inline ml-2 "
                          placeholder="---"
                        />
                        <span></span>
                      </div>
                    </div>

                    <!--       status          -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Status</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input
                          :disabled="!isEditable"
                          v-model="document.status"
                          type="checkbox"
                          class="form-check form-check-inline ml-2 "
                          placeholder="---"
                        />
                        <span></span>
                      </div>
                    </div>

                    <!--       created by           -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Created By</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input v-model="document.createdBy"
                               class="form-control form-control-plaintext"
                               disabled
                               type="text"
                               placeholder="---"
                        />
                      </div>
                    </div>

                    <!--       created at           -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Created at</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input v-model="document.createdAt"
                               class="form-control form-control-plaintext"
                               type="text"
                               disabled
                               placeholder="---"
                        />
                      </div>
                    </div>

                    <!--       updated by           -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Updated by</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input
                          v-model="document.updatedBy"
                          type="text"
                          disabled
                          class="form-control form-control-plaintext"
                          placeholder="---"
                        />
                      </div>
                    </div>

                    <!--       updated at           -->
                    <div class="form-group row mt-2">
                      <label class="col-sm-3 col-form-label">Updated at</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-5">
                        <input
                          v-model="document.updatedAt"
                          type="text"
                          disabled
                          class="form-control form-control-plaintext"
                          placeholder="---"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="selectedTab == 2">
                <div class="tab-pane fade" :class="{'show active' : selectedTab === 2}" id="document-ranks"
                     role="tabpanel" aria-labelledby="document-ranks-tab">
                  <div class="col-md-12 py-2">
                    Pre requisite
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {AppMixins} from '../../../../mixins/app.mixins';
import {AlertMixins} from '../../../../mixins/alert.mixins';
import {AlertService} from "@/service/alert.service";

export default {
  name: 'DocumentView',

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      documentId: this.$route.params.documentId,

      isEditable: false,
      validities: [
        {
          id: 1,
          key: 'days',
        },
        {
          id: 2,
          key: 'months',
        },
        {
          id: 3,
          key: 'years',
        },
        {
          id: 4,
          key: 'lifetime',
        },
      ],
      tabs: [
        {
          id: 1,
          name: 'Details',
          content: 'document-fields',
        },
        {
          id: 2,
          name: 'Pre-requisite',
          content: 'document-ranks',
        },
      ],
      selectedTab: 1,
      activeTabPane: 1,
    };
  },

  /** methods**/
  methods: {
    ...mapActions(
      [
        'getDocumentSetupKeys',
        'set_document',
        'update_document',
        'null_document',
      ],
    ),
    inputValidity(e) {
      let value = e.target.value;
      if (value > 100 || value == '00') {
        this.document.validityValue = Number.parseInt('0', 10);
      }
    },
    async selectTab(__tab) {
      if (this.selectedTab != __tab.id) {
        if (this.isEditable) {
          this.warningAlert(`Save any changes before proceed on ${_.toLower(__tab.name)}`);

        } else {
          this.selectedTab = __tab.id;
          this.isEditable = false; // re assure

        }
      }
    },

    setEditable() {
      this.isEditable = !this.isEditable;
    },

    async save() {
      switch (this.selectedTab) {
        case 1:
          let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update document category', 'question');
          if (await __update) {

            this.update_document(this.document).then((r) => {
              this.isEditable = false;
            });
          }
          break;

        case 2:
          //todo update methods for the document pre requisite.
          break;
      }
    },

    cancel() {
      this.isEditable = !this.isEditable;
      switch (this.selectedTab) {
        case 1:
          this.set_document(this.document.id)
          break;

        case 2:
          //TODO
          break;
      }
    },
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objDocTypes',
        'objDocCategory',
        'document',
      ],
    ),

    documentType: {
      get() {
        let __type = this.objDocTypes.find((val, index) => val.id === this.document.docType);
        return __type ? __type.name : null;
      },
      set(val) {
        this.document.docType = val ? val.id : null;
      },
    },

    documentCategory: {
      get() {
        let __category = this.objDocCategory.find((val, index) => val.id === this.document.docCategory);
        return __category ? __category.name : null;
      },

      set(val) {
        this.document.docCategory = val ? val : null;
      },
    },
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  created() {
    this.getDocumentSetupKeys();
    this.set_document(this.documentId);
  },

  /** watch **/
  watch: {
    selectedTab(val) {
    },
  },

  /** destroyed **/
  destroyed() {
  },

};
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(2) !important;
}

.isDisabledTab {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.selected-tab {
  color: #1FB17C !important;
  font-weight: bold !important;
}
</style>
