import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  checklistCategory: {},
  checklistCategories: [],
  newChecklistCategory: {},

  checklistCategoryFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },

  checklistCategoryPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
};


const mutations = {
  set_checklistCategories(state, params) {
    state.checklistCategories = params
  },

  set_checklistCategory(state, params) {
    state.checklistCategory = params
  },


  create_checklistCategory(state) {
    state.newChecklistCategory = {};
  },

  update_checklistCategory(state, params) {
    let signatoryTypes = state.checklistCategories.find((val, index) => val.id === params.id)
    Object.assign(signatoryTypes, params)
  },

  delete_checklistCategory(state, params) {
    let index = state.checklistCategories.findIndex((val, index) => val.id === params)
    state.checklistCategories.splice(index, 1)
  },


  set_checklistCategory_pagination(state, params) {
    state.checklistCategoryPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {
  async set_checklistCategories({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.CREW_CHECKLIST_DETAILS_CATEGORY, {params}).then((r) => {
      commit('set_checklistCategories', r.data.data)
      commit('set_checklistCategory_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get checklist categories')
      return false
    })
  },

  async set_checklistCategory({commit}, param) {
    return axios.get(`${PMC.CREW_CHECKLIST_DETAILS_CATEGORY}/${param}`).then((r) => {
      commit('set_checklistCategory', r.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Get checklist category');
      return false;
    });
  },

  async create_checklistCategory({commit}, params) {
    return axios.post(PMC.CREW_CHECKLIST_DETAILS_CATEGORY, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_checklistCategory', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create checklist category');
      return false;
    })
  },

  async update_checklistCategory({commit}, params) {
    return axios.put(`${PMC.CREW_CHECKLIST_DETAILS_CATEGORY}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_checklistCategory',r.data.updatedChecklistCategory);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update checklist category');
      return false;

    });
  },

  async delete_checklistCategory ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete checklist category?','question');
    if(await _del){
      return axios.delete(`${PMC.CREW_CHECKLIST_DETAILS_CATEGORY}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete checklist category');
        commit('delete_checklistCategory',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete checklist category");
        return false;

      })
    }
  },
};


const getters = {
  checklistCategories: state => UtilityService.capitalizeProperties(state.checklistCategories),
  checklistCategory: state => UtilityService.capitalizeProperties(state.checklistCategory),

  checklistCategoryFormParam: state => state.checklistCategoryFormParam,
  checklistCategoryPagination: state => state.checklistCategoryPagination,

};


/** export **/
export default {state, actions, mutations, getters};
