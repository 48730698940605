<template>
  <div class="card">
    <div class="card-header">
      <label >Crew Change Remark</label>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="card">
          <div class="card-body">

          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">

    </div>
  </div>
</template>

<script>
export default {
  name: "CrewChangeRemark",
  mixins: [],

  props:{
    crewChangeId : {
      type : Number,
      default : 0,
    }
  },

  /** data **/
  data() {
    return {}
  },

  /**  computed  **/
  computed: {},


  /** methods**/
  methods: {},


  /** mounted **/
  mounted() {
  },

  /** created **/
  created() {
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed() {
  },
}
</script>

<style scoped>

</style>
