import axios from "axios";
import {UtilityService} from "@/service/utility.service";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {commit} from "lodash/seq";
import {FileService} from "@/service/file.service";


const initStateOnboardCrewList = () => {
  return {
    onboardCrew: {},
    onboardCrews: [],
    onboardCrewDetails: {},
  }
};

const state = {
  ...initStateOnboardCrewList()
};



const mutations = {
  set_onboardCrews(state, params) {
    state.onboardCrews = params
  },

  set_onboardCrewDetails(state, params) {
    state.onboardCrewDetails = params
  },

  reset_onboardCrews(){
    state.onboardCrews = [];
  },

  reset_onboardCrewDetails(){
    state.onboardCrewDetails = {};
  }

};


const actions = {

  set_onboardCrews({commit}, params) {
    commit('set_is_loading', true)
    return axios.get(PMC.GET_CREW_ONBOARD_LIST, {params}).then((r) => {
      commit('set_onboardCrews', r.data.data)
      commit('set_onboardCrewDetails', r.data.onboardCrewDetails)
      console.log(r.data.data, 'r.data.data')
      commit('set_is_loading', false)
      return true;

    }).catch((er) => {
      commit('set_is_loading', false)
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get Onboard Crews')
      }
      return false;
    })
  },

  async generateOnboardCrewList({commit}, params) {
    return await axios.post(PMC.GENERATE_ONBOARD_CREW_LIST, params).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
      return false
    });
  },

  resetOnboardCrews({commit}){
    commit('reset_onboardCrews');
  },

  resetOnboardCrewDetails ({commit}) {
    commit('reset_onboardCrewDetails');
  },


};


const getters = {
  onboardCrews: state => UtilityService.capitalizeProperties(state.onboardCrews),
  onboardCrewDetails: state => UtilityService.capitalizeProperties(state.onboardCrewDetails),
};


/** export **/
export default {state, actions, mutations, getters};
