import axios from "axios";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";

const initStateScholarMonitoring = () => {
  return {
    scholar: {},
    scholarMonitoringRemark: {},
    scholars: [],
    scholarCount: null,

    scholarFormParam: {
      searchValue: null,
      type: null,
      filterType: 'all',
      count: 10,
      status: null,
      page: 0,
      rankId: null,
      groupFilterId: null,
      crewType: null,
    },

    scholarPagination: {
      from: 1,
      currentPage: 1,
      lastPage: 0,
      perPage: 10,
      to: 9,
      total: 0,
    },


  }
};

const state = {
  ...initStateScholarMonitoring()
};

const mutations = {
  set_scholars(state, params) {
    state.scholars = params
  },

  set_scholarMonitoringRemark(state, params) {
    state.scholarMonitoringRemark = params
  },

  set_scholarCount(state, params) {
    state.scholarCount = params
  },

  set_scholar_pagination(state, params) {
    state.scholarPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },

  update_scholar_monitoring_remark(state, params) {
    let scholarMonitoringRemark = state.scholars.find((val, index) => val.crewId === params.crewId)
    Object.assign(scholarMonitoringRemark, params)
  },

};


const actions = {
  async set_scholarsMonitoringList({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GET_SCHOLAR_MONITORING_LIST, {params}).then((r) => {
      commit('set_scholars', r.data.data)
      commit('set_scholar_pagination', r.data)
      commit('set_scholarCount', r.data.total)
      commit('set_is_loading', false)
      return true;

    }).catch((er) => {
      commit('set_is_loading', false)
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get scholars')
      }
      return false;
    })
  },

  async generateScholarMonitoringList({commit}, params) {
    return await axios.post(PMC.GENERATE_SCHOLAR_MONITORING_LIST, params).then(r => {
      return r.data.data ? FileService.base64FileToBlobUrl(r.data.data) : false;
    }).catch(error => {
      UtilityService.generateResponseMessage(error, 'Generate scholars list')
      return false
    });
  },

  async set_scholar_monitoring_remark({commit}, param) {
    return await axios.get(`${PMC.FIND_SCHOLAR_MONITORING_REMARK}/${param}`).then((r) => {
      commit('set_scholarMonitoringRemark', r.data);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Get scholar monitoring remark');
      return false;
    });
  },

  async update_scholar_monitoring_remark({commit}, params) {
    return axios.post(`${PMC.UPDATE_SCHOLAR_MONITORING_REMARK}/${params.id}`, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('update_scholar_monitoring_remark', r.data.updatedScholarMonitoringRemark);
      return true;
    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Update scholar monitoring remark');
      return false;

    });
  },


};

const getters = {
  scholars: state => UtilityService.capitalizeProperties(state.scholars),

  scholarPagination: state => state.scholarPagination,
  scholarFormParam: state => state.scholarFormParam,
  scholarCount: state => state.scholarCount,
  scholarMonitoringRemark: state => state.scholarMonitoringRemark,
};


export default {state, actions, mutations, getters};

