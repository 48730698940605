<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"style="font-size: 22px;">Crew Pool Sea Service Lists</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.rankId"
                                required>
                          <option :value="0">-- select rank --</option>
                          <option v-for="(rank, index) in objRanks" :value="rank.id">{{rank.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mt-1">
                      <label for="list-count" class="col-sm-4 col-form-label">VESSEL</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.vesselId"
                                required>
                          <option :value="0">-- select vessel --</option>
                          <option v-for="(vessel, index) in objVessels" :value="vessel.id">{{vessel.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="getCrewPoolSeaServiceLists(false)" :disabled="isLoading === true">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                </div>
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          @click="getCrewPoolSeaServiceLists(true)" :disabled="isLoading === true">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
            <div class="row ml-2">
              <div class="col-sm-3">
                <div class="ml-1 row text-left ">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-form-label">FILTER BY : <b class="pl-2" style="font-size: 1.1em;">{{
                          filteredBy
                        }}</b></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table
                       class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th style="width: 2%"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortByCode', false, 'changecode_id', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortByCode'"
                        :object-data="crewData"
                      />
                      Code
                    </th>
                    <th style="width: 15%"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortBySeafarerName', true, 'last_name', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortBySeafarerName'"
                        :object-data="crewData"
                      />
                      Seafarer Name
                    </th>
                    <th style="width: 7%">Rank on Vessel</th>
                    <th style="width: 6%;"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortByAge', true, 'age', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortByAge'"
                        :object-data="crewData"
                      />
                      Age
                    </th>
                    <th style="width: 15%;"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortBySeaServiceStatus', true, 'crew_sea_service_status', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortBySeaServiceStatus'"
                        :object-data="crewData"
                      />
                      Sea Service Status
                    </th>

                    <th style="width: 10%"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortByRL', false, 'countRL', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortByRL'"
                        :object-data="crewData"
                      />
                      # OF RECO LETTERS
                    </th>

                    <th style="width: 10%;"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortByLicense', true, 'license_document', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortByLicense'"
                        :object-data="crewData"
                      />
                      License
                    </th>
                    <th style="width: 7%;"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortByUsVisaExp', true, 'us_visa_expiry_sort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortByUsVisaExp'"
                        :object-data="crewData"
                      />
                      US Visa EXP
                    </th>
                    <th style="width: 7%;"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortBySirbExp', true, 'sirb_expiry_sort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortBySirbExp'"
                        :object-data="crewData"
                      />
                      SIRB EXP
                    </th>
                    <th style="width: 7%;"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortByPportExp', true, 'passport_expiry_sort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortByPportExp'"
                        :object-data="crewData"
                      />
                      PPORT EXP
                    </th>
                    <th style="width: 9%;"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortByCocCoeExp', true, 'coc_coe_expiry_sort', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortByCocCoeExp'"
                        :object-data="crewData"
                      />
                      COC/COE EXP
                    </th>
                    <th style="width: 10%;"
                        @click="columnSort(crewPoolSeaServiceLists, 'sortByCrewType', true, 'crew_type', crewData)"
                    >
                      <sort-arrow
                        :sort-by-object="crewPoolSeaServiceLists"
                        :sort-by-value="'sortByCrewType'"
                        :object-data="crewData"
                      />
                      CREWTYPE
                    </th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="crewData.length !== 0">
                      <template v-for="(crew, index) in crewData">
                        <tr>
                          <td> {{ index + 1 }}</td>
                          <td> {{ crew.changecode_id }}</td>
                          <td>
                            <a href="#"
                               @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                               style="color:black;">
                              <small><b>{{ getFullName(crew) }} </b></small>
                            </a>
                          </td>
                          <td>{{ crew.rank_on_vessel }}</td>
                          <td>{{ crew.age }}</td>
                          <td class="text-capitalize">{{ crew.crew_sea_service_status }}</td>
                          <td :class="crew.countRL > 1  ? 'recommendation-letter' : ''">
                            {{ crew.countRL !== 0 ? `(` + crew.countRL + `)` + `-` +  (crew.countRL > 1 ? `YES` : `NO`) : 'N/A'  }}
                          </td>
                          <td>{{ crew.license_document }}</td>
                          <td :class="isDocumentExpire(crew.us_visa_expiry) === true ? 'expired' : ''">{{ crew.us_visa_expiry }}</td>
                          <td :class="isDocumentExpire(crew.sirb_expiry) === true ? 'expired' : ''">{{ crew.sirb_expiry }}</td>
                          <td :class="isDocumentExpire(crew.passport_expiry) === true ? 'expired' : ''">{{ crew.passport_expiry }}</td>
                          <td :class="isDocumentExpire(crew.coc_coe_expiry) === true ? 'expired' : ''">{{ crew.coc_coe_expiry }}</td>
                          <td>{{ crew.crew_type }}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import {FileService} from "@/service/file.service";
import _ from "lodash";

export default {
  name: "CrewPoolSeaServiceLists",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      reportParam: {
        rankId: 0,
        vesselId : 0,
        isPDF: false,
        filtered_by : ''
      },
      crewPoolSeaServiceLists : {
        sortBySeafarerName: '',
        sortByCode : '',
        sortByRankOnVessel : '',
        sortByAge : '',
        sortByRL : '',
        sortBySeaServiceStatus : '',
        sortByLicense : '',
        sortByUsVisaExp : '',
        sortBySirbExp : '',
        sortByPportExp : '',
        sortByCocCoeExp : '',
        sortByCrewType : '',
      },
      isLoading: false,
      crewData: []
    }
  },


  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
      'getOwnerKeys',
    ]),

    getCrewPoolSeaServiceLists(printReport) {
      if (this.reportParam.rankId === 0) {
        return this.errorAlert('Please Select Rank', '');
      }
      if (this.reportParam.vesselId === 0) {
        return this.errorAlert('Please Select Vessel', '');
      }

      if (printReport === false) {
        this.isLoading = true;
      } else {
        AlertService.loadingAlert('Please wait ...', 'Generating PDF for Crew Pool Sea Service Lists');
      }
      this.reportParam.isPDF = printReport;
      this.reportParam.filtered_by = this.filteredBy;
      axios.post(PMC.GET_CREW_POOL_SEA_SERVICE_LISTS, this.reportParam)
        .then(result => {
          this.crewData = result.data.crews;
          if (printReport === false) {
            this.isLoading = false;
          } else {
            let fScr = FileService.base64FileToBlobUrl(result.data.printed_data);
            Swal.close();
            this.pdfViewer('File', fScr);
          }
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate crew pool sea service lists');
        Swal.close();
        return false;
      });

    },
    getFullName(crew) {
      let firstName = crew.first_name ?? null;
      let middleInitial = crew.middle_initial ?? null;
      let lastName = crew.last_name ?? null;
      let suffixName = crew.suffix_name ?? null;

      if (suffixName !== null) {
        return suffixName + ' ' +  lastName + ', ' + firstName + ' ' + middleInitial;
      }
      return lastName + ', ' + firstName + ' ' + middleInitial;
    },
    isDocumentExpire(date_expiry) {
      let dateExpiry = new Date(date_expiry)
      let currentDate = new Date();
      let dateDiffInMilliseconds = dateExpiry - currentDate;
      let dateDiffInDays = dateDiffInMilliseconds / (1000 * 60 * 60 * 24);
      return dateDiffInDays < 1;
    }
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'objVessels',
        'objRanks'
      ]),
    rankSelected() {
      let rank = _.find(this.objRanks, (vesselData) => vesselData.id === this.reportParam.rankId);
      return rank ? rank.name : '';
    },
    vesselSelected() {
      let vessel = _.find(this.objVessels, (vesselData) => vesselData.id === this.reportParam.vesselId);
      return vessel ? vessel.name : '';
    },
    filteredBy() {
      let filteredBy = this.rankSelected;
      if (this.rankSelected === '' && this.vesselSelected !== '') {
        filteredBy = this.vesselSelected;
      } else if (this.rankSelected !== '' && this.vesselSelected !== '') {
        filteredBy = this.rankSelected + ' / ' + this.vesselSelected;
      }
      return filteredBy;
    }
  },

  /** created **/
  async created() {
    await this.getCrewComplimentPerVessel();
    await this.getOwnerKeys();
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}

.expired {
  color: red;
}

.recommendation-letter {
  background: green;
  color: white;
}
</style>
