<template>
  <div class="bg-white">
    <index>
      <!--  top header  -->
      <template v-slot:top-nav-header>
        <TopNav></TopNav>
      </template>

      <!--  container   -->
      <template v-slot:container>
        <div class="bg-white">
          <router-view></router-view>
        </div>
      </template>

      <!--  for searching crew    -->
      <template v-slot:modal-crew-searching>
        <pmc-modal :id="searchingModal" :lg_width="true" :xl_height="true" @closingEvent="reset_search_crew_param">
          <template v-slot:custom-header>
            <h6>Search result for </h6> <span class="ml-4 pmc-bg-warning">{{ searchingFor }}</span>
          </template>

          <template v-slot:custom-body>
            <div class="row mt-2">
              <div class="col-md-9">
                <pmc-pagination :page-object="searchCrewPagination" v-on:settingPage="set_this_page"></pmc-pagination>
              </div>

              <div class="col-md-3">
                <select class="custom-select custom-select-sm w-50" id="Count" v-model="searchParams.count"
                        v-on:change="search_crew">
                  <option :value="count" v-for="(count,index) in listCount" :selected="count == searchParams.count">
                    {{ count }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-12">
                <pmc-table :max-height="450" :loading="isReloading" :loader-colspan="5">
                  <template slot="table-header">
                    <tr>
                      <th class="border-bottom-0 border-right-0 border-top-0" style="width:2%;">
                        <font-awesome-icon icon="filter"/>
                      </th>

                      <!-- search rank -->
                      <th class="border-bottom-0 border-right-0 border-top-0" style="width:10%;">
                        <input
                          id="search-rank"
                          name="SearchRank"
                          style="font-size: 0.7em;"
                          type="text"
                          class="form-control border small"
                          placeholder="Rank .."
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                          v-model="searchParams.sRank"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Press enter to search"
                          @keypress.enter="on_key_press">
                      </th>

                      <!-- lastname -->
                      <th class="border-bottom-0 border-right-0 border-top-0" style="width:20%;">
                        <input
                          id="search-lastname"
                          name="SearchLastName"
                          style="font-size: 0.7em;"
                          type="text"
                          class="form-control border small"
                          placeholder="Last Name .."
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                          @keypress.enter="on_key_press"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Press enter to search"
                          v-model="searchParams.sLastName">
                      </th>

                      <!-- first name -->
                      <th class="border-bottom-0 border-right-0 border-top-0" style="width:20%;">
                        <input
                          id="search-firstname"
                          name="SearchFirstName"
                          style="font-size: 0.7em;"
                          type="text"
                          class="form-control border small"
                          placeholder="First Name .."
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                          v-model="searchParams.sFirstName"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Press enter to search"
                          @keypress.enter="on_key_press">
                      </th>

                      <!-- middle name -->
                      <th class="border-bottom-0 border-top-0" style="width:20%;">
                        <input
                          id="search-middlename"
                          name="SearchMiddleName"
                          style="font-size: 0.7em;"
                          type="text"
                          class="form-control border small"
                          placeholder="Middle Name .."
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                          @keypress.enter="on_key_press"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Press enter to search"
                          v-model="searchParams.sMiddleName">
                      </th>
                    </tr>
                    <tr>
                      <th class="border-bottom-0 border-right-0">#</th>
                      <th class="border-bottom-0 border-right-0">Rank</th>
                      <th class="border-bottom-0 border-right-0">Last Name</th>
                      <th class="border-bottom-0 border-right-0">First Name</th>
                      <th class="border-bottom-0">Middle Name</th>
                    </tr>
                  </template>

                  <template slot="table-body">
                    <tr v-for="(crew,index) in crews" :key="crew.id" class="text-left pl-3"
                        v-on:click="go_to_profile(crew.id)">
                      <td class="border-top-0 border-right-0">{{ index + searchCrewPagination.from }}</td>
                      <td class="pl-2 border-top-0 border-right-0">{{ crew.rank ? crew.rank : null }}</td>
                      <td class="pl-2 border-top-0 border-right-0">{{ crew.lastName }}</td>
                      <td class="pl-2 border-top-0 border-right-0">{{ crew.firstName }}</td>
                      <td class="pl-2 border-top-0">{{ crew.middleName }}</td>
                    </tr>
                  </template>

                </pmc-table>
              </div>
            </div>
          </template>
        </pmc-modal>
      </template>

    </index>


  </div>
</template>

<script>

import Index from '../components/app/Index';
import TopNav from '../components/app/layout/TopNav';
import Modal from '../components/base/Modal';
import Table from '../components/base/Table';
import Pagination from '../components/base/Pagination';
import {mapActions, mapGetters} from 'vuex';
import {AppMixins} from '../mixins/app.mixins';
import {AlertMixins} from "@/mixins/alert.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";

export default {
  name: 'Home',
  mixins: [AppMixins],
  components: {
    // Modal,
    Index,
    TopNav,
    // Table,
    // Pagination
  },

  data() {
    return {
      searchingModal: 'searchCrew',
      listCount: [5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 100, 200],
      onSearching: null,
    };
  },

  methods: {
    ...mapActions(
      [
        'set_sFirstName',
        'set_sMiddleName',
        'set_sLastName',
        'set_sRank',
        'resetSearchCrewParam',
        'setNullCrews',
        'resetSearchCrewPagination',
        'getAlLCrewPaginated',
        'set_crew_sea_services'
      ],
    ),

    on_key_press(e) {
      if ((this.searchParams.sFirstName || this.searchParams.sMiddleName || this.searchParams.sLastName) !== null) {
        this.searchParams.searchKey = null;
        this.searchParams.searchValue = null;
      }

      this.onSearching = e.target.value;
      this.searchParams.page = 1;
      this.search_crew();
    },

    search_crew() {
      this.getAlLCrewPaginated(this.searchParams);
    },

    reset_search_crew_param() {
      this.onSearching = null;
      this.resetSearchCrewPagination();
      this.setNullCrews();
      this.resetSearchCrewParam();
    },

    set_this_page(e) {
      this.searchCrewPagination.currentPage = e;
      this.searchParams.page = e;
      this.search_crew();
    },

    async go_to_profile(crewId) {

      if (this.$route.params.crewId != crewId) {

        this.hideModal(this.searchingModal);
        this.resetSearchCrewParam();

        AlertService.loadingAlert('Please wait ...', 'Loading Crew Profile')

        await this.$nextTick(async () => { // Mark the callback function as async
          await this.$router.push({
            name: 'CrewProfile',
            params: {crewId: crewId},
          });

          let crewData = await this.set_crew_sea_services({crewId: crewId});
          if (crewData) {
           Swal.close();
          }
        });

      }else{
        await AlertService.infoAlertService("You are already in the page you are searching for", 'Crew Profile');

      }

    },

    goto_changePass() {
      this.$router.push({name: 'ChangePassword'});
    },
  },

  computed: {
    ...mapGetters(
      [
        'searchParams',
        'crews',
        'searchCrewPagination',
        'b4rExpired',
        'isReloading'
      ],
    ),

    searchingFor() {
      if (this.searchParams.searchValue && this.onSearching == null) {
        return this.searchParams.searchValue;
      }
      if (this.onSearching != null) {
        return this.onSearching;
      }
    },
  },

  created() {
  },

  mounted() {
    if (this.b4rExpired == 0) {
      this.goto_changePass();
    }

    $('[data-toggle="tooltip"]').tooltip();
  },

  destroyed() {
  },
};
</script>


<style lang="scss">

</style>
