import axios from 'axios';
import { UtilityService } from '../../../service/utility.service'
import {PMC} from '../../../config/constants.config';

/** state **/
const state = {
  //for address making
  permanent : {
    objRegions: [],  //default
    objProvince: [],
    objCity: [],
    objBarangay: [],
  },

  temporary : {
    objRegions: [],  //default
    objProvince: [],
    objCity: [],
    objBarangay: [],
  },

  province : {
    objRegions: [],  //default
    objProvince: [],
    objCity: [],
    objBarangay: [],
  }
}

/** mutations **/
const mutations = {
  /**  permanent  */
  set_permanent_region(state,params){
    state.permanent.objRegions = params;
  },

  set_permanent_province(state,params){
    state.permanent.objProvince = params;
  },

  set_permanent_city(state,params){
    state.permanent.objCity = params;
  },

  set_permanent_barangay(state,params){
    state.permanent.objBarangay = params;
  },

  null_permanent_regions(state){
    state.permanent.objRegions = [];
  },

  null_permanent_province(state){
    state.permanent.objProvince = [];
  },

  null_permanent_cities(state){
    state.permanent.objCity = [];
  },

  null_permanent_barangay(state){
    state.permanent.objBarangay = [];
  },


  /**  temporary  ***/
  set_temporary_region(state,params){
    state.temporary.objRegions = params;
  },

  set_temporary_province(state,params){
    state.temporary.objProvince = params;
  },

  set_temporary_city(state,params){
    state.temporary.objCity = params;
  },

  set_temporary_barangay(state,params){
    state.temporary.objBarangay = params;
  },

  null_temporary_regions(state){
    state.temporary.objRegions = [];
  },

  null_temporary_provinces(state){
    state.temporary.objProvince = [];
  },

  null_temporary_cities(state){
    state.temporary.objCity = [];
  },

  null_temporary_barangay(state){
    state.temporary.objBarangay = [];
  },


  /** province  **/
  set_province_region(state,params){
    state.province.objRegions = params;
  },

  set_province_province(state,params){
    state.province.objProvince = params;
  },

  set_province_city(state,params){
    state.province.objCity = params;
  },

  set_province_barangay(state,params){
    state.province.objBarangay = params;
  },

  null_prov_regions(state){
    state.province.objRegions  = [];
  },


  null_prov_provinces(state){
    state.province.objProvince = [];
  },

  null_prov_cities(state){
    state.province.objCity = [];
  },

  null_prov_barangay(state){
    state.province.objBarangay = [];
  }
}

/** actions **/
const actions = {

//region is static, for permanent,temporary, province
 async getRegionAddress({commit}){
    return await axios.get(PMC.GET_REGION_KEYS).then((r)=>{
      commit('set_permanent_region',r.data);
      commit('set_temporary_region',r.data);
      commit('set_province_region',r.data);
      return true;

    }).catch((er)=>{
      UtilityService.failProgressBar();
      return false;
    });
  },


  /** PERMANENT ***/
  getPermanentProvinceKeys({commit},params){
    return  axios.get(PMC.GET_PROVINCE_KEYS,{params}).then((r)=>{
      commit('set_permanent_province',r.data);
      return true;

    }).catch((er)=>{

      UtilityService.failProgressBar();
      return false;
    });
  },

  getPermanentCityKeys({commit},params){
    return  axios.get(PMC.GET_CITY_KEYS,{params}).then((r)=>{
      commit('set_permanent_city',r.data);
      return true;

    }).catch((er)=>{

      UtilityService.failProgressBar();
      return false;
    });
  },

  getPermanentBarangay({commit},params){
    return  axios.get(PMC.GET_BARANGAY_KEYS,{params}).then((r)=>{
      commit('set_permanent_barangay',r.data);
      return true;
    }).catch((er)=>{
      UtilityService.failProgressBar();
      return false;
    });
  },

  null_permanent_regions ({commit}) {
    commit('null_permanent_regions');
  },

  null_permanent_province ({commit}) {
    commit('null_permanent_province');
  },

  null_permanent_cities ({commit}) {
    commit('null_permanent_cities');
  },

  null_permanent_barangay ({commit}) {
    commit('null_permanent_barangay');
  },


  /** TEMPORARY **/
  getTemporaryProvinceKeys({commit},params){
    return  axios.get(PMC.GET_PROVINCE_KEYS,{params}).then((r)=>{
      commit('set_temporary_province',r.data);
      return true;
    }).catch((er)=>{
      UtilityService.failProgressBar();
      return false;
    });
  },

  getTemporaryCityKeys({commit},params){
    return  axios.get(PMC.GET_CITY_KEYS,{params}).then((r)=>{
      commit('set_temporary_city',r.data);
      return true;
    }).catch((er)=>{
      UtilityService.failProgressBar();
      return false;
    });
  },

  getTemporaryBarangayKeys({commit},params){
    return  axios.get(PMC.GET_BARANGAY_KEYS,{params}).then((r)=>{
      commit('set_temporary_barangay',r.data);
      return true;
    }).catch((er)=>{
      UtilityService.failProgressBar();
      return false;
    });
  },

  null_temporary_regions ({commit}) {
    commit('null_temporary_regions');
  },

  null_temporary_provinces ({commit}) {
    commit('null_temporary_provinces');
  },

  null_temporary_cities ({commit}) {
    commit('null_temporary_cities')
  },

  null_temporary_barangay ({commit}) {
    commit('null_temporary_barangay');
  },



  /** PROVINCE **/
  getProvinceAddressKeys({commit},params){
    return  axios.get(PMC.GET_PROVINCE_KEYS,{params}).then((r)=>{
      commit('set_province_province',r.data);
      return true;
    }).catch((er)=>{
      UtilityService.failProgressBar();
      return false;
    });
  },

  getProvinceCityKeys({commit},params){
    return  axios.get(PMC.GET_CITY_KEYS,{params}).then((r)=>{
      commit('set_province_city',r.data);
      return true;
    }).catch((er)=>{
      UtilityService.failProgressBar();
      return false;
    });
  },

  getProvinceBarangayKeys({commit},params){
    return  axios.get(PMC.GET_BARANGAY_KEYS,{params}).then((r)=>{
      commit('set_province_barangay',r.data);
      return true;
    }).catch((er)=>{
      UtilityService.failProgressBar();
      return false;
    });
  },


  null_prov_regions ({commit}) {
    commit('null_prov_regions');
  },

  null_prov_provinces ({commit}) {
    commit('null_prov_provinces');
  },


  null_prov_cities ({commit}) {
    commit('null_prov_cities');
  },

  null_prov_barangay ({commit}) {
    commit('null_prov_barangay');
  }

}

/** getters **/
const getters = {
  //permanent
  permanentRegions: state => state.permanent.objRegions,
  permanentProvinces: state => state.permanent.objProvince,
  permanentCities: state => state.permanent.objCity,
  permanentBarangays: state => UtilityService.capitalizeProperties(state.permanent.objBarangay),

  //temporary
  temporaryRegions : state => state.temporary.objRegions,
  temporaryProvinces : state => state.temporary.objProvince,
  temporaryCities : state => state.temporary.objCity,
  temporaryBarangays : state => UtilityService.capitalizeProperties(state.temporary.objBarangay),

  //province
  provRegions: state => state.province.objRegions,
  provProvinces: state => state.province.objProvince,
  provCities: state => state.province.objCity,
  provBarangays: state => UtilityService.capitalizeProperties(state.province.objBarangay),

}

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
}
