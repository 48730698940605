<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"style="font-size: 22px;">Vessel List</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">REPORT TYPE</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="list-type"
                                class="custom-select custom-select-sm"
                                v-model="reportParam.reportType"
                                @change="selectReportType"
                                required>
                          <option :value="1">Active</option>
                          <option :value="2">In-active</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          @click="getVesselReport">
                    <font-awesome-icon icon="file"/>
                    Generate Report
                  </button>
                  <button type="button" class="btn btn-xs pmc-btn-danger ml-1"
                          @click="printReport">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table v-if="reportParam.reportType === 1" class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th >#</th>
                    <th>
                      Name of Vessel
                    </th>
                    <th
                      style="width: 10%;">
                      Manning
                    </th>
                    <th >Owner</th>
                    <th >Flag State</th>
                    <th >Type of Vessel</th>
                    <th >Main Engine</th>
                    <th >GRT</th>
                    <th >KW</th>
                    <th >Route</th>
                    <th >Year Built</th>
                    <th >IMO No</th>
                    <th >ECDIS Type</th>
                    <th >ECDIS Remarks</th>
                    <th >CBA</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else >
                    <template v-if="hasData" >
                      <template v-for="(vessel, index) in vesselData">
                        <tr class="vessel-data">
                          <td> {{index + 1}} </td>
                          <td class="text-left pl-2"> {{vessel.nameStr}} </td>
                          <td> {{vessel.manningName}} </td>
                          <td> {{vessel.ownerName}} </td>
                          <td> {{vessel.flagName}} </td>
                          <td> {{vessel.vesselTypeName}} </td>
                          <td> {{vessel.engineTypeName}} </td>
                          <td> {{vessel.grt}} </td>
                          <td> {{vessel.kw}} </td>
                          <td> {{vessel.route}} </td>
                          <td> {{vessel.yearBuilt}} </td>
                          <td> {{vessel.imo}} </td>
                          <td> {{vessel.ecdisType}} </td>
                          <td> {{vessel.ecdisRemarks}} </td>
                          <td> {{vessel.cba}} </td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                          No Data
                        </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
                <table v-if="reportParam.reportType === 2" class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th
                      @click="columnSort(vesselListSort, 'sortByVessel', true, 'name', vesselData)"
                      style="width: 10%;">
                      <sort-arrow
                        :sort-by-object="vesselListSort"
                        :sort-by-value="'sortByVessel'"
                        :object-data="vesselData"
                      />
                      VESSEL
                    </th>
                    <th
                      @click="columnSort(vesselListSort, 'sortByFlag', true, 'flagName', vesselData)"
                    >
                      <sort-arrow
                        :sort-by-object="vesselListSort"
                        :sort-by-value="'sortByFlag'"
                        :object-data="vesselData"
                      />
                      Flag
                    </th>
                    <th
                      @click="columnSort(vesselListSort, 'sortByType', true, 'vesselTypeName', vesselData)"
                    >
                      <sort-arrow
                        :sort-by-object="vesselListSort"
                        :sort-by-value="'sortByType'"
                        :object-data="vesselData"
                      />
                      Type
                    </th>
                    <th
                      @click="columnSort(vesselListSort, 'sortByGrt', true, 'grt', vesselData)"
                    >
                      <sort-arrow
                        :sort-by-object="vesselListSort"
                        :sort-by-value="'sortByGrt'"
                        :object-data="vesselData"
                      />
                      GRT
                    </th>
                    <th>KW</th>
                    <th>Route</th>
                    <th>Built</th>
                    <th>IMO No</th>
                    <th>CBA</th>
                  </tr>
                  </thead>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else >
                    <template v-if="hasData" >
                      <template v-for="(vessels, index) in vesselData">
                          <tr v-if="vessels.name">
                            <td colspan="100%" style="text-align: left;">
                              <span style="font-weight: bold;text-align: left;">
                                {{vessels.name}}
                              </span>
                            </td>
                          </tr>
                          <template v-for="(vessel, index1) in vessels.vessels">
                            <tr>
                              <td v-if="vessel.index"> {{vessel.index}} </td>
                              <td v-else> {{(index1 + 1)}} </td>
                              <td class="text-left pl-2"> {{vessel.nameStr}} </td>
                              <td> {{vessel.flagName}} </td>
                              <td> {{vessel.vesselTypeName}} </td>
                              <td> {{vessel.grt}} </td>
                              <td> {{vessel.kw}} </td>
                              <td> {{vessel.route}} </td>
                              <td> {{vessel.yearBuilt}} </td>
                              <td> {{vessel.imo}} </td>
                              <td> {{vessel.cba}} </td>
                            </tr>
                          </template>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="100%">
                          <span style="size: 20px;font-weight: bold;color: red;">
                            No Data
                          </span>
                        </td>
                      </tr>
                    </template>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="modal fade" id="generatingAnimationModal" tabindex="-1" role="dialog" data-backdrop="static"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h4 class="h4 text-center mb-3 text-primary">Generating PDF</h4>

            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {mapActions, mapGetters} from "vuex";
import {global_variables_config} from "@/config/global-variable.config";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {FileService} from "@/service/file.service";
import {CommonMixins} from "@/mixins/common.mixins";

export default {
  name: "VesselActiveInactiveList",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      isEditable: false,
      isGenerateOnboardButton: false,
      reportParam: {
        reportType: 1,
        isPDF: false,
      },
      isLoading: false,
      hasData: false,
      vesselData: null,
      count: 0,
      vesselListSort: {
        sortByNameOfVessel: '',
        sortByManning: '',
        sortByOwner: '',
        sortByFlagState: '',
        sortByTypeOfVessel: '',
        sortByMainEngine: '',
        sortByGrt: '',
        sortByKw: '',
        sortByRoute: '',
        sortByYearBuilt: '',
        sortByImoNo: '',
        sortByEcdisType: '',
        sortByEcdisRemarks: '',
        sortByCba: '',
        sortByVessel: '',
        sortByFlag: '',
        sortByType: '',
        sortByBuilt: '',
      },
    }
  },


  /** methods**/
  methods: {

    async getVesselReport(){
        this.reportParam.isPDF = false;
        this.hasData = false;
        let params = this.reportParam;
        return await axios.post(PMC.GET_VESSEL_LIST_ACTIVE_INACTIVE, params).then(async r => {
          console.log(r.data);
          if (Object.keys(r.data).length > 0) {
            this.hasData = true;
            this.vesselData = r.data;
          } else {
            this.hasData = false;
            this.vesselData = null;
          }
          console.log(this.hasData);
          return r.data;
        }).catch(error => {
          UtilityService.generateResponseMessage(error, 'Generate onboard crew list')
          return false
        });

    },

    selectReportType(){
      this.hasData = false;
    },

    async printVesselReport() {
      this.reportParam.isPDF = true;
      let params = this.reportParam;
      return await axios.post(PMC.GET_VESSEL_LIST_ACTIVE_INACTIVE, params).then(async r => {
        this.isLoading = false;
        return  r.data ? FileService.base64FileToBlobUrl(r.data.pdf) : false;
      }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Vessel List')
        return false
      });
    },

    async printReport(){
      const progressModal = $('#generatingAnimationModal')
      progressModal.modal('show');
      let pdfData = await this.printVesselReport();
      if(pdfData){
        await this.pdfViewer('Vessel List', pdfData);
      }
      progressModal.modal('hide');
    },

    monthStr(month){
      const months = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      }
      return months[month];
    }
  },
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

th, td {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}
</style>
