import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  gearSupplierRates: [],
  totalRateValue: null,

  newGearSupplierRate: {},

  gearSupplierRateFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },

  gearSupplierRatePagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },

  obj_gearsKeysSelection: [],

};


const mutations = {
  set_gearSupplierRates(state, params) {
    state.gearSupplierRates = params
  },

  set_totalRateValue(state, params) {
    state.totalRateValue = params
  },

  create_gearSupplierRate(state) {
    state.newGearSupplierRate = {};
  },

  update_gearSupplierRate(state, params) {
    let index = state.gearSupplierRates.find((val, index) => val.id === params.id)
    Object.assign(index, params)
  },

  delete_gearSupplierRate(state, gearRateId) {
    let index = state.gearSupplierRates.findIndex((val, index) => val.id === gearRateId)
    state.gearSupplierRates.splice(index, 1)
  },

  set_gearSupplierRate_pagination(state, params) {
    state.gearSupplierRatePagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },


  set_obj_gearsKeysSelection(state,payload){
    state.obj_gearsKeysSelection = payload
  },


};


const actions = {
  async set_gearSupplierRates({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GEAR_SUPPLIER_RATE, {params}).then((r) => {
      commit('set_gearSupplierRates', r.data.gearRates)
      commit('set_totalRateValue', r.data.totalRateValue)
      commit('set_gearSupplierRate_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get gear supplier rates')
      return false
    })
  },

  async create_gearSupplierRate({commit}, params) {
    return axios.post(PMC.GEAR_SUPPLIER_RATE, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_gearSupplierRate', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create gear supplier rates');
      return false;
    })
  },


  async update_gearSupplierRate({commit}, params) {
    return axios.put(`${PMC.GEAR_SUPPLIER_RATE}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_gearSupplierRate',r.data.updatedGearSupplierRate);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update gear supplier');
      return false;

    });
  },

  async delete_gearSupplierRate({ commit }, gearRateId) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete Gear Supplier Rate','question');
    if(await _del){
      return axios.delete(`${PMC.GEAR_SUPPLIER_RATE}/${gearRateId}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete gear supplier rate');
        commit('delete_gearSupplierRate',gearRateId);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete gear supplier rate");
        return false;

      })
    }
  },

  //gear supplier keys
  async getGearKeysInRateSupplier({commit}, params){
    return await axios.get(PMC.GET_ACTIVE_GEAR_KEY_SELECTION_IN_GEARRATE_SUPPLIER, {params}).then((r)=>{
      commit('set_obj_gearsKeysSelection',r.data);
      return true;

    }).catch((err)=>{
      UtilityService.generateResponseMessage(err,'Getting gear supplier keys')
      return false;

    })
  },


};


const getters = {
  gearSupplierRates: state => UtilityService.capitalizeProperties(state.gearSupplierRates),
  totalRateValue: state => state.totalRateValue,

  gearSupplierRatePagination: state => state.gearSupplierRatePagination,
  gearSupplierRateFormParam: state => state.gearSupplierRateFormParam,

  obj_gearsKeysSelection: state => UtilityService.capitalizeProperties(state.obj_gearsKeysSelection),

};


/** export **/
export default {state, actions, mutations, getters};
