<template>
  <div>
    <!-- Body    -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="text-left col-lg-12 pb-2">
            <!--
                   type="button"
                  data-toggle="modal"
                  :data-target="'#'+targetModal"
                  data-backdrop="static"
                  data-keyboard="false"
             -->
            <button type="button"
                    class="btn btn-sm pmc-btn-prime"
                    data-toggle="modal"
                    data-target="#add-crew-other-sea-service"
                    data-backdrop="static"
                    data-keyboard="false">
              <font-awesome-icon icon="plus"/>
              Add
            </button>
          </div>
        </div>
        <div>
          <pmc-table :loading="isOutSideServiceLoading" :loader-colspan="12" :max-height="250">
            <template v-slot:table-header>
              <tr>
                <!--<th class="text-center align-middle" style="width: 2%;">#</th>-->
                <th class="text-center align-middle" style="width: 15%;">Agency</th>
                <th class="text-center align-middle" style="width: 13%;">Vessel</th>
                <th class="text-center align-middle" style="width: 3%;">Rank</th>
                <th class="text-center align-middle" style="width: 10%;">Sign On</th>
                <th class="text-center align-middle" style="width: 10%;">Sign Off</th>
                <th class="text-center align-middle" style="width: 5%;">Route</th>
                <th class="text-center align-middle" style="width: 10%;">Vessel Type</th>
                <th class="text-center align-middle" style="width: 5%;">GRT</th>
                <th class="text-center align-middle" style="width: 10%;">Engine</th>
                <th class="text-center align-middle" style="width: 5%;">KW</th>
                <th class="text-center align-middle" style="width: 5%;">Action</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-if="crewOtherSeaServices.length == 0">
                <td colspan="12" class="text-center align-middle">
                  <span>No record found.</span>
                </td>
              </tr>
              <tr v-for="(crewExp,index) in crewOtherSeaServices" :key="crewExp.id">
                <!--<td class="pl-3 text-left align-middle">{{ index + 1 }}</td>-->
                <td class="pl-3 text-left align-middle">{{ crewExp.manning }}</td>
                <td class="pl-3 text-left align-middle">{{ crewExp.vessel }}</td>
                <td class=" text-center align-middle">{{ crewExp.rank }}</td>
                <td class="pl-3 text-center align-middle">{{ crewExp.signOnDate }}</td>
                <td class="pl-3 text-center align-middle">{{ crewExp.signOffDate }}</td>
                <td class="text-center align-middle">{{ crewExp.tradeRouteCode }}</td>
                <td class="text-left align-middle">{{ crewExp.vesselType }}</td>
                <td class="text-center align-middle">{{ crewExp.grt }}</td>
                <td class="text-left align-middle">{{ crewExp.mainEngine }}</td>
                <td class="text-center align-middle">{{ crewExp.kw }}</td>
                <td class="text-center align-middle">
                  <button type="button"
                          data-toggle="modal"
                          class="btn btn-sm pmc-btn-warning"
                          data-target="#view-crew-other-sea-service"
                          data-backdrop="static"
                          data-keyboard="false"
                          @click="editCrewOtherSeaExp(crewExp)">
                    <font-awesome-icon icon="edit"/>
                  </button>
                  <button type="button" class="btn btn-sm pmc-btn-danger ml-2" @click="deleteCrewOtherSeaExp(crewExp)">
                    <font-awesome-icon icon="trash"/>
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!-- Create Modal   -->
    <div class="modal fade" id="add-crew-other-sea-service" tabindex="-1" role="dialog"
         aria-labelledby="addCrewOtherSeaServiceLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addCrewOtherSeaServiceTitle">Add Crew Other Sea Experience</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-rank" class="col-md-4 col-form-label font-weight-bold text-left">Rank</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <select name="crewOtherExpRank" id="crew-other-exp-rank" v-model="newCrewOtherSeaService.rankId"
                        class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="rank.id" v-for="(rank,index) in objRanks">
                    {{ rank.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- flag id -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-flag" class="col-md-4 col-form-label font-weight-bold text-left">Flag</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <select name="crewOtherExpFlag" id="crew-other-exp-flag"
                        v-model="newCrewOtherSeaService.flagId"
                        class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="flag.id" v-for="(flag,index) in objFlags" :key="flag.id">
                    {{ flag.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-flag-other" class="col-md-4 col-form-label font-weight-bold text-left">Flag Other</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="text"
                       name="crewOtherSeaExpFlagOthers"
                       v-model="newCrewOtherSeaService.flagOthers"
                       id="crew-other-sea-exp-flag-others"
                       class="form-control form-control">
              </div>
            </div>

            <!-- vessel name -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-vessel" class="col-md-4 col-form-label font-weight-bold text-left">Vessel
                Name</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="text" name="crewOtherExpVessel" v-model="newCrewOtherSeaService.vesselName" id="crew-other-exp-vessel"
                       class="form-control form-control">
              </div>
            </div>

            <!-- vessel type id -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-vessel-type" class="col-md-4 col-form-label font-weight-bold text-left">Vessel
                Type</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <select name="crewOtherExpVesselType"
                        v-model="newCrewOtherSeaService.vesselTypeId"
                        id="crew-other-exp-vessel-type"
                        class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="vesselType.id" v-for="(vesselType,index) in objVesselTypes">{{ vesselType.name }}</option>
                </select>
              </div>
            </div>

            <!-- signon date -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signon-date" class="col-md-4 col-form-label font-weight-bold text-left">Sign-on
                Date
              </label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="date" name="crewOtherExpSignonDate"
                       v-model="newCrewOtherSeaService.signOnDate"
                       id="crew-other-exp-signon-date"
                       class="form-control form-control text-center align-middle">
              </div>
            </div>

            <!-- signoff date -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signoff-date" class="col-md-4 col-form-label font-weight-bold text-left">Sign-off
                Date
              </label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="date" name="crewOtherExpSignoffDate"
                       v-model="newCrewOtherSeaService.signOffDate"
                       id="crew-other-exp-signoff-date"
                       class="form-control form-control text-center align-middle">
              </div>
            </div>

            <!-- signon port  -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signon-port" class="col-md-4 col-form-label font-weight-bold text-left">Sign-on
                Port
              </label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <select name="crewOtherExpSignonPort"
                        v-model="newCrewOtherSeaService.signOnPortId"
                        id="crew-other-exp-signon-port"
                        class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="signOnPort.id" v-for="(signOnPort,index) in objPorts" :key="signOnPort.id">
                    {{ signOnPort.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- signoff port -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signoff-port" class="col-md-4 col-form-label font-weight-bold text-left">Sign-off
                Port
              </label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <select name="crewOtherExpSignoffPort"
                        v-model="newCrewOtherSeaService.signOffPortId"
                        id="crew-other-exp-signoff-port"
                        class="form-control  form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="signOffPort.id" v-for="(signOffPort,index) in objPorts" :key="signOffPort.id">
                    {{ signOffPort.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- manning id -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signoff-date" class="col-md-4 col-form-label font-weight-bold text-left">Manning</label>
              <span>:</span>
              <div class="col-md-7">
                <select name="crewOtherExpManning"
                        v-model="newCrewOtherSeaService.manningId"
                        id="crew-other-exp-manning" class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="manning.id" v-for="(manning,index) in objMannings" :key="manning.id">
                    {{ manning.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- trade route id -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signoff-date" class="col-md-4 col-form-label font-weight-bold text-left">Trade
                Route</label>
              <span>:</span>
              <div class="col-md-7">
                <select name="crewOtherExpManning"
                        v-model="newCrewOtherSeaService.tradeRouteId"
                        id="crew-other-exp-manning" class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="tradeRoute.id" v-for="(tradeRoute,index) in objTradeRoutes" class="text-left ml-lg-5 align-middle">
                    {{ tradeRoute.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- main engine -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-main-engine" class="col-md-4 col-form-label font-weight-bold text-left">Main
                Engine</label>
              <span>:</span>
              <div class="col-md-7">
                <input type="text" name="crewOtherExpMainEngine"
                       v-model="newCrewOtherSeaService.mainEngine"
                       class="form-control form-control" id="crew-other-exp-main-engine">
              </div>
            </div>

            <!-- grt  -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-grt" class="col-md-4 col-form-label font-weight-bold text-left">GRT</label>
              <span>:</span>
              <div class="col-md-7">
                <input type="number" name="crewOtherExpGrt"
                       v-model="newCrewOtherSeaService.grt" class="form-control form-control"
                       min="0"
                       pattern="^\d*(\.\d{0,2})?$"
                       placeholder="GROSS TON WEIGHT"
                       id="crew-other-exp-grt">
              </div>
              <button>
                <font-awesome-icon icon="times"/>
              </button>
            </div>

            <!-- kw  -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-kw" class="col-md-4 col-form-label font-weight-bold text-left">KW</label>
              <span>:</span>
              <div class="col-md-7">
                <input type="number" name="crewOtherExpKw" v-model="newCrewOtherSeaService.kw"
                       min="0"
                       pattern="^\d*(\.\d{0,2})?$"
                       placeholder="KILOWATT"
                       class="form-control form-control" id="crew-other-exp-kw">
              </div>
              <button>
                <font-awesome-icon icon="times"/>
              </button>
            </div>

            <!-- signoff_reason  -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signoff-reason" class="col-md-4 col-form-label font-weight-bold text-left">Sign-off
                Reason</label>
              <span>:</span>
              <div class="col-md-6">
                <select v-if="!otherSignOffReason"
                        v-model="newCrewOtherSeaService.signOffReason"
                        name="signOffReason" id="sign_off_reason"
                        class="form-control form-control selected-tag">

                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="signOffReason.description" v-for="(signOffReason,index) in objSignOffReasons" :key="signOffReason.id">
                    {{ signOffReason.description }}
                  </option>
                </select>
                <input v-if="otherSignOffReason" type="text" name="crewOtherExpSignoffReason"
                       v-model="newCrewOtherSeaService.signOffReason"
                       class="form-control form-control" id="crew-other-exp-signoff-reason">
              </div>
              <label for="other_sign_off_reason" class="col-form-label">Other</label>
              <input name="otherSignOffReason"
                     @change="onChangeOtherSignOffReason"
                     v-model="otherSignOffReason"
                     id="other_sign_off_reason"
                     type="checkbox" class="ml-lg-5 form-check form-check-inline">
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn pmc-btn-prime" @click="createNewOtherSeaExp">Save</button>
            <button type="button" class="btn pmc-btn-second" data-dismiss="modal" @click="cancelNewOtherSeaExp">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!--  View Modal   -->
    <div class="modal fade"
         id="view-crew-other-sea-service"
         tabindex="-1"
         role="dialog"
         aria-labelledby="viewCrewOtherSeaServiceLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewCrewOtherSeaServiceTitle">View Crew Other Sea Service</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-rank" class="col-md-4 col-form-label font-weight-bold text-left">Rank</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <select name="crewOtherExpRank"
                        id="crew-other-exp-rank"
                        v-model="crewOtherSeaService.rankId"
                        class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="rank.id" v-for="(rank,index) in objRanks" :key="rank.id">
                    {{ rank.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- flag id -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-flag" class="col-md-4 col-form-label font-weight-bold text-left">Flag</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <select name="crewOtherExpFlag" id="crew-other-exp-flag"
                        v-model="crewOtherSeaService.flagId"
                        class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="flag.id" v-for="(flag,index) in objFlags" :key="flag.id">
                    {{ flag.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Flag Others -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-flag-others" class="col-md-4 col-form-label font-weight-bold text-left">Flag Others</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="text"
                       name="crewOtherSeaExpFlagOthers"
                       v-model="crewOtherSeaService.flagOthers"
                       id="crew-other-sea-exp-flag-others"
                       class="form-control form-control">
              </div>
            </div>

            <!-- vessel name -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-vessel" class="col-md-4 col-form-label font-weight-bold text-left">Vessel Name</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="text"
                       name="crewOtherExpVessel"
                       v-model="crewOtherSeaService.vessel"
                       id="crew-other-exp-vessel"
                       class="form-control form-control">
              </div>
            </div>

            <!-- vessel type id -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-vessel-type" class="col-md-4 col-form-label font-weight-bold text-left">Vessel
                Type</label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <select name="crewOtherExpVesselType"
                        v-model="crewOtherSeaService.vesselTypeId"
                        id="crew-other-exp-vessel-type"
                        class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="vesselType.id" v-for="(vesselType,index) in objVesselTypes">{{ vesselType.name }}</option>
                </select>
              </div>
            </div>

            <!-- signon port  -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signon-port" class="col-md-4 col-form-label font-weight-bold text-left">Sign-on
                Port
              </label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="text" class="form-control" v-model="crewOtherSeaService.signOnPort">
                <!--<select name="crewOtherExpSignonPort"
                        v-model="crewOtherSeaService.signOnPort"
                        id="crew-other-exp-signon-port"
                        class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">&#45;&#45;SELECT&#45;&#45;</option>
                  <option :value="signOnPort.id" v-for="(signOnPort,index) in objPorts" :key="signOnPort.id">
                    {{ signOnPort.name }}
                  </option>
                </select>-->
              </div>
            </div>

            <!-- signoff port -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signoff-port" class="col-md-4 col-form-label font-weight-bold text-left">Sign-off
                Port
              </label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="text" class="form-control  form-control" v-model="crewOtherSeaService.signOffPort">
                <!--<select name="crewOtherExpSignoffPort"
                        v-model="newCrewOtherSeaService.signOffPort"
                        id="crew-other-exp-signoff-port"
                        class="form-control  form-control selected-tag">
                  <option :value="null" class="text-center align-middle">&#45;&#45;SELECT&#45;&#45;</option>
                  <option :value="signOffPort.id" v-for="(signOffPort,index) in objPorts" :key="signOffPort.id">
                    {{ signOffPort.name }}
                  </option>
                </select>-->
              </div>
            </div>

            <!-- signon date -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signon-date" class="col-md-4 col-form-label font-weight-bold text-left">Sign-on
                Date
              </label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="date" name="crewOtherExpSignonDate"
                       v-model="crewOtherSeaService.signOnDate"
                       id="crew-other-exp-signon-date"
                       class="form-control form-control text-center align-middle">
              </div>
            </div>

            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signoff-date" class="col-md-4 col-form-label font-weight-bold text-left">Sign-off
                Date
              </label>
              <span class="col-form-label">:</span>
              <div class="col-md-7">
                <input type="date" name="crewOtherExpSignoffDate"
                       v-model="crewOtherSeaService.signOffDate"
                       id="crew-other-exp-signoff-date"
                       class="form-control form-control text-center align-middle">
              </div>
            </div>

            <!-- manning id -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signoff-date" class="col-md-4 col-form-label font-weight-bold text-left">Manning</label>
              <span>:</span>
              <div class="col-md-7">
                <select name="crewOtherExpManning"
                        v-model="crewOtherSeaService.manningId"
                        id="crew-other-exp-manning" class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="manning.id" v-for="(manning,index) in objMannings" :key="manning.id">
                    {{ manning.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- trade route id -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signoff-date" class="col-md-4 col-form-label font-weight-bold text-left">
                Trade Route
              </label>
              <span>:</span>
              <div class="col-md-7">
                <select name="crewOtherExpManning"
                        v-model="crewOtherSeaService.tradeRouteId"
                        id="crew-other-exp-manning" class="form-control form-control selected-tag">
                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="tradeRoute.id" v-for="(tradeRoute,index) in objTradeRoutes" class="text-left ml-lg-5 align-middle">
                    {{ tradeRoute.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- main engine -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-main-engine" class="col-md-4 col-form-label font-weight-bold text-left">
                Main Engine
              </label>
              <span>:</span>
              <div class="col-md-7">
                <input type="text" name="crewOtherExpMainEngine"
                       v-model="crewOtherSeaService.mainEngine"
                       class="form-control form-control" id="crew-other-exp-main-engine">
              </div>
            </div>

            <!-- grt  -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-grt" class="col-md-4 col-form-label font-weight-bold text-left">GRT</label>
              <span>:</span>
              <div class="col-md-7">
                <input type="number" name="crewOtherExpGrt"
                       v-model="crewOtherSeaService.grt" class="form-control form-control"
                       min="0"
                       pattern="^\d*(\.\d{0,2})?$"
                       placeholder="GROSS TON WEIGHT"
                       id="crew-other-exp-grt">
              </div>
              <button>
                <font-awesome-icon icon="times"/>
              </button>
            </div>

            <!-- kw  -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-kw" class="col-md-4 col-form-label font-weight-bold text-left">KW</label>
              <span>:</span>
              <div class="col-md-7">
                <input type="number" name="crewOtherExpKw" v-model="crewOtherSeaService.kw"
                       min="0"
                       pattern="^\d*(\.\d{0,2})?$"
                       placeholder="KILOWATT"
                       class="form-control form-control" id="crew-other-exp-kw">
              </div>
              <button>
                <font-awesome-icon icon="times"/>
              </button>
            </div>

            <!-- signoff_reason  -->
            <div class="form-group row px-lg-5 mt-2 align-content-center d-flex">
              <label for="crew-other-exp-signoff-reason" class="col-md-4 col-form-label font-weight-bold text-left">Sign-off
                Reason</label>
              <span>:</span>
              <div class="col-md-6">
                <select v-if="!otherSignOffReason"
                        v-model="crewOtherSeaService.signOffReason"
                        name="signOffReason" id="sign_off_reason"
                        class="form-control form-control selected-tag">

                  <option :value="null" class="text-center align-middle">--SELECT--</option>
                  <option :value="signOffReason.description" v-for="(signOffReason,index) in objSignOffReasons" :key="signOffReason.id">
                    {{ signOffReason.description }}
                  </option>
                </select>
                <input v-if="otherSignOffReason" type="text" name="crewOtherExpSignoffReason"
                       v-model="crewOtherSeaService.signOffReason"
                       class="form-control form-control" id="crew-other-exp-signoff-reason">
              </div>
              <label for="other_sign_off_reason" class="col-form-label">Other</label>
              <input name="otherSignOffReason"
                     @change="onChangeOtherSignOffReason"
                     v-model="otherSignOffReason"
                     id="other_sign_off_reason"
                     type="checkbox" class="ml-lg-5 form-check form-check-inline">
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn pmc-btn-prime" @click="updateCrewOtherSeaExp">Update</button>
            <button class="btn btn pmc-btn-danger" @click="cancelUpdateCrewOtherSeaExp">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'CrewOutSideService',

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** props **/
  props: {},

  /** data **/
  data() {
    return {
      crewId: parseInt(this.$route.params.crewId, 10),
      otherSignOffReason: false,
      otherFlag: false,
    };
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        "crewOtherSeaServices",
        "crewOtherSeaService",
        "newCrewOtherSeaService",
        "isOutSideServiceLoading",
        "objVesselTypes",
        "objFlags",
        "objPorts",
        "objMannings",
        "objTradeRoutes",
        "objSignOffReasons",
        "objRanks",
      ]
    )
  },

  /** methods**/
  methods: {
    ...mapActions(
      [
        "set_crew_other_sea_services",
        "set_crew_other_sea_service",
        "create_crew_other_sea_service",
        "update_crew_other_sea_service",
        "delete_crew_other_sea_service",
        "reset_state_crew_other_sea_service",
        "null_obj_vessel_types",
        "null_obj_flags",
        "null_obj_ports",
        "null_obj_mannings",
        "null_obj_trade_route",
        "null_obj_sign_off_reasons",
        "null_obj_ranks",
        "set_obj_ranks",
        "null_crew_other_sea_service",
      ]
    ),

    getCrewOtherSeaExp() {
      this.set_crew_other_sea_services({crewId: this.crewId});
    },

    editCrewOtherSeaExp(obj) {
      this.set_crew_other_sea_service(obj);
    },

    async updateCrewOtherSeaExp() {
      let msg = `Do you want to save the changes you made?`;
      if (await this.questionAlert(`${msg}`, "Update Crew Other Sea Experience")) {
        this.update_crew_other_sea_service(this.crewOtherSeaService).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("Updated record successfully")
            })
          }
        })
      }
    },

    cancelUpdateCrewOtherSeaExp() {
      $(`#view-crew-other-sea-service`).modal("hide")
      let obj = this.crewOtherSeaServices.find((val, index) => {
        return val.id === this.crewOtherSeaServices.id
      });


      //this.set_crew_other_sea_service(obj);
    },

    async deleteCrewOtherSeaExp(e) {
      let msg = `Do you want to delete this record`;
      if (await this.questionAlert(`${msg}`, "Delete Crew Other Sea Service")) {
        this.delete_crew_other_sea_service(e).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("Delete record successfully")
            })
          }
        })
      }
    },

    //createNewOtherSeaExp
    async createNewOtherSeaExp() {
      let msg = `Do you want to save this new record`;
      if (await this.questionAlert(`${msg}`, "New Other Sea Experience")) {
        this.newCrewOtherSeaService.crewId = this.crewId;
        this.create_crew_other_sea_service(this.newCrewOtherSeaService).then((r) => {
          if (r) {
            this.$nextTick(() => {
              this.successAlert("New record created successfully");
              this.otherFlag = false;
              this.otherSignOffReason = false;
            })
          }
        })
      }
    },

    cancelNewOtherSeaExp() {
      $(`#add-crew-other-sea-service`).modal('hide');
      this.null_crew_other_sea_service()
    },

    onChangeOtherSignOffReason() {
      this.newCrewOtherSeaService.signOffReason = null;
    },

    onChangeOtherFlag() {
      this.newCrewOtherSeaService.flagId = null;
      this.newCrewOtherSeaService.flagOthers = null;
    },
  },

  /** mounted **/
  mounted() {
    this.getCrewOtherSeaExp();
  },

  /** created **/
  created() {
  },

  /** watch
   searchText : {
			handler : 'fetchUserList',
			immediate : true,
		}
   **/
  watch: {},

  /** destroyed **/
  destroyed() {
    this.reset_state_crew_other_sea_service();
    this.null_obj_vessel_types();
  },
};
</script>

<style scoped>


.selected-tag::-webkit-scrollbar {
  width: 20px !important;
}
</style>
