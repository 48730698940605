<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">
        <!--          <div class="col-md-4">-->
        <!--            <div class="row">-->
        <!--              <div class="form-inline mb-3">-->
        <!--                <div class="form-group mb-2 mr-5">-->
        <!--                  <span class="ml-4 font-weight-bold lead text-primary"-->
        <!--                        style="font-size: 22px;">Onboard Document Expiry </span>-->
        <!--                </div>-->
        <!--                <div class="form-group ">-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->

        <!--            <div class="row">-->
        <!--              <div class="form-inline mb-3">-->
        <!--                <div class="form-group mb-2 mr-5">-->
        <!--                  <span class="ml-4 font-weight-bold lead text-primary"-->
        <!--                        style="font-size: 22px;">Total: {{ onboardCrewsDocCertsCount }}</span>-->
        <!--                </div>-->
        <!--                <div class="form-group ">-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->


        <!--          </div>-->

        <div style="width: 100%;margin-top: -20px;">

          <div style="width:100%;height:84px;">
            <div style="width:110px;height:105px;float:left;">
              <img :src="pmcLogo" alt="" style="width:70%; height:80%;">
            </div>
            <div class="mt-3" style="height:105px;float:left;">
              <div class="row">
                <span style="font-size: 20px">Onboard Document Expiry</span>
              </div>

              <div class="row">
                <span style="font-size: 14px">As of: {{ formatCurrentDate() }}</span>
              </div>

              <div class="row">
                <span style="font-size: 14px">Expiring {{ formatCurrentDate2() }}</span>
              </div>

            </div>

          </div>
        </div>


        <!--          <div style="width: 100%;margin-top: -20px;">-->

        <!--            <div style="width:100%;height:84px;">-->
        <!--              <div style="width:110px;height:105px;float:left;">-->
        <!--                <img :src="pmcLogo" alt="" style="width:70%; height:80%;">-->
        <!--              </div>-->
        <!--              <div class="mt-3" style="height:105px;float:left;">-->
        <!--                <table style="width:100%;" class="">-->
        <!--                  <tbody>-->
        <!--                  <tr>-->
        <!--                    <span style="font-size: 20px">Onboard Document Expiry</span>-->
        <!--                  </tr>-->
        <!--                  <tr>-->
        <!--                    <td>-->
        <!--                            <span>As of: 14 November 2023</span>-->
        <!--                    </td>-->
        <!--                    <td>-->
        <!--                    </td>-->
        <!--                  </tr>-->
        <!--                  </tbody>-->
        <!--                </table>-->
        <!--                <table style="width:100%;" class="">-->
        <!--                  <tbody>-->
        <!--                  <tr>-->
        <!--                    <td>Expiring November 2023</td>-->
        <!--                  </tr>-->

        <!--                  </tbody>-->
        <!--                </table>-->
        <!--              </div>-->


        <!--              <div id="PAScreenOut" style="width:800px;height:35px;" align="right">-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <br>-->
        <!--          </div>-->


        <!--          &lt;!&ndash; Owner &ndash;&gt;-->
        <!--          <div class="col-sm-5">-->
        <!--            <div class="form-group row">-->
        <!--              <label for="list-count" class="col-sm-3 col-form-label">Owner</label>-->
        <!--              <span class="col-form-label">:</span>-->
        <!--              <div class="col-sm-7 text-left">-->
        <!--                <select class="custom-select custom-select-sm"-->
        <!--                >-->
        <!--                  <option :value="null" class="text-center align-middle">&#45;&#45;SELECT OWNER&#45;&#45;</option>-->
        <!--                  <option :value="owner.id" v-for="(owner,index) in objOwners" :key="owner.id">-->
        <!--                    {{ owner.name }}-->
        <!--                  </option>-->
        <!--                </select>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->

        <hr/>

        <!--        <div class="row mt-5">-->

        <!--          <div class="col-md-12">-->
        <!--            <div class="row">-->

        <!--                            <div class="col-sm-3">-->
        <!--                              <div class="form-group row">-->
        <!--                                <span class="ml-4 font-weight-bold lead" style="font-size: 18px;" v-if="selectedVesselName">-->
        <!--                                  {{ selectedVesselName }}-->
        <!--                                </span>-->
        <!--                                <span class="ml-4 font-weight-bold lead" style="font-size: 18px;" v-else>-->
        <!--                                  &#45;&#45;SELECT VESSEL&#45;&#45;-->
        <!--                                </span>-->
        <!--                              </div>-->
        <!--                            </div>-->

        <!--                            <div class="col-sm-3">-->
        <!--                              <div class="form-group row">-->
        <!--                                <label for="list-count" class="col-form-label">VESSEL</label>-->
        <!--                                <span class="col-form-label">:</span>-->
        <!--                                <div class="col-sm-7 text-left">-->
        <!--                                  <select name="list-type" id="list-type" class="custom-select custom-select-sm"-->
        <!--                                  >-->
        <!--                                    <option :value="null">&#45;&#45;SELECT VESSEL&#45;&#45;</option>-->

        <!--                                  </select>-->
        <!--                                </div>-->
        <!--                              </div>-->
        <!--                            </div>-->

        <!--                            <div class="col-sm-12">-->
        <!--                              <div class="form-group row">-->
        <!--              &lt;!&ndash;                  <button type="button" class="btn btn-xs pmc-btn-tertia ml-4"&ndash;&gt;-->
        <!--              &lt;!&ndash;                          @click="loadOnboardDocsCertsExpiry"&ndash;&gt;-->
        <!--              &lt;!&ndash;                  >&ndash;&gt;-->
        <!--              &lt;!&ndash;                    <font-awesome-icon icon="file"/>&ndash;&gt;-->
        <!--              &lt;!&ndash;                    Generate Report&ndash;&gt;-->
        <!--              &lt;!&ndash;                  </button>&ndash;&gt;-->


        <!--                                <button type="button" class="btn btn-xs pmc-btn-tertia ml-4"-->
        <!--                                        @click="printContent"-->
        <!--                                        v-if="showPrintButton"-->
        <!--                                >-->
        <!--                                  <font-awesome-icon icon="file"/>-->
        <!--                                  Print Report-->
        <!--                                </button>-->
        <!--                              </div>-->
        <!--                            </div>-->


        <!--            </div>-->
        <!--          </div>-->


        <!--        </div>-->


        <!--  pagination      -->
        <!--        <div class="row py-1 mt-3">-->
        <!--          <div class="col-md-9">-->
        <!--            <div class="row">-->
        <!--              <div class="col-5">-->
        <!--                <pmc-pagination v-if="showGenerateButton" :page-object="scholarPagination" @settingPage="set_this_page"></pmc-pagination>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!-- table  -->
        <div id="table-section" class="py-3">

          <div class="row justify-content-center">
            <div class="col col-md">
              <div class=""
                   style="height : 550px">


                <!--MASTERLIST FORMAT-->
                <table
                  class="table table-bordered table-sm text-center sticky-header-docs-format small table-hover onboard-doc-expiry-table">
                  <thead>
                  <tr>
                    <th style="width: 2%;" rowspan="2">#</th>
                    <th style="width: 12%;" rowspan="2" class="onboard-doc-expiry-table"
                        @click="columnSort(onboardDocumentExpirySort, 'sortByCrew', true, 'crewName', onboardCrewsDocCerts)"
                    >
                      <sort-arrow
                        :sort-by-object="onboardDocumentExpirySort"
                        :sort-by-value="'sortByCrew'"
                        :object-data="onboardCrewsDocCerts"
                      />

                      CREW
                    </th>

                    <th style="width: 5%;"
                        rowspan="2"
                        @click="columnSort(onboardDocumentExpirySort, 'sortByRank', false, 'rankId', onboardCrewsDocCerts)"
                    >
                      <sort-arrow
                        :sort-by-object="onboardDocumentExpirySort"
                        :sort-by-value="'sortByRank'"
                        :object-data="onboardCrewsDocCerts"
                      />
                      RANK
                    </th>

                    <th style="width: 6%;" rowspan="2"
                        @click="columnSort(onboardDocumentExpirySort, 'sortByVessel', true, 'vessel', onboardCrewsDocCerts)"
                    >
                      <sort-arrow
                        :sort-by-object="onboardDocumentExpirySort"
                        :sort-by-value="'sortByVessel'"
                        :object-data="onboardCrewsDocCerts"
                      />
                      VESSEL
                    </th>

                    <th style="width: 8%;" rowspan="2"
                        @click="columnSort(onboardDocumentExpirySort, 'sortByEmbarkDate', true, 'embarkDate', onboardCrewsDocCerts)"
                    >
                      <sort-arrow
                        :sort-by-object="onboardDocumentExpirySort"
                        :sort-by-value="'sortByEmbarkDate'"
                        :object-data="onboardCrewsDocCerts"
                      />
                      EMBARKED
                      <br>
                      (Y-M-D)
                    </th>

                    <th style="width: 8%;" rowspan="2"
                        @click="columnSort(onboardDocumentExpirySort, 'sortByContractDate', true, 'contractExp', onboardCrewsDocCerts)"
                    >
                      <sort-arrow
                        :sort-by-object="onboardDocumentExpirySort"
                        :sort-by-value="'sortByContractDate'"
                        :object-data="onboardCrewsDocCerts"
                      />
                      CONTRACT EXP/EOC
                      <br>
                      (Y-M-D)
                    </th>

                    <th style="width: 15%;" rowspan="2"
                        @click="columnSort(onboardDocumentExpirySort, 'sortByDocument', true, 'document', onboardCrewsDocCerts)"
                    >
                      <sort-arrow
                        :sort-by-object="onboardDocumentExpirySort"
                        :sort-by-value="'sortByDocument'"
                        :object-data="onboardCrewsDocCerts"
                      />
                      DOCUMENT
                    </th>

                    <th style="width: 8%;" rowspan="2"
                        @click="columnSort(onboardDocumentExpirySort, 'sortByDocValidity', true, 'dateExpiry', onboardCrewsDocCerts)"
                    >
                      <sort-arrow
                        :sort-by-object="onboardDocumentExpirySort"
                        :sort-by-value="'sortByDocValidity'"
                        :object-data="onboardCrewsDocCerts"
                      />
                      VALIDITY
                      <br>
                      (Y-M-D)
                    </th>

                    <th style="width: 8%;" rowspan="2"
                        @click="columnSort(onboardDocumentExpirySort, 'sortByAppointmentDate', true, 'appointmentDate', onboardCrewsDocCerts)"
                    >
                      <sort-arrow
                        :sort-by-object="onboardDocumentExpirySort"
                        :sort-by-value="'sortByAppointmentDate'"
                        :object-data="onboardCrewsDocCerts"
                      />
                      APPOINTMENT DATE
                      <br>
                      (Y-M-D)
                    </th>

                    <th style="width: 8%;" rowspan="2"
                        @click="columnSort(onboardDocumentExpirySort, 'sortByReleaseDate', true, 'releaseDate', onboardCrewsDocCerts)"
                    >
                      <sort-arrow
                        :sort-by-object="onboardDocumentExpirySort"
                        :sort-by-value="'sortByReleaseDate'"
                        :object-data="onboardCrewsDocCerts"
                      />
                      RELEASE DATE
                      <br>
                      (Y-M-D)
                    </th>

                    <th style="width: 10%;" rowspan="2">
                      STATUS
                    </th>
                  </tr>

                  </thead>

                  <template v-if="isReloading">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>

                  <template v-else>
                    <template v-if="onboardCrewsDocCerts ? (onboardCrewsDocCerts.length != 0) : '' ">
                      <tr v-for="(onboardCrewDocCert, index) in onboardCrewsDocCerts" :key="onboardCrewDocCert.id">
                        <td class="align-middle">{{ index + 1 }}</td>
                        <td class="text-left">
                          <span
                            @click.stop="gotoCrewProfileNewWindow(onboardCrewDocCert.crewId, 'all-info')"
                          >
                            {{ onboardCrewDocCert.crewName }}
                          </span>

                        </td>

                        <td class="align-middle">{{ onboardCrewDocCert.rank }}</td>
                        <td class="align-middle">{{ onboardCrewDocCert.vessel }}</td>
                        <td class="align-middle">{{ onboardCrewDocCert.embarkDate }}</td>
                        <td class="align-middle">{{ onboardCrewDocCert.contractExp }}</td>
                        <td class="align-middle">{{ onboardCrewDocCert.document }}</td>

                        <td class="align-middle">{{ onboardCrewDocCert.dateExpiry }}</td>
                        <td class="align-middle"
                            data-toggle="modal"
                            data-target="#onboard-docs-certs-expiry"
                            data-backdrop="static"
                            data-keyboard="false"
                            @click="viewOnboardDocumentRemark(onboardCrewDocCert.onboardDocumentExpiryRemarkId,
                            onboardCrewDocCert.crewDocumentId, onboardCrewDocCert.crewName)"
                        >
                          {{ onboardCrewDocCert.appointmentDate }}
                        </td>

                        <td class="align-middle"
                            data-toggle="modal"
                            data-target="#onboard-docs-certs-expiry"
                            data-backdrop="static"
                            data-keyboard="false"
                            @click="viewOnboardDocumentRemark(onboardCrewDocCert.onboardDocumentExpiryRemarkId,
                            onboardCrewDocCert.crewDocumentId, onboardCrewDocCert.crewName)"
                        >
                          {{ onboardCrewDocCert.releaseDate }}
                        </td>

                        <td class="align-middle"
                            data-toggle="modal"
                            data-target="#onboard-docs-certs-expiry"
                            data-backdrop="static"
                            data-keyboard="false"
                            @click="viewOnboardDocumentRemark(onboardCrewDocCert.onboardDocumentExpiryRemarkId,
                            onboardCrewDocCert.crewDocumentId, onboardCrewDocCert.crewName)"
                        >

                          {{ onboardCrewDocCert.status }}
                        </td>

                      </tr>
                    </template>

                    <template v-else>
                      <tr>
                        <td colspan="100%" class="bg-light-danger font-weight-bolder">
                          <p class="text-center mt-3">No Results</p>
                        </td>
                      </tr>
                    </template>


                  </template>

                </table>

              </div>
            </div>
          </div>


        </div>
      </div>
    </div>


    <div class="modal fade" id="onboard-docs-certs-expiry" tabindex="-1" role="dialog"
         aria-labelledby="documentListModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-left">
              {{ crewName }}
              <button
                type="button"
                class="btn btn-xs pmc-btn-tertia ml-3"
                @click="updateOnboardDocumentExpiry"
              >
                <font-awesome-icon icon="save"/>
                SAVE
              </button>
            </h5>
            <div class="btn-group">
              <button class="btn btn-sm ml-2" @click="closeOnboardDocCertsExpiryModal" aria-label="Close">
                <font-awesome-icon icon="times" class="text-danger"/>
                Close
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div class="table table-responsive-lg">
              <pmc-table :max-height="320"
                         :loader-colspan="10"
                         :is-table-hover="false"
              >
                <template v-slot:table-header>
                  <tr>
                    <th colspan="4" style="width: 45%;font-weight: normal;">Document</th>
                    <th colspan="2"></th>
                  </tr>
                </template>
                <template v-slot:table-body>
                  <tr>
                    <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                      APPOINTMENT DATE (Y-M-D)
                    </td>
                    <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                      <input type="date"
                             class="form-control form-control"
                             v-model="onboardDocumentExpiryRemark.appointmentDate"
                             :disabled="isLoadingOnboardDocRemark"
                      />

                    </td>
                  </tr>

                  <tr>
                    <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                      RELEASE DATE (Y-M-D)
                    </td>
                    <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                      <input type="date"
                             class="form-control form-control"
                             v-model="onboardDocumentExpiryRemark.releaseDate"
                             :disabled="isLoadingOnboardDocRemark"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" class="p-2 cc-font-size text-left font-weight-bold pl-3">
                      STATUS
                    </td>
                    <td colspan="2" class="p-2 cc-font-size text-center align-middle">
                      <textarea
                        id="new-remarks"
                        rows="3"
                        style="resize: none; width: 100%;"
                        class="form-control form-control-sm"
                        v-model="onboardDocumentExpiryRemark.status"
                        :disabled="isLoadingOnboardDocRemark"
                      >
                      </textarea>
                    </td>
                  </tr>

                </template>
              </pmc-table>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import {global_variables_config} from "@/config/global-variable.config";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import {CommonMixins} from "@/mixins/common.mixins";
import PmcLogo from '../../../assets/pmc_logo_only.jpg';

export default {
  name: "ViewOnboardDocCertsExpiry",

  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      pmcLogo: PmcLogo,
      isEditable: false,
      selectedVessel: null,
      selectedVesselName: null,
      crewDocumentId: null,
      crewName: null,

      showPrintButton: true,

      onboardDocumentExpirySort: {
        sortByCrew: '',
        sortByRank: '',
        sortByVessel: '',
        sortByEmbarkDate: '',
        sortByContractDate: '',
        sortByDocument: '',
        sortByDocValidity: '',
        sortByAppointmentDate: '',
        sortByReleaseDate: '',
        sortByStatus: '',
      },

    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_onboardDocsCertsExpiry',
      'generateOnboardDocsCertsExpiryList',
      'set_onboard_document_expiry_remark',
      'update_onboard_document_expiry_remark',
    ]),

    formatCurrentDate() {
      const options = {day: 'numeric', month: 'long', year: 'numeric'};
      return new Date().toLocaleDateString('en-GB', options);
    },

    formatCurrentDate2() {
      const options = {month: 'long', year: 'numeric'};
      return new Date().toLocaleDateString('en-GB', options);
    },

    async loadOnboardDocsCertsExpiry() {
      AlertService.loadingAlert('Please wait ...', 'Loading Scholar Monitoring List');
      let params = {
        crewType: 1
      }
      let onboardDocsCerts = await this.generateOnboardDocsCertsExpiryList(params);
      if (onboardDocsCerts) {
        Swal.close();
        this.pdfViewerMasterChecklist('Onboard Document Expiry', onboardDocsCerts);
      } else {
        Swal.close();
      }
    },


    printContent() {
      this.showPrintButton = false

      setTimeout(() => {
        window.print();
      }, 100);
    },

    closeOnboardDocCertsExpiryModal() {
      $(`#onboard-docs-certs-expiry`).modal('hide');
    },

    viewOnboardDocumentRemark(onboardDocumentExpiryRemarkId, crewDocumentId, crewName) {
      this.crewDocumentId = crewDocumentId
      this.crewName = crewName
      this.set_onboard_document_expiry_remark(crewDocumentId)
    },

    async updateOnboardDocumentExpiry() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update Document Expiry Remark', 'question');
      if (await __update) {
        let params = {
          id: this.onboardDocumentExpiryRemark.id,
          crewDocumentId: this.crewDocumentId,
          appointmentDate: this.onboardDocumentExpiryRemark.appointmentDate ? this.onboardDocumentExpiryRemark.appointmentDate : '',
          releaseDate: this.onboardDocumentExpiryRemark.releaseDate ? this.onboardDocumentExpiryRemark.releaseDate : '',
          status: this.onboardDocumentExpiryRemark.status ? this.onboardDocumentExpiryRemark.status : '',
        };

        let updateOnboardDocumentExpiry = await this.update_onboard_document_expiry_remark(params);
        if (updateOnboardDocumentExpiry) {
          AlertService.successAlertService("Updated successfully", 'Update Document Expiry Remark');
          this.hideModal('onboard-docs-certs-expiry')
        }
      }
    },

  },

  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'isLoadingOnboardDocRemark',
        'onboardCrewsDocCerts',
        'onboardCrewsDocCertsCount',
        'onboardDocumentExpiryRemark',
      ]),
  },


  /** created **/
  async created() {
    this.set_onboardDocsCertsExpiry();
  },


}
</script>


<style scoped>
/*
.table th:first-child,
.table td:first-child {
  font-size: 10px
}
*/

table thead tr th{
  font-size: 10px !important;
}

td {
  font-size: 10px;
}

td span{
  font-size: 10px;
}

</style>
