<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"style="font-size: 22px;">Crew Onboard</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-4">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="list-count" class="col-sm-4 col-form-label">RANK TYPE</label>
                      <span class="col-form-label">:</span>
                      <div class="col-sm-7 text-left">
                        <select name="list-type" id="rank-selected"
                                class="custom-select custom-select-sm"
                                v-model="selectedRankType"
                                required>
                          <option v-for="(rankType, index) in rankTypes" :value="rankType">{{rankType}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group row mt-1">
                  <button type="button" class="btn btn-xs pmc-btn-danger"
                          @click="printReport" :disabled="isLoading === true">
                    <font-awesome-icon icon="file"/>
                    Print Report
                  </button>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>

        <div id="table-section" class="py-3">
          <div class="row justify-content-center">
            <div class="col col-md">
              <div class="table table-responsive pmc-table border-top"
                   style="height : 550px">
                <table class="table table-bordered table-sm text-center small sticky-header table-hover">
                  <thead>
                  <tr v-if="selectedRankType === 'officers'">
                    <th>Vessel Name</th>
                    <th>MSTR</th>
                    <th>C/M</th>
                    <th>2/M</th>
                    <th>3/M</th>
                    <th>C/E</th>
                    <th>2/E</th>
                    <th>3/E</th>
                    <th>4/E</th>
                  </tr>
                  <tr v-else>
                    <th>Vessel Name</th>
                    <th>BSN</th>
                    <th>A/B</th>
                    <th>O/S</th>
                    <th>D/C</th>
                    <th>OLR</th>
                    <th>WPR</th>
                    <th>E/C</th>
                    <th>C/CK</th>
                    <th>M/M</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="isLoading === true">
                    <tr>
                      <td colspan="100%">
                        <span class="spinner-border text-primary" role="status" aria-hidden="true"></span>
                        <span style="size: 20px">
                          Loading ...
                        </span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <template v-if="ranks_data">

                        <template v-if="ranks_data[selectedRankType].length !== 0">
                            <template v-for="vesselRanksData in ranks_data[selectedRankType]">
                              <tr v-if="selectedRankType === 'officers'">
                                <td style="width: 11%">{{ vesselRanksData.vessel_name }}</td>
                                <td>
                                  <template v-for="crew in vesselRanksData['mstr']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['c/m']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['2/m']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['3/m']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['c/e']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['2/e']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['3/e']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['4/e']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                              </tr>
                              <tr v-else>
                                <td style="width: 11%">{{ vesselRanksData.vessel_name }}</td>
                                <td>
                                  <template v-for="crew in vesselRanksData['bsn']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['a/b']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['o/s']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['d/c']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['olr']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['wpr']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['e/c']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                                <td>
                                  <template v-for="crew in vesselRanksData['c/ck']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>

                                <td>
                                  <template v-for="crew in vesselRanksData['m/m']">
                                    <span>
                                      <a href="#"
                                         @click.stop="gotoCrewProfileNewWindow(crew.id, 'all-info')"
                                         :class="getCriticality(parseInt(crew.months_on_board))"
                                      >
                                        <small>
                                          <b>{{ getFullName(crew) }} </b>
                                        </small>
                                      </a>
                                    </span><br>
                                  </template>
                                </td>
                              </tr>
                            </template>
                        </template>
                        <template v-else>
                          <tr>
                            <td colspan="100%">No Data</td>
                          </tr>
                        </template>
                    </template>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <table style="width:100%;">
          <tbody>
          <tr>
            <td><span style="font-weight:bold;">Legend :</span></td>
            <td><span style="text-align:right;" class="legend_near_critical"><b>Near Critical (5-7 mos / Danish 3-5 mos)</b></span></td>
            <td><span style="text-align:right;" class="legend_critical"><b>Critical (8-10 mos / Danish 6-10 mos)</b></span></td>
            <td><span style="text-align:right;" class="legend_over_critical"><b>Over Critical (11 and up mos)</b></span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>

</template>

<script>
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {CommonMixins} from "@/mixins/common.mixins";
import {AlertService} from "@/service/alert.service";
import Swal from "sweetalert2";
import {FileService} from "@/service/file.service";
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CrewOnBoard",


  /** mixins **/
  mixins: [AlertMixins, AppMixins, CommonMixins],

  data() {
    return {
      isLoading: false,
      crewData: [],
      selectedRankType : 'officers',
      rankTypes : [
        'officers',
        'ratings'
      ],
      officers_ranks : {
        1 : 'mstr',
        2 : 'c/m',
        3 : '2/m',
        4 : '3/m',
        10 : 'c/e',
        11 : '2/e',
        12 : '3/e',
        13 : '4/e'
      },
      ratings_ranks : {
        27 : 'bsn',
        6 : 'a/b',
        7 : 'o/s',
        8 : 'd/c',
        15 : 'olr',
        17 : 'wpr',
        18 : 'e/c',
        24 : 'c/ck',
        25 : 'm/m',
      },
      rank_ignored : 'ignored'
    }
  },


  /** methods**/
  methods: {
    ...mapActions([
      'getCrewComplimentPerVessel',
    ]),
    printReport() {
      AlertService.loadingAlert('Please wait ...', 'Generating PDF for Crew Pool Sea Service Lists');

      axios.post(PMC.PRINT_MANPOWER_CREW_ONBOARD_LIST, {
        rank_selected_data : this.ranks_data[this.selectedRankType],
        selected_rank_type : this.selectedRankType
      })
        .then(result => {
          let fScr = FileService.base64FileToBlobUrl(result.data);
          Swal.close();
          this.pdfViewer('File', fScr);
        }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate crew pool sea service lists');
        Swal.close();
        return false;
      });
    },
    getCrewOnBoardLists() {
      this.isLoading = true;
      axios.get(PMC.GET_MANPOWER_CREW_ONBOARD_LIST)
          .then(result => {
            this.crewData = _.sortBy(result.data.crews, 'last_boarded_vessel');
              this.isLoading = false;
          }).catch(error => {
        UtilityService.generateResponseMessage(error, 'Generate crew pool sea service lists');
        Swal.close();
        return false;
      });
    },
    getFullName(crew) {
      let firstName = crew.first_name ?? null;
      let middleInitial = crew.middle_initial ?? null;
      let lastName = crew.last_name ?? null;
      let suffixName = crew.suffix_name ?? null;

      if (suffixName !== null) {
        return suffixName + ' ' +  lastName + ', ' + firstName + ' ' + middleInitial;
      }
      return lastName + ', ' + firstName + ' ' + middleInitial;
    },
    getCriticality(monthsOnBoard) {
      if (monthsOnBoard >= 5 && monthsOnBoard <= 7) {
        return 'legend_near_critical';
      }
      if (monthsOnBoard >= 8 && monthsOnBoard <= 10) {
        return 'legend_critical';
      }
      if (monthsOnBoard >= 11) {
        return 'legend_over_critical';
      }
      return '';
    },
    isDocumentExpire(date_expiry) {
      let dateExpiry = new Date(date_expiry)
      let currentDate = new Date();
      let dateDiffInMilliseconds = dateExpiry - currentDate;
      let dateDiffInDays = dateDiffInMilliseconds / (1000 * 60 * 60 * 24);
      return dateDiffInDays < 1;
    },
    finalizedGroupedByOfficer(groupedByOfficer, groupedByOfficerKey) {
      return _.map(this.objVessels, (vesselData) => {
        let officerRanks = groupedByOfficer[groupedByOfficerKey];
        let groupedByRanks = _.groupBy(officerRanks, (crew) => {
          if (groupedByOfficerKey === 'officers') {
            if (this.officers_ranks.hasOwnProperty(crew.rank_id) === true && vesselData.id === crew.last_boarded_vessel_id) {
              return this.officers_ranks[crew.rank_id];
            }
            return this.rank_ignored;
          } else {
            if (this.ratings_ranks.hasOwnProperty(crew.rank_id) === true && vesselData.id === crew.last_boarded_vessel_id) {
              return this.ratings_ranks[crew.rank_id];
            }
            return this.rank_ignored;
          }
        });

        if (groupedByRanks.hasOwnProperty(this.rank_ignored) === true) {
          delete groupedByRanks[this.rank_ignored];
        }

        groupedByRanks.vessel_name = vesselData.name;
        return groupedByRanks;
      });
    }
  },
  async created() {
      await this.getCrewComplimentPerVessel();
      this.getCrewOnBoardLists();
  },
  computed: {
    ...mapGetters(
      [
        'objVessels'
      ]),
    ranks_data() {
      let _this = this;

      let finalGroupedByOfficer = {
        officers : [],
        ratings : []
      }

      let groupedByOfficer = _.groupBy(_this.crewData, (crew) => {
        if (this.officers_ranks.hasOwnProperty(crew.rank_id) === true) {
          return 'officers';
        }
        return 'ratings';
      });

      finalGroupedByOfficer.officers = this.finalizedGroupedByOfficer(groupedByOfficer, 'officers')
      finalGroupedByOfficer.ratings = this.finalizedGroupedByOfficer(groupedByOfficer, 'ratings')

      return finalGroupedByOfficer;
    }
  }
}
</script>

<style scoped>
.left-align {
  text-align: left;
}

th {
  vertical-align: middle !important;
  padding: 0 5px 0 5px;
}

.expired {
  color: red;
}

.legend_near_critical {
  background-color: green !important;
  color: white !important;
}

.legend_critical {
  background-color: rgba(255,255,0,255) !important;
}

.legend_over_critical {
  background-color: red !important;
}
</style>
