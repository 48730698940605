import axios from 'axios';
import {UtilityService} from '../../../service/utility.service';
import {PMC} from '../../../config/constants.config';
import {AlertService} from "@/service/alert.service";

const initNewCrewDocument = () => {
  return {
    documentId: '',
    documentNo: '',
    documentNo2: '',
    dateIssued: '',
    dateExpiry: '',
    rankId: '',
    remarks: '',
    file: '',
  }
}


/** state **/
const state = {
  crewDocuments: [],
  crewDocument: {},

  crewDocSideNav: [],
  crewDocHistories: [],

  newCrewDocument: initNewCrewDocument(),

  //docImage
  docImageBlob: null,
  docImageBlob_gte10mb: null,

  renewCrewDocument: {
    crewDocId: '',
    documentId: '',
    documentName: '',
    documentNo: '',
    documentNo2: '',
    rankId: '',
    dateIssued: null,
    dateExpiry: null,
  },

  is2mbUp: false,

  form10mbUpDocFile: null,
  formDocImgFile: null,
  formDocImgFileRenew: null,

  crewBioData: [],

};

/** mutations **/
const mutations = {
  SET_CREW_DOC(state, payload) {
    state.crewDocument = payload;
  },

  SET_CREW_DOCS(state, payload) {
    state.crewDocuments = payload;
  },

  CREATE_CREW_DOC(state) {
    Object.assign(state.newCrewDocument, initNewCrewDocument())
  },

  UPDATE_CREW_DOC(state, payload) {
    let crewDoc = state.crewDocuments.find((doc, index) => doc.id === payload.id);
    Object.assign(crewDoc, payload);
  },

  DELETE_CREW_DOC(state, payload) {
    let index = state.crewDocuments.findIndex(
      (crewDoc, index) => crewDoc.id === payload.id);
    state.crewDocuments.splice(index, 1);
  },

  SET_CREWDOC_SIDENAV(state, payload) {
    state.crewDocSideNav = payload;
  },

  NULL_CREW_DOC(state) {
    state.crewDocument = {};
  },

  NULL_CREW_DOCS(state) {
    state.crewDocuments = [];
  },

  NULL_CREWDOC_SIDENAV(state) {
    state.crewDocSideNav = [];
  },

  NULL_NEW_CREW_DOCUMENT(state) {
    state.newCrewDocument = {
      documentId: null,
      documentNo: null,
      documentNo2: null,
      dateIssued: null,
      dateExpiry: null,
      rankId: null,
      remarks: null,
      file: null,
    };
  },

  SET_NEW_CREWDOC_FILE(state, payload) {
    state.newCrewDocument.file = payload;
  },

  NULL_NEW_CREW_DOC_FILE(state) {
    state.newCrewDocument.file = null;
  },

  SET_CREW_DOC_HISTORY(state, payload) {
    state.crewDocHistories = payload;
  },

  SET_IMAGE_BLOB(state, payload) {
    state.docImageBlob = payload;
  },

  NULL_CREW_DOC_HISTORY(state) {
    state.crewDocHistories = [];
  },

  NULL_DOC_IMAGE_BLOB(state) {
    state.docImageBlob = null;
  },

  NULL_RENEW_CREW_DOCUMENT(state) {
    state.renewCrewDocument = {
      crewDocId: '',
      documentNo: '',
      documentNo2: '',
      dateIssued: null,
      dateExpiry: null,
    };
  },

  set_doc_imageblob_gte10mb(state, payload) {
    state.docImageBlob_gte10mb = payload;
  },

  null_doc_imageblob_gte10mb(state) {
    state.docImageBlob_gte10mb = null;
  },

  set_is_2mbUp(state, payload) {
    state.is2mbUp = payload;
  },

  set_form_doc_image_file(state, payload) {
    state.formDocImgFile = payload;
  },

  //formDocImgFileRenew
  set_form_doc_image_filerenew(state, payload) {
    state.formDocImgFileRenew = payload;
  },

  set_form_10mb_docfile(state, payload) {
    state.form10mbUpDocFile = payload;
  },
};

/** actions **/
const actions = {
  async getCrewDocs({commit}, params) {
    commit('set_is_loading', true);
    return await axios.get(`${PMC.CREW_DOCUMENT}`, {params}).then((r) => {
      commit('SET_CREW_DOCS', r.data);
      commit('set_is_loading', false);
      return true;

    }).catch((er) => {
      commit('set_is_loading', false);
      UtilityService.generateResponseMessage(er, 'Get Crew Documents List');
      return false;
    });
  },

  viewCrewDoc({commit}, params) {
    return axios.get(`${PMC.CREW_DOCUMENT}/${params}`).then((res) => {
      let file = res.data.docBlob;
      commit('SET_CREW_DOC', res.data.document);
      commit('SET_CREW_DOC_HISTORY', res.data.history);

      if (file != null || file != undefined) {
        let parseBlob = UtilityService.base64ToBlobParser(res.data.docBlob);

        if (UtilityService.isGte2mb(parseBlob)) {
          commit('set_is_2mbUp', true);
          commit('set_doc_imageblob_gte10mb', res.data.docBlob);
        } else {

          commit('SET_IMAGE_BLOB', res.data.docBlob);
          commit('set_is_2mbUp', false);
        }
      }

      if(!file){
        commit('SET_IMAGE_BLOB', res.data.docBlob);
      }

      return res.data.docBlob;

    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'view crew document');
      return false;

    });
  },

  createCrewDoc({commit}, params) {
    return axios({
      method: 'post',
      url: `${PMC.CREW_DOCUMENT}`,
      data: params,
      'Content-Type': 'multipart/form-data',
      maxContentLength: 10000000,
      maxBodyLength: 10000000,

    }).then((res) => {
      commit('CREATE_CREW_DOC');
      return true;

    }).catch((er) => {
      UtilityService.generateResponseMessage(er);
      return false;

    });
  },

  uploadCrewDocFile({commit}, params) {
    return axios({
      method: 'post',
      url: `${PMC.UPLOAD_CREW_DOC_FILE}`,
      data: params,
      'Content-Type': 'multipart/form-data',
      maxContentLength: 10000000,
      maxBodyLength: 10000000,

    }).then((response) => {
      return true;

    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'Upload Crew Doc file.');
      return false;

    });
  },

  updateCrewDoc({commit}, params) {
    return axios({
      method: 'post',
      url: `${PMC.UPDATE_CREW_DOCUMENT}`,
      data: params,
      'Content-Type': 'multipart/form-data',
      maxContentLength: 10000000,
      maxBodyLength: 10000000,

    }).then((res) => {
      commit('UPDATE_CREW_DOC', res.data);
      return true;

    }).catch((error) => {
      UtilityService.generateResponseMessage(error, 'Update Crew Doc');
      return false;

    });
  },

  async updateCrewDocCcmChecklist ({ commit }, params) {
      return axios.post(`${PMC.UPDATE_CREW_DOCUMENT}`,params,{
        'Content-Type': 'multipart/form-data',
        maxContentLength: 10000000,
        maxBodyLength: 10000000,
      }).then((r)=>{
        return true;

      }).catch((error)=>{
        UtilityService.generateResponseMessage(error,'Update Crew Doc.');
        return false;

      })
  },

  // async updateCrewDocCcmChecklist({commit}, params) {
  //   return axios.post(PMC.UPDATE_CREW_DOCUMENT, params).then((r)=>{
  //     console.log('this from module js updateCrewDoc')
  //     return true;
  //
  //   }).catch((error)=>{
  //     UtilityService.generateResponseMessage(error,'Update Crew Doc');
  //     return false;
  //
  //   });
  // },


  deleteCrewDoc({commit}, params) {
    return axios.delete(`${PMC.CREW_DOCUMENT}/${params}`).then((res) => {
      commit('DELETE_CREW_DOC', res.data);
      return true;

    }).catch((er) => {
      UtilityService.generateResponseMessage(er, 'Delete Crew');
      return false;

    });
  },

  renewCrewDoc({commit}, params) {
    return axios({
      method: 'post',
      url: `${PMC.RENEW_CREW_DOCUMENT}`,
      data: params,
    }).then((res) => {
      return true;

    }).catch((err) => {
      UtilityService.generateResponseMessage(err, 'Renew Crew Document');
      return false;
    });
  },

  getDocTypeKeys({commit}) {
    return axios.get(PMC.GET_DOCTYPE_KEYS).then((res) => {
      commit('SET_CREWDOC_SIDENAV', res.data);
      return true;

    }).catch((err) => {
      return false;

    });
  },

  nullCrewDoc({commit}) {
    commit('NULL_CREW_DOC');
  },

  nullCrewDocs({commit}) {
    commit('NULL_CREW_DOCS');
  },

  nullCrewDocSideNav({commit}) {
    commit('NULL_CREWDOC_SIDENAV');
  },

  nullNewCrewDocuments({commit}) {
    commit('NULL_NEW_CREW_DOCUMENT');
  },

  setNewCrewDocFile({commit}, param) {
    commit('SET_NEW_CREWDOC_FILE', param);
  },

  nullNewCrewDocFile({commit}) {
    commit('NULL_NEW_CREW_DOC_FILE');
  },

  nullDocImageBlob({commit}) {
    commit('NULL_DOC_IMAGE_BLOB');
  },

  nullCrewDocHistory({commit}) {
    commit('NULL_CREW_DOC_HISTORY');
  },

  nullRenewCrewDoc({commit}) {
    commit('NULL_RENEW_CREW_DOCUMENT');
  },

  set_doc_imageblob_gte10mb({commit}, params) {
    commit('set_doc_imageblob_gte10mb', params);
  },

  null_doc_imageblob_gte10mb({commit}) {
    commit('null_doc_imageblob_gte10mb');
  },

  set_is_2mbUp({commit}, params) {
    commit('set_is_2mbUp', params);
  },

  set_image_blob({commit}, params) {
    commit('SET_IMAGE_BLOB', params);
  },

  //covidVaccineBlob

  set_form_doc_image_file({commit}, params) {
    commit('set_form_doc_image_file', params);
  },

  set_form_doc_image_filerenew({commit}, params) {
    commit('set_form_doc_image_filerenew', params);
  },

  set_form_10mb_docfile({commit}, params) {
    commit('set_form_10mb_docfile', params);
  },
};

/** getters **/
const getters = {
  crewDocuments: state => UtilityService.capitalizeProperties(state.crewDocuments),
  crewDocument: state => state.crewDocument,
  newCrewDocument: state => state.newCrewDocument,
  crewDocSideNav: state => state.crewDocSideNav,
  docImageBlob: state => state.docImageBlob,
  crewDocHistories: state => state.crewDocHistories,
  renewCrewDocument: state => state.renewCrewDocument,
  is2mbUp: state => state.is2mbUp,
  docImageBlob_gte10mb: state => state.docImageBlob_gte10mb,  //doc image 10 mb up
  formDocImgFile: state => state.formDocImgFile,
  form10mbUpDocFile: state => state.form10mbUpDocFile,
  formDocImgFileRenew: state => state.formDocImgFileRenew,
};

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
};
