export const FileService = {
  base64FileToBlobUrl(base64File) {
    base64File = base64File.replace('data:application/pdf;base64,', '')
    base64File = base64File.replace('data:image/png;base64,', '')
    base64File = base64File.replace('data:image/jpeg;base64,', '')
    // data:image\/png;base64,
    const byteCharacters = atob(base64File);
    const byteArrays = [];
    let sliceSize = 512;
    let contentType = 'application/pdf';

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
    // window.open(blobUrl);
  }
}
