<template>
  <div class="container-fluid">
    <div class="row pt-3">
      <label class="font-weight-bold ml-sm-4">
        <h4>Crew Medical History</h4>
      </label>
      <div>
        <pmc-modal-btn :button-class="'btn btn-sm ml-4 pmc-btn-prime'"
                       :target-modal="newCrewMedicModal"
                       :button-label="'Add'">
          <template slot="button-icon">
            <font-awesome-icon icon="plus-circle"/>
          </template>
        </pmc-modal-btn>
      </div>

      <!--<div class="col-8 font-weight-bold justify-content-center">
        <pmc-modal-btn :button-class="'btn btn-sm ml-4 pmc-btn-prime'"
                       :target-modal="newCrewMedicModal"
                       :button-label="'Add'">
          <template slot="button-icon">
            <font-awesome-icon icon="plus-circle"/>
          </template>
        </pmc-modal-btn>
      </div>-->
    </div>

    <pmc-table :max-height="400" :loading="isReloading" :loaderColspan="10">
      <template v-slot:table-header>
        <tr>
          <th scope="col" style="width:2%;">#</th>
          <th scope="col" style="width:8%;">Date Endorsed</th>
          <th scope="col" style="width: 8%;">Date Check-up</th>
          <th scope="col" style="width: 18%;">Clinic</th>
          <th scope="col" style="width : 14%">Medical Issue</th>
          <th scope="col" style="width: 17%">Diagnosis</th>
          <th scope="col" style="width: 17%">Remarks</th>
          <th scope="col" style="width: 3%">Fit</th>
          <th scope="col" style="width: 3%">Complete</th>
          <th scope="col" style="width: 8%">Action</th>
        </tr>
      </template>

      <template v-slot:table-body>
        <tr v-for="(crewMedical,index) in crewMedicals" :key="crewMedical.id">
          <td class="text-center p-1 align-middle">
            {{ index + 1 }}
          </td>
          <td class="text-center p-1 align-middle">
            {{ crewMedical.dateEndorsed_str }}
          </td>

          <td class="text-center p-1 align-middle">
            {{ crewMedical.dateCheckup_str }}
          </td>

          <td class="text-left p-1 align-middle">
            {{ crewMedical.clinic }}
          </td>

          <td class="text-left p-1" v-if="crewMedical.medicalIssues">
            <li v-for="(medIssue,index) in crewMedical.medicalIssues" :key="medIssue.id">
              <span class="small text-wrap">{{ medIssue.name }}</span>
            </li>
          </td>

          <td class="p-1 align-middle">
            {{ crewMedical.diagnosis }}
          </td>

          <td class="p-1 align-middle">
            <div class="text-left">
              <p class="ml-1 text-wrap small">{{ crewMedical.remarks }}</p>
            </div>
          </td>

          <td class="p-1">
            <input class="remove-pointer" type="checkbox" v-model="crewMedical.isFitToWork">
          </td>

          <td class="p-1">
            <input class="remove-pointer" type="checkbox" v-model="crewMedical.isCompleted">
          </td>

          <td class="p-1">
            <pmc-modal-btn
              :button-class="'btn btn-xs pmc-btn-prime'"
              :button-label="'view'"
              :target-modal="viewCrewMedicModal"
              @modalButtonEvent="view_crew_medical(crewMedical.id)">
              <template v-slot:button-icon>
                <font-awesome-icon icon="bars"/>
              </template>
            </pmc-modal-btn>

            <img type="button"
                 v-if="crewMedical.hasFile"
                 data-toggle="tooltip" data-placement="center"
                 class="ml-2 custom-icon-size"
                 @click="view_crew_medical_new_window(crewMedical.id)"
                 :src="viewPDFIcon" alt="View Document Attached" title="Document Attached"/>

            <pmc-btn @buttonEvent="delete_crew_medical(crewMedical.id)"
                     :button-label="'delete'"
                     :button-class="'btn btn-xs pmc-btn-danger ml-1'">
              <template slot="button-icon">
                <font-awesome-icon icon="trash" class="icon-margin-top"/>
              </template>
            </pmc-btn>
          </td>
        </tr>
      </template>
    </pmc-table>

    <!-- new crew medical    -->
    <pmc-modal xl_width xl_height :id="newCrewMedicModal" @closingEvent="close_new_crew_medical">
      <template slot="custom-header">
        <span class="font-weight-bold">CREATE NEW CREW MEDICAL</span>
      </template>
      <template slot="custom-body">
        <div class="row">
          <div class="col-md-6 text-left">
            <!-- date endorsed -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">DATE ENDORSED</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <input type="date" v-model="newCrewMedical.dateEndorsed" class="form-control form-control">
              </div>
            </div>

            <!-- date check up -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">DATE CHECKUP</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <input type="date" v-model="newCrewMedical.dateCheckUp" class="form-control form-control">
              </div>
            </div>

            <!-- clinic name -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">CLINIC</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <multi-select
                  v-model="thisNewClinic"
                  :options="objClinics"
                  :custom-label="clinicNameLang"
                  :select-label="'Select Clinic'"
                  placeholder="Select Clinic"
                  label="Clinic"
                  track-by="id">
                </multi-select>
              </div>

              <pmc-btn
                :button-label="''"
                :button-class="'btn btn-xs btn-secondary'">
                <template slot="button-icon">
                  <font-awesome-icon icon="plus-circle"/>
                </template>
              </pmc-btn>
            </div>

            <!-- medical issues -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">MEDICAL ISSUES</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <multi-select
                  v-model="thisNewMedicalIssue"
                  :options="objMedicalIssues"
                  :custom-label="clinicNameLang"
                  :select-label="'Select Medical Issue'"
                  placeholder="Select Medical Issue"
                  label="Clinic"
                  multiple
                  track-by="id">
                </multi-select>
              </div>
              <pmc-btn
                :button-label="''"
                :button-class="'btn btn-xs btn-secondary'">
                <template slot="button-icon">
                  <font-awesome-icon icon="plus-circle"/>
                </template>
              </pmc-btn>
            </div>

            <!-- Fit to work -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">FIT TO WORK</label>
              <span>:</span>
              <div class="col-sm-6 text-left">
                <label class="mr-2">{{ newCrewMedical.isFitToWork ? 'YES' : 'NO' }}</label>
                <input type="checkbox" class="form-check-inline ml-2" v-model="newCrewMedical.isFitToWork">
              </div>
            </div>

            <!-- Completed -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">COMPLETED</label>
              <span>:</span>
              <div class="col-sm-6 text-left">
                <label class="mr-2">{{ newCrewMedical.isCompleted ? 'YES' : 'NO' }}</label>
                <input type="checkbox" class="form-check-inline ml-2" v-model="newCrewMedical.isCompleted">
              </div>
            </div>

            <!-- ATTACHED FILE -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">ATTACHED FILE</label>
              <span>:</span>
              <div class="col-sm-6 text-left">
                <input type="file" id="new-attached-medical-file" class="form-control-file"
                       @change="on_attached_new_file" ref="newFileInput">
              </div>
            </div>

            <!-- Diagnosis -->
            <div class="form-group row mt-2 mb-4">
              <label class="col-sm-3 col-form-label">DIAGNOSIS</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <textarea class="form-control"
                          id="diagnosis"
                          style="height: 80%;
                          resize: none;"
                          placeholder="Diagnosis here...."
                          v-model="newCrewMedical.diagnosis"
                          maxlength="300"
                          rows="3"/>
              </div>
            </div>

            <!-- Remarks -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">REMARKS</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <textarea class="form-control"
                          id="remarks"
                          style="height: 80%;
                             resize: none;"
                          placeholder="Remarks here...."
                          v-model="newCrewMedical.remarks"
                          maxlength="300"
                          rows="3"/>
              </div>
            </div>
          </div>

          <div class="col-md-6 border-left">
            <div style="height: 400px;">
              <span v-if="!formCrewMedicalBlob">
                <font-awesome-icon icon="file" style="height: 80%; "/>
              </span>
              <object v-if="formCrewMedicalBlob" width="100%" height="100%"
                      :data="formCrewMedicalBlob+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=5,10,0'"
                      type="application/pdf"></object>
            </div>
          </div>

        </div>
      </template>

      <template slot="custom-footer">
        <pmc-btn
          :button-label="'SAVE'"
          @buttonEvent="save_new_medical"
          :button-class="'btn btn-sm pmc-btn-tertia'">
          <template slot="button-icon">
            <font-awesome-icon icon="save"/>
          </template>
        </pmc-btn>

        <pmc-btn
          @buttonEvent="close_new_crew_medical"
          :button-label="'CLOSE'"
          :button-class="'btn btn-sm btn-secondary'">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>
      </template>

    </pmc-modal>

    <!--  view crew medical  -->
    <pmc-modal xl_width xl_height :id="viewCrewMedicModal" @closingEvent="close_view_crew_medical">
      <template slot="custom-header">
        <span>Crew Medical Details</span>
      </template>
      <template slot="custom-body">
        <div class="row">
          <div class="col-md-6 text-left">
            <!-- date endorsed -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">DATE ENDORSED</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <input v-if="isEditable" type="date" v-model="crewMedical.dateEndorsed"
                       class="form-control form-control-sm">
                <input v-if="!isEditable" readonly type="text" v-model="crewMedical.dateEndorsed_str"
                       class="form-control-plaintext">
              </div>
            </div>

            <!-- date check up -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">DATE CHECKUP</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <input v-if="isEditable" type="date" v-model="crewMedical.dateCheckUp"
                       class="form-control form-control-sm">
                <input v-if="!isEditable" readonly type="text" v-model="crewMedical.dateCheckup_str"
                       class="form-control-plaintext">
              </div>
            </div>

            <!-- clinic name -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">CLINIC</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <multi-select
                  :disabled="!isEditable"
                  v-model="thisClinic"
                  :options="objClinics"
                  :custom-label="clinicNameLang"
                  :select-label="'Select Clinic'"
                  placeholder="Select Clinic"
                  label="Clinic"
                  track-by="id">
                </multi-select>
              </div>
            </div>

            <!-- medical issues -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">MEDICAL ISSUES</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <multi-select
                  :disabled="!isEditable"
                  v-model="thisMedicalIssue"
                  :options="objMedicalIssues"
                  :custom-label="clinicNameLang"
                  :select-label="'Select Medical Issue'"
                  placeholder="Select Medical Issue"
                  label="Clinic"
                  multiple
                  track-by="id">
                </multi-select>
              </div>
            </div>

            <!-- Fit to work -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">FIT TO WORK</label>
              <span>:</span>
              <div class="col-sm-6 text-left">
                <label class="mr-2">{{ crewMedical.isFitToWork ? 'YES' : 'NO' }}</label>
                <input :disabled="!isEditable" type="checkbox" class="form-check-inline ml-2"
                       v-model="crewMedical.isFitToWork">
              </div>
            </div>

            <!-- Completed -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">COMPLETED</label>
              <span>:</span>
              <div class="col-sm-6 text-left">
                <label class="mr-2">{{ crewMedical.isCompleted ? 'YES' : 'NO' }}</label>
                <input :disabled="!isEditable" type="checkbox" class="form-check-inline ml-2"
                       v-model="crewMedical.isCompleted">
              </div>
            </div>

            <!-- ATTACHED FILE -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">
                {{ crewMedical.hasFile ? 'Replace file' : 'Attach file' }}
              </label>
              <span>:</span>
              <label class="ml-3 mt-1">{{ isAttachedFile ? 'YES' : 'NO' }}</label>
              <input :disabled="!isEditable" type="checkbox" class="form-check-inline ml-3 mt-2"
                     v-model="isAttachedFile">
              <div class="col-sm-5 text-left">
                <input :disabled="!isAttachedFile" type="file" id="medical-file" class="form-control-file mb-2"
                       @change="on_attached_crew_medical" ref="attachedFileInput">
              </div>
            </div>

            <!-- Diagnosis -->
            <div class="form-group row mt-2 mb-4">
              <label class="col-sm-3 col-form-label">DIAGNOSIS</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <textarea class="form-control"
                          :readonly="!isEditable"
                          id="diagnosisView"
                          style="height: 100%;
                          resize: none;"
                          placeholder="Diagnosis here...."
                          v-model="crewMedical.diagnosis"
                          maxlength="300"
                          rows="3"/>
              </div>
            </div>

            <!-- Remarks -->
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">REMARKS</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <textarea class="form-control"
                          :readonly="!isEditable"
                          id="remarksView"
                          style="height: 100%;
                           resize: none;"
                          placeholder="Remarks here...."
                          v-model="crewMedical.remarks"
                          maxlength="300"
                          rows="3"/>
              </div>
            </div>
          </div>

          <div class="col-md-6 border-left">
            <div class="prev-container">
              <div v-if="!is10mbMedical" style="height: 570px;">
                <span v-if="!thisCrewMedicalBlob">
                  <font-awesome-icon icon="file" style="height: 80%; "/>
                </span>
                <object v-if="thisCrewMedicalBlob" width="100%" height="100%"
                        :data="thisCrewMedicalBlob+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=5,10,0'"
                        type="application/pdf"></object>
              </div>

              <div v-if="is10mbMedical">
                <div class="center-alignment">
                  <button class="btn btn-xs btn-outline-danger" @click="previewFileDocument(thisCrewMedical10MbUp)">
                    <font-awesome-icon icon="file"/>
                    <small>document</small>
                  </button>
                  <p class="small ml-1 text-muted">Click to preview file more than 3mb.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="custom-footer">
        <pmc-btn
          v-if="isEditable"
          @buttonEvent="save_updated_crew_medical"
          :button-class="'btn btn-sm pmc-btn-tertia'"
          :button-label="'Update'">
          <template slot="button-icon">
            <font-awesome-icon icon="save"/>
          </template>
        </pmc-btn>

        <pmc-btn
          v-if="isEditable"
          @buttonEvent="set_editable_crewmedical"
          :button-class="'btn btn-sm pmc-btn-danger'"
          :button-label="'Cancel'">
          <template slot="button-icon">
            <font-awesome-icon icon="undo"/>
          </template>
        </pmc-btn>

        <pmc-btn
          v-if="!isEditable"
          @buttonEvent="set_editable_crewmedical"
          :button-class="'btn btn-sm pmc-btn-warning'"
          :button-label="'Edit'">
          <template slot="button-icon">
            <font-awesome-icon icon="edit"/>
          </template>
        </pmc-btn>

        <pmc-btn
          v-if="!isEditable"
          @buttonEvent="close_view_crew_medical"
          :button-class="'btn btn-sm btn-secondary'"
          :button-label="'Close'">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>
      </template>
    </pmc-modal>

    <!--  upload crew medical  -->
    <pmc-modal :id="uploadCrewMedicModal" @closingEvent="close_upload_crew_medical">
      <template slot="custom-header"></template>
      <template slot="custom-body"></template>
      <template slot="custom-footer"></template>
    </pmc-modal>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {AppMixins} from '../../../../../mixins/app.mixins'
import {AlertMixins} from '../../../../../mixins/alert.mixins'
import Button from '../../../../base/Button'
import viewPDFIcon from "@/assets/viewpdf.png";

export default {
  name: 'CrewMedical',
  components: {Button},
  mixins: [AppMixins, AlertMixins],
  data() {
    return {
      crewId: parseInt(this.$route.params.crewId, 10),

      newCrewMedicModal: 'newCrewMedicModal',
      viewCrewMedicModal: 'viewCrewMedicModal',
      uploadCrewMedicModal: 'uploadCrewMedicModal',

      formClinic: null,
      formMedicalIssue: null,

      isAttachedFile: false,
      isCancel: false,
      isEditable: false,

      medicIssueLimit: 8,
    }
  },

  methods: {
    ...mapActions([
      'getCrewMedicals',
      'viewCrewMedical',
      'getCrewMedicalKeys',
      'null_obj_medical_issues',
      'null_obj_clinics',
      'nullNewCrewMedical',
      'createCrewMedical',
      'deleteCrewMedical',
      'updateCrewMedical',
      'NULL_CREW_MEDICAL',
      'NULL_CREW_MEDICALS',
      'set_form_crewmedical_blob',
      'null_form_crewmedical_blob',
      'set_form_crew_medical_blob_gte10mb',
      'set_is10mb_medical',
      'set_crew_medical_blob_file',
      'set_crew_medical_blob_file_gte10mb',
      'null_crew_medical_blob_file_gte10mb',
      'null_crew_medical_blob_file',
    ]),


    get_crew_medicals() {
      this.getCrewMedicals({crewId: this.crewId})
    },

    view_crew_medical(crewMedicalId) {
      this.viewCrewMedical(crewMedicalId)
    },

    async view_crew_medical_new_window(crewMedicalId) {
      let medDoc = await this.viewCrewMedical(crewMedicalId);
      const fileUrl = this.base64PdfParser(medDoc)
      window.open(fileUrl, '', "width=0, height=0");
    },

    close_new_crew_medical() {
      this.nullNewCrewMedical()
      this.hideModal(this.newCrewMedicModal)
      this.formMedicalIssue = null
    },

    close_view_crew_medical() {
      this.hideModal(this.viewCrewMedicModal)
      this.NULL_CREW_MEDICAL()
      this.formMedicalIssue = null
      this.set_form_crewmedical_blob(null);
      this.set_form_crew_medical_blob_gte10mb(null);

      if (this.crewMedicalBlobFile != null || this.crewMedicalBlobFile_gte10mb != null) {
        this.set_crew_medical_blob_file_gte10mb(null);
        this.set_crew_medical_blob_file(null);
      }

      this.set_is10mb_medical(false)
    },

    close_upload_crew_medical() {
      //todo
    },

    clinicNameLang({name, id,}) {
      return `${name.toUpperCase()}`
    },

    medIssiueNameLang({
                        name,
                        id,
                      }) {
      return `${name}`
    },

    on_attached_new_file(e) {
      const blob = e.target.files[0]
      const reader = new FileReader()

      if (blob != (null || undefined)) {
        reader.readAsDataURL(blob)
        reader.onload = e => {
          let param = e.target.result
          this.set_form_crewmedical_blob(param)
        }
      } else {
        this.set_form_crewmedical_blob(param)
        $(`#new-attached-medical-file`).val(null)
      }
    },

    on_attached_crew_medical(e) {
      const blob = e.target.files[0]
      const reader = new FileReader()

      if (blob != (null || undefined)) {
        reader.readAsDataURL(blob)
        reader.onload = async e => {
          let param = e.target.result
          if (this.gte2mb(blob)) {
            this.set_is10mb_medical(true)
            this.set_form_crew_medical_blob_gte10mb(param)
            let msg = 'It seem\'s like you want to upload a file more than 3mb. \n' +
              'Do you want to preview. ?'

            if (await this.questionAlert(`${msg}`, 'File preview', 'warning')) {
              this.base64ToBlob(blob)
            }

          } else {
            this.set_is10mb_medical(false)
            this.set_form_crewmedical_blob(param)
          }
        }
      } else {
        this.set_form_crewmedical_blob(param)
        $(`#medical-file`).val(null)
      }
    },

    on_upload_crew_medical() {
      //todo
    },

    set_cancel_updateFile() {
      this.isUpdate = !this.isUpdate
    },

    set_cacel_uploadFile() {
      this.isUpload = !this.isUpload
    },

    //create new medical
    async save_new_medical() {
      if (await this.questionAlert('Are you sure you want to add crew medical record?', 'Add Medical Record')) {
        const formData = new FormData()
        let file = this.$refs.newFileInput.files[0]
        formData.append('crewId', this.crewId.toString())
        formData.append('dateEndorsed', this.newCrewMedical.dateEndorsed)
        formData.append('dateCheckUp', this.newCrewMedical.dateCheckUp)
        formData.append('clinic', this.newCrewMedical.clinic)
        formData.append('medicalIssues', this.newCrewMedical.medicalIssues)
        formData.append('isFitToWork', this.newCrewMedical.isFitToWork)
        formData.append('isCompleted', this.newCrewMedical.isCompleted)
        formData.append('diagnosis', this.newCrewMedical.diagnosis)
        formData.append('remarks', this.newCrewMedical.remarks)
        formData.append('file', file)
        this.createCrewMedical(formData).then((r) => {
          if (r) {
            this.successAlert('Save successfuly')
            this.nullNewCrewMedical()
            this.hideModal(this.newCrewMedicModal)
            this.set_form_crewmedical_blob(param)
            this.formMedicalIssue = null
            this.formClinic = null
            $(`#new-attached-medical-file`).val(null)
          }
        })
      }
    },

    //set editable crew medical
    set_editable_crewmedical() {
      this.isEditable = !this.isEditable
      if (!this.isEditable) {
        $(`#medical-file`).val(null)
        this.isAttachedFile = this.isAttachedFile ? !this.isAttachedFile : false
        this.view_crew_medical(this.crewMedical.id)
        this.formMedicalIssue = null

        if (this.crewMedicalBlobFile_gte10mb != null) {
          this.set_form_crew_medical_blob_gte10mb(null)
        }
        this.set_form_crewmedical_blob(null)
      }
    },

    //update crew medical
    save_updated_crew_medical() {
      let medIssues = this.crewMedical.medicalIssues.map((val) => val.id ? val.id : val)
      let file = this.$refs.attachedFileInput.files[0]

      const formData = new FormData()

      formData.append('id', this.crewMedical.id)
      formData.append('dateEndorsed', this.crewMedical.dateEndorsed)
      formData.append('dateCheckUp', this.crewMedical.dateCheckUp)
      formData.append('clinicId', this.crewMedical.clinicId)
      formData.append('clinic', this.crewMedical.clinicId)  //use clinicId
      formData.append('medicalIssues', medIssues)
      formData.append('isFitToWork', this.crewMedical.isFitToWork ? 1 : 0)
      formData.append('isCompleted', this.crewMedical.isCompleted ? 1 : 0)
      formData.append('diagnosis', this.crewMedical.diagnosis)
      formData.append('remarks', this.crewMedical.remarks)
      formData.append('file', file)

      this.updateCrewMedical(formData).then((r) => {
        if (r) {
          this.successAlert('Crew medical updated successfully')
          this.hideModal(this.viewCrewMedicModal)
          this.NULL_CREW_MEDICAL()
          this.isEditable = !this.isEditable
          this.isAttachedFile = this.isAttachedFile ? !this.isAttachedFile : false
        }
      })
    },

    async delete_crew_medical(crewMedicalId) {
      if (await this.questionAlert('Are you sure you want to delete this record.', 'Delete Crew Medical Record')) {
        this.deleteCrewMedical(crewMedicalId).then((r) => {
          if (r) {
            this.successAlert('Crew medical record deleted successfuly')
          }
        })
      }
    },

    //preview larger file size attach.
    previewFileDocument(__stringFileParam) {
      this.base64ToBlob(__stringFileParam)
    },
  },

  computed: {
    ...mapGetters([
      'crewMedicals',
      'objMedicalIssues',
      'objClinics',
      'newCrewMedical',
      'crewMedicalBlobFile',
      'crewMedicalBlobFile_gte10mb',
      'crewMedical',
      'is10mbMedical',
      'formCrewMedicalBlob',
      'formCrewMedicalBlob_gte10mb',
      'isReloading'
    ]),

    viewPDFIcon() {
      return viewPDFIcon;
    },

    thisNewClinic: {
      get() {
        return this.formClinic
      },

      set(val) {
        this.formClinic = val
        this.newCrewMedical.clinic = val ? val.id : null
      },
    },

    /* for crew medical */
    thisClinic: {
      get() {
        let _clinic = this.crewMedical.clinicId
          ? this.objClinics.find((val, index) => val.id === this.crewMedical.clinicId)
          : this.formClinic
        return _clinic
      },
      set(val) {
        this.crewMedical.clinic = val ? val.name : ''
        this.crewMedical.clinicId = val ? val.id : ''
      },
    },

    thisNewMedicalIssue: {
      get() {
        return this.formMedicalIssue
      },
      set(val) {
        if (val.length <= this.medicIssueLimit) {
          let result = val.map((val) => val.id)
          this.formMedicalIssue = val
          this.newCrewMedical.medicalIssues = val ? result : null
        } else {
          this.warningAlert(`Tagging medical issue exceeded.`)
        }
      },
    },

    thisMedicalIssue: {
      get() {
        return this.formMedicalIssue ? this.formMedicalIssue : this.crewMedical.medicalIssues
      },
      set(val) {
        if (val.length <= this.medicIssueLimit) {
          let result = val.map((val) => val.id)
          this.formMedicalIssue = val
          this.crewMedical.medicalIssues = val ? result : null
        } else {
          this.warningAlert(`Tagging medical issue exceeded.`)
        }
      },
    },

    thisCrewMedicalBlob() {
      return this.formCrewMedicalBlob ? this.formCrewMedicalBlob : this.crewMedicalBlobFile
    },

    thisCrewMedical10MbUp() {
      return this.formCrewMedicalBlob_gte10mb ? this.formCrewMedicalBlob_gte10mb : this.crewMedicalBlobFile_gte10mb
    },
  },

  /** watch **/
  watch: {
    isAttachedFile(val) {
      if (!val) {
        $(`#medical-file`).val(null)
        this.set_form_crewmedical_blob(null)
      }
    },
  },

  /** mounted **/
  mounted() {
  },

  /** created **/
  created() {
    this.get_crew_medicals()
    this.getCrewMedicalKeys()
  },

  /** destroyed **/
  destroyed() {
    this.null_obj_medical_issues()
    this.null_obj_clinics()
    this.NULL_CREW_MEDICAL()
    this.NULL_CREW_MEDICALS()
    this.null_crew_medical_blob_file()
    this.null_crew_medical_blob_file_gte10mb()
  },
}
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(1) !important;
}

.multiselect {
  width: 100%;
  min-height: 20px !important;
}

</style>
