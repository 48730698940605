<template>
  <div>
    <!-- side bar  -->
    <pmc-side-nav>
      <template v-slot:contents>
        <div v-if="crewDocSideNav.length <= 0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <a v-else v-for="(docType,index) in crewDocSideNav"
           :class="{'active' : docType.id == formParam.docType}"
           :key="docType.id"
           @click="set_doc_type(docType.id)">
          <span v-if="titleCase(docType.name) === 'Cop'">COP</span>
          <span v-else>{{ titleCase(docType.name) }}</span>
        </a>
        <!--<a :class="{'active' : 'ncov' == formParam.docType}"
           @click="set_doc_type('ncov')">
          Covid Vaccine
        </a>-->
        <a v-if="!crewDocSideNav" href="#">
          Failed to load data.
        </a>
      </template>
    </pmc-side-nav>

    <!-- section if not covid document   -->
    <div id="main" class="text-left bg-white" style=" height: 100vh !important;">
      <div v-if="formParam.docType !== 11" class="pl-3">
        <div class="row">
          <div class="col-md-8">
            <div class="row pt-3">
              <label class="font-weight-bold ml-sm-4">
                <h4>Document</h4>
              </label>
              <div class="col-8 font-weight-bold justify-content-center">
                <pmc-modal-btn :button-class="'btn btn-sm pmc-btn-prime ml-5'"
                               :target-modal="newDocModal"
                               :button-label="'add'">
                  <template slot="button-icon">
                    <font-awesome-icon icon="plus-circle"/>
                  </template>
                </pmc-modal-btn>
              </div>
            </div>
          </div>

          <div class="col-md-3" v-if="formParam.docType === 1">
            <div class="row pt-3">
              <label class="col-sm-3 font-weight-bold">Category</label>
              <span>:</span>
              <div class="col-sm-5">
                <select name="DocCathegory" id="doc-category" class="form-control form-control-sm" v-model="formParam.docCategory" @change="on_change_category">
                  <option :value="null">-- all --</option>
                  <option :value="dc.id" v-for="(dc,index) in docCategories" :key="dc.id">
                    {{ titleCase(dc.val) }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="row pt-2">
          <div class="col">
            <pmc-table :max-height="400" :loading="isReloading" :loaderColspan="8">
              <template v-slot:table-header>
                <tr>
                  <th scope="col" width="3%">#</th>
                  <th scope="col" width="25%">Document</th>
                  <th scope="col" width="15%">Document No.</th>
                  <th scope="col" width="15%">Document No2.</th>
                  <th scope="col" width="10%">Issued Date</th>
                  <th scope="col" width="10%">Expiry Date</th>
                  <th scope="col" width="5%">Rank</th>
                  <th scope="col" width="15%">Action</th>
                </tr>
              </template>

              <template  v-slot:table-body>
                <tr v-for="(crewDoc,index) in crewDocuments" :key="crewDoc.id">
                  <td class="text-center">
                    <span>{{ index + 1 }}</span>
                  </td>
                  <td class="text-left">
                    <span class="pl-2">{{ crewDoc.documentName }}</span>
                  </td>
                  <td class="text-left">
                    <span class="pl-2">{{ crewDoc.documentNo }}</span>
                  </td>
                  <td class="text-left">
                    <span class="pl-2">{{ crewDoc.documentNo2 }}</span>
                  </td>
                  <td class="text-center">
                    <span>
                      {{ crewDoc.dateIssued_str ? crewDoc.dateIssued_str : '---' }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span>
                      {{ crewDoc.dateExpiry_str }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span>
                      {{ crewDoc.rank ? crewDoc.rankAlias : '---' }}
                    </span>
                  </td>
                  <td>
                    <pmc-modal-btn
                        :button-class="'btn btn-xs pmc-btn-tertia'"
                        :button-label="'view'"
                        :target-modal="viewDocModal"
                        @modalButtonEvent="view_document(crewDoc.id, crewDoc)">
                      <template slot="button-icon">
                        <font-awesome-icon icon="bars"/>
                      </template>
                    </pmc-modal-btn>

                    <pmc-modal-btn
                        v-if="!crewDoc.hasFile"
                        :button-class="'btn btn-xs pmc-bg-prime-light ml-2'"
                        :button-label="''"
                        :target-modal="upFileModal"
                        @modalButtonEvent="set_up_doc_id(crewDoc.id)">
                      <template slot="button-icon">
                        <font-awesome-icon icon="upload"/>
                      </template>
                    </pmc-modal-btn>

                    <pmc-modal-btn
                        v-if="crewDoc.hasFile"
                        :button-class="'btn btn-xs pmc-btn-prime ml-2'"
                        :button-label="''"
                        :target-modal="renewDocModal"
                        @modalButtonEvent="set_renew_doc_id(crewDoc)">
                      <template slot="button-icon">
                        <font-awesome-icon icon="sync"/>
                      </template>
                    </pmc-modal-btn>

                    <pmc-btn
                        :button-class="'btn btn-xs pmc-btn-danger ml-2'"
                        :button-label="'del'"
                        @buttonEvent="delete_document(crewDoc.id)">
                      <template slot="button-icon">
                        <font-awesome-icon icon="trash"/>
                      </template>
                    </pmc-btn>
                  </td>
                </tr>
              </template>

            </pmc-table>
          </div>
        </div>
      </div>

      <div v-if="formParam.docType === 11">
        <router-view/>
      </div>
    </div>

    <!-- view modal for crew document   -->
    <pmc-modal xl_width :id="viewDocModal" @closingEvent="doc_editable_close">
      <template slot="custom-header">
        <h6 v-if="crewDocData.documentName" class="mr-3">
          {{ crewDocData.documentName?.toUpperCase() }}
        </h6>

        <pmc-modal-btn
            v-if="crewDocData.hasFile"
            :button-class="'btn btn-xs btn-primary ml-2 mr-2'"
            :button-label="'document'"
            @modalButtonEvent="openDocumentNewWindow(thisDocImageBlob)">
          <template slot="button-icon">
            <font-awesome-icon icon="window-maximize"/>
          </template>
        </pmc-modal-btn>

      </template>

      <template slot="custom-body">
        <div class="row">
          <div class="text-left col-md-6">
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Document No.</label>
              <span>:</span>
              <div class="col-sm-8">
                <input
                    type="text"
                    :disabled="!isCrewDocEdited"
                    v-model="crewDocData.documentNo"
                    class="form-control form-control-sm"
                    name="FamFirst"
                    placeholder="--">
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Document No. 2</label>
              <span>:</span>
              <div class="col-sm-8">
                <input type="text"
                       :disabled="!isCrewDocEdited"
                       v-model="crewDocData.documentNo2"
                       class="form-control form-control-sm"
                       name="FamFirst"
                       placeholder="--">
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Ranks</label>
              <span>:</span>
              <div class="col-md-8">
                <multi-select
                    :disabled="!isCrewDocEdited"
                    v-model="thisCrewDocRankId"
                    :options="objRanks"
                    :custom-label="ranks_lang"
                    :select-label="'Select Document Rank'"
                    placeholder="Select Document Rank"
                    label="Document Rank"
                    track-by="id">
                </multi-select>
              </div>
            </div>

            <div class="form-group row mt-2" v-if="crewDocData.isExtension">
              <label class="col-sm-3 col-form-label">Is Extension ?</label>
              <span>:</span>
              <div class="col-sm-8 ml-3">
                <span>
                      <font-awesome-icon icon="check" style="transform: scale(1.7); color:#28a745;"/>
                </span>
              </div>
            </div>

            <div class="form-group row mt-2" v-if="crewDocData.isExtension">
              <label class="col-sm-3 col-form-label">Contract Extension Type</label>
              <span>:</span>
              <div class="col-sm-8 ml-3">
                <span v-if="!isHistoryLogOnClick">
                      {{ crewDocData.extensionTypeNew?.toUpperCase() }}
                </span>

                <span v-if="isHistoryLogOnClick">
                       {{ crewDocData.extensionTypeOld?.toUpperCase() }}
                </span>
              </div>
            </div>


            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Date Issued</label>
              <span>:</span>
              <div class="col-sm-6">
                <input
                    type="date"
                    :disabled="!isCrewDocEdited"
                    v-model="crewDocData.dateIssued"
                    class="form-control form-control-sm">
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Date Expiry</label>
              <span>:</span>
              <div class="col-sm-6">
                <input
                    type="date"
                    :disabled="!isCrewDocEdited"
                    v-model="crewDocData.dateExpiry"
                    class="form-control form-control-sm"
                    placeholder="--">
              </div>
            </div>

            <div class="form-group row mt-3">
              <label class="col-sm-3 col-form-label">
                {{ crewDocData.hasFile ? 'Replace File' : 'Attach File' }}
              </label>
              <span class="col-form-label">:</span>
              <input :disabled="!isCrewDocEdited" type="checkbox" class="form-check form-check-inline ml-sm-4" v-model="isCrewDocReplace" @change="on_attachment_checkbox">
              <div class="col-sm-8">
                <input type="file"
                       :disabled="!isCrewDocReplace"
                       style="resize: none !important;"
                       class="form-control-file"
                       @change="on_change_attachFile"
                       id="replaceAttachment"
                       placeholder="--">
              </div>
            </div>

            <!-- todo remarks  -->
            <!--<div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Remarks</label>
              <span>:</span>
              <div class="col-sm-8">
                <font-awesome-icon icon="comment-dots" class="mr-2"/>
                <span class="small"><u>click to preview remarks</u></span>
              </div>
            </div>-->

            <div class="form-group row mt-3">
              <label class="col-sm-3 col-form-label">Created by</label>
              <span>:</span>
              <div class="col-sm-8">
                <span> {{ titleCase(crewDocData.createdBy) }}</span>
              </div>
            </div>

            <div class="form-group row mt-3 ">
              <label class="col-sm-3 col-form-label">Uploaded by</label>
              <span>:</span>
              <div class="col-sm-8">
                <span class="small"> {{ titleCase(crewDocData.uploadedBy) }}</span>
              </div>
            </div>

            <div class="form-group row mt-3">
              <label class="col-sm-3 col-form-label">Last Update by</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <span class="small">{{ titleCase(crewDocData.updatedBy) }}</span>
              </div>
            </div>

            <div class="form-group row mt-3">
              <label class="col-sm-3 col-form-label">Date Created :</label>
              <span>:</span>
              <div class="col-sm-8 text-left">
                <span class="small">{{ titleCase(crewDocData.createdAt) }}</span>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col">
                <span class="font-weight-bold mr-3">History Logs</span>
                <pmc-modal-btn
                  v-if="isActiveRenewLogHighlight"
                  :button-class="'btn btn-xs pmc-btn-tertia'"
                  :button-label="'Reset'"
                  @modalButtonEvent="resetRenewHistoryLog">
                </pmc-modal-btn>

<!--                <button class="btn btn-xs pmc-btn-tertia"> Reset</button>-->

              </div>
            </div>

            <pmc-table :max-height="300" class="mt-2">
              <template v-slot:table-header>
                <tr>
                  <th class="text-left pl-3" scope="col" width="22%">Uploaded By</th>
                  <th class="text-left pl-3" scope="col" width="14%">Date</th>
                  <th class="text-center" scope="col" width="10%">Rank</th>
                  <th class="text-left" scope="col" width="22%">Document No.</th>
                  <th class="" scope="col" width="10%"
                      v-if="crewDocData.documentId == contracts.contractOfEmployment">
                    Contract Extension?
                  </th>
                  <th class="" scope="col" width="60%"
                      v-if="crewDocData.documentId == contracts.contractOfEmployment">
                    Extension Type
                  </th>
                </tr>
              </template>

              <template v-slot:table-body>

                <tr v-for="(crewDocHistory,index) in crewDocHistories" :key="index"
                    @click="updateCrewDocHistory(crewDocHistory)"
                    :class="[activeRenewHistory(crewDocHistory), { 'badge-warning': crewDocHistory.isExtension }]"
                >
                <!-- <td class="text-left pl-3">{{ titleCase(crewDocHistory.logType) }}</td>-->
                  <td class="text-left pl-3">{{ crewDocHistory.createdBy ? titleCase(crewDocHistory.createdBy) : '--' }}</td>
                  <td class="text-left pl-3">{{ crewDocHistory.createdAt_str }}</td>
                  <td class="text-center">
                    {{ crewDocHistory.rank }}
                  </td>
                  <td class="text-left">
                    {{ crewDocHistory.documentNo }}
                  </td>

                  <td class="text-center" v-if="crewDocData.documentId == contracts.contractOfEmployment">
                    <span v-if="crewDocHistory.isExtension">
                      <font-awesome-icon icon="check" style="transform: scale(1.7); color:#28a745;"/>
                    </span>
                  </td>

                  <td class="text-center" v-if="crewDocData.documentId == contracts.contractOfEmployment">
                    {{ titleCase(crewDocHistory.extensionTypeOld) ?? titleCase(crewDocHistory.lastContractExtBeforeRenew) }}
                  </td>

                </tr>
              </template>
            </pmc-table>
          </div>

          <div class="col-md-6 border-left">
            <div class="prev-container">
              <div v-if="!is2mbUp" style="height: 700px;">
                  <span v-if="!thisDocImageBlob && !formDocImgFile">
                    <font-awesome-icon icon="file" style="height: 80%; "/>
                  </span>
                <object v-if="formDocImgFile" width="100%" height="100%" :data="formDocImgFile+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=50,10,0'" type="application/pdf"></object>
                <object v-if="thisDocImageBlob && !formDocImgFile" width="100%" height="100%" :data="thisDocImageBlob+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=50,10,0'" type="application/pdf"></object>
              </div>

              <div v-if="is2mbUp">
                <div class="center-alignment">
                  <button class="btn btn-xs btn-outline-danger" @click="previewFileDocument(this10mbDocImageBlob)">
                    <font-awesome-icon icon="file"/>
                    <small class="ml-2">document</small>
                  </button>
                  <p class="small ml-1">Click to preview file more than 3mb.</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>

      <template slot="custom-footer">
        <!--  set as editable -->
        <pmc-btn v-if="!isCrewDocEdited" :button-label="'Edit'" :button-class="'btn btn-sm pmc-btn-warning'" @buttonEvent="doc_editable">
          <template slot="button-icon">
            <font-awesome-icon icon="edit"/>
          </template>
        </pmc-btn>

        <!--  update doc -->
        <pmc-btn v-if="isCrewDocEdited" :button-label="'Update'" :button-class="'btn btn-sm pmc-btn-tertia'" @buttonEvent="update_document">
          <template slot="button-icon">
            <font-awesome-icon icon="save"/>
          </template>
        </pmc-btn>

        <!--  editable cancel -->
        <pmc-btn v-if="isCrewDocEdited" :button-label="'Cancel'" :button-class="'btn btn-sm pmc-btn-danger'" @buttonEvent="doc_editable_cancel">
          <template slot="button-icon">
            <font-awesome-icon icon="undo"/>
          </template>
        </pmc-btn>

        <!--  editable close -->
        <pmc-btn v-if="!isCrewDocEdited" :button-label="'Close'" :button-class="'btn btn-sm pmc-btn-danger'" @buttonEvent="doc_editable_close">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>

      </template>
    </pmc-modal>

    <!-- new modal for crew document   -->
    <pmc-modal
        @closingEvent="close_newDoc_modal"
        :lg_width="is2mbUp"
        :xl_width="!is2mbUp"
        :id="newDocModal">
      <template slot="custom-header">
        <span class="font-weight-bold">New Document</span>
      </template>

      <template slot="custom-body" style="position: absolute;">
        <div class="row">
          <div class="text-left border-right" :class="{'col-md-6': !is2mbUp,'col-md-12': is2mbUp}">
            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Document</label>
              <span>:</span>
              <div class="col-md-8">
                <multi-select
                    v-model="newCrewDocumentId"
                    :options="thisObjDocs"
                    :custom-label="docs_lang"
                    :select-label="'Select Document'"
                    placeholder="Select Document"
                    label="Document"
                    track-by="id">
                </multi-select>
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Ranks</label>
              <span>:</span>
              <div class="col-md-8">
                <multi-select
                  v-model="newCrewDocRankId"
                  :options="objRanks"
                  :custom-label="ranks_lang"
                  :select-label="'Select Document'"
                  placeholder="Select Document"
                  label="Document"
                  track-by="id">
                </multi-select>
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Document No.</label>
              <span>:</span>
              <div class="col-sm-8">
                <input
                    v-model="newCrewDocument.documentNo"
                    type="text"
                    class="form-control form-control-sm"
                    name="FamFirst"
                    placeholder="--">
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Document No. 2</label>
              <span>:</span>
              <div class="col-sm-8">
                <input type="text"
                       v-model="newCrewDocument.documentNo2"
                       class="form-control form-control-sm"
                       name="FamFirst"
                       placeholder="--">
              </div>
            </div>

            <div class="form-group row mt-2">
              <label for="new-date-issued" class="col-sm-3 col-form-label">Date Issued</label>
              <span>:</span>
              <div class="col-sm-6">
                <input
                    id="new-date-issued"
                    v-model="newCrewDocument.dateIssued"
                    type="date"
                    class="form-control form-control-sm" min="2000-01-02"/>
              </div>

              <button class="btn btn-xs" v-show="newCrewDocument.dateIssued" @click="newCrewDocument.dateIssued = null">
                <font-awesome-icon icon="times"/>
              </button>
            </div>

            <div class="form-group row mt-2">
              <label for="new-date-expiry" class="col-sm-3 col-form-label">Date Expiry</label>
              <span>:</span>
              <div class="col-sm-6">
                <input
                    id="new-date-expiry"
                    v-model="newCrewDocument.dateExpiry"
                    type="date"
                    class="form-control form-control-sm" min="2000-01-02"/>
              </div>

              <button class="btn btn-xs" v-show="newCrewDocument.dateExpiry" @click="newCrewDocument.dateExpiry = null">
                <font-awesome-icon icon="times"/>
              </button>
            </div>

            <!--<div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Remarks</label>
              <span>:</span>
              <div class="col-sm-8">
                <textarea
                    v-model="newCrewDocument.remarks"
                    type="text"
                    style="resize: none !important;"
                    class="form-control form-control-sm"
                    name="FamFirst"
                    rows="3"
                    placeholder="&#45;&#45;">
                </textarea>
              </div>
            </div>-->

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Attachment</label>
              <span>:</span>
              <div class="col-sm-8">
                <input type="file"
                       style="resize: none !important;"
                       class="form-control-file"
                       id="docAttachment"
                       @change="on_new_file_docs"
                       placeholder="--">

                <div v-show="is2mbUp">
                  <hr>
                  <button class="btn btn-xs btn-outline-danger" @click="previewFileDocument(this10mbDocImageBlob)">
                    <font-awesome-icon icon="file"/>
                    <span class="ml-2 small font-weight-bold">preview file more than 10mb here.</span>
                  </button>
                </div>

              </div>
            </div>
          </div>

          <!--   preview if not 10 mb up -->
          <div class="col-md-6" v-if="!is2mbUp">
            <div class="prev-container">
              <div style="height: 400px;">
                 <span v-show="!newCrewDocument.file">
                   <font-awesome-icon icon="file" style="height: 100%; "/>
                </span>
                <object v-if="newCrewDocument.file" width="100%" height="100%" :data="newCrewDocument.file+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=50,10,0'" type="application/pdf"></object>
              </div>
            </div>
          </div>

        </div>
      </template>

      <template slot="custom-footer">
        <pmc-btn
            :button-class="'btn pmc-btn-tertia ml-1'"
            :button-label="'Save'"
            @buttonEvent="save_document">
          <template slot="button-icon">
            <font-awesome-icon icon="save"/>
          </template>
        </pmc-btn>

        <pmc-btn
            :button-class="'btn pmc-btn-danger ml-1'"
            :button-label="'Cancel'"
            @buttonEvent="close_newDoc_modal">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>
      </template>
    </pmc-modal>

    <!-- upload file  -->
    <pmc-modal
        @closingEvent="close_up_file_modal"
        :xxl_height="formDocFile.file ? true : false"
        :lg_width="(formDocFile.file || is2mbUp) ? true : false"
        :id="upFileModal">
      <template slot="custom-header">
        <span class="font-weight-bold">UPLOAD DOCUMENT</span>
      </template>

      <template slot="custom-body">
        <div class="row">
          <div class="col">
            <div class="form-group row mt-2">
              <label class="col-sm-4 col-form-label">Attachment</label>
              <span>:</span>
              <div class="col-md-7">
                <input type="file"
                       style="resize: none !important;"
                       class="form-control-file"
                       id="upAttachMent"
                       @change="on_up_file_changes"
                       placeholder="--">
              </div>
            </div>
          </div>

          <div class="col" v-if="is2mbUp">
            <div class="center-alignment">
              <a class="btn btn-sm btn-outline-danger" @click="previewFileDocument(this10mbDocImageBlob)">
                <font-awesome-icon icon="file"/>
                <span class="small ml-1">Click to preview file more than 3mb.</span>
              </a>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col justify-content-center">
              <div class="prev-container">
                <div v-if="formDocFile.file" style="width: 790px; height: 680px;">
                  <object width="100%" height="100%" :data="formDocFile.file+'#toolbar=0&navpanes=0&scrollbar=1&border=0'" type="application/pdf"></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template slot="custom-footer">
        <pmc-btn
            v-if="formDocFile.file"
            :button-class="'btn btn-sm pmc-btn-tertia ml-1'"
            @buttonEvent="upload_file_doc"
            :button-label="'Save'">
          <template slot="button-icon">
            <font-awesome-icon icon="save"/>
          </template>
        </pmc-btn>

        <pmc-btn
            :button-class="'btn btn-sm pmc-btn-danger ml-1'"
            :button-label="'Cancel'"
            @buttonEvent="close_up_file_modal">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>

      </template>

    </pmc-modal>

    <!-- renew crew document file   -->
    <pmc-modal :id="renewDocModal" xl_width lg_height @closingEvent="close_renew_file">
      <template slot="custom-header">
<!--        {{ renewCrewDocument.crewDocId }}-->
        <span class="font-weight-bold">Renew Crew Document - {{ renewCrewDocument.documentName }}</span>
      </template>

      <template slot="custom-body">
        <div class="row">
          <div class="text-left col-md-6">

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Document No.</label>
              <span>:</span>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control form-control-sm"
                    name="FamFirst"
                    v-model="renewCrewDocument.documentNo"
                    placeholder="--">
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Document No. 2</label>
              <span>:</span>
              <div class="col-sm-8">
                <input type="text"
                       class="form-control form-control-sm"
                       name="FamFirst"
                       v-model="renewCrewDocument.documentNo2"
                       placeholder="--">
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Ranks</label>
              <span>:</span>
              <div class="col-md-8">
                <multi-select
                    v-model="renewCrewDocRankId"
                    :options="objRanks"
                    :custom-label="ranks_lang"
                    :select-label="'Select Document Rank'"
                    placeholder="Select Document Rank"
                    label="Document Rank"
                    track-by="id">
                </multi-select>
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Date Issued</label>
              <span>:</span>
              <div class="col-sm-6">
                <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="renewCrewDocument.dateIssued"
                    onkeydown="event.preventDefault()">
              </div>
            </div>

            <div class="form-group row mt-2">
              <label class="col-sm-3 col-form-label">Date Expiry</label>
              <span>:</span>
              <div class="col-sm-6">
                <input
                    type="date"
                    class="form-control form-control-sm"
                    v-model="renewCrewDocument.dateExpiry"
                    onkeydown="event.preventDefault()"
                    placeholder="--">
              </div>
            </div>

            <div class="form-group row mt-3">
              <label class="col-sm-3 col-form-label">
                Attach File
              </label>
              <span>:</span>
              <div class="col-sm-8">
                <input type="file"
                       style="resize: none !important;"
                       class="form-control-file"
                       @change="on_change_renewfile"
                       id="renewDocFile"
                       placeholder="--">
              </div>
            </div>

            <div class="form-group row mt-3">
              <template v-if="renewCrewDocument.documentId == contracts.contractOfEmployment">
                <label class="col-sm-3 col-form-label">
                  Is Extension?
                </label>
                <span class="col-form-label">:</span>
                <input v-model="renewCrewDocument.isExtension"
                       type="checkbox"
                       class="form-check form-check-inline ml-sm-4">
              </template>
            </div>

            <div class="form-group row mt-3">
              <template v-if="renewCrewDocument.isExtension">
                <label class="col-sm-3 col-form-label">
                  Select Extension Type
                </label>
                <span class="col-form-label">:</span>

                <div class="col-md-8">
                  <multi-select
                      v-model="renewCrewExtensionTypeDocId"
                      :options="renewThisObjDocs"
                      :custom-label="docs_lang"
                      :select-label="'Select Document'"
                      placeholder="Select Document"
                      label="Document"
                      track-by="id">
                  </multi-select>
                </div>
              </template>
            </div>

          </div>
          <div class="col-md-6">
            <div class="prev-container">
              <div v-if="is2mbUp">
                <div class="center-alignment">
                  <button class="btn btn-xs btn-outline-danger" @click="previewFileDocument(this10mbDocImageBlob)">
                    <font-awesome-icon icon="file"/>
                    <small class="ml-2">document</small>
                  </button>
                  <p class="small ml-1">Click to preview file more than 3mb.</p>
                </div>
              </div>

              <div v-if="!is2mbUp" style="height: 360px;">
                  <span v-if="!formDocImgFileRenew">
                    <font-awesome-icon icon="file" style="height: 100%; "/>
                  </span>
                <object v-if="formDocImgFileRenew" width="100%" height="100%" :data="formDocImgFileRenew+'#toolbar=0&navpanes=0&scrollbar=1&border=0&zoom=5,10,0'" type="application/pdf"></object>
              </div>
            </div>

          </div>
        </div>
      </template>

      <template slot="custom-footer">
        <pmc-btn
            :button-class="'btn btn-sm pmc-btn-tertia ml-1'"
            :button-label="'Save'"
            @buttonEvent="renew_crew_doc_file">
          <template slot="button-icon">
            <font-awesome-icon icon="save"/>
          </template>
        </pmc-btn>

        <pmc-btn
            :button-class="'btn btn-sm pmc-btn-danger ml-1'"
            :button-label="'Close'"
            @buttonEvent="close_renew_file">
          <template slot="button-icon">
            <font-awesome-icon icon="times"/>
          </template>
        </pmc-btn>
      </template>

    </pmc-modal>

  </div>
</template>

<script>

import noDocumentPrev from '../../../../../assets/noDocumentPrev.png';

import {mapActions, mapGetters} from 'vuex';
import {AppMixins} from '../../../../../mixins/app.mixins';
import {AlertMixins} from '../../../../../mixins/alert.mixins';
import {toUpperCase} from "uri-js/dist/esnext/util";

export default {
  name: 'CrewDocument',
  mixins: [AppMixins, AlertMixins],
  data() {
    return {
      noDocumentPrev: noDocumentPrev,
      isDocument: true, //default true,
      isCertificate: false,
      isCOP: false,
      isMedical: false,
      isEcdis: false,

      newDocModal: 'newDocModal',
      viewDocModal: 'viewDocModal',
      upFileModal: 'upFileModal',
      renewDocModal: 'renewDocModal',

      renewHistoryLogId: null,
      isHistoryLogOnClick: false,
      renewHistoryLogIdStore: null,
      isActiveRenewLogHighlight: false,
      clickedTwice: 0,

      renewHistoryLogActive: {
        id: null,
        active: false,
      },

      renewHistoryLogActiveCopy: {},

      formParam: {
        docType: 1,  //default document
        crewId: parseInt(this.$route.params.crewId, 10),
        docCategory: null,
      },

      docCategories: [
        {
          id: 1,
          val: 'foreign',
        },
        {
          id: 2,
          val: 'national',
        },
      ],

      formDocFile: {
        crewDocId: null,
        file: null,
      },

      isCrewDocEdited: false,
      isCrewDocReplace: false,

      crewDocViewToCrewDocData: {},
      crewDocData: {},

      contracts: {
        contractOfEmployment: 472,
        contractOfEmployment_1st: 856,
        contractOfEmployment_2nd: 861,
        contractOfEmployment_3rd: 862,
        contractOfEmployment_onboard_promotion: 857,
        contractOfEmployment_salary_amendment: 858,
      },

      renewCrewDocument: {
        crewDocId: '',
        documentId: '',
        documentName: '',
        documentNo: '',
        documentNo2: '',
        rankId: '',
        dateIssued: '',
        dateExpiry: '',
        isExtension: 0,
        extensionTypeOld: '',
        extensionTypeNew: '',
      },

      //use for new file, preview
    };
  },

  /** actions **/
  methods: {
    toUpperCase,
    ...mapActions(
        [
          'getCrewDocs',
          'getDocumentKeys',
          'getDocTypeKeys',
          'nullCrewDocSideNav',
          'nullNewCrewDocuments',
          'null_obj_docs',
          'nullCrewDocs',
          'null_obj_ranks',
          'setNewCrewDocFile',
          'nullNewCrewDocFile',
          'createCrewDoc',
          'deleteCrewDoc',
          'uploadCrewDocFile',
          'getRankKeys',
          'viewCrewDoc',
          'nullCrewDoc',
          'nullDocImageBlob',
          'updateCrewDoc',
          'nullCrewDocHistory',
          'nullRenewCrewDoc',
          'renewCrewDoc',
          'set_is_10mbUp',
          'set_doc_imageblob_gte10mb',
          'set_image_blob',
          'set_form_doc_image_file',
          'set_form_10mb_docfile',
          'set_form_doc_image_filerenew',
          'null_doc_imageblob_gte10mb',
          'null_obj_covid_vaccine_brands',
          'getVaccineBrandKeys',
          'null_covid_vaccines',
          'set_is_loading',
          "set_is_2mbUp",
        ],
    ),

    get_crew_docs() {
      this.getCrewDocs(this.formParam);
    },

    /**  put all the set form methods here. **/
    set_doc_type(param) {
      this.formParam.docType = param;
    },

    set_up_doc_id(id) {
      this.formDocFile.crewDocId = id;
    },

    set_cancel_renew_file() {
      //todo
    },

    set_renew_doc_id(crewDoc) {
      this.renewCrewDocument.crewDocId = crewDoc.id;
      this.renewCrewDocument.documentId = crewDoc.documentId;
      this.renewCrewDocument.documentName = crewDoc.documentName;
      this.renewCrewDocument.extensionTypeOld = crewDoc.extensionTypeId ? crewDoc.extensionTypeId : ''
    },

    /** save the new crew document **/
    async save_document() {
      if (await this.questionAlert('Are you sure you want to add\n this crew document record?', 'New Crew document')) {
        let file = document.getElementById('docAttachment').files[0];
        const formData = new FormData();
        formData.append('crewId', this.formParam.crewId ? this.formParam.crewId.toString() : '');
        formData.append('documentId', this.newCrewDocument.documentId ? this.newCrewDocument.documentId : '');
        formData.append('documentNo', this.newCrewDocument.documentNo);
        formData.append('documentNo2', this.newCrewDocument.documentNo2);
        formData.append('dateIssued', this.newCrewDocument.dateIssued);
        formData.append('dateExpiry', this.newCrewDocument.dateExpiry);
        formData.append('rankId', this.newCrewDocument.rankId);
        formData.append('file', file ? file : '');
        this.createCrewDoc(formData).then((r) => {
          if (r) {
            this.$nextTick(()=>{
              this.successAlert('Add Successfully');
              this.get_crew_docs();
              this.empt_input_new_doc();
              this.hideModal(this.newDocModal);
            })
          }
        });
      }
    },

    /** update crew document **/
    async update_document() {
      let file = document.getElementById('replaceAttachment').files[0];

      if (this.isCrewDocReplace && file == (null || undefined)) {
        this.warningAlert('Please input a valid attachment');

      } else {
        const msg1 = 'Are you sure you want to update this document record';
        const msg2 = 'Are you sure you want to update & replace ' + '\n' + 'existing file on this document record.';
        let mes = null;

        if (this.crewDocData.hasFile && file != (null || undefined)) {
          mes = msg2;

        } else {
          mes = msg1;

        }

        if (await this.questionAlert(mes, 'Update Crew Document')) {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('crewDocId', this.crewDocData.id);
          formData.append('documentNo', this.crewDocData.documentNo);
          formData.append('documentNo2', this.crewDocData.documentNo2);
          formData.append('dateIssued', this.crewDocData.dateIssued);
          formData.append('dateExpiry', this.crewDocData.dateExpiry);
          formData.append('rankId', this.crewDocData.rankId);
          this.updateCrewDoc(formData).then((r) => {
            if (r) {
              this.successAlert('Update Successfully');
              this.hideModal(this.viewDocModal);
              this.emp_null_v_attachdoc();
              this.set_form_doc_image_file(null);
              this.isCrewDocReplace = false;
              this.isCrewDocEdited = false;
              // this.get_crew_docs();
            }
          });
        }
      }
    },

    view_document(crewDocId , crewDoc) {
      this.viewCrewDoc(crewDocId);
      this.crewDocViewToCrewDocData = crewDoc;
    },

    /** delete crew document **/
    async delete_document(id) {
      if (await this.questionAlert('Are you sure you want to delete this document record.', 'Crew Document',
          'warning')) {
        this.deleteCrewDoc(id).then((r) => {
          if (r) {
            this.successAlert('Deleted Successfully');
          }
        });
      }
    },

    /** update file crew document **/
    async upload_file_doc() {
      if (await this.questionAlert('Please make sure that the document  right ', 'Update File Document')) {
        let fileParam = document.getElementById('upAttachMent').files[0];
        const formData = new FormData();
        formData.append('crewDocId', this.formDocFile.crewDocId);
        formData.append('file', fileParam);
        this.uploadCrewDocFile(formData).then((r) => {
          if (r) {
            this.successAlert('Uploading File successfully', 'File Upload');
            this.hideModal(this.upFileModal);
            this.get_crew_docs();
            this.emp_input_up_doc();
            this.null_form_doc_file();
          }
        });
      }
    },

    /**
     * on new file crew document
     *
     * */
    on_new_file_docs(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      if (image !== undefined) {
        reader.readAsDataURL(image);
        reader.onload = async e => {
          let param = e.target.result;
          if (this.gte2mb(image)) {
            this.set_is_2mbUp(true);
            this.set_form_10mb_docfile(param); //load parse file in dummy variable.
            let msg = 'It seem\'s like you want to upload a file more than 3mb. \n' +
                'Do you want to preview. ?';
            if (await this.questionAlert(`${msg}`, 'File preview', 'warning')) {
              this.base64ToBlob(param);
              //load string into dump var to display
            }
          } else {
            this.setNewCrewDocFile(param);
          }
        };
      } else {
        this.nullNewCrewDocFile();
        this.set_is_2mbUp(false);
      }
    },

    docs_lang({name}) {
      return `${name}`;
    },

    ranks_lang({
      alias,
      name,
    }) {
      return `[${alias}] - ${name}`;
    },

    /**
     * on file uploading only for crew document
     *
     * */
    on_up_file_changes(e) {
      const image = e.target.files[0];
      const reader = new FileReader();

      if (image !== undefined) {
        reader.readAsDataURL(image);
        reader.onload = async e => {
          let param = e.target.result;
          if (this.gte2mb(image)) {

            this.set_is_2mbUp(true);
            this.set_form_10mb_docfile(param);
            let msg = 'It seem\'s like you want to upload a file more than 3mb. \n' +
                'Do you want to preview. ?';
            if (await this.questionAlert(`${msg}`, 'File Preview', 'warning')) {
              this.base64ToBlob(param);   //view file on separate tab.
            }
          } else {
            this.formDocFile.file = param;
          }
        };
      } else {
        this.resetUploadFile();
      }
    },

    /** on viewing attached crew file  **/
    on_change_attachFile(e) {
      const image = e.target.files[0];
      const reader = new FileReader();

      if (image !== undefined) {
        reader.readAsDataURL(image);
        reader.onload = async e => {
          let param = e.target.result;
          if (this.gte2mb(image)) {
            this.set_is_2mbUp(true);
            this.set_form_10mb_docfile(param);
            let msg = 'It seem\'s like you want to upload a file more than 3mb. \n' +
                'Do you want to preview. ?';
            if (await this.questionAlert(`${msg}`, 'File Preview', 'warning')) {
              this.base64ToBlob(param);   //view file on separate tab.
            }
          } else {
            this.set_form_doc_image_file(param);

          }
        };
      } else {
        this.resetAttachFile();

      }
    },

    /**  on viewing attached renew crew document **/
    on_change_renewfile(e) {
      const imgBlob = e.target.files[0];
      const reader = new FileReader();

      if (imgBlob !== undefined) {
        reader.readAsDataURL(imgBlob);
        reader.onload = async e => {
          let param = e.target.result;
          if (this.gte2mb(imgBlob)) {
            this.set_is_2mbUp(true);
            this.set_form_10mb_docfile(param);
            let msg = 'It seem\'s like you want to upload a file more than 3mb. \n' +
                'Do you want to preview. ?';
            if (await this.questionAlert(`${msg}`, 'File Preview', 'warning')) {
              this.base64ToBlob(param);   //view file on separate tab.
            }
          } else {
            this.set_form_doc_image_filerenew(param);
          }
        };
      } else {
        this.set_form_doc_image_filerenew(null);
        this.emp_crew_doc_file_renew();
      }
    },

    on_change_category(e) {
      let val = e.target.value;
      this.formParam.docCategory = val ? val : null;
      this.get_crew_docs();
    },

    //on check view documents.
    on_attachment_checkbox() {
      if (!this.isCrewDocReplace) {
        this.resetAttachFile();
        this.emp_null_v_attachdoc();
      }
    },

    /** close_ **/
    close_up_file_modal() {
      this.hideModal(this.upFileModal);
      this.null_form_doc_file();
      this.set_form_10mb_docfile(null);
      this.set_is_2mbUp(false);
    },

    close_renew_file() {
      this.set_form_doc_image_filerenew(null);
      this.hideModal(this.renewDocModal);
      this.emp_crew_doc_file_renew();
      this.nullRenewCrewDoc();
      this.set_form_10mb_docfile(null);
      this.set_is_2mbUp(false);

      this.nullRenewCrewDocument(); // null the renew modal details
    },

    close_newDoc_modal() {
      this.nullNewCrewDocuments();
      this.nullNewCrewDocFile();
      this.hideModal(this.newDocModal);
      this.empt_input_new_doc();
      this.set_is_2mbUp(false);
      this.set_form_doc_image_file(null);
    },

    null_form_doc_file() {
      this.emp_input_up_doc();
      this.formDocFile = {
        file: null,
        crewDocId: null,
      };
    },

    /**  emp_ **/
    emp_input_up_doc() {
      $(`#upAttachMent`).val(null);
    },

    emp_null_v_attachdoc() {
      $(`#replaceAttachment`).val(null);
    },

    emp_crew_doc_file_renew() {
      $(`#renewDocFile`).val(null);
    },

    empt_input_new_doc() {
      $(`#docAttachment`).val(null);
    },

    resetAttachFile() {
      this.set_form_doc_image_file(null);
      this.set_is_2mbUp(false);
      this.set_form_10mb_docfile(null);
    },

    resetUploadFile() {
      $(`#upAttachMent`).val(null);
      this.formDocFile.file = null;
      this.set_is_2mbUp(false);
      this.set_form_10mb_docfile(null);
    },

    doc_editable() {
      if (!this.isCrewDocEdited) {
        this.isCrewDocEdited = true;
      }

      let crewDocumentDefault = this.crewDocuments.find(cd => {
        return cd.documentId === this.crewDocument.documentId;
      });

      this.crewDocData = Object.assign({}, crewDocumentDefault);
      this.viewCrewDoc(crewDocumentDefault.id);

      this.isActiveRenewLogHighlight = false

    },

    doc_editable_cancel() {
      if (this.isCrewDocEdited) {
        this.isCrewDocEdited = false;
      }
      if (this.isCrewDocReplace) {
        this.isCrewDocReplace = false;
      }

      if (!this.docImageBlob_gte10mb) {
        this.set_is_2mbUp(false);
      }

      let crewDocumentDefault = this.crewDocuments.find(cd => {
        return cd.documentId === this.crewDocument.documentId;
      });

      this.crewDocData = Object.assign({}, crewDocumentDefault);

      this.set_form_10mb_docfile(null);
      this.set_form_doc_image_file(null);
      this.emp_null_v_attachdoc();
    },

     doc_editable_close() {
      this.nullCrewDoc();
      this.nullDocImageBlob();
      this.nullCrewDocHistory();
      this.hideModal(this.viewDocModal);
      this.set_is_2mbUp(false);
      this.isCrewDocEdited = this.isCrewDocEdited ? !this.isCrewDocEdited : false;
      this.set_form_10mb_docfile(null);
      if (this.isCrewDocReplace) {
        this.isCrewDocReplace = false;
      }

      // remove highlight
       this.isActiveRenewLogHighlight = false
       // reset data and pdf file
       this.crewDocData = Object.assign({}, this.crewDocViewToCrewDocData);
       this.viewCrewDoc(this.crewDocData.id);


    },

    async renew_crew_doc_file() {
      if (await this.questionAlert(`Are you sure you want to save and\n renew his crew document`, 'Renew Crew Document')) {
        let fileParam = document.getElementById('renewDocFile').files[0];

        if (fileParam == (null || undefined)) {
          this.warningAlert('Please attach file when renewing crew document.');

        } else {
          const formData = new FormData();
          formData.append('crewDocId', this.renewCrewDocument.crewDocId ? this.renewCrewDocument.crewDocId : '');
          formData.append('documentNo', this.renewCrewDocument.documentNo ? this.renewCrewDocument.documentNo : '');
          formData.append('documentNo2', this.renewCrewDocument.documentNo2 ? this.renewCrewDocument.documentNo2 : '');
          formData.append('dateIssued', this.renewCrewDocument.dateIssued ? this.renewCrewDocument.dateIssued : '');
          formData.append('dateExpiry', this.renewCrewDocument.dateExpiry ? this.renewCrewDocument.dateExpiry : '');
          formData.append('isExtension', this.renewCrewDocument.isExtension ? 1 : 0);
          formData.append('extensionTypeNew', this.renewCrewDocument.isExtension ? this.renewCrewDocument.extensionTypeNew : '');
          formData.append('extensionTypeOld', this.renewCrewDocument.extensionTypeOld);
          formData.append('rankId',this.renewCrewDocument.rankId ? this.renewCrewDocument.rankId : '')
          formData.append('file', fileParam);
          this.renewCrewDoc(formData).then((r) => {
            if (r) {
             this.$nextTick(()=>{
               this.successAlert('Renew crew document successfully', 'Renew Crew Document');
               // this.nullRenewCrewDoc();
               this.nullRenewCrewDocument();
               this.hideModal(this.renewDocModal);
               this.emp_crew_doc_file_renew();
               this.set_form_doc_image_filerenew(null);
               this.get_crew_docs()
             })
            }
          });
        }
      }
    },

    nullRenewCrewDocument(){
      this.renewCrewDocument.crewDocId = ''
      this.renewCrewDocument.documentId = ''
      this.renewCrewDocument.documentName = ''
      this.renewCrewDocument.documentNo = ''
      this.renewCrewDocument.documentNo2 = ''
      this.renewCrewDocument.rankId = ''
      this.renewCrewDocument.dateIssued = ''
      this.renewCrewDocument.dateExpiry = ''
      this.renewCrewDocument.isExtension = 0
      this.renewCrewDocument.extensionTypeOld = ''
      this.renewCrewDocument.extensionTypeNew  = ''
    },

    /** base 64 file string ,  **/
    previewFileDocument(__stringFileParam) {
      this.base64ToBlob(__stringFileParam);
    },

    async updateCrewDocHistory(crewDocument) {
      this.isCrewDocEdited = false;
      this.isHistoryLogOnClick = true;
      this.viewCrewDoc(crewDocument.id);
      this.crewDocData = Object.assign({}, crewDocument);

      this.renewHistoryLogId = crewDocument.id;


      if (crewDocument.id === this.renewHistoryLogId){
        this.isActiveRenewLogHighlight = true
      }


    },


    activeRenewHistory(crewDocHistory) {
      if(crewDocHistory.id === this.renewHistoryLogId && this.isActiveRenewLogHighlight) {
        return "active-renew-log"
      }

    },

    resetRenewHistoryLog(){
      this.isActiveRenewLogHighlight = false
      this.isHistoryLogOnClick = false;
      // reset data and pdf file
      this.crewDocData = Object.assign({}, this.crewDocViewToCrewDocData);
      this.viewCrewDoc(this.crewDocData.id);

    },





  },

  /** computed **/
  computed: {
    ...mapGetters(
        [
          'crewDocuments',
          'crewComment',
          'crewDocSideNav',
          'newCrewDocument',
          'objDocs',
          'objRanks',
          'docImageBlob',
          'crewDocument',
          'crewDocHistories',
          // 'renewCrewDocument',
          'is2mbUp',
          'formDocImgFile',
          'form10mbUpDocFile',
          'formDocImgFileRenew',
          'docImageBlob_gte10mb',
          'objCovidVaccineBrands',
          'isReloading',
          'hasDocBlob',
        ],
    ),

    formDoctype: {
      get(){
        return this.formParam.docType;

      },
      set (val){
        this.formParam.docType = val ? val : 1;
      }
    },

    newCrewDocumentId: {
      get() {
        let obj = this.objDocs.find((doc) => doc.id === this.newCrewDocument.documentId);
        return obj;
      },
      set(val) {
        this.newCrewDocument.documentId = val ? val.id : null;
      },
    },

    renewCrewExtensionTypeDocId: {
      get() {
        let obj = this.objDocs.find((doc) => doc.id === this.renewCrewDocument.extensionTypeNew);
        return obj;
      },
      set(val) {
        this.renewCrewDocument.extensionTypeNew = val ? val.id : '';
      },
    },

    renewCrewDocRankId: {
      get() {
        let obj = this.objRanks.find((doc) => doc.id === this.renewCrewDocument.rankId);
        return obj;
      },
      set(val) {
        this.renewCrewDocument.rankId = val ? val.id : '';
      },
    },

    newCrewDocRankId: {
      get() {
        return this.objRanks.find((doc) => doc.id === this.newCrewDocument.rankId);
      },
      set(val) {
        this.newCrewDocument.rankId = val ? val.id : null;
      },
    },

    thisCrewDocRankId: {
      get() {
        return this.objRanks.find((doc) => doc.id === this.crewDocData.rankId);
      },
      set(val) {
        this.crewDocData.rankId = val ? val.id : null;
      },
    },


    thisDocImageBlob() {
      return this.docImageBlob ? this.docImageBlob : this.formDocImgFile;
    },

    // filtered the existing ids on crew documents,
    // thisObjDocs() {
    //   let filtered = this.objDocs.filter((docx, index) => {
    //     return this.crewDocuments.filter((cdocx, index) => {
    //       return cdocx.documentId == docx.id;
    //     }).length == 0;
    //   });
    //   return filtered;
    // },

    thisObjDocs() {
      let filtered = this.objDocs.filter((docx, index) => {
        return this.crewDocuments.filter((cdocx, index) => {
          return cdocx.documentId == docx.id;
        }).length == 0;
      });

      return filtered.filter(item =>
          item.id !== this.contracts.contractOfEmployment_1st &&
          item.id !== this.contracts.contractOfEmployment_2nd  &&
          item.id !== this.contracts.contractOfEmployment_3rd  &&
          item.id !== this.contracts.contractOfEmployment_onboard_promotion &&
          item.id !== this.contracts.contractOfEmployment_salary_amendment
      );
    },

    this10mbDocImageBlob() {
      return this.form10mbUpDocFile ? this.form10mbUpDocFile : this.docImageBlob_gte10mb;
    },


    renewThisObjDocs() {
      return this.objDocs.filter(item => item.id === this.contracts.contractOfEmployment_1st ||
          item.id === this.contracts.contractOfEmployment_2nd  ||
          item.id === this.contracts.contractOfEmployment_3rd  ||
          item.id === this.contracts.contractOfEmployment_onboard_promotion ||
          item.id === this.contracts.contractOfEmployment_salary_amendment);
    },


  },




  /** mounted **/
  mounted() {
  },

  /** created **/
   created() {
    let docType = this.$route.query.docType;
    if (docType !== undefined) {
      this.formParam.docType = parseInt(this.$route.query.docType,10);
    }

    this.$nextTick(()=>{
      this.get_crew_docs();
      this.getDocTypeKeys();
      this.getRankKeys();
      this.getDocumentKeys({docType: this.formParam.docType});
      this.getVaccineBrandKeys();
    })
  },

  /** watch **/
  watch: {
    formDoctype(val) {
      //for 11 : ncov
      if (val === 11) {
        this.nullCrewDocs();
        this.$router.push({name: 'CrewCovidVaccine'}).catch((r) => {});

      } else {
        const param = {
          docType: val
        }
        this.get_crew_docs();
        this.getDocumentKeys(param);
        this.$router.push({name: 'CrewDocument'}).catch((r) => {});
      }
    },

    'crewDocViewToCrewDocData'() {
      this.crewDocData = Object.assign({}, this.crewDocViewToCrewDocData);
    },

  },

  /** destroyed  **/
  destroyed() {
    this.null_obj_docs();
    this.nullCrewDocSideNav();
    this.nullCrewDocs();
    this.null_obj_ranks();
    this.null_form_doc_file();
    this.null_doc_imageblob_gte10mb();
    this.null_obj_covid_vaccine_brands();
    this.null_covid_vaccines();
  },
};
</script>

<style scoped>
.sidenav {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 15px;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 20px;
}

.sidenav a {
  padding: 2px 2px 2px 20px;
  text-decoration: none;
  font-size: 16px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  background-color: var(--tertia) !important;
  color: var(--light-font) !important;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left .5s;
  /*padding: 16px;*/
}

#crewNoDocPrev {
  width: 30% !important;
  height: 100% !important;
  margin-top: 10rem;
  position: relative;
  color: #c9ced7 !important;
}

input[type=checkbox] {
  transform: scale(2);
}

.spinner {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: 50%;
  right: 50%; /* or: left: 50%; */
  /*margin-top: -. . px; !* half of the elements height *!*/
  /*margin-right: -. . px; !* half of the elements widht *!*/
}

.active-renew-log{
  background-color: #afcae6;
}

</style>
