<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead text-primary"style="font-size: 22px;">Agent List</span>
                </div>
                <div class="form-group ">
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="row ml-2">
              <div class="col-sm-3">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="agent-ccmemo" id="agent-ccmemo-label" class="col-form-label col-sm-3">CC Memo</label>
                      <div class="col-form-label">:</div>
                      <div class="col-sm-8 text-left">
                        <select
                          @change="onChangeCcMemo"
                          v-model="formAgentData.ccMemo"
                          name="agentCcMemo" class="form-control form-control-sm" id="agent-ccmemo">
                          <option :value="null" class="text-center align-middle">-- SELECT --</option>
                          <option :value="batch"
                                  class="text-left align-middle"
                                  v-for="(batch,index) in objBatches">
                            <span>{{ batch.vesselName }}</span>&nbsp;&nbsp;:&nbsp;&nbsp;<span>{{ batch.batchNo }}</span>
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="agent-ccmemo" id="agent-ccmemo-label" class="col-form-label col-sm-4">Port Name</label>
                      <div class="col-form-label">:</div>
                      <div class="col-sm-7">
                        <input type="text"
                               v-model="formAgentData.portName"
                               class="form-control form-control-sm"
                               placeholder="Port Name">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-5">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="agent-ccmemo" id="agent-ccmemo-label" class="col-form-label col-sm-3">Noted By</label>
                      <div class="col-form-label">:</div>
                      <div class="col-sm-8">
                        <select v-model="formAgentData.notedBy"
                                name="agentCcMemo"
                                class="form-control form-control-sm"
                                id="agent-ccmemo">
                          <option :value="null" class="text-lg-center align-middle">-- SELECT --</option>
                          <option :value="notedBy.id" v-for="(notedBy,index) in objNotedByKeys">
                            {{notedBy.name}} - {{notedBy.position}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-1">
                <div class="row text-left">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <button type="button" class="btn btn-xs pmc-btn-tertia"
                              @click="printAgentList" :disabled="!agentListDtls?.data">
                        <font-awesome-icon icon="file"/>
                        Generate Report
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--        <div class="col-md-4">-->
        <!--          <div class="row">-->
        <!--            <div class="form-inline mb-3">-->
        <!--              <div class="form-group mb-2 mr-5">-->
        <!--                <span class="font-weight-bold lead text-primary" style="font-size: 22px;">Agent List</span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->

        <!--          -->
        <!--        </div>-->
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <div class="row">
          <div class="col-12 text-left">
            <span style="font-size: 16px !important;" class="font-weight-bold">Sign On Crews</span>
          </div>
        </div>
        <div class="table table-responsive pmc-table border-top" style="height: 270px">
          <table class="table table-hover" id="agent-tbl-data"  style="width: 100%;">
            <thead>
            <tr>
              <th class="p-1 text-center align-middle" style="width: 10%;">NAME</th>
              <th class="p-1 text-center align-middle" style="width: 5%;">RANK</th>
              <th class="p-1 text-center align-middle" style="width: 6.25%;">BIRTHDATE</th>
              <th class="p-1 text-center align-middle" style="width: 6.25%;">BIRTHPLACE</th>
              <th class="p-1 text-center align-middle" style="width: 6.25%; text-align: center; vertical-align: middle;">
                <span>PASSPORT </span><span>NO.</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25%;">
                <span>PASSPORT ISSUED</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25%;  text-align: center; vertical-align: middle;" >
                <span>PASSPORT</span>
                <br>
                <span>VALIDITY</span>
              </th>

              <th class="p-1 text-center align-middle"
                  style="width: 6.25%; text-align: center !important; vertical-align: middle;">
                <span>SIRB</span>
                <br>
                <span>NO</span>
              </th>

              <th class="p-1 text-center align-middle"
                  style="width: 6.25%; text-align: center !important; vertical-align: middle;">
                <span>SIRB</span>
                <br>
                <span>ISSUED</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25% !important; text-align: center; vertical-align: middle;">
                <span>SIRB</span>
                <br>
                <span>VALIDITY</span>
              </th>

              <th class="p-1 text-center align-middle"
                  style="width: 6.25%; text-align: center !important; vertical-align: middle;">
                <span>SID</span>
                <br>
                <span>NO</span>
              </th>

              <th class="p-1 text-center align-middle"
                  style="width: 6.25%; text-align: center !important; vertical-align: middle;">
                <span>SID</span>
                <br>
                <span>ISSUED</span>
              </th>

              <th class="p-1 text-center align-middle" style="width: 6.25% !important; text-align: center; vertical-align: middle;">
                <span>SID</span>
                <br>
                <span>VALIDITY</span>
              </th>

              <th class="p-1 text-center align-middle" style="width: 6.25% !important; text-align: center; vertical-align: middle;">
                <span>US VISA</span>
                <br>
                <span>TYPE</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25%; text-align: center; vertical-align: middle;">
                <span>VISA</span>
                <br>
                <span>ISSUED</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25%; text-align: center; vertical-align: middle;">
                <span>VISA</span>
                <br>
                <span>VALIDITY</span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="agentListDtls?.data == undefined">
              <td colspan="13" class="text-left">
                <span style="font-size: 14px !important;">No results found. . .</span>
              </td>
            </tr>
            <tr v-for="(signOnCrew,index) in agentListDtls?.data?.signOnCrews">
              <td style="font-size: 12px !important;" class="text-left align-middle">
                <a href="#"
                   @click.stop="gotoCrewProfileNewWindow(signOnCrew.crewId, 'all-info')"
                   style="color:black;">
                  <small><b>{{ signOnCrew.crewName }} </b></small>
                </a>
              </td>

              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew?.rankAlias}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.birthDate | moment("DD-MMM-YY")}}</td>
              <td style="font-size: 12px !important;" class="text-left align-middle">{{signOnCrew?.birthPlace}}</td>

              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.passportDocNo.toUpperCase()}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.passportIssuedDate | moment("DD-MMM-YY")}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.passportExpiryDate | moment("DD-MMM-YY")}}</td>

              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.sirbDocNo}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.sirbIssuedDate | moment("DD-MMM-YY")}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.sirbExpiryDate | moment("DD-MMM-YY")}}</td>

              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.sidDocNo.toUpperCase()}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.sidIssuedDate | moment("DD-MMM-YY")}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.sidExpiryDate | moment("DD-MMM-YY")}}</td>

              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.usVisaType}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.usVisaIssuedDate | moment("DD-MMM-YY")}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOnCrew.usVisaExpiryDate | moment("DD-MMM-YY")}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr>
    <div class="row mt-5">
      <div class="col-12">
        <div class="row">
          <div class="col-12 text-left">
            <span style="font-size: 16px !important;" class="font-weight-bold">Sign Off Crews</span>
          </div>
        </div>
        <div class="table table-responsive pmc-table border-top" style="height: 270px">
          <table class="table table-hover" id="agent-tbl-data"  style="width: 100%;">
            <thead>
            <tr>
              <th class="p-1 text-center align-middle" style="width: 10%;">NAME</th>
              <th class="p-1 text-center align-middle" style="width: 5%;">RANK</th>
              <th class="p-1 text-center align-middle" style="width: 6.25%;">BIRTHDATE</th>
              <th class="p-1 text-center align-middle" style="width: 6.25%;">BIRTHPLACE</th>
              <th class="p-1 text-center align-middle" style="width: 6.25%; text-align: center; vertical-align: middle;">
                <span>PASSPORT </span><span>NO.</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25%;">
                <span>PASSPORT ISSUED</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25%;  text-align: center; vertical-align: middle;" >
                <span>PASSPORT</span>
                <br>
                <span>VALIDITY</span>
              </th>
              <th class="p-1 text-center align-middle"
                  style="width: 6.25%; text-align: center !important; vertical-align: middle;">
                <span>SIRB</span>
                <br>
                <span>NO</span>
              </th>
              <th class="p-1 text-center align-middle"
                  style="width: 6.25%; text-align: center !important; vertical-align: middle;">
                <span>SIRB</span>
                <br>
                <span>ISSUED</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25% !important; text-align: center; vertical-align: middle;">
                <span>SIRB</span>
                <br>
                <span>VALIDITY</span>
              </th>

              <th class="p-1 text-center align-middle"
                  style="width: 6.25%; text-align: center !important; vertical-align: middle;">
                <span>SID</span>
                <br>
                <span>NO</span>
              </th>
              <th class="p-1 text-center align-middle"
                  style="width: 6.25%; text-align: center !important; vertical-align: middle;">
                <span>SID</span>
                <br>
                <span>ISSUED</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25% !important; text-align: center; vertical-align: middle;">
                <span>SID</span>
                <br>
                <span>VALIDITY</span>
              </th>


              <th class="p-1 text-center align-middle" style="width: 6.25% !important; text-align: center; vertical-align: middle;">
                <span>US VISA</span>
                <br>
                <span>TYPE</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25%; text-align: center; vertical-align: middle;">
                <span>VISA</span>
                <br>
                <span>ISSUED</span>
              </th>
              <th class="p-1 text-center align-middle" style="width: 6.25%; text-align: center; vertical-align: middle;">
                <span>VISA</span>
                <br>
                <span>VALIDITY</span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="agentListDtls?.data == undefined">
              <td colspan="13" class="text-left">
                <span style="font-size: 14px !important;">No results found. . .</span>
              </td>
            </tr>
            <tr v-for="(signOffCrew,index) in agentListDtls?.data?.signOffCrews">
              <td style="font-size: 12px !important;" class="text-left align-middle">
                <a href="#"
                   @click.stop="gotoCrewProfileNewWindow(signOffCrew.crewId, 'all-info')"
                   style="color:black;">
                  <small><b>{{ signOffCrew.crewName }} </b></small>
                </a>

              </td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew?.rankAlias}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.birthDate | moment("DD-MMM-YY")}}</td>
              <td style="font-size: 12px !important;" class="text-left align-middle">{{signOffCrew?.birthPlace}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.passportDocNo.toUpperCase()}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.passportIssuedDate | moment("DD-MMM-YY")}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.passportExpiryDate | moment("DD-MMM-YY")}}</td>

              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.sirbDocNo}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.sirbIssuedDate | moment("DD-MMM-YY")}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.sirbExpiryDate | moment("DD-MMM-YY")}}</td>

              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.sidDocNo.toUpperCase()}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.sidIssuedDate | moment("DD-MMM-YY")}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.sidExpiryDate | moment("DD-MMM-YY")}}</td>

              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.usVisaType}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.usVisaIssuedDate | moment("DD-MMM-YY")}}</td>
              <td style="font-size: 12px !important;" class="text-center align-middle">{{signOffCrew.usVisaExpiryDate | moment("DD-MMM-YY")}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- modal here.   -->
    <div class="modal fade" id="cc-agent-list-modal" tabindex="-1" role="dialog" aria-labelledby="cc-agent-list-modal-label" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl  modal" role="document">
        <div class="modal-content" style="max-height: 80%;">
          <div class="modal-header">
            <div class="col-3 text-left align-middle">
              <h5 class="modal-title" id="cc-agent-list-modal-title">Agent Checklist</h5>
            </div>

            <div class="col-1 text-right align-middle">
              <button type="button" class="btn btn-light" data-dismiss="modal" aria-label="Close" @click="closeAgentListModal">
                <span class="text-danger" aria-hidden="true">&times;</span>Close
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div id="agent-file-container"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {defineComponent} from 'vue'
import Table from "@/components/base/Table.vue";
import {mapActions, mapGetters} from "vuex";
import __ from "lodash"
import {AlertMixins} from "@/mixins/alert.mixins";
import {AppMixins} from "@/mixins/app.mixins";
export default defineComponent({
  name: "AgentList",
  mixins: [AlertMixins,AppMixins],
  components: {Table},
  data() {
    return {
      formAgentData : {
        ccMemo: null,
        portName: null,
        notedBy: null,
      }
    }
  },
  computed: {
    ...mapGetters(
      [
        "agentListDtls",
        "objNotedByKeys",
        "objBatches",
      ]
    )
  },
  methods: {
    ...mapActions(
      [
        "get_agent_list",
        "print_agent_list",
        "reset_agent_list_state",
      ]
    ),

    deBounceChangeCcMemo : __.debounce(function (e){
      this.onChangeCcMemo(e);
    },500),

    deBounceOnTypePortName : __.debounce(function (e){
      this.onTypePortName();
    },500),

    deBounceSelectedNotedBy : __.debounce(function (e){
      //TODO
    },500),

    generateAgentList() {
      console.log('Check this out')
    },

    onChangeCcMemo() {
      this.get_agent_list(
        {
          batchId: this.formAgentData.ccMemo?.batchId,
          vesselId: this.formAgentData.ccMemo?.vesselId,
        }
      )
    },

    onTypePortName(){
      console.log('onType port name')
    },

    onSelectedNotedBy(){
      console.log('on selected noted by')
    },

    printAgentList(){
      $(`#agent-file-container`).empty();
      $(`#cc-agent-list-modal`).modal({
        backdrop : 'static',
        keyboard : false,
      })
      let loaderTag  = this.loaderTag()
      $(`#agent-file-container`).append(loaderTag);
      this.$nextTick(()=> {
        this.print_agent_list(
          {
            batchId: this.formAgentData.ccMemo?.batchId,
            vesselId: this.formAgentData.ccMemo?.vesselId,
            portName : this.formAgentData.portName,
            notedBy : this.formAgentData.notedBy,
          }
        ).then((r)=>{
          let fScr = this.base64PdfParser(r) //filesource call back
          let fileFrame = `<iframe src="${fScr}" id="cc-details-file-frame" style="height: 400px; width: 100%;" frameborder="0"></iframe>`;
          $(`#agent-file-container`).empty();
          $(`#agent-file-container`).append(fileFrame);
          // this.onChangeCcMemo();
        }).catch((er)=>{
          $(`#agent-file-container`).empty();
          $(`#agent-checklist-container`).append(`<p style="color: red;">Error while generating agent list report.</p>`);
        })
      })
    },
    closeAgentListModal(){
      $(`#cc-agent-list-modal`).modal('hide')
    }
  },
  mounted() {
    this.get_agent_list();
  },
  created() {
  },
  destroyed() {
    this.reset_agent_list_state();
  },
  watch:{},
})
</script>

<style scoped>

/*#agent-tbl-data >  thead > tr > th{
  font-size: 0.8rem !important;
}*/

/*#agent-tbl-data > thead > tr > th > span{
  font-size: 0.8rem !important;
}*/
.tbl-data{
  overflow-x: scroll !important;
}



</style>
