import axios from "axios";
import {PMC} from "@/config/constants.config";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";

const initState = () => {
  return {
    crewPromotions: [],

    crewPromotion: {
      ccId: null,
      currentRankId: null,
      currentRankAlias: null,
      currentRankName: null,
      crewName: null,
      ccpId: null,
      promRankId: null,
      promRankAlias: null,
      promRankName: null,
      recommendDate: null,
      approveDate: null,
      createdAt: null,
      updateDate: null,
      createdBy: null,
      updatedBy: null,
    },

    newCrewPromotion: {},
    objRankPromKeys: [], //ranks use for promoted rank keys
  }
};

const state = {
  ...initState()
};


const mutations = {

  set_crew_promotions(state, payload) {

  },


  set_crew_promotion(state, payload) {
    state.crewPromotion = {
      ccId: payload.ccId,
      currentRankId: payload.currentRankId,
      currentRankAlias: payload.currentRankAlias,
      currentRankName: payload.currentRankName,
      crewName: payload.crewName,
      ccpId: payload.ccpId,
      promRankId: payload.promRankId,
      promRankAlias: payload.promRankAlias,
      promRankName: payload.promRankName,
      dateRecommend: payload.dateRecommend,
      dateApproved: payload.dateApproved,
      createdAt: payload.createdAt,
      updateDate: payload.updatedDate,
      createdBy: payload.createdBy,
      updatedBy: payload.updatedBy,
    };
  },

  create_new_promotion(state, payload) {

  },

  update_crew_promotion(state, payload) {

  },

  delete_crew_promotion(state,payload) {

  },

  reset_state_crew_promotion(state) {
    Object.assign(state, initState());
  },

  set_obj_prom_rank_prom_keys(state, payload) {
    state.objRankPromKeys = payload;
  }
};


const actions = {
  set_crew_promotions({commit}) {
    //TODO get list of promotions
  },

  set_crew_promotion({commit}, payload) {
    return axios.get(PMC.CREW_PROMOTION).then((r) => {
      return true;
    }).catch((er) => {
      return false;
    })
  },

  create_new_promotion({commit}, params) {
    return axios.post(PMC.CREW_PROMOTION, params).then((r) => {
      commit('set_crew_promotion',r.data)
      return true;

    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection")
      } else {
        UtilityService.generateResponseMessage(er, "Recommendation")
      }
      return false;
    })
  },


  //TODO approve_crew_recommendation: update
  update_crew_promotion({commit}, params) {
    return axios.put(`${PMC.CREW_PROMOTION}/${params.ccpId}`,params).then((r) => {
      commit('set_crew_promotion',r.data)
      return true
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection")

      } else {
        UtilityService.generateResponseMessage(er, "Error")

      }
      return false;
    })
  },

  //TODO: delete_crew_recommendation
  delete_crew_promotion({commit}, params) {
    return axios.delete(`${PMC.CREW_PROMOTION}/${params.ccpId}`).then((r) => {
      commit('set_crew_promotion',r.data)
      return true;

    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection")

      } else {
        UtilityService.generateResponseMessage(er, "Error")
      }
      return false;
    })
  },



  reset_state_crew_promotion({commit}) {
    commit('reset_state_crew_promotion')
  },

  /**
   * @author Optional
   */
  async get_obj_rank_prom_keys({commit}, params) {
    return await axios.get(PMC.GET_RANK_PROMOTION_KEYS, {params}).then((r) => {
      commit('set_obj_prom_rank_prom_keys', r.data)
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection")

      } else {
        UtilityService.generateResponseMessage(er, "Error")

      }
      return false;
    })
  },

  async get_crew_promotion({commit}, params) {
    return await axios.get(PMC.GET_CREW_PROMOTION, {params}).then((r) => {
      commit('set_crew_promotion', r.data.crewPromotion)
      commit('set_obj_prom_rank_prom_keys', r.data.objRankPromoKeys);
      return true;
    }).catch((er) => {
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection")

      } else {
        UtilityService.generateResponseMessage(er, "Error")
      }
      return false;
    })
  }
};


const getters = {
  crewPromotion: state => state.crewPromotion,
  crewPromotions: state => state.crewPromotions,
  newCrewPromotion: state => state.newCrewPromotion,
  objRankPromKeys: state => UtilityService.capitalizeProperties(state.objRankPromKeys)
};

export default {
  state,
  actions,
  mutations,
  getters
}
