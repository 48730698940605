import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vue_moment from 'vue-moment'
import Swal2 from "vue-sweetalert2";

import 'vue-multiselect/dist/vue-multiselect.min.css'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import '../public/css/custom.css'

import 'popper.js'

import __ from 'lodash';
import $ from 'jquery'

import { AppConfig } from './config/app.config'
import { Icons } from './config/icons.config'
import { ComponentConfig } from './config/component.config'

Vue.config.productionTip = false
Vue.use(vue_moment)
Vue.use(Swal2)

global.$ = $
global.__ = __

AppConfig.appAxios()
AppConfig.appSweetAlert2()
AppConfig.elementFocus()
AppConfig.appProgressBar()

ComponentConfig.pluginComponents()
ComponentConfig.customComponents()

Icons.config()


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
