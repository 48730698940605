import axios from 'axios'
import { UtilityService } from '../../../service/utility.service'
import { AlertService } from '../../../service/alert.service'
import {PMC} from '../../../config/constants.config'

/** state **/
const state = {
  course: {},
  courses: [],
  newCourse: {
    code: '',
    name: '',
    status: 0, //default
  },

  courseFormParam: {
    searchValue: null,
    count: 10,
    status: 1, //default true
    page: null,
  },

  coursePagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },
}

/** mutations **/
const mutations = {

  set_course (state, params) {
    state.course = params;
  },

  set_courses (state, params) {
    state.courses = params;
  },

  create_course (state) {
    state.newCourse = {
      code: '',
      name: '',
      status: 0, //default
    };
  },

  update_course (state, params) {
    let school = state.courses.find((val, index) => val.id === params.id)
    Object.assign(school, params)

  },

  delete_course (state, params) {
    let index = state.courses.findIndex((val, index) => val.id === params)
    state.courses.splice(index, 1)
  },

  null_course (state) {
    state.course = {}
  },

  null_courses (state) {
    state.courses = []
  },

  set_course_pagination (state, params) {
    state.coursePagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },

  reset_course_pagination (state) {
    state.coursePagination = {
      from: 1,
      currentPage: 1,
      lastPage: 0,
      perPage: 10,
      to: 10,
      total: 0,
    }
  },

  reset_course_form_param(state){
    state.courseFormParam = {
      searchValue: null,
      count: 10,
      status: 1, //default true
      page: null,
    }
  },
}

/** actions **/
const actions = {

  async set_course ({ commit }, params) {
    return axios.get(`${PMC.COURSE}/${params}`).then((response) => {
      commit('set_course', response.data)
      return true
    }).catch((error) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(error, 'Course details')
      return false
    })
  },

  //paginate
  set_courses ({ commit }, params) {
    commit('set_is_loading',true)
    return axios.get(PMC.COURSE, { params }).then((response) => {
      commit('set_course_pagination', response.data)
      commit('set_courses', response.data.data)
      commit('set_is_loading',false)
      return true

    }).catch((error) => {
      UtilityService.failProgressBar()
      UtilityService.generateResponseMessage(error, 'Get course paginate')
      return false

    })
  },

  async create_course ({ commit }, params) {
    let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New course', 'question')
    if (await __create) {
      return axios.post(PMC.COURSE, UtilityService.deCapitalizeProperties(params)).then((response) => {
        AlertService.successAlertService('Create course record succesfully', 'Create course');
        commit('create_course', response.data);
        return true;

      }).catch((error) => {
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(error, 'Create course');
        return false;
      })
    }
  },

 async update_course ({ commit }, params) {
    let __update = AlertService.questionAlertService("Do you want to save changes that you made.",'Update course','question');
    if(await __update){
      return axios.put(`${PMC.COURSE}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((response)=>{
        AlertService.successAlertService("Updated successfully",'Update course');
        commit('update_course',response.data);
        return true;

      }).catch((error)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(error,'Update course');
        return false;

      });
    }
  },

 async delete_course ({ commit }, params) {
    let _del = AlertService.questionAlertService("Do you want to delete this record.",'Delete course','question');
    if(await _del){
      return axios.delete(`${PMC.COURSE}/${params}`).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete course');
        commit('delete_course',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete course");
        return false;

      })
    }
  },

  null_course ({ commit }) {
    commit('null_course')
  },

  null_courses ({ commit }) {
    commit('null_courses')
  },

  reset_course_pagination ({ commit }) {
    commit('reset_course_pagination')
  },

  reset_course_form_param ({commit}) {
    commit('reset_course_form_param');
  },
}

/** getters **/
const getters = {
  course: state => UtilityService.capitalizeProperties(state.course),
  courses: state => UtilityService.capitalizeProperties(state.courses),
  newCourse: state => UtilityService.capitalizeProperties(state.newCourse),
  courseFormParam: state => state.courseFormParam,
  coursePagination: state => state.coursePagination,
}

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
}
