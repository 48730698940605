<template>
  <router-view/>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EmployeeSetup',

  /** mixins **/
  mixins: [],

  /** props **/
  props: {},

  /** data **/
  data () {
    return {}
  },

  /** methods**/
  methods: {
    ...mapActions([
        'null_obj_departments',
        'null_obj_genders',
        'null_obj_companies',
        'null_obj_civilStatus',
    ])


  },

  /**  computed  **/
  computed: {},

  /** mounted **/
  mounted () {
  },

  /** created **/
  created () {
  },

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed () {
    this.null_obj_departments()
    this.null_obj_genders()
    this.null_obj_companies()
    this.null_obj_civilStatus()
  },

}
</script>

<style scoped>

</style>
