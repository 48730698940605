import axios from 'axios';
import {PMC} from '@/config/constants.config'
import {UtilityService} from '@/service/utility.service';

const initStateCrewCompliment = () => {
  return {
    crewCompliments: [],
    crewCompliment: {},
    formSearchDataCompliment: {
      vesselId : null,
    },
    newCrewCompliment: {},
    isLoadingCrewCompliment: false,
  };
};

/** state **/
const state = {
  ...initStateCrewCompliment(),
};

/** mutations **/
const mutations = {
  set_crew_compliments(state, payload) {
    state.crewCompliments = payload;
  },

  set_crew_compliment(state, payload) {
    state.crewCompliment = payload;
  },

  create_crew_compliment(state, payload) {
    Object.assign(state.newCrewCompliment, {});
    state.crewCompliments.push(payload);
  },

  update_crew_compliment(state, payload) {
    let crewCompliment = state.crewCompliments.find((v,k)=>{
     return v.id == payload.id;
    });
    Object.assign(crewCompliment, payload);
  },

  delete_crew_compliment(state, payload) {
    let complimentIndex = state.crewCompliments.findIndex((value, index) => value.id === payload.id);
    state.crewCompliments.splice(complimentIndex, 1);
  },

  reset_compliment_state(state) {
    Object.assign(state, initStateCrewCompliment());
  },

  set_is_loading_crew_compliment(state,payload){
    state.isLoadingCrewCompliment = payload;
  },

  reset_new_crew_compliment(state){
    state.newCrewCompliment = {}
  },

};

/** actions **/
const actions = {

  setCrewCompliment({commit},payload) {
    commit('set_crew_compliment',payload);
  },

 async getCrewComplimentPerVessel({commit},payload){
    commit('set_is_loading_crew_compliment',true)
    return axios.get(PMC.CREW_COMPLIMENT,{
      params :payload
    }).then((r)=>{
      commit('set_crew_compliments',r.data.crewCompliments);
      commit('set_obj_vessels',r.data.vesselKeys);
      commit('set_obj_ecdis',r.data.ecdisKeys);
      commit('set_obj_ranks',r.data.rankKeys);
      commit('set_obj_all_docs',r.data.getAllDocKeys);
      commit('set_is_loading_crew_compliment',false);
      return true;

    }).catch((er)=>{
      commit('set_is_loading_crew_compliment',false)
      return false;

    });
  },

  createCrewVesselCompliment({commit},payload){
    return axios.post(PMC.CREW_COMPLIMENT,payload).then((r)=>{
      if(Object.keys(r.data).length !== 0){
        //commit('create_crew_compliment',r.data)
        commit('set_crew_compliments',r.data)
      }
      return true;

    }).catch((er)=>{
      UtilityService.generateResponseMessage(er);
      return false;

    })
  },

  updateCrewVesselCompliment({commit},payload){
    return axios.patch(`${PMC.CREW_COMPLIMENT}/${payload.id}`,payload).then((r)=>{
      commit('update_crew_compliment',r.data)
      return true;

    }).catch((er)=>{
      UtilityService.generateResponseMessage(er)
      return false;

    })
  },

  deleteCrewVesselCompliment({commit},payload){
    return axios.delete(`${PMC.CREW_COMPLIMENT}/${payload}`).then((r)=>{
      commit('delete_crew_compliment',r.data)
      return true;

    }).catch((er)=>{
      UtilityService.generateResponseMessage(er)
      return false;

    })
  },

  resetComplimentState({commit}) {
    commit('reset_compliment_state');
  },

  resetNewCrewCompliment({commit}) {
    commit('reset_new_crew_compliment')
  },

  setIsLoadingCrewCompliment({commit}, payload) {
    commit('set_is_loading_crew_compliment',payload)
  }
};

/** getters **/
const getters = {
  crewCompliments: state => state.crewCompliments,
  crewCompliment: state => state.crewCompliment,
  formSearchDataCompliment: state => state.formSearchDataCompliment,
  newCrewCompliment: state => state.newCrewCompliment,
  isLoadingCrewCompliment: state => state.isLoadingCrewCompliment,
};

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
};
