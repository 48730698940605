export const TOKEN = {
  PMC: 'PMC_TOKEN',
}

export const PMC_APP_KEYS = {
  ENCRYPTED_KEY : 'vuex_pmc',
  ENCRYPTED_SECRET : '5266556A586E3272357538782F413F44',
}


export const PMC = {
  // USER_LOGIN : 'userLogin',
  //active

  LOGIN: 'empLogin',
  LOGOUT: 'empLogout',
  USER_DETAILS: 'userDetails',

  CHANGE_PASSWORD: 'changePassword',
  RESET_PASSWORD : 'resetPassword',

  /** api default resource route here **/
  CREW: 'crew',
  CREW_CONTACT: 'crewContact',
  CREW_ADDRESS: 'crewAddress',
  CREW_EDUCATION: 'crewEducation',
  CREW_FAMILY: 'crewFamily',
  CREW_DOCUMENT: 'crewDocument',
  CREW_COMMENT: 'crewComment',
  CREW_MEDICAL: 'crewMedical',
  APPLICANT_TRACKING : 'applicantTracking',
  SCHOOL : 'school',
  EMPLOYEE : 'employee',
  COURSE : 'course',
  DOCUMENT : 'document',
  COVID_VACCINE : 'covidVaccine',
  VESSEL : 'vessel',
  CREW_COMPLIMENT : 'crewCompliment',
  RANK : 'rank',
  PRINCIPAL : 'principal',
  FLAG : 'flag',
  COUNTRY : 'country',
  CLINIC : 'clinic',
  TRADE_ROUTE : 'tradeRoute',
  OWNER : 'owner',
  EMPLOYEE_POSITION : 'employeePosition',
  RELIGION : 'religion',
  DOCUMENT_CATEGORY : 'documentCategory',
  PORT : 'port',
  UNION : 'union',
  GEAR_SUPPLIER_RATE : 'gearSupplierRate',
  GEAR_SUPPLIER : 'gearSupplier',
  GEAR : 'gear',
  GEAR_COMPLEMENT : 'gearComplement',
  GEAR_COMPLEMENT_TEMPLATE : 'gearComplementTemplate',
  GEAR_TYPE : 'gearType',
  GEAR_PRPO : 'gearsPRPO',
  GEAR_PRPO_RECENT_CREW_CHANGE_PLAN_KEYS : 'getRecentCrewChangePlanKeys',
  CREW_OTHER_SEA_SERVICE: "crewOtherSeaService",
  CREW_CHANGE_REMARK : "crewChangeRemark",
  CC_MEMO_REMARK : "crewBatchingRemark",
  CREW_PROMOTION : 'crewPromotion',
  CREW_CHECKLIST_SIGNATORY : 'crewChecklistSignatory',
  CREW_CHECKLIST_SIGNATORY_TYPE : 'crewChecklistSignatoryType',
  CREW_CHECKLIST_DETAILS_CATEGORY : 'crewChecklistDetailsCategory',
  CREW_WITHDRAWAL : 'crewWithdrawal',
  CREW_WITHDRAWAL_REMARKS : 'crewWithdrawalRemarks',
  VESSEL_CLASSIFICATION : 'vesselClassification',
  VESSEL_ENGINE_TYPE : 'vesselEngineType',


  /**
   * @author ALL_EMPLOYEE_ROUTES_RELATED
   */
  UPLOAD_EMPLOYEE_IMAGE : 'uploadEmployeeImage',
  DELETE_EMPLOYEE_IMAGE : 'deleteEmployeeImage',

  /**
   * @author ALL_CREW_DOCUMENT_ROUTES_RELATED_HERE
   */
  UPDATE_CREW_DOCUMENT: 'updateCrewDocument',
  UPDATE_CREW_MEDICAL: 'updateCrewMedical',
  RENEW_CREW_DOCUMENT: 'renewCrewDocument',
  UPDATE_COVID_VACCINE : 'updateCovidVaccine',
  UPLOAD_CREW_DOC_FILE: 'uploadCrewDocFile',

  //get crew covid vaccine [post]
  // deprecated
  GET_COVID_VACCINES : 'getCovidVaccines',
  GET_VACCINES_BOOSTER : 'getVaccineBooster',
  // deprecated

  GET_COVID_VACCINE_BOOSTER_FILE : 'getBoosterAttachmentFile',
  DELETE_COVID_VACCINE_BOOSTER_FILE : 'deleteBoosterAttachmentFile',
  DELETE_COVID_VACCINE_FILE : 'deleteCovidVaccineFile',

  /**
   * @author ALL_CREW_PROFILE_ROUTES_RELATED_HERE
   */
  GET_CREW_PERSONAL_DTLS: 'getCrewPersonalDetails',
  GET_CREW_ADDRESS_CONTACTS: 'getCrewAddressContacts',
  GET_CREW_EDUCATIONS: 'getCrewEducations',
  GET_CREW_FAMILIES: 'getCrewFamilies',
  GET_CREW_REFERRAL : 'getCrewReferral',
  GENERATE_ENDORSED_CREW : 'generateEndorsedCrew',
  GET_CREW_CONTACTS: 'getCrewContacts',
  GET_CREW_CONTACT_DETAILS: 'getCrewContactDetails',
  GET_CONTACT_TYPES: 'getCrewContactTypes',
  GET_CREW_SEA_SERVICES : 'getCrewSeaServices',
  GET_CREW_OTHER_SERVICES : 'getCrewOtherSeaServices',
  GET_CREW_WITH_DRAWAL : 'getCrewWithDrawal',
  GET_CREW_APPLICATION_DETAILS : 'getCrewApplicationDetails',

  GENERATE_CREW_BIO_DATA: 'generateCrewBiodata',

  CREATE_CREW_CONTACTS: 'createCrewContact',
  EDIT_CREW_CONTACTS: 'editCrewContact',
  DELETE_CREW_CONTACT: 'deleteCrewContact',
  UPLOAD_CREW_IMAGE: 'uploadCrewImage',
  DELETE_CREW_IMAGE: 'deleteCrewImage',

  //all info
  UPDATE_CREW_INFO: 'updateCrewInformation',
  UPDATE_CREW_GEN_INFO: 'updateCrewGenInformation',
  UPDATE_CREW_CONTRIB_INFO: 'updateCrewContribInfo',

  //bio data generated
  BIODATA_GET_DETAILS : 'getCrewBiodataDetails',

  UPDATE_APPLICANT_DETAILS : 'updateApplicantDetails',
  UPDATE_CREW_AVAILABILITY : 'updateCrewAvailability',

  GENERATE_SEA_SERVICE_REPORT: 'generateSeaServiceReport',
  GET_SEA_SERVICES_RANKS_AVAILABLE: 'getSeaServicesRanksAvailableKeys',


  UPDATE_WITHDRAWAL_FILE : 'updateWithdrawalFile',
  UPLOAD_ADDITIONAL_ATTACHMENT_FILE : 'uploadAdditionalAttachmentFile',


  UPLOAD_REMARKS_ATTACH_FILE : 'uploadRemarksAttachFile',

  /**
   * @author DOCUMENT_RANK_MATRIX_ENDPOINTS
   */
  GET_DOCUMENT_PREREQUISITE : 'getPreRequisiteDetails',//obsolete
  GET_DOCUMENT_RANKS_MATRIX : 'getDocumentRankMatrix',
  UPDATE_DOCUMENT_PREREQUISITE_RANK : 'updatePreRequisiteDetails',
  UPDATE_DOCUMENT_RANK_MATRIX : 'updateDocumentRankMatrix',


  /**
   * @author CREW_EVALUATION_URI
   */
  GET_EVALUATION_CRITERIA : 'getEvaluationCriteria',
  SAVE_CREW_EVALUATION_CRITERIA : 'saveCrewEvaluationCriteria',
  GET_CREW_EVALUATION_HDR : 'getCrewEvaluationCriteria',
  GET_CREW_EVALUATIONS : 'getCrewEvaluations',
  GET_CREW_EVALUATION_DATA : 'getCrewEvaluationData',
  GET_EVALUATION_DEFAULT: 'getCrewEvaluationDefault',
  GET_EVALUATORS : 'getEvaluatorsList',
  GET_CREW_EVALUATION_DETAILS: 'getCrewEvaluationDetails',
  GET_EVALUATION_SIGNATORIES: 'getEvaluationSignatories',
  UPDATE_CREW_EVALUATION_HDR: 'updateCrewEvaluationHeader',
  SAVE_CREW_EVALUATION_DETAILS : 'saveCrewEvaluationDetails',
  PRINT_EVALUATION_FORMS : 'printEvaluationForms',
  UPLOAD_EVALUATION_DOC: 'uploadEvaluationDoc',
  GET_EVALUATION_DOC: 'getEvaluationDoc',
  DELETE_EVALUATION_DOC: 'deleteEvaluationDoc',



  //Crew Pre Departure Checklist
  GET_CREW_CHECKLIST : 'getCrewChecklist',
  SAVE_CREW_CHECKLIST : 'saveCrewChecklist',
  GENERATE_CREW_CHECKLIST : 'generateCrewChecklist',
  GENERATE_CREW_INFO_SHEET : 'generateInfoSheetPDF',
  GET_CREW_ADHOC_DETAILS: 'getCrewDetailsAdhoc',

  /**
   * @author KEYS
   */
  //ph_arc
  GET_REGION_KEYS: 'getRegionKeys',
  GET_PROVINCE_KEYS: 'getProvinceKeys',
  GET_CITY_KEYS: 'getCityKeys',
  GET_BARANGAY_KEYS: 'getBarangayKeys',
  GET_CREW_EDUCATION_KEYS: 'getCrewEducationKeys',
  GET_CREW_FAMILY_KEYS: 'getCrewFamilyKeys',
  GET_CREW_PERSONAL_OBJ_KEYS: 'getCrewPersonalObjKeys',
  GET_DOCTYPE_KEYS: 'getDocTypeKeys',
  GET_DOCUMENT_KEYS: 'getDocumentKeys',
  GET_RANK_KEYS: 'getRankKeys',
  GET_RANK_TYPE_KEYS: 'getRankTypeKeys',
  GET_CREW_MEDICAL_KEYS: 'getCrewMedicalKeys',
  GET_CREATE_NEW_CREW_KEYS: 'getCreateNewCrewKeys',
  GET_APPLICANT_TRACKING_KEYS : 'getApplicantTrackingKeys',
  GET_NEW_EMPLOYEE_KEYS : 'getNewEmployeeKeys',
  GET_DOCUMENT_SET_UP_KEYS : 'getDocumentSetupKeys',
  GET_COVID_VACCINE_BRAND_KEYS : 'getVaccineBrandKeys',
  GET_GEAR_SUPPLIER_KEYS : 'getGearSupplierKeys',
  GET_GEAR_KEYS : 'getGearKeys',
  GET_GEAR_TYPE_KEYS : 'getGearTypeKeys',
  GET_TOWEL_USAGE_KEYS : 'getTowelUsageKeys',
  GET_EMPLOYEE_KEYS : 'getEmployeeKeys',
  GET_SIGNATORY_TYPE_KEYS : 'getSignatoryTypeKeys',
  GET_CHECKLIST_CATEGORY_KEYS : 'getChecklistCategoryKeys',
  GET_CHECKLIST_TYPE_KEYS : 'getChecklistTypeKeys',
  GET_OWNER_KEYS : 'getOwnerKeys',
  GET_PRINCIPAL_KEYS : 'getPrincipalKeys',


  //DOCUMENT_RANK_MATRIX_KEYS
  GET_ALL_RANK_KEYS : 'getAllRanks',
  GET_DECK_RANK_KEYS : 'getDeckRanks',
  GET_ENGINE_RANK_KEYS : 'getEngineRanks',
  GET_RADIO_RANK_KEYS : 'getRadioRanks',
  GET_STEWARD_RANK_KEYS : 'getStewardRanks',
  GET_ACTIVE_RANK_LEVEL : 'getActiveRankLevelKeys',
  GET_WITHDRAWAL_REASON_KEYS : 'getWithdrawalReasonKeys',


  /**
   * @author GEAR_COMPLEMENTS & DETAILS
   */
  GET_OWNER_WITH_VESSEL_KEYS : 'getOwnersWithVessel',
  GET_VESSEL_WITH_OWNER_KEYS : 'getVesselsWithOwner',
  GET_GEAR_SUPPLIER_RATE_FOR_COMPLEMENT : 'getGearSupplierRatesForComplement',
  GET_ACTIVE_GEAR_KEY_SELECTION_IN_GEARRATE_SUPPLIER: 'getActiveGearKeySelection', // Gear Supplier Rates
  GEARS_PRPO_GET_DETAILS : 'getGearsPRPODetails', // get gears prpo details for generate pdf details Route
  UPDATE_CREW_INFO_FROM_GEARS_PRPO: 'updateCrewInfoFromGearsPRPO',
  GENERATE_GEARS_PRPO: 'generateGearsPRPO',

  //all route paginated here
  GET_ALL_CREW_PAGINATED: 'getAllCrewPaginated',

  //TEST THIS END POINT FOR THE FUTURE NEEDS
  DEMO_UPLOAD : 'testUploadChunkFile',


  /**
   *@author ALL_VESSEL_ROUTES_RELATED
   */
  UPDATE_VESSEL : 'updateVessel',
  CREATE_VESSEL : 'createVessel',
  UPLOAD_VESSEL_IMAGE : 'uploadVesselImage',
  DELETE_VESSEL_IMAGE : 'deleteVesselImage',


  /**
   *@author ALL_CREW_CHANGE_ROUTES_RELATED
   */
  CREW_CHANGE : 'crewchange',
  GET_CREW_CHANGE_PLAN: 'crewchange/getPlan',
  UPDATE_EOC_DATE_CREW_CHANGE_PLAN: 'crewchange/updateEOCDateCrewChangePlan',
  GENERATE_CREW_CHANGE_PLAN: 'crewchange/generateCrewChangePlan',
  GET_CREW_CHANGE_SIGN_ON_LIST: 'crewchange/getCrewChangeSignOnList',
  GET_CREW_CHANGE_SIGN_OFF_LIST: 'crewchange/getCrewChangeSignOffList',
  ADD_CREW_CHANGE_PLAN : 'crewchange/addCrewChangePlan',
  REMOVE_CREW_CHANGE_PLAN : 'crewchange/saveSetUpCcPlans',
  GET_AVAILABLE_CREW_LIST : 'crewchange/getAvailableCrewList',
  SAVE_SETUP_CCPLANS: 'crewchange/saveSetUpCcPlans',
  SAVE_SETUP_CCPLAN_AS_TEMP : 'crewchange/saveSetUpCcPlanAsTemp',
  ADD_RANK_CHANGE_PLAN : 'crewchange/addRankChangePlan',
  DELETE_RANK_CHANGE_PLAN : 'crewchange/deleteRankChangePlan',
  DELETE_CREW_CHANGE : 'crewchange/deleteCrewChange',
  EXTEND_CREW_CONTRACT : 'crewchange/extendCrewChangeContract',

  GET_RANK_PROMOTION_KEYS: 'crewPromotion-custom/getObjRankPromKeys',
  GET_CREW_PROMOTION: 'crewPromotion-custom/getCrewPromotion',

  GET_VESSEL_REMARK : 'crewchange/getVesselRemarks',
  ADD_VESSEL_REMARK : 'crewchange/addVesselRemark',
  UPDATE_VESSEL_REMARK : 'crewchange/updateVesselRemark',
  DELETE_VESSEL_REMARK : 'crewchange/deleteVesselRemark',

  //TODO CREW CHANGE MEMO ROUTES HERE
  GET_BATCH_CCM_NO: 'crewChangeMemo/getBatchCcmNo',
  GENERATE_CCM_DETAILS: 'crewChangeMemo/generateCcMemoDetails',
  GET_CC_MEMO_DETAILS : 'crewChangeMemo/getCcMemoDetails',
  CREATE_CC_MEMO_DETAILS : 'crewChangeMemo/createCcMemoDetails',

  GET_NO_BATCH_SIGNON_LIST : 'crewChangeMemo/getNoBatchSignOnList',

  PREVIEW_PRINT_CC_MEMO : 'crewChangeMemo/previewPrintCcMemo',

  //set_update_cc_memo
  SET_UPDATE_CC_MEMO : 'crewChangeMemo/setUpdateCcMemo',
  ADD_CREW_FROM_MEMO : 'crewChangeMemo/addCrewFromMemo',
  DELETE_CREW_FROM_MEMO : 'crewChangeMemo/deleteCrewFromMemo',

  REVISION_UP_CC_MEMO:"crewChangeMemo/revisionUpCcMemo",
  REVISION_DOWN_CC_MEMO:"crewChangeMemo/revisionDownCcMemo",
  APPROVED_CC_MEMO:"crewChangeMemo/approvedCcMemo",
  DISAPPROVED_CC_MEMO:"crewChangeMemo/disApprovedCcMemo",

  GENERATE_CC_MEMO_COVID_VACCINE_REPORT:"crewChangeMemo/generateCcMemoCovidVaccineReport",

  /**
   * @description Crew Change Memo document endpoints
   */

  GENERATE_CCM_DOCUMENT: 'generateCcmDocument',

  /**
   * @description Crew Change Memo masterlist endpoints
   */

  GET_CCM_MASTER_CHECKLIST_DETAILS: 'getCcmMasterChecklistDetails',
  GENERATE_CCM_MASTER_CHECKLIST: 'generateCcmMasterChecklist',

  GET_CREW_CHANGE_MEMO_DETAILS : 'crewChangeManipulation/getCrewChangeMemoDetails',
  GET_CC_MANIPULATE_KEYS : 'crewChangeManipulation/getCcManipulateKeys',
  //updateCcMemoBatch
  //searchCcMemoNo

  SEARCH_CC_MEMO_NO :  "crewChangeManipulation/searchCcMemoNo",
  UPDATE_CC_MEMO_BATCH : 'crewChangeManipulation/updateCcMemoBatch',
  ACTUALIZE_ONBOARDING : 'crewChangeManipulation/actualizeOnboarding',
  UPDATE_CC_MEMO_DETAILS : "crewChangeManipulation/updateCcMemoDetails",

  //REPORTS HERE.
  GENERATE_AGENT_LIST : "generateAgentList",
  GENERATE_CREW_CHECK_LIST : "generateCrewCheckList",

  //Agent List
  AGENT_LIST : 'agentList',
  AGENT_LIST_PRINT : 'agentList/print',

  //Crew Per Principal
  GENERATE_CREW_PER_PRINCIPAL : "generateCrewPerPrincipal",
  GET_CREW_PER_PRINCIPAL_PRINT : "getCrewPerPrincipalPrint",

  //CREW ONBOARD LIST
  GET_CREW_ONBOARD_LIST : 'getOnboardCrewList',
  GENERATE_ONBOARD_CREW_LIST: 'generateOnboardCrewList',
  GET_SIGN_ON_OFF_CREW_SUMMARY: 'getSignOnOffCrewSummary',
  GENERATE_SIGN_ON_OFF_CREW_SUMMARY: 'generateSignOnOffCrewSummary',

  //STANDBY ONBOARD LIST
  GET_CREW_STANDBY_ONBOARD_LIST_REPORT : 'getCrewsStandbyOnboardListReport',
  GENERATE_CREW_STANDBY_ONBOARD_LIST : 'generateCrewStandbyOnboardList',

  //ONBOARD SENIOR OFFICER LIST
  GET_ONBOARD_SENIOR_OFFICER_LIST : 'getOnboardSeniorOfficerList',

  //MANPOWER POOL STANDBY ONBOARD LIST
  GET_MANPOWER_POOL_STANDBY_ONBOARD_LIST : 'getManpowerpoolList',

  //MANPOWER POOL HIGHEST GRT
  GET_HIGHEST_GRT : 'getHighestGRT',

  //MANPOWER POOL REPORT OF CREW FOR COVID VACCINE
  GET_COVID_VACCINE_REPORT : 'getCrewReportForCovidVaccine',

  //MANPOWER POOL REPORT OF CREW FOR COVID VACCINE
  GET_CREW_AGE_RANGE : 'getCrewAgeRange',

  //MANPOWER POOL INACTIVE LIST
  GET_CREW_INACTIVE_LIST: 'getManpowerPoolListInactive',

  //PROMOTION LIST
  GENERATE_CREW_PROMOTION_YEARLY: 'generateCrewPromotionYearly',
  GENERATE_CREW_PROMOTION_YEARLY_COUNT: 'generateCrewPromotionYearlyCount',
  GENERATE_CREW_PROMOTION_YEARLY_PRINT : "generateCrewPromotionYearlyPrint",
  GENERATE_CREW_PROMOTION_PER_RANK: 'generateCrewPromotionPerRank',
  GENERATE_CREW_PROMOTION_PER_RANK_PRINT: 'generateCrewPromotionPerRankPrint',
  GET_DIRECT_PROMOTION: "getDirectPromotion",
  GET_CREW_DIRECT_PROMOTION: "getCrewDirectPromotion",
  CREATE_DIRECT_PROMOTION: "createDirectPromotion",
  UPDATE_DIRECT_PROMOTION: "updateDirectPromotion",

  //MANPOWER POOL REPORT OF CREW WITH MEDICAL ISSUE
  GET_MEDICAL_ISSUE_REPORT: 'getCrewReportWithMedicalIssue',

  //VESSEL LIST ACTIVE INACTIVE
  GET_VESSEL_LIST_ACTIVE_INACTIVE : 'getVesselActiveInactive',

  //CREW POOL SEA SERVICE LISTS
  GET_CREW_POOL_SEA_SERVICE_LISTS : 'getCrewPoolSeaServiceLists',

  //CREW POOL SEA SERVICE LISTS
  GET_MANPOWER_CREW_ONBOARD_LIST : 'getCrewOnBoardLists',
  PRINT_MANPOWER_CREW_ONBOARD_LIST : 'printCrewOnBoardLists',

  //SCHOLAR MONITORING LIST
  GET_SCHOLAR_MONITORING_LIST: 'getScholarMonitoringList',
  GENERATE_SCHOLAR_MONITORING_LIST: 'generateScholarMonitoringList',
  FIND_SCHOLAR_MONITORING_REMARK: 'findScholarMonitoringRemark',
  UPDATE_SCHOLAR_MONITORING_REMARK: 'updateScholarMonitoringRemark',


  // DASHBOARD ONBOARD CREW DOC CERTS EXPIRY
  GET_ONBOARD_DOCS_CERTS_EXPIRY: 'getOnboardDocsCertsExpiry',
  GENERATE_ONBOARD_DOCS_CERTS_EXPIRY: 'generateOnboardDocsCertsExpiry',
  FIND_ONBOARD_DOCS_CERTS_EXPIRY: 'findOnboardDocumentExpiryRemark',
  UPDATE_ONBOARD_DOCS_CERTS_EXPIRY: 'updateOnboardDocumentExpiryRemark',

  // DASHBOARD CREW POOL REPORT PER RANK LIST
  GET_CREW_POOL_REPORT_PER_RANK_LIST: 'getCrewPoolReportPerRankList',
  GET_CREW_POOL_REPORT_PER_RANK_ALL: 'getCrewPoolReportPerRankAll',
  GET_CREW_POOL_REPORT_PER_RANK_PRINT: 'getCrewPoolReportPerRankPrint',

  //DOCS AND CERT MONITORING
  DOCS_CERT_MONITORING: 'docsAndCertMonitoring',
  DOCS_CERT_MONITORING_UPDATE: 'docsAndCertMonitoringUpdate',
  DOCS_CERT_MONITORING_PRINT: 'docsAndCertMonitoringPrint',


  //RETENTION
  GET_STANDBY_RATIO_LIST: 'getStandbyRatioList',
  GET_ONBOARD_HAS_RECOMMENDATION: 'getOnboardHasRecommendation',
  STANDBY_RATIO_LIST_PRINT : 'standbyRatioList/print',

  // THREE MONTH PLAN
  GET_THREE_MONTH_PLAN_LIST : 'getThreeMonthPlanList',
  PRINT_THREE_MONTH_PLAN_LIST : 'getThreeMonthPlanList/print',
  PRINT_THREE_MONTH_PLAN_SUMMARY : 'getThreeMonthPlanList/summary-print',

  //PRINCIPAL FORMAT
  PRINT_KEIYO_FORMAT : 'printKeiyoFormat',

  //UPDATE SEA SERVICE
  UPDATE_SEA_SERVICE_DATES: 'updateSeaServiceDates',
}

