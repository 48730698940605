import axios from "axios";
import {PMC} from "@/config/constants.config";
import {UtilityService} from "@/service/utility.service";
import {AlertService} from "@/service/alert.service";

const state = {
  gearSupplier: {},
  gearSuppliers: [],
  newGearSupplier: {},

  gearSupplierFormParam: {
    searchValue: null,
    type: null,
    level: null,
    count: 10,
    status: null,
    page: null,
  },

  gearSupplierPagination: {
    from: 1,
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    to: 9,
    total: 0,
  },

};


const mutations = {
  set_gearSupplier(state, params) {
    state.gearSupplier = params
  },

  set_gearSuppliers(state, params) {
    state.gearSuppliers = params
  },

  create_gearSupplier(state) {
    state.newGearSupplier = {};
  },

  update_gearSupplier(state, params) {
    // let gearSuppliers = state.gearSupplier.find((val, index) => val.id === params.id)
    // Object.assign(gearSuppliers, params)

    state.gearSupplier = params
  },


  delete_gearSupplier(state, params) {
    let index = state.gearSuppliers.findIndex((val, index) => val.id === params)
    state.gearSuppliers.splice(index, 1)
  },

  set_gearSupplier_pagination(state, params) {
    state.gearSupplierPagination = {
      from: params.from,
      currentPage: params.current_page,
      lastPage: params.last_page,
      perPage: params.per_page,
      to: params.to,
      total: params.total,
    }
  },
};


const actions = {
  async set_gearSupplier({commit}, supplierId) {
    commit('set_is_loading', true)
    return await axios.get(`${PMC.GEAR_SUPPLIER}/${supplierId}`, {params: supplierId}).then((r) => {
      commit('set_gearSupplier', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get gear supplier')
      return false
    })
  },

  async set_gearSuppliers({commit}, params) {
    commit('set_is_loading', true)
    return await axios.get(PMC.GEAR_SUPPLIER, {params}).then((r) => {
      commit('set_gearSuppliers', r.data.data)
      commit('set_gearSupplier_pagination', r.data)
      commit('set_is_loading', false)
      return true

    }).catch((er) => {
      commit('set_is_loading', false)
      UtilityService.generateResponseMessage(er, 'Get gear suppliers')
      return false
    })
  },

  async create_gearSupplier({commit}, params) {
    return axios.post(PMC.GEAR_SUPPLIER, UtilityService.deCapitalizeProperties(params)).then((r) => {
      commit('create_gearSupplier', r.data);
      return true;

    }).catch((error) => {
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error, 'Create gear supplier');
      return false;
    })
  },

  async update_gearSupplier({commit}, params) {
    return axios.put(`${PMC.GEAR_SUPPLIER}/${params.id}`,UtilityService.deCapitalizeProperties(params)).then((r)=>{
      commit('update_gearSupplier',r.data.updatedGearSupplier);
      return true;

    }).catch((error)=>{
      UtilityService.failProgressBar();
      UtilityService.generateResponseMessage(error,'Update gear supplier');
      return false;

    });
  },

  async delete_gearSupplier({ commit }, params) {
    let _del = AlertService.questionAlertService("This action will delete all records associated with this supplier " +
      "in complements, even if they are currently in use"
      ,'Are you sure? ','warning');
    if(await _del){
      return axios.delete(`${PMC.GEAR_SUPPLIER}/${params.supplierId}`,UtilityService.deCapitalizeProperties({params})).then((r)=>{
        AlertService.successAlertService("Deleted successfully",'Delete gear supplier');
        commit('delete_gearSupplier',r.data.id);
        return true;

      }).catch((er)=>{
        UtilityService.failProgressBar();
        UtilityService.generateResponseMessage(er,"Delete gear");
        return false;

      })
    }
  },

};


const getters = {
  gearSuppliers: state => UtilityService.capitalizeProperties(state.gearSuppliers),
  gearSupplier: state => UtilityService.capitalizeProperties(state.gearSupplier),
  gearSupplierPagination: state => state.gearSupplierPagination,
  gearSupplierFormParam: state => state.gearSupplierFormParam,
};


/** export **/
export default {state, actions, mutations, getters};
