<template>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-md-12">

        <div class="row">
          <div class="col-md-6">
            <div class="row">

              <div class="form-inline mb-3">
                <div class="form-group mb-2 mr-5">
                  <span class="ml-4 font-weight-bold lead" style="font-size: 22px;">Checklist Signatory Types</span>
                </div>
                <div class="form-group ">
                  <button type="button" class="btn btn-xs pmc-btn-tertia"
                          data-toggle="modal"
                          data-target="#new-signatory-type-modal"
                          data-backdrop="static"
                          data-keyboard="false"
                  >
                    <font-awesome-icon icon="plus"/>
                    Add Signatory Type
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div class="input-group mb-2 mr-sm-2">
              <input
                type="text"
                class="form-control form-control-sm"
                id="formdata-searchvalue"
                placeholder="Search signatory type"
                v-model="checklistSignatoryTypeFormParam.searchValue"
                @keypress.enter="searchSignatoryType"
              >

              <button class="btn btn-sm pmc-btn-prime" @click="searchSignatoryType">
                <font-awesome-icon icon="search"/>
                search
              </button>
            </div>
          </div>

          <!-- status -->
          <!--          <div class="col-sm-2">-->
          <!--            <div class="form-group row">-->
          <!--              <label for="list-stat" class="col-sm-3 col-form-label">Status</label>-->
          <!--              <span class="col-form-label">:</span>-->
          <!--              <div class="col-sm-7 text-left">-->
          <!--                <select name="listCount" id="list-stat" class="form-control custom-select-sm"-->
          <!--                        v-model="tradeRouteFormParam.status"-->
          <!--                        @change="on_change_status">-->
          <!--                  <option :value="null">&#45;&#45;select&#45;&#45;</option>-->
          <!--                  <option :value="status.key" v-for="(status,index) in statuses" :key="status.id">-->
          <!--                    {{ status.placeholder }}-->
          <!--                  </option>-->
          <!--                </select>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!-- count -->
          <div class="col-sm-2">
            <div class="form-group row">
              <label for="list-count" class="col-sm-3 col-form-label">Count</label>
              <span class="col-form-label">:</span>
              <div class="col-sm-7 text-left">
                <select name="listCount" id="list-count" class="form-control custom-select-sm"
                        v-model="checklistSignatoryTypeFormParam.count"
                        @change="on_change_count"
                >
                  <option :value="count" v-for="(count ,index ) in counts">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!--  pagination      -->
        <div class="row py-1">
          <div class="col-md-9">
            <div class="row">
              <div class="col-5">
                                <pmc-pagination :page-object="checklistSignatoryTypePagination" @settingPage="set_this_page"></pmc-pagination>
              </div>
            </div>
          </div>
        </div>

        <!-- table  -->
        <div id="table-section" class="py-3">
          <pmc-table :max-height="pmcTableHeight" :loading="isReloading" :loader-colspan="14">
            <template v-slot:table-header>
              <tr>
                <th scope="row" class="align-middle" width="2%">#</th>
                <th scope="row" class="align-middle" width="7%">DESCRIPTION</th>
                <th scope="row" class="align-middle" width="7%">STATUS</th>
                <th scope="row" class="align-middle" width="7%">CREATED<br>BY</th>
                <th scope="row" class="text-center align-middle" width="8%">ACTION</th>
              </tr>
            </template>
            <template v-slot:table-body>
              <tr v-for="(signatoryType, index) in checklistSignatoryTypes">
                <td>{{ index + 1 }}</td>
                <td scope="col" class="p-0 text-center">{{ signatoryType.description }}</td>
                <td scope="col" class="p-0 text-center">
                  <!--                  {{ signatoryType.status == 1 ? 'ACTIVE' : 'INACTIVE' }}-->

                  <template v-if="signatoryType.status == 1">
                    <font-awesome-icon icon="check" style="transform: scale(1.7); color:#28a745;" title="ACTIVE"/>
                  </template>

                  <template v-if="signatoryType.status == 0">
                    <font-awesome-icon icon="minus" style="transform: scale(1.7); color:#DC4C64;" title="INACTIVE"/>
                  </template>

                </td>
                <td scope="col" class="p-0 text-center">{{ signatoryType.created_by }}</td>
                <td>
                  <button
                    class="btn btn-xs pmc-btn-prime ml-2"
                    data-toggle="modal"
                    data-target="#view-signatory-type-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    @click="signatoryTypeViewtoSignatoryTypeData = signatoryType"
                  >
                    <font-awesome-icon icon="bars"/>
                    view
                  </button>
                  <button
                    class="btn btn-xs pmc-btn-danger ml-2"
                    @click="delete_checklistSignatoryTypes(signatoryType.id)"
                  >
                    <font-awesome-icon icon="trash"/>
                    delete
                  </button>
                </td>
              </tr>
            </template>
          </pmc-table>
        </div>
      </div>
    </div>

    <!--  new signatory type modal   -->
    <div class="modal fade" id="new-signatory-type-modal" tabindex="-1" role="dialog"
         aria-labelledby="new-signatory-type-modal-label"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="new-signatory-type-modal-label">NEW SIGNATORY TYPE</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new Replace -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">DESCRIPTION</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="newSignatoryType.description"
                    />
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="newSignatoryType.status"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-tertia"
              @click="save">
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button
              v-show="!isEditable"
              type="button"
              class="btn btn-sm pmc-btn-second"
              data-dismiss="modal">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

          </div>
        </div>
      </div>
    </div>


    <!-- modal view signatory type  -->
    <div class="modal fade" id="view-signatory-type-modal" tabindex="-1" role="dialog"
         aria-labelledby="viewPrincipalModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="view-Replace-modal-label">VIEW SIGNATORY TYPE DETAILS</h5>
          </div>

          <div class="modal-body">
            <div class="row ml-1">
              <div class="col-md-12 text-left ml-2">
                <!-- new Replace -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">DESCRIPTION</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="---"
                           v-model="signatoryTypeData.description"
                           :disabled="!isEditable"
                    />
                  </div>
                </div>


                <!--   status -->
                <div class="form-group row mt-2">
                  <label class="col-sm-3 col-form-label">STATUS</label>
                  <span class="col-form-label">:</span>
                  <div class="col-sm-5">
                    <input
                      type="checkbox"
                      class="form-check form-check-inline ml-2 mt-2"
                      placeholder="---"
                      v-model="signatoryTypeData.status"
                      :disabled="!isEditable"
                    />
                    <span> ACTIVE</span>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button"
                    class="btn btn-sm pmc-bg-warning"
                    v-show="!isEditable"
                    @click="set_edit"
            >
              <font-awesome-icon icon="edit"/>
              EDIT
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-second"
                    data-dismiss="modal"
                    v-show="!isEditable">
              <font-awesome-icon icon="undo"/>
              CLOSE
            </button>

            <button type="button"
                    class="btn btn-sm pmc-btn-tertia"
                    v-show="isEditable"
                    @click="update"
            >
              <font-awesome-icon icon="save"/>
              SAVE
            </button>

            <button type="button" class="btn btn-sm pmc-btn-danger"
                    v-show="isEditable"
                    @click="set_cancel"
            >
              <font-awesome-icon icon="times"/>
              CANCEL
            </button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/service/alert.service";
import {AppMixins} from "@/mixins/app.mixins";
import {AlertMixins} from "@/mixins/alert.mixins";

export default {
  name: "List",

  /** mixins **/
  mixins: [AppMixins, AlertMixins],

  /** data **/
  data() {
    return {

      signatoryTypeViewtoSignatoryTypeData: {},
      newSignatoryType: {},
      signatoryTypeData: {},

      isEditable: false,

      counts: [
        5, 10, 15, 20, 30, 40, 50, 100, 200,
      ],

      statuses: [
        {
          id: 1,
          key: '',
          placeholder: 'All',
        },
        {
          id: 2,
          key: 1,
          placeholder: 'Active',
        },
        {
          id: 3,
          key: 0,
          placeholder: 'Inactive',
        },
      ],

    }
  },

  /** methods**/
  methods: {
    ...mapActions([
      'set_checklistSignatoryTypes',
      'create_checklistSignatoryTypes',
      'update_checklistSignatoryTypes',
      'delete_checklistSignatoryTypes',
    ]),

    async save() {
      let __create = AlertService.questionAlertService('Are you sure you want to add this record', 'New Signatory Type', 'question')
      if (await __create) {

        let params = {
          description: this.newSignatoryType.description ? this.newSignatoryType.description : '',
          status: this.newSignatoryType.status ? 1 : 0,
        };

        let signatoryType = await this.create_checklistSignatoryTypes(params);
        if (signatoryType) {
          AlertService.successAlertService('Create signatory type record successful', 'Create signatory type');
          this.hideModal('new-signatory-type-modal')
          this.newSignatoryType = {};
          this.set_checklistSignatoryTypes();
        }
      }
    },


    async update() {
      let __update = AlertService.questionAlertService("Do you want to save changes that you made.", 'Update signatory type', 'question');
      if (await __update) {
        let params = {
          id: this.signatoryTypeData.id,
          description: this.signatoryTypeData.description ? this.signatoryTypeData.description : '',
          status: this.signatoryTypeData.status ? 1 : 0,
        };

        let signatoryType = await this.update_checklistSignatoryTypes(params);
        if (signatoryType) {
          AlertService.successAlertService("Updated successfully", 'Update signatory type');
          this.hideModal('view-signatory-type-modal')
          this.isEditable = false
        }
      }
    },

    set_edit() {
      this.isEditable = true
    },

    set_cancel(e) {
      this.isEditable = false
      this.signatoryTypeData = Object.assign({}, this.signatoryTypeViewtoSignatoryTypeData);
    },

    set_this_page(e) {
      this.checklistSignatoryTypeFormParam.searchValue = ''
      this.checklistSignatoryTypePagination.currentPage = e
      this.checklistSignatoryTypeFormParam.page = e
      this.pageLastLeft = e
      this.set_checklistSignatoryTypes(this.checklistSignatoryTypeFormParam)
    },

    on_change_count(e) {
      this.checklistSignatoryTypeFormParam.page = 1
      this.checklistSignatoryTypeFormParam.searchValue = ''
      this.pageLastLeft = 1
      this.checklistSignatoryTypeFormParam.count = e.target.value
      this.set_checklistSignatoryTypes(this.checklistSignatoryTypeFormParam)
    },

    searchSignatoryType() {

      if (this.checklistSignatoryTypeFormParam.searchValue === '') {
        this.checklistSignatoryTypeFormParam.page = this.pageLastLeft
        this.set_checklistSignatoryTypes(this.checklistSignatoryTypeFormParam)
      }

      if (this.checklistSignatoryTypeFormParam.searchValue) {
        this.checklistSignatoryTypeFormParam.page = 1
      }

      this.set_checklistSignatoryTypes(this.checklistSignatoryTypeFormParam)

      // this.principalFormParam.page = 1;
      // this.set_principals(this.principalFormParam);
    },

  },


  /**  computed  **/
  computed: {
    ...mapGetters(
      [
        'isReloading',
        'checklistSignatoryTypes',
        'checklistSignatoryTypeFormParam',
        'checklistSignatoryTypePagination',
      ],
    ),
  },

  /**  created  **/
  async created() {
    await this.set_checklistSignatoryTypes();
  },


  /** watch **/
  watch: {
    'signatoryTypeViewtoSignatoryTypeData'() {
      this.signatoryTypeData = Object.assign({}, this.signatoryTypeViewtoSignatoryTypeData);
    },
  },

}
</script>

<style scoped>

</style>
