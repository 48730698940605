import { render, staticRenderFns } from "./CrewChecklistSignatoryTypeSetup.vue?vue&type=template&id=754af5ad&scoped=true&"
import script from "./CrewChecklistSignatoryTypeSetup.vue?vue&type=script&lang=js&"
export * from "./CrewChecklistSignatoryTypeSetup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "754af5ad",
  null
  
)

export default component.exports