import axios from "axios";
import {AlertService} from "@/service/alert.service";
import {UtilityService} from "@/service/utility.service";
import {PMC} from "@/config/constants.config";

const initState = () => {
  return {
    crewOtherSeaServices: [],
    crewOtherSeaService: {},
    newCrewOtherSeaService: {
      crewId: null,
      rankId: null,
      flagOthers: null,
      flagId: null,
      vesselName: null,
      vesselTypeId: null,
      signOnPortId: null,
      signOffPortId: null,
      signOnDate: null,
      signOffDate: null,
      manningId: null,
      tradeRouteId: null,
      mainEngine: null,
      grt: null,
      kw: null,
      signOffReason: null,
    },
    isOutSideServiceLoading: false,
  }
}


/** state **/
const state = {
  ...initState()
};

/** mutations **/
const mutations = {
  set_crew_other_sea_service(state, payload) {
    state.crewOtherSeaService = payload;
  },

  set_crew_other_sea_services(state, payload) {
    state.crewOtherSeaServices = payload;
  },

  create_crew_other_sea_service(state, payload) {
    state.crewOtherSeaServices.unshift(payload);
    state.newCrewOtherSeaService = {
      crewId: null,
      rankId: null,
      flagOthers: null,
      flagId: null,
      vesselName: null,
      vesselTypeId: null,
      signOnPortId: null,
      signOffPortId: null,
      signOnDate: null,
      signOffDate: null,
      manningId: null,
      tradeRouteId: null,
      mainEngine: null,
      grt: null,
      kw: null,
      signOffReason: null,
    }
  },

  update_crew_other_sea_service(state, payload) {
    let val = state.crewOtherSeaServices.find((val, index) => {
      return val.id === payload.id;
    })
    Object.assign(val, payload);
  },

  cancel_update_crew_other_sea_service(state, payload) {
    //TODO: cancel_update_crew_other_sea_service
  },

  delete_crew_other_sea_service(state, payload) {
    let index = state.crewOtherSeaServices.findIndex((val, index) => {
      return val.id === payload.id;
    })
    state.crewOtherSeaServices.splice(index, 1);
  },

  null_crew_other_sea_service(state){
    state.newCrewOtherSeaService = {
      crewId: null,
      rankId: null,
      flagOthers: null,
      flagId: null,
      vesselName: null,
      vesselTypeId: null,
      signOnPortId: null,
      signOffPortId: null,
      signOnDate: null,
      signOffDate: null,
      manningId: null,
      tradeRouteId: null,
      mainEngine: null,
      grt: null,
      kw: null,
      signOffReason: null,
    }
  },

  reset_state_crew_other_sea_service(state) {
    Object.assign(state, initState())
  },

  set_is_out_side_service_loading(state, payload) {
    state.isOutSideServiceLoading = payload;
  }
};

/** actions **/
const actions = {
  set_crew_other_sea_services({commit}, params) {
    commit('set_is_out_side_service_loading', true);
    return axios.get(`${PMC.GET_CREW_OTHER_SERVICES}/${params.crewId}`).then((r) => {
      commit('set_crew_other_sea_services', r.data.crewSeaServices)
      commit('set_obj_vessel_types',r.data.objVesselTypes);
      commit('set_obj_flags',r.data.objFlags)
      commit('set_obj_ports',r.data.objPorts)
      commit('set_obj_manning',r.data.objMannings)
      commit('set_obj_trade_route',r.data.objTradeRoutes)
      commit('set_obj_sign_off_reasons',r.data.objSignOffReasons)
      commit('set_obj_ranks',r.data.objRanks)
      commit('set_is_out_side_service_loading', false);

      return true;

    }).catch((er) => {
      commit('set_is_out_side_service_loading', false);
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Get Other Sea Services')
      }
      return false;
    });
  },

  //TODO: set_crew_other_sea_service
  set_crew_other_sea_service({commit}, params) {
    commit('set_crew_other_sea_service', params)
  },

  create_crew_other_sea_service({commit}, params) {
    //commit('add_crew_other_sea_exp_count'); //update counter in nav tab button .
    return axios.post(PMC.CREW_OTHER_SEA_SERVICE,params).then((r)=>{
      commit('create_crew_other_sea_service',r.data)
      return true;

    }).catch((er)=>{
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Create New Record')
      }
      return false;
    })
  },

  async update_crew_other_sea_service({commit}, params) {
    return await axios.put(`${PMC.CREW_OTHER_SEA_SERVICE}/${params.id}`,params).then((r)=>{
      commit('update_crew_other_sea_service',r.data)
      return true;
    }).catch((er)=>{
      return false;
    })
  },

  delete_crew_other_sea_service({commit}, params) {
    return axios.delete(`${PMC.CREW_OTHER_SEA_SERVICE}/${params.id}`).then((r)=>{
      commit('delete_crew_other_sea_service',r.data)
      return true;
    }).catch((er)=>{
      if (!er.response) {
        AlertService.errorAlertService("Cant establish server connection");
      } else {
        UtilityService.generateResponseMessage(er, 'Delete Crew Other Sea Service')
      }
      return false;
    })
  },

  null_crew_other_sea_service({commit}) {
    commit('null_crew_other_sea_service')
  },

  reset_state_crew_other_sea_service({commit}) {
    commit('reset_state_crew_other_sea_service')
  },
};

/** getters **/
const getters = {
  crewOtherSeaServices: state => UtilityService.capitalizeProperties(state.crewOtherSeaServices),
  crewOtherSeaService: state => UtilityService.capitalizeProperties(state.crewOtherSeaService),
  newCrewOtherSeaService: state => UtilityService.capitalizeProperties(state.newCrewOtherSeaService),
  isOutSideServiceLoading: state => state.isOutSideServiceLoading,
};

/** export **/
export default {
  state,
  actions,
  mutations,
  getters,
};
