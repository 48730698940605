<template>
  <div>
    <section id="cover" class="min-vh-100">
      <div id="cover-caption">
        <div class="container">
          <div class="row text-white">
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
              <h1 class="py-2 text-truncate text-dark">Change Password</h1>
              <span class="text-danger">* All users are required to change their passwords at least once every 6 months</span>
              <div class="px-2">
                <form>
                  <div class="form-group text-left mt-5">
                    <label for="new-password" class="font-weight-bold text-dark">
                     <sup class="text-danger">*</sup> New Password
                    </label>
                    <div class="input-group">
                      <input
                          type="password"
                          class="form-control"
                          id="new-password"
                          v-model="formData.password"
                          maxlength="30"
                          placeholder="Password">
                    </div>
                  </div>

                  <div class="form-group text-left mt-5">
                    <label for="con-password" class="font-weight-bold text-dark">
                      <sup class="text-danger">*</sup>
                      Confirm Password
                    </label>
                    <div class="input-group">
                      <input
                          type="password"
                          class="form-control"
                          id="con-password"
                          maxlength="30"
                          v-model="formData.password_confirmation"
                          placeholder="Password">
                    </div>
                  </div>

                  <div class="mt-3 justify-content-center">
                    <button class="btn pmc-btn-prime mr-3" type="button" title="Show Password" @click="showPassword">
                      <font-awesome-icon :icon="this.icon"/>
                    </button>

                    <button type="button" class="btn pmc-btn-tertia mr-3" @click="submit_pass">
                      <font-awesome-icon icon="save"/>
                      Submit
                    </button>

                    <button type="button" class="btn pmc-btn-danger " v-if="formData.newPw || formData.conPw" @click="cancel">
                      <font-awesome-icon icon="times"/>
                      Cancel
                    </button>

                    <button type="button" class="btn btn-secondary" v-if="(!formData.newPw && !formData.conPw)" @click="logout_user">
                      <font-awesome-icon icon="undo"/>
                      Logout
                    </button>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { AppMixins } from '../mixins/app.mixins'
import { AlertMixins } from '../mixins/alert.mixins'
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'ChangePassword',
  mixins : [AppMixins,AlertMixins],

  /** data **/
  data () {
    return {
      icon: 'eye-slash',
      formData : {
        password : null,
        password_confirmation : null,
      }
    }
  },

  /** methods**/
  methods: {
    ...mapActions(
        [
            'logoutUser',
            'changePassword',
            'reset_auth_state',
            'getAuthUser'
        ]
    ),

    goto_login(){
      this.$router.push({name:'Login'});
    },

   async  submit_pass() {
      console.log('SUBMITTING PASSWORD')
     if(await this.questionAlert('Please remember inputed password','Change Password')){
       this.formData.authUserId = this.authUser.id;
      await this.changePassword(this.formData).then((r)=>{
         if(r){
           this.successAlert('Changing password successfully');
           this.reset_form_data();
           //this.$router.resolve({name : 'Login'})
           this.$store.commit('LOGOUT');
           this.$nextTick(()=>{
             this.goto_login();
           })
         }
       });
     };
    },

    showPassword () {
      const newinput = document.getElementById('new-password');
      const coninput = document.getElementById('con-password');
      if ((newinput.type && coninput.type) === 'password') {
        newinput.type = 'text'
        coninput.type = 'text'
        this.icon = 'eye'
      } else {
        newinput.type = 'password'
        coninput.type = 'password'
        this.icon = 'eye-slash'
      }
    },

    reset_form_data(){
      this.formData = {
        newPw : null,
        conPw : null,
      }
    },

    cancel(e){
      e.preventDefault();
      this.reset_form_data();
    },

   async logout_user () {
      let __msg = "Are you sure you want to logout \n" +
                  "without changing your password?";
     if(await this.questionAlert(__msg,'Logoout','warning')){
       this.logoutUser().then((r)=>{
         if (r){
          this.goto_login();
         }
       });
     }
    },
  },

  /**  computed  **/
  computed: {
    ...mapGetters(
        [
            'authUser'
        ]
    )
  },

  /** mounted **/
  mounted () {
    console.log('Check this auth user',this.authUser)
  },

  /** created **/
  created () {},

  /** watch **/
  watch: {},

  /** destroyed **/
  destroyed () {
    this.reset_auth_state();
  },
}
</script>

<style scoped>

#cover{
  background-size: cover;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
}

#cover-caption {
  width: 100%;
  position: relative;
  z-index: 1;
}


form:before {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0,0,0,0.1);
  z-index: -1;
  border-radius: 10px;
}

</style>
